/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';

@Injectable()
export class uuid4 {
	/**
	 * Generates a unique GUID.
	 *
	 * @returns a string conaning a uniquq GUID.
	 */
	public generate = (): string => {
		return uuidv4();
	};
}

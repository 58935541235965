import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { ConfigService } from '../../services/config/config.service';

import { Pagination, PaginationSettings } from './types/types';

@Component({
	selector: 'pagination-toolbar',
	templateUrl: './pagination-toolbar.component.html',
	styleUrls: ['./pagination-toolbar.component.scss'],
})
export class PaginationToolbarComponent implements OnInit, OnChanges {
	@Input() paginationSettings: PaginationSettings;
	@Input() pagination: Pagination = null;

	@Output() setPageSizeEvent: EventEmitter<number> = new EventEmitter<number>();
	@Output() pageChangedEvent: EventEmitter<number> = new EventEmitter<number>();

	public previousText: string = '<';
	public nextText: string = '>';
	public firstText: string = '<<';
	public lastText: string = '>>';

	private maxNumPages: number = 5;

	constructor(private configService: ConfigService) {}

	/**
	 * handles the initialization of the pagination toolbar
	 *
	 * sets up the num of displayed pages but limited to the max number of config pages
	 */
	public ngOnInit(): void {
		this.maxNumPages = this.configService.getPaginationMaxNumPages();

		if (this.paginationSettings.pageSize > this.paginationSettings.maxPageSize) {
			// config mismatch - can't have a page size less than max size - set to max available
			this.paginationSettings.pageSize = this.paginationSettings.maxPageSize;
		}

		this.setNumPages();
	}

	/**
	 * handles changes to the input params
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public ngOnChanges(): void {
		this.pagination.numPages = Math.ceil(this.pagination.totalItems / this.paginationSettings.pageSize);
	}

	/**
	 * handles the user selection of the page control
	 *
	 * @param page - the page value
	 */
	public selectPage = (page: any): void => {
		let newPage: number = 1;

		switch (page) {
			case 'first':
				newPage = 1;
				break;
			case 'previous':
				newPage = this.pagination.currentPage - 1;
				break;
			case 'next':
				newPage = this.pagination.currentPage + 1;
				break;
			case 'last':
				newPage = this.pagination.numPages;
				break;
			default:
				newPage = page;
				break;
		}

		this.pagination.currentPage = newPage;
		this.pageChangedEvent.emit(newPage);
	};

	/**
	 * manages if the previous selection is allowed
	 *
	 * @returns true when there are no previous pages
	 */
	public noPrevious = (): boolean => {
		return this.pagination.currentPage === 1;
	};

	/**
	 * manages if the last selection is allowed
	 *
	 * @returns true when there are no more pages
	 */
	public noLast = (): boolean => {
		return this.pagination.currentPage === this.pagination.numPages;
	};

	/**
	 * returns the page buttons to the template
	 *
	 * @returns the page buttons
	 */
	public getPageButtons = (): number[] => {
		return this.getPageNumbers(this.maxNumPages, this.pagination.currentPage, this.pagination.numPages);
	};

	/**
	 * handles the change of the page size by the user and emits up tp
	 * the parent to reload data
	 *
	 * @param event - the event containing the new page size
	 */
	public setPageSizeChanged = (event: any): void => {
		this.paginationSettings.pageSize = parseInt(event.target.value, 10);
		this.pagination.currentPage = 1;
		this.setNumPages();
		this.setPageSizeEvent.emit(this.paginationSettings.pageSize);
	};

	/**
	 * sets the number of pages
	 */
	private setNumPages = (): void => {
		this.pagination.numPages = Math.ceil(this.pagination.totalItems / this.paginationSettings.pageSize);
	};

	/**
	 * returns the page buttons based on the current values
	 *
	 * @param size - the number of values
	 * @param current - the current page
	 * @param numPages - the nuymber of pages
	 * @returns the page buttons
	 */
	private getPageNumbers = (size: number, current: number, numPages: number): number[] => {
		let pages: number[] = [];
		let newPages: number[] = [];

		if (size > numPages) {
			pages = Array.from(Array(numPages).keys()).map((page) => ++page);
			newPages = pages;
		} else {
			pages = Array.from(Array(size).keys()).map((page) => ++page);

			let diff: number = 0;

			let start: number = current - (Number.isInteger(pages.length / 2) ? pages.length / 2 : pages.length / 2 - 0.5);

			if (start < 1) {
				diff = 1 - start;
				start = start + diff;
			}

			newPages = pages.map((page, index) => start + index);

			if (newPages[newPages.length - 1] > numPages) {
				diff = numPages - newPages[newPages.length - 1];
				newPages = newPages.map((page) => page + diff);
			}
		}

		return newPages;
	};
}

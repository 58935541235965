/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class VehicleEventsService {
	public closeVehicleEventsList: Observable<any>;

	private closeVehicleEventsListSource: Subject<any> = new Subject<any>();

	constructor() {
		this.closeVehicleEventsList = this.closeVehicleEventsListSource.asObservable();
	}

	/**
	 * Publish a close event list  event allowing subscribers to react.
	 *
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishCloseVehicleEventsList = (event: any): void => {
		this.closeVehicleEventsListSource.next(event);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class AgenciesApiService {
	private readonly agenciesUrl: string = '/agencies';
	private readonly agencyFeaturesUrl: string = '/agencies/features';
	private readonly boundaryUrl: string = '/boundary';

	constructor(private httpService: HttpService) {}

	/**
	 * load the agencies from the nextbus API
	 * @returns the agencies
	 */
	public loadAgencies = async (): Promise<any> => {
		try {
			return await this.httpService.get(this.agenciesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * load the agency features from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @returns the agency features
	 */
	public getAgencyFeatures = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.agencyFeaturesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * get a single agency from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency
	 */
	public getAgency = async (authorityId: string, agencyId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.agenciesUrl + '/' + agencyId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * get the agency boundary from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency boundary
	 */
	public getAgencyBoundary = async (authorityId: string, agencyId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.agenciesUrl + '/' + agencyId + this.boundaryUrl, {});
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import L, { PointExpression } from 'leaflet';

import { MapVehicleTrailService } from '../map-vehicle-trail.service';
import { MapLocationService } from '../map-location.service';

import { Markers, TrailedVehicle, TrailedVehiclePoint } from '../../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapVehiclesTrailMarkerService {
	private mapInstance: L.Map = null;
	private markers: Markers = {};

	constructor(
		private mapLocationService: MapLocationService,
		private mapVehicleTrailService: MapVehicleTrailService
	) {}

	/**
	 * initialize the vehicle trail marker service
	 *
	 * @param mapInstance - the current map instance
	 */
	public init = (mapInstance: L.Map): void => {
		this.mapInstance = mapInstance;

		this.markers = {};
	};

	/**
	 * handle the agency change and clear down any markers
	 */
	public setAgencyChange = (): void => {
		this.markers = {};
	};

	/**
	 * update the vehicle trails (remove and re-add)
	 */
	public updateVehicleTrail = async (): Promise<void> => {
		for (const key in this.markers) {
			this.markers[key].remove();
			delete this.markers[key];
		}

		const currentTrailedVehicle: TrailedVehicle = this.mapVehicleTrailService.getTrailedVehicle();

		currentTrailedVehicle.trailPoints.forEach((trailPoint: TrailedVehiclePoint, index: number) => {
			this.markers[index.toString()] = new L.Marker(new L.LatLng(trailPoint.lat, trailPoint.lng), {
				icon: this.getIcon(currentTrailedVehicle, trailPoint.updatedColor),
				rotationAngle: trailPoint.angle,
				opacity: trailPoint.opacity,
			}).addTo(this.mapInstance);
		});
	};

	/**
	 * refresh vehicle trails
	 */
	public refreshVehicleTrail = (): void => {
		for (const key in this.markers) {
			this.markers[key].remove();
			delete this.markers[key];
		}

		const vehicleTrailId: string = this.mapVehicleTrailService.getTrailedVehicleId();

		if (vehicleTrailId) {
			this.updateVehicleTrail();
		}
	};

	/**
	 * get the vehicle trail icon
	 *
	 * @param vehicle - the vehicle
	 * @param updatedColor - the updated color (with appropriate transparancy)
	 * @returns the vehicle trail icon
	 */
	private getIcon = (vehicle: TrailedVehicle, updatedColor: string): L.DivIcon => {
		const markerSize: number[] = this.getIconSize();

		return L.divIcon({
			className: 'nb-map-vehicle-trail-marker',
			html: this.getIconSvg(vehicle.vehicleId, markerSize, updatedColor, vehicle.vehicleColor.foreColor),
			iconAnchor: [Math.floor(markerSize[0] / 2), Math.floor(markerSize[1] / 2)],
			iconSize: markerSize as PointExpression,
		});
	};

	/**
	 * get the appropriate icon size - based on the zoom level
	 *
	 * @returns the icon size
	 */
	private getIconSize = (): number[] => {
		switch (this.mapLocationService.getLocationZoom()) {
			case 1:
				return [8, 16];
			case 2:
				return [8, 16];
			case 3:
				return [8, 16];
			case 4:
				return [8, 16];
			case 5:
				return [8, 16];
			case 6:
				return [8, 16];
			case 7:
				return [8, 16];
			case 8:
				return [8, 16];
			case 9:
				return [8, 16];
			case 10:
				return [8, 16];
			case 11:
				return [8, 16];
			case 12:
				return [8, 16];
			case 13:
				return [8, 16];
			case 14:
				return [15, 30];
			case 15:
				return [15, 30];
			case 16:
				return [15, 30];
			case 17:
				return [15, 30];
			case 18:
				return [20, 40];
			default:
				return [20, 40];
		}
	};

	/**
	 * get the vehicle icon html
	 *
	 * @param vehicleId - the vehicle id
	 * @param markerSize - the vehicle (icon) marker size
	 * @param vehicleColor - the vehicle color
	 * @param vehicleTextColor - the vehicle text/alternative colour
	 * @returns the vehicle trail icon html
	 */
	private getIconSvg = (vehicleId: string, markerSize: number[], vehicleColor: string, vehicleTextColor: string): string => {
		const classIdent: number = Math.floor(Math.random() * 1000);

		const svgContent: string =
			' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.94 7.81"><defs>' +
			'<style>.cls-vehicle-trail-' +
			vehicleId +
			'-' +
			classIdent +
			'-1{fill:' +
			vehicleColor +
			';}' +
			'.cls-vehicle-trail-' +
			vehicleId +
			'-2{fill:' +
			vehicleTextColor +
			';}</style></defs>' +
			'<title>vehicle-trail-icon' +
			'</title><g id="Layer_' +
			vehicleId +
			'2" ' +
			'data-name="Layer _vehicle_trail_' +
			vehicleId +
			'_2"><g id="Layer_' +
			vehicleId +
			'_1-2" ' +
			'data-name="Layer _' +
			vehicleId +
			'_1"><rect ' +
			'class="cls-vehicle-trail-' +
			vehicleId +
			'-' +
			classIdent +
			'-1" x="0.52" y="0.52" width="11.89" ' +
			'height="6.77"/><path class="cls-vehicle-trail-' +
			vehicleId +
			'-2" ' +
			'd="M12.94,7.81H0V0H12.94ZM1,6.77H11.89V1H1Z"';

		return (
			'<div><svg class="nb-map-vehicle-trail-marker" ' +
			svgContent +
			' height="' +
			markerSize[1] +
			'" width="' +
			markerSize[0] +
			'"/></g></g></svg></div>'
		);
	};
}

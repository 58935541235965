/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgencyDetail } from '../../../../../support-features/agencies/types/api-types';
import { ViewPanelSource } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'agency-admin-view-details',
	templateUrl: './agency-admin-view-details.component.html',
	styleUrls: ['./agency-admin-view-details.component.scss'],
})
export class AgencyAdminViewDetailsComponent extends TranslateBaseComponent implements OnInit {
	@Input() agencyDetail: AgencyDetail = null;

	public detailViewTableOptions: ViewPanelSource;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the agency details view - first tab on the agency details
	 */
	public async ngOnInit(): Promise<void> {
		this.detailViewTableOptions = {
			title: 'T_AGENCY.AGENCY_DETAILS',
			rowDefs: [
				{
					displayName: 'T_CORE.AGENCY_NAME',
					type: 'name',
					name: 'agency_name',
				},
				{
					displayName: 'T_AGENCY.AGENCY_CODE',
					name: 'agency_id',
				},
				{
					displayName: 'T_AGENCY.STATUS',
					name: 'agency_status_id',
					convert: (value): string => this.getTranslation('T_AGENCY.AGENCY_STATUS.' + value),
				},
				{
					displayName: 'T_AGENCY.TYPE',
					name: 'agency_type',
					convert: this.convertAgencyType,
				},
				{
					displayName: 'T_AGENCY.AUTHORITY_ID',
					name: 'authority_id',
					hidden: false,
				},
				{
					displayName: 'T_AGENCY.AGENCY_THUMBNAIL_IMAGE',
					type: 'image',
					name: 'thumbnail_image_id',
					hidden: false,
				},
				{
					displayName: 'T_AGENCY.AGENCY_LOGO_IMAGE',
					type: 'image',
					name: 'image_logo_id',
					hidden: false,
				},
				{
					displayName: 'T_AGENCY.AGENCY_WEBSITE_URL',
					name: 'website_url',
					hidden: false,
				},
				{
					displayName: 'T_AGENCY.AGENCY_SCHEDULE_URL',
					name: 'schedule_url',
					hidden: false,
				},
				{
					displayName: 'T_AGENCY.AGENCY_SMS_KEYWORD',
					name: 'sms_keyword',
					hidden: false,
				},
			],
		};
	}

	/**
	 * converts the agency type value
	 *
	 * @param value - the agency type value
	 * @returns the formatted agency type value
	 */
	private convertAgencyType = (value: string): string => {
		const text: string = value.toLowerCase() === 'customer' ? 'T_AGENCY.TYPES.CUSTOMER' : 'T_AGENCY.TYPES.DATASET';

		return this.getTranslation(text);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { DepotsDataService } from '../services/depots-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { Depots } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'depots-view',
	templateUrl: './depots-view.component.html',
	styleUrls: ['./depots-view.component.scss'],
})
export class DepotsViewComponent extends TranslateBaseComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;

	public depots: Depots = [];
	public searchText: string = '';

	constructor(
		private depotsDataService: DepotsDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handles the depot view initialization - requests the list of depots
	 */
	public async ngOnInit(): Promise<void> {
		await this.getDepots();
	}

	/**
	 * handles the user search click and retrieves the depots after updating the filter
	 *
	 * @param searchText - the search text to filter
	 */
	public search = (searchText: string): void => {
		this.searchText = searchText;

		this.getDepots();
	};

	/**
	 * requests the depots from the data layer
	 */
	private getDepots = async (): Promise<void> => {
		const result: ResultContent = await this.depotsDataService.getDepots(this.authorityId, this.agencyId, this.searchText);

		if (result.success) {
			this.depots = result.resultData;
		}
	};
}

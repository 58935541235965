<div class="search-container">
	<div class="search-input-container">
		<input
			[(ngModel)]="filter"
			type="text"
			class="form-control"
			title="Filter"
			placeholder=""
			[typeahead]="typeAheadSearchSuggestions"
			[typeaheadWaitMs]="requestSuggestionsOverHttp ? 300 : 0"
			[typeaheadIsFirstItemActive]="false"
			(typeaheadOnSelect)="performSearch()"
			(keyup.enter)="performSearch()"
			data-test="search.input" />
	</div>
	<div *ngIf="filter" class="clear-icon-container">
		<div (click)="clear()" class="tooltip-arrow-top">
			<i class="nb-icons nb-clearfield clear-icon-tooltip" data-test="search.clear">
				<span class="tooltiptext">{{ getTranslation('T_CORE.CLEAR_SEARCH') }}</span>
			</i>
		</div>
	</div>
	<div class="search-icon-container">
		<div (click)="performSearch()" class="form-control tooltip-arrow-top" data-test="search.perform">
			<i class="nb-icons nb-search search-icon-tooltip">
				<span class="tooltiptext">{{ getTranslation('T_CORE.SEARCH') }}</span>
			</i>
		</div>
	</div>
</div>

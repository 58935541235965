<div class="dashboard-modal-backdrop"></div>
<div class="dashboard-modal-container">
	<div class="modal-dialog">
		<div class="modal-content" [@modalSlideDown]="animationState">
			<div class="modal-header">
				<button type="button" class="close" (click)="close()">
					<i class="ng-icon ng-close"></i>
				</button>
				<h4 class="dashboard-modal-title" data-test="page.title">{{ 'T_DASH.DB_EDIT_WIDGET' | translate }} - {{ widgetTitle }}</h4>
			</div>
			<div class="modal-body">
				<ng-template widgetEditModalHost></ng-template>
			</div>
			<div class="dashboard-modal-footer">
				<button type="button" (click)="close()" class="btn btn-secondary">
					{{ 'T_DASH.DB_CANCEL' | translate }}
				</button>
				<button type="button" [disabled]="!formValid" (click)="save()" class="btn btn-primary">
					{{ 'T_DASH.DB_SAVE' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid navigable-report">
				<table>
					<thead>
						<tr>
							<th
								[ngClass]="sortDetails.field === 'route_long_name' ? sortClass : null"
								(click)="sortList('route_long_name')"
								class="ellipsis">
								{{ 'T_CORE.ROUTE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'trip_headsign' ? sortClass : null"
								(click)="sortList('trip_headsign')"
								class="ellipsis text-align-right">
								{{ 'T_CORE.DIRECTION' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'avg' ? sortClass : null"
								(click)="sortList('avg')"
								class="mw-col ellipsis text-align-right">
								{{ 'T_CORE.AVG' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'min' ? sortClass : null"
								(click)="sortList('min')"
								class="mw-col ellipsis text-align-right">
								{{ 'T_CORE.MIN' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'max' ? sortClass : null"
								(click)="sortList('max')"
								class="mw-col ellipsis text-align-right">
								{{ 'T_CORE.MAX' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'stddev' ? sortClass : null"
								(click)="sortList('stddev')"
								class="mw-col ellipsis text-align-right">
								{{ 'T_CORE.STDEV' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'count' ? sortClass : null"
								(click)="sortList('count')"
								class="mw-col ellipsis text-align-right">
								{{ 'T_CORE.COUNT' | translate }}
							</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr *ngFor="let reportItem of listData" (click)="generateReport(reportItem)">
							<td class="ellipsis">
								<route-pill
									class="report-viewer-grid-route-pill"
									[route]="determineRoutePillData(reportItem)"
									[useFullWidth]="true"></route-pill>
								<span [ngStyle]="{ 'vertical-align': 'top' }">{{ reportItem.route_long_name }}</span>
							</td>
							<td class="ellipsis text-align-right">{{ reportItem.trip_headsign }}</td>
							<td class="ellipsis text-align-right">{{ reportItem.avg }}</td>
							<td class="ellipsis text-align-right">{{ reportItem.min }}</td>
							<td class="ellipsis text-align-right">{{ reportItem.max }}</td>
							<td class="ellipsis text-align-right">{{ reportItem.stddev }}</td>
							<td class="ellipsis text-align-right">{{ reportItem.count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

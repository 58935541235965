<div class="prediction-modal-container">
	<div class="inner">
		<div *ngIf="initialised">
			<div class="modal-body" [ngClass]="{ 'disable-modal': confirmSelectionMode }" [@fadeInOutAnimation]="mainModalAnimationFadeState">
				<div class="route-filter-modal-container">
					<div *ngIf="predictionMode === predictionModeType.Create">
						<mat-radio-group
							[(ngModel)]="allOrSpecific"
							(change)="predictionModeChanged()"
							aria-labelledby="route-selection-group-label"
							class="route-selection-group">
							<mat-radio-button class="route-selection-button" [value]="specific">
								<span class="route-selection-text">{{ 'T_PREDICTION.PREDICTION_SPECIFIC_ROUTES' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button class="route-selection-button" [value]="all">
								<span class="route-selection-text">{{ 'T_PREDICTION.PREDICTION_ALL_ROUTES' | translate }}</span>
							</mat-radio-button>
						</mat-radio-group>
						<hr class="route-selection-container-divide" />
					</div>
					<ng-container *ngIf="predictionMode === predictionModeType.Create">
						<div *ngIf="allOrSpecific === all">
							<label class="route-label">{{ 'T_CORE.ROUTE' | translate }}</label>
							<label class="selected-text">{{ 'T_PREDICTION.PREDICTION_ALL_ROUTES' | translate }}</label>
						</div>
						<div *ngIf="allOrSpecific === specific" class="route-filter-modal">
							<label for="route" class="route-label">{{ 'T_CORE.ROUTE' | translate }}</label>
							<select name="route" id="route" (change)="routeChanged($event.target)" class="form-control">
								<option *ngFor="let route of routes" value="{{ route.id }}">
									{{ route.title }}
								</option>
							</select>
						</div>
					</ng-container>
					<ng-container *ngIf="predictionMode === predictionModeType.Edit">
						<label class="route-label">{{ 'T_CORE.ROUTE' | translate }}</label>
						<label class="selected-text">{{ selectedRouteText }}</label>
					</ng-container>
				</div>
				<!-- hide this at the request of Neal Pankin due to a back-end issue with predictions. It may be made visible at a later date. -->
				<div [hidden]="true" class="feed-type-filter-container">
					<ng-container *ngIf="predictionMode === predictionModeType.Create">
						<div *ngIf="allOrSpecific === all">
							<label class="selected-title">{{ 'T_PREDICTION.FEED' | translate }}:</label>
							<label class="selected-text">{{ 'T_PREDICTION.ALL_FEEDS' | translate }}</label>
							<hr class="feed-container-divide-create" />
						</div>
						<div *ngIf="allOrSpecific === specific">
							<label for="feed">{{ 'T_PREDICTION.FEED' | translate }}</label>
							<select name="feed" id="feed" (change)="feedChanged($event.target)" class="form-control">
								<option *ngFor="let feed of feedTypes" value="{{ feed.value }}">
									{{ feed.description }}
								</option>
							</select>
						</div>
					</ng-container>
					<ng-container *ngIf="predictionMode === predictionModeType.Edit">
						<label class="selected-title">{{ 'T_PREDICTION.FEED' | translate }}:</label>
						<label class="selected-text">{{ selectedFeedText }}</label>
						<hr class="feed-container-divide-edit" />
					</ng-container>
				</div>
				<table *ngIf="allOrSpecific === specific" class="route-direction-container">
					<tbody>
						<tr>
							<th *ngFor="let destination of routeDestinations">
								<span class="direction-title">{{ destination.name }}</span>
							</th>
						</tr>
						<tr class="direction-row">
							<td *ngFor="let routeDesination of routeDestinations; let routeDestinationIndex = index">
								<mat-selection-list
									#destinationStops
									[disabled]="predictionMode === predictionModeType.Edit"
									[ngClass]="predictionMode === predictionModeType.Edit ? 'stop-selection-list-edit' : 'stop-selection-list-create'"
									class="stop-selection-list">
									<ng-container *ngIf="predictionMode === predictionModeType.Create">
										<mat-list-option
											checkboxPosition="before"
											[value]="{ id: selectAllId }"
											(click)="selectAll(routeDestinationIndex)"
											[selected]="routeDesination.allStopsSelected">
											<span class="select-all">{{ 'T_PREDICTION.SELECT_ALL' | translate }}</span>
										</mat-list-option>
										<hr class="select-all-divide" />
									</ng-container>
									<mat-list-option
										#option
										*ngFor="let stop of routeDesination.stops"
										checkboxPosition="before"
										[ngClass]="stop.style"
										(click)="handleStopClick(stop.id)"
										[selected]="stop.selected">
										<span class="stop-name">{{ stop.code }} - {{ stop.name }}</span>
									</mat-list-option>
								</mat-selection-list>
							</td>
						</tr>
					</tbody>
				</table>
				<br />
				<table class="prediction-selection-container">
					<tbody>
						<tr>
							<td>
								<label id="prediction-selection-group-label" class="prediction-selection-title">{{
									'T_CORE.PREDICTIONS' | translate
								}}</label>
								<mat-radio-group
									[(ngModel)]="predictionRadioSetting"
									(change)="predictionRadioSettingChanged()"
									aria-labelledby="prediction-selection-group-label"
									class="prediction-selection-group">
									<mat-radio-button class="prediction-selection-button" *ngFor="let setting of predictionSettings" [value]="setting.key">
										<span class="prediction-selection-text">{{ setting.text }}</span>
									</mat-radio-button>
								</mat-radio-group>
							</td>
							<!-- hide this at the request of Neal Pankin due to a back-end issue with predictions. It may be made visible at a later date.
							     Should this happen, then the git history should be checked so that the changes to the confirmation
									 translated text can be reverted -->
							<td [hidden]="true">
								<label id="prediction-selection-group-label" class="prediction-selection-title">{{
									'T_PREDICTION.SCHEDULED_TIMES' | translate
								}}</label>
								<mat-radio-group
									[(ngModel)]="scheduledTimesRadioSetting"
									aria-labelledby="prediction-selection-group-label"
									class="prediction-selection-group">
									<mat-radio-button class="prediction-selection-button" *ngFor="let setting of predictionSettings" [value]="setting.key">
										<span class="prediction-selection-text">{{ setting.text }}</span>
									</mat-radio-button>
								</mat-radio-group>
							</td>
						</tr>

						<tr></tr>
					</tbody>
				</table>
				<form *ngIf="dateTimeFormGroup" [formGroup]="dateTimeFormGroup">
					<table class="prediction-selection-container">
						<tbody>
							<tr>
								<td>
									<label class="date-from-label">{{ 'T_PREDICTION.FROM' | translate }}:</label>
								</td>
							</tr>
							<tr>
								<td>
									<mat-form-field>
										<mat-label>{{ 'T_CORE.CHOOSE_DATE' | translate }}</mat-label>
										<input
											matInput
											formControlName="startDate"
											[matDatepicker]="picker"
											(input)="onChangeStartDate()"
											(dateChange)="onChangeStartDate()"
											[min]="minDate" />
										<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-datepicker #picker></mat-datepicker>
									</mat-form-field>
								</td>
								<td>
									<time-selector
										[initialTime]="dateTimeFormGroup.controls.startTime.value"
										(timeValid)="setTimeValid($event, 'startTime')"
										(timeUpdated)="setUpdatedTime($event, 'startTime')">
									</time-selector>
								</td>
							</tr>
							<br />
							<tr>
								<td>
									<label class="date-to-label">{{ 'T_PREDICTION.TO' | translate }}:</label>
								</td>
							</tr>
							<tr>
								<td>
									<mat-form-field>
										<mat-label>{{ 'T_CORE.CHOOSE_DATE' | translate }}</mat-label>
										<input
											matInput
											formControlName="endDate"
											[matDatepicker]="endDatePicker"
											(input)="onChangeEndDate()"
											(dateChange)="onChangeEndDate()"
											[min]="minDate" />
										<mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
										<mat-datepicker #endDatePicker></mat-datepicker>
									</mat-form-field>
								</td>
								<td>
									<time-selector
										[initialTime]="dateTimeFormGroup.controls.endTime.value"
										(timeValid)="setTimeValid($event, 'endTime')"
										(timeUpdated)="setUpdatedTime($event, 'endTime')">
									</time-selector>
								</td>
							</tr>
						</tbody>
					</table>
				</form>
				<span *ngIf="hasDateError" class="date-error">{{ dateErrorText }}</span>
			</div>
			<div class="modal-footer prediction-modal-footer">
				<div *ngIf="!confirmSelectionMode">
					<ng-container *ngIf="predictionMode === predictionModeType.Create">
						<button
							type="button"
							*ngIf="predictionRadioSetting === disabledKey"
							(click)="disablePrediction(false)"
							class="btn btn-secondary btn-update"
							[disabled]="!enableSave">
							{{ 'T_PREDICTION.DISABLE' | translate }}
						</button>
						<button
							type="button"
							*ngIf="predictionRadioSetting !== disabledKey"
							(click)="disablePrediction(false)"
							class="btn btn-secondary btn-update"
							[disabled]="!enableSave">
							{{ 'T_PREDICTION.ENABLE' | translate }}
						</button>
					</ng-container>
					<ng-container *ngIf="predictionMode === predictionModeType.Edit">
						<button type="button" (click)="disablePrediction(true)" class="btn btn-secondary btn-update">
							{{ 'T_CORE.DELETE' | translate }}
						</button>
						<button type="button" (click)="disablePrediction(false)" class="btn btn-secondary btn-update" [disabled]="!enableSave">
							{{ 'T_PREDICTION.UPDATE' | translate }}
						</button>
					</ng-container>
					<button type="button" (click)="closeClick()" class="btn btn-secondary btn-close">
						{{ 'T_PREDICTION.MODAL_CLOSE' | translate }}
					</button>
				</div>
				<div *ngIf="confirmSelectionMode">
					<div class="confirm-container" [@fadeInOutAnimation]="confirmFooterAnimationState">
						<p class="confirm-prompt">{{ 'T_PREDICTION.CONFIRM_SELECTION' | translate }}</p>
						<p
							*ngIf="!isDelete && predictionRadioSetting === enabledKey && scheduledTimesRadioSetting === enabledKey"
							class="confirm-prompt">
							{{ 'T_PREDICTION.CONFIRM_PREDICTIONS_ENABLED_SCHEDULES_ENABLED' | translate }}
						</p>
						<p
							*ngIf="!isDelete && predictionRadioSetting === enabledKey && scheduledTimesRadioSetting === disabledKey"
							class="confirm-prompt">
							{{ 'T_PREDICTION.CONFIRM_PREDICTIONS_ENABLED_SCHEDULES_DISABLED' | translate }}
						</p>
						<p
							*ngIf="!isDelete && predictionRadioSetting === disabledKey && scheduledTimesRadioSetting === enabledKey"
							class="confirm-prompt">
							{{ 'T_PREDICTION.CONFIRM_PREDICTIONS_DISABLED_SCHEDULES_ENABLED' | translate }}
						</p>
						<p
							*ngIf="!isDelete && predictionRadioSetting === disabledKey && scheduledTimesRadioSetting === disabledKey"
							class="confirm-prompt">
							{{ 'T_PREDICTION.CONFIRM_PREDICTIONS_DISABLED_SCHEDULES_DISABLED' | translate }}
						</p>
						<p *ngIf="isDelete" class="confirm-prompt">{{ 'T_PREDICTION.CONFIRM_DELETE' | translate }}</p>
						<div>
							<span class="confirm-title">{{ 'T_CORE.ROUTE' | translate }}:</span><span class="confirm-text">{{ selectedRouteText }}</span
							><br />
						</div>
						<div>
							<span class="confirm-title">{{ 'T_PREDICTION.FEED' | translate }}:</span
							><span class="confirm-text">{{ selectedFeedText }}</span
							><br />
						</div>
						<div *ngIf="!isDelete">
							<span class="confirm-title">{{ 'T_PREDICTION.FROM' | translate }}:</span><span class="confirm-text">{{ startDateText }}</span
							><br />
							<span class="confirm-title">{{ 'T_PREDICTION.TO' | translate }}:</span><span class="confirm-text">{{ endDateText }}</span
							><br />
						</div>
						<br />
					</div>
					<button type="button" *ngIf="!isDelete" (click)="save()" class="btn btn-secondary btn-save">
						{{ 'T_CORE.SAVE' | translate }}
					</button>
					<button type="button" *ngIf="isDelete" (click)="save()" class="btn btn-secondary btn-save">
						{{ 'T_CORE.DELETE' | translate }}
					</button>
					<button type="button" (click)="closeClick()" class="btn btn-secondary btn-close">{{ 'T_CORE.CANCEL' | translate }}</button>
				</div>
			</div>
		</div>
	</div>
</div>

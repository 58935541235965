/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnInit } from '@angular/core';

import { TimeHelpers } from '@cubicNx/libs/utils';

@Component({
	selector: 'block-difference-display',
	templateUrl: './block-difference-display.component.html',
	styleUrls: ['./block-difference-display.component.scss'],
})
export class BlockDifferenceDisplayComponent implements OnInit {
	@Input() blockDifference: string = null;

	public style: { color: string };
	public formattedBlockDifference: string;

	/**
	 * performs initialization tasks for the blocks difference display components
	 *
	 * creates formatted text value and correct styling for template
	 */
	public ngOnInit(): void {
		// Is it a number
		if (this.blockDifference !== '—') {
			const difference: number = parseInt(this.blockDifference, 10);

			if (difference > 0) {
				this.style = {
					color: 'red',
				};
			}

			this.formattedBlockDifference = TimeHelpers.formatTimeDifference(difference);
		} else {
			this.formattedBlockDifference = this.blockDifference;
		}
	}
}

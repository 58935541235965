<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".-->

<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

<panel-header [showBackButton]="true" [heading]="'T_CORE.AGENCIES' | translate"> </panel-header>
<div class="header-container">
	<div class="float-right">
		<search-filter [filterValue]="searchText" (search)="search($event)"></search-filter>
	</div>
</div>
<div class="data-list-container" *ngIf="initailized">
	<data-table
		data-test="agencies.list.table"
		[listName]="listName"
		[columns]="columns"
		[rowData]="agenciesList"
		[pagingEnabled]="true"
		[totalRows]="totalRows"
		[defaultSortings]="defaultSortings"
		[pageInfo]="pageInfo"
		[enableCheckRowSelection]="false"
		[enabledMultiCheckRowSelection]="false"
		[enableMenuSelection]="false"
		[enableFooter]="true"
		[loadingIndicator]="listLoadingIndicator"
		(rowClick)="onSelect($event)"
		(requestData)="handleDataRequest($event)">
	</data-table>
</div>

<form [formGroup]="formGroup" *ngIf="initialized">
	<div *ngIf="agencies?.length > 1">
		<div id="agency" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
			<select
				name="agencyId"
				id="agencyId"
				class="form-control"
				formControlName="agencyId"
				(change)="handleAgencyChanged()"
				data-test="agency.selection">
				<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
					{{ agency.agency_name }}
				</option>
			</select>
			<input-validation [control]="formGroup.controls.agencyId"></input-validation>
		</div>
	</div>

	<div class="form-group settings-container">
		<div class="form-group col-md-6 no-padding">
			<div>
				<label>{{ 'T_DASH.EVENTS.CATEGORY' | translate }}</label>
			</div>
			<div>
				<div id="categoryFilterButtons" class="btn-group" data-toggle="buttons">
					<button
						id="category-filter-alarm-label"
						data-test="category.alarm.btn"
						[ngClass]="settings.categoryFilterOptions.alarm ? 'btn btn-primary' : 'btn btn-default'"
						class="btn"
						(click)="toggleCategoryButton('alarm')">
						{{ 'T_DASH.EVENTS.ALARM' | translate }}
					</button>
					<button
						id="category-filter-alert-label"
						data-test="category.alert.btn"
						[ngClass]="settings.categoryFilterOptions.alert ? 'btn btn-primary' : 'btn btn-default'"
						class="btn"
						(click)="toggleCategoryButton('alert')">
						{{ 'T_DASH.EVENTS.ALERT' | translate }}
					</button>
					<button
						id="category-filter-event-label"
						data-test="category.event.btn"
						[ngClass]="settings.categoryFilterOptions.event ? 'btn btn-primary' : 'btn btn-default'"
						class="btn"
						(click)="toggleCategoryButton('event')">
						{{ 'T_DASH.EVENTS.EVENT' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div class="form-group col-md-6 no-padding">
			<div>
				<label>{{ 'T_DASH.EVENTS.SORT_ORDER' | translate }}</label>
			</div>
			<div>
				<div id="sortBy" class="btn-group" data-toggle="buttons">
					<button
						[ngClass]="settings.sortBy === 'Category' ? 'btn btn-primary' : 'btn btn-default'"
						data-test="sort.category.btn"
						class="btn"
						(click)="setSortOrder('Category')">
						{{ 'T_DASH.EVENTS.CATEGORY' | translate }}
					</button>
					<button
						[ngClass]="settings.sortBy === 'Date' ? 'btn btn-primary' : 'btn btn-default'"
						data-test="sort.date.btn"
						class="btn"
						(click)="setSortOrder('Date')">
						{{ 'T_DASH.EVENTS.DATE' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group available-types-container">
		<div class="col-md-5 nb-padding-left-0">
			<div>
				<label>{{ 'T_DASH.EVENTS.AVAILABLE_EVENTS' | translate }}</label>
			</div>
			<div class="list-group nb-picker" id="availableTypes" data-test="available.list">
				<a
					class="list-group-item nb-picker-item"
					*ngFor="let eventType of getAvailableTypes()"
					(click)="setActive(eventType, false)"
					[ngClass]="{ active: eventType.active, 'nb-group-type-header': eventType.isGroupHeader }">
					{{ eventType.type }}
				</a>
			</div>
		</div>
		<div class="col-md-2 no-padding">
			<button class="btn btn-default add-picked-type" data-test="include.btn" (click)="addPickedType()">
				<i class="ng-icon ng-last include-icon"></i>
				{{ 'T_DASH.EVENTS.INCLUDE' | translate }}
			</button>
			<button class="btn btn-default remove-picked-type" data-test="exclude.btn" (click)="removePickedType()">
				<i class="ng-icon ng-first include-icon"></i>
				{{ 'T_DASH.EVENTS.EXCLUDE' | translate }}
			</button>
		</div>
		<div class="col-md-5 nb-padding-right-0">
			<div>
				<label>{{ 'T_DASH.EVENTS.INCLUDED_EVENTS' | translate }}</label>
			</div>
			<div class="list-group nb-picker" id="pickedTypes" data-test="included.list">
				<a
					class="list-group-item nb-picker-item"
					*ngFor="let eventType of getPickedTypes()"
					(click)="setActive(eventType, true)"
					[ngClass]="{ active: eventType.active, 'nb-group-type-header': eventType.isGroupHeader }">
					{{ eventType.type }}
				</a>
			</div>
		</div>
	</div>

	<div class="form-group no-padding vehicle-filter-container">
		<div class="nb-form-control-vertical col-md-12 no-padding">
			<div>
				<label>{{ 'T_DASH.EVENTS.VEHICLE_FILTER' | translate }}</label>
			</div>
			<div class="nb-radio">
				<label>
					<input
						id="all_vehicles"
						data-test="all.input"
						type="radio"
						name="vehicleFilterSelection"
						formControlName="vehicleFilterSelection"
						(click)="updateVehicleSelectionFilter('all')"
						value="all" />
					<div class="radio-label">{{ 'T_DASH.EVENTS.ALL_VEHICLES' | translate }}</div>
				</label>
				<br />

				<label>
					<input
						id="specific_routes"
						data-test="routes.input"
						type="radio"
						name="vehicleFilterSelection"
						formControlName="vehicleFilterSelection"
						(click)="updateVehicleSelectionFilter('routes')"
						value="routes" />
					<div class="radio-label">{{ 'T_DASH.EVENTS.SPECIFIC_ROUTES' | translate }}</div>
				</label>
				<br />

				<div *ngIf="settings.vehicleFilterSelection?.value === 'routes'" id="routes" class="form-group">
					<div>
						<multi-select
							[settings]="routeSettings"
							[items]="routes"
							[selected]="selectedRoutes"
							data-test="routes.select"
							(itemClickedEvent)="updateRouteItemClicked()"
							(removeItemClicked)="updateRouteItemClicked()">
						</multi-select>
					</div>
					<input-validation [control]="formGroup.controls.routes"></input-validation>
				</div>

				<label>
					<input
						id="specific_vehicles"
						data-test="vehicles.input"
						type="radio"
						name="vehicleFilterSelection"
						formControlName="vehicleFilterSelection"
						(click)="updateVehicleSelectionFilter('vehicles')"
						value="vehicles" />
					<div class="radio-label">{{ 'T_DASH.EVENTS.SPECIFIC_VEHICLES' | translate }}</div>
				</label>
				<br />

				<div *ngIf="settings.vehicleFilterSelection?.value === 'vehicles'" id="vehicles" class="form-group">
					<div>
						<multi-select
							[settings]="vehicleSettings"
							[items]="vehicles"
							data-test="vehicles.select"
							[selected]="selectedVehicles"
							(itemClickedEvent)="updateVehicleItemClicked()"
							(removeItemClicked)="updateVehicleItemClicked()">
						</multi-select>
					</div>
					<input-validation [control]="formGroup.controls.vehicles"></input-validation>
				</div>

				<label>
					<input
						id="specific_depots"
						data-test="depots.input"
						type="radio"
						name="vehicleFilterSelection"
						formControlName="vehicleFilterSelection"
						(click)="updateVehicleSelectionFilter('depots')"
						value="depots" />
					<div class="radio-label">{{ 'T_DASH.EVENTS.SPECIFIC_DEPOTS' | translate }}</div>
				</label>

				<div *ngIf="settings.vehicleFilterSelection?.value === 'depots'" id="depots" class="form-group">
					<div>
						<multi-select
							[settings]="depotSettings"
							[items]="depots"
							data-test="depots.select"
							[selected]="selectedDepots"
							(itemClickedEvent)="updateDepotItemClicked()"
							(removeItemClicked)="updateDepotItemClicked()">
						</multi-select>
					</div>
					<input-validation [control]="formGroup.controls.depots"></input-validation>
				</div>
			</div>
		</div>
	</div>
</form>

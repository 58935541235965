/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AdherenceColorService {
	/**
	 * creates a template styling for an early adherence value
	 *
	 * @param perc - the percentage value
	 * @returns styling for the early adherence value
	 */
	public getEarlyStyle = (perc: number): any => {
		let color: string = 'transparent';

		if (perc > 1 && perc <= 35) {
			const opacity: number = 0.09 + perc / 50;

			color = 'rgba(255, 144, 144, ' + opacity + ')';
		} else if (perc > 35) {
			color = 'rgba(255, 144, 144, 1';
		}

		return {
			background: color,
		};
	};

	/**
	 * creates a template styling for a late adherence value
	 *
	 * @param perc - the percentage value
	 * @returns styling for the late adherence value
	 */
	public getLateStyle = (perc: number): any => {
		let color: string = 'transparent';

		if (perc > 1 && perc <= 35) {
			const opacity: number = 0.09 + perc / 50;

			color = 'rgba(255, 239, 112, ' + opacity + ')';
		} else if (perc > 35) {
			color = 'rgba(255, 239, 112, 1';
		}

		return {
			background: color,
		};
	};
}

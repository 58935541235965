<nav class="navbar-fixed-top header-default no-print nb-navbar nav-bar-pos-size" role="navigation">
	<div class="nav-bar-dimensions"></div>
	<div class="pull-left logo-location" id="headerLogo">
		<img src="/assets/img/umologo_light.svg" class="nb-main- logo-size" alt="Umo IQ Logo" />
	</div>
	<sidebar-minimalizer></sidebar-minimalizer>
	<ul class="nav navbar-top-links navbar-top-links-dimensions">
		<li class="dropdown nb-account-dropdown help-menu-position">
			<a class="dropdown-toggle nb-account-dropdown-title" data-toggle="dropdown">
				<div class="menu-text-holder-position">
					<i class="material-icons">help</i>
					<span class="hidden-xs" data-test="header.help">{{ 'T_CORE.HELP' | translate }}</span>
					<i class="nb-icons nb-dropdown help-text-position"></i>
				</div>
			</a>
			<ul class="dropdown-menu nb-account-dropdown-menu">
				<li>
					<a href="/assets/img/Umo IQ Portal User Guide.pdf" download>
						<i class="nb-icons nb-information-solid user-guide-text-position"></i>
						<span class="">{{ 'T_CORE.USER_GUIDE' | translate }}</span>
					</a>
				</li>
				<li>
					<a (click)="popUpServiceNow()">
						<i class="material-icons">live_help</i>
						<span class="">{{ 'T_CORE.SERVICE_NOW' | translate }}</span>
					</a>
				</li>
			</ul>
		</li>
		<li class="dropdown nb-account-dropdown">
			<a class="dropdown-toggle nb-account-dropdown-title" data-toggle="dropdown">
				<div class="menu-text-holder-position">
					<i
						class="material-icons"
						[attr.title]="warnPasswordExpiring ? getTranslation('T_USER.PASSWORD_EXPIRES_SOON') : null"
						data-test="account_circle">
						account_circle
						<span *ngIf="warnPasswordExpiring" class="badge bg-danger nb-header-user-superscript">&nbsp;</span>
					</i>
					<span class="hidden-xs" data-test="header.username">{{ userFullName }}</span>
					<i class="nb-icons nb-dropdown" data-test="header.username.expand"></i>
				</div>
			</a>
			<ul class="dropdown-menu nb-account-dropdown-menu">
				<li>
					<a (click)="navigate('/admintools/users/profile', currentUserId)" data-test="profile">
						<i class="material-icons">assignment_ind</i>
						<span class=""> {{ 'T_CORE.PROFILE' | translate }} </span>
					</a>
				</li>
				<li>
					<a (click)="logOut()" data-test="logout">
						<i class="nb-icons nb-logout logout-text-position"></i>
						<span class=""> {{ 'T_CORE.LOGOUT' | translate }} </span>
					</a>
				</li>
			</ul>
		</li>
	</ul>
	<div class="nav navbar-top-links vehicle-events-holder">
		<vehicle-events></vehicle-events>
	</div>
</nav>

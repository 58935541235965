/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsConfigService } from '../../../services/reports-config.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { ReportsUtilService } from '../../../services/reports-util.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportConfiguration, ReportTemplateSummaryContent } from '../../../types/types';
import { ReportDateOptions, ReportRoute, ReportRoutes } from '../../../types/api-types';

import moment from 'moment';

@Component({
	selector: 'report-template-summary-content',
	templateUrl: './report-template-summary-content.component.html',
	styleUrls: ['./report-template-summary-content.component.scss'],
})
export class ReportTemplateSummaryContentComponent extends TranslateBaseComponent implements OnInit {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = { loaded: false };
	@Input() defaultTemplateId: string = null;
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;

	public reportConfig: ReportConfiguration = null;
	public startTimeOffset: string = null;
	public endTimeOffset: string = null;
	public initialized: boolean = false;

	private agencyTimezone: string = null;

	constructor(
		private reportsConfigService: ReportsConfigService,
		private reportsUtilService: ReportsUtilService,
		private agenciesDataService: AgenciesDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the report template summary component
	 *
	 * reads report configuration and sets up time zone information
	 */
	public ngOnInit(): void {
		// seen the backen get in to odd state where the template is empty
		if (this.templateSummaryContent.loaded && this.templateSummaryContent.template) {
			this.reportConfig = this.reportsConfigService.getReportConfiguration(this.defaultTemplateId);

			this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

			if (this.templateSummaryContent.template.date_options.calculated_start_date) {
				this.startTimeOffset = this.reportsUtilService.formatTimezoneOffset(
					moment.tz(this.templateSummaryContent.template.date_options.calculated_start_date, this.agencyTimezone).utcOffset()
				);
				this.endTimeOffset = this.reportsUtilService.formatTimezoneOffset(
					moment.tz(this.templateSummaryContent.template.date_options.calculated_end_date, this.agencyTimezone).utcOffset()
				);
			}

			this.initialized = true;
		}
	}

	/**
	 * retrieves the type of route name for each supplied route
	 *
	 * @param routes - the routes in the report summary
	 * @returns the type of route name within each supplied route - short route name, long route name or display route name
	 */
	public getRouteFieldNames = (routes: ReportRoutes): Array<string> => {
		const fieldNames: Array<string> = [];

		routes.forEach((route: ReportRoute) => {
			if (!route.route_display_name) {
				if (!route.route_short_name) {
					fieldNames.push('route_long_name');
				} else {
					fieldNames.push('route_short_name');
				}
			} else {
				fieldNames.push('route_display_name');
			}
		});

		return fieldNames;
	};

	/**
	 * determines whether effective time is enabled or not
	 *
	 * @returns true if effectibve time is enambed, false otherwise
	 */
	public effectiveTimeEnabled = (): boolean => {
		const key: string = this.templateSummaryContent.template.date_options.effective_date_range_type;

		const dateOption: any = this.templateSummaryContent.template.date_options[key as keyof ReportDateOptions];

		return dateOption.effective_time_enabled;
	};
}

import { Agency } from '../../../support-features/agencies/types/api-types';
import { Depots } from '../../../support-features/depots/types/api-types';
import { Routes } from '../../../support-features/routes/types/api-types';
import { VehicleSummaries } from '../../../support-features/vehicles/types/api-types';
import { VehicleEvent } from './api-types';

export interface EventDisplayType {
	id?: number; // only needed for non group header item
	type: string;
	selected: boolean;
	active: boolean;
	group: string;
	groupDisplay: string;
	isGroupHeader: boolean;
}
export type EventDisplayTypes = Array<EventDisplayType>;

export interface CategoryFilterOptions {
	alarm: boolean;
	alert: boolean;
	event: boolean;
}

/* used in vehicle events widget and header.
 note: the saved dashboard config expects the structure as below which is saved
 i.e with selectedDepots.depots so we cant simplify without addressing existing
 dashboard config for existing users
*/
export interface VehicleEventsSettings {
	refreshRateSec?: number;
	selectedAgency: Agency;
	categoryFilterOptions: CategoryFilterOptions;
	sortBy: string;
	includedEventTypeIds: number[];
	selectedVehicles: { vehicles: VehicleSummaries };
	selectedDepots: { depots: Depots };
	selectedRoutes: { routes: Routes };
	vehicleFilterSelection: { value: string };
}

/* used to persist in the user settings against the user
 annoying not quite the same object as above - something to work on perhaps
 but we must be careful so we don't break it for existing user settings
*/
export interface VehicleEventsUserSettings {
	categoryFilterOptions: CategoryFilterOptions;
	filteredAgencyForVehicleEvents: string;
	filteredAgencyDetails: Agency;
	sortBy: string;
	includedEventTypeIds: number[];
	vehicleFilter: string;
	selectedVehicles: VehicleSummaries;
	selectedRoutes: Routes;
	selectedDepots: Depots;
}

/* version with additional properties set by UI */
export interface VehicleEventNotification {
	vehicleEvent: VehicleEvent;
	timezone: string;
	createdAtUtc: number;
	expiryUtc: number;
	playAudio: boolean;
	lastPlayed: number;
}

export type VehicleEventNotifications = Array<VehicleEventNotification>;

export const defaultIncludedEventTypeIds: number[] = [12, 13, 9, 30];

export const defaultSort: string = 'Date';

export const defaultVehicleFilter: string = 'all';

export const defaultCategoryFilterOptions: CategoryFilterOptions = {
	alarm: true,
	alert: true,
	event: false,
};

export interface VehicleEventType {
	id: number;
	type: string;
	priority?: number;
	category?: string;
	group: string;
	audio?: string;
}

export type VehicleEventTypes = Array<VehicleEventType>;

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapModalService } from '../services/map-modal.service';
import { MapPollingService } from '../services/map-polling.service';
import { MapEventsService } from '../services/map-events.service';
import { MapVehiclesService } from '../services/map-vehicles.service';
import { MapOptionsService } from '../services/map-options.service';
import { MapReplayService } from '../services/map-replay.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReplayInitComplete, SelectedReplayTimeFrame } from './types/types';
import { SelectedAgency } from '../../../support-features/agencies/types/api-types';
import { MapModeType } from '../types/types';

import moment, { Moment } from 'moment';

@Component({
	selector: 'map-replay',
	templateUrl: './map-replay.component.html',
	styleUrls: ['./map-replay.component.scss'],
})
export class MapReplayComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild('replayControlBar') private replayControlBar: ElementRef;

	// Enums for html
	public mapModeType: typeof MapModeType = MapModeType;

	public readonly loadingText: string = 'Buffering';

	public replayBuffering: boolean = false;
	public replayPlaying: boolean = false;
	public timezoneDisplay: string = null;
	public infoDisplay: string = null;
	public progressPositionLabel: string = '';
	public startPositionLabel: string = '';
	public endPositionLabel: string = '';
	public progressPlayedPercStyle: { width: string } = null;
	public progressRemainingPercStyle: { width: string } = null;
	public progressPositionMarkerStyle: { left: string } = null;

	private readonly defaultTimeSpanSec: number = 2 * 60 * 60; // 2 hours
	private readonly barWidth: number = 402;
	private readonly refreshRateMs: number = 50;
	private readonly markerOffsetPx: number = 6;
	private readonly dateFormatShort: string = 'HH:mm:ss';
	private readonly dateFormatLong: string = 'MM/DD - HH:mm:ss';

	private playInterval: any = null;
	private timezone: string = null;
	private authorityId: string = null;
	private startTime: Moment = null;
	private endTime: Moment = null;
	private curMoment: Moment = null;
	private timeSpanSec: number = null;
	private playbackSpeed: number = 1;
	private timeRangeInitialized: boolean = false;
	private includePredictions: boolean = false;
	private replayRefreshView: boolean = false;
	private replayInitComplete$Subscription: Subscription = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private mapOptionsService: MapOptionsService,
		private mapReplayService: MapReplayService,
		private mapEventsService: MapEventsService,
		private mapModalService: MapModalService,
		private mapPollingService: MapPollingService,
		private mapVehiclesService: MapVehiclesService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initializes the component and open the time frame selection modal
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();

		this.initAgency();

		this.initTimeRange();

		this.initDisplayControls();

		await this.openTimeFrameSelectionModal();
	}

	/* Supports HTML */

	/**
	 * initializes the component and open the time frame selection modal
	 */
	public openTimeFrameSelectionModal = async (): Promise<void> => {
		this.mapModalService.replayTimeFrameSelect().subscribe((event: any) => {
			const selectedReplayTimeFrame: SelectedReplayTimeFrame = event;

			if (selectedReplayTimeFrame.saved) {
				// if we are already in replay mode then stop - otherwise let polling continue
				if (this.replayPlaying) {
					this.stop();
				}

				this.includePredictions = selectedReplayTimeFrame.includePredictions;
				this.startTime = selectedReplayTimeFrame.selectedStartTime.clone();
				this.endTime = selectedReplayTimeFrame.selectedEndTime.clone();
				this.timeSpanSec = moment.duration(this.endTime.diff(this.startTime)).asSeconds();

				this.curMoment = this.startTime.clone();

				this.mapReplayService.setCurrentReplayTime(this.curMoment.valueOf());

				this.initDisplayControls();

				this.timeRangeInitialized = true;

				this.init();
			} else {
				// if we have cancelled the modal before we have entered replay mode - toggle the replay showing control back off.
				if (!this.timeRangeInitialized) {
					this.mapReplayService.setShowReplayControl(false);
				}
			}
		});
	};

	/**
	 * set the playback speed
	 * @param speed - the playback speed
	 */
	public setPlaybackSpeed = (speed: number): void => {
		this.playbackSpeed = speed;
		this.infoDisplay = this.getSpeedMsg();

		// if we are currently playing stop and start the polling to pick up the new speed
		if (this.replayPlaying) {
			this.mapPollingService.stopPolling();

			this.mapReplayService.setReplayPollInterval(this.playbackSpeed);

			this.mapPollingService.initPolling(this.mapReplayService.getReplayPollInterval());
		}
	};

	/**
	 * toggle the replay play/pause
	 */
	public toggleReplayPlay = (): void => {
		if (this.replayPlaying) {
			this.stop();
		} else {
			this.start();
		}
	};

	/**
	 * handle the user setting of the replay select (slider value)
	 * @param event - the mouse click event with the updated slider value
	 */
	public handleReplayProgressSelect = (event: MouseEvent): void => {
		const clickPosition: number = event.pageX - this.getProgressBarLeftPosition();

		let secondsPlayedPerc: number = 100 * (clickPosition / this.barWidth);

		if (secondsPlayedPerc > 100) {
			secondsPlayedPerc = 100;
		}

		if (secondsPlayedPerc < 0) {
			secondsPlayedPerc = 0;
		}

		this.curMoment = this.startTime.clone().add(0.01 * secondsPlayedPerc * this.timeSpanSec, 'seconds');

		this.mapReplayService.setCurrentReplayTime(this.curMoment.valueOf());

		if (this.replayPlaying) {
			this.stop();
		}

		this.updateProgressBar();
	};

	/**
	 * handle the mouse hover event for the slider
	 * @param event - the mouse event with the current slider value
	 */
	public replayProgressOnMouseHover = (event: MouseEvent): void => {
		const mouseX: number = event.pageX - this.getProgressBarLeftPosition();

		let playedPct: number = 100 * (mouseX / this.barWidth);

		if (playedPct > 100) {
			playedPct = 100;
		}

		if (playedPct < 0) {
			playedPct = 0;
		}

		const hoverMoment: Moment = this.startTime.clone().add(0.01 * playedPct * this.timeSpanSec, 'seconds');

		this.infoDisplay = hoverMoment.format(this.dateFormatLong);
	};

	/**
	 * handle the user leaving the hover of the replay progress
	 */
	public replayProgressOnMouseLeave = (): void => {
		this.infoDisplay = this.getSpeedMsg();
	};

	/**
	 * get the position to display the replay control. The replay control should sit in the centre of the map which
	 * is offset if the nav menu is open
	 *
	 * @returns the position offset
	 */
	public getControlPositionOffset = (): number => {
		return this.mapOptionsService.getNavViewOpen() ? 0 : 280;
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		clearInterval(this.playInterval);

		this.mapReplayService.clear();

		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * replay Init complete - handle the update for the replay initialization completing
	 */
	private setSubscriptions = (): void => {
		this.replayInitComplete$Subscription = this.mapEventsService.replayInitComplete.subscribe((initComplete: ReplayInitComplete) => {
			this.handleReplayInit(initComplete);
		});
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_MAP.MAP_NORMAL', 'T_MAP.MAP_DOUBLE', 'T_MAP.MAP_SEC_SEC']);
	};

	/**
	 * init the current agency
	 */
	private initAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.timezone = this.agenciesDataService.getAgencyTimezone(selectedAgency.authority_id, selectedAgency.agency_id);

		if (this.timezone) {
			this.timezoneDisplay = moment.tz(this.timezone).format('z');
		}
	};

	/**
	 * initialize the time range control
	 */
	private initTimeRange = (): void => {
		this.timeSpanSec = this.defaultTimeSpanSec;
		this.endTime = moment.tz(this.timezone);
		this.startTime = this.endTime.clone().subtract(this.timeSpanSec, 'seconds');
	};

	/**
	 * initialize the display values for the view
	 */
	private initDisplayControls = (): void => {
		this.progressPlayedPercStyle = { width: '0%' };
		this.progressRemainingPercStyle = { width: '100%' };
		this.progressPositionMarkerStyle = { left: 0 - this.markerOffsetPx + 'px' };
		this.progressPositionLabel = this.startTime.clone().format(this.dateFormatLong);
		this.startPositionLabel = this.startTime.format(this.dateFormatShort);
		this.endPositionLabel = this.endTime.format(this.dateFormatShort);
		this.infoDisplay = this.getSpeedMsg();
	};

	/**
	 * get the current speed setting and translated text
	 * @returns the current speed setting
	 */
	private getSpeedMsg = (): string => {
		switch (this.playbackSpeed) {
			case 1:
				return this.translations['T_MAP.MAP_NORMAL'] + ' (1x)';
			case 2:
				return this.translations['T_MAP.MAP_DOUBLE'] + ' (2x)';
			case 5:
				return '5 ' + this.translations['T_MAP.MAP_SEC_SEC'] + ' (5x)';
			case 10:
				return '10 ' + this.translations['T_MAP.MAP_SEC_SEC'] + ' (10x)';
			case 20:
				return '20 ' + this.translations['T_MAP.MAP_SEC_SEC'] + ' (20x)';
			case 60:
				return '1 ' + this.translations['T_MAP.MAP_SEC_SEC'] + ' (60x)';
		}

		return null;
	};

	/**
	 * initialize the replay control
	 */
	private init = async (): Promise<void> => {
		this.replayBuffering = true;

		await this.mapReplayService.init(this.authorityId, this.startTime, this.endTime, this.includePredictions);
	};

	/**
	 * handle the replay init response
	 * @param initComplete - true when the initialization is complete
	 */
	private handleReplayInit = (initComplete: ReplayInitComplete): void => {
		this.replayBuffering = false;

		if (initComplete.success) {
			this.replayRefreshView = true;
		}
	};

	/**
	 * trigger the start of replay mode
	 */
	private start = (): void => {
		this.mapPollingService.stopPolling();

		if (this.mapOptionsService.getMapMode() === MapModeType.live) {
			this.mapOptionsService.setMapMode(MapModeType.replay);

			// clear out any existing vehicles from live mode
			this.mapVehiclesService.clearVehicles();
		}

		// start/resume the replay
		this.replayPlaying = true;
		this.playInterval = setInterval(this.playIncrement, this.refreshRateMs);

		this.mapReplayService.setReplayPollInterval(this.playbackSpeed);
		this.mapPollingService.initPolling(this.mapReplayService.getReplayPollInterval());

		if (this.replayRefreshView) {
			this.mapEventsService.publishNavigateRefresh();
			this.replayRefreshView = false;
		}
	};

	/**
	 * update and handle the replay controls for each increment
	 */
	private playIncrement = async (): Promise<void> => {
		this.curMoment.add((this.refreshRateMs / 1000) * this.playbackSpeed, 'seconds');

		if (this.curMoment.isSame(this.endTime) || this.curMoment.isAfter(this.endTime)) {
			this.stop();

			// reset back to start
			this.curMoment = this.startTime.clone();
		}

		this.mapReplayService.setCurrentReplayTime(this.curMoment.valueOf());

		this.updateProgressBar();
	};

	/**
	 * update the replay progress bar at each interval
	 */
	private updateProgressBar = (): void => {
		const secondsPlayed: number = moment.duration(this.curMoment.diff(this.startTime)).asSeconds();

		const secondsPlayedPerc: number = (secondsPlayed / this.timeSpanSec) * 100;

		let secondsRemainingPerc: number = 100 - secondsPlayedPerc;

		if (secondsRemainingPerc < 0) {
			secondsRemainingPerc = 0;
		}

		this.progressPositionLabel = this.curMoment.format(this.dateFormatLong);
		this.progressPlayedPercStyle = { width: secondsPlayedPerc + '%' };
		this.progressRemainingPercStyle = { width: secondsRemainingPerc + '%' };
		this.progressPositionMarkerStyle = { left: 0.01 * secondsPlayedPerc * this.barWidth - this.markerOffsetPx + 'px' };
	};

	/**
	 * stop replay mode
	 */
	private stop = (): void => {
		// stop the map service polling
		this.mapPollingService.stopPolling();

		this.replayPlaying = false;
		clearInterval(this.playInterval);
	};

	/**
	 * get the progress style position
	 *
	 * @returns the progress bar left position
	 */
	private getProgressBarLeftPosition = (): number => {
		return this.replayControlBar.nativeElement.getBoundingClientRect().left;
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.replayInitComplete$Subscription?.unsubscribe();
	};
}

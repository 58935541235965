/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { allDepotTag, unknownDepotTag } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapDepotService {
	/**
	 * determine if a vehicle belongs to the selected depot
	 * @param lastDepot - the last depot the vehicle visited
	 * @param selectedDepotFilterTag - the depot tag we are filtering on
	 * @returns true if a vehicle belongs to the selected depot
	 */
	public vehicleBelongsToSelectedDepot = (lastDepot: string, selectedDepotFilterTag: string): boolean => {
		const selectedDepotTag: string = selectedDepotFilterTag;

		if (selectedDepotTag === allDepotTag) {
			return true;
		} else if (selectedDepotTag === unknownDepotTag) {
			return !lastDepot; // i.e when the last_depot is null or undefined
		}

		return selectedDepotTag === lastDepot;
	};
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { CurrentUserPermissionService } from '../../support-features/login/services/current-user/current-user-permission.service';
import { CurrentUserUtilService } from '../../support-features/login/services/current-user/current-user-utils.service';
import { AgenciesDataService } from '../../support-features/agencies/services/agencies-data.service';
import { LoginUtilService } from '../../support-features/login/services/login/login-util.service';
import { RoutingHandlerService } from '../services/routing-handler.service';
import { InitService } from '../../support-features/login/services/init/init.service';

import { PermissionRequest, PermissionRequestActionType } from '../../support-features/login/types/types';
import { SelectedAgency } from '../../support-features/agencies/types/api-types';
import { UserLogin } from '../../support-features/login/types/api-types';

@Injectable()
export class CanActivateGuard {
	private authorityId: string = null;
	private agencyId: string = null;
	private authorized: boolean = false;

	constructor(
		private loginUtilService: LoginUtilService,
		private agenciesDataService: AgenciesDataService,
		private initService: InitService,
		private routingHandlerService: RoutingHandlerService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private currentUserUtilService: CurrentUserUtilService,
		private router: Router
	) {}

	/**
	 * determines if a particular url is allowed for that user. if not the user is sent to a 'not authorized' page
	 *
	 * @param route - the intended route to activate
	 * @param state - router snapshot containing the current url
	 * @returns the resuts of the security check (true when the user is allowed to activate the chosen route)
	 */
	canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
		this.authorized = false;

		if (!this.loginUtilService.isLoggedIn()) {
			this.router.navigate(['/login']);
		} else {
			if (this.initService.isInitComplete()) {
				const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

				if (selectedAgency) {
					this.authorityId = selectedAgency.authority_id;
					this.agencyId = selectedAgency.agency_id;

					const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

					const permissionRequest: PermissionRequest = {
						agencyId: this.agencyId,
						authorityId: this.authorityId,
					};

					switch (route.routeConfig.path) {
						case 'dashboard':
							this.authorized = true;
							break;
						case 'admintools':
							this.authorized = true;
							break;
						case 'admintools/users':
						case 'admintools/users/profile/:userId':
							// only check access if for a different user
							this.authorized =
								currentUser.nb_id.toString() === route.params.userId ||
								this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.viewUserList, null);
							break;
						case 'admintools/schedules/view/:authorityId':
						case 'admintools/schedules':
							this.authorized = this.currentUserPermissionService.hasPermissionTo(
								PermissionRequestActionType.viewSchedulesList,
								{}
							);
							break;
						case 'admintools/agencies':
						case 'admintools/agencies/view/:authorityId/:agencyId':
							this.authorized = true;
							break;
						case 'admintools/authorities':
						case 'admintools/authorities/view/:authorityId':
							this.authorized = true;
							break;
						case 'predictions':
							// Ensure user has permission to access this functionality
							this.authorized = this.currentUserPermissionService.hasPermissionTo(
								PermissionRequestActionType.postDisablePredictions,
								permissionRequest
							);
							break;
						case 'vehicle-messages':
							this.authorized = true;
							break;
						case 'rider-messages':
							this.authorized = true;
							break;
						case 'reports/:activeTabId':
						case 'reports/available/:authorityId/:agencyId/:defaultTemplateId/:createdById/:reportId':
						case 'reports/viewer/:authorityId/:agencyId/:defaultTemplateId/:createdById/:reportId':
						case 'reports/template/:authorityId/:agencyId/:defaultTemplateId/:createdById/:reportTemplateId':
							this.authorized = true;
							break;
						case 'map':
							this.authorized = true;
							break;

						default:
							// default to unauthorized - this ensure devs enter an entry here and consider authorization
							this.authorized = false;
							break;
					}

					if (!this.authorized) {
						this.router.navigate(['/not_authorized']);
					}
				}
			} else {
				// this guard is triggered immediately before the initialization has finished (due to the await around agencies etc).
				// This workaround is to inform the routing handler that we have a target to set when the initialization has finished
				this.routingHandlerService.setInitialTarget(state.url);
			}
		}

		return this.authorized;
	};
}

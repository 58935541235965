import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ridershipImage', pure: false })
export class RidershipImagePipe implements PipeTransform {
	/**
	 * pipe to transform the ridership enum value to an appropriate svg image
	 *
	 * @param ridershipEnum - the ridership enum value to convert in to an svg image
	 * @returns the svg image text
	 */
	transform(ridershipEnum: number): string {
		let src: string = '/assets/img/ridership/';

		switch (ridershipEnum) {
			default:
				src += 'occupancy-icon-green.svg';
				break;
			case 0: // empty
				src += 'occupancy-icon-green.svg';
				break;
			case 1: // many seats
				src += 'occupancy-icon-green.svg';
				break;
			case 2: // few seats
				src += 'occupancy-icon-yellow.svg';
				break;
			case 3: // standing room
				src += 'occupancy-icon-darkyellow.svg';
				break;
			case 4: // crushed standing room
				src += 'occupancy-icon-red.svg';
				break;
			case 5: // full
				src += 'occupancy-icon-darkred.svg';
				break;
			case 6: // not accepting passengers
				src += 'occupancy-icon-darkred.svg';
				break;
			case -1: //n ull value or invalid
				src = '';
				break;
		}

		return src;
	}
}

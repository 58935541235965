<form [formGroup]="reactiveForm" class="form-group row">
	<div id="title-text" class="form-group">
		<div class="nb-required">
			<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
		</div>
		<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
		<input
			formControlName="title"
			data-test="title.input"
			type="text"
			id="title"
			class="form-control"
			placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
		<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
	</div>

	<div *ngIf="agencies?.length > 1">
		<div id="agency" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
			<select
				name="agencyId"
				id="agencyId"
				class="form-control"
				formControlName="agencyId"
				data-test="agency.selection"
				(change)="agencyChanged()">
				<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
					{{ agency.agency_name }}
				</option>
			</select>
			<input-validation [control]="reactiveForm.controls.agencyId"></input-validation>
		</div>
	</div>

	<div id="selectedTimeFrame" class="form-group">
		<div>
			<label>{{ 'T_DASH.DB_TRAFFIC_REFRESH_DATA' | translate }}</label>
		</div>
		<div>
			<div class="btn-group">
				<button class="btn btn-extra-width" data-test="btn.1-min" [ngClass]="timeFrameButtonClass(1)" (click)="setTimeFrame(1)">
					{{ 'T_DASH.DB_TRAFFIC_1MIN' | translate }}
				</button>
				<button class="btn btn-extra-width" data-test="btn.3-min" [ngClass]="timeFrameButtonClass(3)" (click)="setTimeFrame(3)">
					{{ 'T_DASH.DB_TRAFFIC_3MIN' | translate }}
				</button>
				<button class="btn btn-extra-width" data-test="btn.5-min" [ngClass]="timeFrameButtonClass(5)" (click)="setTimeFrame(5)">
					{{ 'T_DASH.DB_TRAFFIC_5MIN' | translate }}
				</button>
				<button class="btn btn-extra-width" data-test="btn.10-min" [ngClass]="timeFrameButtonClass(10)" (click)="setTimeFrame(10)">
					{{ 'T_DASH.DB_TRAFFIC_10MIN' | translate }}
				</button>
			</div>
		</div>
	</div>
</form>

<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div *ngIf="hasResults" class="wrap center" [ngClass]="{ flex: !multicolumn }">
					<div *ngIf="!isDisplayOptionSet('listOnly')" class="center-block">
						<headway-performance-summary [performanceData]="svgDisplay"></headway-performance-summary>
					</div>
					<div *ngIf="!isDisplayOptionSet('chartOnly')">
						<table class="table">
							<tr class="table-row">
								<th class="table-heading">{{ 'T_DASH.DB_VEHICLE' | translate }}</th>
								<th class="table-heading">{{ 'T_DASH.DB_ROUTE' | translate }}</th>
								<th class="table-heading">{{ 'T_DASH.DB_HEADWAY' | translate }}</th>
							</tr>
							<tr *ngFor="let vehicle of vehicleDisplay" class="table-row">
								<td
									class="table-data col-pointer"
									data-test="vehicle.table.vehicle_id"
									(click)="navigateToVehicleDetails(data.config.selectedAgency.authority_id, vehicle.vehicle_id)">
									{{ vehicle.vehicle_id }}
								</td>
								<td class="table-data col-pointer" data-test="vehicle.table.route" (click)="navigateToRouteDetails(vehicle.route_id)">
									<route-pill [route]="determineRoutePillData(vehicle)" [useFullWidth]="true"></route-pill>
								</td>
								<td class="table-data" data-test="vehicle.table.adherence">{{ vehicle.displaySecs + ' ' + vehicle.displayPerc }}</td>
							</tr>
						</table>
					</div>
				</div>

				<div *ngIf="!hasResults" class="no-data text-center">{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div *ngIf="hasResults">
					<div data-test="performance.route">{{ routeHeading }}</div>
					<div class="row">
						<div class="col-xs-12">
							<performance-line-chart [chartData]="metrics" [display]="display" [timezone]="timezone"></performance-line-chart>
						</div>
					</div>
				</div>
				<div class="no-data text-center" *ngIf="!hasResults">{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { DisablePredictionUpdate } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class PredictionsApiService {
	private readonly predictionsUrl: string = '/predictions';
	private readonly predictionsRoutesUrl: string = '/prediction-routes';
	private readonly predictionRouteConfigUrl: string = '/prediction-route-config';
	private readonly feedTypesUrl: string = '/feedtypes';
	private readonly allDisabledUrl: string = '/disabled/all';
	private readonly disableUrl: string = '/disable';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the prediction routes for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the prediction routes response
	 */
	public getRoutes = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.predictionsUrl + this.predictionsRoutesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the route configuration for the given authority/route from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param routeId - the route id
	 * @returns the route configuration response
	 */
	public getRouteConfig = async (authorityId: string, routeId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.predictionsUrl + this.predictionRouteConfigUrl + '/' + routeId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the feed types for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the feed types response for supplied authority
	 */
	public getFeedTypes = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.predictionsUrl + this.feedTypesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests all of the disabled predictions for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns all of the disabled predictions details for the supplied authority response
	 */
	public getAllDisabledPredictions = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.predictionsUrl + this.allDisabledUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the update of the disabled predictions change for the given authority to the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param disablePredictionUpdate - disable prediction update details
	 * @param isDelete - whether the update is a delete or not
	 * @returns the disable predictions update response
	 */
	public disablePredictionUpdate = async (
		authorityId: string,
		disablePredictionUpdate: DisablePredictionUpdate,
		isDelete: boolean
	): Promise<any> => {
		try {
			const params: any = {
				isDelete,
			};

			return await this.httpService.post('/' + authorityId + this.predictionsUrl + this.disableUrl, disablePredictionUpdate, params);
		} catch (exception) {
			throw exception;
		}
	};
}

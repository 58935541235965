<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>
<panel-header [showBackButton]="true" [heading]="'T_SCHEDULE.SCHEDULE_DETAILS' | translate"> </panel-header>
<mat-tab-group #tabs (selectedTabChange)="tabChanged($event)">
	<mat-tab label="{{ 'T_SCHEDULE.UPLOAD_HISTORY' | translate | uppercase }}">
		<schedule-admin-view-upload-history #scheduleUploadHistory [active]="true"></schedule-admin-view-upload-history>
	</mat-tab>
	<mat-tab label="{{ 'T_SCHEDULE.REVISION_HISTORY' | translate | uppercase }}">
		<schedule-admin-view-revision-history
			#scheduleRevisionHistory
			*ngIf="loadRevisionHistoryTab"
			[active]="false"></schedule-admin-view-revision-history>
	</mat-tab>
</mat-tab-group>

<div class="settings-container">
	<form [formGroup]="reactiveForm" class="row">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div [hidden]="!loaded" [ngStyle]="heightStyle">
			<!-- just hide otherwise we destroy the underlying control that sends the loaded state -->

			<vehicle-event-settings
				[formGroup]="reactiveForm"
				[settings]="settings"
				[agencies]="agencies"
				(agencyChanged)="agencyChanged()"
				(loaded)="handleLoaded($event)">
			</vehicle-event-settings>

			<div class="save-container">
				<button type="button" (click)="close()" class="btn btn-secondary">
					{{ 'T_CORE.CANCEL' | translate }}
				</button>
				<button type="button" [disabled]="!this.reactiveForm.valid" (click)="save()" class="btn btn-primary">
					{{ 'T_CORE.SAVE' | translate }}
				</button>
			</div>
		</div>
	</form>
</div>

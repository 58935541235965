import { ReportViewerListBaseComponent } from '../report-viewer-list-base.component';
import { ReportsEventsService } from '../../../services/reports-events.service';
import { ReportsConfigService } from '../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportConfiguration, SortDetails } from '../../../types/types';
import { RoutePillData } from '@cubicNx/libs/utils';

export class ReportViewerSortableListBaseComponent extends ReportViewerListBaseComponent {
	public sortDetails: SortDetails = null;
	public sortClass: string = null;

	constructor(
		protected reportsEventsService: ReportsEventsService,
		protected reportsConfigService: ReportsConfigService,
		protected colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * creates a sort details instance (by/direction) and publishes to parent list
	 *
	 * @param sortField - the sort by field
	 */
	public sortList = async (sortField: string): Promise<void> => {
		// Set the field name we wish to sort on. If it's the same field then flip the direction, if it's a different field default to 'asc'

		const previousSortField: string = this.sortDetails.field;

		this.sortDetails = {
			dir: sortField === previousSortField ? (this.sortDetails.dir === 'asc' ? 'desc' : 'asc') : 'asc',
			field: sortField,
		};

		this.sortClass = this.sortDetails.dir === 'asc' ? 'sort sort-by-asc' : 'sort sort-by-desc';

		// Sort column changed so list needs to be loaded accordingly
		this.reportsEventsService.publishLoadReportList(this.sortDetails);
	};

	/**
	 * scrolls into the view for the supplied report index
	 *
	 * @param reportIndex - the report index
	 */
	public scrollIntoView = (reportIndex: string): void => {
		const element: HTMLElement = document.getElementById(reportIndex);

		element.scrollIntoView();
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param listData - the list data
	 * @returns route pill
	 */
	public determineRoutePillData = (listData: any): RoutePillData => {
		return {
			routeShortName: listData.route_short_name,
			routeLongName: listData.route_long_name,
			routeId: listData.route_id,
			routeColor: this.colorUtilityService.getColor(listData.route_color),
			routeTextColor: this.colorUtilityService.getColor(listData.route_text_color),
		};
	};

	/**
	 * initializes the report list configuration and informs parent that a reload is required
	 */
	protected init = (): void => {
		const reportConfig: ReportConfiguration = this.reportsConfigService.getReportConfiguration(this.defaultTemplateId);

		this.sortDetails = { field: reportConfig.defaultSortName, dir: 'asc' };
		this.sortClass = 'sort sort-by-asc';

		// Child view informing parent that list needs loading for the first time
		this.reportsEventsService.publishLoadReportList(this.sortDetails);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { UsersAdminDataService } from '../../../services/users-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'reset-password-entry',
	templateUrl: './reset-password-entry.component.html',
	styleUrls: ['./reset-password-entry.component.scss'],
})
export class ResetPasswordEntryComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() passwordResetComplete: EventEmitter<void> = new EventEmitter<void>();

	public isLoading: boolean = true;
	public error: string = null;
	public passwordValid: boolean = false;

	private routeParams$Subscription: Subscription = null;
	private password: string = null;

	private resetPasswordKey: string = null;

	constructor(
		private usersAdminDataService: UsersAdminDataService,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the reset password entry component
	 */
	public async ngOnInit(): Promise<void> {
		// get params off the url
		this.routeParams$Subscription = this.route.queryParams.subscribe((params) => {
			this.resetPasswordKey = params['reset_password_key'];
		});

		await this.loadTranslations();
	}

	/**
	 * saves the new password
	 */
	public setNewPassword = async (): Promise<void> => {
		this.error = null;

		this.isLoading = true;

		const result: ResultContent = await this.usersAdminDataService.resetPassword(this.resetPasswordKey, this.password);

		if (result?.success) {
			// inform parent so it can move to the next step
			this.passwordResetComplete.emit();
		}

		this.isLoading = false;
	};

	/**
	 * remembers the updated password
	 *
	 * @param password - password
	 */
	public updatePassword = (password: string): void => {
		if (password) {
			this.password = password;
		}
	};

	/**
	 * remembers if the password is valid or not
	 *
	 * @param passwordValid - true if the password is valid, false otherwise
	 */
	public enableSetPassword = (passwordValid: boolean): void => {
		this.passwordValid = passwordValid;
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads the translations presented within the reset password entry component
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_CORE.SERVER_ERROR', 'T_USER.PASSWORD_RESET_KEY_INVALID', 'T_USER.PASSWORD_KEY_INVALID']);

		this.isLoading = false;
	};
}

<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<table>
					<thead>
						<tr class="raw_avl_spacing">
							<th
								[ngClass]="sortDetails.field === 'vehicle_id' ? sortClass : null"
								(click)="sortList('vehicle_id')"
								data-test="th.vehicle_id"
								class="ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_NAME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'rcvtime' ? sortClass : null"
								(click)="sortList('rcvtime')"
								data-test="th.rcvtime"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_GPS_TIMESTAMP' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpstime' ? sortClass : null"
								(click)="sortList('gpstime')"
								data-test="th.gpstime"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_LAST_VALID_GPS_TIMESTAMP' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpslat' ? sortClass : null"
								(click)="sortList('gpslat')"
								data-test="th.gpslat"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_LATITUDE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpslon' ? sortClass : null"
								(click)="sortList('gpslon')"
								data-test="th.gpslon"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_LONGITUDE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpsspeed' ? sortClass : null"
								(click)="sortList('gpsspeed')"
								data-test="th.gpsspeed"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.GTW_SPEED' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpsheading' ? sortClass : null"
								(click)="sortList('gpsheading')"
								data-test="th.gpsheading"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_BEARING' | translate }}
							</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr class="raw_avl_spacing" *ngFor="let reportItem of listData">
							<td data-test="td.vehicle_id" class="ellipsis raw_avl_spacing">
								{{ reportItem.vehicle_id !== null ? reportItem.vehicle_id : 'N/A' }}
							</td>
							<td data-test="td.rcvtime" class="ellipsis raw_avl_spacing">
								{{ reportItem.rcvtime !== null ? (reportItem.rcvtime | date: 'y-MM-dd &nbsp; HH:mm ss') : 'N/A' }}
							</td>
							<td data-test="td.gpstime" class="ellipsis raw_avl_spacing">
								{{ reportItem.gpstime !== null ? (reportItem.gpstime | date: 'y-MM-dd &nbsp; HH:mm ss') : 'N/A' }}
							</td>
							<td data-test="td.gpslat" class="ellipsis raw_avl_spacing">{{ reportItem.gpslat !== null ? reportItem.gpslat : 'N/A' }}</td>
							<td data-test="td.gpslon" class="ellipsis raw_avl_spacing">{{ reportItem.gpslon !== null ? reportItem.gpslon : 'N/A' }}</td>
							<td data-test="td.gpsspeed" class="ellipsis raw_avl_spacing">
								{{ reportItem.gpsspeed !== null ? reportItem.gpsspeed : 'N/A' }}
							</td>
							<td data-test="td.gpsheading" class="ellipsis raw_avl_spacing">
								{{ reportItem.gpsheading !== null ? reportItem.gpsheading : 'N/A' }}
							</td>
						</tr>
					</tbody>
				</table>
				<br />
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { MapOptionsService } from './map-options.service';
import { MapPollingService } from './map-polling.service';
import { MapEventsService } from './map-events.service';
import { MapVehiclesService } from './map-vehicles.service';
import { MapRoutesService } from './map-routes.service';

import { MapRoute, MapRoutes, MapVehicles } from '../types/types';
import { SelectedAgency } from '../../../support-features/agencies/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class MapUpdateService implements OnDestroy {
	private authorityId: string = null;
	private agencyId: string = null;

	private refreshPoll$Subscription: Subscription = null;
	private agenciesSelectionChange$Subscription: Subscription = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		private mapOptionsService: MapOptionsService,
		private mapPollingService: MapPollingService,
		private mapEventsService: MapEventsService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesService: MapVehiclesService,
		private router: Router
	) {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.agencyId = selectedAgency.agency_id;

		this.setSubscriptions();
	}

	/**
	 * toggle live updates on or off
	 */
	public toggleLiveUpdates = (): void => {
		if (this.mapOptionsService.getLiveUpdatesPaused()) {
			this.mapPollingService.resumeLivePolling();
		} else {
			this.mapPollingService.pauseLivePolling();
		}
	};

	/**
	 * handle any clean up - unsubscribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * handle the main refresh for the map and update vehicles (i.e trigger vehicle movement)
	 */
	private refreshCurrentState = async (): Promise<void> => {
		// ignore polling if we are away from the map
		if (this.router.url === '/map') {
			if (this.mapVehiclesService.getFirstRefresh()) {
				// reload any vehicles we have stored in our cache
				this.initVehicles();
				this.mapVehiclesService.setFirstRefresh(false);
			} else {
				// update our vehicles
				this.updateVehicles();
			}
		}
	};

	/**
	 * reload the vehicles for the first time following a return to the map feature
	 */
	private initVehicles = async (): Promise<void> => {
		let routeVehicles: MapVehicles = {};
		let nonRouteVehicles: MapVehicles = {};

		const routes: MapRoutes = this.mapRoutesService.getRoutes();

		const vehiclesProcessed: string[] = [];

		const routeIds: string[] = [];

		// update vehicles that are associated with a route
		if (Object.keys(routes).length > 0) {
			for (const routeId in routes) {
				if (this.mapRoutesService.routeDisplayed(routeId)) {
					routeIds.push(routeId);
				}
			}

			if (routeIds.length > 0) {
				routeVehicles = await this.mapRoutesService.getRouteVehicles(routeIds, this.authorityId, this.agencyId);

				for (const vehicleId in routeVehicles) {
					vehiclesProcessed.push(vehicleId);
				}
			}
		}

		// update vehicles that are not associated with a route
		const vehicles: MapVehicles = this.mapVehiclesService.getVehicles();

		if (Object.keys(vehicles).length > 0) {
			const vehicleIdList: string[] = [];

			for (const vehicleId in vehicles) {
				// work out which vehicles haven't been processed as part of a route
				if (vehiclesProcessed.indexOf(vehicleId) === -1) {
					vehicleIdList.push(vehicleId);
				}
			}

			if (vehicleIdList.length > 0) {
				nonRouteVehicles = await this.mapVehiclesService.getNonRouteVehicles(this.authorityId, vehicleIdList, true);
			}
		}

		const vehiclesToAdd: MapVehicles = { ...nonRouteVehicles, ...routeVehicles };

		// add all cached vehicles in to the map together - the leaflet library seems to have a bug where if
		// we send too many separate requests in quick succession, they don't all render (this may be an issue
		// with the AngularJS version only)
		if (Object.keys(vehiclesToAdd).length > 0) {
			this.mapVehiclesService.addVehicles(vehiclesToAdd);
		}
	};

	/**
	 * update the vehicles on the map. this includes vehicles that have been added as part of a route
	 * but also individual vehicles that have been manually added
	 */
	private updateVehicles = async (): Promise<void> => {
		const vehiclesProcessed: string[] = [];

		// update vehicles that are associated with a route
		const routes: MapRoutes = this.mapRoutesService.getRoutes();

		if (Object.keys(routes).length > 0) {
			const routeIds: string[] = [];

			let routeVehicles: MapVehicles = {};

			for (const routeId in routes) {
				if (this.mapRoutesService.routeDisplayed(routeId)) {
					const route: MapRoute = this.mapRoutesService.getRoute(routeId);

					// check if there are vehicles on the route - also techinically it's possible for this poll event to occur
					// in between adding our route for the first time and actually requesting which vehicles for the route the first time,
					// this checks ensures we don't process this update until we are ready (if there are in fact vehicles for the route)
					if (route.vehicles) {
						routeIds.push(routeId);
					}
				}
			}

			if (routeIds.length > 0) {
				routeVehicles = await this.mapRoutesService.getRouteVehicles(routeIds, this.authorityId, this.agencyId);

				if (Object.keys(routeVehicles).length > 0) {
					Object.keys(routeVehicles).forEach((vehicleId: string) => {
						vehiclesProcessed.push(vehicleId);
					});

					this.mapVehiclesService.updateVehicles(routeVehicles);
				}
			}
		}

		// update vehicles that are not associated with a route
		const vehicles: MapVehicles = this.mapVehiclesService.getVehicles();

		if (Object.keys(vehicles).length > 0) {
			const vehicleIdList: string[] = [];

			for (const vehicleId in vehicles) {
				// work out which vehicles haven't been processed as part of a route
				if (vehiclesProcessed.indexOf(vehicleId) === -1) {
					vehicleIdList.push(vehicleId);
				}
			}

			if (vehicleIdList.length > 0) {
				const updatedVehicles: MapVehicles = await this.mapVehiclesService.getNonRouteVehicles(
					this.authorityId,
					vehicleIdList,
					false
				);

				if (Object.keys(updatedVehicles).length > 0) {
					this.mapVehiclesService.updateVehicles(updatedVehicles);
				}
			}
		}
	};

	/**
	 * set up subscriptions for the page
	 *
	 * poll refresh - handle the main poll refresh for the map
	 *
	 * agency selection change - handle a change to the users agency
	 */
	private setSubscriptions = (): void => {
		this.refreshPoll$Subscription = this.mapEventsService.pollRefresh.subscribe(() => {
			this.refreshCurrentState();
		});

		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgenciesSelectionChange();
		});
	};

	/**
	 *  handle a change to the users agency
	 */
	private handleAgenciesSelectionChange = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.agencyId = selectedAgency.agency_id;
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.refreshPoll$Subscription?.unsubscribe();
		this.agenciesSelectionChange$Subscription?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, ElementRef, HostListener, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgenciesAdminDataService } from '../../../services/agencies-admin-data.service';
import { AgenciesDataService } from '../../../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { SliderConfig } from '@cubicNx/libs/utils';
import { TimeUpdated } from '@cubicNx/libs/utils';
import { AgencyDetail, TerminalDepartureRouteStopConfig } from '../../../../../../support-features/agencies/types/api-types';

import { ValidationText, maxlengthValidation, valueOutOfBoundsMaxValidation, valueOutOfBoundsMinValidation } from '@cubicNx/libs/utils';

import { TerminalDepartureConfigForm } from './terminal-departure-config/terminal-departure-config-form.model';

/**
 * performs validation  of terminal departure configuration
 *
 * @returns null if valid, error object otherwise
 */
export function validateTerminalDepartureConfigs(): { [key: string]: any } | null {
	return (formArray: FormArray): { [key: string]: any } | null => {
		let valid: boolean = true;
		const hashmap: Map<string, boolean> = new Map<string, boolean>();

		for (const exception of formArray.value.controls) {
			const key: string = exception.get('route').value + ',' + exception.get('stop').value;

			valid = valid && !hashmap.has(key);
			hashmap.set(key, true);
		}

		return valid ? null : { error: 'Duplicate Route/Terminal Station configs' };
	};
}

@Component({
	selector: 'edit-agency-admin-settings',
	templateUrl: './edit-agency-admin-settings.component.html',
	styleUrls: ['./edit-agency-admin-settings.component.scss'],
})
export class EditAgencyAdminSettingsComponent extends TranslateBaseComponent implements OnInit {
	@ViewChildren('exceptions', { read: ElementRef }) renderedExceptions: QueryList<ElementRef>;

	public readonly ridershipImageEmptyId: number = 0;
	public readonly ridershipImageManySeats: number = 1;
	public readonly ridershipImageFewSeats: number = 2;
	public readonly ridershipImageStandingRoom: number = 3;
	public readonly ridershipImageCrushedStandingRoom: number = 4;
	public readonly ridershipImageFull: number = 5;
	public readonly ridershipImageNotAcceptingPassengers: number = 6;

	/**
	 * imminent departure slider configuration
	 */
	public readonly iminentDepartureSliderConfig: SliderConfig = {
		step: 10,
		margin: 10,
		padding: 10,
		rangeMultiplier: 1,
		range: {
			min: -10,
			max: 310,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 1, 2, 60, 120, 180, 240, 300],
			density: 300,
		},
	};

	/**
	 * early adherence slider configuration
	 */
	public readonly earlyAdherenceSliderConfig: SliderConfig = {
		rangeMultiplier: 60,
		colors: {
			leftColor: '#14ad3d',
			middleColor: '#eb3b3b',
			rightColor: '#b00808',
		},
	};

	/**
	 * late adherence slider configuration
	 */
	public readonly lateAdherenceSliderConfig: SliderConfig = {
		rangeMultiplier: 60,
		colors: {
			leftColor: '#14ad3d',
			middleColor: '#ffb852',
			rightColor: '#e89015',
		},
	};

	/**
	 * headway close slider configuration
	 */
	public readonly headwayCloseSliderConfig: SliderConfig = {
		rangeMultiplier: 0.01, // effectively cause the slider to display percentage (values passed are decimal)
		range: {
			min: 1,
			max: 90,
		},
		padding: 0,
		pips: {
			stepped: true,
			mode: 'values',
			values: [10, 20, 30, 40, 50, 60, 70, 80, 90],
			density: 90,
		},
		colors: {
			leftColor: '#14ad3d',
			middleColor: '#eb3b3b',
			rightColor: '#b00808',
		},
	};

	/**
	 * headway distant slider configuration
	 */
	public readonly headwayDistantSliderConfig: SliderConfig = {
		rangeMultiplier: 0.01, // effectively cause the slider to display percentage (values passed are decimal)
		range: {
			min: 1,
			max: 400,
		},
		padding: 0,
		pips: {
			stepped: true,
			mode: 'values',
			values: [50, 100, 150, 200, 250, 300, 350, 400],
			density: 90,
		},
		colors: {
			leftColor: '#14ad3d',
			middleColor: '#ffb852',
			rightColor: '#e89015',
		},
	};

	/**
	 * stale settings slider configuration
	 */
	public readonly staleSettingsSliderConfig: SliderConfig = {
		rangeMultiplier: 60,
	};

	/**
	 * ridership accepting threshold slider configuration
	 */
	public readonly ridershipAcceptingThresholdSliderConfig: SliderConfig = {
		rangeMultiplier: 0.01, // effectively cause the slider to display percentage (values passed are decimal)
		padding: 3,
		range: {
			min: -3,
			max: 103,
		},
		colors: {
			leftColor: '#14ad3d',
			middleColor: '#14ad3d',
			middleColor2: '#ffb852',
			rightColor: '#e89015',
		},
		pips: {
			density: 5,
			stepped: true,
			mode: 'values',
			values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
		},
	};

	/**
	 * ridership not accepting threshold slider configuration
	 */
	public readonly ridershipNotAcceptingThresholdSliderConfig: SliderConfig = {
		rangeMultiplier: 0.01, // effectively cause the slider to display percentage (values passed are decimal)
		padding: 3,
		range: {
			min: 47,
			max: 153,
		},
		colors: {
			leftColor: '#e89015',
			middleColor: '#eb3b3b',
			middleColor2: '#b00808',
			rightColor: '#b00808',
		},
		pips: {
			density: 5,
			stepped: true,
			mode: 'values',
			values: [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150],
		},
	};

	/**
	 * terminal departure slider configuration
	 */
	public readonly terminalDepartureSliderConfig: SliderConfig = {
		rangeMultiplier: 60,
		padding: 0,
		range: {
			min: 0,
			max: 60,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 10, 20, 30, 40, 50, 60],
			density: 10,
		},
	};

	/**
	 * nav service distance  slider configuration
	 */
	public readonly navServiceDistanceSliderCfg: SliderConfig = {
		padding: 100,
		rangeMultiplier: 1,
		step: 100,
		margin: 100,
		range: {
			min: 0,
			max: 5100,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 1000, 2000, 3000, 4000, 5000],
			density: 10,
		},
	};

	/**
	 * arrival stop radius slider configuration
	 */
	public readonly arrivalStopRadiusSliderCfg: SliderConfig = {
		padding: 0,
		step: 10,
		margin: 0,
		rangeMultiplier: 1,
		range: {
			min: 0,
			max: 300,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 50, 100, 150, 200, 250, 300],
			density: 10,
		},
	};

	public editAgencySettingsForm: FormGroup = null;
	public loading: boolean = true;
	public agency: AgencyDetail = null;
	public agencyId: string = null;
	public authorityId: string = null;

	public generalSectionCollapsed: boolean = false;
	public adherenceSectionCollapsed: boolean = false;
	public headwaySectionCollapsed: boolean = false;
	public ridershipSectionCollapsed: boolean = false;
	public widgetsSectionCollapsed: boolean = false;
	public legacyNextbusSettingsSectionCollapsed: boolean = false;
	public terminalDepartureSectionCollapsed: boolean = false;
	public hasCustomTimeError: boolean = false;
	public customTimeErrorText: string = null;
	public customStartTimeErrorText: string = null;
	public customEndTimeErrorText: string = null;

	public iminentDepartureValues: Array<number>;
	public earlyAdherenceSettingValues: Array<number>;
	public lateAdherenceSettingValues: Array<number>;
	public staleSettingsValues: Array<number>;
	public headwayCloseValues: Array<number>;
	public headwayDistantValues: Array<number>;
	public ridershipAcceptingThresholdValues: Array<number>;
	public ridershipNotAcceptingThresholdValues: Array<number>;

	public maxExtensionValues: Array<number>;
	public minLayoverValues: Array<number>;
	public minDistanceNavValues: Array<number>;
	public defaultDeadheadValues: Array<number>;
	public startTimeNavValues: Array<number>;
	public endTimeNavValues: Array<number>;
	public callFrequencyNavValues: Array<number>;
	public arrivalStopRadiusValues: Array<number>;

	public defaultExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	public latExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
		[valueOutOfBoundsMinValidation]: null,
		[valueOutOfBoundsMaxValidation]: null,
	};

	public longExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
		[valueOutOfBoundsMinValidation]: null,
		[valueOutOfBoundsMaxValidation]: null,
	};

	private readonly latLonMaxLength: number = 32;
	private readonly fieldMaxLength: number = 128;
	private readonly latMinValue: number = -90;
	private readonly latMaxValue: number = 90;
	private readonly longMinValue: number = -180;
	private readonly longMaxValue: number = 180;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService,
		private agenciesAdminDataService: AgenciesAdminDataService,
		private agenciesDataService: AgenciesDataService,
		private modalRef: MatDialogRef<EditAgencyAdminSettingsComponent>,
		private formBuilder: FormBuilder
	) {
		super(translationService);

		this.defaultExtraMessageText = {
			[maxlengthValidation]: this.fieldMaxLength.toString(),
		};

		this.latExtraMessageText = {
			[maxlengthValidation]: this.latLonMaxLength.toString(),
			[valueOutOfBoundsMinValidation]: this.latMinValue.toString(),
			[valueOutOfBoundsMaxValidation]: this.latMaxValue.toString(),
		};

		this.longExtraMessageText = {
			[maxlengthValidation]: this.latLonMaxLength.toString(),
			[valueOutOfBoundsMinValidation]: this.longMinValue.toString(),
			[valueOutOfBoundsMaxValidation]: this.longMaxValue.toString(),
		};

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close(false);
	}

	/**
	 * performs initialization tasks for the edit agency admin settings view
	 */
	public async ngOnInit(): Promise<void> {
		// grab the modal data passed in
		this.authorityId = this.data['authorityId'];
		this.agencyId = this.data['agencyId'];

		await this.loadTranslations();

		await this.loadAgency();

		if (this.agency) {
			const exceptionFormGroups: FormGroup[] = [];

			for (const exception of this.agency.terminal_departure_exceptions) {
				exceptionFormGroups.push(
					this.formBuilder.group(
						new TerminalDepartureConfigForm(
							exception.route,
							exception.stop,
							exception.max_extension_seconds,
							exception.min_layover_seconds,
							exception.min_dist_nav_service,
							exception.default_deadhead_seconds,
							exception.apply_sched_layovers,
							exception.detect_departure_at_upcoming_stop,
							exception.detect_departure_stop_sequence,
							this.hoursAndMinutesConversion(exception.start_nav_service_throttle),
							this.hoursAndMinutesConversion(exception.end_nav_service_throttle),
							exception.nav_service_call_frequency,
							exception.arrival_stop_radius,
							(exception.default_max_extension_seconds = this.agency.terminal_departure_max_seconds),
							(exception.default_min_layover_seconds = this.agency.terminal_departure_min_layover_seconds),
							(exception.default_min_dist_nav_service = this.agency.terminal_departure_min_dist_nav_service),
							(exception.default_default_deadhead_seconds = this.agency.terminal_departure_default_deadhead_seconds),
							(exception.default_apply_sched_layovers = this.agency.terminal_departure_apply_sched_layovers),
							(exception.default_detect_departure_at_upcoming_stop =
								this.agency.terminal_departure_detect_departure_at_upcoming_stop),
							(exception.default_detect_departure_stop_sequence =
								this.agency.terminal_departure_detect_departure_stop_sequence),
							(exception.default_start_nav_service_throttle = this.hoursAndMinutesConversion(
								this.agency.terminal_departure_start_nav_service_throttle
							)),
							(exception.default_end_nav_service_throttle = this.hoursAndMinutesConversion(
								this.agency.terminal_departure_end_nav_service_throttle
							)),
							(exception.default_nav_service_call_frequency = this.agency.terminal_departure_nav_service_call_frequency),
							(exception.default_arrival_stop_radius = this.agency.terminal_departure_arrival_stop_radius)
						)
					)
				);
			}

			this.editAgencySettingsForm = this.formBuilder.group(
				{
					agency_lang_id: [this.agency.agency_lang_id, Validators.required],
					unit_of_measure: [this.agency.unit_of_measure, Validators.required],
					date_format_nb_id: [this.agency.date_format_nb_id, Validators.required],
					time_format_nb_id: [this.agency.time_format_nb_id, Validators.required],
					latitude: [this.agency.latitude, Validators.maxLength(this.latLonMaxLength)],
					longitude: [this.agency.longitude, Validators.maxLength(this.latLonMaxLength)],
					logout_timer: [this.agency.logout_timer, Validators.required],
					side_of_travel: [this.agency.side_of_travel, Validators.required],
					twitter_consumer_public_key: [this.agency.twitter_consumer_public_key, Validators.maxLength(this.fieldMaxLength)],
					twitter_consumer_secret_key: [this.agency.twitter_consumer_public_key, Validators.maxLength(this.fieldMaxLength)],
					twitter_access_token: [this.agency.twitter_consumer_public_key, Validators.maxLength(this.fieldMaxLength)],
					twitter_access_token_secret: [this.agency.twitter_consumer_public_key, Validators.maxLength(this.fieldMaxLength)],
					external_links: [''],
					departing_word: [this.agency.departing_word, Validators.maxLength(this.fieldMaxLength)],
					arriving_word: [this.agency.arriving_word, Validators.maxLength(this.fieldMaxLength)],
					default_route_id: [this.agency.default_route_id, Validators.maxLength(this.fieldMaxLength)],
					sms_prefix: [this.agency.sms_prefix, Validators.maxLength(this.fieldMaxLength)],
					can_select_by_stop_code: [this.agency.can_select_by_stop_code, Validators.required],
					stop_code_length: [this.agency.stop_code_length, [Validators.required, Validators.min(1)]],
					hassigns: [this.agency.hassigns, Validators.required],

					extend_all_departures: [this.agency.terminal_departure_extend_all],
					def_apply_sched_layovers: [this.agency.terminal_departure_apply_sched_layovers],

					terminal_departure_exceptions: [this.formBuilder.array(exceptionFormGroups), validateTerminalDepartureConfigs()],
					detect_departure_at_upcoming_stop: [this.agency.terminal_departure_detect_departure_at_upcoming_stop],
					detect_departure_stop_sequence: [this.agency.terminal_departure_detect_departure_stop_sequence],
					start_nav_service_throttle: [this.hoursAndMinutesConversion(this.agency.terminal_departure_start_nav_service_throttle)],
					end_nav_service_throttle: [this.hoursAndMinutesConversion(this.agency.terminal_departure_end_nav_service_throttle)],
				},
				{}
			);

			if (this.agency.important_links) {
				this.editAgencySettingsForm.patchValue({ external_links: this.agency.important_links });
			}
		}
	}

	/**
	 * toggles the general section
	 */
	public toggleGeneralSection = (): void => {
		this.generalSectionCollapsed = !this.generalSectionCollapsed;
	};

	/**
	 * toggles the adherence section
	 */
	public toggleAdherenceSection = (): void => {
		this.adherenceSectionCollapsed = !this.adherenceSectionCollapsed;
	};

	/**
	 * toggles the headway section
	 */
	public toggleHeadwaySection = (): void => {
		this.headwaySectionCollapsed = !this.headwaySectionCollapsed;
	};

	/**
	 * toggles the ridership section
	 */
	public toggleRidershipSection = (): void => {
		this.ridershipSectionCollapsed = !this.ridershipSectionCollapsed;
	};

	/**
	 * toggles the widgets section
	 */
	public toggleWidgetsSection = (): void => {
		this.widgetsSectionCollapsed = !this.widgetsSectionCollapsed;
	};

	/**
	 * toggles the legacy nextbus settings section
	 */
	public toggleLegacyNextbusSettingsSection = (): void => {
		this.legacyNextbusSettingsSectionCollapsed = !this.legacyNextbusSettingsSectionCollapsed;
	};

	/**
	 * toggles the terminal departure section
	 */
	public toggleTerminalDepartureSection = (): void => {
		this.terminalDepartureSectionCollapsed = !this.terminalDepartureSectionCollapsed;
	};

	/**
	 * validate the latitude value
	 */
	public validateLatitude = (): void => {
		this.validateLatLong(this.editAgencySettingsForm.controls.latitude, this.latMinValue, this.latMaxValue);
	};

	/**
	 * validate the longitude value
	 */
	public validateLongitude = (): void => {
		this.validateLatLong(this.editAgencySettingsForm.controls.longitude, this.longMinValue, this.longMaxValue);
	};

	/**
	 * sets the updated time in the control
	 *
	 * @param timeUpdated - the time updated
	 * @param controlName - the control name
	 */
	public setUpdatedTime = (timeUpdated: TimeUpdated, controlName: string): void => {
		this.editAgencySettingsForm.controls[controlName].setValue(timeUpdated.time);
	};

	/**
	 * @param control - validates the lat long values
	 * @param minValue - minimum value
	 * @param maxValue - maximum value
	 */
	public validateLatLong = (control: AbstractControl, minValue: number, maxValue: number): void => {
		const value: number = +control.value;

		if (Number.isNaN(value)) {
			control.setErrors({ 'value-invalid': true });
		} else {
			if (control.errors?.['value-invalid']) {
				delete control.errors['value-invalid'];
			}

			if (value < minValue) {
				control.setErrors({ 'value-out-of-bounds-min': true });
			} else {
				if (control.errors?.['value-out-of-bounds-min']) {
					delete control.errors['value-out-of-bounds-min'];
				}
			}

			if (value > maxValue) {
				control.setErrors({ 'value-out-of-bounds-max': true });
			} else {
				if (control.errors?.['value-out-of-bounds-max']) {
					delete control.errors['value-out-of-bounds-max'];
				}
			}
		}
	};

	/**
	 * updates the form with the time validity
	 *
	 * @param valid - whether the time is valid or not
	 * @param controlName - the form control name
	 */
	public setTimeValid = (valid: boolean, controlName: string): void => {
		if (valid) {
			this.editAgencySettingsForm.controls[controlName].setErrors(null);
			this.editAgencySettingsForm.controls[controlName].updateValueAndValidity();
		} else {
			this.editAgencySettingsForm.controls[controlName].setErrors({ invalid: true });
		}

		switch (controlName) {
			case 'start_nav_service_throttle':
			case 'end_nav_service_throttle':
				this.checkCustomTimes();
				this.hasCustomTimeError = this.getCustomTimeErrorText();
				break;
		}
	};

	/**
	 * checks the custom times from within the form
	 */
	public checkCustomTimes = (): void => {
		if (
			!this.editAgencySettingsForm.controls.start_nav_service_throttle.hasError('invalid') &&
			!this.editAgencySettingsForm.controls.end_nav_service_throttle.hasError('invalid')
		) {
			const startTime: number = this.editAgencySettingsForm.controls.start_nav_service_throttle.value;
			const endTime: number = this.editAgencySettingsForm.controls.end_nav_service_throttle.value;

			if (startTime.valueOf() >= endTime.valueOf()) {
				this.editAgencySettingsForm.controls.start_nav_service_throttle.setErrors({ 'start-time-greater': true });
			} else {
				this.editAgencySettingsForm.controls.start_nav_service_throttle.updateValueAndValidity();
			}
		}
	};

	/**
	 * gets percentage value for supplied value
	 *
	 * @param value - value
	 * @returns value as a percentage
	 */
	public getPercentage = (value: number): number => {
		return Math.round(value * 100);
	};

	/**
	 * determines the ability to save the current form details being edited
	 *
	 * @returns true if the save button can be enabled, flase otherwise
	 */
	public enableSave = (): boolean => {
		return this.editAgencySettingsForm?.valid || false;
	};

	/**
	 * converts the number of supplied seconds into hours and minutes
	 *
	 * @param secs - number of seconds
	 * @returns returns the seconds in hours and minutes format
	 */
	public hoursAndMinutesConversion = (secs: number): string => {
		let result: string = '';

		if (secs === 0) {
			result = '00:00';
		} else {
			const hours: number = secs / 3600;
			const minutes: number = Math.round(+hours.toString().replace(/^[^.]+/, '0') * 60);
			let minutesValue: string = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString();

			if (minutesValue === '60') {
				minutesValue = '00';
			}

			if (hours >= 0) {
				const hoursStr: string = hours.toFixed(2);
				const hoursStrArr: string[] = hoursStr.split('.');

				if (hoursStrArr[0].length === 1) {
					hoursStrArr[0] = '0' + hoursStrArr[0];
				}

				result = hoursStrArr[0] + ':' + minutesValue;
			}
		}

		return result;
	};

	/**
	 * sets the external link file selected
	 *
	 * @param target - the target
	 */
	public setExternalLinksFileSelected = async (target: EventTarget): Promise<void> => {
		const element: HTMLInputElement = target as HTMLInputElement;
		const files: FileList = element.files;

		const file: File = files[0];

		if (file) {
			const fileReader: FileReader = new FileReader();

			fileReader.onload = (): void => this.onLoadExternalLinksFileReader(fileReader);

			fileReader.readAsText(file);
		}
	};

	/**
	 * add the terminal departure exception details
	 */
	public addTerminalDepartureException = async (): Promise<void> => {
		const currentExceptions: any = this.editAgencySettingsForm.get('terminal_departure_exceptions').value.controls as FormArray;

		currentExceptions.push(this.formBuilder.group(new TerminalDepartureConfigForm()));

		const defaultTerminalException: any = currentExceptions[currentExceptions.length - 1].controls;

		defaultTerminalException.max_extension_seconds.value = this.agency.terminal_departure_max_seconds;
		defaultTerminalException.min_layover_seconds.value = this.agency.terminal_departure_min_layover_seconds;
		defaultTerminalException.min_dist_nav_service.value = this.agency.terminal_departure_min_dist_nav_service;
		defaultTerminalException.default_deadhead_seconds.value = this.agency.terminal_departure_default_deadhead_seconds;
		defaultTerminalException.apply_sched_layovers.value = this.agency.terminal_departure_apply_sched_layovers;
		defaultTerminalException.detect_departure_at_upcoming_stop.value = this.agency.terminal_departure_detect_departure_at_upcoming_stop;
		defaultTerminalException.detect_departure_stop_sequence.value = this.agency.terminal_departure_detect_departure_stop_sequence;
		defaultTerminalException.start_nav_service_throttle.value = this.hoursAndMinutesConversion(
			this.agency.terminal_departure_start_nav_service_throttle
		);
		defaultTerminalException.end_nav_service_throttle.value = this.hoursAndMinutesConversion(
			this.agency.terminal_departure_end_nav_service_throttle
		);
		defaultTerminalException.nav_service_call_frequency.value = this.agency.terminal_departure_nav_service_call_frequency;
		defaultTerminalException.arrival_stop_radius.value = this.agency.terminal_departure_arrival_stop_radius;

		setTimeout(() => {
			const exceptionsArray: ElementRef<any>[] = this.renderedExceptions.toArray();

			exceptionsArray[currentExceptions.length - 1].nativeElement.scrollIntoView({ behavior: 'smooth' });
			this.updateTerminalDepartureExceptionValidity();
		});
	};

	/**
	 * deletes the terminal departure exception at the supplied index
	 *
	 * @param index - index to delete at
	 */
	public deleteTerminalDepartureException = (index: number): void => {
		const currentExceptions: FormArray = this.editAgencySettingsForm.get('terminal_departure_exceptions').value as FormArray;

		currentExceptions.removeAt(index);
		this.updateTerminalDepartureExceptionValidity();
	};

	/**
	 * validates the terminal departure exception validity form values
	 */
	public updateTerminalDepartureExceptionValidity = (): void => {
		this.editAgencySettingsForm.get('terminal_departure_exceptions').updateValueAndValidity();
		this.editAgencySettingsForm.updateValueAndValidity();

		if (this.editAgencySettingsForm.get('terminal_departure_exceptions').value.controls.length > 0) {
			this.editAgencySettingsForm.get('terminal_departure_exceptions').value.controls.forEach((control: FormGroup) => {
				const startTime: any = control.value.start_nav_service_throttle;
				const endTime: any = control.value.end_nav_service_throttle;

				if (startTime !== null && endTime !== null) {
					if (startTime.valueOf() >= endTime.valueOf()) {
						this.editAgencySettingsForm.get('terminal_departure_exceptions').setErrors({ 'start-time-greater': true });
					} else if (control.status === 'INVALID') {
						this.editAgencySettingsForm.get('terminal_departure_exceptions').setErrors({ invalid: true });
					} else {
						this.editAgencySettingsForm.get('terminal_departure_exceptions').setErrors(null);
					}
				} else {
					if (control.status === 'INVALID') {
						this.editAgencySettingsForm.get('terminal_departure_exceptions').setErrors({ invalid: true });
					} else {
						this.editAgencySettingsForm.get('terminal_departure_exceptions').setErrors(null);
					}
				}
			});
		}
	};

	/**
	 * saves the editied agency details currently in the edit agency details form
	 */
	public save = async (): Promise<void> => {
		// default updated agency to original object
		const updatedAgency: AgencyDetail = this.agency;

		// grab the updated values from the form
		updatedAgency.agency_lang_id = this.editAgencySettingsForm.get('agency_lang_id').value;
		updatedAgency.unit_of_measure = this.editAgencySettingsForm.get('unit_of_measure').value;
		updatedAgency.date_format_nb_id = this.editAgencySettingsForm.get('date_format_nb_id').value;
		updatedAgency.time_format_nb_id = this.editAgencySettingsForm.get('time_format_nb_id').value;
		updatedAgency.latitude = this.editAgencySettingsForm.get('latitude').value;
		updatedAgency.longitude = this.editAgencySettingsForm.get('longitude').value;
		updatedAgency.logout_timer = this.editAgencySettingsForm.get('logout_timer').value;
		updatedAgency.side_of_travel = this.editAgencySettingsForm.get('side_of_travel').value;
		updatedAgency.twitter_consumer_public_key = this.editAgencySettingsForm.get('twitter_consumer_public_key').value;
		updatedAgency.twitter_consumer_secret_key = this.editAgencySettingsForm.get('twitter_consumer_secret_key').value;
		updatedAgency.twitter_access_token = this.editAgencySettingsForm.get('twitter_access_token').value;
		updatedAgency.twitter_access_token_secret = this.editAgencySettingsForm.get('twitter_access_token_secret').value;
		updatedAgency.departing_word = this.editAgencySettingsForm.get('departing_word').value;
		updatedAgency.arriving_word = this.editAgencySettingsForm.get('arriving_word').value;
		updatedAgency.default_route_id = this.editAgencySettingsForm.get('default_route_id').value;
		updatedAgency.sms_prefix = this.editAgencySettingsForm.get('sms_prefix').value;
		updatedAgency.can_select_by_stop_code = this.editAgencySettingsForm.get('can_select_by_stop_code').value;
		updatedAgency.stop_code_length = this.editAgencySettingsForm.get('stop_code_length').value;
		updatedAgency.hassigns = this.editAgencySettingsForm.get('hassigns').value;
		updatedAgency.important_links = this.editAgencySettingsForm.get('external_links').value;

		// grab the updated values from the slider controls
		updatedAgency.imminent_departure = this.iminentDepartureValues[0];
		updatedAgency.adherence_setting_early_min_sec = this.earlyAdherenceSettingValues[0];
		updatedAgency.adherence_setting_very_early_sec = this.earlyAdherenceSettingValues[1];
		updatedAgency.adherence_setting_late_min_sec = this.lateAdherenceSettingValues[0];
		updatedAgency.adherence_setting_very_late_sec = this.lateAdherenceSettingValues[1];
		updatedAgency.stale_sec = this.staleSettingsValues[0];
		updatedAgency.headway_adherence_close = this.headwayCloseValues[0];
		updatedAgency.headway_adherence_very_close = this.headwayCloseValues[1];
		updatedAgency.headway_adherence_distant = this.headwayDistantValues[0];
		updatedAgency.headway_adherence_very_distant = this.headwayDistantValues[1];
		updatedAgency.ridership_threshold_empty = this.ridershipAcceptingThresholdValues[0];
		updatedAgency.ridership_threshold_many = this.ridershipAcceptingThresholdValues[1];
		updatedAgency.ridership_threshold_few_standing = this.ridershipAcceptingThresholdValues[2];
		updatedAgency.ridership_threshold_crushed = this.ridershipNotAcceptingThresholdValues[0];
		updatedAgency.ridership_threshold_full = this.ridershipNotAcceptingThresholdValues[1];
		updatedAgency.ridership_threshold_not_accepting = this.ridershipNotAcceptingThresholdValues[2];

		updatedAgency.terminal_departure_max_seconds = this.maxExtensionValues[0];
		updatedAgency.terminal_departure_min_layover_seconds = this.minLayoverValues[0];
		updatedAgency.terminal_departure_min_dist_nav_service = this.minDistanceNavValues[0];
		updatedAgency.terminal_departure_default_deadhead_seconds = this.defaultDeadheadValues[0];
		updatedAgency.terminal_departure_apply_sched_layovers = this.editAgencySettingsForm.get('def_apply_sched_layovers').value;
		updatedAgency.terminal_departure_extend_all = this.editAgencySettingsForm.get('extend_all_departures').value;
		updatedAgency.terminal_departure_start_nav_service_throttle = this.editAgencySettingsForm.get('start_nav_service_throttle').value;
		updatedAgency.terminal_departure_end_nav_service_throttle = this.editAgencySettingsForm.get('end_nav_service_throttle').value;
		updatedAgency.terminal_departure_nav_service_call_frequency = this.callFrequencyNavValues[0];
		updatedAgency.terminal_departure_arrival_stop_radius = this.arrivalStopRadiusValues[0];
		updatedAgency.terminal_departure_exceptions = this.convertTerminalDepartureExceptionsFormArray();

		updatedAgency.terminal_departure_detect_departure_at_upcoming_stop =
			this.editAgencySettingsForm.get('detect_departure_at_upcoming_stop').value;

		updatedAgency.terminal_departure_detect_departure_stop_sequence =
			this.editAgencySettingsForm.get('detect_departure_stop_sequence').value;

		this.loading = true;

		const result: ResultContent = await this.agenciesAdminDataService.saveAgency(updatedAgency);

		if (result.success) {
			// agency has been updated - reload
			await this.agenciesDataService.loadAgencies();
			this.close(true);
		}
	};

	/**
	 * closes the edit dialog
	 *
	 * @param updated - whether the form has been edited
	 */
	public close = (updated: boolean): void => {
		this.modalRef.close(updated);
	};

	/**
	 * converts the form details regarding terminal departure exception into an equivalent object
	 *
	 * @returns the terminal departure exceptions instance from the form
	 */
	private convertTerminalDepartureExceptionsFormArray = (): TerminalDepartureRouteStopConfig[] => {
		const result: TerminalDepartureRouteStopConfig[] = [];

		for (const control of this.editAgencySettingsForm.get('terminal_departure_exceptions').value.controls) {
			result.push(control.value);
		}

		result.forEach((control: TerminalDepartureRouteStopConfig) => {
			if (!control.override_arrival_stop_radius) {
				control.arrival_stop_radius = null;
			}

			if (!control.override_apply_sched_layovers) {
				control.apply_sched_layovers = null;
			}

			if (!control.override_detect_departure_at_upcoming_stop) {
				control.detect_departure_at_upcoming_stop = null;
			}

			if (!control.override_min_layover_seconds) {
				control.min_layover_seconds = null;
			}

			if (!control.override_max_departure_extension) {
				control.max_extension_seconds = null;
			}

			if (!control.override_detect_departure_stop_sequence) {
				control.detect_departure_stop_sequence = null;
			}

			if (!control.override_default_deadhead_seconds) {
				control.default_deadhead_seconds = null;
			}

			if (!control.override_min_dist_nav_service) {
				control.min_dist_nav_service = null;
			}

			if (!control.override_start_end_nav_service_throttle) {
				control.start_nav_service_throttle = null;
				control.end_nav_service_throttle = null;
			}

			if (!control.override_nav_service_call_frequency) {
				control.nav_service_call_frequency = null;
			}

			if (control.stop.includes('ar') || control.stop.includes('allArr')) {
				control.apply_sched_layovers = null;
				control.max_extension_seconds = null;
				control.detect_departure_at_upcoming_stop = null;
				control.min_layover_seconds = null;
				control.detect_departure_stop_sequence = null;
			} else {
				control.arrival_stop_radius = null;
			}
		});

		return result;
	};

	/**
	 * loads the translations that the view needs
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_CORE.FORM.ERRORS.TIME_START', 'T_CORE.FORM.ERRORS.TIME_END', 'T_CORE.FORM.ERRORS.TIMERANGE_END']);
	};

	/**
	 * retrieves the current agency details
	 */
	private loadAgency = async (): Promise<void> => {
		this.loading = true;

		const result: ResultContent = await this.agenciesAdminDataService.getAgency(this.authorityId, this.agencyId);

		if (result.success) {
			this.agency = result.resultData;
			this.setSliderValues();
		}

		this.loading = false;
	};

	/**
	 * updates all of the sliders on the form with their configured values
	 */
	private setSliderValues = (): void => {
		this.iminentDepartureValues = [this.agency.imminent_departure];

		this.earlyAdherenceSettingValues = [this.agency.adherence_setting_early_min_sec, this.agency.adherence_setting_very_early_sec];

		this.lateAdherenceSettingValues = [this.agency.adherence_setting_late_min_sec, this.agency.adherence_setting_very_late_sec];

		this.staleSettingsValues = [this.agency.stale_sec];

		this.headwayCloseValues = [this.agency.headway_adherence_close, this.agency.headway_adherence_very_close];

		this.headwayDistantValues = [this.agency.headway_adherence_distant, this.agency.headway_adherence_very_distant];

		this.ridershipAcceptingThresholdValues = [
			this.agency.ridership_threshold_empty,
			this.agency.ridership_threshold_many,
			this.agency.ridership_threshold_few_standing,
		];

		this.ridershipNotAcceptingThresholdValues = [
			this.agency.ridership_threshold_crushed,
			this.agency.ridership_threshold_full,
			this.agency.ridership_threshold_not_accepting,
		];

		this.maxExtensionValues = [this.agency.terminal_departure_max_seconds];

		this.minLayoverValues = [this.agency.terminal_departure_min_layover_seconds];

		this.minDistanceNavValues = [this.agency.terminal_departure_min_dist_nav_service];

		this.defaultDeadheadValues = [this.agency.terminal_departure_default_deadhead_seconds];

		this.startTimeNavValues = [this.agency.terminal_departure_start_nav_service_throttle / 60];

		this.endTimeNavValues = [this.agency.terminal_departure_end_nav_service_throttle / 60];

		this.callFrequencyNavValues = [this.agency.terminal_departure_nav_service_call_frequency];

		this.arrivalStopRadiusValues = [this.agency.terminal_departure_arrival_stop_radius];
	};

	/**
	 * customises the relevant error text if some of the form data is invalid
	 *
	 * @returns the customised error text from the form
	 */
	private getCustomTimeErrorText = (): boolean => {
		let hasDateError: boolean = false;

		this.customTimeErrorText = '';
		this.customStartTimeErrorText = '';
		this.customEndTimeErrorText = '';

		if (!hasDateError) {
			if (this.editAgencySettingsForm.controls.start_nav_service_throttle.hasError('invalid')) {
				hasDateError = true;
				this.customStartTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIME_START'];
			}

			if (this.editAgencySettingsForm.controls.end_nav_service_throttle.hasError('invalid')) {
				hasDateError = true;
				this.customEndTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIME_END'];
			}

			if (this.editAgencySettingsForm.controls.start_nav_service_throttle.hasError('start-time-greater')) {
				hasDateError = true;
				this.customTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIMERANGE_END'];
			}
		}

		return hasDateError;
	};

	/**
	 * handle the on load of the external links file
	 *
	 * @param fileReader - the file reader instance
	 */
	private onLoadExternalLinksFileReader = (fileReader: FileReader): void => {
		const text: string | ArrayBuffer = fileReader.result;

		if (typeof text === 'string') {
			this.editAgencySettingsForm.patchValue({ external_links: text });
		}
	};
}

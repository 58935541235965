/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable()
export class SignsApiService {
	private readonly signsUrl: string = '/signs';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the signs for the given authority/agency from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns response containing signs
	 */
	public getSigns = async (authorityId: string, agencyId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.signsUrl);
		} catch (exception) {
			throw exception;
		}
	};
}

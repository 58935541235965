/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { CurrentBlocksEditComponent } from '../widgets/current-blocks/current-blocks-edit/current-blocks-edit.component';
import { CurrentBlocksListComponent } from '../widgets/current-blocks/current-blocks-list/current-blocks-list.component';

import { HeadwayPerformanceComponent } from '../widgets/headway-performance/headway-performance/headway-performance.component';
import { OccupancyEditComponent } from '../widgets/occupancy/occupancy-edit/occupancy-edit.component';
import { OccupancyComponent } from '../widgets/occupancy/occupancy/occupancy.component';
import { PerformanceEditComponent } from '../widgets/performance/performance-edit/performance-edit.component';
import { PerformanceComponent } from '../widgets/performance/performance/performance.component';
import { SchedulePerformanceComponent } from '../widgets/schedule-performance/schedule-performance/schedule-performance.component';
import { TrafficEditComponent } from '../widgets/traffic/traffic-edit/traffic-edit.component';
import { TrafficComponent } from '../widgets/traffic/traffic/traffic.component';
import { TwitterEditComponent } from '../widgets/twitter/twitter-edit/twitter-edit.component';
import { TwitterComponent } from '../widgets/twitter/twitter/twitter.component';
import { WeatherEditComponent } from '../widgets/weather/weather-edit/weather-edit.component';
import { WeatherComponent } from '../widgets/weather/weather/weather.component';
import { VehicleEventSettingsWidgetComponent } from '../widgets/vehicle-events/settings/vehicle-event-settings-widget.component';
import { VehicleEventDetailsWidgetComponent } from '../widgets/vehicle-events/details/vehicle-event-details-widget.component';
import { ExternalLinksViewComponent } from '../widgets/external-links/external-links-view/external-links-view.component';
import { ExternalLinksEditComponent } from '../widgets/external-links/external-links-edit/external-links-edit.component';

import { HeadwayPerformanceEditComponent } from '../widgets/headway-performance/headway-performance-edit/headway-performance-edit.component';
import { SchedulePerformanceEditComponent } from '../widgets/schedule-performance/schedule-performance-edit/schedule-performance-edit.component';

import { DeadheadingVehiclesViewComponent } from '../widgets/deadheading-vehicles/deadheading-vehicles-view/deadheading-vehicles-view.component';

import { DeadheadingVehiclesEditComponent } from '../widgets/deadheading-vehicles/deadheading-vehicles-edit/deadheading-vehicles-edit.component';

import { defaultIncludedEventTypeIds, defaultSort, defaultVehicleFilter, VehicleEventsSettings } from '../../vehicle-events/types/types';
import { defaultCategoryFilterOptions } from '../../vehicle-events/types/types';
import { IStructure, IWidget } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class DashboardConfigService {
	public widgets: IWidget[] = [
		{
			name: 'current-blocks',
			order: 1,
			title: 'T_DASH.DB_UPCOMING_BLOCKS',
			thumbUrl: '/assets/img/DashboardThumbnails/upcomingblocks-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/upcomingblocks-thumb-color.svg',
			description: 'T_DASH.DB_DISPLAYS_CURRENT_AND_UPCOMING_BLOCKS',
			config: {
				display: 'unassigned',
				refreshRateSec: 300,
				versionToggle: 'Routes',
				hours: { id: 2, name: 2 },
				progress: 'upcoming',
			},
			component: CurrentBlocksListComponent,
			editComponent: CurrentBlocksEditComponent,
		},
		{
			name: 'schedule-performance',
			order: 3,
			title: 'T_DASH.DB_SCHEDULE_PERFORMANCE',
			thumbUrl: '/assets/img/DashboardThumbnails/scheduleperformance-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/scheduleperformance-thumb-color.svg',
			description: 'T_DASH.DB_DISPLAYS_SCHEDULE_ADHERENCE_PERFORMANCE',
			config: {
				display: 'chartList',
				chartDisplay: 'perc',
				displayAdherence: true,
				displayUnassigned: false,
				displayOffRoute: true,
				displayEarly: true,
				displayLate: true,
				displayOnTime: true,
				displayVeryEarly: true,
				displayVeryLate: true,
				listSize: { option: '5', value: 5 },
				refreshRateSec: 60,
				vehicleFilterSelection: { value: defaultVehicleFilter },
				selectedVehicles: [],
				selectedDepots: [],
			},
			component: SchedulePerformanceComponent,
			editComponent: SchedulePerformanceEditComponent,
		},
		{
			name: 'performance',
			order: 9,
			title: 'T_DASH.DB_PERFORMANCE',
			thumbUrl: '/assets/img/DashboardThumbnails/performance-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/performance-thumb-color.svg',
			description: 'T_DASH.DB_DISPLAYS_PERFORMANCE',
			config: {
				timeRangeMinutes: 30,
				timeIntervalMinutes: 1,
				displayVeryEarly: true,
				displayEarly: true,
				displayOnTime: true,
				displayLate: true,
				displayVeryLate: true,
				displayHeadwayVeryClose: true,
				displayHeadwayClose: true,
				displayHeadwayOkay: true,
				displayHeadwayDistant: true,
				displayHeadwayVeryDistant: true,
				displayPredictability: false,
				displayPredictionAccuracyAll: false,
				displayPredictionAccuracy0to5: false,
				displayPredictionAccuracy5to10: false,
				displayPredictionAccuracy10to15: false,
				refreshRateSec: 60,
				includeAllRoutes: true,
			},
			component: PerformanceComponent,
			editComponent: PerformanceEditComponent,
		},
		{
			name: 'vehicle-events',
			order: 2,
			title: 'T_DASH.DB_VEHICLE_EVENTS',
			thumbUrl: '/assets/img/DashboardThumbnails/vehicleevents-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/vehicleevents-thumb-color.svg',
			description: 'T_DASH.EVENTS.WIDGET_DESC',
			config: {
				refreshRateSec: 60,
				selectedAgency: null,
				categoryFilterOptions: defaultCategoryFilterOptions,
				sortBy: defaultSort,
				includedEventTypeIds: defaultIncludedEventTypeIds,
				selectedVehicles: { vehicles: [] },
				selectedDepots: { depots: [] },
				selectedRoutes: { routes: [] },
				vehicleFilterSelection: { value: defaultVehicleFilter },
			} as VehicleEventsSettings,
			component: VehicleEventDetailsWidgetComponent,
			editComponent: VehicleEventSettingsWidgetComponent,
		},
		{
			name: 'twitter',
			order: 8,
			title: 'T_DASH.DB_TWITTER',
			thumbUrl: '/assets/img/DashboardThumbnails/twitter-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/twitter-thumb-color.svg',
			description: 'T_DASH.DB_TWITTER_DESCRIPTION',
			config: {
				refreshRateSec: 300,
			},
			component: TwitterComponent,
			editComponent: TwitterEditComponent,
		},
		{
			name: 'weather',
			order: 6,
			title: 'T_DASH.DB_WEATHER',
			thumbUrl: '/assets/img/DashboardThumbnails/weather-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/weather-thumb-color.svg',
			description: 'T_DASH.DB_WEATHER_DESCRIPTION',
			config: {
				timeFrame: 60,
				refreshRateSec: 900,
			},
			component: WeatherComponent,
			editComponent: WeatherEditComponent,
		},
		{
			name: 'traffic',
			order: 5,
			title: 'T_DASH.DB_TRAFFIC',
			thumbUrl: '/assets/img/DashboardThumbnails/traffic-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/traffic-thumb-color.svg',
			description: 'T_DASH.DB_TRAFFIC_DESCRIPTION',
			config: {
				trafficRefreshTime: 10,
			},
			component: TrafficComponent,
			editComponent: TrafficEditComponent,
		},
		{
			name: 'occupancy',
			order: 11,
			title: 'T_DASH.DB_OCCUPANCY',
			thumbUrl: '/assets/img/DashboardThumbnails/occupancy-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/occupancy-thumb-color.svg',
			description: 'T_DASH.DB_OCCUPANCY_DESCRIPTION',
			config: {
				routes: 'all',
				display: 'chartList',
				listSize: { option: '10', value: '10' },
				refreshRateSec: 300,
			},
			component: OccupancyComponent,
			editComponent: OccupancyEditComponent,
		},
		{
			name: 'headway-performance',
			order: 12,
			title: 'T_DASH.DB_HEADWAY_PERFORMANCE',
			thumbUrl: '/assets/img/DashboardThumbnails/headway-performance-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/headway-performance-thumb-color.svg',
			description: 'T_DASH.DB_HEADWAY_PERFORMANCE_DESCRIPTION',
			config: {
				refreshRateSec: 300,
				display: 'chartList',
				listSize: { option: '5', value: '5' },
			},
			component: HeadwayPerformanceComponent,
			editComponent: HeadwayPerformanceEditComponent,
		},
		{
			name: 'external-links',
			order: 13,
			title: 'T_DASH.DB_EXTERNAL_LINKS',
			thumbUrl: '/assets/img/DashboardThumbnails/agencylinks-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/agencylinks-thumb-color.svg',
			description: 'T_DASH.DB_EXTERNAL_LINKS_DESCRIPTION',
			config: {},
			component: ExternalLinksViewComponent,
			editComponent: ExternalLinksEditComponent,
		},
		{
			name: 'deadheading-widget',
			order: 14,
			title: 'T_DASH.DEADHEADING_VEHICLES',
			thumbUrl: '/assets/img/DashboardThumbnails/deadhead-thumb.svg',
			thumbHoverUrl: '/assets/img/DashboardThumbnails/deadhead-thumb-color.svg',
			description: 'T_DASH.DEADHEADING_VEHICLES_DESCRIPTION',
			config: {
				refreshRateSec: 300,
			},
			component: DeadheadingVehiclesViewComponent,
			editComponent: DeadheadingVehiclesEditComponent,
		},
	];

	public structures: IStructure[] = [
		{
			name: '6-6',
			rows: [
				{
					styleClass: 'row-height-100',
					columns: [
						{
							styleClass: 'col-md-6',
							class: 'col-md-6',
						},
						{
							styleClass: 'col-md-6',
							class: 'col-md-6',
						},
					],
				},
			],
		},
		{
			name: '6-6/12',
			rows: [
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-md-6',
							class: 'col-md-6',
						},
						{
							styleClass: 'col-md-6',
							class: 'col-md-6',
						},
					],
				},
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-md-12',
							class: 'col-md-12',
						},
					],
				},
			],
		},
		{
			name: '4-4-4/12',
			rows: [
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
					],
				},
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-md-12',
							class: 'col-md-12',
						},
					],
				},
			],
		},
		{
			name: '4-4-4',
			rows: [
				{
					styleClass: 'row-height-100',
					columns: [
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
					],
				},
			],
		},
		{
			name: '12/6-6',
			rows: [
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-md-12',
							class: 'col-md-12',
						},
					],
				},
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-md-6',
							class: 'col-md-6',
						},
						{
							styleClass: 'col-md-6',
							class: 'col-md-6',
						},
					],
				},
			],
		},
		{
			name: '12/4-4-4',
			rows: [
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-md-12',
							class: 'col-md-12',
						},
					],
				},
				{
					styleClass: 'row-height-50',
					columns: [
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
						{
							styleClass: 'col-lg-4 col-md-6',
							class: 'col-lg-4 col-md-4',
						},
					],
				},
			],
		},
	];
}

export class ObjectHelpers {
	/**
	 * Gets the value at path of object.
	 * If the resolved value is undefined, the defaultValue is returned in its place.
	 *
	 * @param obj - The object to look from.
	 * @param path - The path to look along.
	 * @param defValue - Default value if not found in the path.
	 * @returns The value.
	 */
	public static get = (obj: any, path: any, defValue: any = undefined): void => {
		// If path is not defined or it has false value
		if (!path) {
			return undefined;
		}

		// Check if path is string or array.
		// Regex : ensure that we do not have '.' and brackets.
		const pathArray: any[] = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
		// Find value
		const result: any[] = pathArray.reduce((prevObj: any, key: any) => prevObj && prevObj[key], obj);

		// If found value is undefined return default value; otherwise return the value
		return result === undefined ? defValue : result;
	};

	/**
	 * takes an array and returns the same array with the top x items based on the value passed in
	 *
	 * @param arr - the original array
	 * @param qty - the amount of array elements to keep in the returned array
	 * @returns a new array containing only the elements for the first x indices of the original array
	 */
	public static take = (arr: any, qty: number = 1): any[] => {
		return [...arr].splice(0, qty);
	};

	/**
	 * Assigns own and inherited enumerable string keyed properties of source objects to
	 * the destination object for all destination properties that resolve to undefined.
	 * Source objects are applied from left to right. Once a property is set, additional
	 * values of the same property are ignored.
	 *
	 * @param args - the propert arguments to default
	 * @returns the defaulted object
	 */
	public static defaults = (...args: any[]): any => {
		return args.reverse().reduce((acc, obj) => ({ ...acc, ...obj }), {});
	};

	/**
	 * Creates a deep copy of the supplied parameter and preserves it and its members types.
	 *
	 * @param source - The object to be deep copied.
	 * @returns The deep copied object.
	 */
	public static deepCopy<T>(source: T): T {
		// Check it's type and copy accordingly
		return Array.isArray(source)
			? source.map((item) => this.deepCopy(item))
			: source instanceof Date
				? new Date(source.getTime())
				: source && typeof source === 'object'
					? Object.getOwnPropertyNames(source).reduce(
							(o, prop) => {
								Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
								o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);

								return o;
							},
							Object.create(Object.getPrototypeOf(source))
						)
					: (source as T);
	}

	/**
	 * Object equality check.
	 *
	 * @param x - Object x.
	 * @param y - Object y.
	 * @returns True if they are equal, false otherwise.
	 */
	public static deepEquals = (x: any, y: any): boolean => {
		if (x === y) {
			return true; // if both x and y are null or undefined and exactly the same
		} else if (!(x instanceof Object) || !(y instanceof Object)) {
			return false; // if they are not strictly equal, they both need to be Objects
		} else if (x.constructor !== y.constructor) {
			// they must have the exact same prototype chain, the closest we can do is
			// test their constructor.
			return false;
		} else {
			for (const p in x) {
				if (!x.hasOwnProperty(p)) {
					continue; // other properties were tested using x.constructor === y.constructor
				}

				if (!y.hasOwnProperty(p)) {
					return false; // allows to compare x[ p ] and y[ p ] when set to undefined
				}

				if (x[p] === y[p]) {
					continue; // if they have the same strict value or identity then they are equal
				}

				if (typeof x[p] !== 'object') {
					return false; // Numbers, Strings, Functions, Booleans must be strictly equal
				}

				if (!ObjectHelpers.deepEquals(x[p], y[p])) {
					return false;
				}
			}

			for (const p in y) {
				if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
					return false;
				}
			}

			return true;
		}
	};

	/**
	 * Simple object check.
	 *
	 * @param item - the item being checked.
	 * @returns True if it is an object.
	 */
	public static isObject = (item: any): boolean => {
		return item && typeof item === 'object' && !Array.isArray(item);
	};

	/**
	 * Deep merge two objects.
	 *
	 * @param target - end product of the merge.
	 * @param sources - ...sources to mergr from.
	 * @returns the merged object
	 */
	public static mergeDeep = (target: any, ...sources: any[]): any => {
		if (!sources?.length) {
			return target;
		}

		const source: any[] = sources.shift();

		if (ObjectHelpers.isObject(target) && ObjectHelpers.isObject(source)) {
			for (const key in source) {
				if (ObjectHelpers.isObject(source[key])) {
					if (!target[key]) {
						Object.assign(target, { [key]: {} });
					}

					ObjectHelpers.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		return ObjectHelpers.mergeDeep(target, ...sources);
	};

	/**
	 * prepares a nested object to be 'flattened'
	 *
	 * see flattenObj for the 'flatten' definition
	 *
	 * @param object - object to flatten
	 * @returns the flattened data
	 */
	public static flattenObjectProperties = (object: any): void => {
		const output: any = {};

		ObjectHelpers.flattenObj(object, null, output);

		return output;
	};

	/**
	 * flattens a nested object in to an object with no nested properties
	 *
	 * a: 'blue',
	 * b: \{
	 *    c: 'red',
	 *    d: 'green',
	 * \}
	 *
	 * would become
	 *
	 * a: 'blue'
	 * b.c: 'red',
	 * b.d: 'green',
	 *
	 * @param obj - object to flatten
	 * @param parent - the object parent
	 * @param res - the resulted object
	 * @returns the flattened object
	 */
	private static flattenObj = (obj: any, parent: any, res: any = {}): void => {
		for (const key in obj) {
			const propName: string = parent ? parent + '.' + key : key;

			if (typeof obj[key] !== 'function') {
				if (typeof obj[key] === 'object') {
					ObjectHelpers.flattenObj(obj[key], propName, res);
				} else {
					res[propName] = obj[key];
				}
			}
		}

		return res;
	};
}

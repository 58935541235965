import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { DepotsModule } from '../../../support-features/depots/depots.module';
import { StopsModule } from '../../../support-features/stops/stops.module';
import { AgencyPortalUtilsModule } from '../../../utils/agency-portal-utils.module';

import { TerminalDepartureConfigComponent } from './view/settings/edit/terminal-departure-config/terminal-departure-config.component';
import { AgenciesAdminListComponent } from './list/agencies-admin-list.component';
import { AgencyAdminViewComponent } from './view/agency-admin-view.component';
import { AgencyAdminViewDetailsComponent } from './view/details/agency-admin-view-details.component';
import { AgencyAdminViewSettingsComponent } from './view/settings/agency-admin-view-settings.component';
import { EditAgencyAdminSettingsComponent } from './view/settings/edit/edit-agency-admin-settings.component';

import { AgenciesAdminApiService } from './services/agencies-admin-api.service';
import { AgenciesAdminDataService } from './services/agencies-admin-data.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		AgencyPortalUtilsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		DepotsModule,
		StopsModule,
	],
	declarations: [
		AgenciesAdminListComponent,
		AgencyAdminViewComponent,
		AgencyAdminViewSettingsComponent,
		AgencyAdminViewDetailsComponent,
		EditAgencyAdminSettingsComponent,
		TerminalDepartureConfigComponent,
	],
	providers: [AgenciesAdminDataService, AgenciesAdminApiService],
	exports: [],
})
export class AdminToolsAgenciesModule {}

<div class="vehicle-events-infoblock-container" (click)="routeToMapDetailsPanel(event)">
	<div class="vehicle-events-infoblock">
		<vehicle-event-icon [category]="event?.category" data-test="event.category"></vehicle-event-icon>
		<div class="vehicle-events-text">
			<div class="nb-text vehicle-events-text-panel">
				<div class="nb-text vehicle-events-text-panel-title">
					<span class="nb-title-sm" data-test="event.title">{{ getTranslation('T_DASH.EVENTS.TITLES.' + event.type) }}</span>
				</div>
				<div class="vehicle-events-text-panel-detail">
					<route-pill *ngIf="event.route" [route]="routeData" [useFullWidth]="true" data-test="event.route.pill"></route-pill>
					<div class="truncate vehicle-events-text-panel-detail-longname">
						<span *ngIf="event.route?.route_long_name" class="nb-padding-left-sm" data-test="event.route.name">{{
							event.route?.route_long_name
						}}</span>
					</div>
				</div>
				<div class="vehicle-events-text-panel-detail">
					<span *ngIf="event.vehicle_id" class="nb-inline-label nb-padding-right-xs">{{ 'T_DASH.DB_VEHICLE' | translate }}</span>
					<span *ngIf="event.vehicle_id" data-test="event.vehicle">{{ event.vehicle_id }}</span>
				</div>
				<div class="vehicle-events-text-panel-detail">
					<span *ngIf="event.block_id" class="nb-inline-label nb-padding-right-xs">{{ 'T_DASH.DB_BLOCK' | translate }}</span>
					<span *ngIf="event.block_id" data-test="event.block">{{ event.block_id }}</span>
				</div>
				<div class="vehicle-events-text-panel-detail">
					<span *ngIf="event.driver_name" class="nb-inline-label nb-padding-right-xs">{{ 'T_DASH.DB_DRIVER' | translate }}</span>
					<span *ngIf="event.driver_name" data-test="event.driver">{{ event.driver_name }}</span>
				</div>
				<div class="vehicle-events-text-panel-detail">
					<span class="nb-timestamp" data-test="event.created">{{ getFormattedCreatedAt(event?.created_at) }}</span>
				</div>
			</div>
		</div>
	</div>
</div>

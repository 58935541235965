/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { RoutesApiService } from './routes-api.service';

import {
	RouteMetricsResults,
	OccupancyWidgetData,
	RouteBlocksPaginatedResponse,
	NormalizedStopsForRoute,
	RoutesExtended,
	RoutesExtendedPaginatedResponse,
	HeadwayPerformanceWidgetData,
} from '../types/api-types';

import { PerformanceMetricsDisplayIncludes } from '../types/types';

import { PerformanceMetricParams, Route, Routes, RoutesPaginatedResponse, RouteSummaries } from '../types/api-types';

import { ResultContent } from '@cubicNx/libs/utils';
import { PaginatedParams } from '@cubicNx/libs/utils';

import { MapRoute } from '../../../features/map/types/types';

@Injectable({
	providedIn: 'root',
})
export class RoutesDataService {
	constructor(
		private logger: LoggerService,
		private routesApiService: RoutesApiService
	) {}

	/**
	 * handles the request for the routes list from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param pageNumber - the page number to request
	 * @param pageSize - the page size to request
	 * @param sortBy - the sort by field to request
	 * @param sortDir - the sort direction to request
	 * @param search - the search filter text to request
	 * @returns the result object with the routes list
	 */
	public getRoutesList = async (
		authorityId: string,
		agencyId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string = null
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: PaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir }),
				...(search && { search }),
			};

			const response: any = await this.routesApiService.getRoutes(authorityId, agencyId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as RoutesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: RoutesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as RoutesPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get routes list.', error);
		}

		return result;
	};

	/**
	 * handles the request for the routes (extended) list from the nextbus API
	 *
	 * This version requests addition route detail such as route stops and shape data
	 * and handled paginated data
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param pageNumber - the page number to request
	 * @param pageSize - the page size to request
	 * @param sortBy - the sort by field to request
	 * @param sortDir - the sort direction to request
	 * @param search - the search filter text to request
	 * @returns the result object with the extended routes list
	 */
	public getRoutesListExtended = async (
		authorityId: string,
		agencyId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string = null
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: PaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir }),
				...(search && { search }),
			};

			const response: any = await this.routesApiService.getRoutesExtended(authorityId, agencyId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as RoutesExtendedPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: RoutesExtendedPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as RoutesExtendedPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get routes list.', error);
		}

		return result;
	};

	/**
	 * requests the vehicles from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the result object with all routes
	 */
	public getRoutes = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const routes: Routes = await this.routesApiService.getRoutes(authorityId, agencyId);

			result.success = true;
			result.resultData = routes;
		} catch (error) {
			this.logger.logError(`Failed to get routes for: ${agencyId}`, error);
		}

		return result;
	};

	/**
	 * handles the request for the routes (extended) list from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the result object with all routes
	 */
	public getRoutesExtended = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const routes: RoutesExtended = await this.routesApiService.getRoutesExtended(authorityId, agencyId);

			result.success = true;
			result.resultData = routes;
		} catch (error) {
			this.logger.logError(`Failed to get routes for: ${agencyId}`, error);
		}

		return result;
	};

	/**
	 * handles the request of a single route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id for the request
	 * @returns the result object with the single route
	 */
	public getRoute = async (authorityId: string, agencyId: string, routeId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const route: Route = await this.routesApiService.getRoute(authorityId, agencyId, routeId);

			result.success = true;
			result.resultData = route;
		} catch (error) {
			this.logger.logError('Failed to get route.', error);
		}

		return result;
	};

	/**
	 * handles the request of the route suggestions from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns all routes suggestions
	 */
	public getRouteSuggestions = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		return await this.getRoutes(authorityId, agencyId);
	};

	/**
	 * handles the request of the route block suggestions from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id
	 * @param search - the search filter
	 * @returns all routes block suggestions that pass the filter
	 */
	public getRouteBlocksSuggestions = async (
		authorityId: string,
		agencyId: string,
		routeId: string,
		search: string = null
	): Promise<ResultContent> => {
		return await this.getRouteBlocks(authorityId, agencyId, routeId, 1, 50, 'block_id', 'asc', search);
	};

	/**
	 * handles the request blocks for routes from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the id of the route for the request
	 * @param pageNumber - the page number to request
	 * @param pageSize - the page size to request
	 * @param sortBy - the sort by field to request
	 * @param sortDir - the sort direction to request
	 * @param search - the search filter text to request
	 * @returns the result object with  route blocks
	 */
	public getRouteBlocks = async (
		authorityId: string,
		agencyId: string,
		routeId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: PaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir }),
				...(search && { search }),
			};

			const response: any = await this.routesApiService.getRouteBlocks(authorityId, agencyId, routeId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as RouteBlocksPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: RouteBlocksPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as RouteBlocksPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get blocks for route:' + routeId, error);
		}

		return result;
	};

	/**
	 * handles the request for performance metrics for route(s) from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param timezone - the current timezone
	 * @param timeRangeMinutes - the time range in minutes
	 * @param timeIntervalMinutes - the time interval in minutes
	 * @param displayIncludes - the metrics to include
	 * @param includeAllRoutes - a flag indicating whether to include all routes
	 * @param includeRoutes - the routes to inlcude
	 * @returns the result object with route performance metrics
	 */
	public getPerformanceMetrics = async (
		authorityId: string,
		agencyId: string,
		timezone: string,
		timeRangeMinutes: number,
		timeIntervalMinutes: number,
		displayIncludes: PerformanceMetricsDisplayIncludes,
		includeAllRoutes: boolean,
		includeRoutes: RouteSummaries
	): Promise<ResultContent> => {
		const emptyObj: RouteMetricsResults = {
			routeMetrics: [],
			predictionMetrics: [],
		};

		const result: ResultContent = {
			success: false,
			resultData: emptyObj,
		};

		try {
			if (authorityId.length > 0 && agencyId.length > 0) {
				const params: PerformanceMetricParams = {
					timezone,
					timeRangeMinutes,
					timeIntervalMinutes,
					includeVeryEarly: !!displayIncludes.displayVeryEarly,
					includeEarly: !!displayIncludes.displayEarly,
					includeOnTime: !!displayIncludes.displayOnTime,
					includeLate: !!displayIncludes.displayLate,
					includeVeryLate: !!displayIncludes.displayVeryLate,
					includePredictability: !!displayIncludes.displayPredictability,
					includePredictionAccuracyAll: !!displayIncludes.displayPredictionAccuracyAll,
					includePredictionAccuracy0to5: !!displayIncludes.displayPredictionAccuracy0to5,
					includePredictionAccuracy5to10: !!displayIncludes.displayPredictionAccuracy5to10,
					includePredictionAccuracy10to15: !!displayIncludes.displayPredictionAccuracy10to15,
					includeHeadwayVeryClose: !!displayIncludes.displayHeadwayVeryClose,
					includeHeadwayClose: !!displayIncludes.displayHeadwayClose,
					includeHeadwayOkay: !!displayIncludes.displayHeadwayOkay,
					includeHeadwayDistant: !!displayIncludes.displayHeadwayDistant,
					includeHeadwayVeryDistant: !!displayIncludes.displayHeadwayVeryDistant,
					includeAllRoutes,
					includeRoutes,
					includeBlocksUnassigned: !!displayIncludes.displayBlocksUnassigned,
					includePredictableVehicles: !!displayIncludes.displayPredictableVehicles,
				};

				const response: any = await this.routesApiService.getPerformanceMetrics(authorityId, agencyId, params);

				result.success = true;
				result.resultData = response as RouteMetricsResults;
			}
		} catch (error) {
			this.logger.logError('Failed to get performance metrics.', error);
		}

		return result;
	};

	/**
	 * handles the request for headway performance for a route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeIdList - the list of route ids for the request
	 * @param params - additional info for the request
	 * @returns the result object with route headway performance data
	 */
	public getHeadwayPerformance = async (authorityId: string, agencyId: string, routeIdList: string[]): Promise<ResultContent> => {
		const emptyObj: HeadwayPerformanceWidgetData = {
			vehicleDisplay: [],
			svgDisplay: {
				onTime: 0,
				close: 0,
				veryClose: 0,
				distant: 0,
				veryDistant: 0,
				total: 0,
			},
			routeDisplay: [],
		};

		const result: ResultContent = {
			success: false,
			resultData: emptyObj,
		};

		try {
			if (authorityId && authorityId) {
				const params: any = {
					headwayPerformance: 'true',
				};

				const response: any = await this.routesApiService.getHeadwayPerformance(authorityId, agencyId, routeIdList, params);

				result.success = true;
				result.resultData = response as HeadwayPerformanceWidgetData;
			}
		} catch (error) {
			this.logger.logError('Failed to get headway performance for routes', routeIdList);
			this.logger.logError('Failed to get headway performance for routes - error', error);
		}

		return result;
	};

	/**
	 * handles the request for headway performance for a route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id for the request
	 * @param hasList - the has list flag for the request
	 * @returns the result object with the route occupancy data
	 */
	public getOccupancy = async (authorityId: string, agencyId: string, routeId: string, hasList: boolean): Promise<ResultContent> => {
		const emptyObj: OccupancyWidgetData = {
			svgDisplay: {
				fewSeatsAvailable: 0,
				manySeatsAvailable: 0,
				unknown: 0,
				total: 0,
			},
			listValue: [],
		};

		const result: ResultContent = {
			success: false,
			resultData: emptyObj,
		};

		try {
			if (authorityId && authorityId) {
				const params: any = {
					occupancyWidget: 'true',
					occupancyList: hasList ? 'true' : 'false',
				};

				const response: any = await this.routesApiService.getOccupancy(authorityId, agencyId, routeId, params);

				result.success = true;
				result.resultData = response as OccupancyWidgetData;
			}
		} catch (error) {
			this.logger.logError('Failed to get occupancy for route:' + routeId, error);
		}

		return result;
	};

	/**
	 * handles the request for route normalized stops from the nextbus API
	 *
	 * @param route - the route object for the request
	 * @returns the result object with the route normalized stops
	 */
	public getRouteNormalizedStops = async (route: MapRoute): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const stopsInfo: NormalizedStopsForRoute = await this.routesApiService.getRouteNormalizedStops(
				route.authorityId,
				route.agencyId,
				route.routeId
			);

			result.success = true;
			result.resultData = stopsInfo;
		} catch (error) {
			this.logger.logError('Failed to get route normalized stops.', error);
		}

		return result;
	};
}

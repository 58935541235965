/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ConfigService } from '@cubicNx/libs/utils';
import { DataService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'user-status-change',
	templateUrl: './user-status-change.component.html',
	styleUrls: ['./user-status-change.component.scss'],
})
export class UserStatusChangeComponent extends TranslateBaseComponent implements OnInit {
	public user: any = null;
	public userRoles: any[] = [];

	constructor(
		private configService: ConfigService,
		public data: DataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the user status change view
	 */
	public ngOnInit(): void {
		this.user = this.data['user' as keyof DataService];

		// this logic was originally in data-service-users (before angular refactor) when building
		// up the template.  It suggests the data could be in user_roles.roles or at the root of
		// user_roles.  This allows us to iterate over user roles regardless but not sure if it is
		// needed. Could the html template (ngFor) always just iterate over user_role.roles???
		this.userRoles = this.user.user_roles;

		if (this.user.user_roles.roles) {
			this.userRoles = this.user.user_roles.roles;
		}

		// filter the roles that pass the condition - again honouring older logic -
		// may not be needed if the filter always returns the full set
		this.userRoles = this.userRoles.filter(
			(role) => role.agency_info || role.associated_role.name === this.configService.getAdminMasterRoleName()
		);
	}
}

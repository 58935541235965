<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_AGENCY.AGENCY_SETTINGS' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<div [hidden]="!loading">
		<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
	</div>
	<form [hidden]="loading" *ngIf="editAgencySettingsForm" [formGroup]="editAgencySettingsForm">
		<div class="row" id="detailContainer">
			<div class="modal-neutral-bg nb-padding-all-md">
				<div class="panel panel-default nb-margin-bottom-md">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleGeneralSection()">
						<div class="nb-subhead">{{ 'T_AGENCY.GENERAL' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i class="sectionTitle" [ngClass]="generalSectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div class="panel-body" [hidden]="generalSectionCollapsed">
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label [name]="'agency_lang'" [label]="'T_AGENCY.SETTINGS.AGENCY_LANG' | translate" [required]="true"></field-label>
								<select formControlName="agency_lang_id" class="form-control">
									<option value="17">{{ 'T_AGENCY.SETTINGS.LANGUAGE.ENGLISH' | translate }}</option>
									<option value="19">{{ 'T_AGENCY.SETTINGS.LANGUAGE.SPANISH' | translate }}</option>
									<option value="24">{{ 'T_AGENCY.SETTINGS.LANGUAGE.FRENCH' | translate }}</option>
								</select>
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'unit_of_measure'"
									[label]="'T_AGENCY.SETTINGS.UNIT_OF_MEASURE' | translate"
									[required]="true"></field-label>
								<select formControlName="unit_of_measure" class="form-control">
									<option value="metric">{{ 'T_AGENCY.SETTINGS.METRIC' | translate }}</option>
									<option value="imperial">{{ 'T_AGENCY.SETTINGS.IMPERIAL' | translate }}</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label [name]="'date_format'" [label]="'T_AGENCY.SETTINGS.DATE_FORMAT' | translate" [required]="true"></field-label>
								<select formControlName="date_format_nb_id" class="form-control">
									<option value="1">YYYY-MM-DD</option>
									<option value="2">MM-DD-YYYY</option>
									<option value="3">MM/DD/YYYY</option>
									<option value="4">DD-MM-YYYY</option>
									<option value="5">DD/MM/YYYY</option>
								</select>
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label [name]="'time_format'" [label]="'T_AGENCY.SETTINGS.TIME_FORMAT' | translate" [required]="true"></field-label>
								<select formControlName="time_format_nb_id" class="form-control">
									<option value="1">HH:mm</option>
									<option value="2">hh:mm</option>
									<option value="3">HH:mm:ss</option>
									<option value="4">hh:mm:ss</option>
									<option value="5">hh:mm a</option>
									<option value="6">hh:mm:ss a</option>
									<!-- moment defines hours as H or HH (for military time) or h hh (for regular time), minutes as m or mm, and seconds as s or ss -->
								</select>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<div
									[ngClass]="{
										'has-error': editAgencySettingsForm.controls.latitude.touched && editAgencySettingsForm.controls.latitude.errors,
									}">
									<field-label [name]="'latitude'" [label]="'T_AGENCY.SETTINGS.LATITUDE' | translate" [required]="false"></field-label>
									<input
										formControlName="latitude"
										class="form-control"
										title="{{ 'T_AGENCY.SETTINGS.LATITUDE' | translate }}"
										(input)="validateLatitude()" />
									<input-validation
										[control]="editAgencySettingsForm.controls.latitude"
										[errorOnTouch]="true"
										[extraMessageText]="latExtraMessageText">
									</input-validation>
								</div>
							</div>
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<field-label [name]="'longitude'" [label]="'T_AGENCY.SETTINGS.LONGITUDE' | translate" [required]="false"></field-label>
								<input
									formControlName="longitude"
									class="form-control"
									title="{{ 'T_AGENCY.SETTINGS.LONGITUDE' | translate }}"
									(input)="validateLongitude()" />
								<input-validation
									[control]="editAgencySettingsForm.controls.longitude"
									[errorOnTouch]="true"
									[extraMessageText]="longExtraMessageText">
								</input-validation>
							</div>

							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'agency_timezone'"
									[label]="'T_AGENCY.SETTINGS.AGENCY_TIMEZONE' | translate"
									[required]="false"></field-label>
								<input class="form-control" disabled value="{{ agency.agency_timezone }}" />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<field-label
									[name]="'logout_timer'"
									[label]="'T_AGENCY.SETTINGS.AUTOMATIC_LOGOUT_TIMER' | translate"
									[required]="false"></field-label>
								<select formControlName="logout_timer" class="form-control">
									<option value="300">5 {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}</option>
									<option value="600">10 {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}</option>
									<option value="900">15 {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}</option>
									<option value="1800">30 {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}</option>
									<option value="3600">1 {{ 'T_AGENCY.SETTINGS.HOUR' | translate }}</option>
									<option value="14400">4 {{ 'T_AGENCY.SETTINGS.HOURS' | translate }}</option>
									<option value="28800">8 {{ 'T_AGENCY.SETTINGS.HOURS' | translate }}</option>
									<option value="43200">12 {{ 'T_AGENCY.SETTINGS.HOURS' | translate }}</option>
									<option value="57600">16 {{ 'T_AGENCY.SETTINGS.HOURS' | translate }}</option>
								</select>
							</div>

							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 side-of-travel">
								<field-label [name]="'side_of_travel'" [label]="'T_AGENCY.SETTINGS.SIDE_OF_TRAVEL' | translate"></field-label>
								<select formControlName="side_of_travel" class="form-control">
									<option value="Right">{{ 'T_AGENCY.SETTINGS.RIGHT' | translate }}</option>
									<option value="Left">{{ 'T_AGENCY.SETTINGS.LEFT' | translate }}</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'imminent_departure'"
									[label]="'T_AGENCY.SETTINGS.IMMINENT_DEPARTURE_TRAVEL' | translate"
									[required]="false"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ iminentDepartureValues[0] }} {{ 'T_AGENCY.SETTINGS.SECONDS' | translate }} " />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider *ngIf="iminentDepartureValues" [values]="iminentDepartureValues" [sliderCfg]="iminentDepartureSliderConfig">
								</slider>
							</div>
						</div>
					</div>
				</div>
				<div class="panel panel-default nb-margin-bottom-md">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleAdherenceSection()">
						<div class="nb-subhead">{{ 'T_AGENCY.ADHERENCE' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i class="sectionTitle" [ngClass]="adherenceSectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div [hidden]="adherenceSectionCollapsed" class="panel-body">
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'adherence_setting_early_min_sec'"
									[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_EARLY_MIN_SEC' | translate"
									[required]="false"
									data-test="adherence_setting_early_min_sec"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ earlyAdherenceSettingValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}" />
							</div>

							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'adherence_setting_very_early_sec'"
									[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_VERY_EARLY_SEC' | translate"
									[required]="false"
									data-test="adherence_setting_very_early_sec"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ earlyAdherenceSettingValues[1] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}" />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider
									*ngIf="agency.adherence_setting_early_min_sec && agency.adherence_setting_very_early_sec"
									[values]="earlyAdherenceSettingValues"
									[sliderCfg]="earlyAdherenceSliderConfig">
								</slider>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'adherence_setting_late_min_sec'"
									[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_LATE_MIN_SEC' | translate"
									[required]="false"
									data-test="adherence_setting_late_min_sec"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ lateAdherenceSettingValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}" />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'adherence_setting_very_late_sec'"
									[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_VERY_LATE_SEC' | translate"
									[required]="false"
									data-test="adherence_setting_very_late_sec"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ lateAdherenceSettingValues[1] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}" />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider
									*ngIf="agency.adherence_setting_late_min_sec && agency.adherence_setting_very_late_sec"
									[values]="lateAdherenceSettingValues"
									[sliderCfg]="lateAdherenceSliderConfig">
								</slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'stale_sec'"
									[label]="'T_AGENCY.SETTINGS.STALE_SEC' | translate"
									[required]="false"
									data-test="stale_sec"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ staleSettingsValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }}" />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider *ngIf="agency.stale_sec" [sliderCfg]="staleSettingsSliderConfig" [values]="staleSettingsValues"> </slider>
							</div>
						</div>
					</div>
				</div>
				<div class="panel panel-default nb-margin-bottom-md">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleHeadwaySection()">
						<div class="nb-subhead">{{ 'T_AGENCY.HEADWAY.TITLE' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i class="sectionTitle" [ngClass]="headwaySectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div [hidden]="headwaySectionCollapsed" class="panel-body">
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'headway_setting_close'"
									[label]="'T_AGENCY.HEADWAY.CLOSE' | translate"
									[required]="false"
									data-test="headway_setting_close"></field-label>
								<input class="form-control" disabled value="{{ getPercentage(headwayCloseValues[0]) }}%" />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'headway_setting_very_close'"
									[label]="'T_AGENCY.HEADWAY.VERY_CLOSE' | translate"
									[required]="false"
									data-test="headway_setting_very_close"></field-label>
								<input class="form-control" disabled value="{{ getPercentage(headwayCloseValues[1]) }}%" />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider
									*ngIf="agency.headway_adherence_close && agency.headway_adherence_very_close"
									[values]="headwayCloseValues"
									[sliderCfg]="headwayCloseSliderConfig">
								</slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'headway_setting_distant'"
									[label]="'T_AGENCY.HEADWAY.DISTANT' | translate"
									[required]="false"
									data-test="headway_setting_distant"></field-label>
								<input class="form-control" disabled value="{{ getPercentage(headwayDistantValues[0]) }}%" />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'headway_setting_very_distant'"
									[label]="'T_AGENCY.HEADWAY.VERY_DISTANT' | translate"
									[required]="false"
									data-test="headway_setting_very_distant"></field-label>
								<input class="form-control" disabled value="{{ getPercentage(headwayDistantValues[1]) }}%" />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider
									*ngIf="agency.headway_adherence_distant && agency.headway_adherence_very_distant"
									[values]="headwayDistantValues"
									[sliderCfg]="headwayDistantSliderConfig">
								</slider>
							</div>
						</div>
					</div>
				</div>
				<div class="panel panel-default nb-margin-bottom-md">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleRidershipSection()">
						<div class="nb-subhead">{{ 'T_AGENCY.RIDERSHIP.TITLE' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i class="sectionTitle" [ngClass]="ridershipSectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div [hidden]="ridershipSectionCollapsed" class="panel-body">
						<div class="row">
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<field-label
									[name]="'ridership_threshold_empty'"
									[label]="'T_AGENCY.RIDERSHIP.EMPTY' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageManySeats | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value="<= {{ getPercentage(ridershipAcceptingThresholdValues[0]) }}% {{ 'T_AGENCY.RIDERSHIP.SEATED' | translate }}" />
							</div>
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<field-label
									[name]="'ridership_threshold_many'"
									[label]="'T_AGENCY.RIDERSHIP.MANY_SEATS' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageManySeats | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value="<= {{ getPercentage(ridershipAcceptingThresholdValues[1]) }}% {{ 'T_AGENCY.RIDERSHIP.SEATED' | translate }}" />
							</div>
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<field-label
									[name]="'ridership_threshold_few_standing'"
									[label]="'T_AGENCY.RIDERSHIP.FEW_SEATS' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageFewSeats | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value="<= {{ getPercentage(ridershipAcceptingThresholdValues[2]) }}% {{ 'T_AGENCY.RIDERSHIP.SEATED' | translate }}" />
							</div>
							<div class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3">
								<field-label
									[name]="'ridership_threshold_standing_room_only'"
									[label]="'T_AGENCY.RIDERSHIP.STANDING' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageStandingRoom | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value="> {{ getPercentage(ridershipAcceptingThresholdValues[2]) }}% {{ 'T_AGENCY.RIDERSHIP.SEATED' | translate }}" />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider
									*ngIf="agency.ridership_threshold_empty && agency.ridership_threshold_many && agency.ridership_threshold_few_standing"
									[values]="ridershipAcceptingThresholdValues"
									[sliderCfg]="ridershipAcceptingThresholdSliderConfig">
								</slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'ridership_threshold_crushed'"
									[label]="'T_AGENCY.RIDERSHIP.CRUSHED' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageCrushedStandingRoom | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value=">= {{ getPercentage(ridershipNotAcceptingThresholdValues[0]) }}% {{ 'T_AGENCY.RIDERSHIP.TOTAL' | translate }}" />
							</div>
							<div class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'ridership_threshold_full'"
									[label]="'T_AGENCY.RIDERSHIP.FULL' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageFull | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value=">= {{ getPercentage(ridershipNotAcceptingThresholdValues[1]) }}% {{ 'T_AGENCY.RIDERSHIP.TOTAL' | translate }}" />
							</div>
							<div class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'ridership_threshold_not_accepting'"
									[label]="'T_AGENCY.RIDERSHIP.NOT_ACCEPTING' | translate"
									[required]="false"></field-label>
								<img class="ridership-image" [src]="ridershipImageFull | ridershipImage" alt="Unavailable" />
								<input
									class="form-control"
									disabled
									value=">= {{ getPercentage(ridershipNotAcceptingThresholdValues[2]) }}% {{ 'T_AGENCY.RIDERSHIP.TOTAL' | translate }}" />
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<slider
									*ngIf="agency.ridership_threshold_crushed && agency.ridership_threshold_full && agency.ridership_threshold_not_accepting"
									[values]="ridershipNotAcceptingThresholdValues"
									[sliderCfg]="ridershipNotAcceptingThresholdSliderConfig">
								</slider>
							</div>
						</div>
					</div>
				</div>
				<div class="panel panel-default nb-margin-bottom-md">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleWidgetsSection()">
						<div class="nb-subhead">{{ 'T_AGENCY.WIDGETS' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i class="sectionTitle" [ngClass]="widgetsSectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div [hidden]="widgetsSectionCollapsed" class="panel-body">
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div class="row">
								<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<field-label
										[name]="'twitter_consumer_public_key'"
										[label]="'T_AGENCY.SETTINGS.TWITTER_CONSUMER_PUBLIC_KEY' | translate"
										[required]="false"></field-label>
									<input
										formControlName="twitter_consumer_public_key"
										class="form-control"
										type="text"
										title="{{ 'T_AGENCY.SETTINGS.TWITTER_CONSUMER_PUBLIC_KEY' | translate }}" />
									<input-validation
										[control]="editAgencySettingsForm.controls.twitter_consumer_public_key"
										[errorOnTouch]="true"
										[extraMessageText]="defaultExtraMessageText">
									</input-validation>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<field-label
										[name]="'twitter_consumer_secret_key'"
										[label]="'T_AGENCY.SETTINGS.TWITTER_CONSUMER_SECRET_KEY' | translate"
										[required]="false"></field-label>
									<input
										formControlName="twitter_consumer_secret_key"
										class="form-control"
										type="text"
										title="{{ 'T_AGENCY.SETTINGS.TWITTER_CONSUMER_SECRET_KEY' | translate }}" />
									<input-validation
										[control]="editAgencySettingsForm.controls.twitter_consumer_secret_key"
										[errorOnTouch]="true"
										[extraMessageText]="defaultExtraMessageText">
									</input-validation>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<field-label
										[name]="'twitter_access_token'"
										[label]="'T_AGENCY.SETTINGS.TWITTER_ACCESS_TOKEN' | translate"
										[required]="false"></field-label>
									<input
										formControlName="twitter_access_token"
										class="form-control"
										type="text"
										title="{{ 'T_AGENCY.SETTINGS.TWITTER_ACCESS_TOKEN' | translate }}" />
									<input-validation
										[control]="editAgencySettingsForm.controls.twitter_access_token"
										[errorOnTouch]="true"
										[extraMessageText]="defaultExtraMessageText">
									</input-validation>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<field-label
										[name]="'twitter_access_token_secret'"
										[label]="'T_AGENCY.SETTINGS.TWITTER_ACCESS_TOKEN_SECRET' | translate"
										[required]="false"></field-label>
									<input
										formControlName="twitter_access_token_secret"
										class="form-control"
										type="text"
										title="{{ 'T_AGENCY.SETTINGS.TWITTER_ACCESS_TOKEN_SECRET' | translate }}" />
									<input-validation
										[control]="editAgencySettingsForm.controls.twitter_access_token_secret"
										[errorOnTouch]="true"
										[extraMessageText]="defaultExtraMessageText">
									</input-validation>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<field-label
										[name]="'external_links'"
										[label]="'T_AGENCY.SETTINGS.EXTERNAL_LINKS' | translate"
										[required]="false"></field-label>
									<input
										(change)="setExternalLinksFileSelected($event.target)"
										class="form-control"
										type="file"
										id="external_links"
										title="{{ 'T_AGENCY.SETTINGS.EXTERNAL_LINKS' | translate }}" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="panel panel-default">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleLegacyNextbusSettingsSection()">
						<div class="nb-subhead">{{ 'T_AGENCY.SETTINGS.LEGACY_NEXTBUS_SETTINGS' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i
								class="sectionTitle"
								[ngClass]="legacyNextbusSettingsSectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div [hidden]="legacyNextbusSettingsSectionCollapsed" class="panel-body">
						<div class="row">
							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'departing_word'"
									[label]="'T_AGENCY.SETTINGS.DEPARTING_WORD' | translate"
									[required]="false"></field-label>
								<input
									formControlName="departing_word"
									class="form-control"
									type="text"
									title="{{ 'T_AGENCY.SETTINGS.DEPARTING_WORD' | translate }}" />
								<input-validation
									[control]="editAgencySettingsForm.controls.departing_word"
									[errorOnTouch]="true"
									[extraMessageText]="defaultExtraMessageText">
								</input-validation>
							</div>

							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'arriving_word'"
									[label]="'T_AGENCY.SETTINGS.ARRIVING_WORD' | translate"
									[required]="false"></field-label>
								<input
									formControlName="arriving_word"
									class="form-control"
									type="text"
									title="{{ 'T_AGENCY.SETTINGS.ARRIVING_WORD' | translate }}" />
								<input-validation
									[control]="editAgencySettingsForm.controls.arriving_word"
									[errorOnTouch]="true"
									[extraMessageText]="defaultExtraMessageText">
								</input-validation>
							</div>
							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'default_route_id'"
									[label]="'T_AGENCY.SETTINGS.DEFAULT_ROUTE_ID' | translate"
									[required]="false"></field-label>
								<input
									formControlName="default_route_id"
									class="form-control"
									type="text"
									title="{{ 'T_AGENCY.SETTINGS.DEFAULT_ROUTE_ID' | translate }}" />
								<input-validation
									[control]="editAgencySettingsForm.controls.default_route_id"
									[errorOnTouch]="true"
									[extraMessageText]="defaultExtraMessageText">
								</input-validation>
							</div>
							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label [name]="'sms_prefix'" [label]="'T_AGENCY.SETTINGS.SMS_PREFIX' | translate" [required]="false"></field-label>
								<input
									formControlName="sms_prefix"
									class="form-control"
									type="text"
									title="{{ 'T_AGENCY.SETTINGS.SMS_PREFIX' | translate }}" />
								<input-validation
									[control]="editAgencySettingsForm.controls.sms_prefix"
									[errorOnTouch]="true"
									[extraMessageText]="defaultExtraMessageText">
								</input-validation>
							</div>
							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'can_select_by_stop_code'"
									[label]="'T_AGENCY.SETTINGS.CAN_SELECT_BY_STOP_CODE' | translate"
									[required]="false"></field-label>
								<br />
								<div class="nb-form-control">
									<div class="nb-checkbox">
										<label for="can_select_by_stop_code">
											<input id="can_select_by_stop_code" formControlName="can_select_by_stop_code" type="checkbox" />
											<div class="checkbox-label">
												{{ 'T_AGENCY.ENABLED' | translate }}
											</div>
										</label>
									</div>
								</div>
							</div>
							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label
									[name]="'stop_code_length'"
									[label]="'T_AGENCY.SETTINGS.STOP_CODE_LENGTH' | translate"
									[required]="true"></field-label>
								<input
									formControlName="stop_code_length"
									class="form-control"
									type="number"
									title="{{ 'T_AGENCY.SETTINGS.STOP_CODE_LENGTH' | translate }}" />
								<input-validation [control]="editAgencySettingsForm.controls.stop_code_length" [errorOnTouch]="true"> </input-validation>
							</div>
							<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
								<field-label [name]="'hassigns'" [label]="'T_AGENCY.SETTINGS.HAS_SIGNS' | translate" [required]="false"></field-label>
								<br />
								<div class="nb-form-control">
									<div class="nb-checkbox">
										<label for="hassigns">
											<input id="hassigns" formControlName="hassigns" type="checkbox" />
											<div class="checkbox-label">{{ 'T_AGENCY.ENABLED' | translate }}</div>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="panel panel-default">
					<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleTerminalDepartureSection()">
						<div class="nb-subhead">{{ 'T_AGENCY.TERMINAL_DEPARTURE.TITLE' | translate }}</div>
						<button type="button" class="btn btn-link-dark">
							<i
								class="sectionTitle"
								[ngClass]="terminalDepartureSectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
						</button>
					</div>
					<div [hidden]="terminalDepartureSectionCollapsed" class="panel-body">
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'arrival_stop_radius'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.ARRIVAL_STOP_RADIUS' | translate"
									[required]="false"
									matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.ARRIVAL_STOP_RADIUS_TOOLTIP' | translate }}"
									[matTooltipPosition]="'right'"></field-label>
								<input class="form-control" disabled value="{{ arrivalStopRadiusValues[0] }} {{ 'T_DEPOT.METERS' | translate }} " />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<slider *ngIf="arrivalStopRadiusValues" [values]="arrivalStopRadiusValues" [sliderCfg]="arrivalStopRadiusSliderCfg">
								</slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'def_apply_sched_layovers'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.APPLY_SCHED_LAYOVERS' | translate"
									[required]="false"></field-label>
								<br />
								<div class="nb-form-control">
									<div class="nb-checkbox">
										<label for="def_apply_sched_layovers">
											<input id="def_apply_sched_layovers" formControlName="def_apply_sched_layovers" type="checkbox" />
											<div class="checkbox-label">{{ 'T_AGENCY.ENABLED' | translate }}</div>
										</label>
									</div>
								</div>
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'extend_all_departures'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.EXTEND_ALL' | translate"
									[required]="false"></field-label>
								<br />
								<div class="nb-form-control">
									<div class="nb-checkbox">
										<label for="extend_all_departures">
											<input id="extend_all_departures" formControlName="extend_all_departures" type="checkbox" />
											<div class="checkbox-label">{{ 'T_AGENCY.ENABLED' | translate }}</div>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'min_layover_seconds'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.MIN_LAYOVER' | translate"
									[required]="false"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ minLayoverValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<slider *ngIf="minLayoverValues" [values]="minLayoverValues" [sliderCfg]="terminalDepartureSliderConfig"> </slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'max_departure_extension'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.MAX_DEPARTURE' | translate"
									[required]="false"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ maxExtensionValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<slider *ngIf="maxExtensionValues" [values]="maxExtensionValues" [sliderCfg]="terminalDepartureSliderConfig"> </slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'detect_departure_at_upcoming_stop'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_AT_UPCOMING_STOP' | translate"
									[required]="false"></field-label>
								<br />
								<div class="nb-form-control">
									<div class="nb-checkbox">
										<label for="detect_departure_at_upcoming_stop">
											<input id="detect_departure_at_upcoming_stop" formControlName="detect_departure_at_upcoming_stop" type="checkbox" />
											<div class="checkbox-label">{{ 'T_AGENCY.ENABLED' | translate }}</div>
										</label>
									</div>
								</div>
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'detect_departure_stop_sequence'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_STOP_SEQUENCE' | translate"
									[required]="false"></field-label>
								<select formControlName="detect_departure_stop_sequence" class="form-control">
									<option value="2" selected>2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>
							</div>
						</div>
						<div class="nb-subhead sub-title">{{ 'T_AGENCY.TERMINAL_DEPARTURE.NAVIGATION_SERVICE' | translate }}</div>
						<br />
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'default_deadhead_seconds'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.DEFAULT_DEADHEADING' | translate"
									[required]="false"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ defaultDeadheadValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<slider *ngIf="defaultDeadheadValues" [values]="defaultDeadheadValues" [sliderCfg]="terminalDepartureSliderConfig">
								</slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'min_dist_nav_service'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.MIN_DIST_NAV_SERVICE' | translate"
									[required]="false"></field-label>
								<input class="form-control" disabled value="{{ minDistanceNavValues[0] }} {{ 'T_DEPOT.METERS' | translate }} " />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<slider *ngIf="minDistanceNavValues" [values]="minDistanceNavValues" [sliderCfg]="navServiceDistanceSliderCfg"> </slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'start_nav_service_throttle'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.START_TIME_NAV_SERVICE' | translate"
									[required]="false"></field-label>
								<time-selector
									[initialTime]="editAgencySettingsForm.controls.start_nav_service_throttle.value"
									(timeValid)="setTimeValid($event, 'start_nav_service_throttle')"
									(timeUpdated)="setUpdatedTime($event, 'start_nav_service_throttle')">
								</time-selector>
								<span *ngIf="hasCustomTimeError" class="text-danger">{{ customStartTimeErrorText }}</span>
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'end_nav_service_throttle'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.END_TIME_NAV_SERVICE' | translate"
									[required]="false"></field-label>
								<time-selector
									[initialTime]="editAgencySettingsForm.controls.end_nav_service_throttle.value"
									(timeValid)="setTimeValid($event, 'end_nav_service_throttle')"
									(timeUpdated)="setUpdatedTime($event, 'end_nav_service_throttle')">
								</time-selector>
								<span *ngIf="hasCustomTimeError" class="text-danger">{{ customEndTimeErrorText }}</span>
								<span *ngIf="hasCustomTimeError" class="text-danger">{{ customTimeErrorText }}</span>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<field-label
									[name]="'nav_service_call_frequency'"
									[label]="'T_AGENCY.TERMINAL_DEPARTURE.CALL_FREQUENCY_NAV_SERVICE' | translate"
									[required]="false"></field-label>
								<input
									class="form-control"
									disabled
									value="{{ callFrequencyNavValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
							</div>
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<slider *ngIf="callFrequencyNavValues" [values]="callFrequencyNavValues" [sliderCfg]="terminalDepartureSliderConfig">
								</slider>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
								<button (click)="addTerminalDepartureException()" class="btn btn-action">
									<i class="nb-icons nb-create"></i>{{ 'T_AGENCY.TERMINAL_DEPARTURE.ADD_EXCEPTION' | translate }}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div
					#exceptions
					*ngFor="let exception of editAgencySettingsForm.get('terminal_departure_exceptions').value.controls; let i = index">
					<terminal-departure-config
						id="{{ i }}"
						[authorityId]="authorityId"
						[agencyId]="agencyId"
						[index]="i"
						[form]="exception"
						(deleteException)="deleteTerminalDepartureException($event)"
						(updateValidity)="updateTerminalDepartureExceptionValidity()">
					</terminal-departure-config>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="close(false)" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-secondary" (click)="save()" [disabled]="!enableSave()">{{ 'T_CORE.SAVE' | translate }}</button>
</mat-dialog-actions>

<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="this.data.config?.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div *ngIf="hasData">
					<div markdown [data]="markdownContent"></div>
					<br />
					<br />
				</div>
				<div *ngIf="!hasData" class="no-data text-center">{{ 'T_DASH.DB_EXTERNAL_LINKS_NO_DATA' | translate }}</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

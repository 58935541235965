export interface BreadcrumbItem {
	displayText: string;
	activePage: boolean;
	targetPath?: string; // used when router is used
	entityType?: EntityType; // used when publisher is used
	pageType?: PageType; // used when publisher is used
}

export type BreadcrumbItems = Array<BreadcrumbItem>;

export interface NavigationTargetDetail {
	entityType: EntityType;
	pageType: PageType;
}

export enum PageType {
	list,
	details,
}

export enum EntityType {
	menu,
	route,
	block,
	stop,
	vehicle,
	vehicleReassign,
	vehicleReassignBlock,
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { UserAdminDetailEditComponent } from './detail-edit/user-admin-detail-edit.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { UsersAdminDataService } from '../../services/users-admin-data.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { CurrentUserPermissionService } from '../../../../../support-features/login/services/current-user/current-user-permission.service';
import { CurrentUserUtilService } from '../../../../../support-features/login/services/current-user/current-user-utils.service';
import { PermissionRequest } from '../../../../../support-features/login/types/types';
import { Roles, UpdatedRoles, UserDetail, UserProfileDetails, UserRoles } from '../../types/api-types';
import { Agencies } from '../../../../../support-features/agencies/types/api-types';

import { ProfileUpdate } from '../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'user-admin-profile-edit',
	templateUrl: './user-admin-profile-edit.component.html',
	styleUrls: ['./user-admin-profile-edit.component.scss'],
})
export class UserAdminProfileEditComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild(UserAdminDetailEditComponent) userDetailCmp: UserAdminDetailEditComponent;

	@ViewChild('tabs', { static: false }) tabs: MatTabGroup;

	public loading: boolean = true;
	public modalTitle: string = null;
	public isCreate: boolean = false;
	public adminAgenciesAvailable: boolean = false;
	public user: UserDetail = null;

	private userDetailFormValid: boolean = false;
	private rolesValid: boolean = false;
	private userDetailsEdited: boolean = false;
	private userRolesEdited: boolean = false;
	private selectedUserId: string;
	private maxUserAgencies: number = 40;
	private updatedRoles: UpdatedRoles = null;

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private usersAdminDataService: UsersAdminDataService,
		private agenciesDataService: AgenciesDataService,
		private modalRef: MatDialogRef<UserAdminProfileEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close(false);
	}

	/**
	 * performs initialization tasks for the auser profile edit view
	 */
	public async ngOnInit(): Promise<void> {
		// grab the modal data passed in
		this.isCreate = this.data['isCreate'];
		this.selectedUserId = this.data['selectedUserId'];

		this.modalTitle = this.isCreate ? 'T_USER.USER_CREATOR' : 'T_USER.USER_EDITOR';

		await this.initUser();

		// an existing user must already have valid roles - a new user won't have any
		this.rolesValid = !this.isCreate;

		this.adminAgenciesAvailable = this.getAdminAgenciesAvailable();

		this.loading = false;
	}

	/**
	 * update the tab control
	 *
	 * workaround for bug with angular materials when swapping tabs which introduces a scroll bar.
	 * inform the parent we are initialised so the tab size can be reset (note: init events fire
	 * too early before scroll bar has rendered).
	 * note: we have to wait 500ms exactly as that is when the animation transition finishes (internal to tab control)
	 */
	public updateTabControl = (): void => {
		setTimeout(() => {
			this.tabs.realignInkBar();
		}, 500);
	};

	/**
	 * determines if the save button on the form can be enabled
	 *
	 * @returns true if the save button can be enabled, false otherwise
	 */
	public enableSave = (): boolean => {
		return (this.userDetailsEdited || this.userRolesEdited) && this.userDetailFormValid && this.rolesValid;
	};

	/**
	 * records the validityof the form
	 *
	 * @param userDetailFormValid - the validity of form data
	 */
	public validateUserDetails = (userDetailFormValid: boolean): void => {
		this.userDetailsEdited = true;
		this.userDetailFormValid = userDetailFormValid;
	};

	/**
	 * refreshes the form with supplid updated roles
	 *
	 * @param updatedRoles - updated roles
	 */
	public rolesUpdated = (updatedRoles: UpdatedRoles): void => {
		this.userRolesEdited = true;

		this.user.user_profile.nb_primary_agency = updatedRoles.primaryAgency;
		this.user.user_roles = this.buildRoles(updatedRoles.userRolesUpdated);

		this.updatedRoles = updatedRoles;

		// user has been updated and angular will update child with updated user (including role data)
		// just need to nudge user detail (tab) child component to update role display section in the
		// the correct format
		this.userDetailCmp.refreshRoles();

		this.rolesValid = this.checkRolesValid();
	};

	/**
	 * saves the user profile data
	 */
	public save = async (): Promise<void> => {
		this.loading = true;

		// update main user object with updated profile data from child component
		this.updateUserData();

		if (!this.isCreate) {
			await this.saveExistingUser();
		} else {
			await this.saveNewUser();
		}

		this.loading = false;
	};

	/**
	 * closes the dialog
	 *
	 * @param saved - whether the user saved the profile data
	 */
	public close = (saved: boolean): void => {
		this.modalRef.close(saved);
	};

	/**
	 * initializes the user
	 */
	private initUser = async (): Promise<void> => {
		if (!this.isCreate) {
			// default detail form and role values to true in edit mode (they must have been saved as valid)
			this.userDetailFormValid = true;
			this.rolesValid = true;

			this.user = await this.initExistingUser();
		} else {
			this.user = this.initNewUser();
		}
	};

	/**
	 * initializes a new user
	 *
	 * @returns a new user
	 */
	private initNewUser = (): UserDetail => {
		const userProfileDetails: UserProfileDetails = {
			org_name: null,
			phone: null,
			address: null,
			address_2: null,
			city: null,
			state_province_region: null,
			postal_code: null,
			country: null,
			nb_user: null,
			nb_primary_agency: null,
			agency_info: null,
			nb_id: null,
		};

		const user: UserDetail = {
			username: null,
			sms_number: null,
			email: null,
			real_name: null,
			created: null,
			last_login: null,
			password_reset_key: null,
			password_reset_key_expiration: null,
			agency_portal_settings: null,
			contact: null,
			status: null,
			deactivated_date: null,
			failed_login_attempts: null,
			lock_date: null,
			password_last_changed_date: null,
			user_profile: userProfileDetails,
			user_roles: [],
			nb_id: null,
		};

		return user;
	};

	/**
	 * initializes an existing user
	 *
	 * @returns existing user
	 */
	private initExistingUser = async (): Promise<UserDetail> => {
		let userDetails: UserDetail = null;

		const result: ResultContent = await this.usersAdminDataService.getUser(this.selectedUserId);

		if (result.success) {
			userDetails = result.resultData;
		}

		return userDetails;
	};

	/**
	 * determines the agency count
	 *
	 * @returns agency count
	 */
	private validateAgencyCount = (): boolean => {
		let count: number = 0;
		let validCount: boolean = true;
		const countedAgencies: string[] = [];

		this.user.user_roles.forEach((role) => {
			if (role.agency_info && role.agency_info.authority_id) {
				const agencyCode: string = role.agency_info.authority_id;

				if (countedAgencies.indexOf(agencyCode) === -1) {
					count++;
					countedAgencies.push(agencyCode);
				}
			}
		});

		if (count > this.maxUserAgencies) {
			validCount = false;
		}

		return validCount;
	};

	/**
	 * builds a list of roles
	 *
	 * @param edited_roles_array - edited roles
	 * @returns roles
	 */
	private buildRoles = (edited_roles_array: UserRoles): Roles => {
		const roles: Roles = [];

		for (const role of edited_roles_array) {
			if (!role.selected) {
				continue;
			}

			if (role.agencyId) {
				roles.push({
					agency_info: {
						agency_id: role.agencyId,
						nb_id: role.agencyNbId,
						agency_name: role.agencyName,
						agency_status: { status: 'active' },
						authority_id: role.authorityId,
					},
					user_role_authority: {
						authority_info: {
							authority_id: role.authorityId,
							authority_name: role.authorityName,
						},
					},
					associated_role: {
						name: role.roleName,
						nb_id: role.associated_role_nb_id,
					},
				});
			} else {
				roles.push({
					agency_info: null,
					authority: role.authorityId,
					user_role_authority: {
						authority_info: {
							authority_id: role.authorityId,
							authority_name: role.authorityName,
						},
					},
					associated_role: {
						name: role.roleName,
						nb_id: role.associated_role_nb_id,
					},
				});
			}
		}

		roles.push({
			agency_info: null,
			associated_role: {
				name: 'BASIC_USER',
				nb_id: 2,
			},
		});

		return roles;
	};

	/**
	 * determines if the roles on the form are valid
	 *
	 * @returns true if roles are valid, flase otherwise
	 */
	private checkRolesValid = (): boolean => {
		const validAgencyCount: boolean = this.validateAgencyCount();

		let isUmoAdmin: boolean = false;

		this.user.user_roles.forEach((role) => {
			if (role.agency_info === null && role.authority === null && role.associated_role.name === 'UMO_ADMIN') {
				isUmoAdmin = true;
			}
		});

		if (isUmoAdmin && this.user.user_profile.nb_primary_agency !== null && validAgencyCount) {
			return true;
		}

		return this.checkRolesCount() && this.user.user_profile.nb_primary_agency !== null && validAgencyCount;
	};

	/**
	 * counts the number of roles
	 *
	 * @returns number of roles
	 */
	private checkRolesCount = (): boolean => {
		const roles: Roles = this.user.user_roles || [];

		return roles.length > 1;
	};

	/**
	 * populates the form with user profile details
	 */
	private updateUserData = (): void => {
		const profileUpdate: ProfileUpdate = this.userDetailCmp.getUserProfileDetails();

		this.user.username = profileUpdate.username;
		this.user.real_name = profileUpdate.realName;
		this.user.sms_number = profileUpdate.smsNumber;
		this.user.email = profileUpdate.email;
		this.user.user_profile.address = profileUpdate.address;
		this.user.user_profile.address_2 = profileUpdate.address2;
		this.user.user_profile.city = profileUpdate.city;
		this.user.user_profile.country = profileUpdate.country;
		this.user.user_profile.org_name = profileUpdate.orgName;
		this.user.user_profile.phone = profileUpdate.phone;
		this.user.user_profile.postal_code = profileUpdate.postalCode;
		this.user.user_profile.state_province_region = profileUpdate.stateProvinceRegion;
	};

	/**
	 * saves a new user
	 */
	private saveNewUser = async (): Promise<void> => {
		// sanity check - user must have edited (added) user data in create mode in order to save
		if (this.userDetailsEdited) {
			const createUserResult: ResultContent = await this.usersAdminDataService.createUser(
				this.user,
				this.updatedRoles.userRolesUpdated
			);

			if (createUserResult.success) {
				this.user.nb_id = createUserResult.resultData;

				const saveUserProfileResult: ResultContent = await this.usersAdminDataService.saveUserProfile(this.user);

				if (saveUserProfileResult.success) {
					// sanity check - user must have edited (added)roles in create mode in ordeto save
					if (this.userRolesEdited) {
						const success: boolean = await this.saveNewUserRoles();

						if (success) {
							this.close(true);
						}
					} else {
						this.close(true);
					}
				}
			}
		}
	};

	/**
	 * saves new user roles
	 *
	 * @returns true if the save was successful, false otherwise
	 */
	private saveNewUserRoles = async (): Promise<boolean> => {
		let success: boolean = false;

		const saveUserPrimaryAgencyResult: ResultContent = await this.usersAdminDataService.saveUserPrimaryAgency(
			this.user.nb_id.toString(),
			this.user.user_profile.nb_primary_agency.toString()
		);

		if (saveUserPrimaryAgencyResult.success) {
			const result: ResultContent = await this.usersAdminDataService.editUserRoles(
				this.user.nb_id.toString(),
				this.updatedRoles.userRolesOld,
				this.updatedRoles.userRolesUpdated,
				this.currentUserUtilService.getCurrentUser().nb_id.toString()
			);

			if (result.success) {
				success = true;
			}
		}

		return success;
	};

	/**
	 * saves an existing user
	 */
	private saveExistingUser = async (): Promise<void> => {
		let success: boolean = true;

		if (this.userRolesEdited) {
			success &&= await this.saveExistingUserRoles();
		}

		if (this.userDetailsEdited) {
			success &&= await this.saveExistingUserDetails();
		}

		if (success) {
			this.close(true);
		}
	};

	/**
	 * saves existing user roles
	 *
	 * @returns true if the save was succesful, false otherwise
	 */
	private saveExistingUserRoles = async (): Promise<boolean> => {
		let success: boolean = false;

		const saveUserPrimaryAgencyResult: ResultContent = await this.usersAdminDataService.saveUserPrimaryAgency(
			this.user.nb_id.toString(),
			this.user.user_profile.nb_primary_agency.toString()
		);

		if (saveUserPrimaryAgencyResult.success) {
			const editUserRolesResult: ResultContent = await this.usersAdminDataService.editUserRoles(
				this.user.nb_id.toString(),
				this.updatedRoles.userRolesOld,
				this.updatedRoles.userRolesUpdated,
				this.currentUserUtilService.getCurrentUser().nb_id.toString()
			);

			success = editUserRolesResult.success;
		}

		return success;
	};

	/**
	 * saves existing user details
	 *
	 * @returns true if the save was succesful, false otherwise
	 */
	private saveExistingUserDetails = async (): Promise<boolean> => {
		let success: boolean = false;

		const saveUserResult: ResultContent = await this.usersAdminDataService.saveUser(this.user);

		if (saveUserResult.success) {
			const saveUserProfileRespone: ResultContent = await this.usersAdminDataService.saveUserProfile(this.user);

			success = saveUserProfileRespone.success;
		}

		return success;
	};

	/**
	 * determines if admin agencies are available
	 *
	 * @returns true if admin agencies are available, false otherwise
	 */
	private getAdminAgenciesAvailable = (): boolean => {
		let adminAgenciesAvailable: boolean = false;

		const allAvailableAgencies: Agencies = this.agenciesDataService.getAgencies();

		allAvailableAgencies.forEach((availableAgencies) => {
			const permissionRequest: PermissionRequest = {
				authorityId: availableAgencies.authority_id,
				agencyId: availableAgencies.agency_id,
			};

			if (
				this.currentUserPermissionService.isAdmin(permissionRequest) ||
				this.currentUserPermissionService.isAuthorityAdmin(permissionRequest)
			) {
				adminAgenciesAvailable = true;
			}
		});

		return adminAgenciesAvailable;
	};
}

<div class="occupancy-tooltip-container" [style.top]="tooltipPosition.y - 50 + 'px'" [style.left]="tooltipPosition.x + 'px'">
	<div class="occupancy-tooltip" data-test="occupancy.tooltip">
		<span class="occupancy-image-container">
			<img class="occupancy-image" [src]="occupancyStatus | ridershipImage" alt="Unavailable" />
			<b>Vehicles:</b> {{ vehicleCount }} ({{ vehiclePercent }}%)
		</span>
	</div>
	<div class="occupancy-tooltip-arrow-outline"></div>
	<div class="occupancy-tooltip-arrow"></div>
</div>

<div *ngIf="!initialized" class="nb-padding-all-lg">
	<span *ngIf="templateSummaryContent?.errorMessage">{{ templateSummaryContent.errorMessage }}</span>
	<span *ngIf="!templateSummaryContent?.errorMessage">{{ 'T_REPORT.FAILURE_RETRIEVING_TEMPLATE' | translate }}</span>
</div>
<div *ngIf="initialized">
	<div class="nb-view-table-4">
		<div class="nb-view-table">
			<div class="nb-view-table-heading" data-test="report-details.report-options.heading">{{ 'T_REPORT.REPORT_OPTIONS' | translate }}</div>
			<div class="clearfix nb-view-table-row">
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.authority.label">{{ 'T_CORE.AUTHORITY' | translate }}</label>
					<div data-test="report-details.authority">{{ templateSummaryContent.template.report_options.agencies.authority_id }}</div>
				</div>
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.agency.label">{{ 'T_CORE.AGENCY' | translate }}</label>
					<div data-test="report-details.agency">{{ templateSummaryContent.template.report_options.agencies.agency_name }}</div>
				</div>
				<div
					*ngIf="
						reportConfig.reportOptions?.filterArrivalStops?.enabled &&
						templateSummaryContent.template.report_options.filter_arrival_stops !== undefined
					"
					class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.filter.label">{{ 'T_REPORT.FILTER_ARRIVAL_STOPS' | translate }}</label>
					<div data-test="report-details.filter">
						{{
							templateSummaryContent.template.report_options.filter_arrival_stops === 'true'
								? getTranslation('T_REPORT.FILTER_ARRIVAL_STOPS_CHECKBOX')
								: getTranslation('T_REPORT.FILTER_ARRIVAL_STOPS_CHECKBOX_NO')
						}}
					</div>
				</div>
			</div>
			<div class="clearfix nb-view-table-row">
				<div *ngIf="reportConfig.reportOptions?.routes?.enabled" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.routes.label">{{ 'T_CORE.ROUTES' | translate }}</label>
					<div *ngIf="templateSummaryContent.template.report_options.include_all_routes === 'true'" data-test="report-details.routes">
						{{ 'T_REPORT.ALL_ROUTES' | translate }}
					</div>
					<div *ngIf="templateSummaryContent.template.report_options.include_all_routes === 'false'">
						<div *ngIf="templateSummaryContent.template.report_options?.routes.length > 0" data-test="report-details.routes">
							{{
								templateSummaryContent.template.report_options.routes
									| joinByIndividualFields: ', ' : getRouteFieldNames(templateSummaryContent.template.report_options.routes)
							}}
						</div>
					</div>
				</div>
				<div *ngIf="reportConfig.reportOptions?.vehicles?.enabled" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.vehicles.label">{{ 'T_REPORT.VEHICLES' | translate }}</label>
					<div *ngIf="templateSummaryContent.template.report_options.include_all_vehicles !== 'true'" data-test="report-details.vehicles">
						{{ templateSummaryContent.template.report_options.vehicles | join: ', ' : 'vehicle_id' }}
					</div>
					<div *ngIf="templateSummaryContent.template.report_options.include_all_vehicles === 'true'" data-test="report-details.vehicles">
						{{ 'T_REPORT.ALL_VEHICLES' | translate }}
					</div>
				</div>
				<div *ngIf="reportConfig.reportOptions?.blocks?.enabled" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.blocks.label">{{ 'T_REPORT.BLOCKS' | translate }}</label>

					<div *ngIf="reportConfig.reportOptions.blocks.selections.length > 0">
						<div *ngIf="templateSummaryContent.template.report_options.block_selection === 'all'" data-test="report-details.blocks">
							{{ 'T_REPORT.ALL_BLOCKS' | translate }}
						</div>
						<div *ngIf="templateSummaryContent.template.report_options.block_selection === 'unassigned'" data-test="report-details.blocks">
							{{ 'T_REPORT.UNASSIGNED_BLOCKS' | translate }}
						</div>
						<div *ngIf="templateSummaryContent.template.report_options.block_selection === 'specific'" data-test="report-details.blocks">
							{{ templateSummaryContent.template.report_options.blocks | join: ', ' : 'block_id' }}
						</div>
					</div>

					<!-- prior to the refactor a report with no block selections set (meaning user must pick at least 1 block) did not default the
                    block_selection to 'specific' but we know if there aren't any selections then the blocks field will populated with data. This is
                    needed while reports exist in the system that were created before the refactor but can eventually be removed as 'specific' will be
                    set going forward -->
					<div *ngIf="reportConfig.reportOptions.blocks.selections.length === 0" data-test="report-details.blocks">
						{{ templateSummaryContent.template.report_options.blocks | join: ', ' : 'block_id' }}
					</div>
				</div>
				<div *ngIf="reportConfig.reportOptions?.sort?.enabled" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.sort.label">{{ 'T_REPORT.SORT' | translate }}</label>
					<div
						*ngIf="templateSummaryContent.template.report_options.schedule_sort_selection === 'scheduleSortByBlock'"
						data-test="report-details.sort.block">
						{{ 'T_REPORT.SORT_BY_BLOCK' | translate }}
					</div>
					<div
						*ngIf="templateSummaryContent.template.report_options.schedule_sort_selection === 'scheduleSortByTime'"
						data-test="report-details.sort.time">
						{{ 'T_REPORT.SORT_BY_TIME' | translate }}
					</div>
					<div *ngIf="reportConfig.reportOptions?.speed?.enabled" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
						<label>{{ 'T_REPORT.SPEED_IN' | translate }}</label>
						<div *ngIf="templateSummaryContent.template.report_options.speed_selection === 'mph'">
							{{ 'T_REPORT.SPEED_MPH' | translate }}
						</div>
						<div *ngIf="templateSummaryContent.template.report_options.speed_selection === 'kmh'">
							{{ 'T_REPORT.SPEED_KMH' | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class="clearfix nb-view-table-row">
				<div
					*ngIf="
						reportConfig.reportOptions?.adherenceThreshold?.enabled &&
						templateSummaryContent.template.report_options.adherence_threshold_override !== undefined
					"
					class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.override.label">{{ 'T_REPORT.ADHERENCE_THRESHOLD_OVERRIDE' | translate }}</label>
					<div data-test="report-details.override">
						{{
							templateSummaryContent.template.report_options.adherence_threshold_override === 'true'
								? getTranslation('T_REPORT.ADHERENCE_THRESHOLD_OVERRIDE_ENABLED')
								: getTranslation('T_REPORT.ADHERENCE_THRESHOLD_OVERRIDE_NOT_ENABLED')
						}}
					</div>
				</div>
			</div>
			<div class="clearfix nb-view-table-row">
				<div
					*ngIf="
						reportConfig.reportOptions?.adherenceThreshold?.enabled &&
						templateSummaryContent.template.report_options.adherence_threshold_override === 'true'
					">
					<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
						<label data-test="report-details.very-early.label">{{ 'T_REPORT.ADHERENCE_SETTING_VERY_EARLY_SEC' | translate }}</label>
						<div data-test="report-details.very-early">
							{{ templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_very_early_sec / 60 }}
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_very_early_sec <= 60">
								{{ 'T_REPORT.MINUTE' | translate }}
							</span>
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_very_early_sec > 60">
								{{ 'T_REPORT.MINUTES' | translate }}
							</span>
						</div>
					</div>
					<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
						<label data-test="report-details.early.label">{{ 'T_REPORT.ADHERENCE_SETTING_EARLY_MIN_SEC' | translate }}</label>
						<div data-test="report-details.early">
							{{ templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_early_min_sec / 60 }}
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_early_min_sec <= 60">
								{{ 'T_REPORT.MINUTE' | translate }}
							</span>
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_early_min_sec > 60">
								{{ 'T_REPORT.MINUTES' | translate }}
							</span>
						</div>
					</div>
					<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
						<label data-test="report-details.late.label">{{ 'T_REPORT.ADHERENCE_SETTING_LATE_MIN_SEC' | translate }}</label>
						<div data-test="report-details.late">
							{{ templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_late_min_sec / 60 }}
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_late_min_sec <= 60">
								{{ 'T_REPORT.MINUTE' | translate }}
							</span>
							<span *ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_late_min_sec > 60">
								{{ 'T_REPORT.MINUTES' | translate }}
							</span>
						</div>
					</div>
					<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
						<label data-test="report-details.very-late.label">{{ 'T_REPORT.ADHERENCE_SETTING_VERY_LATE_SEC' | translate }}</label>
						<div data-test="report-details.very-late">
							{{ templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_very_late_sec / 60 }}
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_very_late_sec <= 60">
								{{ 'T_REPORT.MINUTE' | translate }}
							</span>
							<span
								*ngIf="templateSummaryContent.template.report_options.adherence_threshold_settings.adherence_setting_very_late_sec > 60">
								{{ 'T_REPORT.MINUTES' | translate }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="
				templateSummaryContent.template &&
				templateSummaryContent.template.date_options &&
				templateSummaryContent.template.date_options.effective_date_range_type &&
				!templateSummaryContent.template.date_options.disabled
			"
			class="nb-view-table date-options-container">
			<div class="nb-view-table-heading" data-test="report-details.date-options.heading">{{ 'T_REPORT.DATE_OPTIONS' | translate }}</div>
			<div class="clearfix nb-view-table-row">
				<div
					class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6"
					*ngIf="templateSummaryContent.template.date_options.effective_date_range_type">
					<label data-test="report-details.date-range.label">{{ 'T_REPORT.DATE.DATE_RANGE_OPTIONS' | translate }}</label>
					<div
						*ngIf="templateSummaryContent.template.date_options.effective_date_range_type === 'specific_date'"
						data-test="report-details.date-range">
						<span
							>{{ templateSummaryContent.template.date_options.specific_date.effective_date_start | date: 'MMM d, YYYY h:mm a' }} -
						</span>
						<span>{{ templateSummaryContent.template.date_options.specific_date.effective_date_end | date: 'MMM d, YYYY h:mm a' }}</span>
					</div>
					<div
						*ngIf="templateSummaryContent.template.date_options.effective_date_range_type === 'day'"
						data-test="report-details.date-range">
						<span [ngSwitch]="templateSummaryContent.template.date_options.day.type">
							<span *ngSwitchCase="'0'">
								{{ 'T_REPORT.TODAY' | translate }}
							</span>
							<span *ngSwitchCase="'1'">
								{{ 'T_REPORT.YESTERDAY' | translate }}
							</span>
							<span *ngSwitchCase="'2'">
								{{ templateSummaryContent.template.date_options.day.count }}
								{{ getTranslation('T_REPORT.' + templateSummaryContent.template.date_options.effective_date_range_type + '.UNITS') }}
							</span>
							<span *ngSwitchCase="'3'">
								{{ templateSummaryContent.template.date_options.day.effective_date_start | date: 'MMM dd, YYYY h:mm a' }}
							</span>
						</span>
					</div>
					<div
						*ngIf="templateSummaryContent.template.date_options.effective_date_range_type === 'day_name_value'"
						data-test="report-details.date-range">
						<span [ngSwitch]="templateSummaryContent.template.date_options.day_name_value.type">
							<span *ngSwitchCase="'0'">
								{{ 'T_REPORT.INCLUDE_ALL' | translate }}
							</span>
							<span *ngSwitchCase="'1'">
								{{ 'T_REPORT.WEEKDAYS' | translate }}
							</span>
							<span *ngSwitchCase="'2'">
								{{ 'T_REPORT.SATURDAY' | translate }}
							</span>
							<span *ngSwitchCase="'3'">
								{{ 'T_REPORT.SUNDAY' | translate }}
							</span>
						</span>
					</div>
					<div *ngIf="templateSummaryContent.template.date_options.effective_date_range_type === 'week'">
						<span
							[ngSwitch]="templateSummaryContent.template.date_options.week.type"
							data-test="report-details.range"
							data-test="report-details.date-range">
							<span *ngSwitchCase="'0'">
								{{ 'T_REPORT.THIS_WEEK' | translate }}
							</span>
							<span *ngSwitchCase="'1'">
								{{ 'T_REPORT.LAST_WEEK' | translate }}
							</span>
							<span *ngSwitchCase="'2'">
								{{ templateSummaryContent.template.date_options.week.count }}
								{{ getTranslation('T_REPORT.' + templateSummaryContent.template.date_options.effective_date_range_type + '.UNITS') }}
							</span>
						</span>
					</div>
					<div
						*ngIf="templateSummaryContent.template.date_options.effective_date_range_type === 'month'"
						data-test="report-details.date-range">
						<span [ngSwitch]="templateSummaryContent.template.date_options.month.type">
							<span *ngSwitchCase="'0'">
								{{ 'T_REPORT.THIS_MONTH' | translate }}
							</span>
							<span *ngSwitchCase="'1'">
								{{ 'T_REPORT.LAST_MONTH' | translate }}
							</span>
							<span *ngSwitchCase="'2'">
								{{ templateSummaryContent.template.date_options.month.count }}
								{{ getTranslation('T_REPORT.' + templateSummaryContent.template.date_options.effective_date_range_type + '.UNITS') }}
							</span>
						</span>
					</div>
				</div>
				<div *ngIf="effectiveTimeEnabled()" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.date-range-effective.label">{{ 'T_REPORT.LIMIT_EFFECTIVE_TIME_WINDOW' | translate }}</label>
					<div>
						<span data-test="report-details.date-range-effective.start"
							>{{ templateSummaryContent.template.date_options.effective_time_start | date: 'shortTime' }} -
						</span>
						<span data-test="report-details.date-range-effective.end">{{
							templateSummaryContent.template.date_options.effective_time_end | date: 'shortTime'
						}}</span>
					</div>
				</div>
				<div
					*ngIf="templateSummaryContent.template.date_options.calculated_start_date"
					class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.date-range-calculated.label">{{ 'T_REPORT.CALCULATED_DATE_RANGE' | translate }}</label>
					<div>
						<span data-test="report-details.date-range-calculated.start"
							>{{ templateSummaryContent.template.date_options.calculated_start_date | date: 'MMM d, yyyy' : startTimeOffset }} -
						</span>
						<span data-test="report-details.date-range-calculated.end">{{
							templateSummaryContent.template.date_options.calculated_end_date | date: 'MMM d, yyyy' : endTimeOffset
						}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

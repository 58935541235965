import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './support-features/login/login-form/login.component';
import { MainComponent } from './main/main/main.component';
import { AdminToolsComponent } from './features/admin-tools/view/admin-tools-view.component';
import { UserAdminListComponent } from './features/admin-tools/users/list/user-admin-list.component';
import { UserAdminProfileComponent } from './features/admin-tools/users/user-profile/view/user-admin-profile.component';
import { ResetPasswordComponent } from './features/admin-tools/users/password/reset-password/reset-password.component';
import { CreatePasswordComponent } from './features/admin-tools/users/password/create-password/create-password.component';
import { SchedulesAdminListComponent } from './features/admin-tools/schedules/list/schedules-admin-list.component';
import { ScheduleAdminViewComponent } from './features/admin-tools/schedules/view/schedule-admin-view.component';
import { AgenciesAdminListComponent } from './features/admin-tools/agencies/list/agencies-admin-list.component';
import { AgencyAdminViewComponent } from './features/admin-tools/agencies/view/agency-admin-view.component';
import { AuthoritiesAdminListComponent } from './features/admin-tools/authorities/list/authorities-admin-list.component';
import { AuthoritiesAdminViewComponent } from './features/admin-tools/authorities/view/authorities-admin-view.component';
import { PredictionsListComponent } from './features/predictions/list/predictions-list.component';
import { VehicleMessagesListComponent } from './features/vehicle-messages/list/vehicle-messages-list.component';
import { RiderMessagesComponent } from './features/rider-messages/main/rider-messages.component';
import { ReportsHomeComponent } from './features/reports/main/reports-home.component';
import { ReportDetailsComponent } from './features/reports/report-details/report-details.component';
import { ReportViewerComponent } from './features/reports/interactive-viewer/report-viewer.component';
import { ReportTemplateDetailsComponent } from './features/reports/report-template-details/report-template-details.component';
import { MapHomeComponent } from './features/map/home/map-home.component';
import { DashboardMainComponent } from './features/dashboard/dashboard-main/dashboard-main.component';
import { NotAuthorizedComponent } from '@cubicNx/libs/utils';

import { CanDeactivateGuard } from './routing/guard/deactivate-guard';
import { CanActivateGuard } from './routing/guard/activate-guard';

export const routeConfig: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		pathMatch: 'full',
	},
	{
		path: 'reset_password', // param not configured when using ? query parmas notation
		component: ResetPasswordComponent,
		pathMatch: 'full',
	},
	{
		path: 'reset_password_confirm', // param not configured when using ? query parmas notation
		component: ResetPasswordComponent,
		pathMatch: 'full',
	},
	{
		path: 'set_password', // params not configured when using ? query parmas notation
		component: CreatePasswordComponent,
		pathMatch: 'full',
	},
	{
		path: '',
		component: MainComponent,
		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{
				path: 'dashboard',
				component: DashboardMainComponent,
				canActivate: [CanActivateGuard],
				canDeactivate: [CanDeactivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools',
				component: AdminToolsComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/users',
				component: UserAdminListComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/users/profile/:userId',
				component: UserAdminProfileComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/schedules',
				component: SchedulesAdminListComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/schedules/view/:authorityId',
				component: ScheduleAdminViewComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/agencies',
				component: AgenciesAdminListComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/agencies/view/:authorityId/:agencyId',
				component: AgencyAdminViewComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/authorities',
				component: AuthoritiesAdminListComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'admintools/authorities/view/:authorityId',
				component: AuthoritiesAdminViewComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'predictions',
				component: PredictionsListComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'vehicle-messages',
				component: VehicleMessagesListComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'rider-messages',
				component: RiderMessagesComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'reports/:activeTabId',
				component: ReportsHomeComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'reports/available/:authorityId/:agencyId/:defaultTemplateId/:createdById/:reportId',
				component: ReportDetailsComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'reports/viewer/:authorityId/:agencyId/:defaultTemplateId/:createdById/:reportId',
				component: ReportViewerComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'reports/template/:authorityId/:agencyId/:defaultTemplateId/:createdById/:reportTemplateId',
				component: ReportTemplateDetailsComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'map',
				component: MapHomeComponent,
				canActivate: [CanActivateGuard],
				pathMatch: 'full',
			},
			{
				path: 'not_authorized',
				component: NotAuthorizedComponent,
				pathMatch: 'full',
			},
			{ path: '**', redirectTo: 'dashboard', pathMatch: 'full' } /* NEEDS TO BE LAST */,
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routeConfig)],
	providers: [],
	exports: [RouterModule],
})
export class AppRouterModule {}

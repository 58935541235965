/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { OccupancyTooltipComponent } from '../occupancy-tooltip/occupancy-tooltip.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { ComponentInjectionService } from '../../../services/component-injection.service';

import { RoutePillData } from '@cubicNx/libs/utils';

import {
	OccupancyWidgetDataListValues,
	OccupancyWidgetSeats,
	OccupancyWidgetDataListValue,
} from '../../../../../support-features/routes/types/api-types';

@Component({
	selector: 'occupancy-list',
	templateUrl: './occupancy-list.component.html',
	styleUrls: ['./occupancy-list.component.scss'],
})
export class OccupancyListComponent extends TranslateBaseComponent implements OnInit {
	@Input() occupancyListData: OccupancyWidgetDataListValues = null;
	@Input() agency: any = null;
	@Input() listSize: string = null;

	public hasResults: boolean = false;
	public isInitialized: boolean = false;

	private tooltipRef: any = false;

	constructor(
		private componentInjectionService: ComponentInjectionService,
		private mapNavigationService: MapNavigationService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the occupance list component
	 */
	public ngOnInit(): void {
		this.hasResults = this.occupancyListData?.filter((i) => i.occupancy?.total > 0).length > 0;

		this.isInitialized = true;
	}

	/**
	 * navigates to the route details view
	 *
	 * @param authorityId - authority id
	 * @param routeId - route id
	 */
	public navigateToRouteDetails = async (authorityId: string, routeId: string): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(authorityId, routeId);
	};

	/**
	 * retrieves the size of the occupancy data list
	 *
	 * @returns size of the list
	 */
	public getListSize = (): number => {
		if (this.listSize === 'All') {
			return this.occupancyListData.length;
		}

		return +this.listSize;
	};

	/**
	 * returns a style instance of the supplied value and property
	 *
	 * @param value - value
	 * @param prop - property
	 * @returns styling for the supplied value and property
	 */
	public getStyle = (value: any, prop: string): any => {
		const num: number = value[prop];
		const total: number = value.total;
		const percentage: string = ((num / total) * 100).toFixed(2);

		return {
			width: percentage + '%',
			'margin-right': '5px',
			...this.getBackgroundColor(value),
		};
	};

	/**
	 * determines the background color of the supplied route occupance value
	 *
	 * @param value - route occupance value
	 * @returns background color of the supplied route occupance value
	 */
	public getBackgroundColor = (value: string): any => {
		let backgroundColor: string = null;

		switch (value) {
			case 'route.occupancy.unknown':
				backgroundColor = '#dbdbdb';
				break;
			case 'route.occupancy.full':
				backgroundColor = '#B00808;';
				break;
		}

		return backgroundColor && { 'background-color': backgroundColor };
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param route - the route details
	 * @returns route pill
	 */
	public determineRoutePillData = (route: OccupancyWidgetDataListValue): RoutePillData => {
		return {
			routeShortName: null,
			routeLongName: null,
			routeId: route.id,
			routeColor: this.colorUtilityService.getColor(route.routeColor),
			routeTextColor: this.colorUtilityService.getColor(route.textColor),
		};
	};

	/**
	 * Opens a BlockTooltipComponent at the specified position using the componentInjectionService.
	 *
	 * @param event - object containing block and position data.
	 * @param occupancy - the occupancy
	 * @param occupancyType - the occupancy type
	 */
	public openTooltip = (event: any, occupancy: OccupancyWidgetSeats, occupancyType: string): void => {
		const position: any = this.getPosition(event.target);

		this.tooltipRef = this.componentInjectionService.appendComponent(OccupancyTooltipComponent, {
			occupancy,
			occupancyType,
			tooltipPosition: {
				x: position.x,
				y: position.y,
			},
		});
	};

	/**
	 * Closes the tooltip by destroying it.
	 */
	public closeTooltip = (): void => {
		this.tooltipRef?.destroy();
	};

	/**
	 * Gets the target element's position.
	 *
	 * @param target - the target element.
	 * @returns an object with the x & y position.
	 */
	private getPosition = (target: any): any => {
		let offsetLeft: number = 0;
		let offsetTop: number = 0;

		let el: any = target;

		while (el) {
			offsetLeft += el.offsetLeft;
			offsetTop += el.offsetTop;
			el = el.offsetParent;
		}

		return { y: offsetTop, x: offsetLeft };
	};
}

export const minlengthValidation: string = 'minlength';
export const maxlengthValidation: string = 'maxlength';
export const requiredValidation: string = 'required';
export const minValidation: string = 'min';
export const maxValidation: string = 'max';
export const valueInvalid: string = 'value-invalid';
export const valueOutOfBoundsMinValidation: string = 'value-out-of-bounds-min';
export const valueOutOfBoundsMaxValidation: string = 'value-out-of-bounds-max';
export const passwordTooShortValidation: string = 'password-too-short';
export const passwordTooLongValidation: string = 'password-too-long';
export const passwordNeedsUpperValidation: string = 'password-needs-upper';
export const passwordNeedsLowerValidation: string = 'password-needs-lower';
export const passwordNeedsNumberValidation: string = 'password-needs-number';
export const passwordNeedsSpecialValidation: string = 'password-needs-special';
export const passwordsMatchValidation: string = 'passwords-match';
export const passwordNoMatchValidation: string = 'no-match';
export const usernameTooLongValidation: string = 'username-too-long';
export const usernameBadCharValidation: string = 'username-bad-char';
export const patternInvalidValue: string = 'pattern';

export interface ValidationText {
	[key: string]: string;
}

import { UserDetail } from '../../../features/admin-tools/users/types/api-types';

export enum PermissionRequestActionType {
	editAgency,
	viewUserList,
	editUser,
	updateUserStatus,
	viewSchedulesList,
	vehicleReassign,
	sendVehicleMessages,
	postDisablePredictions,
}

export interface UserLoginError {
	status: number;
	message: string;
	passwordExpired?: boolean;
}

export interface PermissionRequest {
	templates?: any;
	isCreate?: any;
	agencies?: any;
	authority?: any;
	agencyId?: string;
	authorityId?: string;
	user?: UserDetail;
}

export interface PasswordExpiry {
	passwordExpiring: boolean;
	passwordExpiryDays?: string;
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { ConfigService } from '@cubicNx/libs/utils';
import { MapLocationService } from './map-location.service';
import { MapStateService } from './map-state.service';

import { Vehicle } from '../../../support-features/vehicles/types/api-types';
import { MapLocation } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapVehicleTrackService {
	constructor(
		private mapStateService: MapStateService,
		private mapLocationService: MapLocationService,
		private configService: ConfigService
	) {}

	/**
	 * toggle the tracked vehicle on or off
	 *
	 * toggle the vehicle in our mapstate between null (tracking off) and our vehicle
	 *
	 * @param vehicle - the vehicle to track on or off
	 */
	public toggleTrackedVehicle = async (vehicle: Vehicle): Promise<void> => {
		if (vehicle.vehicle_id === this.getTrackedVehicleId()) {
			this.setTrackedVehicleId(null);
		} else {
			this.setTrackedVehicleId(vehicle.vehicle_id);

			// send initial location
			this.updateTrackedVehiclePosition(vehicle.current_state.lat, vehicle.current_state.lon, this.configService.getMaxZoomLevel());
		}
	};

	/**
	 * set the tracked vehicle
	 *
	 * @param vehicleId - the id of the vehicle to track
	 */
	public setTrackedVehicleId = (vehicleId: string): void => {
		this.mapStateService.setTrackedVehicleId(vehicleId);
	};

	/**
	 * get the tracked vehicle
	 *
	 * @returns - the id of the currently tracked vehicle
	 */
	public getTrackedVehicleId = (): string => {
		return this.mapStateService.getTrackedVehicleId();
	};

	/**
	 * clear the tracked vehicle
	 */
	public clearTrackedVehicle = (): void => {
		this.mapStateService.setTrackedVehicleId(null);
	};

	/**
	 * update the tracked vehicle position
	 * @param lat - the vehicle latitude
	 * @param lon - the vehicle longitude
	 * @param zoom - the vehicle zoon
	 */
	public updateTrackedVehiclePosition = (lat: number, lon: number, zoom: number): void => {
		const location: MapLocation = {
			lat,
			lon,
			zoom,
			offsetAdjust: true,
			clearTrackedVehicle: false,
		};

		this.mapLocationService.setLocation(location);
	};
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { routesStoreProvider } from './services/routes-store-factory';

import { RoutesApiService } from './services/routes-api.service';
import { RoutesDataService } from './services/routes-data.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
	],
	declarations: [],
	providers: [routesStoreProvider, RoutesDataService, RoutesApiService],
	exports: [],
})
export class RoutesModule {}

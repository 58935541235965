/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { TranslationService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { RoutesUtilService } from '../../../../../support-features/routes/services/routes-util.service';

import { Route, Routes, RouteSummaries } from '../../../../../support-features/routes/types/api-types';
import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { IWidgetEditModalComponent, ListSizeOption, ListSizeOptions } from '../../../types/types';

@Component({
	selector: 'headway-erformance-edit',
	templateUrl: './headway-performance-edit.component.html',
	styleUrls: ['./headway-performance-edit.component.scss'],
})
export class HeadwayPerformanceEditComponent extends WidgetEditBaseComponent implements IWidgetEditModalComponent, OnInit, OnDestroy {
	public loaded: boolean = false;
	public routesSummaries: RouteSummaries = [];
	public selectedRoutes: RouteSummaries[] = [];
	public routeSettings: MultiSelectSettings = {
		id_text: 'route_id',
		value_text: 'route_display_name',
		placeholder: 'Please select a route',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
		maxSelectionCount: 10,
		optionStyles: {
			// apply the italic style under listed conditions as per original dashboard version
			style: { 'font-style': 'italic' },
			fieldComparisonOrOperator: true,
			fields: [
				{ name: 'is_predict_enabled', value: 'false' },
				{ name: 'is_hidden', value: 'true' },
				{ name: 'is_enabled', value: 'false' },
			],
		},
	};

	public listSizeOptions: ListSizeOptions = [];

	constructor(
		private routesDataService: RoutesDataService,
		private routesUtilService: RoutesUtilService,
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * performs initialization tasks for the headway performance edit view - setting up subscriptions,
	 * loading translations and building up the form data
	 */
	public async ngOnInit(): Promise<void> {
		const translationKeys: string[] = ['T_DASH.DB_ALL'];

		this.buildForm(this.addFormControls, {});

		await this.setupAgencies(this.agencyChanged);

		await this.initTranslations(translationKeys);

		this.setup();
		this.setSubscriptions();

		await this.initData();

		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public agencyChanged = (): void => {
		this.handleAgencyChanged(this.getRoutes, this.updateSelectedRoutes);
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param display - the data to check.
	 * @returns a class string.
	 */
	public displayButtonClass = (display: string): string => {
		const current: string = this.reactiveForm.get('display').value;

		return current === display ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget display data.
	 *
	 * @param display - the display to set.
	 */
	public setDisplay = (display: string): void => {
		this.widget.config.display = display;
		this.reactiveForm.get('display').setValue(display);
	};

	/**
	 * Updates the form and widget data with the selectedRoutes.
	 *
	 * @param item - the item to update.
	 */
	public updateItemClicked = (): void => {
		this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
		this.widget.config.selectedRoutes = this.selectedRoutes;
	};

	/**
	 * Updates the listSize for the widget.
	 *
	 * @param event - event with limit number data.
	 */
	public updateListSize = (event: any): void => {
		const update: ListSizeOption = this.listSizeOptions.find((item) => item.value === event.target.value);

		this.widget.config.listSize = update;
	};

	/**
	 * Updates the selected routes for the widget and the form.
	 */
	private updateSelectedRoutes = (): void => {
		this.selectedRoutes = [];
		this.widget.config.selectedRoutes = this.selectedRoutes;
		this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
	};

	/**
	 * Sets up the listSize options mapping.
	 */
	private setup = (): void => {
		this.listSizeOptions = [
			{ option: '5', value: '5' },
			{ option: '10', value: '10' },
			{ option: '15', value: '15' },
			{ option: '20', value: '20' },
			{ option: this.translations['T_DASH.DB_ALL'], value: 'All' },
		];
	};

	/**
	 * Addes extra form controls.
	 */
	private addFormControls = (): void => {
		this.reactiveForm.addControl('selectedRoutes', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('display', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('listSize', new FormControl('', [Validators.required]));
		this.reactiveForm.get('selectedRoutes').setValue(this.widget.config.selectedRoutes);
		this.reactiveForm.get('display').setValue(this.widget.config.display);
		this.reactiveForm.get('listSize').setValue(this.widget.config?.listSize?.value);
	};

	/**
	 * initialize any data based on whether we already have an agency, default to loaded true if first time
	 * as we won't have an agency set yet
	 */
	private initData = async (): Promise<void> => {
		if (this.widget.config.selectedRoutes) {
			this.selectedRoutes = this.widget.config.selectedRoutes;
		}

		if (this.widget.config.selectedAgency) {
			await this.getRoutes();
		} else {
			this.loaded = true;
		}
	};

	/**
	 * Gets the routes for the selectedAgency.
	 */
	private getRoutes = async (): Promise<void> => {
		this.loaded = false;

		if (this.widget.config.selectedAgency) {
			const result: ResultContent = await this.routesDataService.getRoutes(
				this.widget.config.selectedAgency.authority_id,
				this.widget.config.selectedAgency.agency_id
			);

			if (result.success) {
				const routes: Routes = result.resultData;

				// create a route summary rather than saving the whole route object. More effecient and in line with the original
				// dashboard
				this.routesSummaries = routes.map((route: Route) => ({
					route_id: route.route_id,
					route_display_name: this.routesUtilService.getDisplayName(route),
					is_enabled: route.is_enabled,
					is_hidden: route.is_hidden,
					is_predict_enabled: route.is_predict_enabled,
				}));
			}
		}

		this.loaded = true;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class ImageApiService {
	private readonly imagesUrl: string = '/images';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the  image from the nextbus API
	 *
	 * @param imageId - the id of the image to request
	 * @returns the image response
	 */
	public getImage = async (imageId: string): Promise<any> => {
		try {
			return await this.httpService.get(this.imagesUrl + '/' + imageId);
		} catch (exception) {
			throw exception;
		}
	};
}

<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_REPORT.REPORT_TEMPLATE_SELECTOR' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<!-- loading spinner seems to have styling issues if not within mat-dialog-content (and outside tabs)
       also causes issues with the mat-ink-bar. Child components will emit up to here
       if loading spinner required -->
	<loading-overlay [isLoading]="loading" [fit]="'content'"></loading-overlay>

	<div class="tab-container">
		<mat-tab-group #tabs (selectedTabChange)="tabChanged($event)">
			<mat-tab label="{{ 'T_REPORT.DEFAULT_REPORT_TEMPLATES' | translate }}">
				<default-report-templates (templateSelected)="handleTemplateSelectedFromDefault($event)"> </default-report-templates>
			</mat-tab>
			<mat-tab label="{{ 'T_REPORT.SAVED_REPORT_TEMPLATES' | translate }}">
				<saved-report-templates
					#savedReportTemplates
					(setLoading)="setLoading($event)"
					(templateSelected)="handleTemplateSelectedFromSaved($event)">
				</saved-report-templates>
			</mat-tab>
		</mat-tab-group>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
</mat-dialog-actions>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { CurrentUserPermissionService } from '../../../support-features/login/services/current-user/current-user-permission.service';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Injectable()
export class RiderMessagesUtilService {
	constructor(
		private currentUserPermissionService: CurrentUserPermissionService,
		private translationService: TranslationService,
		private notificationService: NotificationService
	) {}

	/**
	 * checks if the current users agency has create message permission
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns true if the users agency has create message permission
	 */
	public canCreateMessages = (authorityId: string, agencyId: string): boolean => {
		return this.hasMessagesPermission('post_authority_agency_alerts', authorityId, agencyId);
	};

	/**
	 * checks if the current users agency has edit message permission
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns true if the users agency has edit message permission
	 */
	public canEditMessages = (authorityId: string, agencyId: string): boolean => {
		return this.hasMessagesPermission('put_authority_agency_alerts_alertid', authorityId, agencyId);
	};

	/**
	 * checks if the current users agency has delete message permission
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns true if the users agency has delete message permission
	 */
	public canDeleteMessages = (authorityId: string, agencyId: string): boolean => {
		return this.hasMessagesPermission('delete_authority_agency_alerts_alertid', authorityId, agencyId);
	};

	/**
	 * checks if the agency has the supplied permission
	 *
	 * @param permission - the permission
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns true if the agency has permission
	 */
	private hasMessagesPermission = (permission: string, authorityId: string, agencyId: string): boolean => {
		if (this.currentUserPermissionService.verifyUserAgencyPermissions(permission, authorityId, agencyId)) {
			return true;
		} else {
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));

			return false;
		}
	};
}

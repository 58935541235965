import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { RoutesModule } from '../../support-features/routes/routes.module';
import { VehiclesModule } from '../../support-features/vehicles/vehicles.module';
import { DepotsModule } from '../../support-features/depots/depots.module';

import { VehicleEventIconComponent } from './details/details-item/event-icon/vehicle-event-icon.component';
import { VehicleEventDetailsItemComponent } from './details/details-item/vehicle-event-details-item.component';
import { VehicleEventDetailsComponent } from './details/vehicle-event-details.component';
import { VehicleEventSettingsComponent } from './settings/vehicle-event-settings.component';

import { vehicleEventsStoreProvider } from './services/vehicle-events-store-factory';

import { VehicleEventsApiService } from './services/vehicle-events-api.service';
import { VehicleEventsDataService } from './services/vehicle-events-data.service';
import { VehicleEventsUtilService } from './services/vehicle-events-util.service';
import { VehicleEventsService } from './services/vehicle-events.service';
import { VehicleEventsAudioService } from '../../home/header/vehicle-events/services/vehicle-events-audio.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		DepotsModule,
		RoutesModule,
		VehiclesModule,
	],
	declarations: [
		VehicleEventSettingsComponent,
		VehicleEventIconComponent,
		VehicleEventDetailsItemComponent,
		VehicleEventDetailsComponent,
	],
	providers: [
		vehicleEventsStoreProvider,
		VehicleEventsService,
		VehicleEventsDataService,
		VehicleEventsUtilService,
		VehicleEventsApiService,
		VehicleEventsAudioService,
	],
	exports: [VehicleEventDetailsComponent, VehicleEventSettingsComponent],
})
export class VehicleEventsModule {}

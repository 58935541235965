import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { ConfirmModalComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { ConfirmButtonType, ConfirmResult } from '@cubicNx/libs/utils';

export interface CanComponentDeactivate {
	canDeactivate: () => Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard {
	constructor(
		private translationService: TranslationService,
		public deactivatePromptModal: MatDialog
	) {}

	/**
	 * determines if the navigation away from a component will be fulfilled or ignored
	 *
	 * the login url is always allowed
	 * any other url is only allowed if the user has clarified with the available prompt
	 *
	 * @param component - the component interface with the canDeactivate method
	 * @param nextState - router snapshot containing the target url
	 * @returns the resuts of the deactivate prompt with a boolean value indicating if the user wishes to leave/stay
	 */
	public canDeactivate = (component: CanComponentDeactivate, nextState?: RouterStateSnapshot): Promise<boolean> | boolean => {
		if (nextState.url === '/login') {
			return true;
		} else {
			return component.canDeactivate ? component.canDeactivate() : true;
		}
	};

	/**
	 * shows a deactivate prompt allowing users to choose leave/stay
	 *
	 * @returns the resuts of the deactivate prompt with a boolean value indicating if the user wishes to leave/stay
	 */
	public showDeactivatePrompt = (): Observable<boolean> => {
		return new Observable((observer) => {
			const message: string = this.translationService.getTranslation('T_CORE.SAVE_PROMPT_MESSAGE');
			const header: string = this.translationService.getTranslation('T_CORE.SAVE_PROMPT_HEADING');

			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deactivatePromptModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message,
					header,
					confirmButtonType: ConfirmButtonType.stayContinueType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				observer.next(result?.confirmed);
			});
		});
	};
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AdminToolsAgenciesModule } from './agencies/admin-tools-agencies.module';
import { AdminToolsAuthoritiesModule } from './authorities/admin-tools-authorities.module';
import { AdminToolsSchedulesModule } from './schedules/admin-tools-schedules.module';
import { AdminToolsUsersModule } from './users/admin-tools-users.module';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { AdminToolsComponent } from './view/admin-tools-view.component';
import { AgencyPortalUtilsModule } from 'src/app/utils/agency-portal-utils.module';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgencyPortalUtilsModule,
		AdminToolsUsersModule,
		AdminToolsAuthoritiesModule,
		AdminToolsAgenciesModule,
		AdminToolsSchedulesModule,
	],
	declarations: [AdminToolsComponent],
	providers: [],
	exports: [],
})
export class AdminToolsModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { ReportViewerSortableSubListBaseComponent } from '../../../report-viewer-sortable-sublist-base.component';

import { ReportsConfigService } from '../../../../../../services/reports-config.service';
import { ReportsGenerationService } from '../../../../../../services/reports-generation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { AdherenceColorService } from '../../ahderence-color.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportVehicle, InteractiveReportAdherenceByVehicleByRouteData } from '../../../../../../types/api-types';
import { AdherenceVehicleArrivalAndDeparture, ReportTemplateSummaryContent } from '../../../../../../types/types';

@Component({
	selector: 'ad300-adherence-by-vehicle-by-route-sub-list',
	templateUrl: './adherence-by-vehicle-by-route-sub-list.component.html',
	styleUrls: ['./adherence-by-vehicle-by-route-sub-list.component.scss'],
})
export class AdherenceByVehicleByRouteSubListComponent extends ReportViewerSortableSubListBaseComponent implements OnInit {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = null;

	constructor(
		public adherenceColorService: AdherenceColorService,
		private reportsGenerationService: ReportsGenerationService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * performs initialization tasks for the adherence by vehicle sub list view (AD300)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * generates the vehicle part of the report data
	 *
	 * @param adherenceData - the adherence data
	 */
	public generateReport = (adherenceData: InteractiveReportAdherenceByVehicleByRouteData): void => {
		const vehicle: ReportVehicle = {
			authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
			vehicle_id: adherenceData.vehicle_id,
			vehicle_name: adherenceData.vehicle_name,
		};

		this.reportsGenerationService.generateVehicleReport(
			AdherenceVehicleArrivalAndDeparture,
			this.templateSummaryContent.template,
			vehicle
		);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TimeHelpers } from '@cubicNx/libs/utils';

import {
	ColumnType,
	Columns,
	CssClassType,
	CssSubClassPosition,
	ListSortings,
	PageRequestInfo,
	SelectedCssClassData,
	SortDirection,
} from '@cubicNx/libs/utils';

import { RoutesDataService } from '../../../../../../support-features/routes/services/routes-data.service';
import { AgenciesDataService } from '../../../../../../support-features/agencies/services/agencies-data.service';
import { MapEventsService } from '../../../../services/map-events.service';
import { VehiclesDataService } from '../../../../../../support-features/vehicles/services/vehicles-data.service';
import { MapHistoryService } from '../../../../services/map-history.service';
import { MapNavigationService } from '../../../../services/map-navigation.service';
import { MapRoutesService } from '../../../../services/map-routes.service';
import { StateService } from '@cubicNx/libs/utils';
import { MapUtilsService } from '../../../../services/map-utils.service';
import { MapVehiclesService } from '../../../../services/map-vehicles.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ListShowingValues, MapVehicles, VehicleDetailsActiveTab } from '../../../../types/types';
import { BlockStatus, BlockType } from '../../../../../../support-features/blocks/types/types';
import { VehicleReassignBlockList, VehicleReassignBlockListItem } from '../../../../../../support-features/vehicles/types/types';
import { RouteBlock, RouteBlocks, RouteBlocksPaginatedResponse } from '../../../../../../support-features/routes/types/api-types';
import { SelectedAgency } from '../../../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'vehicle-reassign-blocks',
	templateUrl: './vehicle-reassign-blocks.component.html',
	styleUrls: ['./vehicle-reassign-blocks.component.scss'],
})
export class VehicleReassignBlocksComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() vehicleId: string = null;
	@Input() vehicleRouteNbId: string = null;
	@Input() reassignRouteId: string = null;
	@Input() reassignRouteNbId: string = null;

	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'blockName', dir: SortDirection.asc }];

	public mapNavigateRefresh$Subscription: Subscription = null;

	public initialized: boolean = false;
	public listName: string = 'vehicle-reassign-blocks-list';
	public columns: Columns = [];
	public pageInfo: PageRequestInfo = null;

	public listLoadingIndicator: boolean = true;
	public totalRows: number = 0;
	public showingFirst: number = 0;
	public showingLast: number = 0;
	public updatedAt: string = null;
	public vehicleAssigning: boolean = false;
	public blocksList: VehicleReassignBlockList = [];
	public blocksSearchSuggestions: string[] = [];
	public searchText: string = '';

	private readonly timeFormat: string = 'HH:mm';

	private readonly blockColumnName: string = 'blockName';
	private readonly blockTimeColumnName: string = 'dateTime';
	private readonly blockStatusColumnName: string = 'status';
	private readonly blockReassignColumnName: string = 'reassign';

	private readonly buttonAssignClass: string = 'btn btn-action assign-button';
	private readonly buttonAssignDisabledClass: string = 'btn btn-action assign-button assign-button-disabled';
	private readonly plusSignClass: string = 'nb-icons nb-add';

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private blocks: RouteBlocks = [];
	private authorityId: string = null;
	private agencyId: string = null;
	private agencyTimezone: string = null;

	constructor(
		private routesDataService: RoutesDataService,
		private agenciesDataService: AgenciesDataService,
		private mapUtilsService: MapUtilsService,
		private mapEventsService: MapEventsService,
		private vehiclesDataService: VehiclesDataService,
		private mapHistoryService: MapHistoryService,
		private stateService: StateService,
		private mapNavigationService: MapNavigationService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesService: MapVehiclesService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();
		this.loadCache();
		this.buildListColumns();

		this.getSelectedAgency();

		this.initialized = true;

		// Do after initialize so it doesnt hold up the list rendering with its loading spinner
		// as we didnt need to wait for search suggestions
		await this.buildSearchSuggestions();
	}

	/**
	 * refresh the list following a refresh click (from parent component)
	 */
	public refresh = async (): Promise<void> => {
		if (this.initialized) {
			await this.getBlocks();
		}
	};

	/**
	 * handle the vehicle data request triggered from the datatable
	 *
	 * @param pageInfo - the page details (page number/size and sorting info)
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getBlocks();
	};

	/**
	 * handle a search from the user and reload the block list with the filter in place
	 *
	 * @param searchText - the search text entered by the user
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getBlocks();
	};

	/**
	 * handle the individual column click of a 'class' item. i.e a column styled using css - in this case the assigned/unassigned badge
	 * @param selectedItem - the individual table cell details
	 */
	public onClassItemSelected = async (selectedItem: SelectedCssClassData): Promise<void> => {
		const selectedBlock: VehicleReassignBlockListItem = selectedItem.item;

		if (selectedItem.columnNameSelected === this.blockReassignColumnName && selectedBlock.available) {
			this.vehicleAssigning = true;

			const block: RouteBlock = this.determineBlock(selectedBlock.blockId);

			await this.assignVehicleToBlock(block);

			this.vehicleAssigning = false;
		}
	};

	/**
	 * get the route blocks from the data layer and update our list
	 */
	public getBlocks = async (): Promise<void> => {
		const secsPastMidnight: number = TimeHelpers.getSecondsPastMidnight(this.agencyTimezone);

		// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
		this.enableRefresh.emit(false);

		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const response: ResultContent = await this.routesDataService.getRouteBlocks(
			this.authorityId,
			this.agencyId,
			this.reassignRouteId,
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.mapColumnName(this.pageInfo.sort),
			this.pageInfo.sortDir,
			this.searchText
		);

		if (response.success) {
			const paginatedResponse: RouteBlocksPaginatedResponse = response.resultData;

			this.blocks = paginatedResponse.results;

			this.blocksList = this.blocks.map((block: RouteBlock) => ({
				blockId: block.block_id,
				blockName: this.getBlockNameDisplay(block.block_id, block.block_type),
				dateTime: this.getDateTimeDisplay(block.start_time_epoch, block.start_time, block.end_time),
				status: this.getStatusDisplay(block.status),
				reassign: this.getReassignDisplay(block, secsPastMidnight),
				available: this.isAvailabile(block.status, block.block_type, block.end_time, secsPastMidnight),
			}));

			this.totalRows = paginatedResponse.total;

			const listShowingValues: ListShowingValues = this.mapUtilsService.getShowingPageValues(
				+this.pageInfo.pageNum,
				+this.pageInfo.pageSize,
				this.totalRows
			);

			this.showingFirst = listShowingValues.showingFirst;
			this.showingLast = listShowingValues.showingTo;

			this.updatedAt = moment().format('HH:mm:ss');
		}

		this.listLoadingIndicator = false;

		this.enableRefresh.emit(true);
	};

	/**
	 * handle the back button click and return to the users previous location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * navigate refresh - handle the click of the parent refresh button and refresh the list
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.refresh();
		});
	};

	/**
	 * get the users current agency details
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
			this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
		}
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_BLOCK',
			'T_MAP.MAP_TIME',
			'T_MAP.MAP_STATUS',
			'T_MAP.MAP_REASSIGN',
			'T_MAP.MAP_ASSIGN',
			'T_MAP.BLOCK_ASSIGNED',
			'T_MAP.BLOCK_UNASSIGNED',
			'T_MAP.BLOCK_CANCELED',
			'T_MAP.TRIPS_CANCELED',
			'T_MAP.MAP_REASSIGN_DISABLED',
			'T_MAP.MAP_REASSIGN_DISABLED_CANCELED',
		]);
	};

	/**
	 * load any persisted data for the component (list page info and filter search text)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * persist the page settings for the list
	 * @param pageInfo - the page settings
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * persist the search text
	 * @param search - the search text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * map the properties from our camel case list to property name recognised by the nextbus API to prepare for our backend sorting
	 * @param sortByValue - the sort by column name
	 * @returns - the sort by property name recognised by the nextbus API
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case this.blockColumnName:
				columnName = 'block_id';
				break;
			case this.blockTimeColumnName:
				columnName = 'start_time';
				break;
			case this.blockStatusColumnName:
				columnName = 'status';
				break;
			default:
				columnName = 'block_id';
		}

		return columnName;
	}

	/**
	 * build the columns for the list
	 *
	 * build the column list for the underlying datatable - camel case equivalents of properties from back end
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: this.blockColumnName,
				displayName: this.translations['T_MAP.MAP_BLOCK'],
				columnType: ColumnType.text,
				width: 85,
			},
			{
				name: this.blockTimeColumnName,
				displayName: this.translations['T_MAP.MAP_TIME'],
				columnType: ColumnType.text,
				width: 145,
			},
			{
				name: this.blockStatusColumnName,
				displayName: this.translations['T_MAP.MAP_STATUS'],
				columnType: ColumnType.cssClass,
				width: 130,
			},
			{
				name: this.blockReassignColumnName,
				displayName: this.translations['T_MAP.MAP_REASSIGN'],
				sortable: false,
				columnType: ColumnType.cssClass,
				width: 160,
			},
		];
	};

	/**
	 * build the list of search suggestions based on the search text
	 *
	 * @param searchText - test seach text
	 */
	private buildSearchSuggestions = async (): Promise<void> => {
		const response: ResultContent = await this.routesDataService.getRouteBlocksSuggestions(
			this.authorityId,
			this.agencyId,
			this.reassignRouteId
		);

		if (response.success) {
			const suggestionList: RouteBlocks = response.resultData.results;

			// build a list of suggestions
			suggestionList.forEach((routeBlock: RouteBlock) => {
				this.addSearchSuggestion(routeBlock.block_id);
			});
		}
	};

	/**
	 * inform the nextbus API of the vehicle reassignment. If the vehicle is showing on the map ( when vehicleExists is true)
	 * then trigger a map update so the map vehicle icon updates (i.e route colour changes)
	 *
	 * @param block - the new block to assign the vehicle to
	 */
	private assignVehicleToBlock = async (block: RouteBlock): Promise<void> => {
		const reassignResponse: ResultContent = await this.vehiclesDataService.reassignVehicleBlock(
			this.authorityId,
			this.agencyTimezone,
			this.vehicleId,
			block
		);

		if (reassignResponse.success) {
			await this.mapRoutesService.updateVehiclesOnRoutes(
				this.vehicleRouteNbId,
				this.reassignRouteNbId,
				this.authorityId,
				this.agencyId
			);

			// refresh the individual vehicle (for the scenario where we are showing the individual vehicle only)
			if (this.mapVehiclesService.vehicleExists(this.vehicleId)) {
				const updatedVehicles: MapVehicles = await this.mapVehiclesService.getNonRouteVehicles(
					this.authorityId,
					[this.vehicleId],
					false
				);

				if (Object.keys(updatedVehicles).length > 0) {
					this.mapVehiclesService.updateVehicles(updatedVehicles);
				}
			}

			await this.mapNavigationService.navigateToVehicleDetails(this.authorityId, this.vehicleId, VehicleDetailsActiveTab.summary);
		}
	};

	/**
	 * determine if the block is available for selection
	 * @param status - the block status
	 * @param type - the block type
	 * @param endTime - the block end time
	 * @param secsPastMidnight - the seconds passed midnight for the timezone
	 * @returns true when the block is available
	 */
	private isAvailabile = (status: BlockStatus, type: BlockType, endTime: number, secsPastMidnight: number): boolean => {
		return status !== BlockStatus.canceled && (type !== BlockType.scheduled || secsPastMidnight <= endTime);
	};

	/**
	 * get the reassin display column style info for the list
	 *
	 * @param block - the block to display
	 * @param secsPastMidnight - the seconds past midnight to determine the available state to retrun the correct style
	 * @returns the style and tooltip object for the view
	 */
	private getReassignDisplay = (block: RouteBlock, secsPastMidnight: number): CssClassType => {
		const value: string = this.translations['T_MAP.MAP_ASSIGN'];
		const isBlockAvailable: boolean = this.isAvailabile(block.status, block.block_type, block.end_time, secsPastMidnight);
		const tooltip: string = block.cancelled
			? this.translations['T_MAP.MAP_REASSIGN_DISABLED_CANCELED']
			: !isBlockAvailable
				? this.translations['T_MAP.MAP_REASSIGN_DISABLED']
				: '';

		return {
			className: isBlockAvailable ? this.buttonAssignClass : this.buttonAssignDisabledClass,
			value,
			tooltip,
			subClassName: this.plusSignClass,
			subClassTooltip: '',
			subClassPosition: CssSubClassPosition.within,
		};
	};

	/**
	 * add the individual search suggestion to the list
	 * @param searchSuggestion - the search suggestion
	 */
	private addSearchSuggestion = (searchSuggestion: string): void => {
		if (!this.blocksSearchSuggestions.includes(searchSuggestion)) {
			this.blocksSearchSuggestions.push(searchSuggestion);
		}
	};

	/**
	 * get the status display value by determining the appropriate css styling for the status column
	 *
	 * @param blockStatus - the block status
	 * @returns the styling for the status dipslay column
	 */
	private getStatusDisplay = (blockStatus: BlockStatus): CssClassType => {
		let className: string = null;
		let value: string = null;
		let tooltip: string = null;

		switch (blockStatus) {
			case BlockStatus.assigned:
				className = 'label nb-block-assigned-full-width';
				value = this.translations['T_MAP.BLOCK_ASSIGNED'];
				tooltip = this.translations['T_MAP.BLOCK_ASSIGNED'];
				break;
			case BlockStatus.unassigned: // class="label nb-block-unassigned"
				className = 'label nb-block-unassigned-full-width';
				value = this.translations['T_MAP.BLOCK_UNASSIGNED'];
				tooltip = this.translations['T_MAP.BLOCK_UNASSIGNED'];
				break;
			case BlockStatus.canceled: // class="label nb-block-canceled"
				className = 'label nb-block-canceled-full-width';
				value = this.translations['T_MAP.BLOCK_CANCELED'];
				tooltip = this.translations['T_MAP.BLOCK_CANCELED'];
				break;
		}

		return {
			className,
			value,
			tooltip,
		};
	};

	/**
	 * determine the block display name for the list
	 *
	 * @param blockId - the block Id
	 * @param blockType  - the block type
	 * @returns the display for the list name column
	 */
	private getBlockNameDisplay = (blockId: string, blockType: BlockType): string => {
		let displayName: string = blockId;

		if (blockType === BlockType.unscheduled) {
			displayName += ' (u)';
		}

		return displayName;
	};

	/**
	 * determine the date time display for the list
	 * @param startTimeEpoch - the start time in epoch format
	 * @param startTime - the start time
	 * @param endTime - the end time
	 * @returns the date time display for the list
	 */
	private getDateTimeDisplay = (startTimeEpoch: number, startTime: number, endTime: number): string => {
		const timeZone: string = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

		return TimeHelpers.getStartEndTimes(startTime, endTime, startTimeEpoch, timeZone, this.timeFormat);
	};

	/**
	 * get the block object for a given block id
	 * @param blockId - the block id
	 * @returns the full block object
	 */
	private determineBlock = (blockId: string): RouteBlock => {
		return this.blocks.find((retrievedBlock: RouteBlock) => retrievedBlock.block_id === blockId);
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
	};
}

<div class="nb-view">
	<div class="header-container">
		<panel-header class="messages-panel-header" [showBackButton]="false" [heading]="'T_RIDER.RIDER_MESSAGES' | translate"> </panel-header>
		<current-agency
			class="nb-panel-header"
			[titleText]="'T_MAP.MAP_AGENCY' | translate"
			[isDisabled]="false"
			(agencyUpdated)="handleAgencyUpdated()"></current-agency>
	</div>
	<div class="clearfix create-container">
		<div class="col-xs-12 nb-padding-left-md nb-padding-top-sm nb-padding-bottom-sm">
			<button (click)="createMessage()" class="btn btn-primary pull-left">
				<i class="nb-icons nb-add"></i>{{ 'T_MESSAGES.CREATE' | translate }}
			</button>
		</div>
	</div>
	<div>
		<mat-tab-group #tabs (selectedTabChange)="tabChanged($event)">
			<mat-tab label="{{ 'T_MESSAGES.ACTIVE' | translate | uppercase }}">
				<rider-messages-list #riderMessages [active]="true"></rider-messages-list>
			</mat-tab>
			<mat-tab label="{{ 'T_MESSAGES.INACTIVE' | translate | uppercase }}">
				<rider-messages-list #riderMessages [active]="false"></rider-messages-list>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

import { Role, Roles } from '../types/api-types';

import { FormattedRolesGroup, FormattedRoles, FormattedRole } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class UsersAdminUtilService {
	constructor(private translationService: TranslationService) {}

	/**
	 * formats the supplied roles into a group of roles
	 *
	 * @param roles - roles
	 * @returns formatted roles group
	 */
	public formatUserRoles = (roles: Roles): FormattedRolesGroup => {
		const tmpRoles: FormattedRoles = [];
		const userMasterRoles: FormattedRoles = [];
		const userRoles: FormattedRoles = [];

		roles.forEach((role: Role) => {
			const tmpRole: FormattedRole = {
				nbId: role.nb_id,
				authority: role.authority,
				associatedRole: role.associated_role,
				agencyInfo: role.agency_info,
				userRoleAuthority: role.user_role_authority,
				roleName: null,
				displayName: null,
				authorityName: null,
				sortStr: null,
			};

			tmpRoles.push(tmpRole);
		});

		for (const tmpRole of tmpRoles) {
			if (tmpRole.associatedRole.name === 'BASIC_USER' || tmpRole.associatedRole.name === 'GUEST_USER') {
				continue;
			}

			if (tmpRole.agencyInfo === null && tmpRole.authority === null && tmpRole.associatedRole.name !== 'UMO_ADMIN') {
				continue;
			}

			if (tmpRole.agencyInfo === null && tmpRole.authority === null && tmpRole.associatedRole.name === 'UMO_ADMIN') {
				tmpRole.roleName = tmpRole.associatedRole.name;
				userMasterRoles.push(tmpRole);
				continue;
			}

			if (
				!tmpRole.userRoleAuthority ||
				!tmpRole.userRoleAuthority.authority_info ||
				!tmpRole.userRoleAuthority.authority_info.authority_name ||
				tmpRole.userRoleAuthority.authority_info.authority_name.length === 0
			) {
				tmpRole.userRoleAuthority = {
					authority_info: {
						authority_id: tmpRole.authority,
						authority_name: tmpRole.authority,
					},
				};
			}

			tmpRole.authorityName = tmpRole.userRoleAuthority.authority_info.authority_name;

			if (
				(tmpRole.agencyInfo === null && tmpRole.authority !== null) ||
				(tmpRole.agencyInfo !== null &&
					tmpRole.agencyInfo.agency_id === null &&
					tmpRole.userRoleAuthority !== null &&
					tmpRole.userRoleAuthority.authority_info !== null &&
					tmpRole.userRoleAuthority.authority_info.authority_id !== null)
			) {
				tmpRole.roleName = tmpRole.associatedRole.international_name
					? this.translationService.getTranslation('T_USER.' + tmpRole.associatedRole.international_name)
					: this.translationService.getTranslation('T_USER.' + tmpRole.associatedRole.name);

				tmpRole.displayName = tmpRole.authorityName;
				tmpRole.sortStr = tmpRole.authorityName + '000000000000' + tmpRole.roleName;

				userRoles.push(tmpRole);
				continue;
			}

			tmpRole.roleName = tmpRole.associatedRole.international_name
				? this.translationService.getTranslation('T_USER.' + tmpRole.associatedRole.international_name)
				: this.translationService.getTranslation('T_USER.' + tmpRole.associatedRole.name);

			tmpRole.displayName = tmpRole.agencyInfo.agency_name;
			tmpRole.sortStr = tmpRole.authorityName + tmpRole.agencyInfo.agency_name + tmpRole.roleName;

			userRoles.push(tmpRole);
		}

		userRoles.sort(this.compareRoles);

		const formattedRolesGroup: FormattedRolesGroup = {
			master: userMasterRoles,
			roles: userRoles,
		};

		return formattedRolesGroup;
	};

	/**
	 * sorts the roles
	 *
	 * @param a - formatted role
	 * @param b - formatted role
	 * @returns sorted roles
	 */
	private compareRoles = (a: FormattedRole, b: FormattedRole): number => {
		if (a.sortStr < b.sortStr) {
			return -1;
		}

		if (a.sortStr > b.sortStr) {
			return 1;
		}

		return 0;
	};
}

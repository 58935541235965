import { TranslateBaseComponent } from './../translate-base/translate-base.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

import { MultiSelectOptionStyleField, MultiSelectSettings } from './types/types';

@Component({
	selector: 'multi-select',
	templateUrl: './multi-select.component.html',
	styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild('filter') filterInput: ElementRef;

	@Input() items: any[] = [];
	@Input() selected: any[] = [];
	@Input() settings: MultiSelectSettings = {
		id_text: 'id',
		value_text: 'value',
		placeholder: 'Please select',
		readonly: false,
		selectedItemBadged: false,
		showCheckbox: false,
		showDropdownCaret: true,
		filterWithSelected: true,
		enableSearchFilter: true,
		maxSelectionCount: -1, // use -1 to denote no max count
		optionStyles: null,
	};

	@Output() itemClickedEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() removeItemClicked: EventEmitter<any> = new EventEmitter<any>();

	public dropDownOpen: boolean = false;
	public allItemsSelected: boolean = false;
	public filterText: string = '';

	private readonly ALL: string = 'all';

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handles the initialization of the multi select control
	 *
	 * determines if all items are already selected based on input values
	 */
	public ngOnInit(): void {
		this.allItemsSelected = this.items?.length === this.selected?.length;
	}

	/**
	 * handles the closing of the drop down
	 */
	public closeDropDown = (): void => {
		this.dropDownOpen = false;
		this.filterText = '';
	};

	/**
	 * handles the user toggle of the drop down
	 */
	public toggleDropDown = (): void => {
		if (this.settings.selectedItemBadged && !this.settings.readonly) {
			this.dropDownOpen = !this.dropDownOpen;
			this.filterText = '';

			if (this.dropDownOpen && this.settings.enableSearchFilter) {
				// allow it to render before setting focus to search
				this.changeDetectorRef.detectChanges();
				this.filterInput.nativeElement.focus();
			}
		}
	};

	/**
	 * handles the user toggle of the drop down if selected item unbadged
	 */
	public toggleDropDownIfSelectedItemUnbadged = (): void => {
		if (!this.settings.showDropdownCaret || (!this.settings.selectedItemBadged && !this.settings.readonly)) {
			this.dropDownOpen = !this.dropDownOpen;
			this.filterText = '';

			if (this.dropDownOpen && this.settings.enableSearchFilter) {
				// allow it to render before setting focus to search
				this.changeDetectorRef.detectChanges();
				this.filterInput.nativeElement.focus();
			}
		}
	};

	/**
	 * determines if the item is selected
	 *
	 * @param item - the current multi select item to be checked
	 * @returns true when the item is selected
	 */
	public isSelected = (item: any): boolean => {
		const index: number = this.getSelectedItemIndex(item[this.settings.id_text]);

		return index > -1;
	};

	/**
	 * handles the click action of an item in the multi select drop down
	 *
	 * @param item - the current multi select item
	 */
	public onItemClick = (item: any): void => {
		const all: any[] = this.items.find((i) => i[this.settings.id_text] === this.ALL);

		if (item === all) {
			this.toggleSelectAll();
		} else {
			this.allItemsSelected = false;
			const index: number = this.getSelectedItemIndex(item[this.settings.id_text]);

			this.checkOrUncheckItem(item, index, all);
		}

		this.filterText = '';

		this.closeDropDown();
		this.itemClickedEvent.emit(this.selected);
	};

	/**
	 * determines if ther are any selected items chosen
	 *
	 * @returns a flag indicating if any items are selected
	 */
	public hasSelections = (): boolean => {
		return this.selected && this.selected.length > 0;
	};

	/**
	 * removes the selected item from the selected list
	 *
	 * @param item - the item to be removed
	 */
	public removeSelectedItem = (item: any): void => {
		const index: number = this.getSelectedItemIndex(item[this.settings.id_text]);

		if (index !== -1) {
			this.selected.splice(index, 1);
		}

		this.allItemsSelected = false;
		this.removeItemClicked.emit(item);
	};

	/**
	 * returns all items that pass the filter
	 *
	 * @returns items that pass the filter
	 */
	public getItems = (): any[] => {
		if (this.settings.filterWithSelected) {
			const selectedIds: any[] = this.selected?.map((selectedId) => selectedId[this.settings.id_text]) ?? [];

			return this.items?.filter(
				(item) =>
					!selectedIds.includes(item[this.settings.id_text]) &&
					item[this.settings.value_text].toUpperCase().includes(this.filterText.toUpperCase())
			);
		} else {
			return this.items?.filter((item) => item[this.settings.value_text].toUpperCase().includes(this.filterText.toUpperCase()));
		}
	};

	/**
	 * get the styling for the multi select options
	 *
	 * @param item - item to style
	 * @returns the styling for the options
	 */
	public getOptionStyle = (item: any): any => {
		if (this.settings.optionStyles) {
			let applyStyle: boolean = false;

			if (this.settings.optionStyles.fieldComparisonOrOperator) {
				applyStyle = false;

				// apply the style if any of the fields match the condition
				this.settings.optionStyles.fields.forEach((field: MultiSelectOptionStyleField) => {
					if (item[field.name].toString() === field.value) {
						applyStyle = true;
					}
				});
			} else {
				applyStyle = true;

				// apply the style only if all of the fields match the condition
				this.settings.optionStyles.fields.forEach((field: MultiSelectOptionStyleField) => {
					if (item[field.name].toString() !== field.value) {
						applyStyle = false;
					}
				});
			}

			if (applyStyle) {
				return this.settings.optionStyles.style;
			}
		}

		return {};
	};

	/**
	 * handles the checking (or unchecking) of an item in the list
	 *
	 * @param item - item being checked/unchecked
	 * @param index - the index of the item
	 * @param all - the 'all' selection
	 */
	private checkOrUncheckItem = (item: any, index: number, all: any): void => {
		if (this.selected && this.isSelected(item)) {
			this.selected.splice(index, 1);
			if (all) {
				const allIndex: number = this.getSelectedItemIndex(all[this.settings.id_text]);

				if (allIndex !== -1) {
					this.selected.splice(allIndex, 1);
				}
			}
		} else {
			let addItem: boolean = true;

			// if max allowed is 1 and we already have an item selected then replace selected item
			if (this.settings.maxSelectionCount === 1 && this.selected.length === 1) {
				this.selected = [];
			} else {
				if (this.settings.maxSelectionCount >= 0 && this.selected.length >= this.settings.maxSelectionCount) {
					addItem = false;
				}
			}

			if (addItem) {
				this.selected.push(item);
				if (all) {
					this.allItemsChecked();
				}
			}
		}
	};

	/**
	 * handles the checking of all items
	 */
	private allItemsChecked = (): void => {
		const currentSelections: any[] = this.selected.filter((i) => i[this.settings.id_text] !== this.ALL);
		const availableSelections: any[] = this.items.filter((i) => i[this.settings.id_text] !== this.ALL);

		if (currentSelections.length === availableSelections.length) {
			this.toggleSelectAll();
		}
	};

	/**
	 * toggles the select all option
	 */
	private toggleSelectAll = (): void => {
		const all: any[] = this.selected.find((i) => i[this.settings.id_text] === this.ALL);

		// just setting the length to 0 clears down the array without destroying the object and associated changed detection
		this.selected.length = 0;

		if (!all) {
			for (const item of this.items) {
				this.selected.push(item);
			}
		}

		this.allItemsSelected = true;
	};

	/**
	 * determines the selected item index based on the id of the item
	 *
	 * @param id - the id of the item
	 * @returns the selected item index
	 */
	private getSelectedItemIndex = (id: any): number => {
		return this.selected ? this.selected.map((e) => e[this.settings.id_text]).indexOf(id) : -1;
	};
}

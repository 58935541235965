<div class="structure-item" data-test="layouts" (mouseover)="mouseOver()" (mouseout)="mouseOut()" (click)="onClick($event, structure.name)">
	<div class="row structure-height">
		<div *ngFor="let row of structure.rows" class="structure-border {{ row.styleClass }}">
			<div
				*ngFor="let column of row.columns"
				class="structure-border column {{ column.class }}"
				[ngClass]="active || selected ? 'structure-active' : 'structure-inactive'"></div>
		</div>
	</div>
</div>

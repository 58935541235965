/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { Moment } from 'moment';

import { LoggerService } from '@cubicNx/libs/utils';
import { ReplayApiService } from './replay-api.service';

import { InitStatusParams, InitStatusResponse, InitReplayParams, InitReplayResponse } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class ReplayDataService {
	private readonly dateTimestampFormat: string = 'YYYY-MM-DD HH:mm Z';

	constructor(
		private replayApiService: ReplayApiService,
		private loggerService: LoggerService
	) {}

	/**
	 * handle the update to the nextbus API to initialize replay mode
	 *
	 * @param authorityId - the authoirity id
	 * @param startMoment - the start time to request replay data
	 * @param endMoment - the end time to request replay data
	 * @param includePredictions - a flag to specify if we should include predictions in the replay data
	 * @returns the nextbus API response
	 */
	public init = async (
		authorityId: string,
		startMoment: Moment,
		endMoment: Moment,
		includePredictions: boolean
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const requestParams: InitReplayParams = {
				authorityId,
				startDate: startMoment.format(this.dateTimestampFormat),
				endDate: endMoment.format(this.dateTimestampFormat),
				includePredictions,
			};

			const results: InitReplayResponse = await this.replayApiService.init(authorityId, requestParams);

			result.success = true;
			result.resultData = results;
		} catch (error) {
			this.loggerService.logError('Failed to initialize replay data', error);
		}

		return result;
	};

	/**
	 * handle the request to get current status from the nextbus API regarding the initialization of replay mode.
	 * Essentially this is used to poll the nextbus API for replay initialization completion
	 *
	 * @param authorityId - the authoirity id
	 * @param replayId - the replay id returned in the init call above
	 * @returns the nextbus API response
	 */
	public getInitStatus = async (authorityId: string, replayId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const requestParams: InitStatusParams = {
				replayId,
			};

			const results: InitStatusResponse = await this.replayApiService.getInitStatus(authorityId, requestParams);

			result.success = true;
			result.resultData = results;
		} catch (error) {
			this.loggerService.logError('Failed to get replay initialization status', error);
		}

		return result;
	};
}

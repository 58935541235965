/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { VehicleMessagesCreateComponent } from '../create/vehicle-messages-create.component';
import { NotificationService } from '@cubicNx/libs/utils';
import { CurrentUserPermissionService } from '../../../support-features/login/services/current-user/current-user-permission.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { PermissionRequest, PermissionRequestActionType } from '../../../support-features/login/types/types';

@Injectable()
export class VehicleMessagesModalService {
	constructor(
		private createMessageModal: MatDialog,
		private currentUserPermissionService: CurrentUserPermissionService,
		private notificationService: NotificationService,
		private translationService: TranslationService
	) {}

	/**
	 * renders the create message dialog
	 *
	 * only if the user has permission
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns true if the message was created
	 */
	public createMessage = (authorityId: string, agencyId: string): Observable<boolean> => {
		return new Observable((observer) => {
			const permissionRequest: PermissionRequest = {
				authorityId,
				agencyId,
			};

			if (this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.sendVehicleMessages, permissionRequest)) {
				const modalRef: MatDialogRef<VehicleMessagesCreateComponent> = this.createMessageModal.open(
					VehicleMessagesCreateComponent,
					{
						minHeight: '570px', //essentially sets an initial height for the page (while loading spinner active)
						width: '938px',
						data: {},
						position: {
							top: '60px',
						},
					}
				);

				return modalRef.afterClosed().subscribe(async (messageCreated: boolean) => {
					observer.next(messageCreated);
				});
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));

				return observer.next(null);
			}
		});
	};
}

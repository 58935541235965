import { Injectable } from '@angular/core';

import { TokenService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class LoginUtilService {
	constructor(private tokenService: TokenService) {}

	/**
	 * determines whether the user is logged in
	 *
	 * @returns the logged in state
	 */
	public isLoggedIn = (): boolean => {
		return this.tokenService.getApiToken() ? true : false;
	};
}

<div class="chart-container" data-test="occupancy.pie-chart">
	<div *ngIf="hasResults" class="chart-summary">
		<div class="chart-header">
			<div class="chart-title-value" data-test="occupancy.overall-percentage">{{ titleVal }}%</div>
			<div data-test="occupancy.overall-text">
				{{ chartTitle }}
			</div>
		</div>
		<nvd3 [options]="options" [data]="data"></nvd3>
	</div>
	<div *ngIf="!hasResults" class="occuppany-no-data-container">
		<div class="occuppany-no-data-flex-center">
			<div class="occuppany-no-data-flex-center">
				<span class="no-data occupancy-no-data">{{ translations.DB_NO_DATA_FOUND }}</span>
			</div>
		</div>
	</div>
</div>

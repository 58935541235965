/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, ComponentRef, Injector, Input, OnDestroy, OnInit, StaticProvider, Type } from '@angular/core';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsDataService } from '../../services/reports-data.service';
import { DataService } from '@cubicNx/libs/utils';
import { ReportsViewLookupService } from '../../services/reports-view-lookup.service';
import { ReportsEventsService } from '../../services/reports-events.service';
import { ReportsConfigService } from '../../services/reports-config.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { DefaultPagination, Pagination, PaginationSettings } from '@cubicNx/libs/utils';
import { ReportConfiguration, ReportListPortalDetails, ReportTemplateSummaryContent, SortDetails } from '../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { InteractiveReportPartPaginatedResponse } from '../../types/api-types';

@Component({
	selector: 'report-viewer-list',
	templateUrl: './report-viewer-list.component.html',
	styleUrls: ['./report-viewer-list.component.scss'],
})
export class ReportViewerListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = null;
	@Input() defaultTemplateId: string = null;
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() createdById: number = null;
	@Input() reportId: string = null;

	public loading: boolean = true;
	public listInitialized: boolean = false;

	public portal: ComponentPortal<any> = null;

	public paginationSettings: PaginationSettings = null;
	public pagination: Pagination = { ...DefaultPagination };

	public sortDetails: SortDetails = null;

	public showPagingToolbar: boolean = true;

	private readonly listRequest: string = 'list';
	private readonly listTotalsRequest: string = 'list2';

	// Reference to the dynamic child view
	private reportViewComponentRef: ComponentRef<any> = null;

	private portalData: ReportListPortalDetails = {
		params: {
			defaultTemplateId: null,
			templateSummaryContent: null,
			authorityId: null,
			agencyId: null,
		},
		view: null,
	};

	private loadReportList$Subscription: Subscription = null;

	constructor(
		private reportsViewLookupService: ReportsViewLookupService,
		private reportsConfigService: ReportsConfigService,
		private reportsDataService: ReportsDataService,
		private reportsEventsService: ReportsEventsService,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the available reports viewer list compoenent
	 *
	 * sets up subscriptions and default values, and loads dynamic list component
	 */
	public async ngOnInit(): Promise<void> {
		const listView: Type<any> = this.reportsViewLookupService.getListView(this.defaultTemplateId);

		if (listView) {
			this.setSubscriptions();

			this.setUpDefaults();

			this.setUpView(listView);
		}
	}

	/**
	 * rememebers the dynamically loaded reference (report view component)
	 *
	 * @param attachedRef - the reference to the report view component
	 */
	public attachChildDynamicComponent = (attachedRef: CdkPortalOutletAttachedRef): void => {
		this.reportViewComponentRef = attachedRef as ComponentRef<any>;
	};

	/**
	 * page data has changed so load the list data
	 */
	public pageChanged = async (): Promise<void> => {
		await this.loadListData();
	};

	/**
	 * page size  has changed to load the list data
	 */
	public setPageSize = async (): Promise<void> => {
		this.pagination.currentPage = 1;

		await this.loadListData();
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * sets up the dynamically loaded view list component
	 *
	 * @param listView - the dynamically loaded list component
	 */
	private setUpView = (listView: any): void => {
		// Setup the dynamic report list component with relevant input parameters
		this.initViewParameters();

		this.portalData.view = listView;

		const sp: Array<StaticProvider> = [{ provide: DataService, useValue: this.portalData.params }];

		const dynamicComponentInjector: Injector = Injector.create({ providers: sp });

		this.portal = new ComponentPortal(this.portalData.view, null, dynamicComponentInjector);
	};

	/**
	 * remembers the view input parameters
	 */
	private initViewParameters = (): void => {
		this.portalData.params.templateSummaryContent = this.templateSummaryContent;
		this.portalData.params.defaultTemplateId = this.defaultTemplateId;
		this.portalData.params.authorityId = this.authorityId;
		this.portalData.params.agencyId = this.agencyId;
	};

	/**
	 * sets up the defaults for the view being loaded
	 */
	private setUpDefaults = (): void => {
		const reportConfig: ReportConfiguration = this.reportsConfigService.getReportConfiguration(this.defaultTemplateId);

		this.paginationSettings = {
			maxPageSize: reportConfig.paginationSettings.maxPageSize,
			pageSize: reportConfig.paginationSettings.initialPageSize,
		};
	};

	/**
	 * sets up subscriptions
	 *
	 * is interested when the user has changed the sort column so the sorting can be achieved
	 */
	private setSubscriptions = (): void => {
		this.loadReportList$Subscription = this.reportsEventsService.loadReportList.subscribe(async (sortDetails: SortDetails) => {
			await this.handleLoadReportList(sortDetails);
		});
	};

	/**
	 * reacts to a sort request on the loaded list
	 *
	 * @param sortDetails - the sort criteria
	 */
	private handleLoadReportList = async (sortDetails: SortDetails): Promise<void> => {
		// Update new sort details and reset the pagination of the main list
		this.pagination.currentPage = 1;
		this.sortDetails = sortDetails;

		await this.loadListData();
	};

	/**
	 * updates the contents of the list view dependent upon the page information
	 */
	private loadListData = async (): Promise<void> => {
		this.loading = true;

		const response: ResultContent = await this.reportsDataService.getInteractiveReportList(
			this.authorityId,
			this.agencyId,
			this.reportId,
			this.createdById,
			this.defaultTemplateId,
			this.pagination.currentPage,
			this.paginationSettings.pageSize,
			this.sortDetails ? this.sortDetails.field : undefined,
			this.sortDetails ? this.sortDetails.dir : undefined,
			this.listRequest
		);

		if (response.success) {
			const reportResults: InteractiveReportPartPaginatedResponse = response.resultData;

			// Pass the list data down to the child view
			this.reportViewComponentRef.instance.updateListView(reportResults.results);

			this.pagination.totalItems = reportResults.total;
			this.pagination.numPages = reportResults.totalPages;

			// Special case with 2nd list for certain reports
			if (
				this.defaultTemplateId === 'config-holidays' ||
				this.defaultTemplateId === 'rider-count-by-route' ||
				this.defaultTemplateId === 'rider-count-by-route-by-stop' ||
				this.defaultTemplateId === 'rider-count-by-trip' ||
				this.defaultTemplateId === 'rider-count-by-block' ||
				this.defaultTemplateId === 'rider-count-by-vehicle'
			) {
				const interactiveReportListResults: ResultContent = await this.reportsDataService.getInteractiveReportList(
					this.authorityId,
					this.agencyId,
					this.reportId,
					this.createdById,
					this.defaultTemplateId,
					this.pagination.currentPage,
					this.paginationSettings.pageSize,
					this.sortDetails.field,
					this.sortDetails.dir,
					this.listTotalsRequest
				);

				if (interactiveReportListResults.success) {
					const interactiveReportData: InteractiveReportPartPaginatedResponse = interactiveReportListResults.resultData;

					// Pass the list totals data down to the child view
					this.reportViewComponentRef.instance.updateListTotalsView(interactiveReportData.results);
				}
			}

			this.loading = false;
			this.listInitialized = true;
		} else {
			this.router.navigate(['reports/home.0']);
		}
	};

	/**
	 * unsubscribes from loading reports subscription
	 */
	private unsubscribe = (): void => {
		this.loadReportList$Subscription?.unsubscribe();
	};
}

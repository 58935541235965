/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';
import { MapNavigationService } from './map-navigation.service';

import { NavigationPage, NavigationPages } from '../types/types';
import { EntityType } from '../../../utils/components/breadcrumbs/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapHistoryService {
	private readonly defaultStartPage: NavigationPage = { entityType: EntityType.menu, pageType: null, entity: null };

	private history: NavigationPages = [];

	constructor(
		private mapStateService: MapStateService,
		private mapNavigationService: MapNavigationService
	) {}

	/**
	 * init the history service. Get the last history page from the peristed cache
	 * set to the default (menu) page if there is no history persisted
	 */
	public init = (): void => {
		this.history = this.mapStateService.getNavHistory();

		if (this.history.length === 0) {
			this.addPage(this.defaultStartPage);
		}
	};

	/**
	 * navigate to the last history page in our persisted cache
	 */
	public navigateToLastHistoryItem = (): void => {
		// navigate to the page from the previous session or default to the menu
		const historyItem: NavigationPage = this.history.length > 0 ? this.history.pop() : null;

		if (historyItem) {
			this.navigateToHistoryItem(historyItem);
		}
	};

	/**
	 * add a visited page to the history service
	 *
	 * @param newItem - the new page  to add
	 */
	public addPage = (newItem: NavigationPage): void => {
		// not sure if duplicates are possible so maybe safe to remove - leaving in to be safe
		if (!this.isDuplicate(newItem)) {
			if (newItem.entity?.replaceHistoryItem) {
				// remove the replace option now we have dealt with it, otherwise when we goBack to this page
				// it won't put it back on the history stack
				delete newItem.entity?.replaceHistoryItem;

				this.history[this.history.length - 1] = newItem;
			} else {
				this.history.push(newItem);
			}

			this.save();
		}
	};

	/**
	 * clear all entries in the persisted history service
	 */
	public clear = (): void => {
		this.history = [];
		this.save();
	};

	/**
	 * go back to the previous page.
	 */
	public goBack = (): void => {
		// pop off the current page that is active now
		this.history.pop();

		const previousItem: NavigationPage = this.history.pop();

		this.navigateToHistoryItem(previousItem);
	};

	/**
	 * reset (re-initialize) the history service and navigate to the last item
	 */
	public reset = (): void => {
		this.init();
		this.navigateToLastHistoryItem();
	};

	/**
	 * navigate to a history page
	 *
	 * @param historyNavPage - the page to navigat to
	 */
	private navigateToHistoryItem = (historyNavPage: NavigationPage): void => {
		this.mapNavigationService.navigateToPage(historyNavPage, false);
	};

	/**
	 * save the current history to the map state
	 */
	private save = (): void => {
		this.mapStateService.setNavHistory(this.history);
	};

	/**
	 * checks if the page is the same as the last item item - i.e is a duplicate page on the history stack
	 *
	 * @param newNavPage - the navigation page to check
	 * @returns true if the page is a duplicate
	 */
	private isDuplicate = (newNavPage: NavigationPage): boolean => {
		if (this.history.length > 0) {
			const lastHistoryItem: NavigationPage = this.history[this.history.length - 1];

			if (JSON.stringify(lastHistoryItem) === JSON.stringify(newNavPage)) {
				// ignore duplicates
				return true;
			}
		}

		return false;
	};
}

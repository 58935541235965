/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';

import { VehicleEventsSettings } from '../types/types';
import { VehicleSummaries } from '../../../support-features/vehicles/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class VehicleEventsUtilService {
	constructor(private logger: LoggerService) {}

	/**
	 * dependent upon the vehicle filter values will determine a list of vehicle identifiers that match the filter criteria
	 *
	 * @param settings - the vehicle event settings
	 * @param vehicles - the vehicles
	 * @returns filtered vehicle identifiers
	 */
	public getFilteredVehicles = (settings: VehicleEventsSettings, vehicles: VehicleSummaries): string[] => {
		let vehicleIds: string[] = [];

		if (settings.vehicleFilterSelection.value === 'vehicles') {
			vehicleIds = settings.selectedVehicles.vehicles.map((v) => v.vehicle_id);
			if (vehicleIds.length === 0) {
				return [];
			}
		} else if (settings.vehicleFilterSelection.value === 'depots') {
			const depotTags: string[] = settings.selectedDepots.depots.map((d) => d.tag);

			vehicleIds = vehicles.filter((v) => depotTags.includes(v.current_state.last_depot)).map((v) => v.vehicle_id);
			if (vehicleIds.length === 0) {
				this.logger.logWarn('Vehicle Events: No vehicles found at chosen depot(s)');

				return [];
			}
		}

		return vehicleIds;
	};

	/**
	 * dependent upon the specific routes filter values will determine a list of route identifiers that match the filter criteria
	 *
	 * @param settings - the vehicle event settings
	 * @returns filtered route identifiers
	 */
	public getFilteredRoutes = (settings: VehicleEventsSettings): string[] => {
		let routeIds: string[] = [];

		if (settings.vehicleFilterSelection.value === 'routes') {
			routeIds = settings.selectedRoutes.routes.map((r) => r.route_id);
			if (routeIds.length === 0) {
				return [];
			}
		}

		return routeIds;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MapUpdateEvent, MapUpdateType, NavigationPage } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapEventsService {
	public mapUpdate: Observable<any>;
	public navigateToPage: Observable<any>;
	public settingsBaseMapChangeComplete: Observable<any>;
	public navigateRefresh: Observable<any>;
	public pollRefresh: Observable<any>;
	public zoomIn: Observable<any>;
	public zoomOut: Observable<any>;
	public replayInitComplete: Observable<any>;
	public stateChanged: Observable<any>;
	public removeTooltips: Observable<any>;

	private mapUpdateSource: Subject<any> = new Subject<any>();
	private navigateToPageSource: Subject<any> = new Subject<any>();
	private settingsBaseMapChangeCompleteSource: Subject<any> = new Subject<any>();
	private navigateRefreshSource: Subject<any> = new Subject<any>();
	private pollRefreshSource: Subject<any> = new Subject<any>();
	private zoomInSource: Subject<any> = new Subject<any>();
	private zoomOutSource: Subject<any> = new Subject<any>();
	private replayInitCompleteSource: Subject<any> = new Subject<any>();
	private stateChangedSource: Subject<any> = new Subject<any>();
	private removeTooltipsSource: Subject<any> = new Subject<any>();

	constructor() {
		this.mapUpdate = this.mapUpdateSource.asObservable();
		this.navigateToPage = this.navigateToPageSource.asObservable();
		this.settingsBaseMapChangeComplete = this.settingsBaseMapChangeCompleteSource.asObservable();
		this.navigateRefresh = this.navigateRefreshSource.asObservable();
		this.pollRefresh = this.pollRefreshSource.asObservable();
		this.zoomIn = this.zoomInSource.asObservable();
		this.zoomOut = this.zoomOutSource.asObservable();
		this.replayInitComplete = this.replayInitCompleteSource.asObservable();
		this.stateChanged = this.stateChangedSource.asObservable();
		this.removeTooltips = this.removeTooltipsSource.asObservable();
	}

	/**
	 * Publish a map update event allowing subscribers to react to map actions.
	 *
	 * @param mapUpdateType - the map update type
	 * @param params - any additional parameters
	 */
	public publishMapUpdate = (mapUpdateType: MapUpdateType, params: any = null): void => {
		const event: MapUpdateEvent = {
			mapUpdateType,
			params,
		};

		this.mapUpdateSource.next(event);
	};

	/**
	 * send an event to navigate to the appropriate page
	 *
	 * @param navigationPage - the page to navigate to
	 */
	public publishNavigateToPage = (navigationPage: NavigationPage): void => {
		this.navigateToPageSource.next(navigationPage);
	};

	/**
	 * Publish a setting base map change complete event allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishSettingsBaseMapChangeComplete = (): void => {
		// @ts-ignore
		this.settingsBaseMapChangeCompleteSource.next();
	};

	/**
	 * Publish a navigate refresh event allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishNavigateRefresh = (): void => {
		// @ts-ignore
		this.navigateRefreshSource.next();
	};

	/**
	 * Publish a map poll refresh allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishPollRefresh = (): void => {
		// @ts-ignore
		this.pollRefreshSource.next();
	};

	/**
	 * Publish a map zoom in refresh allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishZoomIn = (): void => {
		// @ts-ignore
		this.zoomInSource.next();
	};

	/**
	 * Publish a map zoom out refresh allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishZoomOut = (): void => {
		// @ts-ignore
		this.zoomOutSource.next();
	};

	/**
	 * Publish a replay init complete allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishReplayInitComplete = (event: any): void => {
		this.replayInitCompleteSource.next(event);
	};

	/**
	 * Publish a state change allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishStateChanged = (): void => {
		// @ts-ignore
		this.stateChangedSource.next();
	};

	/**
	 * Publish a remove tooltips event allowing subscribers to handle events.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishRemoveTooltips = (): void => {
		// @ts-ignore
		this.removeTooltipsSource.next();
	};
}

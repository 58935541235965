/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../map/services/map-navigation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { VehicleEventsService } from '../../services/vehicle-events.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { VehicleEvent } from '../../types/api-types';
import { RoutePillData } from '@cubicNx/libs/utils';
import { VehicleDetailsActiveTab } from '../../../map/types/types';

import moment from 'moment';

@Component({
	selector: 'vehicle-event-details-item',
	templateUrl: './vehicle-event-details-item.component.html',
	styleUrls: ['./vehicle-event-details-item.component.scss'],
})
export class VehicleEventDetailsItemComponent extends TranslateBaseComponent implements OnInit {
	@Input() event: VehicleEvent;
	@Input() timezone: string;

	public routeData: RoutePillData = null;

	constructor(
		private colorUtilityService: ColorUtilityService,
		private mapNavigationService: MapNavigationService,
		private vehicleEventsService: VehicleEventsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the vehicle event details item component
	 */
	public ngOnInit(): void {
		this.routeData = this.determineRoutePillData(this.event.route);
	}

	/**
	 * this method takes the user back to the map panel depending upon where the vehicle events has come from
	 *
	 * @param vehicleEvent - the vehicle event
	 */
	public routeToMapDetailsPanel = async (vehicleEvent: VehicleEvent): Promise<void> => {
		this.vehicleEventsService.publishCloseVehicleEventsList(null);

		if (vehicleEvent.vehicle_id) {
			await this.mapNavigationService.navigateToVehicleDetails(
				vehicleEvent.authority_id,
				vehicleEvent.vehicle_id,
				VehicleDetailsActiveTab.events
			);
		} else if (vehicleEvent.block_id) {
			await this.mapNavigationService.navigateToBlockDetails(vehicleEvent.authority_id, vehicleEvent.block_id);
		}
	};

	/**
	 * formats the supplied created at time
	 *
	 * @param createdAt - created at
	 * @returns formatted created at
	 */
	public getFormattedCreatedAt = (createdAt: string): string => {
		// timezone should always be present but if there is an issue retrieving the agencies then the console is flood with errors
		// due to this attempted formatting
		if (this.timezone) {
			return moment(moment.utc(createdAt)).tz(this.timezone).format('L LT');
		} else {
			return null;
		}
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param vehicleEventRoute - the vehicle event route data
	 * @returns route pill
	 */
	private determineRoutePillData = (vehicleEventRoute: any): RoutePillData => {
		return {
			routeShortName: vehicleEventRoute?.route_short_name,
			routeLongName: vehicleEventRoute?.route_long_name,
			routeId: vehicleEventRoute?.route_id,
			routeColor: this.colorUtilityService.getColor(vehicleEventRoute?.route_color),
			routeTextColor: this.colorUtilityService.getColor(vehicleEventRoute?.route_text_color),
		};
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnInit } from '@angular/core';

import { AgenciesDataService } from '../../../../../../../support-features/agencies/services/agencies-data.service';

import { Agencies, Agency } from '../../../../../../../support-features/agencies/types/api-types';

@Component({
	selector: 'headway-adherence-display',
	templateUrl: './headway-adherence-display.component.html',
	styleUrls: ['./headway-adherence-display.component.scss'],
})
export class HeadwayAdherenceDisplayComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() headway: string = null;
	@Input() target: string = null;

	public style: { 'background-color': string };

	private headwaySecs: number = null;
	private targetSecs: number = null;
	private agency: Array<Agency> = null;

	constructor(private agenciesService: AgenciesDataService) {}

	/**
	 * performs initialization tasks for the headway adherence display components
	 *
	 * creates formatted text value and correct styling for template
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * initializes the display details for headway adherence related components
	 * (where there are headway adherence related reports with adherence values in a particuar column)
	 */
	private init = (): void => {
		if (this.headway && this.target) {
			this.headwaySecs = this.convertFromAdherenceFormatToSeconds(this.headway);
			this.targetSecs = this.convertFromAdherenceFormatToSeconds(this.target);

			const agencies: Agencies = this.agenciesService.getAgencies();

			this.agency = agencies.filter(
				(agency: Agency) => agency.authority_id === this.authorityId && agency.agency_id === this.agencyId
			);

			if (Array.isArray(this.agency) && this.agency.length > 0) {
				const agency: Agency = this.agency[0];
				const close: number = agency.headway_adherence_close * 1;
				const veryClose: number = agency.headway_adherence_very_close * 1;
				const distant: number = agency.headway_adherence_distant * 1;
				const veryDistant: number = agency.headway_adherence_very_distant * 1;

				this.style = {
					'background-color': 'transparent',
				};

				const adherencePercentage: number = 1.0 - this.headwaySecs / this.targetSecs;

				if (adherencePercentage < 0) {
					if (0 - adherencePercentage > veryClose) {
						this.style = {
							'background-color': 'rgba(255,144,144,.50)',
						};
					} else if (0 - adherencePercentage > close) {
						this.style = {
							'background-color': 'rgba(255,144,144,.15)',
						};
					}
				} else {
					if (adherencePercentage > veryDistant) {
						this.style = {
							'background-color': 'rgba(255,239,112,.50)',
						};
					} else if (adherencePercentage > distant) {
						this.style = {
							'background-color': 'rgba(255,239,112,.50)',
						};
					}
				}
			}
		}
	};

	/**
	 * converts from headway adherence value to headway adherence seconds
	 *
	 * @param value - the value
	 * @returns derived headway adherence value from the supplied text value
	 */
	private convertFromAdherenceFormatToSeconds = (value: any): number => {
		const valueArray: any[] = value.split(':');
		const seconds: number = valueArray.pop() * 1;
		const minutes: number = valueArray.pop() * 60;

		let hours: any = valueArray.pop();

		let total: number;

		if (hours.indexOf('-') > -1) {
			hours = hours * 60 * 60;
			total = (seconds + minutes + hours) * -1;
		} else {
			hours = hours * 60 * 60;
			total = seconds + minutes + hours;
		}

		return total;
	};
}

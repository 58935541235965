/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { VehicleEventDetailsComponent } from '../../../../vehicle-events/details/vehicle-event-details.component';

import { WidgetEventsService } from '../../../services/widget-events.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';

import { Agencies } from '../../../../../support-features/agencies/types/api-types';
import { IWidgetComponent } from '../../../types/types';

@Component({
	selector: 'vehicle-event-details-widget',
	templateUrl: './vehicle-event-details-widget.component.html',
	styleUrls: ['./vehicle-event-details-widget.component.scss'],
})
export class VehicleEventDetailsWidgetComponent implements IWidgetComponent, OnInit, AfterViewInit, OnDestroy {
	@ViewChild(VehicleEventDetailsComponent) vehicleEventsDetails: VehicleEventDetailsComponent;

	@Input() data: any;
	@Input() rowData: any;

	public loaded: boolean = false;
	public hasResults: boolean = false;
	public success: boolean = false;
	public firstTime: boolean = true;

	public reloadWidget$Subscription: Subscription = null;

	private refreshTimer: any = null;
	private agencies: Agencies = null;

	constructor(
		private widgetEventsService: WidgetEventsService,
		private agenciesDataService: AgenciesDataService,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	/**
	 * performs initialization tasks for the vehicle events details component
	 */
	public ngOnInit(): void {
		this.setSubscriptions();
		this.agencies = this.agenciesDataService.getAgencies();
	}

	/**
	 * initailize the refresh timer after the child detail component has rendered
	 */
	public ngAfterViewInit(): void {
		this.initRefreshInterval();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * timer based refresh of the vehicle event details
	 */
	private initRefreshInterval = async (): Promise<void> => {
		if (this.agencies?.length > 0) {
			// get the events for the first time
			this.vehicleEventsDetails.getVehicleEvents();

			if (this.refreshTimer) {
				clearInterval(this.refreshTimer);
			}

			const timerInterval: number = (this.data.config.refreshRateSec || 60) * 1000;

			this.refreshTimer = setInterval(this.vehicleEventsDetails.getVehicleEvents, timerInterval);
		}
	};

	/**
	 * sets up subscriptions - is interested in widget reloading
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe(async (event) => {
			if (event.widgetId === this.data.wid) {
				// let the child component pickup setting changes
				this.changeDetectorRef.detectChanges();

				// reset child control
				await this.vehicleEventsDetails.init();

				this.initRefreshInterval();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.refreshTimer) {
			clearInterval(this.refreshTimer);
		}

		this.reloadWidget$Subscription?.unsubscribe();
	};
}

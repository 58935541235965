import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggerService } from '../../logging/logger.service';

// Intercept the request and log the length of time it takes to go through the server back-end.
@Injectable({
	providedIn: 'root',
})
export class LoggingInterceptor implements HttpInterceptor {
	constructor(private logger: LoggerService) {}

	/**
	 * intercept http requests
	 *
	 * @param req - the http request
	 * @param next - the http handler
	 * @returns the http event
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const started: number = Date.now();

		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					const elapsed: number = Date.now() - started;

					this.logger.logDebug(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
				}
			})
		);
	}
}

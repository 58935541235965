/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { ReportViewerSortableSubListBaseComponent } from '../../../report-viewer-sortable-sublist-base.component';

import { ReportsConfigService } from '../../../../../../services/reports-config.service';
import { ReportsGenerationService } from '../../../../../../services/reports-generation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportRoute, InteractiveReportConfigurationStopTimepointsByBlockData } from '../../../../../../types/api-types';
import { ConfigScheduleForRoute, ReportTemplateSummaryContent } from '../../../../../../types/types';

@Component({
	selector: 'c100-configuration-stops-and-timepoints-by-block-sub-list',
	templateUrl: './configuration-stops-and-timepoints-by-block-sub-list.component.html',
	styleUrls: ['./configuration-stops-and-timepoints-by-block-sub-list.component.scss'],
})
export class ConfigurationStopsAndTimepointsByBlockSubListComponent extends ReportViewerSortableSubListBaseComponent implements OnInit {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = null;

	constructor(
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		private reportsGenerationService: ReportsGenerationService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * performs initialization tasks for the configured stops timepoints by block sub list view (AD300)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * generates the route part of the report data
	 *
	 * @param reportData - the report data
	 */
	public generateReport = (reportData: InteractiveReportConfigurationStopTimepointsByBlockData): void => {
		const route: ReportRoute = {
			authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
			route_id: reportData.route_id,
			route_display_name: reportData.route_long_name,
			route_short_name: reportData.route_short_name,
			route_long_name: reportData.route_long_name,
		};

		this.reportsGenerationService.generateRouteReport(ConfigScheduleForRoute, this.templateSummaryContent.template, route);
	};
}

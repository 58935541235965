import { Injectable } from '@angular/core';

@Injectable()
export class DataStore<T> {
	private dataStoreMap: Map<string, T> = new Map<string, T>();

	/**
	 * Stores the given type.
	 *
	 * @param id - event identifier.
	 * @param item - the item to store.
	 */
	set = (id: string, item: T): void => {
		this.dataStoreMap.set(id, item);
	};

	/**
	 * Gets the event from the store with the specified id.
	 *
	 * @param id - the identifier.
	 * @returns the generic type.
	 */
	get = (id: string): T => {
		return this.dataStoreMap.get(id);
	};

	/**
	 * Removes the type from the store with the specified id.
	 *
	 * @param id - the identifier.
	 */
	remove = (id: string): void => {
		this.dataStoreMap.delete(id);
	};

	/**
	 * Gets the size/length of the map.
	 *
	 * @returns the size of the store.
	 */
	getSize = (): number => {
		return this.dataStoreMap.size;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable, Type } from '@angular/core';

import { AdherenceByRouteSummaryComponent } from '../interactive-viewer/report-viewer-summary/summary-reports/ad100/adherence-by-route-summary.component';
import { AdherenceByStopSummaryComponent } from '../interactive-viewer/report-viewer-summary/summary-reports/ad200/adherence-by-stop-summary.component';
import { AdherenceByVehicleSummaryComponent } from '../interactive-viewer/report-viewer-summary/summary-reports/ad300/adherence-by-vehicle-summary.component';
import { BlockAssignmentsSummaryComponent } from '../interactive-viewer/report-viewer-summary/summary-reports/bl100/block-assignments-summary.component';
import { HeadwayByStopSummaryComponent } from '../interactive-viewer/report-viewer-summary/summary-reports/hw100/headway-by-stop-summary.component';
import { HeadwayDetailSummaryComponent } from '../interactive-viewer/report-viewer-summary/summary-reports/hw200/headway-detail-summary.component';
import { AdherenceByRouteListComponent } from '../interactive-viewer/report-viewer-list/list-reports/adherence/ad100/adherence-by-route-list.component';
import { AdherenceByStopListComponent } from '../interactive-viewer/report-viewer-list/list-reports/adherence/ad200/adherence-by-stop-list.component';
import { AdherenceByVehicleByRouteListComponent } from '../interactive-viewer/report-viewer-list/list-reports/adherence/ad300/adherence-by-vehicle-by-route-list.component';
import { AdherenceForVehiclesByStopListComponent } from '../interactive-viewer/report-viewer-list/list-reports/adherence/ad400/adherence-for-vehicles-by-stop-list.component';
import { AdherenceByRouteDirectionStopListComponent } from '../interactive-viewer/report-viewer-list/list-reports/adherence/ad500/adherence-by-route-direction-stop-list.component';
import { AdherenceVehicleArrivalAndDepartureListComponent } from '../interactive-viewer/report-viewer-list/list-reports/adherence/ad600/adherence-vehicle-arrival-and-departure-list.component';
import { ConfigurationStopsAndTimepointsByBlockListComponent } from '../interactive-viewer/report-viewer-list/list-reports/configuration/c100/configuration-stops-and-timepoints-by-block-list.component';
import { ConfigurationScheduleForRouteListComponent } from '../interactive-viewer/report-viewer-list/list-reports/configuration/c200/configuration-schedule-for-route-list.component';
import { ConfigurationStopsByRouteListComponent } from '../interactive-viewer/report-viewer-list/list-reports/configuration/c300/configuration-stops-by-route-list.component';
import { ConfigurationAverageTravelTimeForRouteComponent } from '../interactive-viewer/report-viewer-list/list-reports/configuration/c500/configuration-average-travel-time-for-route-list.component';
import { BlockAssignmentsListComponent } from '../interactive-viewer/report-viewer-list/list-reports/block/bl100/block-assignments-list.component';
import { HeadwayByStopListComponent } from '../interactive-viewer/report-viewer-list/list-reports/headway/hw100/headway-by-stop-list.component';
import { HeadwayDetailListComponent } from '../interactive-viewer/report-viewer-list/list-reports/headway/hw200/headway-detail-list.component';
import { HeadwayByRouteListComponent } from '../interactive-viewer/report-viewer-list/list-reports/headway/hw300/headway-by-route-list.component';
import { RiderCountByRouteListComponent } from '../interactive-viewer/report-viewer-list/list-reports/ridership/rc100/rider-count-by-route-list.component';
import { RiderCountByRouteByStopListComponent } from '../interactive-viewer/report-viewer-list/list-reports/ridership/rc200/rider-count-by-route-by-stop-list.component';
import { RiderCountByTripListComponent } from '../interactive-viewer/report-viewer-list/list-reports/ridership/rc300/rider-count-by-trip-list.component';
import { RiderCountByBlockListComponent } from '../interactive-viewer/report-viewer-list/list-reports/ridership/rc400/rider-count-by-block-list.component';
import { RiderCountByVehicleListComponent } from '../interactive-viewer/report-viewer-list/list-reports/ridership/rc500/rider-count-by-vehicle-list.component';
import { RiderCountByBlockAndVehicleListComponent } from '../interactive-viewer/report-viewer-list/list-reports/ridership/rc600/rider-count-by-block-and-vehicle-list.component';
import { VehicleLocationByVehicleListComponent } from '../interactive-viewer/report-viewer-list/list-reports/vehicle-location/avl200/vehicle-location-by-vehicle-list.component';
import { VehicleLocationLastListComponent } from '../interactive-viewer/report-viewer-list/list-reports/vehicle-location/avl300/vehicle-location-last-list.component';
import { DeadheadingVehiclesComponent } from '../interactive-viewer/report-viewer-list/list-reports/deadheading-vehicles/dv100/deadheading-vehicles-list.component';

import {
	AdherenceByRoute,
	AdherenceByRouteDirectionStop,
	AdherenceByStop,
	AdherenceByVehicleByRoute,
	AdherenceForVehiclesByStop,
	AdherenceVehicleArrivalAndDeparture,
	AvlReportByVehicle,
	BlockAssignments,
	ConfigScheduleForRoute,
	ConfigStops,
	ConfigStopsAndTimepointsByBlock,
	ConfigAverageTravelTimeForRoute,
	HeadwayByRoute,
	HeadwayByStop,
	HeadwayDetail,
	LastAvlReport,
	DeadheadingVehicles,
	RiderCountByBlock,
	RiderCountByBlockByVehicle,
	RiderCountByRoute,
	RiderCountByRouteByStop,
	RiderCountByTrip,
	RiderCountByVehicle,
} from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class ReportsViewLookupService {
	private summaryViewMap: Map<string, Type<any>> = new Map<string, Type<any>>([
		[AdherenceByRoute, AdherenceByRouteSummaryComponent],
		[AdherenceByStop, AdherenceByStopSummaryComponent],
		[AdherenceByVehicleByRoute, AdherenceByVehicleSummaryComponent],
		[BlockAssignments, BlockAssignmentsSummaryComponent],
		[HeadwayByStop, HeadwayByStopSummaryComponent],
		[HeadwayDetail, HeadwayDetailSummaryComponent],
	]);

	private listViewMap: Map<string, Type<any>> = new Map<string, Type<any>>([
		[AdherenceByRoute, AdherenceByRouteListComponent],
		[AdherenceByStop, AdherenceByStopListComponent],
		[AdherenceByVehicleByRoute, AdherenceByVehicleByRouteListComponent],
		[AdherenceForVehiclesByStop, AdherenceForVehiclesByStopListComponent],
		[AdherenceByRouteDirectionStop, AdherenceByRouteDirectionStopListComponent],
		[AdherenceVehicleArrivalAndDeparture, AdherenceVehicleArrivalAndDepartureListComponent],
		[ConfigStopsAndTimepointsByBlock, ConfigurationStopsAndTimepointsByBlockListComponent],
		[ConfigScheduleForRoute, ConfigurationScheduleForRouteListComponent],
		[ConfigStops, ConfigurationStopsByRouteListComponent],
		[ConfigStops, ConfigurationStopsByRouteListComponent],
		[ConfigAverageTravelTimeForRoute, ConfigurationAverageTravelTimeForRouteComponent],
		[BlockAssignments, BlockAssignmentsListComponent],
		[HeadwayByStop, HeadwayByStopListComponent],
		[HeadwayDetail, HeadwayDetailListComponent],
		[HeadwayByRoute, HeadwayByRouteListComponent],
		[RiderCountByRoute, RiderCountByRouteListComponent],
		[RiderCountByRouteByStop, RiderCountByRouteByStopListComponent],
		[RiderCountByTrip, RiderCountByTripListComponent],
		[RiderCountByBlock, RiderCountByBlockListComponent],
		[RiderCountByVehicle, RiderCountByVehicleListComponent],
		[RiderCountByBlockByVehicle, RiderCountByBlockAndVehicleListComponent],
		[AvlReportByVehicle, VehicleLocationByVehicleListComponent],
		[LastAvlReport, VehicleLocationLastListComponent],
		[DeadheadingVehicles, DeadheadingVehiclesComponent],
	]);

	/**
	 * retrieves the matching report summary view for the suppiled report id
	 *
	 * @param id - the report id
	 * @returns the report summary view
	 */
	public getSummaryView = (id: string): Type<any> => {
		return this.summaryViewMap.get(id);
	};

	/**
	 * retrieves the matching report lust view for the suppiled report id
	 *
	 * @param id - the report id
	 * @returns the report list view
	 */
	public getListView = (id: string): Type<any> => {
		return this.listViewMap.get(id);
	};
}

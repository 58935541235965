import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslationService } from '@cubicNx/libs/utils';

import {
	ValidationText,
	maxlengthValidation,
	minlengthValidation,
	minValidation,
	maxValidation,
	passwordNeedsLowerValidation,
	passwordNeedsNumberValidation,
	passwordNeedsSpecialValidation,
	passwordNeedsUpperValidation,
	passwordNoMatchValidation,
	passwordsMatchValidation,
	passwordTooLongValidation,
	passwordTooShortValidation,
	requiredValidation,
	usernameBadCharValidation,
	usernameTooLongValidation,
	valueInvalid,
	valueOutOfBoundsMaxValidation,
	valueOutOfBoundsMinValidation,
	patternInvalidValue,
} from './types/types';

@Component({
	selector: 'input-validation',
	templateUrl: './input-validation.component.html',
	styleUrls: ['./input-validation.component.scss'],
})
export class InputValidationComponent {
	@Input() control: AbstractControl;
	@Input() errorOnTouch: boolean = false;
	@Input() extraMessageText: ValidationText = null;
	@Input() messageTextOverride: ValidationText = null;

	public message: string;

	private messageMapping: Map<string, string> = new Map<string, string>([
		[minlengthValidation, 'T_CORE.FORM.ERRORS.MIN_LENGTH'],
		[maxlengthValidation, 'T_CORE.FORM.ERRORS.MAX_LENGTH'],
		[requiredValidation, 'T_CORE.FORM.ERRORS.REQUIRED'],
		[minValidation, 'T_CORE.FORM.ERRORS.NUMBER'],
		[maxValidation, 'T_CORE.FORM.ERRORS.NUMBER'],
		[valueInvalid, 'T_CORE.FORM.ERRORS.NUMBER'],
		[valueOutOfBoundsMinValidation, 'T_CORE.FORM.ERRORS.MIN_NUMBER'],
		[valueOutOfBoundsMaxValidation, 'T_CORE.FORM.ERRORS.MAX_NUMBER'],
		[passwordTooShortValidation, 'T_USER.PASSWORD_MIN_LENGTH'],
		[passwordTooLongValidation, 'T_USER.PASSWORD_MAX_LENGTH'],
		[passwordNeedsUpperValidation, 'T_USER.PASSWORD_NEEDS_UPPER'],
		[passwordNeedsLowerValidation, 'T_USER.PASSWORD_NEEDS_LOWER'],
		[passwordNeedsNumberValidation, 'T_USER.PASSWORD_NEEDS_NUMBER'],
		[passwordNeedsSpecialValidation, 'T_USER.PASSWORD_NEEDS_SPECIAL'],
		[passwordsMatchValidation, 'T_USER.PASSWORDS_MATCH'],
		[passwordNoMatchValidation, 'T_USER.PASSWORDS_NOT_MATCH'],
		[usernameTooLongValidation, 'T_CORE.USERNAME_MAX_LENGTH'],
		[usernameBadCharValidation, 'T_CORE.USERNAME_BAD_CHAR'],
		[patternInvalidValue, 'T_CORE.FORM.ERRORS.INVALID_VALUE'],
	]);

	constructor(private translationService: TranslationService) {}

	/**
	 * determines the translated error message based on the settings passed in
	 *
	 * @returns the error message
	 */
	public errorMessage = (): string => {
		for (const propertyName in this.control.errors) {
			if (!this.errorOnTouch || this.control.touched) {
				// do we have a ovverride rather than default error text?
				if (this.messageTextOverride && this.messageTextOverride[propertyName]) {
					this.message = this.translationService.getTranslation(this.messageTextOverride[propertyName]);
				} else {
					this.message = this.translationService.getTranslation(this.messageMapping.get(propertyName));
				}

				if (this.extraMessageText) {
					if (this.extraMessageText[propertyName]) {
						this.message += this.extraMessageText[propertyName];
					}
				}

				return this.message;
			}
		}

		return null;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { StopsPerDestinations } from '../../../types/types';

@Component({
	selector: 'stop-selection',
	templateUrl: './stop-selection.component.html',
	styleUrls: ['./stop-selection.component.scss'],
})
export class StopSelectionComponent extends TranslateBaseComponent implements OnInit {
	@ViewChildren(MatSelectionList) destinationStops: QueryList<MatSelectionList>;

	public readonly selectAllId: string = 'SELECT_ALL';

	public stopsPerDestinations: StopsPerDestinations = [];
	public destinationColumnWidth: string = null;
	public stopsSelected: boolean = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private modalRef: MatDialogRef<StopSelectionComponent>,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * initialiaztion - get the stop data passed in to the modal
	 */
	public async ngOnInit(): Promise<void> {
		// grab the modal data passed in
		this.stopsPerDestinations = this.data['stopsPerDestinations'];

		this.destinationColumnWidth = 100 / this.stopsPerDestinations.length + '%';
	}

	/**
	 * click handler for selected an individual stop - determine stop count used for validation
	 */
	public stopSelected = (): void => {
		this.stopsSelected = this.stopsSelectedCount() > 0;
	};

	/**
	 * selects/deselects all stops for a direction when the 'select all' is selected/deselected
	 *
	 * @param destinationIndex - the direction index being processed
	 */
	public selectAll = (destinationIndex: number): void => {
		const stops: MatSelectionList[] = this.destinationStops.toArray();

		const allStopsSelected: boolean = this.allStopsSelected(destinationIndex);

		// update the control
		if (allStopsSelected) {
			stops[destinationIndex].deselectAll();
		} else {
			stops[destinationIndex].selectAll();
		}

		this.stopsSelected = this.stopsSelectedCount() > 0;
	};

	/**
	 * determines if 'select all' for a destination should be selected in the view
	 *
	 * @param destinationIndex - the destination index being processed
	 * @returns a flag indicating if the 'select all' stops should be checked
	 */
	public isAllOptionSelected = (destinationIndex: number): boolean => {
		return this.allStopsSelected(destinationIndex);
	};

	/**
	 * close the modal passing updated data back to caller
	 */
	public save = async (): Promise<void> => {
		this.modalRef.close(this.getUpdatedSelectedStops());
	};

	/**
	 * close the modal
	 */
	public close = (): void => {
		this.modalRef.close();
	};

	/**
	 * determines if all stops have been selected for a destination in the stop list
	 * If the the control been initialised it checks the select all based on current user selection
	 * otherwise it defaults to our destination object passed in based on initially selected stops
	 *
	 * @param destinationIndex - the destination index being processed
	 * @returns a flag indicating if the all stops have been selected for the direction
	 */
	private allStopsSelected = (destinationIndex: number): boolean => {
		if (this.destinationStops?.length > 0) {
			const stopList: MatSelectionList = this.destinationStops.filter((element, index) => index === destinationIndex)[0];

			if (stopList) {
				return (
					stopList.selectedOptions.selected.filter((option) => option.value.id !== this.selectAllId).length ===
					this.stopsPerDestinations[destinationIndex].stops.length
				);
			}
		} else {
			// control not initialised - default based on value passed in
			return this.stopsPerDestinations[destinationIndex].stops.filter((stop) => stop.selected === false).length === 0;
		}

		return null;
	};

	/**
	 * gets the number of selected stops (total for each destination)
	 *
	 * @returns the number of selected stops
	 */
	private stopsSelectedCount = (): number => {
		let stopsSelectedCount: number = 0;

		this.destinationStops?.forEach((stopList: MatSelectionList) => {
			stopsSelectedCount += stopList.selectedOptions.selected.length;
		});

		return stopsSelectedCount;
	};

	/**
	 * determines the selected stops from the control
	 *
	 * @returns the slected stops per destination
	 */
	private getUpdatedSelectedStops = (): StopsPerDestinations => {
		const stopsPerDestinations: StopsPerDestinations = this.stopsPerDestinations;

		this.destinationStops.forEach((stopList: MatSelectionList, destinationIndex) => {
			// default to everything being unselected
			stopsPerDestinations[destinationIndex].stops.forEach((stops) => {
				stops.selected = false;
			});

			stopList.selectedOptions.selected.forEach((selectedStop: MatListOption) => {
				if (selectedStop.value.id !== this.selectAllId) {
					// locate the stop in our list and set to selected
					stopsPerDestinations[destinationIndex].stops.filter((stop) => stop.id === selectedStop.value.id)[0].selected = true;
				}
			});
		});

		return stopsPerDestinations;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { EventData } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class ModalEventsService {
	public dataChangedEvent: Observable<any>;

	private dataChangedEventSource: Subject<any> = new Subject<any>();

	constructor() {
		this.dataChangedEvent = this.dataChangedEventSource.asObservable();
	}

	/**
	 * Publish a data changed event allowing subscribers to react to data changed actions.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishDataChangedEvent = (event: EventData): void => {
		this.dataChangedEventSource.next(event);
	};
}

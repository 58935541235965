/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { PaginatedParams } from '@cubicNx/libs/utils';
import { ReplayDataParams } from '../../../features/map/replay/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class StopsApiService {
	private readonly stopsUrl: string = '/stops';
	private readonly stopsCodeUrl: string = '/code';
	private readonly predictionsUrl: string = '/predictions';
	private readonly lastPassingUrl: string = '/lastpassing';
	private readonly stopsInfoByCodeUrl: string = '/infoByStopCode';
	private readonly stopsInfoByIdUrl: string = '/infoByStopId';

	constructor(private httpService: HttpService) {}

	/**
	 * requests stops from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param params - additional info for the request
	 * @returns the stops
	 */
	public getStops = async (authorityId: string, agencyId: string, params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.stopsUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request a single stop from the nextbus API using the stop id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @returns the single stop
	 */
	public getStop = async (authorityId: string, agencyId: string, stopId: string): Promise<any> => {
		try {
			if (agencyId) {
				return await this.httpService.get('/' + authorityId + '/' + agencyId + this.stopsUrl + '/' + stopId);
			} else {
				return await this.httpService.get('/' + authorityId + this.stopsUrl + '/' + stopId);
			}
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request a single stop from the nextbus API using the stop code
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopCode - the code of the stop for the request
	 * @returns the single stop
	 */
	public getStopByCode = async (authorityId: string, agencyId: string, stopCode: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.stopsUrl + '/' + stopCode + this.stopsCodeUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests additional single info from the nextbus API using the stop code
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopCode - the code of the stop for the request
	 * @param replayParams - additional params to support the replay feature
	 * @returns the stop info
	 */
	public getStopInfoByCode = async (
		authorityId: string,
		agencyId: string,
		stopCode: string,
		replayParams: ReplayDataParams = null
	): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.stopsUrl + '/' + stopCode + this.stopsInfoByCodeUrl,
				replayParams
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests additional single info from the nextbus API using the stop id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @param replayParams - additional params to support the replay feature
	 * @returns the stop info
	 */
	public getStopInfoById = async (
		authorityId: string,
		agencyId: string,
		stopId: string,
		replayParams: ReplayDataParams = null
	): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.stopsUrl + '/' + stopId + this.stopsInfoByIdUrl,
				replayParams
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests stop predictions info for a single stop from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @param stopCode - the code of the stop for the request
	 * @param replayParams - additional params to support the replay feature
	 * @returns the stop predictions
	 */
	public getStopPredictions = async (
		authorityId: string,
		agencyId: string,
		stopId: string,
		stopCode: string,
		replayParams: ReplayDataParams = null
	): Promise<any> => {
		try {
			if (agencyId) {
				return await this.httpService.get(
					'/' +
						authorityId +
						'/' +
						agencyId +
						this.stopsUrl +
						'/' +
						stopId +
						this.predictionsUrl +
						'/' +
						stopCode +
						this.stopsCodeUrl,
					replayParams
				);
			} else {
				return await this.httpService.get('/' + authorityId + this.stopsUrl + '/' + stopId + this.predictionsUrl, replayParams);
			}
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests stop last passing info for a single stop from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @returns the stop last passing info
	 */
	public getStopLastPassing = async (authorityId: string, agencyId: string, stopId: string): Promise<any> => {
		try {
			if (agencyId) {
				return await this.httpService.get('/' + authorityId + '/' + agencyId + this.stopsUrl + '/' + stopId + this.lastPassingUrl);
			} else {
				return await this.httpService.get('/' + authorityId + this.stopsUrl + '/' + stopId + this.lastPassingUrl);
			}
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';
import { ResultContent } from '@cubicNx/libs/utils';
import { RouteExtended, RouteShapes } from '../../../support-features/routes/types/api-types';
import { Stop, Stops } from '../../../support-features/stops/types/api-types';
import { MapStopsService } from './map-stops.service';
import { MapVehiclesService } from './map-vehicles.service';
import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { MapLocationService } from './map-location.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { RouteVehicle, RouteVehicles, ActiveBlocks, RoutesActivesBlockVehicles } from '../../../support-features/vehicles/types/api-types';

import {
	MapRoute,
	MapRoutes,
	MapRoutesPaths,
	MapStops,
	MapVehicles,
	RouteAssociatedStop,
	RouteAssociatedStops,
	RouteAssociatedVehicle,
	RouteAssociatedVehicles,
} from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapRoutesService {
	constructor(
		private mapStateService: MapStateService,
		private mapStopsService: MapStopsService,
		private mapVehiclesService: MapVehiclesService,
		private mapLocationService: MapLocationService,
		private vehiclesDataService: VehiclesDataService,
		private logger: LoggerService
	) {}

	/**
	 * add a route to the map state
	 *
	 * @param route - the route to add
	 * @param zoomToRoute - true if the route should be zoomed to
	 */
	public addRoute = async (route: RouteExtended, zoomToRoute: boolean = true): Promise<void> => {
		const mapRoute: MapRoute = {
			routeId: route.route_id,
			authorityId: route.authority_id,
			agencyId: route.agency_id,
			routeColor: route.route_color,
			routeTextColor: route.route_text_color,
			routeShortName: route.route_short_name,
			routeLongName: route.route_long_name,
			paths: this.determineRoutePaths(route.shapes),
			stops: this.determineRouteStops(route.stops),
			vehicles: null, // we'll add these after we have requested them below
		};

		this.mapStateService.addRoute(mapRoute);

		this.addRouteStops(route.stops);

		this.addRouteVehicles(mapRoute);

		if (zoomToRoute) {
			this.mapLocationService.setRoutesToLocate([route.route_id]);
		}
	};

	/**
	 * remove a route from the map state
	 *
	 * @param routeId - the route id to remove
	 */
	public removeRoute = (routeId: string): void => {
		const route: MapRoute = this.getRoute(routeId);

		if (route) {
			this.removeRouteVehicles(route);
			this.removeRouteStops(route);

			this.mapStateService.removeRoute(route);
		}
	};

	/**
	 * init saved view routes
	 */
	public initViewRoutes = (): void => {
		this.mapStateService.initViewRoutes();
	};

	/**
	 * determine if a route is displayed
	 *
	 * @param routeId - the route id
	 * @returns true if the route is displayed on the map
	 */
	public routeDisplayed = (routeId: string): boolean => {
		const route: MapRoute = this.mapStateService.getRoute(routeId);

		return route !== null;
	};

	/**
	 * get the route vehicles for lists we are currrently showing on the map.
	 *
	 * vehicles are considered 'route vehicles' when the vehicles are returned for being active on a route
	 *
	 * @param routeIds - the list of route ids to request
	 * @param authorityId - the authority id for the route
	 * @param agencyId - the agency id for the route
	 * @returns the route vehicles (on the map)
	 */
	public getRouteVehicles = async (routeIds: string[], authorityId: string, agencyId: string): Promise<MapVehicles> => {
		let vehicles: MapVehicles = {};

		const result: ResultContent = await this.vehiclesDataService.getRoutesActiveBlockVehicles(routeIds, authorityId, agencyId);

		if (result.success) {
			const routesActivesBlockVehicles: RoutesActivesBlockVehicles = result.resultData;

			if (routesActivesBlockVehicles) {
				for (const routeId in routesActivesBlockVehicles) {
					const routeVehicles: RouteVehicles = routesActivesBlockVehicles[routeId].vehicles || [];
					const activeBlocks: ActiveBlocks = routesActivesBlockVehicles[routeId].activeBlocks || {};

					const route: MapRoute = this.getRoute(routeId);

					// in case user removes route during async call
					if (this.routeDisplayed(routeId)) {
						// concatinate the vehicles from each route
						vehicles = { ...vehicles, ...this.mapVehiclesService.getRouteVehicles(routeVehicles, activeBlocks) };

						// handle the edge case where the updated route contains a vehicle that the user has manually removed
						this.discardManuallyRemovedRouteVehicles(routeVehicles, route.vehicles, vehicles);

						// clean up any associated vehicles that are no longer part of the route
						this.removeVehiclesNoLongerAssignedToRoute(routeId, routeVehicles);

						// maintain our associated list of vehicles with any new/removed vehicles
						route.vehicles = routeVehicles.map((vehicle: RouteVehicle) => ({
							vehicleId: vehicle.vehicle_id,
						}));
					}
				}
			}
		}

		return vehicles;
	};

	/**
	 * update the route vehicles following a reassign/unassign
	 *
	 * vehicles are considered 'route vehicles' when the vehicles are returned for being active on a route
	 *
	 * @param vehicleRouteId - the previous route id
	 * @param reassignRouteId - the reassigned route id
	 * @param authorityId - the authority id for the route
	 * @param agencyId - the agency id for the route
	 */
	public updateVehiclesOnRoutes = async (
		vehicleRouteId: string,
		reassignRouteId: string,
		authorityId: string,
		agencyId: string
	): Promise<void> => {
		// process both the route the vehicle was moved from and the one it was moved to

		const routeIds: string[] = [];

		if (vehicleRouteId) {
			const vehicleRoute: MapRoute = this.getRoute(vehicleRouteId);

			// is this route on the map
			if (vehicleRoute) {
				routeIds.push(vehicleRouteId);
			}
		}

		if (reassignRouteId) {
			const reassignRoute: MapRoute = this.getRoute(reassignRouteId);

			// is this route on the map
			if (reassignRoute) {
				routeIds.push(reassignRouteId);
			}
		}

		if (routeIds.length > 0) {
			const vehicles: MapVehicles = await this.getRouteVehicles(routeIds, authorityId, agencyId);

			if (Object.keys(vehicles).length > 0) {
				this.mapVehiclesService.updateVehicles(vehicles);
			}
		}
	};

	/**
	 * get an individual route from the map state
	 *
	 * @param routeId - the route id to request
	 * @returns the route
	 */
	public getRoute = (routeId: string): MapRoute => {
		return this.mapStateService.getRoute(routeId);
	};

	/**
	 * get all routes currently in the map state
	 *
	 * @returns all routes (on the map)
	 */
	public getRoutes = (): MapRoutes => {
		return this.mapStateService.getRoutes();
	};

	/**
	 * get the current route count
	 *
	 * @returns the current route count
	 */
	public getRouteCount = (): number => {
		return Object.keys(this.mapStateService.getRoutes()).length;
	};

	/**
	 * clear all routes
	 */
	public clearRoutes = (): void => {
		this.mapStateService.clearRoutes();
	};

	/**
	 * remove the vehicles that were added as part of a partiuclar route
	 *
	 * @param currentRoute - the route to remove vehicles for
	 */
	private removeRouteVehicles = (currentRoute: MapRoute): void => {
		if (currentRoute.vehicles) {
			// remove all vehicles from this route
			const vehicleIdsToRemove: Array<string> = currentRoute.vehicles.map((vehicle: RouteAssociatedVehicle) => vehicle.vehicleId);

			if (vehicleIdsToRemove.length > 0) {
				this.mapVehiclesService.removeRouteVehicles(vehicleIdsToRemove);
			}
		}
	};

	/**
	 * remove the stops that were added as part of a partiuclar route
	 *
	 * @param currentRoute - the route to remove stops for
	 */
	private removeRouteStops = (currentRoute: MapRoute): void => {
		// remove all stops from this route EXCEPT those that are currently part of other displayed routes

		let stopCodesToRemove: Array<string> = currentRoute.stops.map((stop: RouteAssociatedStop) => stop.stopCode);

		const routes: MapRoutes = this.getRoutes();

		for (const routeId in routes) {
			if (routeId !== currentRoute.routeId) {
				stopCodesToRemove = stopCodesToRemove.filter(
					(stopCodeToRemove: string) =>
						routes[routeId].stops.map((stop: RouteAssociatedStop) => stop.stopCode).indexOf(stopCodeToRemove) === -1
				);
			}
		}

		if (stopCodesToRemove.length > 0) {
			this.mapStopsService.removeRouteStops(stopCodesToRemove);
		}
	};

	/**
	 * determine the route paths in the format required for our leaflet map from the shape data
	 *
	 * @param shapes - the shape data for the route
	 * @returns the formatted path data for the route
	 */
	private determineRoutePaths = (shapes: RouteShapes): MapRoutesPaths => {
		const paths: MapRoutesPaths = {};

		let shapeId: number = 0;

		if (shapes && shapes.length > 0) {
			shapes.forEach((shape) => {
				if (shapeId !== shape.shape_nb_id) {
					paths[shape.shape_nb_id] = [];
					shapeId = shape.shape_nb_id;
				}

				paths[shape.shape_nb_id].push({
					lat: shape.shape_pt_lat,
					lng: shape.shape_pt_lon,
				});
			});
		}

		return paths;
	};

	/**
	 * determine (extract) the route stop details required (id/code) to store in our route data
	 *
	 * @param routeStops - the full route stop data
	 * @returns the same stops passed in but cut down to just the code and id properties
	 */
	private determineRouteStops = (routeStops: Stops): RouteAssociatedStops => {
		const stops: RouteAssociatedStops = routeStops.map((stop: Stop) => ({
			stopCode: stop.stop_code,
			stopId: stop.stop_id,
		}));

		return stops;
	};

	/**
	 * remove any vehicles no longer assigned to the route
	 *
	 * @param routeId - the route id to check
	 * @param updatedRouteVehicles - the current list of vehicles on the route to check against
	 */
	private removeVehiclesNoLongerAssignedToRoute = (routeId: string, updatedRouteVehicles: RouteVehicles): void => {
		const vehicleIdsToRemove: Array<string> = [];

		const route: MapRoute = this.getRoute(routeId);

		if (route.vehicles) {
			route.vehicles.forEach((existingRouteVehicle: RouteAssociatedVehicle) => {
				// check if it still part of the route
				const vehicleInRoute: RouteVehicle = updatedRouteVehicles.find(
					(routeVehicle: RouteVehicle) => routeVehicle.vehicle_id === existingRouteVehicle.vehicleId
				);

				if (!vehicleInRoute) {
					this.logger.logInfo('Removing vehicle no longer assigned to route: ', existingRouteVehicle.vehicleId);

					vehicleIdsToRemove.push(existingRouteVehicle.vehicleId);
				}
			});

			if (vehicleIdsToRemove.length > 0) {
				// maintain from our list of associated vehicles for this route
				route.vehicles = route.vehicles.filter(
					(vehicle: RouteAssociatedVehicle) => vehicleIdsToRemove.indexOf(vehicle.vehicleId) === -1
				);

				// remove the actual vehicle (if display on the map)
				this.mapVehiclesService.removeRouteVehicles(vehicleIdsToRemove);
			}
		}
	};

	/**
	 * discard any vehicles that are part of the route that the user has previously de-selected
	 *
	 * @param routeVehicles - the vehicles currently in the route
	 * @param existingRouteVehicles - the existing vehicles on the route (taking in to account any that the user has removed)
	 * @param vehicles - all of our route vehicles currently on the map
	 */
	private discardManuallyRemovedRouteVehicles = (
		routeVehicles: RouteVehicles,
		existingRouteVehicles: RouteAssociatedVehicles,
		vehicles: MapVehicles
	): void => {
		routeVehicles.forEach((routeVehicle: RouteVehicle) => {
			// determine what vehicles in the route we already know about. Then if the vehicle itself isn't part of our map
			// state vehicle list then it must be because the user manually removed it
			let vehicleKnownToRoute: boolean = false;

			if (existingRouteVehicles) {
				vehicleKnownToRoute = existingRouteVehicles.some(
					(existingRouteVehicle: RouteAssociatedVehicle) => existingRouteVehicle.vehicleId === routeVehicle.vehicle_id
				);
			}

			// vehicle not already known to route but it doesn't exist in our map state vehicle list - it must have been
			// manually removed by the user - discard it so it doesn't get re-added and rendered on the map
			if (vehicleKnownToRoute) {
				if (!this.mapVehiclesService.vehicleExists(routeVehicle.vehicle_id)) {
					delete vehicles[routeVehicle.vehicle_id];
				}
			}
		});
	};

	/**
	 * add stops for a partiuclar route
	 *
	 * @param routeStops - the route stops to add
	 */
	private addRouteStops = (routeStops: Stops): void => {
		const stops: MapStops = this.mapStopsService.getRouteStops(routeStops);

		if (Object.keys(routeStops).length > 0) {
			this.mapStopsService.addStops(stops);
		}
	};

	/**
	 * add vehicles for a partiuclar route
	 *
	 * @param mapRoute - the route to add vehicles for
	 */
	private addRouteVehicles = async (mapRoute: MapRoute): Promise<void> => {
		// request vehicle for the route
		const vehicles: MapVehicles = await this.getRouteVehicles([mapRoute.routeId], mapRoute.authorityId, mapRoute.agencyId);

		if (Object.keys(vehicles).length > 0) {
			// call the update which will add the vehicles. Note update handles adding and updating (some of the vehicles may
			// already be on the map, such as those added manually before the route was added)
			this.mapVehiclesService.updateVehicles(vehicles);
		}
	};
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LoginApiService } from './login-api.service';
import { CurrentUserUtilService } from '../current-user/current-user-utils.service';
import { InitService } from '../init/init.service';
import { TokenService } from '@cubicNx/libs/utils';
import { LoggerService } from '@cubicNx/libs/utils';
import { LoginModalService } from './login-modal.service';
import { StorageService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { PasswordExpiry, UserLoginError } from '../../types/types';
import { UserLoginRequest, UserLogin, UserSettings } from '../../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { StorageType } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class LoginDataService {
	constructor(
		private storageService: StorageService,
		private initService: InitService,
		private loginApiService: LoginApiService,
		private loginModalService: LoginModalService,
		private currentUserUtilService: CurrentUserUtilService,
		private logger: LoggerService,
		private tokenService: TokenService,
		private translationService: TranslationService,
		private router: Router
	) {}

	/**
	 * handles the request to log in the user and formats suitable failure responses
	 *
	 * @param user - the user login credentials
	 * @returns the log in response
	 */
	public logIn = async (user: UserLoginRequest): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.loginApiService.logIn(user);

			if (response) {
				result.success = true;
				result.resultData = response.user as UserLogin;

				// handle additional login actions before returning
				await this.handleLogin(response.token, response.user);

				setTimeout(() => {
					this.determinePasswordExpiring();
				}, 3000);
			}
		} catch (exception: any) {
			this.logger.logError('Login failed.', exception);

			let userLoginError: UserLoginError = null;

			switch (exception?.status) {
				case 401:
				case 403: {
					userLoginError = {
						status: exception.status,
						message: this.translationService.getTranslation('T_CORE.LOGIN_FAILED_TRY_AGAIN'),
					};
					break;
				}
				case 500:
				default: {
					userLoginError = {
						status: exception.status,
						message: this.translationService.getTranslation('T_CORE.SERVER_ERROR'),
						passwordExpired:
							typeof exception?.error === 'string' && exception?.error?.includes('PasswordExpired') ? true : false,
					};
					break;
				}
			}

			result.resultData = userLoginError;
		}

		return result;
	};

	/**
	 * handle the successful login response and redirect the user to the dashboard
	 *
	 * @param token - the token geenrated from the nextbus API
	 * @param user - the user login response
	 * @returns the log in response
	 */
	private handleLogin = async (token: string, user: UserLogin): Promise<void> => {
		this.tokenService.setApiToken(token);

		const currentUser: UserLogin = user;

		this.storageService.remove('app-state-' + currentUser.username, StorageType.local);

		// the user settings are returned as a string from the backend - convert to object after login
		currentUser.agency_portal_settings = this.parseUserSettings(currentUser.agency_portal_settings);

		// handle the initialization via our init service which also will fire for browser refreshes where user doesn't need to 'log in'
		await this.initService.initActions(currentUser);

		this.router.navigate(['/dashboard']);
	};

	/**
	 * handle the successful login response and redirect the user to the dashboard
	 *
	 * @param userSettings - the user settings in string format
	 * @returns the parsed response in to our UserSettings type
	 */
	private parseUserSettings = (userSettings: string): UserSettings => {
		try {
			return JSON.parse(userSettings);
		} catch (error) {
			this.logger.logError('Failed to parse settings - ', error);

			return null;
		}
	};

	/**
	 * determin the password expiry state and prompt the user if necessary
	 */
	private determinePasswordExpiring = (): void => {
		const passwordExpiry: PasswordExpiry = this.currentUserUtilService.determinePasswordExpiring();

		if (passwordExpiry.passwordExpiring) {
			this.loginModalService.passwordExpiryWarning(+passwordExpiry.passwordExpiryDays);
		}
	};
}

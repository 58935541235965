<div class="chart-container" data-test="headway.pie-chart">
	<div class="chart-summary">
		<div class="chart-header">
			<div class="chart-title-value" data-test="pie.percentage">{{ titleVal }}%</div>
			<div data-test="pie.text">
				{{ chartTitle }}
			</div>
		</div>
		<nvd3 [options]="options" [data]="data"></nvd3>
	</div>
</div>

<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div [hidden]="!success" class="map-container">
			<div id="{{ mapId }}" class="map"></div>
			<div *ngIf="loaded" class="traffic-footer">
				<div class="update-text">
					<span class="nb-text">
						<span class="bold">{{ 'T_DASH.DB_TRAFFIC_UPDATED' | translate }}</span>
						<br />
						<span data-test="updated">{{ lastUpdated.toString() | timeAgo }}</span>
					</span>
				</div>
			</div>
		</div>
		<div [hidden]="success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
	</div>
</div>

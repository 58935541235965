export interface ConfigValidator {
	validator: string;
	error: string;
}

export interface ConfigMapLocation {
	lat: number;
	lng: number;
}

export interface ConfigServiceNow {
	url: string;
	width: string;
	height: string;
}

export interface Config {
	appId: string;
	appVersion: string;
	configId: string;
	minLogLevel: string;
	idle: number;
	idleTimeout: number;
	apiPath: string;
	defaultListSize: number;
	paginationMaxSize: number;
	paginationMaxNumPages: number;
	adminMasterRoleName: string;
	adminAgencyRoleName: string;
	basicUserRoleName: string;
	userNameMinLength: number;
	userNameMaxLength: number;
	usernameValidators: ConfigValidator[];
	passwordValidators: ConfigValidator[];
	navMinified: boolean;
	statusPageUrl: string;
	default_map_location: ConfigMapLocation;
	service_now_url: string;
	service_now_width: string;
	service_now_height: string;
	mapbox_api_key: string;
	show_raster_map: boolean;
	mapRefreshRate: number;
	mapReplayPlaybackSpeed1RefreshRate: number;
	mapReplayPlaybackSpeed2RefreshRate: number;
	mapReplayPlaybackSpeed5RefreshRate: number;
	mapReplayPlaybackSpeed10RefreshRate: number;
	mapReplayPlaybackSpeed20RefreshRate: number;
	mapReplayPlaybackSpeed60RefreshRate: number;
	mapReplayInitPollPeriod: number;
	mapReplayInitTimeoutPeriod: number;
	defaultVehicleColor: string;
	defaultVehicleTextColor: string;
	unpredictableVehicleColor: string;
	unpredictableVehicleTextColor: string;
	adherenceVeryEarlyColor: string;
	adherenceEarlyColor: string;
	adherenceOnTimeColor: string;
	adherenceLateColor: string;
	adherenceVeryLateColor: string;
	headwayVeryCloseColor: string;
	headwayCloseColor: string;
	headwayAcceptableColor: string;
	headwayVeryDistantColor: string;
	headwayDistantColor: string;
	maxVehicleTrailMs: number;
	defaultZoomLevel: number;
	minZoomLevel: number;
	maxZoomLevel: number;
	ladderMaximumStopLabelLength: number;
	vehicleMovingTransitionTime: number;
	vehicleMovingFastTransitionTime: number;
	ridersPortal: RidersPortalConfig;
}

export interface RidersPortalConfig {
	test?: string;
}

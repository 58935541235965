<div class="section-container">
	<div class="section-info-row">
		<div class="section-route-pill" data-test="block.route">
			<route-pill [route]="route"></route-pill>
		</div>
		<div class="section-date">{{ 'T_MAP.MAP_BLOCKS_FOR' | translate }} {{ section.date }}</div>
		<div class="text-right section-close">
			<button type="button" class="btn-link-dark section-close-btn" data-test="block.close" (click)="closeSectionClick()">
				<i class="ng-icon ng-close"></i>
			</button>
		</div>
	</div>
	<div class="section-row section-header-row">
		<div class="section-column">{{ 'T_MAP.MAP_BLOCKS_TITLE' | translate }}</div>
		<div class="section-column">{{ 'T_MAP.MAP_DATE' | translate }}&nbsp;&amp;&nbsp;{{ 'T_MAP.MAP_TIME' | translate }}</div>
		<div class="section-column">{{ 'T_MAP.MAP_STATUS' | translate }}</div>
		<div class="section-column">{{ 'T_MAP.MAP_VEHICLES' | translate }}</div>
	</div>
	<div *ngFor="let displayBlock of section.blocksInRoute" class="section-row">
		<div class="section-column id-with-navigation" (click)="showBlockView(displayBlock)" data-test="block.block_id">
			{{ displayBlock.block_id }}
		</div>
		<div class="section-column" data-test="block.time">{{ formatTime(displayBlock) }}</div>
		<div class="section-column" data-test="block.status">
			<span class="block-status" [ngClass]="getStatus(displayBlock.status)">{{ getStatusTranslation(displayBlock.status) }}</span>
			<span *ngIf="checkCancelledTrips(displayBlock)" class="fa fa-minus-circle blocks-cancel-icon" aria-hidden="true"></span>
		</div>
		<div class="section-column" data-test="block.vehicles">
			<div class="vehicles-red" *ngIf="displayBlock.vehicles?.length === 0">—</div>
			<div *ngIf="displayBlock.vehicles?.length > 0">
				<span
					*ngFor="let vehicle of displayBlock.vehicles; last as isLast"
					class="id-with-navigation"
					[ngStyle]="getVehicleClass(displayBlock)"
					(click)="navigateToVehicleDetails(displayBlock.authority_id, vehicle)"
					>{{ vehicle.vehicle_id }}<span *ngIf="!isLast">, </span>
					<!-- keep on same line - introduces extar space if not -->
				</span>
			</div>
		</div>
	</div>
</div>

<div class="clearfix">
	<div *ngIf="editMode" class="dashboard-toolbar">
		<button
			(click)="editDashboard()"
			class="btn btn-sm btn-action dashboard-toolbar-btn button-margin"
			data-test="dashboard.customize.chooseformat.btn">
			<i class="ng-icon ng-settings"></i>
			<span>{{ 'T_DASH.DB_EDIT_NAME_LAYOUT' | translate }}</span>
		</button>
		<button
			*ngIf="dashboardOptions.length > 1"
			data-test="dashboard.customize.delete.btn"
			(click)="deleteDashboard()"
			class="btn btn-sm btn-action dashboard-toolbar-btn">
			<i class="ng-icon ng-delete"></i>
			<span>{{ 'T_DASH.DB_DELETE' | translate }}</span>
		</button>
	</div>
</div>

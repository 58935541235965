import { Type } from '@angular/core';
import { CssClassType } from '@cubicNx/libs/utils';
import { Agencies } from '../../../support-features/agencies/types/api-types';
import { ReportTemplateDetail, ReportTemplateDetails } from './api-types';

export const AdherenceByRoute: string = 'adherence-by-route';
export const AdherenceByStop: string = 'adherence-by-stop';
export const AdherenceByVehicleByRoute: string = 'adherence-by-vehicle-by-route';
export const AdherenceForVehiclesByStop: string = 'adherence-for-vehicles-by-stop';
export const AdherenceByRouteDirectionStop: string = 'adherence-by-route-direction-stop';
export const AdherenceVehicleArrivalAndDeparture: string = 'adherence-vehicle-arrival-and-departure';
export const ConfigStopsAndTimepointsByBlock: string = 'config-stops-and-timepoints-by-block';
export const ConfigScheduleForRoute: string = 'config-schedule-for-route';
export const ConfigStops: string = 'config-stops';
export const ConfigAverageTravelTimeForRoute: string = 'config-average-travel-time-for-route';
export const BlockAssignments: string = 'block-assignments';
export const HeadwayByStop: string = 'headway-by-stop';
export const HeadwayDetail: string = 'headway-detail';
export const HeadwayByRoute: string = 'headway-by-route';
export const RiderCountByRoute: string = 'rider-count-by-route';
export const RiderCountByRouteByStop: string = 'rider-count-by-route-by-stop';
export const RiderCountByTrip: string = 'rider-count-by-trip';
export const RiderCountByBlock: string = 'rider-count-by-block';
export const RiderCountByVehicle: string = 'rider-count-by-vehicle';
export const RiderCountByBlockByVehicle: string = 'rider-count-by-block-by-vehicle';
export const AvlReportByVehicle: string = 'avl-report-by-vehicle';
export const LastAvlReport: string = 'last-avl-report';
export const DeadheadingVehicles: string = 'deadheading-vehicles';

export enum ActionAllowedType {
	allowed,
	invalidStatus,
	noPermission,
	notEnabled,
	noReportSelected,
	tooManySelected,
}

export enum CreateEditType {
	create,
	edit,
	editTemplate,
}

export enum EntitySelectionType {
	all,
	specific,
	unassigned,
}

export enum MultiSelectionType {
	select,
	list,
}

export enum EffectiveDateRangeType {
	day,
	week,
	month,
	custom,
	dayName,
}

export enum EffectiveDateRangeDayType {
	today,
	yesterday,
	custom,
	specific,
}

export enum EffectiveDateRangeWeekType {
	thisWeek,
	lastWeek,
	custom,
}

export enum EffectiveDateRangeMonthType {
	thisMonth,
	lastMonth,
	custom,
}

export enum EffectiveDateRangeDayNameType {
	includeAll,
	weekdays,
	saturday,
	sunday,
}

export enum ReportSaveType {
	run,
	save,
	saveAndRun,
}

export enum CreateReportType {
	default,
	saved,
}

export enum SortType {
	block,
	time,
}

export enum SpeedType {
	mph,
	kmh,
}

export enum SortFieldType {
	default,
	timeDelta,
}

export enum MultiGenerateReportType {
	perBlock,
	perRoute,
}

export interface SelectedReportsFilter {
	agencies: Agencies;
}

export interface ReportFiltersUpdate {
	saved: boolean;
	selectedReportsFilter?: SelectedReportsFilter;
}

export interface AvailableReportsListItem {
	id: string; // any list with a checkbox must have an id
	category: string;
	code: string;
	name: string;
	rawStatus: string;
	status: string;
	rawOutputType: string;
	outputType: string;
	sharing: string;
	agency: string;
	dateCreated: string;
	authorityId: string;
	agencyId: string;
	createdById: number;
	reportId: string;
	defaultTemplateId: string;
	apiEndpoint: string;
	rowClass: string;
}

export type AvailableReportsList = Array<AvailableReportsListItem>;

export interface AgencyReportCustomization {
	id: string;
	customDescription: string;
	customName: string;
	featureEnabled: boolean;
}

export type AgencyReportCustomizations = Array<AgencyReportCustomization>;

export interface ReportSummaryContent {
	loaded: boolean;
	data?: ReportSummaryContentData;
	errorMessage?: string;
}

export interface ReportSummaryContentData {
	defaultTemplateId: string;
	code: string;
	name: string;
	description: string;
	updatedAt?: string;
	createdByName: string;
	sharing: string;
	authorityId: string;
	status?: string;
	outputType: string;
	agencyName: string;
}

export type ReportSelectionsConfiguration = Array<string>;

export interface ReportEnablementConfiguration {
	enabled: boolean;
}

export interface ReportEntityListEnablementConfiguration {
	enabled: boolean;
	multiSelect: boolean;
	selections: EntitySelectionType[];
	multiSelectionType?: MultiSelectionType;
	endpoint?: string;
}

export interface ReportOptionsConfiguration {
	routes: ReportEntityListEnablementConfiguration;
	blocks: ReportEntityListEnablementConfiguration;
	vehicles: ReportEntityListEnablementConfiguration;
	sort: ReportOptionsConfigurationScheduleSort;
	speed: ReportOptionsConfigurationSpeed;
	filterArrivalStops: ReportEnablementConfiguration;
	adherenceThreshold: ReportEnablementConfiguration;
}

export interface ReportOptionsConfigurationScheduleSort {
	enabled: boolean;
	sortOptions: SortType[];
}

export interface ReportOptionsConfigurationSpeed {
	enabled: boolean;
	speedOptions: SpeedType[];
}

export interface ReportDateRangeTypesConfiguration {
	day: ReportEnablementConfiguration;
	week: ReportEnablementConfiguration;
	month: ReportEnablementConfiguration;
	custom: ReportEnablementConfiguration;
	dayName: ReportEnablementConfiguration;
}

export interface ReportDateEffectiveTime {
	enabled: boolean;
}

export interface ReportDateOptionsConfiguration {
	dateRangeTypes: ReportDateRangeTypesConfiguration;
	showDateRangeOptions: boolean; // allows the date range types radio button set
	initialDateRangeType: EffectiveDateRangeType;
	initialDateRangeDayType: EffectiveDateRangeDayType;
	effectiveTime: ReportDateEffectiveTime;
	effectiveDateStart: ReportDateEffectiveTime;
	effectiveDateEnd: ReportDateEffectiveTime;
	allowFutureDates?: boolean;
	disabled?: boolean;
}

export interface ReportViewerSubItemsConfiguration {
	pageSize: number;
	maxPageSize: number;
}

export interface ReportTemplateDisplayDetail {
	nbId?: number;
	defaultTemplateId: string;
	enabled: boolean;
	code: string;
	outputType: string;
	name: string;
	description: string;
	updatedAt?: string;
	createdById?: number;
	authorityId?: string;
	agencyId?: string;
}

export type ReportTemplateDisplayDetails = Array<ReportTemplateDisplayDetail>;

export interface ReportTemplateDisplayDetailsByCategory {
	[category: string]: ReportTemplateDisplayDetails;
}

export interface ReportTemplateDisplayDetailAction {
	action: string;
	reportTemplateDisplayDetails: ReportTemplateDisplayDetails;
}

export interface ReportTemplateSummaryContent {
	loaded: boolean;
	template?: ReportTemplateDetail;
	errorMessage?: string;
}

export type ReportConfigurations = Array<ReportConfiguration>;

export interface ReportConfiguration {
	code: string;
	sharing: string;
	outputType: string;
	defaultTemplateId: string;
	endpoint: string;
	version: number;
	category: string;
	defaultSortName: string;
	reportOptions: ReportOptionsConfiguration;
	dateOptions: ReportDateOptionsConfiguration;
	paginationSettings: DefaultPaginationSettingConfiguration;
	subViewPaginationSettings: DefaultPaginationSettingConfiguration;
	reportGenerationOverride?: {
		// used when a different report is generated i.e from C400 we create 1 or many C100's
		defaultTemplateId: string; // key of report we want to create
		multiGenerateReportType: MultiGenerateReportType;
	};
}

export interface DefaultPaginationSettingConfiguration {
	initialPageSize: number;
	maxPageSize: number;
}

export interface ReportTemplatesByCategory {
	[category: string]: ReportTemplateDetails;
}

export interface ReportConfigurationsByCategory {
	[category: string]: ReportConfigurations;
}

export interface SortDetails {
	field: string;
	dir: string;
}

export interface ReportSummaryPortalParams {
	summaryData: any;
	templateSummaryContent: ReportTemplateSummaryContent;
}

export interface ReportSummaryPortalDetails {
	params: ReportSummaryPortalParams;
	view: Type<any>;
}

export interface ReportListPortalParams {
	templateSummaryContent: ReportTemplateSummaryContent;
	defaultTemplateId: string;
	authorityId: string;
	agencyId: string;
}

export interface ReportListPortalDetails {
	params: ReportListPortalParams;
	view: Type<any>;
}

// C-400 data list
export interface BlockSelectionListItem {
	id: number;
	blockId: string;
	vehicleId: string;
	scheduledStartTime: string;
	scheduledEndTime: string;
	timeOfAssignment: string;
	assignmentDifference: CssClassType;
	assignmentDifferenceRaw: number;
	status: CssClassType;
}

export type BlockSelectionList = Array<BlockSelectionListItem>;

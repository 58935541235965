/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { ActivateTripsData, CancelBlockData, CancelTripsData } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';
import { CurrentBlocksParams, VehicleBlocksParams } from '../../routes/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class BlocksApiService {
	private readonly blocksUrl: string = '/blocks';
	private readonly allBlocksUrl: string = '/blocks/all';
	private readonly cancelUrl: string = '/cancel';
	private readonly activateUrl: string = '/activate';
	private readonly tripsUrl: string = '/trips';
	private readonly performanceUrl: string = '/performance';

	constructor(private httpService: HttpService) {}

	/**
	 * request blocks from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param params - any additional params for the request
	 * @returns the blocks data
	 */
	public getBlocks = async (authorityId: string, agencyId: string, params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.blocksUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * get all blocks from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param search - the search filter
	 * @returns all block data
	 */
	public getBlocksAll = async (authorityId: string, agencyId: string, search: string = null): Promise<any> => {
		try {
			if (search) {
				return await this.httpService.get('/' + authorityId + '/' + agencyId + this.allBlocksUrl, { search });
			} else {
				return await this.httpService.get('/' + authorityId + '/' + agencyId + this.allBlocksUrl);
			}
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * get a single block from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param blockId - the block id for the request
	 * @returns the single block
	 */
	public getBlock = async (authorityId: string, agencyId: string, blockId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.blocksUrl + '/' + blockId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 *
	 * cancels a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param blockId - the block id for the request
	 * @param body - the block cancellation details for the request
	 * @returns the reponse state of the request
	 */
	public cancelBlock = async (authorityId: string, agencyId: string, blockId: string, body: CancelBlockData): Promise<any> => {
		try {
			return await this.httpService.put('/' + authorityId + '/' + agencyId + this.blocksUrl + '/' + blockId + this.cancelUrl, body);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * activates a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param blockId - the block id for the request
	 * @returns the reponse state of the request
	 */
	public activateBlock = async (authorityId: string, agencyId: string, blockId: string): Promise<any> => {
		try {
			return await this.httpService.put('/' + authorityId + '/' + agencyId + this.blocksUrl + '/' + blockId + this.activateUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 *
	 * cancel trips for a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param blockId - the block id for the request
	 * @param body - the trip cancellation details for the request
	 * @returns the reponse state of the request
	 */
	public cancelTrips = async (authorityId: string, agencyId: string, blockId: string, body: CancelTripsData): Promise<any> => {
		try {
			return await this.httpService.put(
				'/' + authorityId + '/' + agencyId + this.blocksUrl + '/' + blockId + this.tripsUrl + this.cancelUrl,
				body
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * activate all trips for a block via the nextbus APU
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param blockId - the block id for the request
	 * @param body -nthe trip activate trip details for the request
	 * @returns the reponse state of the request
	 */
	public activateTrips = async (authorityId: string, agencyId: string, blockId: string, body: ActivateTripsData): Promise<any> => {
		try {
			return await this.httpService.put(
				'/' + authorityId + '/' + agencyId + this.blocksUrl + '/' + blockId + this.tripsUrl + this.activateUrl,
				body
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * get the current blocks for the supplied paramters from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param params - any additional params for the request
	 * @returns the current blocks for the supplied paramters
	 */
	public getCurrentBlocks = async (authorityId: string, agencyId: string, params: CurrentBlocksParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.blocksUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * gets the vehicle blocks for the dashboard
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the authority id for the request
	 * @param params - any additional params for the request
	 * @returns the vehicle blocks in a suitable format for the dashboard
	 */
	public getDashboardVehiclesBlocks = async (authorityId: string, agencyId: string, params: VehicleBlocksParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.blocksUrl + this.performanceUrl, params);
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { GetVehicleStateParams, GetVehicleStatesParams, ReassignVehicleDetail } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class VehiclesApiService {
	private readonly vehiclesUrl: string = '/vehicles';
	private readonly statesUrl: string = '/states';
	private readonly eventsUrl: string = '/events';
	private readonly eventUrl: string = '/event';
	private readonly audioUrl: string = '/audio';
	private readonly typesUrl: string = '/types';
	private readonly blocksUrl: string = '/blocks';
	private readonly reassignUrl: string = '/reassign';
	private readonly routesUrl: string = '/routes';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the vehicles from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param params - page info for the request
	 * @returns all vehicles
	 */
	public getVehicles = async (authorityId: string, params: PaginatedParams = null): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the vehicle current states from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param params - additional info for the request
	 * @returns all vehicle current states
	 */
	public getVehicleCurrentStates = async (authorityId: string, params: GetVehicleStatesParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.statesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests a single vehicle from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehicleId - the vehicle id for the request
	 * @param params - additional info for the request
	 * @returns the single vehicle
	 */
	public getVehicle = async (authorityId: string, vehicleId: string, params: GetVehicleStateParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + '/' + vehicleId, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests vehicle events from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehicleIds - the vehicle ids for the request
	 * @param params - additional info for the request
	 * @returns the vehicle events
	 */
	public getVehicleEvents = async (authorityId: string, vehicleIds: any, params: string): Promise<any> => {
		try {
			if (typeof vehicleIds === 'string') {
				return await this.httpService.get('/' + authorityId + this.vehiclesUrl + '/' + vehicleIds + this.eventsUrl + params);
			} else {
				return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.eventsUrl + params);
			}
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests vehicle event audio from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param params - additional info for the request
	 * @returns the vehicle event audio
	 */
	public getVehicleEventAudio = async (authorityId: string, params: any): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.eventsUrl + this.audioUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests vehicle event types from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @returns the vehicle event types
	 */
	public getVehicleEventTypes = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.eventUrl + this.typesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests route active block vehicles from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id for the request
	 * @param params - additional info for the request
	 * @returns the route active block vehicles
	 */
	public getRouteActiveBlockVehicles = async (authorityId: string, agencyId: string, routeId: string, params: any): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeId + this.vehiclesUrl,
				params
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests route vehicles active blocks from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id for the request
	 * @param params - additional info for the request
	 * @returns the route vehicles active blocks
	 */
	public getRouteVehiclesActiveBlocks = async (authorityId: string, agencyId: string, routeId: string, params: any): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeId + this.vehiclesUrl,
				params
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests to unassign a vehicle block from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehicleId - the vehicle id to unassign
	 * @param unassignDetails - the unassign details
	 * @returns the result of the request
	 */
	public unassignVehicle = async (authorityId: string, vehicleId: string, unassignDetails: ReassignVehicleDetail): Promise<any> => {
		try {
			return await this.httpService.put(
				'/' + authorityId + this.vehiclesUrl + '/' + vehicleId + this.blocksUrl + this.reassignUrl,
				unassignDetails
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests to reassign a vehicle block from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehicleId - the vehicle id for the request
	 * @param reassignDetails - the details of the reassign
	 * @returns the result of the request
	 */
	public reassignVehicleBlock = async (authorityId: string, vehicleId: string, reassignDetails: ReassignVehicleDetail): Promise<any> => {
		try {
			return await this.httpService.put(
				'/' + authorityId + this.vehiclesUrl + '/' + vehicleId + this.blocksUrl + this.reassignUrl,
				reassignDetails
			);
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Inject, Injectable } from '@angular/core';

import { VEHICLES_STORE } from './vehicles-store-factory';

import { MapOptionsService } from '../../../features/map/services/map-options.service';
import { MapReplayService } from '../../../features/map/services/map-replay.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { VehiclesApiService } from './vehicles-api.service';

import { PaginatedParams } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { RouteBlock, Route } from '../../routes/types/api-types';
import { MapModeType } from '../../../features/map/types/types';

import {
	GetVehicleStateParams,
	GetVehicleStatesParams,
	ReassignVehicleDetail,
	AssignmentVehicleResult,
	RouteActiveBlockVehicles,
	VehiclesPaginatedResponse,
	VehicleSummaries,
	VehicleCurrentStates,
	Vehicle,
	BlockVehicles,
	RoutesActivesBlockVehicles,
} from '../types/api-types';

import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class VehiclesDataService {
	private readonly nullsLast: string = ' NULLS LAST';

	constructor(
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private logger: LoggerService,
		private mapReplayService: MapReplayService,
		private mapOptionsService: MapOptionsService,
		private vehiclesApiService: VehiclesApiService,
		@Inject(VEHICLES_STORE) private vehiclesDataStore: any
	) {}

	/**
	 * handles the request for the vehicles list from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param pageNumber - the page number to request
	 * @param pageSize - the page size to request
	 * @param sortBy - the sort by field to request
	 * @param sortDir - the sort direction to request
	 * @param search - the search filter text to request
	 * @returns the result object with the vehicles list
	 */
	public getVehiclesList = async (
		authorityId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: PaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir: sortDir + this.nullsLast }),
				...(search && { search }),
				...(this.mapOptionsService.getMapMode() === MapModeType.replay && {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
				}),
			};

			const response: any = await this.vehiclesApiService.getVehicles(authorityId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as VehiclesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: VehiclesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as VehiclesPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get vehicles.', error);
		}

		return result;
	};

	/**
	 * requests the vehicles from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param useCache - when true return cached data if available
	 * @returns the result object with all vehicles
	 */
	public getVehicles = async (authorityId: string, useCache: boolean = false): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId;
			let vehicles: VehicleSummaries = null;

			if (useCache) {
				vehicles = this.vehiclesDataStore.get(storeKey);

				if (!vehicles) {
					this.logger.logDebug(`Nothing currently cached so requesting vehicles for: ${storeKey}`);

					const response: any = await this.vehiclesApiService.getVehicles(authorityId);

					vehicles = response;
				} else {
					this.logger.logDebug(`Returning vehicles for: ${storeKey} from cached store`);
				}
			} else {
				this.logger.logDebug(`Requesting vehicles for: ${authorityId}`);

				const response: any = await this.vehiclesApiService.getVehicles(authorityId);

				vehicles = response;
			}

			this.vehiclesDataStore.set(storeKey, vehicles);

			result.success = true;
			result.resultData = vehicles;
		} catch (error) {
			this.logger.logError('Failed to get vehicles for authority: ' + authorityId, error);
		}

		return result;
	};

	/**
	 * handles the request of the vehicles suggestions from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param search - the search filter text
	 * @returns all vehicles that pass the filter
	 */
	public getVehicleSuggestions = async (authorityId: string, search: string): Promise<ResultContent> => {
		return await this.getVehiclesList(authorityId, 1, 25, 'vehicle_id', 'asc', search);
	};

	/**
	 * handles the request of a single vehicle from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehicleId - the vehicle id for the request
	 * @returns the result object with the single vehicle
	 */
	public getVehicle = async (authorityId: string, vehicleId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const requestParams: GetVehicleStateParams = {
			getNextStops: true,
			...(this.mapOptionsService.getMapMode() === MapModeType.replay && {
				replayId: this.mapReplayService.getCurrentReplayId(),
				timestamp: this.mapReplayService.getCurrentReplayTime(),
			}),
		};

		try {
			const vehicle: Vehicle = await this.vehiclesApiService.getVehicle(authorityId, vehicleId, requestParams);

			result.success = true;
			result.resultData = vehicle as Vehicle;
		} catch (error) {
			this.logger.logError('Failed to get vehicle state - vehicle_id: ' + vehicleId, error);
		}

		return result;
	};

	/**
	 * handles the requests of the vehicle current states from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehiclesIds - the list of vehicle ids for the request
	 * @returns the result object with all vehicle current states
	 */
	public getVehicleCurrentStates = async (authorityId: string, vehiclesIds: string[]): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		let vehicleIdStrList: string = '';

		vehiclesIds.forEach((vehicleId: string) => {
			vehicleIdStrList += vehicleId + ',';
		});

		// remove extra comma
		if (vehicleIdStrList.length > 0) {
			vehicleIdStrList = vehicleIdStrList.slice(0, -1);
		}

		try {
			const requestParams: GetVehicleStatesParams = {
				vehicleIds: vehicleIdStrList,
				...(this.mapOptionsService.getMapMode() === MapModeType.replay && {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
				}),
			};

			const vehicleStates: VehicleCurrentStates = await this.vehiclesApiService.getVehicleCurrentStates(authorityId, requestParams);

			result.success = true;
			result.resultData = vehicleStates;
		} catch (error) {
			this.logger.logError('Failed to get vehicle states for authority with id: ' + authorityId, error);
		}

		return result;
	};

	/**
	 * handles the request to reassign a vehicle block from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyTimezone - the current timezone for the request
	 * @param vehicleId - the vehicle id for the request
	 * @param block - the block for the reassign
	 * @returns the result of the request
	 */
	public reassignVehicleBlock = async (
		authorityId: string,
		agencyTimezone: string,
		vehicleId: string,
		block: RouteBlock
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const assignVehicleDetail: ReassignVehicleDetail = {
				block_id_override: block.block_id,
				block_id_override_start_time: moment().tz(agencyTimezone).format(),
				block_id_override_end_time: moment().tz(agencyTimezone).startOf('day').add(block.end_time, 's').format(),
			};

			const assignmentReault: any = await this.vehiclesApiService.reassignVehicleBlock(authorityId, vehicleId, assignVehicleDetail);

			result.success = true;
			result.resultData = assignmentReault as AssignmentVehicleResult;

			this.notificationService.notifySuccess(
				this.translationService.getTranslation('T_CORE.VEHICLE') +
					' ' +
					vehicleId +
					' ' +
					this.translationService.getTranslation('T_MAP.MAP_ASSIGNED_TO_BLOCK') +
					' ' +
					block.block_id
			);
		} catch (error: any) {
			this.logger.logError('Failed to reassign vehicle ' + vehicleId + ' to block ' + block.block_id, error);

			if (error.status === 403) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
			} else {
				this.notificationService.notifyError(
					this.translationService.getTranslation('T_CORE.VEHICLE') +
						' ' +
						vehicleId +
						' ' +
						this.translationService.getTranslation('T_MAP.MAP_REASSIGN_ERROR')
				);
			}
		}

		return result;
	};

	/**
	 * handles the request to unassign a vehicle block from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyTimezone - the current timezone for the request
	 * @param vehicleId - the vehicle id for the request
	 * @param blockId - the block id for the unassign
	 * @returns the result of the request
	 */
	public unassignVehicle = async (
		authorityId: string,
		agencyTimezone: string,
		vehicleId: string,
		blockId: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const unassignVehicleDetail: ReassignVehicleDetail = {
				block_id_override: null, // null indicates it's an unassign
				block_id_override_start_time: moment().tz(agencyTimezone).format(),
				block_id_override_end_time: moment().tz(agencyTimezone).endOf('day').format(),
			};

			const assignmentReault: AssignmentVehicleResult = await this.vehiclesApiService.unassignVehicle(
				authorityId,
				vehicleId,
				unassignVehicleDetail
			);

			result.success = true;
			result.resultData = assignmentReault;

			this.notificationService.notifySuccess(
				this.translationService.getTranslation('T_CORE.VEHICLE') +
					' ' +
					vehicleId +
					' ' +
					this.translationService.getTranslation('T_MAP.MAP_UNASSIGNED_FROM_BLOCK') +
					' ' +
					(blockId ? blockId : '')
			);
		} catch (error: any) {
			this.logger.logError('Failed to unassign vehicle ' + vehicleId, error);

			if (error.status === 403) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
			} else {
				this.notificationService.notifyError(
					this.translationService.getTranslation('T_CORE.VEHICLE') +
						' ' +
						vehicleId +
						' ' +
						this.translationService.getTranslation('T_MAP.MAP_UNASSIGN_ERROR')
				);
			}
		}

		return result;
	};

	/**
	 * handles the request for route vehicles active blocks from the nextbus API
	 *
	 * @param route - the route for the request
	 * @param blockIdList - the block id list id for the request
	 * @returns the route vehicles active blocks
	 */
	public getRouteVehiclesActiveBlocks = async (route: Route, blockIdList: string = ''): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const params: any = {
				blocks: blockIdList,
				...(this.mapOptionsService.getMapMode() === MapModeType.replay && {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
				}),
			};

			const routeVehicleList: any[] = await this.vehiclesApiService.getRouteVehiclesActiveBlocks(
				route.agency.authority_id,
				route.agency.agency_id,
				route.route_id,
				params
			);

			result.resultData = routeVehicleList as BlockVehicles;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get route vehicles active blocks for route: ' + route.route_id, error);
		}

		return result;
	};

	/**
	 * handles the request for route active block vehicles from the nextbus API
	 *
	 * @param routeId - the route id for the request
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the route active block vehicles
	 */
	public getRouteActiveBlockVehicles = async (routeId: string, authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const params: any = {
				activeBlocks: true,
				...(this.mapOptionsService.getMapMode() === MapModeType.replay && {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
				}),
			};

			const activeBlockVehiclesData: any = await this.vehiclesApiService.getRouteActiveBlockVehicles(
				authorityId,
				agencyId,
				routeId,
				params
			);

			result.resultData = activeBlockVehiclesData as RouteActiveBlockVehicles;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get route active block vehicles for route: ' + routeId, error);
		}

		return result;
	};

	/**
	 * handles the request for routes active block vehicles from the nextbus API
	 *
	 * @param routeIds - the route ids for the request
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the routes active block vehicles
	 */
	public getRoutesActiveBlockVehicles = async (routeIds: string[], authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const params: any = {
				activeBlocks: true,
				routeIds,
				...(this.mapOptionsService.getMapMode() === MapModeType.replay && {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
				}),
			};

			// include something to direct API (but routeIds list will be used when processing if length > 0)
			const activeBlockVehiclesData: any = await this.vehiclesApiService.getRouteActiveBlockVehicles(
				authorityId,
				agencyId,
				routeIds[0],
				params
			);

			result.resultData = activeBlockVehiclesData as RoutesActivesBlockVehicles;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get route active block vehicles for routes: ' + routeIds, error);
		}

		return result;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { LoggerService } from '@cubicNx/libs/utils';
import { AuthoritiesAdminApiService } from './authorities-admin-api.service';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { AuthoritiesPaginatedResponse, Authority, AuthoritySettings } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable()
export class AuthoritiesAdminDataService {
	constructor(
		private logger: LoggerService,
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private authoritiesAdminApiService: AuthoritiesAdminApiService
	) {}

	/**
	 * retrieves authority details that match the supplied parameters
	 *
	 * @param authorityId - authority id
	 * @returns authority details
	 */
	public getAuthority = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: Authority = await this.authoritiesAdminApiService.getAuthority(authorityId);

			result.success = true;
			result.resultData = response as Authority;
		} catch (exception) {
			this.logger.logError('Failed to get authority', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves authorities that match the supplied parameters
	 *
	 * @param paginatedParams - authority retrieval parameters - page size, number, sort criteria and search text
	 * @returns authorities matching the supplied parameters
	 */
	public getAuthorities = async (paginatedParams: PaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.authoritiesAdminApiService.getAuthorities(paginatedParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response?.results) {
				// data is a paginated response
				result.resultData = response as AuthoritiesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: AuthoritiesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as AuthoritiesPaginatedResponse;
			}
		} catch (exception) {
			this.logger.logError('Failed to get authorities', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves authority settings for supplied authority identifier
	 *
	 * @param authorityId - authority id
	 * @returns authority settings
	 */
	public getAuthoritySettings = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: AuthoritySettings = await this.authoritiesAdminApiService.getAuthoritySettings(authorityId);

			result.success = true;
			result.resultData = response as AuthoritySettings;
		} catch (exception) {
			this.logger.logError('Failed to get authority admin settings - ', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};
}

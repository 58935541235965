/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { fromEvent, Observable, Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { VehicleEventSettingsComponent } from '../../../../features/vehicle-events/settings/vehicle-event-settings.component';

import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { VehicleEventsSettings } from '../../../../features/vehicle-events/types/types';
import { Agencies, Agency } from '../../../../support-features/agencies/types/api-types';

/**
 * This component is a wrapper for VehicleEventsSettingsComponent which is also used as a dashboard widget.
 * Due to this, this wrapper component must handles it's content in a similar fashion to the widget wrapper
 * albeit without some of the extra widget complication
 * It also intrioduces save/cancel buttons which the widget version doesn't need (dashboard widgets inherit
 * generic save/cancels of their own)
 */

@Component({
	selector: 'vehicle-event-settings-header',
	templateUrl: './vehicle-event-settings-header.component.html',
	styleUrls: ['./vehicle-event-settings-header.component.scss'],
})
export class VehicleEventSettingsHeaderComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild(VehicleEventSettingsComponent) vehicleEventsSettings: VehicleEventSettingsComponent;

	@Input() settings: VehicleEventsSettings = null;

	@Output() closeSettings: EventEmitter<VehicleEventsSettings> = new EventEmitter<VehicleEventsSettings>();

	public loaded: boolean = true;
	public reactiveForm: FormGroup = null;
	public agencies: Agencies = null;

	public override translations: { [key: string]: string } = {};

	public heightStyle: { 'max-height': string };

	private resizeObservable$: Observable<Event> = null;
	private resizeSubscription$: Subscription = null;

	private maxHeight: number = 733;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);

		this.handleResize();
	}

	/**
	 * initializes this component
	 */
	public async ngOnInit(): Promise<void> {
		this.buildForm();

		// setup agencies here as widget base class is responsible for storing them
		this.getSelectableAgencies();
		this.setAgency();

		await this.initTranslations(['T_CORE.CANCEL', 'T_CORE.SAVE']);
	}

	/**
	 * reset the settings page - typically when we cancel/close the settings page
	 * and we revert our settings to what they were previously. Whilst the settings
	 * page will already have tge correct settings object set via the input binding
	 * we need to reset the form to pick up those changes. Also need to reset any
	 * routes/vehicles loaded back to the correct agency
	 *
	 * @param authorityId - the authority id
	 */
	public reset = async (authorityId: string): Promise<void> => {
		// check agency has changed - only make api calls and refresh data if we need to
		if (this.settings.selectedAgency.authority_id !== authorityId) {
			if (this.settings.selectedAgency) {
				await this.vehicleEventsSettings.loadAgencyData();
			}
		}

		this.vehicleEventsSettings.setFormValues();
	};

	/**
	 * Callback from settings component to handle agency being changed.
	 */
	public agencyChanged = (): void => {
		// set the agency loaded form property to be null again - effectively meaning the form will be innvalid
		this.reactiveForm.get('agencyLoaded').setValue(null);

		const agencyId: string = this.getSelectedAgencyId();

		const agency: Agency = this.agencies.find((i) => i.agency_nb_id.toString() === agencyId.toString());

		if (agency) {
			this.settings.selectedAgency = agency;

			this.vehicleEventsSettings.loadAgencyData();
		}

		this.vehicleEventsSettings.clearAgencyData();

		// new agency loaded - set agency loaded back valid.  This ensures the save is unavilable
		// while necessary agency details are loaded (without this test automation was able to save the form
		// before it was ready)
		this.reactiveForm.get('agencyLoaded').setValue(true);
	};

	/**
	 * Callback from settings component to handle loaded value
	 * @param loaded - the loaded state received from the child.
	 */
	public handleLoaded = (loaded: boolean): void => {
		this.loaded = loaded;

		this.changeDetectorRef.detectChanges();
	};

	/**
	 * emit the close event back to the parent with saved edited settings set
	 */
	public save = (): void => {
		this.closeSettings.emit(this.settings);
	};

	/**
	 * emit the close event back to the parent with no edited settings set
	 */
	public close = (): void => {
		this.closeSettings.emit(null);
	};

	/**
	 * handle any clean up in the component destroy
	 */
	public ngOnDestroy(): void {
		this.resizeObservable$ = null;
		this.resizeSubscription$?.unsubscribe();
	}

	/**
	 * override base version of method as in this case the agencyId lives within settings sub group
	 *
	 * @returns returns the agencyId, specific to where it resides in this particular form
	 */
	private getSelectedAgencyId = (): string => {
		return this.reactiveForm.get('agencyId').value;
	};

	/**
	 * Initialize the form. This is an empty form group due to the fact the setting component is shared with the dashboard
	 * widget component. It is the main form group which is passed to the settings component to add the common
	 * form fields. No additional fields are required in this version
	 */
	private buildForm = (): void => {
		this.reactiveForm = new FormGroup({});
	};

	/**
	 * Sets the selected agency.
	 * If the selected agency is undefined or null then the first agency from the agencies list is used.
	 */
	private setAgency = (): void => {
		if (!this.settings?.selectedAgency && this.agencies?.length === 1) {
			this.settings.selectedAgency = this.agencies[0];
		}
	};

	/**
	 * Gets the selectable agencies for the agency dropdown input.
	 */
	private getSelectableAgencies = (): void => {
		this.agencies = this.agenciesDataService.getAgencies();
	};

	/**
	 * reduces height of the window when the browser size is reduced
	 */
	private handleResize = (): void => {
		this.heightStyle = { 'max-height': this.maxHeight + 'px' };

		this.resizeObservable$ = fromEvent(window, 'resize');

		this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
			this.maxHeight = window.innerHeight - 125;
			this.heightStyle = { 'max-height': this.maxHeight + 'px' };
		});
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { ConfirmModalComponent } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { DepotsDataService } from '../../../support-features/depots/services/depots-data.service';
import { VehicleMessagesDataService } from '../services/vehicle-messages-data.service';
import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { PredefinedMessages, CreateVehicleMessage, VehicleMessages, VehicleMessage } from '../types/api-types';
import { AgencyDetail, SelectedAgency } from '../../../support-features/agencies/types/api-types';
import { ConfirmButtonType, ConfirmResult } from '@cubicNx/libs/utils';
import { VehicleSummary, VehicleSummaries } from '../../../support-features/vehicles/types/api-types';
import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { PaginatedParams } from '@cubicNx/libs/utils';
import { Depots } from '../../../support-features/depots/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'vehicle-messages-create',
	templateUrl: './vehicle-messages-create.component.html',
	styleUrls: ['./vehicle-messages-create.component.scss'],
})
export class VehicleMessagesCreateComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild('selectedMessageControl') selectedMessageControl: ElementRef<HTMLTextAreaElement>;

	public readonly selectedMessageMaxLength: number = 255;

	public loaded: boolean = false;
	public templates: PredefinedMessages = [];
	public vehicleSelectionType: string = 'allVehicles';
	public selectedTemplate: string = 'placeholder';
	public selectedMessage: string = '';
	public driverMustAck: boolean = false;
	public vehicles: VehicleSummaries;
	public depots: Depots = [];
	public selectedVehicles: VehicleSummaries = [];
	public selectedDepots: Depots = [];

	public vehicleSettings: MultiSelectSettings = {
		id_text: 'vehicle_id',
		value_text: 'vehicle_id',
		placeholder: 'Select Vehicles',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public depotSettings: MultiSelectSettings = {
		id_text: 'name',
		value_text: 'name',
		placeholder: 'Select Depots',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	private readonly allVehicleSetting: string = 'allVehicles';
	private readonly specificVehiclesSetting: string = 'specificVehicles';
	private readonly specificDepotsSetting: string = 'specificDepots';

	private responseTimeoutSecs: number = 300;
	private selectedAgency: SelectedAgency = null;
	private authorityId: string = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private vehicleMessagesDataService: VehicleMessagesDataService,
		private vehiclesDataService: VehiclesDataService,
		private depotsDataService: DepotsDataService,
		private modalRef: MatDialogRef<VehicleMessagesCreateComponent>,
		private confirmMessageSendModal: MatDialog,
		public zone: NgZone,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * closes the vehicle messages create/edit dialog
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * performs initialization tasks for the vehicle messages create component
	 *
	 * loads translations, initializes the dialog
	 */
	public async ngOnInit(): Promise<void> {
		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		if (this.selectedAgency) {
			this.authorityId = this.selectedAgency.authority_id;
		}

		await this.loadTranslations();
		await this.initAgencyResponseTimeout();
		await this.initTemplates();
		await this.initVehicles();
		await this.initDepots();

		this.loaded = true;
	}

	/**
	 * remembers the predefined message template
	 */
	public templateSelected = (): void => {
		this.selectedMessage = this.selectedTemplate;
		this.selectWildcardChars();
	};

	/**
	 * determines if the save button can be enabled
	 *
	 * one message selected
	 *
	 * @returns true if the save button can be enebaled
	 */
	public saveEnabled = (): boolean => {
		return this.selectedMessage.length > 0 && this.vehicleSelectionValid();
	};

	/**
	 * saves the vehicle message present in the dialog
	 */
	public save = async (): Promise<void> => {
		const success: boolean = await this.saveMessage();

		if (success) {
			this.modalRef.close(true);
		}
	};

	/**
	 * closes the vehicle message create dialog
	 */
	public close = (): void => {
		this.modalRef.close(false);
	};

	/**
	 * loads translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_MESSAGES.CONFIRM_VEHICLE_MESSAGE_TITLE', 'T_MESSAGES.CONFIRM_VEHICLE_MESSAGE']);
	};

	/**
	 * initialize the agency response timeout
	 */
	private initAgencyResponseTimeout = async (): Promise<void> => {
		const result: ResultContent = await this.agenciesDataService.getAgency(this.authorityId, '1');

		if (result.success) {
			const agencyDetail: AgencyDetail = result.resultData;

			if (agencyDetail) {
				const agencyResponseTimeoutSec: number = agencyDetail.defaultResponseTimeoutSec;

				if (agencyResponseTimeoutSec) {
					this.responseTimeoutSecs = agencyResponseTimeoutSec;
				}
			}
		}
	};

	/**
	 * initialize the message templates
	 */
	private initTemplates = async (): Promise<void> => {
		const response: ResultContent = await this.vehicleMessagesDataService.getPredefinedMessages(this.authorityId);

		if (response.success) {
			this.templates = response.resultData as PredefinedMessages;
		}
	};

	/**
	 * initialize the vehicles
	 */
	private initVehicles = async (): Promise<void> => {
		const vehiclesResponse: ResultContent = await this.vehiclesDataService.getVehicles(this.authorityId);

		if (vehiclesResponse.success) {
			this.vehicles = vehiclesResponse.resultData as VehicleSummaries;
		}
	};

	/**
	 * initialize the depots
	 */
	private initDepots = async (): Promise<void> => {
		this.depots = [];

		const result: ResultContent = await this.depotsDataService.getDepots(this.authorityId, '1');

		if (result.success) {
			this.depots = result.resultData;
		}
	};

	/**
	 * renders the send message confirmation
	 *
	 * @returns the outcome of the send message attempt
	 */
	private saveMessage = async (): Promise<boolean> => {
		this.loaded = false;

		let success: boolean = false;

		// “recent” time interval threshold = the time for which we want to show confirmation messages for outgoing messages.
		const recentTimeIntervalThreshold: number = this.responseTimeoutSecs * 1000;
		const nowEpoch: number = Date.now();

		let messages: VehicleMessages = await this.loadAllMessages(this.authorityId);

		// filter the messages by epoch and recentTimeInterval
		messages = messages.filter(
			(message: VehicleMessage) => message.toVehicle && message.sentDateEpoch + recentTimeIntervalThreshold > nowEpoch
		);

		let vehicles: VehicleSummaries = null;

		const depotTags: string[] = this.selectedDepots.map((depot) => depot.tag);

		switch (this.vehicleSelectionType) {
			case this.allVehicleSetting:
				vehicles = this.vehicles.filter((vehicle: VehicleSummary) => !vehicle.is_stale);
				break;
			case this.specificVehiclesSetting:
				vehicles = this.selectedVehicles;
				break;
			case this.specificDepotsSetting:
				vehicles = this.vehicles.filter((vehicle: VehicleSummary) => depotTags.includes(vehicle.current_state.last_depot));
				break;
		}

		this.loaded = true;

		const showConfirmation: boolean = this.recentVehicleMessageSent(vehicles, messages);

		if (showConfirmation) {
			const confirmed: boolean = await this.confirmMessageSend().pipe(take(1)).toPromise();

			if (confirmed) {
				success = await this.sendMessage(vehicles);
			}
		} else {
			success = await this.sendMessage(vehicles);
		}

		return success;
	};

	/**
	 * attempts to create the vehicle message from the dialog data
	 *
	 * @param vehicles - the vehicles
	 * @returns the outcome of the create vehicle message attempt
	 */
	private sendMessage = async (vehicles: VehicleSummaries): Promise<boolean> => {
		this.loaded = false;

		const message: CreateVehicleMessage = {
			vehicleTag: vehicles.map((vehicle: VehicleSummary) => vehicle.vehicle_id).join(','),
			messageText: this.selectedMessage,
			requestResponse: this.driverMustAck,
			responseTimeout: this.responseTimeoutSecs,
		};

		const response: ResultContent = await this.vehicleMessagesDataService.createVehicleMessage(this.authorityId, message);

		this.loaded = true;

		return response.success;
	};

	/**
	 * loads all of the vehicle messages for the given authority
	 *
	 * @param authorityId - the authority id
	 * @returns all of the authorities vehicle messages
	 */
	private loadAllMessages = async (authorityId: string): Promise<VehicleMessages> => {
		let vehicleMessages: VehicleMessages = [];

		const params: PaginatedParams = {};

		const response: ResultContent = await this.vehicleMessagesDataService.getVehicleMessages(authorityId, params);

		if (response.success) {
			vehicleMessages = response.resultData.results as VehicleMessages;
		}

		return vehicleMessages;
	};

	/**
	 * determines whether there was any recent vehicle messages sent to the supplied vehicles
	 *
	 * @param vehicles - the vehicles
	 * @param messages - the messages
	 * @returns true if there was some recent vehicle messages sent
	 */
	private recentVehicleMessageSent = (vehicles: VehicleSummaries, messages: VehicleMessages): boolean => {
		return vehicles.some((vehicle) => messages.some((message) => message.vehicleTag === vehicle.vehicle_id));
	};

	/**
	 * determines whether the vehicle selections are valid
	 *
	 * @returns true if the vehicle selection is valid
	 */
	private vehicleSelectionValid = (): boolean => {
		let selectionValid: boolean = false;

		switch (this.vehicleSelectionType) {
			case this.allVehicleSetting:
				selectionValid = true;
				break;
			case this.specificVehiclesSetting:
				selectionValid = this.selectedVehicles.length > 0;
				break;
			case this.specificDepotsSetting:
				selectionValid = this.selectedDepots.length > 0;
				break;
		}

		return selectionValid;
	};

	/**
	 * selects the wildcard part of the predefined message text so that the user can change the value within the form
	 */
	private selectWildcardChars = (): void => {
		const inputElement: HTMLTextAreaElement = this.selectedMessageControl.nativeElement;

		inputElement.value = this.selectedTemplate;

		const wildcardChars: string = 'XX';
		const index: number = inputElement.value.indexOf(wildcardChars);

		if (index >= 0) {
			this.setSelRange(inputElement, index, index + wildcardChars.length);
		}
	};

	/**
	 * selects the wildcard part of the predefined message text so that the user can change the value within the form
	 *
	 * @param inputElement - the input element
	 * @param selStart - the start index
	 * @param selEnd - the end index
	 */
	private setSelRange = (inputElement: any, selStart: number, selEnd: number): void => {
		if (inputElement.setSelectionRange) {
			inputElement.focus();
			inputElement.setSelectionRange(selStart, selEnd);
		} else if (inputElement.createTextRange) {
			const range: any = inputElement.createTextRange();

			range.collapse(true);
			range.moveEnd('character', selEnd);
			range.moveStart('character', selStart);
			range.select();
		}
	};

	/**
	 * renders the confirm message send dialog
	 *
	 * can't put this in modal service as we have a modal (i.e this component) calling another modal (circular dependency)
	 *
	 * @returns true if the message send was confirmed
	 */
	private confirmMessageSend = (): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.confirmMessageSendModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					header: this.translations['T_MESSAGES.CONFIRM_VEHICLE_MESSAGE_TITLE'],
					message: this.translations['T_MESSAGES.CONFIRM_VEHICLE_MESSAGE'],
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			return modalRef.afterClosed().subscribe(async (response: ConfirmResult) => {
				observer.next(response.confirmed);
			});
		});
	};
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AdminToolsModule } from '../features/admin-tools/admin-tools.module';
import { AgenciesModule } from '../support-features/agencies/agencies.module';
import { DashboardModule } from '../features/dashboard/dashboard.module';
import { MapModule } from '../features/map/map.module';
import { PredictionsModule } from '../features/predictions/predictions.module';
import { ReportsModule } from '../features/reports/reports.module';
import { RiderMessagesModule } from '../features/rider-messages/rider-messages.module';
import { VehicleEventsModule } from '../features/vehicle-events/vehicle-events.module';
import { VehicleMessagesModule } from '../features/vehicle-messages/vehicle-messages.module';
import { VehiclesModule } from '../support-features/vehicles/vehicles.module';

import { HeaderComponent } from './header/header.component';
import { SidebarMinimalizerComponent } from './header/sidebar-minimalizer/sidebar-minimalizer.component';
import { VehicleEventDetailsHeaderComponent } from './header/vehicle-events/details/vehicle-event-details-header.component';
import { VehicleEventSettingsHeaderComponent } from './header/vehicle-events/settings/vehicle-event-settings-header.component';
import { VehicleEventsComponent } from './header/vehicle-events/vehicle-events.component';
import { NavigationComponent } from './navigation/navigation.component';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		VehicleEventsModule,
		AdminToolsModule,
		ReportsModule,
		DashboardModule,
		VehicleEventsModule,
		RiderMessagesModule,
		AgenciesModule,
		VehicleMessagesModule,
		PredictionsModule,
		MapModule,
		VehiclesModule,
	],
	declarations: [
		NavigationComponent,
		SidebarMinimalizerComponent,
		HeaderComponent,
		VehicleEventDetailsHeaderComponent,
		VehicleEventSettingsHeaderComponent,
		VehicleEventsComponent,
	],
	providers: [],
	exports: [
		NavigationComponent,
		SidebarMinimalizerComponent,
		HeaderComponent,
		VehicleEventDetailsHeaderComponent,
		VehicleEventSettingsHeaderComponent,
		VehicleEventsComponent,
	],
})
export class HomeModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from '../../services/config/config.service';
import { LoggerService } from '../logging/logger.service';

/**
 * Service responsible for sending HTTP requests.
 */
@Injectable({
	providedIn: 'root',
})
export class HttpService {
	private readonly baseURL: string = '';

	/**
	 * Initialise the HTTP Service,
	 *
	 * @param httpClient - The underlying HTTP client.
	 * @param configService - The Configuration Service.
	 * @param loggerService - The Logger Service.
	 */
	constructor(
		private httpClient: HttpClient,
		private configService: ConfigService,
		private loggerService: LoggerService
	) {
		this.baseURL = this.configService.getApiUrl();
	}

	/**
	 * Performs the HTTP GET request with the supplied URL.
	 *
	 * @param url - The URL to GET data from.
	 * @param params - The parameters to use during the fetch of the resource data.
	 * @param reponseType - Any specific content type ( Default 'application/json').
	 * @returns The promised retrieved HTTP response content.
	 */
	public get = async (url: string, params: any = null, reponseType: string = null): Promise<any> => {
		try {
			const response: any = await this.httpClient.get(this.baseURL + url, this.headerOptions(params, reponseType) as any).toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP GET call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Performs the HTTP POST request with the supplied data and URL.
	 *
	 * @param url - The URL to POST data to.
	 * @param data - The optional POSTed payload.
	 * @param params - The parameters to use during the post of the resource data.
	 * @returns The promised retrieved HTTP response content.
	 */
	public post = async (url: string, data?: any, params?: any): Promise<any> => {
		try {
			const response: any = await this.httpClient.post(this.baseURL + url, data, this.headerOptions(params) as any).toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP POST call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Performs the HTTP PUT request with the supplied data and URL.
	 *
	 * @param url - The URL to PUT data to.
	 * @param data - The optional PUTted payload.
	 * @returns The promised retrieved HTTP response content.
	 */
	public put = async (url: string, data?: any): Promise<any> => {
		try {
			const response: any = await this.httpClient.put(this.baseURL + url, data, this.headerOptions() as any).toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP PUT call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Performs the HTTP DELETE request with the supplied data and URL.
	 *
	 * @param url - The URL to DELETE data from.
	 * @param data - The optional DELETEd payload.
	 * @returns The promised retrieved HTTP response content.
	 */
	public delete = async (url: string): Promise<any> => {
		try {
			const response: any = await this.httpClient.delete(this.baseURL + url, this.headerOptions() as any).toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP DELETE call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Create the headers for the HTTP request.
	 *
	 * @param parameters - Optional parameters.
	 * @param respType - Optional specific response type header value, default to JSON.
	 * @returns The Headers Options object.
	 */
	private headerOptions = (parameters: any = null, respType: string = null): any => {
		const headers: HttpHeaders = new HttpHeaders({ Accept: 'application/json' });

		if (parameters) {
			const params: any = {};

			for (const property in parameters) {
				if (typeof parameters[property] === 'object') {
					params[property] = JSON.stringify(parameters[property]);
				} else {
					params[property] = parameters[property];
				}
			}

			return {
				headers,
				params,
				withCredentials: false,
				observe: 'response' as const,
				responseType: respType ? respType : 'json',
			};
		} else {
			return {
				headers,
				withCredentials: false,
				observe: 'response' as const,
				responseType: respType ? respType : 'json',
			};
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'admin-tools',
	templateUrl: './admin-tools-view.component.html',
	styleUrls: ['./admin-tools-view.component.scss'],
})
export class AdminToolsComponent extends TranslateBaseComponent {
	constructor(
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * navigates the user to the supplied target
	 *
	 * @param target - the target URL
	 */
	public navigate = (target: string): void => {
		this.router.navigate([target]);
	};
}

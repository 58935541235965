/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';

import { MapOptionsService } from '../services/map-options.service';
import { MapHistoryService } from '../services/map-history.service';
import { MapStateService } from '../services/map-state.service';
import { MapReplayService } from '../services/map-replay.service';
import { MapNavigationService } from '../services/map-navigation.service';
import { MapTimeService } from '../services/map-time.service';

import { ModeType, NavigationPage } from '../types/types';

@Component({
	selector: 'map-home',
	templateUrl: './map-home.component.html',
	styleUrls: ['./map-home.component.scss'],
})
export class MapHomeComponent implements OnInit, AfterViewInit {
	public modeType: typeof ModeType = ModeType; // For HTML

	private requestedNavigationPage: NavigationPage = null;

	constructor(
		private router: Router,
		private mapStateService: MapStateService,
		private mapNavigationService: MapNavigationService,
		private mapTimeService: MapTimeService,
		private mapHistoryService: MapHistoryService,
		public mapReplayService: MapReplayService,
		public mapOptionsService: MapOptionsService
	) {
		const navigation: Navigation = this.router.getCurrentNavigation();

		if (navigation) {
			this.requestedNavigationPage = navigation.extras.state as NavigationPage;
		}
	}

	/**
	 * initialize the map home container component for our map/ladder/replay components
	 *
	 * initialise our map state when this component is initialized (agency may have changed while we have been away from the map)
	 */
	public ngOnInit(): void {
		this.mapTimeService.init();
		this.mapStateService.init();
	}

	/**
	 * manage any additional functionality once the template has rendered
	 *
	 * initialize our history service so we have the users history loaded from previous sessions
	 *
	 * if we are initializing with navigate data (typically from dashboard/vehicle events selection), then navigate to selected page
	 * otherwise return to the last item in the history (init method above will set as menu if empty)
	 */
	public ngAfterViewInit(): void {
		this.mapHistoryService.init();

		if (this.requestedNavigationPage) {
			this.mapNavigationService.navigateToPage(this.requestedNavigationPage);
			this.requestedNavigationPage = null;
		} else {
			this.mapHistoryService.navigateToLastHistoryItem();
		}
	}
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, Injector, OnInit, StaticProvider } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';

import { TranslateBaseComponent } from '../translate-base/translate-base.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { ModalEventsService } from './services/modal-events.service';
import { DataService } from '../../services/data/data.service';

import { ConfirmButtonType, ConfirmResult, EventData } from './types/types';

@Component({
	selector: 'confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent extends TranslateBaseComponent implements OnInit {
	public confirmButtonType: typeof ConfirmButtonType = ConfirmButtonType;

	public confirmEnabled: boolean = true;
	public portal: ComponentPortal<any>;

	private eventData: any = null;

	constructor(
		private modalEventsService: ModalEventsService,
		private modalRef: MatDialogRef<ConfirmModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disbaled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * handles the initialization of the confirm modal
	 */
	public ngOnInit(): void {
		if (this.data.component) {
			const sp: Array<StaticProvider> = [{ provide: DataService, useValue: this.data.params }];

			const dynamicComponentInjector: Injector = Injector.create({ providers: sp });

			this.portal = new ComponentPortal(this.data.component, null, dynamicComponentInjector);
		}

		this.modalEventsService.dataChangedEvent.subscribe((event: EventData) => {
			this.eventData = event.data;
			this.confirmEnabled = event.confirmEnabled;
		});
	}

	/**
	 * handles the closing of the confirm modal with after the confirm button is clicked
	 */
	public confirm = (): void => {
		const result: ConfirmResult = {
			confirmed: true,
			eventData: this.eventData,
		};

		this.modalRef.close(result);
	};

	/**
	 * handles the closing of the confirm modal with after the close button is clicked
	 */
	public close = (): void => {
		const result: ConfirmResult = {
			confirmed: false,
		};

		this.modalRef.close(result);
	};
}

<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_MESSAGES.EDIT_STOPS' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<table>
		<tbody>
			<tr>
				<th *ngFor="let stopsPerDestination of stopsPerDestinations" [ngStyle]="{ width: destinationColumnWidth }">
					<span class="destination-title">{{ stopsPerDestination.id }}</span>
				</th>
			</tr>
			<tr class="destination-row">
				<td
					*ngFor="let stopsPerDestination of stopsPerDestinations; let destinationIndex = index"
					[ngStyle]="{ width: destinationColumnWidth }">
					<mat-selection-list #destinationStops class="stop-selection-list">
						<mat-list-option
							*ngIf="destinationStops"
							checkboxPosition="before"
							[value]="{ id: selectAllId }"
							(click)="selectAll(destinationIndex)"
							[selected]="isAllOptionSelected(destinationIndex)">
							<span class="select-all">{{ 'T_MESSAGES.SELECT_ALL' | translate }}</span>
						</mat-list-option>
						<hr class="select-all-divide" />
						<mat-list-option
							#option
							checkboxPosition="before"
							[value]="stop"
							[selected]="stop.selected"
							(click)="stopSelected()"
							*ngFor="let stop of stopsPerDestination.stops">
							<span class="stop-name">{{ stop.name }}</span>
						</mat-list-option>
					</mat-selection-list>
				</td>
			</tr>
		</tbody>
	</table>
	<div *ngIf="!stopsSelected" class="field-error">
		<p>{{ 'T_MESSAGES.ONE_STOP_REQ' | translate }}</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-secondary" (click)="save()" [disabled]="!stopsSelected">{{ 'T_CORE.SAVE' | translate }}</button>
</mat-dialog-actions>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';

import { DisplayPriorityType, MapModeType, ModeType } from '../types/types';
import { TimeFormatType } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class MapOptionsService {
	constructor(private mapStateService: MapStateService) {}

	/**
	 * set the map state to show/hide vehicle labels
	 *
	 * @param showVehicleLabels - flag to show/hide vehicle labels
	 */
	public setShowVehicleLabels = (showVehicleLabels: boolean): void => {
		this.mapStateService.setShowVehicleLabels(showVehicleLabels);
	};

	/**
	 * get the map state setting to show/hide vehicle labels
	 *
	 * @returns the map state setting to show/hide vehicle labels
	 */
	public getShowVehicleLabels = (): boolean => {
		return this.mapStateService.getShowVehicleLabels();
	};

	/**
	 * set the map state to show/hide vehicle id part of the vehicle label
	 *
	 * @param showVehicleLabelId - flag to show/hide vehicle id
	 */
	public setShowVehicleLabelId = (showVehicleLabelId: boolean): void => {
		this.mapStateService.setShowVehicleLabelId(showVehicleLabelId);
	};

	/**
	 * get the map state setting to show/hide vehicle label id
	 *
	 * @returns the map state setting to show/hide vehicle label id
	 */
	public getShowVehicleLabelId = (): boolean => {
		return this.mapStateService.getShowVehicleLabelId();
	};

	/**
	 * set the map state to show/hide route part of the vehicle label
	 *
	 * @param showVehicleLabelRoute - flag to show/hide route
	 */
	public setShowVehicleLabelRoute = (showVehicleLabelRoute: boolean): void => {
		this.mapStateService.setShowVehicleLabelRoute(showVehicleLabelRoute);
	};

	/**
	 * get the map state setting to show/hide vehicle label route
	 *
	 * @returns the map state setting to show/hide vehicle label route
	 */
	public getShowVehicleLabelRoute = (): boolean => {
		return this.mapStateService.getShowVehicleLabelRoute();
	};

	/**
	 * set the map state to show/hide depature adherence part of the vehicle label
	 *
	 * @param showVehicleLabelDepartureAdherence - flag to show/hide depature adherence
	 */
	public setShowVehicleLabelDepartureAdherence = (showVehicleLabelDepartureAdherence: boolean): void => {
		this.mapStateService.setShowVehicleLabelDepartureAdherence(showVehicleLabelDepartureAdherence);
	};

	/**
	 * get the map state setting to show/hide vehicle label depature adherence
	 *
	 * @returns the map state setting to show/hide vehicle label depature adherence
	 */
	public getShowVehicleLabelDepartureAdherence = (): boolean => {
		return this.mapStateService.getShowVehicleLabelDepartureAdherence();
	};

	/**
	 * set the map state to show/hide headway part of the vehicle label
	 *
	 * @param showVehicleLabelHeadway - flag to show/hide headway
	 */
	public setShowVehicleLabelHeadway = (showVehicleLabelHeadway: boolean): void => {
		this.mapStateService.setShowVehicleLabelHeadway(showVehicleLabelHeadway);
	};

	/**
	 * get the map state setting to show/hide vehicle label headway
	 *
	 * @returns the map state setting to show/hide vehicle label headway
	 */
	public getShowVehicleLabelHeadway = (): boolean => {
		return this.mapStateService.getShowVehicleLabelHeadway();
	};

	/**
	 * set the map state to show/hide status part of the vehicle label
	 *
	 * @param showVehicleLabelStatus - flag to show/hide status
	 */
	public setShowVehicleLabelStatus = (showVehicleLabelStatus: boolean): void => {
		this.mapStateService.setShowVehicleLabelStatus(showVehicleLabelStatus);
	};

	/**
	 * get the map state setting to show/hide vehicle label status
	 *
	 * @returns the map state setting to show/hide vehicle label status
	 */
	public getShowVehicleLabelStatus = (): boolean => {
		return this.mapStateService.getShowVehicleLabelStatus();
	};

	/**
	 * set the map state to show/hide block part of the vehicle label
	 *
	 * @param showVehicleLabelBlock - flag to show/hide block
	 */
	public setShowVehicleLabelBlock = (showVehicleLabelBlock: boolean): void => {
		this.mapStateService.setShowVehicleLabelBlock(showVehicleLabelBlock);
	};

	/**
	 * get the map state setting to show/hide vehicle label block
	 *
	 * @returns the map state setting to show/hide vehicle label block
	 */
	public getShowVehicleLabelBlock = (): boolean => {
		return this.mapStateService.getShowVehicleLabelBlock();
	};

	/**
	 * set the map state to show/hide passengers part of the vehicle label
	 *
	 * @param showVehicleLabelPassengers - flag to show/hide passengers
	 */
	public setShowVehicleLabelPassengers = (showVehicleLabelPassengers: boolean): void => {
		this.mapStateService.setShowVehicleLabelPassengers(showVehicleLabelPassengers);
	};

	/**
	 * get the map state setting to show/hide vehicle label passengers
	 *
	 * @returns the map state setting to show/hide vehicle label passengers
	 */
	public getShowVehicleLabelPassengers = (): boolean => {
		return this.mapStateService.getShowVehicleLabelPassengers();
	};

	/**
	 * set the map state to show/hide ladder stop labels
	 *
	 * @param showLadderStopLabels - the map state setting to show/hide ladder stop labels
	 */
	public setShowLadderStopLabels = (showLadderStopLabels: boolean): void => {
		this.mapStateService.setShowLadderStopLabels(showLadderStopLabels);
	};

	/**
	 * get the map state setting to show/hide ladder stop labels
	 *
	 * @returns the map state setting to show/hide ladder stop labels
	 */
	public getShowLadderStopLabels = (): boolean => {
		return this.mapStateService.getShowLadderStopLabels();
	};

	/**
	 * set the map state to show/hide ladder stop label code
	 *
	 * @param showLadderStopLabelCode - the map state setting to show/hide ladder stop code
	 */
	public setShowLadderStopLabelCode = (showLadderStopLabelCode: boolean): void => {
		this.mapStateService.setShowLadderStopLabelCode(showLadderStopLabelCode);
	};

	/**
	 * get the map state setting to show/hide ladder stop label code
	 *
	 * @returns the map state setting to show/hide ladder stop label code
	 */
	public getShowLadderStopLabelCode = (): boolean => {
		return this.mapStateService.getShowLadderStopLabelCode();
	};

	/**
	 * set the map state to show/hide ladder stop label name
	 *
	 * @param showLadderStopLabelName - the map state setting to show/hide ladder stop name
	 */
	public setShowLadderStopLabelName = (showLadderStopLabelName: boolean): void => {
		this.mapStateService.setShowLadderStopLabelName(showLadderStopLabelName);
	};

	/**
	 * get the map state setting to show/hide ladder stop label name
	 *
	 * @returns the map state setting to show/hide ladder stop label name
	 */
	public getShowLadderStopLabelName = (): boolean => {
		return this.mapStateService.getShowLadderStopLabelName();
	};

	/**
	 * set the map state to the chosen time format
	 *
	 * @param timeFormat - the time format to set
	 */
	public setTimeFormat = (timeFormat: TimeFormatType): void => {
		this.mapStateService.setTimeFormat(timeFormat);
	};

	/**
	 * get the map state setting for the time format
	 *
	 * @returns the map state setting for the time format
	 */
	public getTimeFormat = (): TimeFormatType => {
		return this.mapStateService.getTimeFormat();
	};

	/**
	 * set the map state to whether the navigation view is open
	 *
	 * @param navViewOpen - true when the navigation view is set open
	 */
	public setNavViewOpen = (navViewOpen: boolean): void => {
		this.mapStateService.setNavViewOpen(navViewOpen);
	};

	/**
	 * get whether the map state navigation view is set open
	 *
	 * @returns - true when the navigation view is set open
	 */
	public getNavViewOpen = (): boolean => {
		return this.mapStateService.getNavViewOpen();
	};

	/**
	 * set the map state to the map mode (live/replay)
	 *
	 * @param mapMode - whether map mode is set to live/replay
	 */
	public setMapMode = (mapMode: MapModeType): void => {
		this.mapStateService.setMapMode(mapMode);
	};

	/**
	 * get whether the map state is set to live/replay
	 *
	 * @param mapMode - whether the map state is set to live/replay
	 * @returns the map mode
	 */
	public getMapMode = (): MapModeType => {
		return this.mapStateService.getMapMode();
	};

	/**
	 * set the map state to the mode (map/ladder)
	 *
	 * @param mode - whether mode is set to map/ladder
	 */
	public setMode = (mode: ModeType): void => {
		this.mapStateService.setMode(mode);
	};

	/**
	 * get whether the map state is set to map/ladder
	 *
	 * @param mapMode - whether the map state is set to map/ladder
	 * @returns the mode
	 */
	public getMode = (): ModeType => {
		return this.mapStateService.getMode();
	};

	/**
	 * refresh the map state mode
	 */
	public refreshMode = (): void => {
		this.mapStateService.refreshMode();
	};

	/**
	 * set the map state to paused/unpaused i.e live mode
	 *
	 * @param liveUpdatesPaused - true when map is set to paused
	 */
	public setLiveUpdatesPaused = (liveUpdatesPaused: boolean): void => {
		this.mapStateService.setLiveUpdatesPaused(liveUpdatesPaused);
	};

	/**
	 * get whether the map state is set to paused/unpaused i.e live mode
	 *
	 * @returns true when map is set to paused
	 */
	public getLiveUpdatesPaused = (): boolean => {
		return this.mapStateService.getLiveUpdatesPaused();
	};

	/**
	 * set the map state to full screen / normal mode
	 *
	 * @param fullScreen - true when setting the map to full screen mode
	 */
	public setFullScreen = (fullScreen: boolean): void => {
		this.mapStateService.setFullScreen(fullScreen);
	};

	/**
	 * get whether the map state is set to full screen / normal mode
	 *
	 * @returns true when the map is set to full screen mode
	 */
	public getFullScreen = (): boolean => {
		return this.mapStateService.getFullScreen();
	};

	/**
	 * set the map state display priority to the appropriate type (vehicles/stops on top)
	 *
	 * @param displayPriority - whether to set vehicles or stops on top
	 */
	public setDisplayPriority = (displayPriority: DisplayPriorityType): void => {
		this.mapStateService.setDisplayPriority(displayPriority);
	};

	/**
	 * get the map state display priority (vehicles/stops on top)
	 *
	 * @returns whether he display priority is set to vehicles or stops on top
	 */
	public getDisplayPriority = (): DisplayPriorityType => {
		return this.mapStateService.getDisplayPriority();
	};

	/**
	 * determine whether vehicle labels are showing. Even if the parent setting is turned on, still return
	 * false if there are no parts of the vehicle label set to showing
	 *
	 * @returns whether vehicle labels are showing
	 */
	public vehicleLabelsAreShowing = (): boolean => {
		return (
			this.getShowVehicleLabels() &&
			(this.getShowVehicleLabelBlock() ||
				this.getShowVehicleLabelStatus() ||
				this.getShowVehicleLabelId() ||
				this.getShowVehicleLabelRoute() ||
				this.getShowVehicleLabelDepartureAdherence() ||
				this.getShowVehicleLabelPassengers() ||
				this.getShowVehicleLabelHeadway())
		);
	};
}

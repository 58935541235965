/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { InitStatusParams, InitReplayParams } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class ReplayApiService {
	private readonly initUrl: string = '/vehicles/archive/init';
	private readonly statusUrl: string = '/status';

	constructor(private httpService: HttpService) {}

	/**
	 * send an update to the nextbus API to initialize replay mode
	 *
	 * @param authorityId - the authoirity id
	 * @param requestParams - additional replay params
	 * @returns the nextbus API response
	 */
	public init = async (authorityId: string, requestParams: InitReplayParams): Promise<any> => {
		try {
			return await this.httpService.post('/' + authorityId + this.initUrl, requestParams);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request the current status from the nextbus API regarding the initialization of replay mode. Essentially this
	 * is used to poll the nextbus API for replay initialization completion
	 *
	 * @param authorityId - the authoirity id
	 * @param requestParams - additional replay params
	 * @returns the nextbus API response
	 */
	public getInitStatus = async (authorityId: string, requestParams: InitStatusParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.initUrl + this.statusUrl, requestParams);
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsDataService } from '../../services/reports-data.service';
import { ReportsUtilService } from '../../services/reports-util.service';
import { ConfigService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { PageRequestInfo } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';

import { ReportTemplateDisplayDetail, ReportTemplateDisplayDetailsByCategory } from '../../types/types';
import { ReportTemplateDetail, ReportTemplateDetails } from '../../types/api-types';

@Component({
	selector: 'saved-report-templates',
	templateUrl: './saved-report-templates.component.html',
	styleUrls: ['./saved-report-templates.component.scss'],
})
export class SavedReportTemplatesComponent extends TranslateBaseComponent implements OnInit {
	@Output() setLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() templateSelected: EventEmitter<ReportTemplateDetail> = new EventEmitter<ReportTemplateDetail>();

	public templateDisplayDatailsByCategory: ReportTemplateDisplayDetailsByCategory = null;

	public totalTemplates: number = 0;
	public pageInfo: PageRequestInfo = null;

	private searchText: string = null;
	private templateDetails: ReportTemplateDetails = null;

	private readonly sortField: string = 'name';
	private readonly sortDir: string = 'asc';

	constructor(
		private reportsDataService: ReportsDataService,
		private reportsUtilService: ReportsUtilService,
		private configService: ConfigService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the saved report template component - defaults paging information
	 */
	public async ngOnInit(): Promise<void> {
		// get the page details for the initial request
		this.pageInfo = {
			pageNum: 1,
			pageSize: this.configService.getDefaultPageSize(),
		};
	}

	/**
	 * loads the template display data
	 */
	public refresh = async (): Promise<void> => {
		await this.loadTemplateDisplayData();
	};

	/**
	 * loads the template display data dependent upon paging information supplied
	 *
	 * @param pageInfo - paging information
	 */
	public handleTemplateRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		await this.loadTemplateDisplayData();
	};

	/**
	 * loads the template display data dependent upon the the search text
	 *
	 * @param searchText - the search text
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;
		await this.loadTemplateDisplayData();
	};

	/**
	 * handles request when a template has been selected - retrieves selected template details and emits to the parent
	 *
	 * @param reportTemplateDisplayDetail - report template display detail
	 */
	public handleTemplateSelected = (reportTemplateDisplayDetail: ReportTemplateDisplayDetail): void => {
		const templateDetail: ReportTemplateDetail = this.getTemplateDetailsFromId(reportTemplateDisplayDetail.nbId);

		if (templateDetail) {
			this.templateSelected.emit(templateDetail);
		}
	};

	/**
	 * builds the list of reports by category for display purposes allowing for any overrides set by the agency
	 */
	private loadTemplateDisplayData = async (): Promise<void> => {
		this.setLoading.emit(true);

		const templateDetailsReponse: ResultContent = await this.reportsDataService.getReportTemplates(
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.sortField,
			this.sortDir,
			this.searchText
		);

		if (templateDetailsReponse.success) {
			this.templateDetails = templateDetailsReponse.resultData.results;

			this.templateDisplayDatailsByCategory = await this.reportsUtilService.getSavedReportTemplatesByCategory(this.templateDetails);

			this.totalTemplates = templateDetailsReponse.resultData.total;
		}

		this.setLoading.emit(false);
	};

	/**
	 * retrieves the requested report template details for the supplied report template id
	 *
	 * @param nbId - report template id
	 * @returns requested report template details
	 */
	private getTemplateDetailsFromId = (nbId: number): ReportTemplateDetail => {
		return this.templateDetails.filter((templateDetail: ReportTemplateDetail) => templateDetail.nb_id === nbId)[0];
	};
}

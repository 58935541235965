<div class="grid-title">{{ 'T_CORE.BLOCK' | translate }} {{ subListData.block_id }}</div>
<table data-test="report.stop-list.all" class="configuration-stops-and-timepoints-by-block-sub-list-table">
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'stop_sequence' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_sequence')"
				data-test="th.stop_sequence"
				class="mw-col ellipsis">
				{{ 'T_MAP.MAP_SEQUENCE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'route_id_sort' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'route_id_sort')"
				data-test="th.route_id_sort"
				class="mw-col ellipsis">
				{{ 'T_CORE.ROUTE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_headsign' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_headsign')"
				data-test="th.trip_headsign"
				class="mw-col ellipsis">
				{{ 'T_REPORT.DESTINATION' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				data-test="th.stop_name"
				class="mw-col ellipsis">
				{{ 'T_REPORT.STOP_TITLE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_id_sort' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_id_sort')"
				data-test="th.stop_id_sort"
				class="mw-col ellipsis">
				{{ 'T_REPORT.STOP_TAG' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'arrival_time' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'arrival_time')"
				data-test="th.arrival_time"
				class="mw-col ellipsis">
				{{ 'T_REPORT.TIME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'timepoint' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'timepoint')"
				data-test="th.timepoint"
				class="mw-col ellipsis">
				{{ 'T_REPORT.TIMEPOINT' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_id')"
				data-test="th.trip_id"
				class="mw-col ellipsis">
				{{ 'T_CORE.TRIP' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'shape_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'shape_id')"
				data-test="th.shape_id"
				class="mw-col ellipsis">
				{{ 'T_REPORT.TRIP_PATTERN' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'shape_dist_traveled' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'shape_dist_traveled')"
				data-test="th.shape_dist_traveled"
				class="mw-col ellipsis">
				{{ 'T_REPORT.PATH_POS' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow" (click)="generateReport(reportDataItem)">
			<td data-test="td.stop_sequence" class="ellipsis">{{ reportDataItem.stop_sequence }}</td>
			<td class="reporttooltip">
				<div class="truncate">
					<span class="tooltiptext">{{ reportDataItem.route_long_name }}</span>
					<route-pill
						data-test="td.route_id_sort"
						class="report-viewer-grid-route-pill"
						[route]="determineRoutePillData(reportDataItem)"></route-pill>
					<div class="truncate configuration-stops-and-timepoints-by-block-sub-list-route">{{ reportDataItem.route_long_name }}</div>
				</div>
			</td>
			<td data-test="td.trip_headsign" class="ellipsis reporttooltip">
				<span class="tooltiptext">{{ reportDataItem.trip_headsign }}</span
				>{{ reportDataItem.trip_headsign }}
			</td>
			<td data-test="td.stop_name" class="ellipsis reporttooltip">
				<span class="tooltiptext">{{ reportDataItem.stop_name }}</span
				>{{ reportDataItem.stop_name }}
			</td>
			<td data-test="td.stop_id_sort" class="ellipsis">{{ reportDataItem.stop_id }}</td>
			<td data-test="td.arrival_time" class="ellipsis">{{ reportDataItem.arrival_time }}</td>
			<td data-test="td.timepoint" class="ellipsis">{{ reportDataItem.timepoint }}</td>
			<td data-test="td.trip_id" class="ellipsis">{{ reportDataItem.trip_id }}</td>
			<td data-test="td.shape_id" class="ellipsis">{{ reportDataItem.shape_id }}</td>
			<td data-test="td.shape_dist_traveled" class="ellipsis">{{ reportDataItem.shape_dist_traveled | number: '1.1-1' }}</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

<div class="nb-report-viewer-list nb-padding-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<div *ngFor="let reportItem of listData; let reportIndex = index">
					<div class="configuration-schedule-for-route-list-block-container">
						<div class="configuration-schedule-for-route-list-block">
							<div class="grid-title">
								<div class="configuration-schedule-for-route-list-block-title">
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.ROUTE' | translate }}
										</div>
										<div class="view-table-field">
											<route-pill [route]="determineRoutePillData(reportItem)"></route-pill>
										</div>
									</div>
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.DIRECTION' | translate }}
										</div>
										<div class="view-table-field">
											{{ reportItem.direction }}
										</div>
									</div>
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.TRIP_PATTERN' | translate }}
										</div>
										<div class="view-table-field">
											{{ reportItem.tripPattern }}
										</div>
									</div>
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.DAYS_OF_WEEK' | translate }}
										</div>
										<div class="view-table-field">
											{{ reportItem.dayOfWeek }}
										</div>
									</div>
								</div>
							</div>
							<table>
								<thead>
									<tr>
										<th class="ellipsis">
											{{ 'T_REPORT.STOP' | translate }}
										</th>
										<th class="mw-col ellipsis config-average-travel-time-stop">
											{{ 'T_REPORT.STOP_TAG' | translate }}
										</th>
										<th class="mw-col ellipsis">
											{{ 'T_REPORT.DISTANCE_BETWEEN_STOPS' | translate }}
										</th>
										<th class="mw-col ellipsis" *ngFor="let reportItemData of reportItem.timeOfTheDayTotals">
											{{ 'T_REPORT.TIME_OF_DAY' | translate }} <br />
											{{ reportItemData.timeOfTheDay }} <br />
											<br />
											<span class="span-row-width">
												{{ 'T_REPORT.TIME_MIN' | translate }}
											</span>
											<span> {{ 'T_REPORT.SPEED' | translate }} ({{ reportItem.speed_selection }}) </span>
										</th>
									</tr>
								</thead>
								<tbody class="zebra">
									<tr class="ellipsis" *ngFor="let reportItemData of reportItem.rowData">
										<td class="ellipsis">
											{{ reportItemData.stopTitle }}
										</td>
										<td class="ellipsis">
											{{ reportItemData.stopTag }}
										</td>
										<td class="ellipsis">
											{{ reportItemData.distanceBetweenStops }}
										</td>
										<td *ngFor="let timesData of reportItemData.timeOfTheDay">
											<span class="span-row-width"> {{ timesData.time }} </span>
											<span>
												{{ timesData.speed }}
											</span>
										</td>
									</tr>
									<tr class="ellipsis">
										<td colspan="2" class="ellipsis bold">
											{{ 'T_REPORT.TOTALS' | translate }}
										</td>
										<td class="ellipsis bold">
											{{ reportItem.distanceBetweenStopsTotal }}
										</td>
										<td class="ellipsis bold" *ngFor="let totals of reportItem.timeOfTheDayTotals">
											<span class="span-row-width">
												{{ totals.totalTime }}
											</span>
											<span>
												{{ totals.totalSpeed }}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
							<br />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'loading-overlay',
	templateUrl: './loading-overlay.component.html',
	styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
	@Input() isLoading: boolean = false;
	@Input() fit: string = null;
	@Input() showAnimation: boolean = true;
	@Input() fadeOverlay: boolean = true;
	@Input() loadingText: string = null;

	constructor(private translationService: TranslationService) {}

	/**
	 * handles the initialization of the loading overlay control
	 */
	public ngOnInit(): void {
		this.initTranslation();
	}

	/**
	 * handles the initialization of the translated text for the control
	 */
	private initTranslation = async (): Promise<void> => {
		// if loading Text is set do not default. Also ensure empty string does not display 'loading'
		if (this.loadingText === null && this.loadingText !== '') {
			this.loadingText = this.translationService.getTranslation('T_CORE.LOADING');
		}
	};
}

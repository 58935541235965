import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ScheduleAdminViewUploadHistoryDetailsComponent } from '../view/upload-history/upload-history-details/schedule-admin-view-upload-history-details.component';

import { UploadHistoryDetailsType } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class ScheduleAdminHistoryModalService {
	constructor(private uploadHistoryModal: MatDialog) {}

	/**
	 * presents the upload history details dialog and publishes the updated details thereafter or cancel dependant upon user interaction
	 *
	 * @param authorityId - authority id
	 * @param confirmationNumber - confirmation number
	 * @param uploadHistoryDetailsType - upload history details type
	 * @returns publishes the upload history details or cancel
	 */
	public uploadHistoryConfirmDetails = (
		authorityId: string,
		confirmationNumber: string,
		uploadHistoryDetailsType: UploadHistoryDetailsType
	): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ScheduleAdminViewUploadHistoryDetailsComponent> = this.uploadHistoryModal.open(
				ScheduleAdminViewUploadHistoryDetailsComponent,
				{
					minHeight: '491px', //essentially sets an initial height for the page (while loading spinner active)
					width: '938px',
					data: {
						authorityId,
						confirmationNumber,
						uploadHistoryDetailsType,
					},
					position: {
						top: '60px',
					},
				}
			);

			return modalRef.afterClosed().subscribe(async (confirmNoCreated: boolean) => {
				observer.next(confirmNoCreated);
			});
		});
	};
}

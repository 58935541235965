/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';

import { PerformanceMetricsDisplayIncludes } from '../../../../../support-features/routes/types/types';
import { RouteMetricsResults, RouteSummary } from '../../../../../support-features/routes/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { IWidgetComponent } from '../../../types/types';

@Component({
	selector: 'performance',
	templateUrl: './performance.component.html',
	styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;
	public loaded: boolean = false;
	public hasResults: boolean = false;
	public success: boolean = false;
	public timezone: string = null;

	public metrics: RouteMetricsResults = null;
	public display: PerformanceMetricsDisplayIncludes = null;

	public routeHeading: string = null;
	public authorityId: string = null;
	public agencyId: string = null;
	public includeAllRoutes: boolean = false;

	private listInterval: any = null;

	constructor(
		private widgetEventsService: WidgetEventsService,
		private routesDataService: RoutesDataService,
		private agenciesDataService: AgenciesDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the performance component - loading translations, setting up subscriptions and loading form data
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations(['T_DASH.DB_ALL_ROUTES']);

		this.setSubscriptions();

		this.getData();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * loads underlying widget data
	 */
	private setup = (): void => {
		this.loaded = false;
		this.timezone = null;
		this.display = {
			// if flag is not present in config, default to true
			displayHeadwayVeryClose: this.data.config.displayHeadwayVeryClose !== false,
			displayHeadwayClose: this.data.config.displayHeadwayClose !== false,
			displayHeadwayOkay: this.data.config.displayHeadwayOkay !== false,
			displayHeadwayDistant: this.data.config.displayHeadwayDistant !== false,
			displayHeadwayVeryDistant: this.data.config.displayHeadwayVeryDistant !== false,
			displayVeryEarly: this.data.config.displayVeryEarly !== false,
			displayEarly: this.data.config.displayEarly !== false,
			displayOnTime: this.data.config.displayOnTime !== false,
			displayLate: this.data.config.displayLate !== false,
			displayVeryLate: this.data.config.displayVeryLate !== false,
			displayPredictability: this.data.config.displayPredictability !== false,
			displayPredictionAccuracyAll: this.data.config.displayPredictionAccuracyAll !== false,
			displayPredictionAccuracy0to5: this.data.config.displayPredictionAccuracy0to5 !== false,
			displayPredictionAccuracy5to10: this.data.config.displayPredictionAccuracy5to10 !== false,
			displayPredictionAccuracy10to15: this.data.config.displayPredictionAccuracy10to15 !== false,
			displayBlocksUnassigned: false,
			displayPredictableVehicles: false,
		};
		this.routeHeading = this.translations['DB_ALL_ROUTES'];

		if (this.data.config.selectedAgency && this.data.config.selectedAgency.authority_id) {
			this.authorityId = this.data.config.selectedAgency.authority_id;
		} else {
			this.authorityId = null;
		}

		if (this.data.config.selectedAgency && this.data.config.selectedAgency.agency_id) {
			this.agencyId = this.data.config.selectedAgency.agency_id;
		} else {
			this.agencyId = null;
		}

		if (!this.data.config?.includeAllRoutes) {
			this.includeAllRoutes = this.data.config.includeAllRoutes;
			this.routeHeading = this.setRouteHeading(this.includeAllRoutes);
		} else {
			this.includeAllRoutes = true;
		}
	};

	/**
	 * creates a formatted routes text for the routes heading display
	 *
	 * @param routeOptions - route options
	 * @returns formatted routes heading for widget
	 */
	private setRouteHeading = (routeOptions: boolean): string => {
		return routeOptions
			? this.translations['DB_ALL_ROUTES']
			: this.data.config.selectedRoutes.map((route: RouteSummary) => route.route_display_name).join(', ');
	};

	/**
	 * gets the widget performance data
	 */
	private getData = async (): Promise<void> => {
		this.loaded = false;
		this.setup();
		this.timezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

		await this.getPerformanceMetrics();

		clearInterval(this.listInterval);
		const refreshRate: number = (this.data.config.refreshRateSec || 30) * 1000;

		this.listInterval = setInterval(this.getPerformanceMetrics, refreshRate);
	};

	/**
	 * gets the widget performance metrics data
	 */
	private getPerformanceMetrics = async (): Promise<void> => {
		const response: ResultContent = await this.routesDataService.getPerformanceMetrics(
			this.authorityId,
			this.agencyId,
			this.timezone,
			this.data.config.timeRangeMinutes,
			this.data.config.timeIntervalMinutes,
			this.display,
			this.data.config.includeAllRoutes,
			this.data.config.selectedRoutes
		);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.success = false;
		this.hasResults = false;
		this.metrics = null;

		if (response.success) {
			const responseData: RouteMetricsResults = response.resultData;

			if (responseData) {
				this.metrics = responseData;
				this.hasResults = this.metrics.routeMetrics?.length > 0 && this.metrics.predictionMetrics?.length > 0;
				this.success = true;
			}
		}

		this.loaded = true;
	};

	/**
	 * sets up subscriptions - is interested when the widget is reloaded
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.getData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.listInterval) {
			clearInterval(this.listInterval);
		}

		this.reloadWidget$Subscription?.unsubscribe();
	};
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
	/**
	 * pipe to transform and return the property keys from an object
	 *
	 * @param value - the object to transform
	 * @returns the object property keys
	 */
	transform(value: any): any {
		return Object.keys(value);
	}
}

import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { LoggerService } from '../logging/logger.service';

import { NotificationLevel } from './types/types';

/**
 * Service responsible for notifying the end user.
 */
@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(
		private notifier: ToastrService,
		private loggerService: LoggerService
	) {}

	/**
	 * Notify a success situation with the supplied message.
	 *
	 * @param msg - success message.
	 * @param title - the title of the message (optional)
	 * @param options - any additional options (optional) to configure the message
	 */
	public notifySuccess = (msg: string, title?: string, options?: any): void => {
		this.notify(msg, NotificationLevel.success, title, options);
	};

	/**
	 * Notify an informational situation with the supplied message.
	 *
	 * @param msg - informational message.
	 * @param title - the title of the message (optional)
	 * @param options - any additional options (optional) to configure the message
	 */
	public notifyInfo = (msg: string, title?: string, options?: any): void => {
		this.notify(msg, NotificationLevel.info, title, options);
	};

	/**
	 * Notify a warning situation with the supplied message.
	 *
	 * @param msg - Warning message.
	 * @param title - the title of the message (optional)
	 * @param options - any additional options (optional) to configure the message
	 */
	public notifyWarning = (msg: string, title?: string, options?: any): void => {
		this.notify(msg, NotificationLevel.warning, title, options);
	};

	/**
	 * Notify an error situation with the supplied message.
	 *
	 * @param msg - Error message.
	 * @param title - the title of the message (optional)
	 * @param options - any additional options (optional) to configure the message
	 */
	public notifyError = (msg: string, title?: string, options?: any): void => {
		this.notify(msg, NotificationLevel.error, title, options);
	};

	/**
	 * Clears all notifications
	 */
	public clearAllNotifications = (): void => {
		this.notifier.clear();
	};

	/**
	 * Notify with a popup based on the notification level passes in
	 *
	 * @param msg - Error message.
	 * @param notificationLevel - an enum representing the notification level
	 * @param title - the title of the message (optional)
	 * @param options - any additional options (optional) to configure the message
	 */
	private notify = (msg: string, notificationLevel: NotificationLevel, title?: string, options?: any): void => {
		switch (notificationLevel) {
			case NotificationLevel.success:
				if (this.notifier) {
					this.notifier.success(msg, title, options);
				} else {
					this.loggerService.logInfo(msg);
				}
				break;
			case NotificationLevel.info:
				if (this.notifier) {
					this.notifier.info(msg, title, options);
				} else {
					this.loggerService.logInfo(msg);
				}
				break;
			case NotificationLevel.warning:
				if (this.notifier) {
					this.notifier.warning(msg, title, options);
				} else {
					this.loggerService.logInfo(msg);
				}
				break;
			case NotificationLevel.error:
				if (this.notifier) {
					this.notifier.error(msg, title, options);
				} else {
					this.loggerService.logInfo(msg);
				}
				break;
			default:
				if (this.notifier) {
					this.notifier.info(msg, title, options);
				} else {
					this.loggerService.logInfo(msg);
				}
				break;
		}
	};
}

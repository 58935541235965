/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import L from 'leaflet';

import 'leaflet.markercluster';

import { MapStopsTooltipMarkerService } from './map-stops-tooltip-marker.service';
import { MapStopsClusterTooltipMarkerService } from './map-stops-cluster-tooltip-marker.service';
import { MapMarkerUtilsService } from './map-marker-utils.service';
import { MapActiveEntityService } from '../map-active-entity.service';
import { MapStopsService } from '../map-stops.service';
import { MapOptionsService } from '../map-options.service';
import { MapNavigationService } from '../map-navigation.service';

import { DisplayPriorityType, MapStop, MarkerExtended, Markers } from '../../types/types';

import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';
import { EntityType } from '../../../../utils/components/breadcrumbs/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapStopsMarkerService {
	private readonly maxClusterRadius: number = 8;

	private mapInstance: L.Map = null;
	private markers: Markers = {};
	private markerClusterGroup: L.MarkerClusterGroup = L.markerClusterGroup();
	private selectedAgency: SelectedAgency = null;

	constructor(
		private mapMarkerUtilsService: MapMarkerUtilsService,
		private mapActiveEntityService: MapActiveEntityService,
		private mapOptionsService: MapOptionsService,
		private mapStopsService: MapStopsService,
		private mapNavigationService: MapNavigationService,
		private mapStopsTooltipMarkerService: MapStopsTooltipMarkerService,
		private mapStopsClusterTooltipMarkerService: MapStopsClusterTooltipMarkerService
	) {}

	/**
	 * initialize the stops marker service
	 *
	 * @param mapInstance - the current map instance
	 * @param selectedAgency - the current agency
	 */
	public init = (mapInstance: L.Map, selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapInstance = mapInstance;

		this.markers = {};

		this.initStopClusterGroup();

		this.mapStopsTooltipMarkerService.init(mapInstance, selectedAgency);
		this.mapStopsClusterTooltipMarkerService.init(mapInstance, selectedAgency);
	};

	/**
	 * handle the agency change and clear down any markers
	 *
	 * @param selectedAgency - the current agency
	 */
	public setAgencyChange = (selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapStopsTooltipMarkerService.setAgencyChange(selectedAgency);
		this.mapStopsClusterTooltipMarkerService.setAgencyChange(selectedAgency);

		this.clearStops();
	};

	/**
	 * add the stop (icon) marker
	 *
	 * @param stopCode - the stop code for the stop
	 */
	public addStop = (stopCode: string): void => {
		const stop: MapStop = this.mapStopsService.getStop(stopCode);

		// _ar stops are virtual stops for the ladder only
		if (!stop.hidden && !stop.stopCode.includes('_ar')) {
			// check it's not already there otherwise we could add a stop multiple times if it's shared between routes
			if (!this.markers[stop.stopCode]) {
				this.markers[stop.stopCode] = new L.Marker(new L.LatLng(stop.stopLat, stop.stopLon), {
					icon: this.getIcon(stop.stopCode),
					zIndexOffset: 3000,
				});

				// hack to add our custom content - there is probably a better way that requires extending the leaflet classes
				this.markers[stop.stopCode]['stopCode'] = stop.stopCode;

				this.markers[stop.stopCode].on('mouseover', () => {
					this.mapStopsTooltipMarkerService.addStopTooltip(stop.stopCode);
				});

				this.markers[stop.stopCode].on('mouseout', () => {
					this.mapInstance.closePopup();

					this.mapStopsTooltipMarkerService.removeStopTooltip(stop.stopCode);
				});

				this.markers[stop.stopCode].on('click', async () => {
					await this.mapNavigationService.navigateToStopDetails(this.selectedAgency.authority_id, stop.stopCode);
				});

				this.markerClusterGroup.addLayer(this.markers[stop.stopCode]);
			}
		}
	};

	/**
	 * remove the stop (icon) marker
	 *
	 * @param stopCode - the stop code for the stop
	 */
	public removeStop = (stopCode: string): void => {
		if (this.markers[stopCode]) {
			this.markers[stopCode].remove();

			this.markerClusterGroup.removeLayer(this.markers[stopCode]);

			delete this.markers[stopCode];
		}
	};

	/**
	 * refresh all stop icons
	 *
	 * we can't just set the icon again like we do with vehicle markers i.e this.markers[stopCode].setIcon(this.getIcon(stopCode));
	 * as the cluster doesn't refresh and we lose styling around the cluster icon (i.e highlight shimmer)
	 * for stops just reload instead of refreshing
	 */
	public refreshStopIcons = (): void => {
		this.reloadStops();
	};

	/**
	 * reload the current stop icons
	 */
	public reloadStops = (): void => {
		for (const stopCode in this.mapStopsService.getStops()) {
			this.removeStop(stopCode);
			this.addStop(stopCode);
		}
	};

	/**
	 * clear the current stop icons
	 */
	public clearStops = (): void => {
		for (const stopCode in this.markers) {
			this.removeStop(stopCode);
		}
	};

	/**
	 * initialize a stop cluster group
	 */
	private initStopClusterGroup = (): void => {
		this.markerClusterGroup = new L.MarkerClusterGroup({
			iconCreateFunction: this.iconCreateFunction,
			spiderfyOnMaxZoom: false,
			zoomToBoundsOnClick: false, // stops zooming to markers on click
			chunkedLoading: true,
			showCoverageOnHover: false,
			removeOutsideVisibleBounds: true,
			maxClusterRadius: (): number => this.maxClusterRadius,
		});

		this.markerClusterGroup.on('clustermouseover', (e: any) => {
			const stopCodes: string[] = [];

			e.layer.getAllChildMarkers().forEach((marker: MarkerExtended) => {
				stopCodes.push(marker.stopCode);
			});

			const markerId: string = this.getStopClusterTooltipMarkerId(stopCodes);

			this.mapStopsClusterTooltipMarkerService.addStopTooltip(stopCodes, markerId, e.latlng.lat, e.latlng.lng);
		});

		this.markerClusterGroup.on('clustermouseout', (e: any) => {
			const stopCodes: string[] = [];

			e.layer.getAllChildMarkers().forEach((marker: MarkerExtended) => {
				stopCodes.push(marker.stopCode);
			});

			const markerId: string = this.getStopClusterTooltipMarkerId(stopCodes);

			this.mapStopsClusterTooltipMarkerService.removeStopTooltip(markerId);
		});

		this.mapInstance.addLayer(this.markerClusterGroup);
	};

	/**
	 * helper function for the initStopClusterGroup method
	 *
	 * @param cluster - the stop label cluster
	 * @returns a leaflet div icon to display on the map
	 */
	private iconCreateFunction = (cluster: any): L.Icon => {
		const stopClusterChildMarkers: any = cluster.getAllChildMarkers();

		const stops: any[] = stopClusterChildMarkers;

		const stopCodes: any[] = stops.map((stop) => stop.stopCode);

		return this.getStopClusterIcon(stopCodes);
	};

	/**
	 * get the stop cluster tooltip marker (icon) id
	 *
	 * @param stopCodes - the stop codes for the cluser
	 * @returns the stop cluster tooltip marker (icon) id
	 */
	private getStopClusterTooltipMarkerId = (stopCodes: string[]): string => {
		let markerId: string = '';

		stopCodes.forEach((stopCode: string, index: number) => {
			if (index !== 0) {
				markerId += '_';
			}

			markerId += stopCode;
		});

		return markerId;
	};

	/**
	 * get the stop icon
	 *
	 * @param stopCode - the stop code
	 * @returns a leaflet icon to display on the map
	 */
	private getIcon = (stopCode: string): L.Icon => {
		const markerSize: number[] = this.mapMarkerUtilsService.getStopMarkerSize();

		let className: string = 'nb-stop-icon-shadow';

		if (this.mapActiveEntityService.isActiveEntity(stopCode, EntityType.stop)) {
			className = 'nb-stop-icon-shadow-active';
		}

		if (this.mapOptionsService.getDisplayPriority() === DisplayPriorityType.stops) {
			className += ' nb-stop-icon-stops-top';
		} else {
			className += ' nb-stop-icon-stops-bottom';
		}

		// Add a hook in to the class for the test automation
		className += ' stopCode-' + stopCode;

		return L.icon({
			className,
			iconUrl: '/assets/img/stopicon.png',
			iconAnchor: [Math.floor(markerSize[0] / 2), Math.floor(markerSize[1] / 2)],
			iconSize: markerSize as L.PointExpression,
		});
	};

	/**
	 * get the stop cluster icon
	 * @param stopCodes - the stop codes that for the stops in the cluster
	 * @returns the stop cluster icon
	 */
	private getStopClusterIcon = (stopCodes: string[]): L.Icon => {
		const markerSize: number[] = this.mapMarkerUtilsService.getStopMarkerSize();

		let className: string = 'nb-stop-icon-shadow';

		if (this.mapActiveEntityService.hasActiveEntity()) {
			stopCodes.forEach((stopCode: string) => {
				if (this.mapActiveEntityService.isActiveEntity(stopCode, EntityType.stop)) {
					className = 'nb-stop-icon-shadow-active';
				}
			});
		}

		if (this.mapOptionsService.getDisplayPriority() === DisplayPriorityType.stops) {
			className += ' nb-stop-icon-stops-top';
		} else {
			className += ' nb-stop-icon-stops-bottom';
		}

		// Add a hook in to the class for the test automation
		className += ' stopCodes-';

		stopCodes.forEach((stopCode: string, index: number) => {
			className += stopCode;

			if (index !== stopCodes.length - 1) {
				className += '-';
			}
		});

		return L.icon({
			className,
			iconUrl: '/assets/img/stopicon.png',
			iconAnchor: [Math.floor(markerSize[0] / 2), Math.floor(markerSize[1] / 2)],
			iconSize: markerSize as L.PointExpression,
		});
	};
}

<loading-overlay [isLoading]="loading"></loading-overlay>

<div *ngIf="!listInitialized" class="nb-padding-all-lg building-report-list-container">
	{{ 'T_REPORT.BUILDING_REPORT_LIST' | translate }}
</div>

<div [hidden]="!listInitialized">
	<ng-template [cdkPortalOutlet]="portal" (attached)="attachChildDynamicComponent($event)"></ng-template>
	<div *ngIf="showPagingToolbar">
		<pagination-toolbar
			[paginationSettings]="paginationSettings"
			[pagination]="pagination"
			(setPageSizeEvent)="setPageSize()"
			(pageChangedEvent)="pageChanged()"
			data-test="pagination.bar">
		</pagination-toolbar>
	</div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarkdownModule } from 'ngx-markdown';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { DisablePredictionsEditModalComponent } from './list/disable-predictions-edit-modal/disable-predictions-edit-modal.component';
import { PredictionsListComponent } from './list/predictions-list.component';

import { routeConfigStoreProvider } from './services/predictions-store-factory';

import { PredictionsApiService } from './services/predictions-api.service';
import { PredictionsDataService } from './services/predictions-data.service';
import { PredictionsModalService } from './services/predictions-modal.service';
import { AgenciesModule } from '../../support-features/agencies/agencies.module';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [PredictionsListComponent, DisablePredictionsEditModalComponent],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		AgenciesModule,
		MarkdownModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
	],
	providers: [
		routeConfigStoreProvider,
		PredictionsDataService,
		PredictionsModalService,
		PredictionsApiService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class PredictionsModule {}

import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgIdleModule } from '@ng-idle/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { UtilsModule } from '@cubicNx/libs/utils';

import { LoginComponent } from './login-form/login.component';
import { PasswordExpiryWarningComponent } from './login-form/password-expiry-warning/password-expiry-warning.component';

import { CurrentUserApiService } from './services/current-user/current-user-api.service';
import { CurrentUserDataService } from './services/current-user/current-user-data.service';
import { CurrentUserUtilService } from './services/current-user/current-user-utils.service';
import { InitEventsService } from './services/init/init-events.service';
import { InitService } from './services/init/init.service';
import { IdleService } from './services/idle/idle.service';
import { UserCacheService } from './services/user-cache/user-cache.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		NgIdleModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
	],
	declarations: [PasswordExpiryWarningComponent, LoginComponent],
	providers: [
		CurrentUserApiService,
		CurrentUserUtilService,
		CurrentUserDataService,
		InitEventsService,
		InitService,
		IdleService,
		UserCacheService,
	],
	exports: [],
})
export class LoginModule {}

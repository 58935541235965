import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

/**
 * Service responsible for i18n translations.
 */
@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	private langs: string[] = ['en', 'fr', 'es'];

	constructor(private translateService: TranslateService) {}

	/**
	 * Performs translation related initialization
	 */
	public init = (): void => {
		this.translateService.addLangs(this.langs);

		const browserlang: string = this.translateService.getBrowserLang();

		if (this.langs.indexOf(browserlang) > -1) {
			this.translateService.setDefaultLang(browserlang);
			this.translateService.use(browserlang);
		} else {
			this.translateService.setDefaultLang('en');
			this.translateService.use('en');
		}
	};

	/**
	 * will return a translated version of the supplied text
	 *
	 * replace any dashes/hyphens with underscores - typically hardcoded translations will already have underscores
	 * but some translation keys are contructed from back end data which may have hyphens
	 *
	 * @param translateKey - key of the text to be translated
	 * @returns Language specific variant
	 */
	public getTranslation = (translateKey: string): string => {
		let translatedKey: string = '';

		translateKey = translateKey.replace(/ |-/g, '_').toUpperCase();

		this.translateService.get([translateKey]).subscribe((translations: { [x: string]: string }) => {
			translatedKey = translations[translateKey];

			return translatedKey;
		});

		return translatedKey;
	};
}

<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".
-->

<saved-report-template-selector
	[templatesByCategory]="templateDisplayDatailsByCategory"
	[checkboxesEnabled]="false"
	[paginationEnabled]="true"
	[pageInfo]="pageInfo"
	[totalTemplates]="totalTemplates"
	(requestTemplates)="handleTemplateRequest($event)"
	(searchTemplates)="search($event)"
	(templateSelected)="handleTemplateSelected($event)">
</saved-report-template-selector>

<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_USER.CHANGE_PASSWORD' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<form *ngIf="changePasswordForm" [formGroup]="changePasswordForm">
		<loading-overlay [isLoading]="loading" [fit]="'content'"></loading-overlay>

		<div
			class="form-group"
			[ngClass]="{ 'has-error': changePasswordForm.controls.password.touched && changePasswordForm.controls.password.errors }">
			<field-label [name]="'currentPassword'" [label]="'T_USER.CURRENT_PASSWORD' | translate" [required]="true"></field-label>
			<input
				formControlName="password"
				data-test="password.current"
				type="password"
				class="form-control"
				title="{{ 'T_CORE.PASSWORD' | translate }}"
				placeholder="{{ 'T_CORE.PASSWORD' | translate }}"
				(input)="currentPasswordUpdated()" />
			<input-validation [control]="changePasswordForm.controls.password" [errorOnTouch]="true"> </input-validation>
		</div>
	</form>
	<set-password
		[showFieldLabels]="true"
		[passwordTitle]="'T_USER.NEW_PASSWORD'"
		[currentPassword]="this.changePasswordForm.controls.password.value"
		(passwordEntryValid)="setNewPasswordValid($event)"
		(newPassword)="setNewPassword($event)"></set-password>
</mat-dialog-content>

<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-secondary" (click)="save()" [disabled]="!enableSave()">{{ 'T_CORE.SAVE' | translate }}</button>
</mat-dialog-actions>

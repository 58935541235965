<div class="data-list-container" *ngIf="initialized">
	<data-table
		data-test="schedule.upload.table"
		[listName]="'schedule-upload-history'"
		[columns]="uploadHistoryListColumns"
		[selectionByType]="selectionByType"
		[rowData]="uploadHistoryListData"
		[enableCheckRowSelection]="false"
		[enabledMultiCheckRowSelection]="false"
		[pagingEnabled]="true"
		[totalRows]="totalRows"
		[defaultSortings]="defaultSortings"
		[pageInfo]="pageInfo"
		[enableMenuSelection]="false"
		[enableFooter]="true"
		[loadingIndicator]="listLoadingIndicator"
		(requestData)="handleDataRequest($event)"
		(rowClick)="onSelect($event)">
	</data-table>
</div>

<table>
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'stop_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_id')"
				data-test="th.stop_id"
				class="ellipsis">
				{{ 'T_CORE.STOP_ID' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				data-test="th.stop_name"
				class="ellipsis">
				{{ 'T_CORE.STOP_NAME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_headsign' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_headsign')"
				data-test="th.trip_headsign"
				class="mw-col ellipsis">
				{{ 'T_CORE.DIRECTION' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'boardings' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'boardings')"
				data-test="th.boardings"
				class="mw-col ellipsis">
				{{ 'T_REPORT.BOARDINGS' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'alightings' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'alightings')"
				data-test="th.alightings"
				class="mw-col ellipsis">
				{{ 'T_REPORT.ALIGHTINGS' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'max_load' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'max_load')"
				data-test="th.max_load"
				class="mw-col ellipsis">
				{{ 'T_REPORT.MAX_LOAD' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'max_load_pct' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'max_load_pct')"
				data-test="th.max_load_pct"
				class="mw-col ellipsis">
				{{ 'T_REPORT.MAX_LOAD_PCT' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow">
			<td data-test="td.stop_id" class="ellipsis">{{ reportDataItem.stop_id !== null ? reportDataItem.stop_id : 'N/A' }}</td>
			<td data-test="td.stop_name" class="ellipsis">{{ reportDataItem.stop_name !== null ? reportDataItem.stop_name : 'N/A' }}</td>
			<td data-test="td.trip_headsign" class="ellipsis">
				{{ reportDataItem.trip_headsign === null ? 'N/A' : reportDataItem.trip_headsign === '-null-' ? '' : reportDataItem.trip_headsign }}
			</td>
			<td data-test="td.boardings" class="ellipsis">{{ reportDataItem.boardings !== null ? reportDataItem.boardings : 'N/A' }}</td>
			<td data-test="td.alightings" class="ellipsis">{{ reportDataItem.alightings !== null ? reportDataItem.alightings : 'N/A' }}</td>
			<td data-test="td.max_load" class="ellipsis">{{ reportDataItem.max_load !== null ? reportDataItem.max_load : 'N/A' }}</td>
			<td data-test="td.max_load_pct" class="ellipsis">
				{{ reportDataItem.max_load_pct !== null ? (reportDataItem.max_load_pct * 100).toFixed() + '%' : 'N/A' }}
			</td>
		</tr>
	</tbody>
</table>

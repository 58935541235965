<div id="mainLadder" role="main" class="main-ladder-container">
	<div id="mainLadderContent" [ngStyle]="{ height: getLadderHeight() }" class="row main-ladder-content">
		<div class="column" [ngStyle]="getLadderContainerPosition()"></div>
		<div id="ladderContainer">
			<div *ngIf="ladderRoutes.length === 0" class="nb-ladder-empty-container" [ngStyle]="{ left: getEmptyMessagePositionOffset() + 'px' }">
				<img class="empty-ladder-icon" src="/assets/img/LadderIcon.svg" alt="Unavailable" />
				<div class="empty-ladder-title">
					<span>{{ 'T_MAP.LADDER_EMPTY_VIEW_TITLE' | translate }}</span>
				</div>
				<div class="empty-ladder-subtitle">
					<span>{{ 'T_MAP.LADDER_EMPTY_VIEW_SUB_TITLE' | translate }}</span>
				</div>
				<a class="btn-primary" (click)="goToRoutes()">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" height="30px" width="30px">
						<path
							class="liveops-nav_icon"
							d="M55,35A5,5,0,1,1,55,45a4.54,4.54,0,0,1-4.59-3H44.59A4.54,4.54,0,0,1,40,45a4.54,4.54,0,0,1-4.59-3H29.59A4.54,4.54,0,0,1,25,45,5,5,0,1,1,25,35a4.54,4.54,0,0,1,4.59,3h5.81A4.54,4.54,0,0,1,40,35a4.54,4.54,0,0,1,4.59,3h5.81A4.54,4.54,0,0,1,55,35Z"></path>
					</svg>
					<span>{{ 'T_MAP.LADDER_EMPTY_VIEW_BUTTON_TEXT' | translate }}</span>
				</a>
			</div>
			<div
				id="ladderColumn"
				*ngIf="ladderRoutes.length > 0"
				class="column main-ladder-column"
				[ngStyle]="{ 'padding-left': getLabelLeftPadding(), 'padding-right': getLabelRightPadding(), left: getContainerLeftPosition() }">
				<div [ngStyle]="{ position: 'relative', margin: '10px', width: ladderRoutes.length * 300 + 'px', 'margin-left': '50px' }">
					<table class="main-ladder-column-table">
						<tr class="main-ladder-column-row">
							<td *ngFor="let route of ladderRoutes" class="main-ladder-column-row-route">
								<ladder-vertical [route]="route" id="{{ 'route_' + route.routeId }}"></ladder-vertical>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>

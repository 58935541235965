<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<table>
					<thead>
						<tr class="raw_avl_spacing">
							<th
								[ngClass]="sortDetails.field === 'yard_name' ? sortClass : null"
								(click)="sortList('yard_name')"
								data-test="th.yard_name"
								class="ellipsis raw_avl_spacing">
								{{ 'T_REPORT.YARD_NAME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'first_stop' ? sortClass : null"
								(click)="sortList('first_stop')"
								data-test="th.first_stop"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.FIRST_STOP' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'vehicle_id' ? sortClass : null"
								(click)="sortList('vehicle_id')"
								data-test="th.vehicle_id"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.VEHICLE_NAME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'job_tag' ? sortClass : null"
								(click)="sortList('job_tag')"
								data-test="th.job_tag"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.BLOCK' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'route_tag' ? sortClass : null"
								(click)="sortList('route_tag')"
								data-test="th.route_tag"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.ROUTE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'trip_tag' ? sortClass : null"
								(click)="sortList('trip_tag')"
								data-test="th.trip_tag"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.TRIP' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'scheduled_time' ? sortClass : null"
								(click)="sortList('scheduled_time')"
								data-test="th.scheduled_time"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.SCHEDULED_TIME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'predicted_travel_time' ? sortClass : null"
								(click)="sortList('predicted_travel_time')"
								data-test="th.predicted_travel_time"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.PREDICTED_TRAVEL_TIME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'difference' ? sortClass : null"
								(click)="sortList('difference')"
								data-test="th.difference"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.DIFFERENCE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'actual_departure_time' ? sortClass : null"
								(click)="sortList('actual_departure_time')"
								data-test="th.actual_departure_time"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.ACTUAL_DEPARTURE_TIME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'actual_stop' ? sortClass : null"
								(click)="sortList('actual_stop')"
								data-test="th.actual_stop"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.ACTUAL_STOP' | translate }}
							</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr class="raw_avl_spacing" *ngFor="let reportItem of listData">
							<td data-test="td.yard_name" class="ellipsis raw_avl_spacing">
								{{ reportItem.yard_name !== null ? reportItem.yard_name : 'N/A' }}
							</td>
							<td data-test="td.first_stop" class="ellipsis raw_avl_spacing">
								{{ reportItem.first_stop !== null ? reportItem.first_stop : 'N/A' }}
							</td>
							<td data-test="td.vehicle_id" class="ellipsis raw_avl_spacing">
								{{ reportItem.vehicle_id !== null ? reportItem.vehicle_id : 'N/A' }}
							</td>
							<td data-test="td.job_tag" class="ellipsis raw_avl_spacing">
								{{ reportItem.job_tag !== null ? reportItem.job_tag : 'N/A' }}
							</td>
							<td data-test="td.route_tag" class="ellipsis raw_avl_spacing">
								{{ reportItem.route_tag !== null ? reportItem.route_tag : 'N/A' }}
							</td>
							<td data-test="td.trip_tag" class="ellipsis raw_avl_spacing">
								{{ reportItem.trip_tag !== null ? reportItem.trip_tag : 'N/A' }}
							</td>
							<td data-test="td.scheduled_time" class="ellipsis raw_avl_spacing">
								{{ reportItem.scheduled_time !== null ? (reportItem.scheduled_time | date: 'y-MM-dd &nbsp; HH:mm ss') : 'N/A' }}
							</td>
							<td data-test="td.predicted_travel_time" class="ellipsis raw_avl_spacing">
								{{
									reportItem.predicted_travel_time !== null ? (reportItem.predicted_travel_time | date: 'y-MM-dd &nbsp; HH:mm ss') : 'N/A'
								}}
							</td>
							<td data-test="td.difference" class="ellipsis raw_avl_spacing">
								{{ reportItem.difference !== null ? reportItem.difference : 'N/A' }}
							</td>
							<td data-test="td.actual_departure_time" class="ellipsis raw_avl_spacing">
								{{
									reportItem.actual_departure_time !== null ? (reportItem.actual_departure_time | date: 'y-MM-dd &nbsp; HH:mm ss') : 'N/A'
								}}
							</td>
							<td data-test="td.actual_stop" class="ellipsis raw_avl_spacing">
								{{ reportItem.actual_stop !== null ? reportItem.actual_stop : 'N/A' }}
							</td>
						</tr>
					</tbody>
				</table>
				<br />
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

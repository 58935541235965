import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			autoDismiss: true,
			maxOpened: 0,
			preventDuplicates: false,
			newestOnTop: true,
			positionClass: 'toast-top-center',
			progressBar: true,
			closeButton: true,
			timeOut: 10000,
			extendedTimeOut: 1000,
			easing: 'swing',
		}),
	],
	providers: [],
})
export class NotificationModule {}

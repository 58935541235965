/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { ReportsConfigService } from '../../../../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportViewerSortableSubListBaseComponent } from '../../../report-viewer-sortable-sublist-base.component';

@Component({
	selector: 'rc600-rider-count-by-block-and-vehicle-sub-list',
	templateUrl: './rider-count-by-block-and-vehicle-sub-list.component.html',
	styleUrls: ['./rider-count-by-block-and-vehicle-sub-list.component.scss'],
})
export class RiderCountByBlockAndVehicleSubListComponent extends ReportViewerSortableSubListBaseComponent implements OnInit {
	constructor(
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * performs initialization tasks for the rider count by block and vehicles sub list view (RC600)
	 */
	public ngOnInit(): void {
		this.init();
	}
}

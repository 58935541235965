<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".
-->
<div class="nb-map-details-views-wrapper nb-map-details-body">
	<div class="content-block-no-border">
		<div class="bs-loading-container">
			<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		</div>

		<div *ngIf="loaded">
			<div class="sectiontitle-block">
				<div class="nb-section-title flex-center-items">
					<span>{{ 'T_MAP.MAP_MY_VIEWS' | translate }}</span>
					<i class="nb-padding-left-sm nb-icons nb-favorite-solid text-accent"></i>
				</div>
				<div [hidden]="mapViews.length > 0" class="nb-padding-top-sm">
					<span class="nb-text"></span>{{ 'T_MAP.MAP_NO_VIEWS' | translate }}
				</div>
			</div>

			<div class="nb-map-saved-views">
				<div class="flex-table">
					<div *ngFor="let view of mapViews" class="flex-table-row">
						<div class="nb-padding-all-xs name-container" (click)="applyView(view)">
							<span>{{ view.name }}</span>
						</div>
						<i
							class="nb-map-view-function-button nb-icons nb-settings"
							(click)="renameView(view)"
							title="{{ 'T_CORE.RENAME' | translate }}"></i>
						<i
							class="nb-map-view-function-button nb-icons nb-delete"
							(click)="deleteView(view)"
							title="{{ 'T_CORE.DELETE' | translate }}"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

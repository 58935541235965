/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapPollingService } from '../services/map-polling.service';
import { MapVehicleTrailService } from '../services/map-vehicle-trail.service';
import { MapVehicleTrackService } from '../services/map-vehicle-track.service';
import { MapVehiclesService } from '../services/map-vehicles.service';
import { MapOptionsService } from '../services/map-options.service';
import { MapEventsService } from '../services/map-events.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { MapDepotSelection, MapModeType, ModeType, allDepotTag } from '../types/types';

@Component({
	selector: 'map-banners',
	templateUrl: './map-banners.component.html',
	styleUrls: ['./map-banners.component.scss'],
})
export class MapBannersComponent extends TranslateBaseComponent {
	// make enums accessible in html
	public modeType: typeof ModeType = ModeType;
	public mapModeType: typeof MapModeType = MapModeType;

	constructor(
		public mapOptionsService: MapOptionsService,
		public mapVehicleTrailService: MapVehicleTrailService,
		public mapVehicleTrackService: MapVehicleTrackService,
		public mapVehiclesService: MapVehiclesService,
		private mapPollingService: MapPollingService,
		private mapEventsService: MapEventsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 *  handle the user click and turn off the replay mode via the green banner
	 */
	public setReplayModeOff = (): void => {
		// clear out any existing vehicles from replay mode
		this.mapVehiclesService.clearVehicles();
		this.mapOptionsService.setMapMode(MapModeType.live);
		this.mapEventsService.publishNavigateRefresh();
		this.mapPollingService.initPolling();
	};

	/**
	 *  handle the user click and turn off vehicle tracking via the green banner
	 */
	public setTrackingModeOff = (): void => {
		this.mapVehicleTrackService.clearTrackedVehicle();
	};

	/**
	 *  handle the user click and turn off vehicle trails via the green banner
	 */
	public setTrailModeOff = (): void => {
		this.mapVehicleTrailService.clearTrailedVehicle();
	};

	/**
	 *  handle the user click and turn off the early/late filter via the green banner
	 */
	public setVehicleEarlyLateOff = (): void => {
		this.mapVehiclesService.setShowVehiclesWithAdherenceIssues(false);
	};

	/**
	 *  handle the user click and turn off the vehicle close/distant filter via the green banner
	 */
	public setVehicleCloseDistantOff = (): void => {
		this.mapVehiclesService.setShowVehiclesWithHeadwayIssues(false);
	};

	/**
	 * handle the user click and reset the selected depot when the user closes the filter via the green banner
	 */
	public resetSelectedDepot = (): void => {
		const allDepotSelection: MapDepotSelection = {
			tag: allDepotTag,
			name: null, // don't need the name when 'all' is selected as we don't show it
		};

		this.mapVehiclesService.setSelectedDepotFilter(allDepotSelection);
	};

	/**
	 * handle the user click and resume live updates when the user cancels the pause live updates via the red banner
	 */
	public resumeLiveUpdates = (): void => {
		this.mapPollingService.resumeLivePolling();
	};

	/**
	 * determine the position offset to ensure the banners are central - taking in to account whether the nav menu is active or not
	 * @returns the offset position in pixels
	 */
	public getControlPositionOffset = (): number => {
		return this.mapOptionsService.getNavViewOpen() ? 264 : 0;
	};
}

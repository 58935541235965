/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalMode } from '../dashboard-format-modal/dashboard-format-modal.component';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { maxlengthValidation, ValidationText } from '@cubicNx/libs/utils';

@Component({
	selector: 'dashboard-title',
	templateUrl: './dashboard-title.component.html',
	styleUrls: ['./dashboard-title.component.scss'],
})
export class DashboardTitleComponent extends TranslateBaseComponent implements OnInit, OnChanges {
	@Input() dashboardOptions: any;
	@Input() editMode: any;

	@Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() createDashboardClicked: EventEmitter<ModalMode> = new EventEmitter<ModalMode>();
	@Output() selectDashboardClicked: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveDashboardClicked: EventEmitter<string> = new EventEmitter<string>();
	@Output() cancelWithoutSave: EventEmitter<void> = new EventEmitter<void>();

	public dashboardTitleForm: FormGroup = null;
	public isDashboardSelectOpen: boolean = false;
	public selectedDashboard: any = null;
	public translationsLoaded: boolean = false;

	public titleExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	public override translations: { [key: string]: string } = {};

	private maxTitleLength: number = 25;
	private minTitleLength: number = 1;

	constructor(
		private formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(translationService);

		this.titleExtraMessageText = {
			[maxlengthValidation]: this.maxTitleLength.toString(),
		};
	}

	/**
	 * performs initialization tasks for the dashboard title component - loads translations, builds the form and populates it
	 */
	public ngOnInit(): void {
		this.initTranslations(['T_DASH.DB_DASHBOARD']).then((value) => (this.translationsLoaded = value));
		this.buildForm();
		this.setupData();
	}

	/**
	 * for when the dashboard mode, options change
	 * @param changes - the input changes
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		const current: string = changes.dashboardOptions?.currentValue?.find((i: any) => i.selected)?.id;
		const previous: string = changes.dashboardOptions?.previousValue?.find((i: any) => i.selected)?.id;

		if (current !== previous) {
			this.selectedDashboard = this.dashboardOptions.find((i: any) => i.selected);
			this.dashboardTitleForm?.get('title').setValue(this.selectedDashboard?.title);
		}
	}

	/**
	 * Toggles the dashboard selection dropdown.
	 */
	public toggleDashboardSelect = (): void => {
		this.isDashboardSelectOpen = !this.isDashboardSelectOpen;
	};

	/**
	 * Toggles the dashboard edit mode, revealing a form to update the dashboard title.
	 * Also emits an event with the edit mode value listenable by the containing component.
	 */
	public toggleEditMode = (): void => {
		this.isDashboardSelectOpen = false;

		this.editMode = !this.editMode;
		this.editModeChange.emit(this.editMode);
	};

	/**
	 * Cancels edit mode, emitting an event to the containing component
	 * and resetting the title input to the currently selected dashboard title.
	 */
	public cancelEditMode = (): void => {
		this.editMode = false;
		this.cancelWithoutSave.emit();
		this.dashboardTitleForm.get('title').setValue(this.selectedDashboard?.title);
	};

	/**
	 * saves the dashboard title
	 */
	public saveDashboard = (): void => {
		this.selectedDashboard.title = this.dashboardTitleForm.get('title').value;

		this.saveDashboardClicked.emit();
	};

	/**
	 * Emits a dashboard create event to the containing component and closes the dashboard select dropdown.
	 */
	public createDashboard = (): void => {
		this.isDashboardSelectOpen = false;
		this.createDashboardClicked.emit(ModalMode.create);
	};

	/**
	 * Checks the validity of the dashboard title form.
	 *
	 * @returns true if the form is valid.
	 */
	public isFormValid = (): boolean => {
		return this.dashboardTitleForm.valid;
	};

	/**
	 * Select dashboard, updating the selected dashboard in the dashboardOptions object,
	 * the selectedDashboard object to new selection and updating the title input of the form with the new title.
	 * Finally emitting a select dashboard event to the containing component.
	 *
	 * @param event - - click event data, containing id of the selected dashboard.
	 */
	public selectDashboard = (event: any): void => {
		this.dashboardOptions.forEach((i: any) => {
			i.selected = i.id === event.id;
		});
		this.selectedDashboard = this.dashboardOptions.find((dashboard: any) => dashboard.selected);
		this.dashboardTitleForm.get('title').setValue(this.selectedDashboard?.title);
		this.isDashboardSelectOpen = false;
		this.selectDashboardClicked.emit(event.id);
	};

	/**
	 * Gets the padding-bottom style depending on the component editMode.
	 *
	 * @returns padding-bottom style class.
	 */
	public paddingStyle = (): any => {
		return {
			'padding-bottom': this.editMode ? '28px' : '8px',
		};
	};

	/**
	 * Builds the form with input and validators set.
	 */
	private buildForm = (): void => {
		this.dashboardTitleForm = this.formBuilder.group(
			{
				title: ['', [Validators.required, Validators.maxLength(this.maxTitleLength), Validators.minLength(this.minTitleLength)]],
			},
			{}
		);
	};

	/**
	 * Sets up the component data.
	 */
	private setupData = (): void => {
		this.selectedDashboard = this.dashboardOptions.find((dashboard: any) => dashboard.selected);
		this.dashboardTitleForm.get('title').setValue(this.selectedDashboard?.title);
	};
}

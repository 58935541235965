export interface SliderConfig {
	limit?: number;
	start?: Array<number>;
	connect?: boolean;
	step?: number;
	tooltips?: boolean;
	margin?: number;
	padding?: number;
	range?: Range;
	rangeMultiplier?: number;
	colors?: Colors;
	pips?: Pips;
	behaviour?: string;
}

export interface Range {
	min: number;
	max: number;
}

export interface Colors {
	middleColor: string;
	middleColor2?: string;
	rightColor: string;
	leftColor: string;
}

export interface Pips {
	stepped?: true;
	mode: string;
	values: Array<number>;
	density?: number;
	format?: PipsFormat;
}

export interface PipsFormat {
	to: Function;
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnInit } from '@angular/core';

import { OccupancyWidgetSeats } from '../../../../../support-features/routes/types/api-types';

@Component({
	selector: 'occupancy-tooltip',
	templateUrl: './occupancy-tooltip.component.html',
	styleUrls: ['./occupancy-tooltip.component.scss'],
})
export class OccupancyTooltipComponent implements OnInit {
	@Input() occupancy: OccupancyWidgetSeats;
	@Input() occupancyType: string;
	@Input() tooltipPosition: any;

	public vehicleCount: any;
	public vehiclePercent: any;
	public occupancyStatus: any;
	public ridershipImage: any;

	/**
	 * performs initialization tasks for the occupance toolkit component - loading widget data
	 */
	public ngOnInit(): void {
		const occupancyValues: any = {
			manySeatsAvailable: 1,
			fewSeatsAvailable: 2,
			standingRoomOnly: 3,
			crushedStandingRoomOnly: 4,
			full: 5,
		};

		const num: number = this.occupancy[this.occupancyType as keyof OccupancyWidgetSeats] as number;
		const total: number = this.occupancy.total;

		this.occupancyStatus = occupancyValues[this.occupancyType] || -1;
		this.vehicleCount = num;
		this.vehiclePercent = Math.round((100 * num) / total);
	}
}

<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
		<div>
			<field-label [name]="'name'" [label]="'T_CORE.AGENCY_SELECTION' | translate" [required]="true"></field-label>
		</div>
		<select
			name="agencyId"
			id="agencyId"
			class="form-control"
			[(ngModel)]="selectedAgencyId"
			(change)="agencyChanged()"
			data-test="agency.selection">
			<option *ngFor="let agency of availableAgencies" [ngValue]="agency.agency_nb_id">
				{{ agency.agency_name }}
			</option>
		</select>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="cancel()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-primary" (click)="save()" [disabled]="!newAgencySelected">{{ 'T_CORE.SAVE' | translate }}</button>
</mat-dialog-actions>

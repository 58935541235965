<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".-->

<div class="nb-fullscreen-login-bg">
	<video playsinline autoplay muted loop class="nb-fullscreen-bg-video">
		<source src="/assets/img/Nextbusloop_Reduced.mp4" type="video/mp4" />
	</video>
</div>

<div *ngIf="loginForm" class="nb-login-card middle-box text-center loginscreen animated fadeInDown nb-login-container" role="main">
	<div class="nb-login-colorblock"></div>
	<div>
		<div class="nb-logo-container">
			<img class="nb-loginlogo" src="/assets/img/umo_agencyportal.svg" alt="Umo IQ Agency Portal" />
		</div>

		<form class="nb-login-form-container" [formGroup]="loginForm" novalidate>
			<loading-overlay [isLoading]="loading" [fit]="'content'"></loading-overlay>

			<div *ngIf="error" class="nb-login-invalid-login-error-container">
				<div name="loginErrorMsg">
					<span name="loginErrorMsgText" data-test="login.error">{{ error }}</span>
				</div>
			</div>

			<!--Log in form-->
			<div class="form-group" [ngClass]="{ 'has-error': loginForm.controls.username.touched && loginForm.controls.username.errors }">
				<input
					formControlName="username"
					type="text"
					class="form-control"
					id="username"
					data-test="username.input"
					title="{{ 'T_CORE.ENTER_USERNAME' | translate }}"
					placeholder="{{ 'T_CORE.USERNAME' | translate }}" />
				<input-validation
					[control]="loginForm.controls.username"
					[errorOnTouch]="true"
					[messageTextOverride]="usernameRequiredTextOverride">
				</input-validation>
			</div>

			<div class="form-group" [ngClass]="{ 'has-error': loginForm.controls.password.touched && loginForm.controls.password.errors }">
				<input
					formControlName="password"
					type="password"
					class="form-control"
					id="password"
					data-test="password.input"
					autocomplete="off"
					title="{{ 'T_CORE.ENTER_PASSWORD' | translate }}"
					placeholder="{{ 'T_CORE.ENTER_PASSWORD' | translate }}" />
				<input-validation
					[control]="loginForm.controls.password"
					[errorOnTouch]="true"
					[messageTextOverride]="passwordRequiredTextOverride">
				</input-validation>
			</div>

			<div class="nb-button-group-container">
				<!--Log in button-->
				<div class="nb-padding-top-sm nb-padding-bottom-md">
					<button name="loginBtn" data-test="login.button" class="btn btn-primary" (click)="logIn()">
						{{ 'T_CORE.LOGIN' | translate }}
					</button>
				</div>

				<!--Reset password-->
				<div>
					<span class="nb-text">
						<a class="nb-text-link" (click)="resetPassword()" data-test="reset_password.button">{{
							'T_CORE.RESET_PASSWORD' | translate
						}}</a>
					</span>
				</div>
			</div>
		</form>
	</div>
	<div class="nb-login-colorblock nb-login-footer">
		<img src="/assets/img/poweredbycubic.png" alt="Powered by Cubic" />
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input } from '@angular/core';

@Component({
	selector: 'vehicle-event-icon',
	templateUrl: './vehicle-event-icon.component.html',
	styleUrls: ['./vehicle-event-icon.component.scss'],
})
export class VehicleEventIconComponent {
	@Input() category: string;
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '../translate-base/translate-base.component';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'view-panel',
	templateUrl: './view-panel.component.html',
	styleUrls: ['./view-panel.component.scss'],
})
export class ViewPanelComponent extends TranslateBaseComponent implements OnInit {
	@Input() layout: any = null;
	@Input() viewtableoptions: any = null;
	@Input() model: any = null;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * initializes the panel. If any fields are set to auto hide and the value is empty
	 * set the row to be hidden (ensures the title is also hidden)
	 */
	public ngOnInit(): void {
		this.viewtableoptions?.rowDefs?.forEach((rowDef: any) => {
			if (rowDef.type === 'autoHideString') {
				// if the value passed in is null, hide the entire row
				if (this.model[rowDef.name] === null) {
					rowDef.hidden = true;
				}
			}
		});
	}

	/**
	 * gets the panel outer container style class
	 *
	 * @returns the panel outer container style class
	 */
	public getContainerClass = (): string => {
		switch (this.layout) {
			case 'column-center':
				return 'column-center-container';
			case 'column':
			case 'row':
			default:
				return null;
		}
	};

	/**
	 * gets the panel content style class
	 *
	 * @returns the panel content style class
	 */
	public getContentClass = (): string => {
		switch (this.layout) {
			case 'row':
				return 'row-content';
			case 'column-center':
			case 'column':
			default:
				return null;
		}
	};

	/**
	 * gets the panel data style class
	 *
	 * @returns the panel data style class
	 */
	public getDataClass = (): string => {
		switch (this.layout) {
			case 'column-center':
			case 'column':
				return 'row column-data';
			case 'row':
				return 'row-data';
			default:
				return null;
		}
	};

	/**
	 * gets the panel title style class
	 *
	 * @returns the panel title style class
	 */
	public getTitleClass = (): string => {
		switch (this.layout) {
			case 'column-center':
				return 'heading column-center-title';
			case 'column':
				return 'heading';
			case 'row':
				return 'nb-label nb-view-table-th';
			default:
				return null;
		}
	};
}

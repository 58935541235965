<div class="nb-fullscreen-login-bg">
	<video playsinline autoplay muted loop class="nb-fullscreen-bg-video">
		<source src="/assets/img/Nextbusloop_Reduced.mp4" type="video/mp4" />
	</video>
</div>

<div class="nb-login-card middle-box text-center loginscreen animated fadeInDown nb-login-container">
	<div class="nb-login-colorblock"></div>
	<div class="bs-loading-container nb-login-maincontent">
		<div class="nb-logo-container">
			<img class="nb-loginlogo" src="/assets/img/umo_agencyportal.svg" alt="Unavailable" />
		</div>
		<div>
			<div [ngSwitch]="resetProcess">
				<div *ngSwitchCase="resetProcessEnum.passwordRequest">
					<reset-password-request (resetInitiated)="resetInitiated()"></reset-password-request>
				</div>
				<div *ngSwitchCase="resetProcessEnum.passwordRequestConfirm">
					<reset-password-confirm [confirmText]="'T_USER.PASSWORD_RESET_EMAIL_SENT'"></reset-password-confirm>
				</div>
				<div *ngSwitchCase="resetProcessEnum.passwordEntry">
					<reset-password-entry (passwordResetComplete)="passwordResetComplete()"></reset-password-entry>
				</div>
				<div *ngSwitchCase="resetProcessEnum.passwordConfirmResponse">
					<reset-password-confirm [confirmText]="'T_USER.PASSWORD_RESET_CONFIRM_RESPONSE'"></reset-password-confirm>
				</div>
			</div>
			<div class="nb-text return-to-login-button">
				<a (click)="returnToLogin()"
					><p>{{ 'T_USER.PASSWORD_RESET_RETURN_LOGIN' | translate }}</p></a
				>
			</div>
		</div>
		<div class="nb-login-colorblock nb-login-footer">
			<img src="/assets/img/poweredbycubic.svg" alt="Unavailable" />
		</div>
	</div>
</div>

<div class="grid-title">
	<route-pill class="report-viewer-grid-route-pill" [route]="routeData" [useFullWidth]="true"></route-pill>
	<div class="report-viewer-grid-route-descriptor">
		<span>{{ subListData.route_long_name }} {{ subListData.trip_headsign }}</span>
	</div>
</div>
<div class="nb-padding-top-lg">{{ subListData.stop_name }}</div>
<table class="adherence-by-route-direction-stop-sub-list-table">
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'vehicle_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'vehicle_id')"
				data-test="th.vehicle_id"
				class="ellipsis">
				{{ 'T_CORE.VEHICLE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'block_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'block_id')"
				data-test="th.block_id"
				class="ellipsis">
				{{ 'T_CORE.BLOCK' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'date' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'date')"
				data-test="th.date"
				class="mw-col ellipsis">
				{{ 'T_CORE.DATE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'timepoint' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'timepoint')"
				data-test="th.timepoint"
				class="mw-col ellipsis">
				{{ 'T_CORE.SCHEDULED' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'actual' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'actual')"
				data-test="th.actual"
				class="mw-col ellipsis">
				{{ 'T_CORE.ACTUAL' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'adherence' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'adherence', SortFieldType.timeDelta)"
				data-test="th.adherence"
				class="mw-col ellipsis">
				{{ 'T_CORE.ADHERENCE' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow" (click)="generateReport(reportDataItem)">
			<td data-test="td.vehicle_id" class="ellipsis">{{ reportDataItem.vehicle_id }}</td>
			<td data-test="td.block_id" class="ellipsis">{{ reportDataItem.block_id }}</td>
			<td data-test="td.date" class="ellipsis">{{ reportDataItem.date }}</td>
			<td data-test="td.timepoint" class="ellipsis">{{ reportDataItem.timepoint }}</td>
			<td data-test="td.actual" class="ellipsis">{{ reportDataItem.actual }}</td>
			<td data-test="td.adherence">
				<adherence-display
					[adherence]="reportDataItem.adherence"
					[adherenceOptions]="adherenceOptions"
					[authorityId]="authorityId"
					[agencyId]="agencyId">
				</adherence-display>
			</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

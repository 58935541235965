/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';

import { ReportsConfigService } from './reports-config.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { ObjectHelpers } from '@cubicNx/libs/utils';

import { UserLogin } from '../../../support-features/login/types/api-types';
import { Agency, AgencyFeature } from '../../../support-features/agencies/types/api-types';
import { ReportsDataService } from './reports-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportConfiguration, ReportDateOptionsConfiguration, ReportOptionsConfiguration, EntitySelectionType } from '../types/types';
import { ReportDateOptions, ReportOptions, ReportTemplateDetail, ReportRoute, ReportVehicle } from '../types/api-types';

import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class ReportsGenerationService {
	constructor(
		private reportsConfigService: ReportsConfigService,
		private currentUserUtilService: CurrentUserUtilService,
		private reportsDataService: ReportsDataService,
		private agenciesDataService: AgenciesDataService,
		private translationService: TranslationService
	) {}

	/**
	 * generates the route part of the report from the supplied data and then runs that said report
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param baseReportTemplateDetail - the base report template detail
	 * @param route - the route details
	 */
	public generateRouteReport = async (
		defaultTemplateId: string,
		baseReportTemplateDetail: ReportTemplateDetail,
		route: ReportRoute
	): Promise<void> => {
		const reportTemplateDetail: ReportTemplateDetail = this.prepareReport(defaultTemplateId, baseReportTemplateDetail);

		reportTemplateDetail.report_options.routes = [];
		reportTemplateDetail.report_options.routes.push(route);
		reportTemplateDetail.report_options.include_all_routes = 'false';
		reportTemplateDetail.report_options.include_all_vehicles = 'true';

		await this.reportsDataService.runReport(reportTemplateDetail);
	};

	/**
	 * generates the headway details route part of the report from the supplied data and then runs that said report
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param baseReportTemplateDetail - the base report template detail
	 * @param route - the route details
	 * @param stopId - the stop id
	 */
	public generateHeadwayDetailRouteReport = async (
		defaultTemplateId: string,
		baseReportTemplateDetail: ReportTemplateDetail,
		route: ReportRoute,
		stopId: string
	): Promise<void> => {
		const reportTemplateDetail: ReportTemplateDetail = this.prepareReport(defaultTemplateId, baseReportTemplateDetail);

		reportTemplateDetail.report_options.routes = [];
		reportTemplateDetail.report_options.routes.push(route);
		reportTemplateDetail.report_options.currentStop_id = stopId;
		reportTemplateDetail.report_options.include_all_routes = 'false';
		reportTemplateDetail.report_options.include_all_vehicles = 'true';

		await this.reportsDataService.runReport(reportTemplateDetail);
	};

	/**
	 * generates the vehicle part of the report from the supplied data and then runs that said report
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param baseReportTemplateDetail - the base report template detail
	 * @param vehicle - the vehicle
	 */
	public generateVehicleReport = async (
		defaultTemplateId: string,
		baseReportTemplateDetail: ReportTemplateDetail,
		vehicle: ReportVehicle
	): Promise<void> => {
		const reportTemplateDetail: ReportTemplateDetail = this.prepareReport(defaultTemplateId, baseReportTemplateDetail);

		reportTemplateDetail.report_options.vehicles = [];
		reportTemplateDetail.report_options.vehicles.push(vehicle);
		reportTemplateDetail.report_options.include_all_routes = 'true';
		reportTemplateDetail.report_options.include_all_vehicles = 'false';

		await this.reportsDataService.runReport(reportTemplateDetail);
	};

	/**
	 * generates the vehicles on date part of the report from the supplied data and then runs that said report
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param baseReportTemplateDetail - the base report template detail
	 * @param vehicle - the vehicle
	 * @param date - the report date
	 */
	public generateVehiclesOnDateReport = async (
		defaultTemplateId: string,
		baseReportTemplateDetail: ReportTemplateDetail,
		date: string,
		vehicle: ReportVehicle
	): Promise<void> => {
		const reportTemplateDetail: ReportTemplateDetail = this.prepareReport(defaultTemplateId, baseReportTemplateDetail);

		// ad-600 report can only be run for a single day
		reportTemplateDetail.date_options.effective_date_range_type = 'day';

		reportTemplateDetail.date_options.day = {
			effective_time_enabled: false,
			type: '3', // specific day
			// original functionality defaulted to this date if date empty - keeping for now
			effective_date_start: date || '2018-04-08 00:00:00',
		};

		reportTemplateDetail.report_options.vehicles = [];
		reportTemplateDetail.report_options.vehicles.push(vehicle);
		reportTemplateDetail.report_options.include_all_routes = 'false';
		reportTemplateDetail.report_options.include_all_vehicles = 'false';

		await this.reportsDataService.runReport(reportTemplateDetail);
	};

	/**
	 * creates a report template in readiness to be ran
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param baseReportTemplateDetail - the base report template detail
	 * @returns the prepared report template detail
	 */
	private prepareReport = (defaultTemplateId: string, baseReportTemplateDetail: ReportTemplateDetail): ReportTemplateDetail => {
		const reportConfig: ReportConfiguration = this.reportsConfigService.getReportConfiguration(defaultTemplateId);

		// get the basic report details using values from the configuration/default values for the report we are generating.
		// note: this will be will be different to the base report i.e an AD-100 generates an AD-200 for a particular route
		const reportTemplateDetail: ReportTemplateDetail = this.getDefaultReportData(
			reportConfig,
			baseReportTemplateDetail.report_options.agencies.nb_id
		);

		// override default report data with specific data from the base report (of which was used to generate this one).
		// deep copy required as otherwise we would be changing the date of the original reports when we determine the dates
		// etc for the generated report
		reportTemplateDetail.report_options = ObjectHelpers.defaults(
			ObjectHelpers.deepCopy(baseReportTemplateDetail.report_options),
			reportTemplateDetail.report_options
		);
		reportTemplateDetail.date_options = ObjectHelpers.defaults(
			ObjectHelpers.deepCopy(baseReportTemplateDetail.date_options),
			reportTemplateDetail.date_options
		);

		this.determineDates(reportTemplateDetail.date_options);

		return reportTemplateDetail;
	};

	/**
	 * determines the effective date values for the report data
	 *
	 * @param dateOptions - the report date options
	 */
	private determineDates = (dateOptions: ReportDateOptions): void => {
		const format: string = 'YYYY-MM-DD HH:mm:ss';

		if (dateOptions.effective_date_range_type === 'day' && dateOptions.day) {
			// if the base report was set to 'day' - we want to resuse the day from when the report was created (not the current day now).
			// Therefore override the day type to always be specific_date (type 3) and use the calculated start date which is the actual
			// datetime from the base report.
			dateOptions.day.type = '3';
			dateOptions.day.effective_date_start = moment(dateOptions.calculated_start_date).tz(dateOptions.tz).format(format);

			// required to get the dates in to format (lack of timezone that the backend expected). This needs a wider refactor
			// after analysis of the backend
			dateOptions.day.effective_time_start = moment(dateOptions.day.effective_time_start).format(format);
			dateOptions.day.effective_time_end = moment(dateOptions.day.effective_time_end).format(format);
		} else if (dateOptions.effective_date_range_type === 'week' || dateOptions.effective_date_range_type === 'month') {
			// if the base report was set to 'week' or 'month - we want to resuse the week/month from when the report was created (not the
			// current week/month).Therefore override the date range type to be the custom option (sent to back end as 'specific_date') and
			// set our date range based on the week or month from the base report
			// datetime from the base report.
			dateOptions.effective_date_range_type = 'specific_date';
			dateOptions.specific_date = {
				effective_date_start: moment(dateOptions.calculated_start_date).tz(dateOptions.tz).format(format),
				effective_date_end: moment(dateOptions.calculated_end_date).tz(dateOptions.tz).format(format),
				effective_time_enabled:
					(dateOptions.week && dateOptions.week.effective_time_enabled) ||
					(dateOptions.month && dateOptions.month.effective_time_enabled),
			};
		}
	};

	/**
	 * sets up a default report template detail instance from the supplied configuration
	 *
	 * @param reportConfig - the report configuration
	 * @param agencyNbId - the agency nextbus id
	 * @returns the default report template details instance
	 */
	private getDefaultReportData = (reportConfig: ReportConfiguration, agencyNbId: number): ReportTemplateDetail => {
		const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		const defaultData: ReportTemplateDetail = {
			default_template_id: reportConfig.defaultTemplateId,
			version: reportConfig.version,
			name: this.translationService.getTranslation(('T_REPORT.TEMPLATES.' + reportConfig.defaultTemplateId + '.name').toUpperCase()),
			code: reportConfig.code,
			description: this.translationService.getTranslation(
				('T_REPORT.TEMPLATES.' + reportConfig.defaultTemplateId + '.description').toUpperCase()
			),
			category: reportConfig.category,
			sharing: reportConfig.sharing,
			output_type: reportConfig.outputType,
			created_by_name: currentUser.profile.real_name,
			created_by_id: currentUser.nb_id,
			report_options: this.getDefaultReportOptions(reportConfig.reportOptions, agencyNbId),
			date_options: this.getDefaultDateOptions(reportConfig.dateOptions),
		};

		// apply agency's custom configured report title and description
		const agencyFeature: AgencyFeature = this.agenciesDataService.getAgencyFeature(defaultData.default_template_id);

		if (agencyFeature) {
			if (agencyFeature.parameters && agencyFeature.parameters.custom_name !== '') {
				defaultData.name = agencyFeature.parameters.custom_name;
			}

			if (agencyFeature.parameters && agencyFeature.parameters.custom_description !== '') {
				defaultData.description = agencyFeature.parameters.custom_description;
			}
		}

		return defaultData;
	};

	/**
	 * sets up a default report options instance from the supplied options configuration
	 *
	 * @param reportOptionsConfig - the report options configuration
	 * @param agencyNbId - the agency nextbus id
	 * @returns the default report options instance
	 */
	private getDefaultReportOptions = (reportOptionsConfig: ReportOptionsConfiguration, agencyNbId: number): ReportOptions => {
		// get the full agency details for ther additional properties we need

		// return agency based on nb_id rather than agency_nb_id as we do with other features as for some reason it's the nb_id
		// that is saved against a report/template this could be changed but we have to consider reports/template already on site.
		// Typically the 2 id's are the same anyway but noted they are different for SF Muni CIS (potentially others)
		const agency: Agency = this.agenciesDataService.getAgencyByNbId(agencyNbId);

		const reportOptions: ReportOptions = {
			authority: {
				authority_id: agency.authority_id,
			},
			agencies: {
				nb_id: agency.nb_id,
				authority_id: agency.authority_id,
				agency_id: agency.agency_id,
				agency_name: agency.agency_name,
				date_format: agency.date_format,
				time_format: agency.time_format,
			},
			excluded_fields: [],
			filter_arrival_stops: undefined,
			adherence_threshold_settings: undefined,
			adherence_threshold_override: undefined,
			include_all_routes: undefined,
			routes: undefined,
			include_all_vehicles: undefined,
			vehicles: undefined,
			block_selection: undefined,
			blocks: undefined,
		};

		// default values based on config - if routes are selected
		if (reportOptionsConfig.routes && reportOptionsConfig.routes.enabled) {
			if (reportOptionsConfig.routes.multiSelect) {
				reportOptions.routes = reportOptionsConfig.routes.multiSelect ? [] : null;
			}

			if (reportOptionsConfig.routes.selections.includes(EntitySelectionType.all)) {
				reportOptions.include_all_routes = 'true';
			} else {
				reportOptions.include_all_routes = 'false';
			}
		}

		if (reportOptionsConfig.vehicles && reportOptionsConfig.vehicles.enabled) {
			reportOptions.vehicles = reportOptionsConfig.vehicles.multiSelect ? [] : null;
			if (reportOptionsConfig.vehicles.selections.includes(EntitySelectionType.all)) {
				reportOptions.include_all_vehicles = 'true';
			} else {
				reportOptions.include_all_vehicles = 'false';
			}
		}

		if (reportOptionsConfig.blocks && reportOptionsConfig.blocks.enabled) {
			reportOptions.blocks = reportOptionsConfig.blocks.multiSelect ? [] : null;

			if (reportOptionsConfig.blocks.selections.includes(EntitySelectionType.all)) {
				reportOptions.block_selection = 'all';
			} else {
				reportOptions.block_selection = 'specific';
			}
		}

		if (reportOptionsConfig?.filterArrivalStops?.enabled) {
			reportOptions.filter_arrival_stops = 'false';
		}

		if (reportOptionsConfig?.adherenceThreshold?.enabled) {
			reportOptions.adherence_threshold_override = 'false';
		}

		if (reportOptionsConfig.sort?.enabled) {
			reportOptions.schedule_sort_selection = 'scheduleSortByBlock';
		}

		if (reportOptionsConfig.speed?.enabled) {
			reportOptions.speed_selection = 'mph';
		}

		return reportOptions;
	};

	/**
	 * sets up a default date options instance from the supplied options configuration
	 *
	 * @param reportDateOptionsConfig - the report date options configuration
	 * @returns the default report date options instance
	 */
	private getDefaultDateOptions = (reportDateOptionsConfig: ReportDateOptionsConfiguration): ReportDateOptions => {
		let dateOptions: ReportDateOptions;

		if (!reportDateOptionsConfig.disabled) {
			if (reportDateOptionsConfig.dateRangeTypes.dayName.enabled) {
				dateOptions = {
					relative_date_type: 'now',
					effective_date_range_type: 'day_name_value',
					day_name_value: {
						type: '0', // "0" == "all", "1" == "weekdays", "2" == "saturday", 3 = "sunday"
						effective_time_enabled: false,
					},
				};
			} else {
				dateOptions = {
					relative_date_type: 'now',
					effective_date_range_type: 'day',
					day: {
						type: '0', // "0" == "today", "1" == "yesterday", "2" == "custom"
						effective_time_enabled: false,
					},
					week: {
						type: '0',
						effective_time_enabled: false,
					},
					month: {
						type: '0',
						effective_time_enabled: false,
					},
				};
			}
		}

		return dateOptions;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { ModalMode } from '../dashboard-format-modal/dashboard-format-modal.component';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'dashboard-toolbar',
	templateUrl: './dashboard-toolbar.component.html',
	styleUrls: ['./dashboard-toolbar.component.scss'],
})
export class DashboardToolbarComponent extends TranslateBaseComponent {
	@Input() dashboardOptions: any;
	@Input() editMode: any;

	@Output() editDashboardClicked: EventEmitter<ModalMode> = new EventEmitter<ModalMode>();
	@Output() deleteDashboardClicked: EventEmitter<void> = new EventEmitter<void>();

	public override translations: { [key: string]: string } = {};

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * Fires editDashboardClicked event.
	 */
	public editDashboard = (): void => {
		this.editDashboardClicked.emit(ModalMode.edit);
	};

	/**
	 * Fires deleteDashboardClicked event.
	 */
	public deleteDashboard = (): void => {
		this.deleteDashboardClicked.emit();
	};
}

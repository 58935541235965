/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { VehicleEventDetailsHeaderComponent } from './details/vehicle-event-details-header.component';
import { VehicleEventSettingsHeaderComponent } from './settings/vehicle-event-settings-header.component';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { CurrentUserPermissionService } from '../../../support-features/login/services/current-user/current-user-permission.service';
import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { VehicleSummaries } from '../../../support-features/vehicles/types/api-types';
import { VehicleEventsService } from '../../../features/vehicle-events/services/vehicle-events.service';
import { VehicleEventsDataService } from '../../../features/vehicle-events/services/vehicle-events-data.service';
import { VehicleEventsUtilService } from '../../../features/vehicle-events/services/vehicle-events-util.service';
import { VehicleEventsAudioService } from './services/vehicle-events-audio.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { VehicleEvent, VehicleEventDetails } from '../../../features/vehicle-events/types/api-types';
import { CurrentUserAgencies } from '../../../support-features/agencies/types/types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	defaultCategoryFilterOptions,
	defaultIncludedEventTypeIds,
	defaultSort,
	defaultVehicleFilter,
	VehicleEventNotification,
	VehicleEventNotifications,
	VehicleEventsSettings,
	VehicleEventsUserSettings,
	VehicleEventTypes,
} from '../../../features/vehicle-events/types/types';

import moment from 'moment';

@Component({
	selector: 'vehicle-events',
	templateUrl: './vehicle-events.component.html',
	styleUrls: ['./vehicle-events.component.scss'],
})
export class VehicleEventsComponent extends TranslateBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(VehicleEventDetailsHeaderComponent) vehicleEventsDetails: VehicleEventDetailsHeaderComponent;
	@ViewChild(VehicleEventSettingsHeaderComponent) vehicleEventsSettings: VehicleEventSettingsHeaderComponent;

	public activeAlarmNum: number = 0;
	public showSettings: boolean = false;
	public isVisible: boolean = false;
	public editableSettings: VehicleEventsSettings = null;
	public liveEventsPlay: boolean = false;
	public settings: VehicleEventsSettings = null;

	private timezone: string = null;
	private vehicles: VehicleSummaries = [];
	private vehicleEventNotifications: VehicleEventNotifications = [];
	private eventTypes: VehicleEventTypes = [];
	private lastUpdated: number = 0;
	private closeVehicleEventsList$Subscription: Subscription = null;
	private eventListTimer: any = null;
	private eventAudioTimer: any = null;

	private readonly refreshRateMillis: number = 10000;
	private readonly refreshAudioRate: number = 5000;

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private vehiclesDataService: VehiclesDataService,
		private vehicleEventsService: VehicleEventsService,
		private vehicleEventsDataService: VehicleEventsDataService,
		private vehicleEventsUtilService: VehicleEventsUtilService,
		private vehicleEventsAudioService: VehicleEventsAudioService,
		private agenciesDataService: AgenciesDataService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the header vehicle events details view
	 *
	 * sets up subscriptions, initializes settings for the view
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		// We need to init the audio service to create the audio context for playing audio for notifications
		// The audio context however can only be started following a user gesture due to broswer restrictions.
		// Create a click handler for the window to capture a 'user gensture' to init the audio handler
		window.addEventListener('click', this.eventUserGestureClick);

		// init the settings before other awaits so they are initialised for child components
		await this.initSettings();
	}

	/**
	 * handles initializes actions once the page has rendered
	 *
	 * intializes timer to check for vehicle events
	 */
	public async ngAfterViewInit(): Promise<void> {
		// initialize from here once child detail component has rendered
		this.initTimers();
	}

	/**
	 * shows the vehicle events settings view
	 */
	public openSettings = async (): Promise<void> => {
		this.showSettings = true;
		this.liveEventsPlay = false;
	};

	/**
	 * closes the vehicle event settings view
	 *
	 * saves any settings that have been updated
	 *
	 * @param updatedSettings - the updated vehicle event settings
	 */
	public closeSettings = async (updatedSettings: VehicleEventsSettings): Promise<void> => {
		this.showSettings = false;

		// handle updated settings from setting component
		if (updatedSettings) {
			let agencyChanged: boolean = false;

			if (updatedSettings.selectedAgency.authority_id !== this.settings.selectedAgency.authority_id) {
				agencyChanged = true;
			}

			// asign the updated settings to our settings after the change check above
			this.settings = ObjectHelpers.deepCopy(updatedSettings);

			// let the child component pickup setting changes
			this.changeDetectorRef.detectChanges();

			// get the agency dependent data (now our settings have been updated)
			if (agencyChanged) {
				await this.getAgencyInfo();

				await this.vehicleEventsDetails.init();
			}

			this.saveUserSettings();

			this.resetEventList();

			await this.getVehicleEventNotifications();

			if (this.isVisible) {
				await this.vehicleEventsDetails.getVehicleEvents();
			}
		} else {
			// settings has been closed - revert settings page to reset anything modified
			this.resetEditableSettings();
		}
	};

	/**
	 * pauses or resumes the viewing of live vehicle events
	 */
	public toggleLiveEvents = async (): Promise<void> => {
		this.liveEventsPlay = !this.liveEventsPlay;
		if (this.liveEventsPlay) {
			await this.vehicleEventsDetails.resetCurrentPage();
			await this.vehicleEventsDetails.getVehicleEvents();
		}
	};

	/**
	 * closes the vehicle events view
	 */
	public closeVehicleEvents = (): void => {
		if (this.showSettings) {
			// settings has been closed - revert settings page to reset anything modified
			this.resetEditableSettings();
			this.showSettings = false;
		}

		this.isVisible = false;
	};

	/**
	 * general clean up activities such as removing subscriptions, cancelling timers when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.saveUserSettings();
		this.unsubscribe();
		this.cancelTimers();
	}

	/**
	 * saves the vehicle event user settings
	 */
	public saveUserSettings = (): void => {
		const vehicleEventsUserSettings: VehicleEventsUserSettings = {
			categoryFilterOptions: this.settings.categoryFilterOptions,
			filteredAgencyForVehicleEvents: this.settings.selectedAgency.authority_id,
			filteredAgencyDetails: this.settings.selectedAgency,
			sortBy: this.settings.sortBy,
			includedEventTypeIds: this.settings.includedEventTypeIds,
			vehicleFilter: this.settings.vehicleFilterSelection.value,
			selectedVehicles: this.settings.selectedVehicles.vehicles,
			selectedRoutes: this.settings.selectedRoutes.routes,
			selectedDepots: this.settings.selectedDepots.depots,
		};

		this.currentUserUtilService.saveVehicleEventSettings(vehicleEventsUserSettings);
	};

	/**
	 * opens or closes the vehicle events view
	 *
	 * @returns the open/close response
	 */
	public toggleVehicleEvents = (): any => {
		if (this.isVisible) {
			return this.closeVehicleEvents();
		}

		return this.openVehicleEvents();
	};

	/**
	 * initializes the vehicle event settings view
	 *
	 * sets up defaults and customizes with user specific settings
	 */
	private initSettings = async (): Promise<void> => {
		this.settings = {
			selectedAgency: null,
			categoryFilterOptions: defaultCategoryFilterOptions,
			sortBy: defaultSort,
			includedEventTypeIds: defaultIncludedEventTypeIds,
			selectedVehicles: { vehicles: [] },
			selectedDepots: { depots: [] },
			selectedRoutes: { routes: [] },
			vehicleFilterSelection: { value: defaultVehicleFilter },
		};

		const userSettings: VehicleEventsUserSettings = this.currentUserUtilService.getEventNotificationSettings();

		if (userSettings) {
			if (userSettings.categoryFilterOptions) {
				this.settings.categoryFilterOptions = ObjectHelpers.deepCopy(userSettings.categoryFilterOptions);
			}

			if (userSettings.sortBy) {
				this.settings.sortBy = userSettings.sortBy;
			}

			if (userSettings.includedEventTypeIds) {
				this.settings.includedEventTypeIds = [...userSettings.includedEventTypeIds];
			}

			if (userSettings.filteredAgencyDetails) {
				// Check if the user still has access to the agency. We're only passing in userSettings.filteredAgencyDetails
				// because it's the only agency we care about.
				const userAgencies: CurrentUserAgencies = this.agenciesDataService.getCurrentUserAgencies([
					userSettings.filteredAgencyDetails,
				]);

				if (
					this.currentUserPermissionService.isNextbusAdmin() ||
					userAgencies.some((a) => a.authorityId === userSettings.filteredAgencyForVehicleEvents)
				) {
					this.settings.selectedAgency = userSettings.filteredAgencyDetails;
				}
			}

			if (userSettings.vehicleFilter) {
				this.settings.vehicleFilterSelection.value = userSettings.vehicleFilter;
			}

			if (Array.isArray(userSettings.selectedVehicles)) {
				this.settings.selectedVehicles = { vehicles: [...userSettings.selectedVehicles] };
			}

			if (Array.isArray(userSettings.selectedRoutes)) {
				this.settings.selectedRoutes = { routes: [...userSettings.selectedRoutes] };
			}

			if (Array.isArray(userSettings.selectedDepots)) {
				this.settings.selectedDepots = { depots: [...userSettings.selectedDepots] };
			}
		}

		if (this.settings.selectedAgency === null) {
			this.settings.selectedAgency = this.agenciesDataService.getDefaultAgency();
		}

		// make a copy of the settings for comparison following potential changes from child settings component
		this.editableSettings = ObjectHelpers.deepCopy(this.settings);

		await this.getAgencyInfo();
	};

	/**
	 * initializes the component timers
	 *
	 * list poller and audio playing poller
	 */
	private initTimers = async (): Promise<void> => {
		this.eventListTimer = setInterval(this.timerEvent, this.refreshRateMillis);

		this.eventAudioTimer = setInterval(this.playAudioForEventNotificationsEvent, this.refreshAudioRate);

		await this.vehicleEventsDetails.getVehicleEvents();
		await this.getVehicleEventNotifications();
	};

	/**
	 * plays the audio representing a notification of a vehicle event
	 */
	private playAudioForEventNotificationsEvent = (): void => {
		// send the params each time, even settings/event types as they will change depending on the agency
		this.vehicleEventsAudioService.playAudioForEventNotifications(this.settings, this.eventTypes, this.vehicleEventNotifications);
	};

	/**
	 * poll for vehicle event notifications
	 */
	private timerEvent = async (): Promise<void> => {
		await this.getVehicleEventNotifications();
		if (this.liveEventsPlay) {
			await this.vehicleEventsDetails.getVehicleEvents();
		}
	};

	/**
	 * opens the vehicle events view
	 */
	private openVehicleEvents = async (): Promise<void> => {
		this.isVisible = true;
		this.showSettings = false;

		await this.vehicleEventsDetails.resetCurrentPage();
		await this.vehicleEventsDetails.getVehicleEvents();
	};

	/**
	 * retrieves recent vehicle event notifications
	 *
	 * get the vehicle events to workout the notification count.  First filter any that have expired since the previous
	 * request and remove any that don't pass our filter. This is required as future request only get the events since
	 * the last request (i.e in batches - see lastUpdated).  For the most part we are only returning the events to get
	 * the alarm count so there is a strong argument to only request alarms if selected (and not events/alerts) but there
	 * is code to play audio if configured for each event type (not sure if this audio code is redundant)
	 */
	private getVehicleEventNotifications = async (): Promise<void> => {
		let countAlarm: number = 0;
		const nowTime: number = moment.utc().unix();
		let vehicleIds: string[] = [];
		let routeIds: string[] = [];

		this.vehicleEventNotifications = this.vehicleEventNotifications.filter((notification: VehicleEventNotification) => {
			let keepEvent: boolean = true;

			if (notification.expiryUtc < nowTime) {
				keepEvent = false;
			} else if (this.settings.categoryFilterOptions.alarm === true && notification.vehicleEvent.category === 'alarm') {
				const foundRes: number = this.settings.includedEventTypeIds.find(
					(type) => type === notification.vehicleEvent.event_type_id
				);

				if (foundRes !== undefined) {
					countAlarm++;
				}
			}

			return keepEvent;
		});

		if (
			(this.settings.includedEventTypeIds.length !== 0 && this.settings.categoryFilterOptions.alarm) ||
			this.settings.categoryFilterOptions.alert ||
			this.settings.categoryFilterOptions.event
		) {
			if (this.vehicles) {
				vehicleIds = this.vehicleEventsUtilService.getFilteredVehicles(this.settings, this.vehicles);
			}

			routeIds = this.vehicleEventsUtilService.getFilteredRoutes(this.settings);

			this.activeAlarmNum = countAlarm;

			// either we have selected all vehicles or we must have a valid route list of vehicle list following our filters
			// if not then the request wouldn't return any data so don't bother
			if (this.settings.vehicleFilterSelection.value === 'all' || vehicleIds.length > 0 || routeIds.length > 0) {
				const response: ResultContent = await this.vehicleEventsDataService.getVehicleEvents(
					this.settings.selectedAgency.authority_id,
					this.settings.includedEventTypeIds,
					vehicleIds,
					null,
					null,
					this.lastUpdated,
					null,
					this.settings.categoryFilterOptions,
					null,
					null,
					routeIds
				);

				if (response.success) {
					const events: VehicleEventDetails = response.resultData;

					if (events && events.rows) {
						const newVehicleEventNotifications: VehicleEventNotifications = events.rows.map((event: VehicleEvent) => ({
							vehicleEvent: event,
							timezone: this.timezone,
							createdAtUtc: moment.utc(event.created_at).valueOf() / 1000,
							expiryUtc: moment.utc(event.expiry).valueOf() / 1000,
							playAudio: true,
							lastPlayed: null,
						}));

						if (newVehicleEventNotifications.length > 0) {
							newVehicleEventNotifications.forEach((notification: VehicleEventNotification) => {
								this.vehicleEventNotifications.push(notification);

								if (notification.createdAtUtc > this.lastUpdated) {
									this.lastUpdated = notification.createdAtUtc + 1;
								}

								if (this.settings.categoryFilterOptions.alarm === true && notification.vehicleEvent.category === 'alarm') {
									const foundRes: number = this.settings.includedEventTypeIds.find(
										(type) => type === notification.vehicleEvent.event_type_id
									);

									if (foundRes !== undefined) {
										countAlarm++;
									}
								}
							});
						}
					}
				}

				this.activeAlarmNum = countAlarm;
			} else {
				this.resetEventList();
			}
		} else {
			this.resetEventList();
		}
	};

	/**
	 * resets the vehicle events view
	 */
	private resetEventList = async (): Promise<void> => {
		this.vehicleEventNotifications = [];
		this.lastUpdated = 0;
		this.activeAlarmNum = 0;
	};

	/**
	 * retrieves agency information
	 */
	private getAgencyInfo = async (): Promise<void> => {
		this.timezone = this.agenciesDataService.getAgencyTimezone(
			this.settings.selectedAgency.authority_id,
			this.settings.selectedAgency.agency_id
		);
		this.eventTypes = await this.getVehicleEventTypes();
		this.vehicles = await this.getVehicles();
	};

	/**
	 * retrieves vehicle summaries
	 *
	 * @returns vehicle summaries
	 */
	private getVehicles = async (): Promise<VehicleSummaries> => {
		const response: ResultContent = await this.vehiclesDataService.getVehicles(this.settings.selectedAgency.authority_id);

		if (response.success) {
			return response.resultData as VehicleSummaries;
		}

		return null;
	};

	/**
	 * retrieves vehicle event types
	 *
	 * @returns vehicle event types
	 */
	private getVehicleEventTypes = async (): Promise<VehicleEventTypes> => {
		const response: ResultContent = await this.vehicleEventsDataService.getVehicleEventTypes(this.settings.selectedAgency.authority_id);

		if (response.success) {
			const eventTypes: VehicleEventTypes = response.resultData;

			if (!eventTypes) {
				return [];
			}

			return eventTypes;
		}

		return [];
	};

	/**
	 * resets the editable vehicle event settings
	 */
	private resetEditableSettings = (): void => {
		// reset our editable version of the settings
		this.editableSettings = ObjectHelpers.deepCopy(this.settings);

		// let the child component pickup setting changes
		this.changeDetectorRef.detectChanges();

		// revert any settings changed in the settings component
		this.vehicleEventsSettings.reset(this.settings.selectedAgency.authority_id);
	};

	/**
	 * initializes the audio service and removes the audio instance
	 */
	private eventUserGestureClick = async (): Promise<void> => {
		this.vehicleEventsAudioService.init();

		// now we've used the click handler workaround to initialise the audio object - remove it
		window.removeEventListener('click', this.eventUserGestureClick);
	};

	/**
	 * sets up subscriptions
	 *
	 * is interested in closing of the vehicle events list
	 */
	private setSubscriptions = (): void => {
		this.closeVehicleEventsList$Subscription = this.vehicleEventsService.closeVehicleEventsList.subscribe(() => {
			this.closeVehicleEvents();
		});
	};

	/**
	 * unsubscribes the subscriptions
	 */
	private unsubscribe = (): void => {
		this.closeVehicleEventsList$Subscription?.unsubscribe();
	};

	/**
	 * cancels the polling timers
	 */
	private cancelTimers = (): void => {
		if (this.eventListTimer) {
			clearInterval(this.eventListTimer);
		}

		if (this.eventAudioTimer) {
			clearInterval(this.eventAudioTimer);
		}
	};
}

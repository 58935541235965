<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<div *ngFor="let reportItem of listData; let reportIndex = index">
					<div class="grid-title">
						<route-pill
							class="report-viewer-grid-route-pill"
							[route]="determineRoutePillData(reportItem)"
							[useFullWidth]="true"></route-pill>
						<span class="rider-count-by-route-by-stop-list-title">
							{{ reportItem.route_long_name }}
						</span>
					</div>
					<rc200-rider-count-by-route-by-stop-sub-list
						[id]="reportIndex"
						[subListData]="reportItem"
						[defaultTemplateId]="defaultTemplateId"
						(pageSizeChanged)="scrollIntoView(reportIndex.toString())">
					</rc200-rider-count-by-route-by-stop-sub-list>
				</div>
				<br />
				<table>
					<thead>
						<tr>
							<th class="mw-col ellipsis"></th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.BOARDINGS' | translate }}</th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.ALIGHTINGS' | translate }}</th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.MAX_LOAD_TOTAL' | translate }}</th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.MAX_LOAD_PCT_TOTAL' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let totalItem of totalsData">
							<td class="ellipsis bold">{{ 'T_REPORT.TOTALS' | translate }}</td>
							<td class="ellipsis">{{ totalItem.boardings }}</td>
							<td class="ellipsis">{{ totalItem.alightings }}</td>
							<td class="ellipsis">{{ totalItem.max_load }}</td>
							<td class="ellipsis">{{ (totalItem.max_load_pct * 100).toFixed() + '%' }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { UsersAdminDataService } from '../../services/users-admin-data.service';
import { UsersAdminEventsService } from '../../services/users-admin-events.service';
import { UsersAdminModalService } from '../../services/users-admin-modal.service';
import { CurrentUserUtilService } from '../../../../../support-features/login/services/current-user/current-user-utils.service';
import { CurrentUserPermissionService } from '../../../../../support-features/login/services/current-user/current-user-permission.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { UsersAdminUtilService } from '../../services/users-admin-utils.service';

import { Columns, ColumnType } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../../../utils/components/breadcrumbs/types/types';
import { PasswordExpiry, PermissionRequestActionType } from '../../../../../support-features/login/types/types';
import { ResultContent, ViewPanelSource } from '@cubicNx/libs/utils';
import { ActivityNotes, UserDetail } from '../../types/api-types';
import { UserLogin } from '../../../../../support-features/login/types/api-types';

import {
	AgencyRole,
	AuthorityAgencyRole,
	AuthorityAgencyRoles,
	FormattedRole,
	FormattedRoles,
	FormattedRolesGroup,
	UserDetailDisplay,
} from '../../types/types';

@Component({
	selector: 'user-admin-profile',
	templateUrl: './user-admin-profile.component.html',
	styleUrls: ['./user-admin-profile.component.scss'],
})
export class UserAdminProfileComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public activityNotesListColumns: Columns = [];
	public activityNotesListData: ActivityNotes = [];
	public activityNotesLoadingIndicator: boolean = true;

	public hideActivityList: boolean = false;
	public selectedUserDisplay: UserDetailDisplay = null;
	public changePasswordAllowed: boolean = false;
	public resetPasswordAllowed: boolean = false;
	public authorityAgencyRoles: AuthorityAgencyRoles = null;
	public detailViewTableOptions: ViewPanelSource;
	public detailViewTableUserStatus: ViewPanelSource;

	public isStatusDeactivated: boolean = false;
	public isStatusLocked: boolean = false;
	public disableResetAndEdit: boolean = true;
	public disableReset: boolean = true;
	public isOwnProfile: boolean = false;
	public initialized: boolean = false;
	public loading: boolean = true;
	public breadcrumbItems: BreadcrumbItems = [];
	public isPasswordAdminUser: boolean = false;

	private userId: string = null;
	private selectedUser: UserDetail = null;
	private routeParams$Subscription: Subscription = null;
	private passwordChange$Subscription: Subscription = null;

	constructor(
		private usersAdminDataService: UsersAdminDataService,
		private usersAdminUtilService: UsersAdminUtilService,
		private usersAdminModalService: UsersAdminModalService,
		private usersAdminEventsService: UsersAdminEventsService,
		private currentUserUtilService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the user profile view
	 */
	public async ngOnInit(): Promise<void> {
		// get params off the url
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.userId = params['userId'];

			await this.loadUser(this.userId);
		});

		await this.initTranslations([
			'T_USER.DAYS',
			'T_USER.TOMORROW',
			'T_CORE.TODAY',
			'T_USER.EXPIRED',
			'T_USER.USER_DEACTIVATE_CONFIRM',
			'T_USER.USER_DEACTIVATE_CONFIRM_HEADING',
			'T_USER.USER_DEACTIVATE_SUCCESS',
			'T_USER.USER_ENABLE_CONFIRM',
			'T_USER.USER_ENABLE_CONFIRM_HEADING',
			'T_USER.USER_ENABLE_SUCCESS',
			'T_USER.USER_UNLOCK_CONFIRM',
			'T_USER.USER_UNLOCK_CONFIRM_HEADING',
			'T_USER.USER_UNLOCK_SUCCESS',
			'T_USER.USER_STATUS_UPDATE_ERROR',
			'T_USER.NOTE',
			'T_USER.ENTERED_BY',
			'T_USER.ENTERED_AT',
			'T_USER.USER_STATUS.NEW',
			'T_USER.USER_STATUS.ACTIVE',
			'T_USER.USER_STATUS.INACTIVE',
			'T_USER.USER_STATUS.PASSWORDEXPIRED',
			'T_USER.USER_STATUS.DEACTIVATED',
			'T_USER.USER_STATUS.LOCKED',
			'T_USER.USER_PROFILE',
			'T_CORE.NEXTBUS',
			'T_CORE.ADMIN_TOOLS',
			'T_CORE.USERS',
			'T_USER.ACCOUNT_ENABLED',
			'T_USER.ACCOUNT_DEACTIVATED',
			'T_USER.ACCOUNT_UNLOCKED',
			'T_CORE.PASSWORD_CHANGED',
		]);

		this.initBreadcrumbs();

		this.setSubscriptions();

		this.hideActivityList = !this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.viewUserList, {});

		// initialize the state of password buttons
		this.resetPasswordAllowed = false;
		this.changePasswordAllowed = false;

		this.detailViewTableOptions = {
			rowDefs: [
				{
					displayName: 'T_CORE.NAME',
					name: 'realName',
					hidden: false,
				},
				{
					displayName: 'T_USER.USER_NAME',
					name: 'username',
				},
				{
					displayName: 'T_USER.COMPANY_NAME',
					name: 'orgName',
				},
				{
					displayName: 'T_USER.WORK_PHONE',
					name: 'phone',
				},
				{
					displayName: 'T_USER.MOBILE_PHONE',
					name: 'smsNumber',
				},
				{
					displayName: 'T_USER.WORK_EMAIL',
					name: 'email',
				},
				{
					displayName: 'T_USER.WORK_ADDRESS',
					name: 'address',
				},
				{
					displayName: 'T_USER.WORK_ADDRESS_2',
					name: 'address2',
				},
				{
					displayName: 'T_USER.WORK_CITY',
					name: 'city',
				},
				{
					displayName: 'T_USER.STATE_PROVINCE_REGION',
					name: 'stateProvinceRegion',
				},
				{
					displayName: 'T_USER.POSTAL_CODE',
					name: 'postalCode',
				},
				{
					displayName: 'T_USER.COUNTRY',
					name: 'country',
				},
			],
		};

		this.detailViewTableUserStatus = {
			rowDefs: [
				{
					displayName: 'T_CORE.CREATED',
					name: 'created',
					type: 'date',
					dateFormat: 'MM/dd/yyyy hh:mm a',
				},
				{
					displayName: 'T_CORE.LAST_LOGIN',
					name: 'lastLogin',
					type: 'date',
					dateFormat: 'MM/dd/yyyy hh:mm a',
				},
				{
					displayName: 'T_CORE.PASSWORD_CHANGED',
					name: 'passwordLastChangedDate',
					type: 'date',
					dateFormat: 'MM/dd/yyyy hh:mm a',
				},
				{
					displayName: 'T_USER.LOCKED_DATE',
					name: 'lockDate',
					type: 'date',
					dateFormat: 'MM/dd/yyyy hh:mm a',
				},
				{
					displayName: 'T_USER.DEACTIVATED_DATE',
					name: 'deactivatedDate',
					type: 'date',
					dateFormat: 'MM/dd/yyyy hh:mm a',
				},
				{
					displayName: 'T_CORE.STATUS',
					name: 'status',
					convert: (value): string => this.translations['T_USER.USER_STATUS.' + value.toUpperCase()],
				},
				{
					displayName: 'T_USER.EXPIRES',
					name: 'expiringIn',
					type: 'autoHideString',
					hidden: false,
				},
			],
		};

		this.buildActivityListColumns();

		if (this.currentUserPermissionService.isPWAdmin()) {
			this.isPasswordAdminUser = true;
		}
	}

	/**
	 * resets the user password
	 */
	public resetPassword = (): void => {
		this.usersAdminModalService.resetPassword(this.selectedUser);
	};

	/**
	 * changes the user password
	 */
	public changePassword = (): void => {
		this.usersAdminModalService.changePassword(this.selectedUser);
	};

	/**
	 * deactivates a user via a dialog that needs to be confirmed
	 */
	public deactivateUser = async (): Promise<void> => {
		const message: string = this.translations['T_USER.USER_DEACTIVATE_CONFIRM'];
		const header: string = this.translations['T_USER.USER_DEACTIVATE_CONFIRM_HEADING'];
		const successMsg: string = this.translations['T_USER.USER_DEACTIVATE_SUCCESS'];
		const failureMsg: string = this.translations['T_USER.USER_STATUS_UPDATE_ERROR'];

		this.usersAdminModalService
			.confirmStatusChange(this.selectedUser, 'Deactivated', 'ACCOUNT_DEACTIVATED', message, header, successMsg, failureMsg)
			.subscribe((updated: boolean) => {
				if (updated) {
					this.loadUser(this.userId);
				}
			});
	};

	/**
	 * enables a user via a dialog that needs to be confirmed
	 */
	public enableUser = async (): Promise<void> => {
		const message: string = this.translations['T_USER.USER_ENABLE_CONFIRM'];
		const header: string = this.translations['T_USER.USER_ENABLE_CONFIRM_HEADING'];
		const successMsg: string = this.translations['T_USER.USER_ENABLE_SUCCESS'];
		const failureMsg: string = this.translations['T_USER.USER_STATUS_UPDATE_ERROR'];

		this.usersAdminModalService
			.confirmStatusChange(this.selectedUser, 'Active', 'ACCOUNT_ENABLED', message, header, successMsg, failureMsg)
			.subscribe((updated: boolean) => {
				if (updated) {
					this.loadUser(this.userId);
				}
			});
	};

	/**
	 * unlocks a user via a dialog that needs to be confirmed
	 */
	public unlockUser = async (): Promise<void> => {
		const message: string = this.translations['T_USER.USER_UNLOCK_CONFIRM'];
		const header: string = this.translations['T_USER.USER_UNLOCK_CONFIRM_HEADING'];
		const successMsg: string = this.translations['T_USER.USER_UNLOCK_SUCCESS'];
		const failureMsg: string = this.translations['T_USER.USER_STATUS_UPDATE_ERROR'];

		this.usersAdminModalService
			.confirmStatusChange(this.selectedUser, 'Active', 'ACCOUNT_UNLOCKED', message, header, successMsg, failureMsg)
			.subscribe((updated: boolean) => {
				if (updated) {
					this.loadUser(this.userId);
				}
			});
	};

	/**
	 * enables a user via a dialog that needs to be confirmed
	 */
	public edit = (): void => {
		const selectedUser: UserDetail = ObjectHelpers.deepCopy(this.selectedUser);

		this.usersAdminModalService.openUserEditor(selectedUser, false).subscribe((saved: boolean) => {
			if (saved) {
				this.loadUser(this.userId);
			}
		});
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * initializes the views breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_CORE.ADMIN_TOOLS'], targetPath: '/admintools', activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_CORE.USERS'], targetPath: '/admintools/users', activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_USER.USER_PROFILE'], activePage: true });
	};

	/**
	 * builds the activity notes columns
	 */
	private buildActivityListColumns = (): void => {
		// build the column list for the underlying datatable.
		// note: the each name must correspond to a value to our row data passed in (disablePredictionFilteredTableData in this case)
		this.activityNotesListColumns = [
			{
				name: 'note',
				displayName: this.translations['T_USER.NOTE'],
				columnType: ColumnType.subText,
				subText: 'routeSubText',
			},
			{
				name: 'entered_by',
				displayName: this.translations['T_USER.ENTERED_BY'],
				columnType: ColumnType.text,
			},
			{
				name: 'entered_at',
				displayName: this.translations['T_USER.ENTERED_AT'],
				columnType: ColumnType.date,
				format: 'MM/dd/YYYY hh:mm a',
			},
		];
	};

	/**
	 * retrieves user details and populates the form
	 *
	 * @param id - user id
	 */
	private loadUser = async (id: string): Promise<void> => {
		this.loading = true;

		const getUserResult: ResultContent = await this.usersAdminDataService.getUser(id);

		if (getUserResult.success) {
			this.selectedUser = getUserResult.resultData;

			this.selectedUserDisplay = {
				nbId: this.selectedUser.nb_id,
				realName: this.selectedUser.real_name,
				username: this.selectedUser.username,
				smsNumber: this.selectedUser.sms_number,
				email: this.selectedUser.email,
				orgName: this.selectedUser.user_profile.org_name,
				phone: this.selectedUser.user_profile.phone,
				address: this.selectedUser.user_profile.address,
				address2: this.selectedUser.user_profile.address_2,
				city: this.selectedUser.user_profile.city,
				stateProvinceRegion: this.selectedUser.user_profile.state_province_region,
				postalCode: this.selectedUser.user_profile.postal_code,
				country: this.selectedUser.user_profile.country,
				created: this.selectedUser.created,
				lastLogin: this.selectedUser.last_login,
				passwordLastChangedDate: this.selectedUser.password_last_changed_date,
				lockDate: this.selectedUser.lock_date,
				deactivatedDate: this.selectedUser.deactivated_date,
				status: this.selectedUser.status,
				expiringIn: this.determineWarnPasswordExpire(),
			};

			const loggedInUser: UserLogin = this.currentUserUtilService.getCurrentUser();

			this.isOwnProfile = loggedInUser.nb_id === this.selectedUser.nb_id;

			this.disableResetAndEdit = this.isPasswordAdminUser
				? true
				: this.selectedUser.status !== 'New' &&
					this.selectedUser.status !== 'Inactive' &&
					this.selectedUser.status !== 'Active' &&
					this.selectedUser.status !== 'PasswordExpired';

			this.disableReset =
				this.selectedUser.status !== 'New' &&
				this.selectedUser.status !== 'Inactive' &&
				this.selectedUser.status !== 'Active' &&
				this.selectedUser.status !== 'PasswordExpired';

			this.isStatusDeactivated = this.selectedUser.status === 'Deactivated';
			this.isStatusLocked = this.selectedUser.status === 'Locked';

			// set the state of the password buttons
			this.initAllowedActions();

			this.initRoles();

			if (!this.hideActivityList) {
				const result: ResultContent = await this.usersAdminDataService.getUserActivityNotes(id);

				if (result.success) {
					this.activityNotesListData = result.resultData;

					this.activityNotesListData.forEach((activity) => {
						activity.note = this.getTranslation('T_USER.' + activity.note);
					});

					this.activityNotesLoadingIndicator = false;
				}
			}

			this.initialized = true;
		}

		this.loading = false;
	};

	/**
	 * initialize the roles
	 */
	private initRoles = (): void => {
		// organize the user role data, grouping by agencies within authorities
		this.authorityAgencyRoles = {
			UmoIQ: null,
		};

		const formattedRolesGroup: FormattedRolesGroup = this.usersAdminUtilService.formatUserRoles(this.selectedUser.user_roles);

		const masterRoles: FormattedRoles = formattedRolesGroup.master || [];
		const userRoles: FormattedRoles = formattedRolesGroup.roles || [];

		// first get the master nextbus roles
		masterRoles.forEach((masterRole: FormattedRole) => {
			let master: AuthorityAgencyRole = this.authorityAgencyRoles.UmoIQ;

			if (!master) {
				master = this.authorityAgencyRoles.UmoIQ = {
					authorityName: this.translations['T_CORE.NEXTBUS'],
					authorityRoles: [],
					agencies: {},
				};
			}

			master.authorityRoles.push(masterRole.roleName);
		});

		// associate each individual role with an authority
		userRoles.forEach((userRole: FormattedRole) => {
			// find the authority list, or create a new one if needed
			let authority: AuthorityAgencyRole = this.authorityAgencyRoles[userRole.authority];

			if (!authority) {
				authority = this.authorityAgencyRoles[userRole.authority] = {
					authorityName: userRole.authorityName,
					authorityRoles: [],
					isAuthority: true,
					agencies: {},
				};
			}

			if (!userRole.agencyInfo) {
				// authority roles do not have an agency
				authority.authorityRoles.push(userRole.roleName);
			} else {
				// find the agency list, or create a new one if needed
				let agency: AgencyRole = authority.agencies[userRole.agencyInfo.agency_id];

				if (!agency) {
					agency = authority.agencies[userRole.agencyInfo.agency_id] = {
						agency_name: userRole.agencyInfo.agency_name,
						roles: [],
					};
				}

				agency.roles.push(userRole.roleName);
			}
		});

		if (this.authorityAgencyRoles.UmoIQ === null) {
			delete this.authorityAgencyRoles.UmoIQ;
		}
	};

	/**
	 * setup component subscriptions - it is interested in password changes
	 */
	private setSubscriptions = (): void => {
		this.passwordChange$Subscription = this.usersAdminEventsService.passwordChange.subscribe(() => {
			// update the display panel with updated data
			this.selectedUserDisplay.expiringIn = null;
			this.selectedUserDisplay.passwordLastChangedDate = new Date().toISOString();
		});
	};

	/**
	 * unsubscribes any subscriptions
	 */
	private unsubscribe = (): void => {
		this.routeParams$Subscription?.unsubscribe();
		this.passwordChange$Subscription?.unsubscribe();
	};

	/**
	 * initialize allowed actions
	 */
	private initAllowedActions = (): void => {
		this.changePasswordAllowed = this.isOwnProfile;

		if (!this.isOwnProfile) {
			this.resetPasswordAllowed =
				this.currentUserPermissionService.isAdmin(null) ||
				this.currentUserPermissionService.isPWAdmin() ||
				this.currentUserPermissionService.isNextbusAdmin();
		}
	};

	/**
	 * determines the password expiring warning text
	 *
	 * @returns password expiring warning text
	 */
	private determineWarnPasswordExpire = (): string => {
		let userWarningMessage: string = null;

		if (this.selectedUser.status !== 'New') {
			const passwordExpiry: PasswordExpiry = this.currentUserUtilService.determinePasswordExpiring();

			const translatedDays: string = this.translations['T_USER.DAYS'];

			if (passwordExpiry.passwordExpiring) {
				userWarningMessage = passwordExpiry.passwordExpiryDays + ' ' + translatedDays;

				if (passwordExpiry.passwordExpiryDays === '2') {
					userWarningMessage = this.translations['T_USER.TOMORROW'];
				} else if (passwordExpiry.passwordExpiryDays === '1') {
					userWarningMessage = this.translations['T_CORE.TODAY'];
				} else if (passwordExpiry.passwordExpiryDays <= '0') {
					userWarningMessage = this.translations['T_USER.EXPIRED'];
				}
			}
		}

		return userWarningMessage;
	};
}

<div class="nb-fullscreen-login-bg">
	<video playsinline autoplay muted loop class="nb-fullscreen-bg-video">
		<source src="/assets/img/Nextbusloop_Reduced.mp4" type="video/mp4" />
	</video>
</div>

<div *ngIf="initialized" class="nb-login-card middle-box text-center loginscreen animated fadeInDown nb-login-container">
	<div class="nb-login-colorblock"></div>
	<div class="bs-loading-container loading-container">
		<div class="nb-logo-container">
			<img class="nb-loginlogo" src="/assets/img/umo_agencyportal.svg" alt="Umo IQ Agency Portal" />
		</div>
		<loading-overlay [isLoading]="loading" [fit]="'content'"></loading-overlay>
		<div class="nb-login-padded-container">
			<div name="resetErrorMsg" *ngIf="error" class="alert alert-danger row error-container">
				<span name="resetErrorMsgText">{{ error }}</span>
			</div>
			<div>
				<div class="title">
					<span>{{ 'T_USER.PASSWORD_ENTER_NEW_PASSWORD' | translate }}</span>
				</div>

				<form class="m-t" name="newPasswordForm">
					<set-password (passwordEntryValid)="enableSetPassword($event)" (newPassword)="newPassword($event)"></set-password>
					<div class="divide"></div>
					<div class="form-group">
						<button name="passwordBtn" class="btn btn-primary btn-reset" [disabled]="!passwordValid" (click)="createPassword()">
							<p>{{ 'T_USER.PASSWORD_RESET_SET_PASSWORD' | translate }}</p>
						</button>
					</div>
				</form>
			</div>
			<div class="cancel">
				<a (click)="returnToLogin()">
					<span>{{ 'T_USER.PASSWORD_RESET_CANCEL' | translate }}</span>
				</a>
			</div>
		</div>
		<div class="nb-login-colorblock">
			<img src="/assets/img/poweredbycubic.svg" alt="Unavailable" />
		</div>
	</div>
</div>

import { Injectable } from '@angular/core';

import { CurrentUserUtilService } from '../current-user/current-user-utils.service';
import { AgenciesDataService } from '../../../agencies/services/agencies-data.service';
import { InitEventsService } from './init-events.service';
import { StateService } from '@cubicNx/libs/utils';
import { IdleService } from '../idle/idle.service';

import { UserLogin } from '../../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class InitService {
	private initComplete: boolean = false;

	constructor(
		private initEventsService: InitEventsService,
		private agenciesDataService: AgenciesDataService,
		private currentUserUtilService: CurrentUserUtilService,
		private stateService: StateService,
		private idleService: IdleService
	) {}

	/**
	 * Handles any initialization actions. Any initialization required before the main app loads should be here, however
	 * care must be taken to not introduce circular dependenices. This is a central point for initiliazation as we know
	 * we have a user whether it be because the user has logged in, or following a refresh with a user and token already
	 * cached
	 *
	 * @param currentUser - the current user returned from the login reponse.
	 */
	public initActions = async (currentUser: UserLogin): Promise<void> => {
		// init user settings
		this.currentUserUtilService.initUserSettings(currentUser);

		// init the state service
		this.stateService.init(currentUser.username);

		// agencies are accessed throughout the application - get them here so we can guarantee they are available
		await this.agenciesDataService.loadAgencies();

		// Set-up defaults for idle monitoring
		this.idleService.init(currentUser);

		this.setInitComplete();
	};

	/**
	 * a public method to which informs callers whether initialization is complete
	 *
	 * @returns whether initialization has completed
	 */
	public isInitComplete = (): boolean => {
		return this.initComplete;
	};

	/**
	 * sets initialization as complete and broadcasts a message for subscribers
	 */
	private setInitComplete = (): void => {
		this.initComplete = true;
		this.initEventsService.publishInitComplete();
	};
}

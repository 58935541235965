/* all types in api-type files represent the data returned from our nextbus-api (NodeJS)
 *
 *  Changes to this file should ONLY be made to match what is returned from our API to keep
 *  our types clean. If properties are required for UI purposes then a new type should be
 *  created accordingly outside of this file
 */

export enum ReplayStatus {
	pending,
	processing,
	ready,
	complete,
	aborted,
	expired,
}

export interface ReplayDataParams {
	replayId: string;
	timestamp: number;
	stopId?: string;
}

export interface InitReplayParams {
	authorityId: string;
	startDate: string;
	endDate: string;
	includePredictions: boolean;
}

export interface InitReplayResponse {
	success: boolean;
	replayId: string;
}

export interface InitStatusParams {
	replayId: string;
}

export interface InitStatusResponse {
	replayDataStatus: ReplayStatus;
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { ScheduleAdminViewUploadHistoryComponent } from './upload-history/schedule-admin-view-upload-history.component';
import { ScheduleAdminViewRevisionHistoryComponent } from './revision-history/schedule-admin-view-revision-history.component';

import { TranslationService } from '@cubicNx/libs/utils';

import { BreadcrumbItems } from '../../../../utils/components/breadcrumbs/types/types';

@Component({
	selector: 'schedule-admin-view',
	templateUrl: './schedule-admin-view.component.html',
	styleUrls: ['./schedule-admin-view.component.scss'],
})
export class ScheduleAdminViewComponent extends TranslateBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChildren(ScheduleAdminViewUploadHistoryComponent) scheduleUploadHistory: QueryList<ScheduleAdminViewUploadHistoryComponent>;
	@ViewChildren(ScheduleAdminViewRevisionHistoryComponent) scheduleRevisionHistory: QueryList<ScheduleAdminViewRevisionHistoryComponent>;

	public loading: boolean = true;
	public authorityId: string = null;
	public breadcrumbItems: BreadcrumbItems = [];
	public loadRevisionHistoryTab: boolean = false;

	private routeParams$Subscription: Subscription = null;
	private scheduleUploadHistoryComponent: ScheduleAdminViewUploadHistoryComponent = null;
	private scheduleRevisionHistoryComponent: ScheduleAdminViewRevisionHistoryComponent = null;

	constructor(
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the schedules admin view such as loading translations
	 * and initialising breadcrumbs
	 */
	public async ngOnInit(): Promise<void> {
		// get params off the url
		this.routeParams$Subscription = this.route.params.subscribe((params) => {
			this.authorityId = params['authorityId'];
		});

		await this.loadTranslations();

		this.initBreadcrumbs();
	}

	/**
	 * functionality executed after the component has initialised.
	 */
	public async ngAfterViewInit(): Promise<void> {
		const scheduleUploadHistoryComponents: ScheduleAdminViewUploadHistoryComponent[] = this.scheduleUploadHistory.toArray();

		this.scheduleUploadHistoryComponent = scheduleUploadHistoryComponents[0];
	}

	/**
	 * When a tab is clicked, this is executed and is responsible for loading the data for the relevant
	 * tab - upload history or revision history
	 *
	 * @param tab - tab index
	 */
	public tabChanged = async (tab: any): Promise<void> => {
		if (tab.index === 0) {
			await this.scheduleUploadHistoryComponent.getHistoryData();
		} else {
			this.loadRevisionHistoryTab = true;
			const scheduleRevisionHistoryComponents: ScheduleAdminViewRevisionHistoryComponent[] = this.scheduleRevisionHistory.toArray();

			this.scheduleRevisionHistoryComponent = scheduleRevisionHistoryComponents[0];
		}
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads the translations presented within the view
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_CORE.ADMIN_TOOLS', 'T_SCHEDULE.SCHEDULES', 'T_SCHEDULE.SCHEDULE_DETAILS']);
	};

	/**
	 * initialises the view breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({
			displayText: this.translations['T_CORE.ADMIN_TOOLS'],
			targetPath: '/admintools',
			activePage: false,
		});

		this.breadcrumbItems.push({
			displayText: this.translations['T_SCHEDULE.SCHEDULES'],
			targetPath: '/admintools/schedules',
			activePage: false,
		});

		this.breadcrumbItems.push({
			displayText: this.translations['T_SCHEDULE.SCHEDULE_DETAILS'],
			activePage: true,
		});
	};
}

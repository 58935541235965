/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class DepotsApiService {
	private readonly depotsUrl: string = '/depots';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the depots for the given authority/agency (filtered with any search text) from the nextbus API
	 *
	 * @param authorityId - the current authority id
	 * @param agencyId - the current agency id
	 * @param searchText - any uiser filter text
	 * @returns the depots list response
	 */
	public getDepots = async (authorityId: string, agencyId: string, searchText: string): Promise<any> => {
		try {
			if (searchText) {
				return await this.httpService.get('/' + authorityId + '/' + agencyId + this.depotsUrl, { search: searchText });
			} else {
				return await this.httpService.get('/' + authorityId + '/' + agencyId + this.depotsUrl);
			}
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapViewApiService } from './map-view-api.service';
import { CurrentUserUtilService } from '../../../../../support-features/login/services/current-user/current-user-utils.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { MapState } from '../../../types/types';
import { MapViews } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapViewDataService {
	constructor(
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private logger: LoggerService,
		private currentUserUtilService: CurrentUserUtilService,
		private mapViewApiService: MapViewApiService
	) {}

	/**
	 * handle the request to get the user map views from the nextbus API
	 *
	 * @returns the views for the user
	 */
	public getViews = async (): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: [],
		};

		try {
			const userId: number = this.currentUserUtilService.getCurrentUser().nb_id;

			const mapViews: MapViews = await this.mapViewApiService.getViews(userId);

			if (mapViews && Array.isArray(mapViews)) {
				result.success = true;
				result.resultData = mapViews as MapViews;
			}
		} catch (exception) {
			this.logger.logError('Failed to get user map views.', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_MAP.MAP_VIEW_GET_FAILURE'));
		}

		return result;
	};

	/**
	 * handle the request to create a view via the nextbus API
	 *
	 * @param userId - the id of the user
	 * @param viewTitle - the new view title
	 * @param viewState - a copy (snapshot) of the map state for this view
	 * @returns the response object containing the success result
	 */
	public createView = async (userId: number, viewTitle: string, viewState: MapState): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.mapViewApiService.createView(userId, viewTitle, viewState);

			// the response returns a simple boolean so just set in success flag
			result.success = response as boolean;
		} catch (exception) {
			this.logger.logError('Failed to create user map view.', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_MAP.MAP_VIEW_CREATE_FAILURE'));
		}

		return result;
	};

	/**
	 * handle the request to update a view via the nextbus API
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view to update
	 * @param viewTitle - the new view title
	 * @param viewState - a copy (snapshot) of the map state for this view
	 * @returns the response object containing the success result
	 */
	public updateView = async (userId: number, viewId: number, viewTitle: string, viewState: MapState): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.mapViewApiService.updateView(userId, viewId, viewTitle, viewState);

			// the response returns a simple boolean so just set in success flag
			result.success = response as boolean;
		} catch (exception) {
			this.logger.logError('Failed to update map view.', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_MAP.MAP_VIEW_UPDATE_FAILURE'));
		}

		return result;
	};

	/**
	 * handle the request to delete a view via the nextbus API
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view
	 * @returns the response object
	 */
	public deleteView = async (userId: number, viewId: number): Promise<any> => {
		const result: ResultContent = {
			success: false,
			resultData: false, //response is expected to be a boolean
		};

		try {
			const response: any = await this.mapViewApiService.deleteView(userId, viewId);

			// the response returns a simple boolean so just set in success flag
			result.success = response as boolean;
		} catch (exception) {
			this.logger.logError('Failed to remove map view.', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_MAP.MAP_VIEW_DELETE_FAILURE'));
		}

		return result;
	};
}

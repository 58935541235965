import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { CurrentUserUtilService } from '../../support-features/login/services/current-user/current-user-utils.service';
import { CurrentUserPermissionService } from '../../support-features/login/services/current-user/current-user-permission.service';
import { AgenciesDataService } from '../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { PermissionRequest, PermissionRequestActionType } from '../../support-features/login/types/types';
import { UserLogin } from '../../support-features/login/types/api-types';
import { agencyConstants } from '../../support-features/agencies/types/types';

@Component({
	selector: 'navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends TranslateBaseComponent implements OnInit {
	public vehicleMessageEnabled: boolean = false;
	public riderMessageEnabled: boolean = false;
	public hasDisablePredictionPermission: boolean = false;
	public showPredictionActive: boolean = false;

	constructor(
		private currentUserService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private agenciesDataService: AgenciesDataService,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the navigation component
	 *
	 * checks permissions and features that may or may not be available to the user
	 */
	public ngOnInit(): void {
		const currentUser: UserLogin = this.currentUserService.getCurrentUser();

		this.checkFeaturesEnabled(currentUser);
		this.checkFeaturesPermissions(currentUser);
	}

	/**
	 * whether the user has a mini nav bar configured for them
	 *
	 * @returns true if the user has a mini nav bar
	 */
	public hasMiniNavBar = (): boolean => {
		return this.currentUserService.hasMiniNavBar();
	};

	/**
	 * navigates the user to the supplied target view
	 *
	 * @param target - the target URL
	 */
	public navigate = (target: string): void => {
		this.router.navigate([target]);
	};

	/**
	 * retrieves the location within the application for the user
	 *
	 * @returns the current locayion within the application
	 */
	public getLocation = (): string => {
		return this.router.url;
	};

	/**
	 * reemembers if predictions are active or not
	 *
	 * @param active - active or not
	 */
	public setPredictionActive = (active: boolean): void => {
		this.showPredictionActive = active;
	};

	/**
	 * determines if the user/agency has permission to perform the supplied permission request
	 *
	 * @param permission - the permission
	 * @param agencyId - the agency id
	 * @param authorityId - the authority id
	 * @returns if permission is granted or not
	 */
	private hasPermission = (permission: PermissionRequestActionType, agencyId: string, authorityId: string): boolean => {
		const permissionRequest: PermissionRequest = {
			agencyId,
			authorityId,
		};

		// Verify if this user has permission the appropriate control can be enabled or not
		return this.currentUserPermissionService.hasPermissionTo(permission, permissionRequest);
	};

	/**
	 * determines if the current user/agency has vehicle messages and rider message features enabked for them
	 *
	 * @param currentUser - the current user
	 */
	private checkFeaturesEnabled = async (currentUser: UserLogin): Promise<void> => {
		const nbPrimaryAgencyId: number = currentUser.nb_primary_agency_id;

		// check if feature is available for the agency
		this.vehicleMessageEnabled = this.agenciesDataService.isFeatureEnabled(nbPrimaryAgencyId, agencyConstants.dispatchTextMessage);
		this.riderMessageEnabled = this.agenciesDataService.isFeatureEnabled(nbPrimaryAgencyId, agencyConstants.riderMessage);
	};

	/**
	 * determines if the current user/agency has disable prediction permission
	 *
	 * @param currentUser - the current user
	 */
	private checkFeaturesPermissions = async (currentUser: UserLogin): Promise<void> => {
		const primaryAgencyId: string = currentUser.primary_agency_id;
		const primaryAuthorityId: string = currentUser.primary_agency_authority_id;

		// check if feature is available for this user
		this.hasDisablePredictionPermission = this.hasPermission(
			PermissionRequestActionType.postDisablePredictions,
			primaryAgencyId,
			primaryAuthorityId
		);
	};
}

<loading-overlay [isLoading]="loading"></loading-overlay>

<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

<div class="nb-view">
	<panel-header [showBackButton]="true" [heading]="'T_USER.USER_PROFILE' | translate"> </panel-header>
	<div *ngIf="initialized">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 toolbar">
			<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 button-container">
				<button (click)="edit()" class="btn btn-action pull-left" [disabled]="disableResetAndEdit">
					<span class="nb-button-text">{{ 'T_CORE.EDIT' | translate }}</span>
				</button>
				<button *ngIf="changePasswordAllowed" (click)="changePassword()" class="btn btn-action pull-left" [disabled]="disableReset">
					<span class="nb-button-text">{{ 'T_USER.CHANGE_PASSWORD' | translate }}</span>
				</button>
				<button *ngIf="resetPasswordAllowed" (click)="resetPassword()" class="btn btn-action pull-left" [disabled]="disableReset">
					<span class="nb-button-text">{{ 'T_CORE.RESET_PASSWORD' | translate }}</span>
				</button>
				<button
					*ngIf="!isStatusDeactivated && !isOwnProfile"
					[disabled]="isPasswordAdminUser"
					(click)="deactivateUser()"
					class="btn btn-action pull-left">
					<span class="nb-button-text">{{ 'T_USER.DEACTIVATE' | translate }}</span>
				</button>
				<button *ngIf="isStatusDeactivated" (click)="enableUser()" [disabled]="isPasswordAdminUser" class="btn btn-action pull-left">
					<span class="nb-button-text">{{ 'T_USER.ENABLE' | translate }}</span>
				</button>
				<button *ngIf="isStatusLocked" (click)="unlockUser()" class="btn btn-action pull-left">
					<span class="nb-button-text">{{ 'T_USER.UNLOCK' | translate }}</span>
				</button>
			</div>
			<div class="col-xs-1"></div>
		</div>

		<div class="divide"></div>
		<div>
			<div class="form-group panel panel-default nb-view-table detail-view-container">
				<view-panel
					class="nb-margin-all-md detail-view-table"
					[model]="selectedUserDisplay"
					[viewtableoptions]="detailViewTableOptions"
					[layout]="'column-center'">
				</view-panel>
				<view-panel
					class="nb-margin-all-md detail-view-table-user-status"
					[model]="selectedUserDisplay"
					[viewtableoptions]="detailViewTableUserStatus"
					[layout]="'column-center'">
				</view-panel>
			</div>

			<div class="form-group panel panel-default nb-view-table nb-margin-all-md user-account-notes-container" [hidden]="hideActivityList">
				<div class="panel-heading nb-role-edit-panel-header user-account-notes-header">
					<span class="nb-subhead pull-left"> {{ 'T_CORE.USER_ACCOUNT_NOTES' | translate }}</span>
				</div>
				<div class="panel-body account-notes-panel">
					<div class="activity-notes-container">
						<data-table
							data-test="user.details.activity.table"
							[listName]="'user-activity-notes'"
							[columns]="activityNotesListColumns"
							[rowData]="activityNotesListData"
							[pagingEnabled]="false"
							[enableCheckRowSelection]="false"
							[enableFooter]="false"
							[loadingIndicator]="activityNotesLoadingIndicator">
						</data-table>
					</div>
				</div>
			</div>

			<div class="form-group panel panel-default nb-view-table nb-margin-left-md nb-margin-right-md nb-margin-top-md">
				<div class="panel-heading nb-role-edit-panel-header">
					<span class="nb-subhead pull-left"> {{ 'T_CORE.ROLES' | translate }}</span>
				</div>

				<div class="panel-body" *ngIf="authorityAgencyRoles">
					<div
						*ngFor="let authorityAgencyRole of authorityAgencyRoles | keys"
						class="panel panel-default nb-margin-bottom-md nb-view-table">
						<div class="panel-heading nb-role-edit-panel-header">
							<span class="nb-section-title pull-left"> {{ authorityAgencyRoles[authorityAgencyRole].authorityName }}</span>
						</div>
						<div class="panel-body">
							<div class="nb-margin-bottom-md" *ngIf="authorityAgencyRoles[authorityAgencyRole].authorityRoles.length > 0">
								<div *ngIf="authorityAgencyRoles[authorityAgencyRole].isAuthority">
									<span class="nb-title-sm">{{ 'T_CORE.AUTHORITY_ROLES' | translate }}</span
									><br />
								</div>
								<span class="nb-body">{{ authorityAgencyRoles[authorityAgencyRole].authorityRoles.join(', ') }}</span>
							</div>
							<div class="nb-margin-bottom-md" *ngFor="let agency of authorityAgencyRoles[authorityAgencyRole].agencies | keys">
								<span class="nb-title-sm">{{ 'T_CORE.AGENCY_ROLES' | translate }}: </span>
								<span class="nb-title-sm nb-text-info">{{ authorityAgencyRoles[authorityAgencyRole].agencies[agency].agency_name }}</span
								><br />
								<span class="nb-body">{{ authorityAgencyRoles[authorityAgencyRole].agencies[agency].roles.join(', ') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<loading-overlay [isLoading]="isLoading" [fit]="'content'"></loading-overlay>

<div [hidden]="isLoading">
	<div class="nb-reset-password-text">
		<p>{{ 'T_USER.PASSWORD_RESET_ENTER_EMAIL_TO_SEND' | translate }}</p>
	</div>

	<div>
		<form *ngIf="resetForm" class="nb-login-form-container m-t" [formGroup]="resetForm" novalidate>
			<div class="form-group" [ngClass]="{ 'has-error': resetForm.controls.username.invalid && !resetForm.controls.username.pristine }">
				<input
					formControlName="username"
					name="username"
					id="username"
					type="text"
					class="form-control"
					value=""
					tabindex="1"
					title="{{ 'T_CORE.ENTER_USERNAME' | translate }}"
					placeholder="{{ 'T_CORE.USERNAME' | translate }}" />
				<input-validation
					[control]="resetForm.controls.username"
					[errorOnTouch]="true"
					[messageTextOverride]="usernameRequiredTextOverride"
					[extraMessageText]="usernameExtraMessageText">
				</input-validation>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': resetForm.controls.email.invalid && !resetForm.controls.email.pristine }">
				<input
					formControlName="email"
					name="email"
					id="email"
					type="email"
					class="form-control"
					autocomplete="off"
					title="{{ 'T_CORE.ENTER_EMAIL' | translate }}"
					placeholder="{{ 'T_CORE.EMAIL_ADDRESS' | translate }}" />
				<input-validation
					[control]="resetForm.controls.email"
					[errorOnTouch]="true"
					[messageTextOverride]="emailRequiredTextOverride"
					[extraMessageText]="emailExtraMessageText">
				</input-validation>
			</div>
			<div class="form-group">
				<button name="resetBtn" class="btn btn-primary reset-pass-btn" (click)="resetPassword()">
					<!-- Reset password -->
					<p>{{ 'T_USER.PASSWORD_RESET_RESET_PASSWORD' | translate }}</p>
				</button>
			</div>
		</form>
	</div>
</div>

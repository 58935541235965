/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BlockUtilsService {
	constructor() {}

	/**
	 * formats a list of routeIds in to a comma seperated string
	 * @param routeIds - the list of route ids
	 * @returns a list of routeIds formatted in a comma seperated string
	 */
	public getRouteIdentificationFromRoutes = (routeIds: string[]): string => {
		let formattedRouteIds: string = null;

		if (routeIds.length > 0) {
			formattedRouteIds = '';

			for (const routeId of routeIds) {
				if (routeId === 'All') {
					formattedRouteIds = null;
					break;
				} else {
					formattedRouteIds += routeId + ',';
				}
			}

			if (formattedRouteIds !== null) {
				formattedRouteIds = formattedRouteIds.substring(0, formattedRouteIds.length - 1);
			}
		}

		return formattedRouteIds;
	};
}

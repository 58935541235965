/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ObjectHelpers } from '../../helpers/object-helpers';
import { LogLevel } from '../logging/types/types';
import { Config, ConfigMapLocation, ConfigServiceNow, ConfigValidator } from './types/types';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	/**
	 * The default values set here are only used when running locally. When deployed to dev-1 or prd-1,
	 * these values are overwritten with the config data stored in S3
	 */
	private appSettings: Config = {
		appId: 'nextbus-agency-portal',
		appVersion: 'v2.0.5-alpha',
		configId: 'local',
		minLogLevel: 'info',
		idle: 14100,
		idleTimeout: 300,
		//apiPath: 'https://api.prd-1.iq.live.umoiq.com/v2.0',
		apiPath: 'https://api.dev-1.iq.play.umoiq.com/v2.0',
		//apiPath: 'https://api.stage-1.iq.work.umoiq.com/v2.0',
		//apiPath: 'http://localhost:8089/v2.0',
		defaultListSize: 25,
		paginationMaxSize: 200, // the maximum value allowed when selected page size (number of records to show) in dropdown
		paginationMaxNumPages: 5, // how many pages options we show in paging i.e << < 1 2 3 4 5 > >>
		adminMasterRoleName: 'UMO_ADMIN',
		adminAgencyRoleName: 'Administrator',
		basicUserRoleName: 'BASIC_USER',
		userNameMinLength: 1,
		userNameMaxLength: 30,
		usernameValidators: [
			{
				validator: '^.{0,30}$',
				error: 'username-too-long',
			},
			{
				validator: '^[\\w\\.@\\-]+$',
				error: 'username-bad-char',
			},
		],
		passwordValidators: [
			{
				validator: '^.{10,}$',
				error: 'password-too-short',
			},
			{
				validator: '^.{0,160}$',
				error: 'password-too-long',
			},
			{
				validator: '[A-Z]',
				error: 'password-needs-upper',
			},
			{
				validator: '[a-z]',
				error: 'password-needs-lower',
			},
			{
				validator: '[0-9]',
				error: 'password-needs-number',
			},
			{
				validator: '[!"#$%\\&\\(\\)\\*\\+,./:;<=>?@\\[\\]\\^_\\{|\\}~]',
				error: 'password-needs-special',
			},
		],
		navMinified: false,
		statusPageUrl: 'http://status.dev.nxbs2dev.com:8080/',
		default_map_location: { lat: 30.2672, lng: -97.7431 },
		service_now_url: 'https://ctscubic.service-now.com/',
		service_now_width: '800',
		service_now_height: '600',
		mapbox_api_key: 'pk.eyJ1IjoibmV4dGJ1cyIsImEiOiJjamRuNXJrNnowYXplMnFtcG95dWh0MHduIn0.R_G_ioW1qGMJbmde6-HhoA',
		show_raster_map: true,
		mapRefreshRate: 10,
		mapReplayPlaybackSpeed1RefreshRate: 10,
		mapReplayPlaybackSpeed2RefreshRate: 5,
		mapReplayPlaybackSpeed5RefreshRate: 3,
		mapReplayPlaybackSpeed10RefreshRate: 3,
		mapReplayPlaybackSpeed20RefreshRate: 3,
		mapReplayPlaybackSpeed60RefreshRate: 3,
		mapReplayInitPollPeriod: 3,
		mapReplayInitTimeoutPeriod: 300,
		defaultVehicleColor: '#666666',
		defaultVehicleTextColor: '#ffffff',
		unpredictableVehicleColor: '#ffffff',
		unpredictableVehicleTextColor: '#000000',
		adherenceVeryEarlyColor: '#b00808',
		adherenceEarlyColor: '#eb3b3b',
		adherenceOnTimeColor: '#14ad3d',
		adherenceLateColor: '#ffb852',
		adherenceVeryLateColor: '#e89015',
		headwayVeryCloseColor: '#b00808',
		headwayCloseColor: '#eb3b3b',
		headwayAcceptableColor: '#14ad3d',
		headwayVeryDistantColor: '#e89015',
		headwayDistantColor: '#ffb852',
		maxVehicleTrailMs: 300000,
		defaultZoomLevel: 11,
		minZoomLevel: 8,
		maxZoomLevel: 18,
		ladderMaximumStopLabelLength: 25,
		// needs to match delay in nb-map-vehicle-marker. Can we set this in code so both values point to the same place?
		vehicleMovingTransitionTime: 5000,
		// needs to match delay in nb-map-vehicle-marker-fast-transition. Can we set this in code so both values point to the same place?
		vehicleMovingFastTransitionTime: 2000,
		ridersPortal: {
			test: null,
		},
	};

	constructor(private http: HttpClient) {}

	/**
	 * initilization handler for the configuration,
	 *
	 * Request configuration from the server and merge (overwrite) with default configuration.
	 *
	 * Note: if the request fails, the app will continue with default configuration (as it does when
	 * running up during development)
	 */
	public init = async (): Promise<void> => {
		try {
			await this.http.get('appsettings', {}).subscribe(
				(response: any) => {
					ObjectHelpers.mergeDeep(this.appSettings, response);

					console.log('Using server app settings', this.appSettings);
				},
				(error) => {
					console.warn('App settings not available when running locally - using default app settings', this.appSettings, error);
				}
			);
		} catch (exception: any) {
			console.warn('Using local default app settings - ' + exception);
		}
	};

	/**
	 * gets the app id
	 *
	 * @returns the app id
	 */
	public getAppId = (): string => {
		return this.appSettings.appId;
	};

	/**
	 * gets the current user key
	 *
	 * @returns the current user key
	 */
	public getCurrentUserKey = (): string => {
		return this.appSettings.appId + '-current-user';
	};

	/**
	 * gets the API URL
	 *
	 * @returns the API URL
	 */
	public getApiUrl = (): string => {
		return this.appSettings.apiPath;
	};

	/**
	 * gets the MapBox API key
	 *
	 * @returns the MapBox API key
	 */
	public getMapBoxApiKey = (): string => {
		return this.appSettings.mapbox_api_key;
	};

	/**
	 * gets the idle value (how long the app can be idle before user is logged out)
	 *
	 * @returns the idle value
	 */
	public getIdle = (): number => {
		return this.appSettings.idle;
	};

	/**
	 * gets the idle timeout value (how long before the idle value elapses to warn the user)
	 *
	 * @returns the idle value
	 */
	public getIdleTimeout = (): number => {
		return this.appSettings.idleTimeout;
	};

	/**
	 * gets the default map location
	 *
	 * @returns the default map location
	 */
	public getDefaultMapLocation = (): ConfigMapLocation => {
		return this.appSettings.default_map_location;
	};

	/**
	 * gets the default pagination size
	 *
	 * @returns the default pagination size
	 */
	public getDefaultPageSize = (): number => {
		return this.appSettings.defaultListSize;
	};

	/**
	 * gets the pagination maximum size
	 *
	 * @returns the pagination maximum size
	 */
	public getPaginationMaxSize = (): number => {
		return this.appSettings.paginationMaxSize;
	};

	/**
	 * gets the pagination maximum number of pages
	 *
	 * @returns the pagination maximum number of pages
	 */
	public getPaginationMaxNumPages = (): number => {
		return this.appSettings.paginationMaxNumPages;
	};

	/**
	 * gets the admin master role name
	 *
	 * @returns the admin master role name
	 */
	public getAdminMasterRoleName = (): string => {
		return this.appSettings.adminMasterRoleName;
	};

	/**
	 * gets the admin agency role name
	 *
	 * @returns the admin agency role name
	 */
	public getAdminAgencyRoleName = (): string => {
		return this.appSettings.adminAgencyRoleName;
	};

	/**
	 * gets the admin agency role name
	 *
	 * @returns the admin agency role name
	 */
	public getBasicUserRoleName = (): string => {
		return this.appSettings.basicUserRoleName;
	};

	/**
	 * gets the guest user role name
	 *
	 * @returns the guest user role name
	 */
	public getGuestUserRoleName = (): string => {
		return 'GUEST_USER';
	};

	/**
	 * gets the username validators
	 *
	 * @returns the username validators
	 */
	public getUserNameValidators = (): ConfigValidator[] => {
		return this.appSettings.usernameValidators;
	};

	/**
	 * gets the password validators
	 *
	 * @returns the password validators
	 */
	public getPasswordValidators = (): ConfigValidator[] => {
		return this.appSettings.passwordValidators;
	};

	/**
	 * gets the service now settings
	 *
	 * @returns the service now settings
	 */
	public getServiceNowSettings = (): ConfigServiceNow => {
		return {
			url: this.appSettings.service_now_url,
			width: this.appSettings.service_now_width,
			height: this.appSettings.service_now_height,
		};
	};

	/**
	 * gets default zoom level
	 *
	 * @returns the default zoom level
	 */
	public getDefaultZoomLevel = (): number => {
		return this.appSettings.defaultZoomLevel;
	};

	/**
	 * gets default min zoom level
	 *
	 * @returns the default min zoom level
	 */
	public getMinZoomLevel = (): number => {
		return this.appSettings.minZoomLevel;
	};

	/**
	 * gets default max zoom level
	 *
	 * @returns the default max zoom level
	 */
	public getMaxZoomLevel = (): number => {
		return this.appSettings.maxZoomLevel;
	};

	/**
	 * gets whether to show the raster map options
	 *
	 * @returns whether to show the raster map options
	 */
	public getShowRasterMapOption = (): boolean => {
		return typeof this.appSettings.show_raster_map !== 'undefined';
	};

	/**
	 * gets the map refresh rate
	 *
	 * @returns the map refresh rate
	 */
	public getMapRefreshRate = (): number => {
		return this.appSettings.mapRefreshRate;
	};

	/**
	 * gets the map replay speed 1 refresh rate
	 *
	 * @returns the map replay speed 1 refresh rate
	 */
	public getMapReplayPlaybackSpeed1RefreshRate = (): number => {
		return this.appSettings.mapReplayPlaybackSpeed1RefreshRate;
	};

	/**
	 * gets the map replay speed 2 refresh rate
	 *
	 * @returns the map replay speed 2 refresh rate
	 */
	public getMapReplayPlaybackSpeed2RefreshRate = (): number => {
		return this.appSettings.mapReplayPlaybackSpeed2RefreshRate;
	};

	/**
	 * gets the map replay speed 5 refresh rate
	 *
	 * @returns the map replay speed 5 refresh rate
	 */
	public getMapReplayPlaybackSpeed5RefreshRate = (): number => {
		return this.appSettings.mapReplayPlaybackSpeed5RefreshRate;
	};

	/**
	 * gets the map replay speed 10 refresh rate
	 *
	 * @returns the map replay speed 10 refresh rate
	 */
	public getMapReplayPlaybackSpeed10RefreshRate = (): number => {
		return this.appSettings.mapReplayPlaybackSpeed10RefreshRate;
	};

	/**
	 * gets the map replay speed 20 refresh rate
	 *
	 * @returns the map replay speed 20 refresh rate
	 */
	public getMapReplayPlaybackSpeed20RefreshRate = (): number => {
		return this.appSettings.mapReplayPlaybackSpeed20RefreshRate;
	};

	/**
	 * gets the map replay speed 60 refresh rate
	 *
	 * @returns the map replay speed 60 refresh rate
	 */
	public getMapReplayPlaybackSpeed60RefreshRate = (): number => {
		return this.appSettings.mapReplayPlaybackSpeed60RefreshRate;
	};

	/**
	 * gets the map replay initial poll period
	 *
	 * @returns the map replay initial poll period
	 */
	public getMapReplayInitPollPeriod = (): number => {
		return this.appSettings.mapReplayInitPollPeriod;
	};

	/**
	 * gets the map replay initial timeout period
	 *
	 * @returns the map replay initial timeout period
	 */
	public getMapReplayInitTimeoutPeriod = (): number => {
		return this.appSettings.mapReplayInitTimeoutPeriod;
	};

	/**
	 * gets the default vehicle color
	 *
	 * @returns the default vehicle color
	 */
	public getDefaultVehicleColor = (): string => {
		return this.appSettings.defaultVehicleColor;
	};

	/**
	 * gets the default vehicle text (accent) color
	 *
	 * @returns the default vehicle text (accent) color
	 */
	public getDefaultVehicleTextColor = (): string => {
		return this.appSettings.defaultVehicleTextColor;
	};

	/**
	 * gets the default unpredictable vehicle (accent) color
	 *
	 * @returns the default unpredictable vehicle (accent) color
	 */
	public getDefaultUnpredictableVehicleColor = (): string => {
		return this.appSettings.unpredictableVehicleColor;
	};

	/**
	 * gets the default unpredictable vehicle text (accent) color
	 *
	 * @returns the default unpredictable vehicle text (accent) color
	 */
	public getDefaultUnpredictableVehicleTextColor = (): string => {
		return this.appSettings.unpredictableVehicleTextColor;
	};

	/**
	 * gets the adherence very early color
	 *
	 * @returns the adherence very early color
	 */
	public getAdherenceVeryEarlyColor = (): string => {
		return this.appSettings.adherenceVeryEarlyColor;
	};

	/**
	 * gets the adherence early color
	 *
	 * @returns the adherence early color
	 */
	public getAdherenceEarlyColor = (): string => {
		return this.appSettings.adherenceEarlyColor;
	};

	/**
	 * gets the adherence on-time color
	 *
	 * @returns the adherence on-time color
	 */
	public getAdherenceOnTimeColor = (): string => {
		return this.appSettings.adherenceOnTimeColor;
	};

	/**
	 * gets the adherence late color
	 *
	 * @returns the adherence late color
	 */
	public getAdherenceLateColor = (): string => {
		return this.appSettings.adherenceLateColor;
	};

	/**
	 * gets the adherence very late color
	 *
	 * @returns the adherence very late color
	 */
	public getAdherenceVeryLateColor = (): string => {
		return this.appSettings.adherenceVeryLateColor;
	};

	/**
	 * gets the headway very close color
	 *
	 * @returns the headway very close color
	 */
	public getHeadwayVeryCloseColor = (): string => {
		return this.appSettings.headwayVeryCloseColor;
	};

	/**
	 * gets the headway close color
	 *
	 * @returns the headway close color
	 */
	public getHeadwayCloseColor = (): string => {
		return this.appSettings.headwayCloseColor;
	};

	/**
	 * gets the headway acceptable color
	 *
	 * @returns the headway acceptable color
	 */
	public getHeadwayAcceptableColor = (): string => {
		return this.appSettings.headwayAcceptableColor;
	};

	/**
	 * gets the headway very distant color
	 *
	 * @returns the headway very distant color
	 */
	public getHeadwayVeryDistantColor = (): string => {
		return this.appSettings.headwayVeryDistantColor;
	};

	/**
	 * gets the headway distant color
	 *
	 * @returns the headway distant color
	 */
	public getHeadwayDistantColor = (): string => {
		return this.appSettings.headwayDistantColor;
	};

	/**
	 * gets the default maximum trail time (Milliseconds)
	 *
	 * @returns the default maximum trail time (Milliseconds)
	 */
	public getDefaultMaxVehicleTrailMs = (): number => {
		return this.appSettings.maxVehicleTrailMs;
	};

	/**
	 * gets the ladder maximum stoplabel length
	 *
	 * @returns the ladder maximum stoplabel length
	 */
	public getLadderMaximumStopLabelLength = (): number => {
		return this.appSettings.ladderMaximumStopLabelLength;
	};

	/**
	 * gets the (animated) vehicle moving transition time
	 *
	 * @returns the (animated) vehicle moving transition time
	 */
	public getVehicleMovingTransitionTime = (): number => {
		return this.appSettings.vehicleMovingTransitionTime;
	};

	/**
	 * gets the (animated) vehicle moving fast (for replay) transition time
	 *
	 * @returns the (animated) vehicle moving fast (for replay) transition time
	 */
	public getVehicleMovingFastTransitionTime: () => number = (): number => {
		return this.appSettings.vehicleMovingFastTransitionTime;
	};

	/**
	 * gets the default min log level
	 *
	 * @returns the (animated) vehicle moving fast (for replay) transition time
	 */
	public getDefaultMinLogLevel = (): LogLevel => {
		const logLevelStr: any = this.appSettings.minLogLevel;

		const minLogLevel: LogLevel = +LogLevel[logLevelStr];

		// Return configured minimum log level or default 'info' level if the log level has been mis-configured
		return minLogLevel !== undefined && !Number.isNaN(minLogLevel) ? minLogLevel : LogLevel.info;
	};
}

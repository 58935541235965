import { Injectable } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class TranslateBaseComponent {
	public translations: { [key: string]: string } = {};

	constructor(private translationService: TranslationService) {}

	/**
	 * Gets translated text based on the supplied key value.
	 *
	 * @param key - the name of the translation to get.
	 * @returns the tranlated text.
	 */
	public getTranslation = (key: string): string => {
		return this.translationService.getTranslation(key);
	};

	/**
	 * Initializes the translations for the widget.
	 *
	 * @param keys - the names of the translations to initialize.
	 * @returns a flag indicating success.
	 */
	protected initTranslations = async (keys: string[]): Promise<boolean> => {
		keys.forEach((key) => {
			const translated: string = this.getTranslation(key);

			this.translations[key] = translated;
		});

		return true;
	};
}

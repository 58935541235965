/* allows interfaces to be extended and overridden with alternative types */
type Diff<T extends keyof any, U extends keyof any> = ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];

export type Overwrite<T, U> = Pick<T, Diff<keyof T, keyof U>> & U;

export interface ResultContent {
	success: boolean;
	resultData: any;
}

export interface ViewPanelSource {
	title?: string;
	dynamicTitle?: (value: any) => string;
	rowDefs: ViewPanelRowDef[];
	dynamicSubTitle?: (value: any) => string;
	rowSubDefs?: ViewPanelRowDef[];
}

export interface ViewPanelRowDef {
	displayName: string;
	name: string;
	type?: string;
	hidden?: boolean;
	convert?: (value: any) => any; // see FormatField pipe for usage
	dateFormat?: string;
}

export interface SelectValue {
	name: string;
	display: any;
}

export type SelectValues = Array<SelectValue>;

export interface SelectCheckValue extends SelectValue {
	id?: number;
	isChecked: boolean;
}

export type SelectCheckValues = Array<SelectCheckValue>;

export enum TimeFormatType {
	relativeTime,
	clockTime,
}

<div class="grid-title">
	<route-pill class="report-viewer-grid-route-pill" [route]="routeData" [useFullWidth]="true"></route-pill>
	<div class="report-viewer-grid-route-descriptor">
		<span>{{ subListData.route_long_name }} | Block {{ subListData.block_id }}</span>
	</div>
</div>
<table>
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'stop_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_id')"
				data-test="th.stop_id"
				class="ellipsis">
				{{ 'T_CORE.STOP_ID' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				data-test="th.stop_name"
				class="ellipsis">
				{{ 'T_CORE.STOP_NAME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_headsign' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_headsign')"
				data-test="th.trip_headsign"
				class="mw-col ellipsis">
				{{ 'T_REPORT.DESTINATION' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'timepoint' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'timepoint')"
				data-test="th.timepoint"
				class="mw-col ellipsis">
				{{ 'T_REPORT.TIMEPOINT' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'arrival_time' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'arrival_time')"
				data-test="th.arrival_time"
				class="mw-col ellipsis">
				{{ 'T_CORE.ARRIVAL' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'departure_time' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'departure_time')"
				data-test="th.departure_time"
				class="mw-col ellipsis">
				{{ 'T_CORE.DEPARTURE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'dwelling' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'dwelling')"
				data-test="th.dwelling"
				class="mw-col ellipsis">
				{{ 'T_CORE.DWELL' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'adherence' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'adherence', SortFieldType.timeDelta)"
				data-test="th.adherence"
				class="mw-col ellipsis">
				{{ 'T_CORE.ADHERENCE' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow">
			<td data-test="td.stop_id" class="ellipsis">{{ reportDataItem.stop_id }}</td>
			<td data-test="td.stop_name" class="ellipsis reporttooltip">
				<span class="tooltiptext">{{ reportDataItem.stop_name }}</span
				>{{ reportDataItem.stop_name }}
			</td>
			<td data-test="td.trip_headsign" class="ellipsis">{{ reportDataItem.trip_headsign }}</td>
			<td data-test="td.timepoint" class="ellipsis">{{ reportDataItem.timepoint }}</td>
			<td data-test="td.arrival_time" class="ellipsis">{{ reportDataItem.arrival_time }}</td>
			<td data-test="td.departure_time" class="ellipsis">{{ reportDataItem.departure_time }}</td>
			<td data-test="td.dwelling" class="ellipsis">{{ reportDataItem.dwelling }}</td>
			<td data-test="td.adherence">
				<adherence-display
					[adherence]="reportDataItem.adherence"
					[adherenceOptions]="adherenceOptions"
					[authorityId]="authorityId"
					[agencyId]="agencyId">
				</adherence-display>
			</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<div *ngFor="let reportItem of listData; let reportIndex = index">
					<ad400-adherence-for-vehicles-by-stop-sub-list
						[id]="reportIndex"
						[subListData]="reportItem"
						[defaultTemplateId]="defaultTemplateId"
						[templateSummaryContent]="templateSummaryContent"
						[authorityId]="authorityId"
						[agencyId]="agencyId"
						(pageSizeChanged)="scrollIntoView(reportIndex.toString())">
					</ad400-adherence-for-vehicles-by-stop-sub-list>
				</div>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

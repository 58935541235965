/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class SchedulesAdminApiService {
	private readonly agenciesUrl: string = '/agencies';
	private readonly schedulesRevisionHistory: string = '/revision-history';
	private readonly schedulesUploadHistory: string = '/upload-history';

	constructor(private httpService: HttpService) {}

	/**
	 * retrieves agencies that match the supplied parameters
	 *
	 * @param params - agency retrieval parameters - page size, number, sort criteria and search text
	 * @returns agencies matching the supplied parameters
	 */
	public getAgencies = async (params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get(this.agenciesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves schedules revision history for the supplied authority
	 *
	 * @param authorityId - authority id
	 * @returns schedules revision history for the supplied authority
	 */
	public getSchedulesRevisionHistory = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get(this.agenciesUrl + this.schedulesRevisionHistory + '/' + authorityId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves schedules upload history for the supplied authority
	 *
	 * @param authorityId - authority id
	 * @param paginatedParams - schedule upload history parameters - page size, number, sort criteria and search text
	 * @returns schedules upload history for the supplied authority
	 */
	public getSchedulesUploadHistory = async (authorityId: string, paginatedParams: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get(this.agenciesUrl + this.schedulesUploadHistory + '/' + authorityId, paginatedParams);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves schedules upload history details for the supplied authority and confirmation number
	 *
	 * @param authorityId - authority id
	 * @param confirmNo - confirmation number
	 * @returns schedules upload history details for the supplied authority and confirmation number
	 */
	public getSchedulesUploadHistoryDetails = async (authorityId: string, confirmNo: string): Promise<any> => {
		try {
			return await this.httpService.get(this.agenciesUrl + this.schedulesUploadHistory + '/' + authorityId + '/' + confirmNo);
		} catch (exception) {
			throw exception;
		}
	};
}

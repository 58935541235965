/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { TimeHelpers } from '@cubicNx/libs/utils';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapLocationService } from '../../../services/map-location.service';
import { StateService } from '@cubicNx/libs/utils';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { BlocksDataService } from '../../../../../support-features/blocks/services/block-data.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { MapEventsService } from '../../../services/map-events.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { CurrentUserUtilService } from '../../../../../support-features/login/services/current-user/current-user-utils.service';
import { BlockModalService } from '../../../../../support-features/blocks/services/block-modal.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { Route, RoutesExtended } from '../../../../../support-features/routes/types/api-types';
import { agencyConstants } from '../../../../../support-features/agencies/types/types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';

import { Block, TripData, TripsToActivate, TripsToCancel } from '../../../../../support-features/blocks/types/api-types';

import { BlockStatus, TripList, TripListItem } from '../../../../../support-features/blocks/types/types';

import { ColumnType, Columns, CssClassType, PageRequestInfo, SortDirection, SortRequestInfo } from '@cubicNx/libs/utils';

import { RoutePillData } from '@cubicNx/libs/utils';
import { MapModeType, VehicleDetailsActiveTab } from '../../../types/types';

@Component({
	selector: 'block-details',
	templateUrl: './block-details.component.html',
	styleUrls: ['./block-details.component.scss'],
})
export class BlockDetailsComponent extends TranslateBaseComponent implements OnInit, OnDestroy, OnChanges {
	@Input() blockId: string = null;

	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('routePillColumnTemplate', { static: true, read: TemplateRef }) routePillColumnTemplate: TemplateRef<any>;

	// make enum accessible in html
	public blockStatus: typeof BlockStatus = BlockStatus;

	public block: Block = null;
	public blockLoaded: boolean = false;
	public enableCancelBlock: boolean = false;
	public enableActivateBlock: boolean = false;
	public enableCancelTrips: boolean = false;
	public enableActivateTrips: boolean = false;
	public cancelBlockTooltip: string = null;
	public activateBlockTooltip: string = null;
	public cancelTripsTooltip: string = null;
	public activateTripsTooltip: string = null;
	public blockTimeRangeDisplay: string = null;

	public toggleBlockDisabled: boolean = false;

	public routeLoaded: boolean = false;
	public listName: string = 'block-trips-list';
	public sortInfo: SortRequestInfo = { sort: 'time', sortDir: SortDirection.asc };
	public columns: Columns = [];
	public listLoadingIndicator: boolean = true;
	public tripList: TripList = [];

	private readonly activeTripClass: string = 'active-trip';
	private readonly futureTripClass: string = 'future-trip';

	private blockCancellationFeatureEnabled: boolean = false;
	private tripCancellationFeatureEnabled: boolean = false;

	private authorityId: string = null;
	private agencyId: string = null;
	private agencyTimezone: string = null;

	private initialized: boolean = false;
	private lastBlockPollSuccess: boolean = false;

	private mapNavigateRefresh$Subscription: Subscription = null;
	private refreshPoll$Subscription: Subscription = null;

	private tripSelection: TripList = [];
	private routes: RoutesExtended = [];
	private routesForBlock: RoutesExtended = [];

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['sortInfo'];

	private readonly tripIdColumnName: string = 'id';
	private readonly tripRouteColumnName: string = 'routeId';
	private readonly tripDirectionColumnName: string = 'direction';
	private readonly tripCancelledColumnName: string = 'cancelledIcon';
	private readonly tripTimeColumnName: string = 'time';

	constructor(
		private stateService: StateService,
		private mapRoutesService: MapRoutesService,
		private mapEventsService: MapEventsService,
		private mapHistoryService: MapHistoryService,
		private mapLocationService: MapLocationService,
		private agenciesDataService: AgenciesDataService,
		private colorUtilityService: ColorUtilityService,
		private blocksDataService: BlocksDataService,
		private routesDataService: RoutesDataService,
		private mapNavigationService: MapNavigationService,
		private currentUserUtilService: CurrentUserUtilService,
		private blockModalService: BlockModalService,
		private mapOptionsService: MapOptionsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the block detaiils page
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();

		this.loadCache();

		this.buildListColumns();

		this.init();
	}

	/**
	 * handle any changes to the component input values. If a new block id is set - we need
	 * to reinitialize for that block
	 *
	 * @param changes - the details of the change of our inputs
	 */
	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.blockId && !changes.blockId.firstChange) {
			if (changes.blockId.previousValue !== changes.blockId.currentValue) {
				this.init();
			}
		}
	}

	/**
	 * construct the route pill object using information retrieved about the route
	 *
	 * @param routeId - the route for the target block
	 * @returns the route pill data object used to populate our route pill badges
	 */
	public determineRoutePillData = (routeId: string): RoutePillData => {
		const route: Route = this.determineRoute(routeId);

		return {
			routeShortName: route.route_short_name,
			routeLongName: route.route_long_name,
			routeId: route.route_id,
			routeColor: this.colorUtilityService.getColor(route.route_color),
			routeTextColor: this.colorUtilityService.getColor(route.route_text_color),
		};
	};

	/**
	 * return to the users previous history location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * navigate to the vehcile details page for the selected vehicle
	 * @param vehicleId - the id of the vehicle we wish to select
	 */
	public navigateToVehicleDetails = async (vehicleId: string): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(this.authorityId, vehicleId, VehicleDetailsActiveTab.summary);
	};

	/**
	 * handle the sort request triggered from our datatable
	 * @param sortInfo - the details about the sort (sort field/direction)
	 */
	public handleSortRequest = async (sortInfo: PageRequestInfo): Promise<void> => {
		this.sortInfo.sort = sortInfo.sort;
		this.sortInfo.sortDir = sortInfo.sortDir;

		this.cacheSortInfo();

		this.tripList = this.sortList(this.tripList);
	};

	/**
	 * handle the user selection of cancelling trips. Launch the cancel trips modal popup and re-initailize
	 * to refresh the page on completion
	 */
	public cancelTrips = async (): Promise<void> => {
		const tripsToCancel: TripsToCancel = this.tripSelection.map((trip: TripListItem) => ({
			tripId: trip.id,
			endTime: trip.endTimeEpoch,
		}));

		if (tripsToCancel.length > 0) {
			this.blockModalService
				.cancelTrips(this.authorityId, this.agencyId, this.blockId, tripsToCancel)
				.subscribe(async (cancelled: boolean) => {
					if (cancelled) {
						this.init();
					}
				});
		}
	};

	/**
	 * handle the user selection of activating trips. Launch the activate trips modal popup and re-initailize
	 * to refresh the page on completion
	 */
	public activateTrips = async (): Promise<void> => {
		const tripsToActivate: TripsToActivate = this.tripSelection.map((trip: TripListItem) => trip.id);

		if (tripsToActivate.length > 0) {
			this.blockModalService
				.activateTrips(this.authorityId, this.agencyId, this.blockId, tripsToActivate)
				.subscribe(async (activated: boolean) => {
					if (activated) {
						this.init();
					}
				});
		}
	};

	/**
	 * handle the user selection of cacnelling the block. Launch the cancel block modal popup and re-initailize
	 * to refresh the page on completion
	 */
	public cancelBlock = async (): Promise<void> => {
		this.blockModalService
			.cancelBlock(this.authorityId, this.agencyId, this.blockId, this.block.end_time_epoch)
			.subscribe(async (cancelled: boolean) => {
				if (cancelled) {
					this.init();
				}
			});
	};

	/**
	 * handle the user selection of activating the block. Launch the activate block modal popup and re-initailize
	 * to refresh the page on completion
	 */
	public activateBlock = async (): Promise<void> => {
		this.blockModalService.activateBlock(this.authorityId, this.agencyId, this.blockId).subscribe(async (activated: boolean) => {
			if (activated) {
				this.init();
			}
		});
	};

	/**
	 * handle check box selection change in the trips list and determine the button states to enable/disable accordingly
	 *
	 * @param selectedTrips - the trips selected by the user
	 */
	public onCheckSelect = (selectedTrips: TripList): void => {
		this.tripSelection = selectedTrips.filter((selectedTrip) => !selectedTrip.checkDisabled);

		this.determineTripButtonStates();
	};

	/**
	 * handle the user toggle of the block. When we toggle on the block we must add each route that is part of the block from
	 * our routeForBlock list. Inform our map route service to add/remove each route accordingly
	 */
	public toggleRenderBlock = async (): Promise<void> => {
		this.toggleBlockDisabled = true;

		if (this.routesForBlock.length <= 0) {
			return;
		}

		if (this.blockIsRendered()) {
			this.routesForBlock.forEach((route: Route) => {
				if (this.mapRoutesService.routeDisplayed(route.route_id)) {
					this.mapRoutesService.removeRoute(route.route_id);
				}
			});
		} else {
			const tasks: any = [];

			this.routesForBlock.forEach(async (route) => {
				if (!this.mapRoutesService.routeDisplayed(route.route_id)) {
					tasks.push(this.mapRoutesService.addRoute(route, false));
				}
			});

			await Promise.all(tasks);

			// when we add routes individually we tell the addRoute not to zoom to the route. Instead wait for all to finish
			// then zoom here
			this.zoomToBlock();
		}

		this.toggleBlockDisabled = false;
	};

	/**
	 * handle the zoom click trigger the zoom to the block (or route(s) for the block)
	 *
	 * if the block is already rendered - simply zoom to it. If not then render the block (the zoom will occur automatically when rendered)
	 */
	public zoomTo = (): void => {
		if (this.routesForBlock.length > 0) {
			if (!this.blockIsRendered()) {
				this.toggleRenderBlock();
			} else {
				this.zoomToBlock();
			}
		}
	};

	/**
	 * determine if the block (or every route within) is rendered
	 *
	 * @returns true when the block is rendered
	 */
	public blockIsRendered = (): boolean => {
		if (this.routesForBlock) {
			if (this.routesForBlock.length === 0) {
				return false;
			}

			return this.routesForBlock.every((route: Route) => this.mapRoutesService.routeDisplayed(route.route_id));
		}

		return false;
	};

	/**
	 * handle any clean up - unsusbribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * handle any initialization actions. load routes for the block and the block itself
	 */
	private init = async (): Promise<void> => {
		this.enableRefresh.emit(false);

		this.resetBlockDetails();

		// perform in init so we pick up potential new agency from ngOnChanges i.e when a vehicle for a different agency is selected
		this.loadAgencyDetails();

		// get the routes as a lookup for when display trips (we only know the route id)
		await this.loadRoutes();

		await this.loadBlock();

		if (this.block) {
			// show loading during init only - not during poll of data
			this.listLoadingIndicator = true;

			this.determineBlockDetails();

			this.determineTrips();

			this.determineRoutesForBlock();

			this.listLoadingIndicator = false;

			if (this.blockIsRendered()) {
				this.zoomToBlock();
			}

			this.initialized = true;
		} else {
			this.mapNavigationService.navigateToBlockList(this.authorityId);
		}

		this.enableRefresh.emit(true);
	};

	/**
	 * load required translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_BLOCKS_ASSIGNED_BLOCK_TOOLTIP',
			'T_MAP.MAP_BLOCKS_CANCELLED_BLOCK_TOOLTIP',
			'T_MAP.MAP_BLOCKS_ACTIVATE_BLOCK',
			'T_MAP.MAP_BLOCKS_CANCEL_BLOCK',
			'T_MAP.MAP_BLOCKS_UNASSIGNED_BLOCK_TOOLTIP',
			'T_MAP.MAP_FEATURE_DISABLED_TOOLTIP',
			'T_MAP.MAP_BLOCKS_CANCEL_TRIPS',
			'T_MAP.MAP_BLOCKS_ACTIVATE_TRIPS',
			'T_MAP.MAP_NO_CANCELLABLE_TRIPS_SELECTED',
			'T_MAP.MAP_CANCELLABLE_TRIPS_SELECTED',
			'T_MAP.MAP_NO_TRIPS_SELECTED',
			'T_MAP.MAP_TIME',
			'T_MAP.MAP_VIEW_ZOOM_TO',
			'T_MAP.MAP_ID',
			'T_MAP.MAP_ROUTE',
			'T_MAP.MAP_DIRECTION',
			'T_MAP.NO_BLOCK_TRIPS_INFO',
		]);
	};

	/**
	 * load the cache for the page (sort info for the trips list)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.sortInfo) {
			this.sortInfo = cacheContainer['sortInfo'];
		}
	};

	/**
	 * cache for sort details selected by the user
	 */
	private cacheSortInfo = (): void => {
		this.listCacheContainer['sortInfo'] = this.sortInfo;

		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * load the agency details and establish if trip cancellation is enabled for this agency
	 */
	private loadAgencyDetails = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.agencyId = selectedAgency.agency_id;
		this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

		// There is a flaw here in that we are deciding which features are enabled based on the users default agency
		// and not the currently selected agency. We can't just pass is the currently selected agency as the agency are
		// only loaded for the default agency so a bigger change is needed. The reality is users only have a single agency
		// so it's not really a problem. Also features are generally enabled the same for all agencies anyway. Leaving for now
		// as this code has been like this for some time (prior to refactor)
		const primaryAgencyId: number = this.currentUserUtilService.getCurrentUserPrimaryAgencyId();

		this.blockCancellationFeatureEnabled = this.agenciesDataService.isFeatureEnabled(
			primaryAgencyId,
			agencyConstants.blockCancellations
		);

		this.tripCancellationFeatureEnabled = this.agenciesDataService.isFeatureEnabled(primaryAgencyId, agencyConstants.tripCancellations);
	};

	/**
	 * sort the trips list by id
	 *
	 * @param tripList - the trip list to sort
	 * @returns the sorted trip list
	 */
	private sortByTripId = (tripList: TripList): TripList => {
		return tripList.sort((aItem: TripListItem, bItem: TripListItem) => aItem.id.localeCompare(bItem.id));
	};

	/**
	 * sort the trips list by route
	 *
	 * @param tripList - the trip list to sort
	 * @returns the sorted trip list
	 */
	private sortByRoute = (tripList: TripList): TripList => {
		return tripList.sort((aItem: TripListItem, bItem: TripListItem) => aItem.routeId.localeCompare(bItem.routeId));
	};

	/**
	 * sort the trips list by direction
	 *
	 * @param tripList - the trip list to sort
	 * @returns the sorted trip list
	 */
	private sortByDirection = (tripList: TripList): TripList => {
		return tripList.sort((aItem: TripListItem, bItem: TripListItem) => aItem.direction.localeCompare(bItem.direction));
	};

	/**
	 * sort the trips list by time
	 *
	 * @param tripList - the trip list to sort
	 * @returns the sorted trip list
	 */
	private sortByTime = (tripList: TripList): TripList => {
		return tripList.sort((aItem: TripListItem, bItem: TripListItem) => aItem.endTimeEpoch - bItem.endTimeEpoch);
	};

	/**
	 * main entry point for the list sort
	 *
	 * @param tripList  - the trip list to sort
	 * @returns the sorted trip list
	 */
	private sortList = (tripList: TripList): TripList => {
		switch (this.sortInfo.sort) {
			case this.tripIdColumnName: {
				tripList = this.sortByTripId(tripList);
				break;
			}
			case this.tripRouteColumnName: {
				tripList = this.sortByRoute(tripList);
				break;
			}
			case this.tripDirectionColumnName: {
				tripList = this.sortByDirection(tripList);
				break;
			}
			case this.tripTimeColumnName: {
				tripList = this.sortByTime(tripList);
				break;
			}
		}

		if (this.sortInfo.sortDir === SortDirection.desc) {
			tripList = tripList.reverse();
		}

		return tripList;
	};

	/**
	 * reset the block details
	 */
	private resetBlockDetails = (): void => {
		this.blockLoaded = false;

		this.block = null;
		this.routesForBlock = null;

		this.blockTimeRangeDisplay = null;

		this.tripSelection = [];
	};

	/**
	 * build the trips list columns for our data table
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: this.tripIdColumnName,
				displayName: this.translations['T_MAP.MAP_ID'],
				columnType: ColumnType.text,
				width: 90,
			},
			{
				name: this.tripRouteColumnName,
				displayName: this.translations['T_MAP.MAP_ROUTE'],
				componentTemplate: this.routePillColumnTemplate,
				columnType: ColumnType.component,
				width: 110,
			},
			{
				name: this.tripDirectionColumnName,
				displayName: this.translations['T_MAP.MAP_DIRECTION'],
				columnType: ColumnType.text,
				width: 115,
			},
			{
				name: this.tripCancelledColumnName,
				displayName: '',
				columnType: ColumnType.cssClass,
				sortable: false,
				fixedWidth: 30,
			},
			{
				name: this.tripTimeColumnName,
				displayName: this.translations['T_MAP.MAP_TIME'],
				columnType: ColumnType.text,
				width: 115,
			},
		];
	};

	/**
	 * handle the refresh button and re-initaize the page.
	 *
	 * as we dont support block details in replay mode, revert to the menu when in that mode
	 */
	private handleNavigateRefresh = async (): Promise<void> => {
		if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
			this.mapNavigationService.navigateToMenu();
		} else {
			this.init();
		}
	};

	/**
	 * determine various states for the page
	 */
	private determineBlockDetails = (): void => {
		this.determineBlockTimeRangeDisplay();
		this.determineBlockButtonState();
		this.determineTripButtonStates();
	};

	/**
	 * determine the block time range display for the template
	 */
	private determineBlockTimeRangeDisplay = (): void => {
		if (this.block.start_time && this.block.end_time) {
			this.blockTimeRangeDisplay = TimeHelpers.formatTimeRange(this.block.start_time, this.block.end_time, this.agencyTimezone);
		}
	};

	/**
	 * determine the button states based on the state of the block
	 */
	private determineBlockButtonState = (): void => {
		if (this.blockCancellationFeatureEnabled) {
			switch (this.block.status) {
				case BlockStatus.assigned:
					this.enableCancelBlock = false;
					this.cancelBlockTooltip = this.translations['T_MAP.MAP_BLOCKS_ASSIGNED_BLOCK_TOOLTIP'];
					this.enableActivateBlock = false;
					this.activateBlockTooltip = this.translations['T_MAP.MAP_BLOCKS_ASSIGNED_BLOCK_TOOLTIP'];
					break;
				case BlockStatus.canceled:
					this.enableCancelBlock = false;
					this.cancelBlockTooltip = this.translations['T_MAP.MAP_BLOCKS_CANCELLED_BLOCK_TOOLTIP'];
					this.enableActivateBlock = true;
					this.activateBlockTooltip = this.translations['T_MAP.MAP_BLOCKS_ACTIVATE_BLOCK'];
					break;
				case BlockStatus.unassigned:
					this.enableCancelBlock = true;
					this.cancelBlockTooltip = this.translations['T_MAP.MAP_BLOCKS_CANCEL_BLOCK'];
					this.enableActivateBlock = false;
					this.activateBlockTooltip = this.translations['T_MAP.MAP_BLOCKS_UNASSIGNED_BLOCK_TOOLTIP'];
					break;
			}
		} else {
			this.enableCancelBlock = false;
			this.cancelBlockTooltip = this.translations['T_MAP.MAP_FEATURE_DISABLED_TOOLTIP'];
			this.enableActivateBlock = false;
			this.activateBlockTooltip = this.translations['T_MAP.MAP_FEATURE_DISABLED_TOOLTIP'];
		}
	};

	/**
	 * determine trip button states based the state of the trips selected
	 */
	private determineTripButtonStates = (): void => {
		this.cancelTripsTooltip = this.translations['T_MAP.MAP_BLOCKS_CANCEL_TRIPS'];
		this.activateTripsTooltip = this.translations['T_MAP.MAP_BLOCKS_ACTIVATE_TRIPS'];

		this.enableCancelTrips = false;
		this.enableActivateTrips = false;

		let allTripsCancelled: boolean = true;

		if (this.tripCancellationFeatureEnabled) {
			if (this.tripSelection.length > 0) {
				this.tripSelection.forEach((trip) => {
					if (!trip.cancelled) {
						allTripsCancelled = false;
					}
				});

				if (allTripsCancelled) {
					this.enableActivateTrips = true;
					this.cancelTripsTooltip = this.translations['T_MAP.MAP_NO_CANCELLABLE_TRIPS_SELECTED'];
				} else {
					this.enableCancelTrips = true;
					this.activateTripsTooltip = this.translations['T_MAP.MAP_CANCELLABLE_TRIPS_SELECTED'];
				}
			} else {
				this.cancelTripsTooltip = this.translations['T_MAP.MAP_NO_TRIPS_SELECTED'];
				this.activateTripsTooltip = this.translations['T_MAP.MAP_NO_TRIPS_SELECTED'];
			}
		} else {
			this.cancelTripsTooltip = this.translations['T_MAP.MAP_FEATURE_DISABLED_TOOLTIP'];
			this.activateTripsTooltip = this.translations['T_MAP.MAP_FEATURE_DISABLED_TOOLTIP'];
		}
	};

	/**
	 * prepare the destination field for the trips list, removing the 'to' from the text
	 * @param destination - the destination
	 * @returns the destination with the 'to' stripped out
	 */
	private getDestination = (destination: string): string => {
		destination = destination.replace('to ', '');
		destination = destination.replace('To ', '');
		destination = destination.replace('TO ', '');

		return destination;
	};

	/**
	 * determine the full route from the routes for this block using the route id
	 *
	 * @param routeId - the route id to find
	 * @returns the route
	 */
	private determineRoute = (routeId: string): Route => {
		return this.routesForBlock.find((retrievedRoute: Route) => retrievedRoute.route_id === routeId);
	};

	/**
	 * determine if a route has completed
	 *
	 * @param endTimeEpoch - the trip end time to compare against
	 * @returns true if the trip has completed
	 */
	private isCompletedTrip = (endTimeEpoch: number): boolean => {
		return endTimeEpoch <= Date.now();
	};

	/**
	 * determine routes for the block. Build a list of routes for the block. Whilst typically one the one route, the block
	 * may span 2 or more routes.
	 */
	private determineRoutesForBlock = (): void => {
		// Use a set so that the routeIds collection contains a distinct collection of route identifiers for this block
		const routeIds: Set<string> = new Set(this.block.tripDetails?.map((trip) => trip.routeId));

		this.routesForBlock = this.routes.filter((route) => routeIds.has(route.route_id));
	};

	/**
	 * determine the trips for the block and build the list ready for our datatable
	 */
	private determineTrips = async (): Promise<void> => {
		const tripList: TripList = [];

		// not sure if we ever get a block with no trips but original code had this check
		if (this.block.tripDetails) {
			this.block.tripDetails.forEach((trip: TripData) => {
				const tripListItem: TripListItem = {
					id: trip.id,
					routeId: trip.routeId,
					direction: this.getDestination(trip.destination),
					time: TimeHelpers.formatTimeRange(trip.startTime, trip.endTime, this.agencyTimezone),
					cancelled: trip.cancelled,
					endTimeEpoch: trip.endTimeEpoch,
					cancelledIcon: this.getCancelledIconDisplay(trip.cancelled),
					rowClass: this.getRowClass(trip.active, trip.endTimeEpoch),
					checkDisabled: this.isTripDisabled(trip.active, trip.endTimeEpoch),
				};

				tripList.push(tripListItem);
			});

			this.tripList = this.sortList(tripList);
		}
	};

	/**
	 * determine if a trip (row) should be disabled
	 *
	 * @param active - whether the trip is currently active
	 * @param endTimeEpoch - the end time to check against
	 * @returns true if the trip should be disabled
	 */
	private isTripDisabled = (active: boolean, endTimeEpoch: number): boolean => {
		return !active && this.isCompletedTrip(endTimeEpoch);
	};

	/**
	 * determine the icon for the trip if the trip is cancelled (otherwise no icon)
	 * @param cancelled - whether the trip is cancelled
	 * @returns the style details for the icon
	 */
	private getCancelledIconDisplay = (cancelled: boolean): CssClassType => {
		const classType: CssClassType = {
			className: cancelled ? 'fa fa-minus-circle blocks-cancel-icon blocks-cancel-icon-seperator' : null,
			value: null,
			tooltip: this.translations['T_MAP.MAP_BLOCKS_CANCELLED_BLOCK_TOOLTIP'],
		};

		return classType;
	};

	/**
	 * get the row style details basedon whether the trip active or cancelled (or otherwise pending)
	 *
	 * @param active - whether the trip is active
	 * @param endTimeEpoch - the trip end time
	 * @returns the style class for the row
	 */
	private getRowClass = (active: boolean, endTimeEpoch: number): string => {
		let rowClass: string = '';

		if (active) {
			rowClass = this.activeTripClass;
		} else {
			if (!this.isCompletedTrip(endTimeEpoch)) {
				rowClass = this.futureTripClass;
			}
		}

		return rowClass;
	};

	/**
	 * load extended routes. Routes are needed to lookup routes associated with the block. Extended routes are needed
	 * which include the stops/shape data for when we render the block/route(s)
	 */
	private loadRoutes = async (): Promise<void> => {
		const result: ResultContent = await this.routesDataService.getRoutesExtended(this.authorityId, this.agencyId);

		if (result.success) {
			this.routes = result.resultData;
		}
	};

	/**
	 * load the block from our data service
	 */
	private loadBlock = async (): Promise<void> => {
		const result: ResultContent = await this.blocksDataService.getBlock(this.authorityId, this.agencyId, this.blockId);

		if (result.success) {
			const block: Block = result.resultData;

			this.block = block;

			this.blockLoaded = true;
		}

		this.lastBlockPollSuccess = result.success;
	};

	/**
	 * handle the refresh of the page - triggered from our poll subscription
	 */
	private handleRefreshBlockDetails = async (): Promise<void> => {
		// make sure we are initialized so we don't attempt a refresh at the same time
		if (this.initialized) {
			// essentially stop updates if the block isn't returned the last time.
			// this handles an edge case where the block isn't returned and we keep polling with the error
			// a block for one day may not exist for the next and the back end will return an error)
			if (this.lastBlockPollSuccess) {
				this.enableRefresh.emit(false);

				await this.loadBlock();

				this.determineBlockDetails();

				this.determineTrips();

				this.determineRoutesForBlock();

				this.enableRefresh.emit(true);
			}
		}
	};

	/**
	 * zoom to the block or to be precise, the route(s) for the block
	 */
	private zoomToBlock = (): void => {
		const routeIds: Array<string> = this.routesForBlock.map((route: Route) => route.nb_id);

		this.mapLocationService.setRoutesToLocate(routeIds);
	};

	/**
	 * handle any subsbriptions.
	 *
	 * refresh - the click of the refresh icon contained within the parent
	 *
	 * poll - the regular poll update triggered by the polling service
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.handleNavigateRefresh();
		});

		this.refreshPoll$Subscription = this.mapEventsService.pollRefresh.subscribe(async () => {
			await this.handleRefreshBlockDetails();
		});
	};

	/**
	 * unsubscribe from our subscriptions for clean up
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
		this.refreshPoll$Subscription?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { IWidgetEditModalComponent } from '../../../types/types';

@Component({
	selector: 'deadheading-vehicles-edit',
	templateUrl: './deadheading-vehicles-edit.component.html',
	styleUrls: ['./deadheading-vehicles-edit.component.scss'],
})
export class DeadheadingVehiclesEditComponent extends WidgetEditBaseComponent implements IWidgetEditModalComponent, OnInit, OnDestroy {
	constructor(
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * performs initialization tasks for the deadheading vehicles edit compoenent - building the form and setting up subscriptions
	 */
	public async ngOnInit(): Promise<void> {
		this.buildForm(() => {}, {});

		await this.setupAgencies(this.agencyChanged);

		this.setSubscriptions();
		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public agencyChanged = (): void => {
		this.handleAgencyChanged(
			() => {},
			() => {}
		);
	};
}

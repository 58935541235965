<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_MESSAGES.CREATE_VEHICLE_MESSAGE' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<div class="nb-search-grid-inner">
		<div *ngIf="!loaded">
			<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
		</div>
		<div *ngIf="loaded" class="nb-messages-control">
			<div class="nb-view-table">
				<div class="nb-view-table-heading">{{ 'T_MESSAGES.GENERAL' | translate }}</div>
				<div class="nb-view-table-content">
					<div class="form-group">
						<field-label [name]="'template'" [label]="'T_MESSAGES.CHOOSE_TEMPLATE' | translate" [required]="false"></field-label>
						<select class="form-control" [(ngModel)]="selectedTemplate" (change)="templateSelected()" data-test="template.selection">
							<option value="placeholder" disabled selected>{{ 'T_MESSAGES.PRE' | translate }}</option>
							<option *ngFor="let template of templates" [ngValue]="template">{{ template }}</option>
						</select>
					</div>
					<div class="form-group">
						<field-label [name]="'type'" [label]="'T_MESSAGES.MESSAGE' | translate" [required]="true"></field-label>
						<textarea
							#selectedMessageControl
							class="nb-textarea form-control"
							[(ngModel)]="selectedMessage"
							data-test="message.input"
							maxlength="255">
						</textarea>
						<div class="nb-character-count" data-test="message.count">
							{{ selectedMessageMaxLength - selectedMessage.length }} {{ 'T_MESSAGES.REMAIN' | translate }}
						</div>
						<div class="field-error" *ngIf="selectedMessage.length === 0">
							<p>{{ 'T_CORE.FORM.ERRORS.REQUIRED' | translate }}</p>
						</div>
					</div>

					<div class="form-group">
						<div class="nb-form-control">
							<div class="nb-checkbox driver-must-ack-container">
								<label for="driverMustAck">
									<input [(ngModel)]="driverMustAck" id="driverMustAck" type="checkbox" />
									<div class="checkbox-label">
										{{ 'T_MESSAGES.DRIVER_MUST_ACK' | translate }}
									</div>
								</label>
							</div>
						</div>
					</div>
					<div class="form-group">
						<mat-radio-group
							[(ngModel)]="vehicleSelectionType"
							aria-labelledby="vehicle-selection-group-label"
							class="vehicle-selection-group">
							<mat-radio-button class="vehicle-selection-button" [value]="'allVehicles'">
								<span class="vehicle-selection-text">{{ 'T_MESSAGES.ALL_ACTIVE_VEHICLES' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button class="vehicle-selection-button" [value]="'specificVehicles'">
								<span class="vehicle-selection-text">{{ 'T_MESSAGES.SPECIFIC_VEHICLES' | translate }}</span>
							</mat-radio-button>

							<div class="col-xs-12" *ngIf="vehicleSelectionType === 'specificVehicles'">
								<div class="form-group">
									<multi-select [settings]="vehicleSettings" [items]="vehicles" [selected]="selectedVehicles"> </multi-select>
								</div>
							</div>

							<mat-radio-button class="vehicle-selection-button" [value]="'specificDepots'">
								<span class="vehicle-selection-text">{{ 'T_MESSAGES.SPECIFIC_DEPOTS' | translate }}</span>
							</mat-radio-button>

							<div class="col-xs-12" *ngIf="vehicleSelectionType === 'specificDepots'">
								<multi-select [settings]="depotSettings" [items]="depots" [selected]="selectedDepots"> </multi-select>
							</div>
						</mat-radio-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="loaded" align="center">
	<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-secondary" (click)="save()" [disabled]="!saveEnabled()">{{ 'T_MESSAGES.SEND' | translate }}</button>
</mat-dialog-actions>

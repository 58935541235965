/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { ReportViewerSortableSubListBaseComponent } from '../../../report-viewer-sortable-sublist-base.component';

import { ReportsConfigService } from '../../../../../../services/reports-config.service';
import { ReportsGenerationService } from '../../../../../../services/reports-generation.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { ReportRoute, InteractiveReportHeadwayByStopDetail } from '../../../../../../types/api-types';
import { HeadwayDetail, ReportTemplateSummaryContent } from '../../../../../../types/types';

@Component({
	selector: 'hw100-headway-by-stop-sub-list',
	templateUrl: './headway-by-stop-sub-list.component.html',
	styleUrls: ['./headway-by-stop-sub-list.component.scss'],
})
export class HeadwayByStopSubListComponent extends ReportViewerSortableSubListBaseComponent implements OnInit {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = null;

	constructor(
		private reportsGenerationService: ReportsGenerationService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * performs initialization tasks for the headway by stops sub list view (HW100)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * generates the route part of the report data
	 *
	 * @param headwayByStopDetail - the headway by stops data
	 * @param stopId - the stop id
	 */
	public generateReport = (headwayByStopDetail: InteractiveReportHeadwayByStopDetail, stopId: string): void => {
		// HW-200 report doesn't support the Custom option
		if (this.templateSummaryContent.template.date_options.day.type !== '2') {
			const route: ReportRoute = {
				authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
				route_id: headwayByStopDetail.route_id,
				route_short_name: headwayByStopDetail.route_short_name,
				route_long_name: headwayByStopDetail.route_long_name,
				trip_headsign: headwayByStopDetail.headsign,
			};

			this.reportsGenerationService.generateHeadwayDetailRouteReport(
				HeadwayDetail,
				this.templateSummaryContent.template,
				route,
				stopId
			);
		}
	};
}

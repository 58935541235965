import { Injectable } from '@angular/core';

@Injectable()
export class ColorUtilityService {
	/**
	 * returns a converted color in #RRGGBB string format
	 *
	 * @param color - the color value to convert
	 * @returns the color in string (hex format)
	 */
	public getColor = (color: any): string => {
		if (color) {
			return isNaN(color) ? color : this.decimalToHex(color);
		} else {
			return '#000000';
		}
	};

	/**
	 * converts a decimal color in to a #RRGGBB string format
	 *
	 * @param value - the color value to convert
	 * @returns the color in string (hex format)
	 */
	public decimalToHex = (value: number): string => {
		const htmlColor: string = value.toString(16);

		// Template adds # for standard HTML #RRGGBB
		return '#000000'.substring(0, 7 - htmlColor.length) + htmlColor;
	};
}

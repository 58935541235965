<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_CORE.FILTERS' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<div *ngIf="agencies.length > 1" class="form-group" [ngClass]="{ 'has-error': !agencySelected }">
		<field-label [name]="'agency'" [label]="'T_CORE.AGENCY' | translate" [required]="true"></field-label>
		<multi-select
			[settings]="angencySelectSettings"
			[items]="agencies"
			[selected]="selectedAgencies"
			(itemClickedEvent)="agencyItemClicked()"
			(removeItemClicked)="agencyItemClicked()">
		</multi-select>
		<div class="field-error" *ngIf="!agencySelected">
			<p>{{ 'T_CORE.FORM.ERRORS.REQUIRED' | translate }}</p>
		</div>
	</div>
	<div class="form-group">
		<field-label [name]="'status'" [label]="'T_CORE.STATUS' | translate" [required]="false"></field-label>
		<multi-select [settings]="statusesSelectSettings" [items]="statuses" [selected]="selectedStatuses"> </multi-select>
	</div>
	<div class="form-group">
		<field-label [name]="'roles'" [label]="'T_CORE.ROLES' | translate" [required]="false"></field-label>
		<multi-select [settings]="roleSettings" [items]="roles" [selected]="selectedRoles"> </multi-select>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="cancel()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-secondary" (click)="save()" [disabled]="!agencySelected">{{ 'T_CORE.SAVE' | translate }}</button>
</mat-dialog-actions>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AgencyPortalUtilsModule } from '../../../utils/agency-portal-utils.module';

import { UserAdminListComponent } from './list/user-admin-list.component';
import { ChangePasswordComponent } from './password/change-password/change-password.component';
import { UserStatusChangeComponent } from './shared/user-status-change/user-status-change.component';
import { UserAdminDetailEditComponent } from './user-profile/edit/detail-edit/user-admin-detail-edit.component';
import { UserAdminProfileEditComponent } from './user-profile/edit/user-admin-profile-edit.component';
import { UserAdminListFilterComponent } from './list/filter/user-admin-list-filter.component';
import { SetPasswordComponent } from './password/shared/set-password/set-password.component';
import { UserAdminProfileComponent } from './user-profile/view/user-admin-profile.component';
import { CreatePasswordComponent } from './password/create-password/create-password.component';
import { ResetPasswordConfirmComponent } from './password/reset-password/confirm/reset-password-confirm.component';
import { ResetPasswordEntryComponent } from './password/reset-password/entry/reset-password-entry.component';
import { ResetPasswordRequestComponent } from './password/reset-password/request/reset-password-request.component';
import { ResetPasswordComponent } from './password/reset-password/reset-password.component';
import { UserAdminRoleEditComponent } from './user-profile/edit/role-edit/user-admin-role-edit.component';

import { UsersAdminApiService } from './services/users-admin-api.service';
import { UsersAdminDataService } from './services/users-admin-data.service';
import { UsersAdminEventsService } from './services/users-admin-events.service';
import { UsersAdminModalService } from './services/users-admin-modal.service';
import { UsersAdminUtilService } from './services/users-admin-utils.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [
		ChangePasswordComponent,
		UserStatusChangeComponent,
		UserAdminDetailEditComponent,
		UserAdminProfileEditComponent,
		UserAdminListFilterComponent,
		UserAdminListComponent,
		SetPasswordComponent,
		UserAdminProfileComponent,
		UserAdminRoleEditComponent,
		ResetPasswordComponent,
		ResetPasswordEntryComponent,
		ResetPasswordConfirmComponent,
		ResetPasswordRequestComponent,
		CreatePasswordComponent,
	],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgencyPortalUtilsModule,
	],
	providers: [
		UsersAdminEventsService,
		UsersAdminDataService,
		UsersAdminUtilService,
		UsersAdminApiService,
		UsersAdminModalService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AdminToolsUsersModule {}

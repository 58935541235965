/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';

import { ReportsUtilService } from './reports-util.service';
import { ReportsConfigService } from './reports-config.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { CurrentUserPermissionService } from '../../../support-features/login/services/current-user/current-user-permission.service';

import { UserLogin } from '../../../support-features/login/types/api-types';

import {
	ActionAllowedType,
	AvailableReportsList,
	AvailableReportsListItem,
	ReportTemplateDisplayDetail,
	ReportTemplateDisplayDetails,
} from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class ReportsStateService {
	private readonly interactive: string = 'interactive';
	private readonly csv: string = 'csv';
	private readonly finished: string = 'finished';
	private readonly failed: string = 'failed';
	private readonly running: string = 'running';
	private readonly deleteReportTemplatePermission: string = 'delete_reports_templates_templateid';
	private readonly postReportTemplatePermission: string = 'post_reports_templates';
	private readonly deleteAvailableReportPermission: string = 'delete_reports_stored_reportid';

	constructor(
		private reportsUtilService: ReportsUtilService,
		private reportsConfigService: ReportsConfigService,
		private currentUserUtilService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService
	) {}

	/**
	 * determines whether or not the user can create reports
	 *
	 * @returns whether the user can create reports
	 */
	public canCreateReports = (): ActionAllowedType => {
		// Anyone can create reports
		return ActionAllowedType.allowed;
	};

	/**
	 * determines whether or not the user can view available report details that are in the supplied report state
	 *
	 * @param status - the report state
	 * @returns whether the user can view available reports
	 */
	public canViewAvailableReportDetail = (status: string): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (!this.isFinishedOrFailed(status)) {
			actionAllowed = ActionAllowedType.invalidStatus;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can view available report details that have been selected
	 *
	 * @param reports - the selected available reports
	 * @returns whether the user can view available reports
	 */
	public canViewAvailableReportsDetails = (reports: AvailableReportsList): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.noneSelected(reports)) {
			actionAllowed = ActionAllowedType.noReportSelected;
		} else if (this.moreThanOneSelected(reports)) {
			actionAllowed = ActionAllowedType.tooManySelected;
		} else {
			const selectedReport: AvailableReportsListItem = reports[0];

			if (!this.reportConfigAvailable(selectedReport.defaultTemplateId)) {
				actionAllowed = ActionAllowedType.notEnabled;
			} else {
				actionAllowed = this.canViewAvailableReportDetail(selectedReport.rawStatus);
			}
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can view the available report using status and type details
	 *
	 * @param status - the selected available report status
	 * @param outputType - the type of available report
	 * @returns whether the user can view the available report
	 */
	public canViewAvailableReport = (status: string, outputType: string): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (!this.isFinishedInteractive(status, outputType)) {
			actionAllowed = ActionAllowedType.invalidStatus;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can view available reports that have been selected
	 *
	 * @param reports - the selected available reports
	 * @returns whether the user can view available reports
	 */
	public canViewAvailableReports = (reports: AvailableReportsList): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.noneSelected(reports)) {
			actionAllowed = ActionAllowedType.noReportSelected;
		} else if (this.moreThanOneSelected(reports)) {
			actionAllowed = ActionAllowedType.tooManySelected;
		} else {
			const selectedReport: AvailableReportsListItem = reports[0];

			if (!this.reportConfigAvailable(selectedReport.defaultTemplateId)) {
				actionAllowed = ActionAllowedType.notEnabled;
			} else {
				actionAllowed = this.canViewAvailableReport(selectedReport.rawStatus, selectedReport.rawOutputType);
			}
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can download the available report using status and type details
	 *
	 * @param status - the selected available report status
	 * @param outputType - the type of available report
	 * @returns whether the user can download the available report
	 */
	public canDownloadAvailableReport = (status: string, outputType: string): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (!this.isFinishedCSV(status, outputType)) {
			actionAllowed = ActionAllowedType.invalidStatus;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can download available reports that have been selected
	 *
	 * @param reports - the selected available reports
	 * @returns whether the user can download available reports
	 */
	public canDownloadAvailableReports = (reports: AvailableReportsList): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.noneSelected(reports)) {
			actionAllowed = ActionAllowedType.noReportSelected;
		} else if (this.moreThanOneSelected(reports)) {
			actionAllowed = ActionAllowedType.tooManySelected;
		} else {
			const selectedReport: AvailableReportsListItem = reports[0];

			if (!this.reportConfigAvailable(selectedReport.defaultTemplateId)) {
				actionAllowed = ActionAllowedType.notEnabled;
			} else {
				actionAllowed = this.canDownloadAvailableReport(selectedReport.rawStatus, selectedReport.rawOutputType);
			}
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can edit the available report using supplied details
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param status - the report state
	 * @param outputType - the output type of report
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param createdById - the created by id
	 * @returns whether the user can edit the available report
	 */
	public canEditAvailableReport = (
		defaultTemplateId: string,
		status: string,
		outputType: string,
		authorityId: string,
		agencyId: string,
		createdById: number
	): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		// Assumption that if the user has delete then they also can edit
		const editPermissions: Array<string> = [this.deleteAvailableReportPermission, this.postReportTemplatePermission];

		if (!this.isFeatureEnabled(defaultTemplateId)) {
			actionAllowed = ActionAllowedType.notEnabled;
		} else if (!this.userAllowed(createdById, editPermissions, authorityId, agencyId)) {
			actionAllowed = ActionAllowedType.noPermission;
		} else if (!this.isFinishedInteractive(status, outputType)) {
			actionAllowed = ActionAllowedType.invalidStatus;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can edit available reports that have been selected
	 *
	 * @param reports - the selected available reports
	 * @returns whether the user can edit available reports
	 */
	public canEditAvailableReports = (reports: AvailableReportsList): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.noneSelected(reports)) {
			actionAllowed = ActionAllowedType.noReportSelected;
		} else if (this.moreThanOneSelected(reports)) {
			actionAllowed = ActionAllowedType.tooManySelected;
		} else {
			const selectedReport: AvailableReportsListItem = reports[0];

			if (!this.reportConfigAvailable(selectedReport.defaultTemplateId)) {
				actionAllowed = ActionAllowedType.notEnabled;
			} else {
				actionAllowed = this.canEditAvailableReport(
					selectedReport.defaultTemplateId,
					selectedReport.rawStatus,
					selectedReport.rawOutputType,
					selectedReport.authorityId,
					selectedReport.agencyId,
					selectedReport.createdById
				);
			}
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can delete the available report using supplied details
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param createdById - the created by id
	 * @param status - the report state
	 * @returns whether the user can delete the available report
	 */
	public canDeleteAvailableReport = (authorityId: string, agencyId: string, createdById: number, status: string): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (!this.userAllowed(createdById, [this.deleteAvailableReportPermission], authorityId, agencyId)) {
			actionAllowed = ActionAllowedType.noPermission;
		} else if (this.isRunning(status)) {
			actionAllowed = ActionAllowedType.invalidStatus;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can delete available reports that have been selected
	 *
	 * @param reports - the selected available reports
	 * @returns whether the user can delete available reports
	 */
	public canDeleteAvailableReports = (reports: AvailableReportsList): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.atLeastOneSelected(reports)) {
			// This uses the position of the allowed type in the enumeration to determine priority
			// of any reason why this action is disabled - so types at the end of the enumeration take precedence
			reports.forEach((report: AvailableReportsListItem) => {
				const action: ActionAllowedType = this.canDeleteAvailableReport(
					report.authorityId,
					report.agencyId,
					report.createdById,
					report.status
				);

				actionAllowed = action > actionAllowed ? action : actionAllowed;
			});
		} else {
			actionAllowed = ActionAllowedType.noReportSelected;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can view the report templates
	 *
	 * @param templates - the report templates
	 * @returns whether the user can view report templates
	 */
	public canViewReportTemplates = (templates: ReportTemplateDisplayDetails): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.noneSelected(templates)) {
			actionAllowed = ActionAllowedType.noReportSelected;
		} else if (this.moreThanOneSelected(templates)) {
			actionAllowed = ActionAllowedType.tooManySelected;
		} else {
			const selectedTemplate: ReportTemplateDisplayDetail = templates[0];

			if (!this.reportConfigAvailable(selectedTemplate.defaultTemplateId)) {
				actionAllowed = ActionAllowedType.notEnabled;
			}
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can edit the report template
	 *
	 * @param defaultTemplateId - the default report template id
	 * @param createdById - the created by is
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns whether the user can edit the report template
	 */
	public canEditReportTemplate = (
		defaultTemplateId: string,
		createdById: number,
		authorityId: string,
		agencyId: string
	): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		// Assumption that if the user has delete then they also can edit
		const editPermissions: Array<string> = [this.deleteReportTemplatePermission, this.postReportTemplatePermission];

		if (!this.isFeatureEnabled(defaultTemplateId)) {
			actionAllowed = ActionAllowedType.notEnabled;
		} else if (!this.userAllowed(createdById, editPermissions, authorityId, agencyId)) {
			actionAllowed = ActionAllowedType.noPermission;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can edit the report templates
	 *
	 * @param templates - the report templates
	 * @returns whether the user can edit report templates
	 */
	public canEditReportTemplates = (templates: ReportTemplateDisplayDetails): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.noneSelected(templates)) {
			actionAllowed = ActionAllowedType.noReportSelected;
		} else if (this.moreThanOneSelected(templates)) {
			actionAllowed = ActionAllowedType.tooManySelected;
		} else {
			const selectedTemplate: ReportTemplateDisplayDetail = templates[0];

			if (!this.reportConfigAvailable(selectedTemplate.defaultTemplateId)) {
				actionAllowed = ActionAllowedType.notEnabled;
			} else {
				actionAllowed = this.canEditReportTemplate(
					selectedTemplate.defaultTemplateId,
					selectedTemplate.createdById,
					selectedTemplate.authorityId,
					selectedTemplate.agencyId
				);
			}
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can run the report template
	 *
	 * @param defaultTemplateId - the default report template id
	 * @returns whether the user can run the report template
	 */
	public canRunReportTemplate = (defaultTemplateId: string): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		// Everyone is allowed to run a report so long as the feature is enabled for the report being ran
		if (!this.isFeatureEnabled(defaultTemplateId)) {
			actionAllowed = ActionAllowedType.notEnabled;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can run the report templates
	 *
	 * @param templates - the report templates
	 * @returns whether the user can run report templates
	 */
	public canRunReportTemplates = (templates: ReportTemplateDisplayDetails): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.atLeastOneSelected(templates)) {
			// This uses the position of the allowed type in the enumeration to determine priority
			// of any reason why this action is disabled - so types at the end of the enumeration take precedence
			templates.forEach((report: ReportTemplateDisplayDetail) => {
				if (!this.reportConfigAvailable(report.defaultTemplateId)) {
					actionAllowed = ActionAllowedType.notEnabled;
				} else {
					const action: ActionAllowedType = this.canRunReportTemplate(report.defaultTemplateId);

					actionAllowed = action > actionAllowed ? action : actionAllowed;
				}
			});
		} else {
			actionAllowed = ActionAllowedType.noReportSelected;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can delete the report template
	 *
	 * @param createdById - the created by id
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns whether the user can delete the report template
	 */
	public canDeleteReportTemplate = (createdById: number, authorityId: string, agencyId: string): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (!this.userAllowed(createdById, [this.deleteReportTemplatePermission], authorityId, agencyId)) {
			actionAllowed = ActionAllowedType.noPermission;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can delete the report templates
	 *
	 * @param templates - the report templates
	 * @returns whether the user can delete report templates
	 */
	public canDeleteReportTemplates = (templates: ReportTemplateDisplayDetails): ActionAllowedType => {
		let actionAllowed: ActionAllowedType = ActionAllowedType.allowed;

		if (this.atLeastOneSelected(templates)) {
			// This uses the position of the allowed type in the enumeration to determine priority
			// of any reason why this action is disabled - so types at the end of the enumeration take precedence
			templates.forEach((report: ReportTemplateDisplayDetail) => {
				const action: ActionAllowedType = this.canDeleteReportTemplate(report.createdById, report.authorityId, report.agencyId);

				actionAllowed = action > actionAllowed ? action : actionAllowed;
			});
		} else {
			actionAllowed = ActionAllowedType.noReportSelected;
		}

		return actionAllowed;
	};

	/**
	 * determines whether or not the user can save the report template
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns whether the user can save the report template
	 */
	public canSaveReportTemplate = (authorityId: string, agencyId: string): boolean => {
		return this.currentUserPermissionService.agencyHasPermissionsTo(
			[this.deleteReportTemplatePermission, this.postReportTemplatePermission],
			authorityId,
			agencyId
		);
	};

	/**
	 * determines whether the report is enabled for the supplied authority/agency
	 *
	 * @param defaultTemplateId - the default template id
	 * @returns whether the report is enabled for this agency
	 */
	public isFeatureEnabled = (defaultTemplateId: string): boolean => {
		return this.reportsUtilService.getAgencyReportCustomization(defaultTemplateId).featureEnabled;
	};

	/**
	 * determines whether the user is allowed to action the supplied permissions
	 *
	 * @param createdById - the created by id
	 * @param permissions - the permissions
	 * @param authorityId - the authority id
	 * @param agencyId  - the agency id
	 * @returns whether the user is allowed to action the supplied permissions
	 */
	private userAllowed = (createdById: number, permissions: Array<string>, authorityId: string, agencyId: string): boolean => {
		return (
			this.isReportOwner(createdById) || this.currentUserPermissionService.agencyHasPermissionsTo(permissions, authorityId, agencyId)
		);
	};

	/**
	 * determines whether the current logged in user is the report owner
	 *
	 * @param createdById - the created by id
	 * @returns whether the current logged in user is the report owner
	 */
	private isReportOwner = (createdById: number): boolean => {
		const userLogin: UserLogin = this.currentUserUtilService.getCurrentUser();

		return userLogin.nb_id === createdById;
	};

	/**
	 * determines whether there is any report configuration available for the supplied template
	 *
	 * @param defaultTemplateId - the default report template id
	 * @returns whether there is any report configuration available for the supplied template
	 */
	private reportConfigAvailable = (defaultTemplateId: string): boolean => {
		return (
			this.reportsConfigService.getReportConfiguration(defaultTemplateId) !== null &&
			this.reportsConfigService.getReportConfiguration(defaultTemplateId) !== undefined
		);
	};

	/**
	 * determines whether more than one report has been selected from the list
	 *
	 * @param reports - the selected reports
	 * @returns whether more than one report has been selected from the list
	 */
	private moreThanOneSelected = (reports: Array<any>): boolean => {
		return reports?.length > 1;
	};

	/**
	 * determines whether no reports have been selected from the list
	 *
	 * @param reports - the selected reports
	 * @returns whether no reports have been selected from the list
	 */
	private noneSelected = (reports: Array<any>): boolean => {
		return reports?.length === 0;
	};

	/**
	 * determines whether at least one report has been selected from the list
	 *
	 * @param reports - the selected reports
	 * @returns whether at least one report has been selected from the list
	 */
	private atLeastOneSelected = (reports: Array<any>): boolean => {
		return !this.noneSelected(reports);
	};

	/**
	 * determines whether the report is running or not
	 *
	 * @param reportStatus - the report state
	 * @returns whether the report is running or not
	 */
	private isRunning = (reportStatus: string): boolean => {
		return reportStatus.toLowerCase() === this.running;
	};

	/**
	 * determines whether the report is finished or failed, false otherwise
	 *
	 * @param status - the report status
	 * @returns whether the report is finished or failed, false otherwise
	 */
	private isFinishedOrFailed = (status: string): boolean => {
		return status.toLowerCase().includes(this.finished) || status.toLowerCase().includes(this.failed);
	};

	/**
	 * determines whether the report is a finished interactive report or not
	 *
	 * @param status - the report status
	 * @param outputType - output type i.e interactive or CSV
	 * @returns whether the report is a finished interactive report or not
	 */
	private isFinishedInteractive = (status: string, outputType: string): boolean => {
		return status.toLowerCase().includes(this.finished) && outputType.toLowerCase() === this.interactive;
	};

	/**
	 * determines whether the report is a finished CSV report or not
	 *
	 * @param status - the report status
	 * @param outputType - output type i.e interactive or CSV
	 * @returns whether the report is a finished CSV report or not
	 */
	private isFinishedCSV = (status: string, outputType: string): boolean => {
		return status.toLowerCase().includes(this.finished) && outputType.toLowerCase() === this.csv;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { StateService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapUtilsService } from '../../../services/map-utils.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { RoutePillData } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapVehiclesService } from '../../../services/map-vehicles.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';

import { ListShowingValues, MapModeType, ModeType, VehicleDetailsActiveTab } from '../../../types/types';

import {
	VehiclesList,
	VehiclesListItem,
	VehicleStatusDisplayType,
	VehicleStatusDisplayTypes,
} from '../../../../../support-features/vehicles/types/types';

import {
	VehicleSummary,
	Vehicle,
	VehicleRoute,
	VehicleSummaries,
	VehiclesPaginatedResponse,
} from '../../../../../support-features/vehicles/types/api-types';

import {
	ColumnType,
	Columns,
	CssClassType,
	CssSubClassPosition,
	ListSortings,
	PageRequestInfo,
	SelectableIconType,
	SelectedRowData,
	SortDirection,
} from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'vehicles-list',
	templateUrl: './vehicles-list.component.html',
	styleUrls: ['./vehicles-list.component.scss'],
})
export class VehiclesListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('routePillColumnTemplate', { static: true, read: TemplateRef }) routePillColumnTemplate: TemplateRef<any>;

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'vehicleId', dir: SortDirection.asc }];

	public mapNavigateRefresh$Subscription: Subscription = null;

	public initialized: boolean = false;
	public updatedAt: string = null;
	public listName: string = 'vehicles-list';
	public columns: Columns = [];
	public vehiclesList: VehiclesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo = null;
	public totalRows: number = 0;
	public showingFirst: number = 0;
	public showingLast: number = 0;
	public vehiclesSearchSuggestions: string[] = [];
	public searchText: string = '';

	private readonly vehicleSelectedIconClass: string = 'nb-icons nb-view-on';
	private readonly vehicleNotSelectedIconClass: string = 'nb-icons nb-view-off';

	private readonly vehicleVisibilityColumnName: string = 'vehicleVisibility';
	private readonly vehicleIdColumnName: string = 'vehicleId';
	private readonly vehicleRouteColumnName: string = 'vehicleRoute';
	private readonly vehicleStatusColumnName: string = 'vehicleStatus';

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private authorityId: string = null;
	private vehicles: VehicleSummaries = [];

	constructor(
		private agenciesDataService: AgenciesDataService,
		private vehiclesDataService: VehiclesDataService,
		private mapUtilsService: MapUtilsService,
		private mapEventsService: MapEventsService,
		private mapHistoryService: MapHistoryService,
		private mapOptionsService: MapOptionsService,
		private mapNavigationService: MapNavigationService,
		private mapVehiclesService: MapVehiclesService,
		private stateService: StateService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the stop list
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();
		this.loadCache();
		this.buildListColumns();

		this.getSelectedAgency();

		this.initialized = true;
	}

	/**
	 * refresh the list following a refresh click (from parent component)
	 */
	public refresh = async (): Promise<void> => {
		if (this.initialized) {
			// refresh columns as we may be switching to/from replay mode
			this.buildListColumns();
			await this.getVehicles();
		}
	};

	/**
	 * handle the vehicle data request triggered from the datatable
	 *
	 * @param pageInfo - the page details (page number/size and sorting info)
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getVehicles();
	};

	/**
	 * get the search suggestions based on the search text
	 *
	 * @param searchText - test seach text
	 */
	public getSearchSuggestions = async (searchText: string): Promise<void> => {
		const response: ResultContent = await this.vehiclesDataService.getVehicleSuggestions(this.authorityId, searchText);

		this.vehiclesSearchSuggestions = [];

		if (response.success) {
			const resultData: VehiclesPaginatedResponse = response.resultData;

			const vehicleSuggestions: VehicleSummaries = resultData.results;

			vehicleSuggestions.forEach((vehicleSuggestion) => {
				this.addSearchSuggestion(vehicleSuggestion.vehicle_id);
			});
		}
	};

	/**
	 * handle a search from the user and reload the vehicle list with the filter in place
	 *
	 * @param searchText - the search text entered by the user
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getVehicles();
	};

	/**
	 * handle the select of a row in the vehicle list and navigate to vehicle details
	 * @param selectedRow - the selected vehicle row
	 */
	public onSelect = async (selectedRow: SelectedRowData): Promise<void> => {
		const selectedVehicle: VehiclesListItem = selectedRow.row;

		// Lookup the full vehicle details
		const vehicle: VehicleSummary = this.determineVehicle(selectedVehicle.id);

		if (selectedRow.columnNameSelected === this.vehicleVisibilityColumnName) {
			if (this.isVehicleToggleEnabled(selectedVehicle.vehicleId)) {
				this.toggleRenderVehicle(vehicle);
			} else {
				await this.navigateToVehicleDetails(selectedVehicle);
			}
		} else {
			await this.navigateToVehicleDetails(selectedVehicle);
		}
	};

	/**
	 * handle the back button click and return to the users previous location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * get the route associated with the vehicle
	 * @param vehicle - the id of the vehicle
	 * @returns the route associated with the vehicle
	 */
	public getRoute = (vehicle: string): VehicleRoute => {
		const vehice: VehicleSummary = this.determineVehicle(vehicle);

		return this.getRouteDisplay(vehice);
	};

	/**
	 * construct the route pill object using information retrieved about the route
	 *
	 * @param vehicleId - the vehicle id to determine the associated route
	 * @returns the route pill data object used to populate our route pill badges
	 */
	public determineRoutePillData = (vehicleId: string): RoutePillData => {
		const vehicle: VehicleSummary = this.determineVehicle(vehicleId);

		return {
			routeShortName: vehicle.current_state.route.route_name,
			routeLongName: vehicle.current_state.route.route_name,
			routeId: vehicle.current_state.route.route_id,
			routeColor: this.colorUtilityService.getColor(vehicle.current_state.route.route_color),
			routeTextColor: this.colorUtilityService.getColor(vehicle.current_state.route.route_text_color),
		};
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * navigate refresh - handle the click of the parent refresh button and refresh the list
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.refresh();
		});
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_ID',
			'T_MAP.MAP_ROUTE_COL_HEADING',
			'T_MAP.MAP_STATUS',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP',
			'T_MAP.MAP_UNASSIGNED',
			'T_MAP.MAP_STALE',
			'T_MAP.MAP_ON_ROUTE',
			'T_MAP.MAP_UNPREDICTABLE',
			'T_MAP.MAP_DEADHEADING',
			'T_MAP.MAP_DEASSIGNED',
			'T_MAP.MAP_DEPOT',
			'T_MAP.MAP_IN_TRANSIT',
			'T_MAP.MAP_ARRIVED',
			'T_MAP.MAP_DWELLING',
			'T_MAP.MAP_DEPARTING',
			'T_MAP.MAP_DEPARTED',
			'T_MAP.MAP_DRIVER_ON_BREAK',
			'T_MAP.NO_VEHICLES',
		]);
	};

	/**
	 * load any persisted data for the component (list page info and filter search text)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * persist the page settings for the list
	 * @param pageInfo - the page settings
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * persist the search text
	 * @param search - the search text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * build the columns for the list
	 */
	private buildListColumns = (): void => {
		// Build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: this.vehicleVisibilityColumnName,
				displayName: '',
				sortable: false,
				columnType: ColumnType.selectableIcon,
				fixedWidth: 50,
			},
			{
				name: this.vehicleIdColumnName,
				displayName: this.translations['T_MAP.MAP_ID'],
				columnType: ColumnType.text,
				fixedWidth: 60,
			},
			{
				name: this.vehicleRouteColumnName,
				displayName: this.translations['T_MAP.MAP_ROUTE_COL_HEADING'],
				componentTemplate: this.routePillColumnTemplate,
				sortable: !this.isReplayMode(),
				columnType: ColumnType.component,
				width: 165,
			},
			{
				name: this.vehicleStatusColumnName,
				displayName: this.translations['T_MAP.MAP_STATUS'],
				sortable: !this.isReplayMode(),
				columnType: ColumnType.cssClass,
				width: 230,
			},
		];
	};

	/**
	 * map the properties from our camel case list to property name recognised by the nextbus API to prepare for our backend sorting
	 *
	 * @param sortByValue - the sort by column name
	 * @returns - the sort by property name recognised by the nextbus API
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case this.vehicleIdColumnName:
				columnName = 'vehicle_id';
				break;
			case this.vehicleRouteColumnName:
				columnName = 'vehicle_route';
				break;
			case this.vehicleStatusColumnName:
				columnName = 'vehicle_current_state';
				break;
			default:
				columnName = 'vehicle_id';
				break;
		}

		return columnName;
	}

	/**
	 * get the vehicles from the data layer
	 */
	private getVehicles = async (): Promise<void> => {
		// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
		this.enableRefresh.emit(false);

		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const response: ResultContent = await this.vehiclesDataService.getVehiclesList(
			this.authorityId,
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.mapColumnName(this.pageInfo.sort),
			this.pageInfo.sortDir,
			this.searchText
		);

		if (response.success) {
			const resultData: VehiclesPaginatedResponse = response.resultData;

			if (resultData?.results) {
				this.vehicles = response.resultData.results;
				this.vehiclesList = this.vehicles.map((vehicle: VehicleSummary) => ({
					id: vehicle.nb_id,
					vehicleVisibility: this.mapVehicleVisibilityIconValue(vehicle.nb_id),
					vehicleId: vehicle.vehicle_id,
					vehicleStatus: this.getStatusDisplay(vehicle),
				}));

				this.totalRows = resultData.total;

				const listShowingValues: ListShowingValues = this.mapUtilsService.getShowingPageValues(
					+this.pageInfo.pageNum,
					+this.pageInfo.pageSize,
					this.totalRows
				);

				this.showingFirst = listShowingValues.showingFirst;
				this.showingLast = listShowingValues.showingTo;

				this.updatedAt = moment().format('HH:mm:ss');
			}
		}

		this.listLoadingIndicator = false;

		this.enableRefresh.emit(true);
	};

	/**
	 * determine the vehicle from the vehicle id
	 * @param vehicleId - the vehicle id
	 * @returns the vehicle
	 */
	private determineVehicle = (vehicleId: string): VehicleSummary => {
		return this.vehicles.find((retrievedVehicle: VehicleSummary) => retrievedVehicle.nb_id === vehicleId);
	};

	/**
	 * prepare a function to load the appropriate tooltip for the 'eye' visibility icon in the list
	 *
	 * @param vehicleId - the vehicle id
	 * @returns a function to pass in to our datatable to allow this parent to handle the underlying tooltip
	 */
	private getVehicleIconDynamicTooltip = (vehicleId: string): Function => {
		return (): string =>
			this.vehicleDisplayed(vehicleId)
				? this.translations['T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP']
				: this.translations['T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP'];
	};

	/**
	 * prepare a function to load the appropriate styling for the 'eye' visibility icon in the list
	 *
	 * @param vehicleId - the vehicle id
	 * @returns a function to pass in to our datatable to allow this parent to handle the underlying styling
	 */
	private getVehicleVisibilityDynamicState = (vehicleId: string): Function => {
		return (): boolean => this.vehicleDisplayed(vehicleId);
	};

	/**
	 * toggle the rendering of the vehicle on the map
	 *
	 * @param vehicle - the vehicle to render
	 */
	private toggleRenderVehicle = async (vehicle: VehicleSummary): Promise<void> => {
		if (this.vehicleDisplayed(vehicle.vehicle_id)) {
			this.mapVehiclesService.removeVehicle(vehicle.vehicle_id);
		} else {
			const vehicleResponse: ResultContent = await this.vehiclesDataService.getVehicle(this.authorityId, vehicle.vehicle_id);

			if (vehicleResponse.success) {
				const vehicle: Vehicle = vehicleResponse.resultData;

				this.mapVehiclesService.addVehicle(vehicle);
				this.mapVehiclesService.zoomToVehicle(vehicle);
			}
		}
	};

	/**
	 * determine if the vehicle is displayed on the map
	 *
	 * @param vehicleId - the vehicle id to check
	 * @returns true if the vehicle is displayed on the map
	 */
	private vehicleDisplayed = (vehicleId: string): boolean => {
		return this.mapVehiclesService.vehicleDisplayed(vehicleId);
	};

	/**
	 * get the route display - clear the route from the list if it's stale or not predictable
	 *
	 * @param vehicle - the vehicle
	 * @returns the vehicle route
	 */
	private getRouteDisplay = (vehicle: VehicleSummary): VehicleRoute => {
		let route: VehicleRoute = vehicle.current_state.route;

		if (vehicle.is_stale) {
			route = null;
		} else if (!vehicle.current_state.predictability) {
			route = null;
		} else if (vehicle.current_state.predictability.toLowerCase() === 'no_route') {
			route = null;
		}

		return route;
	};

	/**
	 * determine if the map is in ladder mode
	 *
	 * @returns true if the map is in ladder mode
	 */
	private isLadderMode = (): boolean => {
		return this.mapOptionsService.getMode() === ModeType.ladder;
	};

	/**
	 * determine if the map is in replay mode
	 *
	 * @returns true if the map is in replay mode
	 */
	private isReplayMode = (): boolean => {
		return this.mapOptionsService.getMapMode() === MapModeType.replay;
	};

	/**
	 * determine if the vehicle toggle is enabled (not allowed in ladder mode when there is no route)
	 *
	 * @param vehicleId - the vehicle id
	 * @returns true if the vehicle toggle is enabled
	 */
	private isVehicleToggleEnabled = (vehicleId: string): boolean => {
		const route: VehicleRoute = this.getRoute(vehicleId);

		return !this.isLadderMode() || (this.isLadderMode && route !== null);
	};

	/**
	 * map the vehicle details to determine the vehicle list visibility icon style details
	 * @param vehicleId - the vehicle id
	 * @returns the icon styling ready for display
	 */
	private mapVehicleVisibilityIconValue = (vehicleId: string): SelectableIconType => {
		// A way of passing a parameter to a function pointer/reference
		const isVehicleToggleHidden: () => boolean = () => !this.isVehicleToggleEnabled(vehicleId);

		return {
			iconName: this.getVehicleIconDynamicTooltip(vehicleId),
			selectedState: {
				selected: this.getVehicleVisibilityDynamicState(vehicleId),
				selectedIconClass: this.vehicleSelectedIconClass,
				notSelectedIconClass: this.vehicleNotSelectedIconClass,
			},
			hidden: isVehicleToggleHidden,
		};
	};

	/**
	 * navigate to the vehicle details using the supplied vehicle
	 *
	 * @param selectedVehicle - the vehicle to navigate to
	 */
	private navigateToVehicleDetails = async (selectedVehicle: VehiclesListItem): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(
			this.authorityId,
			selectedVehicle.vehicleId,
			VehicleDetailsActiveTab.summary
		);
	};

	/**
	 * get the status display value by determining the appropriate css styling for the status column
	 *
	 * @param vehicle - the vehicle details
	 * @returns the styling for the status dipslay column
	 */
	private getStatusDisplay = (vehicle: VehicleSummary): CssClassType => {
		const vehicleStatusDisplayTypes: VehicleStatusDisplayTypes = this.getVehicleStatusDisplayTypes(
			vehicle.current_state.veh_state,
			vehicle.in_depot,
			vehicle.current_state.route,
			vehicle.current_state.predictability,
			vehicle.is_stale
		);

		let className: string = null;

		switch (vehicleStatusDisplayTypes.vehicleStatusDisplayType) {
			case VehicleStatusDisplayType.unassigned:
				className = 'label nb-vehicle-details-status-unassigned';
				break;
			case VehicleStatusDisplayType.unassignedInDepot:
				className = 'label nb-vehicle-details-status-unassigned';
				break;
			case VehicleStatusDisplayType.stale:
				className = 'label nb-vehicle-details-status-stale';
				break;
			case VehicleStatusDisplayType.onRoute:
				className = 'label nb-vehicle-details-status-on-route';
				break;
			case VehicleStatusDisplayType.unpredictable:
				className = 'label nb-vehicle-details-status-unpredictable';
				break;
			case VehicleStatusDisplayType.deadheading:
				className = 'label nb-vehicle-details-status-on-route';
				break;
			case VehicleStatusDisplayType.deassignedNoRoute:
				className = 'label nb-vehicle-details-status-deassigned';
				break;
		}

		const statusDisplaySubClassValue: string = this.mapVehiclesService.getVehicleStatusDisplayText(
			vehicleStatusDisplayTypes.VehicleStatusDisplaySubtype
		);

		const classType: CssClassType = {
			className,
			value: this.mapVehiclesService.getVehicleStatusDisplayText(vehicleStatusDisplayTypes.vehicleStatusDisplayType, true),
			tooltip: this.mapVehiclesService.getVehicleStatusDisplayText(vehicleStatusDisplayTypes.vehicleStatusDisplayType, true),
			subClassName: statusDisplaySubClassValue ? 'nb-icons nb-information-solid vehicle-status-solid-icon' : null,
			subClassValue: statusDisplaySubClassValue ? statusDisplaySubClassValue : null,
			subClassTooltip: statusDisplaySubClassValue ? statusDisplaySubClassValue : null,
			subClassPosition: CssSubClassPosition.after,
		};

		return classType;
	};

	/**
	 * determine the vehicle state display type based on varous info for the vehicle
	 *
	 * @param state - the vehicle state
	 * @param inDepot - whether the vehicle is at the depot
	 * @param route - the vehicle route
	 * @param predictability - the vehicle predictability
	 * @param isStale - whether the vehicle is stale
	 * @returns a suitable status enum type to summarize the overall state of the vehicle
	 */
	private getVehicleStatusDisplayTypes = (
		state: string,
		inDepot: boolean,
		route: VehicleRoute,
		predictability: string,
		isStale: boolean
	): VehicleStatusDisplayTypes => {
		// defaults
		let vehicleStatusDisplayType: VehicleStatusDisplayType = VehicleStatusDisplayType.unassigned;
		let vehicleStatusDisplaySubType: VehicleStatusDisplayType = VehicleStatusDisplayType.none;

		if (inDepot) {
			vehicleStatusDisplayType = VehicleStatusDisplayType.unassignedInDepot;
			vehicleStatusDisplaySubType = VehicleStatusDisplayType.unassignedInDepot;
		}

		if (isStale) {
			vehicleStatusDisplayType = VehicleStatusDisplayType.stale;
			vehicleStatusDisplaySubType = VehicleStatusDisplayType.none;
		} else if (route) {
			vehicleStatusDisplayType = VehicleStatusDisplayType.onRoute;
			vehicleStatusDisplaySubType = VehicleStatusDisplayType.none;

			if (predictability === 'OFF_ROUTE') {
				vehicleStatusDisplayType = VehicleStatusDisplayType.unpredictable;
			}

			if (state && state.toLowerCase() === 'deadheading') {
				vehicleStatusDisplayType = VehicleStatusDisplayType.deadheading;
			} else {
				if (state !== null) {
					switch (state.toLowerCase()) {
						case 'intransit':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.intransit;
							break;
						case 'arrived':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.arrived;
							break;
						case 'dwelling':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.dwelling;
							break;
						case 'departing':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.departing;
							break;
						case 'departed':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.departed;
							break;
						case 'driver on break':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.driverOnBreak;
							break;
						case 'at_depot':
							vehicleStatusDisplaySubType = VehicleStatusDisplayType.atDepot;
							break;
					}
				}
			}
		} else {
			if (state && state.toLowerCase() === 'deassigned') {
				vehicleStatusDisplayType = VehicleStatusDisplayType.deassignedNoRoute;
				vehicleStatusDisplaySubType = VehicleStatusDisplayType.none;
			}
		}

		const vehicleStatusDisplayTypes: VehicleStatusDisplayTypes = {
			vehicleStatusDisplayType,
			VehicleStatusDisplaySubtype: vehicleStatusDisplaySubType,
		};

		return vehicleStatusDisplayTypes;
	};

	/**
	 * get the users current agency
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
		}
	};

	/**
	 * add the individual search suggestion to the list
	 * @param searchSuggestion - the search suggestion
	 */
	private addSearchSuggestion = (searchSuggestion: string): void => {
		if (!this.vehiclesSearchSuggestions.includes(searchSuggestion)) {
			this.vehiclesSearchSuggestions.push(searchSuggestion);
		}
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BreadcrumbItems, EntityType, NavigationTargetDetail, PageType } from './types/types';

@Component({
	selector: 'breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
	@Input() useRouter: boolean = true;
	@Input() breadcrumbItems: BreadcrumbItems = [];

	@Output() navigate: EventEmitter<NavigationTargetDetail> = new EventEmitter<NavigationTargetDetail>();

	constructor(private router: Router) {}

	/**
	 * handles the navigation to a different page via our the router
	 *
	 * @param target - the target page
	 */
	public navigateRouter = (target: string): void => {
		this.router.navigate([target]);
	};

	/**
	 * handles the navigation to a different page via our navigation component
	 *
	 * @param entityType - the entity type i.e vehicle/stop
	 * @param pageType - the page type i.e list/details
	 */
	public navigateTarget = (entityType: EntityType, pageType: PageType): void => {
		const navigationTargetDetail: NavigationTargetDetail = {
			entityType,
			pageType,
		};

		this.navigate.emit(navigationTargetDetail);
	};
}

import { Agency } from './api-types';

export type AuthoritiesAgenciesType = Array<AuthorityAgencyType>;

export interface AuthorityAgencyType {
	agency_id: string;
	authority_id: string;
}

export interface CurrentUserAgency {
	authorityId: string;
	nbAgencyInfoId: number;
	nbAgencyId: number;
}

export type CurrentUserAgencies = Array<CurrentUserAgency>;

export interface AgencySelectedUpdate {
	saved: boolean;
	agency?: Agency;
}

export interface AdherenceSettings {
	lateMinSec: number;
	veryLateSec: number;
	earlyMinSec: number;
	veryEarlySec: number;
}

export interface HeadwaySettings {
	headwayAdherenceClose: number;
	headwayAdherenceDistant: number;
	headwayAdherenceVeryClose: number;
	headwayAdherenceVeryDistant: number;
}

export const defaultHeadwayAdherenceSettings: HeadwaySettings = {
	headwayAdherenceClose: 0.05,
	headwayAdherenceDistant: 0.1,
	headwayAdherenceVeryClose: 0.15,
	headwayAdherenceVeryDistant: 0.25,
};

export const agencyConstants: any = {
	ladderVisualization: 1,
	adherenceByRoute: 2,
	adherenceByStop: 3,
	adherenceByVehicleByRoute: 4,
	adherenceForVehiclesByStop: 5,
	adherenceByRouteDirectionStop: 6,
	adherenceVehicleArrivalAndDeparture: 7,
	configStopsAndTimepointsByBlock: 8,
	configScheduleForRoute: 9,
	configStops: 10,
	configHolidays: 11,
	headwayByStop: 12,
	headwayDetail: 13,
	headwayByRoute: 14,
	blockAssignments: 15,
	predictionAccuracy: 16,
	predictionAccuracyByRoute: 17,
	riderCountByRoute: 18,
	riderCountByRouteByStop: 19,
	riderCountByTrip: 20,
	riderCountByBlock: 21,
	riderCountByVehicle: 22,
	riderCountByBlockByVehicle: 23,
	avlReport: 24,
	avlReportByVehicle: 25,
	lastAvlReport: 26,
	rawAvlReport: 27,
	blockCancellations: 28,
	tripCancellations: 29,
	stopCancellations: 30,
	cancellationAlert: 31,
	ridership: 32,
	dispatchTextMessage: 33,
	riderMessage: 34,
};

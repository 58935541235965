<div class="content nb-padding-all-md" [attr.title]="titleText">
	<div class="row">
		<div class="title-container">
			<span class="nb-section-title">{{ titleText }}</span>
		</div>
	</div>
	<div>
		<div>
			<button class="btn btn-default" href (click)="openAgencySelector()" [disabled]="isDisabled" data-test="agency.selection">
				<span class="nb-icons nb-settings nb-button-text selector-btn"></span>
				<span [innerHtml]="getLabel()" class="selector-btn-text"></span>
			</button>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { PredictionsDataService } from '../../services/predictions-data.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { TimeHelpers } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';

import moment, { Moment } from 'moment';

import {
	PredictionRouteDestinations,
	PredictionSetting,
	PredictionRouteDestinationStop,
	PredictionMode,
	AllRouteId,
	DisablePredictionCloseType,
	PredictionRouteDestination,
	PredictionRouteDestinationStops,
} from '../../types/types';

import {
	DisabledPrediction,
	DisabledStopsUpdate,
	DisabledStopUpdate,
	DisablePredictionUpdate,
	FeedType,
	FeedTypes,
	Route,
} from '../../types/api-types';

import { Directions, RouteConfig, Routes, Direction } from '../../types/api-types';
import { TimeUpdated } from '@cubicNx/libs/utils';

@Component({
	selector: 'disable-predictions-edit-modal',
	templateUrl: './disable-predictions-edit-modal.component.html',
	styleUrls: ['./disable-predictions-edit-modal.component.scss'],
	animations: [
		trigger('fadeInOutAnimation', [
			state(
				'in',
				style({
					opacity: 1,
				})
			),
			state(
				'out',
				style({
					opacity: 0.4,
				})
			),
			transition('* => in', [animate('1s')]),
			transition('* => out', [animate('1s')]),
		]),
	],
})
export class DisablePredictionsEditModalComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild('startDatePicker') startDatePicker: any;
	@ViewChild('endDatePicker') endDatePicker: any;

	public readonly selectAllId: string = 'SELECT_ALL';
	public readonly enabledKey: string = 'Enabled';
	public readonly disabledKey: string = 'Disabled';

	public readonly all: string = 'all';
	public readonly specific: string = 'specific';

	public predictionModeType: typeof PredictionMode = PredictionMode;
	public predictionMode: PredictionMode = PredictionMode.Create;
	public allOrSpecific: string = this.specific;
	public predictionRadioSetting: string = this.disabledKey;
	public scheduledTimesRadioSetting: string = this.disabledKey;
	public predictionSettings: PredictionSetting[] = [];

	public routes: Routes = [];
	public feedTypes: FeedTypes = [];
	public routeDirections: Directions = [];
	public routeDestinations: PredictionRouteDestinations = [];
	public feedType: FeedType = null;
	public initialised: boolean = false;
	public confirmSelectionMode: boolean = false;
	public isDelete: boolean = false;
	public enableSave: boolean = false;
	public dateErrorText: string = '';

	// ensure we cant have dates in the past
	public minDate: Date = null;
	public dateTimeFormGroup: FormGroup = null;

	public selectedRouteText: string = null;
	public selectedFeedText: string = null;
	public startDateText: string = null;
	public endDateText: string = null;
	public hasDateError: boolean = false;

	public mainModalAnimationFadeState: string = '';
	public confirmFooterAnimationState: string = '';

	private readonly predictionActiveClass: string = 'prediction-active';
	private readonly predictionPendingDisabledClass: string = 'prediction-pending-disabled';
	private readonly predictionDisabledClass: string = 'prediction-disabled';

	private readonly displayDateFormat: string = 'MM/DD/YYYY';

	private readonly animationIn: string = 'in';
	private readonly animationOut: string = 'out';

	private routeConfig: RouteConfig = null;
	private dateTimeUpdated: boolean = false;

	private startDateDefault: Date = null;
	private endDateDefault: Date = null;
	private utcOffset: number = 0;

	private routeId: string = null;
	private authorityId: string = null;
	private timeZone: string = null;
	private predictionData: DisabledPrediction = null;
	private isDisableAllActive: boolean = false;

	constructor(
		private predictionsDataService: PredictionsDataService,
		private loggerService: LoggerService,
		private modalRef: MatDialogRef<DisablePredictionsEditModalComponent>,
		private formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		const disablePredictionCloseType: DisablePredictionCloseType = {
			saved: false,
		};

		this.modalRef.close(disablePredictionCloseType);
	}

	/**
	 * Initialises the page (getting what data is required from back end)
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations([
			'T_CORE.FORM.ERRORS.DATERANGE_END',
			'T_CORE.FORM.ERRORS.DATE_END_PAST',
			'T_CORE.FORM.ERRORS.DATE_START_PAST',
			'T_CORE.FORM.ERRORS.DATE_TIME_START',
			'T_CORE.FORM.ERRORS.DATE_TIME_END',
			'T_PREDICTION.ENABLED',
			'T_PREDICTION.DISABLED',
			'T_PREDICTION.PREDICTION_ALL_ROUTES',
			'T_PREDICTION.ALL_FEEDS',
		]);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;

		// grab the modal data passed in
		this.routeId = this.data['routeId'];
		this.authorityId = this.data['authorityId'];
		this.timeZone = this.data['timeZone'];
		this.predictionData = this.data['predictionData'];
		this.isDisableAllActive = this.data['isDisableAllActive'];

		this.predictionSettings = [
			{ checked: false, key: this.enabledKey, text: this.getTranslation('T_PREDICTION.ENABLED') },
			{ checked: true, key: this.disabledKey, text: this.getTranslation('T_PREDICTION.DISABLED') },
		];

		this.routes = await this.getRoutes();
		this.feedTypes = await this.getFeedTypes();

		if (this.routes && this.feedTypes) {
			// sort the feed types
			this.feedTypes = this.sortFeedTypes(this.feedTypes);

			this.predictionMode = this.routeId ? PredictionMode.Edit : PredictionMode.Create;

			// Set-up the mimimum date to be current date/time for the agency timezone
			this.minDate = TimeHelpers.createNewDateFromMoment(moment.tz(new Date(), this.timeZone));

			if (this.predictionMode === PredictionMode.Edit) {
				await this.initialiseEditMode();
			} else {
				await this.initialiseCreateMode();
			}

			this.enableSave = this.canEnableDisablePrediction();

			this.initialised = true;
		} else {
			this.loggerService.logError('There has been a problem retrieving routes and/or feed type data.');
		}
	}

	/**
	 * Fired when prediction mode radio control is changed
	 */
	public predictionModeChanged = (): void => {
		if (this.allOrSpecific === this.all) {
			// There is an 'ALL' active in the list, so allow the user to toggle the 'ALL' settings
			if (this.isDisableAllActive) {
				// initialise radio controls - logic seems back to front here but this is how the data comes across from back end
				this.predictionRadioSetting = this.predictionData.enabled ? this.disabledKey : this.enabledKey;
				this.scheduledTimesRadioSetting = this.predictionData.hideScheduledTimes ? this.disabledKey : this.enabledKey;
			}
		} else {
			// Revert back to defaults
			this.predictionRadioSetting = this.disabledKey;
			this.scheduledTimesRadioSetting = this.disabledKey;
		}

		this.enableSave = this.canEnableDisablePrediction();
	};

	/**
	 * Fired when prediction setting radio control is changed
	 */
	public predictionRadioSettingChanged = (): void => {
		this.updateStopSelectionStyle();
	};

	/**
	 * fires when the start date is changed - used to assist with validation
	 *
	 * @param event - the event containing change details
	 */
	public onChangeStartDate = (): void => {
		// trigger the update of the stop style color if times have changed
		this.updateStopSelectionStyle();

		this.hasDateError = this.getDateTimeErrors();

		// trigger the save button state
		this.enableSave = this.canEnableDisablePrediction();

		this.dateTimeUpdated = true;
	};

	/**
	 * fires when the end date is changed - used to assist with validation
	 *
	 * @param event - the event containing change details
	 */
	public onChangeEndDate = (): void => {
		this.hasDateError = this.getDateTimeErrors();

		// trigger the save button state
		this.enableSave = this.canEnableDisablePrediction();

		this.dateTimeUpdated = true;
	};

	/**
	 * fires when the route is changed and rebuilds the stop list based on the route chosen
	 *
	 * @param target - the target
	 */
	public routeChanged = (target: EventTarget): void => {
		const element: HTMLInputElement = target as HTMLInputElement;
		const routeId: string = element.value;

		this.routeId = routeId;
		this.buildStopData(routeId);

		// reset the save button state
		this.enableSave = false;
	};

	/**
	 * fires when the feed type is changed and updates the internal variable (used when we save)
	 *
	 * @param target - the target
	 */
	public feedChanged = (target: EventTarget): void => {
		const element: HTMLInputElement = target as HTMLInputElement;
		const feedTypeValue: string = element.value;

		this.feedType = this.feedTypes.filter((feedType) => feedType.value === feedTypeValue)[0];
	};

	/**
	 * handles the selection of the stop and sets the appropriate color
	 *
	 * @param stopId - the stop id
	 */
	public handleStopClick = (stopId: string): void => {
		if (this.predictionMode === PredictionMode.Create) {
			// keep our list in sync with the control.  We must search through each destination
			// as stops can exist in more than one
			this.routeDestinations.forEach((destination: PredictionRouteDestination) => {
				destination.stops.forEach((stop: PredictionRouteDestinationStop) => {
					if (stop.id === stopId) {
						stop.selected = !stop.selected;
						stop.style = this.getStopStyle(stop.selected);
					}
				});
			});

			this.determineAllStopsSelected();

			// trigger the save button state
			this.enableSave = this.canEnableDisablePrediction();
		}
	};

	/**
	 * selects/deselects all stops for a destination when the 'select all' is selected/deselected
	 *
	 * @param destinationIndex - the destination index being processed
	 */
	public selectAll = (destinationIndex: number): void => {
		let select: boolean = true;

		// if all stops already selected then set all to off
		if (this.allStopsSelected(destinationIndex)) {
			select = false;
		}

		// set all stops in the destination selected/unselected
		this.routeDestinations[destinationIndex].stops.forEach((stop: PredictionRouteDestinationStop) => {
			stop.selected = select;
			stop.style = this.getStopStyle(select);
		});

		// look for stops that appear in other destinations and set also
		this.routeDestinations.forEach((destination: PredictionRouteDestination, index: number) => {
			if (index !== destinationIndex) {
				destination.stops.forEach((stop: PredictionRouteDestinationStop) => {
					const matchingStop: PredictionRouteDestinationStop = this.routeDestinations[destinationIndex].stops.find(
						(selectedStop) => selectedStop.id === stop.id
					);

					if (matchingStop) {
						stop.selected = select;
						stop.style = this.getStopStyle(select);
					}
				});
			}
		});

		// determine state of all selected check value (each destination must be checked. There is an
		// edge case where selecting all from one destination may set the remaining unselected stops
		// for another destination
		this.determineAllStopsSelected();

		// trigger the save button state
		this.enableSave = this.canEnableDisablePrediction();
	};

	/**
	 * gets appropriate style class (color) for the stop (multi select item)
	 *
	 * @param selected - if the stop entry has been checked or not in the view
	 * @returns the style class
	 */
	public getStopStyle = (selected: boolean): string => {
		if (selected) {
			if (this.predictionRadioSetting === this.enabledKey) {
				return this.predictionActiveClass;
			} else {
				const now: Moment = TimeHelpers.createMomentNowForTimeZone(this.timeZone);

				this.utcOffset = TimeHelpers.getTimeZoneOffset(now);

				// first work out the time in seconds
				const [startHours, startMinutes] = this.dateTimeFormGroup.controls.startTime.value.split(':');
				const totalStartSeconds: number = +startHours * 60 * 60 + +startMinutes * 60;

				// add it to our date
				const startDateTimeMoment: Moment = moment(this.dateTimeFormGroup.controls.startDate.value).add(
					totalStartSeconds,
					'seconds'
				);

				const startDateTime: Date = new Date(startDateTimeMoment.toString());

				// Adjust for UTC offset
				startDateTime.setMinutes(startDateTime.getMinutes() - this.utcOffset);

				// Retrieve number of milliseconds since Epoch for the start date (UTS)
				const utcStart: number = TimeHelpers.getUtcMilliSecondsForDate(startDateTime);

				if (utcStart.valueOf() > now.valueOf()) {
					return this.predictionPendingDisabledClass;
				} else {
					return this.predictionDisabledClass;
				}
			}
		}

		return null;
	};

	/**
	 * handles a disable prediction update/delete and gets the data from the view
	 *
	 * @param isDelete - whether or not the we are handling a delete or update
	 */
	public disablePrediction = async (isDelete: boolean): Promise<void> => {
		this.confirmSelectionMode = true;

		this.mainModalAnimationFadeState = this.animationOut;
		this.confirmFooterAnimationState = this.animationIn;

		// prepare the data - the real 'disable' will come in the confirm (save) below
		this.isDelete = isDelete;

		// Only set route and feed if this is a specific
		if (this.allOrSpecific === this.specific) {
			this.setSelectedRouteText();
			this.selectedFeedText = this.feedType.description;
		} else {
			this.selectedRouteText = this.translations['T_PREDICTION.PREDICTION_ALL_ROUTES'];
			this.selectedFeedText = this.translations['T_PREDICTION.ALL_FEEDS'];
		}

		// Render the start / end summary if this is an update - control values are already in agency time zone specific
		// so no conversion required
		if (!isDelete) {
			this.startDateText =
				moment(this.dateTimeFormGroup.controls.startDate.value.valueOf()).format(this.displayDateFormat) +
				' ' +
				this.dateTimeFormGroup.controls.startTime.value;

			this.endDateText =
				moment(this.dateTimeFormGroup.controls.endDate.value.valueOf()).format(this.displayDateFormat) +
				' ' +
				this.dateTimeFormGroup.controls.endTime.value;
		}
	};

	/**
	 * handles a save - update to the back end
	 */
	public save = async (): Promise<void> => {
		// Create Date objects from the values currently in the date/time controls

		// first work out the time in seconds
		const [startHours, startMinutes] = this.dateTimeFormGroup.controls.startTime.value.split(':');
		const totalStartSeconds: number = +startHours * 60 * 60 + +startMinutes * 60;

		const [endHours, endMinutes] = this.dateTimeFormGroup.controls.endTime.value.split(':');
		const totalEndSeconds: number = +endHours * 60 * 60 + +endMinutes * 60;

		// add it to our date
		const startDateTimeMoment: Moment = moment(this.dateTimeFormGroup.controls.startDate.value).add(totalStartSeconds, 'seconds');
		const startDateTime: Date = new Date(startDateTimeMoment.toString());

		const endDateTimeMoment: Moment = moment(this.dateTimeFormGroup.controls.endDate.value).add(totalEndSeconds, 'seconds');
		const endDateTime: Date = new Date(endDateTimeMoment.toString());

		startDateTime.setMinutes(startDateTime.getMinutes() - this.utcOffset);
		const utcStartDate: number = TimeHelpers.getUtcMilliSecondsForDate(startDateTime);
		const formattedUtcStartDate: string = new Date(utcStartDate).toISOString();

		endDateTime.setMinutes(endDateTime.getMinutes() - this.utcOffset);
		const utcEndDate: number = TimeHelpers.getUtcMilliSecondsForDate(endDateTime);
		const formattedUtcEndDate: string = new Date(utcEndDate).toISOString();

		const disablePredictionUpdate: DisablePredictionUpdate = {
			id: -1,
			agencyId: this.authorityId,
			routeId: this.allOrSpecific === this.specific ? this.routeId : AllRouteId,
			feedType: this.allOrSpecific === this.specific ? this.feedType.type : AllRouteId,
			feedTypeValue: this.allOrSpecific === this.specific ? this.feedType.value : AllRouteId,
			disabledStopList:
				this.allOrSpecific === this.specific
					? this.getSelectedDisabledStops(this.feedType, formattedUtcStartDate, formattedUtcEndDate)
					: [],
			timeStart: formattedUtcStartDate,
			timeEnd: formattedUtcEndDate,
			// seems the wrong way round but this is the correct logic for the back end
			enabled: this.predictionRadioSetting === this.enabledKey ? false : true,
			// seems the wrong way round but this is the correct logic for the back end
			hideScheduledTimes: this.scheduledTimesRadioSetting === this.enabledKey ? false : true,
		};

		const disablePredictionCloseType: DisablePredictionCloseType = {
			saved: true,
			disablePredictionUpdate,
			isDelete: this.isDelete,
		};

		this.modalRef.close(disablePredictionCloseType);
	};

	/**
	 * sets the approprate control valid/invalid based on child component updates
	 *
	 * @param valid - the state of the control - true = valid
	 * @param controlName - the name of the control
	 */
	public setTimeValid = (valid: boolean, controlName: string): void => {
		if (valid) {
			this.dateTimeFormGroup.controls[controlName].setErrors(null);
			this.dateTimeFormGroup.controls[controlName].updateValueAndValidity();
		} else {
			this.dateTimeFormGroup.controls[controlName].setErrors({ invalid: true });
		}

		this.hasDateError = this.getDateTimeErrors();

		// trigger the save button state
		this.enableSave = this.canEnableDisablePrediction();
	};

	/**
	 * maintains the approriate time from the time control in our form object
	 *
	 * @param timeUpdated - the time to update the control with (HH:MM)
	 * @param controlName - the name of the control
	 */
	public setUpdatedTime = (timeUpdated: TimeUpdated, controlName: string): void => {
		if (!timeUpdated.initialPublish) {
			this.dateTimeUpdated = true;
		}

		this.dateTimeFormGroup.controls[controlName].setValue(timeUpdated.time);

		// trigger the update of the stop style color if times have changed
		this.updateStopSelectionStyle();

		this.hasDateError = this.getDateTimeErrors();

		// trigger the save button state
		this.enableSave = this.canEnableDisablePrediction();
	};

	/**
	 * handles a close click from the view (and opens the confirm dialog if so)
	 */
	public closeClick = (): void => {
		if (this.confirmSelectionMode) {
			this.confirmSelectionMode = false;
			this.mainModalAnimationFadeState = this.animationIn;
			this.confirmFooterAnimationState = this.animationOut;
		} else {
			const disablePredictionCloseType: DisablePredictionCloseType = {
				saved: false,
			};

			this.modalRef.close(disablePredictionCloseType);
		}
	};

	/**
	 * initialises the data when in edit mode
	 */
	private initialiseEditMode = async (): Promise<void> => {
		this.allOrSpecific = this.routeId === AllRouteId ? this.all : this.specific;

		// init radio controls - logic seems back to front here but this is how the data comes across from back end
		this.predictionRadioSetting = this.predictionData.enabled ? this.disabledKey : this.enabledKey;
		this.scheduledTimesRadioSetting = this.predictionData.hideScheduledTimes ? this.disabledKey : this.enabledKey;

		// Create moment in times for dates - that are in agancy timezone, they come in in UTC format
		const startMomentTZ: Moment = moment.utc(this.predictionData.timeStart).tz(this.timeZone);
		const endMomentTZ: Moment = moment.utc(this.predictionData.timeEnd).tz(this.timeZone);

		this.utcOffset = TimeHelpers.getTimeZoneOffset(startMomentTZ);

		// Create Date objects from these moments for the form control purposes only
		const startDate: Date = TimeHelpers.createNewDateFromMoment(startMomentTZ.clone().startOf('day'));
		const endDate: Date = TimeHelpers.createNewDateFromMoment(endMomentTZ.clone().startOf('day'));

		const startTime: string = TimeHelpers.createNewTimeFromMoment(startMomentTZ);
		const endTime: string = TimeHelpers.createNewTimeFromMoment(endMomentTZ);

		this.dateTimeFormGroup = this.formBuilder.group(
			{
				startDate: [startDate, Validators.required],
				endDate: [endDate, Validators.required],
				startTime: [startTime, Validators.required],
				endTime: [endTime, Validators.required],
			},
			{}
		);

		// Only attempt to get route / feeds / stops if we are editing a row created from a specific route
		if (this.allOrSpecific === this.specific) {
			this.setSelectedRouteText();
			this.feedType = this.feedTypes.filter((feedType) => feedType.value === this.predictionData.feedTypeValue)[0];
			this.selectedFeedText = this.feedType.description;

			// build stop data from the routeId passed from the parent
			await this.buildStopData(this.routeId);
		} else {
			this.selectedRouteText = this.translations['T_PREDICTION.PREDICTION_ALL_ROUTES'];
			this.selectedFeedText = this.translations['T_PREDICTION.ALL_FEEDS'];
		}
	};

	/**
	 * initializes for create mode
	 */
	private initialiseCreateMode = async (): Promise<void> => {
		this.routeId = this.routes[0].id;
		this.feedType = this.feedTypes[0];

		// Create moment in time objects for the agency timezones for now and one hour's time as defaults or start and end
		const nowMomentTZ: Moment = TimeHelpers.createMomentNowForTimeZone(this.timeZone);

		this.utcOffset = TimeHelpers.getTimeZoneOffset(nowMomentTZ);

		// Create Date objects from these moments for the form control purposes only
		this.startDateDefault = TimeHelpers.createNewDateFromMoment(nowMomentTZ.startOf('day'));
		this.endDateDefault = TimeHelpers.createNewDateFromMoment(nowMomentTZ.startOf('day'));

		// Create moment in time objects for the agency timezones for now and one hour's time as defaults or start and end
		const startMomentTZ: Moment = TimeHelpers.createMomentNowForTimeZone(this.timeZone);
		const endMomentTZ: Moment = TimeHelpers.createMomentNowForTimeZone(this.timeZone).add(1, 'hours');

		const startTimeDefault: string = TimeHelpers.createNewTimeFromMoment(startMomentTZ);
		const endTimeDefault: string = TimeHelpers.createNewTimeFromMoment(endMomentTZ);

		this.dateTimeFormGroup = this.formBuilder.group(
			{
				startDate: [this.startDateDefault, Validators.required],
				endDate: [this.endDateDefault, Validators.required],
				startTime: [startTimeDefault, Validators.required],
				endTime: [endTimeDefault, Validators.required],
			},
			{}
		);

		// default stop list using the first route in the list (which will be the one selected by default)
		await this.buildStopData(this.routeId);
	};

	/**
	 * build the stop lists (per direction) for the route
	 *
	 * @param routeId - the route Id
	 */
	private buildStopData = async (routeId: string): Promise<void> => {
		this.routeConfig = await this.getRouteConfig(routeId);

		if (this.routeConfig) {
			this.routeDirections = this.routeConfig.directions.filter((direction) => direction.useForUi === true);

			this.buildSortedStopList();
		}
	};

	/**
	 * Build a list of stop names per destination with an id an actual stop name for display (with sorted names)
	 * Loop through our route config with 'directions' to build our own list of destinations with types for
	 * 'all selected' and stop list ready for our multi list control group
	 */
	private buildSortedStopList = (): void => {
		this.routeDestinations = [];

		this.routeDirections.forEach((routeDirection: Direction) => {
			const destination: PredictionRouteDestination = {
				name: routeDirection.shortName,
				allStopsSelected: true,
				stops: [],
			};

			routeDirection.stops.forEach((stopId: string) => {
				let selected: boolean = false;
				let stopStyle: any = null;

				const stopCode: string = this.getStopCode(stopId);

				if (this.predictionMode === PredictionMode.Edit) {
					selected = this.isStopInitiallySelected(stopId);
					stopStyle = this.getStopStyle(selected);
				}

				const stop: PredictionRouteDestinationStop = {
					id: stopId,
					name: this.getStopName(stopId),
					selected,
					style: stopStyle,
					code: stopCode ? stopCode : stopId,
				};

				destination.stops.push(stop);

				if (!selected) {
					destination.allStopsSelected = false;
				}
			});

			this.routeDestinations.push(destination);
		});
	};

	/**
	 * determines if the stop should be selected in the view by looking up the raw data to see the stop
	 * exists within the disabled stop list
	 *
	 * @param stopId - the stop id being process
	 * @returns if the stop is selected
	 */
	private isStopInitiallySelected = (stopId: string): boolean => {
		if (this.routeId !== AllRouteId) {
			if (this.predictionData.disabledStopList?.length > 0) {
				return this.predictionData.disabledStopList.filter((disabledStop) => disabledStop.stopId === stopId).length > 0;
			}
		}

		return null;
	};

	/**
	 * determines if the stop is currently selected
	 *
	 * @param stopId - the stop id being process
	 * @returns if the stop is selected
	 */
	private isStopSelected = (stopId: string): boolean => {
		let selected: boolean = false;

		if (this.routeId !== AllRouteId) {
			this.routeDestinations.forEach((destination: PredictionRouteDestination) => {
				const stop: PredictionRouteDestinationStop = destination.stops.find((s) => s.id === stopId);

				if (stop) {
					if (stop.selected) {
						selected = true;
					}
				}
			});
		}

		return selected;
	};

	/**
	 * determine if all stops are selected for a destination and sets the flag accordingly
	 */
	private determineAllStopsSelected = (): void => {
		this.routeDestinations.forEach((destination: PredictionRouteDestination, index: number) => {
			destination.allStopsSelected = this.allStopsSelected(index);
		});
	};

	/**
	 * Set-up the route text value
	 */
	private setSelectedRouteText = (): void => {
		const selectedRoute: Route = this.routes.filter((route) => route.id === this.routeId)[0];

		// Check in place just in case the route have been deleted since the last time the prediction was disabled
		this.selectedRouteText = selectedRoute ? selectedRoute.title : this.routeId;
	};

	/**
	 * determines if all stops have been selected for a destination within the disabled stop list
	 *
	 * @param destinationIndex - the destination index being processed
	 * @returns a flag indicating if the all stops have been selected for the destination
	 */
	private allStopsSelected = (destinationIndex: number): boolean => {
		const stops: PredictionRouteDestinationStops = this.routeDestinations[destinationIndex].stops;

		return stops.filter((stop) => stop.selected === true).length === stops.length;
	};

	/**
	 * gets the stopbased on the stopId
	 *
	 * @param stopId - the stop id
	 * @returns the stop
	 */
	private getStopName = (stopId: string): string => this.routeConfig.stops.filter((stopData) => stopData.id === stopId)[0].name;

	private getStopCode = (stopId: string): string => this.routeConfig.stops.filter((stopData) => stopData.id === stopId)[0].code;

	/**
	 * sorts the feed types
	 *
	 * @param feedTypes - the feed types
	 * @returns the sorted feed types
	 */
	private sortFeedTypes = (feedTypes: FeedTypes): FeedTypes =>
		feedTypes.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

	/**
	 * gets the route data from the backend/cache
	 *
	 * @returns the route data
	 */
	private getRoutes = async (): Promise<Routes> => {
		// preciction service will handle returning of cached data if available
		const response: ResultContent = await this.predictionsDataService.getRoutes(this.authorityId);

		if (response.success) {
			return response.resultData;
		} else {
			return null;
		}
	};

	/**
	 * gets the individual route config from the backend/cache
	 *
	 * @param routeId - the route id
	 * @returns the feed type data
	 */
	private getRouteConfig = async (routeId: string): Promise<RouteConfig> => {
		// preciction service will handle returning of cached data if available
		const response: ResultContent = await this.predictionsDataService.getRouteConfig(this.authorityId, routeId);

		if (response.success) {
			return response.resultData;
		} else {
			return null;
		}
	};

	/**
	 * gets the feed type data from the backend/cache
	 *
	 * @returns the feed type data
	 */
	private getFeedTypes = async (): Promise<FeedTypes> => {
		// preciction service will handle returning of cached data if available
		const response: ResultContent = await this.predictionsDataService.getFeedTypes(this.authorityId);

		if (response.success) {
			return response.resultData;
		} else {
			return null;
		}
	};

	/**
	 * trigger the update of the stop selection style
	 */
	private updateStopSelectionStyle = (): void => {
		this.routeDestinations.forEach((destination: PredictionRouteDestination) => {
			destination.stops.forEach((stop: PredictionRouteDestinationStop) => {
				stop.style = this.getStopStyle(this.isStopSelected(stop.id));
			});
		});
	};

	/**
	 * determines if we have an error related to the dates and
	 * builds up and error string based on the dates enetered by the user
	 *
	 * @returns true when there are date time errors
	 */
	private getDateTimeErrors = (): boolean => {
		this.dateErrorText = '';

		let hasDateError: boolean = false;

		if (this.dateTimeUpdated) {
			// check the date has been updated before checking errors. Then check null value which covers an actual null
			// but also anything entered that isn't a valid date
			if (
				this.dateTimeFormGroup.controls.startDate.value === null ||
				this.dateTimeFormGroup.controls.startTime.value === null ||
				this.dateTimeFormGroup.controls.startTime.errors
			) {
				hasDateError = true;
				this.dateErrorText += this.translations['T_CORE.FORM.ERRORS.DATE_TIME_START'];
			}

			if (
				this.dateTimeFormGroup.controls.endDate.value === null ||
				this.dateTimeFormGroup.controls.endTime.value === null ||
				this.dateTimeFormGroup.controls.endTime.errors
			) {
				if (hasDateError) {
					this.dateErrorText += ' / ';
				}

				hasDateError = true;
				this.dateErrorText += this.translations['T_CORE.FORM.ERRORS.DATE_TIME_END'];
			}

			// we have 2 valid dates - now check for that they are not in the past and the start date is before the end date
			if (!hasDateError) {
				// this property only exists if the min date requirment doesnt pass
				if (this.dateTimeFormGroup.controls.startDate.errors?.matDatepickerMin) {
					if (hasDateError) {
						this.dateErrorText += ' / ';
					}

					hasDateError = true;
					this.dateErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_START_PAST'];
				}

				if (this.dateTimeFormGroup.controls.endDate.errors?.matDatepickerMin) {
					if (hasDateError) {
						this.dateErrorText += ' / ';
					}

					hasDateError = true;
					this.dateErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_END_PAST'];
				}

				if (!hasDateError) {
					const startDateMoment: Moment = moment(this.dateTimeFormGroup.controls.startDate.value);
					const endDateMoment: Moment = moment(this.dateTimeFormGroup.controls.endDate.value);

					const startDateTime: Moment = this.setDateTime(startDateMoment, this.dateTimeFormGroup.controls.startTime.value);
					const endDateTime: Moment = this.setDateTime(endDateMoment, this.dateTimeFormGroup.controls.endTime.value);

					if (startDateTime >= endDateTime) {
						hasDateError = true;
						this.dateErrorText = this.translations['T_CORE.FORM.ERRORS.DATERANGE_END'];
					}
				}
			}
		}

		return hasDateError;
	};

	/**
	 * utility to create a moment in time representing the supplied date/time
	 *
	 * @param date - date
	 * @param time - time
	 * @returns moment in time representing the supplied date/time
	 */
	private setDateTime = (date: moment.Moment, time: string): moment.Moment => {
		const timeSplit: string[] = time.split(':');

		date.hour(+timeSplit[0]);
		date.minutes(+timeSplit[1]);

		return date;
	};

	/**
	 * determines whether an enable/disabled prediction is allowed i.e checks for a stop to be selected
	 * used to enable/disable the update/disable/delete button.
	 *
	 * If ALL routes and stops are to be disabled / enabled then the check does not need to check if any stops
	 * have been selected.
	 *
	 * @returns true when we can enable the 'disable prediction'
	 */
	private canEnableDisablePrediction = (): boolean => {
		let enabled: boolean = false;

		if (!this.hasDateError) {
			if (this.allOrSpecific === this.specific) {
				this.routeDestinations.forEach((destination: PredictionRouteDestination) => {
					if (destination.stops.filter((stop: PredictionRouteDestinationStop) => stop.selected === true).length > 0) {
						enabled = true;
					}
				});
			} else {
				enabled = true;
			}
		}

		return enabled;
	};

	/**
	 * gets the selected stops from the view (multi-select control) and formats the data ready to update the backend
	 *
	 * @param feedType - the selected feed type required by the update object
	 * @param startDate - the start date/time required by the update object
	 * @param endDate - the end date/time required by the update object
	 * @returns the formated disabled stop list
	 */
	private getSelectedDisabledStops = (feedType: FeedType, startDate: string, endDate: string): DisabledStopsUpdate => {
		const disabledStopsUpdate: DisabledStopsUpdate = [];

		this.routeDestinations.forEach((destination: PredictionRouteDestination) => {
			destination.stops.forEach((stop: PredictionRouteDestinationStop) => {
				if (stop.selected) {
					const disabledStopUpdate: DisabledStopUpdate = {
						stopId: stop.id,
						feedType: feedType.value,
						timeStart: startDate,
						timeEnd: endDate,
					};

					disabledStopsUpdate.push(disabledStopUpdate);
				}
			});
		});

		return disabledStopsUpdate;
	};
}

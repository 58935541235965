import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

import { NvD3Component } from '@cubicNx/libs/utils';

import moment, { Moment } from 'moment';

@Component({
	selector: 'performance-line-chart',
	templateUrl: './performance-line-chart.component.html',
	styleUrls: ['./performance-line-chart.component.scss'],
})
export class PerformanceLineChartComponent implements OnChanges {
	@Input() chartData: any;
	@Input() display: any;
	@Input() timezone: any;

	@ViewChild('nvd3') nvd3: NvD3Component;

	public translations: any = {};
	public data: any = null;
	public options: any = null;

	private showLegend: any = null;
	private chartOptionsSet: boolean = false;

	constructor(private translationService: TranslationService) {}

	/**
	 * for when the chart data changes - loads chart with new data
	 */
	public ngOnChanges(): void {
		this.handleChanges();
	}

	/**
	 * returns a scroll style text
	 *
	 * @returns scroll style text
	 */
	public showScrollStyle = (): any => {
		return { 'overflow-x': 'hidden' };
	};

	/**
	 * loads the chart when the underlying chart data changes
	 */
	private handleChanges = async (): Promise<void> => {
		await this.checkTranslationsLoaded();

		if (this.chartData?.routeMetrics?.length > 0 && this.chartData?.predictionMetrics?.length > 0) {
			// ensure we only do this one as deselecting keys on the chart (in view mode) is overwritten on each refresh
			if (!this.chartOptionsSet) {
				this.setChartOptions(100);
				this.chartOptionsSet = true;
			}

			this.loadChart(this.chartData);

			if (this.nvd3.options?.chart) {
				this.nvd3.updateWithData(this.data);
			}
		}
	};

	/**
	 * formats the supplied metrics data
	 *
	 * @param metrics - metrics data
	 * @returns formatted metrics data
	 */
	private formatChartData = (metrics: any): any => {
		const chartValues: any = {
			predictability: [],
			early: [],
			veryEarly: [],
			onTime: [],
			late: [],
			veryLate: [],
			headwayVeryClose: [],
			headwayClose: [],
			headwayOkay: [],
			headwayDistant: [],
			headwayVeryDistant: [],
			predictionAccuracyAll: [],
			predictionAccuracy0to5: [],
			predictionAccuracy5to10: [],
			predictionAccuracy10to15: [],
		};

		let timestampMoment: Moment = null;

		metrics.routeMetrics.forEach((routeMetric: any) => {
			timestampMoment = moment(routeMetric.timestamp);

			let predictability: number = 0;

			if (routeMetric.active_blocks_count > 0) {
				predictability = (100 * routeMetric.active_blocks_assigned_count) / routeMetric.active_blocks_count;
				predictability = parseFloat(predictability.toFixed(1));
			}

			chartValues.predictability.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: predictability,
			});

			let vehicleCount: any =
				routeMetric.adherence_early_count +
				routeMetric.adherence_very_early_count +
				routeMetric.adherence_late_count +
				routeMetric.adherence_very_late_count +
				routeMetric.adherence_on_time_count;

			let early: number = 0;

			if (vehicleCount > 0) {
				early = (100 * routeMetric.adherence_early_count) / vehicleCount;
				early = parseFloat(early.toFixed(1));
			}

			chartValues.early.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: early,
			});

			let veryEarly: number = 0;

			if (vehicleCount > 0) {
				veryEarly = (100 * routeMetric.adherence_very_early_count) / vehicleCount;
				veryEarly = parseFloat(veryEarly.toFixed(1));
			}

			chartValues.veryEarly.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: veryEarly,
			});

			let late: number = 0;

			if (vehicleCount > 0) {
				late = (100 * routeMetric.adherence_late_count) / vehicleCount;
				late = parseFloat(late.toFixed(1));
			}

			chartValues.late.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: late,
			});

			let veryLate: number = 0;

			if (vehicleCount > 0) {
				veryLate = (100 * routeMetric.adherence_very_late_count) / vehicleCount;
				veryLate = parseFloat(veryLate.toFixed(1));
			}

			chartValues.veryLate.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: veryLate,
			});

			let onTime: number = 0;

			if (vehicleCount > 0) {
				onTime = (100 * routeMetric.adherence_on_time_count) / vehicleCount;
				onTime = parseFloat(onTime.toFixed(1));
			}

			chartValues.onTime.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: onTime,
			});

			// Vehicles won't always have a headway metric so we have to find the vehicle count again.
			vehicleCount =
				routeMetric.headway_adherence_very_close_count +
				routeMetric.headway_adherence_close_count +
				routeMetric.headway_adherence_ok_count +
				routeMetric.headway_adherence_distant_count +
				routeMetric.headway_adherence_very_distant_count;

			let headwayVeryClose: number = 0;

			if (vehicleCount > 0) {
				headwayVeryClose = (100 * routeMetric.headway_adherence_very_close_count) / vehicleCount;
				headwayVeryClose = parseFloat(headwayVeryClose.toFixed(1));
			}

			chartValues.headwayVeryClose.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: headwayVeryClose,
			});

			let headwayClose: number = 0;

			if (vehicleCount > 0) {
				headwayClose = (100 * routeMetric.headway_adherence_close_count) / vehicleCount;
				headwayClose = parseFloat(headwayClose.toFixed(1));
			}

			chartValues.headwayClose.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: headwayClose,
			});

			let headwayOkay: number = 0;

			if (vehicleCount > 0) {
				headwayOkay = (100 * routeMetric.headway_adherence_ok_count) / vehicleCount;
				headwayOkay = parseFloat(headwayOkay.toFixed(1));
			}

			chartValues.headwayOkay.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: headwayOkay,
			});

			let headwayDistant: number = 0;

			if (vehicleCount > 0) {
				headwayDistant = (100 * routeMetric.headway_adherence_distant_count) / vehicleCount;
				headwayDistant = parseFloat(headwayDistant.toFixed(1));
			}

			chartValues.headwayDistant.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: headwayDistant,
			});

			let headwayVeryDistant: number = 0;

			if (vehicleCount > 0) {
				headwayVeryDistant = (100 * routeMetric.headway_adherence_very_distant_count) / vehicleCount;
				headwayVeryDistant = parseFloat(headwayVeryDistant.toFixed(1));
			}

			chartValues.headwayVeryDistant.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: headwayVeryDistant,
			});
		});

		metrics.predictionMetrics.forEach((predictionMetric: any) => {
			timestampMoment = moment(predictionMetric.timestamp);

			let predictionAccuracyAll: number = 0;
			const positiveAll: number =
				predictionMetric.positive_0_5min + predictionMetric.positive_5_10min + predictionMetric.positive_10_15min;

			const countAll: number = predictionMetric.count_0_5min + predictionMetric.count_5_10min + predictionMetric.count_10_15min;

			if (countAll > 0) {
				predictionAccuracyAll = (100 * positiveAll) / countAll;
				predictionAccuracyAll = parseFloat(predictionAccuracyAll.toFixed(1));
			}

			chartValues.predictionAccuracyAll.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: predictionAccuracyAll,
				label: this.translations['CHART_PREDICTION_ACCURACY_ALL'],
			});

			let predictionAccuracy0to5: number = 0;

			if (predictionMetric.count_0_5min > 0) {
				predictionAccuracy0to5 = (100 * predictionMetric.positive_0_5min) / predictionMetric.count_0_5min;
				predictionAccuracy0to5 = parseFloat(predictionAccuracy0to5.toFixed(1));
			}

			chartValues.predictionAccuracy0to5.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: predictionAccuracy0to5,
				label: this.translations['CHART_PREDICTION_ACCURACY_0to5'],
			});

			let predictionAccuracy5to10: number = 0;

			if (predictionMetric.count_5_10min > 0) {
				predictionAccuracy5to10 = (100 * predictionMetric.positive_5_10min) / predictionMetric.count_5_10min;
				predictionAccuracy5to10 = parseFloat(predictionAccuracy5to10.toFixed(1));
			}

			chartValues.predictionAccuracy5to10.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: predictionAccuracy5to10,
				label: this.translations['CHART_PREDICTION_ACCURACY_5to10'],
			});

			let predictionAccuracy10to15: number = 0;

			if (predictionMetric.count_10_15min > 0) {
				predictionAccuracy10to15 = (100 * predictionMetric.positive_10_15min) / predictionMetric.count_10_15min;
				predictionAccuracy10to15 = parseFloat(predictionAccuracy10to15.toFixed(1));
			}

			chartValues.predictionAccuracy10to15.push({
				x: (timestampMoment.format('X') as any) * 1000,
				y: predictionAccuracy10to15,
				label: this.translations['CHART_PREDICTION_ACCURACY_10to15'],
			});
		});

		return chartValues;
	};

	/**
	 * sets the chart options
	 * @param maxY - the max Y value
	 */
	private setChartOptions = (maxY: number): void => {
		this.options = {
			chart: {
				type: 'lineChart',
				height: 300,
				yDomain: [0, maxY],
				margin: {
					top: 10,
					right: 40,
					bottom: 45,
					left: 50,
				},
				noData: '',
				x: (d: any): any => d.x,
				y: (d: any): any => d.y,
				showLegend: this.showLegend,
				showXAxis: true,
				showYAxis: true,
				useInteractiveGuideline: true,
				interactiveUpdateDelay: 300,
				yAxis: {
					axisLabel: this.translations['CHARTS_PERCENTAGE'],
					axisLabelDistance: -25,
				},
				xAxis: {
					axisLabel: this.translations['CHARTS_TIME'],
					tickFormat: this.generateXTicks,
					showMaxMin: true,
					staggerLabels: false,
				},
				interactiveLayer: {
					tooltip: {
						contentGenerator: this.generateTooltipContent,
					},
				},
			},
		};

		// setup the chart data structure for each series that can be displayed
		const chartData: any[] = [];

		if (this.display.displayHeadwayVeryClose) {
			chartData.push({
				id: 'CHART_HEADWAY_VERY_CLOSE',
				key: this.translations['CHART_HEADWAY_VERY_CLOSE'],
				values: [],
				color: '#b05c56',
			});
		}

		if (this.display.displayHeadwayClose) {
			chartData.push({
				id: 'CHART_HEADWAY_CLOSE',
				key: this.translations['CHART_HEADWAY_CLOSE'],
				values: [],
				color: '#eb9d9b',
			});
		}

		if (this.display.displayHeadwayOkay) {
			chartData.push({
				id: 'CHART_HEADWAY_OKAY',
				key: this.translations['CHART_HEADWAY_OKAY'],
				values: [],
				color: '#7cc293',
			});
		}

		if (this.display.displayHeadwayDistant) {
			chartData.push({
				id: 'CHART_HEADWAY_DISTANT',
				key: this.translations['CHART_HEADWAY_DISTANT'],
				values: [],
				color: '#fce0b6',
			});
		}

		if (this.display.displayHeadwayVeryDistant) {
			chartData.push({
				id: 'CHART_HEADWAY_VERY_DISTANT',
				key: this.translations['CHART_HEADWAY_VERY_DISTANT'],
				values: [],
				color: '#e6b97a',
			});
		}

		if (this.display.displayVeryEarly) {
			chartData.push({
				id: 'CHARTS_VERY_EARLY',
				key: this.translations['CHARTS_VERY_EARLY'],
				values: [],
				color: '#b0080a',
			});
		}

		if (this.display.displayEarly) {
			chartData.push({
				id: 'CHARTS_EARLY',
				key: this.translations['CHARTS_EARLY'],
				values: [],
				color: '#eb3b3b',
			});
		}

		if (this.display.displayOnTime) {
			chartData.push({
				id: 'CHARTS_ON_TIME',
				key: this.translations['CHARTS_ON_TIME'],
				values: [],
				color: '#3ac15d',
			});
		}

		if (this.display.displayLate) {
			chartData.push({
				id: 'CHARTS_LATE',
				key: this.translations['CHARTS_LATE'],
				values: [],
				color: '#ffb851',
			});
		}

		if (this.display.displayVeryLate) {
			chartData.push({
				id: 'CHARTS_VERY_LATE',
				key: this.translations['CHARTS_VERY_LATE'],
				values: [],
				color: '#e89016',
			});
		}

		if (this.display.displayPredictability) {
			chartData.push({
				id: 'CHART_PREDICTABILITY',
				key: this.translations['CHART_PREDICTABILITY'],
				values: [],
				color: '#01adbb',
			});
		}

		if (this.display.displayPredictionAccuracyAll) {
			chartData.push({
				id: 'CHART_PREDICTION_ACCURACY_ALL',
				key: this.translations['CHART_PREDICTION_ACCURACY_ALL_SHORT'],
				values: [],
				color: '#01548b',
			});
		}

		if (this.display.displayPredictionAccuracy0to5) {
			chartData.push({
				id: 'CHART_PREDICTION_ACCURACY_0to5',
				key: this.translations['CHART_PREDICTION_ACCURACY_0to5_SHORT'],
				values: [],
				color: '#cbe3f2',
			});
		}

		if (this.display.displayPredictionAccuracy5to10) {
			chartData.push({
				id: 'CHART_PREDICTION_ACCURACY_5to10',
				key: this.translations['CHART_PREDICTION_ACCURACY_5to10_SHORT'],
				values: [],
				color: '#61a8d8',
			});
		}

		if (this.display.displayPredictionAccuracy10to15) {
			chartData.push({
				id: 'CHART_PREDICTION_ACCURACY_10to15',
				key: this.translations['CHART_PREDICTION_ACCURACY_10to15_SHORT'],
				values: [],
				color: '#0073be',
			});
		}

		this.data = chartData;
	};

	/**
	 * generates the template using the supplied object that represents the tooltip over the chart
	 *
	 * @param e - the object
	 * @returns template using the supplied object data
	 */
	private generateTooltipContent = (e: any): string => {
		const series: any = e.series[0];

		if (series.value !== null) {
			let rows: string = '';

			e.series.forEach((seriesItem: any) => {
				const toolTipLabel: string = seriesItem.data.label || seriesItem.key;

				rows +=
					'<tr>' +
					'<td class="legend-color-guide">' +
					'<div style="background-color: ' +
					seriesItem.color +
					';"></div></td>' +
					'<td class="key">' +
					toolTipLabel +
					'</td>' +
					'<td class="x-value">' +
					seriesItem.value +
					'%</td>' +
					'</tr>';
			});

			const tickMoment: string = moment(e.value).tz(this.timezone).format('HH:mm');

			const header: string =
				'<thead>' + '<tr>' + '<td colspan="3" class="key"><strong>' + tickMoment + '</strong></td>' + '</tr>' + '</thead>';

			return '<table>' + header + '<tbody>' + rows + '</tbody>' + '</table>';
		}

		return null;
	};

	/**
	 * retrieves a formatted value of the supplied x coordinate
	 *
	 * @param d - the x coordinate value
	 * @returns formatted value of the coordinate
	 */
	private generateXTicks = (d: any): string => {
		const tickMoment: Moment = moment(d).tz(this.timezone);

		return tickMoment.format('HH:mm');
	};

	/**
	 * retrieves an element from within the supplied data that matches the supplied id
	 *
	 * @param id - element id
	 * @param data - data
	 * @returns element that matches the supplied id
	 */
	private findElement = (id: string, data: any): void => {
		return data.find((element: any) => element.id === id);
	};

	/**
	 * loads the chart with the underlying chart data
	 *
	 * @param metrics - the metrics
	 */
	private loadChart = (metrics: any): void => {
		const chartValues: any = this.formatChartData(metrics);
		const chartData: any = this.data;

		let element: any;

		// find each series data structure and replace the values with the new set
		element = this.findElement('CHARTS_VERY_EARLY', chartData);

		if (element) {
			element.values = chartValues.veryEarly;
		}

		element = this.findElement('CHARTS_EARLY', chartData);

		if (element) {
			element.values = chartValues.early;
		}

		element = this.findElement('CHARTS_ON_TIME', chartData);

		if (element) {
			element.values = chartValues.onTime;
		}

		element = this.findElement('CHARTS_LATE', chartData);

		if (element) {
			element.values = chartValues.late;
		}

		element = this.findElement('CHARTS_VERY_LATE', chartData);

		if (element) {
			element.values = chartValues.veryLate;
		}

		element = this.findElement('CHART_PREDICTABILITY', chartData);

		if (element) {
			element.values = chartValues.predictability;
		}

		element = this.findElement('CHART_PREDICTION_ACCURACY_ALL', chartData);

		if (element) {
			element.values = chartValues.predictionAccuracyAll;
		}

		element = this.findElement('CHART_PREDICTION_ACCURACY_0to5', chartData);

		if (element) {
			element.values = chartValues.predictionAccuracy0to5;
		}

		element = this.findElement('CHART_PREDICTION_ACCURACY_5to10', chartData);

		if (element) {
			element.values = chartValues.predictionAccuracy5to10;
		}

		element = this.findElement('CHART_PREDICTION_ACCURACY_10to15', chartData);

		if (element) {
			element.values = chartValues.predictionAccuracy10to15;
		}

		element = this.findElement('CHART_HEADWAY_VERY_CLOSE', chartData);

		if (element) {
			element.values = chartValues.headwayVeryClose;
		}

		element = this.findElement('CHART_HEADWAY_CLOSE', chartData);

		if (element) {
			element.values = chartValues.headwayClose;
		}

		element = this.findElement('CHART_HEADWAY_OKAY', chartData);

		if (element) {
			element.values = chartValues.headwayOkay;
		}

		element = this.findElement('CHART_HEADWAY_DISTANT', chartData);

		if (element) {
			element.values = chartValues.headwayDistant;
		}

		element = this.findElement('CHART_HEADWAY_VERY_DISTANT', chartData);

		if (element) {
			element.values = chartValues.headwayVeryDistant;
		}

		if (!this.options.chart.noData) {
			this.options.chart.noData = this.translations['CHARTS_NO_DATA_AVAILABLE'];
		}
	};

	/**
	 * initializes the translations for the view
	 */
	private initTranslations = async (): Promise<void> => {
		this.translations['CHARTS_NO_DATA_AVAILABLE'] = this.translationService.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE');

		this.translations['CHARTS_PERCENTAGE'] = this.translationService.getTranslation('T_DASH.CHARTS_PERCENTAGE');

		this.translations['CHARTS_TIME'] = this.translationService.getTranslation('T_DASH.CHARTS_TIME');

		this.translations['CHART_HEADWAY_VERY_CLOSE'] = this.translationService.getTranslation('T_DASH.CHART_HEADWAY_VERY_CLOSE');

		this.translations['CHART_HEADWAY_CLOSE'] = this.translationService.getTranslation('T_DASH.CHART_HEADWAY_CLOSE');

		this.translations['CHART_HEADWAY_OKAY'] = this.translationService.getTranslation('T_DASH.CHART_HEADWAY_OKAY');

		this.translations['CHART_HEADWAY_DISTANT'] = this.translationService.getTranslation('T_DASH.CHART_HEADWAY_DISTANT');

		this.translations['CHART_HEADWAY_VERY_DISTANT'] = this.translationService.getTranslation('T_DASH.CHART_HEADWAY_VERY_DISTANT');

		this.translations['CHARTS_VERY_EARLY'] = this.translationService.getTranslation('T_CORE.CHARTS_VERY_EARLY');

		this.translations['CHARTS_EARLY'] = this.translationService.getTranslation('T_CORE.CHARTS_EARLY');

		this.translations['CHARTS_ON_TIME'] = this.translationService.getTranslation('T_CORE.CHARTS_ON_TIME');

		this.translations['CHARTS_LATE'] = this.translationService.getTranslation('T_CORE.CHARTS_LATE');

		this.translations['CHARTS_VERY_LATE'] = this.translationService.getTranslation('T_CORE.CHARTS_VERY_LATE');

		this.translations['CHART_PREDICTABILITY'] = this.translationService.getTranslation('T_DASH.CHART_PREDICTABILITY');

		this.translations['CHART_PREDICTION_ACCURACY_ALL'] = this.translationService.getTranslation('T_DASH.CHART_PREDICTION_ACCURACY_ALL');

		this.translations['CHART_PREDICTION_ACCURACY_0to5'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_0to5'
		);

		this.translations['CHART_PREDICTION_ACCURACY_5to10'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_5to10'
		);

		this.translations['CHART_PREDICTION_ACCURACY_10to15'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_10to15'
		);

		this.translations['CHART_PREDICTION_ACCURACY_ALL_SHORT'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_ALL_SHORT'
		);

		this.translations['CHART_PREDICTION_ACCURACY_0to5_SHORT'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_0to5_SHORT'
		);

		this.translations['CHART_PREDICTION_ACCURACY_5to10_SHORT'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_5to10_SHORT'
		);

		this.translations['CHART_PREDICTION_ACCURACY_10to15_SHORT'] = this.translationService.getTranslation(
			'T_DASH.CHART_PREDICTION_ACCURACY_10to15_SHORT'
		);
	};

	/**
	 * checks if the translations have been loaded
	 */
	private checkTranslationsLoaded = async (): Promise<void> => {
		if (!this.translations['CHARTS_NO_DATA_AVAILABLE']) {
			await this.initTranslations();
		}
	};
}

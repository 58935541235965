<div class="widget">
	<widget-header [title]="data.title" [agencyName]="selectedAgency?.agency_name" [editMode]="false" (editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div class="weather-widget">
					<div class="weather-widget-container nb-subhead">
						<div class="nb-padding-top-md nb-subhead">
							<div *ngFor="let alert of currentWeather.alerts">
								<div>
									<div>
										<a target="_blank" rel="noopener" href="{{ alert?.uri }}">
											<i class="nb-icons nb-danger-solid nb-padding-right-xs"></i>
											{{ alert?.title }}
										</a>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="currentWeather.current_observation" class="nb-padding-top-md">
							<div class="weather-details">
								<span class="nb-text">{{ currentWeather.current_observation.weather }}</span>
							</div>

							<div class="weather-details">
								<!--Temperature-->
								<div id="temp" class="weather-detail-item temperature-container">
									<div>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'imperial' || unitOfMeasure === ''">
											{{ currentWeather.current_observation.temp_f }}&deg;
											<span class="small-caps">F</span>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'metric'">
											{{ currentWeather.current_observation.temp_c }}&deg;
											<span class="small-caps">C</span>
										</span>
									</div>
									<div>
										<span class="nb-text" for="Temp">{{ 'T_DASH.DB_WEATHER_TEMP' | translate }}</span>
									</div>
								</div>
								<div>
									<span class="nb-text" for="temp"> </span>
								</div>

								<!--Wind-->
								<div id="Wind" class="weather-detail-item">
									<div>
										<span [ngClass]="rotateClass.direction">
											<i class="material-icons">trending_flat</i>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'imperial' || unitOfMeasure === ''">
											{{ currentWeather.current_observation.wind_mph }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_MPH' | translate }}</span>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'metric'">
											{{ currentWeather.current_observation.wind_kph }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_KMPH' | translate }}</span>
										</span>
									</div>
									<div>
										<span class="nb-text" for="Wind">{{ 'T_DASH.DB_WEATHER_WIND' | translate }}</span>
									</div>
								</div>

								<!--Precipitation-->
								<div id="precip" class="weather-detail-item">
									<div>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'imperial' || unitOfMeasure === ''">
											{{ currentWeather.current_observation.precip_today_in }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_IN' | translate }}</span>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'metric'">
											{{ currentWeather.current_observation.precip_today_metric }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_MM' | translate }}</span>
										</span>
									</div>
									<div>
										<span class="nb-text" for="Precip">{{ 'T_DASH.DB_WEATHER_PRECIP' | translate }}</span>
									</div>
								</div>

								<!--Pressure-->
								<div id="pressure" class="weather-detail-item">
									<div>
										<span [ngClass]="rotateClass.pressure" *ngIf="rotateClass.pressure">
											<i class="material-icons">arrow_back</i>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'imperial' || unitOfMeasure === ''">
											{{ currentWeather.current_observation.pressure_in }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_IN' | translate }}</span>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'metric'">
											{{ currentWeather.current_observation.pressure_mb }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_MB' | translate }}</span>
										</span>
									</div>
									<div>
										<span class="nb-text" for="Pressure">{{ 'T_DASH.DB_WEATHER_PRESSURE' | translate }}</span>
									</div>
								</div>

								<!--Visibility-->
								<div id="visibility" class="weather-detail-item">
									<div>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'imperial' || unitOfMeasure === ''">
											{{ currentWeather.current_observation.visibility_mi }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_MI' | translate }}</span>
										</span>
										<span class="nb-heading" *ngIf="unitOfMeasure === 'metric'">
											{{ currentWeather.current_observation.visibility_km }}
											<span class="small-caps">{{ 'T_DASH.DB_WEATHER_KM' | translate }}</span>
										</span>
									</div>
									<div class="weather-visibility">
										<span class="nb-text" for="Visibility">{{ 'T_DASH.DB_WEATHER_VISIBILITY' | translate }}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="weather-footer">
							<div class="update-text">
								<span class="nb-text">
									<span class="bold">{{ 'T_DASH.DB_WEATHER_UPDATED' | translate }}</span>
									<br />
									<span data-test="updated">{{ lastUpdated.toString() | timeAgo }}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

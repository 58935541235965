/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component } from '@angular/core';

import { ReportViewerBaseComponent } from '../../../report-viewer-base.component';

import { DataService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { HeadwaySummaryDetail } from '../../../../types/api-types';

@Component({
	selector: 'hw100-headway-by-stop-summary',
	templateUrl: './headway-by-stop-summary.component.html',
	styleUrls: ['./headway-by-stop-summary.component.scss'],
})
export class HeadwayByStopSummaryComponent extends ReportViewerBaseComponent {
	public summaryData: HeadwaySummaryDetail = null;

	constructor(
		public dataService: DataService,
		translationService: TranslationService
	) {
		super(translationService);

		this.summaryData = this.dataService['summaryData' as keyof DataService];
	}
}

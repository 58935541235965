<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div class="flex" [ngStyle]="flexDirection()">
					<div *ngIf="!isDisplayOptionSet('listOnly')" class="occupany-flex-1">
						<div class="occupancy-chart-title">
							<span data-test="occupancy.title">{{ 'T_DASH.DB_OCCUPANCY_CHART_TITLE' | translate }}</span>
						</div>
						<occupancy-chart [occupancyData]="svgDisplay"></occupancy-chart>
					</div>
					<div *ngIf="!isDisplayOptionSet('chartOnly')" class="occupany-flex-1">
						<div class="occupancy-table-title">
							<span>{{ 'T_DASH.DB_OCCUPANCY_TABLE_TITLE' | translate }}</span>
						</div>
						<occupancy-list
							[agency]="data.config.selectedAgency"
							[occupancyListData]="listValues"
							[listSize]="data.config.listSize.value"
							data-test="occupancy.table"></occupancy-list>
					</div>
				</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

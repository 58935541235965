<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ data.header }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<div class="nb-text">
		<p class="confirm-message confirm-message-icon"><i class="nb-icons nb-warning-solid warning-icon"></i></p>
		<p class="nb-confirm-message confirm-message" *ngIf="data.message">{{ data.message }}</p>
		<ng-container *ngIf="portal">
			<ng-template [cdkPortalOutlet]="portal"></ng-template>
		</ng-container>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<ng-container *ngIf="data.confirmButtonType === confirmButtonType.yesNoType">
		<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.NO' | translate }}</button>
		<button class="btn btn-primary" (click)="confirm()" [disabled]="!confirmEnabled">{{ 'T_CORE.YES' | translate }}</button>
	</ng-container>
	<ng-container *ngIf="data.confirmButtonType === confirmButtonType.stayContinueType">
		<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.STAY' | translate }}</button>
		<button class="btn btn-primary" (click)="confirm()" [disabled]="!confirmEnabled">{{ 'T_CORE.DISCARD' | translate }}</button>
	</ng-container>
	<ng-container *ngIf="data.confirmButtonType === confirmButtonType.okType">
		<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.OK' | translate }}</button>
	</ng-container>
	<ng-container *ngIf="data.confirmButtonType === confirmButtonType.saveCancelType">
		<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
		<button class="btn btn-primary" (click)="confirm()" [disabled]="!confirmEnabled">{{ 'T_CORE.SAVE' | translate }}</button>
	</ng-container>
</mat-dialog-actions>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { StateService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapUtilsService } from '../../../services/map-utils.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { MapVehiclesService } from '../../../services/map-vehicles.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { Route, Routes, RoutesPaginatedResponse } from '../../../../../support-features/routes/types/api-types';
import { RoutesList, RoutesListItem } from '../../../../../support-features/routes/types/types';
import { ListShowingValues, MapVehicles, VehicleDetailsActiveTab } from '../../../types/types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { RoutePillData } from '@cubicNx/libs/utils';

import { ColumnType, Columns, ListSortings, PageRequestInfo, SelectedRowData, SortDirection } from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'vehicle-reassign',
	templateUrl: './vehicle-reassign.component.html',
	styleUrls: ['./vehicle-reassign.component.scss'],
})
export class VehicleReassignComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() vehicleId: string = null;
	@Input() vehicleRouteNbId: string = null;
	@Input() vehicleBlockId: string = null;

	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('routePillColumnTemplate', { static: true, read: TemplateRef }) routePillColumnTemplate: TemplateRef<any>;

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'routeShortName', dir: SortDirection.asc }];

	public mapNavigateRefresh$Subscription: Subscription = null;

	public initialized: boolean = false;
	public listName: string = 'vehicle-reassign-routes-list';
	public columns: Columns = [];
	public routesList: RoutesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo = null;
	public totalRows: number = 0;
	public showingFirst: number = 0;
	public showingLast: number = 0;
	public updatedAt: string = null;
	public searchText: string = '';
	public routeSearchSuggestions: string[] = [];
	public vehicleUnassigning: boolean = false;
	public unassignStatus: string = null;

	private readonly routeColumnName: string = 'routeShortName';
	private readonly routeLongNameColumnName: string = 'routeLongName';

	private routes: Routes = [];

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private authorityId: string = null;
	private agencyId: string = null;
	private agencyTimezone: string = null;

	constructor(
		private routesDataService: RoutesDataService,
		private agenciesDataService: AgenciesDataService,
		private mapUtilsService: MapUtilsService,
		private mapEventsService: MapEventsService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesService: MapVehiclesService,
		private vehiclesDataService: VehiclesDataService,
		private mapHistoryService: MapHistoryService,
		private mapNavigationService: MapNavigationService,
		private stateService: StateService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();
		this.loadCache();
		this.buildListColumns();

		this.getSelectedAgency();

		this.unassignStatus = this.vehicleBlockId
			? this.getTranslation('T_MAP.MAP_UNASSIGN')
			: this.getTranslation('T_MAP.MAP_UNASSIGN_TOOLTIP');

		this.initialized = true;

		// Do after initialize so it doesnt hold up the list rendering with its loading spinner
		// as we didnt need to wait for search suggestions
		await this.buildSearchSuggestions();
	}

	/**
	 * refresh the list following a refresh click (from parent component)
	 */
	public refresh = async (): Promise<void> => {
		if (this.initialized) {
			await this.getRoutes();
		}
	};

	/**
	 * handle the vehicle data request triggered from the datatable
	 *
	 * @param pageInfo - the page details (page number/size and sorting info)
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getRoutes();
	};

	/**
	 * handle a search from the user and reload the block list with the filter in place
	 *
	 * @param searchText - the search text entered by the user
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getRoutes();
	};

	/**
	 * construct the route pill object using information retrieved about the route
	 *
	 * @param routeId - the route id to determine the associated route
	 * @returns the route pill data object used to populate our route pill badges
	 */
	public determineRoutePillData = (routeId: string): RoutePillData => {
		const route: Route = this.determineRoute(routeId);

		return {
			routeShortName: route.route_short_name,
			routeLongName: route.route_long_name,
			routeId: route.route_id,
			routeColor: this.colorUtilityService.getColor(route.route_color),
			routeTextColor: this.colorUtilityService.getColor(route.route_text_color),
		};
	};

	/**
	 * inform the data layer to update the backend with the vehicle unassign
	 *
	 * trigger and update on our map also (if vehicle exists on the map) as the unassign could affect the vehicle color
	 */
	public unassignVehicle = async (): Promise<void> => {
		this.vehicleUnassigning = true;

		const unassignResponse: ResultContent = await this.vehiclesDataService.unassignVehicle(
			this.authorityId,
			this.agencyTimezone,
			this.vehicleId,
			this.vehicleBlockId
		);

		if (unassignResponse.success) {
			// refresh the route - this vehicle wil be no longer part of the route and this will trigger the removal
			// set assigned route to null
			await this.mapRoutesService.updateVehiclesOnRoutes(this.vehicleRouteNbId, null, this.authorityId, this.agencyId);

			// refresh the individual vehicle (for the scenario where we are showing the individual vehicle only)
			// check it's still present on the map - which will only happen in the above scenario
			if (this.mapVehiclesService.vehicleExists(this.vehicleId)) {
				const updatedVehicles: MapVehicles = await this.mapVehiclesService.getNonRouteVehicles(
					this.authorityId,
					[this.vehicleId],
					false
				);

				if (Object.keys(updatedVehicles).length > 0) {
					this.mapVehiclesService.updateVehicles(updatedVehicles);
				}
			}

			this.vehicleUnassigning = false;

			await this.mapNavigationService.navigateToVehicleDetails(this.authorityId, this.vehicleId, VehicleDetailsActiveTab.summary);
		}
	};

	/**
	 * handle the select of a row in the route list and navigate to the vehicle reassign block list
	 * @param selectedRow - the selected vehicle row
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const selectedRoute: RoutesListItem = selectedRow.row;

		this.navigateToVehicleReassignBlock(selectedRoute.id);
	};

	/**
	 * handle the back button click and return to the users previous location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * navigate refresh - handle the click of the parent refresh button and refresh the list
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.refresh();
		});
	};

	/**
	 * navigate to the vehicle ressaign block page
	 * @param routeId - the route id for the route we need to supply to load blocks for
	 */
	private navigateToVehicleReassignBlock = (routeId: string): void => {
		const reassigRoute: Route = this.determineRoute(routeId);

		this.mapNavigationService.navigateToVehicleReassignBlockDetails(
			this.vehicleId,
			this.vehicleRouteNbId,
			this.vehicleBlockId,
			reassigRoute.route_id,
			reassigRoute.nb_id
		);
	};

	/**
	 * get the users current agency details
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
			this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
		}
	};

	/**
	 * get the route object for a given route id
	 * @param routeId - the route id
	 * @returns the full route object
	 */
	private determineRoute = (routeId: string): Route => {
		return this.routes.find((retrievedRoute: Route) => retrievedRoute.route_id === routeId);
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_MAP.MAP_ROUTE', 'T_MAP.MAP_NAME', 'T_MAP.MAP_UNASSIGN_TOOLTIP', 'T_MAP.MAP_UNASSIGN']);
	};

	/**
	 * load any persisted data for the component (list page info and filter search text)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * persist the page settings for the list
	 * @param pageInfo - the page settings
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * persist the search text
	 * @param search - the search text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * map the properties from our camel case list to property name recognised by the nextbus API to prepare for our backend sorting
	 * @param sortByValue - the sort by column name
	 * @returns - the sort by property name recognised by the nextbus API
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case this.routeColumnName:
				columnName = 'route_short_name';
				break;
			case this.routeLongNameColumnName:
				columnName = 'route_long_name';
				break;
			default:
				columnName = 'route_short_name';
		}

		return columnName;
	}

	/**
	 * build the columns for the list
	 *
	 * build the column list for the underlying datatable - camel case equivalents of properties from back end
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: this.routeColumnName,
				displayName: this.translations['T_MAP.MAP_ROUTE'],
				componentTemplate: this.routePillColumnTemplate,
				columnType: ColumnType.component,
				width: 250,
			},
			{
				name: this.routeLongNameColumnName,
				displayName: this.translations['T_MAP.MAP_NAME'],
				columnType: ColumnType.text,
				width: 500,
			},
		];
	};

	/**
	 * build the list of search suggestions based on the search text
	 *
	 * @param searchText - test seach text
	 */
	private buildSearchSuggestions = async (): Promise<void> => {
		const response: ResultContent = await this.routesDataService.getRouteSuggestions(this.authorityId, this.agencyId);

		if (response.success) {
			const routes: Routes = response.resultData;

			// build a list of suggestions
			routes.forEach((route: Route) => {
				this.addSearchSuggestion(route.route_short_name);
				this.addSearchSuggestion(route.route_long_name);
			});
		}
	};

	/**
	 * add the individual search suggestion to the list
	 * @param searchSuggestion - the search suggestion
	 */
	private addSearchSuggestion = (searchSuggestion: string): void => {
		if (!this.routeSearchSuggestions.includes(searchSuggestion)) {
			this.routeSearchSuggestions.push(searchSuggestion);
		}
	};

	/**
	 * get the route from the data layer and update our list
	 */
	private getRoutes = async (): Promise<void> => {
		// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
		this.enableRefresh.emit(false);

		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const response: ResultContent = await this.routesDataService.getRoutesList(
			this.authorityId,
			this.agencyId,
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.mapColumnName(this.pageInfo.sort),
			this.pageInfo.sortDir,
			this.searchText
		);

		if (response.success) {
			const resultData: RoutesPaginatedResponse = response.resultData;

			if (resultData?.results) {
				this.routes = response.resultData.results as Routes;

				this.routesList = this.routes.map((route: Route) => ({
					id: route.route_id,
					routeLongName: route.route_long_name,
				}));

				this.totalRows = resultData.total;

				const listShowingValues: ListShowingValues = this.mapUtilsService.getShowingPageValues(
					+this.pageInfo.pageNum,
					+this.pageInfo.pageSize,
					this.totalRows
				);

				this.showingFirst = listShowingValues.showingFirst;
				this.showingLast = listShowingValues.showingTo;

				this.updatedAt = moment().format('HH:mm:ss');
			}
		}

		this.listLoadingIndicator = false;

		this.enableRefresh.emit(true);
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
	};
}

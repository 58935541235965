<div class="grid-title">Vehicle {{ subListData.vehicle_id }}</div>
<table class="adherence-vehicle-arrival-and-departure-sub-list-table">
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'block_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'block_id')"
				data-test="th.block_id"
				class="ellipsis">
				{{ 'T_CORE.BLOCK' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'route_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'route_id')"
				data-test="th.route_id"
				class="ellipsis">
				{{ 'T_CORE.ROUTE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				data-test="th.stop_name"
				class="mw-col ellipsis">
				{{ 'T_CORE.STOP' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_headsign' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_headsign')"
				data-test="th.trip_headsign"
				class="mw-col ellipsis">
				{{ 'T_REPORT.DESTINATION' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'timepoint' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'timepoint')"
				data-test="th.timepoint"
				class="mw-col ellipsis">
				{{ 'T_CORE.SCHEDULED' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'realtime' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'realtime')"
				data-test="th.realtime"
				class="mw-col ellipsis">
				{{ 'T_CORE.ACTUAL' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'adherence' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'adherence', SortFieldType.timeDelta)"
				data-test="th.adherence"
				class="mw-col ellipsis">
				{{ 'T_CORE.ADHERENCE' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow">
			<td data-test="td.block_id" class="ellipsis">{{ reportDataItem.block_id }}</td>
			<td class="reporttooltip">
				<div class="truncate">
					<span class="tooltiptext">{{ reportDataItem.route_long_name }} {{ reportDataItem.trip_headsign }}</span>
					<route-pill
						data-test="td.route_id"
						class="report-viewer-grid-route-pill"
						[route]="determineRoutePillData(reportDataItem)"></route-pill>
					<div class="truncate" class="adherence-vehicle-arrival-and-departure-sub-list-route-container">
						{{ reportDataItem.route_long_name }} {{ reportDataItem.trip_headsign }}
					</div>
				</div>
			</td>
			<td data-test="td.stop_name" class="ellipsis reporttooltip">
				<span class="tooltiptext">{{ reportDataItem.stop_name }}</span
				>{{ reportDataItem.stop_name }}
			</td>
			<td data-test="td.trip_headsign" class="ellipsis reporttooltip">
				<span class="tooltiptext">{{ reportDataItem.trip_headsign }}</span
				>{{ reportDataItem.trip_headsign }}
			</td>
			<td data-test="td.timepoint" class="ellipsis">{{ reportDataItem.timepoint }}</td>
			<td data-test="td.realtime" class="ellipsis">{{ reportDataItem.realtime }}</td>
			<td data-test="td.adherence">
				<adherence-display
					[adherence]="reportDataItem.adherence"
					[adherenceOptions]="adherenceOptions"
					[authorityId]="authorityId"
					[agencyId]="agencyId">
				</adherence-display>
			</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

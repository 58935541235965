<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<table>
					<thead>
						<tr class="raw_avl_spacing">
							<th
								[ngClass]="sortDetails.field === 'recDate' ? sortClass : null"
								(click)="sortList('recDate')"
								data-test="th.recDate"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.EVDATE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'rcvtime' ? sortClass : null"
								(click)="sortList('rcvtime')"
								data-test="th.rcvtime"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.EVTIME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpstime' ? sortClass : null"
								(click)="sortList('gpstime')"
								data-test="th.gpstime"
								class="mw-col ellipsis raw_avl_spacing vehicle-location-by-vehicle-list-gps-timestamp">
								{{ 'T_REPORT.GPS_TIMESTAMP' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpslat' ? sortClass : null"
								(click)="sortList('gpslat')"
								data-test="th.gpslat"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.LATITUDE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpslon' ? sortClass : null"
								(click)="sortList('gpslon')"
								data-test="th.gpslon"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.LONGITUDE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpsspeed' ? sortClass : null"
								(click)="sortList('gpsspeed')"
								data-test="th.gpsspeed"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.GTW_SPEED' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gpsheading' ? sortClass : null"
								(click)="sortList('gpsheading')"
								data-test="th.gpsheading"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.GTW_HEADING' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'predictable' ? sortClass : null"
								(click)="sortList('predictable')"
								data-test="th.predictable"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.PREDICTABILITY' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'route' ? sortClass : null"
								(click)="sortList('route')"
								data-test="th.route"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.ROUTE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'jobtag' ? sortClass : null"
								(click)="sortList('jobtag')"
								data-test="th.jobtag"
								class="mw-col ellipsis raw_avl_spacing">
								{{ 'T_REPORT.BLOCK_RUN' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'inputs' ? sortClass : null"
								(click)="sortList('inputs')"
								data-test="th.inputs"
								class="ellipsis raw_avl_spacing">
								{{ 'T_REPORT.INPUTS_TYPE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'fix_invalid' ? sortClass : null"
								(click)="sortList('fix_invalid')"
								data-test="th.fix_invalid"
								class="ellipsis raw_avl_spacing vehicle-location-by-vehicle-list-invalid-reason">
								{{ 'T_REPORT.INVALID_REASON' | translate }}
							</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr class="raw_avl_spacing" *ngFor="let reportItem of listData">
							<td data-test="td.recDate" class="ellipsis raw_avl_spacing">
								{{ reportItem.recDate !== null ? reportItem.recDate : 'N/A' }}
							</td>
							<td data-test="td.rcvtime" class="ellipsis raw_avl_spacing">
								{{ reportItem.rcvtime !== null ? reportItem.rcvtime : 'N/A' }}
							</td>
							<td data-test="td.gpstime" class="ellipsis raw_avl_spacing">
								{{ reportItem.gpstime !== null ? reportItem.gpstime : 'N/A' }}
							</td>
							<td data-test="td.gpslat" class="ellipsis raw_avl_spacing">{{ reportItem.gpslat !== null ? reportItem.gpslat : 'N/A' }}</td>
							<td data-test="td.gpslon" class="ellipsis raw_avl_spacing">{{ reportItem.gpslon !== null ? reportItem.gpslon : 'N/A' }}</td>
							<td data-test="td.gpsspeed" class="ellipsis raw_avl_spacing">
								{{ reportItem.gpsspeed !== null ? reportItem.gpsspeed : 'N/A' }}
							</td>
							<td data-test="td.gpsheading" class="ellipsis raw_avl_spacing">
								{{ reportItem.gpsheading !== null ? reportItem.gpsheading : 'N/A' }}
							</td>
							<td data-test="td.predictable" class="ellipsis raw_avl_spacing">
								{{ reportItem.predictable !== null ? reportItem.predictable : 'N/A' }}
							</td>
							<td data-test="td.route" class="ellipsis raw_avl_spacing">{{ reportItem.route !== null ? reportItem.route : 'N/A' }}</td>
							<td data-test="td.jobtag" class="ellipsis raw_avl_spacing">{{ reportItem.jobtag !== null ? reportItem.jobtag : 'N/A' }}</td>
							<td data-test="td.inputs" class="ellipsis raw_avl_spacing">{{ reportItem.inputs !== null ? reportItem.inputs : 'N/A' }}</td>
							<td data-test="td.fix_invalid" class="ellipsis raw_avl_spacing vehicle-location-by-vehicle-list-invalid-reason">
								{{ reportItem.fix_invalid !== null ? reportItem.fix_invalid : 'N/A' }}
							</td>
						</tr>
					</tbody>
				</table>
				<br />
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

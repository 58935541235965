/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { ConfirmModalComponent } from '@cubicNx/libs/utils';
import { ConfirmRiderCancellationAlertComponent } from '../confirm/confirm-rider-cancellation-alert.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { BlocksDataService } from './block-data.service';

import { ConfirmButtonType, ConfirmResult } from '@cubicNx/libs/utils';
import { TripsToActivate, TripsToCancel } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable()
export class BlockModalService {
	constructor(
		private translationService: TranslationService,
		private blocksDataService: BlocksDataService,
		private cancelTripsModal: MatDialog,
		private activateTripsModal: MatDialog,
		private cancelBlockModal: MatDialog,
		private activateBlockModal: MatDialog
	) {}

	/**
	 * trigger the cancel trips modal popup and cancel the trips on confirm
	 *
	 * @param authorityId - the authority id required for the cancel trips request
	 * @param agencyId - the agency id required for the cancel trips request
	 * @param blockId - the block id required for the cancel trips request
	 * @param tripsToCancel - the trips to cancel
	 * @returns true when the trips are successfully cancelled
	 */
	public cancelTrips = (authorityId: string, agencyId: string, blockId: string, tripsToCancel: TripsToCancel): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.cancelTripsModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_CANCEL_TRIPS'),
					header: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_CANCEL_TRIPS_HEADER'),
					confirmButtonType: ConfirmButtonType.yesNoType,
					component: ConfirmRiderCancellationAlertComponent,
					params: {
						confirmText: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_SEND_CANCEL_ALERT'),
					},
				},
			});

			return modalRef.afterClosed().subscribe(async (confirmResult: ConfirmResult) => {
				let cancelled: boolean = false;

				if (confirmResult.confirmed) {
					const sendCancellationAlert: boolean = confirmResult.eventData;

					const response: ResultContent = await this.blocksDataService.cancelTrips(
						authorityId,
						agencyId,
						blockId,
						tripsToCancel,
						sendCancellationAlert
					);

					if (response.success) {
						cancelled = true;
					}
				}

				observer.next(cancelled);
			});
		});
	};

	/**
	 * trigger the cancel trips modal popup and activate the trips on confirm
	 *
	 * @param authorityId - the authority id required for the activate trips request
	 * @param agencyId - the agency id required for the activate trips request
	 * @param blockId - the block id required for the activate trips request
	 * @param tripsToActivate - the trips to activate
	 * @returns true when the trips are successfully activated
	 */
	public activateTrips = (
		authorityId: string,
		agencyId: string,
		blockId: string,
		tripsToActivate: TripsToActivate
	): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.activateTripsModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_ACTIVE_TRIPS'),
					header: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_ACTIVE_TRIPS_HEADER'),
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			return modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				let activated: boolean = false;

				if (result?.confirmed) {
					const activateTripsResult: ResultContent = await this.blocksDataService.activateTrips(
						authorityId,
						agencyId,
						blockId,
						tripsToActivate
					);

					if (activateTripsResult.success) {
						activated = true;
					}
				}

				observer.next(activated);
			});
		});
	};

	/**
	 * trigger the cancel block modal popup and cancel the block on confirm
	 *
	 * @param authorityId - the authority id required for the cancel block request
	 * @param agencyId - the agency id required for the cancel block request
	 * @param blockId - the block id required for the cancel block request
	 * @param endTimestamp - the end time stamp for the cancel block request
	 * @returns true when the block is successfully cancelled
	 */
	public cancelBlock = (authorityId: string, agencyId: string, blockId: string, endTimestamp: number): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.cancelBlockModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_CANCEL_BLOCK'),
					header: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_CANCEL_BLOCK_HEADER'),
					confirmButtonType: ConfirmButtonType.yesNoType,
					component: ConfirmRiderCancellationAlertComponent,
					params: {
						confirmText: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_SEND_CANCEL_ALERT'),
					},
				},
			});

			return modalRef.afterClosed().subscribe(async (confirmResult: ConfirmResult) => {
				let cancelled: boolean = false;

				if (confirmResult.confirmed) {
					const sendCancellationAlert: boolean = confirmResult.eventData;

					const response: ResultContent = await this.blocksDataService.cancelBlock(
						authorityId,
						agencyId,
						blockId,
						endTimestamp,
						sendCancellationAlert
					);

					if (response.success) {
						cancelled = true;
					}
				}

				observer.next(cancelled);
			});
		});
	};

	/**
	 * trigger the activate block modal popup and activate the block on confirm
	 *
	 * @param authorityId - the authority id required for the activate block request
	 * @param agencyId - the agency id required for the activate block request
	 * @param blockId - the block id required for the activate block request
	 * @param endTimestamp - the end time stamp for the activate block request
	 * @returns true when the block is successfully activated
	 */
	public activateBlock = (authorityId: string, agencyId: string, blockId: string): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.activateBlockModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_ACTIVE_BLOCK'),
					header: this.translationService.getTranslation('T_MAP.MAP_BLOCKS_CONFIRM_ACTIVE_BLOCK_HEADER'),
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			return modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				let activated: boolean = false;

				if (result?.confirmed) {
					const activateBlockResult: ResultContent = await this.blocksDataService.activateBlock(authorityId, agencyId, blockId);

					if (activateBlockResult.success) {
						activated = true;
					}
				}

				observer.next(activated);
			});
		});
	};
}

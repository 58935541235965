/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UsersAdminEventsService {
	public passwordChange: Observable<any>;

	private passwordChangeSource: Subject<any> = new Subject<any>();

	constructor() {
		this.passwordChange = this.passwordChangeSource.asObservable();
	}

	/**
	 * Publish a user data change event allowing subscribers to react to password change actions.
	 * @param event - - an object containing data to pass to subscribers.
	 */
	public publishPasswordChange = (): void => {
		// @ts-ignore
		this.passwordChangeSource.next();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'reset-password-confirm',
	templateUrl: './reset-password-confirm.component.html',
	styleUrls: ['./reset-password-confirm.component.scss'],
})
export class ResetPasswordConfirmComponent extends TranslateBaseComponent {
	@Input() confirmText: string = null;

	constructor(translationService: TranslationService) {
		super(translationService);

		this.initTranslations(['T_USER.PASSWORD_RESET_CONFIRM_RESPONSE', 'T_USER.PASSWORD_RESET_EMAIL_SENT']);
	}
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AgenciesModule } from '../../support-features/agencies/agencies.module';
import { DepotsModule } from '../../support-features/depots/depots.module';
import { VehiclesModule } from '../../support-features/vehicles/vehicles.module';

import { VehicleMessagesCreateComponent } from './create/vehicle-messages-create.component';
import { VehicleMessagesListComponent } from './list/vehicle-messages-list.component';

import { VehicleMessagesApiService } from './services/vehicle-messages-api.service';
import { VehicleMessagesDataService } from './services/vehicle-messages-data.service';
import { VehicleMessagesModalService } from './services/vehicle-messages-modal.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [VehicleMessagesCreateComponent, VehicleMessagesListComponent],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgenciesModule,
		DepotsModule,
		VehiclesModule,
	],
	providers: [
		VehicleMessagesDataService,
		VehicleMessagesApiService,
		VehicleMessagesModalService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class VehicleMessagesModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InitEventsService {
	public initComplete: Observable<any>;

	private initCompleteSource: Subject<any> = new Subject<any>();

	constructor() {
		this.initComplete = this.initCompleteSource.asObservable();
	}

	/**
	 * Publish an init complete event allowing subscribers to react to init complete actions.
	 */
	public publishInitComplete = (): void => {
		// @ts-ignore
		this.initCompleteSource.next();
	};
}

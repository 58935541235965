<div class="paging-toolbar flex">
	<ul class="paging-toolbar-buttons pagination">
		<li role="menuitem" class="pagination-first" disabled="noPrevious()" data-test="pagination.page" [ngClass]="{ disabled: noPrevious() }">
			<a (click)="!noPrevious() && selectPage('first')"><i class="datatable-icon-prev"></i></a>
		</li>
		<li role="menuitem" class="pagination-prev" disabled="noPrevious()" data-test="pagination.page" [ngClass]="{ disabled: noPrevious() }">
			<a (click)="!noPrevious() && selectPage('previous')"><i class="datatable-icon-left"></i></a>
		</li>

		<li
			*ngFor="let page of getPageButtons()"
			role="menuitem"
			class="pagination-page"
			[ngClass]="{ active: page === pagination.currentPage }"
			data-test="pagination.page">
			<a (click)="selectPage(page)">{{ page }}</a>
		</li>

		<li role="menuitem" class="pagination-next" disabled="noLast()" data-test="pagination.page" [ngClass]="{ disabled: noLast() }">
			<a (click)="!noLast() && selectPage('next')"><i class="datatable-icon-right"></i></a>
		</li>
		<li role="menuitem" class="pagination-last" disabled="noLast()" data-test="pagination.page" [ngClass]="{ disabled: noLast() }">
			<a (click)="!noLast() && selectPage('last')"><i class="datatable-icon-skip"></i></a>
		</li>
	</ul>
	<div class="paging-toolbar-number-per-page" data-test="pagination.perPage">
		<select class="form-control" [(ngModel)]="paginationSettings.pageSize" (change)="setPageSizeChanged($event)">
			<option value="5">5 per page</option>
			<option *ngIf="paginationSettings.maxPageSize >= 10" value="10">10 per page</option>
			<option *ngIf="paginationSettings.maxPageSize >= 25" value="25">25 per page</option>
			<option *ngIf="paginationSettings.maxPageSize >= 50" value="50">50 per page</option>
			<option *ngIf="paginationSettings.maxPageSize >= 100" value="100">100 per page</option>
			<option *ngIf="paginationSettings.maxPageSize >= 200" value="200">200 per page</option>
		</select>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { WeatherDataService } from '../services/weather-data.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';

import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { AgencyDetail } from '../../../../../support-features/agencies/types/api-types';
import { Observation, RotateIconType, WeatherConditions } from '../types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { IWidgetComponent } from '../../../types/types';

@Component({
	selector: 'weather',
	templateUrl: './weather.component.html',
	styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;

	public loaded: boolean = false;
	public success: boolean = false;
	public hasResults: boolean = false;
	public selectedAgency: SelectedAgency = null;
	public currentWeather: WeatherConditions = null;

	public rotateClass: RotateIconType = { direction: '', pressure: '' };
	public lastUpdated: Date = null;
	public unitOfMeasure: string = null;

	private latitude: string = null;
	private longitude: string = null;
	private refreshTimer: any = null;

	constructor(
		private widgetEventsService: WidgetEventsService,
		private agenciesDataService: AgenciesDataService,
		private weatherDataService: WeatherDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the weather component - setting up subscriptions and getting initial
	 * wether conditions for the agency
	 */
	public ngOnInit(): void {
		this.setSubscriptions();

		this.init();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * timer based refresh of the weather conditions
	 */
	private init = async (): Promise<void> => {
		this.loaded = false;

		this.lastUpdated = new Date();

		this.selectedAgency = this.data.config?.selectedAgency;

		if (this.refreshTimer) {
			this.stopTimer();
		}

		const timerValue: number = (this.data.config.refreshRateSec || 10) * 1000;

		await this.setup();

		await this.GetCurrentWeatherConditions();

		this.loaded = true;

		this.refreshTimer = setInterval(async () => {
			await this.GetCurrentWeatherConditions();
			this.lastUpdated = new Date();
		}, timerValue);
	};

	/**
	 * stop the refresh timer
	 */
	private stopTimer = (): void => {
		clearInterval(this.refreshTimer);
	};

	/**
	 * widget setup so which agency and then setting the weather boundaries
	 */
	private setup = async (): Promise<void> => {
		const result: ResultContent = await this.agenciesDataService.getAgency(
			this.selectedAgency.authority_id,
			this.selectedAgency.agency_id
		);

		if (result.success) {
			const agencyDetail: AgencyDetail = result.resultData;

			this.setUpBoundary(agencyDetail);
		}
	};

	/**
	 * setting up the boundaries for retrieving weather conditions
	 *
	 * @param agencyDetail - agency detail
	 */
	private setUpBoundary = (agencyDetail: AgencyDetail): void => {
		this.latitude = agencyDetail?.latitude;
		this.longitude = agencyDetail?.longitude;
		this.unitOfMeasure = agencyDetail?.unit_of_measure;
	};

	/**
	 * requests the current weather conditions
	 */
	private GetCurrentWeatherConditions = async (): Promise<void> => {
		const result: ResultContent = await this.weatherDataService.getWeatherConditions(
			this.selectedAgency.authority_id,
			this.selectedAgency.agency_id,
			this.longitude,
			this.latitude
		);

		// wait until after the request so we don't see it flicker back to no data on the page
		this.success = false;

		if (result.success) {
			this.currentWeather = result.resultData as WeatherConditions;

			this.currentWeather.alerts = this.currentWeather.alerts || [];

			const observation: Observation = this.currentWeather.current_observation;

			observation.external_link = observation.forecast_url || observation.image.link;

			this.rotateClass.direction = this.setWindDirectionIcon(observation.wind_dir);
			this.rotateClass.pressure = this.setPressureDirectionIcon(observation.pressure_trend);

			this.success = true;
		}
	};

	/**
	 * determines which icon is needed for the supplied pressure trend
	 *
	 * @param pressureTrend - pressure trend
	 * @returns which icon represents the supplied pressure trend
	 */
	private setPressureDirectionIcon = (pressureTrend: string): string => {
		let lessClass: string = null;

		switch (pressureTrend) {
			case '+':
				lessClass = 'south-icon';
				break;
			case '-':
				lessClass = 'north-icon';
				break;
			default:
				lessClass = '';
				break;
		}

		return lessClass;
	};

	/**
	 * determines which icon is needed for the supplied wind direction
	 *
	 * @param direction - wind direction
	 * @returns which icon represents the supplied wind direction
	 */
	private setWindDirectionIcon = (direction: string): string => {
		let lessClass: string = null;

		switch (direction) {
			case 'North':
				lessClass = 'north-icon';
				break;
			case 'NNE':
				lessClass = 'north-northeast-icon';
				break;
			case 'NE':
				lessClass = 'northeast-icon';
				break;
			case 'ENE':
				lessClass = 'east-northeast-icon';
				break;
			case 'East':
				lessClass = 'east-icon';
				break;
			case 'ESE':
				lessClass = 'east-southeast-icon';
				break;
			case 'SE':
				lessClass = 'southeast-icon';
				break;
			case 'SSE':
				lessClass = 'south-southeast-icon';
				break;
			case 'South':
				lessClass = 'south-icon';
				break;
			case 'SSW':
				lessClass = 'south-southwest-icon';
				break;
			case 'SW':
				lessClass = 'southwest-icon';
				break;
			case 'WSW':
				lessClass = 'west-southwest-icon';
				break;
			case 'West':
				lessClass = 'west-icon';
				break;
			case 'WNW':
				lessClass = 'west-northwest-icon';
				break;
			case 'NW':
				lessClass = 'northwest-icon';
				break;
			case 'NNW':
				lessClass = 'north-northwest-icon';
				break;
			default:
				lessClass = '';
				break;
		}

		return lessClass;
	};

	/**
	 * sets up subscriptions - is interested in the widget reloading
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.init();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		this.stopTimer();
		this.reloadWidget$Subscription?.unsubscribe();
	};
}

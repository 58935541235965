/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { DataService } from '@cubicNx/libs/utils';
import { ModalEventsService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { EventData } from '@cubicNx/libs/utils';

@Component({
	selector: 'confirm-map-view-save',
	templateUrl: './confirm-map-view-save.component.html',
	styleUrls: ['./confirm-map-view-save.component.scss'],
})
export class ConfirmMapViewSaveComponent extends TranslateBaseComponent implements OnInit {
	public viewName: string = null;
	public viewNameEdited: boolean = false;

	constructor(
		private modalEventsService: ModalEventsService,
		public data: DataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		this.viewName = this.data['viewName' as keyof DataService];

		this.publishViewNameDataUpdate();
	}

	/**
	 * handle the user changing thew view name
	 */
	public onViewNameChange = (): void => {
		this.viewNameEdited = true;

		this.publishViewNameDataUpdate();
	};

	/**
	 * publish a view name update
	 */
	private publishViewNameDataUpdate = (): void => {
		const eventData: EventData = {
			data: this.viewName,
			confirmEnabled: this.viewName?.length > 0,
		};

		this.modalEventsService.publishDataChangedEvent(eventData);
	};
}

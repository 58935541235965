/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { MapViewDataService } from './map-view-data.service';
import { MapViewModalService } from './map-view-modal.service';
import { MapViewEventsService } from './map-view-events.service';
import { MapStateService } from '../../../services/map-state.service';
import { MapPollingService } from '../../../services/map-polling.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { MapStopsService } from '../../../services/map-stops.service';
import { MapVehiclesService } from '../../../services/map-vehicles.service';
import { MapLocationService } from '../../../services/map-location.service';
import { MapOptionsService } from '../../../services/map-options.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { MapViews } from '../types/types';
import { MapState } from '../../../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapViewService {
	constructor(
		private mapViewDataService: MapViewDataService,
		private mapViewEventsService: MapViewEventsService,
		private mapStateService: MapStateService,
		private mapPollingService: MapPollingService,
		private mapHistoryService: MapHistoryService,
		private mapOptionsService: MapOptionsService,
		private mapRoutesService: MapRoutesService,
		private mapStopsService: MapStopsService,
		private mapVehiclesService: MapVehiclesService,
		private mapLocationService: MapLocationService,
		private mapViewModalService: MapViewModalService
	) {}

	/**
	 * get the list views from the data layer
	 * @returns  the list views
	 */
	public getViews = async (): Promise<MapViews> => {
		let viewList: MapViews = [];

		const response: ResultContent = await this.mapViewDataService.getViews();

		if (response.success) {
			viewList = response.resultData as MapViews;
		}

		return viewList;
	};

	/**
	 * rename the view via the data layer
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view to update
	 * @param viewTitle - the new view title
	 * @param viewState - a copy (snapshot) of the map state for this view
	 */
	public renameView = async (userId: number, viewId: number, viewTitle: string, viewState: MapState): Promise<void> => {
		// Put up a confirmation modal for the rename - assuming it is confirmed then publish to enforce the list retrieval
		// to refresh the list
		this.mapViewModalService.renameView(userId, viewId, viewTitle, viewState).subscribe(async (renamed: boolean) => {
			if (renamed) {
				this.mapViewEventsService.publishRefreshViews();
			}
		});
	};

	/**
	 * create  aview via the data layer
	 *
	 * @param userId - the id of the user
	 */
	public createView = async (userId: number): Promise<void> => {
		// Put up a confirmation modal for the creation - assuming it is confirmed then publish to enforce the list retrieval
		// to refresh the list
		this.mapViewModalService.createView(userId).subscribe(async (created: boolean) => {
			if (created) {
				this.mapViewEventsService.publishRefreshViews();
			}
		});
	};

	/**
	 * delete a view via the data layer
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view to update
	 * @param viewTitle - the new view title
	 */
	public deleteView = async (userId: number, viewId: number, viewTitle: string): Promise<void> => {
		// Put up a confirmation modal for the deletion - assuming it is confirmed then publish to enforce the list retrieval
		// to refresh the list
		this.mapViewModalService.deleteView(userId, viewId, viewTitle).subscribe(async (deleted: boolean) => {
			if (deleted) {
				this.mapViewEventsService.publishRefreshViews();
			}
		});
	};

	/**
	 * apply a view via the data layer
	 *
	 * @param viewTitle - the new view title
	 * @param viewState - a copy (snapshot) of the map state for this view
	 */
	public applyView = async (viewTitle: string, viewState: MapState): Promise<void> => {
		// Put up a confirmation modal for the apply - assuming it is confirmed then apply the view
		this.mapViewModalService.applyView(viewTitle).subscribe(async (applied: boolean) => {
			if (applied) {
				// take a copy so edit's don't affect the original
				const viewStateClone: MapState = ObjectHelpers.deepCopy(viewState);

				this.apply(viewStateClone);
			}
		});
	};

	/**
	 * handle the apply of a view by replacing the current map state object with the map state object saved against
	 * this view.  Clear all entities down, reload the new view and re-initialise
	 *
	 * @param viewState - the map state saved against the view to apply
	 */
	private apply = async (viewState: MapState): Promise<void> => {
		this.mapPollingService.stopPolling();

		this.mapVehiclesService.clearVehicles();
		this.mapRoutesService.clearRoutes();
		this.mapStopsService.clearStops();

		await this.mapStateService.setCurrentState(viewState);

		setTimeout(() => {
			this.mapHistoryService.reset();

			this.mapLocationService.setLocation(this.mapLocationService.getLocation());

			// initialize routes and stops from what we have saved - note: we wont load vehicles as they will be out of date
			this.mapRoutesService.initViewRoutes();
			this.mapStopsService.initViewStops();

			// refresh the mode - the ladder needs to setup it's routes etc if we are in that mode
			this.mapOptionsService.refreshMode();

			// set the first refresh back to true - when we start polling the first poll will get the latest vehicle positions
			// for those in our state and render
			this.mapVehiclesService.setFirstRefresh(true);

			// for a restart of the polling
			this.mapPollingService.initPolling();
		}, 500);
	};
}

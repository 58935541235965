<div *ngIf="!stopLoaded">
	<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
</div>
<div *ngIf="stopLoaded">
	<div class="nb-map-subpage-header-container nb-map-details-body stop-details-header-container" id="map-details-stop-header">
		<!--Back button container for alignment-->
		<div class="header-backbutton">
			<button (click)="goBack()" class="btn btn-link-dark" data-test="stop-details.back.btn">
				<i class="nb-icons nb-backarrow"></i>
			</button>
		</div>
		<!--Header content-->
		<div class="header-content stop-details-route-header-content-container">
			<div class="header-title stop-details-stop-header-title-container">
				<span class="nb-subhead stop-details-stop-header-title-sub-header-container">
					<span>{{ 'T_MAP.MAP_STOP' | translate }}</span>
					<span class="nb-subhead nb-padding-left-sm" data-test="stop-details.header.code">{{ stop.stop_code }}</span>
				</span>
			</div>
			<div class="header-body">
				<div class="nb-maps-viewtables">
					<div class="item">
						<div>
							<span class="view-table-label">{{ 'T_MAP.MAP_DESCRIPTION' | translate }}</span>
						</div>
						<div>
							<span class="view-table-field" data-test="stop.name.header">{{ stop.stop_name }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="nb-mapheader-toolbar" id="map-details-stop-toolbar">
		<!--Buttons here, like view-on/off, zoom to object-->
		<span [hidden]="!stopDisplayed()">
			<button
				class="btn btn-toggle-on"
				(click)="toggleRenderStop()"
				title="{{
					mapOptionsService.getMode() === modeType.ladder
						? getTranslation('T_CORE.UNAVAILABLE')
						: getTranslation('T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_TOOLTIP')
				}}"
				[disabled]="mapOptionsService.getMode() === modeType.ladder"
				data-test="stop-details.toggleVisibility.off">
				<i class="nb-icons nb-view-on visibility-button-icon"></i>
			</button>
		</span>
		<span [hidden]="stopDisplayed()">
			<button
				class="btn btn-toggle"
				(click)="toggleRenderStop()"
				title="{{
					mapOptionsService.getMode() === modeType.ladder
						? getTranslation('T_CORE.UNAVAILABLE')
						: getTranslation('T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_TOOLTIP')
				}}"
				[disabled]="mapOptionsService.getMode() === modeType.ladder"
				data-test="stop-details.toggleVisibility.on">
				<i class="nb-icons nb-view-off visibility-button-icon"></i>
			</button>
		</span>
		<button
			class="btn btn-action"
			(click)="zoomTo()"
			title="{{
				mapOptionsService.getMode() === modeType.ladder
					? getTranslation('T_CORE.UNAVAILABLE')
					: stopDisplayed()
						? getTranslation('T_MAP.MAP_VIEW_ZOOM_TO')
						: getTranslation('T_MAP.STOP_VEHICLE_ACTION_DISABLED')
			}}"
			[disabled]="!zoomEnabled()"
			data-test="stop-details.zoomTo">
			<i class="nb-icons nb-zoomtoobject zoom-button-icon" aria-hidden="true"></i>
		</button>
	</div>
	<div class="nb-map-details-stop-wrapper nb-map-details-body" id="stopdetailstop">
		<div class="content-block">
			<div class="sectiontitle-block" data-test="section">
				<div class="nb-section-title">
					<div>
						<span>{{ 'T_MAP.MAP_ROUTES' | translate }}</span>
					</div>
				</div>
			</div>
			<div>
				<div class="info-table nb-margin-bottom-sm">
					<div>
						<div *ngFor="let route of stopRoutes" class="stop-routes-container">
							<a (click)="navigateToRouteDetails(route.routeId)">
								<route-pill [route]="route" [useFullWidth]="true" data-test="stop-details.route"></route-pill>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--Stops Predictions-->
		<div class="content-block" data-test="stop-details.vehicle.table">
			<div class="sectiontitle-block" data-test="section">
				<div class="nb-section-title">
					<span>{{ 'T_MAP.MAP_PREDICTIONS' | translate }}</span>
				</div>
			</div>

			<div class="data-list-container">
				<data-table
					#dataList
					data-test="stop.details.table"
					[listName]="listName"
					[columns]="columns"
					[rowData]="stopDetailPredictionsList"
					[totalRows]="stopDetailPredictionsList?.length"
					[pagingEnabled]="false"
					[externalSorting]="true"
					[sortInfo]="sortInfo"
					[enableFooter]="false"
					[enableCheckRowSelection]="false"
					[loadingIndicator]="listLoadingIndicator"
					[noDataMessage]="'T_MAP.MAP_NO_PREDICTIONS'"
					(rowClick)="onSelect($event)"
					(sortData)="handleSortRequest($event)">
				</data-table>
			</div>
		</div>
	</div>
</div>

<!-- Placed outside of ngIfs so view child can pick up the reference -->
<ng-template #routePillColumnTemplate let-row="row">
	<route-pill *ngIf="row" [route]="determinePredictedRoutePillData(row.id)" [useFullWidth]="true"></route-pill>
</ng-template>

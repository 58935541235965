/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { AgencyDetail } from '../../../../support-features/agencies/types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class AgenciesAdminApiService {
	private readonly agenciesUrl: string = '/agencies';

	constructor(private httpService: HttpService) {}

	/**
	 * retrieves agencies that match the supplied parameters
	 *
	 * @param params - agency retrieval parameters - page size, number, sort criteria and search text
	 * @returns agencies matching the supplied parameters
	 */
	public getAgencies = async (params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get(this.agenciesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves agency details that match the supplied parameters
	 *
	 * @param authorityId - authority id
	 * @param agencyId - agency id
	 * @returns agency details
	 */
	public getAgency = async (authorityId: string, agencyId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.agenciesUrl + '/' + agencyId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * saves the agency as per the supplied parameters
	 *
	 * @param updatedAgency - updated agency details
	 * @returns the result of the save operation
	 */
	public saveAgency = async (updatedAgency: AgencyDetail): Promise<any> => {
		try {
			return await this.httpService.put(
				'/' + updatedAgency.authority_id + this.agenciesUrl + '/' + updatedAgency.agency_id,
				updatedAgency
			);
		} catch (exception) {
			throw exception;
		}
	};
}

import { Component, Input, OnChanges } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'headway-performance-summary',
	templateUrl: './headway-performance-summary.component.html',
	styleUrls: ['./headway-performance-summary.component.scss'],
})
export class HeadwayPerformanceSummaryComponent implements OnChanges {
	@Input() performanceData: any;

	public translations: any = {};
	public data: any;

	public chartTitle: string = null;
	public options: any = null;
	public titleVal: string = null;

	private onTimePercentage: number = null;

	constructor(private translationService: TranslationService) {}

	/**
	 * for when the performance data changes - loads chart with new data
	 */
	public ngOnChanges(): void {
		this.handleChanges();
	}

	/**
	 * loads the chart when the performance data changes
	 */
	private handleChanges = async (): Promise<void> => {
		await this.checkTranslationsLoaded();
		this.data = [
			{
				key: this.translations['CHARTS_ON_TIME'],
				values: [],
			},
		];

		if (!this.performanceData) {
			return;
		}

		this.chartTitle = this.translations['CHARTS_ON_TIME'];

		await this.loadChart();
	};

	/**
	 * loads the chart with the performance data
	 */
	private loadChart = async (): Promise<void> => {
		this.onTimePercentage = this.formatPercentages(this.performanceData.onTime, this.performanceData.total);
		this.titleVal = this.onTimePercentage.toString();

		// This next line is a hack to stop tooltips from staying rendered after chart reloads
		// d3.selectAll('.nvtooltip').style('opacity', 0);
		if (isNaN(this.performanceData.onTimePercentage)) {
			this.performanceData.onTimePercentage = 0;
		}

		this.setChartOptions();

		this.data = [
			{
				key: this.translations['CHARTS_ON_TIME'],
				y: this.formatPercentages(this.performanceData.onTime, this.performanceData.total),
				color: '#14ad3d',
			},
			{
				key: this.translations['CHARTS_CLOSE'],
				y: this.formatPercentages(this.performanceData.close, this.performanceData.total),
				color: '#eb3b3b',
			},
			{
				key: this.translations['CHARTS_VERY_CLOSE'],
				y: this.formatPercentages(this.performanceData.veryClose, this.performanceData.total),
				color: '#b00808',
			},
			{
				key: this.translations['CHARTS_DISTANT'],
				y: this.formatPercentages(this.performanceData.distant, this.performanceData.total),
				color: '#ffb852',
			},
			{
				key: this.translations['CHARTS_VERY_DISTANT'],
				y: this.formatPercentages(this.performanceData.veryDistant, this.performanceData.total),
				color: '#e89015',
			},
		];
	};

	/**
	 * sets the chart options
	 */
	private setChartOptions = (): void => {
		this.options = {
			title: {
				enable: false,
				html: this.dynamicHTML(this.onTimePercentage.toString()),
				css: {
					position: 'absolute',
					top: '50%',
					transform: 'translate(0, -62%)',
					pointerEvents: 'none',
				},
			},
			chart: {
				type: 'pieChart',
				height: 300,
				width: 300,
				donut: true,
				donutRatio: 0.85,
				noData: this.translations['CHARTS_NO_DATA_AVAILABLE'],
				x: (d: any): any => d.key,
				y: (d: any): any => d.y,
				showLabels: false,
				showLegend: false,
				duration: 500,
				startAngle: (d: any): any => d.startAngle + Math.PI,
				endAngle: (d: any): any => d.endAngle + Math.PI,
				pie: {
					dispatch: {
						elementMouseover: this.mouseOver,
						elementMouseout: this.mouseOut,
					},
				},
				tooltip: {
					enabled: false,
				},
			},
		};
	};

	/**
	 * when mouse is moved out of the chart
	 *
	 * title and value are set accordingly
	 */
	private mouseOut = (): void => {
		this.chartTitle = this.translations['CHARTS_ON_TIME'];
		this.titleVal = this.onTimePercentage.toString();
	};

	/**
	 * when mouse is moved over the chart
	 *
	 * title and value are set accordingly
	 * @param e - the mouse over event
	 */
	private mouseOver = (e: any): void => {
		this.chartTitle = e.data.key;
		this.titleVal = e.data.y;
	};

	/**
	 * creates a percentage from the value and total
	 *
	 * @param value - value
	 * @param total - total
	 * @returns formatted percentage value of the total
	 */
	private formatPercentages = (value: number, total: number): number => {
		const result: number = Math.floor((value / total) * 100);

		return isNaN(result) ? 0 : result;
	};

	/**
	 * creates a snippet of HTML for the chart title
	 *
	 * @param title - title
	 * @returns div tag for the chart title
	 */
	private dynamicHTML = (title: string): string => {
		return '<div id="dynamicTitle" style="font-size: 28px; ">' + title + '%' + '</div>' + this.chartTitle;
	};

	/**
	 * initializes the translations for the view
	 */
	private initTranslations = async (): Promise<void> => {
		this.translations['CHARTS_ON_TIME'] = this.translationService.getTranslation('T_CORE.CHARTS_ON_TIME');
		this.translations['CHARTS_CLOSE'] = this.translationService.getTranslation('T_CORE.CHARTS_CLOSE');
		this.translations['CHARTS_VERY_CLOSE'] = this.translationService.getTranslation('T_CORE.CHARTS_VERY_CLOSE');
		this.translations['CHARTS_DISTANT'] = this.translationService.getTranslation('T_CORE.CHARTS_DISTANT');
		this.translations['CHARTS_VERY_DISTANT'] = this.translationService.getTranslation('T_CORE.CHARTS_VERY_DISTANT');
		this.translations['CHARTS_NO_DATA_AVAILABLE'] = this.translationService.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE');
	};

	/**
	 * checks if the translations have been loaded
	 */
	private checkTranslationsLoaded = async (): Promise<void> => {
		if (!this.translations['CHARTS_ON_TIME']) {
			await this.initTranslations();
		}
	};
}

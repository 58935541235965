import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
	name: 'formatField',
	pure: false,
})
export class FormatField implements PipeTransform {
	private nb: any = {
		data: {
			field: {
				Field: {
					type: 'auto',
					isField: true,
					allowBlank: true,
					allowNull: true,
					defaultValue: undefined,
					name: null,
					persist: null,
					identifier: false,
					convert: null,
					validators: null,
				},

				Date: undefined,
				String: undefined,
				Integer: undefined,
				Number: undefined,
				Boolean: undefined,
			},
		},
	};

	/**
	 * this pipe transform is the main entry point for processing each field within our templates
	 *
	 * control the field filtering using the following field config options
	 *
	 * String type
	 *    available types  ["string", "int", "number", "boolean", "date"]
	 *
	 * Example:
	 * type:"string"
	 *
	 *
	 * String dateFormat
	 *
	 * Example:
	 * type:"MM/dd"
	 *
	 *
	 * Function convert
	 * A function to be called to process that value; passed the value and the field config record;
	 * Example:
	 * convert: function (v, record)\{ return value.toUpperCase()\}
	 *
	 *
	 * Boolean allowblank
	 * defines if the field is required when persisting or not
	 * Example:
	 * allowblank:true
	 *
	 *
	 * Auto defaultValue
	 * a value to display when no value is available;
	 * Example:
	 * defaultValue:"none available"
	 *
	 *
	 * boolean persist
	 * a value to display when no value is available;
	 * Example:
	 * defaultValue:"none available"
	 *
	 *
	 * string template
	 * a string with a bunch of html
	 * Example:
	 * defaultValue:"none available"
	 *
	 * string templateUrl
	 * a url to load the template
	 * Example:
	 * defaultValue:"/features/user/userselectorfield.js"
	 *
	 *
	 * array validators
	 *
	 * each field class will have the validators defined;
	 * minValue
	 * maxValue
	 * maxLength
	 *
	 * @param input - the data to transform/format
	 * @param rowDef - the row definitions including each type and format
	 * @param model - the object containing the data
	 * @returns the formatted/converted string
	 */
	transform(input: string, rowDef: any, model: any): string {
		this.nb.data.field.Date = Object.assign({}, this.nb.data.field.Field, {
			type: 'date',
		});

		this.nb.data.field.String = Object.assign({}, this.nb.data.field.Field, {
			type: 'string',
			convert: this.dateFieldConvert,
		});

		this.nb.data.field.Integer = Object.assign({}, this.nb.data.field.Field, {
			type: 'int',
		});

		this.nb.data.field.Number = Object.assign({}, this.nb.data.field.Field, {
			type: 'number',
		});

		this.nb.data.field.Boolean = Object.assign({}, this.nb.data.field.Field, {
			type: 'boolean',
		});

		// *************** Note this is not called when using rowDef.template ******************

		let output: string = null;

		const defaultDateFormat: string = 'shortDate';
		const dateFormat: any = rowDef.dateFormat || defaultDateFormat;

		// dataType check;
		switch (rowDef.type) {
			case 'string':
			case 'autoHideString':
				output = this.nb.data.field.String.convert(input);
				output = input;
				break;
			case 'boolean':
				output = input ? 'true' : 'false';
				break;
			case 'date':
				output = input ? formatDate(input, dateFormat, 'en-US') : '';
				break;
			case 'int':
				output = input;
				break;
			case 'number':
				output = input;
				break;
			default:
				output = input;
				break;
		}

		if (rowDef.convert) {
			output = rowDef.convert(output, model);
		}

		// check output for null. if rowDef has a defaultValue return defaultValue. else if rowDef.allowNull return null otherwise return ""
		if (output === undefined || output === null) {
			output = rowDef.defaultValue ? rowDef.defaultValue : rowDef.allowNull ? null : '';
		}

		return output;
	}

	/**
	 * handle the date field conversion
	 *
	 * @param v - the date value
	 * @returns - the converted date
	 */
	private dateFieldConvert = (v: any): any => {
		const defaultValue: string = this.nb.data.field.Field.allowNull ? null : '';

		return v === undefined || v === null ? defaultValue : v.toString();
	};
}

<div class="ladder-container">
	<table>
		<tr class="ladder-row">
			<td>
				<table>
					<tr>
						<th colspan="2" class="ladder-width">
							<div class="vertical-ladder-header1" data-test="ladder.header" (click)="openRouteDetails()">
								<route-pill *ngIf="route" [route]="routePill" class="ladder-row-route-pill"></route-pill>
							</div>
							<div
								[ngStyle]="{ visibility: directionCount !== 1 ? 'visible' : 'hidden' }"
								class="vertical-ladder-header2"
								data-test="ladder.graybox.top">
								<div
									*ngFor="let vehicle of upDeadGrayBoxVehicles"
									class="ladder-row-vehicle"
									id="{{ 'v_' + vehicle.vehicleId }}"
									(mouseover)="vehicleLabelHoverIn(vehicle)"
									(mouseout)="vehicleLabelHoverOut(vehicle)">
									<div class="up-dead-gray-box-vehicle" [innerHtml]="vehicle.icon | safeHtml" (click)="openVehDetails(vehicle)"></div>
									<div [hidden]="!vehicle.hoverFlag" class="ladder-row-vehicle-inner" [innerHtml]="vehicle.vehLabelHtml | safeHtml"></div>
								</div>
							</div>
						</th>
					</tr>
					<tr *ngIf="!loading">
						<td
							*ngIf="directionCount !== 0"
							class="half-ladder-width"
							[ngStyle]="{ height: heightForStops + 'px' }"
							[attr.colspan]="directionCount === 1 ? 2 : 1">
							<div class="single-ladder single-ladder-left" [ngStyle]="{ right: directionCount === 1 ? '50px' : '10px' }">
								<div *ngFor="let stop of inboundStopsDisplay; let last = last">
									<div [hidden]="!stop.showLabel" data-test="ladder.stop" class="vertical-stop-label-right">
										{{ formatStopLabel(stop) }}
									</div>
									<img
										src="/assets/img/stopicon.png"
										data-test="ladder.stop.icon"
										(mouseout)="stopHoverOut()"
										(mouseover)="stopHoverIn($event, stop, 'inbound', 'left')"
										(click)="openStopDetails(stop)"
										[ngClass]="getStopStyle(stop.stopCode)"
										alt="Unavailable" />
									<div
										id="{{ 'inbound-stopLabel' + route.routeId }}"
										class="stop-label"
										[ngStyle]="getStopLabelStyle()"
										(mouseover)="stopTooltipHoverIn()"
										(mouseout)="stopTooltipHoverOut()"></div>
									<div class="step-line" [hidden]="last" [ngStyle]="{ height: stop.lineHeight + 'px' }"></div>
								</div>
								<div
									*ngFor="let vehicle of direction1Vehicles"
									id="{{ 'v_' + vehicle.vehicleId }}"
									(mouseover)="vehicleLabelHoverIn(vehicle)"
									(mouseout)="vehicleLabelHoverOut(vehicle)">
									<div [ngStyle]="getVehicleStyle(vehicle)" [innerHtml]="vehicle.icon | safeHtml" (click)="openVehDetails(vehicle)"></div>
									<div
										[hidden]="!mapOptionsService.vehicleLabelsAreShowing() && !vehicle.hoverFlag"
										[ngStyle]="getVehicleLabelStyle(vehicle, 'left')"
										[innerHtml]="vehicle.vehLabelHtml | safeHtml"></div>
								</div>
							</div>
						</td>
						<td *ngIf="directionCount > 1" class="half-ladder-width" [ngStyle]="{ height: heightForStops + 'px' }">
							<div class="single-ladder single-ladder-right">
								<div *ngFor="let stop of outboundStopsDisplay; let last = last">
									<div [hidden]="!stop.showLabel" data-test="ladder.stop" class="vertical-stop-label-left">{{ formatStopLabel(stop) }}</div>
									<img
										src="/assets/img/stopicon.png"
										data-test="ladder.stop.icon"
										(mouseover)="stopHoverIn($event, stop, 'outbound', 'right')"
										(mouseout)="stopHoverOut()"
										(click)="openStopDetails(stop)"
										[ngClass]="getStopStyle(stop.stopCode)"
										alt="Unavailable" />
									<div
										id="{{ 'outbound-stopLabel' + route.routeId }}"
										class="stop-label"
										[ngStyle]="getStopLabelStyle()"
										(mouseover)="stopTooltipHoverIn()"
										(mouseout)="stopTooltipHoverOut()"></div>
									<div class="step-line" [hidden]="last" [ngStyle]="{ height: stop.lineHeight + 'px' }"></div>
								</div>
								<div
									*ngFor="let vehicle of direction2Vehicles"
									id="{{ 'v_' + vehicle.vehicleId }}"
									(mouseover)="vehicleLabelHoverIn(vehicle)"
									(mouseout)="vehicleLabelHoverOut(vehicle)">
									<div [ngStyle]="getVehicleStyle(vehicle)" [innerHtml]="vehicle.icon | safeHtml" (click)="openVehDetails(vehicle)"></div>
									<div
										[hidden]="!mapOptionsService.vehicleLabelsAreShowing() && !vehicle.hoverFlag"
										[ngStyle]="getVehicleLabelStyle(vehicle, 'right')"
										[innerHtml]="vehicle.vehLabelHtml | safeHtml"></div>
								</div>
							</div>
						</td>
						<td colspan="2" [ngStyle]="{ height: heightForStops + 'px' }" *ngIf="directionCount === 0">
							<div class="ladder-row-no-active-trips-container">
								<span class="ladder-row-no-active-trips">{{ 'T_MAP.LADDER_NO_ACTIVE_TRIPS' | translate }}</span>
							</div>
						</td>
					</tr>
					<tr *ngIf="loading">
						<td colspan="2" [ngStyle]="{ height: heightForStops + 'px' }" class="ladder-row-stops-column">
							<div class="center-text">
								<img src="/assets/img/loading.svg" data-test="ladder.loading" class="ladder-row-stops" alt="Unavailable" />
							</div>
						</td>
					</tr>
					<tr>
						<td colspan="2" [ngStyle]="{ 'padding-top': '17px' }">
							<div class="vertical-ladder-header2 ladder-row-vehicle-label-container" data-test="ladder.graybox.bottom">
								<div
									*ngFor="let vehicle of downDeadGrayBoxVehicles"
									class="ladder-row-vehicle-label"
									id="{{ 'v_' + vehicle.vehicleId }}"
									(mouseover)="vehicleLabelHoverIn(vehicle)"
									(mouseout)="vehicleLabelHoverOut(vehicle)">
									<div class="down-dead-gray-box-vehicle" [innerHtml]="vehicle.icon | safeHtml" (click)="openVehDetails(vehicle)"></div>
									<div
										[hidden]="!vehicle.hoverFlag"
										class="ladder-row-vehicle-label-inner"
										[innerHtml]="vehicle.vehLabelHtml | safeHtml"></div>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</td>
		</tr>
	</table>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { UserSettings } from '../../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class CurrentUserApiService {
	private readonly usersUrl: string = '/users';
	private readonly agencyPortalSettingsUrl: string = '/agencyportalsettings';

	constructor(private httpService: HttpService) {}

	/**
	 * save user settings to the backend API
	 * @param currentUserId - the current user id
	 * @param userSettings - the current user settings
	 * @returns the result of the request
	 */
	public saveUserSettings = async (currentUserId: number, userSettings: UserSettings): Promise<any> => {
		try {
			return await this.httpService.put(this.usersUrl + '/' + currentUserId + this.agencyPortalSettingsUrl, userSettings);
		} catch (exception) {
			throw exception;
		}
	};
}

import { Directive, ElementRef, Output, EventEmitter, HostListener, Inject } from '@angular/core';

@Directive({
	selector: '[clickOutside]',
})
export class ClickOutsideDirective {
	@Output() public clickOutside: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	constructor(@Inject(ElementRef) private _elementRef: ElementRef) {}

	/**
	 * directive which determines if the user has clicked outside the target element
	 *
	 * @param event - the event containing the click location
	 * @param targetElement - the element we wish to determine if we have clicked outside of
	 */
	@HostListener('document:click', ['$event', '$event.target'])
	public onClick(event: MouseEvent, targetElement: HTMLElement): void {
		if (!targetElement) {
			return;
		}

		const clickedInside: boolean = this._elementRef.nativeElement.contains(targetElement);

		if (!clickedInside) {
			this.clickOutside.emit(event);
		}
	}
}

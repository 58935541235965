/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { CurrentUserPermissionService } from '../../../../support-features/login/services/current-user/current-user-permission.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';

import { AgencySelectorComponent } from '../../../../support-features/agencies/agency-selection/agency-selector/agency-selector.component';
import { EditAgencyAdminSettingsComponent } from '../view/settings/edit/edit-agency-admin-settings.component';
import { PermissionRequest, PermissionRequestActionType } from '../../../../support-features/login/types/types';

@Injectable({
	providedIn: 'root',
})
export class AgenciesAdminModalService {
	constructor(
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private editSettingsModal: MatDialog,
		private agencySelectModal: MatDialog
	) {}

	/**
	 * presents the edit agency details dialog and publishes the updated details thereafter or cancel dependant upon user interaction
	 * note that a permission check is executed prior to the dialog being presented
	 *
	 * @param authorityId - authority id
	 * @param agencyId - agency id
	 * @returns publishes the edit agency details or cancel
	 */
	public editSettings = (authorityId: string, agencyId: string): Observable<any> => {
		return new Observable((observer) => {
			const permissionRequest: PermissionRequest = {
				authorityId,
				agencyId,
			};

			if (this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.editAgency, permissionRequest)) {
				const modalRef: MatDialogRef<EditAgencyAdminSettingsComponent> = this.editSettingsModal.open(
					EditAgencyAdminSettingsComponent,
					{
						width: '800px',
						maxWidth: '1000px',
						height: 'calc(80vh + 113px)', // fixed height (rather than maxHeight) so loading spinner takes up full space
						position: {
							top: '60px',
						},
						data: {
							authorityId,
							agencyId,
						},
					}
				);

				return modalRef.afterClosed().subscribe(async (result) => {
					observer.next(result);
				});
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));

				return observer.next(null);
			}
		});
	};

	/**
	 * presents the select agency dialog
	 *
	 * @returns publishes the selected agency
	 */
	public selectAgency = (): Observable<any> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<AgencySelectorComponent> = this.agencySelectModal.open(AgencySelectorComponent, {
				width: '600px',
				data: {},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe(async (result) => {
				observer.next(result);
			});
		});
	};
}

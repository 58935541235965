<div class="nb-dashboard-row-container">
	<div class="nb-dashboard-row-container-inner">
		<div class="route-col">{{ 'T_DASH.DB_ROUTE' | translate }}</div>
		<div class="assigned-col">{{ 'T_DASH.DB_ASSIGNED' | translate }}</div>
		<div class="occupancy-col">{{ 'T_DASH.DB_ROUTE_OCCUPANCY' | translate }}</div>
	</div>
	<div *ngFor="let route of occupancyListData; let i = index; first as isFirst">
		<ng-container *ngIf="i < this.getListSize()">
			<div *ngIf="route.id" class="divrow" class="occupancy-data-container">
				<div class="route-pill-container" data-test="route.table.route_id">
					<a (click)="navigateToRouteDetails(agency.authority_id, route.id)">
						<route-pill [route]="determineRoutePillData(route)"></route-pill>
					</a>
				</div>
				<div *ngIf="hasResults" class="total" data-test="route.occupancy.total">
					{{ route.occupancy.total }}
				</div>
				<div class="occupancy-container" data-test="route.occupancy">
					<div class="no-data" *ngIf="!hasResults && isInitialized && isFirst">
						{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}
					</div>
					<div
						*ngIf="route.occupancy.unknown"
						(mouseenter)="openTooltip($event, route.occupancy, 'unknown')"
						(mouseleave)="closeTooltip()"
						[ngStyle]="getStyle(route.occupancy, 'unknown')"></div>
					<div
						*ngIf="route.occupancy.manySeatsAvailable"
						(mouseenter)="openTooltip($event, route.occupancy, 'manySeatsAvailable')"
						(mouseleave)="closeTooltip()"
						class="bg-acceptable"
						[ngStyle]="getStyle(route.occupancy, 'manySeatsAvailable')"></div>
					<div
						*ngIf="route.occupancy.fewSeatsAvailable"
						(mouseenter)="openTooltip($event, route.occupancy, 'fewSeatsAvailable')"
						(mouseleave)="closeTooltip()"
						class="bg-bad-caution"
						[ngStyle]="getStyle(route.occupancy, 'fewSeatsAvailable')"></div>
					<div
						*ngIf="route.occupancy.standingRoomOnly"
						(mouseenter)="openTooltip($event, route.occupancy, 'standingRoomOnly')"
						(mouseleave)="closeTooltip()"
						class="bg-bad"
						[ngStyle]="getStyle(route.occupancy, 'standingRoomOnly')"></div>
					<div
						*ngIf="route.occupancy.crushedStandingRoomOnly"
						(mouseenter)="openTooltip($event, route.occupancy, 'crushedStandingRoomOnly')"
						(mouseleave)="closeTooltip()"
						class="bg-reallybad"
						[ngStyle]="getStyle(route.occupancy, 'crushedStandingRoomOnly')"></div>
					<div
						*ngIf="route.occupancy.full"
						(mouseenter)="openTooltip($event, route.occupancy, 'full')"
						(mouseleave)="closeTooltip()"
						[ngStyle]="getStyle(route.occupancy, 'full')"></div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

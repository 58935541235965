<div class="nb-view">
	<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

	<panel-header [showBackButton]="true" [heading]="'T_REPORT.VIEWER.INTERACTIVE_REPORT_VIEWER' | translate"> </panel-header>

	<loading-overlay [isLoading]="!headerLoaded"></loading-overlay>

	<div class="clearfix">
		<div class="col-lg-12 col-md-12 col-sm1-12 col-xs-12 toolbar report-viewer-button-container">
			<button
				(click)="viewDetails()"
				[disabled]="!viewDetailsButtonEnabled"
				title="{{ viewDetailsButtonTooltip }}"
				class="btn btn-action pull-left report-viewer-view-button"
				data-test="report-viewer.details.btn">
				<i class="nb-icons nb-view-on"></i> {{ 'T_CORE.DETAILS' | translate }}
			</button>
			<button
				(click)="edit()"
				[disabled]="!editButtonEnabled"
				title="{{ editButtonTooltip }}"
				class="btn btn-action pull-left report-viewer-edit-button"
				data-test="report-viewer.edit.btn">
				<i class="nb-icons nb-edit"></i>{{ 'T_CORE.EDIT' | translate }}
			</button>
			<button
				(click)="delete()"
				[disabled]="!deleteButtonEnabled"
				title="{{ deleteButtonTooltip }}"
				class="btn btn-action pull-left report-viewer-delete-button">
				<i class="nb-icons nb-delete"></i>{{ 'T_CORE.DELETE' | translate }}
			</button>
		</div>
	</div>

	<div class="nb-report-viewer nb-padding-all-md">
		<div *ngIf="headerLoaded">
			<report-viewer-header
				[summaryContent]="summaryContent"
				[templateSummaryContent]="templateSummaryContent"
				[defaultTemplateId]="defaultTemplateId"
				[authorityId]="authorityId"
				[agencyId]="agencyId">
			</report-viewer-header>
			<report-viewer-summary
				[templateSummaryContent]="templateSummaryContent"
				[authorityId]="authorityId"
				[agencyId]="agencyId"
				[defaultTemplateId]="defaultTemplateId"
				[createdById]="createdById"
				[reportId]="reportId">
			</report-viewer-summary>
			<report-viewer-list
				[templateSummaryContent]="templateSummaryContent"
				[reportId]="reportId"
				[authorityId]="authorityId"
				[agencyId]="agencyId"
				[defaultTemplateId]="defaultTemplateId"
				[createdById]="createdById">
			</report-viewer-list>
		</div>
	</div>
</div>

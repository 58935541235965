import { CssClassType, CssMultiClassType } from '@cubicNx/libs/utils';

export enum BlockType {
	scheduled = 0,
	special = 1,
	unscheduled = 2,
}

export enum BlockStatus {
	assigned = 0,
	canceled = 1,
	unassigned = 2,
}

export interface BlocksListItem {
	blockId: string;
	dateTime: string;
	status: CssClassType;
	vehicles: CssMultiClassType;
}

export type BlocksList = Array<BlocksListItem>;

export interface TripListItem {
	id: string;
	routeId: string;
	direction: string;
	time: string;
	cancelled: boolean;
	endTimeEpoch: number;
	cancelledIcon: CssClassType;
	rowClass: string;
	checkDisabled: boolean;
}

export type TripList = Array<TripListItem>;

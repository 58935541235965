/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, HostListener, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { DepotsDataService } from '../../../support-features/depots/services/depots-data.service';
import { ValidatorService } from '@cubicNx/libs/utils';
import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { Depots } from '../../../support-features/depots/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { RoutesDataService } from '../../../support-features/routes/services/routes-data.service';
import { VehicleSummaries } from '../../../support-features/vehicles/types/api-types';
import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { Routes } from '../../../support-features/routes/types/api-types';
import { StringHelpers } from '@cubicNx/libs/utils';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { Agencies } from '../../../support-features/agencies/types/api-types';
import { VehicleEventsDataService } from '../services/vehicle-events-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import {
	CategoryFilterOptions,
	EventDisplayType,
	EventDisplayTypes,
	VehicleEventsSettings,
	VehicleEventType,
	VehicleEventTypes,
} from '../types/types';

@Component({
	selector: 'vehicle-event-settings',
	templateUrl: './vehicle-event-settings.component.html',
	styleUrls: ['./vehicle-event-settings.component.scss'],
})
export class VehicleEventSettingsComponent extends TranslateBaseComponent implements OnInit {
	@Input() settings: VehicleEventsSettings;
	@Input() agencies: Agencies;
	@Input() formGroup: FormGroup;

	@Output() agencyChanged: EventEmitter<void> = new EventEmitter<void>();
	@Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

	public initialized: boolean = false;

	public vehicles: VehicleSummaries = [];
	public depots: Depots = [];
	public routes: Routes = [];

	public selectedDepots: Depots = [];
	public selectedRoutes: Routes = [];
	public selectedVehicles: VehicleSummaries = [];

	public routeSettings: MultiSelectSettings = {
		id_text: 'route_id',
		value_text: 'route_short_name',
		placeholder: 'Please select a route',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public vehicleSettings: MultiSelectSettings = {
		id_text: 'vehicle_id',
		value_text: 'vehicle_id',
		placeholder: 'Please select a vehicle',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public depotSettings: MultiSelectSettings = {
		id_text: 'name',
		value_text: 'name',
		placeholder: 'Please select a depot',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	private availableTypes: EventDisplayTypes = [];

	private ctrlKeyPressed: boolean;

	constructor(
		private routesDataService: RoutesDataService,
		private depotsDataService: DepotsDataService,
		private vehiclesDataService: VehiclesDataService,
		private vehicleEventsDataService: VehicleEventsDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * remembers if the ctrl key has been pressed on the key down event
	 *
	 * @param event - the keydown event
	 */
	@HostListener('document:keydown', ['$event'])
	handleKeydownEvent(event: KeyboardEvent): void {
		if (event.key === 'Control') {
			this.ctrlKeyPressed = true;
		}
	}

	/**
	 * remembers if the ctrl key has been unpressed on the key up event
	 *
	 * @param event - the keydown event
	 */
	@HostListener('document:keyup', ['$event'])
	handleKeyupEvent(event: KeyboardEvent): void {
		if (event.key === 'Control') {
			this.ctrlKeyPressed = false;
		}
	}

	/**
	 * performs initialization tasks for the vehicle event settings component
	 *
	 * updates the form
	 */
	public async ngOnInit(): Promise<void> {
		this.addFormControls();

		if (this.settings.selectedAgency) {
			await this.loadAgencyData();
		}

		this.initialized = true;
	}

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public handleAgencyChanged = (): void => {
		// emit to the parent to handle
		this.agencyChanged.emit();
	};

	/**
	 * remebers the vehicle event categories
	 *
	 * @param category - the category
	 */
	public toggleCategoryButton = (category: string): void => {
		const control: AbstractControl<any, any> = this.formGroup.get(category);

		control?.setValue(!control?.value);
		this.settings.categoryFilterOptions[category as keyof CategoryFilterOptions] =
			!this.settings.categoryFilterOptions[category as keyof CategoryFilterOptions];
	};

	/**
	 * remembers the vehicle event sort by order
	 *
	 * @param sortBy - the sort by order
	 */
	public setSortOrder = (sortBy: string): void => {
		this.formGroup.get('sortBy').setValue(sortBy);
		this.settings.sortBy = sortBy;
	};

	/**
	 * determines whether at least one event type is included in the included events data
	 *
	 * @param eventType - the event type
	 * @returns true if at least one event type is present in the included events data
	 */
	public isPicked = (eventType: any): void => {
		return eventType.selected || (eventType.isGroupHeader && this.atLeastOneItemFromGroupIsInList(eventType.group, true));
	};

	/**
	 * determines whether at least one event type is not included in the included events data
	 *
	 * @param eventType - the event type
	 * @returns true if no event type are present in the included events data
	 */
	public isNotPicked = (eventType: any): boolean => {
		return (
			(!eventType.isGroupHeader && !eventType.selected) ||
			(eventType.isGroupHeader && this.atLeastOneItemFromGroupIsInList(eventType.group, false))
		);
	};

	/**
	 * retrieves the available event types
	 *
	 * @returns available vehicle event types
	 */
	public getAvailableTypes = (): EventDisplayTypes => {
		return this.availableTypes?.filter((item) => this.isNotPicked(item))?.sort((a, b) => this.compareByGroupAndName(a, b)) ?? [];
	};

	/**
	 * retrieves the picked vehicle event types
	 *
	 * @returns the picked vehicle event types
	 */
	public getPickedTypes = (): EventDisplayTypes => {
		return this.availableTypes?.filter((item) => this.isPicked(item))?.sort((a, b) => this.compareByGroupAndName(a, b)) ?? [];
	};

	/**
	 * sets the supplied vehicle event type as being active
	 *
	 * @param eventType - the vehicle event type
	 * @param isPickedList - is in the picked list
	 */
	public setActive = (eventType: EventDisplayType, isPickedList: boolean): void => {
		if (eventType.isGroupHeader) {
			// A group header was clicked.
			let numSel: number = 0;
			let numNot: number = 0;

			if (this.ctrlKeyPressed) {
				this.availableTypes.forEach((item: EventDisplayType) => {
					if (item.group === eventType.group && item.selected === isPickedList) {
						if (item.active) {
							numSel++;
						} else {
							numNot++;
						}
					}
				});
			}

			const selectAll: boolean = numNot > numSel;

			this.availableTypes.forEach((item: EventDisplayType) => {
				if (this.ctrlKeyPressed) {
					if (item.group === eventType.group && item.selected === isPickedList) {
						item.active = selectAll;
					}
				} else {
					item.active = item.selected === isPickedList && item.group === eventType.group;
				}
			});
		} else {
			// An individual event type was clicked.
			this.availableTypes.forEach((item) => {
				if (this.ctrlKeyPressed) {
					if (item.id === eventType.id) {
						item.active = !item.active;
					}
				} else {
					item.active = item.id === eventType.id;
				}
			});
		}
	};

	/**
	 * adds a picked type
	 */
	public addPickedType = (): void => {
		const activeItems: EventDisplayType[] = this.availableTypes.filter((item) => item.active);

		activeItems.forEach((item) => {
			if (!item.isGroupHeader) {
				item.selected = true;
				if (!this.settings.includedEventTypeIds.includes(item.id)) {
					this.settings.includedEventTypeIds.push(item.id);
					this.formGroup.get('includedEventTypeIds').setValue(this.settings.includedEventTypeIds);
				}
			}
		});
	};

	/**
	 * removes a picked type
	 */
	public removePickedType = (): void => {
		const activeItems: EventDisplayType[] = this.availableTypes.filter((item) => item.active);

		activeItems.forEach((item) => {
			if (!item.isGroupHeader) {
				item.selected = false;
			}
		});

		const activeIds: number[] = activeItems.map((item) => item.id);

		this.settings.includedEventTypeIds = this.settings.includedEventTypeIds.filter((etId) => !activeIds.includes(etId));
		this.formGroup.get('includedEventTypeIds').setValue(this.settings.includedEventTypeIds);
	};

	/**
	 * Updates the form and widget data with the selectedRoutes.
	 *
	 * @param item - the item to update.
	 */
	public updateRouteItemClicked = (): void => {
		this.formGroup.get('routes').setValue(this.selectedRoutes);
		this.settings.selectedRoutes.routes = this.selectedRoutes;
		this.formGroup.updateValueAndValidity();
	};

	/**
	 * Updates the form and widget data with the selectedVehicles.
	 *
	 * @param item - the item to update.
	 */
	public updateVehicleItemClicked = (): void => {
		this.formGroup.get('vehicles').setValue(this.selectedVehicles);
		this.settings.selectedVehicles.vehicles = this.selectedVehicles;
		this.formGroup.updateValueAndValidity();
	};

	/**
	 * Updates the form and widget data with the selectedDepots.
	 *
	 * @param item - the item to update.
	 */
	public updateDepotItemClicked = (): void => {
		this.formGroup.get('depots').setValue(this.selectedDepots);
		this.settings.selectedDepots.depots = this.selectedDepots;
		this.formGroup.updateValueAndValidity();
	};

	/**
	 * Updates the vehicle selection filter value in the widget data and form.
	 *
	 * @param filter - the filter value to update to.
	 */
	public updateVehicleSelectionFilter = (filter: string): void => {
		this.formGroup.get('vehicleFilterSelection').setValue(filter);
		this.settings.vehicleFilterSelection.value = filter;
	};

	/**
	 * Clears the agency data from widget and form.
	 */
	public clearAgencyData = (): void => {
		this.settings.selectedVehicles = { vehicles: [] };
		this.settings.selectedDepots = { depots: [] };
		this.settings.selectedRoutes = { routes: [] };
		this.settings.vehicleFilterSelection = { value: 'all' };
		this.selectedDepots = [];
		this.selectedRoutes = [];
		this.selectedVehicles = [];
		this.formGroup.get('vehicleFilterSelection').setValue('all');
		this.formGroup.get('vehicles').setValue([]);
		this.formGroup.get('depots').setValue([]);
		this.formGroup.get('routes').setValue([]);
	};

	/**
	 * Loads the selected agency data.
	 */
	public loadAgencyData = async (): Promise<void> => {
		this.loaded.emit(false);
		await this.getVehicleEventTypes(this.settings.includedEventTypeIds);
		await this.loadVehicles();
		await this.loadDepots();
		await this.loadRoutes();
		this.loaded.emit(true);
	};

	/**
	 * Sets the form values based on settings
	 */
	public setFormValues = (): void => {
		this.setRouteData();
		this.setVehicleData();
		this.setDepotData();

		this.formGroup.get('agencyId').setValue(this.settings.selectedAgency?.agency_nb_id);
		this.formGroup.get('agencyLoaded').setValue(this.settings.selectedAgency?.agency_nb_id ? true : null);
		this.formGroup.get('alarm').setValue(this.settings.categoryFilterOptions?.alarm ?? false);
		this.formGroup.get('alert').setValue(this.settings.categoryFilterOptions?.alert ?? false);
		this.formGroup.get('event').setValue(this.settings.categoryFilterOptions?.event ?? false);
		this.formGroup.get('sortBy').setValue(this.settings.sortBy);
		this.formGroup.get('includedEventTypeIds').setValue(this.settings.includedEventTypeIds);
		this.formGroup.get('vehicleFilterSelection').setValue(this.settings.vehicleFilterSelection?.value);
		this.formGroup.get('routes').setValue(this.settings.selectedRoutes?.routes ?? []);
		this.formGroup.get('vehicles').setValue(this.settings.selectedVehicles?.vehicles ?? []);
		this.formGroup.get('depots').setValue(this.settings.selectedDepots?.depots ?? []);
	};

	/**
	 * Add extra form controls.
	 */
	public addFormControls = (): void => {
		this.formGroup.addControl('agencyId', new FormControl('', [Validators.required]));
		this.formGroup.addControl('agencyLoaded', new FormControl('', [Validators.required]));
		this.formGroup.addControl('alarm', new FormControl('', [Validators.required]));
		this.formGroup.addControl('alert', new FormControl('', [Validators.required]));
		this.formGroup.addControl('event', new FormControl('', [Validators.required]));
		this.formGroup.addControl('sortBy', new FormControl('', [Validators.required]));
		this.formGroup.addControl('includedEventTypeIds', new FormControl('', [Validators.required]));
		this.formGroup.addControl('vehicleFilterSelection', new FormControl('', [Validators.required]));
		this.formGroup.addControl('routes', new FormControl(''));
		this.formGroup.addControl('vehicles', new FormControl(''));
		this.formGroup.addControl('depots', new FormControl(''));

		const formOptions: any = {
			validators: [
				ValidatorService.dependantRequiredIfPrimaryHasValue('vehicleFilterSelection', 'routes', 'routes'),
				ValidatorService.dependantRequiredIfPrimaryHasValue('vehicleFilterSelection', 'vehicles', 'vehicles'),
				ValidatorService.dependantRequiredIfPrimaryHasValue('vehicleFilterSelection', 'depots', 'depots'),
			],
		};

		this.formGroup.setValidators(formOptions.validators);

		this.setFormValues();
	};

	/**
	 * compares event display types by group and name
	 *
	 * @param et1 - event display type 1
	 * @param et2 - event display type 2
	 * @returns comparison result
	 */
	private compareByGroupAndName = (et1: EventDisplayType, et2: EventDisplayType): number => {
		if (et1.groupDisplay < et2.groupDisplay) {
			return -1;
		} else if (et1.groupDisplay > et2.groupDisplay) {
			return 1;
		} else if (et1.isGroupHeader && !et2.isGroupHeader) {
			return -1;
		} else if (!et1.isGroupHeader && et2.isGroupHeader) {
			return 1;
		} else if (et1.type < et2.type) {
			return -1;
		} else if (et1.type > et2.type) {
			return 1;
		} else {
			return 0;
		}
	};

	/**
	 * Gets the agency veichle event types.
	 *
	 * @param includedEventTypeIds - event type ids to set as included in the widget data.
	 * @returns null if there are no event types.
	 */
	private getVehicleEventTypes = async (includedEventTypeIds: any): Promise<EventDisplayType> => {
		const response: ResultContent = await this.vehicleEventsDataService.getVehicleEventTypes(
			this.settings.selectedAgency?.authority_id
		);

		if (response.success) {
			const eventTypes: VehicleEventTypes = response.resultData;

			if (!eventTypes) {
				return null;
			}

			// Create equivalent list of vehcile event types for display purposes
			const typesForDisplay: EventDisplayTypes = eventTypes.map((eventType: VehicleEventType) => {
				const forDisplayType: EventDisplayType = {
					id: eventType.id,
					type: this.getTranslation('T_DASH.EVENTS.TITLES.' + StringHelpers.toSnakeCase(eventType.type).toUpperCase()),
					selected: includedEventTypeIds.includes(eventType.id),
					active: false,
					group: eventType.group,
					groupDisplay: this.getTranslation('T_DASH.EVENTS.GROUPS.' + StringHelpers.toSnakeCase(eventType.group).toUpperCase()),
					isGroupHeader: false,
				};

				return forDisplayType;
			});

			const groups: any = {};

			typesForDisplay.forEach((type: EventDisplayType) => (groups[type.group] = true));

			// Add entries in the display list for the group headers
			for (const groupName in groups) {
				const groupTranslated: string = this.getTranslation(
					'T_DASH.EVENTS.GROUPS.' + StringHelpers.toSnakeCase(groupName).toUpperCase()
				);

				typesForDisplay.push({
					type: groupTranslated,
					selected: false,
					active: false,
					group: groupName,
					groupDisplay: groupTranslated,
					isGroupHeader: true,
				});
			}

			this.availableTypes = typesForDisplay;
			this.settings.includedEventTypeIds = this.availableTypes.filter((et) => et.selected).map((et) => et.id);
		}

		return null;
	};

	/**
	 * determines whether there is at least one item from the group in the list
	 *
	 * @param group - the group
	 * @param isPickedList - the picked list
	 * @returns true if there is at least one item in the list
	 */
	private atLeastOneItemFromGroupIsInList = (group: any, isPickedList: any): boolean => {
		return this.availableTypes.some(
			(eventType) => eventType.selected === isPickedList && !eventType.isGroupHeader && eventType.group === group
		);
	};

	/**
	 * Gets the vehicles for the selectedAgency.
	 */
	private loadVehicles = async (): Promise<void> => {
		const vehiclesResponse: ResultContent = await this.vehiclesDataService.getVehicles(this.settings.selectedAgency?.authority_id);

		if (vehiclesResponse.success) {
			this.vehicles = vehiclesResponse.resultData as VehicleSummaries;
		}
	};

	/**
	 * Gets the depots for the selectedAgency.
	 */
	private loadDepots = async (): Promise<void> => {
		this.depots = [];

		const result: ResultContent = await this.depotsDataService.getDepots(this.settings.selectedAgency?.authority_id, '1');

		if (result.success) {
			this.depots = result.resultData;
		}
	};

	/**
	 * Gets the routes for the selectedAgency.
	 */
	private loadRoutes = async (): Promise<void> => {
		if (this.settings.selectedAgency) {
			const result: ResultContent = await this.routesDataService.getRoutes(
				this.settings.selectedAgency.authority_id,
				this.settings.selectedAgency.agency_id
			);

			if (result.success) {
				this.routes = result.resultData;
			}
		}
	};

	/**
	 * Sets the selectedRoutes data from the widget data.
	 */
	private setRouteData = (): void => {
		this.selectedRoutes = this.settings.selectedRoutes?.routes ?? [];
	};

	/**
	 * Sets the selectedVehicles data from the widget data.
	 */
	private setVehicleData = (): void => {
		this.selectedVehicles = this.settings.selectedVehicles?.vehicles ?? [];
	};

	/**
	 * Sets the selectedDepots data from the widget data.
	 */
	private setDepotData = (): void => {
		this.selectedDepots = this.settings.selectedDepots?.depots ?? [];
	};
}

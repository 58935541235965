/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { DashboardConfigService } from '../services/dashboard-config.service';

import { WidgetDirective } from './widget.directive';

import { IWidget, IWidgetComponent } from '../types/types';

@Component({
	selector: 'widget-container',
	templateUrl: './widget-container.component.html',
	styleUrls: ['./widget-container.component.scss'],
})
export class WidgetContainerComponent implements OnInit {
	@Input() widget: any;
	@Input() rowData: any;

	@Output() widgetConfigUpdated: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild(WidgetDirective, { static: true }) widgetHost!: WidgetDirective;

	constructor(
		private resolver: ComponentFactoryResolver,
		private dashboardConfig: DashboardConfigService
	) {}

	/**
	 * performs initialization tasks for the widget container - loads the widget
	 */
	public ngOnInit(): void {
		this.loadComponent();
	}

	/**
	 * Loads the dynamic component.
	 * The type of dynamic component to load is determined from the widget input data type's config component property.
	 */
	private loadComponent = (): void => {
		const viewContainerRef: ViewContainerRef = this.widgetHost.viewContainerRef;

		viewContainerRef.clear();

		const widgetConfig: IWidget = this.dashboardConfig.widgets.find((i) => i.name === this.widget.type);

		this.handleUpdatedConfig(widgetConfig);

		const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(widgetConfig.component);
		const componentRef: any = viewContainerRef.createComponent<IWidgetComponent>(factory);

		componentRef.instance.data = this.widget;
		componentRef.instance.rowData = this.rowData;
	};

	/**
	 * Handles edge case where new config/controls/options have been added to the widget but a
	 * previous a saved version of the widget exists that was created before the new property was added
	 * If there is new property, update the old widget saved config so it has a value
	 *
	 * @param widgetConfig - the widget config.
	 */
	private handleUpdatedConfig = (widgetConfig: IWidget): void => {
		const previousWidgetSavedConfig: any = ObjectHelpers.deepCopy(this.widget.config);

		// take a copy as otherwise the original config can be changed (due to inner objects being copied
		// in merge deep which share same references). Without this new widgets added were sharing settings
		// with other widgets of the same type
		const widgetConfigCopy: any = ObjectHelpers.deepCopy(widgetConfig.config);

		// add any properties that exist in the config that don't exist in the saved config
		const updatedWidgetConfig: any = ObjectHelpers.mergeDeep(widgetConfigCopy, this.widget.config);

		this.widget.config = updatedWidgetConfig;

		// determine if something has changed and trigger update to back end if so
		if (!ObjectHelpers.deepEquals(previousWidgetSavedConfig, updatedWidgetConfig)) {
			this.widgetConfigUpdated.emit(this.widget.config);
		}
	};
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { InitService } from '../../support-features/login/services/init/init.service';
import { InitEventsService } from '../../support-features/login/services/init/init-events.service';
import { MapEventsService } from '../../features/map/services/map-events.service';
import { MapOptionsService } from '../../features/map/services/map-options.service';
import { LoggerService } from '@cubicNx/libs/utils';

import { MapUpdateEvent, MapUpdateType } from '../../features/map/types/types';

@Component({
	selector: 'main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
	@ViewChild('fullscreen') private fullScreenRef: ElementRef;

	public initialized: boolean = false;
	public fullScreen: boolean = false;

	private mapUpdate$Subscription: Subscription = null;
	private initComplete$Subscription: Subscription = null;

	constructor(
		private initService: InitService,
		private initEventsService: InitEventsService,
		private mapEventsService: MapEventsService,
		private mapOptionsService: MapOptionsService,
		private loggerService: LoggerService
	) {}

	/**
	 * performs initialization tasks for the main component
	 *
	 * sets yp subscriptions
	 */
	public ngOnInit(): void {
		this.setSubscriptions();

		this.initialized = this.initService.isInitComplete();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * sets up subscriptions
	 *
	 * is interested in application initialization being completed
	 * also interested in map updates
	 */
	private setSubscriptions = async (): Promise<void> => {
		this.initComplete$Subscription = this.initEventsService.initComplete.subscribe(() => {
			this.initialized = true;
		});

		this.mapUpdate$Subscription = this.mapEventsService.mapUpdate.subscribe((event) => {
			this.handleMapUpdate(event);
		});
	};

	/**
	 * deals with map updates, particularly if the full screen is required
	 *
	 * @param updateEvent - the map update details
	 */
	private handleMapUpdate = (updateEvent: MapUpdateEvent): void => {
		// include try catch - shouldn't be needed but if an exception otherwise occurs it breaks the subscription and nothing works until
		// the user refreshes
		try {
			switch (updateEvent.mapUpdateType) {
				case MapUpdateType.fullScreenChange:
					this.handleFullScreenRefresh();
					break;
			}
		} catch (exception) {
			this.loggerService.logError('Failed to process map update', exception);
		}
	};

	/**
	 * handles a full screen refresh
	 */
	private handleFullScreenRefresh = (): void => {
		this.setFullScreen(this.mapOptionsService.getFullScreen());
	};

	/**
	 * sets full screen or not depending on supplied full screen value
	 *
	 * @param fullscreen - the full screen required or not
	 */
	private setFullScreen = (fullscreen: boolean): void => {
		if (fullscreen) {
			const fullScreenRef: any = this.fullScreenRef.nativeElement;

			if (fullScreenRef.requestFullscreen) {
				fullScreenRef.requestFullscreen();
			} else if (fullScreenRef.msRequestFullscreen) {
				fullScreenRef.msRequestFullscreen();
			} else if (fullScreenRef.mozRequestFullScreen) {
				fullScreenRef.mozRequestFullScreen();
			} else if (fullScreenRef.webkitRequestFullscreen) {
				fullScreenRef.webkitRequestFullscreen();
			}
		} else {
			const document: any = window.document;

			if (document.fullscreenElement) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				}
			}
		}
	};

	/**
	 * unsubscribes from subscriptions
	 */
	private unsubscribe = (): void => {
		this.initComplete$Subscription?.unsubscribe();
		this.mapUpdate$Subscription?.unsubscribe();
	};
}

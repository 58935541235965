import { VehicleAdherenceDisplay, VehicleColor } from '../../../support-features/vehicles/types/types';

import { EntityType, PageType } from '../../../utils/components/breadcrumbs/types/types';

import { Adherence, Headway, Passengers, VehiclePosition } from '../../../support-features/vehicles/types/api-types';

import { TimeFormatType } from '@cubicNx/libs/utils';

import { Moment } from 'moment';

import L from 'leaflet';

export enum MapModeType {
	live,
	replay,
}

export enum ModeType {
	map,
	ladder,
}

export enum TooltipEntityType {
	stop,
	stopCluster,
	vehicle,
}

export enum MarkerType {
	vehicle,
	vehicleLabel,
	vehicleTrail,
	stop,
	stopLabel,
	stopCusterLabel,
}

export enum VehicleHeadwayType {
	unknown,
	veryClose,
	close,
	acceptable,
	veryDistant,
	distant,
}

export enum DisplayPriorityType {
	vehicles,
	stops,
}

export enum ColorVehicleByType {
	route,
	adherence,
	headway,
}

export enum BaseMapType {
	streets,
	dark,
	light,
	traffic,
	satellite,
	rasterStreets,
	rasterDark,
	rasterLight,
	rasterTraffic,
	rasterSatellite,
}

export enum SettingsDisplayType {
	settings,
	moreSettings,
}

export enum MapUpdateType {
	modeRefresh,
	fullScreenChange,
	trailedVehicleUpdate,
	locationUpdate,
	routesToLocateUpdate,
	activeEntityUpdate,
	initViewRoutes,
	refreshDisplayPriority,
	addRoute,
	removeRoute,
	clearRoutes,
	initViewStops,
	addStop,
	addStops,
	removeStop,
	removeStops,
	clearStops,
	reloadStops,
	addVehicles,
	addVehicle,
	updateVehicles,
	removeVehicle,
	removeVehicles,
	reloadVehicles,
	clearVehicles,
	refreshVehicleLabels,
	vehicleLabelClusterRadiusUpdate,
}

export enum BaseMapLayerType {
	vector,
	raster,
}

export interface MapUpdateEvent {
	mapUpdateType: MapUpdateType;
	params: any;
}

export interface HeadwayFormatObj {
	textClass: string;
	time: string;
	class: string;
}

export interface MapDepotSelection {
	name: string;
	tag: string;
}

export type MapDepotSelections = Array<MapDepotSelection>;

export const allDepotTag: string = 'All';
export const unknownDepotTag: string = 'Unknown';

export const allDepot: any = {
	tag: allDepotTag,
	name: null,
};

export interface MapStops {
	[key: string]: MapStop;
}

export interface MapStop {
	stopLat: number;
	stopLon: number;
	stopName: string;
	stopId: string;
	stopCode: string;
	hiddenRaw: boolean; // the raw hidden value from the back end
	hidden: boolean; // used to determine if we should hide the stop (i.e false if hiddenRaw or we are showing hidden stops)
}

export interface MapVehicles {
	[key: string]: MapVehicle;
}

export interface MapVehicle {
	vehicleId: string;
	authorityId: string;
	agencyId: string;
	blockId: string;
	routeId: string;
	routeName: string;
	predictability: string;
	adherence: Adherence;
	headway: Headway;
	lat: number;
	lon: number;
	vehicleMoving: boolean;
	tripId: string;
	date: number;
	runid: string;
	direction: string;
	passengers: Passengers;
	vehState: string;
	vehicleIds: string[];
	vehicleColor: VehicleColor;
	routeColor: VehicleColor;
	headwayColor: VehicleColor;
	adherenceColor: VehicleColor;
	angle: number;
	state: string;
	adherenceDisplay: VehicleAdherenceDisplay;
	headwayDisplay: string;
	lastDepot: string;
	vehiclePosition: VehiclePosition;
	vehicleActive: boolean;
	applyFilter: boolean;
	hidden: boolean;
}

export interface MapRoutes {
	[key: string]: MapRoute;
}

export interface MapRoute {
	routeId: string;
	authorityId: string;
	agencyId: string;
	routeColor: number;
	routeTextColor: number;
	routeShortName: string;
	routeLongName: string;
	paths: MapRoutesPaths;
	stops: RouteAssociatedStops;
	vehicles: RouteAssociatedVehicles;
}

export interface MapRoutesPaths {
	[key: string]: MapRoutesPathItems;
}

export type MapRoutesPathItems = Array<MapRoutesPathItem>;

export interface MapRoutesPathItem {
	lat: number;
	lng: number;
}

export type RouteAssociatedStops = Array<RouteAssociatedStop>;

export interface RouteAssociatedStop {
	stopCode: string;
	stopId: string;
}

export type RouteAssociatedVehicles = Array<RouteAssociatedVehicle>;

export interface RouteAssociatedVehicle {
	vehicleId: string;
}

export interface MapState {
	navViewOpen: boolean;
	navHistory: NavigationPages;
	mode: ModeType;
	mapMode: MapModeType;
	replay: MapReplayState;
	liveUpdatesPaused: boolean;
	fullScreen: boolean;
	location: MapLocation;
	routesToLocate: Array<string>;
	activeEntity: string;
	trackedVehicleId: string;
	trailedVehicle: TrailedVehicle;
	routes: MapRoutes;
	stops: MapStops;
	vehicles: MapVehicles;
	settings: MapSettings;
	authorityId: string;
	firstRefresh: boolean;
}

export interface MapReplayState {
	showReplayControl: boolean;
	currentReplayTime: number;
	currentReplayId: string;
}

export interface NavigationPage {
	entityType: EntityType;
	pageType: PageType;
	entity: any; // type depends on whether entity type is route/vehicle/stop/block
}

export type NavigationPages = Array<NavigationPage>;

export interface NavigateRouteDetail {
	routeId: string;
}

export interface NavigateStopDetail {
	stopCode: string;
}

export interface NavigateBlockDetail {
	blockId: string;
}

export enum VehicleDetailsActiveTab {
	summary,
	events,
}

export interface NavigateVehicleDetail {
	vehicleId: string;
	activeTab: VehicleDetailsActiveTab;
	replaceHistoryItem: boolean;
}

export interface NavigateVehicleReassignDetail {
	vehicleId: string;
	vehicleRouteNbId: string;
	vehicleBlockId: string;
}

export interface VehicleReassignBlocksDetail {
	vehicleId: string;
	vehicleRouteNbId: string;
	vehicleBlockId: string;
	reassignRouteId: string;
	reassignRouteNbId: string;
}

export interface ListShowingValues {
	showingFirst: number;
	showingTo: number;
}

export interface MapSettings {
	baseMap: BaseMapType;
	showVehicleLabels: boolean;
	showVehicleLabelId: boolean;
	showVehicleLabelRoute: boolean;
	showVehicleLabelDepartureAdherence: boolean;
	showVehicleLabelHeadway: boolean;
	showVehicleLabelStatus: boolean;
	showVehicleLabelBlock: boolean;
	showVehicleLabelPassengers: boolean;
	displayPriority: DisplayPriorityType;
	colorVehicleBy: ColorVehicleByType;
	timeFormat: TimeFormatType;
	showVehiclesWithAdherenceIssues: boolean;
	showVehiclesWithHeadwayIssues: boolean;
	showVehiclesAtDepot: boolean;
	showHiddenStops: boolean;
	showLadderStopLabels: boolean;
	showLadderStopLabelCode: boolean;
	showLadderStopLabelName: boolean;
	selectedDepotFilter: MapDepotSelection;
	vehicleLabelClusterRadius: number;
}

export interface TrailedVehiclePoint {
	lat: number;
	lng: number;
	angle: number;
	timeStamp: Moment;
	updatedColor: string;
	opacity: number;
}

export type TrailedVehiclePoints = Array<TrailedVehiclePoint>;

export interface TrailedVehicle {
	vehicleId: string;
	vehicleColor: VehicleColor;
	trailPoints: TrailedVehiclePoints;
}

export interface MapLocation {
	lat: number;
	lon: number;
	zoom: number;
	offsetAdjust: boolean; // allows for offset when the center needs to 'offset' due to the left hand menu
	clearTrackedVehicle: boolean; // indicates that we want to clear the tracked vehicle (which typically we do when we navigate elsewhere)
}

export interface MapDefaults {
	maxZoom: number;
	minZoom: number;
	scrollWheelZoom: boolean;
	attributionControl: boolean;
	zoomControl: boolean;
	preferCanvas: boolean;
}

export interface AgencyBoundary {
	maxlat: number;
	maxlon: number;
	minlat: number;
	minlon: number;
}

export type AgencyBoundaries = Array<AgencyBoundary>;

export interface AgencyCircle {
	agencyId: number;
	weight: number;
	latlngs: AgencyCircleLatLon;
	radius: number;
	type: string;
}

export interface AgencyCircleLatLon {
	lat: number;
	lon: number;
}

export interface BaseMapLayerConfigLayerOptions {
	accessToken: string;
	style?: string;
	zoomOffset?: number;
	tileSize?: number;
}

export interface BaseMapLayerConfig {
	name: string;
	format: BaseMapLayerType;
	url?: string;
	layerOptions: BaseMapLayerConfigLayerOptions;
}

export interface BaseMapLayersConfig {
	[key: string]: BaseMapLayerConfig;
}

export interface Markers {
	[key: string]: MarkerExtended;
}

export interface Polylines {
	[key: string]: L.Polyline;
}

export interface MarkerExtended extends L.Marker {
	withinTooltip?: boolean;
	stopCode?: string;
	vehicleId?: string;
	routeIds?: string[];
}

export interface HTMLElementExtended extends HTMLElement {
	stopCode?: string;
	routeId?: string;
	vehicleId?: string;
	blockId?: string;
	vehicleRouteId?: string;
	stopRouteId?: string;
}

export interface EventExtended extends Event {
	currentTarget: EventTargetExtended;
}

export interface EventTargetExtended extends EventTarget {
	stopCode?: string;
	routeId?: string;
	vehicleId?: string;
	blockId?: string;
	vehicleRouteId?: string;
	stopRouteId?: string;
}

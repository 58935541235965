import { Pipe, PipeTransform } from '@angular/core';

import { TimeHelpers } from '../../helpers/time-helpers';

/**

 */
@Pipe({
	name: 'secondsToHoursMinutes',
})
export class SecondsToHoursMinutesPipe implements PipeTransform {
	/**
	 * This pipe converts the supplied time in seconds into number of hours minutes and seconds.
	 * Inlcude the sign and no leading zeroes
	 *
	 * @param timeInSeconds - the atime in seconds
	 * @returns the formatted time difference
	 */
	transform(timeInSeconds: number): string {
		return TimeHelpers.formatTimeDifference(timeInSeconds);
	}
}

@Pipe({
	name: 'headwaySecondsToHoursMinutes',
})
export class HeadwaySecondsToHoursMinutesPipe implements PipeTransform {
	/**
	 * This pipe converts the supplied time in seconds into number of hours minutes and seconds.
	 * Inlcude the sign and no leading zeroes (specific to the headway value)
	 *
	 * @param timeInSeconds - the atime in seconds
	 * @returns the formatted time difference
	 */
	transform(timeInSeconds: string): string {
		let input: number = parseInt(timeInSeconds, 10);

		if (!input) {
			return timeInSeconds;
		}

		const leadingZeros: (n: number) => string = (n: number): string => (n < 10 ? '0' : '') + n;

		const isNegative: string = input < 0 ? '-' : ' ';

		input = Math.abs(input);

		const seconds: number = input % 60;
		const minutes: number = Math.floor((input % 3600) / 60);
		const hours: number = Math.floor(input / 3600);

		let hoursStr: string = null;
		let minutesStr: string = null;

		if (hours > 0) {
			hoursStr = leadingZeros(hours);
		} else {
			hoursStr = '00';
		}

		hoursStr += ':';

		if (minutes > 0) {
			minutesStr = leadingZeros(minutes);
		} else {
			minutesStr = '00';
		}

		minutesStr += ':';

		const secondsStr: string = leadingZeros(seconds);

		return isNegative + hoursStr + minutesStr + secondsStr;
	}
}

<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ modalTitle }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content #modalContent>
	<div class="nb-search-grid-inner">
		<div *ngIf="loading">
			<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
		</div>
		<div>
			<form *ngIf="reportForm" [formGroup]="reportForm">
				<div class="row nb-padding-top-md">
					<div class="form-group col-xs-12 col-sm-8 col-md-8 col-lg-8">
						<field-label [name]="'name'" [label]="'T_CORE.NAME' | translate" [required]="true"></field-label>

						<input
							formControlName="name"
							type="text"
							class="form-control"
							id="name"
							data-test="report-create.name"
							title="{{ 'T_CORE.NAME' | translate }}"
							this.reportForm.controls.selectedBlocks.value
							placeholder="{{ 'T_CORE.NAME' | translate }}" />
						<div class="character-count">
							{{ maxFieldLength - reportForm.controls.name.value.length }} {{ 'T_REPORT.CHAR_REMAINING' | translate }}
						</div>

						<input-validation
							[control]="reportForm.controls.name"
							[errorOnTouch]="true"
							[messageTextOverride]="nameValidationTextOverride"
							[extraMessageText]="maxLengthAdditionalMessageText">
						</input-validation>
					</div>
					<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<field-label [name]="'code'" [label]="'T_REPORT.CODE' | translate" [required]="true"></field-label>
						<input
							formControlName="code"
							type="text"
							class="form-control"
							id="code"
							data-test="report-create.code"
							title="{{ 'T_REPORT.CODE' | translate }}"
							placeholder="{{ 'T_REPORT.CODE' | translate }}" />
						<div class="character-count">
							{{ maxFieldLength - reportForm.controls.code.value.length }} {{ 'T_REPORT.CHAR_REMAINING' | translate }}
						</div>
						<input-validation
							[control]="reportForm.controls.code"
							[errorOnTouch]="true"
							[extraMessageText]="maxLengthAdditionalMessageText">
						</input-validation>
					</div>
					<div class="form-group col-xs-12 col-sm-8 col-md-8 col-lg-8">
						<field-label [name]="'description'" [label]="'T_REPORT.DESCRIPTION' | translate" [required]="false"></field-label>
						<input
							formControlName="description"
							type="text"
							class="form-control"
							id="description"
							data-test="report-create.description"
							title="{{ 'T_REPORT.DESCRIPTION' | translate }}"
							placeholder="{{ 'T_REPORT.DESCRIPTION' | translate }}" />
						<div class="character-count">
							{{ maxFieldLength - reportForm.controls.description.value.length }} {{ 'T_REPORT.CHAR_REMAINING' | translate }}
						</div>
						<input-validation
							[control]="reportForm.controls.description"
							[errorOnTouch]="true"
							[extraMessageText]="maxLengthAdditionalMessageText">
						</input-validation>
					</div>
					<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<field-label [name]="'sharing'" [label]="'T_REPORT.SHARING' | translate"></field-label>
						<select class="form-control" formControlName="sharing" data-test="report-create.sharing">
							<option [ngValue]="'private'">{{ 'T_REPORT.PRIVATE' | translate }}</option>
							<option [ngValue]="'public'">{{ 'T_REPORT.PUBLIC' | translate }}</option>
						</select>
					</div>
					<div class="form-group col-xs-12 col-sm-8 col-md-8 col-lg-8">
						<field-label [name]="'output_type'" [label]="'T_REPORT.OUTPUT_TYPE' | translate" [required]="false"></field-label>
						<mat-radio-group formControlName="outputType" class="selection-group">
							<mat-radio-button
								class="selection-button"
								[value]="'interactive'"
								id="output_type_interactive"
								data-test="report-create.output_type-interactive">
								<span class="selection-text">{{ 'T_REPORT.INTERACTIVE' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button class="selection-button" [value]="'CSV'" id="output_type_csv" data-test="report-create.output_type-csv">
								<span class="selection-text">{{ 'T_REPORT.CSV' | translate }}</span>
							</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<field-label [name]="'created_by_name'" [label]="'T_REPORT.CREATED_BY_NAME' | translate"></field-label>
						<input
							formControlName="createdByName"
							type="text"
							class="form-control"
							id="createdByName"
							data-test="report-create.created_by"
							title="{{ 'T_REPORT.CREATED_BY_NAME' | translate }}"
							placeholder="{{ 'T_REPORT.CREATED_BY_NAME' | translate }}" />
					</div>
					<br />
					<div class="col-xs-12">
						<h2 class="nb-section-title">{{ 'T_REPORT.REPORT_OPTIONS' | translate }}</h2>
					</div>
					<!-- only render agencies if more than one. If single agency the form will already be defaulte to users default agency -->
					<div *ngIf="agencies?.length > 1">
						<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<field-label [name]="'agency'" [label]="'T_CORE.AGENCY' | translate" [required]="true"></field-label>
							<select
								name="agencyId"
								id="agencyId"
								class="form-control"
								formControlName="agencyId"
								(change)="agencyChanged()"
								data-test="agency.selection">
								<option *ngFor="let agency of agencies" [ngValue]="agency.nb_id">
									{{ agency.agency_name }}
								</option>
							</select>
						</div>
					</div>

					<div *ngIf="reportConfig.reportOptions.routes.enabled" class="form-group col-xs-12">
						<field-label
							[name]="'report_options_routes'"
							[label]="reportConfig.reportOptions.routes.multiSelect ? getTranslation('T_REPORT.ROUTES') : getTranslation('T_REPORT.ROUTE')"
							[required]="true"></field-label>

						<mat-radio-group
							*ngIf="reportConfig.reportOptions.routes.selections.length > 0"
							formControlName="routeSelection"
							class="selection-group">
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.routes.selections.includes(entitySelectionType.all)"
								class="selection-button"
								[value]="entitySelectionType.all"
								data-test="report-create.all_routes">
								<span class="selection-text">{{ 'T_REPORT.ALL_ROUTES' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.routes.selections.includes(entitySelectionType.specific)"
								class="selection-button"
								[value]="entitySelectionType.specific"
								data-test="report-create.specific_routes">
								<span class="selection-text">{{ 'T_REPORT.SPECIFIC_ROUTES' | translate }}</span>
							</mat-radio-button>
						</mat-radio-group>
						<div *ngIf="reportForm.controls.routeSelection.value === entitySelectionType.specific">
							<!-- defaults to specific when the all selection isn't available -->
							<multi-select
								data-test="routes.select"
								[settings]="routeSettings"
								[items]="routes"
								[selected]="reportForm.controls.selectedRoutes.value"
								(itemClickedEvent)="routeItemUpdated()"
								(removeItemClicked)="routeItemUpdated()">
							</multi-select>
						</div>
					</div>

					<div *ngIf="reportConfig.reportOptions.vehicles.enabled" class="form-group col-xs-12">
						<field-label
							[name]="'report_options_vehicles'"
							[label]="
								reportConfig.reportOptions.vehicles.multiSelect ? getTranslation('T_REPORT.VEHICLES') : getTranslation('T_REPORT.VEHICLE')
							"
							[required]="true"></field-label>

						<mat-radio-group
							*ngIf="reportConfig.reportOptions.vehicles.selections.length > 0"
							formControlName="vehicleSelection"
							class="selection-group">
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.vehicles.selections.includes(entitySelectionType.all)"
								class="selection-button"
								[value]="entitySelectionType.all"
								data-test="report-create.all_vehicles">
								<span class="selection-text">{{ 'T_REPORT.ALL_VEHICLES' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.vehicles.selections.includes(entitySelectionType.specific)"
								class="selection-button"
								[value]="entitySelectionType.specific"
								data-test="report-create.specific_vehicles">
								<span class="selection-text">{{ 'T_REPORT.SPECIFIC_VEHICLES' | translate }}</span>
							</mat-radio-button>
						</mat-radio-group>

						<div *ngIf="reportForm.controls.vehicleSelection.value === entitySelectionType.specific">
							<!-- defaults to specific when the all selection isn't available -->
							<multi-select
								data-test="vehicles.select"
								[settings]="vehicleSettings"
								[items]="vehicles"
								[selected]="reportForm.controls.selectedVehicles.value"
								(itemClickedEvent)="vehicleItemUpdated()"
								(removeItemClicked)="vehicleItemUpdated()">
							</multi-select>
						</div>
					</div>

					<div
						*ngIf="
							reportConfig.reportOptions.blocks.enabled &&
							reportConfig.reportOptions.blocks.multiSelectionType === multiSelectionType.select
						"
						class="form-group col-xs-12">
						<field-label
							[name]="'report_options_blocks'"
							[label]="reportConfig.reportOptions.blocks.multiSelect ? getTranslation('T_REPORT.BLOCKS') : getTranslation('T_REPORT.BLOCK')"
							[required]="true"></field-label>

						<mat-radio-group
							*ngIf="reportConfig.reportOptions.blocks.selections.length > 0"
							formControlName="blockSelection"
							class="selection-group">
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.blocks.selections.includes(entitySelectionType.all)"
								class="selection-button"
								[value]="entitySelectionType.all"
								data-test="report-create.all_blocks">
								<span class="selection-text">{{ 'T_REPORT.ALL_BLOCKS' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.blocks.selections.includes(entitySelectionType.specific)"
								class="selection-button"
								[value]="entitySelectionType.specific"
								data-test="report-create.specific_blocks">
								<span class="selection-text">{{ 'T_REPORT.SPECIFIC_BLOCKS' | translate }}</span>
							</mat-radio-button>
							<mat-radio-button
								*ngIf="reportConfig.reportOptions.blocks.selections.includes(entitySelectionType.unassigned)"
								class="selection-button"
								[value]="entitySelectionType.unassigned"
								data-test="report-create.unassigned_blocks">
								<span class="selection-text">{{ 'T_REPORT.UNASSIGNED_BLOCKS' | translate }}</span>
							</mat-radio-button>
						</mat-radio-group>
						<div *ngIf="reportForm.controls.blockSelection.value === entitySelectionType.specific">
							<multi-select
								data-test="blocks.select"
								[settings]="blockSettings"
								[items]="blocks"
								[selected]="reportForm.controls.selectedBlocks.value"
								(itemClickedEvent)="blockItemUpdated()"
								(removeItemClicked)="blockItemUpdated()">
							</multi-select>
						</div>
					</div>

					<div *ngIf="reportConfig.reportOptions.filterArrivalStops.enabled" class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<div>
							<field-label
								[name]="'filterArrivalStops'"
								[label]="'T_REPORT.FILTER_ARRIVAL_STOPS' | translate"
								[required]="false"></field-label>
						</div>
						<div class="nb-form-control">
							<div class="nb-checkbox" data-test="report-create.filter-arrival-stops">
								<label for="filterArrivalStops">
									<input id="filterArrivalStops" type="checkbox" formControlName="filterArrivalStops" />
									<div class="checkbox-label">{{ 'T_REPORT.FILTER_ARRIVAL_STOPS_CHECKBOX' | translate }}</div>
								</label>
							</div>
						</div>
					</div>

					<div *ngIf="reportConfig.reportOptions.adherenceThreshold.enabled" class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<div>
							<field-label
								[name]="'adherenceThresholdOverride'"
								[label]="'T_REPORT.ADHERENCE_THRESHOLD_OVERRIDE' | translate"
								[required]="false"></field-label>
						</div>
						<div class="nb-form-control">
							<div class="nb-checkbox" data-test="report-create.adherence-threshold-override">
								<label for="adherenceThresholdOverride">
									<input
										id="adherenceThresholdOverride"
										type="checkbox"
										formControlName="adherenceThresholdOverride"
										(change)="adherenceThresholdOverrideUpdated()" />
									<div class="checkbox-label">{{ 'T_REPORT.ADHERENCE_THRESHOLD_OVERRIDE_ENABLED' | translate }}</div>
								</label>
							</div>
						</div>
					</div>
					<div
						*ngIf="reportConfig.reportOptions.adherenceThreshold.enabled && reportForm.controls.adherenceThresholdOverride.value === true">
						<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<field-label
								[name]="'adherence_setting_early_min_sec'"
								[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_EARLY_MIN_SEC' | translate"
								[required]="false"></field-label>
							<div class="form-control">{{ earlySliderValues[0] / 60 }} Minutes</div>
						</div>
						<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<field-label
								[name]="'adherence_setting_very_early_sec'"
								[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_VERY_EARLY_SEC' | translate"
								[required]="false"></field-label>
							<div class="form-control">{{ earlySliderValues[1] / 60 }} Minutes</div>
						</div>

						<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<slider [values]="earlySliderValues" [sliderCfg]="earlyLateSliderConfig"> </slider>
						</div>

						<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<field-label
								[name]="'adherence_setting_late_min_sec'"
								[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_LATE_MIN_SEC' | translate"
								[required]="false"></field-label>
							<div class="form-control">{{ lateSliderValues[0] / 60 }} Minutes</div>
						</div>
						<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<field-label
								[name]="'adherence_setting_very_late_sec'"
								[label]="'T_AGENCY.SETTINGS.ADHERENCE_SETTING_VERY_LATE_SEC' | translate"
								[required]="false"></field-label>
							<div class="form-control">{{ lateSliderValues[1] / 60 }} Minutes</div>
						</div>

						<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<slider [sliderCfg]="earlyLateSliderConfig" [values]="lateSliderValues"> </slider>
						</div>
					</div>

					<!-- Sort by block (or) Sort by time-->
					<div *ngIf="reportConfig.reportOptions.sort.enabled">
						<div class="col-xs-12">
							<h2 class="nb-section-title">{{ 'T_REPORT.SORT_OPTIONS' | translate }}</h2>
						</div>
						<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<field-label
								[name]="'report_options_schedule_sort'"
								[label]="'T_REPORT.SORT_TYPES' | translate"
								[required]="false"></field-label>
							<mat-radio-group
								*ngIf="reportConfig.reportOptions.sort.sortOptions.length > 0"
								formControlName="scheduleSortSelection"
								class="selection-group">
								<mat-radio-button
									class="selection-button"
									*ngIf="reportConfig.reportOptions.sort.sortOptions.includes(sortType.block)"
									[value]="sortType.block"
									data-test="report-create.sort.block">
									<span class="selection-text">{{ 'T_REPORT.SORT_BY_BLOCK' | translate }}</span>
								</mat-radio-button>
								<mat-radio-button
									class="selection-button"
									*ngIf="reportConfig.reportOptions.sort.sortOptions.includes(sortType.time)"
									[value]="sortType.time"
									data-test="report-create.sort.time">
									<span class="selection-text">{{ 'T_REPORT.SORT_BY_TIME' | translate }}</span>
								</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>

					<!-- Speed Options -->
					<div *ngIf="reportConfig.reportOptions.speed.enabled">
						<div class="col-xs-12">
							<h2 class="nb-section-title">
								{{ 'T_REPORT.SPEED_OPTIONS' | translate }}
							</h2>
						</div>
						<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<field-label [name]="'report_options_speed'" [label]="'T_REPORT.SPEED_IN' | translate" [required]="false"> </field-label>
							<mat-radio-group
								*ngIf="reportConfig.reportOptions.speed.speedOptions.length > 0"
								formControlName="speedSelection"
								class="selection-group">
								<mat-radio-button
									class="selection-button"
									*ngIf="reportConfig.reportOptions.speed.speedOptions.includes(speedType.mph)"
									[value]="speedType.mph">
									<span class="selection-text">
										{{ 'T_REPORT.SPEED_MPH' | translate }}
									</span>
								</mat-radio-button>
								<mat-radio-button
									class="selection-button"
									*ngIf="reportConfig.reportOptions.speed.speedOptions.includes(speedType.kmh)"
									[value]="speedType.kmh">
									<span class="selection-text">
										{{ 'T_REPORT.SPEED_KMH' | translate }}
									</span>
								</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>

					<!-- Date Options -->
					<div *ngIf="!reportConfig.dateOptions.disabled">
						<br />
						<div class="col-xs-12">
							<h2 class="nb-section-title">{{ 'T_REPORT.DATE_OPTIONS' | translate }}</h2>
						</div>
						<div *ngIf="reportConfig.dateOptions.showDateRangeOptions" class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<field-label
								[name]="'effective_date_range_type'"
								[label]="'T_REPORT.DATE.EFFECTIVE_DATE_RANGE_TYPE' | translate"
								[required]="false"></field-label>
							<mat-radio-group formControlName="effectiveDateRangeType" class="selection-group" (change)="effectiveDateRangeTypeChange()">
								<div *ngIf="reportConfig.dateOptions.dateRangeTypes.day.enabled">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeType.day"
										data-test="report-create.date-options.day">
										<span class="selection-text">{{ 'T_REPORT.DATE.DAY' | translate }}</span>
									</mat-radio-button>
								</div>
								<div *ngIf="reportConfig.dateOptions.dateRangeTypes.week.enabled">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeType.week"
										data-test="report-create.date-options.week">
										<span class="selection-text">{{ 'T_REPORT.DATE.WEEK' | translate }}</span>
									</mat-radio-button>
								</div>
								<div *ngIf="reportConfig.dateOptions.dateRangeTypes.month.enabled">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeType.month"
										data-test="report-create.date-options.month">
										<span class="selection-text">{{ 'T_REPORT.DATE.MONTH' | translate }}</span>
									</mat-radio-button>
								</div>
								<div *ngIf="reportConfig.dateOptions.dateRangeTypes.custom.enabled">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeType.custom"
										data-test="report-create.date-options.custom">
										<span class="selection-text">{{ 'T_REPORT.DATE.CUSTOM_DATE_RANGE' | translate }}</span>
									</mat-radio-button>
								</div>
							</mat-radio-group>
						</div>

						<!-- DAY DATE TYPE SELECTION -->
						<div *ngIf="reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.day">
							<div class="form-group col-xs-12 date-range-label">
								<field-label [name]="'type'" [label]="'T_REPORT.DATE.DATE_RANGE_OPTIONS' | translate" [required]="true"></field-label>
							</div>
							<div class="form-group col-xs-2">
								<mat-radio-group
									formControlName="effectiveDateRangeDayType"
									class="selection-group"
									(change)="effectiveDateRangeDayTypeChange()">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayType.today"
										data-test="report-create.date-range.day.today">
										<span class="selection-text">{{ 'T_REPORT.TODAY' | translate }}</span>
									</mat-radio-button>

									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayType.yesterday"
										data-test="report-create.date-range.day.yesterday">
										<span class="selection-text">{{ 'T_REPORT.YESTERDAY' | translate }}</span>
									</mat-radio-button>

									<div *ngIf="reportConfig.dateOptions.dateRangeTypes.custom.enabled">
										<mat-radio-button
											class="selection-button"
											[value]="effectiveDateRangeDayType.custom"
											data-test="report-create.date-range.custom">
											<span class="selection-text">{{ 'T_REPORT.CUSTOM' | translate }}</span>
										</mat-radio-button>
									</div>
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayType.specific"
										data-test="report-create.date-range.day.specific">
										<span class="selection-text">{{ 'T_REPORT.SPECIFIC_DATE' | translate }}</span>
									</mat-radio-button>
								</mat-radio-group>
							</div>

							<!-- DATE RANGE TYPE - 'day' / DAY TYPE - 'specific'-->
							<div
								*ngIf="
									reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.day &&
									reportForm.controls.effectiveDateRangeDayType.value === effectiveDateRangeDayType.specific
								">
								<div class="col-xs-10" data-test="report-create.specific.date">
									<div [ngClass]="reportConfig.dateOptions.dateRangeTypes.custom.enabled ? 'specific-date-with-custom' : 'specific-date'">
										<mat-form-field>
											<mat-label>{{ 'T_CORE.CHOOSE_DATE' | translate }}</mat-label>
											<input
												matInput
												formControlName="specificDate"
												[matDatepicker]="specificDatePicker"
												[max]="maxDate"
												(input)="setUpdatedSpecificDate()"
												(dateChange)="setUpdatedSpecificDate()" />
											<mat-datepicker-toggle matIconSuffix [for]="specificDatePicker"></mat-datepicker-toggle>
											<mat-datepicker #specificDatePicker></mat-datepicker>
										</mat-form-field>
									</div>
									<span *ngIf="hasSpecificDateError" class="specific-date-error" data-test="form.error">{{ specificDateErrorText }}</span>
								</div>
							</div>
						</div>

						<!-- WEEK DATE TYPE SELECTION -->
						<div *ngIf="reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.week">
							<div class="form-group col-xs-12">
								<field-label [name]="'type'" [label]="'T_REPORT.DATE.DATE_RANGE_OPTIONS' | translate" [required]="false"></field-label>
								<mat-radio-group formControlName="effectiveDateRangeWeekType" class="selection-group">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeWeekType.thisWeek"
										data-test="report-create.date-range.week.this-week">
										<span class="selection-text">{{ 'T_REPORT.THIS_WEEK' | translate }}</span>
									</mat-radio-button>

									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeWeekType.lastWeek"
										data-test="report-create.date-range.week.last-week">
										<span class="selection-text">{{ 'T_REPORT.LAST_WEEK' | translate }}</span>
									</mat-radio-button>

									<div *ngIf="reportConfig.dateOptions.dateRangeTypes.custom.enabled">
										<mat-radio-button
											class="selection-button"
											[value]="effectiveDateRangeWeekType.custom"
											data-test="report-create.date-range.custom">
											<span class="selection-text">{{ 'T_REPORT.CUSTOM' | translate }}</span>
										</mat-radio-button>
									</div>
								</mat-radio-group>
							</div>
						</div>

						<!-- MONTH DATE TYPE SELECTION -->
						<div *ngIf="reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.month">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<field-label [name]="'type'" [label]="'T_REPORT.DATE.DATE_RANGE_OPTIONS' | translate" [required]="false"></field-label>
								<mat-radio-group formControlName="effectiveDateRangeMonthType" class="selection-group">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeMonthType.thisMonth"
										data-test="report-create.date-range.month.this-month">
										<span class="selection-text">{{ 'T_REPORT.THIS_MONTH' | translate }}</span>
									</mat-radio-button>

									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeMonthType.lastMonth"
										data-test="report-create.date-range.month.last-month">
										<span class="selection-text">{{ 'T_REPORT.LAST_MONTH' | translate }}</span>
									</mat-radio-button>

									<div *ngIf="reportConfig.dateOptions.dateRangeTypes.custom.enabled">
										<mat-radio-button
											class="selection-button"
											[value]="effectiveDateRangeMonthType.custom"
											data-test="report-create.date-range.custom">
											<span class="selection-text">{{ 'T_REPORT.CUSTOM' | translate }}</span>
										</mat-radio-button>
									</div>
								</mat-radio-group>
							</div>
						</div>

						<!-- CUSTOM DATE TYPE SELECTION -->
						<div *ngIf="reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.custom">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<field-label
									[name]="'T_REPORT.DATE.START_DATE'"
									[label]="'T_REPORT.DATE.START_DATE' | translate"
									[required]="true"></field-label>
								<div class="row custom-date-row" data-test="report-create.date-options.custom.start">
									<div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 custom-date-col">
										<mat-form-field>
											<mat-label>{{ 'T_CORE.CHOOSE_DATE' | translate }}</mat-label>
											<input
												matInput
												matInput
												formControlName="customStartDate"
												[matDatepicker]="customStartDatePicker"
												[max]="maxDate"
												(input)="setUpdatedCustomDate()"
												(dateChange)="setUpdatedCustomDate()" />
											<mat-datepicker-toggle matIconSuffix [for]="customStartDatePicker"></mat-datepicker-toggle>
											<mat-datepicker #customStartDatePicker></mat-datepicker>
										</mat-form-field>
									</div>
									<div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
										<time-selector
											data-test="custom-start-time"
											[initialTime]="reportForm.controls.customStartTime.value"
											(timeValid)="setTimeValid($event, 'customStartTime')"
											(timeUpdated)="setUpdatedTime($event, 'customStartTime')">
										</time-selector>
									</div>
									<br />
									<br />
								</div>
								<field-label
									[name]="'T_REPORT.DATE.END_DATE'"
									[label]="'T_REPORT.DATE.END_DATE' | translate"
									[required]="true"></field-label>
								<div class="row custom-date-row" data-test="report-create.date-options.custom.end">
									<div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 custom-date-col">
										<mat-form-field>
											<mat-label>{{ 'T_CORE.CHOOSE_DATE' | translate }}</mat-label>
											<input
												matInput
												matInput
												formControlName="customEndDate"
												[matDatepicker]="customEndDatePicker"
												[max]="maxDate"
												(input)="setUpdatedCustomDate()"
												(dateChange)="setUpdatedCustomDate()" />
											<mat-datepicker-toggle matIconSuffix [for]="customEndDatePicker"></mat-datepicker-toggle>
											<mat-datepicker #customEndDatePicker></mat-datepicker>
										</mat-form-field>
									</div>
									<div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
										<time-selector
											data-test="custom-end-time"
											[initialTime]="reportForm.controls.customEndTime.value"
											(timeValid)="setTimeValid($event, 'customEndTime')"
											(timeUpdated)="setUpdatedTime($event, 'customEndTime')">
										</time-selector>
									</div>
									<br />
									<br />
								</div>
								<span *ngIf="hasCustomDateTimeError" class="custom-date-error" data-test="form.error">{{ customDateTimeErrorText }}</span>
							</div>
						</div>
						<br />
						<br />
						<!-- DATE RANGE TYPE - 'day', 'week', 'month' / 'custom' SELECTION -->
						<div
							*ngIf="
								(reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.day &&
									reportForm.controls.effectiveDateRangeDayType.value === effectiveDateRangeDayType.custom) ||
								(reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.week &&
									reportForm.controls.effectiveDateRangeWeekType.value === effectiveDateRangeWeekType.custom) ||
								(reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.month &&
									reportForm.controls.effectiveDateRangeMonthType.value === effectiveDateRangeMonthType.custom)
							">
							<div class="form-group col-xs-12 number-of-units-label">
								<field-label
									[name]="'number_of_units'"
									[label]="getNumberOfUnitsTranslation(reportForm.controls.effectiveDateRangeType.value)"
									[required]="true"></field-label>
							</div>
							<div class="form-group col-xs-6">
								<input
									formControlName="noOfUnitsCount"
									class="form-control"
									type="number"
									data-test="report-create.number_of_units"
									min="1"
									max="{{ getMaxNoOfUnitCount() }}"
									title="{{ getNumberOfUnitsTranslation(reportForm.controls.effectiveDateRangeType.value) }}" />
								<input-validation [control]="reportForm.controls.noOfUnitsCount" [errorOnTouch]="false"> </input-validation>
							</div>

							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div>
									<field-label
										[name]="'FULL_UNITS_ONLY'"
										[label]="getFullUnitsOnlyTranslation(reportForm.controls.effectiveDateRangeType.value)"
										[required]="false"></field-label>
								</div>
								<div class="nb-form-control">
									<div class="nb-checkbox" data-test="report-create.full-units-only">
										<label for="fullUnitsOnly">
											<input id="fullUnitsOnly" type="checkbox" formControlName="fullUnitsOnly" />
											<div class="checkbox-label">
												{{ getFullUnitsOnlyDescriptionTranslation(reportForm.controls.effectiveDateRangeType.value) }}
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>

						<!-- DAY RANGE TYPE - 'dayName' -->
						<div
							*ngIf="
								reportConfig.dateOptions.dateRangeTypes.dayName.enabled &&
								reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType.dayName
							">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<field-label [name]="'type'" [label]="'T_REPORT.DAY_TYPE' | translate" [required]="false"></field-label>
								<mat-radio-group formControlName="dayNameType" class="selection-group">
									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayNameType.includeAll"
										data-test="report-create.date-range.day_name.include-all">
										<span class="selection-text">{{ 'T_REPORT.INCLUDE_ALL' | translate }}</span>
									</mat-radio-button>

									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayNameType.weekdays"
										data-test="report-create.date-range.day_name.weekday">
										<span class="selection-text">{{ 'T_REPORT.WEEKDAYS' | translate }}</span>
									</mat-radio-button>

									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayNameType.saturday"
										data-test="report-create.date-range.day_name.saturday">
										<span class="selection-text">{{ 'T_REPORT.SATURDAY' | translate }}</span>
									</mat-radio-button>

									<mat-radio-button
										class="selection-button"
										[value]="effectiveDateRangeDayNameType.sunday"
										data-test="report-create.date-range.day_name.sunday">
										<span class="selection-text">{{ 'T_REPORT.SUNDAY' | translate }}</span>
									</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>

						<div *ngIf="reportConfig.dateOptions.effectiveTime.enabled">
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 specific-time-col">
								<div>
									<field-label
										[name]="'effective_time_enabled'"
										[label]="'T_REPORT.LIMIT_EFFECTIVE_TIME_WINDOW' | translate"
										[required]="false"></field-label>
								</div>
								<div class="nb-form-control">
									<div class="nb-checkbox" data-test="report-create.effective-time-enabled">
										<label for="effectiveTimeEnabled">
											<input id="effectiveTimeEnabled" type="checkbox" formControlName="effectiveTimeEnabled" />
											<div class="checkbox-label">{{ 'T_REPORT.EFFECTIVE_TIME_ENABLED' | translate }}</div>
										</label>
									</div>
								</div>
							</div>
							<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div *ngIf="reportForm.controls.effectiveTimeEnabled.value === true">
									<time-selector
										data-test="report-create.effective-start-time"
										[initialTime]="reportForm.controls.effectiveStartTime.value"
										[timeLabel]="'T_REPORT.DATE.START_TIME' | translate"
										[timeLabelRequired]="true"
										(timeValid)="setTimeValid($event, 'effectiveStartTime')"
										(timeUpdated)="setUpdatedTime($event, 'effectiveStartTime')">
									</time-selector>
									<time-selector
										data-test="report-create.effective-end-time"
										[initialTime]="reportForm.controls.effectiveEndTime.value"
										[timeLabel]="'T_REPORT.DATE.END_TIME' | translate"
										[timeLabelRequired]="true"
										(timeValid)="setTimeValid($event, 'effectiveEndTime')"
										(timeUpdated)="setUpdatedTime($event, 'effectiveEndTime')">
									</time-selector>
									<span *ngIf="hasEffectiveTimeError" class="time-error" data-test="form.error">{{ effectiveTimeErrorText }}</span>
								</div>
							</div>
						</div>
					</div>
					<!-- Multi Selection Type Blocks List -->
					<div
						class="list_data_table"
						*ngIf="
							reportConfig.reportOptions.blocks.enabled && reportConfig.reportOptions.blocks.multiSelectionType === multiSelectionType.list
						">
						<div class="data-list-container" *ngIf="blockListInitialized">
							<data-table
								#dataList
								data-test="reports.template.table"
								[listName]="'blocksSelectionList'"
								[columns]="blocksSelectionListColumns"
								[rowData]="blockSelectionList"
								[selectedRows]="selectedBlocks"
								[pagingEnabled]="false"
								[externalSorting]="true"
								[sortInfo]="sortInfo"
								[enableCheckRowSelection]="true"
								[enabledMultiCheckRowSelection]="true"
								[enabledCheckSelectAllRowSelection]="false"
								[enableMenuSelection]="false"
								[enableFooter]="true"
								[enableTotal]="false"
								[enableCount]="true"
								[loadingIndicator]="blockListLoadingIndicator"
								[loadingText]="blocksLoadingText"
								(sortData)="handleSortRequest($event)"
								(checkSelect)="onBlockListCheckSelect($event)">
							</data-table>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="close(null)" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-primary" [disabled]="!enableAction()" (click)="handleSaveReport()">{{ 'T_REPORT.SAVE' | translate }}</button>
	<button class="btn btn-primary" [disabled]="!enableAction()" (click)="handleSaveAndRunReport()">
		{{ 'T_REPORT.SAVE_AND_RUN' | translate }}
	</button>
	<button class="btn btn-primary" [disabled]="!enableAction()" (click)="handleRunReport()">{{ 'T_REPORT.RUN' | translate }}</button>
</mat-dialog-actions>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { AgencySelectorComponent } from './agency-selection/agency-selector/agency-selector.component';
import { CurrentAgencyComponent } from './agency-selection/current-agency.component';

import { AgenciesApiService } from './services/agencies-api.service';
import { AgenciesEventsService } from './services/agencies-events.service';
import { AgenciesDataService } from './services/agencies-data.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
	],
	declarations: [CurrentAgencyComponent, AgencySelectorComponent],
	providers: [AgenciesApiService, AgenciesEventsService, AgenciesDataService],
	exports: [CurrentAgencyComponent],
})
export class AgenciesModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { VehicleEventsDataService } from '../../../../features/vehicle-events/services/vehicle-events-data.service';
import { LoggerService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';

import {
	VehicleEventNotification,
	VehicleEventNotifications,
	VehicleEventsSettings,
	VehicleEventTypes,
} from '../../../../features/vehicle-events/types/types';

import moment from 'moment';

@Injectable()
export class VehicleEventsAudioService {
	private eventAudioContext: AudioContext = null;

	constructor(
		private vehicleEventsDataService: VehicleEventsDataService,
		private logger: LoggerService
	) {}

	/**
	 * initializes the vehicle events audio service
	 */
	public init = (): void => {
		if (!this.eventAudioContext) {
			// note: audio context needs to be created and resumed following
			// a user gesture such as a click due to browser restrictions
			this.eventAudioContext = new AudioContext();
			this.eventAudioContext.resume();
		}
	};

	/**
	 * plays the audio for vehicle event notifications
	 *
	 * @param settings - the vehicle event settings
	 * @param eventTypes - the vehicle event types
	 * @param vehicleEventNotifications - the vehicle event notifications
	 */
	public playAudioForEventNotifications = async (
		settings: VehicleEventsSettings,
		eventTypes: VehicleEventTypes,
		vehicleEventNotifications: VehicleEventNotifications
	): Promise<void> => {
		if (this.eventAudioContext !== null) {
			const audioPlayed: any[] = [];
			const delay: number = 1000;
			let delayCount: number = 0;

			vehicleEventNotifications.forEach((notification: VehicleEventNotification) => {
				const foundRes: number = settings.includedEventTypeIds.find((type) => type === notification.vehicleEvent.event_type_id);

				if (foundRes !== undefined) {
					if (!notification.playAudio && notification.vehicleEvent.repeat > 0 && notification.lastPlayed) {
						const delta: number = moment.utc().unix() - notification.lastPlayed;

						if (delta >= notification.vehicleEvent.repeat) {
							notification.playAudio = true;
						}
					}

					if (notification.playAudio) {
						if (!audioPlayed.includes(notification.vehicleEvent.type)) {
							eventTypes.forEach(async (type) => {
								if (type.audio && notification.vehicleEvent.type.toLowerCase() === type.type.toLowerCase()) {
									try {
										const response: ResultContent = await this.vehicleEventsDataService.getEventAudio(
											settings.selectedAgency.authority_id,
											type.audio
										);

										if (response.success) {
											const data: any = response.resultData;
											const { data: data1 } = await this.delayBetweenPromises(delay * delayCount, data);

											this.playByteArray(data1);
										}
									} catch (error) {
										this.logger.logError('Failed to play audio - ' + JSON.stringify(error));
									}
									delayCount++;
								}
							});
							audioPlayed.push(notification.vehicleEvent.type);
						}

						notification.playAudio = false;
						notification.lastPlayed = moment.utc().unix();
					}
				}
			});
		}
	};

	/**
	 * creates a delay between promises
	 *
	 * @param ms - the milliseconds time
	 * @param x - the x
	 * @returns x
	 */
	private delayBetweenPromises = async (ms: number, x: any): Promise<any> => {
		const timeout: (milliSecs: number) => Promise<number> = (milliSecs: number) =>
			new Promise((resolve) => setTimeout(resolve, milliSecs));

		await timeout(ms);

		return x;
	};

	/**
	 * plays the audio
	 *
	 * @param bytes - the bytes (audio file)
	 */
	private playByteArray = async (bytes: any): Promise<void> => {
		const buffer: Uint8Array = new Uint8Array(bytes.length);

		buffer.set(new Uint8Array(bytes), 0);

		try {
			const audioBuffer: AudioBuffer = await this.eventAudioContext.decodeAudioData(buffer.buffer);
			const source: AudioBufferSourceNode = this.eventAudioContext.createBufferSource();

			source.buffer = audioBuffer;
			source.connect(this.eventAudioContext.destination);
			source.start(0);
		} catch (error) {
			this.logger.logError('Failed to play audio - ' + JSON.stringify(error));
		}
	};
}

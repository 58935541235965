/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { DataTableComponent } from '@cubicNx/libs/utils';
import { RiderMessagesListExpandedDetailComponent } from './expanded-detail/rider-messages-list-expanded-detail.component';

import { StringHelpers } from '@cubicNx/libs/utils';

import { StateService } from '@cubicNx/libs/utils';
import { RiderMessagesDataService } from '../../services/rider-messages-data.service';
import { RiderMessagesUtilService } from '../../services/rider-messages-util.service';
import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { RiderMessagesModalService } from '../../services/rider-messages-modal.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { RiderMessagesPaginatedResponse, RiderMessagesPaginatedParams, DayTimeInterval, RiderMessage } from '../../types/api-types';
import { Columns, ColumnType, ListSortings, PageRequestInfo, SortDirection } from '@cubicNx/libs/utils';
import { IconType, SelectedRowData } from '@cubicNx/libs/utils';
import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	RiderMessagesListItem,
	RiderMessagesList,
	criticalPriorityName,
	highPriorityName,
	normalPriorityName,
	lowPriorityName,
} from '../../types/types';

import moment, { Moment } from 'moment/moment';

@Component({
	selector: 'rider-messages-list',
	templateUrl: './rider-messages-list.component.html',
	styleUrls: ['./rider-messages-list.component.scss'],
})
export class RiderMessagesListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild('expandedDetailTemplate', { static: true, read: TemplateRef }) template: TemplateRef<any>;
	@ViewChild(DataTableComponent) dataList: DataTableComponent;
	@ViewChild(RiderMessagesListExpandedDetailComponent) expandedDetailComponent: RiderMessagesListExpandedDetailComponent;

	@Input() active: boolean = true;

	// pass through the defaukt sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'startDate', dir: SortDirection.desc }];

	public authorityId: string = null;
	public agencyId: string = null;
	public hasFilters: boolean = false;
	public listName: string = 'rider-messages-list';
	public columns: Columns = [];
	public messagesList: RiderMessagesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public initialized: boolean = false;
	public allRowsExpanded: boolean = false;
	public messageSelection: RiderMessagesList = [];
	public columnSizes: Map<string, number>;
	public searchText: string = '';

	private readonly pollInterval: number = 60000;

	private readonly allDaysName: string = 'All Days';
	private readonly sundayName: string = 'Sun';
	private readonly mondayName: string = 'Mon';
	private readonly tuesdayName: string = 'Tue';
	private readonly wednesdayName: string = 'Wed';
	private readonly thursdayName: string = 'Thu';
	private readonly fridayName: string = 'Fri';
	private readonly saturdayName: string = 'Sat';

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];

	private pollTimer: any = null;

	constructor(
		private riderMessagesDataService: RiderMessagesDataService,
		private riderMessagesUtilService: RiderMessagesUtilService,
		private riderMessagesModalService: RiderMessagesModalService,
		private agenciesDataService: AgenciesDataService,
		private stateService: StateService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the rider messages list
	 *
	 * loads translations, builds list columns and starts polling for rider messages for the agency
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.getSelectedAgency();
		this.loadCache();
		this.buildListColumns();
		this.startPollTimer();

		this.initialized = true;
	}

	/**
	 * caches the list column sizes
	 *
	 * @param columnSizes - the column sizes
	 */
	public setColumnSizes = (columnSizes: Map<string, number>): void => {
		this.columnSizes = columnSizes;
	};

	/**
	 * updates the contents of the rider messages list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;

		this.cachePageInfo(this.pageInfo);

		this.getRiderMessages(this.active);
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = (searchText: string): void => {
		this.searchText = searchText;

		// we can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intigating a search
		this.pageInfo.pageNum = 1;

		this.cacheSearch(this.searchText);

		this.getRiderMessages(this.active);
	};

	/**
	 * refreshes the list with the new agency rider messages if the aganecy has changed
	 *
	 * @param refreshAgency - refresh agency or not
	 */
	public refresh = (refreshAgency: boolean): void => {
		// external refresh to refresh the data with the current page settings
		if (this.initialized) {
			if (refreshAgency) {
				this.getSelectedAgency();
			}

			this.getRiderMessages(this.active);
		}
	};

	/**
	 * remembers the selected rider messages
	 *
	 * @param messages - the selected rider messages
	 */
	public onCheckSelect = (messages: RiderMessagesList): void => {
		this.messageSelection = messages;
	};

	/**
	 * when a row on the list is selected, the edit message dialog is rendered
	 *
	 * @param selectedRow - the selected row from the list
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const message: RiderMessagesListItem = selectedRow.row;

		const serviceAlertId: string = message.id;

		this.editMessage(serviceAlertId);
	};

	/**
	 * when the edit button is selected, the edit message dialog is rendered
	 */
	public edit = (): void => {
		// there can only be one selected for edit to be enabled so grab the first entry
		const serviceAlertId: string = this.messageSelection[0].id;

		this.editMessage(serviceAlertId);
	};

	/**
	 * when the delete button is selected, the delete dialog is rendered
	 */
	public delete = (): void => {
		const serviceAlertIds: string[] = this.messageSelection.map((message) => message.id);

		this.deleteMessages(serviceAlertIds);
	};

	/**
	 * when the expand all option is selected, the data list is expanded or collapsed depending upon current state
	 */
	public expandAll = (): void => {
		if (this.allRowsExpanded) {
			this.dataList.collapseAllRows();
		} else {
			this.dataList.expandAllRows();
		}

		this.allRowsExpanded = !this.allRowsExpanded;
	};

	/**
	 * remembers whether the all expanded is set or not
	 *
	 * @param allExpanded - the all expanded value
	 */
	public curentPageAllRowsExpanded = (allExpanded: boolean): void => {
		this.allRowsExpanded = allExpanded;
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		clearTimeout(this.pollTimer);
	}

	/**
	 * load translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.MESSAGE',
			'T_RIDER.PRIORITY',
			'T_RIDER.END_DATE',
			'T_RIDER.START_DATE',
			'T_RIDER.TIMES_ACTIVE',
			'T_RIDER.CREATED_BY',
		]);
	};

	/**
	 * builds the rider messages list columns
	 */
	private buildListColumns = (): void => {
		// build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: 'priority',
				displayName: this.translations['T_RIDER.PRIORITY'],
				columnType: ColumnType.icon,
				fixedWidth: 90,
			},
			{
				name: 'descriptionText',
				displayName: this.translations['T_CORE.MESSAGE'],
				columnType: ColumnType.text,
				width: 500,
			},
			{
				name: 'startDate',
				displayName: this.translations['T_RIDER.START_DATE'],
				columnType: ColumnType.date,
			},
			{
				name: 'endDate',
				displayName: this.translations['T_RIDER.END_DATE'],
				columnType: ColumnType.date,
			},
			{
				name: 'activeTimes',
				displayName: this.translations['T_RIDER.TIMES_ACTIVE'],
				columnType: ColumnType.text,
				width: 400,
				sortable: false,
			},
			{
				name: 'createdBy',
				displayName: this.translations['T_RIDER.CREATED_BY'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * retrieves last used search and page information for the list
	 */
	private loadCache = (): void => {
		if (this.active) {
			this.listName += '-active';
		} else {
			this.listName += '-inactive';
		}

		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information such as page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current searhed for text
	 *
	 * @param search - the current searched for text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * retrieves the rider messages (active or inactive) and populates the list
	 *
	 * @param active - whether active messages are required or not
	 */
	private getRiderMessages = async (active: boolean): Promise<void> => {
		this.listLoadingIndicator = true;

		this.totalRows = 0;

		// convert client side camel side notation for field name back to underscore version for back end request
		const paginatedParams: RiderMessagesPaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			sort: StringHelpers.getUnderscoreValueFromCamelCase(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
		};

		// set disabled if requesting inactive messaged - can't just pass in active flag as  whether we set disabled true or false
		// it returns inactive data - seems just the existance of the parameter is what the back end drives from
		if (!active) {
			paginatedParams.disabled = true;
		}

		const result: ResultContent = await this.riderMessagesDataService.getMessages(this.authorityId, this.agencyId, paginatedParams);

		if (result.success) {
			const authoritiesResponse: RiderMessagesPaginatedResponse = result.resultData;

			if (authoritiesResponse?.results) {
				// object for our list
				this.messagesList = authoritiesResponse.results.map((message) => ({
					id: message.service_alert_id,
					descriptionText: message.description_text,
					startDate: message.start_date,
					createdBy: message.created_by,
					priority: this.getPriority(message.priority),
					endDate: message.end_date,
					signIds: message.sign_ids,
					routes: message.routes,
					stops: message.stop_service_alerts,
					activeTimes: this.getActiveTimes(message),
				}));

				this.totalRows = authoritiesResponse.total;

				// reset when we get new data as rows are not expanded when loaded
				this.allRowsExpanded = false;
			}
		}

		this.listLoadingIndicator = false;

		this.changeDetectorRef.detectChanges();
	};

	/**
	 * retrieves the active times from the supplied rider message
	 *
	 * @param message - a rider message
	 * @returns active times from the supplied message
	 */
	private getActiveTimes = (message: RiderMessage): string => {
		let activeTimes: string = '';

		if (message.time_intervals.length > 0) {
			// workaround for issue when Saturday (end of week) is selected and times span midnight
			if (
				message.time_intervals.length > 1 &&
				message.time_intervals[0].end_time !== message.time_intervals[message.time_intervals.length - 1].endTime
			) {
				message.time_intervals[message.time_intervals.length - 1].end_time = message.time_intervals[0].end_time;
				message.time_intervals.shift();
			}

			let times: string = '';
			let days: string = '';

			let timeIntervalIndex: number = 0;

			if (message.time_intervals.length > 1 && message.time_intervals[1].end_time > message.time_intervals[1].start_time) {
				timeIntervalIndex = 1;
			}

			const startTime: Moment = moment().startOf('day').seconds(message.time_intervals[timeIntervalIndex].start_time);
			const endTime: Moment = moment().startOf('day').seconds(message.time_intervals[timeIntervalIndex].end_time);

			times += moment(startTime).format('hh:mm A');
			times += ' to ';
			times += moment(endTime).format('hh:mm A');

			if (message.time_intervals.length === 7) {
				days += this.allDaysName;
			} else {
				message.time_intervals.forEach((dayTimeInterval: DayTimeInterval) => {
					days += this.getDay(dayTimeInterval.startDay) + ', ';
				});
				days = days.substring(0, days.length - 2);
			}

			// day list may be empty if message created by UI1
			activeTimes = days ? days + ' - ' + times : times;
		}

		return activeTimes;
	};

	/**
	 * gets the day as a text value from the supplied day value
	 *
	 * @param dayValue - the day value
	 * @returns day
	 */
	private getDay = (dayValue: number): string => {
		let day: string = '';

		switch (dayValue) {
			case 0:
				day = this.sundayName;
				break;
			case 1:
				day = this.mondayName;
				break;
			case 2:
				day = this.tuesdayName;
				break;
			case 3:
				day = this.wednesdayName;
				break;
			case 4:
				day = this.thursdayName;
				break;
			case 5:
				day = this.fridayName;
				break;
			case 6:
				day = this.saturdayName;
				break;
		}

		return day;
	};

	/**
	 * gets the priority icon
	 *
	 * @param priority - the priority
	 * @returns icon representing the supplied priority
	 */
	private getPriority = (priority: string): IconType => {
		let iconClass: string = 'material-icons ';
		let iconType: string = '';

		switch (priority) {
			case criticalPriorityName:
			case highPriorityName:
				iconClass += 'nb-text-error';
				iconType = 'error';
				break;
			case normalPriorityName:
				iconClass += 'nb-text-warning';
				iconType = 'warning';
				break;
			case lowPriorityName:
				iconClass += 'nb-text-info';
				iconType = 'info';
				break;
		}

		return {
			iconName: priority,
			iconClass,
			iconType,
		};
	};

	/**
	 * if permission allows, will render the edit message dialog
	 *
	 * if changes are saved then the rider messages list is refreshed
	 *
	 * @param serviceAlertId - the service alert id
	 */
	private editMessage = (serviceAlertId: string): void => {
		if (this.riderMessagesUtilService.canEditMessages(this.authorityId, this.agencyId)) {
			this.riderMessagesModalService.editMessage(serviceAlertId).subscribe((saved: boolean) => {
				if (saved) {
					// refresh the data with the current page settings
					this.getRiderMessages(this.active);
				}
			});
		}
	};

	/**
	 * if permission allows, will render the delete message dialog
	 *
	 * if changes are confirmed then the rider messages list is refreshed
	 *
	 * @param serviceAlertIds - the service alert ids
	 */
	private deleteMessages = (serviceAlertIds: string[]): void => {
		if (this.riderMessagesUtilService.canDeleteMessages(this.authorityId, this.agencyId)) {
			this.riderMessagesModalService
				.deleteMessages(serviceAlertIds, this.authorityId, this.agencyId)
				.subscribe((deleted: boolean) => {
					if (deleted) {
						// refresh the data with the current page settings
						this.getRiderMessages(this.active);
					}
				});
		}
	};

	/**
	 * gets the selected agency and remembers agency details
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
		}
	};

	/**
	 * start polling the list data
	 */
	private startPollTimer = (): void => {
		this.pollTimer = setTimeout(() => {
			this.pollData();
		}, this.pollInterval);
	};

	/**
	 * on a poll attempt, retrieve new data for the list and set up next poll attmpt
	 */
	private pollData = async (): Promise<void> => {
		this.getRiderMessages(this.active);

		// restart the timer
		this.startPollTimer();
	};
}

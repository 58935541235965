/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnChanges } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { HeadwaySummaryDetail, HeadwayValue, HeadwayValues } from '../../../types/api-types';

import moment from 'moment';

@Component({
	selector: 'headway-by-stop-chart',
	templateUrl: './headway-by-stop-chart.component.html',
	styleUrls: ['./headway-by-stop-chart.component.scss'],
})
export class HeadwayByStopChartComponent extends TranslateBaseComponent implements OnChanges {
	@Input() summaryData: HeadwaySummaryDetail = null;

	public hasResults: boolean = false;
	public options: any = null;
	public chartIsInitialized: boolean = false;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * handles changes to the input params
	 *
	 * loads the chart data
	 */
	public ngOnChanges(): void {
		this.chartIsInitialized = false;
		if (!this.summaryData) {
			return;
		}

		this.loadChart();
	}

	/**
	 * loads the headway by stop chart with data
	 */
	private loadChart = (): void => {
		this.hasResults = true;
		this.setChartOptions();
		this.chartIsInitialized = true;
	};

	/**
	 * sets up the headway by stop chart options
	 */
	private setChartOptions = (): void => {
		this.options = {
			chart: {
				type: 'discreteBarChart',
				height: 450,
				margin: {
					top: 20,
					right: 20,
					bottom: 50,
					left: 55,
				},
				noData: this.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE'),
				x: (d: any): string => d.headsign,
				y: this.formatYValue,
				showValues: false,
				duration: 500,
				xAxis: {
					axisLabel: this.getTranslation('T_REPORT.DIRECTION'),
				},
				yAxis: {
					axisLabel: this.getTranslation('T_REPORT.AVERAGE_HEADWAY_REPORT'),
					axisLabelDistance: -10,
					tickValues: this.getYTickValues,
					tickFormat: (d: any): string => this.setTimes(d),
				},
				tooltip: {
					contentGenerator: this.generateTooltipContent,
				},
			},
		};
	};

	/**
	 * retrieves the Y Axis tick values for the average headway chart
	 *
	 * @returns tick values
	 */
	private getYTickValues = (): number[] => {
		const valuesArray: HeadwayValues = this.summaryData.values;
		const averageValues: number[] = valuesArray.map((item: HeadwayValue) => parseFloat(item.avg));
		const max: number = Math.max(...averageValues);
		let numTicks: number = Math.floor(max / 300);

		numTicks = numTicks + 2;
		const ticks: number[] = [];

		for (let i: number = 0; i < numTicks; i++) {
			ticks.push(i * 300);
		}

		return ticks;
	};

	/**
	 * generates the template using the supplied object that represnts the tooltip over the chart
	 *
	 * @param obj - the object
	 * @returns template using the supplied object data
	 */
	private generateTooltipContent = (obj: any): string => {
		const tooltipText: string =
			'<div style="background-color:white; display:flex; flex-direction:column; padding: 16px;">' +
			'<div style=" border-bottom:1px solid #ccc; display:flex; margin-bottom: 8px;">' +
			obj.data.route_long_name +
			'&nbsp' +
			obj.data.headsign +
			'</div>' +
			'<div style="display:flex; justify-content: space-between">' +
			'<div style="display:flex;">' +
			this.getTranslation('T_REPORT.AVG') +
			'</div>' +
			'<div  style="display:flex;"> ' +
			this.setTimes(obj.data.avg) +
			'</div>' +
			'</div  style="display:flex;">' +
			'<div style= "display:flex; justify-content: space-between">' +
			'<div  style="display:flex;">' +
			this.getTranslation('T_REPORT.MAX') +
			'</div>' +
			'<div  style="display:flex;">' +
			this.setTimes(obj.data.max) +
			'</div>' +
			'</div>' +
			'<div style= "display:flex; justify-content: space-between">' +
			'<div  style="display:flex;">' +
			this.getTranslation('T_REPORT.MIN') +
			'</div>' +
			'<div  style="display:flex;">' +
			this.setTimes(obj.data.min) +
			'</div>' +
			'</div>' +
			'<div style= "display:flex; justify-content: space-between">' +
			'<div  style="display:flex;">' +
			this.getTranslation('T_REPORT.STD') +
			'</div>' +
			'<div  style="display:flex;">' +
			this.fixedTwoPosStdDev(obj.data.numStddev) +
			'</div>' +
			'</div>' +
			'<div style= "display:flex; justify-content: space-between">' +
			'<div class="nb-padding-right-sm">' +
			this.getTranslation('T_REPORT.ADHERENCE') +
			'</div>' +
			'<div  style="display:flex;">' +
			obj.data.adherence +
			'</div>' +
			'</div>' +
			'</div>';

		return tooltipText;
	};

	/**
	 * formats the Y coordinate of the chart
	 *
	 * @param y - the coordinate
	 * @returns formatted float
	 */
	private formatYValue = (y: any): number => {
		if (y.avg === '-') {
			return 0;
		}

		return parseFloat(y.avg);
	};

	/**
	 * formats the supplied time into hh mm ss format
	 *
	 * @param time - the time
	 * @returns frmatted time in hh mm ss
	 */
	private setTimes = (time: number): string => {
		Math.floor(time);

		let format: string = 'mm:ss';

		if (time >= 3600) {
			format = 'hh:mm:ss';
		}

		return moment().startOf('day').add(time, 'seconds').format(format);
	};

	/**
	 * formats the supplied number to 2 decimal points
	 *
	 * @param value - the value
	 * @returns formatted number (2 decimal places)
	 */
	private fixedTwoPosStdDev = (value: number): string => {
		if (isNaN(value) || value === Number.MAX_SAFE_INTEGER || value === Number.MIN_SAFE_INTEGER) {
			return '-';
		}

		return parseFloat(value.toString()).toFixed(2);
	};
}

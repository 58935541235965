import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { DepotsViewComponent } from './view/depots-view.component';

import { depotsStoreProvider } from './services/depots-store-factory';

import { DepotsApiService } from './services/depots-api.service';
import { DepotsDataService } from './services/depots-data.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
	],
	declarations: [DepotsViewComponent],
	providers: [depotsStoreProvider, DepotsDataService, DepotsApiService],
	exports: [DepotsViewComponent],
})
export class DepotsModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { ReportViewerAdherenceColumnBasedBaseComponent } from '../../report-viewer-adherence-column-based-base.component';

import { ReportsConfigService } from '../../../../../../services/reports-config.service';
import { ReportsGenerationService } from '../../../../../../services/reports-generation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportVehicle, InteractiveReportAdherenceRouteDirectionStopData } from '../../../../../../types/api-types';
import { AdherenceVehicleArrivalAndDeparture } from '../../../../../../types/types';

@Component({
	selector: 'ad500-adherence-by-route-direction-stop-sub-list',
	templateUrl: './adherence-by-route-direction-stop-sub-list.component.html',
	styleUrls: ['./adherence-by-route-direction-stop-sub-list.component.scss'],
})
export class AdherenceByRouteDirectionStopSubListComponent extends ReportViewerAdherenceColumnBasedBaseComponent implements OnInit {
	@Input() override authorityId: string = null;
	@Input() override agencyId: string = null;

	constructor(
		private reportsGenerationService: ReportsGenerationService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * performs initialization tasks for the adherence route direction stop sub list view (AD500)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * generates the vehicle part of the report data
	 *
	 * @param adherenceData - the adherence data
	 */
	public generateReport = (adherenceData: InteractiveReportAdherenceRouteDirectionStopData): void => {
		if (this.templateSummaryContent.template.date_options.effective_date_range_type === 'day') {
			const vehicle: ReportVehicle = {
				authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
				vehicle_id: adherenceData.vehicle_id,
			};

			this.reportsGenerationService.generateVehiclesOnDateReport(
				AdherenceVehicleArrivalAndDeparture,
				this.templateSummaryContent.template,
				adherenceData.date,
				vehicle
			);
		}
	};
}

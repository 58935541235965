/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { SchedulesAdminDataService } from '../../services/schedules-admin-data.service';
import { StateService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { ScheduleAdminHistoryModalService } from '../../services/schedule-admin-history-modal.service';

import { UploadHistoryDataList, UploadHistoryDetailsType } from '../../types/types';
import { SchedulesPaginatedResponse } from '../../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	Columns,
	ColumnType,
	CssClassType,
	IconType,
	ListSortings,
	PageRequestInfo,
	PaginatedParams,
	SortDirection,
	SelectionByType,
	SelectedRowData,
} from '@cubicNx/libs/utils';

@Component({
	selector: 'schedule-admin-view-upload-history',
	templateUrl: './schedule-admin-view-upload-history.component.html',
	styleUrls: ['./schedule-admin-view-upload-history.component.scss'],
})
export class ScheduleAdminViewUploadHistoryComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() active: boolean = true;

	public readonly defaultSortings: ListSortings = [{ prop: 'startTime', dir: SortDirection.desc }];
	public readonly selectionByType: SelectionByType = SelectionByType.cell;

	public listName: string = 'schedule-upload-history';
	public listLoadingIndicator: boolean = true;
	public uploadHistoryListColumns: Columns = [];
	public uploadHistoryListData: UploadHistoryDataList = [];
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public initialized: boolean = false;

	private readonly donePreProduction: string = 'Done:pre-production';
	private readonly doneErrors: string = 'Done:errors';
	private readonly doneFailed: string = 'Done:failed';
	private readonly doneCancelled: string = 'Done:cancelled';
	private readonly doneProductionLive: string = 'Done:production(live)';

	private routeParams$Subscription: Subscription = null;
	private authorityId: string = null;
	private listCacheContainer: any = {};
	private cacheFields: string[] = ['pageInfo'];

	constructor(
		private stateService: StateService,
		private schedulesAdminDataService: SchedulesAdminDataService,
		private scheduleAdminHistoryModalService: ScheduleAdminHistoryModalService,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the schedules upload history list such as loading translations
	 * and building the list columns
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		// get params off the url
		this.routeParams$Subscription = this.route.params.subscribe((params) => {
			this.authorityId = params['authorityId'];

			this.buildRevisionUploadListColumns();
			this.loadCache();

			this.initialized = true;
		});
	}

	/**
	 * updates the contents of the schedule upload history list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getHistoryData();
	};

	/**
	 * action taken when an upload history is selected from the list - navigates to the selected schedule upload history details
	 *
	 * @param selectedRow - the selected row information
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		if (selectedRow.columnNameSelected === 'confirmationNumber' || selectedRow.columnNameSelected === 'status') {
			const confirmationNumber: string = selectedRow.row.confirmationNumber.value;

			this.scheduleAdminHistoryModalService
				.uploadHistoryConfirmDetails(
					this.authorityId,
					confirmationNumber,
					selectedRow.columnNameSelected === 'confirmationNumber'
						? UploadHistoryDetailsType.confirmationNumber
						: UploadHistoryDetailsType.status
				)
				.subscribe();
		}
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * retrieves the schedule upload history for the paging parameters and authority
	 */
	public getHistoryData = async (): Promise<void> => {
		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const paginatedParams: PaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			sort: this.mapColumnNames(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
		};

		const result: ResultContent = await this.schedulesAdminDataService.getSchedulesUploadHistory(this.authorityId, paginatedParams);

		if (result.success) {
			const schedulesHistoryResponse: SchedulesPaginatedResponse = result.resultData;

			if (schedulesHistoryResponse?.results) {
				// object for our list
				this.uploadHistoryListData = schedulesHistoryResponse.results.map((uploadHistory) => ({
					seq: uploadHistory.seq,
					startDate: uploadHistory.startDate,
					productType: uploadHistory.productType,
					confirmationNumber: this.getConfirmationNumberStyle(uploadHistory.confirmNo),
					status: this.getHistoryIconStyle(uploadHistory.status),
					updateComments: uploadHistory.updateComments,
				}));
				this.totalRows = schedulesHistoryResponse.total;
			}
		}

		this.listLoadingIndicator = false;
	};

	/**
	 * retrieves last used page information for the list
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * loads the translations presented within the list
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_SCHEDULE.UPLOAD_DETAILS.SEQ',
			'T_SCHEDULE.UPLOAD_DETAILS.DATETIME',
			'T_SCHEDULE.UPLOAD_DETAILS.TYPE',
			'T_SCHEDULE.UPLOAD_DETAILS.CONFIRM_NO',
			'T_SCHEDULE.UPLOAD_DETAILS.STATUS',
			'T_SCHEDULE.UPLOAD_DETAILS.COMMENTS',
		]);
	};

	/**
	 * builds the schedule upload history list columns
	 */
	private buildRevisionUploadListColumns = (): void => {
		this.uploadHistoryListColumns = [
			{
				name: 'seq',
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.SEQ'],
				columnType: ColumnType.text,
				width: 80,
			},
			{
				name: 'startDate',
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.DATETIME'],
				columnType: ColumnType.date,
				format: 'MM/dd/YYYY hh:mm a',
			},
			{
				name: 'productType',
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.TYPE'],
				columnType: ColumnType.text,
			},
			{
				name: 'confirmationNumber',
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.CONFIRM_NO'],
				columnType: ColumnType.cssClass,
			},
			{
				name: 'status',
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.STATUS'],
				columnType: ColumnType.icon,
				width: 200,
			},
			{
				name: 'updateComments',
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.COMMENTS'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * maps the column names for sorting puposes needed by the back-end API
	 *
	 * @param listColumnName - column name from the list
	 * @returns equivalent sort by value for the back-end API
	 */
	private mapColumnNames = (listColumnName: string): string => {
		let columnToSortBy: string = listColumnName;

		switch (listColumnName) {
			case 'confirmationNumber':
				columnToSortBy = 'confirmNo';
				break;
			default:
				// Leave as is - same column name
				break;
		}

		return columnToSortBy;
	};

	/**
	 * Retrieves the CSS styling for the confirmation number
	 *
	 * @param confirmationNumber - confirmation number
	 * @returns CSS styling matching the supplied confirmation number
	 */
	private getConfirmationNumberStyle = (confirmationNumber: string): CssClassType => {
		return {
			className: 'columnClass',
			value: confirmationNumber,
			tooltip: confirmationNumber,
		};
	};

	/**
	 * retrieves the Icon details for the schedule status
	 *
	 * @param status - schedule status
	 * @returns  Icon matching the supplied schedule status
	 */
	private getHistoryIconStyle = (status: string): IconType => {
		let iconClass: string = 'nb-icons ';
		let iconName: string = '';

		switch (status) {
			case this.donePreProduction:
				iconClass += 'nb-success-solid icon-green';
				iconName = 'Done:pre-production';
				break;
			case this.doneProductionLive:
				iconClass += 'nb-success-solid icon-darkgreen';
				iconName = 'Done:production(live)';
				break;
			case this.doneErrors:
				iconClass += 'nb-solid-circle-x icon-orange';
				iconName = 'Done:errors';
				break;
			case this.doneFailed:
				iconClass += 'nb-solid-circle-x icon-red';
				iconName = 'Done:failed';
				break;
			case this.doneCancelled:
				iconClass += 'nb-solid-circle-x icon-darkred';
				iconName = 'Done:cancelled';
				break;
		}

		return {
			iconName,
			iconClass,
			iconType: null,
		};
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { StateService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapUtilsService } from '../../../services/map-utils.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { StopsDataService } from '../../../../../support-features/stops/services/stops-data.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapStopsService } from '../../../services/map-stops.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { ListShowingValues, ModeType } from '../../../types/types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';

import { Stop, Stops, StopsPaginatedResponse } from '../../../../../support-features/stops/types/api-types';

import { StopsList, StopsListItem } from '../../../../../support-features/stops/types/types';

import {
	ColumnType,
	Columns,
	ListSortings,
	PageRequestInfo,
	SelectableIconType,
	SelectedRowData,
	SortDirection,
} from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'stops-list',
	templateUrl: './stops-list.component.html',
	styleUrls: ['./stops-list.component.scss'],
})
export class StopsListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'stopCode', dir: SortDirection.asc }];

	public mapNavigateRefresh$Subscription: Subscription = null;

	public initialized: boolean = false;
	public updatedAt: string = null;
	public listName: string = 'stops-list';
	public columns: Columns = [];
	public stopsList: StopsList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo = null;
	public totalRows: number = 0;
	public showingFirst: number = 0;
	public showingLast: number = 0;
	public stopsSearchSuggestions: string[] = [];
	public searchText: string = '';

	private readonly stopSelectedIconClass: string = 'nb-icons nb-view-on';
	private readonly stopNotSelectedIconClass: string = 'nb-icons nb-view-off';

	private readonly stopVisibilityColumnName: string = 'stopVisibility';
	private readonly stopCodeColumnName: string = 'stopCode';
	private readonly stopNameColumnName: string = 'stopName';

	private readonly doubleDash: string = '\u2014';

	private stops: Stops = [];

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private authorityId: string = null;
	private agencyId: string = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private mapUtilsService: MapUtilsService,
		private mapEventsService: MapEventsService,
		private mapNavigationService: MapNavigationService,
		private mapHistoryService: MapHistoryService,
		private mapOptionsService: MapOptionsService,
		private mapStopsService: MapStopsService,
		private stateService: StateService,
		private stopsDataService: StopsDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the stop list
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();
		this.loadCache();
		this.buildListColumns();

		this.getSelectedAgency();

		this.initialized = true;
	}

	/**
	 * refresh the list following a refresh click (from parent component)
	 */
	public refresh = async (): Promise<void> => {
		if (this.initialized) {
			await this.getStops();
		}
	};

	/**
	 * handle the stops data request triggered from the datatable
	 * @param pageInfo - the page details (page number/size and sorting info)
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getStops();
	};

	/**
	 * get the search suggestions based on the search text
	 * @param searchText - test seach text
	 */
	public getSearchSuggestions = async (searchText: string): Promise<void> => {
		const response: ResultContent = await this.stopsDataService.getStopsSuggestions(this.authorityId, this.agencyId, searchText);

		this.stopsSearchSuggestions = [];

		if (response.success) {
			const resultData: StopsPaginatedResponse = response.resultData;

			if (resultData?.results) {
				const stops: Stops = resultData.results;

				// build a list of suggestions
				stops.forEach((stop: Stop) => {
					// Make a displayable suggestion, Double dash used as seperator to avoid single dash confusion within
					// any real stop names during the search part of the process
					const suggestion: string = stop.stop_code + ' ' + this.doubleDash + ' ' + stop.stop_name;

					this.addSearchSuggestion(suggestion);
				});
			}
		}
	};

	/**
	 * handle a search from the user and reload the stops list with the filter in place
	 * @param searchText - the search text entered by the user
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// Because the displayed search suggestion may have a double dash seperator this needs to be removed prior to the actual search

		let stopName: string = null;
		const seperatorPosition: number = searchText.indexOf(this.doubleDash);

		if (seperatorPosition > -1) {
			stopName = searchText.substring(seperatorPosition + this.doubleDash.length).trim();
			this.searchText = stopName;
		}

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getStops();
	};

	/**
	 * determine the stop for a given stop code
	 *
	 * @param stopCode - the stop code to find
	 * @returns the stop
	 */
	public determineStop = (stopCode: string): Stop => {
		return this.stops.find((retrievedStop: Stop) => retrievedStop.stop_code === stopCode);
	};

	/**
	 * handle the select of a row in the stop list and navigate to stop details
	 * @param selectedRow - the selected stop row
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const selectedStop: StopsListItem = selectedRow.row;

		// Lookup the full stop details
		const stop: Stop = this.determineStop(selectedStop.stopCode);

		if (selectedRow.columnNameSelected === this.stopVisibilityColumnName) {
			this.toggleRenderStop(stop);
		} else {
			this.navigateToStopDetails(stop);
		}
	};

	/**
	 * handle the back button click and return to the users previous location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * add the individual search suggestion to the list
	 * @param searchSuggestion - the search suggestion
	 */
	private addSearchSuggestion = (searchSuggestion: string): void => {
		if (!this.stopsSearchSuggestions.includes(searchSuggestion)) {
			this.stopsSearchSuggestions.push(searchSuggestion);
		}
	};

	/**
	 * set up subscriptions for the page
	 *
	 * navigate refresh - handle the click of the parent refresh button and refresh the list
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.refresh();
		});
	};

	/**
	 * get the users current agency
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
		}
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_CODE',
			'T_MAP.MAP_NAME',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP',
		]);
	};

	/**
	 * load any persisted data for the component (list page info and filter search text)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * persist the page settings for the list
	 * @param pageInfo - the page settings
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * persist the search text
	 * @param search - the search text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * map the properties from our camel case list to property name recognised by the nextbus API to prepare for our backend sorting
	 * @param sortByValue - the sort by column name
	 * @returns - the sort by property name recognised by the nextbus API
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case this.stopCodeColumnName:
				columnName = 'stop_code';
				break;
			case this.stopNameColumnName:
				columnName = 'stop_name';
				break;
			default:
				columnName = 'stop_code';
		}

		return columnName;
	}

	/**
	 * determine if the map is in ladder mode
	 * @returns true if the map is in ladder mode
	 */
	private isLadderMode = (): boolean => {
		return this.mapOptionsService.getMode() === ModeType.ladder;
	};

	/**
	 * build the columns for the list
	 */
	private buildListColumns = (): void => {
		// Build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: this.stopVisibilityColumnName,
				displayName: '',
				sortable: false,
				columnType: ColumnType.selectableIcon,
				width: 50,
				hidden: this.isLadderMode, // If the UI is in ladder mode, then this column should be hidden
			},
			{
				name: this.stopCodeColumnName,
				displayName: this.translations['T_MAP.MAP_CODE'],
				columnType: ColumnType.text,
				width: 250,
			},
			{
				name: this.stopNameColumnName,
				displayName: this.translations['T_MAP.MAP_NAME'],
				columnType: ColumnType.text,
				width: 500,
			},
		];
	};

	/**
	 * determine if the stop is displayed on the map
	 *
	 * @param stopCode - the stop code to check
	 * @returns true if the stop is displayed on the map
	 */
	private stopDisplayed = (stopCode: string): boolean => {
		return this.mapStopsService.stopDisplayed(stopCode);
	};

	/**
	 * navigate to the stop details using the supplied stop
	 * @param stop - the stop to navigate to
	 */
	private navigateToStopDetails = async (stop: Stop): Promise<void> => {
		await this.mapNavigationService.navigateToStopDetails(this.authorityId, stop.stop_code);
	};

	/**
	 * toggle the rendering of the stop on the map
	 * @param stop - the stop to render
	 */
	private toggleRenderStop = (stop: Stop): void => {
		if (this.stopDisplayed(stop.stop_code)) {
			this.mapStopsService.removeStop(stop.stop_code);
		} else {
			this.mapStopsService.addStop(stop);
			this.mapStopsService.zoomToStop(stop);
		}
	};

	/**
	 * prepare a function to load the appropriate tooltip for the 'eye' visibility icon in the list
	 * @param stopCode - the stop code
	 * @returns a function to pass in to our datatable to allow this parent to handle the underlying tooltip
	 */
	private getStopIconDynamicTooltip = (stopCode: string): Function => {
		return (): string =>
			this.stopDisplayed(stopCode)
				? this.translations['T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP']
				: this.translations['T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP'];
	};

	/**
	 * prepare a function to load the appropriate styling for the 'eye' visibility icon in the list
	 * @param stopCode - the stop code
	 * @returns a function to pass in to our datatable to allow this parent to handle the underlying styling
	 */
	private getStopVisibilityDynamicState = (stopCode: string): Function => {
		return (): boolean => this.stopDisplayed(stopCode);
	};

	/**
	 * map the stop details to determine the stop list visibility icon style details
	 * @param stop - the stop
	 * @returns the icon styling ready for display
	 */
	private mapStopVisibilityIconValue = (stop: Stop): SelectableIconType => {
		return {
			iconName: this.getStopIconDynamicTooltip(stop.stop_code),
			selectedState: {
				selected: this.getStopVisibilityDynamicState(stop.stop_code),
				selectedIconClass: this.stopSelectedIconClass,
				notSelectedIconClass: this.stopNotSelectedIconClass,
			},
		};
	};

	/**
	 * get the stops from the data layer
	 */
	private getStops = async (): Promise<void> => {
		// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
		this.enableRefresh.emit(false);

		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const response: ResultContent = await this.stopsDataService.getStopsList(
			this.authorityId,
			this.agencyId,
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.mapColumnName(this.pageInfo.sort),
			this.pageInfo.sortDir,
			this.searchText
		);

		if (response.success) {
			const resultData: StopsPaginatedResponse = response.resultData;

			if (resultData?.results) {
				this.stops = response.resultData.results as Stops;

				this.stopsList = this.stops.map((stop: Stop) => ({
					id: stop.stop_id,
					stopVisibility: this.mapStopVisibilityIconValue(stop),
					stopCode: stop.stop_code,
					stopName: stop.stop_name,
				}));

				this.totalRows = resultData.total;

				const listShowingValues: ListShowingValues = this.mapUtilsService.getShowingPageValues(
					+this.pageInfo.pageNum,
					+this.pageInfo.pageSize,
					this.totalRows
				);

				this.showingFirst = listShowingValues.showingFirst;
				this.showingLast = listShowingValues.showingTo;

				this.updatedAt = moment().format('HH:mm:ss');
			}
		}

		this.listLoadingIndicator = false;

		this.enableRefresh.emit(true);
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
	};
}

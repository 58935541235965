import { Injectable } from '@angular/core';

import { LoggerService } from '../logging/logger.service';

import { StorageType } from './types/types';

/**
 * Service responsible for storage interaction.
 */
@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private readonly warningMessage: string = 'Received request to get item from an unknown storage type: ';

	constructor(private logger: LoggerService) {}

	/**
	 * Gets the item from the specified storage space with the specified key.
	 *
	 * @param key - The unique identifier for the stored item
	 * @param storage - The type of storage to retrieve from
	 * @returns The item stored in storage and keyed on key
	 */
	public get(key: string, storage: StorageType): any {
		let item: any = null;

		switch (storage) {
			case StorageType.local: {
				const value: string = localStorage.getItem(key);

				if (value) {
					item = JSON.parse(value);
				}
				break;
			}
			case StorageType.session: {
				const value: string = sessionStorage.getItem(key);

				if (value) {
					item = JSON.parse(value);
				}
				break;
			}
			default: {
				this.logger.logWarn(this.warningMessage + storage);
				break;
			}
		}

		return item;
	}

	/**
	 * Stores the item into the specified storage space with the specified key.
	 *
	 * @param key - The unique identifier for the item to be stored
	 * @param item - The item being stored
	 * @param storage - The type of storage to insert into
	 */
	public set(key: string, item: any, storage: StorageType): void {
		switch (storage) {
			case StorageType.local: {
				localStorage.setItem(key, JSON.stringify(item));
				break;
			}
			case StorageType.session: {
				sessionStorage.setItem(key, JSON.stringify(item));
				break;
			}
			default: {
				this.logger.logWarn(this.warningMessage + storage);
				break;
			}
		}
	}

	/**
	 * Stores the item into the specified storage space with the specified key.
	 *
	 * @param key - The unique identifier for the item to be stored
	 * @param storage - The type of storage to insert into
	 */
	public remove(key: string, storage: StorageType): void {
		switch (storage) {
			case StorageType.local: {
				localStorage.removeItem(key);
				break;
			}
			case StorageType.session: {
				sessionStorage.removeItem(key);
				break;
			}
			default: {
				this.logger.logWarn(this.warningMessage + storage);
				break;
			}
		}
	}

	/**
	 * Removes ALL of the items currently in the local or session storage.
	 *
	 * @param storage - The type of storage to retrieve from
	 */
	public clear(storage: StorageType): void {
		switch (storage) {
			case StorageType.local: {
				localStorage.clear();
				break;
			}
			case StorageType.session: {
				sessionStorage.clear();
				break;
			}
			default: {
				this.logger.logWarn(this.warningMessage + storage);
				break;
			}
		}
	}

	/**
	 * Checks if the supplied item key exists in the specified storage space.
	 *
	 * @param key - The unique identifier for the stored item
	 * @param storage - The type of storage to retrieve from
	 * @returns True if the key is in storage, false otherwise
	 */
	public exists(key: string, storage: StorageType): boolean {
		let exists: boolean = false;

		switch (storage) {
			case StorageType.local: {
				const value: string = localStorage.getItem(key);

				if (value) {
					exists = true;
				}
				break;
			}
			case StorageType.session: {
				const value: string = sessionStorage.getItem(key);

				if (value) {
					exists = true;
				}
				break;
			}
			default: {
				this.logger.logWarn(this.warningMessage + storage);
				break;
			}
		}

		return exists;
	}
}

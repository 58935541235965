/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { SavedReportTemplatesComponent } from './saved-report-templates/saved-report-templates.component';

import { TranslationService } from '@cubicNx/libs/utils';

import { CreateReportTypeDetails, ReportTemplateDetail } from '../types/api-types';
import { CreateReportType } from '../types/types';

@Component({
	selector: 'report-template-selector',
	templateUrl: './report-template-selector.component.html',
	styleUrls: ['./report-template-selector.component.scss'],
})
export class ReportTemplateSelectorComponent extends TranslateBaseComponent {
	@ViewChild('tabs', { static: false }) tabs: MatTabGroup;

	@ViewChild('savedReportTemplates') savedReportTemplatesComponent: SavedReportTemplatesComponent;

	public loading: boolean = false;

	constructor(
		private modalRef: MatDialogRef<ReportTemplateSelectorComponent>,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * closes the selector
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * sets the loading value of the component
	 *
	 * @param loading - true if the component is loading, false otherwise
	 */
	public setLoading = (loading: boolean): void => {
		this.loading = loading;
	};

	/**
	 * changes the tab accordingly within the report template selector
	 *
	 * @param tab -the index of the selected tab
	 */
	public tabChanged = (tab: any): void => {
		this.updateTabControl();

		if (tab.index === 0) {
			// make sure spinner is always disabled for create tab (no back end call)
			this.loading = false;
		} else {
			this.savedReportTemplatesComponent.refresh();
		}
	};

	/**
	 * creates report type details instance (including id of the template and default type) and closes the modal
	 * detailing the instance created
	 *
	 * @param defaultTemplateId - default template id
	 */
	public handleTemplateSelectedFromDefault = (defaultTemplateId: string): void => {
		const createReportType: CreateReportTypeDetails = {
			createReportType: CreateReportType.default,
			defaultTemplateId,
		};

		this.modalRef.close(createReportType);
	};

	/**
	 * creates report type details instance (including template and save type) and closes the modal
	 * detailing the instance created
	 *
	 * @param reportTemplateDetail - the report template details
	 */
	public handleTemplateSelectedFromSaved = (reportTemplateDetail: ReportTemplateDetail): void => {
		const createReportType: CreateReportTypeDetails = {
			createReportType: CreateReportType.saved,
			reportTemplateDetail,
		};

		this.modalRef.close(createReportType);
	};

	/**
	 * Closes the modal
	 */
	public close = (): void => {
		this.modalRef.close(null);
	};

	/**
	 * workaround for bug with angular materials when swapping tabs which introduces a scroll bar
	 * inform the parent we are initialised so the tab size can be reset (note: init events fire too early before scroll bar has rendered)
	 * note: we have to wait 500ms exactly as that is when the animation transition finishes (internal to tab control)
	 */
	private updateTabControl = (): void => {
		setTimeout(() => {
			this.tabs.realignInkBar();
		}, 500);
	};
}

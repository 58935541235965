import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AgencyPortalUtilsModule } from '../../../utils/agency-portal-utils.module';

import { AuthoritiesAdminApiService } from './services/authorities-admin-api.service';
import { AuthoritiesAdminDataService } from './services/authorities-admin-data.service';

import { AuthoritiesAdminListComponent } from './list/authorities-admin-list.component';
import { AuthoritiesAdminViewComponent } from './view/authorities-admin-view.component';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [AuthoritiesAdminListComponent, AuthoritiesAdminViewComponent],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgencyPortalUtilsModule,
	],
	providers: [AuthoritiesAdminDataService, AuthoritiesAdminApiService, provideHttpClient(withInterceptorsFromDi())],
})
export class AdminToolsAuthoritiesModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsConfigService } from '../services/reports-config.service';
import { ReportsDataService } from '../services/reports-data.service';
import { ReportsStateService } from '../services/reports-state.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { TimeHelpers } from '@cubicNx/libs/utils';
import { RoutesDataService } from '../../../support-features/routes/services/routes-data.service';
import { RoutesUtilService } from '../../../support-features/routes/services/routes-util.service';
import { ReportsUtilService } from '../services/reports-util.service';
import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { BlocksDataService } from '../../../support-features/blocks/services/block-data.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { ReportsBlockSelectionDataService } from '../services/reports-block-selection-data.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ValidationText, maxlengthValidation, patternInvalidValue } from '@cubicNx/libs/utils';
import { BlockSummary, BlockSummaries } from '../../../support-features/blocks/types/api-types';
import { VehicleSummary, VehicleSummaries } from '../../../support-features/vehicles/types/api-types';
import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { Routes, Route } from '../../../support-features/routes/types/api-types';
import { UserLogin } from '../../../support-features/login/types/api-types';
import { SliderConfig } from '@cubicNx/libs/utils';
import { TimeUpdated } from '@cubicNx/libs/utils';
import { Agencies, Agency } from '../../../support-features/agencies/types/api-types';

import { Columns, ColumnType, CssClassType, PageRequestInfo, SortDirection, SortRequestInfo } from '@cubicNx/libs/utils';

import {
	ReportConfiguration,
	CreateEditType,
	EffectiveDateRangeWeekType,
	EffectiveDateRangeDayType,
	EffectiveDateRangeMonthType,
	EffectiveDateRangeType,
	EffectiveDateRangeDayNameType,
	ReportSaveType,
	AgencyReportCustomization,
	EntitySelectionType,
	SortType,
	MultiSelectionType,
	BlockSelectionList,
	BlockSelectionListItem,
	SpeedType,
} from '../types/types';

import {
	ReportTemplateDetail,
	ReportOptions,
	ReportDateOptions,
	ReportOptionsAdherenceThresholdSettings,
	ReportOptionsAgencies,
	ReportOptionsAuthority,
	ReportRoute,
	ReportRoutes,
	ReportVehicle,
	ReportVehicles,
	ReportBlock,
	ReportBlocks,
	OptionalCustomDateEffectiveDateDetails,
	OptionalCustomDateEffectiveTimeEnabled,
	OptionalCustomDateRangeCount,
	OptionalFilterArrivalStops,
	OptionalAdherenceThresholdOverride,
	OptionalAdherenceSettingsOverride,
	OptionalIncludeAllRoutes,
	OptionalIncludeAllVehicles,
	OptionalBlockSelection,
	OptionalReportRoutes,
	OptionalReportVehicles,
	OptionalDaySpecificDate,
	OptionalDateOptionsDay,
	OptionalDateOptionsWeek,
	OptionalDateOptionsMonth,
	OptionalDateOptionsDayName,
	OptionalReportBlocks,
	OptionalFullUnits,
	OptionalScheduleSortSelection,
	OptionalSpeedSelection,
	ReportSelectionBlock,
	ReportSelectionBlocks,
} from '../types/api-types';

import moment, { Moment } from 'moment';

@Component({
	selector: 'report-template-edit',
	templateUrl: './report-template-edit.component.html',
	styleUrls: ['./report-template-edit.component.scss'],
})
export class ReportTemplateEditComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild('modalContent') private scrollContainer: ElementRef;

	public readonly nameMaxLength: number = 200;

	public routeSettings: MultiSelectSettings = {
		id_text: 'route_id',
		value_text: 'route_display_name',
		placeholder: null,
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
		optionStyles: {
			// apply the italic style under listed conditions
			style: { 'font-style': 'italic' },
			fieldComparisonOrOperator: true,
			fields: [
				{ name: 'is_predict_enabled', value: 'false' },
				{ name: 'is_hidden', value: 'true' },
				{ name: 'is_enabled', value: 'false' },
			],
		},
	};

	public vehicleSettings: MultiSelectSettings = {
		id_text: 'vehicle_id',
		value_text: 'vehicle_id',
		placeholder: null,
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public blockSettings: MultiSelectSettings = {
		id_text: 'block_id',
		value_text: 'block_id',
		placeholder: null,
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	// make enums accessible in html
	public createEditTypeType: typeof CreateEditType = CreateEditType;
	public entitySelectionType: typeof EntitySelectionType = EntitySelectionType;
	public effectiveDateRangeType: typeof EffectiveDateRangeType = EffectiveDateRangeType;
	public effectiveDateRangeWeekType: typeof EffectiveDateRangeWeekType = EffectiveDateRangeWeekType;
	public effectiveDateRangeDayType: typeof EffectiveDateRangeDayType = EffectiveDateRangeDayType;
	public effectiveDateRangeMonthType: typeof EffectiveDateRangeMonthType = EffectiveDateRangeMonthType;
	public effectiveDateRangeDayNameType: typeof EffectiveDateRangeDayNameType = EffectiveDateRangeDayNameType;
	public sortType: typeof SortType = SortType;
	public speedType: typeof SpeedType = SpeedType;
	public multiSelectionType: typeof MultiSelectionType = MultiSelectionType;
	public initialDateRangeDayType: typeof EffectiveDateRangeType = EffectiveDateRangeType;

	public readonly earlyLateSliderConfig: SliderConfig = {
		rangeMultiplier: 60,
	};

	public routes: ReportRoutes = [];
	public vehicles: ReportVehicles = [];
	public blocks: ReportBlocks = [];
	public reportForm: FormGroup = null;
	public modalTitle: string = null;
	public loading: boolean = true;

	public listName: string = 'blocks-selection-list';
	public sortInfo: SortRequestInfo = { sort: 'blockId', sortDir: SortDirection.asc };
	public blocksSelectionListColumns: Columns = [];
	public blockSelectionList: BlockSelectionList = [];
	public selectedBlocks: BlockSelectionList = [];
	public agencyTimezoneOffset: string = null;
	public blockListLoadingIndicator: boolean = true;
	public blockListInitialized: boolean = false;
	public blocksLoadingText: string = null;

	public reportConfig: ReportConfiguration = null;
	public agencies: Agencies = null;
	public earlySliderValues: Array<number> = [];
	public lateSliderValues: Array<number> = [];

	// custom date range
	public customDateTimeErrorText: string = null;
	public hasCustomDateTimeError: boolean = false;

	public effectiveTimeErrorText: string = null;
	public hasEffectiveTimeError: boolean = false;

	public specificDateErrorText: string = null;
	public hasSpecificDateError: boolean = false;

	public maxDate: Date = null;
	public nameValidationTextOverride: ValidationText = null;
	public maxLengthAdditionalMessageText: ValidationText = null;
	public maxFieldLength: number = 200;

	private readonly nameAllowedPattern: RegExp = /^[A-Za-z0-9-_, ]+$/;

	private lastRequestId: number = 0;

	private createEditType: CreateEditType = CreateEditType.create;
	private defaultDate: Date = null;

	private selectedAgency: Agency = null;
	private currentUser: UserLogin = null;
	private savedReportTemplate: ReportTemplateDetail = null;
	private defaultTemplateId: string = null;
	private reportCustomization: AgencyReportCustomization = null;

	constructor(
		private formBuilder: FormBuilder,
		private reportsConfigService: ReportsConfigService,
		private reportsDataService: ReportsDataService,
		private routesUtilService: RoutesUtilService,
		private reportsUtilService: ReportsUtilService,
		private reportsBlockSelectionService: ReportsBlockSelectionDataService,
		private currentUserUtilService: CurrentUserUtilService,
		private agenciesDataService: AgenciesDataService,
		private routesDataService: RoutesDataService,
		private vehiclesDataService: VehiclesDataService,
		private blocksDataService: BlocksDataService,
		private reportsStateService: ReportsStateService,
		private loggerService: LoggerService,
		private notificationService: NotificationService,
		private changeDetectorRef: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private modalRef: MatDialogRef<ReportTemplateEditComponent>,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;

		this.nameValidationTextOverride = {
			[patternInvalidValue]: 'T_CORE.SPL_CHAR_NOT_ALLOWED',
		};

		this.maxLengthAdditionalMessageText = {
			[maxlengthValidation]: this.maxFieldLength.toString(),
		};
	}

	/**
	 * closes the report template edit dialog
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close(null);
	}

	/**
	 * initializes the modal - handling any passed in properties
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		// grab the modal data passed in
		this.createEditType = this.data['createEditType'];

		this.defaultTemplateId = this.data['defaultTemplateId'];
		this.savedReportTemplate = this.data['savedReportTemplate'];

		this.modalTitle = this.getTitle(this.data['modalTitle']);

		this.agencies = this.agenciesDataService.getAgencies();

		this.currentUser = this.currentUserUtilService.getCurrentUser();

		this.reportConfig = this.reportsConfigService.getReportConfiguration(this.defaultTemplateId);

		this.maxDate = new Date();

		if (this.reportConfig.dateOptions.allowFutureDates) {
			// allow future dates - doesn't seem to be a limit with the original code - setting to a large value
			this.maxDate.setFullYear(this.maxDate.getFullYear() + 100);
		}

		// default specific date to 'now'
		this.defaultDate = TimeHelpers.createNewDateOnlyFromMoment(moment());

		this.selectedAgency = this.agenciesDataService.getDefaultAgency();

		if (this.reportConfig.reportOptions.adherenceThreshold.enabled) {
			this.initAdherenceSettings(
				this.selectedAgency.adherence_setting_early_min_sec,
				this.selectedAgency.adherence_setting_very_early_sec,
				this.selectedAgency.adherence_setting_late_min_sec,
				this.selectedAgency.adherence_setting_very_late_sec
			);
		}

		switch (this.createEditType) {
			case CreateEditType.create:
				// returns default (override for agency) name/description
				this.reportCustomization = this.reportsUtilService.getAgencyReportCustomization(this.defaultTemplateId);
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				// return agency based on nb_id rather than agency_nb_id as we do with other features as for some reason it's the nb_id
				// that is saved against a report/template
				// this could be changed but we have to consider reports/template already on site. Typically the 2 id's are the same
				// anyway but noted they are different
				// for SF Muni CIS (potentially others)
				this.selectedAgency = this.agenciesDataService.getAgencyByNbId(this.savedReportTemplate.report_options.agencies.nb_id);

				if (this.reportConfig.reportOptions.adherenceThreshold.enabled) {
					//if adherence_threshold_settings checkbox was already enabled in edit mode
					if (this.savedReportTemplate.report_options.adherence_threshold_override === 'true') {
						// override with saved adherence settings
						this.initAdherenceSettings(
							this.savedReportTemplate.report_options.adherence_threshold_settings.adherence_setting_early_min_sec,
							this.savedReportTemplate.report_options.adherence_threshold_settings.adherence_setting_very_early_sec,
							this.savedReportTemplate.report_options.adherence_threshold_settings.adherence_setting_late_min_sec,
							this.savedReportTemplate.report_options.adherence_threshold_settings.adherence_setting_very_late_sec
						);
					}
				}
				break;
		}

		this.buildReportForm();

		this.initReportValidation();

		if (this.reportConfig.reportOptions.routes.enabled) {
			await this.loadRouteData(this.selectedAgency.authority_id, this.selectedAgency.agency_id);
		}

		if (this.reportConfig.reportOptions.vehicles.enabled) {
			await this.loadVehicleData(this.selectedAgency.authority_id);
		}

		if (this.reportConfig.reportOptions.blocks.enabled) {
			switch (this.reportConfig.reportOptions.blocks.multiSelectionType) {
				case MultiSelectionType.select:
					await this.loadBlockData(this.selectedAgency.authority_id, this.selectedAgency.agency_id);

					this.loading = false;
					break;
				case MultiSelectionType.list:
					// turn off loading spinner before retrieving list as that has it's own loading indicator
					this.loading = false;

					// setup the list as it was in edit mode - otherwise we don't get the block list until a day type has been selected
					if (this.createEditType === CreateEditType.edit || this.createEditType === CreateEditType.editTemplate) {
						// if the report is in list mode get the block data (based on current day selection) then
						// establish which blocks were selected
						await this.getBlockList();

						if (this.reportForm.controls.selectedBlocks.value) {
							this.selectedBlocks = this.reportForm.controls.selectedBlocks.value.map((block: ReportBlock) =>
								this.blockSelectionList.find((selectionBlocks) => selectionBlocks.blockId === block.block_id)
							);
						}
					}
					break;
			}
		} else {
			this.loading = false;
		}

		this.initMultiSelectSettings();

		this.changeDetectorRef.detectChanges();
	}

	/**
	 * sets the approprate control valid/invalid based on child component updates
	 *
	 * @param valid - the state of the control - true = valid
	 * @param controlName - the name of the control
	 */
	public setTimeValid = (valid: boolean, controlName: string): void => {
		if (valid) {
			this.reportForm.controls[controlName].setErrors(null);
			this.reportForm.controls[controlName].updateValueAndValidity();
		} else {
			this.reportForm.controls[controlName].setErrors({ invalid: true });
		}

		switch (controlName) {
			case 'customStartTime':
			case 'customEndTime':
				this.checkCustomDateTimes();
				this.hasCustomDateTimeError = this.getCustomDateTimeErrorText();
				break;
			case 'effectiveStartTime':
			case 'effectiveEndTime':
				this.checkEffectiveTimes();
				this.hasEffectiveTimeError = this.getEffectiveTimeErrorText();
				break;
		}
	};

	/**
	 * maintains the approriate time from the time control in our form object
	 *
	 * @param timeUpdated - the time to update the control with (HH:MM)
	 * @param controlName - the name of the control
	 */
	public setUpdatedTime = (timeUpdated: TimeUpdated, controlName: string): void => {
		this.reportForm.controls[controlName].setValue(timeUpdated.time);
	};

	/**
	 * maintains the approriate custom date and checks for errors
	 */
	public setUpdatedCustomDate = (): void => {
		this.checkCustomDateTimes();

		this.hasCustomDateTimeError = this.getCustomDateTimeErrorText();
	};

	/**
	 * maintains the approriate specific date from the date control in our form object
	 *
	 */
	public setUpdatedSpecificDate = async (): Promise<void> => {
		this.hasSpecificDateError = this.getSpecificDateErrorText();

		if (this.hasSpecificDateError) {
			this.blockListInitialized = false;
		} else {
			if (
				this.reportConfig.reportOptions.blocks.enabled &&
				this.reportConfig.reportOptions.blocks.multiSelectionType === MultiSelectionType.list
			) {
				await this.getBlockList();
			}
		}
	};

	/**
	 * handles the change the date range type (reset noOfUnits count as we change date range type to day/week/month
	 * so it doesn't retain an old and no longer valid value)
	 */
	public effectiveDateRangeTypeChange = (): void => {
		this.reportForm.controls.noOfUnitsCount.setValue(1);

		// reset values. Ensures when user sets back to 'custom' that correct validation is set
		this.reportForm.controls.effectiveDateRangeDayType.setValue(EffectiveDateRangeDayType.today);
		this.reportForm.controls.effectiveDateRangeWeekType.setValue(EffectiveDateRangeWeekType.thisWeek);
		this.reportForm.controls.effectiveDateRangeMonthType.setValue(EffectiveDateRangeMonthType.thisMonth);
	};

	/**
	 * handles the change to the effective date range day type
	 */
	public effectiveDateRangeDayTypeChange = async (): Promise<void> => {
		if (
			this.reportConfig.reportOptions.blocks.enabled &&
			this.reportConfig.reportOptions.blocks.multiSelectionType === MultiSelectionType.list
		) {
			switch (this.reportForm.controls.effectiveDateRangeDayType.value) {
				case EffectiveDateRangeDayType.today:
				case EffectiveDateRangeDayType.yesterday:
					await this.getBlockList();
					break;
				case EffectiveDateRangeDayType.specific:
					if (!this.hasSpecificDateError && this.reportForm.controls.specificDate.value !== null) {
						await this.getBlockList();
					} else {
						this.blockListInitialized = false;
					}
					break;
			}
		}
	};

	/**
	 * gets the max no of units count dependent on selected effective date range type
	 *
	 * @returns the maximum number of units dependent upon the date range
	 */
	public getMaxNoOfUnitCount = (): number => {
		let maxCount: number = 62;

		switch (this.reportForm.controls.effectiveDateRangeType.value) {
			case EffectiveDateRangeType.day:
				maxCount = 62;
				break;
			case EffectiveDateRangeType.week:
				maxCount = 8;
				break;
			case EffectiveDateRangeType.month:
				maxCount = 2;
				break;
		}

		return maxCount;
	};

	/**
	 * gets number of units translation
	 *
	 * @param effectiveDateRangeType - the date range type value i.e day
	 * @returns the number of units translation
	 */
	public getNumberOfUnitsTranslation = (effectiveDateRangeType: EffectiveDateRangeType): string => {
		let translation: string = null;

		switch (effectiveDateRangeType) {
			case EffectiveDateRangeType.day:
				translation = this.translations['T_REPORT.DAY.NUMBER_OF_UNITS'];
				break;
			case EffectiveDateRangeType.week:
				translation = this.translations['T_REPORT.WEEK.NUMBER_OF_UNITS'];
				break;
			case EffectiveDateRangeType.month:
				translation = this.translations['T_REPORT.MONTH.NUMBER_OF_UNITS'];
				break;
		}

		return translation;
	};

	/**
	 * gets full units only translation
	 *
	 * @param effectiveDateRangeType - the date range type value i.e day
	 * @returns the full units only translation
	 */
	public getFullUnitsOnlyTranslation = (effectiveDateRangeType: EffectiveDateRangeType): string => {
		let translation: string = null;

		switch (effectiveDateRangeType) {
			case EffectiveDateRangeType.day:
				translation = this.translations['T_REPORT.DAY.FULL_UNITS_ONLY'];
				break;
			case EffectiveDateRangeType.week:
				translation = this.translations['T_REPORT.WEEK.FULL_UNITS_ONLY'];
				break;
			case EffectiveDateRangeType.month:
				translation = this.translations['T_REPORT.MONTH.FULL_UNITS_ONLY'];
				break;
		}

		return translation;
	};

	/**
	 * gets full units only description translation
	 *
	 * @param effectiveDateRangeType - the date range type value i.e day
	 * @returns the full units only translation
	 */
	public getFullUnitsOnlyDescriptionTranslation = (effectiveDateRangeType: EffectiveDateRangeType): string => {
		let translation: string = null;

		switch (effectiveDateRangeType) {
			case EffectiveDateRangeType.day:
				translation = this.translations['T_REPORT.DAY.FULL_UNITS_ONLY_DESCRIPTION'];
				break;
			case EffectiveDateRangeType.week:
				translation = this.translations['T_REPORT.WEEK.FULL_UNITS_ONLY_DESCRIPTION'];
				break;
			case EffectiveDateRangeType.month:
				translation = this.translations['T_REPORT.MONTH.FULL_UNITS_ONLY_DESCRIPTION'];
				break;
		}

		return translation;
	};

	/**
	 * Sets up the selected Agency value when the chosen agency changes
	 * ** loads any route data and resets the form when applicable
	 * ** sets the initial adherence settings to the default agency values
	 */
	public agencyChanged = async (): Promise<void> => {
		this.loading = true;

		// return agency based on nb_id rather than agency_nb_id as we do with other features as for some reason it's the nb_id
		// that is saved against a report/template
		// this could be changed but we have to consider reports/template already on site. Typically the 2 id's are the same
		// anyway but noted they are different
		// for SF Muni CIS (potentially others)
		this.selectedAgency = this.agenciesDataService.getAgencyByNbId(this.reportForm.controls.agencyId.value);

		if (this.reportConfig.reportOptions.routes.enabled) {
			await this.loadRouteData(this.selectedAgency.authority_id, this.selectedAgency.agency_id);
			this.reportForm.controls.selectedRoutes.setValue([]);
		}

		if (this.reportConfig.reportOptions.vehicles.enabled) {
			await this.loadVehicleData(this.selectedAgency.authority_id);
			this.reportForm.controls.selectedVehicles.setValue([]);
		}

		if (this.reportConfig.reportOptions.blocks.enabled) {
			if (this.reportConfig.reportOptions.blocks.multiSelectionType === MultiSelectionType.select) {
				await this.loadBlockData(this.selectedAgency.authority_id, this.selectedAgency.agency_id);
			}

			this.reportForm.controls.selectedBlocks.setValue([]);
			this.reportForm.controls.effectiveDateRangeDayType.setValue(this.reportConfig.dateOptions.initialDateRangeDayType);
			this.blockListInitialized = false;
			this.selectedBlocks = [];
		}

		// reset agency specifc adherence settings
		if (this.reportConfig.reportOptions.adherenceThreshold.enabled) {
			this.reportForm.controls.adherenceThresholdOverride.setValue(false);
			this.initAdherenceSettings(
				this.selectedAgency.adherence_setting_early_min_sec,
				this.selectedAgency.adherence_setting_very_early_sec,
				this.selectedAgency.adherence_setting_late_min_sec,
				this.selectedAgency.adherence_setting_very_late_sec
			);
		}

		this.loading = false;
	};

	/**
	 * triggers a refresh of the form validation once a specific route has been added or removed.
	 * This is a manual process as when the route multi select control is updated. It doesn't automatically
	 * update the form validation (probably as the selected route multi control isn't setup with the typical formControlName)
	 */
	public routeItemUpdated = (): void => {
		this.reportForm.controls.selectedRoutes.updateValueAndValidity();
	};

	/**
	 * triggers a refresh of the form validation once a specific vehicle has been added or removed.
	 * This is a manual process as when the vehicle multi select control is updated. It doesn't automatically
	 * update the form validation (probably as the selected vehicle multi control isn't setup with the typical formControlName)
	 */
	public vehicleItemUpdated = (): void => {
		this.reportForm.controls.selectedVehicles.updateValueAndValidity();
	};

	/**
	 * triggers a refresh of the form validation once a specific block has been added or removed.
	 * This is a manual process as when the block multi select control is updated. It doesn't automatically
	 * update the form validation (probably as the selected block multi control isn't setup with the typical formControlName)
	 */
	public blockItemUpdated = (): void => {
		this.reportForm.controls.selectedBlocks.updateValueAndValidity();
	};

	/**
	 * sorts the relevant list selections that can be present within the edit dialog such as blocks etc.
	 *
	 * @param sortRequest - the srot request details
	 */
	public handleSortRequest = (sortRequest: PageRequestInfo): void => {
		this.sortInfo.sort = sortRequest.sort;
		this.sortInfo.sortDir = sortRequest.sortDir;
		this.sortList();
	};

	/**
	 * triggrrs when a block has been selected in the list and updates our form control
	 * Also maintains our list of currently selected blocks
	 *
	 * @param blocks - the selected blocks
	 */
	public onBlockListCheckSelect = (blocks: BlockSelectionList): void => {
		this.selectedBlocks = blocks;

		const selectedBlocksRaw: ReportBlocks = blocks.map((block: BlockSelectionListItem) => ({
			block_id: block.blockId,
		}));

		this.reportForm.controls.selectedBlocks.setValue(selectedBlocksRaw);

		this.reportForm.controls.selectedBlocks.updateValueAndValidity();
	};

	/**
	 * Fires when the adherence threshold override checkbox is changed and ensures when the checkbox is unselected, the
	 * adherence settings are reset to the default values of the currently selected agency
	 */
	public adherenceThresholdOverrideUpdated = (): void => {
		if (!this.reportForm.controls.adherenceThresholdOverride.value) {
			this.initAdherenceSettings(
				this.selectedAgency.adherence_setting_early_min_sec,
				this.selectedAgency.adherence_setting_very_early_sec,
				this.selectedAgency.adherence_setting_late_min_sec,
				this.selectedAgency.adherence_setting_very_late_sec
			);
		}
	};

	/**
	 * Handler for the run button - prepares and makes a request to the backend to run the report
	 */
	public handleRunReport = async (): Promise<void> => {
		this.loading = true;

		await this.runReport();

		this.loading = false;

		this.close(ReportSaveType.run);
	};

	/**
	 * Handler for the save button - prepares and makes a request to the backend to save the report
	 */
	public handleSaveReport = async (): Promise<void> => {
		this.loading = true;

		await this.saveReport();

		this.loading = false;

		this.close(ReportSaveType.save);
	};

	/**
	 * Handler for the save and run button - prepares and makes a request to the backend to save and run the report
	 */
	public handleSaveAndRunReport = async (): Promise<void> => {
		this.loading = true;

		await this.runReport();

		await this.saveReport();

		this.loading = false;

		this.close(ReportSaveType.saveAndRun);
	};

	/**
	 * Closes the modal
	 *
	 * @param reportSaveType - how the modal was saved
	 */
	public close = (reportSaveType: ReportSaveType): void => {
		this.modalRef.close(reportSaveType);
	};

	/**
	 * Determines whether the 'action' buttons should be enabled i.e save, run report etc
	 *
	 * @returns true if the 'action' buttons should be enabled, false otherwise
	 */
	public enableAction = (): boolean => {
		return !this.loading && this.reportForm.valid;
	};

	/**
	 * retrieves a list of blocks
	 */
	public getBlockList = async (): Promise<void> => {
		this.blockSelectionList = [];

		const agencyTimezone: string = this.agenciesDataService.getAgencyTimezone(
			this.selectedAgency.authority_id,
			this.selectedAgency.agency_id
		);

		this.agencyTimezoneOffset = this.reportsUtilService.formatTimezoneOffset(moment.tz(agencyTimezone).utcOffset());

		this.blocksLoadingText = this.getBlocksLoadingText();

		this.buildListColumns();

		const reportTemplate: ReportTemplateDetail = this.buildReportTemplate();

		this.blockListLoadingIndicator = true;

		this.lastRequestId++;

		const response: ResultContent = await this.reportsBlockSelectionService.getBlocks(
			this.reportConfig.reportOptions.blocks.endpoint,
			reportTemplate,
			this.lastRequestId
		);

		if (response.success) {
			if (response.resultData.lastRequestId === this.lastRequestId) {
				this.loggerService.logDebug('processing last request id: ', response.resultData.lastRequestId);

				const reportSelectionBlocks: ReportSelectionBlocks = response.resultData.response;

				this.blockSelectionList = reportSelectionBlocks.map((reportSelectionBlock: ReportSelectionBlock) => ({
					id: reportSelectionBlock.seq,
					blockId: reportSelectionBlock.block_id,
					vehicleId: reportSelectionBlock.vehicle_id,
					scheduledStartTime: this.getFormattedDateTime(reportSelectionBlock.scheduled_start_time),
					timeOfAssignment: this.getFormattedDateTime(reportSelectionBlock.time_of_assignment),
					assignmentDifference: this.getAssignmentDifferenceDisplay(
						this.getBlockTimeDifference(reportSelectionBlock.assignment_difference)
					),
					assignmentDifferenceRaw: this.getRawAssignmentDifference(reportSelectionBlock.assignment_difference),
					scheduledEndTime: this.getFormattedTime(reportSelectionBlock.scheduled_end_time),
					status: this.getStatusDisplay(reportSelectionBlock.status),
				}));

				this.blockListLoadingIndicator = false;

				// the is a hack to wait for the data to be rendered. Ideally we need create an event that is triggered when data
				// is rendered then scroll to the bottom or perhaps trigger angular change detection here then scroll
				setTimeout(() => {
					this.scrollToBottom();
				}, 1);
			} else {
				this.loggerService.logDebug('ignoring last request id: ', response.resultData.lastRequestId);
			}
		} else {
			this.blockListLoadingIndicator = false;
		}
	};

	/**
	 * formats the title
	 *
	 * @param defaultTitle - the default titile
	 * @returns the formatted (translated) title
	 */
	private getTitle = (defaultTitle: string): string => {
		let title: string = this.translations[defaultTitle];

		if (this.createEditType === CreateEditType.editTemplate && this.savedReportTemplate && this.savedReportTemplate.updated_at) {
			const datepipe: DatePipe = new DatePipe('en-US');

			const formattedDate: string = datepipe.transform(this.savedReportTemplate.updated_at, 'dd-MMM-YYYY HH:mm:ss');

			title += ' (' + this.translations['T_REPORT.DATE_LAST_MODIFIED'] + ': ' + formattedDate + ')';
		}

		return title;
	};

	/**
	 * Checks the start date/time is greater than the end time. Only bother checking if no
	 * other errors. if there are this will invalidate the form anyway. If there isn't we know
	 * we have a date and a time to make the check simpler.
	 */
	private checkCustomDateTimes = (): void => {
		// clear the custom error first then re-check
		this.reportForm.controls.customStartDate.updateValueAndValidity();

		if (
			this.reportForm.controls.customStartDate.valid &&
			this.reportForm.controls.customEndDate.valid &&
			this.reportForm.controls.customStartTime.valid &&
			this.reportForm.controls.customEndTime.valid
		) {
			const startDateMoment: Moment = moment(this.reportForm.controls.customStartDate.value);
			const endDateMoment: Moment = moment(this.reportForm.controls.customEndDate.value);

			const startDateTime: Moment = this.setDateTime(startDateMoment, this.reportForm.controls.customStartTime.value);
			const endDateTime: Moment = this.setDateTime(endDateMoment, this.reportForm.controls.customEndTime.value);

			if (startDateTime.valueOf() >= endDateTime.valueOf()) {
				this.reportForm.controls.customStartDate.setErrors({ 'start-time-greater': true });
			}
		}
	};

	/**
	 * determines if we have an error related to the custom dates and
	 * builds up and error string based on the dates entered by the user
	 *
	 * @returns a flag inidicating if an error text was set
	 */
	private getCustomDateTimeErrorText = (): boolean => {
		let hasDateError: boolean = false;

		this.customDateTimeErrorText = '';

		// check the date has been updated before checking errors. Then check null value which covers an actual null
		// but also anything entered that isn't a valid date
		if (this.reportForm.controls.customStartDate.hasError('required')) {
			hasDateError = true;
			this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.REQUIRED'];
		}

		if (this.reportForm.controls.customStartDate.hasError('matDatepickerParse')) {
			hasDateError = true;
			this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_TIME_START'];
		}

		if (this.reportForm.controls.customStartTime.hasError('invalid')) {
			hasDateError = true;
			this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_TIME_START'];
		}

		if (this.reportForm.controls.customEndDate.hasError('required')) {
			hasDateError = true;
			this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.REQUIRED'];
		}

		if (this.reportForm.controls.customEndDate.hasError('matDatepickerParse')) {
			hasDateError = true;
			this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_TIME_START'];
		}

		if (this.reportForm.controls.customEndTime.hasError('invalid')) {
			hasDateError = true;
			this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_TIME_END'];
		}

		// we have 2 valid dates - now check for that they are not in the past and the start date is before the end date
		if (!hasDateError) {
			// this property only exists if the min date requirment doesnt pass
			if (this.reportForm.controls.customStartDate.hasError('matDatepickerMax')) {
				hasDateError = true;
				this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_TIME_START_FUTURE'];
			}

			if (this.reportForm.controls.customEndDate.hasError('matDatepickerMax')) {
				hasDateError = true;
				this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_TIME_END_FUTURE'];
			}

			if (this.reportForm.controls.customStartDate.hasError('start-time-greater')) {
				hasDateError = true;
				this.customDateTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATERANGE_END'];
			}
		}

		return hasDateError;
	};

	/**
	 * determines if we have an error related to the specific date and
	 * builds up and error string based on the date entered by the user
	 *
	 * @returns a flag inidicating if an error text was set
	 */
	private getSpecificDateErrorText = (): boolean => {
		let hasDateError: boolean = false;

		this.specificDateErrorText = '';

		// check the date has been updated before checking errors. Then check null value which covers an actual null
		// but also anything entered that isn't a valid date
		if (this.reportForm.controls.specificDate.hasError('required')) {
			hasDateError = true;
			this.specificDateErrorText = this.translations['T_CORE.FORM.ERRORS.REQUIRED'];
		}

		if (
			this.reportForm.controls.specificDate.hasError('invalid') ||
			this.reportForm.controls.specificDate.hasError('matDatepickerParse')
		) {
			hasDateError = true;
			this.specificDateErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_START'];
		}

		// we have 2 valid dates - now check for that they are not in the past and the start date is before the end date
		if (!hasDateError) {
			// this property only exists if the min date requirment doesnt pass
			if (this.reportForm.controls.specificDate.hasError('matDatepickerMax')) {
				hasDateError = true;
				this.specificDateErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_START_FUTURE'];
			}
		}

		return hasDateError;
	};

	/**
	 * Checks the start time is greater than the end time. Only bother checking if no
	 * other errors exist. if there are this will invalidate the form anyway. If there isn't we know
	 * we have a time to make the check simpler.
	 */
	private checkEffectiveTimes = (): void => {
		// check the custome 'invalid' error and if we get past that, set or clear the start-time-greater custom error
		if (
			!this.reportForm.controls.effectiveStartTime.hasError('invalid') &&
			!this.reportForm.controls.effectiveEndTime.hasError('invalid')
		) {
			const startDate: Moment = moment().startOf('day');
			const endDate: Moment = moment().startOf('day');

			const startDateTime: Moment = this.setDateTime(startDate, this.reportForm.controls.effectiveStartTime.value);
			const endDateTime: Moment = this.setDateTime(endDate, this.reportForm.controls.effectiveEndTime.value);

			if (startDateTime.valueOf() >= endDateTime.valueOf()) {
				this.reportForm.controls.effectiveStartTime.setErrors({ 'start-time-greater': true });
			} else {
				this.reportForm.controls.effectiveStartTime.setErrors(null);
				this.reportForm.controls.effectiveStartTime.updateValueAndValidity();
			}
		}
	};

	/**
	 * determines if we have an error related to the effectiev times and
	 * builds up an error string based on the times entered by the user
	 *
	 * @returns a flag inidicating if an error text was set
	 */
	private getEffectiveTimeErrorText = (): boolean => {
		let hasDateError: boolean = false;

		this.effectiveTimeErrorText = '';

		// check the date has been updated before checking errors. Then check null value which covers an actual null
		// but also anything entered that isn't a valid date
		if (
			this.reportForm.controls.effectiveStartTime.hasError('required') ||
			this.reportForm.controls.effectiveStartTime.hasError('invalid')
		) {
			hasDateError = true;
			this.effectiveTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIME_START'];
		}

		if (
			this.reportForm.controls.effectiveEndTime.hasError('required') ||
			this.reportForm.controls.effectiveEndTime.hasError('invalid')
		) {
			hasDateError = true;
			this.effectiveTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIME_END'];
		}

		// we have 2 valid times - now check for that start time is before the end time
		if (!hasDateError) {
			if (this.reportForm.controls.effectiveStartTime.hasError('start-time-greater')) {
				hasDateError = true;
				this.effectiveTimeErrorText = this.translations['T_CORE.FORM.ERRORS.DATERANGE_END'];
			}
		}

		return hasDateError;
	};

	/**
	 * Makes a request to the run the report
	 */
	private runReport = async (): Promise<void> => {
		const reportTemplate: ReportTemplateDetail = this.buildReportTemplate();

		await this.reportsDataService.runReport(reportTemplate);
	};

	/**
	 * Prepares and makes a request to the backend to save the report
	 *
	 * @param reportTemplate - the report template to save
	 */
	private saveReport = async (): Promise<void> => {
		const reportTemplate: ReportTemplateDetail = this.buildReportTemplate();

		const authorityId: string = reportTemplate.report_options.authority.authority_id;
		const agencyId: string = reportTemplate.report_options.agencies.agency_id;

		if (this.createEditType === CreateEditType.editTemplate) {
			if (this.reportsStateService.canSaveReportTemplate(authorityId, agencyId)) {
				// add in the original Id and update
				reportTemplate.nb_id = this.savedReportTemplate.nb_id;
				await this.reportsDataService.updateReportTemplate(reportTemplate);
			} else {
				this.notificationService.notifyError(this.translations['T_CORE.NO_PERMISSION']);
			}
		} else {
			await this.reportsDataService.createReportTemplate(reportTemplate);
		}
	};

	/**
	 * Loads any translations reqquired by the modal
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.SELECT_ROUTE',
			'T_CORE.SELECT_ROUTES',
			'T_CORE.SELECT_VEHICLES',
			'T_CORE.SELECT_VEHICLE',
			'T_CORE.SELECT_BLOCKS',
			'T_CORE.SELECT_BLOCK',
			'T_CORE.FORM.ERRORS.REQUIRED',
			'T_CORE.FORM.ERRORS.DATERANGE_END',
			'T_CORE.FORM.ERRORS.DATE_START',
			'T_CORE.FORM.ERRORS.DATE_TIME_START',
			'T_CORE.FORM.ERRORS.DATE_END',
			'T_CORE.FORM.ERRORS.DATE_TIME_END',
			'T_CORE.FORM.ERRORS.DATE_TIME_START_FUTURE',
			'T_CORE.FORM.ERRORS.DATE_TIME_END_FUTURE',
			'T_CORE.FORM.ERRORS.DATE_START_FUTURE',
			'T_CORE.FORM.ERRORS.TIME_START',
			'T_CORE.FORM.ERRORS.TIME_END',
			'T_CORE.NO_PERMISSION',
			'T_CORE.BLOCK',
			'T_CORE.VEHICLE',
			'T_CORE.SCHEDULED_START',
			'T_CORE.ASSIGNMENT_TIME',
			'T_CORE.DIFFERENCE',
			'T_CORE.SCHEDULED_END',
			'T_REPORT.BLOCK_ASSIGNED',
			'T_REPORT.BLOCK_MISSED',
			'T_REPORT.BLOCK_UPCOMING',
			'T_REPORT.BLOCK_UNSCHEDULED',
			'T_REPORT.DAY.NUMBER_OF_UNITS',
			'T_REPORT.WEEK.NUMBER_OF_UNITS',
			'T_REPORT.MONTH.NUMBER_OF_UNITS',
			'T_REPORT.DAY.FULL_UNITS_ONLY',
			'T_REPORT.WEEK.FULL_UNITS_ONLY',
			'T_REPORT.MONTH.FULL_UNITS_ONLY',
			'T_REPORT.DAY.FULL_UNITS_ONLY_DESCRIPTION',
			'T_REPORT.WEEK.FULL_UNITS_ONLY_DESCRIPTION',
			'T_REPORT.MONTH.FULL_UNITS_ONLY_DESCRIPTION',
			'T_REPORT.DATE_LAST_MODIFIED',
			'T_REPORT.REPORT_TEMPLATE_CREATOR',
			'T_REPORT.REPORT_TEMPLATE_EDITOR',
			'T_REPORT.BLOCK_STATUS',
			'T_REPORT.LOADING_TEXT_BLOCKS_TODAY',
			'T_REPORT.LOADING_TEXT_BLOCKS_YESTERDAY',
			'T_REPORT.LOADING_TEXT_BLOCKS',
		]);
	};

	/**
	 * sets up multi select controls (routes, vehicles etc) with overriden values once we have necesssary translations and config
	 * limits the config to only 1 selected item based on report config (-1 ensures no upper limit)
	 */
	private initMultiSelectSettings = (): void => {
		if (this.reportConfig.reportOptions.routes.enabled) {
			this.routeSettings.placeholder = this.reportConfig.reportOptions.routes.multiSelect
				? this.translations['T_CORE.SELECT_ROUTES']
				: this.translations['T_CORE.SELECT_ROUTE'];
			this.routeSettings.maxSelectionCount = this.reportConfig.reportOptions.routes.multiSelect ? -1 : 1;
		}

		if (this.reportConfig.reportOptions.vehicles.enabled) {
			this.vehicleSettings.placeholder = this.reportConfig.reportOptions.vehicles.multiSelect
				? this.translations['T_CORE.SELECT_VEHICLES']
				: this.translations['T_CORE.SELECT_VEHICLE'];
			this.vehicleSettings.maxSelectionCount = this.reportConfig.reportOptions.vehicles.multiSelect ? -1 : 1;
		}

		if (this.reportConfig.reportOptions.blocks.enabled) {
			this.blockSettings.placeholder = this.reportConfig.reportOptions.vehicles.multiSelect
				? this.translations['T_CORE.SELECT_BLOCKS']
				: this.translations['T_CORE.SELECT_BLOCK'];
			this.blockSettings.maxSelectionCount = this.reportConfig.reportOptions.blocks.multiSelect ? -1 : 1;
		}
	};

	/**
	 * builds (and initializes) the report form from the established reportTemplate (whether it be default values for create mode
	 * or saved values in edit mode)
	 */
	private buildReportForm = (): void => {
		this.reportForm = this.formBuilder.group(
			{
				name: [
					{
						value: this.initName(),
						disabled: false,
					},
				],
				code: [
					{
						value: this.initCode(),
						disabled: false,
					},
				],
				createdByName: [
					{
						value: this.initCreatedByName(),
						disabled: true,
					},
				],
				description: [
					{
						value: this.initDescription(),
						disabled: false,
					},
				],
				sharing: [
					{
						value: this.initSharing(),
						disabled: false,
					},
				],
				outputType: [
					{
						value: this.initOutputType(),
						disabled: false,
					},
				],
				agencyId: [
					{
						value: this.initAgencyId(),
						disabled: false,
					},
				],
				routeSelection: [
					{
						value: this.initRouteSelection(),
						disabled: false,
					},
				],
				selectedRoutes: [
					{
						value: this.initSelectedRoutes(),
						disabled: false,
					},
				],
				vehicleSelection: [
					{
						value: this.initVehicleSelection(),
						disabled: false,
					},
				],
				selectedVehicles: [
					{
						value: this.initSelectedVehicles(),
						disabled: false,
					},
				],
				blockSelection: [
					{
						value: this.initBlockSelection(),
						disabled: false,
					},
				],
				scheduleSortSelection: [
					{
						value: this.initScheduleSort(),
						disabled: false,
					},
				],
				speedSelection: [
					{
						value: this.speedSelection(),
						disabled: false,
					},
				],
				selectedBlocks: [
					{
						value: this.initSelectedBlocks(),
						disabled: false,
					},
				],
				filterArrivalStops: [
					{
						value: this.initFilterArrivalStops(),
						disabled: false,
					},
				],
				adherenceThresholdOverride: [
					{
						value: this.initAdherenceThresholdOverride(),
						disabled: false,
					},
				],
				effectiveDateRangeType: [
					{
						value: this.initEffectiveDateRangeType(),
						disabled: false,
					},
				],
				effectiveDateRangeDayType: [
					// used when effectiveDateRangeType is 'day'
					{
						value: this.initDayTypeSelectionType(),
						disabled: false,
					},
				],
				effectiveDateRangeWeekType: [
					// used when effectiveDateRangeType is 'week'
					{
						value: this.initWeekTypeSelectionType(),
						disabled: false,
					},
				],
				effectiveDateRangeMonthType: [
					// used when effectiveDateRangeType is 'month'
					{
						value: this.initMonthTypeSelectionType(),
						disabled: false,
					},
				],
				dayNameType: [
					// used when effectiveDateRangeType is 'dayName'
					{
						value: this.initDayNameType(),
						disabled: false,
					},
				],
				customStartDate: [
					// used when effectiveDateRangeType is 'custom'
					{
						value: this.initCustomStartDate(),
						disabled: false,
					},
				],
				customStartTime: [
					// used when effectiveDateRangeType is 'custom'
					{
						value: this.initCustomStartTime(),
						disabled: false,
					},
				],
				customEndDate: [
					// used when effectiveDateRangeType is 'custom'
					{
						value: this.initCustomEndDate(),
						disabled: false,
					},
				],
				customEndTime: [
					// used when effectiveDateRangeType is 'custom'
					{
						value: this.initCustomEndTime(),
						disabled: false,
					},
				],
				// used when effectiveDateRangeType is day, week or month and effectiveDateRangeDayType, effectiveDateRangeWeekType
				// or effectiveDateRangemonth type is custom
				noOfUnitsCount: [
					{
						value: this.initNoOfUnitsCount(),
						disabled: false,
					},
				],
				// used when effectiveDateRangeType is day, week or month and effectiveDateRangeDayType, effectiveDateRangeWeekType
				// or effectiveDateRangeMonthType is custom.
				fullUnitsOnly: [
					// determines if we should use the current day, week or month i.e when false ignore current day - yesterday being
					// the first 'full' day
					{
						value: this.initFullUnitsOnly(),
						disabled: false,
					},
				],
				specificDate: [
					// used when effectiveDateRangeType is day
					{
						value: this.initSpecificDate(),
						disabled: false,
					},
				],
				effectiveTimeEnabled: [
					{
						value: this.initEffectiveTimeEnabled(),
						disabled: false,
					},
				],
				effectiveStartTime: [
					{
						value: this.initEffectiveTimeStart(),
						disabled: false,
					},
				],
				effectiveEndTime: [
					{
						value: this.initEffectiveTimeEnd(),
						disabled: false,
					},
				],
			},
			{}
		);
	};

	/**
	 * determines the initial name value
	 *
	 * @returns the report name based on selection/config
	 */
	private initName = (): string => {
		let name: string = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				if (this.reportCustomization?.customName) {
					name = this.reportCustomization.customName;
				} else {
					name = this.getTranslation(('T_REPORT.TEMPLATES.' + this.reportConfig.defaultTemplateId + '.name').toUpperCase());
				}
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				name = this.savedReportTemplate.name;
				break;
		}

		return name;
	};

	/**
	 * determines the initial code value
	 *
	 * @returns the report code based on selection/config
	 */
	private initCode = (): string => {
		let code: string = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				code = this.reportConfig.code;
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				code = this.savedReportTemplate.code;
				break;
		}

		return code;
	};

	/**
	 * determines the created by name for the report - always returns the current user, even if we are editing
	 * because it is that user that should be displayed and saved
	 *
	 * @returns the report created by name
	 */
	private initCreatedByName = (): string => {
		return this.currentUser.profile.real_name;
	};

	/**
	 * determines the initial description value
	 *
	 * @returns the report description based on selection/config
	 */
	private initDescription = (): string => {
		let description: string = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				if (this.reportCustomization.customDescription) {
					description = this.reportCustomization.customDescription;
				} else {
					description = this.getTranslation(
						('T_REPORT.TEMPLATES.' + this.reportConfig.defaultTemplateId + '.description').toUpperCase()
					);
				}
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				description = this.savedReportTemplate.description;
				break;
		}

		return description;
	};

	/**
	 * determines the initial sharing value
	 *
	 * @returns the report sharing value based on selection/config
	 */
	private initSharing = (): string => {
		let sharing: string = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				sharing = this.reportConfig.sharing;
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				sharing = this.savedReportTemplate.sharing;
				break;
		}

		return sharing;
	};

	/**
	 * determines the initial output type value
	 *
	 * @returns the report output type value based on selection/config
	 */
	private initOutputType = (): string => {
		let outputType: string = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				outputType = this.reportConfig.outputType;
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				outputType = this.savedReportTemplate.output_type;
				break;
		}

		return outputType;
	};

	/**
	 * determines the initial agency Id
	 *
	 * @returns the report agency Id value based the users default agency
	 */
	private initAgencyId = (): number => {
		return this.selectedAgency.nb_id;
	};

	/**
	 * determines the initial route selection value. Default to all if configured
	 *
	 * @returns the route selection value based on selection/config
	 */
	private initRouteSelection = (): EntitySelectionType => {
		let routeSelection: EntitySelectionType = null;

		if (this.reportConfig.reportOptions.routes.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					routeSelection = EntitySelectionType.specific;

					if (this.reportConfig.reportOptions.routes.selections.includes(EntitySelectionType.all)) {
						routeSelection = EntitySelectionType.all;
					}
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					routeSelection =
						this.savedReportTemplate.report_options.include_all_routes === 'true'
							? EntitySelectionType.all
							: EntitySelectionType.specific;
					break;
			}
		}

		return routeSelection;
	};

	/**
	 * determines the initial routes selected
	 *
	 * @returns routes selected based on previous selection/config
	 */
	private initSelectedRoutes = (): ReportRoutes => {
		let selectedRoutes: ReportRoutes = null;

		if (this.reportConfig.reportOptions.routes.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					selectedRoutes = [];
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					selectedRoutes = this.savedReportTemplate.report_options.routes;
					break;
			}
		}

		return selectedRoutes;
	};

	/**
	 * determines the initial vehicle selection selection value. Default to all if configured
	 *
	 * @returns the vehicle selection value based on selection/config
	 */
	private initVehicleSelection = (): EntitySelectionType => {
		let vehicleSelection: EntitySelectionType = null;

		if (this.reportConfig.reportOptions.vehicles.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					vehicleSelection = EntitySelectionType.specific;

					if (this.reportConfig.reportOptions.vehicles.selections.includes(EntitySelectionType.all)) {
						vehicleSelection = EntitySelectionType.all;
					}
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					vehicleSelection =
						this.savedReportTemplate.report_options.include_all_vehicles === 'true'
							? EntitySelectionType.all
							: EntitySelectionType.specific;
					break;
			}
		}

		return vehicleSelection;
	};

	/**
	 * determines the initial routes selected
	 *
	 * @returns routes selected based on previous selection/config
	 */
	private initSelectedVehicles = (): ReportVehicles => {
		let selectedVehicles: ReportVehicles = null;

		if (this.reportConfig.reportOptions.vehicles.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					selectedVehicles = [];
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					selectedVehicles = this.savedReportTemplate.report_options.vehicles;
					break;
			}
		}

		return selectedVehicles;
	};

	/**
	 * determines the initial block selection value. Default to all if configured
	 *
	 * @returns the route selection value based on selection/config
	 */
	private initBlockSelection = (): EntitySelectionType => {
		let blockSelection: EntitySelectionType = null;

		if (this.reportConfig.reportOptions.blocks.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					blockSelection = EntitySelectionType.specific;

					if (this.reportConfig.reportOptions.blocks.selections.includes(EntitySelectionType.all)) {
						blockSelection = EntitySelectionType.all;
					}
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					switch (this.savedReportTemplate.report_options.block_selection) {
						case 'all':
							blockSelection = EntitySelectionType.all;
							break;
						case 'specific':
							blockSelection = EntitySelectionType.specific;
							break;
						case 'unassigned':
							blockSelection = EntitySelectionType.unassigned;
							break;
					}
			}
		}

		return blockSelection;
	};

	/**
	 * determines the initial Schedule Sort by Blocks and Time selection value. Default to scheduleSortByBlock if configured
	 *
	 * @returns the Schedule Sort by Blocks and Time selection value based on selection/config
	 */
	private initScheduleSort = (): SortType => {
		let scheduleSortSelection: SortType = null;

		if (this.reportConfig.reportOptions.sort.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					scheduleSortSelection = SortType.time;

					if (this.reportConfig.reportOptions.sort.sortOptions.includes(SortType.block)) {
						scheduleSortSelection = SortType.block;
					}
					break;

				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					switch (this.savedReportTemplate.report_options.schedule_sort_selection) {
						case 'scheduleSortByBlock':
							scheduleSortSelection = SortType.block;
							break;
						case 'scheduleSortByTime':
							scheduleSortSelection = SortType.time;
							break;
					}
			}
		}

		return scheduleSortSelection;
	};

	/**
	 * determines the initial Speed Selection .Default to mph if configured
	 *
	 * @returns the Speed Selection value based on selection/config
	 */
	private speedSelection = (): SpeedType => {
		let speedSelection: SpeedType = null;

		if (this.reportConfig.reportOptions.speed.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					speedSelection = SpeedType.mph;
					if (this.reportConfig.reportOptions.speed.speedOptions.includes(SpeedType.mph)) {
						speedSelection = SpeedType.mph;
					}
					break;

				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					switch (this.savedReportTemplate.report_options.speed_selection) {
						case 'mph':
							speedSelection = SpeedType.mph;
							break;
						case 'km/h':
							speedSelection = SpeedType.kmh;
							break;
					}
			}
		}

		return speedSelection;
	};

	/**
	 * determines the initial blocks selected
	 *
	 * @returns blocks selected based on previous selection/config
	 */
	private initSelectedBlocks = (): ReportBlocks => {
		let selectedBlocks: ReportBlocks = null;

		if (this.reportConfig.reportOptions.blocks.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					selectedBlocks = [];
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					selectedBlocks = this.savedReportTemplate.report_options.blocks;
					break;
			}
		}

		return selectedBlocks;
	};

	/**
	 * determines the initial filter arrival stops value
	 *
	 * @returns filter arrival stops selected based on previous selection/config
	 */
	private initFilterArrivalStops = (): boolean => {
		let filterArrivalStops: boolean = false;

		if (this.reportConfig.reportOptions.filterArrivalStops.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					filterArrivalStops = this.savedReportTemplate.report_options.filter_arrival_stops === 'true';
					break;
			}
		}

		return filterArrivalStops;
	};

	/**
	 * determines the initial adherence threshold override value
	 *
	 * @returns adherence threshold override selected based on previous selection/config
	 */
	private initAdherenceThresholdOverride = (): boolean => {
		let adherenceThresholdOverride: boolean = false;

		if (this.reportConfig.reportOptions.adherenceThreshold.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					adherenceThresholdOverride = this.savedReportTemplate.report_options.adherence_threshold_override === 'true';
					break;
			}
		}

		return adherenceThresholdOverride;
	};

	/**
	 * determines the initial effective date range value
	 *
	 * @returns the initial effective selection based on previous selection/config
	 */
	private initEffectiveDateRangeType = (): EffectiveDateRangeType => {
		let effectiveDateRangeType: EffectiveDateRangeType = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				effectiveDateRangeType = this.reportConfig.dateOptions.initialDateRangeType;
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				// we store custom-date type as specific_date (as that is what the interface/payload expects).
				// we use custom-date as it is used with custom date range type and we don't want to confuse
				// with day type and specific date sub type
				switch (this.savedReportTemplate.date_options.effective_date_range_type) {
					case 'day':
						effectiveDateRangeType = EffectiveDateRangeType.day;
						break;
					case 'week':
						effectiveDateRangeType = EffectiveDateRangeType.week;
						break;
					case 'month':
						effectiveDateRangeType = EffectiveDateRangeType.month;
						break;
					case 'specific_date':
						effectiveDateRangeType = EffectiveDateRangeType.custom;
						break;
					case 'day_name_value':
						effectiveDateRangeType = EffectiveDateRangeType.dayName;
						break;
				}
				break;
		}

		return effectiveDateRangeType;
	};

	/**
	 * determines the initial day type for the report form from the numerical version stored in the back end
	 * or default to 'today'
	 *
	 * @returns the type value used to set the report form
	 */
	private initDayTypeSelectionType = (): EffectiveDateRangeDayType => {
		let type: string = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				// checking for null ensure 0 is processed as false
				type =
					this.reportConfig.dateOptions.initialDateRangeDayType !== null
						? this.reportConfig.dateOptions.initialDateRangeDayType.toString()
						: null;
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'day') {
					type = this.savedReportTemplate.date_options.day.type;
				}
				break;
		}

		let typeEnum: EffectiveDateRangeDayType = null;

		switch (type) {
			case '0':
				typeEnum = EffectiveDateRangeDayType.today;
				break;
			case '1':
				typeEnum = EffectiveDateRangeDayType.yesterday;
				break;
			case '2':
				typeEnum = EffectiveDateRangeDayType.custom;
				break;
			case '3':
				typeEnum = EffectiveDateRangeDayType.specific;
				break;
		}

		return typeEnum;
	};

	/**
	 * determines the initial week type for the report form from the numerical version stored in the back end
	 * or defaults to thisWeek
	 *
	 * @returns the type value used to set the report form
	 */
	private initWeekTypeSelectionType = (): EffectiveDateRangeWeekType => {
		let type: string = '0';

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'week') {
					type = this.savedReportTemplate.date_options.week.type;
				}
				break;
		}

		let typeEnum: EffectiveDateRangeWeekType = null;

		switch (type) {
			case '0':
				typeEnum = EffectiveDateRangeWeekType.thisWeek;
				break;
			case '1':
				typeEnum = EffectiveDateRangeWeekType.lastWeek;
				break;
			case '2':
				typeEnum = EffectiveDateRangeWeekType.custom;
				break;
		}

		return typeEnum;
	};

	/**
	 * determines the initial month type for the report form from the numerical version stored in the back end
	 * or defaults to 'this-month'
	 *
	 * @returns the type value used to set the report form
	 */
	private initMonthTypeSelectionType = (): EffectiveDateRangeMonthType => {
		let type: string = '0';

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'month') {
					type = this.savedReportTemplate.date_options.month.type;
				}
				break;
		}

		let typeEnum: EffectiveDateRangeMonthType = null;

		switch (type) {
			case '0':
				typeEnum = EffectiveDateRangeMonthType.thisMonth;
				break;
			case '1':
				typeEnum = EffectiveDateRangeMonthType.lastMonth;
				break;
			case '2':
				typeEnum = EffectiveDateRangeMonthType.custom;
				break;
		}

		return typeEnum;
	};

	/**
	 * determines the initial dayName for the report form from the numerical version stored in the back end
	 * or defaults to 'includeAll'
	 *
	 * @returns the type value used to set the report form
	 */
	private initDayNameType = (): EffectiveDateRangeDayNameType => {
		let type: string = '0';

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'day_name_value') {
					type = this.savedReportTemplate.date_options.day_name_value.type;
				}
				break;
		}

		let typeEnum: EffectiveDateRangeDayNameType = null;

		switch (type) {
			case '0':
				typeEnum = EffectiveDateRangeDayNameType.includeAll;
				break;
			case '1':
				typeEnum = EffectiveDateRangeDayNameType.weekdays;
				break;
			case '2':
				typeEnum = EffectiveDateRangeDayNameType.saturday;
				break;
			case '3':
				typeEnum = EffectiveDateRangeDayNameType.sunday;
				break;
		}

		return typeEnum;
	};

	/**
	 * determines the initial custom start date for the report form using the default value (for create)
	 * or the saved value when in edit mode
	 *
	 * @returns the custom start date used to set the report form
	 */
	private initCustomStartDate = (): Date => {
		let date: Date = this.defaultDate;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'specific_date') {
					date = new Date(
						formatDate(this.savedReportTemplate.date_options.specific_date.effective_date_start, 'yyyy-MM-dd', 'en-US')
					);
				}
				break;
		}

		return date;
	};

	/**
	 * determines the initial custom end date for the report form using the default value (for create)
	 * or the saved value when in edit mode
	 *
	 * @returns the custom end date used to set the report form
	 */
	private initCustomEndDate = (): Date => {
		let date: Date = this.defaultDate;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'specific_date') {
					date = new Date(
						formatDate(this.savedReportTemplate.date_options.specific_date.effective_date_end, 'yyyy-MM-dd', 'en-US')
					);
				}
				break;
		}

		return date;
	};

	/**
	 * determines the initial custom start time for the report form using the default value (for create)
	 * or the saved value when in edit mode.
	 *
	 * @returns the custom start time used to set the report form
	 */
	private initCustomStartTime = (): string => {
		let time: string = '00:00';

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'specific_date') {
					const dateTimeParts: string[] = this.savedReportTemplate.date_options.specific_date.effective_date_start.split(' ');
					const timeParts: string[] = dateTimeParts[1].split(':');

					time = timeParts[0] + ':' + timeParts[1]; // drop the seconds part
				}
				break;
		}

		return time;
	};

	/**
	 * determines the initial custom end time for the report form using the default value (for create)
	 * or the saved value when in edit mode.
	 *
	 * @returns the custom end time used to set the report form
	 */
	private initCustomEndTime = (): string => {
		let time: string = '23:59';

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'specific_date') {
					const dateTimeParts: string[] = this.savedReportTemplate.date_options.specific_date.effective_date_end.split(' ');
					const timeParts: string[] = dateTimeParts[1].split(':');

					time = timeParts[0] + ':' + timeParts[1]; // drop the seconds part
				}
				break;
		}

		return time;
	};

	/**
	 * determines the initial number of units count for the report form for the effective date range type
	 *
	 * @returns the count value used to set the report form
	 */
	private initNoOfUnitsCount = (): number => {
		let noOfUnitsCount: number = 1;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				switch (this.savedReportTemplate.date_options.effective_date_range_type) {
					case 'day':
						if (this.savedReportTemplate.date_options.day.type === '2') {
							// custom
							noOfUnitsCount = this.savedReportTemplate.date_options.day.count;
						}
						break;
					case 'week':
						if (this.savedReportTemplate.date_options.week.type === '2') {
							// custom
							noOfUnitsCount = this.savedReportTemplate.date_options.week.count;
						}
						break;
					case 'month':
						if (this.savedReportTemplate.date_options.month.type === '2') {
							// custom
							noOfUnitsCount = this.savedReportTemplate.date_options.month.count;
						}
						break;
				}
				break;
		}

		return noOfUnitsCount;
	};

	/**
	 * Gets the full units only value to set in the form for the 'does not include...' checkbox
	 *
	 * This value determines if the full units only value (true/false) for the custom option (if day, week or month date range)
	 * i.e if we should use the current day, week or month. For example when false and date range option is day then ignore
	 * current day - yesterday being the first 'full' day
	 *
	 * @returns whether not or not to include full units only
	 */
	private initFullUnitsOnly = (): boolean => {
		let fullUnitsOnly: boolean = false;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				switch (this.savedReportTemplate.date_options.effective_date_range_type) {
					case 'day':
						if (this.savedReportTemplate.date_options.day.type === '2') {
							// custom
							fullUnitsOnly = this.savedReportTemplate.date_options.day.full_units;
						}
						break;
					case 'week':
						if (this.savedReportTemplate.date_options.week.type === '2') {
							// custom
							fullUnitsOnly = this.savedReportTemplate.date_options.week.full_units;
						}
						break;
					case 'month':
						if (this.savedReportTemplate.date_options.month.type === '2') {
							// custom
							fullUnitsOnly = this.savedReportTemplate.date_options.month.full_units;
						}
						break;
				}
				break;
		}

		return fullUnitsOnly;
	};

	/**
	 * determines the specific date value for the report form (only valid when effective date range type is 'day')
	 *
	 * @returns the specific date value used to set the report form
	 */
	private initSpecificDate = (): Date => {
		// if we have configured our report to be an empty radio for the date control then also set the default specific date to null
		let specificDate: Date = this.reportConfig.dateOptions.initialDateRangeDayType !== null ? this.defaultDate : null;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				if (this.savedReportTemplate.date_options.effective_date_range_type === 'day') {
					if (this.savedReportTemplate.date_options.day.type === '3') {
						// specific date
						specificDate = new Date(this.savedReportTemplate.date_options.day.effective_date_start);
					}
				}
				break;
		}

		return specificDate;
	};

	/**
	 * determines the initial effective time enabled value
	 * @returns the effective value used to set the report form
	 */
	private initEffectiveTimeEnabled = (): boolean => {
		let effectiveTimeEnabled: boolean = false;

		let dateOptionsKey: keyof ReportDateOptions = null;
		let reportDateOptions: any = null;

		if (this.reportConfig.dateOptions.disabled !== true && this.reportConfig.dateOptions.effectiveTime.enabled) {
			switch (this.createEditType) {
				case CreateEditType.create:
					break;
				case CreateEditType.edit:
				case CreateEditType.editTemplate:
					dateOptionsKey = this.savedReportTemplate.date_options.effective_date_range_type as keyof ReportDateOptions;

					reportDateOptions = this.savedReportTemplate.date_options[dateOptionsKey];

					effectiveTimeEnabled = reportDateOptions.effective_time_enabled || false;
					break;
			}
		}

		return effectiveTimeEnabled;
	};

	/**
	 * Determines the initial effective start time value
	 *
	 * @returns the effective start value used to set the report form
	 */
	private initEffectiveTimeStart = (): string => {
		let time: string = '00:00';

		let dateOptionsKey: keyof ReportDateOptions = null;
		let reportDateOptions: any = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				dateOptionsKey = this.savedReportTemplate.date_options.effective_date_range_type as keyof ReportDateOptions;

				reportDateOptions = this.savedReportTemplate.date_options[dateOptionsKey];

				if (this.reportConfig.dateOptions.disabled !== true && reportDateOptions.effective_time_enabled) {
					const date: Date = new Date(this.savedReportTemplate.date_options.effective_time_start);

					const hours: string = String(date.getHours()).padStart(2, '0');
					const mins: string = String(date.getMinutes()).padStart(2, '0');

					time = hours + ':' + mins;
				}
				break;
		}

		return time;
	};

	/**
	 * Determines the initial effective end time value
	 *
	 * @returns the effective end value used to set the report form
	 */
	private initEffectiveTimeEnd = (): string => {
		let time: string = '23:59';

		let dateOptionsKey: keyof ReportDateOptions = null;
		let reportDateOptions: any = null;

		switch (this.createEditType) {
			case CreateEditType.create:
				break;
			case CreateEditType.edit:
			case CreateEditType.editTemplate:
				dateOptionsKey = this.savedReportTemplate.date_options.effective_date_range_type as keyof ReportDateOptions;

				reportDateOptions = this.savedReportTemplate.date_options[dateOptionsKey];

				if (this.reportConfig.dateOptions.disabled !== true && reportDateOptions.effective_time_enabled) {
					const date: Date = new Date(this.savedReportTemplate.date_options.effective_time_end);

					const hours: string = String(date.getHours()).padStart(2, '0');
					const mins: string = String(date.getMinutes()).padStart(2, '0');

					time = hours + ':' + mins;
				}
				break;
		}

		return time;
	};

	/**
	 * initializes the validation for the form.  We do this here rather than as when we build the report, simply to keep the validation
	 * together and keep the code maintainable as most of  the validatio is conditional based on report config
	 */
	private initReportValidation = (): void => {
		this.reportForm.controls.name.setValidators([
			Validators.required,
			Validators.maxLength(this.maxFieldLength),
			Validators.pattern(this.nameAllowedPattern),
		]);
		this.reportForm.controls.code.setValidators([Validators.required, Validators.maxLength(this.maxFieldLength)]);
		this.reportForm.controls.description.setValidators([Validators.maxLength(this.maxFieldLength)]);

		if (this.reportConfig.reportOptions.routes.enabled) {
			// turn on selected route validation when the form is set to 'specific'
			// either defaulted to 'specific' as the report config for routes has no selections configured
			// or report config has all/specific selections and the report has previously been set to 'specific' i.e in edit mode
			if (this.reportForm.controls.routeSelection.value === EntitySelectionType.specific) {
				this.reportForm.controls.selectedRoutes.setValidators([Validators.required, Validators.minLength(1)]);
			}
		}

		if (this.reportConfig.reportOptions.vehicles.enabled) {
			// turn on selected vehicle validation when the form is set to 'specific'
			// either defaulted to 'specific' as the report config for vehicles has no selections configured
			// or report config has all/specific selections and the report has previously been set to 'specific' i.e in edit mode
			if (this.reportForm.controls.vehicleSelection.value === EntitySelectionType.specific) {
				this.reportForm.controls.selectedVehicles.setValidators([Validators.required, Validators.minLength(1)]);
			}
		}

		if (this.reportConfig.reportOptions.blocks.enabled) {
			// turn on selected block validation when the form is set to 'specific'
			// either defaulted to 'specific' as the report config for blocks has no selections configured
			// or report config has all/specific selections and the report has previously been set to 'specific' i.e in edit mode
			if (this.reportForm.controls.blockSelection.value === EntitySelectionType.specific) {
				this.reportForm.controls.selectedBlocks.setValidators([Validators.required, Validators.minLength(1)]);
			}
		}

		// the following subscriptions monitor changes to user selection to apply conditional validation where appropriate
		this.reportForm.controls.routeSelection.valueChanges.subscribe((option: EntitySelectionType) => {
			if (option === EntitySelectionType.specific) {
				this.reportForm.controls.selectedRoutes.setValidators([Validators.required, Validators.minLength(1)]);
			} else {
				// 'all'
				this.reportForm.controls.selectedRoutes.setValue([]);
				this.reportForm.controls.selectedRoutes.clearValidators();
			}

			this.reportForm.controls.selectedRoutes.updateValueAndValidity();
		});

		this.reportForm.controls.vehicleSelection.valueChanges.subscribe((option: EntitySelectionType) => {
			if (option === EntitySelectionType.specific) {
				this.reportForm.controls.selectedVehicles.setValidators([Validators.required, Validators.minLength(1)]);
			} else {
				// 'all'
				this.reportForm.controls.selectedVehicles.setValue([]);
				this.reportForm.controls.selectedVehicles.clearValidators();
			}

			this.reportForm.controls.selectedVehicles.updateValueAndValidity();
		});

		this.reportForm.controls.blockSelection.valueChanges.subscribe((option: EntitySelectionType) => {
			if (option === EntitySelectionType.specific) {
				this.reportForm.controls.selectedBlocks.setValidators([Validators.required, Validators.minLength(1)]);
			} else {
				// 'all' or 'unassigned'
				this.reportForm.controls.selectedBlocks.setValue([]);
				this.reportForm.controls.selectedBlocks.clearValidators();
			}

			this.reportForm.controls.selectedBlocks.updateValueAndValidity();
		});

		this.reportForm.controls.effectiveDateRangeType.valueChanges.subscribe((option) => {
			if (option === EffectiveDateRangeType.custom) {
				this.reportForm.controls.customStartDate.setValidators([Validators.required]);
				this.reportForm.controls.customStartTime.setValidators([Validators.required]);
				this.reportForm.controls.customEndDate.setValidators([Validators.required]);
				this.reportForm.controls.customEndTime.setValidators([Validators.required]);
			} else {
				this.reportForm.controls.customStartDate.clearValidators();
				this.reportForm.controls.customStartTime.clearValidators();
				this.reportForm.controls.customEndDate.clearValidators();
				this.reportForm.controls.customEndTime.clearValidators();
			}

			this.reportForm.controls.customStartDate.updateValueAndValidity();
			this.reportForm.controls.customStartTime.updateValueAndValidity();
			this.reportForm.controls.customEndDate.updateValueAndValidity();
			this.reportForm.controls.customEndTime.updateValueAndValidity();
		});

		this.reportForm.controls.effectiveDateRangeDayType.valueChanges.subscribe((option) => {
			if (option === EffectiveDateRangeDayType.specific) {
				this.reportForm.controls.specificDate.setValidators([Validators.required]);
			} else {
				this.reportForm.controls.specificDate.clearValidators();
			}

			if (option === EffectiveDateRangeDayType.custom) {
				this.reportForm.controls.noOfUnitsCount.setValidators([Validators.required, Validators.min(1), Validators.max(62)]);
			} else {
				this.reportForm.controls.noOfUnitsCount.clearValidators();
			}

			this.reportForm.controls.specificDate.updateValueAndValidity();
			this.reportForm.controls.noOfUnitsCount.updateValueAndValidity();
		});

		this.reportForm.controls.effectiveDateRangeWeekType.valueChanges.subscribe((option) => {
			if (option === EffectiveDateRangeWeekType.custom) {
				this.reportForm.controls.noOfUnitsCount.setValidators([Validators.required, Validators.min(1), Validators.max(8)]);
			} else {
				this.reportForm.controls.noOfUnitsCount.clearValidators();
			}

			this.reportForm.controls.noOfUnitsCount.updateValueAndValidity();
		});

		this.reportForm.controls.effectiveDateRangeMonthType.valueChanges.subscribe((option) => {
			if (option === EffectiveDateRangeMonthType.custom) {
				this.reportForm.controls.noOfUnitsCount.setValidators([Validators.required, Validators.min(1), Validators.max(2)]);
			} else {
				this.reportForm.controls.noOfUnitsCount.clearValidators();
			}

			this.reportForm.controls.noOfUnitsCount.updateValueAndValidity();
		});

		this.reportForm.controls.effectiveTimeEnabled.valueChanges.subscribe((enabled) => {
			if (enabled) {
				this.reportForm.controls.effectiveStartTime.setValidators([Validators.required]);
				this.reportForm.controls.effectiveEndTime.setValidators([Validators.required]);
			} else {
				this.reportForm.controls.effectiveStartTime.clearValidators();
				this.reportForm.controls.effectiveEndTime.clearValidators();
			}

			this.reportForm.controls.effectiveStartTime.updateValueAndValidity();
			this.reportForm.controls.effectiveEndTime.updateValueAndValidity();
		});
	};

	/**
	 * Loads route data for the selected agency
	 *
	 * @param authorityId - the authority id fot the route
	 * @param agencyId - the agency id fot the route
	 */
	private loadRouteData = async (authorityId: string, agencyId: string): Promise<any> => {
		const result: ResultContent = await this.routesDataService.getRoutes(authorityId, agencyId);

		if (result.success) {
			const routes: Routes = result.resultData;

			this.routes = routes.flatMap((route: Route) => {
				const reportRoute: ReportRoute = {
					authority_id: route.authority_id,
					agency_id: route.agency_id,
					agency_nb_id: route.agency_nb_id,
					route_display_name: this.routesUtilService.getDisplayName(route),
					route_short_name: route.route_short_name,
					route_long_name: route.route_long_name,
					route_id: route.route_id,
					is_enabled: route.is_enabled,
					is_hidden: route.is_hidden,
					is_predict_enabled: route.is_predict_enabled,
				};

				return [reportRoute];
			});

			this.sortRoutes(this.routes);
		}
	};

	/**
	 * sorts route data to the correct order
	 *
	 * @param routes - the unsorted routes
	 * @returns the sorted report routes
	 */
	private sortRoutes = (routes: ReportRoutes): ReportRoutes => {
		return routes.sort((a, b) => a.route_display_name.localeCompare(b.route_display_name, 'en', { numeric: true }));
	};

	/**
	 * Loads vehicles data for the selected agency
	 *
	 * @param authorityId - the authourity id of the selected agency
	 */
	private loadVehicleData = async (authorityId: string): Promise<void> => {
		const result: ResultContent = await this.vehiclesDataService.getVehicles(authorityId, true);

		if (result.success) {
			const vehicles: VehicleSummaries = result.resultData;

			this.vehicles = vehicles.flatMap((vehicle: VehicleSummary) => {
				const reportVehicle: ReportVehicle = {
					vehicle_id: vehicle.vehicle_id,
					agency_nb_id: vehicle.agency_nb_id,
					agency_vehicle_id: vehicle.vehicle_id + '/' + vehicle.agency_nb_id,
				};

				return [reportVehicle];
			});
		}
	};

	/**
	 * Loads block data for the selected agency
	 *
	 * @param authorityId - the authority id fot the block
	 * @param agencyId - the agency id fot the block
	 */
	private loadBlockData = async (authorityId: string, agencyId: string): Promise<void> => {
		const result: ResultContent = await this.blocksDataService.getBlocksAll(authorityId, agencyId);

		if (result.success) {
			const blocks: BlockSummaries = result.resultData;

			this.blocks = blocks.flatMap((block: BlockSummary) => {
				const reportBlock: ReportBlock = {
					block_id: block.block_id,
				};

				return [reportBlock];
			});
		}
	};

	/**
	 * determines the presentation styling to represent the supplied assignment difference
	 *
	 * @param difference - assignment difference
	 * @returns presentation styling representing the assignment difference value
	 */
	private getAssignmentDifferenceDisplay = (difference: string): CssClassType => {
		return {
			className: difference.startsWith('+') ? 'nb-report-block-difference-warning' : null,
			value: difference,
			tooltip: null,
		};
	};

	/**
	 * derives the presentation styling for the supplied block status
	 *
	 * @param status - block status
	 * @returns presentation styling for the supplied block status
	 */
	private getStatusDisplay = (status: string): CssClassType => {
		let className: string = null;
		let value: string = null;
		let tooltip: string = null;

		switch (status) {
			case 'Assigned':
				className = 'nb-report-block-assigned';
				value = this.translations['T_REPORT.BLOCK_ASSIGNED'];
				tooltip = this.translations['T_REPORT.BLOCK_ASSIGNED'];
				break;
			case 'Missed':
				className = 'nb-report-block-missed';
				value = this.translations['T_REPORT.BLOCK_MISSED'];
				tooltip = this.translations['T_REPORT.BLOCK_MISSED'];
				break;
			case 'Upcoming':
				className = 'nb-report-block-upcoming';
				value = this.translations['T_REPORT.BLOCK_UPCOMING'];
				tooltip = this.translations['T_REPORT.BLOCK_UPCOMING'];
				break;
			case 'Unscheduled':
				className = 'nb-report-block-unscheduled';
				value = this.translations['T_REPORT.BLOCK_UNSCHEDULED'];
				tooltip = this.translations['T_REPORT.BLOCK_UNSCHEDULED'];
				break;
			default:
				break;
		}

		const classType: CssClassType = {
			className,
			value,
			tooltip,
		};

		return classType;
	};

	/**
	 * utility to sort block list by block id
	 */
	private sortByBlockId = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort((aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) =>
			aItem.blockId.localeCompare(bItem.blockId)
		);
	};

	/**
	 * utility to sort block list by vehicle id
	 */
	private sortByVehicleId = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort((aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) =>
			aItem.vehicleId.localeCompare(bItem.vehicleId)
		);
	};

	/**
	 * utility to sort block list by schedule start time
	 */
	private sortByScheduledStartTime = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort((aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) =>
			aItem.scheduledStartTime.localeCompare(bItem.scheduledStartTime)
		);
	};

	/**
	 * utility to sort block list by schedule end time
	 */
	private sortByScheduledEndTime = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort((aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) =>
			aItem.scheduledEndTime.localeCompare(bItem.scheduledEndTime)
		);
	};

	/**
	 * utility to sort block list by time of assignment
	 */
	private sortByTimeOfAssignment = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort((aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) =>
			aItem.timeOfAssignment.localeCompare(bItem.timeOfAssignment)
		);
	};

	/**
	 * utility to sort block list by assignment difference
	 */
	private sortByAssignmentDifference = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort(
			(aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) => aItem.assignmentDifferenceRaw - bItem.assignmentDifferenceRaw
		);
	};

	/**
	 * utility to sort block list by status
	 */
	private sortByStatus = (): void => {
		this.blockSelectionList = this.blockSelectionList.sort((aItem: BlockSelectionListItem, bItem: BlockSelectionListItem) =>
			aItem.status.value.localeCompare(bItem.status.value)
		);
	};

	/**
	 * sorts the block list
	 */
	private sortList = (): void => {
		switch (this.sortInfo.sort) {
			case 'blockId': {
				this.sortByBlockId();
				break;
			}
			case 'vehicleId': {
				this.sortByVehicleId();
				break;
			}
			case 'scheduledStartTime': {
				this.sortByScheduledStartTime();
				break;
			}
			case 'scheduledEndTime': {
				this.sortByScheduledEndTime();
				break;
			}
			case 'timeOfAssignment': {
				this.sortByTimeOfAssignment();
				break;
			}
			case 'assignmentDifference': {
				this.sortByAssignmentDifference();
				break;
			}
			case 'status': {
				this.sortByStatus();
				break;
			}
		}

		if (this.sortInfo.sortDir === SortDirection.desc) {
			this.blockSelectionList = this.blockSelectionList.reverse();
		}
	};

	/**
	 * scrolls to the bottom of the modal
	 */
	private scrollToBottom(): void {
		this.scrollContainer.nativeElement.scroll({
			top: this.scrollContainer.nativeElement.scrollHeight,
			left: 0,
			behavior: 'auto',
		});
	}

	/**
	 * initializes the agency settings based on the settings passed in
	 *
	 * @param adherenceSettingEarlyMinSec - early min adhearence setting
	 * @param adherenceSettingVeryEarlySec - very early min adhearence setting
	 * @param adherenceSettingLateMinSec - late min adhearence setting
	 * @param adherenceSettingVeryLateSec - very late min adhearence setting
	 */
	private initAdherenceSettings = (
		adherenceSettingEarlyMinSec: number,
		adherenceSettingVeryEarlySec: number,
		adherenceSettingLateMinSec: number,
		adherenceSettingVeryLateSec: number
	): void => {
		this.earlySliderValues = [adherenceSettingEarlyMinSec, adherenceSettingVeryEarlySec];

		this.lateSliderValues = [adherenceSettingLateMinSec, adherenceSettingVeryLateSec];
	};

	/**
	 * build the report template up from the modified data in the reactive report form to sumbit to back end
	 *
	 * This method and the subsequent methods it calls are responsible for building up the payload to create a new report (even in
	 * edit mode we are using exisiting parameters to create a new report).
	 *
	 * Throughout these methods, the aim is build up the same payload as prior to the Angular 11 upgrade despite
	 * a number of issues:
	 * ** Bloated payload (is everything required?)
	 * ** Inconsistent date formats (some UTC, some local time in a different formats)
	 * ** Some properties are omitted from the payload if not required where as other times they are set as false, undefined or null)
	 *
	 * Further analysis of how the backend deals with the payload is required to solve the issues above and simplify the
	 * process but until that time, at least the build up of the data is separate and the initial refactor has removed the 'spaggetti'
	 * nature of the build up of the payload in the original AngularJS version
	 *
	 * @returns the report template detail
	 */
	private buildReportTemplate = (): ReportTemplateDetail => {
		const reportTemplate: ReportTemplateDetail = {
			default_template_id: this.reportConfig.defaultTemplateId,
			version: this.reportConfig.version,
			name: this.reportForm.controls.name.value,
			code: this.reportForm.controls.code.value,
			description: this.reportForm.controls.description.value,
			category: this.reportConfig.category,
			sharing: this.reportForm.controls.sharing.value,
			output_type: this.reportForm.controls.outputType.value,
			created_by_id: this.currentUser.nb_id,
			created_by_name: this.currentUser.profile.real_name,
			report_options: this.getReportOptions(),
			date_options: this.getDateOptions(),
		};

		return reportTemplate;
	};

	/**
	 * build the report options aspect of the payload
	 *
	 * The '...' notation ensures the appropriate property is set in the sub method or omitted completely (as per the original
	 * payload prior to refactoring)
	 *
	 * @returns a ReportOptions object ready to be inserted in to the report payload
	 */
	private getReportOptions = (): ReportOptions => {
		return {
			agencies: this.getAgencyReportOptions(),
			authority: this.getAuthorityReportOptions(),
			excluded_fields: [],
			...this.getAdherenceThresholdOverrideProperty(),
			...this.getAdherenceThresholdSettingsProperty(),
			...this.getFilterArrivalStopsProperty(),
			...this.getIncludeAllRoutesProperty(),
			...this.getRoutesProperty(),
			...this.getIncludeAllVehiclesProperty(),
			...this.getVehiclesProperty(),
			...this.getBlockSelectionProperty(),
			...this.getBlocksProperty(),
			...this.getScheduleSortProperty(),
			...this.getSpeedSelectionProperty(),
		};
	};

	/**
	 * build the adherence settings for the payload from the slider controls (initialized to default agency settings if unchanged)
	 *
	 * @returns a ReportOptionsAdherenceThresholdSettings object ready to be inserted in to the report payload
	 */
	private getAdherenceThresholdSettings = (): ReportOptionsAdherenceThresholdSettings => {
		return {
			adherence_setting_early_min_sec: this.earlySliderValues[0],
			adherence_setting_late_min_sec: this.lateSliderValues[0],
			adherence_setting_very_early_sec: this.earlySliderValues[1],
			adherence_setting_very_late_sec: this.lateSliderValues[1],
		};
	};

	/**
	 * build the agency report payload details for the selected agency
	 *
	 * @returns a ReportOptionsAgencies object ready to be inserted in to the report payload
	 */
	private getAgencyReportOptions = (): ReportOptionsAgencies => {
		return {
			nb_id: this.selectedAgency.nb_id,
			authority_id: this.selectedAgency.authority_id,
			agency_id: this.selectedAgency.agency_id,
			agency_name: this.selectedAgency.agency_name,
			date_format: this.selectedAgency.date_format,
			time_format: this.selectedAgency.time_format,
		};
	};

	/**
	 * build the authority report payload details for the selected agency
	 *
	 * @returns a ReportOptionsAuthority object ready to be inserted in to the report payload
	 */
	private getAuthorityReportOptions = (): ReportOptionsAuthority => {
		return {
			authority_id: this.selectedAgency.authority_id,
		};
	};

	/**
	 * Sets and returns the adherence_threshold_override property with the correct value but only if it is enabled
	 *
	 * @returns an object containing the adherence_threshold_override value ONLY if the condition passes
	 */
	private getAdherenceThresholdOverrideProperty = (): OptionalAdherenceThresholdOverride => {
		return (
			this.reportConfig.reportOptions.adherenceThreshold.enabled && {
				adherence_threshold_override: this.reportForm.controls.adherenceThresholdOverride.value ? 'true' : 'false',
			}
		);
		// revert back to string format (consistent with existing reports so difficult to refactor to boolean).
	};

	/**
	 * Sets and returns the adherence_threshold_settings property with the correct value but only if it is enabled
	 *
	 * @returns an object containing the adherence_threshold_settings values ONLY if the condition passes
	 */
	private getAdherenceThresholdSettingsProperty = (): OptionalAdherenceSettingsOverride => {
		return (
			this.reportConfig.reportOptions.adherenceThreshold.enabled && {
				adherence_threshold_settings: this.getAdherenceThresholdSettings(),
			}
		);
		// revert back to string format (consistent with existing reports so difficult to refactor to boolean).
	};

	/**
	 * Sets and returns the filter_arrival_stops property with the correct value but only if it is enabled
	 *
	 * @returns an object containing the filter_arrival_stops value ONLY if the condition passes
	 */
	private getFilterArrivalStopsProperty = (): OptionalFilterArrivalStops => {
		return (
			this.reportConfig.reportOptions.filterArrivalStops.enabled && {
				filter_arrival_stops: this.reportForm.controls.filterArrivalStops.value ? 'true' : 'false',
			}
		);
		// revert back to string format (consistent with existing reports so difficult to refactor to boolean).
	};

	/**
	 * Sets and returns the include_all_routes property with the correct value but only if routes are enabled
	 *
	 * @returns an object containing the include_all_routes value ONLY if the condition passes
	 */
	private getIncludeAllRoutesProperty = (): OptionalIncludeAllRoutes => {
		return this.reportConfig.reportOptions.routes.enabled && { include_all_routes: this.getIncludeAllRoutes() }; // conditional property
	};

	/**
	 * determine the include all routes value of the report payload details. If the 'all' selection is
	 * not configured then the we know the payload must always be "false"
	 *
	 * @returns whether or not to include all routes in string format
	 */
	private getIncludeAllRoutes = (): string => {
		let includeAllRoutes: boolean = false;

		if (this.reportConfig.reportOptions.routes.selections.includes(EntitySelectionType.all)) {
			includeAllRoutes = this.reportForm.controls.routeSelection.value === EntitySelectionType.all;
		}

		return includeAllRoutes ? 'true' : 'false';
	};

	/**
	 * Sets and returns the routes property with the correct list of routes but only if routes are enabled
	 *
	 * @returns the list of routes but ONLY if the condition passes
	 */
	private getRoutesProperty = (): OptionalReportRoutes => {
		// conditional property
		return this.reportConfig.reportOptions.routes.enabled && { routes: this.reportForm.controls.selectedRoutes.value };
	};

	/**
	 * Sets and returns the include_all_vehicles property with the correct value but only if we vehicles are enabled
	 *
	 * @returns an object containing the include_all_vehicles value ONLY if the condition passes
	 */
	private getIncludeAllVehiclesProperty = (): OptionalIncludeAllVehicles => {
		// conditional property
		return this.reportConfig.reportOptions.vehicles.enabled && { include_all_vehicles: this.getIncludeAllVehicles() };
	};

	/**
	 * determine the include all vehicles value of the report payload details. If the 'all' selection is
	 * not configured then the we know the payload must always be "false"
	 *
	 * @returns whether or not to include all vehicles in string format
	 */
	private getIncludeAllVehicles = (): string => {
		let includeAllVehicles: boolean = false;

		if (this.reportConfig.reportOptions.vehicles.selections.includes(EntitySelectionType.all)) {
			includeAllVehicles = this.reportForm.controls.vehicleSelection.value === EntitySelectionType.all;
		}

		return includeAllVehicles ? 'true' : 'false';
	};

	/**
	 * retrieves the schedule sort property
	 *
	 * @returns optional sort selection
	 */
	private getScheduleSortProperty = (): OptionalScheduleSortSelection => {
		let scheduleSortSelection: string = null;

		switch (this.reportForm.controls.scheduleSortSelection.value) {
			case SortType.block:
				scheduleSortSelection = 'scheduleSortByBlock';
				break;
			case SortType.time:
				scheduleSortSelection = 'scheduleSortByTime';
				break;
		}

		return this.reportConfig.reportOptions.sort.enabled && { schedule_sort_selection: scheduleSortSelection }; // conditional property
	};
	/**
	 * determines the initial Speed Selection .Default to mph if configured
	 *
	 * @returns the Speed Selection value based on selection/config
	 */
	private getSpeedSelectionProperty = (): OptionalSpeedSelection => {
		let speedSelection: string = null;

		switch (this.reportForm.controls.speedSelection.value) {
			case SpeedType.mph:
				speedSelection = 'mph';
				break;
			case SpeedType.kmh:
				speedSelection = 'km/h';
				break;
		}

		return this.reportConfig.reportOptions.speed.enabled && { speed_selection: speedSelection };
	};

	/**
	 * Sets and returns the vehicles property with the correct list of vehicles but only if vehicles are enabled
	 *
	 * @returns the list of vehicles but ONLY if the condition passes
	 */
	private getVehiclesProperty = (): OptionalReportVehicles => {
		// conditional property
		return this.reportConfig.reportOptions.vehicles.enabled && { vehicles: this.reportForm.controls.selectedVehicles.value };
	};

	/**
	 * gets the optional block selection value for the payload if blocks are enabled.
	 * This is different to the general approach to routes/vehicles
	 * as the values can be 'all', 'unassigned' or 'specific' rather than a flag (i.e include_all_routes - 'true'
	 * indicating 'all and 'false' indicating 'specific').
	 * Ideally the routes and vehicles would be refactored to be consistent but that means further backend refactoring too.
	 *
	 * @returns a string representing the block selection to be 'all', 'unassigned' or 'specific' when blocks are configured to be enabled
	 */
	private getBlockSelectionProperty = (): OptionalBlockSelection => {
		let blockSelection: string = null;

		switch (this.reportForm.controls.blockSelection.value) {
			case EntitySelectionType.all:
				blockSelection = 'all';
				break;
			case EntitySelectionType.specific:
				blockSelection = 'specific';
				break;
			case EntitySelectionType.unassigned:
				blockSelection = 'unassigned';
				break;
		}

		// conditional property
		return this.reportConfig.reportOptions.blocks.enabled && { block_selection: blockSelection };
	};

	/**
	 * Sets and returns the blocks property depending if we are running a regular report or iterating over a list
	 * or reports when the report generation override feature is set
	 *
	 * @returns the list of blocks but ONLY if the condition passes
	 */
	private getBlocksProperty = (): OptionalReportBlocks => {
		// conditional property
		return this.reportConfig.reportOptions.blocks.enabled && { blocks: this.reportForm.controls.selectedBlocks.value };
	};

	/**
	 * build the date options aspect of the payload
	 *
	 * @returns a DateOptions object ready to be inserted in to the report payload
	 */
	private getDateOptions = (): ReportDateOptions => {
		if (!this.reportConfig.dateOptions.disabled) {
			return {
				relative_date_type: 'now',
				effective_date_range_type: this.getEffectiveDateRange(),
				// conditional property - returns a valid 'day' property when applicable or omits it
				...this.getEffectiveDateRangeDayProperty(),
				// conditional property - returns a valid 'week' property when applicable or omits it
				...this.getEffectiveDateRangeWeekProperty(),
				// conditional property - returns a valid 'month' property when applicable or omits it
				...this.getEffectiveDateRangeMonthProperty(),
				// conditional property - returns a valid 'specific_date' property when applicable or omits it
				...this.getCustomDateRangeProperty(),
				// conditional property - returns a valid 'date_name_value' property when applicable or omits it
				...this.getDayNameProperty(),
				effective_time_start: this.getEffectiveTimeStart(),
				effective_time_end: this.getEffectiveTimeEnd(),
				effective_time_base: this.getEffectiveTimeBase(),
			};
		} else {
			return {
				disabled: true,
			};
		}
	};

	/**
	 * returns the correct effective date range value from the reactive report form. the html form will set the appropriate
	 * values such as day, week, month. For the case of custom we override the value used in the form to 'specific_date' here
	 * which is what the back end currently expects.  We use 'custom' throughout the report form to try and remove confusion with
	 * the specific date sub option for the 'day' type. dayName is replaced with day_name_value (an inconsistent name but one the
	 * backend expects)
	 *
	 * @returns the effective date range value type for the report payload
	 */
	private getEffectiveDateRange = (): string => {
		let effectiveDateRangeType: string = null;

		switch (this.reportForm.controls.effectiveDateRangeType.value) {
			case EffectiveDateRangeType.day:
				effectiveDateRangeType = 'day';
				break;
			case EffectiveDateRangeType.week:
				effectiveDateRangeType = 'week';
				break;
			case EffectiveDateRangeType.month:
				effectiveDateRangeType = 'month';
				break;
			case EffectiveDateRangeType.custom:
				effectiveDateRangeType = 'specific_date';
				break;
			case EffectiveDateRangeType.dayName:
				effectiveDateRangeType = 'day_name_value';
				break;
		}

		return effectiveDateRangeType;
	};

	/**
	 * returns the optional effective date range day value from the reactive report form if the relevant condition passes
	 *
	 * @returns the effective date range day value type for the report payload
	 */
	private getEffectiveDateRangeDayProperty = (): OptionalDateOptionsDay => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.day && {
				day: {
					effective_time_enabled: this.getEffectiveTimeEnabled(EffectiveDateRangeType.day),
					type: this.getDayTypeSelection(this.reportForm.controls.effectiveDateRangeDayType.value),
					// conditional property - returns a valid 'count' property with value or omits it
					...this.getDayCustomCountProperty(),
					// conditional property - returns a valid 'full_units' property with true or omits it (when false)
					...this.getFullDaysOnlyProperty(),
					// conditional property - returns a valid 'effective_date_start' property with value or omits it
					...this.getDaySpecificDateProperty(),
				},
			}
		);
	};

	/**
	 * returns the optional effective date range week value from the reactive report form if the relevant condition passes
	 *
	 * @returns the effective date range week value type for the report payload
	 */
	private getEffectiveDateRangeWeekProperty = (): OptionalDateOptionsWeek => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.week && {
				week: {
					effective_time_enabled: this.getEffectiveTimeEnabled(EffectiveDateRangeType.week),
					type: this.getWeekTypeSelection(this.reportForm.controls.effectiveDateRangeWeekType.value),
					// conditional property - returns a valid 'count' property with value or omits it
					...this.getWeekCustomCountProperty(),
					// conditional property - returns a valid 'full_units' property with true or omits it (when false)
					...this.getFullDaysOnlyProperty(),
				},
			}
		);
	};

	/**
	 * returns the optional effective date range week value from the reactive report form if the relevant condition passes
	 *
	 * @returns the effective date range week value type for the report payload
	 */
	private getEffectiveDateRangeMonthProperty = (): OptionalDateOptionsMonth => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.month && {
				month: {
					effective_time_enabled: this.getEffectiveTimeEnabled(EffectiveDateRangeType.month),
					type: this.getMonthTypeSelection(this.reportForm.controls.effectiveDateRangeMonthType.value),
					// conditional property - returns a valid 'count' property with value or omits it
					...this.getMonthCustomCountProperty(),
					// conditional property - returns a valid 'full_units' property with true or omits it (when false)
					...this.getFullDaysOnlyProperty(),
				},
			}
		);
	};

	/**
	 * returns the optional day name value (includeAll, weekdays, saturday, sunday) from the reactive
	 * report form if the relevant condition passes
	 *
	 * @returns the day name value type for the report payload
	 */
	private getDayNameProperty = (): OptionalDateOptionsDayName => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.dayName && {
				day_name_value: {
					effective_time_enabled: false,
					type: this.getDayNameTypeSelection(this.reportForm.controls.dayNameType.value),
				},
			}
		);
	};

	/**
	 * Determines if the effective time should be set enabled for the given date range type
	 *
	 * @param effectiveDateRangeType - the date range i.e 'day', 'week' 'month' value etc
	 * @returns a flag indicating whether or not the effective time enabled is set for the report payload
	 */
	private getEffectiveTimeEnabled = (effectiveDateRangeType: EffectiveDateRangeType): boolean => {
		// If the effective time is enabled then set the appropriate flag in day/month/year
		if (
			this.reportForm.controls.effectiveTimeEnabled.value &&
			this.reportForm.controls.effectiveDateRangeType.value === effectiveDateRangeType
		) {
			return true;
		}

		return false;
	};

	/**
	 * Sets and returns an object with a count property with the correct value but only if we have chosen 'day'
	 * date range and selected 'custom'
	 *
	 * @returns an object containing the count value ONLY if the conditions pass
	 */
	private getDayCustomCountProperty = (): OptionalCustomDateRangeCount => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.day &&
			this.reportForm.controls.effectiveDateRangeDayType.value === EffectiveDateRangeDayType.custom && {
				count: this.reportForm.controls.noOfUnitsCount.value,
			}
		); // conditional property
	};

	/**
	 * Sets and return an object with a count property with the correct value but only if we have
	 * chosen 'week' date range and selected 'custom'
	 *
	 * @returns an object containing the count value ONLY if the conditions pass
	 */
	private getWeekCustomCountProperty = (): OptionalCustomDateRangeCount => {
		// returns a count property with the correct value but only if we have chosen 'week' date range and selected 'custom'
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.week &&
			this.reportForm.controls.effectiveDateRangeWeekType.value === EffectiveDateRangeWeekType.custom && {
				count: this.reportForm.controls.noOfUnitsCount.value,
			}
		); // conditional property
	};

	/**
	 * Sets and returns an an object with a count property with the correct value but only if we have
	 * chosen 'month' date range and selected 'custom'
	 *
	 * @returns an object containing the count value ONLY if the conditions pass
	 */
	private getMonthCustomCountProperty = (): OptionalCustomDateRangeCount => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.month &&
			this.reportForm.controls.effectiveDateRangeMonthType.value === EffectiveDateRangeMonthType.custom && {
				count: this.reportForm.controls.noOfUnitsCount.value,
			}
		); // conditional property
	};

	/**
	 * Sets and returns an an object with a count property with the correct value but only if we have chosen
	 * 'month' date range and selected 'custom'
	 *
	 * @returns an object containing the full_units value ONLY if the conditions pass
	 */
	private getFullDaysOnlyProperty = (): OptionalFullUnits => {
		// conditional property
		return this.reportForm.controls.fullUnitsOnly.value === true && { full_units: this.reportForm.controls.fullUnitsOnly.value };
	};

	/**
	 * Sets and returns an object for the custom date range (set as 'specific_date' for the backend) for when the custom
	 * date range is selected)
	 *
	 * @returns an object containing the CustomDateEffectiveDateDetails object with the requried properties for the report payload
	 */
	private getCustomDateRangeProperty = (): OptionalCustomDateEffectiveDateDetails => {
		// returns a 'specific_date' property with the correct values but only if we have chosen 'custom' date range
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.custom && {
				specific_date: {
					// conditional property
					effective_date_start: this.getEffectiveDateTime(
						this.reportForm.controls.customStartDate.value,
						this.reportForm.controls.customStartTime.value
					),
					effective_date_end: this.getEffectiveDateTime(
						this.reportForm.controls.customEndDate.value,
						this.reportForm.controls.customEndTime.value
					),
					...this.getCustomDateEffectiveTimeEnabledProperty(),
					// unlike the other date range types we only set the effective_time_enabled: true when true and omit
					// when false (based on original payload - further testing of back end api may simplify this)
				},
			}
		);
	};

	/**
	 * Sets and returns object with a effective_time_enabled property but ONLY if the effective time option is enabled
	 *
	 * @returns an object containing the count value ONLY if the conditions pass
	 */
	private getCustomDateEffectiveTimeEnabledProperty = (): OptionalCustomDateEffectiveTimeEnabled => {
		return this.reportForm.controls.effectiveTimeEnabled.value === true && { effective_time_enabled: true };
	};

	/**
	 * Build up effective date/time string - original interface maintained during refactor to Angular 11 however it isn't clear
	 * if this date format (i.e just sending time as it is and not UTC is valid).  Further analysis of the backend and further changes
	 * required for consistency
	 *
	 * @param dateValue - the custom date entered by the user
	 * @param timeValue - the custom time entered by the user
	 * @returns the formatted date/time string in the following format - yyyy-MM-dd HH:MM
	 */
	private getEffectiveDateTime = (dateValue: string, timeValue: string): string => {
		let customDateTime: string = new Date(dateValue).toDateString();

		customDateTime = formatDate(customDateTime, 'yyyy-MM-dd', 'en-US');

		customDateTime += ' ' + timeValue + ':00';

		return customDateTime;
	};

	/**
	 * get the DateTime in a readable format
	 *
	 * @param dateValue - the custom date entered by the user
	 * @returns the formatted date/time string in the following format - dd-MM-yyyy
	 */
	private getDateTimeDisplay = (dateValue: string): string => {
		let customDateTime: string = new Date(dateValue).toDateString();

		customDateTime = formatDate(customDateTime, 'MM-dd-yyyy', 'en-US');

		return customDateTime;
	};

	/**
	 * Determines the type for the selected 'day' date range such as today, yesterday, custom or specific date
	 *
	 * @param type - the day type selected by the user
	 * @returns the associated 'number' for the chosen type (as expected in the payload)
	 */
	private getDayTypeSelection = (type: EffectiveDateRangeDayType): string => {
		let typeValue: string = '0';

		switch (type) {
			case EffectiveDateRangeDayType.today:
				typeValue = '0';
				break;
			case EffectiveDateRangeDayType.yesterday:
				typeValue = '1';
				break;
			case EffectiveDateRangeDayType.custom:
				typeValue = '2';
				break;
			case EffectiveDateRangeDayType.specific:
				typeValue = '3';
				break;
		}

		return typeValue;
	};

	/**
	 * Determines the type for the selected 'week' date range such as this week, last week or custom
	 *
	 * @param type - the week type selected by the user
	 * @returns the associated 'number' for the chosen type (as expected in the payload)
	 */
	private getWeekTypeSelection = (type: EffectiveDateRangeWeekType): string => {
		let typeValue: string = '0';

		switch (type) {
			case EffectiveDateRangeWeekType.thisWeek:
				typeValue = '0';
				break;
			case EffectiveDateRangeWeekType.lastWeek:
				typeValue = '1';
				break;
			case EffectiveDateRangeWeekType.custom:
				typeValue = '2';
				break;
		}

		return typeValue;
	};

	/**
	 * Determines the type for the selected 'month' date range such as this month, last month or custom
	 *
	 * @param type - the month type selected by the user
	 * @returns the associated 'number' for the chosen type (as expected in the payload)
	 */
	private getMonthTypeSelection = (type: EffectiveDateRangeMonthType): string => {
		let typeValue: string = '0';

		switch (type) {
			case EffectiveDateRangeMonthType.thisMonth:
				typeValue = '0';
				break;
			case EffectiveDateRangeMonthType.lastMonth:
				typeValue = '1';
				break;
			case EffectiveDateRangeMonthType.custom:
				typeValue = '2';
				break;
		}

		return typeValue;
	};

	/**
	 * Determines the type for the selected 'day_name_value' date range such as this includeAll, weekdays, saturday, sunday
	 *
	 * @param type - the dayName type selected by the user
	 * @returns the associated 'number' for the chosen type (as expected in the payload)
	 */
	private getDayNameTypeSelection = (type: EffectiveDateRangeDayNameType): string => {
		let typeValue: string = '0';

		switch (type) {
			case EffectiveDateRangeDayNameType.includeAll:
				typeValue = '0';
				break;
			case EffectiveDateRangeDayNameType.weekdays:
				typeValue = '1';
				break;
			case EffectiveDateRangeDayNameType.saturday:
				typeValue = '2';
				break;
			case EffectiveDateRangeDayNameType.sunday:
				typeValue = '3';
				break;
		}

		return typeValue;
	};

	/**
	 * Sets and returns object with a effective_date_start property but ONLY if the effective date range option is 'day'
	 * and the specific date option is chosen
	 *
	 * @returns an object containing the effective date start ONLY if the conditions pass
	 */
	private getDaySpecificDateProperty = (): OptionalDaySpecificDate => {
		return (
			this.reportForm.controls.effectiveDateRangeType.value === EffectiveDateRangeType.day &&
			this.reportForm.controls.effectiveDateRangeDayType.value === EffectiveDateRangeDayType.specific && {
				// conditional property
				effective_date_start: this.getEffectiveDateTime(this.reportForm.controls.specificDate.value, '00:00'),
			}
		);
	};

	/**
	 * gets the effective start time value, defaulted to start of the day with time effective time modified if
	 * the effective time range option
	 * is selected
	 *
	 * @returns the effective start date and time
	 */
	private getEffectiveTimeStart = (): string => {
		let date: Moment = moment().startOf('day');

		if (this.reportForm.controls.effectiveTimeEnabled.value === true) {
			date = this.setDateTime(date, this.reportForm.controls.effectiveStartTime.value);
		}

		return this.formatEffectiveDateTime(date.toDate());
	};

	/**
	 * gets the effective end time value, defaulted to end of the day with time effective time modified if the effective time range option
	 * is selected
	 *
	 * @returns the effective start date and time
	 */
	private getEffectiveTimeEnd = (): string => {
		let date: Moment = moment().endOf('day');

		if (this.reportForm.controls.effectiveTimeEnabled.value === true) {
			date = this.setDateTime(date, this.reportForm.controls.effectiveEndTime.value);
		}

		return this.formatEffectiveDateTime(date.toDate());
	};

	/**
	 * gets the effective base time value, defaulted to start of the day
	 *
	 * Previous comment prior to refactor noted 'Get base so we can remove date and timezone info.
	 * Effective time only needs hours:mins:secs'
	 * This needs further analysis for potential improvements
	 *
	 * @returns the effective base date and time
	 */
	private getEffectiveTimeBase = (): string => {
		const date: Moment = moment().startOf('day');

		return this.formatEffectiveDateTime(date.toDate());
	};

	/**
	 * sets a combined date with time
	 *
	 * @param date - a moment object with the effective date time
	 * @param time - the effective time in string format HH:MM
	 * @returns the combined effective date and time
	 */
	private setDateTime = (date: moment.Moment, time: string): moment.Moment => {
		const timeSplit: string[] = time.split(':');

		date.hour(+timeSplit[0]);
		date.minutes(+timeSplit[1]);

		return date;
	};

	/**
	 * Formats the effecting dateTine in UTC format with seconds set to 0
	 *
	 * @param effectiveDateTime - the effective date time
	 * @returns the effective date time in the correct format (with 0 seconds)
	 */
	private formatEffectiveDateTime = (effectiveDateTime: Date): string => {
		effectiveDateTime.setSeconds(0, 0);

		return effectiveDateTime.toISOString();
	};

	/**
	 * builds the list cloumns
	 */
	private buildListColumns = (): void => {
		this.blocksSelectionListColumns = [
			{
				name: 'blockId',
				displayName: this.translations['T_CORE.BLOCK'],
				columnType: ColumnType.text,
				width: 98,
			},
			{
				name: 'vehicleId',
				displayName: this.translations['T_CORE.VEHICLE'],
				columnType: ColumnType.text,
				width: 106,
			},
			{
				name: 'scheduledStartTime',
				displayName: this.translations['T_CORE.SCHEDULED_START'],
				columnType: ColumnType.text,
				width: 175,
			},
			{
				name: 'timeOfAssignment',
				displayName: this.translations['T_CORE.ASSIGNMENT_TIME'],
				columnType: ColumnType.text,
				width: 180,
			},
			{
				name: 'assignmentDifference',
				displayName: this.translations['T_CORE.DIFFERENCE'],
				columnType: ColumnType.cssClass,
				width: 103,
			},
			{
				name: 'scheduledEndTime',
				displayName: this.translations['T_CORE.SCHEDULED_END'],
				columnType: ColumnType.text,
				width: 125,
			},
			{
				name: 'status',
				displayName: this.translations['T_REPORT.BLOCK_STATUS'],
				columnType: ColumnType.cssClass,
				width: 152,
			},
		];

		this.blockListInitialized = true;
	};

	/**
	 * formats the supplied data/time
	 *
	 * @param dateTime - date and time
	 * @returns formatted date and time
	 */
	private getFormattedDateTime = (dateTime: string): string => {
		if (dateTime !== '—') {
			return formatDate(dateTime, 'y-MM-dd HH:mm', 'en-US', this.agencyTimezoneOffset);
		} else {
			return dateTime;
		}
	};

	/**
	 * formats the supplied time
	 *
	 * @param time - time
	 * @returns formatted time
	 */
	private getFormattedTime = (time: string): string => {
		if (time !== '—') {
			return formatDate(time, 'HH:mm', 'en-US', this.agencyTimezoneOffset);
		} else {
			return time;
		}
	};

	/**
	 * formats the block time difference value
	 *
	 * @param blockTimeDifferenceValue - block time difference value
	 * @returns formatted block time difference value
	 */
	private getBlockTimeDifference = (blockTimeDifferenceValue: string): string => {
		if (blockTimeDifferenceValue !== '—') {
			const difference: number = parseInt(blockTimeDifferenceValue, 10);

			blockTimeDifferenceValue = TimeHelpers.formatTimeDifference(difference);
		}

		return blockTimeDifferenceValue;
	};

	/**
	 * retrieves the raw block time difference value
	 *
	 * @param blockTimeDifferenceValue - block time difference value
	 * @returns raw block assignment difference
	 */
	private getRawAssignmentDifference = (blockTimeDifferenceValue: string): number => {
		// Default return value high to ensure all non values are grouped together
		let rawAssignmnentDifference: number = Number.MAX_VALUE;

		if (blockTimeDifferenceValue !== '—') {
			rawAssignmnentDifference = parseInt(blockTimeDifferenceValue, 10);
		}

		return rawAssignmnentDifference;
	};

	/**
	 * determines and returns translated text of the supplied blocks loading
	 *
	 * @returns blocks loading text
	 */
	private getBlocksLoadingText = (): string => {
		let loadingText: string = '';

		switch (this.reportForm.controls.effectiveDateRangeDayType.value) {
			case EffectiveDateRangeDayType.today:
				loadingText = this.translations['T_REPORT.LOADING_TEXT_BLOCKS_TODAY'];
				break;
			case EffectiveDateRangeDayType.yesterday:
				loadingText = this.translations['T_REPORT.LOADING_TEXT_BLOCKS_YESTERDAY'];
				break;
			case EffectiveDateRangeDayType.specific:
				loadingText =
					this.translations['T_REPORT.LOADING_TEXT_BLOCKS'] +
					' ' +
					this.getDateTimeDisplay(this.reportForm.controls.specificDate.value);
				break;
		}

		loadingText += ' ...';

		return loadingText;
	};
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRouterModule } from './app-router.module';

import { UtilsModule } from '@cubicNx/libs/utils';
import { HomeModule } from './home/home.module';
import { LoginModule } from './support-features/login/login.module';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main/main.component';

@NgModule({
	imports: [BrowserModule, AppRouterModule, UtilsModule, HomeModule, LoginModule],
	declarations: [AppComponent, MainComponent],
	providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
	bootstrap: [AppComponent],
})
export class AppModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { ReportsUtilService } from '../../services/reports-util.service';
import { ReportsConfigService } from '../../services/reports-config.service';

import {
	ReportConfigurationsByCategory,
	AgencyReportCustomization,
	ReportConfiguration,
	ReportTemplateDisplayDetail,
	ReportTemplateDisplayDetailsByCategory,
} from '../../types/types';

@Component({
	selector: 'default-report-templates',
	templateUrl: './default-report-templates.component.html',
	styleUrls: ['./default-report-templates.component.scss'],
})
export class DefaultReportTemplatesComponent extends TranslateBaseComponent implements OnInit {
	@Output() templateSelected: EventEmitter<string> = new EventEmitter<string>();

	public templateDisplayDatailsByCategory: ReportTemplateDisplayDetailsByCategory = {};
	public totalTemplates: number = 0;

	constructor(
		private reportsUtilService: ReportsUtilService,
		private reportsConfigService: ReportsConfigService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the default report template component - loads display data
	 */
	public ngOnInit(): void {
		this.loadTemplateDisplayData(null);
	}

	/**
	 * loads the template display data based upon the supplied search text
	 *
	 * @param searchText - the search text
	 */
	public search = (searchText: string): void => {
		this.loadTemplateDisplayData(searchText);
	};

	/**
	 * handles a report template being selected - emits to the parent the id of the selected template
	 *
	 * @param reportItem - report item
	 */
	public handleTemplateSelected = (reportItem: ReportTemplateDisplayDetail): void => {
		this.templateSelected.emit(reportItem.defaultTemplateId);
	};

	/**
	 * builds the list of reports by category for display purposes allowing for any overrides set by the agency
	 * @param searchText - the search filter text
	 */
	private loadTemplateDisplayData = (searchText: string): void => {
		const reportConfigurationsByCategory: ReportConfigurationsByCategory = this.getDefaultReportTemplatesByCategory(searchText);

		this.totalTemplates = 0;

		Object.keys(reportConfigurationsByCategory).forEach((categoryKey: string) => {
			this.templateDisplayDatailsByCategory[categoryKey] = [];

			reportConfigurationsByCategory[categoryKey].forEach((report: ReportConfiguration) => {
				const reportCustomization: AgencyReportCustomization = this.reportsUtilService.getAgencyReportCustomization(
					report.defaultTemplateId
				);

				const reportTemplateDisplayDetails: ReportTemplateDisplayDetail = {
					defaultTemplateId: report.defaultTemplateId,
					enabled: reportCustomization.featureEnabled,
					code: report.code,
					outputType: this.getOutputType(report.outputType),
					name: this.getReportName(report.defaultTemplateId, reportCustomization.customName),
					description: this.getReportDescription(report.defaultTemplateId, reportCustomization.customDescription),
				};

				this.templateDisplayDatailsByCategory[categoryKey].push(reportTemplateDisplayDetails);

				this.totalTemplates++;
			});
		});
	};

	/**
	 * gets the reports from the reports config service by cateogory, filtering if there is search text
	 *
	 * @param search - the search criteria
	 * @returns default report templates by category filtered by the supplied search criteria
	 */
	private getDefaultReportTemplatesByCategory = (search: string): ReportConfigurationsByCategory => {
		const reportTemplates: ReportConfigurationsByCategory = this.reportsConfigService.getReportsConfigurationsByCategory();

		if (search !== null && search !== '') {
			Object.keys(reportTemplates).forEach((categoryKey: string) => {
				reportTemplates[categoryKey] = reportTemplates[categoryKey].filter(
					(report) =>
						report.category.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
						report.code.toLowerCase().indexOf(search.toLowerCase()) !== -1
				);
			});
		}

		return reportTemplates;
	};

	/**
	 * gets the translated output type
	 *
	 * @param outputType - the report output type
	 * @returns the translated report output type
	 */
	private getOutputType = (outputType: string): string => {
		return this.getTranslation('T_REPORT.' + outputType.toUpperCase());
	};

	/**
	 * determines if a custom name is set and returns it, otherwise returns the default config name
	 *
	 * @param defaultTemplateId - the default template id
	 * @param customName - the custom name
	 * @returns the report name - custom name takes precedence
	 */
	private getReportName = (defaultTemplateId: string, customName: string): string => {
		return customName ? customName : this.getTranslation('T_REPORT.TEMPLATES.' + defaultTemplateId.toUpperCase() + '.NAME');
	};

	/**
	 * determines if a custom description is set and returns it, otherwise returns the default config description
	 *
	 * @param defaultTemplateId - the default template id
	 * @param customDescription - the custom description
	 * @returns the report description - custom description takes precedence
	 */
	private getReportDescription = (defaultTemplateId: string, customDescription: string): string => {
		return customDescription
			? customDescription
			: this.getTranslation('T_REPORT.TEMPLATES.' + defaultTemplateId.toUpperCase() + '.DESCRIPTION');
	};
}

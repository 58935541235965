/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { SIGNS_STORE } from './signs-store-factory';

import { LoggerService } from '@cubicNx/libs/utils';
import { SignsApiService } from './signs-api.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { Signs } from '../types/types';

@Injectable()
export class SignsDataService {
	constructor(
		private logger: LoggerService,
		private signsApiService: SignsApiService,
		@Inject(SIGNS_STORE) private signsDataStore: any
	) {}

	/**
	 * handles the request for the signs for the given authority/agency from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns response containing signs
	 */
	public getSigns = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + agencyId;

			let signs: Signs = this.signsDataStore.get(storeKey);

			if (!signs) {
				this.logger.logDebug(`Requesting signs for: ${storeKey}`);

				signs = await this.signsApiService.getSigns(authorityId, agencyId);

				this.signsDataStore.set(storeKey, signs);
			} else {
				this.logger.logDebug(`Returning signs for: ${storeKey} from cached store`);
			}

			result.success = true;
			result.resultData = signs as Signs;
		} catch (exception) {
			this.logger.logError('Failed to get signs', exception);
		}

		return result;
	};
}

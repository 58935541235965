<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="isConfigured">
				<div *ngIf="hasResults">
					<div *ngFor="let tweet of tweets; last as isLast">
						<div class="nb-text" [ngClass]="!isLast ? 'twitter-container' : 'twitter-container-noborder'">
							<div class="nb-padding-all-sm nb-tweet" (click)="openTwitterToMention(tweet.id_str, tweet.user.screen_name)">
								<div class="twitter-profile" *ngIf="data.config.showUserPicture">
									<img
										src="{{ tweet.user.profile_image_url_https }}"
										class="nb-twitter-profile"
										alt="{{ 'T_DASH.DB_USER_PICTURE' | translate }}"
										data-test="tweet.picture" />
								</div>

								<div class="nb-text twitter-body">
									<div>
										<span class="nb-title-sm">{{ tweet.user.name }}</span>
										<span class="twitterhandle">{{ ' @' + tweet.user.screen_name }}</span>
									</div>
									<div>{{ tweet.text }}</div>
									<div class="nb-timestamp" data-test="tweet.date">{{ tweet.created_at }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!hasResults && !errorMessage">
					<div class="no-data text-center" data-test="tweet.no-data">
						{{ 'T_DASH.DB_THERE_ARE_NO_TWEETS_TO_DISPLAY' | translate }}
					</div>
				</div>
				<div *ngIf="!hasResults && errorMessage">
					<div class="no-data text-center" data-test="server.error">
						{{ errorMessage }}
					</div>
				</div>
			</div>
			<div *ngIf="!isConfigured">
				<div class="no-data text-center" data-test="tweet.not-configured">
					{{ 'T_DASH.DB_TWITTER_CONFIGURATION_BAD' | translate }}
				</div>
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnInit } from '@angular/core';

import { AgenciesDataService } from '../../../../../../../support-features/agencies/services/agencies-data.service';

import { Agencies, Agency } from '../../../../../../../support-features/agencies/types/api-types';
import { AdherenceThresholdOverride } from '../../../../../types/api-types';

@Component({
	selector: 'adherence-display',
	templateUrl: './adherence-display.component.html',
	styleUrls: ['./adherence-display.component.scss'],
})
export class AdherenceDisplayComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() adherence: string = null;
	@Input() adherenceOptions: AdherenceThresholdOverride = null;

	public style: { 'background-color': string };

	private adherenceSeconds: number = null;
	private agency: any = null;

	constructor(private agenciesService: AgenciesDataService) {}

	/**
	 * performs initialization tasks for the adherence display components
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * initializes the display details for adherence related components
	 * (where there are adherence related reports with adherence values in a particuar column)
	 */
	private init = (): void => {
		const agencies: Agencies = this.agenciesService.getAgencies();

		this.adherenceSeconds = this.convertFromAdherenceToSecondsAdherence(this.adherence);

		this.agency = agencies.filter((agency: Agency) => agency.authority_id === this.authorityId && agency.agency_id === this.agencyId);

		if (Array.isArray(this.agency) && this.agency.length > 0) {
			this.agency = this.agency[0];

			const {
				adherence_setting_early_min_sec,
				adherence_setting_very_early_sec,
				adherence_setting_late_min_sec,
				adherence_setting_very_late_sec,
			} = this.adherenceOptions.adherence_threshold_override ? this.adherenceOptions.adherence_threshold_settings : this.agency;

			this.style = {
				'background-color': 'transparent',
			};

			if (this.adherenceSeconds <= 0) {
				// Late
				const adh: number = -1 * this.adherenceSeconds;

				if (adh >= adherence_setting_very_late_sec) {
					this.style = {
						'background-color': 'rgba(255,239,112,.50)',
					};
				} else if (adh >= adherence_setting_late_min_sec) {
					this.style = {
						'background-color': 'rgba(255,239,112,.15)',
					};
				}
			} else {
				// Early
				if (this.adherenceSeconds >= adherence_setting_very_early_sec) {
					this.style = {
						'background-color': 'rgba(255,144,144,.50)',
					};
				} else if (this.adherenceSeconds >= adherence_setting_early_min_sec) {
					this.style = {
						'background-color': 'rgba(255,144,144,.15)',
					};
				}
			}
		}
	};

	/**
	 * converts from adherence value to adherence seconds
	 *
	 * @param value - the value
	 * @returns derived adherence value from the supplied text value
	 */
	private convertFromAdherenceToSecondsAdherence = (value: string): number => {
		const valueArray: string[] = value.split(':');
		const seconds: number = parseInt(valueArray.pop(), 10) * 1;
		const minutes: number = parseInt(valueArray.pop(), 10) * 60;
		const hoursAsString: string = valueArray.pop();

		let hours: number = parseInt(hoursAsString, 10);
		let total: number = 0;

		if (hoursAsString.indexOf('-') > -1) {
			if (hours < 0) {
				hours = hours * -1;
			}

			hours = hours * 60 * 60;
			total = (seconds + minutes + hours) * -1;
		} else {
			hours = hours * 60 * 60;
			total = seconds + minutes + hours;
		}

		return total;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import Moment from 'moment-timezone';

declare let d3: any;

@Component({
	selector: 'd3-wrapper',
	templateUrl: './d3-wrapper.component.html',
	styleUrls: ['./d3-wrapper.component.scss'],
})
export class D3WrapperComponent extends TranslateBaseComponent implements AfterViewInit, OnChanges {
	@Input() data: any = null;

	@ViewChild('d3wrapper') private chartElement: ElementRef;

	private readyToRender: boolean = false;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * re -renders the chart when it is resized
	 */
	@HostListener('window:resize', ['$event'])
	public onResize(): void {
		if (this.readyToRender) {
			this.clearExistingGraph();
			this.render();
		}
	}

	/**
	 * handles initializes actions once the chart has rendered
	 *
	 * re-renders the chart
	 */
	public ngAfterViewInit(): void {
		this.readyToRender = true;
		this.render();
	}

	/**
	 * handles changes to the input params
	 *
	 * re-renders the chart
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data.currentValue && this.readyToRender) {
			this.render();
		}
	}

	/**
	 * clears down the exisitng graph
	 */
	private clearExistingGraph = (): void => {
		// This ensures the svg d3 tag is cleared down when the page is resized,
		// otherwise it would keep increasing the size of the underlying DOM during resizes.
		while (this.chartElement.nativeElement.hasChildNodes()) {
			this.chartElement.nativeElement.removeChild(this.chartElement.nativeElement.firstChild);
		}
	};

	/**
	 * renders the graph
	 */
	private render = (): void => {
		const containerWidth: number = this.chartElement.nativeElement.clientWidth;
		const containerHeight: number = 300;
		const margin: any = { top: 30, right: 20, bottom: 50, left: 60 };
		const width: number = containerWidth - margin.left - margin.right;
		const height: number = containerHeight - margin.top - margin.bottom;
		const columnCount: number = this.data[0].values.length;

		const x: any = d3.scale.ordinal().rangeRoundBands([0, width], 0.05);
		const y: any = d3.scale.linear().rangeRound([height, 0]);

		const color: any = d3.scale.category20();

		const xAxis: any = d3.svg
			.axis()
			.scale(x)
			.orient('bottom')
			.ticks(5)
			.tickPadding(1)
			.tickFormat((d: any) => d3.time.format('%a %m / %d')(Moment(d).toDate()));

		const yAxis: any = d3.svg
			.axis()
			.scale(y)
			.orient('left')
			.ticks(5)
			.tickPadding(1)
			.tickFormat((d: any) => d + '%');

		// Need to made the root d3 selection. this should isolate the selection to only include children of this directive;
		// Made a d3 selection out of the wrapper and append the root svg tag.

		const svg: any = d3
			.select(this.chartElement.nativeElement)
			.append('svg')
			.attr('class', 'nvd3-svg nvd3')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('class', 'nv-barsWrap nvmultibar')
			.append('g')
			.attr('class', 'nvd3 nv-wrap nv-multibar')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		const formattedData: any[] = [];

		this.data.forEach((stackItem: any) => {
			formattedData.push(stackItem.values);
		});

		const dataStackLayout: any = d3.layout.stack()(formattedData);

		x.domain(dataStackLayout[0].map((d: any) => d.x));

		y.domain([0, 100]).nice();

		const groups: any = svg.append('g').attr('class', 'nv-groups');

		const layer: any = groups
			.selectAll('.stack')
			.data(dataStackLayout)
			.enter()
			.append('g')
			.attr('class', 'stack nv-group')
			.style('fill-opacity', '0.75')
			.style('fill', (d: any, i: any) => color(String(i)))
			.style('stroke', (d: any, i: any) => color(String(i)))
			.style('stroke-opacity', '1');

		const tooltip: any = d3
			.select('body')
			.append('div')
			.style('position', 'absolute')
			.style('z-index', '1000')
			.style('left', '400px')
			.style('top', '400px')
			.style('padding', '10px')
			.style('display', 'none')
			.style('opacity', 0)
			.style('background', '#fff')
			.style('border', '1px solid #666')
			.text('a simple tooltip');

		layer
			.selectAll('rect')
			.data((d: any) => d)
			.enter()
			.append('rect')
			.attr('class', 'nv-bar')
			.attr('data-test', 'bar')
			.style('fill', (d: any) => d.color)
			.style(
				'stroke',
				() =>
					// Return d.color;
					'#fff'
			)
			.style('stroke-opacity', 0.5)
			.style('stroke-width', '1')
			.attr('x', (d: any) => x(d.x))
			.attr('y', (d: any) => y(d.y + d.y0))
			.attr('height', (d: any) => y(d.y0) - y(d.y + d.y0))
			.attr('width', x.rangeBand())

			.on('mousemove', () => tooltip.style('top', (<any>d3.event).pageY - 10 + 'px').style('left', (<any>d3.event).pageX + 10 + 'px'))
			.on('mouseover', (d: any) => {
				tooltip.html(this.getToolTipText(d));
				tooltip.transition().duration(200).style('opacity', 0.9).style('display', 'block');

				const self: any = this;

				// workaround to avoid console error with d3 module
				self.setAttribute = (): any => {};

				d3.select(self).attr('class', 'nv-bar hover');
			})
			.on('mouseout', () => {
				tooltip.transition().duration(200).style('opacity', 0).style('display', 'none');

				const self: any = this;

				d3.select(self).attr('class', 'nv-bar');
			});

		const xGroup: any = svg
			.append('g')
			.attr('class', 'axis nv-x nv-axis nvd3-svg')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('data-test', 'report.datebar')
			.call(xAxis);

		svg.append('g').attr('class', 'axis nv-y nv-axis nvd3-svg').call(yAxis);

		const xTicks: any = xGroup.selectAll('g');

		xTicks.selectAll('line, text').style('opacity', 1);

		xTicks
			.filter((d: any, i: any) => i % Math.ceil(columnCount / (width / 100)) !== 0)
			.selectAll('text, line')
			.style('opacity', 0);
	};

	/**
	 * determines and retrieves the tooltip text for supplied graph coordinate
	 *
	 * @param d - the object from the graph
	 * @returns tooltip
	 */
	private getToolTipText = (d: any): string => {
		let retVal: string =
			"<div style='font-weight:bold' data-test='bar.popup.date'>" + d3.time.format('%a %m / %d')(Moment(d.x).toDate()) + '</div>';

		this.data.forEach((stackItem: any) => {
			const stackValue: any = stackItem.values.find((item: any) => item.x === d.x);

			const stackHtml: string =
				'<div data-test="bar.popup.item">' +
				"<div style='float:left;width:12px;height:12px;margin-top:4px;margin-right:10px;background-color:" +
				stackValue.color +
				"'></div><span>" +
				stackItem.key +
				"</span><span style='float:right;padding-left:20px;font-weight:bold'>" +
				stackValue.y +
				' %</span></div>';

			retVal += stackHtml;
		});

		return retVal;
	};
}

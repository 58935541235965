/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';

import { IWidgetComponent } from '../../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	OccupancyWidgetData,
	OccupancyWidgetDataListValues,
	OccupancyWidgetDataSvgDisplay,
} from '../../../../../support-features/routes/types/api-types';

@Component({
	selector: 'occupancy',
	templateUrl: './occupancy.component.html',
	styleUrls: ['./occupancy.component.scss'],
})
export class OccupancyComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;
	public loaded: boolean = false;
	public hasResults: boolean = false;
	public success: boolean = false;
	public multicolumn: boolean = false;

	public svgDisplay: OccupancyWidgetDataSvgDisplay = null;
	public listValues: OccupancyWidgetDataListValues = null;

	private listInterval: any = null;

	constructor(
		private widgetEventsService: WidgetEventsService,
		private routesDataService: RoutesDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the occupance component - setting up subscriptions, loading widget data
	 */
	public ngOnInit(): void {
		this.setSubscriptions();

		this.setup();

		this.getData();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * returns flex direction styling dependant upon multicolumn data or not
	 *
	 * @returns flex direction styling
	 */
	public flexDirection = (): any => {
		return { 'flex-direction': this.multicolumn ? 'column' : 'row' };
	};

	/**
	 * determines whether the supplied display is in the widget configuration data
	 *
	 * @param display - display
	 * @returns true if the supplied display is in the widget configuration
	 */
	public isDisplayOptionSet = (display: string): boolean => {
		return this.data.config.display === display;
	};

	/**
	 * Setup the component properties.
	 */
	private setup = (): void => {
		this.multicolumn = this.rowData.columns.length > 1;
	};

	/**
	 * handles getting data and setting up intervals.
	 */
	private getData = async (): Promise<void> => {
		clearInterval(this.listInterval);
		const refreshRate: number = (this.data.config.refreshRateSec || 30) * 1000;

		await this.getOccupancy();

		this.listInterval = setInterval(this.getOccupancy, refreshRate);
	};

	/**
	 * Gets the occupancy data.
	 */
	private getOccupancy = async (): Promise<void> => {
		this.loaded = false;

		const routeList: any =
			this.data.config.selectedRoutes?.length > 0 ? this.data.config.selectedRoutes?.map((i: any) => i.route_id) : '*';

		const response: ResultContent = await this.routesDataService.getOccupancy(
			this.data.config.selectedAgency.authority_id,
			this.data.config.selectedAgency.agency_id,
			routeList,
			this.data.config.display.toLowerCase().indexOf('list') > -1
		);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.hasResults = false;
		this.success = false;

		if (response.success) {
			const results: OccupancyWidgetData = response.resultData;

			this.svgDisplay = results.svgDisplay;
			this.listValues = results.listValue;
			this.hasResults = this.svgDisplay?.total > 0 || this.listValues?.filter((i) => i.occupancy?.total > 0).length > 0;
			this.success = true;
		}

		this.loaded = true;
	};

	/**
	 * Setup subscriptions to observables.
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.getData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.listInterval) {
			clearInterval(this.listInterval);
		}

		this.reloadWidget$Subscription?.unsubscribe();
	};
}

<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".-->

<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

<panel-header [showBackButton]="false" [heading]="'T_CORE.USERS' | translate"> </panel-header>

<div class="header-container">
	<button (click)="create()" [disabled]="isPasswordAdminUser" class="btn btn-action btn-action-users">
		<i class="nb-icons nb-add"></i>
		{{ 'T_CORE.CREATE' | translate }}
	</button>
	<button (click)="edit()" [disabled]="!selectedUser" class="btn btn-action btn-action-users">
		<i class="nb-icons nb-edit"></i>
		{{ 'T_CORE.EDIT' | translate }}
	</button>
	<button (click)="deactivate()" *ngIf="!selectedUserDeactivated" [disabled]="!selectedUser" class="btn btn-action btn-action-users">
		<i class="nb-icons nb-deactivate"></i>
		{{ 'T_USER.DEACTIVATE' | translate }}
	</button>
	<button (click)="enable()" *ngIf="selectedUserDeactivated" [disabled]="!selectedUser" class="btn btn-action btn-action-users">
		<i class="nb-icons nb-enable"></i>
		{{ 'T_USER.ENABLE' | translate }}
	</button>
	<div class="float-right">
		<search-filter [filterValue]="searchText" (search)="search($event)"></search-filter>

		<button class="btn btn-action" (click)="openFilter()">
			<i class="fa nb-margin-right-sm" [ngClass]="{ 'fa-circle-o': !hasFilters, 'fa-circle': hasFilters }"></i>
			<span class="filter-text">{{ 'T_CORE.FILTER' | translate }}</span>
		</button>

		<span class="tooltip-arrow-top">
			<button *ngIf="hasFilters" class="btn btn-action nb-unfiltered-ctrl-text ng-binding clear-btn" (click)="clearFilter()">
				<i class="nb-icons nb-clearfield nb-margin-right-sm">
					<span class="tooltiptext">{{ 'T_CORE.CLEAR_ALL_FILTERS' | translate }}</span> </i
				>{{ 'T_CORE.CLEAR' | translate }}
			</button>
		</span>
	</div>
</div>

<div class="data-list-container" *ngIf="initailized">
	<data-table
		data-test="users.list.table"
		[listName]="listName"
		[columns]="columns"
		[rowData]="usersList"
		[pagingEnabled]="true"
		[totalRows]="totalRows"
		[defaultSortings]="defaultSortings"
		[pageInfo]="pageInfo"
		[enableCheckRowSelection]="true"
		[enabledMultiCheckRowSelection]="false"
		[enableMenuSelection]="true"
		[enableFooter]="true"
		[loadingIndicator]="listLoadingIndicator"
		(rowClick)="onSelect($event)"
		(checkSelect)="onCheckSelect($event)"
		(exportCsv)="onExportCsv()"
		(requestData)="handleDataRequest($event)">
	</data-table>
</div>

<div *ngIf="!editMode">
	<div *ngIf="!hasWidgets()" class="add-widget">
		<div class="add-widget-button-container">
			<div class="add-widget-text">
				{{ 'T_DASH.NO_WIDGETS_FOUND' | translate }}
			</div>
			<button class="btn btn-sm btn-primary" (click)="addWidget(0, 0)" data-test="dashboard.add_widget">
				<i class="ng-icon ng-add"></i>
				{{ 'T_DASH.DB_ADD_WIDGET' | translate }}
			</button>
		</div>
	</div>
	<div *ngIf="hasWidgets()" class="dashboard">
		<div *ngFor="let row of dashboard.config.rows" class="row">
			<div *ngFor="let column of row.columns" class="{{ column.styleClass }} column">
				<div *ngFor="let widget of column.widgets">
					<widget-container [widget]="widget" [rowData]="row" (widgetConfigUpdated)="handleWidgetConfigUpdated($event)"></widget-container>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="editMode" [@modalSlideUp]="animationState">
	<div class="drag-dashboard" cdkDropListGroup>
		<div *ngFor="let row of dashboard.config.rows; index as rowIndex" class="row">
			<div *ngFor="let column of row.columns; index as columnIndex" class="column {{ column.styleClass }}">
				<div class="inner">
					<div class="widgets" id="{{ column.cid }}" cdkDropList (cdkDropListDropped)="dropWidget($event)">
						<div *ngIf="column.widgets.length > 0">
							<div *ngFor="let widget of column.widgets; index as widgetIndex">
								<div
									id="{{ widget.wid }}"
									class="widget-container"
									cdkDrag
									[cdkDragData]="{ widget: widget, row: rowIndex, column: columnIndex, widgetIndex: widgetIndex }">
									<div class="widget">
										<widget-header
											[title]="widget.title"
											[agencyName]="widget.config.selectedAgency?.agency_name"
											[editMode]="editMode"
											(deleteWidgetClicked)="deleteWidget(widget)">
										</widget-header>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="column.widgets.length === 0" class="empty-widget"></div>
					</div>
					<div class="button">
						<button class="btn btn-sm btn-primary" (click)="addWidget(rowIndex, columnIndex)" data-test="dashboard.add_widget">
							<i class="ng-icon ng-add"></i>
							{{ 'T_DASH.DB_ADD_WIDGET' | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ConfigService } from '@cubicNx/libs/utils';
import { ReportsStateService } from '../services/reports-state.service';
import { ReportsConfigService } from '../services/reports-config.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { DefaultPagination, Pagination, PaginationSettings } from '@cubicNx/libs/utils';
import { PageRequestInfo } from '@cubicNx/libs/utils';

import {
	ActionAllowedType,
	ReportConfiguration,
	ReportTemplateDisplayDetail,
	ReportTemplateDisplayDetailAction,
	ReportTemplateDisplayDetails,
	ReportTemplateDisplayDetailsByCategory,
} from '../types/types';

@Component({
	selector: 'saved-report-template-selector',
	templateUrl: './saved-report-template-selector.component.html',
	styleUrls: ['./saved-report-template-selector.component.scss'],
})
export class SavedReportTemplateSelectorComponent extends TranslateBaseComponent implements OnInit, OnChanges {
	@Input() templatesByCategory: ReportTemplateDisplayDetailsByCategory = null;
	@Input() totalTemplates: number = 0;
	@Input() checkboxesEnabled: boolean = false;
	@Input() paginationEnabled: boolean = false;
	@Input() pageInfo: PageRequestInfo = null;

	@Output() requestTemplates: EventEmitter<PageRequestInfo> = new EventEmitter<PageRequestInfo>();
	@Output() templateSelected: EventEmitter<ReportTemplateDisplayDetail> = new EventEmitter<ReportTemplateDisplayDetail>();
	@Output() searchTemplates: EventEmitter<string> = new EventEmitter<string>();

	@Output() actionClicked: EventEmitter<ReportTemplateDisplayDetailAction> = new EventEmitter<ReportTemplateDisplayDetailAction>();

	public pagination: Pagination = { ...DefaultPagination };
	public paginationSettings: PaginationSettings = null;

	public viewButtonEnabled: boolean = false;
	public viewButtonTooltip: string = '';
	public editButtonEnabled: boolean = false;
	public editButtonTooltip: string = '';
	public runButtonEnabled: boolean = false;
	public runButtonTooltip: string = '';
	public deleteButtonEnabled: boolean = false;
	public deleteButtonTooltip: string = '';
	public disabledTooltip: string = '';

	private checkedReportTemplates: ReportTemplateDisplayDetails = [];

	constructor(
		private configService: ConfigService,
		private logger: LoggerService,
		private reportsStateService: ReportsStateService,
		private reportsConfigService: ReportsConfigService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * init method - setup initial paging and translations
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.disabledTooltip = this.translations['T_REPORT.FEATURE_DISABLED'];

		if (this.paginationEnabled) {
			this.paginationSettings = {
				pageSize: this.pageInfo.pageSize,
				maxPageSize: this.configService.getPaginationMaxSize(),
			};

			this.pagination.totalItems = this.totalTemplates;
		}
	}

	/**
	 * handles any change to the input parameters,
	 *
	 * Tracks the current template count for the paging when the templates data set changes
	 *
	 * Handles the selected checked template data list and clears as appropriate following template data changes
	 * i.e. when a new page or page size is set (or reloaded i.e following edit/delete)
	 *
	 * @param changes - object dertailing the input changes
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.totalTemplates && changes.totalTemplates.currentValue !== null) {
			this.pagination.totalItems = changes.totalTemplates.currentValue;
			this.pagination = { ...this.pagination }; // force change detection
		}

		if (changes.templatesByCategory && changes.templatesByCategory.currentValue !== null) {
			if (this.checkboxesEnabled) {
				let index: number = this.checkedReportTemplates.length - 1;

				// only keep selected what was previously selected and exists in the updated data set
				// work backwords as if we remove an item in the array using splice, the indices will change
				this.checkedReportTemplates
					.slice()
					.reverse()
					.forEach((selectedTemplate: ReportTemplateDisplayDetail) => {
						let templateFound: boolean = false;

						Object.keys(changes.templatesByCategory.currentValue).forEach((categoryKey: string) => {
							if (
								changes.templatesByCategory.currentValue[categoryKey].find(
									(template: ReportTemplateDisplayDetail) => template.nbId === selectedTemplate.nbId
								)
							) {
								templateFound = true;
							}
						});

						if (!templateFound) {
							this.checkedReportTemplates.splice(index, 1);
						}

						index--;
					});

				this.determineButtonStates();
			}
		}
	}

	/**
	 * searches the saved template reports for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = (searchText: string): void => {
		if (this.paginationEnabled) {
			// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
			// one when intiating a search

			this.pageInfo.pageNum = 1; // updates the request
			this.pagination.currentPage = 1; // updates the control
		}

		this.searchTemplates.emit(searchText);
	};

	/**
	 * public button handler to view a template
	 */
	public viewTemplate = (): void => {
		// There can only be one selected for viewing so use the first entry
		const selectedTemplate: ReportTemplateDisplayDetail = this.checkedReportTemplates[0];

		if (selectedTemplate) {
			const reportTemplateDisplayDetailAction: ReportTemplateDisplayDetailAction = {
				action: 'VIEW',
				reportTemplateDisplayDetails: [selectedTemplate],
			};

			this.actionClicked.emit(reportTemplateDisplayDetailAction);
		}
	};

	/**
	 * public button handler to edit a template
	 */
	public editTemplate = (): void => {
		// There can only be one selected for viewing so use the first entry
		const selectedTemplate: ReportTemplateDisplayDetail = this.checkedReportTemplates[0];

		if (selectedTemplate) {
			const reportTemplateDisplayDetailAction: ReportTemplateDisplayDetailAction = {
				action: 'EDIT',
				reportTemplateDisplayDetails: [selectedTemplate],
			};

			this.actionClicked.emit(reportTemplateDisplayDetailAction);
		}
	};

	/**
	 * public button handler to run 1 or more reports
	 */
	public runReports = (): void => {
		const selectedTemplates: ReportTemplateDisplayDetails = this.checkedReportTemplates;

		if (selectedTemplates) {
			const reportTemplateDisplayDetailAction: ReportTemplateDisplayDetailAction = {
				action: 'RUN',
				reportTemplateDisplayDetails: selectedTemplates,
			};

			this.actionClicked.emit(reportTemplateDisplayDetailAction);
		}
	};

	/**
	 * public button handler to delete 1 or more reports
	 */
	public deleteTemplates = (): void => {
		const selectedTemplates: ReportTemplateDisplayDetails = this.checkedReportTemplates;

		if (selectedTemplates) {
			const reportTemplateDisplayDetailAction: ReportTemplateDisplayDetailAction = {
				action: 'DELETE',
				reportTemplateDisplayDetails: selectedTemplates,
			};

			this.actionClicked.emit(reportTemplateDisplayDetailAction);
		}
	};

	/**
	 * public handler when the user selects a template (emit to parent to handle specific use case)
	 *
	 * @param templateItem - the report template detail item
	 */
	public selectTemplate = (templateItem: ReportTemplateDisplayDetail): void => {
		const reportConfigAvailable: ReportConfiguration = this.reportsConfigService.getReportConfiguration(templateItem.defaultTemplateId);

		if (templateItem.enabled && reportConfigAvailable) {
			this.templateSelected.emit(templateItem);
		}
	};

	/**
	 * fires when a template checkbox is selected and updates the selected list as appropriate
	 *
	 * Tracks the current template count for the paging when the templates data set changes
	 *
	 * Handles the selected checked template data list and clears as appropriate following template data changes
	 * i.e. when a new page or page size is set (or reloaded i.e following edit/delete)
	 *
	 * @param evt - the event object containing the checked state
	 * @param checkedReportItem - our current report item that was checked containing the id etc
	 */
	public checkTemplate(evt: any, checkedReportItem: ReportTemplateDisplayDetail): void {
		if (evt.target.checked) {
			this.checkedReportTemplates.push(checkedReportItem);
		} else {
			this.checkedReportTemplates = this.checkedReportTemplates.filter(
				(reportItem: ReportTemplateDisplayDetail) => reportItem.nbId !== checkedReportItem.nbId
			);
		}

		this.determineButtonStates();
	}

	/**
	 * determines the current checked state of the checkbox bases on the template passed in by checking
	 * the list of selected templates
	 *
	 * @param templateItem - our current report item that is being determined for its checked state
	 * @returns true if the supplied template has been check, false otherwise
	 */
	public templateChecked = (templateItem: ReportTemplateDisplayDetail): ReportTemplateDisplayDetail => {
		return this.checkedReportTemplates.find((template) => template.nbId === templateItem.nbId);
	};

	/**
	 * fires when the page number is changed by the user then requests the appropriate data
	 */
	public pageChanged = async (): Promise<void> => {
		this.requestTemplateData();
	};

	/**
	 * fires when the page size is changed by the user then requests the appropriate data
	 */
	public setPageSize = async (): Promise<void> => {
		this.requestTemplateData();
	};

	/**
	 * loads the translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_REPORT.NO_ROW_SELECTED',
			'T_REPORT.TOO_MANY_ROWS_SELECTED',
			'T_REPORT.FEATURE_DISABLED',
			'T_CORE.NEED_EDIT_PERMISSION',
			'T_CORE.NEED_DEL_PERMISSION',
		]);
	};

	/**
	 * fires a request to the parent to get the required data with the current paging values set
	 */
	private requestTemplateData = async (): Promise<void> => {
		this.pageInfo = {
			pageNum: this.pagination.currentPage,
			pageSize: this.paginationSettings.pageSize,
		};

		this.requestTemplates.emit(this.pageInfo);
	};

	/**
	 * determines button enabled state and tooltip text based on the selected templates based on whether the feature is enabled
	 * and user permissions
	 */
	private determineButtonStates = (): void => {
		const viewActionAllowed: ActionAllowedType = this.reportsStateService.canViewReportTemplates(this.checkedReportTemplates);

		this.viewButtonEnabled = viewActionAllowed === ActionAllowedType.allowed;
		this.viewButtonTooltip = this.determineViewTooltip(viewActionAllowed);

		const editActionAllowed: ActionAllowedType = this.reportsStateService.canEditReportTemplates(this.checkedReportTemplates);

		this.editButtonEnabled = editActionAllowed === ActionAllowedType.allowed;
		this.editButtonTooltip = this.determineEditTooltip(editActionAllowed);

		const runActionAllowed: ActionAllowedType = this.reportsStateService.canRunReportTemplates(this.checkedReportTemplates);

		this.runButtonEnabled = runActionAllowed === ActionAllowedType.allowed;
		this.runButtonTooltip = this.determineRunTooltip(runActionAllowed);

		const deleteActionAllowed: ActionAllowedType = this.reportsStateService.canDeleteReportTemplates(this.checkedReportTemplates);

		this.deleteButtonEnabled = deleteActionAllowed === ActionAllowedType.allowed;
		this.deleteButtonTooltip = this.determineDeleteTooltip(deleteActionAllowed);
	};

	/**
	 * determines if viewing saved report template is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if viewing saved report templates is allowed
	 */
	private determineViewTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.tooManySelected:
				tooltip = this.translations['T_REPORT.TOO_MANY_ROWS_SELECTED'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if editing saved report template is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if editing saved report templates is allowed
	 */
	private determineEditTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_EDIT_PERMISSION'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.tooManySelected:
				tooltip = this.translations['T_REPORT.TOO_MANY_ROWS_SELECTED'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if running saved report templates is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if running saved report templates is allowed
	 */
	private determineRunTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if deleting saved report template is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if deleting saved report templates is allowed
	 */
	private determineDeleteTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_DEL_PERMISSION'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};
}

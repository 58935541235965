/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import L, { PointExpression } from 'leaflet';

import { TimeFormatType, TimeHelpers } from '@cubicNx/libs/utils';

import { MapNavigationService } from '../map-navigation.service';
import { MapOptionsService } from '../map-options.service';
import { MapReplayService } from '../map-replay.service';
import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { MapVehiclesService } from '../map-vehicles.service';
import { MapMarkerUtilsService } from './map-marker-utils.service';
import { MapEventsService } from '../map-events.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { VehicleColor } from '../../../../support-features/vehicles/types/types';
import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';

import { EventExtended, HTMLElementExtended, MapModeType, MapVehicle, Markers, VehicleDetailsActiveTab } from '../../types/types';

import moment, { Moment } from 'moment';

@Injectable({
	providedIn: 'root',
})
export class MapVehiclesTooltipMarkerService implements OnDestroy {
	private readonly tooltipCloseDelay: number = 300;
	private mapInstance: L.Map = null;
	private markers: Markers = {};
	private selectedAgency: SelectedAgency = null;
	private removeTooltips$Subscription: Subscription = null;

	constructor(
		private translationService: TranslationService,
		private agenciesDataService: AgenciesDataService,
		private mapVehiclesService: MapVehiclesService,
		private mapOptionsService: MapOptionsService,
		private mapNavigationService: MapNavigationService,
		private mapMarkerUtilsService: MapMarkerUtilsService,
		private mapReplayService: MapReplayService,
		private mapEventsService: MapEventsService
	) {}

	/**
	 * initialize the vehicle tooltip marker service
	 *
	 * @param mapInstance - the current map instance
	 * @param selectedAgency - the current agency
	 */
	public init = (mapInstance: L.Map, selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapInstance = mapInstance;

		this.markers = {};

		this.setSubscriptions();
	};

	/**
	 * handle the agency change and clear down any markers
	 *
	 * @param selectedAgency - the current agency
	 */
	public setAgencyChange = (selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.markers = {};
	};

	/**
	 * handle any cleanup for the service (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.removeTooltips$Subscription.unsubscribe();
	}

	/**
	 * add the vehicle tooltip
	 *
	 * @param vehicleId - the vehicle id
	 */
	public addVehicleTooltip = async (vehicleId: string): Promise<void> => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			// if the tooltip is not already showing...
			if (!this.markers[vehicleId]) {
				// make sure any other tooltips are removed before we add the new one
				this.mapEventsService.publishRemoveTooltips();

				this.markers[vehicleId] = new L.Marker(new L.LatLng(vehicle.lat, vehicle.lon), {
					icon: this.getIcon(vehicle),
					zIndexOffset: 9002,
				}).addTo(this.mapInstance);

				// add in a withinTooltip flag to manage closing of the tooltip
				this.markers[vehicleId]['withinTooltip'] = false;

				this.markers[vehicleId].on('mouseover', () => {
					this.handleMouseOver(vehicleId);
				});

				this.markers[vehicleId].on('mouseout', () => {
					this.handleMouseOut(vehicleId);
				});
			}
		}
	};

	/**
	 * remove the vehicle tooltip
	 *
	 * allow a delay so if we get here from the parent (leaving the stop icon area) but the user enters the
	 * tooltip itself, the withinTooltip will be set and we wont actually close the tooltip
	 *
	 * @param vehicleId - the vehicle id
	 */
	public removeVehicleTooltip = (vehicleId: string): void => {
		setTimeout(() => {
			if (this.markers[vehicleId] && !this.markers[vehicleId]['withinTooltip']) {
				this.removeClickListeners(vehicleId);

				this.markers[vehicleId].remove();

				delete this.markers[vehicleId];
			}
		}, this.tooltipCloseDelay);
	};

	/**
	 * get the vehicle icon
	 *
	 * @param vehicle - the vehicle
	 * @returns a leaflet div icon to display on the map
	 */
	public getIcon = (vehicle: MapVehicle): L.DivIcon => {
		return L.divIcon({
			className: 'nb-map-vehicle-marker-container',
			iconAnchor: this.getIconAnchor() as PointExpression,
			html: this.getTooltipHtml(vehicle),
		});
	};

	/**
	 * get the icon anchor (location) to display the tooltip
	 * @returns the tooltip icon anchor (location) to display the tooltip
	 */
	private getIconAnchor = (): number[] => {
		const markerSize: [number, number] = this.mapMarkerUtilsService.getVehicleMarkerSize();

		return [-0.5 * markerSize[1], -0.5 * markerSize[0]];
	};

	/**
	 * get the tooltip html to display the tooltip
	 *
	 * @param vehicle - the vehicle
	 * @returns the tooltip html to display the tooltip
	 */
	private getTooltipHtml = (vehicle: MapVehicle): string => {
		let vehicleIds: string = '';

		// get list of vehicleIds (list will typically have the 1 vehicle id but could be more when we have linked vehicles)
		vehicle.vehicleIds.forEach((vehicleId: string, index) => {
			vehicleIds += vehicleId;
			if (index !== vehicle.vehicleIds.length - 1) {
				vehicleIds += ',';
			}
		});

		return (
			'' +
			'<div class="vehicle-map-labels" label-vehicle-id="' +
			vehicleIds +
			'">' +
			'<div class="label">' +
			'<div class="flex">' +
			this.getSummaryHtml(vehicle) +
			this.getDetailsHtml(vehicle) +
			'</div>' +
			'</div>' +
			'</div>'
		);
	};

	/**
	 * get the vehicle summary html for the tooltip
	 *
	 * @param vehicle - the vehicle
	 * @returns the vehicle summary html for the tooltip
	 */
	private getSummaryHtml = (vehicle: MapVehicle): string => {
		let html: string = '';

		if (this.mapOptionsService.vehicleLabelsAreShowing()) {
			html = '<div class="label-summary" data-test="map.label">';

			if (this.mapOptionsService.getShowVehicleLabelRoute()) {
				html +=
					'<div style="' +
					this.getRouteStyle(vehicle.routeColor, vehicle.routeName) +
					'" class="label-route truncate" data-test="map.label.route">' +
					vehicle.routeName +
					'</div>';
			}

			if (this.mapOptionsService.getShowVehicleLabelId()) {
				let vehicleIds: string = '';

				// get list of vehicleIds (list will typically have the 1 vehicle id but could be more when we have linked vehicles)
				vehicle.vehicleIds.forEach((vehicleId: string, index) => {
					vehicleIds += vehicleId;
					if (index !== vehicle.vehicleIds.length - 1) {
						vehicleIds += ',';
					}
				});

				html += '<div class="data vehicle-id vehicle-summary-label-id" data-test="map.label.vehicleId">' + vehicleIds + '</div>';
			}

			if (this.mapOptionsService.getShowVehicleLabelBlock()) {
				if (vehicle.blockId === '—') {
					html += '<div class="data emdash" data-test="map.label.blockId">—</div>';
				} else {
					html += '<div class="data block-summary-label-id" data-test="map.label.blockId">' + vehicle.blockId + '</div>';
				}
			}

			if (this.mapOptionsService.getShowVehicleLabelDepartureAdherence()) {
				if (vehicle.adherenceDisplay.time === '—') {
					html += '<div class="data emdash" data-test="map.label.adherence">—</div>';
				} else {
					html +=
						'<div class="data adherence" data-test="map.label.adherence" style="' +
						this.getAdherenceStyle(vehicle.adherenceColor) +
						'">' +
						vehicle.adherenceDisplay.time +
						'</div>';
				}
			}

			if (this.mapOptionsService.getShowVehicleLabelHeadway()) {
				if (vehicle.headwayDisplay) {
					html +=
						'<div class="data headway" data-test="map.label.headway" style="' +
						this.getHeadwayStyle(vehicle.headwayColor) +
						'">' +
						vehicle.headwayDisplay +
						' </div>';
				} else {
					html += '<div class="data emdash" data-test="map.label.headway"> — </div>';
				}
			}

			if (this.mapOptionsService.getShowVehicleLabelStatus()) {
				html += '<div class="data status" data-test="map.label.status">' + vehicle.state + '</div> ';
			}

			if (this.mapOptionsService.getShowVehicleLabelPassengers()) {
				html += this.getRidershipIcon(vehicle.passengers.ridershipEnum, 'summary');
			}

			html += '</div> ';
		}

		return html;
	};

	/**
	 * get the vehicle detail html for the tooltip
	 *
	 * @param vehicle - the vehicle
	 * @returns the vehicle detail html for the tooltip
	 */
	private getDetailsHtml = (vehicle: MapVehicle): string => {
		return (
			'' +
			'<div class="label-details" style="visibility: visible"> ' +
			this.labelDetailsRow1(vehicle) +
			this.labelDetailsRow2(vehicle) +
			this.labelDetailsFooter(vehicle) +
			'</div>'
		);
	};

	/**
	 * get the vehicle detail html (row 1) for the tooltip
	 *
	 * @param vehicle - the vehicle
	 * @returns the vehicle detail (row 1) html for the tooltip
	 */
	private labelDetailsRow1 = (vehicle: MapVehicle): string => {
		let html: string = '<div class="flexrow">';

		// route
		html += '<div class="data-group">';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_CORE.ROUTE') + '</div> ';
		html += '<a id="vehicleRouteId' + vehicle.vehicleId + '_' + vehicle.routeId + 'Click">';

		html +=
			'<div style="' +
			this.getRouteStyle(vehicle.routeColor, vehicle.routeName) +
			'" class="label-route data-value route-id" data-test="tooltip.route">' +
			vehicle.routeName +
			'</div> ';

		html += '</a>';
		html += '</div> ';

		//direction
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_DIRECTION') + '</div> ';

		if (vehicle.direction) {
			html += '<div class="data-value " style="" data-test="tooltip.direction">' + vehicle.direction + '</div> ';
		} else {
			html += '<div class="data-value headway emdash" data-test="tooltip.direction">—</div> ';
		}

		html += '</div> ';

		// adherence
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_ADHERENCE') + '</div> ';

		if (vehicle.adherenceDisplay.time === '—') {
			html += '<div class="data emdash data-value" data-test="tooltip.adherence">—</div>';
		} else {
			html +=
				'<div class="data adherence data-value" data-test="tooltip.adherence" style="' +
				this.getAdherenceStyle(vehicle.adherenceColor) +
				'">' +
				vehicle.adherenceDisplay.time +
				'</div>';
		}

		html += '</div> ';

		// headway
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_HEADWAY') + '</div> ';

		if (vehicle.headwayDisplay === '—') {
			html += '<div class="data-value headway emdash" data-test="tooltip.headway">—</div> ';
		} else {
			html +=
				'<div style="' +
				this.getHeadwayStyle(vehicle.headwayColor) +
				'" class="data-value headway" data-test="tooltip.headway">' +
				vehicle.headwayDisplay +
				' </div>';
		}

		html += '</div> ';

		// status
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_STATUS') + '</div> ';
		html += '<div class="data-value status" data-test="tooltip.status">' + vehicle.state + '</div> ';
		html += '</div>';

		html += '</div>';

		return html;
	};

	/**
	 * get the vehicle detail html (row 2) for the tooltip
	 *
	 * @param vehicle - the vehicle
	 * @returns the vehicle detail (row 2) html for the tooltip
	 */
	private labelDetailsRow2 = (vehicle: MapVehicle): string => {
		let html: string = '<div class="row2 flexrow"> ';

		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_CORE.VEHICLE') + '</div> ';

		// get list of vehicleIds (list will typically have the 1 vehicle id but could be more when we have linked vehicles)
		vehicle.vehicleIds.forEach((vehicleId: string, index) => {
			html += '<a id="vehicleId' + vehicleId + 'Click">';

			if (index === 0) {
				html += '<span class="data-value-no-right-margin" data-test="tooltip.vehicle">' + vehicleId + '</span>';
			} else {
				html += '<span class="data-value-no-margin" data-test="tooltip.vehicle">' + vehicleId + '</span>';
			}

			if (index !== vehicle.vehicleIds.length - 1) {
				html += ', ';
			}
		});

		html += '</a>';
		html += '</div> ';

		// block
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_BLOCK') + '</div> ';
		if (this.mapOptionsService.getMapMode() === MapModeType.live) {
			html += '<a id="blockId' + vehicle.blockId + 'Click">';
		}

		if (vehicle.blockId !== '—') {
			html += '<div class="data-value" data-test="tooltip.block">' + vehicle.blockId + '</div> ';
		} else {
			html += '<div class="data-value emdash" data-test="tooltip.block">' + vehicle.blockId + '</div> ';
		}

		html += '</a>';
		html += '</div> ';

		// runId
		if (vehicle.runid) {
			html += '<div class="data-group"> ';
			html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_RUNID') + '</div> ';
			html += '<div class="data-value">' + vehicle.runid + '</div> ';
			html += '</div> ';
		}

		// passengers
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_RIDERSHIP') + '</div> ';

		let passengersOnboard: string = '—';
		let ridershipPercentage: string = '—';

		if (typeof vehicle.passengers.onboard === 'number') {
			passengersOnboard = vehicle.passengers.onboard.toString();
		}

		if (typeof vehicle.passengers.ridershipPercentage === 'number') {
			ridershipPercentage = Math.round(vehicle.passengers.ridershipPercentage * 100).toString();
		}

		const ridershipIcon: string = this.getRidershipIcon(vehicle.passengers.ridershipEnum, 'details');

		html +=
			'<span class="data-value passengers" data-test="tooltip.ridership">' +
			passengersOnboard +
			'&nbsp(' +
			ridershipPercentage +
			'%)' +
			ridershipIcon +
			' </span>';

		html += '</div> ';

		// trip
		html += '<div class="data-group"> ';
		html += '<div class="data-label">' + this.translationService.getTranslation('T_MAP.MAP_TRIP') + '</div> ';

		if (vehicle.tripId !== '—') {
			html += '<div class="data-value" data-test="tooltip.trip">' + vehicle.tripId + '</div> ';
		} else {
			html += '<div class="data-value emdash" data-test="tooltip.trip">' + vehicle.tripId + '</div> ';
		}

		html += '</div> ';

		html += '</div> ';

		return html;
	};

	/**
	 * get the vehicle detail footer html for the tooltip
	 *
	 * @param vehicle - the vehicle
	 * @returns the vehicle detail footer html for the tooltip
	 */
	private labelDetailsFooter = (vehicle: MapVehicle): string => {
		const agencyTimezone: string = this.agenciesDataService.getAgencyTimezone(vehicle.authorityId, vehicle.agencyId);

		let html: string = '<div class="text-center secondary">';

		if (vehicle.date) {
			const dateVal: Moment = moment(vehicle.date).tz(agencyTimezone);
			const format: TimeFormatType = this.mapOptionsService.getTimeFormat();
			const formatdate: string = this.formatTime(dateVal, format, agencyTimezone);

			if (dateVal) {
				if (format === TimeFormatType.clockTime) {
					html +=
						'<div>' +
						this.translationService.getTranslation('T_MAP.MAP_UPDATED') +
						' ' +
						this.translationService.getTranslation('T_MAP.MAP_AT') +
						' ' +
						formatdate +
						'</div>';
				} else {
					html += '<div>' + this.translationService.getTranslation('T_MAP.MAP_UPDATED') + ' ' + formatdate + '</div>';
				}
			}
		}

		html += '</div> ';

		return html;
	};

	/**
	 * format the time for the tooltip
	 *
	 * @param time - the time
	 * @param format - the format required
	 * @param timezone - the timezone  to set
	 * @returns the formatted time
	 */
	private formatTime = (time: Moment, format: TimeFormatType, timezone: string): string => {
		const formattedTime: Moment = moment(time).tz(timezone);

		let replayTime: number = null;

		if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
			replayTime = this.mapReplayService.getCurrentReplayTime();
		}

		return TimeHelpers.getTimeByUserSelectedFormat(
			formattedTime,
			format,
			timezone,
			this.translationService.getTranslation('T_MAP.MAP_AGO'),
			replayTime
		);
	};

	/**
	 * get the ridership icon for the tooltip
	 *
	 * @param ridershipValue - the ridership value to convert
	 * @param location - the location (i.e summary/detail section)
	 * @returns the ridership icon html
	 */
	private getRidershipIcon = (ridershipValue: number, location: string): string => {
		const height: number = location === 'summary' ? 22 : 15;
		const width: number = 18;

		let html: string = '';

		// Select appropriate image based on ridership enum value
		switch (ridershipValue) {
			case 0:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-green.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			case 1:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-green.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			case 2:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-yellow.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			case 3:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-darkyellow.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			case 4:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-red.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			case 5:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-darkred.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			case 6:
				html +=
					'<img class="occupancy" src="/assets/img/ridership/occupancy-icon-darkred.svg"' +
					' data-test="map.label.occupancy" width="' +
					width +
					'" height="' +
					height +
					'"/>';
				break;
			default:
				html += '<span class="data-value emdash" style="color: black" data-test="map.label.occupancy">—</span>';
				break;
		}

		return html;
	};

	/**
	 * get the adherence css style for the tooltip
	 *
	 * @param adherenceColor - the adherence color
	 * @returns the adherence styling
	 */
	private getAdherenceStyle = (adherenceColor: VehicleColor): string => {
		return 'background: ' + adherenceColor.backgroundColor + '; color: ' + adherenceColor.foreColor + ';';
	};

	/**
	 * get the headway css style for the tooltip
	 *
	 * @param adherenceColor - the adherence color
	 * @param headwayColor - the headway color
	 * @returns the adherence styling
	 */
	private getHeadwayStyle = (headwayColor: VehicleColor): string => {
		return 'background: ' + headwayColor.backgroundColor + '; color: ' + headwayColor.foreColor + ';';
	};

	/**
	 * get the route css style for the tooltip
	 *
	 * @param routeColor - the route color
	 * @param routeName  - the route name
	 * @returns the route styling
	 */
	private getRouteStyle = (routeColor: VehicleColor, routeName: string): string => {
		let routeStyle: string =
			'white-space:nowrap; text-align:center; justify-content: center; background-color: ' +
			routeColor.backgroundColor +
			'; color: ' +
			routeColor.foreColor +
			';';

		if (routeName?.indexOf('*') !== -1) {
			routeStyle += 'font-style: italic';
		}

		return routeStyle;
	};

	/**
	 * handle the mouse over within the tooltip and add click listeners
	 *
	 * @param vehicleId - the vehicle id
	 */
	private handleMouseOver = (vehicleId: string): void => {
		this.markers[vehicleId]['withinTooltip'] = true;

		// add listeners to handle our click handlers for links in our html
		this.addClickListeners(vehicleId);
	};

	/**
	 * handle the mouse out for the tooltip and remove click listeners
	 *
	 * @param vehicleId - the vehicle id
	 */
	private handleMouseOut = (vehicleId: string): void => {
		this.markers[vehicleId]['withinTooltip'] = false;
		this.removeVehicleTooltip(vehicleId);
	};

	/**
	 * add click listeners for the tooltip
	 * @param vehicleId - the vehicle id
	 */
	private addClickListeners = (vehicleId: string): void => {
		this.addVehicleIdListeners(vehicleId);
		this.addRouteIdClickListener(vehicleId);
		this.addBlockIdClickListener(vehicleId);
	};

	/**
	 * remove click listeners for the tooltip
	 * @param vehicleId - the vehicle id
	 */
	private removeClickListeners = (vehicleId: string): void => {
		this.removeVehicleIdClickListeners(vehicleId);
		this.removeRouteClickListener(vehicleId);
		this.removeBlockClickListener(vehicleId);
	};

	/**
	 * add vehicle id click listeners for the tooltip
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleIdListeners = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			// setup click listeners for vehicle id clicks (we will typically have the 1 vehicle id
			// but could be more when we have linked vehicles)
			vehicle.vehicleIds.forEach((vehicleId: string) => {
				this.addVehicleIdClickListener(vehicleId);
			});
		}
	};

	/**
	 * add vehicle id click listener for the tooltip
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleIdClickListener = (vehicleId: string): void => {
		const vehicleIdElement: HTMLElementExtended = document.getElementById('vehicleId' + vehicleId + 'Click');

		if (vehicleIdElement) {
			vehicleIdElement.vehicleId = vehicleId;
			vehicleIdElement.addEventListener('click', this.onVehicleIdClick);
		}
	};

	/**
	 * remove vehicle id click listeners for the tooltip
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleIdClickListeners = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			vehicle.vehicleIds.forEach((vehicleId: string) => {
				this.removeVehicleIdClickListener(vehicleId);
			});
		}
	};

	/**
	 * remove vehicle id click listener for the tooltip
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleIdClickListener = (vehicleId: string): void => {
		const vehicleIdElement: HTMLElement = document.getElementById('vehicleId' + vehicleId + 'Click');

		if (vehicleIdElement) {
			vehicleIdElement.removeEventListener('click', this.onVehicleIdClick);
		}
	};

	/**
	 * handle the vehicle id click - navigate to vehicle details
	 *
	 * @param evt - the vehicle id click event
	 */
	private onVehicleIdClick = async (evt: EventExtended): Promise<void> => {
		const vehicleId: string = evt.currentTarget.vehicleId;

		await this.mapNavigationService.navigateToVehicleDetails(
			this.selectedAgency.authority_id,
			vehicleId,
			VehicleDetailsActiveTab.summary
		);
	};

	/**
	 * add route id click listeners for the tooltip
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addRouteIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const routeIdElement: HTMLElementExtended = document.getElementById('vehicleRouteId' + vehicleId + '_' + vehicle.routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.routeId = vehicle.routeId;
			routeIdElement.addEventListener('click', this.onRouteIdClick);
		}
	};

	/**
	 * remove route click listener for the tooltip
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeRouteClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			this.removeRouteIdClickListener(vehicleId, vehicle.routeId);
		}
	};

	/**
	 * remove route id click listener for the tooltip
	 *
	 * @param vehicleId - the vehicle id
	 * @param routeId - the route id
	 */
	private removeRouteIdClickListener = (vehicleId: string, routeId: string): void => {
		const routeIdElement: HTMLElement = document.getElementById('vehicleRouteId' + vehicleId + '_' + routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.removeEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * handle the route id click - navigate to route details
	 *
	 * @param evt - the route id click event
	 */
	private onRouteIdClick = async (evt: EventExtended): Promise<void> => {
		const routeId: string = evt.currentTarget.routeId;

		if (routeId) {
			await this.mapNavigationService.navigateToRouteDetails(this.selectedAgency.authority_id, routeId);
		}
	};

	/**
	 * add block id click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addBlockIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const blockIdElement: HTMLElementExtended = document.getElementById('blockId' + vehicle.blockId + 'Click');

		if (blockIdElement) {
			blockIdElement.blockId = vehicle.blockId;
			blockIdElement.addEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * remove block click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeBlockClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			this.removeBlockIdClickListener(vehicle.blockId);
		}
	};

	/**
	 * remove block id click listener
	 *
	 * @param blockId - the block id
	 */
	private removeBlockIdClickListener = (blockId: string): void => {
		const blockIdElement: HTMLElement = document.getElementById('blockId' + blockId + 'Click');

		if (blockIdElement) {
			blockIdElement.removeEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * handle block id click - navigate to the block details page
	 *
	 * @param evt - the block id click event including the block id
	 */
	private onBlockIdClick = async (evt: EventExtended): Promise<void> => {
		const blockId: string = evt.currentTarget.blockId;

		if (blockId && blockId !== '-' && blockId !== '—') {
			await this.mapNavigationService.navigateToBlockDetails(this.selectedAgency.authority_id, blockId);
		}
	};

	/**
	 * set up subscriptions for the page
	 *
	 * remove tooltips - handle the subscription to the removal of tooltips (typically when other tooltips are displayed)
	 */
	private setSubscriptions = (): void => {
		this.removeTooltips$Subscription = this.mapEventsService.removeTooltips.subscribe(() => {
			for (const markerId in this.markers) {
				this.markers[markerId].remove();
				delete this.markers[markerId];
			}
		});
	};
}

<form [formGroup]="reactiveForm" class="form-group row" [ngClass]="agencies?.length > 1 ? 'form-height' : 'form-height-single-agency'">
	<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
	<div [hidden]="!loaded">
		<div id="title-text" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
			<input
				formControlName="title"
				data-test="title.input"
				type="text"
				id="title"
				class="form-control"
				placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
			<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
		</div>

		<div *ngIf="agencies?.length > 1">
			<div id="agency" class="form-group">
				<div class="nb-required">
					<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
				</div>
				<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
				<select
					name="agencyId"
					id="agencyId"
					class="form-control"
					formControlName="agencyId"
					data-test="agency.selection"
					(change)="agencyChanged()">
					<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
						{{ agency.agency_name }}
					</option>
				</select>
				<input-validation [control]="reactiveForm.controls.agencyId"></input-validation>
			</div>
		</div>

		<div id="routes" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="title">{{ 'T_DASH.DB_ROUTES' | translate }}</label>
			<div class="nb-form-control">
				<div class="nb-radio">
					<label for="routesAll">
						<input
							id="routesAll"
							type="radio"
							formControlName="routes"
							[value]="'all'"
							(change)="routeChange('all')"
							data-test="all.input" />
						<div class="checkbox-label">
							{{ 'T_DASH.DB_ALL_ROUTES' | translate }}
						</div>
					</label>
				</div>
			</div>
			<div class="nb-form-control">
				<div class="nb-radio">
					<label for="someRoutes">
						<input
							id="someRoutes"
							type="radio"
							formControlName="routes"
							[value]="'someRoutes'"
							(change)="routeChange('someRoutes')"
							data-test="routes.input" />
						<div class="checkbox-label">
							{{ 'T_DASH.DB_SPECIFIC_ROUTES' | translate }}
						</div>
					</label>
				</div>
			</div>
		</div>

		<div *ngIf="!widget.config.includeAllRoutes" id="route" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<div>
				<label>{{ 'T_DASH.DB_ROUTE_SELECTION' | translate }}</label>
			</div>
			<div>
				<multi-select
					[settings]="routeSettings"
					[items]="routesSummaries"
					data-test="routes.select"
					[selected]="selectedRoutes"
					(itemClickedEvent)="updateItemClicked()"
					(removeItemClicked)="updateItemClicked()">
				</multi-select>
			</div>
			<input-validation [control]="reactiveForm.controls.selectedRoutes"></input-validation>
		</div>

		<div id="timeFrameSelection" class="form-group">
			<div>
				<label>{{ 'T_DASH.DB_RANGE' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button
						id="DB_30_MIN"
						class="btn btn-extra-width"
						data-test="range.30-min"
						[ngClass]="timeRangeButtonClass(30)"
						(click)="setTimeRange(30)">
						{{ 'T_DASH.DB_30_MIN' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_60_MIN"
						class="btn btn-extra-width"
						data-test="range.1-hr"
						[ngClass]="timeRangeButtonClass(60)"
						(click)="setTimeRange(60)">
						{{ 'T_DASH.DB_1_HOUR' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_120_MIN"
						class="btn btn-extra-width"
						data-test="range.2-hr"
						[ngClass]="timeRangeButtonClass(120)"
						(click)="setTimeRange(120)">
						{{ 'T_DASH.DB_2_HOUR' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_180_MIN"
						class="btn btn-extra-width"
						data-test="range.3-hr"
						[ngClass]="timeRangeButtonClass(180)"
						(click)="setTimeRange(180)">
						{{ 'T_DASH.DB_3_HOUR' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_360_MIN"
						class="btn btn-extra-width"
						data-test="range.6-hr"
						[ngClass]="timeRangeButtonClass(360)"
						(click)="setTimeRange(360)">
						{{ 'T_DASH.DB_6_HOUR' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_720_MIN"
						class="btn btn-extra-width"
						data-test="range.12-hr"
						[ngClass]="timeRangeButtonClass(720)"
						(click)="setTimeRange(720)">
						{{ 'T_DASH.DB_12_HOUR' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div id="intervalSelection" class="form-group">
			<div>
				<label>{{ 'T_DASH.DB_INTERVAL' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button
						id="DB_1_MINUTE"
						[disabled]="!enableLowerIntervals"
						class="btn btn-extra-width"
						data-test="btn.1-min"
						[ngClass]="timeIntervalButtonClass(1)"
						(click)="setTimeInterval(1)">
						{{ 'T_DASH.DB_1_MINUTE' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_5_MINUTE"
						[disabled]="!enableLowerIntervals"
						class="btn btn-extra-width"
						data-test="btn.5-min"
						[ngClass]="timeIntervalButtonClass(5)"
						(click)="setTimeInterval(5)">
						{{ 'T_DASH.DB_5_MINUTE' | translate }}
					</button>
				</div>
				<div class="btn-group">
					<button
						id="DB_10_MINUTE"
						class="btn btn-extra-width"
						data-test="btn.10-min"
						[ngClass]="timeIntervalButtonClass(10)"
						(click)="setTimeInterval(10)">
						{{ 'T_DASH.DB_10_MINUTE' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div class="nb-margin-bottom-sm">
			<span class="nb-section-title">{{ 'T_DASH.DB_VIEW' | translate }}</span>
		</div>

		<div class="performance-settings">
			<div class="nb-section-subtitle">{{ 'T_DASH.DB_ADHERENCE' | translate }}</div>
			<div class="form-group adherence-section">
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="displayVeryEarly">
							<input
								id="displayVeryEarly"
								data-test="status.very-early"
								type="checkbox"
								formControlName="displayVeryEarly"
								(change)="checkboxClicked($event, 'displayVeryEarly')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_VERY_EARLY' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="displayEarly">
							<input
								id="displayEarly"
								data-test="status.early"
								type="checkbox"
								formControlName="displayEarly"
								(change)="checkboxClicked($event, 'displayEarly')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_EARLY' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="displayOnTime">
							<input
								id="displayOnTime"
								data-test="status.on-time"
								type="checkbox"
								formControlName="displayOnTime"
								(change)="checkboxClicked($event, 'displayOnTime')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_ON_TIME' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="displayLate">
							<input
								id="displayLate"
								data-test="status.late"
								type="checkbox"
								formControlName="displayLate"
								(change)="checkboxClicked($event, 'displayLate')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_LATE' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="displayVeryLate">
							<input
								id="displayVeryLate"
								data-test="status.very-late"
								type="checkbox"
								formControlName="displayVeryLate"
								(change)="checkboxClicked($event, 'displayVeryLate')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_VERY_LATE' | translate }}</div>
						</label>
					</div>
				</div>
			</div>

			<div class="nb-section-subtitle">{{ 'T_DASH.DB_HEADWAY' | translate }}</div>
			<div class="form-group headway-section">
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayHeadwayVeryClose">
							<input
								id="displayHeadwayVeryClose"
								data-test="headway.very-close"
								type="checkbox"
								formControlName="displayHeadwayVeryClose"
								(change)="checkboxClicked($event, 'displayHeadwayVeryClose')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_VERY_CLOSE' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayHeadwayClose">
							<input
								id="displayHeadwayClose"
								data-test="headway.close"
								type="checkbox"
								formControlName="displayHeadwayClose"
								(change)="checkboxClicked($event, 'displayHeadwayClose')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_CLOSE' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayHeadwayOkay">
							<input
								id="displayHeadwayOkay"
								data-test="headway.ok"
								type="checkbox"
								formControlName="displayHeadwayOkay"
								(change)="checkboxClicked($event, 'displayHeadwayOkay')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_OKAY' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayHeadwayDistant">
							<input
								id="displayHeadwayDistant"
								data-test="headway.distant"
								type="checkbox"
								formControlName="displayHeadwayDistant"
								(change)="checkboxClicked($event, 'displayHeadwayDistant')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_DISTANT' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayHeadwayVeryDistant">
							<input
								id="displayHeadwayVeryDistant"
								data-test="headway.very-distant"
								type="checkbox"
								formControlName="displayHeadwayVeryDistant"
								(change)="checkboxClicked($event, 'displayHeadwayVeryDistant')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_VERY_DISTANT' | translate }}</div>
						</label>
					</div>
				</div>
			</div>

			<div class="nb-section-subtitle" hidden>{{ 'T_DASH.DB_PREDICTIONS' | translate }}</div>
			<div class="form-group prediction-section" hidden>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayPredictability">
							<input
								id="displayPredictability"
								type="checkbox"
								formControlName="displayPredictability"
								(change)="checkboxClicked($event, 'displayPredictability')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_PREDICTABILITY' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayPredictionAccuracyAll">
							<input
								id="displayPredictionAccuracyAll"
								type="checkbox"
								formControlName="displayPredictionAccuracyAll"
								(change)="checkboxClicked($event, 'displayPredictionAccuracyAll')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_PREDICTION_ACCURACY_ALL' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayPredictionAccuracy0to5">
							<input
								id="displayPredictionAccuracy0to5"
								type="checkbox"
								formControlName="displayPredictionAccuracy0to5"
								(change)="checkboxClicked($event, 'displayPredictionAccuracy0to5')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_PREDICTION_ACCURACY_0_TO_5' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayPredictionAccuracy5to10">
							<input
								id="displayPredictionAccuracy5to10"
								type="checkbox"
								formControlName="displayPredictionAccuracy5to10"
								(change)="checkboxClicked($event, 'displayPredictionAccuracy5to10')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_PREDICTION_ACCURACY_5_TO_10' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="displayPredictionAccuracy10to15">
							<input
								id="displayPredictionAccuracy10to15"
								type="checkbox"
								formControlName="displayPredictionAccuracy10to15"
								(change)="checkboxClicked($event, 'displayPredictionAccuracy10to15')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_PREDICTION_ACCURACY_10_TO_15' | translate }}</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

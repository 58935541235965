/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { AgenciesEventsService } from '../../../../support-features/agencies/services/agencies-events.service';
import { MapNavigationService } from '../../services/map-navigation.service';
import { RoutesDataService } from '../../../../support-features/routes/services/routes-data.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { MapVehiclesService } from '../../services/map-vehicles.service';
import { MapRoutesService } from '../../services/map-routes.service';
import { MapOptionsService } from '../../services/map-options.service';
import { MapLocationService } from '../../services/map-location.service';
import { MapEventsService } from '../../services/map-events.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { RouteExtended } from '../../../../support-features/routes/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';

import { MapModeType, MapRoute, MapRoutes, MapUpdateEvent, MapUpdateType, MapVehicles, ModeType } from '../../types/types';

declare let $: any;

@Component({
	selector: 'ladder',
	templateUrl: './ladder.component.html',
	styleUrls: ['./ladder.component.scss'],
})
export class LadderComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public mapModeType: typeof MapModeType = MapModeType;
	public modeType: typeof ModeType = ModeType;

	public ladderRoutes: MapRoute[] = [];

	private mapUpdate$Subscription: Subscription = null;
	private agenciesSelectionChange$Subscription: Subscription = null;

	private selectedAgency: SelectedAgency = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		private mapEventsService: MapEventsService,
		private loggerService: LoggerService,
		private routesDataService: RoutesDataService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesService: MapVehiclesService,
		private mapNavigationService: MapNavigationService,
		private mapOptionsService: MapOptionsService,
		private mapLocationService: MapLocationService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * hanlde the initialization for the ladder view
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		this.ladderRoutes = this.getLadderRoutes();
	}

	/**
	 * determine the ladder height for the view
	 *
	 * @returns a caclulated style height value
	 */
	public getLadderHeight = (): string => {
		if (this.mapOptionsService.getFullScreen()) {
			return 'calc(100vh - 28px)';
		} else {
			return 'calc(100vh - 93px)';
		}
	};

	/**
	 * determine left padding for the ladder view container (to allow room for vehicle labels if they are turned on)
	 *
	 * @returns the additional left padding dependent on what label parts are turned on
	 */
	public getLabelLeftPadding = (): string => {
		let padding: number = 0;

		if (this.mapOptionsService.getShowVehicleLabels()) {
			if (this.mapOptionsService.getShowVehicleLabelId()) {
				padding += 43;
			}

			if (this.mapOptionsService.getShowVehicleLabelRoute()) {
				padding += 64;
			}

			if (this.mapOptionsService.getShowVehicleLabelDepartureAdherence()) {
				padding += 46;
			}

			if (this.mapOptionsService.getShowVehicleLabelHeadway()) {
				padding += 93;
			}

			if (this.mapOptionsService.getShowVehicleLabelStatus()) {
				padding += 69;
			}

			if (this.mapOptionsService.getShowVehicleLabelBlock()) {
				padding += 75;
			}

			if (this.mapOptionsService.getShowVehicleLabelPassengers()) {
				padding += 18;
			}

			// remove some padding for where the label overlays the route
			padding -= 145;

			if (padding < 0) {
				padding = 0;
			}
		}

		return padding.toString() + 'px';
	};

	/**
	 * determine right padding for the ladder view container (to allow room for vehicle labels if they are turned on)
	 *
	 * if labels are active and appear to the right (for outbound direction) - we show labels to the right, when we expand
	 * on these to show full detail/tooltip allow extra space
	 *
	 * @returns the additional right padding dependent on what label parts are turned on
	 */
	public getLabelRightPadding = (): string => {
		let padding: number = 0;

		if (this.mapOptionsService.getShowVehicleLabels()) {
			padding = 360;
		}

		return padding.toString() + 'px';
	};

	/**
	 * get the ladder container position based on parameters such as being in full screen and the navigation menu being open
	 *
	 * @returns get the ladder container position
	 */
	public getLadderContainerPosition = (): any => {
		const navViewOpen: boolean = this.mapOptionsService.getNavViewOpen();

		const ladderPositionAdjustment: number = this.mapOptionsService.getFullScreen() ? (navViewOpen ? 140 : 125) : navViewOpen ? 70 : 55;
		const appendWidth: number = parseFloat($('nav.navbar-default')?.width()?.toString()) - ladderPositionAdjustment;

		if (navViewOpen) {
			const panelWidth: number = parseFloat($('.nb-map-details')?.width()?.toString()) + appendWidth;

			return { width: panelWidth + 'px' };
		} else {
			return { width: appendWidth + 'px' };
		}
	};

	/**
	 * get the ladder container left position based on the the navigation menu being open
	 *
	 * @returns get the ladder container left position
	 */
	public getContainerLeftPosition = (): string => {
		const navViewOpen: boolean = this.mapOptionsService.getNavViewOpen();

		if (navViewOpen) {
			return 510 + 'px';
		} else {
			return 0 + 'px';
		}
	};

	/**
	 * get the position offset if no ladder routes are active
	 *
	 * @returns the empty message position offset
	 */
	public getEmptyMessagePositionOffset = (): number => {
		return this.mapOptionsService.getNavViewOpen() ? 330 : 66;
	};

	/**
	 * navigate to the route list (for routes button when no ladder routes active)
	 */
	public goToRoutes = (): void => {
		this.mapNavigationService.navigateToRouteList(this.selectedAgency.authority_id);
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * get the routes loaded and create a list/array version of our keyed routes so we can iterate over them
	 *
	 * also sorts the routes
	 *
	 * @returns a list of sorted routes
	 */
	private getLadderRoutes = (): MapRoute[] => {
		const routeList: MapRoute[] = [];

		const routes: MapRoutes = this.mapRoutesService.getRoutes();

		for (const routeId in routes) {
			routeList.push(routes[routeId]);
		}

		routeList.sort(this.routeNameComparator);

		return routeList;
	};

	/**
	 * the sort route comparator
	 *
	 * @param firstRoute - the first route to determine sort order
	 * @param secondRoute - the second route to determine sort order
	 * @returns the sorted routes
	 */
	private routeNameComparator = (firstRoute: MapRoute, secondRoute: MapRoute): number => {
		return firstRoute.routeShortName.localeCompare(secondRoute.routeShortName, undefined, {
			numeric: true,
			sensitivity: 'base',
		});
	};

	/**
	 * set up subscriptions for the ladder view
	 *
	 * map update - handle the map update event
	 *
	 * agency selection change - handle the user changing agency
	 */
	private setSubscriptions = (): void => {
		this.mapUpdate$Subscription = this.mapEventsService.mapUpdate.subscribe((event) => {
			this.handleMapUpdate(event);
		});

		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgenciesSelectionChange();
		});
	};

	/**
	 * handle the map update event. This event is the main event handler for the map that reacts to changes in the map
	 * state and updated the ladder accordingly
	 *
	 * include try catch - shouldn't be needed but if an exception otherwise occurs it breaks the subscription and nothing works until
	 * the user refreshes
	 *
	 * @param mapUpdateEvent - the type that describes the type of update it is i.e addVehicle
	 */
	private handleMapUpdate = async (mapUpdateEvent: MapUpdateEvent): Promise<void> => {
		try {
			const route: MapRoute = this.mapRoutesService.getRoute(mapUpdateEvent.params);

			switch (mapUpdateEvent.mapUpdateType) {
				case MapUpdateType.addRoute:
					this.ladderRoutes = this.getLadderRoutes();

					// detect changes so the route above is rendered
					this.changeDetectorRef.detectChanges();

					this.scrollRouteIntoView(route.routeId);
					break;

				case MapUpdateType.routesToLocateUpdate:
					// ensure the route is in view ...
					// This event will fire when we first add a route (or zooming to a route)
					// it will also fire when zooming to multiple routes (for a block)
					// We can only scroll 1 in to view so may as well be the first one (most of the time there
					// will only be one even with blocks)

					// only bother scrolling to the route if we are viewing it - i.e leave the ladder how it was left/default position
					if (this.mapOptionsService.getMode() === ModeType.ladder) {
						const routeIds: Array<string> = this.mapLocationService.getRoutesToLocate();

						this.scrollRouteIntoView(routeIds[0]);
					}
					break;

				case MapUpdateType.modeRefresh:
					if (this.mapOptionsService.getMode() === ModeType.ladder) {
						await this.addMissingLadderRoutes();
						this.ladderRoutes = this.getLadderRoutes();
					}
					break;
				case MapUpdateType.removeRoute:
				case MapUpdateType.clearRoutes:
				case MapUpdateType.initViewRoutes: // needed after applying views
					this.ladderRoutes = this.getLadderRoutes();
					break;
				case MapUpdateType.addVehicle:
					this.addMissingLadderRoutes();
					break;
			}
		} catch (exception) {
			this.loggerService.logError('Failed to process map update', exception);
		}
	};

	/**
	 * adds the routes for any vehicles that are showing in the map state that don't have the route showing (typically when
	 * a vehicle has been manually added in the map)
	 */
	private addMissingLadderRoutes = async (): Promise<void> => {
		if (this.mapOptionsService.getMode() === ModeType.ladder) {
			const routesToAdd: RouteExtended[] = [];

			const vehicles: MapVehicles = this.mapVehiclesService.getVehicles();

			const routes: MapRoutes = this.mapRoutesService.getRoutes();

			const routesList: string[] = [];

			for (const routeId in routes) {
				routesList.push(routeId);
			}

			for (const vehicleKey in vehicles) {
				if (
					this.mapVehiclesService.vehicleDisplayed(vehicleKey) &&
					vehicles[vehicleKey].routeId !== null &&
					routesList.indexOf(vehicles[vehicleKey].routeId) === -1
				) {
					this.loggerService.logInfo('Adding route (for ladder) for vehicle ', vehicleKey);

					const result: ResultContent = await this.routesDataService.getRoute(
						vehicles[vehicleKey].authorityId,
						vehicles[vehicleKey].agencyId,
						vehicles[vehicleKey].routeId
					);

					if (result.success) {
						const route: RouteExtended = result.resultData;

						routesToAdd.push(route);

						this.mapRoutesService.addRoute(route);
					}
				}
			}
		}
	};

	/**
	 * ensures the route supplied is scrolled in to view
	 *
	 * @param routeId - the route id
	 */
	private scrollRouteIntoView = async (routeId: string): Promise<void> => {
		const routeIdLocator: string = 'route_' + routeId;

		const routeElement: HTMLElement = document.getElementById(routeIdLocator);

		if (routeElement) {
			routeElement.scrollIntoView();
		}
	};

	/**
	 * handle the agency change event
	 */
	private handleAgenciesSelectionChange = (): void => {
		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		this.ladderRoutes = [];
	};

	/**
	 * unsubscribes from service subscriptions.
	 */
	private unsubscribe = (): void => {
		this.mapUpdate$Subscription?.unsubscribe();
		this.agenciesSelectionChange$Subscription?.unsubscribe();
	};
}

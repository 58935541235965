/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AuthoritiesAdminDataService } from '../services/authorities-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { StringHelpers } from '@cubicNx/libs/utils';
import { StateService } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../../utils/components/breadcrumbs/types/types';
import { AuthoritiesPaginatedResponse } from '../types/api-types';
import { AuthoritiesList, AuthorityListItem } from '../types/types';

import { Columns, ColumnType, ListSortings, PageRequestInfo, PaginatedParams, SelectedRowData, SortDirection } from '@cubicNx/libs/utils';

@Component({
	selector: 'authorities-admin-list',
	templateUrl: './authorities-admin-list.component.html',
	styleUrls: ['./authorities-admin-list.component.scss'],
})
export class AuthoritiesAdminListComponent extends TranslateBaseComponent implements OnInit {
	// pass through the defaukt sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'authorityId', dir: SortDirection.asc }];

	public hasFilters: boolean = false;

	public listName: string = 'authorities-list';
	public columns: Columns = [];
	public authoritiesList: AuthoritiesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public initailized: boolean = false;
	public breadcrumbItems: BreadcrumbItems = [];
	public searchText: string = '';

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];

	constructor(
		private authoritiesAdminService: AuthoritiesAdminDataService,
		private stateService: StateService,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the authorities list such as loading translations, initialisng breadcrumbs
	 * and building the list columns
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.initBreadcrumbs();

		this.loadCache();

		this.buildListColumns();

		this.initailized = true;
	}

	/**
	 * updates the contents of the authorities list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;

		this.cachePageInfo(this.pageInfo);

		this.getAuthorities();
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = (searchText: string): void => {
		this.searchText = searchText;

		// we can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intigating a search
		this.pageInfo.pageNum = 1;

		this.cacheSearch(this.searchText);

		this.getAuthorities();
	};

	/**
	 * action taken when an authority is selecred from the list - navigates to the selected authority details
	 *
	 * @param selectedRow - the selected row information
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const authority: AuthorityListItem = selectedRow.row;

		this.router.navigate(['/admintools/authorities/view', authority.authorityId]);
	};

	/**
	 * loads the translations presented within the authorities list
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.ADMIN_TOOLS',
			'T_AUTHORITY.AUTHORITIES',
			'T_AUTHORITY.AUTHORITY_CODE',
			'T_AUTHORITY.AUTHORITY_NAME',
			'T_AUTHORITY.CITY',
			'T_AUTHORITY.TYPE',
		]);
	};

	/**
	 * initialises the authority list breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_CORE.ADMIN_TOOLS'], targetPath: '/admintools', activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_AUTHORITY.AUTHORITIES'], activePage: true });
	};

	/**
	 * builds the authority list columns
	 */
	private buildListColumns = (): void => {
		// build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: 'authorityId',
				displayName: this.translations['T_AUTHORITY.AUTHORITY_CODE'],
				columnType: ColumnType.text,
			},
			{
				name: 'authorityName',
				displayName: this.translations['T_AUTHORITY.AUTHORITY_NAME'],
				columnType: ColumnType.text,
			},
			{
				name: 'city',
				displayName: this.translations['T_AUTHORITY.CITY'],
				columnType: ColumnType.text,
			},
			{
				name: 'authorityType',
				displayName: this.translations['T_AUTHORITY.TYPE'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * retrieves last used search and page information for the list
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current searhed for text
	 *
	 * @param search - the current searched for text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * retrieves list of authorities that match the current page information and search text and populates the list accordingly
	 */
	private getAuthorities = async (): Promise<void> => {
		this.listLoadingIndicator = true;

		this.totalRows = 0;

		// convert client side camel side notation for field name back to underscore version for back end request
		const paginatedParams: PaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			sort: StringHelpers.getUnderscoreValueFromCamelCase(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
		};

		const result: ResultContent = await this.authoritiesAdminService.getAuthorities(paginatedParams);

		if (result.success) {
			const authoritiesResponse: AuthoritiesPaginatedResponse = result.resultData;

			if (authoritiesResponse?.results) {
				// object for our list
				this.authoritiesList = authoritiesResponse.results.map((authority) => ({
					authorityId: authority.authority_id,
					authorityName: authority.authority_name,
					city: authority.city,
					authorityType: authority.authority_type,
				}));

				this.totalRows = authoritiesResponse.total;
			}
		}

		this.listLoadingIndicator = false;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { TimeHelpers } from '@cubicNx/libs/utils';

import { MapNavigationService } from '../../../services/map-navigation.service';
import { StateService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapUtilsService } from '../../../services/map-utils.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { BlocksDataService } from '../../../../../support-features/blocks/services/block-data.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ListShowingValues, MapModeType, VehicleDetailsActiveTab } from '../../../types/types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	Block,
	Blocks,
	BlocksPaginatedResponse,
	TripsData,
	VehicleIdentifier,
	VehicleIdentifiers,
} from '../../../../../support-features/blocks/types/api-types';

import { BlocksList, BlocksListItem, BlockStatus } from '../../../../../support-features/blocks/types/types';

import {
	ColumnType,
	Columns,
	CssClassType,
	CssMultiClassType,
	CssMultiClassTypeItem,
	CssSubClassPosition,
	ListSortings,
	PageRequestInfo,
	SelectedCssClassData,
	SelectedRowData,
	SortDirection,
} from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'blocks-list',
	templateUrl: './blocks-list.component.html',
	styleUrls: ['./blocks-list.component.scss'],
})
export class BlocksListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'blockId', dir: SortDirection.asc }];

	public mapNavigateRefresh$Subscription: Subscription = null;

	public initialized: boolean = false;
	public updatedAt: string = null;
	public listName: string = 'blocks-list';
	public columns: Columns = [];
	public blocksList: BlocksList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo = null;
	public totalRows: number = 0;
	public showingFirst: number = 0;
	public showingLast: number = 0;
	public blocksSearchSuggestions: string[] = [];
	public searchText: string = '';

	private readonly noVehiclesClass: string = 'cancelled-inactive-no-vehicles';
	private readonly vehiclesClass: string = 'vehicles-on-block';
	private readonly defaultClass: string = 'default-block';

	private readonly blockIdColumnName: string = 'blockId';
	private readonly blockDateTimeColumnName: string = 'dateTime';
	private readonly blockStatusColumnName: string = 'status';
	private readonly blockVehiclesColumnName: string = 'vehicles';

	private readonly timeFormat: string = 'HH:mm';
	private readonly noVehicle: string = '—';

	private blocks: Blocks = [];
	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private authorityId: string = null;
	private agencyId: string = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private blocksDataService: BlocksDataService,
		private mapUtilsService: MapUtilsService,
		private mapEventsService: MapEventsService,
		private mapHistoryService: MapHistoryService,
		private mapNavigationService: MapNavigationService,
		private mapOptionsService: MapOptionsService,
		private stateService: StateService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the block list
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();
		this.loadCache();
		this.buildListColumns();

		this.getSelectedAgency();

		this.initialized = true;
	}

	/**
	 * handle the blocks data request triggered from the datatable
	 * @param pageInfo - the page details (page number/size and sorting info)
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getBlocks();
	};

	/**
	 * handle a search from the user and reload the blocks list with the filter in place
	 * @param searchText - the search text entered by the user
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getBlocks();
	};

	/**
	 * handle the select of a row in the block list and navigate to block details
	 * @param selectedRow - the selected row including the block id
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const selectedBlock: BlocksListItem = selectedRow.row;

		// Individual vehicle clicks handled separately
		if (selectedRow.columnNameSelected !== this.blockVehiclesColumnName) {
			this.navigateToBlockDetails(selectedBlock.blockId);
		}
	};

	/**
	 * handle the individual column click of a 'class' item. i.e a column styled using css - in this case the assigned/unassigned badge
	 * @param selectedItem - the individual table cell details
	 */
	public onClassItemSelected = async (selectedItem: SelectedCssClassData): Promise<void> => {
		// Ignore anything emitted that is not a vehicle on the vehicles column
		if (selectedItem.columnNameSelected === this.blockVehiclesColumnName && selectedItem.item.value !== this.noVehicle) {
			// Attributes should be the vehicles authority id for this component
			await this.mapNavigationService.navigateToVehicleDetails(
				selectedItem.item.attributes,
				selectedItem.item.value,
				VehicleDetailsActiveTab.summary
			);
		}
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * handle the back button click and return to the users previous location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * retrieve the block search suggestions from the data layer
	 * @param searchText - the user filter search text
	 */
	public getSearchSuggestions = async (searchText: string): Promise<void> => {
		const response: ResultContent = await this.blocksDataService.getBlockSuggestions(this.authorityId, this.agencyId, searchText);

		this.blocksSearchSuggestions = [];

		if (response.success) {
			const resultData: BlocksPaginatedResponse = response.resultData;

			if (resultData?.results) {
				const blocksDataList: Blocks = resultData.results;

				// build the list of suggestions
				blocksDataList.forEach((blockData: Block) => {
					this.blocksSearchSuggestions.push(blockData.block_id);
				});
			}
		}
	};

	/**
	 * navigate to the block details using the supplied block id
	 * @param blockId - the block to navigate to
	 */
	private navigateToBlockDetails = async (blockId: string): Promise<void> => {
		await this.mapNavigationService.navigateToBlockDetails(this.authorityId, blockId);
	};

	/**
	 * set up subscriptions for the page
	 *
	 * navigate refresh - handle the click of the parent refresh button and refresh the list
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				this.mapNavigationService.navigateToMenu();
			} else {
				this.refresh();
			}
		});
	};

	/**
	 * refresh the list following a refresh click (from parent component)
	 */
	private refresh = async (): Promise<void> => {
		if (this.initialized) {
			await this.getBlocks();
		}
	};

	/**
	 * get the users current agency
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
		}
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_BLOCK',
			'T_MAP.MAP_DATE_AND_TIME',
			'T_MAP.MAP_STATUS',
			'T_MAP.MAP_VEHICLES',
			'T_MAP.BLOCK_ASSIGNED',
			'T_MAP.BLOCK_UNASSIGNED',
			'T_MAP.BLOCK_CANCELED',
			'T_MAP.TRIPS_CANCELED',
			'T_MAP.NO_VEHICLES',
		]);
	};

	/**
	 * load any persisted data for the component (list page info and filter search text)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * persist the page settings for the list
	 * @param pageInfo - the page settings
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * persist the search text
	 * @param search - the search text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * map the properties from our camel case list to property name recognised by the nextbus API to prepare for our backend sorting
	 * @param sortByValue - the sort by column name
	 * @returns - the sort by property name recognised by the nextbus API
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case this.blockIdColumnName:
				columnName = 'block_id';
				break;
			case this.blockDateTimeColumnName:
				columnName = 'start_time_epoch';
				break;
			case this.blockStatusColumnName:
				columnName = 'status';
				break;
			case this.blockVehiclesColumnName:
				columnName = 'vehicle_sort';
				break;
			default:
				columnName = 'block_id';
		}

		return columnName;
	}

	/**
	 * build the columns for the list
	 */
	private buildListColumns = (): void => {
		// Build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: this.blockIdColumnName,
				displayName: this.translations['T_MAP.MAP_BLOCK'],
				columnType: ColumnType.text,
				width: 83,
			},
			{
				name: this.blockDateTimeColumnName,
				displayName: this.translations['T_MAP.MAP_DATE_AND_TIME'],
				columnType: ColumnType.text,
				width: 140,
			},
			{
				name: this.blockStatusColumnName,
				displayName: this.translations['T_MAP.MAP_STATUS'],
				columnType: ColumnType.cssClass,
				width: 97,
			},
			{
				name: this.blockVehiclesColumnName,
				displayName: this.translations['T_MAP.MAP_VEHICLES'],
				columnType: ColumnType.multiClass,
				width: 143,
			},
		];
	};

	/**
	 * get the date time text to display in the data table list
	 *
	 * @param startTimeEpoch - the start time (epoch)
	 * @param startTime - the start time
	 * @param endTime - the start time
	 * @returns a formatted time string
	 */
	private getDateTimeDisplay = (startTimeEpoch: number, startTime: number, endTime: number): string => {
		const timeZone: string = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

		return TimeHelpers.getStartEndTimes(startTime, endTime, startTimeEpoch, timeZone, this.timeFormat);
	};

	/**
	 * get the status column display value - which requires the appropriate css class to dsiplay the badge format in the status column
	 * @param status - the block status
	 * @param trips - the trip list for the block
	 * @returns the css class to display the appropriate badge
	 */
	private getStatusDisplay = (status: BlockStatus, trips: TripsData): CssClassType => {
		let className: string = null;
		let value: string = null;
		let tooltip: string = null;

		switch (status) {
			case BlockStatus.assigned:
				className = 'label nb-block-assigned-full-width';
				value = this.translations['T_MAP.BLOCK_ASSIGNED'];
				tooltip = this.translations['T_MAP.BLOCK_ASSIGNED'];
				break;
			case BlockStatus.canceled:
				className = 'label nb-block-cancelT_MAP.ed-full-width';
				value = this.translations['T_MAP.BLOCK_CANCELED'];
				tooltip = this.translations['T_MAP.BLOCK_CANCELED'];
				break;
			case BlockStatus.unassigned:
				className = 'label nb-block-unassigned-full-width';
				value = this.translations['T_MAP.BLOCK_UNASSIGNED'];
				tooltip = this.translations['T_MAP.BLOCK_UNASSIGNED'];
				break;
			default:
				break;
		}

		const anyCanceledTripsInBlock: boolean = this.anyCanceledTrips(trips);

		const classType: CssClassType = {
			className,
			value,
			tooltip,
			subClassName: anyCanceledTripsInBlock ? 'fa fa-minus-circle blocks-cancel-icon sub-class-name-seperator' : null,
			subClassTooltip: anyCanceledTripsInBlock ? this.translations['T_MAP.TRIPS_CANCELED'] : null,
			subClassPosition: CssSubClassPosition.after,
		};

		return classType;
	};

	/**
	 * determine if there are any cancelled trips
	 *
	 * @param trips - the trips list for the indiviudal block
	 * @returns true if there are any cancelled trips
	 */
	private anyCanceledTrips = (trips: TripsData): boolean => {
		let canceledTrips: TripsData = [];

		if (trips && Array.isArray(trips)) {
			canceledTrips = trips.filter((trip) => trip.cancelled);
		}

		return canceledTrips.length > 0;
	};

	/**
	 * determine what vehicles are set on the block
	 *
	 * @param authorityId - the current authority id
	 * @param vehiclesIdentifiers - the list of vehicles on the block
	 * @returns a list of indiviudal prepared style classes for each vehicle
	 */
	private determineBlockVehicles = (authorityId: string, vehiclesIdentifiers: VehicleIdentifiers): Array<CssMultiClassTypeItem> => {
		const items: Array<CssMultiClassTypeItem> = [];

		if (vehiclesIdentifiers.length > 0) {
			vehiclesIdentifiers.forEach((vehicleIdentifier: VehicleIdentifier) => {
				const vehicleId: string = vehicleIdentifier.vehicle_id;

				const item: CssMultiClassTypeItem = {
					value: vehicleId,
					tooltip: vehicleId,
					attributes: authorityId,
				};

				items.push(item);
			});
		} else {
			const item: CssMultiClassTypeItem = {
				value: this.noVehicle,
				tooltip: this.translations['T_MAP.NO_VEHICLES'],
			};

			items.push(item);
		}

		return items;
	};

	/**
	 * determines if the block is active
	 *
	 * @param blockEndTime - the block end time
	 * @returns true if the block is active
	 */
	private isActive = (blockEndTime: number): boolean => {
		return blockEndTime > moment.now().valueOf();
	};

	/**
	 * get vehicles display value for the list column
	 *
	 * determine the appropriate style class value based on the list of vehicles
	 *
	 * @param authorityId - the authority id
	 * @param vehiclesIdentifiers - the list of vehicles on the block
	 * @param blockCanceled - the block cancelled state
	 * @param blockEndTime - the block end time
	 * @returns the appropriate style class for the vehicles column
	 */
	private getVehicles = (
		authorityId: string,
		vehiclesIdentifiers: VehicleIdentifiers,
		blockCanceled: boolean,
		blockEndTime: number
	): CssMultiClassType => {
		let className: string = '';

		if (vehiclesIdentifiers.length > 0) {
			className = this.vehiclesClass;
		} else if (vehiclesIdentifiers.length === 0 || blockCanceled || !this.isActive(blockEndTime)) {
			className = this.noVehiclesClass;
		} else {
			className = this.defaultClass;
		}

		const classType: CssMultiClassType = {
			className,
			items: this.determineBlockVehicles(authorityId, vehiclesIdentifiers),
		};

		return classType;
	};

	/**
	 * get the blocks list from our data layer and populate our data list
	 *
	 * convert the nextbus API underscore case to our expected camel case for our block list columns
	 */
	private getBlocks = async (): Promise<void> => {
		// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
		this.enableRefresh.emit(false);

		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const response: ResultContent = await this.blocksDataService.getBlocksList(
			this.authorityId,
			this.agencyId,
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.mapColumnName(this.pageInfo.sort),
			this.pageInfo.sortDir,
			this.searchText
		);

		if (response.success) {
			const resultData: BlocksPaginatedResponse = response.resultData;

			if (resultData?.results) {
				this.blocks = response.resultData.results;

				this.blocksList = this.blocks.map((blockData: Block) => ({
					blockId: blockData.block_id,
					dateTime: this.getDateTimeDisplay(blockData.start_time_epoch, blockData.start_time, blockData.end_time),
					status: this.getStatusDisplay(blockData.status, blockData.tripDetails),
					vehicles: this.getVehicles(blockData.authority_id, blockData.vehicles, blockData.cancelled, blockData.end_time),
				}));

				this.totalRows = resultData.total;

				const listShowingValues: ListShowingValues = this.mapUtilsService.getShowingPageValues(
					+this.pageInfo.pageNum,
					+this.pageInfo.pageSize,
					this.totalRows
				);

				this.showingFirst = listShowingValues.showingFirst;
				this.showingLast = listShowingValues.showingTo;

				this.updatedAt = moment().format('HH:mm:ss');
			}
		}

		this.listLoadingIndicator = false;

		this.enableRefresh.emit(true);
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
	};
}

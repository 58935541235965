/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { UserLoginRequest } from '../../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class LoginApiService {
	private readonly loginUrl: string = '/login';

	constructor(private httpService: HttpService) {}

	/**
	 * logs the user in via the nextbus API
	 *
	 * @param user - the user login credentials
	 * @returns the user login response
	 */
	public logIn = async (user: UserLoginRequest): Promise<any> => {
		const loginCredentials: any = { ident: user.username, password: user.password };

		try {
			return await this.httpService.post(this.loginUrl, loginCredentials);
		} catch (exception) {
			throw exception;
		}
	};
}

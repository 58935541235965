/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ConfigService } from '@cubicNx/libs/utils';
import { UsersAdminUtilService } from '../../../services/users-admin-utils.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { CurrentUserPermissionService } from '../../../../../../support-features/login/services/current-user/current-user-permission.service';

import { maxlengthValidation, ValidationText } from '@cubicNx/libs/utils';
import { FormattedRolesGroup, ProfileUpdate } from '../../../types/types';
import { UserDetail } from '../../../types/api-types';

@Component({
	selector: 'user-admin-detail-edit',
	templateUrl: './user-admin-detail-edit.component.html',
	styleUrls: ['./user-admin-detail-edit.component.scss'],
})
export class UserAdminDetailEditComponent extends TranslateBaseComponent implements OnInit {
	@Input() isCreate: boolean = false;
	@Input() user: UserDetail = null;
	@Output() userDetailsValid: EventEmitter<boolean> = new EventEmitter<boolean>();

	public userRolesDisplay: FormattedRolesGroup;
	public userDetailForm: FormGroup;

	public realNameMessageTextOverride: ValidationText = {
		[maxlengthValidation]: 'T_CORE.REAL_NAME_TOO_LONG',
	};

	public defaultExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	public phoneExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	public countryExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	private readonly defaultFieldLength: number = 100;
	private readonly phoneFieldLength: number = 25;
	private readonly countryFieldLength: number = 50;

	private usernameValidators: { validator: string; error: string }[];
	private phoneNumberPattern: RegExp = /^(\+?(\d{1}|\d{2}|\d{3})[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/;

	// This regex ensures that the email's domain has a dot followed by a string that looks like a TLD.
	// The built-in browser validation will take care of the rest. Be super careful about making this regex more strict.;
	private emailPattern: RegExp = /@((.+\.)+[a-zA-Z\-0-9]{2,})$/;

	constructor(
		private formBuilder: FormBuilder,
		private configService: ConfigService,
		private usersAdminUtilService: UsersAdminUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		translationService: TranslationService
	) {
		super(translationService);

		this.realNameMessageTextOverride = {
			[maxlengthValidation]: 'T_CORE.REAL_NAME_TOO_LONG',
		};

		this.defaultExtraMessageText = {
			[maxlengthValidation]: this.defaultFieldLength.toString(),
		};

		this.phoneExtraMessageText = {
			[maxlengthValidation]: this.phoneFieldLength.toString(),
		};

		this.countryExtraMessageText = {
			[maxlengthValidation]: this.countryFieldLength.toString(),
		};
	}

	/**
	 * performs initialization tasks for the user details edit view
	 */
	public async ngOnInit(): Promise<void> {
		this.userRolesDisplay = this.usersAdminUtilService.formatUserRoles(this.user.user_roles);

		this.usernameValidators = this.configService.getUserNameValidators();

		this.buildUserDetailForm();
	}

	/**
	 * emit form validity to parent
	 */
	public validate = (): void => {
		this.userDetailsValid.emit(this.userDetailForm.valid);
	};

	/**
	 * validates the user name
	 */
	public validateUsername = (): void => {
		if (!this.userDetailForm.controls.username.errors?.required) {
			// the field has passed the required validation - run through our custom validators
			this.checkCustomerUsernameValidators();
		}

		this.validate();
	};

	/**
	 * creates a user profile instance from the data in the form
	 *
	 * @returns user profile
	 */
	public getUserProfileDetails = (): ProfileUpdate => {
		const profileUpdate: ProfileUpdate = {
			username: this.userDetailForm.get('username').value,
			realName: this.userDetailForm.get('realName').value,
			email: this.userDetailForm.get('email').value,
			smsNumber: this.userDetailForm.get('smsNumber').value,
			address: this.userDetailForm.get('address').value,
			address2: this.userDetailForm.get('address2').value,
			country: this.userDetailForm.get('country').value,
			city: this.userDetailForm.get('city').value,
			orgName: this.userDetailForm.get('orgName').value,
			phone: this.userDetailForm.get('phone').value,
			postalCode: this.userDetailForm.get('postalCode').value,
			stateProvinceRegion: this.userDetailForm.get('state').value,
		};

		return profileUpdate;
	};

	/**
	 * refreshes the roles within the form
	 */
	public refreshRoles = (): void => {
		this.userRolesDisplay = this.usersAdminUtilService.formatUserRoles(this.user.user_roles);
	};

	/**
	 * populates the form with the users profile data
	 */
	private buildUserDetailForm = (): void => {
		this.userDetailForm = this.formBuilder.group(
			{
				username: [
					{
						value: this.isCreate ? '' : this.user.username,
						disabled: !this.isCreate,
					},
					[Validators.required],
				],
				realName: [
					{
						value: this.isCreate ? '' : this.user.real_name,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(this.defaultFieldLength)],
				],
				email: [
					{
						value: this.isCreate ? '' : this.user.email,
						disabled: !this.currentUserPermissionService.isAdmin(null),
					},
					[Validators.required, Validators.maxLength(this.defaultFieldLength), Validators.pattern(this.emailPattern)],
				],
				smsNumber: [
					{
						value: this.isCreate ? '' : this.user.sms_number,
						disabled: false,
					},
					[Validators.maxLength(this.defaultFieldLength), Validators.pattern(this.phoneNumberPattern)],
				],
				phone: [
					{
						value: this.isCreate ? '' : this.user.user_profile.phone,
						disabled: false,
					},
					[Validators.maxLength(this.phoneFieldLength), Validators.pattern(this.phoneNumberPattern)],
				],
				address: [
					{
						value: this.isCreate ? '' : this.user.user_profile.address,
						disabled: false,
					},
					[Validators.maxLength(this.defaultFieldLength)],
				],
				address2: [
					{
						value: this.isCreate ? '' : this.user.user_profile.address_2,
						disabled: false,
					},
					[Validators.maxLength(this.defaultFieldLength)],
				],
				city: [
					{
						value: this.isCreate ? '' : this.user.user_profile.city,
						disabled: false,
					},
					[Validators.maxLength(this.defaultFieldLength)],
				],
				state: [
					{
						value: this.isCreate ? '' : this.user.user_profile.state_province_region,
						disabled: false,
					},
					[Validators.maxLength(this.defaultFieldLength)],
				],
				postalCode: [
					{
						value: this.isCreate ? '' : this.user.user_profile.postal_code,
						disabled: false,
					},
					[Validators.maxLength(this.defaultFieldLength)],
				],
				country: [
					{
						value: this.isCreate ? '' : this.user.user_profile.country,
						disabled: false,
					},
					[Validators.maxLength(this.countryFieldLength)],
				],
				orgName: [
					{
						value: this.isCreate ? '' : this.user.user_profile.org_name,
						disabled: !this.currentUserPermissionService.isAdmin(null),
					},
					[Validators.maxLength(this.defaultFieldLength)],
				],
			},
			{}
		);
	};

	/**
	 * Validates the users entered username
	 */
	private checkCustomerUsernameValidators = (): void => {
		// validate the username based on our custom username requirements
		if (this.usernameValidators) {
			// use a regular for loop (can't return out of a foreach and we just want the first error to be set)
			for (const v of this.usernameValidators) {
				const regexp: RegExp = RegExp(v.validator);

				if (!regexp.test(this.userDetailForm.controls.username.value)) {
					this.userDetailForm.controls.username.setErrors({ [v.error]: true });

					return;
				}
			}
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import moment from 'moment-timezone';

@Injectable({
	providedIn: 'root',
})
export class MapTimeService {
	/**
	 * When the map server is initialised, the time difference between the client machine and the server hosting the UI App
	 * is calculated and the offset used to override the current time that is returned from the moment instance within this service.
	 * Note that no time zone is used in this calculation and also that the overriden moment value is returned
	 * via way of the public getCurDateTime method (note method getCurDateTime removed but just returned moment()).
	 * This seems to be key when the map is in live mode for showing accurate predicted times perhaps to end-users?
	 *
	 * @returns the resolved promise
	 */
	public init = async (): Promise<void> => {
		return new Promise((resolve, reject) => {
			// Retrieve the datestamp from the server
			let serverDate: string = null;
			let serverOffset: any = null;
			const xmlHttp: XMLHttpRequest = new XMLHttpRequest();

			try {
				xmlHttp.open('HEAD', window.location.href.toString(), true);
				xmlHttp.setRequestHeader('Content-Type', 'text/html');
				xmlHttp.send('');
				// wait for the request to be serviced:
				xmlHttp.onreadystatechange = function (): any {
					if (this.readyState === this.HEADERS_RECEIVED) {
						serverDate = this.getResponseHeader('Date');
						// Determine the offset between the server time and the local computer time
						serverOffset = moment(serverDate).diff(new Date());
						moment.now = (): number => serverOffset + Date.now();
						resolve(serverOffset + Date.now());
					}
				};
			} catch {
				// If any errors happen with server communication, ensure serverOffset is initialized such that
				// predictions can be generated using client-side time data
				serverOffset = 0;
				moment.now = (): number => serverOffset + Date.now();
				reject(serverOffset + Date.now());
			}
		});
	};
}

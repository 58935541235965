/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class VehicleEventsApiService {
	private readonly vehiclesUrl: string = '/vehicles';
	private readonly eventsUrl: string = '/events';
	private readonly eventUrl: string = '/event';
	private readonly audioUrl: string = '/audio';
	private readonly typesUrl: string = '/types';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the vehicle events for the given authority, vehicles and parameters from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param vehicleIds - the vehicle identifiers
	 * @param params - the parameters
	 * @returns the vehicle events response
	 */
	public getVehicleEvents = async (authorityId: string, vehicleIds: any, params: string): Promise<any> => {
		try {
			if (typeof vehicleIds === 'string') {
				return await this.httpService.get('/' + authorityId + this.vehiclesUrl + '/' + vehicleIds + this.eventsUrl + params);
			} else {
				return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.eventsUrl + params);
			}
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the vehicle event types for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the vehicle event types response
	 */
	public getVehicleEventTypes = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.eventUrl + this.typesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the vehicle event audio for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param params - the parameters
	 * @returns the vehicle events audio response
	 */
	public getVehicleEventAudio = async (authorityId: string, params: any): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.eventsUrl + this.audioUrl, params);
		} catch (exception) {
			throw exception;
		}
	};
}

<div class="grid-title">
	<div class="rider-count-by-block-and-vehicle-sub-list-title">
		<span>Vehicle {{ subListData.vehicle_id }}</span>
	</div>
</div>
<table>
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'arrival_time' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'arrival_time')"
				data-test="th.arrival_time"
				class="ellipsis">
				{{ 'T_CORE.TIME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'date' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'date')"
				data-test="th.date"
				class="ellipsis">
				{{ 'T_CORE.DATE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'route_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'route_id')"
				data-test="th.route_id"
				class="mw-col ellipsis">
				{{ 'T_CORE.ROUTE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				data-test="th.stop_name"
				class="mw-col ellipsis">
				{{ 'T_CORE.STOP' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'boardings' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'boardings')"
				data-test="th.boardings"
				class="mw-col ellipsis">
				{{ 'T_REPORT.BOARDINGS' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'alightings' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'alightings')"
				data-test="th.alightings"
				class="mw-col ellipsis">
				{{ 'T_REPORT.ALIGHTINGS' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'max_load' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'max_load')"
				data-test="th.max_load"
				class="mw-col ellipsis">
				{{ 'T_REPORT.PASSENGER_COUNT' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow">
			<td data-test="td.arrival_time" class="ellipsis">{{ reportDataItem.arrival_time !== null ? reportDataItem.arrival_time : 'N/A' }}</td>
			<td data-test="td.date" class="ellipsis">{{ reportDataItem.date }}</td>
			<td data-test="td.route_id" class="ellipsis">{{ reportDataItem.route_id !== null ? reportDataItem.route_id : 'N/A' }}</td>
			<td data-test="td.stop_name" class="ellipsis">{{ reportDataItem.stop_name !== null ? reportDataItem.stop_name : 'N/A' }}</td>
			<td data-test="td.boardings" class="ellipsis">{{ reportDataItem.boardings !== null ? reportDataItem.boardings : 'N/A' }}</td>
			<td data-test="td.alightings" class="ellipsis">{{ reportDataItem.alightings !== null ? reportDataItem.alightings : 'N/A' }}</td>
			<td data-test="td.max_load" class="ellipsis">{{ reportDataItem.max_load !== null ? reportDataItem.max_load : 'N/A' }}</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

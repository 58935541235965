<!--
COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

Information Contained Herein is Proprietary and Confidential.
The document is the property of "CUBIC" and may not be disclosed
distributed, or reproduced  without the express written permission of
"CUBIC".
-->

<div id="nb-map-details-nav-top" class="nb-map-header-container">
	<div class="header-title-row">
		<div class="nb-map-details-title-bar">
			<span class="nb-subhead" data-test="maps.title">{{ 'T_CORE.MAP_LIVE_OPS' | translate }}</span>
		</div>
	</div>
	<div id="nb-map-details-nav" class="nb-map-details-nav map-details-nav-menu">
		<!--Routes-->
		<div class="liveops-nav map-details-nav-menu-routes-container">
			<div class="map-details-nav-menu-sub-container">
				<a tabindex="1" (click)="navigateMenuItem(navEntityType.route)" data-test="maps.nav.routes">
					<svg class="liveops-nav-item" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
						<circle class="liveops-nav_bg" cx="40" cy="40" r="40"></circle>
						<path
							class="liveops-nav_icon"
							d="M55,35A5,5,0,1,1,55,45a4.54,4.54,0,0,1-4.59-3H44.59A4.54,4.54,0,0,1,40,45a4.54,4.54,0,0,1-4.59-3H29.59A4.54,4.54,0,0,1,25,45,5,5,0,1,1,25,35a4.54,4.54,0,0,1,4.59,3h5.81A4.54,4.54,0,0,1,40,35a4.54,4.54,0,0,1,4.59,3h5.81A4.54,4.54,0,0,1,55,35Z"></path>
					</svg>
					<div class="nb-section-title">
						<span class="ng-scope">{{ 'T_MAP.MAP_ROUTES_TITLE' | translate }}</span>
					</div>
				</a>
			</div>
		</div>
		<!--Stops-->
		<div class="liveops-nav map-details-nav-menu-entity-container">
			<div class="map-details-nav-menu-sub-container">
				<a tabindex="2" (click)="navigateMenuItem(navEntityType.stop)" data-test="maps.nav.stops">
					<svg class="liveops-nav-item" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
						<circle class="liveops-nav_bg" cx="40" cy="40" r="40"></circle>
						<path
							class="liveops-nav_icon"
							d="M25.89,25.89A19.24,19.24,0,0,1,40,20a19.24,19.24,0,0,1,14.11,5.86A19.24,19.24,0,0,1,60,40a19.24,19.24,0,0,1-5.86,14.11A19.23,19.23,0,0,1,40,60a19.23,19.23,0,0,1-14.11-5.86A19.23,19.23,0,0,1,20,40,19.23,19.23,0,0,1,25.89,25.89ZM28.7,51.3a15.85,15.85,0,0,0,22.59,0,15.85,15.85,0,0,0,0-22.59,15.85,15.85,0,0,0-22.59,0,15.85,15.85,0,0,0,0,22.59Zm4.22-18.37a10,10,0,0,1,14.16,0,10,10,0,0,1,0,14.16,10,10,0,0,1-14.16,0,10,10,0,0,1,0-14.16Z"></path>
					</svg>
					<div class="nb-section-title">
						<span class="ng-scope">{{ 'T_MAP.MAP_STOPS_TITLE' | translate }}</span>
					</div>
				</a>
			</div>
		</div>
		<!--Vehicles-->
		<div class="liveops-nav map-details-nav-menu-entity-container">
			<div class="map-details-nav-menu-sub-container">
				<a tabindex="3" (click)="navigateMenuItem(navEntityType.vehicle)" data-test="maps.nav.vehicles">
					<svg class="liveops-nav-item" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
						<circle class="liveops-nav_bg" cx="40" cy="40" r="40"></circle>
						<path class="liveops-nav_icon" d="M58,22,42.91,58h-2L35.69,44.31,22,39.06v-2Z"></path>
					</svg>
					<div class="nb-section-title">
						<span class="ng-scope">{{ 'T_MAP.MAP_VEHICLES_TITLE' | translate }}</span>
					</div>
				</a>
			</div>
		</div>

		<!--Blocks-->
		<div *ngIf="!replayModeActive()" class="liveops-nav map-details-nav-menu-blocks-container">
			<div class="map-details-nav-menu-sub-container">
				<a tabindex="4" (click)="navigateMenuItem(navEntityType.block)" data-test="maps.nav.blocks">
					<svg
						class="liveops-nav-item map-details-nav-menu-block-item"
						xmlns="http://www.w3.org/2000/svg"
						version="1.0"
						width="166.000000pt"
						height="168.000000pt"
						viewBox="0 0 80 80"
						preserveAspectRatio="xMidYMid meet">
						<circle class="liveops-nav_bg" cx="40" cy="40" r="40"></circle>
						<g transform="translate(7,72.000000) scale(0.04,-0.04)" fill="#ffffff" stroke="none">
							<path
								d="M581 1274 c-140 -51 -262 -101 -272 -110 -18 -16 -19 -37 -19 -324 0 -279 2 -310 17 -328 32 -35 518 -292 553 -292 35 0 521 257 553 292 15 18 17 49 17 328 0 286 -1 308 -19 324 -22 20 -527 206 -556 205 -11 -1 -134 -43 -274 -95z m527 -78 c123 -45 222 -86 221 -91 -3 -11 -444 -175 -469 -175 -23 0 -475 167 -475 175 0 11 445 173 475 174 14 0 125 -37 248 -83z m250 -407 l-3 -221 -215 -119 c-118 -65 -221 -118 -228 -119 -10 0 -12 55 -10 261 l3 262 210 77 c116 43 218 78 228 79 16 1 17 -14 15 -220z"></path>
						</g>
					</svg>
					<div class="nb-section-title">
						<span class="ng-scope">{{ 'T_MAP.MAP_BLOCKS_TITLE' | translate }}</span>
					</div>
				</a>
			</div>
		</div>
		<div *ngIf="!replayModeActive()" class="liveops-nav map-details-nav-menu-blocks-container">
			<div class="map-details-nav-menu-sub-container"></div>
		</div>
		<div *ngIf="!replayModeActive()" class="liveops-nav map-details-nav-menu-blocks-container">
			<div class="map-details-nav-menu-sub-container"></div>
		</div>
	</div>
</div>

<!--
    COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

    Information Contained Herein is Proprietary and Confidential.
    The document is the property of "CUBIC" and may not be disclosed
    distributed, or reproduced  without the express written permission of "CUBIC".
-->

<div
	id="mapDetailsOpenBtn"
	(click)="openNavView()"
	class="nb-map-details-button-data-view-open"
	[ngClass]="{ 'details-open-fullscreen': mapOptionsService.getFullScreen() }"
	[hidden]="mapOptionsService.getNavViewOpen()">
	<i class="fa fa-angle-right"></i>
</div>

<div
	[ngClass]="mapOptionsService.getNavViewOpen() ? 'nb-map-nav-view-container map-nav-view-container' : 'nb-map-nav-view-container-closed'"
	id="mapNavViewContainer"
	[ngStyle]="navAnimation">
	<div class="nb-map-details pull-left nb-map-details">
		<div class="nb-map-details-content">
			<div class="nb-map-details-home">
				<div *ngIf="currentNavEntityType !== null">
					<div class="map-nav-breadcrumbs-container">
						<!--we need an empty div when no breadcrumbs displayed -->
						<div *ngIf="currentNavEntityType === navEntityType.menu"></div>
						<div *ngIf="currentNavEntityType !== navEntityType.menu">
							<div class="pull-left">
								<breadcrumbs [breadcrumbItems]="breadcrumbItems" [useRouter]="false" (navigate)="handleBreadcrumbNavigate($event)">
								</breadcrumbs>
							</div>
						</div>

						<div class="pull-right">
							<button
								*ngIf="currentNavEntityType !== navEntityType.menu"
								class="btn-link-dark map-nav-refresh-button"
								(click)="refreshView()"
								title="{{ 'T_MAP.MAP_REFRESH' | translate }}"
								[disabled]="!enableRefresh"
								data-test="details.refresh">
								<i class="nb-icons nb-refresh map-nav-refresh-button-icon"></i>
							</button>
							<button type="button" class="btn-link-dark" id="closeDataViewBtn" data-test="details.close" (click)="closeNavView()">
								<i
									[ngClass]="{ 'map-nav-close-button-icon-menu-open': currentNavEntityType === navEntityType.menu }"
									class="nb-icons nb-close map-nav-close-button-icon"></i>
							</button>
						</div>
					</div>

					<div [hidden]="currentNavEntityType !== navEntityType.menu">
						<map-navigation-menu (navigateToPage)="navigateToPage($event)"></map-navigation-menu>
						<map-views-list></map-views-list>
					</div>

					<ng-container [ngSwitch]="currentNavEntityType">
						<ng-container *ngSwitchCase="navEntityType.route">
							<routes-list *ngIf="currentNavPageType === navPageType.list" (enableRefresh)="handleEnableRefresh($event)"> </routes-list>
							<route-details
								*ngIf="currentNavPageType === navPageType.details"
								[routeId]="currentNavEntity.routeId"
								(enableRefresh)="handleEnableRefresh($event)">
							</route-details>
						</ng-container>

						<ng-container *ngSwitchCase="navEntityType.block">
							<blocks-list *ngIf="currentNavPageType === navPageType.list" (enableRefresh)="handleEnableRefresh($event)"> </blocks-list>
							<block-details
								*ngIf="currentNavPageType === navPageType.details"
								[blockId]="currentNavEntity.blockId"
								(enableRefresh)="handleEnableRefresh($event)">
							</block-details>
						</ng-container>

						<ng-container *ngSwitchCase="navEntityType.stop">
							<stops-list *ngIf="currentNavPageType === navPageType.list" (enableRefresh)="handleEnableRefresh($event)"> </stops-list>
							<stop-details
								*ngIf="currentNavPageType === navPageType.details"
								[stopCode]="currentNavEntity.stopCode"
								(enableRefresh)="handleEnableRefresh($event)">
							</stop-details>
						</ng-container>

						<ng-container *ngSwitchCase="navEntityType.vehicle">
							<vehicles-list *ngIf="currentNavPageType === navPageType.list" (enableRefresh)="handleEnableRefresh($event)"> </vehicles-list>
							<vehicle-details
								*ngIf="currentNavPageType === navPageType.details"
								[vehicleId]="currentNavEntity.vehicleId"
								[activeTab]="currentNavEntity.activeTab"
								(enableRefresh)="handleEnableRefresh($event)">
							</vehicle-details>
						</ng-container>

						<ng-container *ngSwitchCase="navEntityType.vehicleReassign">
							<vehicle-reassign
								*ngIf="currentNavPageType === navPageType.details"
								[vehicleId]="currentNavEntity.vehicleId"
								[vehicleRouteNbId]="currentNavEntity.vehicleRouteNbId"
								[vehicleBlockId]="currentNavEntity.vehicleBlockId"
								(enableRefresh)="handleEnableRefresh($event)">
							</vehicle-reassign>
						</ng-container>

						<ng-container *ngSwitchCase="navEntityType.vehicleReassignBlock">
							<vehicle-reassign-blocks
								*ngIf="currentNavPageType === navPageType.details"
								[vehicleId]="currentNavEntity.vehicleId"
								[vehicleRouteNbId]="currentNavEntity.vehicleRouteNbId"
								[reassignRouteId]="currentNavEntity.reassignRouteId"
								[reassignRouteNbId]="currentNavEntity.reassignRouteNbId"
								(enableRefresh)="handleEnableRefresh($event)">
							</vehicle-reassign-blocks>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

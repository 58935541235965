<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid navigable-report">
				<table>
					<thead>
						<tr>
							<th
								[ngClass]="sortDetails.field === 'route_id' ? sortClass : null"
								(click)="sortList('route_id')"
								data-test="th.route_id"
								class="ellipsis">
								{{ 'T_CORE.ROUTE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'boardings' ? sortClass : null"
								(click)="sortList('boardings')"
								data-test="th.boardings"
								class="mw-col ellipsis">
								{{ 'T_REPORT.BOARDINGS' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'alightings' ? sortClass : null"
								(click)="sortList('alightings')"
								data-test="th.alightings"
								class="mw-col ellipsis">
								{{ 'T_REPORT.ALIGHTINGS' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'max_load' ? sortClass : null"
								(click)="sortList('max_load')"
								data-test="th.max_load"
								class="mw-col ellipsis">
								{{ 'T_REPORT.MAX_LOAD' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'max_load_pct' ? sortClass : null"
								(click)="sortList('max_load_pct')"
								data-test="th.max_load_pct"
								class="mw-col ellipsis">
								{{ 'T_REPORT.MAX_LOAD_PCT' | translate }}
							</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr data-test="td.route_id" *ngFor="let reportItem of listData" (click)="generateReport(reportItem)">
							<td>
								<div class="truncate">
									<route-pill class="report-viewer-grid-route-pill" [route]="determineRoutePillData(reportItem)"></route-pill>
									<div class="truncate bold">{{ reportItem.route_long_name }}</div>
								</div>
							</td>
							<td data-test="td.boardings" class="ellipsis">{{ reportItem.boardings !== null ? reportItem.boardings : 'N/A' }}</td>
							<td data-test="td.alightings" class="ellipsis">{{ reportItem.alightings !== null ? reportItem.alightings : 'N/A' }}</td>
							<td data-test="td.max_load" class="ellipsis">{{ reportItem.max_load !== null ? reportItem.max_load : 'N/A' }}</td>
							<td data-test="td.max_load_pct" class="ellipsis">
								{{ reportItem.max_load_pct !== null ? (reportItem.max_load_pct * 100).toFixed() + '%' : 'N/A' }}
							</td>
						</tr>
					</tbody>
				</table>
				<br />
				<table>
					<thead>
						<tr>
							<th class="mw-col ellipsis"></th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.BOARDINGS' | translate }}</th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.ALIGHTINGS' | translate }}</th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.MAX_LOAD_TOTAL' | translate }}</th>
							<th class="mw-col ellipsis">{{ 'T_REPORT.MAX_LOAD_PCT_TOTAL' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let totalItem of totalsData">
							<td class="ellipsis bold">{{ 'T_REPORT.TOTALS' | translate }}</td>
							<td class="ellipsis">{{ totalItem.boardings }}</td>
							<td class="ellipsis">{{ totalItem.alightings }}</td>
							<td class="ellipsis">{{ totalItem.max_load }}</td>
							<td class="ellipsis">{{ (totalItem.max_load_pct * 100).toFixed() + '%' }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { SignsDataService } from './service/signs-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { Columns, ColumnType, ListSortings, SortDirection } from '@cubicNx/libs/utils';
import { MessageSelectedSigns, Signs } from './types/types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'sign-selection',
	templateUrl: './sign-selection.component.html',
	styleUrls: ['./sign-selection.component.scss'],
})
export class SignSelectionComponent extends TranslateBaseComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() initialSignsSelected: string[] = [];

	@Output() signsSelected: EventEmitter<MessageSelectedSigns> = new EventEmitter<MessageSelectedSigns>();

	// pass through the defaukt sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'id', dir: SortDirection.asc }];

	public columns: Columns = [];
	public signs: MessageSelectedSigns = [];
	public listLoadingIndicator: boolean = false;
	public totalRows: number = 0;
	public initailized: boolean = false;
	public selectedRows: MessageSelectedSigns = [];
	public listName: string = 'signs-list';

	constructor(
		private signsService: SignsDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the sign selection component
	 *
	 * gets signs builds list columns and sets the selected signs accordingly
	 */
	public async ngOnInit(): Promise<void> {
		await this.getSigns();

		this.buildListColumns();
		this.setSelectedSigns();

		this.initailized = true;
	}

	/**
	 * when a sign is selected from the list, this emits the selection up to the parent
	 *
	 * @param signs - the signs
	 */
	public onCheckSelect = (signs: MessageSelectedSigns): void => {
		this.selectedRows = signs;

		// pass the selected signs to the parent
		this.signsSelected.emit(this.selectedRows);
	};

	/**
	 * build the column list for the underlying datatable - camel case equivalents of properties from back end
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: 'id',
				displayName: 'Sign Id',
				columnType: ColumnType.text,
			},
			{
				name: 'name',
				displayName: 'Sign Name',
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * retrievs the signs data from the backend and populates the signs list
	 */
	private getSigns = async (): Promise<void> => {
		this.listLoadingIndicator = true;

		this.totalRows = 0;

		const result: ResultContent = await this.signsService.getSigns(this.authorityId, this.agencyId);

		if (result.success) {
			const signsResponse: Signs = result.resultData;

			if (signsResponse?.signInfos?.length > 0) {
				// object for our list
				this.signs = signsResponse.signInfos.map((sign) => ({
					id: sign.id,
					name: sign.location,
				}));

				this.totalRows = signsResponse.signInfos.length;
			}
		}

		this.listLoadingIndicator = false;
	};

	/**
	 * emits the selected signs to the parent
	 */
	private setSelectedSigns = (): void => {
		if (this.initialSignsSelected.length > 0) {
			// determine which signs from the retrived list to default as selected from our list of selected sign ids
			this.selectedRows = this.signs.filter((sign) => this.initialSignsSelected.indexOf(sign.id) > -1);

			// pass the selected signs to the parent (so the parent knows the signs component initaially passes valididation in edit mode)
			this.signsSelected.emit(this.selectedRows);
		}
	};
}

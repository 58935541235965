/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { StateService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapUtilsService } from '../../../services/map-utils.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { ListShowingValues } from '../../../types/types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { RoutePillData } from '@cubicNx/libs/utils';
import { RoutesList, RoutesListItem } from '../../../../../support-features/routes/types/types';

import {
	Route,
	RouteExtended,
	Routes,
	RoutesExtended,
	RoutesExtendedPaginatedResponse,
} from '../../../../../support-features/routes/types/api-types';

import {
	ColumnType,
	Columns,
	ListSortings,
	PageRequestInfo,
	SelectableIconType,
	SelectedRowData,
	SortDirection,
} from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'routes-list',
	templateUrl: './routes-list.component.html',
	styleUrls: ['./routes-list.component.scss'],
})
export class RoutesListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('routePillColumnTemplate', { static: true, read: TemplateRef }) routePillColumnTemplate: TemplateRef<any>;

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'route', dir: SortDirection.asc }];

	public mapNavigateRefresh$Subscription: Subscription = null;

	public initialized: boolean = false;
	public updatedAt: string = null;
	public listName: string = 'routes-list';
	public columns: Columns = [];
	public routesList: RoutesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo = null;
	public totalRows: number = 0;
	public showingFirst: number = 0;
	public showingLast: number = 0;
	public routeSearchSuggestions: string[] = [];
	public searchText: string = '';

	private readonly routeSelectedIconClass: string = 'nb-icons nb-view-on';
	private readonly routeNotSelectedIconClass: string = 'nb-icons nb-view-off';
	private readonly routeSelectedIconDisabledClass: string = 'nb-icons nb-view-on nb-view-on-disabled';
	private readonly routeNotSelectedIconDisabledClass: string = 'nb-icons nb-view-off nb-view-off-disabled';

	private readonly routeVisibilityColumnName: string = 'routeVisibility';
	private readonly routeColumnName: string = 'route';
	private readonly routeLongNameColumnName: string = 'routeLongName';

	private routes: RoutesExtended = [];
	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private authorityId: string = null;
	private agencyId: string = null;

	constructor(
		private routesDataService: RoutesDataService,
		private agenciesDataService: AgenciesDataService,
		private mapUtilsService: MapUtilsService,
		private mapRoutesService: MapRoutesService,
		private mapEventsService: MapEventsService,
		private mapHistoryService: MapHistoryService,
		private mapNavigationService: MapNavigationService,
		private stateService: StateService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the route list
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();

		this.loadCache();
		this.buildListColumns();

		this.getSelectedAgency();

		this.initialized = true;

		// Do after initialize so it doesnt hold up the list rendering with its loading spinner
		// as we didnt need to wait for search suggestions
		await this.buildSearchSuggestions();
	}

	/**
	 * refresh the list following a refresh click (from parent component)
	 */
	public refresh = async (): Promise<void> => {
		if (this.initialized) {
			await this.getRoutes();
		}
	};

	/**
	 * handle the routes data request triggered from the datatable
	 * @param pageInfo - the page details (page number/size and sorting info)
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getRoutes();
	};

	/**
	 * handle a search from the user and reload the routes list with the filter in place
	 * @param searchText - the search text entered by the user
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getRoutes();
	};

	/**
	 * construct the route pill object using information retrieved about the route
	 *
	 * @param routeId - the route for the target block
	 * @returns the route pill data object used to populate our route pill badges
	 */
	public determineRoutePillData = (routeId: string): RoutePillData => {
		const route: RouteExtended = this.determineRoute(routeId);

		return {
			routeShortName: route.route_short_name,
			routeLongName: route.route_long_name,
			routeId: route.route_id,
			routeColor: this.colorUtilityService.getColor(route.route_color),
			routeTextColor: this.colorUtilityService.getColor(route.route_text_color),
		};
	};

	/**
	 * handle the select of a row in the route list and navigate to block details
	 * @param selectedRow - the selected row including the block id
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const selectedRoute: RoutesListItem = selectedRow.row;

		// Lookup the full route details
		const route: RouteExtended = this.determineRoute(selectedRoute.id);

		if (selectedRow.columnNameSelected === this.routeVisibilityColumnName) {
			this.toggleRenderRoute(route);
		} else {
			this.navigateToRouteDetails(route);
		}
	};

	/**
	 * handle the back button click and return to the users previous location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * handle any cleanup for the component (unsubsribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * navigate refresh - handle the click of the parent refresh button and refresh the list
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.refresh();
		});
	};

	/**
	 * get the users current agency
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
		}
	};

	/**
	 * determine the route for a given route id
	 *
	 * @param routeId - the route id to find
	 * @returns the route
	 */
	private determineRoute = (routeId: string): RouteExtended => {
		return this.routes.find((retrievedRoute: RouteExtended) => retrievedRoute.route_id === routeId);
	};

	/**
	 * determine if the route is displayed on the map
	 *
	 * @param route - the route to check
	 * @returns true if the route is displayed on the map
	 */
	private routeDisplayed = (route: RouteExtended): boolean => {
		return this.mapRoutesService.routeDisplayed(route.route_id);
	};

	/**
	 * toggle the rendering of the route on the map
	 * @param route - the route to render
	 */
	private toggleRenderRoute = async (route: RouteExtended): Promise<void> => {
		if (this.routeDisplayed(route)) {
			this.mapRoutesService.removeRoute(route.route_id);
		} else {
			await this.mapRoutesService.addRoute(route);
		}
	};

	/**
	 * navigate to the route details using the supplied route
	 * @param route - the route to navigate to
	 */
	private navigateToRouteDetails = async (route: RouteExtended): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(route.authority_id, route.route_id);
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_ROUTE',
			'T_MAP.MAP_NAME',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP',
		]);
	};

	/**
	 * load any persisted data for the component (list page info and filter search text)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * persist the page settings for the list
	 * @param pageInfo - the page settings
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * persist the search text
	 * @param search - the search text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * map the properties from our camel case list to property name recognised by the nextbus API to prepare for our backend sorting
	 * @param sortByValue - the sort by column name
	 * @returns - the sort by property name recognised by the nextbus API
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case this.routeColumnName:
				columnName = 'route_short_name';
				break;
			case this.routeLongNameColumnName:
				columnName = 'route_long_name';
				break;
			default:
				columnName = 'route_short_name';
		}

		return columnName;
	}

	/**
	 * build the columns for the list
	 */
	private buildListColumns = (): void => {
		// Build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: this.routeVisibilityColumnName,
				displayName: '',
				sortable: false,
				columnType: ColumnType.selectableIcon,
				fixedWidth: 50,
			},
			{
				name: this.routeColumnName,
				displayName: this.translations['T_MAP.MAP_ROUTE'],
				componentTemplate: this.routePillColumnTemplate,
				columnType: ColumnType.component,
				width: 250,
			},
			{
				name: this.routeLongNameColumnName,
				displayName: this.translations['T_MAP.MAP_NAME'],
				columnType: ColumnType.text,
				width: 500,
			},
		];
	};

	/**
	 * build the search suggestion list after retrieving the data from the route data layer
	 */
	private buildSearchSuggestions = async (): Promise<void> => {
		const response: ResultContent = await this.routesDataService.getRouteSuggestions(this.authorityId, this.agencyId);

		if (response.success) {
			const routes: Routes = response.resultData;

			// build a list of suggestions
			routes.forEach((route: Route) => {
				this.addSearchSuggestion(route.route_short_name);
				this.addSearchSuggestion(route.route_long_name);
			});
		}
	};

	/**
	 * add the individual search suggestion to the list
	 * @param searchSuggestion - the search suggestion
	 */
	private addSearchSuggestion = (searchSuggestion: string): void => {
		if (!this.routeSearchSuggestions.includes(searchSuggestion)) {
			this.routeSearchSuggestions.push(searchSuggestion);
		}
	};

	/**
	 * prepare a function to load the appropriate tooltip for the 'eye' visibility icon in the list
	 * @param route - the route
	 * @returns a function to pass in to our datatable to allow this parent to handle the underlying tooltip
	 */
	private getRouteIconDynamicTooltip = (route: RouteExtended): Function => {
		return (): string =>
			this.routeDisplayed(route)
				? this.translations['T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP']
				: this.translations['T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP'];
	};

	/**
	 * prepare a function to load the appropriate styling for the 'eye' visibility icon in the list
	 * @param route - the route
	 * @returns a function to pass in to our datatable to allow this parent to handle the underlying styling
	 */
	private getRouteVisibilityDynamicState = (route: RouteExtended): Function => {
		return (): boolean => this.routeDisplayed(route);
	};

	/**
	 * map the route details to determine the route list visibility icon style details
	 * @param route - the route
	 * @returns the icon styling ready for display
	 */
	private mapRouteVisibilityIconValue = (route: RouteExtended): SelectableIconType => {
		return {
			iconName: this.getRouteIconDynamicTooltip(route),
			selectedState: {
				selected: this.getRouteVisibilityDynamicState(route),
				selectedIconClass: this.routeSelectedIconClass,
				notSelectedIconClass: this.routeNotSelectedIconClass,
				selectedIconDisabledClass: this.routeSelectedIconDisabledClass,
				notSelectedIconDisabledClass: this.routeNotSelectedIconDisabledClass,
			},
		};
	};

	/**
	 * get the routes from the data layer
	 */
	private getRoutes = async (): Promise<void> => {
		// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
		this.enableRefresh.emit(false);

		this.listLoadingIndicator = true;
		this.totalRows = 0;

		const response: ResultContent = await this.routesDataService.getRoutesListExtended(
			this.authorityId,
			this.agencyId,
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.mapColumnName(this.pageInfo.sort),
			this.pageInfo.sortDir,
			this.searchText
		);

		if (response.success) {
			const resultData: RoutesExtendedPaginatedResponse = response.resultData;

			if (resultData?.results) {
				this.routes = response.resultData.results as RoutesExtended;

				this.routesList = this.routes.map((route: RouteExtended) => ({
					id: route.route_id,
					routeVisibility: this.mapRouteVisibilityIconValue(route),
					routeLongName: route.route_long_name,
				}));

				this.totalRows = resultData.total;

				const listShowingValues: ListShowingValues = this.mapUtilsService.getShowingPageValues(
					+this.pageInfo.pageNum,
					+this.pageInfo.pageSize,
					this.totalRows
				);

				this.showingFirst = listShowingValues.showingFirst;
				this.showingLast = listShowingValues.showingTo;

				this.updatedAt = moment().format('HH:mm:ss');
			}
		}

		this.listLoadingIndicator = false;

		this.enableRefresh.emit(true);
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
	};
}

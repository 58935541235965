<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".
-->

<div class="header-container">
	<button (click)="edit()" [disabled]="messageSelection.length !== 1" class="btn btn-action btn-action-messages">
		<i class="nb-icons nb-edit"></i>
		{{ 'T_CORE.EDIT' | translate }}
	</button>
	<button (click)="delete()" [disabled]="messageSelection.length === 0" class="btn btn-action btn-action-messages">
		<i class="nb-icons nb-delete"></i>
		{{ 'T_CORE.DELETE' | translate }}
	</button>
	<button (click)="expandAll()" class="btn btn-action btn-action-messages">
		<i class="nb-icons nb-expand"></i>
		<span *ngIf="!allRowsExpanded">{{ 'T_RIDER.EXPAND_ALL' | translate }}</span>
		<span *ngIf="allRowsExpanded">{{ 'T_RIDER.COLLAPSE_ALL' | translate }}</span>
	</button>

	<div class="float-right">
		<search-filter [filterValue]="searchText" (search)="search($event)"></search-filter>
		<span class="tooltip-arrow-top">
			<button *ngIf="hasFilters" class="btn btn-action nb-unfiltered-ctrl-text ng-binding clear-btn">
				<i class="nb-icons nb-clearfield nb-margin-right-sm">
					<span class="tooltiptext">{{ 'T_CORE.CLEAR_ALL_FILTERS' | translate }}</span> </i
				>{{ 'T_CORE.CLEAR' | translate }}
			</button>
		</span>
	</div>
</div>

<!-- template to pass in to the data table for expanded view. Note row will contain the list row at runtime -->
<ng-template #expandedDetailTemplate let-row="row">
	<rider-messages-list-expanded-detail
		[messageListRow]="row"
		[parentListColumnSizes]="columnSizes"
		[authorityId]="authorityId"
		[agencyId]="agencyId">
	</rider-messages-list-expanded-detail>
</ng-template>

<div class="data-list-container" *ngIf="initialized">
	<data-table
		#dataList
		data-test="rider-messages.list.table"
		[listName]="listName"
		[columns]="columns"
		[rowData]="messagesList"
		[pagingEnabled]="true"
		[totalRows]="totalRows"
		[defaultSortings]="defaultSortings"
		[pageInfo]="pageInfo"
		[selectedRows]="messageSelection"
		[enableCheckRowSelection]="true"
		[enabledMultiCheckRowSelection]="true"
		[enabledCheckSelectAllRowSelection]="true"
		[enableMenuSelection]="false"
		[enableFooter]="true"
		[loadingIndicator]="listLoadingIndicator"
		[expandedDetailTemplate]="expandedDetailTemplate"
		(columnSizes)="setColumnSizes($event)"
		(rowClick)="onSelect($event)"
		(checkSelect)="onCheckSelect($event)"
		(curentPageAllRowsExpanded)="curentPageAllRowsExpanded($event)"
		(requestData)="handleDataRequest($event)">
	</data-table>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { LoggerService } from '@cubicNx/libs/utils';
import { RiderMessagesModalService } from '../../services/rider-messages-modal.service';
import { RiderMessagesDataService } from '../../services/rider-messages-data.service';
import { RoutesDataService } from '../../../../support-features/routes/services/routes-data.service';
import { RoutesUtilService } from '../../../../support-features/routes/services/routes-util.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { RiderMessage, StopServiceAlert, StopServiceAlerts, TripHeadsigns } from '../../types/api-types';
import { Route, Routes } from '../../../../support-features/routes/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	MessageRoute,
	MessageRoutes,
	MessageSelectedRoute,
	MessageSelectedRoutes,
	SelectedStop,
	StopsPerDestination,
	StopsPerDestinations,
} from '../../types/types';

@Component({
	selector: 'route-selection',
	templateUrl: './route-selection.component.html',
	styleUrls: ['./route-selection.component.scss'],
})
export class RouteSelectionComponent extends TranslateBaseComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() isCreate: boolean = false;

	@Input() currentMessage: RiderMessage = null;

	@Output() routesSelected: EventEmitter<MessageSelectedRoutes> = new EventEmitter<MessageSelectedRoutes>();
	@Output() routesValid: EventEmitter<boolean> = new EventEmitter<boolean>();

	public routeStopSelectionType: string = 'all';
	public selectedRouteId: string = 'placeholder';
	public allRoutesList: MessageRoutes;
	public selectedRoutes: MessageSelectedRoutes = [];

	private readonly allStopsSetting: string = 'all';
	private readonly specificStopsSetting: string = 'specific';
	private readonly routeSelectionPlaceholder: string = 'placeholder';
	private readonly allStopsLabel: string = 'All Stops';
	private readonly specificStopsLabel: string = 'Specific Stops';

	constructor(
		private routesDataService: RoutesDataService,
		private routesUtilService: RoutesUtilService,
		private riderMessagesDataService: RiderMessagesDataService,
		private riderMessagesModalService: RiderMessagesModalService,
		private logger: LoggerService,
		tranlsationService: TranslationService
	) {
		super(tranlsationService);
	}

	/**
	 * performs initialization tasks for the route selection view
	 *
	 * loads route data, initializes edit details if edit mode
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadRouteData();

		if (!this.isCreate) {
			this.initializeEdit(this.currentMessage.stop_service_alerts, this.currentMessage.routes);
		}
	}

	/**
	 * handles the route being selected
	 *
	 * retrieves stops for the selected route then populates the form data
	 */
	public routeSelected = async (): Promise<void> => {
		const response: ResultContent = await this.riderMessagesDataService.getStopsByRoute(this.authorityId, this.selectedRouteId);

		if (response.success) {
			const stopInfo: any = response.resultData;

			// despite the name (from back end) and, this is essentially the stops for each destination in a route
			const tripHeadsigns: TripHeadsigns = stopInfo[this.selectedRouteId].trip_headsigns;

			// the above call returns stops for each destination (despite the name) keyed by stop id.
			// It's easier for us to work with (and iterate over) a regular list
			const stopsPerDestinations: StopsPerDestinations = this.getStopsPerDesinationList(tripHeadsigns);

			this.addRoute(this.selectedRouteId, stopsPerDestinations);

			// revert the selection drop down back to the placeholder
			// setting the selectedRouteId here after we have just got to this method after it has changed
			// caused an edge case where the selectedRouteId wouldn't always be set back to the placeholder
			// a small delay is actully preferable anyway for the user experience to avoid it flickering
			setTimeout(() => {
				this.selectedRouteId = this.routeSelectionPlaceholder;
			}, 250);
		}
	};

	/**
	 * removes the supplied route from the form
	 *
	 * @param routeId - the route id
	 * @param routeIndex - the route index
	 */
	public removeRoute = (routeId: string, routeIndex: number): void => {
		const formattedRouteId: string[] = routeId.split('-');

		routeId = formattedRouteId[0].replace(/\s/g, '');

		this.selectedRoutes.splice(routeIndex, 1);

		// update the parent with the updated selection list
		this.routesSelected.emit(this.selectedRoutes);

		// update the parent with the validation state
		this.routesValid.emit(this.getRoutesValid());
	};

	/**
	 * renders the edit stop dialog for the supplied route
	 *
	 * on confirmation, any stops per destination are rememeberd and emitted back to the parent
	 *
	 * @param routeId - the route id
	 */
	public editStops = async (routeId: string): Promise<void> => {
		const selectedRoute: MessageSelectedRoute = this.getSelectedRoute(routeId);

		this.riderMessagesModalService
			.editStops(selectedRoute.stopsPerDestinations)
			.subscribe((stopsPerDestinations: StopsPerDestinations) => {
				if (stopsPerDestinations) {
					selectedRoute.stopsPerDestinations = stopsPerDestinations;
					selectedRoute.stopsLabel = this.getStopsLabelForRoute(selectedRoute.stopsPerDestinations);

					// emit the whole selection back to the parent to process
					this.routesSelected.emit(this.selectedRoutes);
				}
			});
	};

	/**
	 * handles the user changing routes stop selection
	 */
	public routeStopSelectionTypeChange = (): void => {
		if (this.routeStopSelectionType === this.allStopsSetting) {
			this.selectedRoutes = [];
			this.routesSelected.emit(this.selectedRoutes);
		}

		this.routesValid.emit(this.getRoutesValid());
	};

	/**
	 * determines whether the supplied route is selected or not
	 *
	 * @param routeId - the route id
	 * @returns true if the supplied route is the selected one, false otherwise
	 */
	public isRouteSelected = (routeId: string): boolean => {
		return this.getSelectedRoute(routeId) !== undefined;
	};

	/**
	 * loads the form with routes data
	 */
	private loadRouteData = async (): Promise<void> => {
		try {
			const result: ResultContent = await this.routesDataService.getRoutes(this.authorityId, this.agencyId);

			if (result.success) {
				const routes: Routes = result.resultData;

				this.allRoutesList = routes.flatMap((route: Route) => {
					const messageRoute: MessageRoute = {
						routeId: route.route_id,
						routeDisplayName: this.routesUtilService.getDisplayName(route),
						routeIsPredictEnabled: route.route_is_predict_enabled,
						routeIsHidden: route.route_is_hidden,
						routeIsEnabled: route.route_is_enabled,
					};

					return [messageRoute];
				});

				this.sortMessageRoutes(this.allRoutesList);
			}
		} catch (error: any) {
			this.logger.logError(error.message);
		}
	};

	/**
	 * sorts the messages routes data
	 *
	 * @param messageRoutes - the message routes
	 * @returns sorted message routes
	 */
	private sortMessageRoutes = (messageRoutes: MessageRoutes): MessageRoutes => {
		return messageRoutes.sort((a, b) => a.routeDisplayName.localeCompare(b.routeDisplayName, 'en', { numeric: true }));
	};

	/**
	 * initializes the form for edit purposes
	 *
	 * @param stopServiceAlerts - the stop service alerts
	 * @param routes - the routes
	 */
	private initializeEdit = (stopServiceAlerts: StopServiceAlerts, routes: string[]): void => {
		this.routeStopSelectionType = this.allStopsSetting;

		// if routes has a value then a specific route was selected (empty when all routes/stops selected)
		if (routes.length > 0) {
			this.routeStopSelectionType = this.specificStopsSetting;

			routes.forEach(async (route) => {
				const routeId: string = route;

				const response: ResultContent = await this.riderMessagesDataService.getStopsByRoute(this.authorityId, routeId);

				if (response.success) {
					const stopInfo: any = response.resultData;

					const tripHeadsigns: TripHeadsigns = stopInfo[routeId].trip_headsigns;
					const stopsPerDestinations: StopsPerDestinations = [];

					tripHeadsigns.forEach((tripHeadsign) => {
						const stopsPerDestination: StopsPerDestination = {
							id: tripHeadsign.id,
							stops: [],
						};

						tripHeadsign.stops.forEach((stop) => {
							const selectedStop: SelectedStop = {
								id: stop.stopId,
								name: stop.stopName,
								selected: this.determineStopSelectedState(routeId, stop.stopId, stopServiceAlerts),
							};

							stopsPerDestination.stops.push(selectedStop);
						});

						stopsPerDestinations.push(stopsPerDestination);
					});

					this.addRoute(routeId, stopsPerDestinations);
				}
			});
		} else {
			//no routes were selected and thus all routes and stops are selected.
			this.routeStopSelectionType = this.allStopsSetting;
		}
	};

	/**
	 * determines the state of the selected stops
	 *
	 * @param routeId - the route id
	 * @param stopId - the stop id
	 * @param stopServiceAlerts - the stop service alerts
	 * @returns true if there is a route and stop within the stop service alerts, false otherwise
	 */
	private determineStopSelectedState = (routeId: string, stopId: string, stopServiceAlerts: StopServiceAlerts): boolean => {
		// if the route/stop we are looking for is returned from the back end stopServiceAlerts then we know this stop is selected
		return (
			stopServiceAlerts.filter(
				(stopServiceAlert: StopServiceAlert) => stopServiceAlert.route_id === routeId && stopServiceAlert.stop_id_text === stopId
			).length > 0
		);
	};

	/**
	 * retrieves the stops per destination list from the supplied trip headsigns
	 *
	 * @param tripHeadsigns - the trip headsigns
	 * @returns the stops per destination for the supplied trip headsigns
	 */
	private getStopsPerDesinationList = (tripHeadsigns: TripHeadsigns): StopsPerDestinations => {
		const stopsPerDestinations: StopsPerDestinations = [];

		tripHeadsigns.forEach((tripHeadsign) => {
			const stopsPerDestination: StopsPerDestination = {
				id: tripHeadsign.id,
				stops: [],
			};

			tripHeadsign.stops.forEach((stop) => {
				const selectedStop: SelectedStop = {
					id: stop.stopId,
					name: stop.stopName,
					selected: true,
				};

				stopsPerDestination.stops.push(selectedStop);
			});

			stopsPerDestinations.push(stopsPerDestination);
		});

		return stopsPerDestinations;
	};

	/**
	 * adds a route to the selection component
	 *
	 * @param routeId - the route id
	 * @param stopsPerDestinations - the stops per destination
	 */
	private addRoute = async (routeId: string, stopsPerDestinations: StopsPerDestinations): Promise<void> => {
		const messageRoute: MessageRoute = this.allRoutesList.filter((route) => route.routeId === routeId)[0];

		const selectedRoute: MessageSelectedRoute = {
			id: messageRoute.routeId,
			routeDetails: messageRoute,
			stopsLabel: this.getStopsLabelForRoute(stopsPerDestinations),
			stopsPerDestinations,
			availabeStops: stopsPerDestinations.length > 0,
		};

		this.selectedRoutes.push(selectedRoute);

		// update the parent with the selected routes (defaulted with all stops)
		this.routesSelected.emit(this.selectedRoutes);

		// update the parent with the validation state
		this.routesValid.emit(this.getRoutesValid());
	};

	/**
	 * retrieves the selected message route
	 *
	 * @param routeId - the route id
	 * @returns the selected message route
	 */
	private getSelectedRoute = (routeId: string): MessageSelectedRoute => {
		return this.selectedRoutes.filter((route) => route.id === routeId)[0];
	};

	/**
	 * retrieves stop labels for the route
	 *
	 * @param stopsPerDestinations - the stops per destinations
	 * @returns stop labels
	 */
	private getStopsLabelForRoute = (stopsPerDestinations: StopsPerDestinations): string => {
		let stopsLabel: string = this.allStopsLabel;
		let allStops: number = 0;
		let editStops: number = 0;

		stopsPerDestinations.forEach((destination) => {
			allStops += destination.stops.length;

			const selectedStops: SelectedStop[] = destination.stops.filter((stop) => stop.selected === true);

			if (selectedStops) {
				editStops += selectedStops.length;
			}
		});

		if (editStops !== allStops) {
			stopsLabel = this.specificStopsLabel;
		}

		stopsLabel += ' (' + editStops + ')';

		return stopsLabel;
	};

	/**
	 * determines whether the routes are valid or not
	 *
	 *  all selected or at least one route selected
	 *
	 * @returns true if the routes are valid, false otherwise
	 */
	private getRoutesValid = (): boolean => {
		return this.routeStopSelectionType === this.allStopsSetting || this.selectedRoutes.length > 0;
	};
}

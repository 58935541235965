import { Injectable } from '@angular/core';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { ConfigService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../agencies/services/agencies-data.service';
import { LogoutService } from '../../../logout/services/logout.service';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { UserLogin } from '../../types/api-types';
import { Agency } from '../../../agencies/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class IdleService {
	constructor(
		private idle: Idle,
		private agenciesDataService: AgenciesDataService,
		private configService: ConfigService,
		private logoutService: LogoutService,
		private notificationService: NotificationService,
		private translationService: TranslationService
	) {
		// Do something when the user becomes idle
		this.idle.onIdleStart.subscribe(() => {
			this.performNowIdleActions();
		});

		// Do something when the user has timed out
		this.idle.onTimeout.subscribe(() => {
			this.performIdleTimedOutActions();
		});

		// Do something when the user is no longer idle
		this.idle.onIdleEnd.subscribe(() => {
			this.performEndOfIdleTimedOutActions();
		});
	}

	/**
	 * Idle setup function.
	 *
	 * Check for this logged in user's primary agency to see if there is a configured logout time value.
	 * If there is one then use this otherwise use the default configured value - idleTime.
	 *
	 * Note that the idle timeout is not configured for the Agency so must use the default configured value - idleTimeout.
	 *
	 * @param currentUser - the currently logged-in user.
	 */
	public init = (currentUser: UserLogin): void => {
		let idleTime: number = this.configService.getIdle();
		const idleTimeout: number = this.configService.getIdleTimeout();

		if (this.agenciesDataService.agenciesLoaded()) {
			const agencySettings: Agency = this.agenciesDataService.getAgencyDetail(
				currentUser.primary_agency_authority_id,
				currentUser.primary_agency_id
			);

			idleTime = agencySettings.logout_timer ? agencySettings.logout_timer : this.configService.getIdle();
		}

		// Setup configured idle timings
		this.idle.setIdle(idleTime);
		this.idle.setTimeout(idleTimeout);
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		// Stop and start monitoring for an inactive user session
		if (this.idle.isRunning()) {
			this.idle.stop();
		}

		this.idle.watch();
	};

	/**
	 * Create the warning message regarding inactivity and time before being automatically logged out
	 */
	private performNowIdleActions = (): void => {
		const idleTime: number = Math.ceil(this.idle.getIdle() / 60);
		const idleTimeout: number = this.idle.getTimeout();
		const timeLeft: number = Math.ceil(idleTimeout / 60);

		const idleMsgRoot: string =
			this.translationService.getTranslation('T_CORE.DUE_TO_INACTIVITY_OF') +
			' ' +
			idleTime +
			' ' +
			this.translationService.getTranslation('T_CORE.MINUTES_YOU_WILL_BE_LOGGED_OUT_IN') +
			' ';

		this.notificationService.notifyWarning(
			idleMsgRoot + timeLeft + ' ' + this.translationService.getTranslation('T_CORE.MINUTES_LOWERCASE'),
			null,
			{
				timeOut: idleTimeout * 1000,
			}
		);
	};

	/**
	 * Stop idle watching and log out the user
	 */
	private performIdleTimedOutActions = (): void => {
		this.idle.stop();
		this.notificationService.clearAllNotifications();
		this.logoutService.logOut();
	};

	/**
	 * Perform any end of idle time out actions
	 */
	private performEndOfIdleTimedOutActions = (): void => {
		this.notificationService.clearAllNotifications();
	};
}

<div
	*ngIf="summaryData.route_count && summaryData.route_count === 1"
	class="nb-report-viewer-summary nb-padding-all-md report-viewer-summary-container">
	<div class="nb-report-viewer-summary-inner">
		<div
			class="report-viewer-graph-container nb-padding-left-md nb-padding-right-md nb-padding-bottom-md report-viewer-summary-graph-container">
			<div class="nb-section-title">{{ 'T_REPORT.HEADWAY_SUMMARY' | translate }}</div>
			<div
				class="report-viewer-graph-container nb-padding-left-md nb-padding-right-md nb-padding-bottom-md report-viewer-summary-graph-container">
				<div class="nb-margin-top-md headway-chart-container">
					<headway-by-stop-chart [summaryData]="summaryData"></headway-by-stop-chart>
				</div>
			</div>
		</div>
	</div>
</div>

<!--
    COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

    Information Contained Herein is Proprietary and Confidential.
    The document is the property of "CUBIC" and may not be disclosed
    distributed, or reproduced  without the express written permission of "CUBIC".
-->
<div
	class="nb-replay-control-container"
	[ngStyle]="{ left: 'calc(50% - ' + getControlPositionOffset() + 'px)' }"
	id="nb-replay-control-container">
	<div class="nb-map-replay-mode" id="nb-replay-controls" data-test="replay.controls">
		<div *ngIf="replayBuffering">
			<loading-overlay [isLoading]="true" [loadingText]="loadingText" [fit]="'content'"></loading-overlay>
		</div>
		<div class="row nb-replay-control-container-inner">
			<div class="col-xs-1 nb-replay-play-control-container">
				<a class="nb-text nb-replay-play-control" data-test="replay.play-btn" (click)="toggleReplayPlay()" [hidden]="replayPlaying">
					<i class="fa fa-play fa-2x"></i>
				</a>
				<a class="nb-text nb-replay-play-control" data-test="replay.pause-btn" (click)="toggleReplayPlay()" [hidden]="!replayPlaying">
					<i class="fa fa-pause fa-2x"></i>
				</a>
			</div>
			<div class="nb-replay-position-control-container col-xs-9">
				<div class="nb-replay-position-msg-label-container">
					<div>
						<span class="nb-text">{{ 'T_MAP.MAP_R_TIMEZONE' | translate }}</span>
						<span class="nb-text">{{ timezoneDisplay }} </span>
						<span class="nb-text" data-test="replay.speed.label">{{ infoDisplay }}</span>
					</div>
				</div>
				<div
					#replayControlBar
					class="progress nb-replay-position-control-bar"
					data-test="replay.progress.bar"
					(click)="handleReplayProgressSelect($event)"
					(mousemove)="replayProgressOnMouseHover($event)"
					(mouseleave)="replayProgressOnMouseLeave()">
					<i class="fa fa-circle nb-replay-position-marker" data-test="replay.progress" [ngStyle]="progressPositionMarkerStyle"> </i>
					<div class="progress-bar nb-replay-position-control-progress-played" role="progressbar" [ngStyle]="progressPlayedPercStyle"></div>
					<div
						class="progress-bar nb-replay-position-control-progress-left"
						role="progressbar"
						[ngStyle]="progressRemainingPercStyle"></div>
				</div>
				<div class="nb-replay-position-start-label">
					<span class="nb-text" data-test="replay.start.time">{{ startPositionLabel }}</span>
				</div>
				<div class="nb-replay-position-control-label">
					<span class="nb-text">{{ progressPositionLabel }}</span>
				</div>
				<div class="nb-replay-position-end-label">
					<span class="nb-text" data-test="replay.end.time">{{ endPositionLabel }}</span>
				</div>
			</div>
			<div class="col-xs-1 nb-replay-calendar-control-container">
				<button class="btn btn-default" data-test="replay.calendar-btn" (click)="openTimeFrameSelectionModal()">
					<i class="fa fa-calendar"></i>
				</button>
			</div>
			<div class="col-xs-1 nb-replay-speed-control-container">
				<div class="btn-group dropup">
					<button data-toggle="dropdown" class="btn btn-default dropdown-toggle" data-test="replay.speed-btn">
						<i class="fa fa-clock-o"></i>
					</button>
					<ul class="dropdown-menu pull-right">
						<li class="dropdown-header">
							<span class="nb-label">{{ 'T_MAP.MAP_SPEED' | translate }}</span>
						</li>
						<li>
							<a data-test="replay.speed-1" (click)="setPlaybackSpeed(1)">{{ 'T_MAP.MAP_NORMAL' | translate }} (1x)</a>
						</li>
						<li>
							<a data-test="replay.speed-2" (click)="setPlaybackSpeed(2)">{{ 'T_MAP.MAP_DOUBLE' | translate }} (2x)</a>
						</li>
						<li>
							<a data-test="replay.speed-5" (click)="setPlaybackSpeed(5)">5 {{ 'T_MAP.MAP_SEC_SEC' | translate }} (5x)</a>
						</li>
						<li>
							<a data-test="replay.speed-10" (click)="setPlaybackSpeed(10)">10 {{ 'T_MAP.MAP_SEC_SEC' | translate }} (10x)</a>
						</li>
						<li>
							<a data-test="replay.speed-20" (click)="setPlaybackSpeed(20)">20 {{ 'T_MAP.MAP_SEC_SEC' | translate }} (20x)</a>
						</li>
						<li>
							<a data-test="replay.speed-60" (click)="setPlaybackSpeed(60)"> 1 {{ 'T_MAP.MAP_MIN_SEC' | translate }} (60x)</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

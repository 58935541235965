/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class TwitterApiService {
	private readonly agencyTweetsUrl: string = '/agencies/twitter/mentions';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the tweets for the given authority from the twitter API
	 *
	 * @param authorityId - the current authority id
	 * @returns the tweets response
	 */
	public getTweets = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.agencyTweetsUrl, {});
		} catch (exception) {
			throw exception;
		}
	};
}

<div class="dashboard-modal-backdrop"></div>
<div class="dashboard-modal-container">
	<div class="modal-dialog inner">
		<div class="modal-content" data-test="dashboard.add-widget.model" [@modalSlideDown] *ngIf="contentVisible">
			<div class="modal-header">
				<button type="button" class="close" (click)="close()" data-test="dashboard.add-widget.close">
					<i class="ng-icon ng-close"></i>
				</button>
				<h4 class="dashboard-modal-title" data-test="dashboard.add-widget.title">{{ 'T_DASH.DB_ADD_WIDGET' | translate }}</h4>
			</div>
			<div class="modal-body add-widget-container">
				<div *ngFor="let widget of availableWidgets" class="item" (click)="selectWidget(widget)">
					<div class="thumb">
						<img id="initial" src="{{ widget.thumbUrl }}" alt="Unavailable" />
						<img id="onhover" src="{{ widget.thumbHoverUrl }}" alt="Unavailable" />
					</div>
					<div class="description">
						<h4 class="title" data-test="add-widget.widget.title">{{ getTranslation(widget.title) }}</h4>
						<div class="text" data-test="add-widget.widget.description">{{ getTranslation(widget.description) }}</div>
					</div>
				</div>
			</div>
			<div class="dashboard-modal-footer">
				<button type="button" data-test="dashboard.add-widget.cancel" (click)="close()" class="btn btn-secondary">
					{{ 'T_DASH.DB_CANCEL' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

import { ReportViewerBaseComponent } from '../report-viewer-base.component';

import { TranslationService } from '@cubicNx/libs/utils';

import { ReportTemplateSummaryContent } from '../../types/types';

export class ReportViewerListBaseComponent extends ReportViewerBaseComponent {
	public defaultTemplateId: string = null;
	public templateSummaryContent: ReportTemplateSummaryContent = null;

	constructor(translationService: TranslationService) {
		super(translationService);
	}
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { TwitterDataService } from '../services/twitter-data.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { AgencyDetail } from './../../../../../support-features/agencies/types/api-types';
import { IWidgetComponent } from '../../../types/types';

import moment, { Moment } from 'moment-timezone';

@Component({
	selector: 'twitter',
	templateUrl: './twitter.component.html',
	styleUrls: ['./twitter.component.scss'],
})
export class TwitterComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;

	// required to satisfy interface - we normally use it to display standard server error on
	// failure but in this widget we display the error message returned from twitter instead
	public success: boolean = true;

	public loaded: boolean = false;
	public hasResults: boolean = false;

	public isConfigured: boolean = false;
	public errorMessage: string = null;

	public tweets: any[] = [];

	private eventListInterval: any = null;

	constructor(
		private widgetEventsService: WidgetEventsService,
		private agenciesDataService: AgenciesDataService,
		private twitterDataService: TwitterDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the twitter component - loading translations, setting up subscriptions and initializing the widget
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations(['T_CORE.UNKNOWN_ERROR']);

		this.setSubscriptions();

		this.init();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * opens the twitter to mention view
	 *
	 * @param id - id
	 * @param screen_name - screen name
	 */
	public openTwitterToMention = (id: string, screen_name: string): void => {
		const url: string = `https://twitter.com/${screen_name}/status/${id}`;

		window.location.href = url;
	};

	/**
	 * initializes the twitter feed configuration and attempts to retrieve tweets
	 */
	private init = async (): Promise<void> => {
		this.loaded = false;

		this.isConfigured = await this.isTwitterFeedConfigured();

		if (this.isConfigured) {
			this.getData();
		} else {
			this.loaded = true;
		}
	};

	/**
	 * determines if the twitter feed is configured or not
	 *
	 * @returns whether the twitter feed is configured or not
	 */
	private isTwitterFeedConfigured = async (): Promise<boolean> => {
		const agency: any = this.data.config.selectedAgency;

		let isConfigured: boolean = false;

		const result: ResultContent = await this.agenciesDataService.getAgency(agency.authority_id, agency.agency_id);

		if (result.success) {
			this.errorMessage = null;

			const agencyDetail: AgencyDetail = result.resultData;

			if (agencyDetail.twitter_configured_flag) {
				isConfigured = true;
			}
		}

		return isConfigured;
	};

	/**
	 * attempts to retrieve latest tweets
	 */
	private getData = async (): Promise<void> => {
		if (this.eventListInterval) {
			clearInterval(this.eventListInterval);
		}

		this.getTweets();

		const refreshRate: number = (this.data.config.refreshRateSec ?? 60) * 1000;

		this.eventListInterval = setInterval(this.getTweets, refreshRate);
	};

	/**
	 * attempt to retrieve the latest tweets
	 */
	private getTweets = async (): Promise<void> => {
		const response: ResultContent = await this.twitterDataService.getTweets(this.data.config.selectedAgency.authority_id);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.hasResults = false;

		if (response.success) {
			this.tweets = response.resultData;

			this.tweets.forEach((tweet) => {
				const createdAt: Moment = moment(tweet.created_at, 'dd MMM DD HH:mm:ss ZZ YYYY', 'en');

				tweet.created_at = moment(createdAt).format('MM/DD/YYYY h:mm a');
			});

			this.hasResults = this.tweets?.length > 0;
		} else {
			if (response.resultData) {
				this.errorMessage = response.resultData;
			} else {
				this.errorMessage = this.translations['T_CORE.UNKNOWN_ERROR'];
			}
		}

		this.loaded = true;
	};

	/**
	 * sets up subscriptions - is interested in widget being reloaded
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.init();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.eventListInterval) {
			clearInterval(this.eventListInterval);
		}

		this.reloadWidget$Subscription?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { ReportTemplateEditComponent } from '../report-template-edit/report-template-edit.component';
import { ConfirmModalComponent } from '@cubicNx/libs/utils';
import { ReportTemplateSelectorComponent } from '../create-report-template-selector/report-template-selector.component';
import { AvailableReportsListFilterComponent } from '../main/list/available/filter/available-reports-list-filter.component';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { ReportsDataService } from './reports-data.service';

import { ConfirmButtonType, ConfirmResult } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	CreateEditType,
	ReportSaveType,
	ReportFiltersUpdate,
	SelectedReportsFilter,
	AvailableReportsList,
	AvailableReportsListItem,
	CreateReportType,
} from '../types/types';

import { ReportTemplateDetail, CreateReportTypeDetails, ReportTemplateDetails } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class ReportsModalService {
	constructor(
		private translationService: TranslationService,
		private reportsDataService: ReportsDataService,
		private createReportModal: MatDialog,
		private editReportModal: MatDialog,
		private editReportTemplateModal: MatDialog,
		private deleteReportsModal: MatDialog,
		private deleteReportTemplateModal: MatDialog,
		private reportTemplateSelectorModal: MatDialog,
		private openFilterModal: MatDialog
	) {}

	/**
	 * renders the select report template dialog
	 * depending on user interaction the create report or edit report dialogs are rendered
	 *
	 * @returns the type of user interaction - run, save or run and save
	 */
	public createReportFromTemplateSelection = (): Observable<ReportSaveType> => {
		return new Observable((observer) =>
			this.openReportTemplateSelector().subscribe((createReportTypeDetails: CreateReportTypeDetails) => {
				// createReportTypeDetails will be null if we cancelled the modal
				if (createReportTypeDetails) {
					switch (createReportTypeDetails.createReportType) {
						case CreateReportType.default:
							// value will be null if user cancelled the template selection
							if (createReportTypeDetails.defaultTemplateId) {
								return this.createReport(createReportTypeDetails.defaultTemplateId).subscribe(
									(reportSaveType: ReportSaveType) => {
										observer.next(reportSaveType);
									}
								);
							} else {
								observer.next(null);
							}
							break;
						case CreateReportType.saved:
							if (createReportTypeDetails.reportTemplateDetail) {
								return this.editReport(createReportTypeDetails.reportTemplateDetail).subscribe(
									(reportSaveType: ReportSaveType) => {
										observer.next(reportSaveType);
									}
								);
							} else {
								observer.next(null);
							}
							break;
						default:
							return observer.next(null);
					}
				}
			})
		);
	};

	/**
	 * renders the edit report dialog and publishes users interaction with the dialog
	 *
	 * savedReportTemplate must be a deep copy as edit modal can change inner properties causing cached
	 * data to change for report this was created from
	 *
	 * @param savedReportTemplate - the saved report template
	 * @returns the users interaction - save, run or save and run
	 */
	public editReport = (savedReportTemplate: ReportTemplateDetail): Observable<ReportSaveType> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ReportTemplateEditComponent> = this.editReportModal.open(ReportTemplateEditComponent, {
				width: '1000px',
				data: {
					createEditType: CreateEditType.edit,
					modalTitle: 'T_REPORT.REPORT_TEMPLATE_CREATOR',
					defaultTemplateId: savedReportTemplate.default_template_id,
					savedReportTemplate: ObjectHelpers.deepCopy(savedReportTemplate),
				},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe((reportSaveType: ReportSaveType) => {
				observer.next(reportSaveType);
			});
		});
	};

	/**
	 * renders the delete report template dialog
	 *
	 * if user confirms the deletion the report template is deleted
	 *
	 * @param toDeleteReportTemplate - the to be deleted report template
	 * @returns whether the user successfully deleted the report template or not
	 */
	public deleteReportTemplate = (toDeleteReportTemplate: ReportTemplateDetail): Observable<boolean> => {
		const message: string = this.translationService.getTranslation('T_REPORT.REPORT_TEMPLATE_DELETE_CONFIRM');
		const header: string = this.translationService.getTranslation('T_REPORT.REPORT_TEMPLATE_DELETE_CONFIRM_HEADING');

		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteReportTemplateModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message,
					header,
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				if (result?.confirmed) {
					let deleteResult: ResultContent = null;

					deleteResult = await this.reportsDataService.deleteReportTemplate(
						toDeleteReportTemplate.report_options.authority.authority_id,
						toDeleteReportTemplate.created_by_id,
						toDeleteReportTemplate.nb_id.toString()
					);
					observer.next(deleteResult.success);
				}
			});
		});
	};

	/**
	 * renders the delete report template(s) dialog
	 *
	 * if user confirms the deletion the report template(s) are deleted
	 *
	 * @param reportTemplatesToDelete - the to be deleted report template(s)
	 * @returns whether the user successfully deleted the report template(s) or not
	 */
	public deleteReportTemplates = (reportTemplatesToDelete: ReportTemplateDetails): Observable<boolean> => {
		const message: string =
			reportTemplatesToDelete.length > 1
				? this.translationService.getTranslation('T_REPORT.REPORT_TEMPLATE_MULTI_DELETE_CONFIRM')
				: this.translationService.getTranslation('T_REPORT.REPORT_TEMPLATE_DELETE_CONFIRM');

		const header: string = this.translationService.getTranslation('T_REPORT.REPORT_TEMPLATE_DELETE_CONFIRM_HEADING');

		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteReportTemplateModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message,
					header,
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				if (result?.confirmed) {
					let deleteResult: ResultContent = null;

					if (reportTemplatesToDelete.length > 1) {
						deleteResult = await this.reportsDataService.deleteReportTemplates(reportTemplatesToDelete);
					} else {
						const reportTemplateToDelete: ReportTemplateDetail = reportTemplatesToDelete[0];

						deleteResult = await this.reportsDataService.deleteReportTemplate(
							reportTemplateToDelete.report_options.authority.authority_id,
							reportTemplateToDelete.created_by_id,
							reportTemplateToDelete.nb_id.toString()
						);
					}

					observer.next(deleteResult.success);
				}
			});
		});
	};

	/**
	 * renders the delete available report(s) dialog
	 *
	 * if user confirms the deletion the available report(s) are deleted
	 *
	 * @param reportsToDelete - the to be deleted available report(s)
	 * @returns whether the user successfully deleted the available report(s) or not
	 */
	public deleteReports = (reportsToDelete: AvailableReportsList): Observable<boolean> => {
		const message: string =
			reportsToDelete.length > 1
				? this.translationService.getTranslation('T_REPORT.REPORT_MULTI_DELETE_CONFIRM')
				: this.translationService.getTranslation('T_REPORT.REPORT_DELETE_CONFIRM');

		const header: string = this.translationService.getTranslation('T_REPORT.REPORT_DELETE_CONFIRM_HEADING');

		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteReportsModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message,
					header,
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				if (result?.confirmed) {
					let deleteResult: ResultContent = null;

					if (reportsToDelete.length > 1) {
						deleteResult = await this.reportsDataService.deleteAvailableReports(reportsToDelete);
					} else {
						const reportToDelete: AvailableReportsListItem = reportsToDelete[0];

						deleteResult = await this.reportsDataService.deleteAvailableReport(
							reportToDelete.authorityId,
							reportToDelete.createdById,
							reportToDelete.reportId
						);
					}

					observer.next(deleteResult.success);
				}
			});
		});
	};

	/**
	 * renders the delete available report dialog
	 *
	 * if user confirms the deletion the available report is deleted
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @returns whether the user successfully deleted the available report(s) or not
	 */
	public deleteReport = (authorityId: string, createdById: number, reportId: string): Observable<boolean> => {
		const message: string = this.translationService.getTranslation('T_REPORT.REPORT_DELETE_CONFIRM');
		const header: string = this.translationService.getTranslation('T_REPORT.REPORT_DELETE_CONFIRM_HEADING');

		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteReportsModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message,
					header,
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				if (result?.confirmed) {
					const deleteResult: ResultContent = await this.reportsDataService.deleteAvailableReport(
						authorityId,
						createdById,
						reportId
					);

					observer.next(deleteResult.success);
				}
			});
		});
	};

	/**
	 * renders the edit report template dialog and publishes users interaction with the dialog
	 *
	 * @param savedReportTemplate - the saved report template
	 * @returns the users interaction - save, run or save and run
	 */
	public editReportTemplate = (savedReportTemplate: ReportTemplateDetail): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ReportTemplateEditComponent> = this.editReportTemplateModal.open(ReportTemplateEditComponent, {
				width: '1000px',
				data: {
					createEditType: CreateEditType.editTemplate,
					modalTitle: 'T_REPORT.REPORT_TEMPLATE_EDITOR',
					defaultTemplateId: savedReportTemplate.default_template_id,
					savedReportTemplate,
				},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe((reportSaveType: ReportSaveType) => {
				observer.next(reportSaveType === ReportSaveType.save || reportSaveType === ReportSaveType.saveAndRun);
			});
		});
	};

	/**
	 * renders the open available reports list filter dialog
	 *
	 * publishes the user interaction with the dialog
	 *
	 * @param selectedAgenciesFilters - the selected angencies filter details
	 * @returns publishes the filter details and if save option was selected
	 */
	public openAvailableReportsListFilter = (selectedAgenciesFilters: SelectedReportsFilter): Observable<ReportFiltersUpdate> => {
		// set the initial selected filters.  Ensure we take a copy as otherwise it will affect the original
		// from the parent and we don't want edit that object within the modal as we may cancel changes
		const selectedAgenciesFiltersCopy: SelectedReportsFilter = ObjectHelpers.deepCopy(selectedAgenciesFilters);

		return new Observable((observer) => {
			const modalRef: MatDialogRef<AvailableReportsListFilterComponent> = this.openFilterModal.open(
				AvailableReportsListFilterComponent,
				{
					width: '600px',
					data: { selectedAgenciesFilters: selectedAgenciesFiltersCopy },
					position: {
						top: '60px',
					},
				}
			);

			return modalRef.afterClosed().subscribe(async (result: ReportFiltersUpdate) => {
				observer.next(result);
			});
		});
	};

	/**
	 * renders the select report template dialog
	 *
	 * publishes the user interaction with the dialog
	 *
	 * @returns publishes the dialog details and if save option was selected
	 */
	private openReportTemplateSelector = (): Observable<CreateReportTypeDetails> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ReportTemplateSelectorComponent> = this.reportTemplateSelectorModal.open(
				ReportTemplateSelectorComponent,
				{
					minHeight: 'calc(80vh + 113px)', // min height so loading spinner takes up full space
					width: '1260px',
					data: {},
					position: {
						top: '60px',
					},
				}
			);

			return modalRef.afterClosed().subscribe((createReportTypeDetails: CreateReportTypeDetails) => {
				observer.next(createReportTypeDetails);
			});
		});
	};

	/**
	 * renders the create report dialog and publishes users interaction with the dialog
	 *
	 * @param defaultTemplateId - the default report template id
	 * @returns the users interaction - save, run or save and run
	 */
	private createReport = (defaultTemplateId: string): Observable<ReportSaveType> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ReportTemplateEditComponent> = this.createReportModal.open(ReportTemplateEditComponent, {
				width: '1000px',
				data: {
					createEditType: CreateEditType.create,
					modalTitle: 'T_REPORT.REPORT_TEMPLATE_CREATOR',
					defaultTemplateId,
				},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe((reportSaveType: ReportSaveType) => {
				observer.next(reportSaveType);
			});
		});
	};
}

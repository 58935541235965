/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'structure',
	templateUrl: './structure.component.html',
	styleUrls: ['./structure.component.scss'],
})
export class StructureComponent {
	@Input() structure: any;
	@Input() selected: boolean;
	@Output() update: EventEmitter<string> = new EventEmitter<string>();

	public active: boolean = false;

	/**
	 * Handles the mouseover event setting the active state affecting the styling of the component.
	 */
	public mouseOver = (): void => {
		this.active = true;
	};

	/**
	 * Handles the mouseout event setting the active state affecting the styling of the component.
	 */
	public mouseOut = (): void => {
		this.active = false;
	};

	/**
	 * Updates the selected structure.
	 *
	 * @param event - the click event.
	 * @param name - the name of the structure to update.
	 */
	public onClick = (event: any, name: string): void => {
		this.update.emit(name);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { TimeFormatType, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapEventsService } from '../../services/map-events.service';
import { MapStateService } from '../../services/map-state.service';
import { MapNavigationService } from '../../services/map-navigation.service';
import { MapVehiclesService } from '../../services/map-vehicles.service';
import { MapStopsService } from '../../services/map-stops.service';
import { MapOptionsService } from '../../services/map-options.service';
import { MapBaseService } from '../../services/map-base.service';
import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { ConfigService } from '@cubicNx/libs/utils';
import { AgenciesEventsService } from '../../../../support-features/agencies/services/agencies-events.service';
import { DepotsDataService } from '../../../../support-features/depots/services/depots-data.service';
import { MapPollingService } from '../../services/map-polling.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { SliderConfig } from '@cubicNx/libs/utils';
import { Depot, Depots } from '../../../../support-features/depots/types/api-types';
import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	BaseMapType,
	ColorVehicleByType,
	DisplayPriorityType,
	MapDepotSelection,
	MapDepotSelections,
	MapModeType,
	ModeType,
	SettingsDisplayType,
	allDepotTag,
	unknownDepotTag,
} from '../../types/types';

@Component({
	selector: 'map-settings',
	templateUrl: './map-settings.component.html',
	styleUrls: ['./map-settings.component.scss'],
})
export class MapSettingsComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() closeSettings: EventEmitter<void> = new EventEmitter<void>();

	public readonly vehicleLabelClusterRadiusConfig: SliderConfig = {
		padding: 0,
		step: 50,
		margin: 0,
		rangeMultiplier: 1,
		range: {
			min: 0,
			max: 500,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 100, 200, 300, 400, 500],
			density: 100,
		},
	};

	// Enums for html
	public mapModeType: typeof MapModeType = MapModeType;
	public modeType: typeof ModeType = ModeType;
	public displayPriorityType: typeof DisplayPriorityType = DisplayPriorityType;
	public colorVehicleByType: typeof ColorVehicleByType = ColorVehicleByType;
	public timeFormatType: typeof TimeFormatType = TimeFormatType;
	public baseMapType: typeof BaseMapType = BaseMapType;
	public settingsDisplayType: typeof SettingsDisplayType = SettingsDisplayType;

	public settingsDisplay: SettingsDisplayType = SettingsDisplayType.settings;
	public showRasterMapOption: boolean = false;
	public baseMapChanging: boolean = false;
	public sliderInitialized: boolean = false;
	public depotSelections: MapDepotSelections = [];

	public vehicleLabelClusterRadiusValues: Array<number> = [];

	private mapModeSettingsBaseMapChangeComplete$Subscription: Subscription = null;
	private mapStateChanged$SubscriptionS: Subscription = null;
	private agenciesSelectionChange$Subscription: Subscription = null;

	constructor(
		private configService: ConfigService,
		private depotsService: DepotsDataService,
		private agenciesDataService: AgenciesDataService,
		private mapEventsService: MapEventsService,
		private mapStateService: MapStateService,
		private mapPollingService: MapPollingService,
		private agenciesEventsService: AgenciesEventsService,
		private mapNavigationService: MapNavigationService,
		public mapStopsService: MapStopsService,
		public mapBaseService: MapBaseService,
		public mapVehiclesService: MapVehiclesService,
		public mapOptionsService: MapOptionsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.setSubscriptions();

		this.initialiseVehicleLabelClusterSlider();

		await this.loadDepots();

		this.showRasterMapOption = this.configService.getShowRasterMapOption();
	}

	/**
	 * handle the user selecting a new base map
	 * @param baseMap - the map type to set
	 */
	public baseMapChange = (baseMap: BaseMapType): void => {
		this.baseMapChanging = true;

		this.mapBaseService.setBaseMap(baseMap);
	};

	/**
	 * handle the user setting the display priority so show vehicles/stops on top
	 * @param displayPriorityType -the display priority to set
	 */
	public displayPriorityChange = (displayPriorityType: DisplayPriorityType): void => {
		this.mapOptionsService.setDisplayPriority(displayPriorityType);
	};

	/**
	 * handle the user setting for the time format to set in the detail pages
	 * @param timeFomat - the time format to set
	 */
	public setTimeFormatChange = (timeFomat: TimeFormatType): void => {
		this.mapOptionsService.setTimeFormat(timeFomat);
	};

	/**
	 * handle the user vehicle color by setting
	 * @param colorVehicleBy - the color by type to set for vehicles on the map
	 */
	public colorVehicleByChange = (colorVehicleBy: ColorVehicleByType): void => {
		this.mapVehiclesService.setColorVehicleBy(colorVehicleBy);
	};

	/**
	 * handle the user setting to show vehicle with adherence issues
	 */
	public showVehiclesWithAdherenceIssuesChange = (): void => {
		this.mapVehiclesService.setShowVehiclesWithAdherenceIssues(!this.mapVehiclesService.getShowVehiclesWithAdherenceIssues());
	};

	/**
	 * handle the user setting to show vehicle with headway issues
	 */
	public showVehiclesWithHeadwayIssuesChange = (): void => {
		this.mapVehiclesService.setShowVehiclesWithHeadwayIssues(!this.mapVehiclesService.getShowVehiclesWithHeadwayIssues());
	};

	/**
	 * handle the user setting to show hidden stops
	 */
	public showHiddenStopsChange = (): void => {
		this.mapStopsService.setShowHiddenStops(!this.mapStopsService.getShowHiddenStops());
	};

	/**
	 * handle the user setting the show stop labels on the ladder
	 */
	public showLadderStopLabelsChange = (): void => {
		this.mapOptionsService.setShowLadderStopLabels(!this.mapOptionsService.getShowLadderStopLabels());
	};

	/**
	 * handle the user setting the show stop code on the ladder
	 */
	public showLadderStopLabelCodeChange = (): void => {
		this.mapOptionsService.setShowLadderStopLabelCode(!this.mapOptionsService.getShowLadderStopLabelCode());
	};

	/**
	 * handle the user setting the show stop names on the ladder
	 */
	public showLadderStopLabelNameChange = (): void => {
		this.mapOptionsService.setShowLadderStopLabelName(!this.mapOptionsService.getShowLadderStopLabelName());
	};

	/**
	 * handle the user setting to show vehicle labels
	 */
	public showVehicleLabelsChange = (): void => {
		this.mapOptionsService.setShowVehicleLabels(!this.mapOptionsService.getShowVehicleLabels());
	};

	/**
	 * handle the user setting to show vehicle label ids
	 */
	public showVehicleLabelIdChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelId(!this.mapOptionsService.getShowVehicleLabelId());
	};

	/**
	 * handle the user settingt o show vehicle label routes
	 */
	public showVehicleLabelRouteChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelRoute(!this.mapOptionsService.getShowVehicleLabelRoute());
	};

	/**
	 * handle the user setting to show vehicle label departure adherence
	 */
	public showVehicleLabelDepartureAdherenceChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelDepartureAdherence(!this.mapOptionsService.getShowVehicleLabelDepartureAdherence());
	};

	/**
	 * handle the user setting to show vehicle headway
	 */
	public showVehicleLabelHeadwayChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelHeadway(!this.mapOptionsService.getShowVehicleLabelHeadway());
	};

	/**
	 * handle the user setting to show vehicle status
	 */
	public showVehicleLabelStatusChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelStatus(!this.mapOptionsService.getShowVehicleLabelStatus());
	};

	/**
	 * handle the user setting to show vehicle block
	 */
	public showVehicleLabelBlockChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelBlock(!this.mapOptionsService.getShowVehicleLabelBlock());
	};

	/**
	 * handle the user setting to show vehicle passengers
	 */
	public showVehicleLabelPassengersChange = (): void => {
		this.mapOptionsService.setShowVehicleLabelPassengers(!this.mapOptionsService.getShowVehicleLabelPassengers());
	};

	/**
	 * handle the user setting to set the depot filter
	 *
	 * @param target - the html element containing the depit filter tag
	 */
	public selectedDepotTagFilterChanged = (target: EventTarget): void => {
		const element: HTMLInputElement = target as HTMLInputElement;
		const tag: string = element.value;

		const depotSelection: MapDepotSelection = {
			tag,
			name: this.depotSelections.find((depot: MapDepotSelection) => depot.tag === tag).name,
		};

		this.mapVehiclesService.setSelectedDepotFilter(depotSelection);
	};

	/**
	 * handle the user setting to set the vehicle label cluster radius i.e at one radius label clustering will become active
	 *
	 * @param values - the values set in the slider control
	 */
	public setVehicleLabelClusterRadius = (values: Array<number>): void => {
		// it won't allow a cluster size of 0 - override to 1 if we set 0
		const radius: number = values[0] === 0 ? 1 : values[0];

		// check it's different - stops it firing during initialization which we dont want
		if (radius !== this.mapVehiclesService.getVehicleLabelClusterRadius()) {
			this.mapVehiclesService.setVehicleLabelClusterRadius(radius, true);
		}
	};

	/**
	 * handle the user setting to view the 'more settings' tab
	 */
	public moreSettingsDisplayToggle = (): void => {
		this.settingsDisplay = SettingsDisplayType.moreSettings;
	};

	/**
	 * handle the user setting to view the 'settings' tab
	 */
	public settingsDisplayToggle = (): void => {
		this.settingsDisplay = SettingsDisplayType.settings;
	};

	/**
	 * handle the user setting to set the setting display tab
	 *
	 * @param settingsDisplay - the setting display type
	 */
	public setSettingDisplayType = (settingsDisplay: SettingsDisplayType): void => {
		this.settingsDisplay = settingsDisplay;
	};

	/**
	 * handle the user setting to close the settings
	 */
	public handleCloseSettings = (): void => {
		this.closeSettings.emit();
	};

	/**
	 * handle the user setting to change thge agency and re-initialise the map
	 *
	 * when user changes the agency - revert to the main menu. We want to do this here and not in the subscribe of the
	 * agency change as we don't want to revert to nav menu for other scenarios where the agency changes. For example
	 * we may change the agency due a vehicle selection from the dashboard (of a different agency) where that functionality
	 * will send the user to the vehicle-details page instead
	 */
	public handleAgencyUpdated = (): void => {
		this.mapStateService.reset();

		this.agenciesEventsService.publishAgenciesSelectionChange();

		// for a restart of the polling
		this.mapPollingService.initPolling();

		this.mapNavigationService.navigateToMenu();

		this.mapOptionsService.setNavViewOpen(true);
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * initialize the vehicle clustor slider and setup the default (or cached) value
	 */
	private initialiseVehicleLabelClusterSlider = (): void => {
		this.vehicleLabelClusterRadiusValues = [this.mapVehiclesService.getVehicleLabelClusterRadius()];

		this.sliderInitialized = true;
	};

	/**
	 * set up subscriptions for the page
	 *
	 * agency selection - handle the change of the agency changing
	 *
	 * base map change complete - handle the change of the base map change complete
	 *
	 * state change - handle the sceneario where a new map state has been loaded (possibly from loading a view)
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgenciesSelectionChange();
		});

		this.mapModeSettingsBaseMapChangeComplete$Subscription = this.mapEventsService.settingsBaseMapChangeComplete.subscribe(() => {
			this.handleMapSettingsBaseMapChangeComplete();
		});

		this.mapStateChanged$SubscriptionS = this.mapEventsService.stateChanged.subscribe(() => {
			this.handleStateChangedComplete();
		});
	};

	/**
	 * reload the depots when the agency changes
	 */
	private handleAgenciesSelectionChange = async (): Promise<void> => {
		await this.loadDepots();
	};

	/**
	 * set the display flag when the base map has completed (the display flag blocks user interaction while the map is changing)
	 */
	private handleMapSettingsBaseMapChangeComplete = (): void => {
		this.baseMapChanging = false;
	};

	/**
	 * handle the slider following the map state changing
	 *
	 * hack to force the update of our slider for when we change the state with potentially an updated
	 * slider value (i.e when applying a view) toggling sliderInitialized to false removes it from thge
	 * page and forces it to reinitialize with the updated value
	 *
	 * trigger a refresh of the vehicle label layer with the updated cluster radius - set false as we don't want the labels
	 * to be reloaded. When applying state change (after applying a view) vehicles/labels will be out of date so they are
	 * reloaded from the backend
	 */
	private handleStateChangedComplete = (): void => {
		this.sliderInitialized = false;

		setTimeout(() => {
			this.vehicleLabelClusterRadiusValues = [this.mapVehiclesService.getVehicleLabelClusterRadius()];
			this.sliderInitialized = true;

			this.mapVehiclesService.setVehicleLabelClusterRadius(this.vehicleLabelClusterRadiusValues[0], false);
		}, 0);
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_MAP.MAP_ALL', 'T_MAP.MAP_UNKNOWN']);
	};

	/**
	 * load the depots for the agency
	 */
	private loadDepots = async (): Promise<void> => {
		this.depotSelections = [];

		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		const result: ResultContent = await this.depotsService.getDepots(selectedAgency.authority_id, selectedAgency.agency_id);

		if (result.success) {
			const depots: Depots = result.resultData;

			const depotSelections: MapDepotSelections = depots.map((depot: Depot) => ({
				name: depot.name,
				tag: depot.tag,
			}));

			const allDepot: any = {
				name: this.translations['T_MAP.MAP_ALL'],
				tag: allDepotTag,
			};

			const unknownDepot: any = {
				name: this.translations['T_MAP.MAP_UNKNOWN'],
				tag: unknownDepotTag,
			};

			// add our 2 custom values
			this.depotSelections.push(allDepot, unknownDepot);

			// add the remaining selections after
			this.depotSelections = this.depotSelections.concat(depotSelections);
		}
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
		this.mapModeSettingsBaseMapChangeComplete$Subscription?.unsubscribe();
		this.mapStateChanged$SubscriptionS?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { ResultContent } from '../../../types/types';
import { ImageApiService } from './image-api.service';
import { GetImageResponse } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class ImageDataService {
	constructor(
		private logger: LoggerService,
		private imageApiService: ImageApiService
	) {}

	/**
	 * handles the requests of the image from the nextbus API
	 *
	 * @param imageId - the id of the inmage to request
	 * @returns the result of the request
	 */
	public getImage = async (imageId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.imageApiService.getImage(imageId);

			result.success = true;
			result.resultData = response as GetImageResponse;
		} catch (exception) {
			this.logger.logError('Failed to get image - id: ' + imageId, exception);
		}

		return result;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { CurrentUserUtilService } from '../../../../../support-features/login/services/current-user/current-user-utils.service';
import { StorageService } from '@cubicNx/libs/utils';

import { StorageType } from '@cubicNx/libs/utils';
import { TrafficWidgetSettings } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class TrafficService {
	constructor(
		private storageService: StorageService,
		private currentUserUtilService: CurrentUserUtilService
	) {}

	/**
	 * retrieves the map settings for the traffic widget
	 *
	 * @param widgetId - widget id
	 * @returns mapp settings for the traffic widget
	 */
	public getTrafficWidgetMapSettings = (widgetId: string): TrafficWidgetSettings => {
		let mapConfig: TrafficWidgetSettings;
		const key: string = this.getTrafficWidgetMapKey(widgetId);

		if (key) {
			mapConfig = this.storageService.get(key, StorageType.session);
		}

		return mapConfig;
	};

	/**
	 * saves the traffic widget settings
	 *
	 * @param widgetId - widget id
	 * @param config - traffic widget configuration
	 */
	public saveTrafficWidgetMapSettings = (widgetId: string, config: TrafficWidgetSettings): void => {
		const key: string = this.getTrafficWidgetMapKey(widgetId);

		if (key) {
			this.storageService.set(key, config, StorageType.session);
		}
	};

	/**
	 * clears the traffic widget settings
	 *
	 * @param widgetId - widget id
	 */
	public clearTrafficWidgetMapSettings = (widgetId: string): void => {
		const key: string = this.getTrafficWidgetMapKey(widgetId);

		if (key) {
			this.storageService.remove(key, StorageType.session);
		}
	};

	/**
	 * retrieves the map key for the traffic widget
	 *
	 * @param widgetId - widget id
	 * @returns map key for the traffic widget
	 */
	private getTrafficWidgetMapKey = (widgetId: string): string => {
		// widgetId is not guaranteed to be unique, but it is based on
		// epoch time and cid increments and should be sufficient.
		// Session storage is not cleared during a logout/login so adding current username
		// for added key uniqueness across users using the same browser/session
		let key: string = null;

		if (widgetId) {
			const array: string[] = ['dashboard_traffic_map', widgetId, this.currentUserUtilService.getCurrentUser().username];

			key = array.join('_');
		}

		return key;
	};
}

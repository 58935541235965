<div class="nb-report-viewer-summary nb-padding-all-md report-viewer-summary-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-graph-container nb-padding-left-md nb-padding-right-md nb-padding-bottom-md">
			<div class="nb-section-title">{{ 'T_REPORT.SUMMARY' | translate }}</div>
			<div class="donut-chart-container">
				<adherence-donut-chart [summaryTotals]="summaryData.totals"></adherence-donut-chart>
			</div>
			<div class="schedule-performance-chart-horizontal">
				<div class="schedule-performance-chart-horizontal-item very-early-border">
					<div class="nb-heading">{{ summaryData.totals.very_early_percent }}%</div>
					<div class="nb-text">{{ summaryData.totals.very_early_total }}</div>
					<div class="nb-text report-viewer-graph-border-label">{{ 'T_REPORT.VERY_EARLY' | translate }}</div>
				</div>
				<div class="schedule-performance-chart-horizontal-item early-border">
					<div class="nb-heading">{{ summaryData.totals.early_percent }}%</div>
					<div class="nb-text">{{ summaryData.totals.early_total }}</div>
					<div class="nb-text">{{ 'T_REPORT.EARLY' | translate }}</div>
				</div>
				<div class="schedule-performance-chart-horizontal-item on-time-border">
					<div class="nb-heading">{{ summaryData.totals.on_time_percent }}%</div>
					<div class="nb-text">{{ summaryData.totals.on_time_total }}</div>
					<div class="nb-text report-viewer-graph-border-label">{{ 'T_REPORT.ON_TIME' | translate }}</div>
				</div>
				<div class="schedule-performance-chart-horizontal-item late-border">
					<div class="nb-heading">{{ summaryData.totals.late_percent }}%</div>
					<div class="nb-text">{{ summaryData.totals.late_total }}</div>
					<div class="nb-text">{{ 'T_REPORT.LATE' | translate }}</div>
				</div>
				<div class="schedule-performance-chart-horizontal-item very-late-border">
					<div class="nb-heading">{{ summaryData.totals.very_late_percent }}%</div>
					<div class="nb-text">{{ summaryData.totals.very_late_total }}</div>
					<div class="nb-text report-viewer-graph-border-label">{{ 'T_REPORT.VERY_LATE' | translate }}</div>
				</div>
			</div>
		</div>
		<div class="report-viewer-grid-container">
			<div class="report-viewer-grid navigable-report nb-padding-bottom-md report-viewer-grid-inner-container">
				<div class="nb-section-title">{{ 'T_REPORT.BEST_PERFORMING' | translate }}</div>
				<table data-test="report.route-list.best">
					<thead>
						<tr>
							<th>{{ 'T_REPORT.ROUTE' | translate }}</th>
							<th>{{ 'T_REPORT.VEHICLE' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.VERY_EARLY' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.EARLY' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.ON_TIME' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.LATE' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.VERY_LATE' | translate }}</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr *ngFor="let best of summaryData.best" (click)="generateVehicleReport(best)">
							<td class="reporttooltip">
								<div class="truncate">
									<span class="tooltiptext">{{ best.route_long_name }}</span>
									<route-pill class="report-viewer-grid-route-pill" [route]="determineRoutePillData(best)"></route-pill>
									<div class="truncate report-viewer-grid-id-name">{{ best.route_long_name }}</div>
								</div>
							</td>
							<td>
								<span>{{ best.vehicle_id }}</span>
							</td>
							<td title="{{ best.very_early.percent }}% ({{ best.very_early.count }})">
								{{ best.very_early.percent }}% ({{ best.very_early.count }})
							</td>
							<td title="{{ best.early.percent }}% ({{ best.early.count }})">{{ best.early.percent }}% ({{ best.early.count }})</td>
							<td title="{{ best.on_time.percent }}% ({{ best.on_time.count }})">{{ best.on_time.percent }}% ({{ best.on_time.count }})</td>
							<td title="{{ best.late.percent }}% ({{ best.late.count }})">{{ best.late.percent }}% ({{ best.late.count }})</td>
							<td title="{{ best.very_late.percent }}% ({{ best.very_late.count }})">
								{{ best.very_late.percent }}% ({{ best.very_late.count }})
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="report-viewer-grid navigable-report nb-padding-bottom-md report-viewer-grid-inner-container">
				<div class="nb-section-title">{{ 'T_REPORT.WORST_PERFORMING' | translate }}</div>
				<table data-test="report.route-list.worst">
					<thead>
						<tr>
							<th>{{ 'T_REPORT.ROUTE' | translate }}</th>
							<th>{{ 'T_REPORT.VEHICLE' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.VERY_EARLY' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.EARLY' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.ON_TIME' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.LATE' | translate }}</th>
							<th class="mw-col">{{ 'T_REPORT.VERY_LATE' | translate }}</th>
						</tr>
					</thead>
					<tbody class="zebra">
						<tr *ngFor="let worst of summaryData.worst" (click)="generateVehicleReport(worst)">
							<td class="reporttooltip">
								<div class="truncate">
									<span class="tooltiptext">{{ worst.route_long_name }}</span>
									<route-pill class="report-viewer-grid-route-pill" [route]="determineRoutePillData(worst)"></route-pill>
									<div class="truncate report-viewer-grid-id-name">{{ worst.route_long_name }}</div>
								</div>
							</td>
							<td>
								<span>{{ worst.vehicle_id }}</span>
							</td>
							<td title="{{ worst.very_early.percent }}% ({{ worst.very_early.count }})">
								{{ worst.very_early.percent }}% ({{ worst.very_early.count }})
							</td>
							<td title="{{ worst.early.percent }}% ({{ worst.early.count }})">{{ worst.early.percent }}% ({{ worst.early.count }})</td>
							<td title="{{ worst.on_time.percent }}% ({{ worst.on_time.count }})">
								{{ worst.on_time.percent }}% ({{ worst.on_time.count }})
							</td>
							<td title="{{ worst.late.percent }}% ({{ worst.late.count }})">{{ worst.late.percent }}% ({{ worst.late.count }})</td>
							<td title="{{ worst.very_late.percent }}% ({{ worst.very_late.count }})">
								{{ worst.very_late.percent }}% ({{ worst.very_late.count }})
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

export class AsyncTimeout {
	private id: any;

	/**
	 * starts an async timeout
	 *
	 * @param ms - the number in ms to wait before firing the timeout
	 * @returns a promise which resolves when the timer has fired
	 */
	public start = (ms: number): Promise<any> => {
		return new Promise((resolve) => {
			if (this.id === -1) {
				throw new Error('Timer already aborted');
			}

			this.id = setTimeout(resolve, ms);
		});
	};

	/**
	 * cancels the async timeout
	 */
	public cancel = (): void => {
		if (this.id !== -1 || this.id === undefined) {
			clearTimeout(this.id);
			this.id = -1;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { StopsApiService } from './stops-api.service';
import { MapOptionsService } from '../../../features/map/services/map-options.service';
import { MapReplayService } from '../../../features/map/services/map-replay.service';

import { StopInfo, StopLastPassingRoutes, StopPredictionsMap, StopWithRoutes, StopsPaginatedResponse } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';
import { ReplayDataParams } from '../../../features/map/replay/types/api-types';
import { MapModeType } from '../../../features/map/types/types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class StopsDataService {
	constructor(
		private logger: LoggerService,
		private notificationService: NotificationService,
		private translationService: TranslationService,
		private stopsApiService: StopsApiService,
		private mapOptionsService: MapOptionsService,
		private mapReplayService: MapReplayService
	) {}

	/**
	 * handles the request for the stops list from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param pageNumber - the page number to request
	 * @param pageSize - the page size to request
	 * @param sortBy - the sort by field to request
	 * @param sortDir - the sort direction to request
	 * @param search - the search filter text to request
	 * @returns the result object with the stops list
	 */
	public getStopsList = async (
		authorityId: string,
		agencyId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: PaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(search && { search }),
				...(sortDir && { sortDir }),
			};

			const response: any = await this.stopsApiService.getStops(authorityId, agencyId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as StopsPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: StopsPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as StopsPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get stops list.', error);
		}

		return result;
	};

	/**
	 * handles the request of the stops suggestions from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param search - the search filter text
	 * @returns the result object with all stops that pass the filter
	 */
	public getStopsSuggestions = async (authorityId: string, agencyId: string, search: string): Promise<ResultContent> => {
		return await this.getStopsList(authorityId, agencyId, 1, 50, 'stop_code', 'asc', search);
	};

	/**
	 * handles the request of a single stop from the nextbus API using the stop id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @returns the result object with the single stop
	 */
	public getStop = async (authorityId: string, agencyId: string, stopId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.stopsApiService.getStop(authorityId, agencyId, stopId);

			result.success = true;

			result.resultData = response as StopWithRoutes;
		} catch (error) {
			this.logger.logError('Failed to get stop: ' + stopId, error);
		}

		return result;
	};

	/**
	 * handles the request of a single stop from the nextbus API using the stop code
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopCode - the code of the stop for the request
	 * @returns the result object with the single stop
	 */
	public getStopByCode = async (authorityId: string, agencyId: string, stopCode: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.stopsApiService.getStopByCode(authorityId, agencyId, stopCode);

			result.success = true;

			result.resultData = response as StopWithRoutes;
		} catch (error) {
			this.logger.logError('Failed to get stop: ' + stopCode, error);

			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * handles the request request of stop predictions info for a single stop from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @param stopCode - the code of the stop for the request
	 * @returns the result object with the stop predictions
	 */
	public getStopPredictions = async (authorityId: string, agencyId: string, stopId: string, stopCode: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			let replayParams: ReplayDataParams = null;

			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				replayParams = {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
				};
			}

			const response: StopPredictionsMap = await this.stopsApiService.getStopPredictions(
				authorityId,
				agencyId,
				stopId,
				stopCode,
				replayParams
			);

			result.success = true;
			result.resultData = response;
		} catch (error) {
			this.logger.logError('Failed to get stop predictions.', error);
		}

		return result;
	};

	/**
	 * handles the request for stop last passing info for a single stop from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @returns the result object with the stop last passing info
	 */
	public getStopLastPassing = async (authorityId: string, agencyId: string, stopId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: StopLastPassingRoutes = await this.stopsApiService.getStopLastPassing(authorityId, agencyId, stopId);

			result.success = true;
			result.resultData = response;
		} catch (error) {
			this.logger.logError('Failed to get stop last passing details', error);
		}

		return result;
	};

	/**
	 * requests additional single info from the nextbus API using the stop code
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopCode - the code of the stop for the request
	 * @param stopId - the id of the stop for the request
	 * @returns the result object with the stop info
	 */
	public getStopInfoByCode = async (authorityId: string, agencyId: string, stopCode: string, stopId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			let replayParams: ReplayDataParams = null;

			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				replayParams = {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
					stopId,
				};
			}

			const response: any = await this.stopsApiService.getStopInfoByCode(authorityId, agencyId, stopCode, replayParams);

			result.success = true;
			result.resultData = response as StopInfo;
		} catch (error) {
			this.logger.logError('Failed to get stop info by stop code', error);
		}

		return result;
	};

	/**
	 * requests additional single info from the nextbus API using the stop id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param stopId - the id of the stop for the request
	 * @returns the result object with the stop info
	 */
	public getStopInfoById = async (authorityId: string, agencyId: string, stopId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			let replayParams: ReplayDataParams = null;

			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				replayParams = {
					replayId: this.mapReplayService.getCurrentReplayId(),
					timestamp: this.mapReplayService.getCurrentReplayTime(),
					stopId,
				};
			}

			const response: any = await this.stopsApiService.getStopInfoById(authorityId, agencyId, stopId, replayParams);

			result.success = true;
			result.resultData = response as StopInfo;
		} catch (error) {
			this.logger.logError('Failed to get stop info by stop id', error);
		}

		return result;
	};
}

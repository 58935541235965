import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';
import { EventEmitter, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface DashboardSelectedAgency {
	id: number;
	agency_id: string;
	agency_nb_id: number;
	authority_id: string;
	agency_name: string;
}

export interface IStructure {
	name: string;
	rows: IStructureRow[];
}

export interface IStructureRow {
	styleClass: string;
	columns: any[];
}

export interface IStructureColumn {
	styleClass: string;
	class: string;
}

export interface IWidget {
	name: string;
	order: number;
	title: string;
	thumbUrl: string;
	thumbHoverUrl: string;
	description: string;
	controller?: string;
	controllerAs?: string;
	templateUrl?: string;
	reload?: boolean;
	edit?: IWidgetEdit;
	config: any;
	component?: Type<any>;
	editComponent?: Type<any>;
	data?: any;
}

export interface IWidgetEdit {
	template?: string;
	templateUrl?: string;
	controller?: string;
	controllerAs?: string;
	immediate: boolean;
}

export interface IWidgetEditModalComponent {
	widget: any;
	formValidChanged: EventEmitter<any>;
	translations: any;
	reactiveForm: FormGroup;
	agencies: any[];
}

export interface IWidgetComponent {
	data: any;
	rowData: any;
	reloadWidget$Subscription: any;
	loaded: boolean;
	hasResults: boolean;
	success: boolean;
}

export const VehicleSelectionAll: string = 'all';
export const VehicleSelectionDepots: string = 'depots';
export const VehicleSelectionVehicles: string = 'vehicles';

export const modalSlideAnimationTime: number = 400;

export const modalSlideDownAnimation: AnimationTriggerMetadata[] = [
	trigger('modalSlideDown', [
		transition('void => *', [style({ transform: 'translateY(-100%)', opacity: 0 }), animate(modalSlideAnimationTime)]),
		transition('* => void', [animate(modalSlideAnimationTime, style({ transform: 'translateY(-100%)', opacity: 0 }))]),
		transition('void => open', [style({ transform: 'translateY(-100%)', opacity: 0 }), animate(modalSlideAnimationTime)]),
		transition('open => close', [animate(modalSlideAnimationTime, style({ transform: 'translateY(-100%)', opacity: 0 }))]),
	]),
];

export const modalSlideUpAnimation: AnimationTriggerMetadata[] = [
	trigger('modalSlideUp', [
		transition('void => *', [style({ transform: 'translateY(100%)', opacity: 0 }), animate(modalSlideAnimationTime)]),
		transition('* => void', [animate(200, style({ transform: 'translateY(100%)', opacity: 0 }))]),
		transition('void => open', [style({ transform: 'translateY(100%)', opacity: 0 }), animate(modalSlideAnimationTime)]),
		transition('open => close', [animate(200, style({ transform: 'translateY(100%)', opacity: 0 }))]),
	]),
];

export interface ListSizeOption {
	option: string;
	value: string;
}

export type ListSizeOptions = Array<ListSizeOption>;

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { TranslationService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { IWidgetEditModalComponent } from '../../../types/types';

import L from 'leaflet';

@Component({
	selector: 'traffic-edit',
	templateUrl: './traffic-edit.component.html',
	styleUrls: ['./traffic-edit.component.scss'],
})
export class TrafficEditComponent extends WidgetEditBaseComponent implements IWidgetEditModalComponent, OnInit, OnDestroy {
	constructor(
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * performs initialization tasks for the traffic edit component
	 */
	public async ngOnInit(): Promise<void> {
		this.buildForm(this.addFormControls, {});

		await this.setupAgencies(this.agencyChanged);

		this.setSubscriptions();

		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public agencyChanged = async (): Promise<void> => {
		await this.handleAgencyChanged(this.setAgencyBoundary, () => {});
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param timeFrame - the data to check.
	 * @returns a class string.
	 */
	public timeFrameButtonClass = (timeFrame: number): string => {
		const current: number = this.reactiveForm.get('selectedTimeFrame').value;

		return current === timeFrame ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget selectedTimeFrame data.
	 *
	 * @param timeFrame - the timeFrame to set.
	 */
	public setTimeFrame = (timeFrame: number): void => {
		this.widget.config.trafficRefreshTime = timeFrame;
		this.reactiveForm.get('selectedTimeFrame').setValue(timeFrame);
	};

	/**
	 * Set agency boundary based on currently selected agency
	 */
	private setAgencyBoundary = async (): Promise<void> => {
		this.widget.config.bounds = null;

		const result: ResultContent = await this.agenciesService.getAgencyBoundary(
			this.widget.config.selectedAgency.authority_id,
			this.widget.config.selectedAgency.agency_id
		);

		if (result.success) {
			const boundary: any = result.resultData;

			const { maxlat, maxlon, minlat, minlon } = boundary[0];

			const latlonvals: L.LatLng[] = [];

			latlonvals[0] = new L.LatLng(parseFloat(maxlat), parseFloat(maxlon));
			latlonvals[1] = new L.LatLng(parseFloat(minlat), parseFloat(minlon));

			this.widget.config.bounds = latlonvals;
		}
	};

	/**
	 * Setup and populate form fields.
	 */
	private addFormControls = (): void => {
		this.reactiveForm.addControl('selectedTimeFrame', new FormControl('', [Validators.required]));
		this.reactiveForm.get('selectedTimeFrame').setValue(this.widget.config?.trafficRefreshTime);
	};
}

import { Injectable } from '@angular/core';

import * as uuid from 'uuid';

import { UserCacheService } from '../user-cache/user-cache.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { CurrentUserDataService } from './current-user-data.service';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { SelectedAgencies, SelectedAgency } from '../../../agencies/types/api-types';
import { VehicleEventsUserSettings } from '../../../../features/vehicle-events/types/types';
import { UserSettings, UserLogin, DashboardSetting } from '../../types/api-types';
import { PasswordExpiry } from '../../types/types';

@Injectable({
	providedIn: 'root',
})
export class CurrentUserUtilService {
	private currentUser: UserLogin = null;

	private userSettings: UserSettings = null;

	constructor(
		private userCacheService: UserCacheService,
		private translationService: TranslationService,
		private currentUserDataService: CurrentUserDataService
	) {}

	/**
	 * returns the users primary agency id. Note: the users nb_primary_agency_id
	 * returned maps to the agenc 'nb_id' rather than the 'agency_nb_id'.
	 * Typically they hold the same value but not for SF Muni CIS (potentially others).
	 *
	 * @returns the users primary agency id
	 */
	public getCurrentUserPrimaryAgencyId = (): number => {
		if (!this.currentUser) {
			this.getCurrentUser();
		}

		if (!this.currentUser) {
			return null;
		}

		return this.currentUser.nb_primary_agency_id;
	};

	/**
	 * sets the current user and stores in the cache
	 *
	 * @param currentUser - the current user
	 */
	public setCurrentUser = (currentUser: UserLogin): void => {
		this.currentUser = currentUser;
		this.userCacheService.setCurrentUser(currentUser);
	};

	/**
	 * gets the current user
	 *
	 * @returns the current user
	 */
	public getCurrentUser = (): UserLogin => {
		return this.currentUser;
	};

	/**
	 * loads the current user from the cache
	 */
	public loadCachedCurrentUser = (): void => {
		this.currentUser = this.userCacheService.getCurrentUser();
	};

	/**
	 * clears the current user
	 */
	public clearCurrentUser = (): void => {
		this.userSettings = null;
		this.currentUser = null;
		this.userCacheService.clearCurrentUser();
	};

	/**
	 * set the selected agency for the user
	 *
	 * @param selectedAgency - the current agency
	 */
	public setUserSelectedAgency = (selectedAgency: SelectedAgency): void => {
		this.userSettings.selectedAgencies = [selectedAgency];
		this.setUserSettings();
	};

	/**
	 * get the selected agency for the user
	 *
	 * having a list of selected agencies is a legacy concept - just return the first
	 *
	 * @returns the agency for the current user
	 */
	public getSelectedAgency(): SelectedAgency {
		if (this.userSettings.selectedAgencies) {
			const agencies: SelectedAgencies = this.userSettings.selectedAgencies.filter((agency: SelectedAgency) => !!agency.agency_nb_id);

			return agencies[0];
		}

		return null;
	}

	/**
	 * saves the dashboard layout settings in to the user settings
	 *
	 * @param dashboardSettings - the dashboard layout settings
	 * @param selectedDashboardId - the dashboard id
	 */
	public saveDashboardSettings = (dashboardSettings: any[], selectedDashboardId: string): void => {
		// make a deep copy to avoid issue in create mode where widgets are added but then the process cancelled
		// but the widgets still appear
		this.userSettings.dashboardSettings = ObjectHelpers.deepCopy(dashboardSettings);
		this.userSettings.selectedDashboardId = selectedDashboardId;
		this.setUserSettings();
	};

	/**
	 * gets the current dashboard id
	 *
	 * @returns  the current dashboard id
	 */
	public getSelectedDashboardId = (): string => {
		return this.userSettings?.selectedDashboardId;
	};

	/**
	 * gets the current dashboard settings
	 *
	 * @returns  the current dashboard settings
	 */
	public getDashboardSettings = (): DashboardSetting[] => {
		return this.userSettings?.dashboardSettings;
	};

	/**
	 * saves the vehicle event settings in to the user settings
	 *
	 * @param vehicleEventSettings - the vehicle event settings
	 */
	public saveVehicleEventSettings = (vehicleEventSettings: VehicleEventsUserSettings): void => {
		if (this.userSettings !== null) {
			this.userSettings.eventNotificationSettings = vehicleEventSettings;
			this.setUserSettings();
		}
	};

	/**
	 * gets the vehicle event settings from the user settings
	 *
	 * @returns the vehicle event settings
	 */
	public getEventNotificationSettings = (): VehicleEventsUserSettings => {
		return this.userSettings.eventNotificationSettings;
	};

	/**
	 * stores the toggling of the nav bar between full and mini and save
	 */
	public toggleMiniNavBar = (): void => {
		this.userSettings.navMinified = !this.userSettings.navMinified;

		this.setUserSettings();
	};

	/**
	 * determines if the user is set to mini nav bar view
	 *
	 * @returns true if the user is set to the mini nav bar view
	 */
	public hasMiniNavBar = (): boolean => {
		if (!this.userSettings) {
			return false;
		}

		return this.userSettings.navMinified;
	};

	/**
	 * determines if the users password is expiring
	 * @returns true if the users password is expiring
	 */
	public determinePasswordExpiring = (): PasswordExpiry => {
		const passwordExpiry: PasswordExpiry = {
			passwordExpiring: false,
		};

		const numOfDaysBeforeWarning: number = this.getDaysBeforePasswordExpiry();
		const passwordExpiryDays: number = this.getPasswordExpiryDaysPending();

		if (passwordExpiryDays <= numOfDaysBeforeWarning) {
			passwordExpiry.passwordExpiring = true;
			passwordExpiry.passwordExpiryDays = passwordExpiryDays.toFixed(0);
		}

		return passwordExpiry;
	};

	/**
	 * initializes the user settings from the login response
	 * @param user - the login response
	 */
	public initUserSettings = (user: UserLogin): void => {
		const userSettings: any = user.agency_portal_settings;

		if (!userSettings) {
			this.userSettings = this.getDefaultUserSettings();
		} else {
			this.userSettings = userSettings;
		}

		this.setCurrentUser(user);
	};

	/**
	 * create the default dashboard layout settings to store in our user settings
	 * @param selectedDashboardId - the dashboard id
	 * @returns the default dashboard settings
	 */
	public createUserDefaultDashboardSettings(selectedDashboardId: string): any {
		return [
			{
				id: selectedDashboardId,
				config: {
					title: this.translationService.getTranslation('T_CORE.DEFAULT_DASHBOARD'),
					order: 1,
					structure: '6-6',
					rows: [
						{
							columns: [
								{
									cid: uuid.v4(),
									styleClass: 'col-md-6',
									widgets: [],
								},
								{
									cid: uuid.v4(),
									styleClass: 'col-md-6',
									widgets: [],
								},
							],
						},
					],
				},
				order: 0,
			},
		];
	}

	/**
	 * determines the number of days before warning the user about password expiry
	 *
	 * @returns the number of days before warning the user about password expiry
	 */
	private getDaysBeforePasswordExpiry(): number {
		return this.getCurrentUser().profile.warn_password_expiry_days;
	}

	/**
	 * determines the number of details until the password expires
	 *
	 * @returns the number of details until the password expires
	 */
	private getPasswordExpiryDaysPending(): number {
		const curUser: UserLogin = this.getCurrentUser();

		const daysToMsec: number = 24 * 60 * 60 * 1000;
		const autoPasswordExpiryMSec: number = curUser.profile.auto_password_expiry_days * daysToMsec;
		const passLastChangeMsec: number = new Date(curUser.profile.password_last_changed_date).getTime();

		const warnExpiryDate: number = new Date(passLastChangeMsec + autoPasswordExpiryMSec).getTime();
		const currentTimeStamp: number = new Date().getTime();

		const daysToExpiry: number = (warnExpiryDate - currentTimeStamp) / daysToMsec;

		return daysToExpiry;
	}

	/**
	 * determines default user settings (when setting up a new user)
	 *
	 * @returns the default user settings
	 */
	private getDefaultUserSettings(): any {
		const selectedDashboardId: string = uuid.v4();

		return {
			selectedAgencies: null,
			dashboardSettings: this.createUserDefaultDashboardSettings(selectedDashboardId),
			selectedDashboardId,
		};
	}

	/**
	 * update the user settings
	 */
	private setUserSettings = (): void => {
		if (this.currentUser !== null) {
			this.currentUser.agency_portal_settings = this.userSettings;
			this.setCurrentUser(this.currentUser);

			this.currentUserDataService.setUserSettings(this.currentUser.nb_id, this.userSettings);
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { ReportViewerBaseComponent } from '../../../../report-viewer-base.component';

import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { RoutePillData } from '@cubicNx/libs/utils';

import {
	InteractiveReportConfigurationScheduleForRoutesDetail,
	InteractiveReportConfigurationScheduleForRoutesDetails,
} from '../../../../../types/api-types';

@Component({
	selector: 'c200-configuration-schedule-for-route-list',
	templateUrl: './configuration-schedule-for-route-list.component.html',
	styleUrls: ['./configuration-schedule-for-route-list.component.scss'],
})
export class ConfigurationScheduleForRouteListComponent extends ReportViewerBaseComponent implements OnInit {
	public listData: InteractiveReportConfigurationScheduleForRoutesDetails = null;

	public listDataAvailable: boolean = false;

	constructor(
		private reportsEventsService: ReportsEventsService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the configured schedule for route list view (C200)
	 */
	public ngOnInit(): void {
		// Child view informing parent that list needs loading for the first time
		// Becuase this report has NO sort column functionality, there are no sort details to pass through
		this.reportsEventsService.publishLoadReportList();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (configured schedules for routes data)
	 */
	public updateListView = (listData: InteractiveReportConfigurationScheduleForRoutesDetails): void => {
		this.listData = listData;
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param reportItem - the report item
	 * @returns route pill
	 */
	public determineRoutePillData = (reportItem: InteractiveReportConfigurationScheduleForRoutesDetail): RoutePillData => {
		return {
			routeShortName: reportItem.route_short_name,
			routeLongName: reportItem.route_long_name,
			routeId: reportItem.route_id,
			routeColor: this.colorUtilityService.getColor(reportItem.route_color),
			routeTextColor: this.colorUtilityService.getColor(reportItem.route_text_color),
		};
	};
}

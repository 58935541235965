/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { DisplayBlock, VehicleBlockStyle, BlockSection } from '../types/types';
import { TripDetails, VehicleSummary } from '../../../../../support-features/routes/types/api-types';
import { RoutePillData } from '@cubicNx/libs/utils';
import { BlockStatus } from '../../../../../support-features/blocks/types/types';
import { VehicleDetailsActiveTab } from '../../../../map/types/types';

import moment from 'moment';

@Component({
	selector: 'current-section',
	templateUrl: './current-section.component.html',
	styleUrls: ['./current-section.component.scss'],
})
export class CurrentSectionComponent extends TranslateBaseComponent implements OnInit {
	@Input() section: BlockSection;
	@Input() timezone: string;

	@Output() closeSection: EventEmitter<void> = new EventEmitter<void>();
	@Output() showBlockViewEvent: EventEmitter<string> = new EventEmitter<string>();

	public route: RoutePillData = null;

	private readonly defaultTimeFormat: string = 'HH:mm';
	private readonly months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

	private statusMapTranslations: Map<BlockStatus, string> = new Map<BlockStatus, string>();

	private statusMap: Map<BlockStatus, string> = new Map<BlockStatus, string>([
		[BlockStatus.assigned, 'block-assigned'],
		[BlockStatus.canceled, 'block-canceled'],
		[BlockStatus.unassigned, 'block-unassigned'],
	]);

	constructor(
		private mapNavigationService: MapNavigationService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the current blocks section component - loading translations
	 */
	public ngOnInit(): void {
		this.initTranslations([
			'T_MAP.MAP_BLOCKS_TITLE',
			'T_MAP.MAP_DATE',
			'T_MAP.MAP_TIME',
			'T_MAP.MAP_STATUS',
			'T_MAP.MAP_VEHICLES',
			'T_MAP.BLOCK_ASSIGNED',
			'T_MAP.BLOCK_CANCELED',
			'T_MAP.BLOCK_UNASSIGNED',
		]).then(() => {
			this.statusMapTranslations = new Map<BlockStatus, string>([
				[BlockStatus.assigned, this.translations['T_MAP.BLOCK_ASSIGNED']],
				[BlockStatus.canceled, this.translations['T_MAP.BLOCK_CANCELED']],
				[BlockStatus.unassigned, this.translations['T_MAP.BLOCK_UNASSIGNED']],
			]);
		});

		this.route = this.determineRoutePillData();
	}

	/**
	 * Closes the section.
	 */
	public closeSectionClick = (): void => {
		this.closeSection.emit();
	};

	/**
	 * Fires a showBlockViewEvent emitting the block id.
	 *
	 * @param displayBlock - the block to show.
	 */
	public showBlockView = (displayBlock: DisplayBlock): void => {
		this.showBlockViewEvent.emit(displayBlock.block_id);
	};

	/**
	 * Gets the formatatted date/time for the view
	 *
	 * @param displayBlock - the current block.
	 * @returns a formatted date/time.
	 */
	public formatTime = (displayBlock: DisplayBlock): any => {
		const date: Date = new Date(displayBlock.start_time_epoch);
		const currentMonth: string = this.months[date.getMonth()];

		let formatedDateTime: string = '';

		if (displayBlock.start_time_epoch) {
			formatedDateTime += currentMonth + ' ' + date.getDate() + ' / ';
		}

		formatedDateTime += this.getTimes(displayBlock.start_time) + ' — ' + this.getTimes(displayBlock.end_time);

		return formatedDateTime;
	};

	/**
	 * Gets the status from the statusMap.
	 *
	 * @param status - an integer representing the status.
	 * @returns the mapped status string.
	 */
	public getStatus = (status: number): string => {
		return this.statusMap.get(status);
	};

	/**
	 * Gets the translated status.
	 *
	 * @param status - the status to translate.
	 * @returns the translated status.
	 */
	public getStatusTranslation = (status: number): string => {
		return this.statusMapTranslations.get(status);
	};

	/**
	 * Gets the vehicle color css styling.
	 *
	 * @param displayBlock - the block with the vehicle data.
	 * @returns css class with color styling.
	 */
	public getVehicleClass = (displayBlock: DisplayBlock): VehicleBlockStyle => {
		const now: number = moment.now().valueOf();

		if (displayBlock.cancelled && displayBlock.vehicles?.length === 0 && displayBlock.end_time_epoch > now) {
			return { color: 'red' };
		} else {
			return { color: 'black' };
		}
	};

	/**
	 * Navigates to the vehicle details view.
	 *
	 * @param authorityId - the authority the vehicle belongs to.
	 * @param vehicle - the vehicle id to navigate to.
	 */
	public navigateToVehicleDetails = async (authorityId: string, vehicle: VehicleSummary): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(authorityId, vehicle.vehicle_id, VehicleDetailsActiveTab.summary);
	};

	/**
	 * Checks a block for cancelled trips.
	 *
	 * @param displayBlock - the block containing tripDetails data.
	 * @returns true if the block has cancelled trips.
	 */
	public checkCancelledTrips = (displayBlock: DisplayBlock): boolean => {
		const cancelledTrips: TripDetails = displayBlock.tripDetails?.filter((trip) => trip.cancelled);

		return cancelledTrips?.length > 0;
	};

	/**
	 * Formats the time in seconds adding to the start of the day.
	 *
	 * @param time - the time in seconds.
	 * @returns the formatted time string
	 */
	private getTimes = (time: number): string => {
		return moment().tz(this.timezone).startOf('day').add(time, 'seconds').format(this.defaultTimeFormat);
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @returns route pill
	 */
	private determineRoutePillData = (): RoutePillData => {
		return {
			routeShortName: this.section.route_short_name,
			routeLongName: this.section.routeLongName,
			routeId: this.section.routeId,
			routeColor: this.colorUtilityService.getColor(this.section.routeColor),
			routeTextColor: this.colorUtilityService.getColor(this.section.routeTextColor),
		};
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { ReportsApiService } from './reports-api.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { ReportSelectionBlocks, ReportTemplateDetail } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class ReportsBlockSelectionDataService {
	constructor(
		private logger: LoggerService,
		private reportsApiService: ReportsApiService
	) {}

	/**
	 * handles the request to retrieve blocks data for report purposes
	 *
	 * note: at the moment only getStopsAndTimePointsByScheduledBlocks is supported
	 *
	 * @param blocksEndpoint - the blocks endpoint
	 * @param reportRequest - the report request
	 * @param lastRequestId - the last request id
	 * @returns the requested report blocks information
	 */
	public getBlocks = async (
		blocksEndpoint: string,
		reportRequest: ReportTemplateDetail,
		lastRequestId: number
	): Promise<ResultContent> => {
		switch (blocksEndpoint) {
			case 'c400_stops_and_timepoints_by_scheduled_blocks':
				return this.getStopsAndTimePointsByScheduledBlocks(reportRequest, blocksEndpoint, lastRequestId);
				break;

			default:
				this.logger.logError('No support for', blocksEndpoint);
		}

		return null;
	};

	/**
	 * handles the request to retrieve stops and time points by scheduled blocks
	 *
	 * @param reportRequest - the report request details
	 * @param blocksEndpoint - the blocks endpoint
	 * @param lastRequestId - the last request id
	 * @returns stops and time points by scheduled blocks response
	 */
	public getStopsAndTimePointsByScheduledBlocks = async (
		reportRequest: ReportTemplateDetail,
		blocksEndpoint: string,
		lastRequestId: number
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const authorityId: string = reportRequest.report_options.authority.authority_id;
		const agencyId: string = reportRequest.report_options.agencies.agency_id;

		try {
			const response: any = await this.reportsApiService.getStopsAndTimePointsByScheduledBlocks(
				authorityId,
				agencyId,
				blocksEndpoint,
				reportRequest
			);

			result.success = true;

			result.resultData = {
				lastRequestId,
				response: response as ReportSelectionBlocks,
			};
		} catch (error) {
			this.logger.logError('Failed to get blocks stops and timepoints by scheduled blocks', error);
		}

		return result;
	};
}

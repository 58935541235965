/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { PaginatedParams } from '@cubicNx/libs/utils';
import { RiderMessage } from '../types/api-types';

@Injectable()
export class RiderMessagesApiService {
	private readonly riderMessagesUrl: string = '/alerts';
	private readonly stopsByRouteUrl: string = '/stopsbyroute';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the rider messages for the given authority/agency and parameters from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param params - the retrieve request parameters
	 * @returns response containing rider messages
	 */
	public getRiderMessages = async (authorityId: string, agencyId: string, params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.riderMessagesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the rider message for the given authority, message id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param messageId - the message id
	 * @returns response containing rider message
	 */
	public getRiderMessage = async (authorityId: string, messageId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.riderMessagesUrl + '/' + messageId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the stops by route for the given authority, route from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param routeId - the route id
	 * @returns response containing stops by route
	 */
	public getStopsByRoute = async (authorityId: string, routeId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.riderMessagesUrl + this.stopsByRouteUrl, { routeId });
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the creation of a rider message for the authority/agency rider message data from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param message - the rider message
	 * @returns response containing result of the creation attempt
	 */
	public createRiderMessage = async (authorityId: string, agencyId: string, message: RiderMessage): Promise<any> => {
		try {
			return await this.httpService.post('/' + authorityId + '/' + agencyId + this.riderMessagesUrl, message);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the editing of a rider message for the authority/agency, rider message id and edited rider message data from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param messageId - the ridermessage id being edited
	 * @param message - the edited rider message
	 * @returns response containing result of the edit attempt
	 */
	public editRiderMessage = async (authorityId: string, agencyId: string, messageId: string, message: RiderMessage): Promise<any> => {
		try {
			return await this.httpService.put('/' + authorityId + '/' + agencyId + this.riderMessagesUrl + '/' + messageId, message);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the deletion of a rider message for the authority/agency, rider message id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param messageId - the ridermessage id being deleted
	 * @returns response containing result of the delete attempt
	 */
	public deleteRiderMessage = async (authorityId: string, agencyId: string, messageId: string): Promise<any> => {
		try {
			return await this.httpService.delete('/' + authorityId + '/' + agencyId + this.riderMessagesUrl + '/' + messageId);
		} catch (exception) {
			throw exception;
		}
	};
}

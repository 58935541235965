/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { uuid4 } from '../../services/uuid/uuid-service';

import { SliderConfig } from './types/types';

import { LoggerService } from '@cubicNx/libs/utils';

// ts-ignore required as this wont compile - hence the ignore (which causes everything to compile and function). Ideally we want to
// replace this control with an angular version - see https://stackblitz.com/edit/angular-nouislider-full?file=main.ts

//@ts-ignore
import * as noUiSlider from 'nouislider/distribute/nouislider';

@Component({
	selector: 'slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() values: Array<number>;
	@Input() sliderCfg: SliderConfig = null;
	@Input() disabled: boolean = false;
	@Output() updatedValues: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

	public id: string;
	public gradientStyle: any = {};

	private cfg: SliderConfig;
	private isInitialized: boolean;
	private slider: noUiSlider;

	constructor(
		private uuId: uuid4,
		private changeDetectorRef: ChangeDetectorRef,
		private loggerService: LoggerService
	) {}

	/**
	 * initializes the slider control. Sets up a unique Id using the sipplied service
	 */
	public ngOnInit(): void {
		this.id = this.uuId.generate();
	}

	/**
	 * handles initializes actions once the page has rendered
	 *
	 * merges the default config overridden with an config passed in
	 */
	public ngAfterViewInit(): void {
		const defaultConfig: SliderConfig = this.getDefaultConfig();

		// merge the default config with our passed in config
		this.cfg = { ...defaultConfig, ...this.sliderCfg };

		this.slider = document.getElementById(this.id);

		this.initialize();
	}

	/**
	 * handles changes to the input params
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.sliderCfg && !changes.sliderCfg.firstChange) {
			if (changes.sliderCfg.previousValue !== changes.sliderCfg.currentValue) {
				this.slider.noUiSlider.updateOptions(changes.sliderCfg.currentValue);
			}
		}
	}

	/**
	 * gets default configuration for the slider control
	 *
	 * @returns default configuration for the slider control
	 */
	private getDefaultConfig(): SliderConfig {
		return {
			start: [],
			connect: true,
			step: 1,
			tooltips: true,
			margin: 1,
			padding: 1,
			range: {
				min: 0, // min - padding
				max: 41, // max + padding
			},
			rangeMultiplier: 1,
			colors: {
				middleColor: '#00838f',
				middleColor2: null,
				rightColor: '#dbdbdb',
				leftColor: '#dbdbdb',
			},
			pips: {
				stepped: true,
				mode: 'values',
				values: [5, 10, 15, 20, 25, 30, 35, 40],
				density: 40,
			},
		};
	}

	/**
	 * main initialization method for the slider control
	 */
	private initialize = (): void => {
		if (this.isInitialized) {
			return;
		}

		if (this.values) {
			this.cfg.start = [];

			for (const val of this.values) {
				this.cfg.start.push(val / this.cfg.rangeMultiplier);
			}
		}

		noUiSlider.create(this.slider, this.cfg);
		if (this.disabled) {
			this.slider.setAttribute('disabled', true);
		}

		this.slider.noUiSlider.on('update', (values: any, handle: any) => {
			this.gradientStyle = this.getGradientStyleString(values);

			if (this.isInitialized) {
				this.updateValues(values, handle);
			}

			this.updatedValues.emit(this.values);
		});

		this.isInitialized = true;

		// ensures the coloring for the slider is rendered instantly
		this.changeDetectorRef.detectChanges();
	};

	/**
	 * update the slider controls with the actual values
	 *
	 * @param values - object holding all our slider settings
	 * @param handle - individual handle value
	 */
	private updateValues = (values: any, handle: any): void => {
		this.values[handle] = values[handle] * this.cfg.rangeMultiplier;
	};

	/**
	 * gets the gradient perecentage for the value passed in
	 *
	 * @param targetValue - the target value
	 * @returns the gradient percentage
	 */
	private getGradientPercentageForSliderValue = (targetValue: number): number => {
		try {
			const min: number = this.cfg.range.min;
			const max: number = this.cfg.range.max;

			return Math.round(((targetValue - min) / (max - min)) * 100);
		} catch (exception) {
			this.loggerService.logError('Failed to get tweets.', exception);

			return 0;
		}
	};

	/**
	 * gets the gradient styling
	 *
	 * @param values - the slider values
	 * @returns the backround gradient styling
	 */
	private getGradientStyleString = (values: any[]): any => {
		if (this.cfg) {
			let count: number = 0;
			let retStr: string =
				this.cfg.colors.leftColor +
				' 0%, ' +
				this.cfg.colors.leftColor +
				' ' +
				this.getGradientPercentageForSliderValue(values[count]) +
				'%, ' +
				this.cfg.colors.middleColor +
				' ' +
				(this.getGradientPercentageForSliderValue(values[count]) + 1) +
				'%, ' +
				this.cfg.colors.middleColor +
				' ' +
				this.getGradientPercentageForSliderValue(values[++count]) +
				'%, ';

			if (this.cfg.colors.middleColor2) {
				retStr +=
					this.cfg.colors.middleColor2 +
					' ' +
					(this.getGradientPercentageForSliderValue(values[count]) + 1) +
					'%, ' +
					this.cfg.colors.middleColor2 +
					' ' +
					this.getGradientPercentageForSliderValue(values[++count]) +
					'%, ';
			}

			retStr +=
				this.cfg.colors.rightColor +
				' ' +
				(this.getGradientPercentageForSliderValue(values[count]) + 1) +
				'%, ' +
				this.cfg.colors.rightColor +
				' 100%';

			const gradientParams: string = `to right, ${retStr}`;

			return { background: `linear-gradient(${gradientParams})` };
		}

		return null;
	};
}

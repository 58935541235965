<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div #schedPerfContainer *ngIf="hasResults" class="schedule-performance-container wrap center" [ngClass]="{ flex: !multicolumn }">
					<div *ngIf="!isDisplayOptionSet('listOnly')" class="schedule-performance-chart" data-test="pie.chart">
						<schedule-performance-donut-chart [summaryData]="summary" [display]="display"></schedule-performance-donut-chart>
					</div>
					<div *ngIf="!isDisplayOptionSet('chartOnly')" class="schedule-performance-table" data-test="vehicle.table">
						<div class="nb-map-details-static-table">
							<table class="table table-hover" id="vehiclesTable">
								<thead class="table-head">
									<th class="col-title width-19 text-header">{{ 'T_DASH.DB_VEHICLE' | translate }}</th>
									<th class="col-title width-17 text-header">{{ 'T_DASH.DB_ROUTE' | translate }}</th>
									<th class="col-title text-header">{{ 'T_DASH.DB_BLOCK' | translate }}</th>
									<th class="col-title text-header">{{ 'T_DASH.DB_STATUS' | translate }}</th>
									<th class="col-title width-25 text-header">{{ 'T_DASH.DB_ADHERENCE' | translate }}</th>
								</thead>
								<tbody>
									<tr class="table-row-summary nb-table-row" *ngFor="let block of displayBlocks">
										<!--Vehicle-->
										<td class="cursor-pointer">
											<div *ngIf="block.vehicleIdDisplay !== '--'">
												<a href="javascript:void(0);" (click)="navigateToVehicleDetails(block)" data-test="vehicle.table.vehicle_id">
													<span>{{ block.vehicleIdDisplay }}</span>
												</a>
											</div>
											<div *ngIf="block.vehicleIdDisplay === '--'" data-test="vehicle.table.vehicle_id">
												<span>{{ block.vehicleIdDisplay }}</span>
											</div>
										</td>
										<!--Route-->
										<td class="cursor-pointer">
											<a href="javascript:void(0);" (click)="navigateToRouteDetails(block)" data-test="vehicle.table.route">
												<route-pill [route]="determineRoutePillData(block)" [useFullWidth]="true"></route-pill>
											</a>
										</td>
										<!--Block-->
										<td class="cursor-pointer" data-test="vehicle.table.block" (click)="navigateToBlockDetails(block.block_id)">
											{{ block.block_id }}
										</td>
										<!--Status-->
										<td class="cursor-default" data-test="vehicle.table.status">{{ block.statusText }}</td>
										<!--Adherence-->
										<td class="cursor-default" [ngClass]="block.adherenceClass" data-test="vehicle.table.adherence">
											{{ block.adherenceTime }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div *ngIf="!hasResults" class="no-data text-center">{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}</div>
				<div class="schedule-performance-footer">
					<div class="update-text">
						<span class="nb-text">
							<span class="bold">{{ 'T_DASH.DB_PERFORMANCE_UPDATED' | translate }}</span>
							<br />
							<span data-test="updated">{{ lastUpdateTime.toString() | timeAgo }}</span>
						</span>
					</div>
				</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

<div class="grid-title">
	<route-pill class="report-viewer-grid-route-pill" [route]="routeData" [useFullWidth]="true"></route-pill>
	<div class="report-viewer-grid-route-descriptor">
		<span>{{ subListData.route_long_name }}</span>
	</div>
</div>
<div class="report-viewer-grid navigable-report">
	<table>
		<thead>
			<tr>
				<th
					[ngClass]="sortDetails.field === 'vehicle_id' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'vehicle_id')"
					data-test="th.vehicle_id"
					class="ellipsis">
					{{ 'T_CORE.VEHICLE' | translate }}
				</th>
				<th
					[ngClass]="sortDetails.field === 'very_early.percent' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'very_early.percent')"
					data-test="th.very_early_percent"
					class="mw-col ellipsis">
					{{ 'T_CORE.VERY_EARLY' | translate }}
				</th>
				<th
					[ngClass]="sortDetails.field === 'very_early.count' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'very_early.count')"
					data-test="th.very_early_count"
					class="mw-col ellipsis"></th>
				<th
					[ngClass]="sortDetails.field === 'early.percent' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'early.percent')"
					data-test="th.early_percent"
					class="mw-col ellipsis">
					{{ 'T_CORE.EARLY' | translate }}
				</th>
				<th
					[ngClass]="sortDetails.field === 'early.count' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'early.count')"
					data-test="th.early_count"
					class="mw-col ellipsis"></th>
				<th
					[ngClass]="sortDetails.field === 'on_time.percent' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'on_time.percent')"
					data-test="th.on_time_percent"
					class="mw-col ellipsis">
					{{ 'T_CORE.ON_TIME' | translate }}
				</th>
				<th
					[ngClass]="sortDetails.field === 'on_time.count' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'on_time.count')"
					data-test="th.on_time_count"
					class="mw-col ellipsis"></th>
				<th
					[ngClass]="sortDetails.field === 'late.percent' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'late.percent')"
					data-test="th.late_percent"
					class="mw-col ellipsis">
					{{ 'T_CORE.LATE' | translate }}
				</th>
				<th
					[ngClass]="sortDetails.field === 'late.count' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'late.count')"
					data-test="th.late_count"
					class="mw-col ellipsis"></th>
				<th
					[ngClass]="sortDetails.field === 'very_late.percent' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'very_late.percent')"
					data-test="th.very_late_percent"
					class="mw-col ellipsis">
					{{ 'T_CORE.VERY_LATE' | translate }}
				</th>
				<th
					[ngClass]="sortDetails.field === 'very_late.count' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'very_late.count')"
					data-test="th.very_late_count"
					class="mw-col ellipsis"></th>
				<th
					[ngClass]="sortDetails.field === 'total_count' ? sortClass : null"
					(click)="toggleSortList(subListData.data, 'total_count')"
					data-test="th.total"
					class="mw-col ellipsis">
					{{ 'T_REPORT.TOTAL' | translate }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let reportDataItem of currentItemsToShow" (click)="generateReport(reportDataItem)">
				<td data-test="td.vehicle_id" class="ellipsis">{{ reportDataItem.vehicle_id }}</td>
				<td
					data-test="td.very_early_percent"
					class="ellipsis"
					[ngStyle]="adherenceColorService.getEarlyStyle(reportDataItem.very_early.percent)">
					{{ reportDataItem.very_early.percent }}%
				</td>
				<td
					data-test="td.very_early_count"
					class="ellipsis"
					[ngStyle]="adherenceColorService.getEarlyStyle(reportDataItem.very_early.percent)">
					{{ reportDataItem.very_early.count }}
				</td>
				<td data-test="td.early_percent" class="ellipsis" [ngStyle]="adherenceColorService.getEarlyStyle(reportDataItem.early.percent)">
					{{ reportDataItem.early.percent }}%
				</td>
				<td data-test="td.early_count" class="ellipsis" [ngStyle]="adherenceColorService.getEarlyStyle(reportDataItem.early.percent)">
					{{ reportDataItem.early.count }}
				</td>
				<td data-test="td.on_time_percent" class="ellipsis">{{ reportDataItem.on_time.percent }}%</td>
				<td data-test="td.on_time_count" class="ellipsis">{{ reportDataItem.on_time.count }}</td>
				<td data-test="td.late_percent" class="ellipsis" [ngStyle]="adherenceColorService.getLateStyle(reportDataItem.late.percent)">
					{{ reportDataItem.late.percent }}%
				</td>
				<td data-test="td.late_count" class="ellipsis" [ngStyle]="adherenceColorService.getLateStyle(reportDataItem.late.percent)">
					{{ reportDataItem.late.count }}
				</td>
				<td
					data-test="td.very_late_percent"
					class="ellipsis"
					[ngStyle]="adherenceColorService.getLateStyle(reportDataItem.very_late.percent)">
					{{ reportDataItem.very_late.percent }}%
				</td>
				<td
					data-test="td.very_late_count"
					class="ellipsis"
					[ngStyle]="adherenceColorService.getLateStyle(reportDataItem.very_late.percent)">
					{{ reportDataItem.very_late.count }}
				</td>
				<td data-test="td.total" class="ellipsis">{{ reportDataItem.total_count }}</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

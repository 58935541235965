<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_SCHEDULE.UPLOAD_DETAILS.HEADER' | translate }}</h4>
</div>

<hr class="mat-dialog-header-divide" />

<div *ngIf="!loaded">
	<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
</div>

<mat-dialog-content *ngIf="loaded">
	<ng-container [ngSwitch]="uploadType">
		<table *ngSwitchCase="uploadHistoryDetailsTypeEnum.confirmationNumber">
			<tbody>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.CONFIRM_NO' | translate }}</th>
					<td>{{ historyDetails[0].confirmNo }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.FILE_NAME' | translate }}</th>
					<td>{{ historyDetails[0].fileName }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.NOTES' | translate }}</th>
					<td>{{ historyDetails[0].updateComments }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.DEPLOY_TIME' | translate }}</th>
					<td>{{ historyDetails[0].deployedTime | date: 'short' }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.DEPLOY_REV' | translate }}</th>
					<td>{{ historyDetails[0].deployedRev }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.REVIEWER' | translate }}</th>
					<td>{{ historyDetails[0].reviewerName }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.APPROVAL' | translate }}</th>
					<td>{{ historyDetails[0].approvedName }}</td>
				</tr>
			</tbody>
		</table>
		<table *ngSwitchCase="uploadHistoryDetailsTypeEnum.status">
			<tbody>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.STATUS' | translate }}</th>
					<td>{{ historyDetails[0].status }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.UPLOAD' | translate }}</th>
					<td>{{ historyDetails[0].startDate | date: 'short' }}</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.WARN' | translate }}</th>
					<td>
						<button class="btn btn-secondary" *ngIf="historyDetails[0].messageWarn" (click)="showDetails(historyDetails[0].messageWarn)">
							{{ 'T_SCHEDULE.UPLOAD_DETAILS.WARN_SEE' | translate }}
						</button>
					</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.ERROR' | translate }}</th>
					<td>
						<button class="btn btn-secondary" *ngIf="historyDetails[0].messageError" (click)="showDetails(historyDetails[0].messageError)">
							{{ 'T_SCHEDULE.UPLOAD_DETAILS.ERROR_SEE' | translate }}
						</button>
					</td>
				</tr>
				<tr>
					<th>{{ 'T_SCHEDULE.UPLOAD_DETAILS.INFO' | translate }}</th>
					<td>
						<button class="btn btn-secondary" *ngIf="historyDetails[0].messageInfo" (click)="showDetails(historyDetails[0].messageInfo)">
							{{ 'T_SCHEDULE.UPLOAD_DETAILS.INFO_SEE' | translate }}
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="center" *ngIf="loaded">
	<button class="btn btn-secondary" (click)="close()" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
</mat-dialog-actions>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { StringHelpers } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { AgencyFeature } from '../../../support-features/agencies/types/api-types';

import {
	AgencyReportCustomization,
	ReportSummaryContent,
	ReportTemplateDisplayDetailsByCategory,
	ReportTemplatesByCategory,
	ReportTemplateDisplayDetail,
} from '../types/types';

import { ReportSummaryDetailData, ReportTemplateDetail, ReportTemplateDetails } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class ReportsUtilService {
	constructor(
		private agenciesDataService: AgenciesDataService,
		private translationService: TranslationService
	) {}

	/**
	 * Helper method to convert a ReportSummaryDetail data into a reportSummary content.
	 *
	 * @param reportDetailData - Report Detail data.
	 * @returns Report Summary content.
	 */
	public getSummaryContentFromDetail = (reportDetailData: ReportSummaryDetailData): ReportSummaryContent => {
		return {
			loaded: true,
			data: {
				defaultTemplateId: reportDetailData.default_template_id,
				code: reportDetailData.code,
				name: reportDetailData.name,
				description: reportDetailData.description,
				updatedAt: reportDetailData.updatedAt,
				createdByName: reportDetailData.created_by_name,
				sharing: reportDetailData.sharing,
				authorityId: reportDetailData.authority_id,
				status: reportDetailData.status,
				outputType: reportDetailData.output_type,
				agencyName: this.agenciesDataService.getAgencyName(reportDetailData.authority_id, reportDetailData.agency_id),
			},
		};
	};

	/**
	 * retrievs the relevant agency report customization for the supplied template id
	 *
	 * @param defaultTemplateId - the default template id
	 * @returns agency report customization
	 */
	public getAgencyReportCustomization = (defaultTemplateId: string): AgencyReportCustomization => {
		const agencyReportCustomizations: AgencyReportCustomization = {
			id: defaultTemplateId,
			featureEnabled: true,
			customName: null,
			customDescription: null,
		};

		const agencyFeature: AgencyFeature = this.agenciesDataService.getAgencyFeature(defaultTemplateId);

		if (agencyFeature) {
			const today: Date = new Date(Date.now());

			// A feature is enabled if turned_on date is today or in the past,
			// and expire date is either null or in the future.
			if (agencyFeature.turned_on) {
				const turnedOnDate: Date = new Date(agencyFeature.turned_on);

				if (turnedOnDate > today) {
					agencyReportCustomizations.featureEnabled = false;
				} else if (agencyFeature.expires) {
					const expiration: Date = new Date(agencyFeature.expires);

					agencyReportCustomizations.featureEnabled = expiration > today;
				}
			} else {
				agencyReportCustomizations.featureEnabled = false;
			}

			if (agencyFeature.parameters) {
				agencyReportCustomizations.customName = agencyFeature.parameters.custom_name
					? agencyFeature.parameters.custom_name
					: undefined;
				agencyReportCustomizations.customDescription = agencyFeature.parameters.custom_description
					? agencyFeature.parameters.custom_description
					: undefined;
			}
		}

		return agencyReportCustomizations;
	};

	/**
	 * formats the supplied offset in minutes into hours and minutes
	 *
	 * @param offsetMinutes - the offset in minutes
	 * @returns formatted timezone offset
	 */
	public formatTimezoneOffset = (offsetMinutes: number): string => {
		let input: number = parseInt(offsetMinutes.toString(), 10);

		if (!input) {
			return offsetMinutes.toString();
		}

		const sign: string = input < 0 ? '-' : '+';

		input = Math.abs(input);

		const minutes: number = input % 60;
		const hours: number = Math.floor(input / 60);
		const hoursStr: string = hours.toString();
		const minutesStr: string = hours > 0 ? StringHelpers.addLeadingZero(minutes) : minutes.toString();

		return sign + hoursStr + minutesStr;
	};

	/**
	 * returns a formatted report status value
	 *
	 * @param status - the report status
	 * @returns formatted report status value
	 */
	public getStatusText = (status: string): string => {
		const statusUpper: string = status?.toUpperCase();

		let statusText: string = '';

		switch (statusUpper) {
			case 'FINISHED':
				statusText = this.translationService.getTranslation('T_REPORT.STATUS.FINISHED');
				break;
			case 'QUEUED':
				statusText = this.translationService.getTranslation('T_REPORT.STATUS.QUEUED');
				break;
			case 'RUNNING':
				// typically can't get in to a state where details is showing for 'RUNNING' but possible
				// via url manipulation so may as well cover scenario
				statusText = this.translationService.getTranslation('T_REPORT.STATUS.RUNNING');
				break;
			case 'FAILED-NO-DATA':
				statusText = this.translationService.getTranslation('T_REPORT.STATUS.FAILED_NO_DATA');
				break;
			case 'FAILED-SYSTEM':
				statusText = this.translationService.getTranslation('T_REPORT.STATUS.FAILED_SERVER_ERR');
				break;
			default:
				// old reports (prior to Sep23 Angular refactor) returned an error description rather than a fixed failed string
				// such as failed-no-data/failed-system handle these in the old way. Eventually this can be removed
				if (statusUpper?.search('FAILED') >= 0) {
					if (statusUpper?.search('NO DATA') >= 0) {
						statusText = this.translationService.getTranslation('T_REPORT.STATUS.FAILED_NO_DATA');
					} else {
						statusText = this.translationService.getTranslation('T_REPORT.STATUS.FAILED_SERVER_ERR');
					}
				}
				break;
		}

		return statusText;
	};

	/**
	 * retrieves saved report templates by category
	 *
	 * @param reportTemplateDetails - the report template details
	 * @returns saved report templates by category
	 */
	public getSavedReportTemplatesByCategory = async (
		reportTemplateDetails: ReportTemplateDetails
	): Promise<ReportTemplateDisplayDetailsByCategory> => {
		const reportTemplateDisplayDetailsByCategory: ReportTemplateDisplayDetailsByCategory = {};

		// format data so that it is keyed by category
		const reportConfigurationsByCategory: ReportTemplatesByCategory = this.getReportsTemplatesByCategory(reportTemplateDetails);

		Object.keys(reportConfigurationsByCategory).forEach((categoryKey: string) => {
			reportTemplateDisplayDetailsByCategory[categoryKey] = [];

			reportConfigurationsByCategory[categoryKey].forEach((report: ReportTemplateDetail) => {
				const reportCustomization: AgencyReportCustomization = this.getAgencyReportCustomization(report.default_template_id);

				const reportTemplateDisplayDetails: ReportTemplateDisplayDetail = {
					nbId: report.nb_id,
					defaultTemplateId: report.default_template_id,
					enabled: reportCustomization.featureEnabled,
					code: report.code,
					outputType: report.output_type,
					name: report.name,
					description: report.description,
					updatedAt: report.updated_at,
					createdById: report.created_by_id,
					authorityId: report.report_options.authority.authority_id,
					agencyId: report.report_options.agencies.agency_id,
				};

				reportTemplateDisplayDetailsByCategory[categoryKey].push(reportTemplateDisplayDetails);
			});
		});

		return reportTemplateDisplayDetailsByCategory;
	};

	/**
	 * retrieves report templates by category
	 *
	 * @param reportTemplateDetails - the report template details
	 * @returns report templates by category
	 */
	private getReportsTemplatesByCategory = (reportTemplateDetails: ReportTemplateDetails): ReportTemplatesByCategory => {
		const categories: string[] = [];

		reportTemplateDetails.forEach((reportTemplateDetail: ReportTemplateDetail) => {
			if (!categories.includes(reportTemplateDetail.category)) {
				categories.push(reportTemplateDetail.category);
			}
		});

		const templatesByCategory: ReportTemplatesByCategory = {};

		categories.forEach((category: string) => {
			const reportTemplatesWithCategory: ReportTemplateDetails =
				reportTemplateDetails.filter((reportTemplateDetail) => reportTemplateDetail.category === category) || [];

			templatesByCategory[category] = reportTemplatesWithCategory;
		});

		return templatesByCategory;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgenciesAdminDataService } from '../services/agencies-admin-data.service';
import { AgencyDetail } from '../../../../support-features/agencies/types/api-types';
import { BreadcrumbItems } from '../../../../utils/components/breadcrumbs/types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { Subscription } from 'rxjs';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'agency-admin-view',
	templateUrl: './agency-admin-view.component.html',
	styleUrls: ['./agency-admin-view.component.scss'],
})
export class AgencyAdminViewComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild('tabs', { static: false }) tabs: MatTabGroup;

	public loading: boolean = true;
	public settingsLoading: boolean = true;
	public agencyDetail: AgencyDetail = null;
	public breadcrumbItems: BreadcrumbItems = [];
	public authorityId: string = null;
	public agencyId: string = null;

	private routeParams$Subscription: Subscription = null;

	constructor(
		private agenciesAdminDataService: AgenciesAdminDataService,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the agency admin view
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.initBreadcrumbs();

		// get params off the url
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.authorityId = params['authorityId'];
			this.agencyId = params['agencyId'];

			await this.loadAgencyData();

			this.loading = false;
		});
	}

	/**
	 * loads the agency details
	 */
	public agencyUpdated = (): void => {
		this.loadAgencyData();
	};

	/**
	 * resets that settings are not loaded
	 */
	public settingsLoaded = (): void => {
		this.settingsLoading = false;
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads translations needed by component
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.AGENCIES',
			'T_CORE.ADMIN_TOOLS',
			'T_AGENCY.AGENCY_DETAILS',
			'T_AGENCY.SETTINGS.LANGUAGE.ENGLISH',
			'T_AGENCY.SETTINGS.LANGUAGE.SPANISH',
			'T_AGENCY.SETTINGS.LANGUAGE.FRENCH',
		]);
	};

	/**
	 * initialises the agency admin view breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({
			displayText: this.translations['T_CORE.ADMIN_TOOLS'],
			targetPath: '/admintools',
			activePage: false,
		});

		this.breadcrumbItems.push({
			displayText: this.translations['T_CORE.AGENCIES'],
			targetPath: '/admintools/agencies',
			activePage: false,
		});

		this.breadcrumbItems.push({
			displayText: this.translations['T_AGENCY.AGENCY_DETAILS'],
			activePage: true,
		});
	};

	/**
	 * retrieves agency details for supplied id and loads them into the component
	 */
	private loadAgencyData = async (): Promise<void> => {
		const result: ResultContent = await this.agenciesAdminDataService.getAgency(this.authorityId, this.agencyId);

		if (result.success) {
			this.agencyDetail = result.resultData;

			if (this.agencyDetail.agency_lang_id) {
				switch (this.agencyDetail.agency_lang_id) {
					case 17:
						this.agencyDetail.agency_lang = this.translations['T_AGENCY.SETTINGS.LANGUAGE.ENGLISH'];
						break;
					case 19:
						this.agencyDetail.agency_lang = this.translations['T_AGENCY.SETTINGS.LANGUAGE.SPANISH'];
						break;
					case 24:
						this.agencyDetail.agency_lang = this.translations['T_AGENCY.SETTINGS.LANGUAGE.FRENCH'];
						break;
					default:
						this.agencyDetail.agency_lang = '';
				}
			}
		}
	};
}

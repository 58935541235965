/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import L, { PointExpression } from 'leaflet';

import { MapNavigationService } from '../map-navigation.service';
import { MapStopsService } from '../map-stops.service';
import { MapLocationService } from '../map-location.service';
import { MapOptionsService } from '../map-options.service';
import { MapReplayService } from '../map-replay.service';
import { MapEventsService } from '../map-events.service';
import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { StopsDataService } from '../../../../support-features/stops/services/stops-data.service';
import { ColorUtilityService, TimeFormatType } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ObjectHelpers } from '@cubicNx/libs/utils';
import { TimeHelpers } from '@cubicNx/libs/utils';

import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	PredictionDisplay,
	PredictionsDisplay,
	StopLabelLastPassingDisplay,
	StopLabelPredictionDisplay,
	StopLabelRouteDisplay,
	StopLabelRoutesDisplay,
	StopPredictions,
	StopPredictionsByRouteMap,
} from '../../../../support-features/stops/types/types';

import {
	Prediction,
	StopInfo,
	StopLastPassingRoute,
	StopLastPassingRoutes,
	StopPrediction,
	StopPredictionsMap,
	StopRoute,
	StopRoutesMap,
} from '../../../../support-features/stops/types/api-types';

import { EventExtended, HTMLElementExtended, MapModeType, MapStop, Markers } from '../../types/types';

import moment, { Moment } from 'moment';

@Injectable({
	providedIn: 'root',
})
export class MapStopsTooltipMarkerService implements OnDestroy {
	private readonly tooltipCloseDelay: number = 300;

	private readonly defaultRouteColor: string = '#dddddd';
	private readonly defaultRouteTextColor: string = '#000000';

	private readonly defaultStopLabelPredictionDisplay: StopLabelPredictionDisplay = {
		vehicleId: '—',
		arrivalTime: '—',
		arrivalTimeRaw: 0,
	};

	private readonly defaultStopLabelLastPassingDisplay: StopLabelLastPassingDisplay = {
		vehicleId: '—',
		departure: '—',
		headway: null,
	};

	private mapInstance: L.Map = null;
	private markers: Markers = {};
	private selectedAgency: SelectedAgency = null;
	private removeTooltips$Subscription: Subscription = null;
	private updateTimer: any = null;
	private agencyTimezone: string = null;

	constructor(
		private translationService: TranslationService,
		private agenciesDataService: AgenciesDataService,
		private mapLocationService: MapLocationService,
		private mapStopsService: MapStopsService,
		private mapOptionsService: MapOptionsService,
		private mapNavigationService: MapNavigationService,
		private mapReplayService: MapReplayService,
		private mapEventsService: MapEventsService,
		private stopsDataService: StopsDataService,
		private colorUtilityService: ColorUtilityService
	) {}

	/**
	 * initialize the stops tooltip marker service
	 *
	 * @param mapInstance - the current map instance
	 * @param selectedAgency - the current agency
	 */
	public init = (mapInstance: L.Map, selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.selectedAgency.authority_id, this.selectedAgency.agency_id);

		this.mapInstance = mapInstance;

		this.markers = {};

		this.setSubscriptions();
	};

	/**
	 * handle any cleanup for the service (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.removeTooltips$Subscription.unsubscribe();
	}

	/**
	 * handle the agency change and clear down any markers
	 *
	 * @param selectedAgency - the current agency
	 */
	public setAgencyChange = (selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.selectedAgency.authority_id, this.selectedAgency.agency_id);

		this.markers = {};
	};

	/**
	 * add the stop tooltip
	 *
	 * @param stopCode - the stop code
	 */
	public addStopTooltip = async (stopCode: string): Promise<void> => {
		const stop: MapStop = this.mapStopsService.getStop(stopCode);

		if (stop) {
			// if the tooltip is not already showing...
			if (!this.markers[stopCode]) {
				// make sure any other tooltips are removed before we add the new one
				this.mapEventsService.publishRemoveTooltips();

				// set the initial tooltip - it only has enough detail to show the loading spinner - we will update with content next
				this.markers[stop.stopCode] = new L.Marker(new L.LatLng(stop.stopLat, stop.stopLon), {
					icon: this.getIcon(stop, null, null, null),
					zIndexOffset: 9002,
				}).addTo(this.mapInstance);

				// add in a withinTooltip flag to manage closing of the tooltip
				this.markers[stop.stopCode].withinTooltip = false;

				this.markers[stop.stopCode].on('mouseover', () => {
					this.handleMouseOver(stop.stopCode);
				});

				this.markers[stop.stopCode].on('mouseout', () => {
					this.handleMouseOut(stop.stopCode);
				});

				this.updateStopTooltip(stop);
			}
		}
	};

	/**
	 * remove the stop tooltip
	 *
	 * @param stopCode - the stop code
	 */
	public removeStopTooltip = (stopCode: string): void => {
		// allow a delay so if we get here from the parent (leaving the stop icon area) but the user enters
		// the tooltip itself, the withinTooltip
		// will be set and we wont actually close the tooltip
		setTimeout(() => {
			if (this.markers[stopCode] && !this.markers[stopCode].withinTooltip) {
				this.removeClickListeners(stopCode);

				this.markers[stopCode].remove();

				clearInterval(this.updateTimer);

				delete this.markers[stopCode];
			}
		}, this.tooltipCloseDelay);
	};

	/**
	 * get the stop icon tooltip for the map
	 *
	 * @param stop - the stop object
	 * @param routes - the associated routes
	 * @param predictions - the associated predictions
	 * @param lastPassing - the last passing info
	 * @returns a leaflet div icon to display on the map
	 */
	public getIcon = (
		stop: MapStop,
		routes: StopRoutesMap,
		predictions: StopPredictionsMap,
		lastPassing: StopLastPassingRoutes
	): L.DivIcon => {
		const routeIds: string[] = [];

		let html: string = '<div id="stop-label" class="nb-map-stop-label" ';

		html += 'style="padding-left:10px;padding-right:10px; width: 400px;">';
		html += '<a id="stopCode' + stop.stopCode + 'Click">';
		html += '<div class="label-header">';
		html += '<div class="text-block">';
		html += '<div class="stop-id">' + stop.stopCode + '</div>'; // stop code is not always the same as the id
		html += '<div class="stop-desc">' + stop.stopName + '</div>';
		html += '</div>';
		html += '<div class="stop-features">';
		html += '</div>';
		html += '</div>';
		html += '</a>';

		if (lastPassing && predictions) {
			const stopLabelRoutesDisplay: StopLabelRoutesDisplay = this.getLivePredictions(routes, predictions, lastPassing);

			if (stopLabelRoutesDisplay.length > 0) {
				html += '<div class="label-body" >';

				stopLabelRoutesDisplay.forEach((route: StopLabelRouteDisplay) => {
					routeIds.push(route.routeId);

					const routeStyle: string = 'background-color: ' + route.routeColor + ';color: ' + route.routeTextColor + ';';
					const routeDisplayName: string = route.routeName;

					html += '<div class="body-row" style="display:flex; justify-content:space-between; width:100%;">';
					html += '<div class="" style="display:flex; flex-direction:column; justify-content:space-between; width:100%;">';
					html += '<div style="display:flex; width:100%;">';
					html += '<div style="display:flex;">';

					html += '<a id="stopRouteId' + route.routeId + 'Click">';

					html += '<span class="label-route truncate" style="' + routeStyle + '"> ' + routeDisplayName + ' </span>';

					html += '</a>';
					html += '</div>';
					html += '<div class="nb-padding-left-sm" style="display:flex;">';
					html += '</div>';
					html += '</div>';
					html += '<div class="nb-padding-top-sm" style="display:flex; justify-content:space-between;">';
					html += '<div style="display:flex; flex-direction:column;">';
					html += '<div style="display:flex; ">';
					html += this.translationService.getTranslation('T_CORE.NEXT');
					html += '</div>';
					html += '<div class="nb-text-bold" style="display:flex;">';
					html += route[0].arrivalTime;
					html += '</div>';
					html += '<div style="display:flex;">';
					html += route[0].vehicleId;
					html += '</div>';
					html += '</div>';
					html += '<div style="display:flex;">';
					html += '<div style="display:flex; flex-direction:column;">';
					html += '<div style="display:flex; ">';
					html += '&nbsp;'; //column heading no label
					html += '</div>';
					html += '<div class="nb-text-bold" style="display:flex;">';
					html += route[1].arrivalTime;
					html += '</div>';
					html += '<div style="display:flex;">';
					html += route[1].vehicleId;
					html += '</div>';
					html += '</div>';
					html += '</div>';
					html += '<div style="display:flex;">';
					html += '<div style="display:flex; flex-direction:column;">';
					html += '<div style="display:flex; ">';
					html += '&nbsp;'; //column heading no label
					html += '</div>';
					html += '<div class="nb-text-bold" style="display:flex;">';
					html += route[2].arrivalTime;
					html += '</div>';
					html += '<div style="display:flex;">';
					html += route[2].vehicleId;
					html += '</div>';
					html += '</div>';
					html += '</div>';
					html += '<div style="display:flex;">';
					html += '<div style="display:flex; flex-direction:column;">';
					html += '<div style="display:flex; ">';
					html += this.translationService.getTranslation('T_CORE.LAST_PASSING');
					html += '</div>';
					html += '<div class="nb-text-bold" style="display:flex;">';

					if (
						route[3].departure === 'Invalid date' ||
						route[3].departure === 'Invalid Date' ||
						route[3].departure === '' ||
						route[3].departure === null
					) {
						route[3].departure = '—';
					}

					html += route[3].departure;
					html += '</div>';
					html += '<div style="display:flex;">';
					html += route[3].vehicleId ? route[3].vehicleId : '';
					html += '</div>';
					html += '</div>';
					html += '</div>';
					html += '<div style =" display: flex;">';
					html += '<div style =" display: flex; flex-direction: column; ">';
					html += '<div style =" display: flex; flex: 1;  ">';
					html += this.translationService.getTranslation('T_CORE.HEADWAY');
					html += '</div>';
					html += '<div class="nb-text-bold" style =" display: flex;">';

					if (route[3].departure === '—') {
						route[3].headway = '—';
					}

					html += route[3].headway;
					html += '</div>';
					html += '<div style =" display: flex; flex: 1;">';
					html += '</div>';
					html += '</div>';
					html += '</div>';
					html += '</div>';
					html += '</div>';
					html += '</div>';
				});

				// save the routeIds so we can hook up our event listeners later. Note check for marker exists is needed
				// for the ladder which just uses this to return html only
				if (this.markers[stop.stopCode]) {
					this.markers[stop.stopCode].routeIds = routeIds;
				}
			} else {
				html += '<div class="body-row">';
				html += '<div class="text-block" data-test="stop.tooltip.predictions">';
				html += '&nbsp;' + this.translationService.getTranslation('T_MAP.MAP_NO_PREDICTIONS');
				html += '</div>';
				html += '</div>';
			}
		} else {
			html += `<div class="loader-container">
            <div class="loader"></div>
            <div class="loader-text"><span class="text">${this.translationService.getTranslation('T_CORE.LOADING')}</span></div></div>`;
		}

		html += '</div>';

		return L.divIcon({
			className: 'nb-map-stop-marker',
			html,
			iconAnchor: this.getStopLabelAnchor() as PointExpression,
		});
	};

	/**
	 * update the stop tooltip
	 *
	 * @param stop - the current stop object for the map
	 */
	public updateStopTooltip = async (stop: MapStop): Promise<void> => {
		const authorityId: string = this.selectedAgency.authority_id;
		const agencyId: string = this.selectedAgency.agency_id;
		const stopCode: string = stop.stopCode;
		const stopId: string = stop.stopId;

		const result: ResultContent = await this.stopsDataService.getStopInfoByCode(authorityId, agencyId, stopCode, stopId);

		if (result.success) {
			const stopInfo: StopInfo = result.resultData;

			// check if the marker is still there - we may have moved away and the tooltip closed while we were
			// waiting for predictions to load
			if (this.markers[stop.stopCode]) {
				this.markers[stop.stopCode].setIcon(this.getIcon(stop, stopInfo.routes, stopInfo.predictions, stopInfo.lastPassing));
			}

			clearInterval(this.updateTimer);

			this.updateTimer = setInterval(() => {
				if (this.markers[stop.stopCode]) {
					this.markers[stop.stopCode].setIcon(this.getIcon(stop, stopInfo.routes, stopInfo.predictions, stopInfo.lastPassing));
				}
			}, 1000);
		}
	};

	/**
	 * get live predictions for the stop tooltip
	 *
	 * @param routes - the routes associated with the stop
	 * @param predictions - the associated predictions
	 * @param lastPassing - the last passing info
	 * @returns the display info for the tooltip
	 */
	public getLivePredictions = (
		routes: StopRoutesMap,
		predictions: StopPredictionsMap,
		lastPassing: StopLastPassingRoutes
	): StopLabelRoutesDisplay => {
		let stopLabelRoutes: StopLabelRoutesDisplay = [];

		if (predictions && lastPassing.length > 0) {
			// stop prediction branch
			const predictionsByRoute: StopPredictionsByRouteMap = {};

			for (const vehicleId in predictions) {
				const stopPredictions: StopPrediction = predictions[vehicleId];

				if (stopPredictions.predictions && stopPredictions.predictions.length > 0) {
					predictionsByRoute[stopPredictions.route_id] = predictionsByRoute[stopPredictions.route_id] || [];
					predictionsByRoute[stopPredictions.route_id].push(stopPredictions);
				}
			}

			Object.values(routes).forEach((route: StopRoute) => {
				if (Object.keys(predictionsByRoute).length > 0) {
					// Build the display prediction object for this route
					const routeDisplayRow: StopLabelRouteDisplay = {
						0: ObjectHelpers.deepCopy(this.defaultStopLabelPredictionDisplay),
						1: ObjectHelpers.deepCopy(this.defaultStopLabelPredictionDisplay),
						2: ObjectHelpers.deepCopy(this.defaultStopLabelPredictionDisplay),
						3: ObjectHelpers.deepCopy(this.defaultStopLabelLastPassingDisplay),
						routeId: route.route_id,
						routeName: route.route_short_name || route.route_long_name,
						routeColor:
							route.route_color !== null ? this.colorUtilityService.getColor(route.route_color) : this.defaultRouteColor,
						routeTextColor:
							route.route_text_color !== null
								? this.colorUtilityService.getColor(route.route_text_color)
								: this.defaultRouteTextColor,
					};

					let nearest3Preds: PredictionsDisplay = [];

					if (predictionsByRoute[route.route_id]) {
						nearest3Preds = this.getNearest3Predictions(predictionsByRoute[route.route_id]);
					}

					if (nearest3Preds.length > 0) {
						// Populate prediction object with our top 3 established predictions
						nearest3Preds.forEach((pred: PredictionDisplay, index: number) => {
							if (index < 3) {
								const stopLabelPrediction: StopLabelPredictionDisplay = routeDisplayRow[
									index as keyof StopLabelRouteDisplay
								] as StopLabelPredictionDisplay;

								stopLabelPrediction.vehicleId = pred.vehicleId;
								stopLabelPrediction.arrivalTimeRaw = pred.arrivalTimeRaw;
								stopLabelPrediction.arrivalTime = this.getFormattedTimeFromEpochTime(pred.arrivalTimeRaw);
							}
						});

						const lastPassingInfo: StopLabelLastPassingDisplay = this.getLastPassingInfo(routeDisplayRow.routeId, lastPassing);

						if (lastPassingInfo) {
							routeDisplayRow[3] = lastPassingInfo;
						}

						stopLabelRoutes.push(routeDisplayRow);
					}
				}
			});
		}

		stopLabelRoutes.sort(this.transformSort((a: StopLabelRouteDisplay) => a[0].arrivalTimeRaw));

		const uniqueIds: string[] = [];

		stopLabelRoutes = stopLabelRoutes.filter((stopLabelRoute: StopLabelRouteDisplay) => {
			const isDuplicate: boolean = uniqueIds.includes(stopLabelRoute.routeId);

			if (!isDuplicate) {
				uniqueIds.push(stopLabelRoute.routeId);

				return true;
			}

			return false;
		});

		stopLabelRoutes = stopLabelRoutes.filter((stopLabelRoute: StopLabelRouteDisplay) => {
			let valid: boolean = false;

			Object.keys(stopLabelRoute).forEach((key: any) => {
				const stopLabelPrediction: StopLabelPredictionDisplay = stopLabelRoute[
					key as keyof StopLabelRouteDisplay
				] as StopLabelPredictionDisplay;

				if (typeof stopLabelPrediction === 'object') {
					if (!(stopLabelPrediction.vehicleId === '—' || !stopLabelPrediction.vehicleId) && !valid) {
						valid = true;
					}
				}
			});

			return valid;
		});

		return stopLabelRoutes;
	};

	/**
	 * get the nearest 3 predictions to display on the tooltip
	 * @param routePredictions - the route prediction data
	 * @returns the nearest 3 predictions
	 */
	private getNearest3Predictions = (routePredictions: StopPredictions): PredictionsDisplay => {
		const predictionsDisplayFull: PredictionsDisplay = routePredictions
			.reduce(this.predictionsReduceFunction, [])
			.sort((a, b) => a.arrivalTimeRaw - b.arrivalTimeRaw);

		// Restrict to top 3
		return ObjectHelpers.take(predictionsDisplayFull, 3);
	};

	/**
	 * sorts the prediction data
	 *
	 * @param accumulator - the accumulator for the list
	 * @param stopPrediction - the stop prediction data
	 * @returns - the sorted predictions (nearest first)
	 */
	private predictionsReduceFunction = (accumulator: PredictionsDisplay, stopPrediction: StopPrediction): PredictionDisplay[] => {
		const predictionsDisplay: PredictionsDisplay = stopPrediction.predictions.map((prediction: Prediction) => ({
			vehicleId: stopPrediction.vehicle_id,
			arrivalTimeRaw: prediction.predicted_arrival,
			arrivalTime: this.getFormattedTimeFromEpochTime(prediction.predicted_arrival),
		}));

		return [...accumulator, ...predictionsDisplay];
	};

	/**
	 * sort method for live predictions
	 * @param transform - transform function
	 * @param reverse - reverse flag to determin order
	 * @returns the sorted predictions
	 */
	private transformSort = (transform: any, reverse?: any): any => {
		reverse = reverse ? 1 : -1;
		transform =
			transform ||
			function (a: any): any {
				return a;
			};

		return (a: any, b: any) => {
			if (transform(a) < transform(b)) {
				return reverse;
			} else if (transform(a) > transform(b)) {
				return -1 * reverse;
			}

			return 0;
		};
	};

	/**
	 * get the icon anchor (location) to display the tooltip
	 * @returns the icon anchor (location) to display the tooltip
	 */
	private getStopLabelAnchor = (): Array<number> => {
		switch (this.mapLocationService.getLocationZoom()) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
			case 11:
			case 12:
			case 13:
			case 14:
			case 15:
			case 16:
				return [0, 0];
			default:
				return [-4, -4];
		}
	};

	/**
	 * get the last passing info
	 *
	 * @param routeId - the route id
	 * @param lastPassing - the last passing data
	 * @returns the last passing info
	 */
	private getLastPassingInfo = (routeId: string, lastPassing: StopLastPassingRoutes): StopLabelLastPassingDisplay => {
		let lastPassingDisplay: StopLabelLastPassingDisplay = null;

		lastPassing.forEach((lastPassed: StopLastPassingRoute) => {
			if (routeId === lastPassed.route_id) {
				let headway: string = '—';

				if (lastPassed.departure_headway) {
					headway = this.getHeadWayDisplay(lastPassed.departure_headway);
				} else if (lastPassed.arrival_headway) {
					headway = this.getHeadWayDisplay(lastPassed.arrival_headway);
				}

				lastPassingDisplay = {
					vehicleId: lastPassed.vehicle_id,
					departure: this.getFormattedTimeFromStringTime(lastPassed.departure_datetime || lastPassed.arrival_datetime),
					headway,
				};
			}
		});

		return lastPassingDisplay;
	};

	/**
	 * get the headway display value for the tooltip
	 *
	 * @param valueInSeconds - the value in seconds
	 * @returns the formatted headway for display
	 */
	private getHeadWayDisplay = (valueInSeconds: number): string => {
		if (!valueInSeconds) {
			return '—';
		}

		return this.getFormattedHeadway(valueInSeconds);
	};

	/**
	 * get the formatted headway display value for the tooltip
	 *
	 * @param secondsValue - the value in seconds
	 * @returns the formatted headway for display
	 */
	private getFormattedHeadway = (secondsValue: number = 0): string => {
		const hours: number = Math.floor(secondsValue / 3600);
		const minutes: number = Math.floor((secondsValue % 3600) / 60);
		const seconds: number = Math.floor((secondsValue % 3600) % 60);

		let timeString: string = '';

		if (hours > 0) {
			timeString += hours + 'h ';
		}

		if (minutes > 0) {
			timeString += minutes + 'm ';
		}

		if (seconds > 0 && hours < 1) {
			timeString += seconds + 's ';
		}

		return timeString;
	};

	/**
	 * get the formatted time
	 *
	 * @param time - the time in string format
	 * @returns - the formatted time
	 */
	private getFormattedTimeFromStringTime = (time: string): string => {
		const timezoneTime: Moment = moment(time).tz(this.agencyTimezone);

		return this.getFormattedTime(timezoneTime);
	};

	/**
	 * get the formatted time from an epoch time
	 *
	 * @param time - the time in epoch format
	 * @returns - the formatted time
	 */
	private getFormattedTimeFromEpochTime = (time: number): string => {
		const timezoneTime: Moment = moment(time).tz(this.agencyTimezone);

		return this.getFormattedTime(timezoneTime);
	};

	/**
	 * get the formatted time from an moment time
	 *
	 * @param time - the time in moment format
	 * @returns - the formatted time
	 */
	private getFormattedTime = (time: Moment): string => {
		const format: TimeFormatType = this.mapOptionsService.getTimeFormat();

		let formattedTime: string = null;

		if (format === TimeFormatType.clockTime) {
			formattedTime = time.format('hh:mm A');
		} else {
			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				const replayTime: number = this.mapReplayService.getCurrentReplayTime();
				const replayMoment: Moment = moment(replayTime).tz(this.agencyTimezone);
				const durationSecs: number = moment.duration(replayMoment.diff(time)).asSeconds();

				formattedTime = TimeHelpers.getRelativeTimeFormatting(
					durationSecs,
					this.translationService.getTranslation('T_MAP.MAP_AGO')
				);
			} else {
				const now: Moment = moment().tz(this.agencyTimezone);
				const durationSecs: number = moment.duration(now.diff(time)).asSeconds();

				formattedTime = TimeHelpers.getRelativeTimeFormatting(
					durationSecs,
					this.translationService.getTranslation('T_MAP.MAP_AGO')
				);
			}
		}

		return formattedTime;
	};

	/**
	 * handle the mouse over event for the stop tooltip - adds click listeners
	 * @param stopCode - the stop code
	 */
	private handleMouseOver = (stopCode: string): void => {
		this.markers[stopCode].withinTooltip = true;

		// add listeners to handle our click handlers for links in our html
		this.addClickListeners(stopCode);
	};

	/**
	 * handle the mouse out event for the stop tooltip - removes click listeners
	 * @param stopCode - the stop code
	 */
	private handleMouseOut = (stopCode: string): void => {
		this.markers[stopCode].withinTooltip = false;
		this.removeStopTooltip(stopCode);
	};

	/**
	 * add click listeners for the tooltip
	 * @param stopCode - the stop code
	 */
	private addClickListeners = (stopCode: string): void => {
		this.addStopCodeClickListener(stopCode);
		this.addRouteClickListeners(stopCode);
	};

	/**
	 * remove click listeners for the tooltip
	 * @param stopCode - the stop code
	 */
	private removeClickListeners = (stopCode: string): void => {
		this.removeStopCodeClickListener(stopCode);
		this.removeRouteClickListeners(stopCode);
	};

	/**
	 * add stop code click listeners for the tooltip
	 * @param stopCode - the stop code
	 */
	private addStopCodeClickListener = (stopCode: string): void => {
		const stopCodeElement: HTMLElementExtended = document.getElementById('stopCode' + stopCode + 'Click');

		if (stopCodeElement) {
			stopCodeElement.stopCode = stopCode;
			stopCodeElement.addEventListener('click', this.onStopCodeClick);
		}
	};

	/**
	 * remove stop code click listeners for the tooltip
	 * @param stopCode - the stop code
	 */
	private removeStopCodeClickListener = (stopCode: string): void => {
		const stopCodeElement: HTMLElementExtended = document.getElementById('stopCode' + stopCode + 'Click');

		if (stopCodeElement) {
			stopCodeElement.stopCode = null;
			stopCodeElement.removeEventListener('click', this.onStopCodeClick);
		}
	};

	/**
	 * add route click listeners for the tooltip
	 * @param stopCode - the stop code
	 */
	private addRouteClickListeners = (stopCode: string): void => {
		const routeIds: string[] = this.markers[stopCode].routeIds;

		if (routeIds) {
			routeIds.forEach((routeId: string) => {
				this.addRouteIdClickListener(routeId);
			});
		}
	};

	/**
	 * add route id click listeners for the tooltip
	 * @param routeId - the route id
	 */
	private addRouteIdClickListener = (routeId: string): void => {
		const routeIdElement: HTMLElementExtended = document.getElementById('stopRouteId' + routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.routeId = routeId;
			routeIdElement.addEventListener('click', this.onRouteIdClick);
		}
	};

	/**
	 * handle the stop code click - navigate to stop details
	 *
	 * @param evt - the stop code click event including the stop code
	 */
	private onStopCodeClick = async (evt: EventExtended): Promise<void> => {
		const stopCode: string = evt.currentTarget.stopCode;

		await this.mapNavigationService.navigateToStopDetails(this.selectedAgency.authority_id, stopCode);
	};

	/**
	 * remove stop code click listeners for the tooltip
	 * @param stopCode - the stop code
	 */
	private removeRouteClickListeners = (stopCode: string): void => {
		const routeIds: string[] = this.markers[stopCode].routeIds;

		if (routeIds) {
			routeIds.forEach((routeId: string) => {
				this.removeRouteIdClickListener(routeId);
			});
		}
	};

	/**
	 * remove route id click listeners for the tooltip
	 * @param routeId - the route id
	 */
	private removeRouteIdClickListener = (routeId: string): void => {
		const routeIdElement: HTMLElementExtended = document.getElementById('stopRouteId' + routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.routeId = null;
			routeIdElement.removeEventListener('click', this.onRouteIdClick);
		}
	};

	/**
	 * handle the route id click
	 * @param evt - the click event
	 */
	private onRouteIdClick = async (evt: EventExtended): Promise<void> => {
		const routeId: string = evt.currentTarget.routeId;

		await this.mapNavigationService.navigateToRouteDetails(this.selectedAgency.authority_id, routeId);
	};

	/**
	 * set up subscriptions for the page
	 *
	 * remove tooltips - handle the suscrive to remove the stop tooltip(s)
	 */
	private setSubscriptions = (): void => {
		this.removeTooltips$Subscription = this.mapEventsService.removeTooltips.subscribe(() => {
			for (const markerId in this.markers) {
				this.markers[markerId].remove();
				delete this.markers[markerId];
			}
		});
	};
}

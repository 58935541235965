<div class="form-group col-xs-12">
	<field-label [name]="'viewName'" [label]="'T_MAP.MAP_VIEW_TITLE' | translate" [required]="true"></field-label>
	<input
		class="form-control"
		[(ngModel)]="viewName"
		title="{{ 'T_MAP.MAP_VIEW_TITLE' | translate }}"
		placeholder="{{ 'T_MAP.MAP_VIEW_TITLE' | translate }}"
		type="text"
		name="viewName"
		minlength="1"
		maxlength="250"
		(input)="onViewNameChange()"
		data-test="password.current" />
	<span [hidden]="!viewNameEdited || viewName?.length > 0" class="text-danger">{{ 'T_MAP.MAP_VIEW_NAME_REQUIRED' | translate }}</span>
</div>

<form [formGroup]="reactiveForm" class="form-group row" [ngClass]="agencies?.length > 1 ? 'form-height' : 'form-height-single-agency'">
	<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
	<div [hidden]="!loaded">
		<!-- just hide otherwise we destroy the underlying control that sends the loaded state -->
		<div id="title-text" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
			<input
				formControlName="title"
				data-test="title.input"
				type="text"
				id="title"
				class="form-control"
				placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
			<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
		</div>

		<vehicle-event-settings
			[formGroup]="reactiveForm"
			[settings]="widget.config"
			[agencies]="agencies"
			(agencyChanged)="agencyChanged()"
			(loaded)="handleLoaded($event)">
		</vehicle-event-settings>
	</div>
</form>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { MapViewService } from '../services/map-view.service';
import { MapViewEventsService } from '../services/map-view-events.service';

import { MapView, MapViews } from '../types/types';
import { MapState } from '../../../types/types';

@Component({
	selector: 'map-views-list',
	templateUrl: './map-views-list.component.html',
	styleUrls: ['./map-views-list.component.scss'],
})
export class MapViewsListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public mapViews: MapViews = null;
	public loaded: boolean = false;

	private refreshView$Subscription: Subscription = null;

	constructor(
		private mapViewEventsService: MapViewEventsService,
		private mapViewService: MapViewService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.getMapViews();
	}

	/**
	 * load the map views saved for this user
	 */
	public getMapViews = async (): Promise<void> => {
		this.loaded = false;

		this.mapViews = await this.mapViewService.getViews();

		this.loaded = true;
	};

	/**
	 * rename the map view
	 * @param view - the view to rename
	 */
	public renameView = async (view: MapView): Promise<void> => {
		const mapState: MapState = JSON.parse(view.view);

		this.mapViewService.renameView(view.nb_user, view.nb_id, view.name, mapState);
	};

	/**
	 * delete the map view
	 * @param view - the view to delete
	 */
	public deleteView = async (view: MapView): Promise<void> => {
		this.mapViewService.deleteView(view.nb_user, view.nb_id, view.name);
	};

	/**
	 * apply the map view
	 * @param view - the view to apply
	 */
	public applyView = async (view: MapView): Promise<void> => {
		const mapState: MapState = JSON.parse(view.view);

		this.mapViewService.applyView(view.name, mapState);
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * refresh views - handle the a potential change for the views list and refresh
	 */
	private setSubscriptions = (): void => {
		this.refreshView$Subscription = this.mapViewEventsService.refreshViews.subscribe(async () => {
			await this.getMapViews();
		});
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.refreshView$Subscription?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsUtilService } from '../../../services/reports-util.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportSummaryContent } from '../../../types/types';

@Component({
	selector: 'report-summary-content',
	templateUrl: './report-summary-content.component.html',
	styleUrls: ['./report-summary-content.component.scss'],
})
export class ReportSummaryContentComponent extends TranslateBaseComponent implements OnInit {
	@Input() summaryContent: ReportSummaryContent = { loaded: false };
	@Input() showHeader: boolean = false;

	public initialized: boolean = false;

	constructor(
		private reportsUtilService: ReportsUtilService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the report summary content component
	 *
	 * loads translations
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations([
			'T_REPORT.STATUS.FAILED_NO_DATA',
			'T_REPORT.STATUS.FAILED_SERVER_ERR',
			'T_REPORT.STATUS.FINISHED',
			'T_REPORT.STATUS.QUEUED',
			'T_REPORT.STATUS.RUNNING',
		]);

		this.initialized = true;
	}

	/**
	 * retrieves the status
	 *
	 * @param status - report status
	 * @returns the status text
	 */
	public getStatusText = (status: string): string => {
		return this.reportsUtilService.getStatusText(status).toUpperCase();
	};
}

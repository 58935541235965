<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".
-->

<div *ngIf="initailized">
	<div *ngIf="signs.length > 0">
		<div class="signs-panel">
			<field-label name="'type'" [label]="'T_MESSAGES.SIGNS' | translate" [required]="true"></field-label>
			<div *ngIf="selectedRows.length <= 0" class="field-error">
				<p>{{ 'T_MESSAGES.ERRORS.SIGNS_REQUIRED' | translate }}</p>
			</div>
		</div>
		<div class="data-list-container">
			<data-table
				data-test="sign.list.table"
				[listName]="listName"
				[columns]="columns"
				[rowData]="signs"
				[pagingEnabled]="false"
				[totalRows]="totalRows"
				[defaultSortings]="defaultSortings"
				[enableCheckRowSelection]="true"
				[enabledMultiCheckRowSelection]="true"
				[enabledCheckSelectAllRowSelection]="true"
				[selectedRows]="selectedRows"
				[enableMenuSelection]="false"
				[enableFooter]="true"
				[loadingIndicator]="listLoadingIndicator"
				(checkSelect)="onCheckSelect($event)">
			</data-table>
		</div>
	</div>
	<div *ngIf="signs.length <= 0">
		<div class="no-signs-panel">
			<field-label name="'type'" [label]="'T_MESSAGES.SIGNS' | translate" [required]="true"></field-label>
			<div *ngIf="selectedRows.length <= 0" class="field-error">
				<p>{{ 'T_MESSAGES.ERRORS.AGENCY_NO_SIGNS' | translate }}</p>
			</div>
		</div>
	</div>
</div>

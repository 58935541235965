import { IconType } from '@cubicNx/libs/utils';
import { RouteStopDetails, StopServiceAlerts } from './api-types';

export const criticalPriorityName: string = 'Critical';
export const highPriorityName: string = 'High';
export const normalPriorityName: string = 'Normal';
export const lowPriorityName: string = 'Low';

export interface SelectedStop {
	id: string;
	name: string;
	selected: boolean;
}

export interface MessageRoute {
	routeId: string;
	routeDisplayName: string;
	routeIsPredictEnabled?: boolean;
	routeIsHidden?: boolean;
	routeIsEnabled?: boolean;
}

export type MessageRoutes = Array<MessageRoute>;

export interface MessageRouteWithStops extends MessageRoute {
	stopsLabel: string;
}

export type MessageRoutesWithStops = Array<MessageRouteWithStops>;

export interface MessageSelectedRoute {
	id: string;
	routeDetails: MessageRoute;
	stopsLabel: string;
	stopsPerDestinations: StopsPerDestinations;
	availabeStops: boolean;
}

export type MessageSelectedRoutes = Array<MessageSelectedRoute>;

export type SelectedStops = Array<SelectedStop>;

export interface StopsPerDestination {
	id: string;
	stops: SelectedStops;
}

export type StopsPerDestinations = Array<StopsPerDestination>;

// Optional because they come from the back-end but are not used
export interface TimeInterval {
	startTime: number;
	endTime: number;
}

export interface RiderMessagesListItem {
	id: string; // any list with a checkbox must have an id
	priority: IconType;
	descriptionText: string;
	startDate: string;
	createdBy: string;
	endDate: string;
	signIds: string[];
	routes: string[];
	stops: StopServiceAlerts;
	activeTimes: string;
}

export type RiderMessagesList = Array<RiderMessagesListItem>;

export interface StopsForRoute {
	[key: string]: RouteStopDetails;
}

<div *ngIf="translationsLoaded" class="dashboard-title-container" [ngStyle]="paddingStyle()">
	<div class="dashboard-title-form" *ngIf="editMode">
		<form [formGroup]="dashboardTitleForm">
			<input
				formControlName="title"
				data-test="customize.title.input"
				type="text"
				id="title"
				class="form-control inputWidth"
				placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
			<input-validation [control]="dashboardTitleForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
		</form>
	</div>
	<div class="dropdown" *ngIf="!editMode">
		<button class="dashboard-title-btn" (click)="toggleDashboardSelect()" data-test="dashboard.3-lines">
			<i class="nb-icons btn-link-dark" [ngClass]="isDashboardSelectOpen ? 'nb-zoomin' : 'nb-menu'"></i>
		</button>
		<ul class="dropdown-menu" [ngClass]="isDashboardSelectOpen ? 'open' : ''">
			<li *ngFor="let item of dashboardOptions" data-test="dashboard.option" [ngClass]="{ disabled: editMode }">
				<a (click)="selectDashboard(item)" href="javascript:void(0);">
					<span class="nb-text ellipsis" [ngClass]="{ 'font-bold': item.selected }">
						{{ item.title }}
					</span>
				</a>
			</li>
			<li (click)="createDashboard()" [ngClass]="{ disabled: editMode }">
				<a href="javascript:void(0);">
					<i class="nb-icons nb-add new-dashboard-button"></i>
					<span class="nb-text" data-test="dashboard.new-dashboard">{{ 'T_DASH.DB_NEW_DASHBOARD' | translate }}</span>
				</a>
			</li>
		</ul>
	</div>
	<div class="truncate nb-heading" *ngIf="!editMode" data-test="dashboard.title">
		{{ selectedDashboard?.title || translations['T_DASH.DB_DASHBOARD'] }}
	</div>
	<div class="dashboard-grow"></div>
	<div class="text-right nb-padding-all-xs">
		<div *ngIf="!editMode">
			<button class="btn btn-action btn-customize" (click)="toggleEditMode()">
				<i class="nb-editwidget-icon nb-icons nb-settings"></i>
				<span class="db-edit-text">{{ 'T_DASH.DB_EDIT' | translate }}</span>
			</button>
		</div>
		<div *ngIf="editMode" class="edit-mode-buttons">
			<button type="button" class="btn btn-secondary button-margin" (click)="cancelEditMode()" data-test="customize.cancel">
				{{ 'T_DASH.DB_CANCEL' | translate }}
			</button>
			<button type="button" [disabled]="!isFormValid()" class="btn btn-primary" (click)="saveDashboard()" data-test="customize.save">
				{{ 'T_DASH.DB_SAVE' | translate }}
			</button>
		</div>
	</div>
</div>

<div *ngIf="!routeLoaded">
	<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
</div>
<div *ngIf="routeLoaded">
	<div class="nb-map-subpage-header-container nb-map-details-body route-details-header-container" id="map-details-route-header">
		<!--Back button container for alignment-->
		<div class="header-backbutton">
			<button (click)="goBack()" class="btn btn-link-dark" data-test="route.details.back.btn">
				<i class="nb-icons nb-backarrow"></i>
			</button>
		</div>
		<!--Header content-->
		<div class="header-content route-details-route-header-content-container">
			<div class="header-title route-details-route-header-title-container">
				<route-pill
					*ngIf="routePillDisplay"
					[route]="routePillDisplay"
					[useFullWidth]="true"
					class="route-details-route-header-pill"
					data-test="route.details.pill"></route-pill>
				<span class="nb-subhead nb-padding-left-sm" data-test="route.details.routeName">{{ route.route_long_name }}</span>
			</div>
		</div>
	</div>
	<div class="nb-mapheader-toolbar" id="map-details-route-toolbar">
		<!--Buttons here, like view-on/off, zoom to object, other actions-->
		<span [hidden]="!routeDisplayed()">
			<button
				class="btn btn-toggle-on"
				(click)="toggleRenderRoute()"
				title="{{ 'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP' | translate }}"
				data-test="route.details.toggleVisibility.off">
				<i class="nb-icons nb-view-on visibility-button-icon"></i>
			</button>
		</span>
		<span [hidden]="routeDisplayed()">
			<button
				class="btn btn-toggle"
				(click)="toggleRenderRoute()"
				title="{{ 'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP' | translate }}"
				data-test="route.details.toggleVisibility.on">
				<i class="nb-icons nb-view-off visibility-button-icon"></i>
			</button>
		</span>
		<button class="btn btn-action" (click)="zoomTo()" title="{{ 'T_MAP.MAP_VIEW_ZOOM_TO' | translate }}" data-test="route.details.zoomTo">
			<i class="nb-icons nb-zoomtoobject zoom-button-icon" aria-hidden="true"></i>
		</button>
	</div>
	<div class="nb-map-details-route-wrapper nb-map-details-body" id="routedetailstop">
		<div class="content-block" data-test="route.details.blocks.content">
			<!--Assignments titles-->
			<div class="sectiontitle-block" data-test="section">
				<div class="nb-section-title">
					<div>
						<span>{{ 'T_MAP.MAP_BLOCKS' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="chart">
				<!--Assignments Labels-->
				<div class="row chart-row">
					<div class="col-xs-4 chart-labels">
						<span>{{ 'T_MAP.MAP_ASSIGNED' | translate }}</span>
					</div>
					<div class="col-xs-4 chart-labels">
						<span>{{ 'T_CORE.TOTAL' | translate }}</span>
					</div>
					<div class="col-xs-4 chart-labels">
						<span>{{ 'T_MAP.MAP_WITH_ISSUES' | translate }}</span>
					</div>
				</div>
				<!--Assignment numbers-->
				<div class="row chart-row">
					<!--Assigned-->
					<div class="col-xs-4 map-details-metric-assigned" data-test="route.details.blocks.assigned">
						{{ blocksAssignedList.length }}
					</div>
					<!--Blocks-->
					<div class="col-xs-4 map-details-metric-blocks" data-test="route.details.blocks.total">
						{{ blocksActiveList.length }}
					</div>
					<!--With Issues-->
					<div class="col-xs-4 map-details-metric-issues" data-test="route.details.blocks.issues">
						{{ blocksWithIssues.length }}
					</div>
				</div>
				<!--Assignment percentages-->
				<div class="row chart-row">
					<!--Assigned-->
					<div class="col-xs-4 map-details-metric-assigned-pct" data-test="route.details.blocks.assigned.percentage">
						{{ blockAssignmentPercentage }}<span>%</span>
					</div>
					<!--Blocks-->
					<div class="col-xs-4 map-details-metric-assigned-pct"></div>
					<!--With Issues-->
					<div class="col-xs-4 map-details-metric-issues-pct" data-test="route.details.blocks.issues.percentage">
						{{ blocksWithIssuesPercentage }}<span>%</span>
					</div>
				</div>
				<!--Assignments Metric bar-->
				<div [hidden]="blocksActiveList.length === 0">
					<div class="progress map-details-metric-bar">
						<div
							class="progress-bar map-details-metric-assigned-bar"
							role="progressbar"
							[ngStyle]="getBlockAssignmentPercentageWidth()"></div>
						<div
							class="progress-bar map-details-metric-issues-bar"
							role="progressbar"
							[ngStyle]="getBlockWithIssuesPercentageWidth()"></div>
					</div>
				</div>
			</div>
			<div class="info-table">
				<div class="sectiontitle-block" data-test="active-blocks-section">
					<div class="nb-section-title">
						<div class="">
							<span>{{ 'T_MAP.MAP_ACTIVE_BLOCKS' | translate }}</span>
						</div>
					</div>
				</div>
				<div class="active-blocks-container">
					<span class="nb-text" data-test="route.details.active.blocks">{{ blocksActiveListDisplay }}</span>
				</div>
			</div>
		</div>
		<!--Blocks/Vehicles-->
		<div class="content-block" data-test="route.details.vehicle.table">
			<div class="sectiontitle-block" data-test="section">
				<div class="nb-section-title">
					<span>{{ 'T_MAP.MAP_VEHICLES' | translate }}</span>
				</div>
			</div>
			<div class="data-list-container">
				<data-table
					#dataList
					data-test="route.details.table"
					[listName]="listName"
					[columns]="columns"
					[rowData]="routeDetailVehicleList"
					[totalRows]="routeDetailVehicleList?.length"
					[pagingEnabled]="false"
					[externalSorting]="true"
					[sortInfo]="sortInfo"
					[enableFooter]="false"
					[loadingIndicator]="listLoadingIndicator"
					[noDataMessage]="'T_MAP.MAP_NO_VEHICLES_ON_ROUTE'"
					(rowClick)="onSelect($event)"
					(sortData)="handleSortRequest($event)">
				</data-table>
			</div>
		</div>
	</div>
</div>

<div class="grid-title">
	<route-pill class="report-viewer-grid-route-pill" [route]="routeData" [useFullWidth]="true"></route-pill>
	<div class="report-viewer-grid-route-descriptor">
		<span>{{ subListData.route_long_name }} - {{ subListData.headsign }}</span>
	</div>
</div>
<table>
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'stop_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_id')"
				class="ellipsis">
				{{ 'T_CORE.STOP_ID' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				class="ellipsis">
				{{ 'T_CORE.STOP_NAME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'avg_headway' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'avg_headway')"
				class="mw-col ellipsis">
				{{ 'T_CORE.AVG' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'numMin' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'numMin')"
				class="mw-col ellipsis">
				{{ 'T_CORE.MIN' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'numMax' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'numMax')"
				class="mw-col ellipsis">
				{{ 'T_CORE.MAX' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stddev_num' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stddev_num')"
				class="mw-col ellipsis">
				{{ 'T_CORE.STDEV' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'adherence_percentage' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'adherence_percentage')"
				class="mw-col ellipsis">
				{{ 'T_CORE.ADHERENCE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'count' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'count')"
				class="mw-col ellipsis">
				{{ 'T_CORE.SAMPLE_COUNT' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow" (click)="generateReport(subListData, reportDataItem.stop_id)">
			<td class="ellipsis">{{ reportDataItem.stop_id }}</td>
			<td class="ellipsis">{{ reportDataItem.stop_name }}</td>
			<td class="ellipsis">{{ reportDataItem.avg }}</td>
			<td class="ellipsis">{{ reportDataItem.min }}</td>
			<td class="ellipsis">{{ reportDataItem.max }}</td>
			<td class="ellipsis">{{ reportDataItem.stddev }}</td>
			<td class="ellipsis">{{ reportDataItem.adherence }}</td>
			<td class="ellipsis">{{ reportDataItem.count }}</td>
		</tr>
	</tbody>
	<tbody>
		<tr>
			<td class="ellipsis">{{ 'T_REPORT.SUMMARY' | translate }}</td>
			<td class="ellipsis">{{ subListData.summary.stop_name }}</td>
			<td class="ellipsis">{{ subListData.summary.avg }}</td>
			<td class="ellipsis">{{ subListData.summary.min }}</td>
			<td class="ellipsis">{{ subListData.summary.max }}</td>
			<td class="ellipsis">{{ subListData.summary.stddev }}</td>
			<td class="ellipsis">{{ subListData.summary.adherence }}</td>
			<td class="ellipsis">{{ subListData.summary.count }}</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

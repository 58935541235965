<div class="data-list-container" *ngIf="initialized">
	<data-table
		data-test="schedule.revision.table"
		[listName]="'schedule-revision-history'"
		[columns]="revisionHistoryListColumns"
		[rowData]="revisionHistoryListData"
		[pagingEnabled]="false"
		[enableCheckRowSelection]="false"
		[enableFooter]="false"
		[loadingIndicator]="revisionHistoryLoadingIndicator"
		(requestData)="handleDataRequest()">
	</data-table>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { DisplayBlock, TooltipPosition } from '../types/types';
import { RoutePillData } from '@cubicNx/libs/utils';

@Component({
	selector: 'block-tooltip',
	templateUrl: './block-tooltip.component.html',
	styleUrls: ['./block-tooltip.component.scss'],
})
export class BlockTooltipComponent extends TranslateBaseComponent implements OnInit {
	@Input() displayBlock: DisplayBlock;
	@Input() tooltipPosition: TooltipPosition;

	public blockRoute: RoutePillData = null;

	constructor(
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the current blocks tooltip component
	 */
	public ngOnInit(): void {
		this.blockRoute = this.determineRoutePillData();
	}

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @returns route pill
	 */
	private determineRoutePillData = (): RoutePillData => ({
		routeShortName: null,
		routeLongName: this.displayBlock.routeLongName,
		routeId: this.displayBlock.routeId,
		routeColor: this.colorUtilityService.getColor(this.displayBlock.routeColor),
		routeTextColor: this.colorUtilityService.getColor(this.displayBlock.routeTextColor),
	});
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import {
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ChangeDetectorRef,
	ViewContainerRef,
	ComponentFactory,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { DashboardConfigService } from '../services/dashboard-config.service';
import { WidgetEditModalDirective } from './widget-edit-modal.directive';
import { ComponentInjectionService } from '../services/component-injection.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { IWidgetEditModalComponent, modalSlideDownAnimation, modalSlideAnimationTime, IWidget } from '../types/types';

@Component({
	selector: 'widget-edit-modal',
	templateUrl: './widget-edit-modal.component.html',
	styleUrls: ['./widget-edit-modal.component.scss'],
	animations: modalSlideDownAnimation,
})
export class WidgetEditModalComponent implements OnInit, OnDestroy {
	@Input() widget: any;
	@Input() currentDashboard: any;
	@Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild(WidgetEditModalDirective, { static: true }) widgetEditModalHost!: WidgetEditModalDirective;

	public widgetTitle: string = null;
	public formValid: boolean = false;
	public translations: any = {};
	public animationState: string = null;

	private componentRef: ComponentRef<IWidgetEditModalComponent>;
	private formChanged$Subscription: Subscription = null;

	constructor(
		private popoverService: ComponentInjectionService,
		private translationService: TranslationService,
		private resolver: ComponentFactoryResolver,
		private dashboardConfig: DashboardConfigService,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	/**
	 * performs initialization tasks for the widget edit modal - loads translations and the widget compoenent
	 */
	public ngOnInit(): void {
		this.popoverService.appendClassToBody('modal-open');
		this.initTranslation();
		this.loadComponent();

		this.animationState = 'open';
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.popoverService.removeClassFromBody('modal-open');
		this.formChanged$Subscription?.unsubscribe();
		this.componentRef.destroy();
		this.widget = undefined;
	}

	/**
	 * Gets the dynamic component instance's widget data,
	 * sets it to initialized and fires saveEvent emitting the widget to save.
	 * sets content visible to false to trigger animation (timeout allows animation to finish)
	 */
	public save = (): void => {
		this.animationState = 'close';

		setTimeout(() => {
			const widgetData: any = this.componentRef.instance.widget;

			widgetData.config.isInitialized = true;
			this.saveEvent.emit(widgetData);
		}, modalSlideAnimationTime);
	};

	/**
	 * Closes the edit modal and clears the dynamic component from the viewContainerRef,
	 * finally firing closeEvent.
	 * sets content visible to false to trigger animation (timeout allows animation to finish)
	 */
	public close = (): void => {
		this.animationState = 'close';

		setTimeout(() => {
			const viewContainerRef: ViewContainerRef = this.widgetEditModalHost.viewContainerRef;

			viewContainerRef.clear();
			this.closeEvent.emit();
		}, modalSlideAnimationTime);
	};

	/**
	 * Loads the dynamic component and subscribes to the formValidChanged observable.
	 * The type of dynamic component to load is determined from the widget input data type's config editComponent property.
	 */
	private loadComponent = (): void => {
		const viewContainerRef: ViewContainerRef = this.widgetEditModalHost.viewContainerRef;

		viewContainerRef.clear();

		this.widgetTitle = this.widget.title;

		const widgetConfig: IWidget = this.dashboardConfig.widgets.find((i) => i.name === this.widget.type);

		const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(widgetConfig.editComponent);

		this.componentRef = viewContainerRef.createComponent<IWidgetEditModalComponent>(factory);
		this.componentRef.instance.widget = this.createWidgetCopy();

		factory.outputs.forEach((output) => {
			if (output.propName === 'formValidChanged') {
				this.formChanged$Subscription = (this.componentRef.instance[output.propName] as EventEmitter<any>).subscribe((event) => {
					this.updateFormValid(event);
				});
			}
		});
	};

	/**
	 * Creates a copy of a widget.
	 *
	 * @param widget - the widget to copy.
	 * @returns a copy of a widget.
	 */
	private createWidgetCopy = (): void => {
		return JSON.parse(JSON.stringify(this.widget));
	};

	/**
	 * Updates the formValid property.
	 *
	 * @param isValid - true if the form is valid.
	 */
	private updateFormValid = (isValid: any): void => {
		this.formValid = isValid;

		this.changeDetectorRef.detectChanges();
	};

	/**
	 * Initializes translations for the component.
	 */
	private initTranslation = async (): Promise<void> => {
		this.translations['DB_EDIT_WIDGET'] = this.translationService.getTranslation('T_DASH.DB_EDIT_WIDGET');
		this.translations['DB_CANCEL'] = this.translationService.getTranslation('T_DASH.DB_CANCEL');
		this.translations['DB_SAVE'] = this.translationService.getTranslation('T_DASH.DB_SAVE');
	};
}

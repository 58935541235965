import { Component, Input, OnChanges } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'occupancy-chart',
	templateUrl: './occupancy-chart.component.html',
	styleUrls: ['./occupancy-chart.component.scss'],
})
export class OccupancyChartComponent implements OnChanges {
	@Input() occupancyData: any;
	@Input() showScrollBar: boolean;

	public translations: any = {};
	public hasResults: boolean = false;
	public chartTitle: boolean = false;
	public options: any = null;
	public titleVal: string = null;
	public data: any = null;

	private manySeatsPercentage: number = 0;

	constructor(private translationService: TranslationService) {}

	/**
	 * for when the occupancy data changes - loads chart with new data
	 */
	public ngOnChanges(): void {
		this.handleChanges();
	}

	/**
	 * loads the chart when the occupancy data changes
	 */
	private handleChanges = async (): Promise<void> => {
		await this.checkTranslationsLoaded();
		this.data = [
			{
				key: this.translations['MANY_SEATS_AVAILABLE'],
				values: [],
			},
		];

		if (Array.isArray(this.occupancyData)) {
			return;
		}

		this.chartTitle = this.translations['MANY_SEATS_AVAILABLE'];

		this.loadChart(this.occupancyData);
	};

	/**
	 * loads the chart with the occupancy data
	 * @param data - the chart data
	 */
	private loadChart = (data: any): void => {
		if (!data || data.total === 0) {
			this.titleVal = '';
			this.chartTitle = null;
			this.hasResults = false;
			this.data = [];
			this.setChartOptions();

			return;
		}

		this.manySeatsPercentage = this.formatPercentages(data.manySeatsAvailable, data.total);
		this.titleVal = this.manySeatsPercentage.toString();

		if (isNaN(data.manySeatsPercentage)) {
			data.manySeatsPercentage = 0;
		}

		this.setChartOptions();

		this.hasResults = true;
		this.data = [
			{
				key: this.translations['UNKNOWN'],
				y: this.formatPercentages(data.unknown, data.total),
				color: '#C3C3C4',
			},
			{
				key: this.translations['MANY_SEATS_AVAILABLE'],
				y: this.formatPercentages(data.manySeatsAvailable, data.total),
				color: '#14ad3d',
			},
			{
				key: this.translations['FEW_SEATS_AVAILABLE'],
				y: this.formatPercentages(data.fewSeatsAvailable, data.total),
				color: '#FFB852',
			},
			{
				key: this.translations['STANDING_ROOM_ONLY'],
				y: this.formatPercentages(data.standingRoomOnly, data.total),
				color: '#E89015',
			},
			{
				key: this.translations['CRUSHED_STANDING_ROOM_ONLY'],
				y: this.formatPercentages(data.crushedStandingRoomOnly, data.total),
				color: '#EB3B3B',
			},
			{
				key: this.translations['FULL'],
				y: this.formatPercentages(data.full, data.total),
				color: '#B00808',
			},
		];
	};

	/**
	 * creates a percentage from the value and total
	 *
	 * @param value - value
	 * @param total - total
	 * @returns formatted percentage value of the total
	 */
	private formatPercentages = (value: number, total: number): number => {
		const result: number = Math.floor((value / total) * 100);

		return isNaN(result) ? 0 : result;
	};

	/**
	 * sets the chart options
	 */
	private setChartOptions = (): void => {
		this.options = {
			title: {
				enable: false,
				html: this.dynamicHTML(this.manySeatsPercentage.toString()),
				css: {
					position: 'absolute',
					top: '50%',
					transform: 'translate(0, -62%)',
					pointerEvents: 'none',
				},
			},
			chart: {
				type: 'pieChart',
				height: 300,
				width: 300,
				donut: true,
				donutRatio: 0.7,
				noData: this.translations['CHARTS_NO_DATA_AVAILABLE'],
				x: (d: any): any => d.key,
				y: (d: any): any => d.y,
				showLabels: false,
				showLegend: false,
				duration: 500,
				startAngle: (d: any): any => d.startAngle + Math.PI,
				endAngle: (d: any): any => d.endAngle + Math.PI,
				pie: {
					dispatch: {
						elementMouseover: this.mouseOver,
						elementMouseout: this.mouseOut,
					},
				},
				tooltip: {
					enabled: false,
				},
			},
		};
	};

	/**
	 * when mouse is moved out of the chart
	 *
	 * title and value are set accordingly
	 */
	private mouseOut = (): void => {
		if (this.hasResults) {
			this.chartTitle = this.translations['MANY_SEATS_AVAILABLE'];
			this.titleVal = this.manySeatsPercentage.toString();
		}
	};

	/**
	 * when mouse is moved over the chart
	 *
	 * title and value are set accordingly
	 * @param e - the mouse over event
	 */
	private mouseOver = (e: any): void => {
		this.chartTitle = e.data.key;
		this.titleVal = e.data.y;
	};

	/**
	 * creates a snippet of HTML for the chart title
	 *
	 * @param title - title
	 * @returns div tag for the chart title
	 */
	private dynamicHTML = (title: string): string => {
		return '<div style="font-size: 28px; ">' + title + '%' + '</div>' + this.chartTitle;
	};

	/**
	 * initializes the translations for the view
	 */
	private initTranslations = async (): Promise<void> => {
		this.translations['MANY_SEATS_AVAILABLE'] = this.translationService.getTranslation('T_DASH.MANY_SEATS_AVAILABLE');
		this.translations['UNKNOWN'] = this.translationService.getTranslation('T_CORE.UNKNOWN');
		this.translations['FEW_SEATS_AVAILABLE'] = this.translationService.getTranslation('T_DASH.FEW_SEATS_AVAILABLE');
		this.translations['STANDING_ROOM_ONLY'] = this.translationService.getTranslation('T_DASH.STANDING_ROOM_ONLY');
		this.translations['CRUSHED_STANDING_ROOM_ONLY'] = this.translationService.getTranslation('T_DASH.CRUSHED_STANDING_ROOM_ONLY');
		this.translations['FULL'] = this.translationService.getTranslation('T_DASH.FULL');
		this.translations['CHARTS_NO_DATA_AVAILABLE'] = this.translationService.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE');
		this.translations['DB_NO_DATA_FOUND'] = this.translationService.getTranslation('T_DASH.DB_NO_DATA_FOUND');
	};

	/**
	 * checks if the translations have been loaded
	 */
	private checkTranslationsLoaded = async (): Promise<void> => {
		if (!this.translations['MANY_SEATS_AVAILABLE']) {
			await this.initTranslations();
		}
	};
}

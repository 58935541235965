import { Injectable } from '@angular/core';

import { DownloadHelpers } from '../../helpers/download-helpers';

import { LoggerService } from '@cubicNx/libs/utils';

import { ExportColumnType, ExportData } from './types/types';

import moment from 'moment';

/**
 * Service responsible for exporting data.
 */

@Injectable({
	providedIn: 'root',
})
export class ExporterService {
	private readonly comma: string = ',';
	private readonly newLine: string = '\n';

	constructor(private loggerService: LoggerService) {}

	/**
	 * Convert the supplied raw data to CSV form and export.
	 *
	 * @param dataToExport - data to export
	 */
	public exportToCSV = (dataToExport: ExportData): void => {
		const csvContent: string = this.createCSVContent(dataToExport);

		DownloadHelpers.download(dataToExport.fileName, csvContent, 'text/csv');
	};

	/**
	 * Create the CSV content
	 *
	 * Ensure textual properties dont contain commas and ensure date based properties
	 * are formatted appropriately
	 *
	 * @param dataToExport - data to export
	 * @returns the formatted data
	 */
	private createCSVContent(dataToExport: ExportData): string {
		let formattedData: string = '';

		formattedData += dataToExport.headers.join() + this.newLine;

		// Populate the CSV part (Body)
		dataToExport.data.forEach((dataObject) => {
			const rowData: string[] = [];

			dataObject.properties.forEach((property) => {
				let propertyValue: string = '';

				switch (property.type) {
					case ExportColumnType.text: {
						propertyValue = property.value.replaceAll(this.comma, ' ');
						break;
					}
					case ExportColumnType.date: {
						if (property.value) {
							propertyValue = moment(property.value).format(property.dateFormatter);
						}
						break;
					}
					default: {
						this.loggerService.logWarn('Unhandled export column type: ' + property.type);
					}
				}

				rowData.push(propertyValue);
			});

			formattedData += rowData.join() + this.newLine;
		});

		return formattedData;
	}
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { TranslationService } from '@cubicNx/libs/utils';

declare let $: any;

@Component({
	selector: 'sidebar-minimalizer',
	templateUrl: './sidebar-minimalizer.component.html',
	styleUrls: ['./sidebar-minimalizer.component.scss'],
})
export class SidebarMinimalizerComponent extends TranslateBaseComponent {
	public isMinimized: boolean = false;

	private sideMenuElement: any = $('#side-menu');

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * expands or contracts the sidebar
	 */
	public minimalize = (): void => {
		const bodyElement: any = $('body');

		bodyElement.toggleClass('mini-navbar');

		if (!bodyElement.hasClass('mini-navbar') || bodyElement.hasClass('body-small')) {
			this.hideSideMenu(200);
		} else if (bodyElement.hasClass('fixed-sidebar')) {
			this.hideSideMenu(100);
		} else {
			// Remove all inline style from jquery fadeIn function to reset menu state
			this.sideMenuElement.removeAttr('style');
		}

		this.isMinimized = !this.isMinimized;

		// update the cached user settings for this user
		this.currentUserUtilService.toggleMiniNavBar();
	};

	/**
	 * hides the side menu using the supplied timeout value
	 *
	 * @param timeout - the timeout (milliseconds)
	 */
	private hideSideMenu = (timeout: number): void => {
		// Hide menu in order to smoothly turn on when maximize menu
		this.sideMenuElement.hide();
		// For smoothly turn on menu
		setTimeout(() => {
			this.sideMenuElement.fadeIn(400);
		}, timeout);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { IWidgetComponent } from '../../../types/types';
import { RoutePillData } from '@cubicNx/libs/utils';
import { VehicleDetailsActiveTab } from '../../../../map/types/types';

import {
	HeadwayPerformanceWidgetData,
	HeadwayPeformanceDataSvgDisplay,
	VehicleDisplays,
	VehicleDisplayItem,
} from '../../../../../support-features/routes/types/api-types';

import moment from 'moment';

@Component({
	selector: 'headway-performance',
	templateUrl: './headway-performance.component.html',
	styleUrls: ['./headway-performance.component.scss'],
})
export class HeadwayPerformanceComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;
	public loaded: boolean = false;
	public hasResults: boolean = false;
	public success: boolean = false;
	public multicolumn: boolean = false;
	public vehicleDisplay: VehicleDisplays = null;
	public svgDisplay: HeadwayPeformanceDataSvgDisplay = null;

	private listInterval: any = null;
	private agenciesLoaded$Subscription: Subscription = null;

	constructor(
		private mapNavigationService: MapNavigationService,
		private widgetEventsService: WidgetEventsService,
		private colorUtilityService: ColorUtilityService,
		private routesDataService: RoutesDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the headway performance component - setting up subscriptions and loading widget data
	 */
	public ngOnInit(): void {
		this.setSubscriptions();

		this.setup();
		this.getData();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * determines if the supplied display is set in configuration
	 *
	 * @param display - display
	 * @returns true if the supplied display value is set, false otherwise
	 */
	public isDisplayOptionSet = (display: string): boolean => {
		return this.data.config.display === display;
	};

	/**
	 * navigates to the route details view
	 *
	 * @param routeId - route id
	 */
	public navigateToRouteDetails = async (routeId: string): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(this.data.config.selectedAgency.authority_id, routeId);
	};

	/**
	 * navigates to the vehicle details view
	 *
	 * @param authorityId - authority id
	 * @param vehicleId - vehicle id
	 */
	public navigateToVehicleDetails = async (authorityId: string, vehicleId: string): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(authorityId, vehicleId, VehicleDetailsActiveTab.summary);
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param vehicle - the vehicle
	 * @returns route pill
	 */
	public determineRoutePillData = (vehicle: VehicleDisplayItem): RoutePillData => {
		return {
			routeShortName: vehicle.route_short_name,
			routeLongName: vehicle.route_long_name,
			routeId: vehicle.route_id,
			routeColor: this.colorUtilityService.getColor(vehicle.route_color),
			routeTextColor: this.colorUtilityService.getColor(vehicle.route_text_color),
		};
	};

	/**
	 * determines if this is a multi-column widget
	 */
	private setup = (): void => {
		this.multicolumn = this.rowData.columns.length > 1;
	};

	/**
	 * retrieves the headway performance data, loads the widget
	 */
	private getData = async (): Promise<void> => {
		this.loaded = false;

		if (this.data.config.display) {
			clearInterval(this.listInterval);
			const refreshRate: number = (this.data.config.refreshRateSec || 30) * 1000;

			await this.getHeadwayPerformance();

			this.listInterval = setInterval(this.getHeadwayPerformance, refreshRate);
		}
	};

	/**
	 * retrieves the headway performance data, loads the widget
	 */
	private getHeadwayPerformance = async (): Promise<void> => {
		const routeIdList: string[] = this.data.config.selectedRoutes?.map((i: any) => i.route_id);

		const response: ResultContent = await this.routesDataService.getHeadwayPerformance(
			this.data.config.selectedAgency.authority_id,
			this.data.config.selectedAgency.agency_id,
			routeIdList
		);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.success = false;
		this.hasResults = false;

		if (response.success) {
			const results: HeadwayPerformanceWidgetData = response.resultData;

			if (results?.vehicleDisplay?.length > 0) {
				this.vehicleDisplay = results.vehicleDisplay;
				this.svgDisplay = results.svgDisplay;

				this.vehicleDisplay = this.sortAndLimit(this.vehicleDisplay, this.data.config.listSize.value, 'headwayPerc');

				this.setColors(this.vehicleDisplay, 'route_color');
				this.setColors(this.vehicleDisplay, 'route_text_color');
				this.formatSecondsDisplay(this.vehicleDisplay, 'headway');

				this.hasResults = true;
			}

			this.success = true;
		}

		this.loaded = true;
	};

	/**
	 * populates the colors of the supplied property
	 *
	 * @param vehicleDisplays - vehicle displays
	 * @param prop - property
	 */
	private setColors = (vehicleDisplays: VehicleDisplays, prop: string): void => {
		vehicleDisplays.forEach((item: any) => {
			item[prop] = this.colorUtilityService.getColor(item[prop]);
		});
	};

	/**
	 * goes through the vehicles and sorts via the supplied property, then limits the amount returned
	 *
	 * @param vehicleDisplays - vehicle displays
	 * @param limit - limit value
	 * @param prop - property
	 * @returns sorted and limited vehicles list
	 */
	private sortAndLimit = (vehicleDisplays: VehicleDisplays, limit: any, prop: string): VehicleDisplays => {
		vehicleDisplays.sort((a, b) => {
			let aNaN: any = a[prop as keyof VehicleDisplayItem];
			let bNaN: any = b[prop as keyof VehicleDisplayItem];

			if (isNaN(aNaN)) {
				aNaN = 0;
			}

			if (isNaN(bNaN)) {
				bNaN = 0;
			}

			return Math.abs(bNaN) - Math.abs(aNaN);
		});

		if (limit === 'All') {
			return vehicleDisplays;
		} else {
			return vehicleDisplays.slice(0, limit * 1);
		}
	};

	/**
	 * creates a formatted seconds and percentage for display purposes
	 *
	 * @param vehicleDisplays - vehicle displays
	 * @param prop - property
	 */
	private formatSecondsDisplay = (vehicleDisplays: VehicleDisplays, prop: string): void => {
		vehicleDisplays.forEach((item: any) => {
			item.displaySecs = this.setTimes(item[prop]);
			item.displayPerc = !isNaN(item.headwayPerc) ? '(' + item.headwayPerc + '%)' : '';
		});
	};

	/**
	 * createa a moment in time for the supplied value
	 *
	 * @param time - time
	 * @returns formatted time
	 */
	private setTimes = (time: number): string => {
		if (typeof time !== 'number') {
			return '-';
		}

		Math.floor(time);
		const format: string = time > 3600 ? 'hh:mm:ss' : 'mm:ss';

		return moment().startOf('day').add(time, 'seconds').format(format);
	};

	/**
	 * Setup subscriptions to observables.
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.getData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.listInterval) {
			clearInterval(this.listInterval);
		}

		this.agenciesLoaded$Subscription?.unsubscribe();
		this.reloadWidget$Subscription?.unsubscribe();
	};
}

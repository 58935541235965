/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { BlocksDataService } from '../../../../../support-features/blocks/services/block-data.service';
import { DepotsDataService } from '../../../../../support-features/depots/services/depots-data.service';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { MapVehiclesService } from '../../../../map/services/map-vehicles.service';

import { VehicleDetailsActiveTab } from '../../../../map/types/types';
import { Depot, Depots } from '../../../../../support-features/depots/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { RoutePillData } from '@cubicNx/libs/utils';

import { VehicleSummary, VehicleSummaries } from '../../../../../support-features/vehicles/types/api-types';
import { VehicleAdherenceDisplay, VehicleAdherenceType } from '../../../../../support-features/vehicles/types/types';
import { IWidgetComponent, VehicleSelectionAll, VehicleSelectionDepots, VehicleSelectionVehicles } from '../../../types/types';
import { Agency } from '../../../../../support-features/agencies/types/api-types';

import {
	BlockRoute,
	BlockRoutes,
	DashboardVehicleBlocks,
	DashboardVehicleBlocksSummary,
} from '../../../../../support-features/routes/types/api-types';

import {
	BlocksByAdherence,
	DashboardDisplayAdherences,
	DisplayBlockRoute,
	DisplayBlockRoutes,
} from '../../../../../support-features/routes/types/types';

@Component({
	selector: 'schedule-performance',
	templateUrl: './schedule-performance.component.html',
	styleUrls: ['./schedule-performance.component.scss'],
})
export class SchedulePerformanceComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	@ViewChild('schedPerfContainer', { static: false }) containerElement: ElementRef;

	public reloadWidget$Subscription: Subscription = null;
	public lastUpdateTime: Date = null;
	public loaded: boolean = false;
	public success: boolean = false;
	public hasResults: boolean = false;
	public summary: DashboardVehicleBlocksSummary = null;
	public display: DashboardDisplayAdherences = null;
	public blocks: BlockRoutes = null;
	public displayBlocks: DisplayBlockRoutes = null;
	public chartTitle: string = null;
	public multicolumn: boolean = false;

	private authorityId: string = null;
	private agencyId: string = null;
	private listInterval: any = null;
	private vehicles: VehicleSummaries = [];
	private depots: Depots = [];

	private adhEarlyThreshold: number = null;
	private adhLateThreshold: number = null;
	private adhVeryEarlyThreshold: number = null;
	private adhVeryLateThreshold: number = null;
	private stale: number = null;

	constructor(
		private depotsDataService: DepotsDataService,
		private vehiclesDataService: VehiclesDataService,
		private mapNavigationService: MapNavigationService,
		private mapVehiclesService: MapVehiclesService,
		private colorUtilityService: ColorUtilityService,
		private widgetEventsService: WidgetEventsService,
		private blocksDataService: BlocksDataService,
		private agenciesDataService: AgenciesDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the schedule performance component - loading translations, setting up subscriptions
	 * and loading widget data
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations([
			'T_DASH.DB_UNASSIGNED',
			'T_DASH.DB_VERY_LATE',
			'T_DASH.DB_LATE',
			'T_DASH.DB_ON_TIME',
			'T_DASH.DB_EARLY',
			'T_DASH.DB_VERY_EARLY',
			'T_DASH.DB_UNKNOWN',
		]);

		this.setSubscriptions();

		await this.loadAgencyData();

		this.setup();
		this.getData();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * navigates to the vehicle details view
	 *
	 * @param block - block details
	 */
	public navigateToVehicleDetails = async (block: DisplayBlockRoute): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(
			block.authority_id,
			block.vehicleIdDisplay,
			VehicleDetailsActiveTab.summary
		);
	};

	/**
	 * navigates to the route details view
	 *
	 * @param block - block details
	 */
	public navigateToRouteDetails = async (block: DisplayBlockRoute): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(block.authority_id, block.route_id);
	};

	/**
	 * navigates to the block details view
	 *
	 * @param blockId - block id
	 */
	public navigateToBlockDetails = async (blockId: string): Promise<void> => {
		await this.mapNavigationService.navigateToBlockDetails(this.authorityId, blockId);
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param block - the block data
	 * @returns route pill
	 */
	public determineRoutePillData = (block: DisplayBlockRoute): RoutePillData => {
		return {
			routeShortName: block.route_short_name,
			routeLongName: block.route_long_name,
			routeId: block.route_id,
			routeColor: this.colorUtilityService.getColor(block.route_color),
			routeTextColor: this.colorUtilityService.getColor(block.route_text_color),
		};
	};

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * determines whether the supplied display value exists within the widget configuration
	 *
	 * @param display - display
	 * @returns true if widget configuration contains the supplied display value, false otherwise
	 */
	public isDisplayOptionSet = (display: string): boolean => {
		return this.data.config.display === display;
	};

	/**
	 * Loads the selected agency data.
	 */
	public loadAgencyData = async (): Promise<void> => {
		await this.loadVehicles();
		await this.loadDepots();
	};

	/**
	 * Gets the vehicles for the selectedAgency.
	 */
	private loadVehicles = async (): Promise<void> => {
		const vehiclesResponse: ResultContent = await this.vehiclesDataService.getVehicles(this.data.config.selectedAgency?.authority_id);

		if (vehiclesResponse.success) {
			this.vehicles = vehiclesResponse.resultData as VehicleSummaries;
		}
	};

	/**
	 * Gets the depots for the selectedAgency.
	 */
	private loadDepots = async (): Promise<void> => {
		this.depots = [];

		const result: ResultContent = await this.depotsDataService.getDepots(this.data.config.selectedAgency?.authority_id, '1');

		if (result.success) {
			this.depots = result.resultData;
		}
	};

	/**
	 * sets up the widget data from configuration
	 */
	private setup = (): void => {
		this.multicolumn = this.rowData.columns.length > 1;
		this.display = {
			displayAdherence: this.data.config.displayAdherence,
			displayUnassigned: this.data.config.displayUnassigned,
			displayOffRoute: this.data.config.displayOffRoute,
			displayVeryEarly: this.data.config.displayVeryEarly,
			displayEarly: this.data.config.displayEarly,
			displayOnTime: this.data.config.displayOnTime,
			displayLate: this.data.config.displayLate,
			displayVeryLate: this.data.config.displayVeryLate,
		};

		this.authorityId = this.data.config?.selectedAgency?.authority_id;

		this.agencyId = this.data.config?.selectedAgency?.agency_id;
	};

	/**
	 * retrieves widget data and updates the view
	 */
	private getData = async (): Promise<void> => {
		this.loaded = false;

		const timezone: string = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

		if (!timezone) {
			return;
		}

		await this.getVehiclesBlocks();

		clearInterval(this.listInterval);
		const refreshRateSec: number = (this.data.config.refreshRateSec || 30) * 1000;

		this.listInterval = setInterval(this.getVehiclesBlocks, refreshRateSec);
	};

	/**
	 * retrieves the vehicle blocks data and updates the underlying widget data accordingly
	 */
	private getVehiclesBlocks = async (): Promise<void> => {
		const agencySettings: Agency = this.agenciesDataService.getAgencyDetail(this.authorityId, this.agencyId);
		const timezone: string = agencySettings.agency_timezone;

		// not to be confused with the aherence early to late filter checkboxes in the form. These are the
		// configured thresholds for the agency
		this.adhEarlyThreshold = agencySettings.adherence_setting_early_min_sec;
		this.adhLateThreshold = agencySettings.adherence_setting_late_min_sec;
		this.adhVeryEarlyThreshold = agencySettings.adherence_setting_very_early_sec;
		this.adhVeryLateThreshold = agencySettings.adherence_setting_very_late_sec;
		this.stale = agencySettings.stale_sec;

		this.summary = null;

		// always request adherence results even if we just want to display unassigned blocks so we can use the totals
		// for the unassigned v assigned count for the chart.  We can't rely on the supplied counts from the back end
		// as we filter client side on vehicles so these counts will be invalid. We must calculate client side totals
		const response: ResultContent = await this.blocksDataService.getDashboardVehiclesBlocks(
			this.authorityId,
			this.agencyId,
			timezone,
			this.adhEarlyThreshold,
			this.adhLateThreshold,
			this.adhVeryEarlyThreshold,
			this.adhVeryLateThreshold,
			this.stale,
			// adherence included - set to true (see above comment)
			true,
			this.data.config.displayUnassigned,
			this.data.config.displayOffRoute,
			null
		);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.success = false;
		this.hasResults = false;

		if (response.success) {
			const dashboardVehicleBlocks: DashboardVehicleBlocks = response.resultData;

			if (Array.isArray(dashboardVehicleBlocks.results) && dashboardVehicleBlocks.results.length > 0) {
				// a decision was made in the past, when sorting was introduced, to request all blocks and sort client side.
				// The reason for this was unclear but since then vehicle and adherence filtering has been added to client
				// side too by the Cyient team. It has to all be filtered and sorted on client side or all moved to the
				// backend as requesting a paged size of say 10 then filtering/sorting wouldn't make sense. This logic
				// should be moved to the back end and keep the widget simple and display the data as requested.

				// filter blocks list by vehicles for list/graph
				this.blocks = this.filterBlocksByVehicleSelection(dashboardVehicleBlocks.results);

				// set graph counts on filtered list before we apply further adherence filters when creating the list
				// otherwise we may filter out onTime adherence (if configured off) which we still need for the graph
				this.setGraphCounts(this.blocks);

				this.displayBlocks = this.getFilteredBlockList(this.blocks);

				this.hasResults = this.displayBlocks.length > 0;
			}

			this.lastUpdateTime = new Date();

			this.success = true;
		}

		// turn of loading spinner after initial load - just let the widget update on
		// future requests without spinner
		this.loaded = true;
	};

	/**
	 * updates the counts for the graph on the widget
	 *
	 * @param blockRoutes - block routes
	 */
	private setGraphCounts = (blockRoutes: BlockRoutes): void => {
		// Not sure why the solution here is to use the adhClassification
		// when we have the individual adherence arrays we could use - perhaps it makes no difference
		const assignedCount: number = blockRoutes.filter((x) => x.adherence !== null).length;
		const unassignedCount: number = blockRoutes.filter((x) => x.adherence === null).length;

		const veryLateCount: number = blockRoutes.filter((x) => x.adhClassification === 'very late').length;
		const lateCount: number = blockRoutes.filter((x) => x.adhClassification === 'late').length;
		const veryEarlyCount: number = blockRoutes.filter((x) => x.adhClassification === 'very early').length;
		const onTimeEarlyCount: number = blockRoutes.filter((x) => x.adhClassification === 'on time early').length;
		const onTimeLateCount: number = blockRoutes.filter((x) => x.adhClassification === 'on time late').length;
		const earlyCount: number = blockRoutes.filter((x) => x.adhClassification === 'early').length;
		const onTimeCount: number = blockRoutes.filter((x) => x.adherence === 0).length;

		const onTimeGeneralCount: number = onTimeCount + onTimeEarlyCount + onTimeLateCount;

		this.summary = {
			assignedCount,
			unassignedCount,
			onTimeCount: onTimeGeneralCount, // we want the onTime in the graph regardless
			earlyCount: this.data.config.displayEarly ? earlyCount : 0,
			lateCount: this.data.config.displayLate ? lateCount : 0,
			veryEarlyCount: this.data.config.displayVeryEarly ? veryEarlyCount : 0,
			veryLateCount: this.data.config.displayVeryLate ? veryLateCount : 0,
		};
	};

	/**
	 * creates a list of blocks and routes for display purposes
	 * As filtering/sorting is done client side we have asked for everything in the request
	 * we must then filter the data accordingly based on whether the user wants to see
	 * adherence only or unassigned only. Note: we initially ask for everything so we still have
	 * appropraite counts available for the chart i.e assigned count when displaying unassigned only.
	 * Previously, the back end counts were used but these don't take in to account any client side filtering
	 *
	 * @param blocks - blocks routes
	 * @returns blocks and route for display purposes
	 */
	private getFilteredBlockList = (blocks: BlockRoutes): DisplayBlockRoutes => {
		// if adherence selected then filter/sort. The data will contain unassigned too if selected so
		// no additional processing needed as handled in the request
		if (this.data.config.displayAdherence) {
			const filteredBlocksByAdherence: BlocksByAdherence = this.filterBlockListByAdherence(blocks);

			blocks = this.sortBlockListByAdherence(filteredBlocksByAdherence);
		} else if (this.data.config.displayUnassigned) {
			// unassigned only - filter as appropriate. i.e remove all assigned. This could be handled
			// by only request unassigned in the request but we needed assigned too to get the count for the chart
			blocks = this.filterBlockListByUnassigned(blocks);
		}

		blocks = this.setBlockListSize(blocks);

		// take our sorted filtered blocks and create new object for display with extra properties for the view
		return this.setVehicleBlockListDisplayVals(blocks);
	};

	/**
	 * filters the supplied block and routes by the selected vehicles
	 *
	 * @param blockRoutes - block and routes
	 * @returns filtered block and routes by vehicle
	 */
	private filterBlocksByVehicleSelection = (blockRoutes: BlockRoutes): BlockRoutes => {
		let filteredBlockRoutesByVehicle: BlockRoutes = [];

		// get our fill depot object based on depot we have selected
		const filteredDepots: Depot[] = this.depots.filter((depot: Depot) => this.data.config.selectedDepots.includes(depot.nb_id));

		// create list of depot tags
		const depotTags: string[] = filteredDepots.map((d) => d.tag);

		// create a list of vehicle based on vehicles that correspond to our chosen depit tags
		const depotVehicles: VehicleSummaries = this.vehicles.filter((v) => depotTags.includes(v.current_state.last_depot));

		// create a list of vehicle Ids based on our filtered vehicles
		const depotVehicleIds: string[] = depotVehicles.map((v) => v.vehicle_id);

		switch (this.data.config.vehicleFilterSelection.value) {
			case VehicleSelectionVehicles:
				filteredBlockRoutesByVehicle = blockRoutes.filter((blockRoute: BlockRoute) =>
					this.data.config.selectedVehicles.includes(blockRoute.vehicle_id)
				);
				break;
			case VehicleSelectionDepots:
				// filter our blocks based on the vehicles corresponding to our chosen depot(s)
				filteredBlockRoutesByVehicle = blockRoutes.filter((blockRoute: BlockRoute) =>
					depotVehicleIds.includes(blockRoute.vehicle_id)
				);
				break;
			case VehicleSelectionAll:
			default:
				// for all vehicles we still want to filter on our full vehicle list (rather than apply no filter) as
				// this removes stale vehicles
				// that are returned in our block list but are not present in our vehicle list (however we dont want
				// to filter unassigned)
				filteredBlockRoutesByVehicle = blockRoutes.filter(
					(blockRoute: BlockRoute) =>
						this.vehicles.filter((vehicle: VehicleSummary) => vehicle.vehicle_id === blockRoute.vehicle_id).length > 0 ||
						blockRoute.vehicle_id === null // keep unassigned
				);
				break;
		}

		return filteredBlockRoutesByVehicle;
	};

	/**
	 * creates a block and routes instance filtered by adherence
	 *
	 * @param blocks - blocks and routes
	 * @returns a filtered block instance by adherence
	 */
	private filterBlockListByAdherence = (blocks: BlockRoutes): BlocksByAdherence => {
		const blocksByAdherence: BlocksByAdherence = this.getAdherences(blocks);

		const filteredAdherences: BlocksByAdherence = {
			veryEarly: this.data.config.displayVeryEarly ? blocksByAdherence.veryEarly : [],
			veryLate: this.data.config.displayVeryLate ? blocksByAdherence.veryLate : [],
			late: this.data.config.displayLate ? blocksByAdherence.late : [],
			early: this.data.config.displayEarly ? blocksByAdherence.early : [],
			earlyOnTime: this.data.config.displayOnTime ? blocksByAdherence.earlyOnTime : [],
			lateOnTime: this.data.config.displayOnTime ? blocksByAdherence.lateOnTime : [],
			onTime: this.data.config.displayOnTime ? blocksByAdherence.onTime : [],
			unassigned: this.data.config.displayUnassigned ? blocksByAdherence.unassigned : [],
		};

		return filteredAdherences;
	};

	/**
	 * creates a blocks by adherence instance from the supplied blocks and routes
	 *
	 * @param blockRoutes - blocks and routes
	 * @returns blocks by adherence instance
	 */
	private getAdherences = (blockRoutes: BlockRoutes): BlocksByAdherence => {
		const veryEarly: BlockRoutes = [];
		const veryLate: BlockRoutes = [];
		const late: BlockRoutes = [];
		const early: BlockRoutes = [];
		const earlyOnTime: BlockRoutes = [];
		const lateOnTime: BlockRoutes = [];
		const onTime: BlockRoutes = [];
		const unassigned: BlockRoutes = [];

		blockRoutes.forEach((blockRoute: BlockRoute) => {
			if (blockRoute.adherence !== null) {
				if (blockRoute.adherence < 0) {
					const posAdherence: number = blockRoute.adherence * -1;

					// block is early
					if (posAdherence >= this.adhVeryEarlyThreshold) {
						veryEarly.push(blockRoute);
					} else if (posAdherence >= this.adhEarlyThreshold) {
						early.push(blockRoute);
					} else {
						earlyOnTime.push(blockRoute);
					}
				} else if (blockRoute.adherence > 0) {
					// block is late
					if (blockRoute.adherence >= this.adhVeryLateThreshold) {
						veryLate.push(blockRoute);
					} else if (blockRoute.adherence >= this.adhLateThreshold) {
						late.push(blockRoute);
					} else {
						lateOnTime.push(blockRoute);
					}
				} else {
					onTime.push(blockRoute);
				}
			} else {
				unassigned.push(blockRoute);
			}
		});

		const adherences: BlocksByAdherence = {
			veryEarly,
			veryLate,
			late,
			early,
			earlyOnTime,
			lateOnTime,
			onTime,
			unassigned,
		};

		return adherences;
	};

	/**
	 * sorts the supplied blocsk and routes by adherence
	 *
	 * @param adherences - blocks by adherence
	 * @returns sorted blocks by adherence
	 */
	private sortBlockListByAdherence = (adherences: BlocksByAdherence): BlockRoutes => {
		adherences.veryEarly = adherences.veryEarly.sort(this.sortEarly);
		adherences.veryLate = adherences.veryLate.sort(this.sortLate);
		adherences.early = adherences.early.sort(this.sortEarly);
		adherences.late = adherences.late.sort(this.sortLate);
		adherences.earlyOnTime = adherences.earlyOnTime.sort(this.sortEarly);
		adherences.lateOnTime = adherences.lateOnTime.sort(this.sortLate);

		const sortedBlocksByAdherence: BlockRoutes = [
			...adherences.veryEarly.sort(this.sortEarly),
			...adherences.veryLate.sort(this.sortLate),
			...adherences.early.sort(this.sortEarly),
			...adherences.late.sort(this.sortLate),
			...adherences.earlyOnTime.sort(this.sortEarly),
			...adherences.lateOnTime.sort(this.sortLate),
			...adherences.onTime,
			...adherences.unassigned,
		];

		return sortedBlocksByAdherence;
	};

	/**
	 * filters out the unassigned blocks
	 *
	 * @param blockRoutes - blocks routes
	 * @returns unassigned block routes
	 */
	private filterBlockListByUnassigned = (blockRoutes: BlockRoutes): BlockRoutes => {
		return blockRoutes.filter((blockRoute: BlockRoute) => blockRoute.adherence === null);
	};

	/**
	 * returns blocks and routes dependent upon widget configuration
	 *
	 * limit our list based on our configured size - as per previous implmenetation
	 * this only affects the list (i.e all values are taken in to account for the graph).
	 * Makes sense but if the list size isn't thougt of as a filter and instead typical paging
	 *
	 * @param blockRoutes - blocks routes
	 * @returns blocks route either limited or all dependent upon configuration
	 */
	private setBlockListSize = (blockRoutes: BlockRoutes): BlockRoutes => {
		// results have been sorted client side so take the top x rows based on our list size
		if (this.data.config.listSize.value !== 'All') {
			return blockRoutes.slice(0, this.data.config.listSize.value);
		} else {
			return blockRoutes;
		}
	};

	/**
	 * derives widget display values for supplied blocks and routes
	 *
	 * @param blocks - blocks and routes
	 * @returns blocks and routes with display values
	 */
	private setVehicleBlockListDisplayVals = (blocks: BlockRoutes): DisplayBlockRoutes => {
		const displayBlocks: DisplayBlockRoutes = [];

		blocks.forEach((block: BlockRoute) => {
			let adherenceTime: string = null;
			let adherenceClass: string = null;
			let statusText: string = null;
			let vehicleIdDisplay: string = null;

			let vehAdh: VehicleAdherenceDisplay = {
				time: '--',
				class: '',
			};

			let adherenceType: VehicleAdherenceType = VehicleAdherenceType.unknown;

			if (block.adherence !== null) {
				adherenceType = this.mapVehiclesService.getVehicleAdherenceType(
					block.block_id,
					block.adherence,
					this.data.config.selectedAgency.authority_id
				);

				vehAdh = this.mapVehiclesService.getAdherenceDisplay(block.adherence, adherenceType);
			}

			adherenceTime = vehAdh.time;
			adherenceClass = vehAdh.class;

			vehicleIdDisplay = block.vehicle_id;

			if (block.vehicle_id === null) {
				statusText = this.translations['T_DASH.DB_UNASSIGNED'];
				vehicleIdDisplay = '--';
			} else if (block.adherence !== null) {
				switch (adherenceType) {
					case VehicleAdherenceType.veryLate:
						statusText = this.translations['T_DASH.DB_VERY_LATE'];
						break;
					case VehicleAdherenceType.late:
						statusText = this.translations['T_DASH.DB_LATE'];
						break;
					case VehicleAdherenceType.onTime:
						statusText = this.translations['T_DASH.DB_ON_TIME'];
						break;
					case VehicleAdherenceType.early:
						statusText = this.translations['T_DASH.DB_EARLY'];
						break;
					case VehicleAdherenceType.veryEarly:
						statusText = this.translations['T_DASH.DB_VERY_EARLY'];
						break;
				}
			} else {
				statusText = this.translations['T_DASH.DB_UNKNOWN'];
			}

			block.route_color = this.colorUtilityService.getColor(block.route_color);
			block.route_text_color = this.colorUtilityService.getColor(block.route_text_color);

			const displayBlock: DisplayBlockRoute = {
				...block,
				adherenceTime,
				adherenceClass,
				statusText,
				vehicleIdDisplay,
			};

			displayBlocks.push(displayBlock);
		});

		return displayBlocks;
	};

	/**
	 * comparitor to sort adherence values by late order
	 *
	 * @param a - adherence a
	 * @param b - adherence b
	 * @returns sorted adherence value (late order)
	 */
	private sortLate = (a: any, b: any): number => {
		return b.adherence - a.adherence;
	};

	/**
	 * comparitor to sort adherence values by early order
	 *
	 * @param a - adherence a
	 * @param b - adherence b
	 * @returns sorted adherence value (early order)
	 */
	private sortEarly = (a: any, b: any): number => {
		return a.adherence - b.adherence;
	};

	/**
	 * sets up subscriptions - is interested in the widget being reloaded
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.setup();
				this.getData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.listInterval) {
			clearInterval(this.listInterval);
		}

		this.reloadWidget$Subscription?.unsubscribe();
	};
}

<form *ngIf="userDetailForm" [formGroup]="userDetailForm">
	<div class="col-xs-12" class="divide"></div>
	<div class="form-group" [ngClass]="{ 'col-xs-9': !isCreate, 'col-xs-12': isCreate }">
		<field-label [name]="'username'" [label]="'T_CORE.USERNAME' | translate" [required]="true"></field-label>
		<div [ngClass]="{ 'has-error': userDetailForm.controls.username.touched && userDetailForm.controls.username.errors }">
			<!-- name="new-username" essentially disables the chrome autofill-->
			<input
				name="new-username"
				formControlName="username"
				type="text"
				class="form-control"
				id="username"
				data-test="user-edit.username"
				title="{{ 'T_CORE.USERNAME' | translate }}"
				[placeholder]="isCreate ? getTranslation('T_CORE.USERNAME') : ''"
				(input)="validateUsername()" />
			<input-validation [control]="userDetailForm.controls.username" [errorOnTouch]="true"> </input-validation>
		</div>
	</div>

	<div *ngIf="!isCreate" class="form-group col-xs-3">
		<field-label [name]="'created'" [label]="'T_CORE.CREATED' | translate" [required]="false"></field-label>
		<div disabled class="form-control" data-test="user-edit.created">{{ user.created | date: 'short' }}</div>
	</div>

	<div class="form-group" [ngClass]="{ 'col-xs-9': !isCreate, 'col-xs-12': isCreate }">
		<field-label [name]="'realName'" [label]="'T_CORE.NAME' | translate" [required]="true"></field-label>
		<input
			formControlName="realName"
			type="text"
			id="realName"
			class="form-control"
			data-test="user-edit.realname"
			[placeholder]="isCreate ? getTranslation('T_CORE.NAME') : ''"
			title="{{ 'T_CORE.NAME' | translate }}"
			(input)="validate()" />
		<input-validation
			[control]="userDetailForm.controls.realName"
			[errorOnTouch]="true"
			[messageTextOverride]="realNameMessageTextOverride">
		</input-validation>
	</div>

	<div *ngIf="!isCreate" class="form-group col-xs-3">
		<field-label [name]="'last_login'" [label]="'T_CORE.LAST_LOGIN' | translate" [required]="false"></field-label>
		<div disabled class="form-control" data-test="user-edit.last-login">{{ user.last_login | date: 'short' }}</div>
	</div>

	<div class="form-group" [ngClass]="{ 'col-xs-9': !isCreate, 'col-xs-12': isCreate }">
		<field-label [name]="'phone'" [label]="'T_USER.WORK_PHONE' | translate" [required]="false"></field-label>
		<input
			formControlName="phone"
			type="text"
			id="phone"
			class="form-control"
			data-test="user-edit.work-phone"
			[placeholder]="isCreate ? getTranslation('T_CORE.PHONE') : ''"
			title="{{ 'T_CORE.PHONE' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.phone" [extraMessageText]="phoneExtraMessageText"> </input-validation>
	</div>

	<div *ngIf="!isCreate" class="form-group col-xs-3">
		<field-label [name]="'last_password_change'" [label]="'T_CORE.PASSWORD_CHANGED' | translate" [required]="false"></field-label>
		<div disabled class="form-control" data-test="user-edit.password-changed">{{ user.password_last_changed_date | date: 'short' }}</div>
	</div>

	<div class="form-group" [ngClass]="{ 'col-xs-9': !isCreate, 'col-xs-12': isCreate }">
		<field-label [name]="'email'" [label]="'T_USER.WORK_EMAIL' | translate" [required]="true"></field-label>
		<input
			formControlName="email"
			type="text"
			id="email"
			class="form-control"
			data-test="user-edit.email"
			[placeholder]="isCreate ? getTranslation('T_USER.WORK_EMAIL') : ''"
			title="{{ 'T_USER.WORK_EMAIL' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.email" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div *ngIf="!isCreate" class="form-group col-xs-3">
		<field-label [name]="'date_locked'" [label]="'T_USER.LOCKED_DATE' | translate" [required]="false"></field-label>
		<div disabled class="form-control" data-test="user-edit.locked-date">{{ user.lock_date | date: 'short' }}</div>
	</div>

	<div class="form-group" [ngClass]="{ 'col-xs-9': !isCreate, 'col-xs-12': isCreate }">
		<field-label [name]="'smsNumber'" [label]="'T_USER.MOBILE_PHONE' | translate" [required]="false"></field-label>
		<input
			formControlName="smsNumber"
			type="text"
			id="smsNumber"
			class="form-control"
			data-test="user-edit.mobile-phone"
			[placeholder]="isCreate ? getTranslation('T_USER.MOBILE_PHONE') : ''"
			title="{{ 'T_USER.MOBILE_PHONE' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.smsNumber" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div *ngIf="!isCreate" class="form-group col-xs-3">
		<field-label [name]="'date_deactivated'" [label]="'T_USER.DEACTIVATED_DATE' | translate" [required]="false"></field-label>
		<div disabled class="form-control" data-test="user-edit.deactivated">{{ user.deactivated_date | date: 'short' }}</div>
	</div>

	<div class="form-group" [ngClass]="{ 'col-xs-9': !isCreate, 'col-xs-12': isCreate }">
		<field-label [name]="'address'" [label]="'T_USER.WORK_ADDRESS' | translate" [required]="false"></field-label>
		<input
			formControlName="address"
			type="text"
			id="address"
			class="form-control"
			data-test="user-edit.address-1"
			[placeholder]="isCreate ? getTranslation('T_USER.WORK_ADDRESS') : ''"
			title="{{ 'T_USER.WORK_ADDRESS' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.address" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div *ngIf="!isCreate" class="form-group col-xs-3">
		<field-label [name]="'status'" [label]="'T_CORE.STATUS' | translate" [required]="false"></field-label>
		<div disabled class="form-control" data-test="user-edit.status">{{ user.status }}</div>
	</div>

	<div class="form-group col-xs-12">
		<field-label [name]="'address2'" [label]="'T_USER.WORK_ADDRESS_2' | translate" [required]="false"></field-label>
		<input
			formControlName="address2"
			type="text"
			id="address"
			class="form-control"
			data-test="user-edit.address-2"
			[placeholder]="isCreate ? getTranslation('T_USER.WORK_ADDRESS_2') : ''"
			title="{{ 'T_USER.WORK_ADDRESS_2' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.address2" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div class="form-group col-xs-12">
		<field-label [name]="'city'" [label]="'T_CORE.CITY' | translate" [required]="false"></field-label>
		<input
			formControlName="city"
			type="text"
			id="city"
			class="form-control"
			data-test="user-edit.city"
			[placeholder]="isCreate ? getTranslation('T_CORE.CITY') : ''"
			title="{{ 'T_CORE.CITY' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.city" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div class="form-group col-xs-12">
		<field-label [name]="'state'" [label]="'T_CORE.STATE_PROVINCE_REGION' | translate" [required]="false"></field-label>
		<input
			formControlName="state"
			type="text"
			id="state"
			class="form-control"
			data-test="user-edit.state"
			[placeholder]="isCreate ? getTranslation('T_CORE.STATE_PROVINCE_REGION') : ''"
			title="{{ 'T_CORE.STATE_PROVINCE_REGION' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.state" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div class="form-group col-xs-12">
		<field-label [name]="'postalCode'" [label]="'T_CORE.POSTAL_CODE' | translate" [required]="false"></field-label>
		<input
			formControlName="postalCode"
			type="text"
			id="postalCode"
			class="form-control"
			data-test="user-edit.postcode"
			[placeholder]="isCreate ? getTranslation('T_CORE.POSTAL_CODE') : ''"
			title="{{ 'T_CORE.' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.postalCode" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div class="form-group col-xs-12">
		<field-label [name]="'country'" [label]="'T_USER.COUNTRY' | translate" [required]="false"></field-label>
		<input
			formControlName="country"
			type="text"
			id="country"
			class="form-control"
			data-test="user-edit.country"
			[placeholder]="isCreate ? getTranslation('T_USER.COUNTRY') : ''"
			title="{{ 'T_USER.COUNTRY' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.country" [extraMessageText]="countryExtraMessageText"> </input-validation>
	</div>

	<div class="form-group col-xs-12">
		<field-label [name]="'orgName'" [label]="'T_USER.COMPANY_NAME' | translate" [required]="false"></field-label>
		<input
			formControlName="orgName"
			type="text"
			id="orgName"
			class="form-control"
			data-test="user-edit.company-name"
			[placeholder]="isCreate ? getTranslation('T_USER.COMPANY_NAME') : ''"
			title="{{ 'T_USER.COMPANY_NAME' | translate }}"
			(input)="validate()" />
		<input-validation [control]="userDetailForm.controls.orgName" [errorOnTouch]="true" [extraMessageText]="defaultExtraMessageText">
		</input-validation>
	</div>

	<div *ngIf="userRolesDisplay?.master.length > 0 || userRolesDisplay?.roles.length > 0">
		<div class="col-xs-12">
			<field-label [name]="'roleSelect'" [label]="'T_CORE.ROLES' | translate" [required]="true"></field-label>
		</div>

		<div class="row nb-view-table">
			<table class="table th-align-right">
				<tbody>
					<tr *ngFor="let role of userRolesDisplay?.master">
						<th scope="row" class="col-lg-3 col-md-4 col-sm-4 col-xs-4 nb-view-table-th role-header">
							<span class="nb-label">{{ 'T_CORE.NEXTBUS' | translate }}</span>
						</th>
						<td class="nb-text">{{ role.roleName }}</td>
					</tr>
					<tr *ngFor="let role of userRolesDisplay?.roles">
						<th scope="row" class="col-lg-3 col-md-4 col-sm-4 col-xs-4 nb-view-table-th role-header">
							<span class="nb-label">{{ role.displayName }}</span>
						</th>
						<td class="nb-text">{{ role.roleName }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div *ngIf="userRolesDisplay?.master.length === 0 && userRolesDisplay?.roles.length === 0">
		<div class="col-xs-12 form-group">
			<field-label [name]="'NoRoles'" [label]="'T_CORE.ROLES' | translate" [required]="true"></field-label>
			<input type="text" class="form-control" value="None" [disabled]="true" />
		</div>
	</div>
</form>

<ng-container *ngIf="templatesByCategory">
	<div class="nb-report-selector">
		<div class="header-container">
			<!-- only have buttons if we can select templates to click on -->
			<div *ngIf="checkboxesEnabled">
				<button (click)="viewTemplate()" [disabled]="!viewButtonEnabled" title="{{ viewButtonTooltip }}" class="btn btn-action">
					<i class="nb-icons nb-information-solid"></i>{{ 'T_REPORT.VIEW' | translate }}
				</button>

				<button (click)="editTemplate()" [disabled]="!editButtonEnabled" title="{{ editButtonTooltip }}" class="btn btn-action">
					<i class="nb-icons nb-edit"></i>{{ 'T_REPORT.EDIT' | translate }}
				</button>

				<button (click)="runReports()" [disabled]="!runButtonEnabled" title="{{ runButtonTooltip }}" class="btn btn-action">
					<i class="nb-iconsb-success-solid"></i>{{ 'T_REPORT.RUN' | translate }}
				</button>

				<button (click)="deleteTemplates()" [disabled]="!deleteButtonEnabled" title="{{ deleteButtonTooltip }}" class="btn btn-action">
					<i class="nb-icons nb-delete"></i>{{ 'T_REPORT.DELETE' | translate }}
				</button>
			</div>

			<div class="float-right">
				<search-filter (search)="search($event)"></search-filter>
			</div>
		</div>
		<hr />

		<ng-container *ngIf="totalTemplates > 0">
			<div *ngFor="let category of templatesByCategory | keys" class="nb-dataview-group">
				<ng-container *ngIf="templatesByCategory[category].length > 0">
					<div class="nb-dataview-group-title">
						<h3 class="nb-section-title">{{ category | uppercase }}</h3>
					</div>
					<div class="nb-dataview nb-dataview-fullwidth nb-default-report-template-dataview group-by-{{ category }}">
						<ng-container *ngFor="let reportItem of templatesByCategory[category]">
							<div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 nb-dataview-row">
								<div class="nb-dataview-inner">
									<div *ngIf="checkboxesEnabled" class="nb-form-control-vertical">
										<div class="nb-checkbox">
											<label>
												<input
													*ngIf="reportItem.enabled"
													type="checkbox"
													[checked]="templateChecked(reportItem)"
													(change)="checkTemplate($event, reportItem)" />
												<div class="checkbox-label"></div>
											</label>
										</div>
									</div>
									<div class="nb-dataview-cell">
										<div class="nb-dataview-cell-contents">
											<div
												(click)="selectTemplate(reportItem)"
												class="nb-dataview-cell-contents-inner"
												[attr.title]="!reportItem.enabled ? disabledTooltip : ''"
												[ngClass]="{ 'saved-reports-disabled': !reportItem.enabled, 'default-reports-disabled': !reportItem.enabled }">
												<div class="report-title-card">
													<div class="report-card-header">
														<div class="report-code">{{ reportItem.code }}</div>
														<div class="report-type">{{ reportItem.outputType }}</div>
													</div>
													<div class="report-content">
														<div class="report-title">{{ reportItem.name }}</div>
														<span class="nb-text break-text">{{ reportItem.description }}</span>
													</div>
													<div *ngIf="reportItem.updatedAt" class="report-footer">
														<div class="report-timestamp">
															{{ reportItem.updatedAt | date: 'MMM d, y hh:mm a' }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="totalTemplates === 0">
			<div class="nb-padding-all-lg no-data-sm">
				{{ 'T_CORE.NO_RESULTS_AVAILABLE' | translate }}
			</div>
		</ng-container>

		<pagination-toolbar
			*ngIf="this.paginationEnabled"
			[paginationSettings]="paginationSettings"
			[pagination]="pagination"
			(setPageSizeEvent)="setPageSize()"
			(pageChangedEvent)="pageChanged()">
		</pagination-toolbar>
	</div>
</ng-container>

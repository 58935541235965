<!--
* COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
*
* Information Contained Herein is Proprietary and Confidential.
* The document is the property of "CUBIC" and may not be disclosed
* distributed, or reproduced  without the express written permission of
* "CUBIC".
-->

<div id="wrapper">
	<div *ngIf="initialized">
		<div>
			<navigation id="navigation"></navigation>
			<the-header></the-header>
			<div id="page-wrapper" class="white-bg">
				<div #fullscreen class="app-background">
					<!-- Main view -->
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';

import { ReportViewerBaseComponent } from '../../../report-viewer-base.component';

import { DataService } from '@cubicNx/libs/utils';
import { ReportsGenerationService } from '../../../../services/reports-generation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportVehicle, AdherenceReportSummaryDetail, InteractiveReportAdherenceDetail } from '../../../../types/api-types';
import { ReportTemplateSummaryContent, AdherenceByRouteDirectionStop } from '../../../../types/types';
import { RoutePillData } from '@cubicNx/libs/utils';

@Component({
	selector: 'ad300-adherence-by-vehicle-summary',
	templateUrl: './adherence-by-vehicle-summary.component.html',
	styleUrls: ['./adherence-by-vehicle-summary.component.scss'],
})
export class AdherenceByVehicleSummaryComponent extends ReportViewerBaseComponent {
	public summaryData: AdherenceReportSummaryDetail = null;
	public templateSummaryContent: ReportTemplateSummaryContent = null;

	constructor(
		public dataService: DataService,
		private reportsGenerationService: ReportsGenerationService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);

		this.summaryData = this.dataService['summaryData' as keyof DataService];
		this.templateSummaryContent = this.dataService['templateSummaryContent' as keyof DataService];
	}

	/**
	 * generates a vehicle report instance from supplied adherence data
	 *
	 * @param adherenceData - the adherence data
	 */
	public generateVehicleReport = (adherenceData: InteractiveReportAdherenceDetail): void => {
		const vehicle: ReportVehicle = {
			authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
			vehicle_id: adherenceData.vehicle_id,
			vehicle_name: adherenceData.vehicle_name,
		};

		this.reportsGenerationService.generateVehicleReport(AdherenceByRouteDirectionStop, this.templateSummaryContent.template, vehicle);
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param adherenceData - the adherence data
	 * @returns route pill
	 */
	public determineRoutePillData = (adherenceData: InteractiveReportAdherenceDetail): RoutePillData => {
		return {
			routeShortName: adherenceData.route_short_name,
			routeLongName: adherenceData.route_long_name,
			routeId: adherenceData.route_id,
			routeColor: this.colorUtilityService.getColor(adherenceData.route_color),
			routeTextColor: this.colorUtilityService.getColor(adherenceData.route_text_color),
		};
	};
}

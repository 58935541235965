import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { ConfirmRiderCancellationAlertComponent } from './confirm/confirm-rider-cancellation-alert.component';

import { BlockModalService } from './services/block-modal.service';
import { BlocksApiService } from './services/block-api.service';
import { BlocksDataService } from './services/block-data.service';
import { BlockUtilsService } from './services/block-utils.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
	],
	declarations: [ConfirmRiderCancellationAlertComponent],
	providers: [BlocksDataService, BlockUtilsService, BlocksApiService, BlockModalService],
	exports: [],
})
export class BlocksModule {}

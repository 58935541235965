import { FormControl } from '@angular/forms';

export class TerminalDepartureConfigForm {
	route: FormControl = new FormControl();
	stop: FormControl = new FormControl();
	max_extension_seconds: FormControl = new FormControl();
	min_layover_seconds: FormControl = new FormControl();
	min_dist_nav_service: FormControl = new FormControl();
	default_deadhead_seconds: FormControl = new FormControl();
	apply_sched_layovers: FormControl = new FormControl();
	detect_departure_at_upcoming_stop: FormControl = new FormControl();
	detect_departure_stop_sequence: FormControl = new FormControl();
	start_nav_service_throttle: FormControl = new FormControl();
	end_nav_service_throttle: FormControl = new FormControl();
	nav_service_call_frequency: FormControl = new FormControl();
	arrival_stop_radius: FormControl = new FormControl();
	override_arrival_stop_radius: FormControl = new FormControl();
	override_apply_sched_layovers: FormControl = new FormControl();
	override_detect_departure_at_upcoming_stop: FormControl = new FormControl();
	override_min_layover_seconds: FormControl = new FormControl();
	override_max_departure_extension: FormControl = new FormControl();
	override_detect_departure_stop_sequence: FormControl = new FormControl();
	override_default_deadhead_seconds: FormControl = new FormControl();
	override_min_dist_nav_service: FormControl = new FormControl();
	override_start_end_nav_service_throttle: FormControl = new FormControl();
	override_nav_service_call_frequency: FormControl = new FormControl();

	constructor(
		routeId?: string,
		stopId?: string,
		maxExtensionSeconds?: number,
		minLayoverSeconds?: number,
		minDistNavService?: number,
		defaultDeadheadSeconds?: number,
		applySchedLayovers?: boolean,
		detect_departure_at_upcoming_stop?: boolean,
		detect_departure_stop_sequence?: number,
		start_nav_service_throttle?: string,
		end_nav_service_throttle?: string,
		nav_service_call_frequency?: number,
		arrival_stop_radius?: number,
		default_maxExtensionSeconds?: number,
		default_minLayoverSeconds?: number,
		default_minDistNavService?: number,
		default_defaultDeadheadSeconds?: number,
		default_applySchedLayovers?: boolean,
		default_detect_departure_at_upcoming_stop?: boolean,
		default_detect_departure_stop_sequence?: number,
		default_start_nav_service_throttle?: string,
		default_end_nav_service_throttle?: string,
		default_nav_service_call_frequency?: number,
		default_arrival_stop_radius?: number
	) {
		this.route.setValue(routeId);
		this.stop.setValue(stopId);

		if (maxExtensionSeconds || maxExtensionSeconds === 0) {
			this.max_extension_seconds.setValue(maxExtensionSeconds);
			this.override_max_departure_extension.setValue(true);
		} else {
			this.max_extension_seconds.setValue(default_maxExtensionSeconds);
			this.override_max_departure_extension.setValue(false);
		}

		if (minLayoverSeconds || minLayoverSeconds === 0) {
			this.min_layover_seconds.setValue(minLayoverSeconds);
			this.override_min_layover_seconds.setValue(true);
		} else {
			this.min_layover_seconds.setValue(default_minLayoverSeconds);
			this.override_min_layover_seconds.setValue(false);
		}

		if (minDistNavService || minDistNavService === 0) {
			this.min_dist_nav_service.setValue(minDistNavService);
			this.override_min_dist_nav_service.setValue(true);
		} else {
			this.min_dist_nav_service.setValue(default_minDistNavService);
			this.override_min_dist_nav_service.setValue(false);
		}

		if (defaultDeadheadSeconds || defaultDeadheadSeconds === 0) {
			this.default_deadhead_seconds.setValue(defaultDeadheadSeconds);
			this.override_default_deadhead_seconds.setValue(true);
		} else {
			this.default_deadhead_seconds.setValue(default_defaultDeadheadSeconds);
			this.override_default_deadhead_seconds.setValue(false);
		}

		if (applySchedLayovers || applySchedLayovers === false) {
			this.apply_sched_layovers.setValue(applySchedLayovers);
			this.override_apply_sched_layovers.setValue(true);
		} else {
			this.apply_sched_layovers.setValue(default_applySchedLayovers);
			this.override_apply_sched_layovers.setValue(false);
		}

		if (detect_departure_stop_sequence) {
			this.detect_departure_stop_sequence.setValue(detect_departure_stop_sequence);
			this.override_detect_departure_stop_sequence.setValue(true);
		} else {
			this.detect_departure_stop_sequence.setValue(default_detect_departure_stop_sequence);
			this.override_detect_departure_stop_sequence.setValue(false);
		}

		if (detect_departure_at_upcoming_stop || detect_departure_at_upcoming_stop === false) {
			this.detect_departure_at_upcoming_stop.setValue(detect_departure_at_upcoming_stop);
			this.override_detect_departure_at_upcoming_stop.setValue(true);
		} else {
			this.detect_departure_at_upcoming_stop.setValue(default_detect_departure_at_upcoming_stop);
			this.override_detect_departure_at_upcoming_stop.setValue(false);
		}

		if (start_nav_service_throttle && start_nav_service_throttle !== '00:00') {
			this.start_nav_service_throttle.setValue(start_nav_service_throttle);
			this.override_start_end_nav_service_throttle.setValue(true);
		} else {
			this.start_nav_service_throttle.setValue(default_start_nav_service_throttle);
			this.override_start_end_nav_service_throttle.setValue(false);
		}

		if (end_nav_service_throttle && end_nav_service_throttle !== '00:00') {
			this.end_nav_service_throttle.setValue(end_nav_service_throttle);
			this.override_start_end_nav_service_throttle.setValue(true);
		} else {
			this.end_nav_service_throttle.setValue(default_end_nav_service_throttle);
			this.override_start_end_nav_service_throttle.setValue(false);
		}

		if (nav_service_call_frequency || nav_service_call_frequency === 0) {
			this.nav_service_call_frequency.setValue(nav_service_call_frequency);
			this.override_nav_service_call_frequency.setValue(true);
		} else {
			this.nav_service_call_frequency.setValue(default_nav_service_call_frequency);
			this.override_nav_service_call_frequency.setValue(false);
		}

		if (arrival_stop_radius || arrival_stop_radius === 0) {
			this.arrival_stop_radius.setValue(arrival_stop_radius);
			this.override_arrival_stop_radius.setValue(true);
		} else {
			this.arrival_stop_radius.setValue(default_arrival_stop_radius);
			this.override_arrival_stop_radius.setValue(false);
		}
	}
}

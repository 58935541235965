/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { LoginDataService } from '../services/login/login-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { requiredValidation, ValidationText } from '@cubicNx/libs/utils';
import { UserLoginRequest } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends TranslateBaseComponent implements OnInit {
	public loading: boolean = true;
	public error: string = null;

	public loginForm: FormGroup = null;
	public currentUserId: string = null;
	public user: any = null;

	public usernameRequiredTextOverride: ValidationText = {
		[requiredValidation]: 'T_CORE.USERNAME_REQUIRED',
	};

	public passwordRequiredTextOverride: ValidationText = {
		[requiredValidation]: 'T_USER.PASSWORD_REQUIRED',
	};

	constructor(
		private loginDataService: LoginDataService,
		private formBuilder: FormBuilder,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initailzes the login form and sets up form validators
	 */
	public async ngOnInit(): Promise<void> {
		this.loginForm = this.formBuilder.group(
			{
				username: ['', Validators.required],
				password: ['', Validators.required],
			},
			{}
		);

		this.loading = false;
	}

	/**
	 * handles the user login click
	 */
	public logIn = async (): Promise<void> => {
		this.error = null;

		if (this.loginForm.valid) {
			this.loading = true;

			const userLoginRequest: UserLoginRequest = {
				username: this.loginForm.value.username,
				password: this.loginForm.value.password,
			};

			const result: ResultContent = await this.loginDataService.logIn(userLoginRequest);

			if (!result.success) {
				if (result.resultData?.passwordExpired) {
					this.router.navigate(['/reset_password']);
				} else {
					this.error = result.resultData.message;
				}

				this.loading = false;
			}
		} else {
			this.loginForm.markAllAsTouched();
		}
	};

	/**
	 * handles the reset password click
	 */
	public resetPassword = (): void => {
		if (!this.loading) {
			this.router.navigate(['/reset_password']);
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { UsersAdminDataService } from '../../services/users-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { RolesSummary } from '../../types/api-types';
import { SelectedUserFilters, UserFiltersUpdate } from '../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'user-admin-list-filter',
	templateUrl: './user-admin-list-filter.component.html',
	styleUrls: ['./user-admin-list-filter.component.scss'],
})
export class UserAdminListFilterComponent extends TranslateBaseComponent implements OnInit {
	public agencySelected: boolean = true;

	public agencies: any[] = [];
	public statuses: any[] = [];
	public roles: RolesSummary = [];

	public selectedAgencies: any[] = [];
	public selectedStatuses: any[] = [];
	public selectedRoles: any[] = [];

	public angencySelectSettings: MultiSelectSettings = {
		id_text: 'agency_nb_id',
		value_text: 'authority_name',
		placeholder: '',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public statusesSelectSettings: MultiSelectSettings = {
		id_text: 'code',
		value_text: 'name',
		placeholder: '',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public roleSettings: MultiSelectSettings = {
		id_text: 'nb_id',
		value_text: 'localized_name',
		placeholder: '',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	constructor(
		private agenciesDataService: AgenciesDataService,
		private userAdminDataService: UsersAdminDataService,
		private modalRef: MatDialogRef<UserAdminListFilterComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.cancel();
	}

	/**
	 * performs initialization tasks for the users list filter such as loading translations and other set up functionality
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations([
			'T_USER.USER_STATUS.NEW',
			'T_USER.USER_STATUS.ACTIVE',
			'T_USER.USER_STATUS.INACTIVE',
			'T_USER.USER_STATUS.PASSWORDEXPIRED',
			'T_USER.USER_STATUS.DEACTIVATED',
			'T_USER.USER_STATUS.LOCKED',
		]);

		this.initAgencies();
		this.initStatuses();
		await this.initRoles();

		// set any previously selected filters
		this.getInitialFilters();

		this.agencySelected = this.agencies.length === 1 ? true : this.selectedAgencies.length > 0;
	}

	/**
	 * sets the selected agency
	 */
	public agencyItemClicked = (): void => {
		this.agencySelected = this.selectedAgencies.length > 0;
	};

	/**
	 * saves the current user filter criteria for searching purposes
	 */
	public save = (): void => {
		const userFilters: SelectedUserFilters = {
			agencies: this.selectedAgencies,
			statuses: this.selectedStatuses,
			roles: this.selectedRoles,
		};

		const userFiltersUpdate: UserFiltersUpdate = {
			saved: true,
			selectedUserFilters: userFilters,
		};

		this.modalRef.close(userFiltersUpdate);
	};

	/**
	 * cancels the current user filter criteria that may have been setup
	 */
	public cancel = (): void => {
		const userFiltersUpdate: UserFiltersUpdate = {
			saved: false,
		};

		this.modalRef.close(userFiltersUpdate);
	};

	/**
	 * retrieves the current selected agency details
	 */
	private initAgencies = (): void => {
		this.agencies = this.agenciesDataService.getAgencies();
	};

	/**
	 * sets up the user statuses
	 */
	private initStatuses = (): void => {
		this.statuses = [
			{ code: 'new_user', name: this.translations['T_USER.USER_STATUS.NEW'] },
			{ code: 'active_user', name: this.translations['T_USER.USER_STATUS.ACTIVE'] },
			{ code: 'inactive_user', name: this.translations['T_USER.USER_STATUS.INACTIVE'] },
			{ code: 'password_expired', name: this.translations['T_USER.USER_STATUS.PASSWORDEXPIRED'] },
			{ code: 'deactivated', name: this.translations['T_USER.USER_STATUS.DEACTIVATED'] },
			{ code: 'account_locked', name: this.translations['T_USER.USER_STATUS.LOCKED'] },
		];
	};

	/**
	 * sets up the user roles for the authority
	 */
	private initRoles = async (): Promise<void> => {
		let rolesAll: RolesSummary = [];
		const rolesResponse: ResultContent = await this.userAdminDataService.getRoles();

		if (rolesResponse.success) {
			rolesAll = rolesResponse.resultData as RolesSummary;
			for (let i: number = 0; i < rolesAll.length; i++) {
				if (rolesAll[i].name === 'BASIC_USER') {
					rolesAll.splice(i, 1);
				}
			}

			if (this.agencies.length < 2) {
				for (let i: number = 0; i < rolesAll.length; i++) {
					if (rolesAll[i].name === 'UMO_ADMIN') {
						rolesAll.splice(i, 1);
					}
				}
			}

			this.roles = rolesAll;
		}
	};

	/**
	 * sets the initial selected filters
	 */
	private getInitialFilters = (): void => {
		if (this.data.selectedUserFilters) {
			this.selectedAgencies = this.data.selectedUserFilters.agencies;
			this.selectedStatuses = this.data.selectedUserFilters.statuses;
			this.selectedRoles = this.data.selectedUserFilters.roles;
		}
	};
}

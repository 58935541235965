<div [style.top]="tooltipPosition.y - 120 + 'px'" [style.left]="tooltipPosition.x + 'px'" class="block-tooltip-container">
	<div class="block-tooltip">
		<div class="block-id" data-test="block.tooltip.block_id">{{ 'T_MAP.MAP_BLOCK' | translate }} {{ displayBlock.blockIdText }}</div>
		<div class="block-info" data-test="block.tooltip.time">
			<div>{{ displayBlock.theDate }}</div>
			<div>{{ displayBlock.startTime }} - {{ displayBlock.endTime }}</div>
		</div>
		<div class="block-info">
			<route-pill [route]="blockRoute" data-test="block.tooltip.route"></route-pill>
			<div *ngIf="displayBlock.vehicleIdentifiers.length > 0" class="label label-route" data-test="block.tooltip.vehicle">
				{{ displayBlock.vehicleIdentifiers }}
			</div>
			<div *ngIf="displayBlock.vehicles.length === 0" class="label label-route unassigned" data-test="block.tooltip.status">Unassigned</div>
		</div>
	</div>
	<div class="block-tooltip-arrow-outline"></div>
	<div class="block-tooltip-arrow"></div>
</div>

export enum ExportColumnType {
	text,
	date,
}

export interface ExportData {
	fileName: string;
	headers: Array<string>;
	data: Array<ExportObject>;
}

export interface ExportObject {
	properties: Array<ExportProperty>;
}

export interface ExportProperty {
	value: any;
	type: ExportColumnType;
	dateFormatter?: string;
}

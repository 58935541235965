/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnInit } from '@angular/core';

import { ReportViewerSortableSubListBaseComponent } from '../../../report-viewer-sortable-sublist-base.component';

import { ReportsConfigService } from '../../../../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'hw200-headway-detail-sub-list',
	templateUrl: './headway-detail-sub-list.component.html',
	styleUrls: ['./headway-detail-sub-list.component.scss'],
})
export class HeadwayDetailSubListComponent extends ReportViewerSortableSubListBaseComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;

	constructor(
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * performs initialization tasks for the headway detail sub list view (HW200)
	 */
	public ngOnInit(): void {
		this.init();
	}
}

<div class="block-text-container" [ngStyle]="{ left: displayBlock.barLeftPerc }">
	<div
		class="block-bar block-bar-text truncate"
		(mouseenter)="openTooltip($event)"
		(mouseleave)="closeTooltip()"
		(click)="showVehicleView(displayBlock)"
		[ngStyle]="getBlockBarStyle(displayBlock)"
		data-test="block.row.text">
		<i class="ng-icon ng-information" *ngIf="displayBlock.unassigned"></i>
		{{ displayBlock.routeLongName || displayBlock.routeId }} ({{ displayBlock.text }})
	</div>
</div>

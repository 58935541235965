/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, ViewChild } from '@angular/core';

import { VehicleEventDetailsComponent } from '../../../../features/vehicle-events/details/vehicle-event-details.component';

/* simple comonent that wraps the vehicle-event-details component in the same manner as the widget version does so
   they can share the common component. This component sets up this way (in this wrapper) as the widget version does
   as that version must adhere to the widget interface which has added complexity due to sharing functionality with
   other widgets
*/
@Component({
	selector: 'vehicle-event-details-header',
	templateUrl: './vehicle-event-details-header.component.html',
	styleUrls: ['./vehicle-event-details-header.component.scss'],
})
export class VehicleEventDetailsHeaderComponent {
	@ViewChild(VehicleEventDetailsComponent) vehicleEventsDetails: VehicleEventDetailsComponent;

	@Input() settings: any;

	public loaded: boolean = false;
	public hasResults: boolean = false;
	public success: boolean = false;
	public firstTime: boolean = true;

	/**
	 * initilizes the vehicle events details header component
	 *
	 * delegates to the wrapped vehicle event details component
	 */
	public init = async (): Promise<void> => {
		await this.vehicleEventsDetails.init();
	};

	/**
	 * delegates the call to the wrapped vehicle event details component to retrieve the vehicle events
	 */
	public getVehicleEvents = async (): Promise<void> => {
		await this.vehicleEventsDetails.getVehicleEvents();
	};

	/**
	 * delegates to the wrapped vehicle events details component to reset the current view
	 */
	public resetCurrentPage = async (): Promise<void> => {
		await this.vehicleEventsDetails.resetCurrentPage();
	};
}

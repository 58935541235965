import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MapEventsService } from './map-events.service';
import { MapOptionsService } from './map-options.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';
import { MapStateService } from './map-state.service';
import { MapPollingService } from './map-polling.service';

import { SelectedAgency } from '../../../support-features/agencies/types/api-types';

import {
	NavigateBlockDetail,
	NavigationPage,
	NavigateRouteDetail,
	NavigateStopDetail,
	NavigateVehicleDetail,
	VehicleReassignBlocksDetail,
	NavigateVehicleReassignDetail,
	VehicleDetailsActiveTab,
} from '../types/types';

import { EntityType, PageType } from '../../../utils/components/breadcrumbs/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapNavigationService {
	constructor(
		private agenciesDataService: AgenciesDataService,
		private mapOptionsService: MapOptionsService,
		private mapStateService: MapStateService,
		private mapEventsService: MapEventsService,
		private mapPollingService: MapPollingService,
		private agenciesEventsService: AgenciesEventsService,
		private router: Router
	) {}

	/**
	 * navigate to the vehicle list page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 */
	public navigateToVehicleList = async (authorityId: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const navigationPage: NavigationPage = {
			entityType: EntityType.vehicle,
			pageType: PageType.list,
			entity: null,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the stops list page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 */
	public navigateToStopList = async (authorityId: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const navigationPage: NavigationPage = {
			entityType: EntityType.stop,
			pageType: PageType.list,
			entity: null,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the routes list page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 */
	public navigateToRouteList = async (authorityId: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const navigationPage: NavigationPage = {
			entityType: EntityType.route,
			pageType: PageType.list,
			entity: null,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the blocks list page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 */
	public navigateToBlockList = async (authorityId: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const navigationPage: NavigationPage = {
			entityType: EntityType.block,
			pageType: PageType.list,
			entity: null,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the vehicle details page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 * @param vehicleId - the vehicle to display
	 * @param vehicleDetailsActiveTab - the tab to select
	 * @param replaceHistoryItem - whether to replace the history item rather than add to the stack
	 */
	public navigateToVehicleDetails = async (
		authorityId: string,
		vehicleId: string,
		vehicleDetailsActiveTab: VehicleDetailsActiveTab,
		replaceHistoryItem = false
	): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		// we are already showing the map - just navigate to the vehicle details page as usual
		const vehicleDetail: NavigateVehicleDetail = {
			vehicleId,
			activeTab: vehicleDetailsActiveTab,
			replaceHistoryItem,
		};

		const navigationPage: NavigationPage = {
			entityType: EntityType.vehicle,
			pageType: PageType.details,
			entity: vehicleDetail,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the stop details page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 * @param stopCode - the stop to naigate to
	 */
	public navigateToStopDetails = async (authorityId: string, stopCode: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const stopDetail: NavigateStopDetail = {
			stopCode,
		};

		const navigationPage: NavigationPage = {
			entityType: EntityType.stop,
			pageType: PageType.details,
			entity: stopDetail,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the route details page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 * @param routeId - the route we are navigating to
	 */
	public navigateToRouteDetails = async (authorityId: string, routeId: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const routeDetail: NavigateRouteDetail = {
			routeId,
		};

		const navigationPage: NavigationPage = {
			entityType: EntityType.route,
			pageType: PageType.details,
			entity: routeDetail,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the block details page. If the authority is different to the one we are navigating to then switch to the
	 * new authority/agency.
	 *
	 * @param authorityId - the authority we are navigating to
	 * @param blockId - the block we are navigating to
	 */
	public navigateToBlockDetails = async (authorityId: string, blockId: string): Promise<void> => {
		if (this.hasAgencyChanged(authorityId)) {
			await this.updateAgency(authorityId);
		}

		const blockDetail: NavigateBlockDetail = {
			blockId,
		};

		const navigationPage: NavigationPage = {
			entityType: EntityType.block,
			pageType: PageType.details,
			entity: blockDetail,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the vehicle reassign page
	 *
	 * @param vehicleId - the id of the vehicle we are reassigning
	 * @param vehicleRouteId - the current vehicle route id
	 * @param vehicleBlockId - the current vehicle block id
	 */
	public navigateToVehicleReassignDetails = (vehicleId: string, vehicleRouteId: string, vehicleBlockId: string): void => {
		const vehicleReassignDetail: NavigateVehicleReassignDetail = {
			vehicleId,
			vehicleRouteNbId: vehicleRouteId,
			vehicleBlockId,
		};

		const navigationPage: NavigationPage = {
			entityType: EntityType.vehicleReassign,
			pageType: PageType.details,
			entity: vehicleReassignDetail,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the vehicle reassign block page.
	 *
	 * @param vehicleId - the id of the vehicle we are reassigning
	 * @param vehicleRouteNbId - the current vehicle route id
	 * @param vehicleBlockId - the current vehicle block id
	 * @param reassignRouteId - the route id we are reassigning to
	 * @param reassignRouteNbId - the route nb id we are reassigning to
	 */
	public navigateToVehicleReassignBlockDetails = (
		vehicleId: string,
		vehicleRouteNbId: string,
		vehicleBlockId: string,
		reassignRouteId: string,
		reassignRouteNbId: string
	): any => {
		const vehicleReassignBlockDetail: VehicleReassignBlocksDetail = {
			vehicleId,
			vehicleRouteNbId,
			vehicleBlockId,
			reassignRouteId,
			reassignRouteNbId,
		};

		const navigationPage: NavigationPage = {
			entityType: EntityType.vehicleReassignBlock,
			pageType: PageType.details,
			entity: vehicleReassignBlockDetail,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the main menu page
	 */
	public navigateToMenu = (): void => {
		const navigationPage: NavigationPage = {
			entityType: EntityType.menu,
			pageType: null,
			entity: null,
		};

		this.navigateToPage(navigationPage);
	};

	/**
	 * navigate to the chosen page
	 *
	 * @param navigationPage - the page to navigate to
	 * @param openNavView - whether to set the navigation view open as we do (typically true)
	 */
	public navigateToPage = async (navigationPage: NavigationPage, openNavView: boolean = true): Promise<void> => {
		if (this.router.url === '/map') {
			this.mapEventsService.publishNavigateToPage(navigationPage);

			if (openNavView) {
				this.mapOptionsService.setNavViewOpen(true);
			}
		} else {
			// not currently showing the map - transition to the map instructing it to pre select our entity
			this.router.navigate(['/map'], { state: navigationPage });
		}
	};

	/**
	 * check if the agency is different to the currently selected agency
	 *
	 * @param authorityId - the authoirty to check
	 * @returns true if the agency is different to the current
	 */
	private hasAgencyChanged = (authorityId: string): boolean => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (authorityId !== selectedAgency.authority_id) {
			return true;
		}

		return false;
	};

	/**
	 * update the currently selected agency and publish a change for other components to react
	 *
	 * @param authorityId - the new authority id
	 */
	private updateAgency = async (authorityId: string): Promise<void> => {
		await this.agenciesDataService.setUserSelectedAgency(authorityId);

		this.mapStateService.reset();

		this.agenciesEventsService.publishAgenciesSelectionChange();

		// for a restart of the polling
		this.mapPollingService.initPolling();
	};
}

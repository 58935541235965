/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, ComponentRef, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { BlockTooltipComponent } from '../block-tooltip/block-tooltip.component';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TimeHelpers } from '@cubicNx/libs/utils';

import { ColorUtilityService } from '@cubicNx/libs/utils';
import { ComponentInjectionService } from '../../../services/component-injection.service';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { BlocksDataService } from '../../../../../support-features/blocks/services/block-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { IWidgetComponent } from '../../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { RoutePillData } from '@cubicNx/libs/utils';

import {
	DashboardCurrentRouteBlocks,
	VehicleIds,
	VehicleSummary,
	DashboardCurrentRouteBlock,
} from '../../../../../support-features/routes/types/api-types';

import { BlockSection, DisplayBlocks, DisplayBlock, RoutesBlocksAndSections, RouteBlocksAndSections, SectionEndTime } from '../types/types';

import moment, { Moment } from 'moment';

@Component({
	selector: 'current-blocks-list',
	templateUrl: './current-blocks-list.component.html',
	styleUrls: ['./current-blocks-list.component.scss'],
})
export class CurrentBlocksListComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public loaded: boolean = false;
	public success: boolean = false;
	public hasResults: boolean = false;
	public displayBlocks: DisplayBlocks = [];
	public routes: RoutesBlocksAndSections = [];
	public isSectionInformationShown: boolean = false;
	public sectionToShow: BlockSection = null;
	public timezone: string = null;
	public times: string[] = [];

	public reloadWidget$Subscription: Subscription = null;
	public tooltipRef: ComponentRef<any>;

	private numHoursToDisplay: number = 6;
	private minWidth: number = 0;
	private colWidth: number = 0;
	private colWidthPercent: string = '';

	private timeFormat: string = null;

	private authorityId: string = null;
	private agencyId: string = null;

	private blockInterval: any = null;

	constructor(
		private mapNavigationService: MapNavigationService,
		private widgetEventsService: WidgetEventsService,
		private componentInjectionService: ComponentInjectionService,
		private colorUtilityService: ColorUtilityService,
		private agenciesDataService: AgenciesDataService,
		private blocksDataService: BlocksDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the current blocks widget list compoenent - loading translations, subscriptions
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.initTranslations([
			'T_DASH.DB_NO_DATA_FOUND',
			'T_DASH.DB_UNASSIGNED',
			'T_DASH.DB_CANCELLED',
			'T_DASH.DB_ASSIGNED',
			'T_DASH.DB_SERVER_ERROR',
		]);

		this.setup();
		this.init();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
		this.clearIntervals();
	}

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param routeBlocksAndSections - the route blocks and sections
	 * @returns route pill
	 */
	public determineRoutePillData = (routeBlocksAndSections: RouteBlocksAndSections): RoutePillData => {
		return {
			routeShortName: routeBlocksAndSections.route_short_name,
			routeLongName: routeBlocksAndSections.routeLongName,
			routeId: routeBlocksAndSections.routeId,
			routeColor: this.colorUtilityService.getColor(routeBlocksAndSections.routeColor),
			routeTextColor: this.colorUtilityService.getColor(routeBlocksAndSections.routeTextColor),
		};
	};

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * Gets the calculated width of a column as a percentage.
	 *
	 * @returns a style object containing css width property.
	 */
	public getColumnWidthPercentage = (): any => {
		return { width: this.colWidthPercent };
	};

	/**
	 * retrieves the minimum width for the widget
	 *
	 * @returns minimum width
	 */
	public getMinWidth = (): any => {
		return { width: '75px' };
	};

	/**
	 * Navigates to the route view of the block.
	 *
	 * @param block - the block data.
	 */
	public navigateToRouteDetails = async (block: DisplayBlock): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(block.authority_id, block.route_id);
	};

	/**
	 * Show the section information panel for the specified section.
	 *
	 * @param section - the section to show.
	 */
	public showSectionInformation = (section: BlockSection): void => {
		this.isSectionInformationShown = section.routeId !== this.sectionToShow?.routeId;
		this.sectionToShow = this.isSectionInformationShown ? section : undefined;
	};

	/**
	 * Closes the section component panel.
	 */
	public closeSection = (): void => {
		this.isSectionInformationShown = false;
		this.sectionToShow = undefined;
	};

	/**
	 * Navigates to the block view page for the specified block.
	 *
	 * @param blockId - the block id
	 */
	public navigateToBlockDetails = async (blockId: string): Promise<void> => {
		await this.mapNavigationService.navigateToBlockDetails(this.authorityId, blockId);
	};

	/**
	 * Gets the remaining number of columns as an array.
	 *
	 * @returns an array of numbers.
	 */
	public getRemainingColumns = (): Array<number> => {
		return new Array(this.numHoursToDisplay - 1);
	};

	/**
	 * Gets the css style for the section containing width & left properties.
	 *
	 * @param section - the section.
	 * @returns css style for the section.
	 */
	public getSectionStyle = (section: BlockSection): any => {
		return {
			width: section.barFillPerc,
			left: section.barLeftPerc,
		};
	};

	/**
	 * Opens a BlockTooltipComponent at the specified position using the componentInjectionService.
	 *
	 * @param event - object containing block and position data.
	 */
	public openTooltip = (event: any): void => {
		this.tooltipRef = this.componentInjectionService.appendComponent(BlockTooltipComponent, {
			displayBlock: event.displayBlock,
			tooltipPosition: {
				x: event.position.x,
				y: event.position.y,
			},
		});
	};

	/**
	 * Closes the tooltip by destroying it.
	 */
	public closeTooltip = (): void => {
		this.tooltipRef?.destroy();
	};

	/**
	 * performs widget setup functionality - populating widget size
	 */
	private setup = async (): Promise<void> => {
		this.isSectionInformationShown = false;
		this.sectionToShow = null;

		if (this.data.config?.hours) {
			this.numHoursToDisplay = parseInt(this.data.config.hours.name || this.data.config.hours);
		}

		this.minWidth = 164 * (this.numHoursToDisplay + 1);
		this.colWidth = 100 / (this.numHoursToDisplay + 1);
		this.colWidthPercent = `${this.colWidth}%`;
		this.authorityId = this.data.config.selectedAgency?.authority_id;
		this.agencyId = this.data.config.selectedAgency?.agency_id;
	};

	/**
	 * converts blocks to a display equivalent
	 *
	 * @param blocks - current blocks data
	 * @returns display version of the current blocks data
	 */
	private toDisplayBlocks = (blocks: DashboardCurrentRouteBlocks): DisplayBlocks => {
		const displayBlocks: DisplayBlocks = [];

		blocks.forEach((block: DashboardCurrentRouteBlock) => {
			// Convert the Block data which has been returned from the API call into the equivalent data that
			// includes extra properties that are needed by the components' view logic.
			// Note that there seems to be a lot of data that may not be used / needed and this will be sorted
			// in a subsequent rework iteration
			const displayBlock: DisplayBlock = {
				...block,
				blockId: '',
				vehicleIdentifiers: '',
				blockIdText: '',
				authorityId: '',
				agencyId: '',
				theDate: '',
				routeNbId: '',
				routeId: '',
				routeColor: '',
				routeTextColor: '',
				startTime: '',
				endTime: '',
				startTimeSecPastMdnt: 0,
				endTimeSecPastMdnt: 0,
				barLeftPerc: '',
				barFillPerc: '',
				class: '',
				text: '',
				unassigned: false,
			};

			displayBlocks.push(displayBlock);
		});

		return displayBlocks;
	};

	/**
	 * determines the aganecies formatted timezone
	 */
	private getTimeZone = (): void => {
		this.timezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
		this.timeFormat = this.agenciesDataService.getAgencyTimeFormat(this.authorityId, this.agencyId);
		if (this.timeFormat) {
			if (this.timeFormat === 'HH:mm:ss') {
				this.timeFormat = 'HH:mm';
			}
		} else {
			this.timeFormat = 'HH:mm';
		}
	};

	/**
	 * sets up the widget refresh interval
	 */
	private setupIntervals = (): void => {
		const refresh: number = (this.data.config.refreshRateSec ?? 300) * 1000;

		if (this.data.config.versionToggle === 'Blocks') {
			this.getBlocks();
			clearInterval(this.blockInterval);
			this.blockInterval = setInterval(this.getBlocks, refresh);
		} else {
			this.getRoutesFromBlocks();
			clearInterval(this.blockInterval);
			this.blockInterval = setInterval(this.getRoutesFromBlocks, refresh);
		}
	};

	/**
	 * gets the current blocks data and loads the widget
	 */
	private getBlocks = async (): Promise<void> => {
		this.setTimes(this.timezone);

		let routesIds: string[] = [];

		if (this.data.config.selectedRoutes) {
			routesIds = this.data.config.selectedRoutes.map((route: any) => route.route_id);
		}

		const response: ResultContent = await this.blocksDataService.getCurrentBlocks(
			this.authorityId,
			this.agencyId,
			this.timezone,
			this.data.config.display,
			routesIds,
			this.numHoursToDisplay,
			undefined,
			this.data.config.progress
		);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.success = false;

		if (response.success) {
			this.setUpcomingBlocksDisplay(Array.isArray(response.resultData) ? response.resultData : []);
			this.displayBlocks.sort((a, b) => a.startTimeEpoch - b.startTimeEpoch);
			this.hasResults = this.displayBlocks.length > 0;
			this.success = true;
		}

		// turn of loading spinner after initial load - just let the widget update on
		// future requests without spinner
		this.loaded = true;
	};

	/**
	 * loads the upcoming blocks for display purposes
	 *
	 * @param blocks - current route blocks
	 */
	private setUpcomingBlocksDisplay = (blocks: DashboardCurrentRouteBlocks): void => {
		this.displayBlocks = [];

		const displayBlocks: DisplayBlocks = this.toDisplayBlocks(blocks);

		displayBlocks.forEach((displayBlock: DisplayBlock) => {
			if (displayBlock.used) {
				return;
			}

			displayBlock.used = true;
			this.addDisplayBlock(displayBlocks, displayBlock);
			displayBlocks.forEach((displayBlock: DisplayBlock) => {
				if (
					!displayBlock.used &&
					displayBlock.block_id === displayBlock.block_id &&
					displayBlock.route_id !== displayBlock.route_id
				) {
					displayBlock.used = true;
					this.addDisplayBlock(displayBlocks, displayBlock);
				}
			});
		});
	};

	/**
	 * Formats the blocks date
	 *
	 * @param displayBlock - block details
	 * @returns formatted block date
	 */
	private setBlockDate = (displayBlock: DisplayBlock): string => {
		const dateFormat: string =
			this.agenciesDataService.getAgencyDateFormat(displayBlock?.authority_id, displayBlock?.agency_id) ?? 'YYYY-MM-DD';

		return moment().tz(this.timezone).startOf('day').format(dateFormat);
	};

	/**
	 * creates a formatted version of the supplied vehicle identifiers
	 *
	 * @param vehicles - list of vehicle identifiers
	 * @returns joined up vehicle identifiers
	 */
	private buildVehicleString = (vehicles: VehicleIds): string => {
		return vehicles.map((vehicle: VehicleSummary) => vehicle.vehicle_id).join(', ');
	};

	/**
	 * appends block data to the widget
	 *
	 * @param displayBlock - current block details
	 * @param currentHour - current hour
	 */
	private appendBlock = (displayBlock: DisplayBlock, currentHour: Moment): void => {
		const barLeft: number = this.getBarLeft(displayBlock.start_time_epoch, currentHour);
		const barFill: number = this.getBarFill(displayBlock.end_time_epoch, currentHour, barLeft);
		const __ret: any = this.getBarDisplayBlock(displayBlock);
		const barClass: any = __ret.barClass;
		const barText: string = __ret.barText;
		const barUnassigned: boolean = __ret.barUnassigned;
		const blockIdText: string = __ret.blockIdText;

		displayBlock.nextBlocks.push({
			blockId: displayBlock.block_id,
			blockIdText,
			authorityId: displayBlock.authority_id,
			agencyId: displayBlock.agency_id,
			routeNbId: displayBlock.route_nb_id,
			routeId: displayBlock.route_id,
			theDate: this.setBlockDate(displayBlock),
			routeColor: this.colorUtilityService.getColor(displayBlock.route_color),
			routeTextColor: this.colorUtilityService.getColor(displayBlock.route_text_color),
			route_is_enabled: displayBlock.route_is_enabled,
			route_is_hidden: displayBlock.route_is_hidden,
			route_is_predict_enabled: displayBlock.route_is_predict_enabled,
			startTime: TimeHelpers.secondsToHm(displayBlock.start_time),
			endTime: TimeHelpers.secondsToHm(displayBlock.end_time),
			startTimeSecPastMdnt: displayBlock.start_time,
			endTimeSecPastMdnt: displayBlock.end_time,
			barLeftPerc: barLeft.toFixed(1) + '%',
			barFillPerc: barFill.toFixed(1) + '%',
			class: barClass,
			unassigned: barUnassigned,
			text: barText,
			date: displayBlock.date,
			vehicleIdentifiers: this.buildVehicleString(displayBlock.vehicles),
			vehicles: displayBlock.vehicles,
			vehicle_sort: displayBlock.vehicle_sort,
			authority_id: '',
			agency_id: '',
			agency_info_id: '',
			block_id: '',
			route_nb_id: '',
			route_id: '',
			route_text_color: 0,
			route_color: 0,
			start_time: 0,
			end_time: 0,
			block_type: 0,
			status: 0,
			cancelled: false,
			tripDetails: [],
			start_time_epoch: 0,
			end_time_epoch: 0,
			route_short_name: '',
			route_long_name: '',
		});
	};

	/**
	 * creates a bar representing the block data
	 *
	 * @param displayBlock - block details
	 * @returns block bar instance
	 */
	private getBarDisplayBlock = (displayBlock: DisplayBlock): any => {
		const barClass: string = !displayBlock.cancelled && displayBlock.vehicles?.length > 0 ? 'blocks-assigned' : 'blocks-unassigned';

		let barUnassigned: boolean = true;

		let barText: string = this.translations['T_DASH.DB_UNASSIGNED'];

		if (displayBlock.cancelled) {
			barText = this.translations['T_DASH.DB_CANCELLED'];
			barUnassigned = false;
		} else if (displayBlock.vehicles?.length > 0) {
			barText = displayBlock.vehicles
				?.map((vehicle) => (vehicle.driver_id?.length > 0 ? `${vehicle.vehicle_id} (${vehicle.driver_id})` : vehicle.vehicle_id))
				.join(',');
			barUnassigned = false;
		}

		return {
			barClass,
			barText,
			barUnassigned,
			blockIdText: displayBlock.block_id,
		};
	};

	/**
	 * adds the supplied block to the supplied blocks
	 *
	 * @param displayBlocks - blocks
	 * @param displayBlock - block
	 */
	private addDisplayBlock = (displayBlocks: DisplayBlocks, displayBlock: DisplayBlock): void => {
		const currentMoment: Moment = moment().tz(this.timezone).startOf('hour');
		const barLeft: number = this.getBarLeft(displayBlock.start_time_epoch, currentMoment);
		const barFill: number = this.getBarFill(displayBlock.end_time_epoch, currentMoment, barLeft);
		const { barClass, barText, barUnassigned, blockIdText } = this.getBarDisplayBlock(displayBlock);

		for (const displayBlockItem of displayBlocks) {
			if (displayBlockItem.used) {
				continue;
			}

			if (
				displayBlockItem.block_id === displayBlock.block_id &&
				displayBlockItem.route_id === displayBlock.route_id &&
				displayBlockItem.date !== displayBlock.date
			) {
				displayBlockItem.used = true;
				this.appendBlock(displayBlockItem, currentMoment);
			}
		}

		const nextBlocks: DisplayBlocks = displayBlock.nextBlocks || [];
		const b: DisplayBlock = {
			blockId: displayBlock.block_id,
			vehicleIdentifiers: this.buildVehicleString(displayBlock.vehicles),
			blockIdText,
			authorityId: displayBlock.authority_id,
			agencyId: displayBlock.agency_id,
			theDate: this.setBlockDate(displayBlock),
			routeNbId: displayBlock.route_nb_id,
			routeId: displayBlock.route_id,
			route_short_name: displayBlock.route_short_name,
			routeLongName: displayBlock.route_long_name,
			route_is_enabled: displayBlock.route_is_enabled,
			route_is_hidden: displayBlock.route_is_hidden,
			route_is_predict_enabled: displayBlock.route_is_predict_enabled,
			routeColor: this.colorUtilityService.getColor(displayBlock.route_color),
			routeTextColor: this.colorUtilityService.getColor(displayBlock.route_text_color),
			startTime: TimeHelpers.secondsToHm(displayBlock.start_time),
			endTime: TimeHelpers.secondsToHm(displayBlock.end_time),
			startTimeEpoch: displayBlock.start_time_epoch,
			endTimeEpoch: displayBlock.end_time_epoch,
			startTimeSecPastMdnt: displayBlock.start_time,
			endTimeSecPastMdnt: displayBlock.end_time,
			barLeftPerc: barLeft.toFixed(1) + '%',
			barFillPerc: barFill.toFixed(1) + '%',
			class: barClass,
			unassigned: barUnassigned,
			text: barText,
			date: displayBlock.date,
			nextBlocks,
			vehicles: displayBlock.vehicles,
			vehicle_sort: displayBlock.vehicle_sort,
			colWidthPerc: this.colWidthPercent,
			block: displayBlock,
			authority_id: '',
			agency_id: '',
			agency_info_id: '',
			block_id: '',
			route_nb_id: '',
			route_id: '',
			route_text_color: 0,
			route_color: 0,
			start_time: 0,
			end_time: 0,
			block_type: 0,
			status: 0,
			cancelled: false,
			tripDetails: [],
			start_time_epoch: 0,
			end_time_epoch: 0,
			route_long_name: '',
		};

		if (b.routeColor === '#000000' && b.routeTextColor === '#000000') {
			b.routeColor = '#eaeaea';
		}

		this.displayBlocks.push(b);
	};

	/**
	 * determines the routes from the current block details
	 */
	private getRoutesFromBlocks = async (): Promise<void> => {
		this.setTimes(this.timezone);

		let routesIds: string[] = [];

		if (this.data.config.selectedRoutes) {
			routesIds = this.data.config.selectedRoutes.map((route: any) => route.route_id);
		}

		const response: ResultContent = await this.blocksDataService.getCurrentBlocks(
			this.authorityId,
			this.agencyId,
			this.timezone,
			this.data.config.display,
			routesIds,
			this.numHoursToDisplay,
			undefined,
			this.data.config.progress
		);

		// default to false - do this after await so angular doesnt start change detection while we wait
		this.success = false;

		if (response.success) {
			this.displayBlocks = this.toDisplayBlocks(Array.isArray(response.resultData) ? response.resultData : []);
			this.routes = this.setUpcomingRoutesDisplay(
				this.buildSectionStartAndEndTimes(
					this.sortBlocksInRouteByStartTimes(this.buildRoutesInfoFromBlocks(this.sortBlocksByRoute(this.displayBlocks)))
				)
			);

			this.hasResults = this.routes.length > 0;
			this.success = true;
		}

		this.loaded = true;
	};

	/**
	 * creates a bar representing the block routes data
	 *
	 * @param routeSection - block section
	 * @returns bar represntation of the block routes
	 */
	private getBarDisplayRoute = (routeSection: BlockSection): any => {
		const barText: string = `${routeSection.assignedBlocks} / ${routeSection.blockCount} ${this.translations['T_DASH.DB_ASSIGNED']}`;

		let barClass: string = 'routes-all-assigned';

		if (routeSection.assignedBlocks === 0) {
			barClass = 'routes-none-assigned';
		} else if (routeSection.assignedBlocks < routeSection.blockCount) {
			barClass = 'routes-some-assigned';
		}

		return {
			barClass,
			barText,
			blockIdText: '',
		};
	};

	/**
	 * determines the formatted route section date
	 *
	 * @param section - block section
	 * @returns formatted route section date
	 */
	private setRouteSectionDate = (section: BlockSection): string => {
		let dateFormat: string = 'YYYY-MM-DD';

		if (section?.blocksInRoute?.length > 0) {
			dateFormat = this.agenciesDataService.getAgencyDateFormat(
				section.blocksInRoute[0].authority_id,
				section.blocksInRoute[0].agency_id
			);
		}

		return moment().tz(this.timezone).startOf('day').format(dateFormat);
	};

	/**
	 * adds a route to block section
	 *
	 * @param routeSection - route section
	 */
	private addRoute = (routeSection: BlockSection): void => {
		const currentMoment: Moment = moment().tz(this.timezone).startOf('hour');
		const barLeft: number = this.getBarLeft(routeSection.start_time_epoch, currentMoment);
		const barFill: number = this.getBarFill(routeSection.end_time_epoch, currentMoment, barLeft);
		const { barClass, barText } = this.getBarDisplayRoute(routeSection);

		routeSection.barLeftPerc = barLeft.toFixed(1) + '%';
		routeSection.barFillPerc = barFill.toFixed(1) + '%';
		routeSection.class = barClass;
		routeSection.text = barText;
	};

	/**
	 * sets the upcoming routes to the blocks and sections
	 *
	 * @param routes - routes blocks and sections
	 * @returns routes blocks and sections with upcoming routes
	 */
	private setUpcomingRoutesDisplay = (routes: RoutesBlocksAndSections): RoutesBlocksAndSections => {
		this.routes = [];

		routes.forEach((route: RouteBlocksAndSections) => {
			route.sections.forEach((section: BlockSection) => {
				section.routeId = route.routeId;
				section.route_short_name = route.route_short_name;
				section.routeLongName = route.routeLongName;
				section.routeColor = route.routeColor;
				section.routeTextColor = route.routeTextColor;
				section.route_is_enabled = route.route_is_enabled;
				section.route_is_hidden = route.route_is_hidden;
				section.route_is_predict_enabled = route.route_is_predict_enabled;
				section.date = this.setRouteSectionDate(section);
				this.addRoute(section);
			});
		});

		return routes;
	};

	/**
	 * derives the left bar time
	 *
	 * @param startTimeEpoch - starting epoch time
	 * @param currentMoment - current start of hour
	 * @returns left bar time
	 */
	private getBarLeft = (startTimeEpoch: number, currentMoment: Moment): number => {
		const currentEpoch: number = currentMoment.valueOf();

		return startTimeEpoch > currentEpoch ? 100 * this.convertMsToHours(startTimeEpoch - currentEpoch) : 0;
	};

	/**
	 * derives the bar fill
	 *
	 * @param endTimeEpoch - end epoch time
	 * @param currentMoment - current start of hour
	 * @param barLeft - left bar
	 * @returns bar fill
	 */
	private getBarFill = (endTimeEpoch: number, currentMoment: Moment, barLeft: number): number => {
		let barFill: number = 100 * this.numHoursToDisplay - barLeft;
		const endMoment: Moment = currentMoment.clone().add(this.numHoursToDisplay, 'hours');
		const endEpoch: number = endMoment.valueOf();

		if (endTimeEpoch < endEpoch) {
			barFill -= 100 * this.convertMsToHours(endEpoch - endTimeEpoch);
		}

		return barFill;
	};

	/**
	 * adds a new route section to the block display
	 *
	 * @param route - routes blocks and sections
	 * @param displayBlock - block being displayed
	 * @param vehicleCount - vehicle count
	 * @param curSectionEndTimeEpoch - section end epoch time
	 */
	private addNewRouteSection = (
		route: RouteBlocksAndSections,
		displayBlock: DisplayBlock,
		vehicleCount: number,
		curSectionEndTimeEpoch: SectionEndTime
	): void => {
		curSectionEndTimeEpoch.endTimeEpoch = displayBlock.end_time_epoch;
		route.sections.push({
			start_time: displayBlock.start_time,
			end_time: displayBlock.end_time,
			start_time_epoch: displayBlock.start_time_epoch,
			end_time_epoch: displayBlock.end_time_epoch,
			vehicles: vehicleCount,
			blockCount: 1,
			assignedBlocks: vehicleCount > 0 ? 1 : 0,
			blocksInRoute: [displayBlock],
		});
	};

	/**
	 * updates the route section
	 *
	 * @param section - block section
	 * @param displayBlock - block being displayed
	 * @param vehicleCount - vehicle count
	 * @param curSectionEndTimeEpoch - section end epoch time
	 */
	private updateRouteSection = (
		section: BlockSection,
		displayBlock: DisplayBlock,
		vehicleCount: number,
		curSectionEndTimeEpoch: SectionEndTime
	): void => {
		if (displayBlock.end_time_epoch > section.end_time_epoch) {
			curSectionEndTimeEpoch.endTimeEpoch = displayBlock.end_time_epoch;
			section.end_time_epoch = curSectionEndTimeEpoch.endTimeEpoch;
			section.end_time = displayBlock.end_time;
		}

		section.vehicles += vehicleCount;
		section.blockCount += 1;
		if (vehicleCount > 0) {
			section.assignedBlocks++;
		}

		section.blocksInRoute.push(displayBlock);
	};

	/**
	 * derives the vehicle count
	 *
	 * @param vehicles - vehicles
	 * @returns vehicle count
	 */
	private getVehicleCount = (vehicles: VehicleIds): number => {
		let vehicleCount: number = 0;

		if (vehicles && vehicles.length > 0) {
			vehicleCount = vehicles.length;
		}

		return vehicleCount;
	};

	/**
	 * adds start and end times to the supplied routes blocks and section data
	 *
	 * @param routes - rotues blocks and sections
	 * @returns routes blocks and sections with start and end times
	 */
	private buildSectionStartAndEndTimes = (routes: RoutesBlocksAndSections): RoutesBlocksAndSections => {
		const curSectionEndTimeEpoch: SectionEndTime = {
			endTimeEpoch: 0,
		};

		routes.forEach((route: RouteBlocksAndSections) => {
			route.sections = [];
			const displayBlocks: DisplayBlocks = route.blocksInRoute;

			displayBlocks.sort((a, b) => a.start_time_epoch - b.start_time_epoch);

			displayBlocks.forEach((displayBlock: DisplayBlock) => {
				if (route.sections.length === 0) {
					this.addNewRouteSection(route, displayBlock, this.getVehicleCount(displayBlock.vehicles), curSectionEndTimeEpoch);
				} else {
					if (displayBlock.start_time_epoch <= curSectionEndTimeEpoch.endTimeEpoch) {
						this.updateRouteSection(
							route.sections[route.sections.length - 1],
							displayBlock,
							this.getVehicleCount(displayBlock.vehicles),
							curSectionEndTimeEpoch
						);
					} else {
						this.addNewRouteSection(route, displayBlock, this.getVehicleCount(displayBlock.vehicles), curSectionEndTimeEpoch);
					}
				}
			});
		});

		return routes;
	};

	/**
	 * sorts the blocks by route start times
	 *
	 * @param routes - routes blocks and sections
	 * @returns sorted routes blocks and sections
	 */
	private sortBlocksInRouteByStartTimes = (routes: RoutesBlocksAndSections): RoutesBlocksAndSections => {
		routes.forEach((route: RouteBlocksAndSections) => {
			route.blocksInRoute.sort((a, b) => {
				if (typeof a.adj_start_time !== 'undefined') {
					if (typeof b.adj_start_time !== 'undefined') {
						return a.adj_start_time - b.adj_start_time;
					} else {
						return a.adj_start_time - b.start_time;
					}
				} else if (typeof b.adj_start_time !== 'undefined') {
					return a.start_time - b.adj_start_time;
				} else {
					return a.start_time - b.start_time;
				}
			});
		});

		return this.setAdjTimesToZeroIfNegative(routes);
	};

	/**
	 * adjusts negative start times to zero
	 *
	 * @param routes - routes blocks and sections
	 * @returns adjusted routes blocks and sections
	 */
	private setAdjTimesToZeroIfNegative = (routes: RoutesBlocksAndSections): RoutesBlocksAndSections => {
		routes.forEach((route) => {
			route.blocksInRoute.forEach((item) => {
				if (item.adj_start_time < 0) {
					item.adj_start_time = 0;
				}
			});
		});

		return routes;
	};

	/**
	 * adds routes blocks and sections to the block being displayed
	 *
	 * @param routes - routes blocks and sections
	 * @param displayBlock - block being displayed
	 */
	private addNewRoute = (routes: RoutesBlocksAndSections, displayBlock: DisplayBlock): void => {
		routes.push({
			routeId: displayBlock.route_id,
			route_short_name: displayBlock.route_short_name,
			routeLongName: displayBlock.route_long_name,
			routeColor: this.colorUtilityService.getColor(displayBlock.route_color),
			routeTextColor: this.colorUtilityService.getColor(displayBlock.route_text_color),
			route_is_enabled: displayBlock.route_is_enabled,
			route_is_hidden: displayBlock.route_is_hidden,
			route_is_predict_enabled: displayBlock.route_is_predict_enabled,
			blocksInRoute: [displayBlock],
			sections: [],
		});
	};

	/**
	 * builds route information from supplied blocks
	 *
	 * @param displayBlocks - block being displayed
	 * @returns routes blocks and sections
	 */
	private buildRoutesInfoFromBlocks = (displayBlocks: DisplayBlocks): RoutesBlocksAndSections => {
		const routes: RoutesBlocksAndSections = [];

		for (const displayBlock of displayBlocks) {
			if (routes.length === 0) {
				this.addNewRoute(routes, displayBlock);
			} else {
				let routeFound: boolean = false;

				for (const route of routes) {
					if (displayBlock.route_id === route.routeId) {
						route.blocksInRoute.push(displayBlock);
						routeFound = true;
						break;
					}
				}

				if (!routeFound) {
					this.addNewRoute(routes, displayBlock);
				}
			}
		}

		return routes;
	};

	/**
	 * sorts the supplied blocks by route
	 *
	 * @param displayBlocks - blocks being displayed
	 * @returns blocks sorted by route
	 */
	private sortBlocksByRoute = (displayBlocks: DisplayBlocks): DisplayBlocks => {
		displayBlocks.sort((a, b) => Number(a.route_nb_id) - Number(b.route_nb_id));

		return displayBlocks;
	};

	/**
	 * Sets the number of hours to show in the blocks/routes chart.
	 *
	 * @param timezone - the timezone the agency is configured in.
	 */
	private setTimes = (timezone: string): void => {
		this.times = [''];
		const hoursToAdd: number[] = Array.from(Array(this.numHoursToDisplay).keys());

		hoursToAdd.forEach((x) => this.times.push(moment().tz(timezone).startOf('hour').add(x, 'hours').format(this.timeFormat)));
	};

	/**
	 * Converts milliseconds to hours.
	 *
	 * @param ms - value to convert in milliseconds.
	 * @returns value in hours.
	 */
	private convertMsToHours = (ms: number): number => {
		return ms / 1000 / 60 / 60;
	};

	/**
	 * If the agencyId is set then ensure
	 * the timezone and intervals are setup.
	 */
	private init = (): void => {
		this.loaded = false;

		this.getTimeZone();
		this.setupIntervals();
	};

	/**
	 * Subscribes to the required observables.
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.setup();
				this.init();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		this.clearIntervals();
		this.reloadWidget$Subscription?.unsubscribe();
	};

	/**
	 * Clears any intervals set for the component.
	 */
	private clearIntervals = (): void => {
		if (this.blockInterval) {
			clearInterval(this.blockInterval);
		}
	};
}

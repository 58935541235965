/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import {
	EffectiveDateRangeType,
	EntitySelectionType,
	ReportConfiguration,
	MultiSelectionType,
	ReportConfigurations,
	ReportConfigurationsByCategory,
	SortType,
	SpeedType,
	EffectiveDateRangeDayType,
	MultiGenerateReportType,
} from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class ReportsConfigService {
	private readonly adherenceByRouteTemplateId: string = 'adherence-by-route';
	private readonly adherenceByStopTemplateId: string = 'adherence-by-stop';
	private readonly adherenceByVehicleByRouteTemplateId: string = 'adherence-by-vehicle-by-route';
	private readonly adherenceForVehiclesByStopTemplateId: string = 'adherence-for-vehicles-by-stop';
	private readonly adherenceByRouteDirectionStopTemplateId: string = 'adherence-by-route-direction-stop';
	private readonly adherenceVehicleArrivalAndDepartureTemplateId: string = 'adherence-vehicle-arrival-and-departure';
	private readonly configStopsAndTimepointsByBlockTemplateId: string = 'config-stops-and-timepoints-by-block';
	private readonly configScheduleForRouteTemplateId: string = 'config-schedule-for-route';
	private readonly configStopsTemplateId: string = 'config-stops';
	private readonly blockAssignmentsTemplateId: string = 'block-assignments';
	private readonly configStopsAndTimePointsByScheduledBlocksTemplateId: string = 'config-stops-and-timepoints-by-scheduled-blocks';
	private readonly configaAverageTravelTimeForRouteTemplateId: string = 'config-average-travel-time-for-route';
	private readonly headwayByStopTemplateId: string = 'headway-by-stop';
	private readonly headwayDetailTemplateId: string = 'headway-detail';
	private readonly headwayByRouteTemplateId: string = 'headway-by-route';
	private readonly riderCountByRouteTemplateId: string = 'rider-count-by-route';
	private readonly riderCountByRouteByStopTemplateId: string = 'rider-count-by-route-by-stop';
	private readonly riderCountByTripTemplateIdTemplateId: string = 'rider-count-by-trip';
	private readonly riderCountByBlockTemplateId: string = 'rider-count-by-block';
	private readonly riderCountByVehicleTemplateId: string = 'rider-count-by-vehicle';
	private readonly riderCountByBlockByVehicleTemplateId: string = 'rider-count-by-block-by-vehicle';
	private readonly avlReportByVehicleTemplateId: string = 'avl-report-by-vehicle';
	private readonly lastAvlReportTemplateId: string = 'last-avl-report';
	private readonly departuresTemplateId: string = 'deadheading-vehicles';

	private reportsConfigMap: Map<string, ReportConfiguration> = new Map<string, ReportConfiguration>();

	constructor() {
		this.registerReports();
	}

	/**
	 * retrieves the report configuration for the supplied report id
	 *
	 * @param id - the report id
	 * @returns the report configuration
	 */
	public getReportConfiguration = (id: string): ReportConfiguration => {
		return this.reportsConfigMap.get(id);
	};

	/**
	 * retrieves the report configurations by catagory
	 *
	 * @returns report configurations by category
	 */
	public getReportsConfigurationsByCategory = (): ReportConfigurationsByCategory => {
		const reports: ReportConfigurations = Array.from(this.reportsConfigMap.values());

		const categories: string[] = [];

		reports.forEach((reportConfiguration: ReportConfiguration) => {
			if (!categories.includes(reportConfiguration.category)) {
				categories.push(reportConfiguration.category);
			}
		});

		const reportsByCategory: ReportConfigurationsByCategory = {};

		categories.forEach((category: string) => {
			const reportsWithCategory: ReportConfiguration[] = reports.filter((report) => report.category === category) || [];

			reportsByCategory[category] = reportsWithCategory;
		});

		return reportsByCategory;
	};

	/**
	 * registers all of the available report type configurations
	 *
	 * involves creating relevant report configuration and recording that said report configuration
	 * into the underlying map of registered report configurations
	 */
	private registerReports = (): void => {
		// Adherence
		this.registerAdherenceByRoute();
		this.registerAdherenceByStop();
		this.registerAdherenceByVehicleByRoute();
		this.registerAdherenceForVehiclesByStop();
		this.registerAdherenceByRouteDirectionStop();
		this.registerAdherenceVehicleArrivalAndDeparture();

		// Configuration
		this.registerConfigStopsAndTimepointsByBlock();
		this.registerConfigScheduleForRoute();
		this.registerConfigStops();
		this.registerConfigStopsAndTimePointsByScheduledBlocks();
		this.registerAverageTravelTimeForRoute();

		// Blocks
		this.registerBlockAssignments();

		// Headway
		this.registerHeadwayByStop();
		this.registerHeadwayDetail();
		this.registerHeadwayByRoute();

		// Ridership
		this.registerRiderCountByRoute();
		this.registerRiderCountByRouteByStop();
		this.registerCountByTrip();
		this.registerCountByBlock();
		this.registerCountByVehicle();
		this.registerCountByBlockByVehicle();

		// AVL
		this.registerAVLByVehicle();
		this.registerLastAVLReport();

		// Departures
		this.registerDeadheadVehicles();
	};

	/**
	 * registers the adherence by route report configuration
	 */
	private registerAdherenceByRoute = (): void => {
		const adherenceByRouteReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.adherenceByRouteTemplateId,
			endpoint: 'ad100_adherence_by_route',
			version: 1,
			category: 'adherence',
			code: 'AD-100',
			defaultSortName: 'route_id',
			// links to ad-200
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				adherenceThreshold: { enabled: true },
				filterArrivalStops: { enabled: true },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 100,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.adherenceByRouteTemplateId, adherenceByRouteReportCfg);
	};

	/**
	 * registers the adherence by stop report configuration
	 */
	private registerAdherenceByStop = (): void => {
		const adherenceByStopReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.adherenceByStopTemplateId,
			endpoint: 'ad200_adherence_by_route_and_stop',
			version: 1,
			category: 'adherence',
			code: 'AD-200',
			defaultSortName: 'stop_id',
			// links to ad-500// passes route, stop, and direction.
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				adherenceThreshold: { enabled: true },
				filterArrivalStops: { enabled: true },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 5,
			},
		};

		this.reportsConfigMap.set(this.adherenceByStopTemplateId, adherenceByStopReportCfg);
	};

	/**
	 * registers the adherence by vehicle by route report configuration
	 */
	private registerAdherenceByVehicleByRoute = (): void => {
		const adherenceByVehicleReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.adherenceByVehicleByRouteTemplateId,
			endpoint: 'ad300_adherence_for_vehicles_by_route',
			version: 1,
			category: 'adherence',
			code: 'AD-300',
			defaultSortName: 'vehicle_id',
			// links to ad-600
			// passes vehicle selection
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				adherenceThreshold: { enabled: true },
				filterArrivalStops: { enabled: true },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 5,
			},
		};

		this.reportsConfigMap.set(this.adherenceByVehicleByRouteTemplateId, adherenceByVehicleReportCfg);
	};

	/**
	 * registers the adherence by vehicle by stop report configuration
	 */
	private registerAdherenceForVehiclesByStop = (): void => {
		const adherenceForVehiclesByStopReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.adherenceForVehiclesByStopTemplateId,
			endpoint: 'ad400_adherence_for_vehicles_by_stop',
			version: 1,
			category: 'adherence',
			code: 'AD-400',
			defaultSortName: 'stop_id',
			// formerly Arrivals and Departures
			reportOptions: {
				vehicles: { enabled: true, multiSelect: false, selections: [] },
				routes: { enabled: false, multiSelect: false, selections: [] },
				blocks: { enabled: false, multiSelect: false, selections: [] },
				adherenceThreshold: { enabled: true },
				filterArrivalStops: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 200,
			},
		};

		this.reportsConfigMap.set(this.adherenceForVehiclesByStopTemplateId, adherenceForVehiclesByStopReportCfg);
	};

	/**
	 * registers the adherence by route direction report configuration
	 */
	private registerAdherenceByRouteDirectionStop = (): void => {
		const adherenceByRouteDirectionStopReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.adherenceByRouteDirectionStopTemplateId,
			endpoint: 'ad500_adherence_for_route_direction_stop',
			version: 1,
			category: 'adherence',
			code: 'AD-500',
			defaultSortName: 'vehicle_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: true },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.adherenceByRouteDirectionStopTemplateId, adherenceByRouteDirectionStopReportCfg);
	};

	/**
	 * registers the adherence by vehicle arrival and departure report configuration
	 */
	private registerAdherenceVehicleArrivalAndDeparture = (): void => {
		const adherenceVehicleArrivalAndDepartureReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.adherenceVehicleArrivalAndDepartureTemplateId,
			endpoint: 'ad600_vehicle_arrivals_and_departures',
			version: 1,
			category: 'adherence',
			code: 'AD-600',
			defaultSortName: 'block_id',
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: true, multiSelect: false, selections: [] },
				filterArrivalStops: { enabled: true },
				adherenceThreshold: { enabled: true },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.adherenceVehicleArrivalAndDepartureTemplateId, adherenceVehicleArrivalAndDepartureReportCfg);
	};

	/**
	 * registers the configured stops and timepoints by block report configuration
	 */
	private registerConfigStopsAndTimepointsByBlock = (): void => {
		const configStopsAndTimepointsByBlockReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.configStopsAndTimepointsByBlockTemplateId,
			endpoint: 'c100_stops_and_timepoints_by_block',
			version: 1,
			category: 'configuration',
			code: 'C-100',
			defaultSortName: 'stop_sequence',
			reportOptions: {
				blocks: { enabled: true, multiSelect: false, selections: [], multiSelectionType: MultiSelectionType.select },
				routes: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: true },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: false },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
				allowFutureDates: true,
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 5,
			},
		};

		this.reportsConfigMap.set(this.configStopsAndTimepointsByBlockTemplateId, configStopsAndTimepointsByBlockReportCfg);
	};

	/**
	 * registers the configured schedules for route report configuration
	 */
	private registerConfigScheduleForRoute = (): void => {
		const configScheduleForRouteReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.configScheduleForRouteTemplateId,
			endpoint: 'c200_schedule_for_route',
			version: 1,
			category: 'configuration',
			code: 'C-200',
			defaultSortName: '',
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: false },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.dayName,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: false },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: true },
				},
			},
			reportOptions: {
				routes: { enabled: true, multiSelect: false, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: true, sortOptions: [SortType.block, SortType.time] },
				speed: { enabled: false, speedOptions: [] },
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.configScheduleForRouteTemplateId, configScheduleForRouteReportCfg);
	};

	/**
	 * registers the configured stops report configuration
	 */
	private registerConfigStops = (): void => {
		const configStopsReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.configStopsTemplateId,
			endpoint: 'c300_stops_by_route',
			version: 1,
			category: 'configuration',
			code: 'C-300',
			defaultSortName: 'sequence',
			dateOptions: {
				disabled: true,
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.configStopsTemplateId, configStopsReportCfg);
	};

	/**
	 * registers the configured stops and timepoints by scheduled block report configuration
	 */
	private registerConfigStopsAndTimePointsByScheduledBlocks = (): void => {
		const configStopsAndTimePointsByScheduledBlocksReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.configStopsAndTimePointsByScheduledBlocksTemplateId,
			endpoint: 'c100_stops_and_timepoints_by_block', // we create C100's from C400 for each block selected
			version: 1,
			category: 'configuration',
			code: 'C-400',
			reportGenerationOverride: {
				defaultTemplateId: this.configStopsAndTimepointsByBlockTemplateId,
				multiGenerateReportType: MultiGenerateReportType.perBlock,
			},
			defaultSortName: 'stop_sequence',
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: false },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: null,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
				allowFutureDates: true,
			},
			reportOptions: {
				routes: { enabled: false, multiSelect: false, selections: [] },
				blocks: {
					enabled: true,
					multiSelect: false,
					selections: [],
					multiSelectionType: MultiSelectionType.list,
					endpoint: 'c400_stops_and_timepoints_by_scheduled_blocks',
				},
				vehicles: { enabled: false, multiSelect: false, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(
			this.configStopsAndTimePointsByScheduledBlocksTemplateId,
			configStopsAndTimePointsByScheduledBlocksReportCfg
		);
	};

	/**
	 * registers the configured Average travel time for route report configuration
	 */
	private registerAverageTravelTimeForRoute = (): void => {
		const configAverageTravelTimeForRouteReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.configaAverageTravelTimeForRouteTemplateId,
			endpoint: 'c500_average_travel_time_for_route',
			version: 1,
			category: 'configuration',
			code: 'C-500',
			defaultSortName: '',
			dateOptions: {
				disabled: true,
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: false },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: null,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
				allowFutureDates: true,
			},
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: false, selections: [] },
				vehicles: { enabled: false, multiSelect: false, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: true, speedOptions: [SpeedType.kmh, SpeedType.mph] },
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.configaAverageTravelTimeForRouteTemplateId, configAverageTravelTimeForRouteReportCfg);
	};
	/**
	 * registers the block assignments report configuration
	 */
	private registerBlockAssignments = (): void => {
		const blockAssignmentsReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.blockAssignmentsTemplateId,
			endpoint: 'b100_blocks',
			version: 1,
			category: 'block',
			code: 'BL-100',
			defaultSortName: 'block_id',
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
			},
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				blocks: {
					enabled: true,
					multiSelect: false,
					selections: [EntitySelectionType.all, EntitySelectionType.unassigned],
					multiSelectionType: MultiSelectionType.select,
				},
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.blockAssignmentsTemplateId, blockAssignmentsReportCfg);
	};

	/**
	 * registers the headway by stop report configuration
	 */
	private registerHeadwayByStop = (): void => {
		const headwayByStopReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.headwayByStopTemplateId,
			version: 1,
			category: 'headway',
			code: 'HW-100',
			endpoint: 'hw100_headway_by_stop',
			defaultSortName: 'stop_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.headwayByStopTemplateId, headwayByStopReportCfg);
	};

	/**
	 * registers the headway detail report configuration
	 */
	private registerHeadwayDetail = (): void => {
		const headwayDetailReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.headwayDetailTemplateId,
			version: 1,
			category: 'headway',
			code: 'HW-200',
			endpoint: 'hw200_headway_detail',
			defaultSortName: 'vehicle_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.headwayDetailTemplateId, headwayDetailReportCfg);
	};

	/**
	 * registers the headway by route report configuration
	 */
	private registerHeadwayByRoute = (): void => {
		const headwayByRouteReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.headwayByRouteTemplateId,
			version: 1,
			category: 'headway',
			code: 'HW-300',
			endpoint: 'hw300_headway_by_route',
			defaultSortName: 'route_long_name',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.headwayByRouteTemplateId, headwayByRouteReportCfg);
	};

	/**
	 * registers the rider count by route report configuration
	 */
	private registerRiderCountByRoute = (): void => {
		const riderCountByRouteReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.riderCountByRouteTemplateId,
			version: 1,
			category: 'ridership',
			code: 'RC-100',
			endpoint: 'rc100_rider_count_by_route',
			defaultSortName: 'route_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				vehicles: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.riderCountByRouteTemplateId, riderCountByRouteReportCfg);
	};

	/**
	 * registers the rider count by route by stop report configuration
	 */
	private registerRiderCountByRouteByStop = (): void => {
		const riderCountByRouteByStopReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.riderCountByRouteByStopTemplateId,
			version: 1,
			category: 'ridership',
			code: 'RC-200',
			endpoint: 'rc200_rider_count_by_route_by_stop',
			defaultSortName: 'stop_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				vehicles: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.riderCountByRouteByStopTemplateId, riderCountByRouteByStopReportCfg);
	};

	/**
	 * registers the count by trip report configuration
	 */
	private registerCountByTrip = (): void => {
		const riderCountByTripReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.riderCountByTripTemplateIdTemplateId,
			version: 1,
			category: 'ridership',
			code: 'RC-300',
			endpoint: 'rc300_rider_count_by_trip',
			defaultSortName: 'stop_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: false, selections: [] },
				vehicles: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.riderCountByTripTemplateIdTemplateId, riderCountByTripReportCfg);
	};

	/**
	 * registers the count by block report configuration
	 */
	private registerCountByBlock = (): void => {
		const riderCountByBlockReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.riderCountByBlockTemplateId,
			version: 1,
			category: 'ridership',
			code: 'RC-400',
			endpoint: 'rc400_rider_count_by_block',
			defaultSortName: 'block_id',
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				blocks: {
					enabled: true,
					multiSelect: true,
					selections: [EntitySelectionType.all, EntitySelectionType.specific],
					multiSelectionType: MultiSelectionType.select,
				},
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.riderCountByBlockTemplateId, riderCountByBlockReportCfg);
	};

	/**
	 * registers the count by vehicle report configuration
	 */
	private registerCountByVehicle = (): void => {
		const riderCountByVehicleReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.riderCountByVehicleTemplateId,
			version: 1,
			category: 'ridership',
			code: 'RC-500',
			endpoint: 'rc500_rider_count_by_vehicle',
			defaultSortName: 'vehicle_id',
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.riderCountByVehicleTemplateId, riderCountByVehicleReportCfg);
	};

	/**
	 * registers the count by block by vehicle report configuration
	 */
	private registerCountByBlockByVehicle = (): void => {
		const riderCountByBlockByVehicleReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.riderCountByBlockByVehicleTemplateId,
			version: 1,
			category: 'ridership',
			code: 'RC-600',
			endpoint: 'rc600_rider_count_by_block_by_vehicle',
			defaultSortName: 'arrival_time',
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				blocks: {
					enabled: true,
					multiSelect: false,
					selections: [EntitySelectionType.all, EntitySelectionType.specific],
					multiSelectionType: MultiSelectionType.select,
				},
				vehicles: {
					enabled: true,
					multiSelect: true,
					selections: [EntitySelectionType.all, EntitySelectionType.specific],
				},
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 10,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 5,
			},
		};

		this.reportsConfigMap.set(this.riderCountByBlockByVehicleTemplateId, riderCountByBlockByVehicleReportCfg);
	};

	/**
	 * registers the AVL by vehicle report configuration
	 */
	private registerAVLByVehicle = (): void => {
		const avlByVehicleReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.avlReportByVehicleTemplateId,
			version: 1,
			category: 'avl',
			code: 'AVL-200',
			endpoint: 'avl200_avl_report_by_vehicle',
			defaultSortName: 'recDate',
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: true, multiSelect: false, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: false,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: false },
					month: { enabled: false },
					custom: { enabled: false },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.avlReportByVehicleTemplateId, avlByVehicleReportCfg);
	};

	/**
	 * registers the last AVL report configuration
	 */
	private registerLastAVLReport = (): void => {
		const lastAvlReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.lastAvlReportTemplateId,
			version: 1,
			category: 'avl',
			code: 'AVL-300',
			endpoint: 'avl300_last_avl_report',
			defaultSortName: 'vehicle_id',
			reportOptions: {
				routes: { enabled: false, multiSelect: true, selections: [] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				disabled: true,
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.lastAvlReportTemplateId, lastAvlReportCfg);
	};

	/**
	 * registers the deadhead vehicle report configuration
	 */
	private registerDeadheadVehicles = (): void => {
		const deadheadVehiclesReportCfg: ReportConfiguration = {
			sharing: 'private',
			outputType: 'interactive',
			defaultTemplateId: this.departuresTemplateId,
			version: 1,
			category: 'misc',
			code: 'DV-100',
			endpoint: 'dv100_late_depatures_details_report_by_deadhead_vehicles',
			defaultSortName: 'route_id',
			reportOptions: {
				routes: { enabled: true, multiSelect: true, selections: [EntitySelectionType.all, EntitySelectionType.specific] },
				blocks: { enabled: false, multiSelect: true, selections: [] },
				vehicles: { enabled: false, multiSelect: true, selections: [] },
				filterArrivalStops: { enabled: false },
				adherenceThreshold: { enabled: false },
				sort: { enabled: false, sortOptions: [] },
				speed: { enabled: false, speedOptions: [] },
			},
			dateOptions: {
				effectiveDateStart: { enabled: true },
				effectiveDateEnd: { enabled: true },
				effectiveTime: { enabled: true },
				showDateRangeOptions: true,
				initialDateRangeType: EffectiveDateRangeType.day,
				initialDateRangeDayType: EffectiveDateRangeDayType.today,
				dateRangeTypes: {
					day: { enabled: true },
					week: { enabled: true },
					month: { enabled: true },
					custom: { enabled: true },
					dayName: { enabled: false },
				},
			},
			paginationSettings: {
				maxPageSize: 200,
				initialPageSize: 50,
			},
			subViewPaginationSettings: {
				maxPageSize: 200,
				initialPageSize: 25,
			},
		};

		this.reportsConfigMap.set(this.departuresTemplateId, deadheadVehiclesReportCfg);
	};
}

import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AgencyPortalUtilsModule } from '../../../utils/agency-portal-utils.module';

import { SchedulesAdminApiService } from './services/schedules-admin-api.service';
import { SchedulesAdminDataService } from './services/schedules-admin-data.service';

import { SchedulesAdminListComponent } from './list/schedules-admin-list.component';
import { ScheduleAdminViewRevisionHistoryComponent } from './view/revision-history/schedule-admin-view-revision-history.component';
import { ScheduleAdminViewComponent } from './view/schedule-admin-view.component';
import { ScheduleAdminViewUploadHistoryComponent } from './view/upload-history/schedule-admin-view-upload-history.component';

import { ScheduleAdminViewUploadHistoryDetailsComponent } from './view/upload-history/upload-history-details/schedule-admin-view-upload-history-details.component';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [
		SchedulesAdminListComponent,
		ScheduleAdminViewUploadHistoryDetailsComponent,
		ScheduleAdminViewComponent,
		ScheduleAdminViewUploadHistoryComponent,
		ScheduleAdminViewRevisionHistoryComponent,
	],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgencyPortalUtilsModule,
	],
	providers: [SchedulesAdminDataService, SchedulesAdminApiService, provideHttpClient(withInterceptorsFromDi())],
})
export class AdminToolsSchedulesModule {}

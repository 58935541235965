/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { SchedulesAdminDataService } from '../../services/schedules-admin-data.service';

import { Columns, ColumnType } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'schedule-admin-view-revision-history',
	templateUrl: './schedule-admin-view-revision-history.component.html',
	styleUrls: ['./schedule-admin-view-revision-history.component.scss'],
})
export class ScheduleAdminViewRevisionHistoryComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() active: boolean = true;

	public revisionHistoryListColumns: Columns = [];
	public revisionHistoryListData: Array<string> = [];
	public revisionHistoryLoadingIndicator: boolean = false;
	public initialized: boolean = false;

	private routeParams$Subscription: Subscription = null;
	private authorityId: string = null;
	private timestamp: string = '_timestamp';
	private rev: string = '_rev';
	private comment: string = '_comment';

	constructor(
		private schedulesAdminDataService: SchedulesAdminDataService,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the schedule view revision history component
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		// get params off the url
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.authorityId = params['authorityId'];

			this.buildRevisionHistoryListColumns();

			await this.getRevisionHistory();

			this.initialized = true;
		});
	}

	/**
	 * updates the contents of the schedulesrevsion history list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (): Promise<void> => {
		await this.getRevisionHistory();
	};

	/**
	 * retrieves list of schedule revision history entries that match the current page information and search
	 * text and populates the list accordingly
	 */
	public getRevisionHistory = async (): Promise<void> => {
		this.revisionHistoryLoadingIndicator = true;
		const result: ResultContent = await this.schedulesAdminDataService.getSchedulesRevisionHistory(this.authorityId);

		if (result.success) {
			this.revisionHistoryListData = result.resultData;
		}

		this.revisionHistoryLoadingIndicator = false;
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads the translations presented within the schedules revision history list
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_SCHEDULE.UPLOAD_DETAILS.DATETIME',
			'T_SCHEDULE.UPLOAD_DETAILS.REV',
			'T_SCHEDULE.UPLOAD_DETAILS.COMMENTS',
		]);
	};

	/**
	 * builds the schedule history revision list columns
	 */
	private buildRevisionHistoryListColumns = (): void => {
		this.revisionHistoryListColumns = [
			{
				name: this.timestamp,
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.DATETIME'],
				columnType: ColumnType.date,
				format: 'MM/dd/YYYY hh:mm a',
			},
			{
				name: this.rev,
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.REV'],
				columnType: ColumnType.text,
			},
			{
				name: this.comment,
				displayName: this.translations['T_SCHEDULE.UPLOAD_DETAILS.COMMENTS'],
				columnType: ColumnType.text,
			},
		];
	};
}

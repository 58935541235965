import moment, { Duration, Moment } from 'moment-timezone';

import { StringHelpers } from './string-helpers';

import { TimeFormatType } from '../types/types';

export class TimeHelpers {
	private static readonly months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

	/**
	 * Converts the supplied moment in time into a new date.
	 *
	 * Note this is different from the moment toDate function which creates a new Date for the SAME moment in time.
	 * This one creates a new date based on the moment yy/mm/dd/hh etc. contents, so will only be the same as the toDate function if
	 * this is called within GMT timezone.
	 *
	 * @param momentInTime - A moment in time.
	 * @returns Representative date taking primitive units - no time zone offsets
	 */
	public static createNewDateFromMoment = (momentInTime: Moment): Date => {
		return new Date(momentInTime.year(), momentInTime.month(), momentInTime.date(), momentInTime.hours(), momentInTime.minutes(), 0);
	};

	/**
	 * Converts the supplied moment datetime in to a time with leading zero's
	 *
	 * I.e 09:30 instead of 9:30 or 11:01 instead of 11:1
	 *
	 * @param momentInTime - A moment in time.
	 * @returns the time in HH:MM format with leading zeros'
	 */
	public static createNewTimeFromMoment = (momentInTime: Moment): string => {
		return StringHelpers.addLeadingZero(momentInTime.hours()) + ':' + StringHelpers.addLeadingZero(momentInTime.minutes());
	};

	/**
	 * Converts the datetimes to a formatted start - end time
	 *
	 * I.e 09:30 instead of 9:30 or 11:01 instead of 11:1
	 *
	 * @param startTime - the start time
	 * @param endTime - the end time
	 * @param startTimeEpoch - the start epoch
	 * @param timeZone - the timezone for conversion
	 * @param timeFormat - the time format required
	 * @returns formatted start - end time
	 */
	public static getStartEndTimes = (
		startTime: number,
		endTime: number,
		startTimeEpoch: number,
		timeZone: string,
		timeFormat: string
	): string => {
		const date: Date = new Date(startTimeEpoch);
		const month: string = TimeHelpers.months[date.getMonth()];

		const formattedStart: string = TimeHelpers.getTimes(timeZone, startTime, timeFormat);
		const formattedEnd: string = TimeHelpers.getTimes(timeZone, endTime, timeFormat);

		if (startTimeEpoch) {
			return month + ' ' + date.getDate() + ' / ' + formattedStart + ' - ' + formattedEnd;
		} else {
			return formattedStart + ' - ' + formattedEnd;
		}
	};

	/**
	 * Converts the supplied moment in time into a new date.
	 *
	 * Note this is different from the moment toDate function which creates a new Date for the SAME moment in time.
	 * This one creates a new date based on the moment yy/mm/dd. contents, so will only be the same as the toDate function if
	 * this is called within GMT timezone.
	 *
	 * @param momentInTime - A moment in time.
	 * @returns Representative date taking primitive units - no time zone offsets
	 */
	public static createNewDateOnlyFromMoment = (momentInTime: Moment): Date => {
		return new Date(momentInTime.year(), momentInTime.month(), momentInTime.date(), 0, 0, 0);
	};

	/**
	 * Retrieves the number of milliseconds from Epoch for the supplied date - ie UTC
	 *
	 * @param date - The date.
	 * @returns The number of milliseconds from epoch for the date
	 */
	public static getUtcMilliSecondsForDate = (date: Date): number => {
		return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
	};

	/**
	 * Retrieves the offset in minutes from UTC for the supplied moment in time
	 *
	 * @param momentInTime - A moment in time.
	 * @returns Offset in minutes from UTC for the supplied moment in time.
	 */
	public static getTimeZoneOffset = (momentInTime: Moment): number => {
		return momentInTime.utcOffset();
	};

	/**
	 * Creates the moment in time for now in the supplied time zone.
	 *
	 * @param timeZone - The time zone
	 * @returns The moment in time for now in the supplied time zone.
	 */
	public static createMomentNowForTimeZone = (timeZone: string): Moment => {
		return moment.tz(new Date(), timeZone);
	};

	/**
	 * Convert delay as seconds to a formatted string.
	 *
	 * @param seconds - value in seconds to be formatted mm:ss.
	 * @returns formatted value.
	 */
	public static toDelayTimeString = (seconds: number): string => {
		if (seconds) {
			const delay: string = (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds;

			return seconds > 0 ? `+${delay}` : `-${delay};`;
		} else {
			return '0:00';
		}
	};

	/**
	 * gets the timestamp in the appropriate format
	 *
	 * @param arrival - the arrival timestamp value
	 * @param format - the format to return
	 * @param agencyTimezone - the format to return
	 * @param timeAgoTranslation - the time 'ago' in the correct time translation
	 * @param replayTime - the replay timestamp
	 * @returns the fromatted timestamp
	 */
	public static getTimeByUserSelectedFormat = (
		arrival: any,
		format: TimeFormatType,
		agencyTimezone: string,
		timeAgoTranslation: string,
		replayTime: number = null
	): string => {
		if (format === TimeFormatType.clockTime) {
			return arrival.format('hh:mm A');
		} else {
			const currentTime: Moment = moment().tz(agencyTimezone);
			let duration: Duration = moment.duration(currentTime.diff(arrival));

			if (replayTime !== null) {
				const replayMoment: Moment = moment(replayTime).tz(agencyTimezone);

				duration = moment.duration(replayMoment.diff(arrival));
			}

			return TimeHelpers.getRelativeTimeFormatting(duration.asSeconds(), timeAgoTranslation);
		}
	};

	/**
	 * gets the seconds for a timestamp
	 *
	 * @param timestamp - the timestamp value
	 * @returns the seconds from the timestamp
	 */
	public static getSeconds = (timestamp: number): number => {
		timestamp = Math.floor(timestamp / 1000);
		const value: string = moment().format('x');
		const currentSeconds: number = Math.floor(parseInt(value, 10) / 1000);

		return currentSeconds - timestamp;
	};

	/**
	 * gets the seconds from the current replay time
	 *
	 * @param timestamp - the timestamp value
	 * @param replayTime - the replay time value
	 * @returns the appropriate seconds value to display.
	 */
	public static getSecondsFromReplayTime = (timestamp: number, replayTime: number): number => {
		timestamp = Math.floor(timestamp / 1000);
		const value: string = moment(replayTime).format('x');
		const currentSeconds: number = Math.floor(parseInt(value, 10) / 1000);

		return currentSeconds - timestamp;
	};

	/**
	 * Formats seconds in the a time to/ago format : h m s ago.
	 *
	 * @param secs - seconds to convert.
	 * @param timeAgoTranslation - the 'ago' text translation
	 * @returns time to/ago formatted string.
	 */
	public static getRelativeTimeFormatting = (secs: number, timeAgoTranslation: string): string => {
		const s: number = Math.floor(Math.abs(secs));

		let hours: number = 0;
		let minutes: number = 0;
		let seconds: number = 0;

		seconds = s % 60;
		minutes = Math.floor(s / 60) % 60;
		hours = Math.floor(s / 3600);

		let timeString: string = '';

		if (hours > 0) {
			timeString += hours + 'h ';
		}

		if (minutes > 0) {
			timeString += minutes + 'm ';
		}

		if (seconds > 0 && hours < 1) {
			timeString += seconds + 's ';
		}

		if (secs > 1) {
			timeString += timeAgoTranslation;
		}

		return timeString;
	};

	/**
	 * Format a number of seconds as 'hh:mm'.
	 *
	 * @param seconds - the number of seconds to format.
	 * @returns a formatted string.
	 */
	public static secondsToHm = (seconds: number): string => {
		return TimeHelpers.getSecondsSinceStartOfDay(seconds).format('HH:mm');
	};

	/**
	 * Format a number of seconds as 'hh:mm:ss'.
	 *
	 * @param seconds - the number of seconds to format.
	 * @returns a formatted string.
	 */
	public static secondsToHms = (seconds: number): string => {
		return TimeHelpers.getSecondsSinceStartOfDay(seconds).format('HH:mm:ss');
	};

	/**
	 * get the seconds since the start of day
	 *
	 * @param seconds - the number of seconds to format.
	 * @param timezone - the current timezone (optional)
	 * @returns a formatted string.
	 */
	public static getSecondsSinceStartOfDay = (seconds: number, timezone: string = null): Moment => {
		if (timezone) {
			return moment.tz(timezone).startOf('day').add(seconds, 'seconds');
		} else {
			return moment().startOf('day').add(seconds, 'seconds');
		}
	};

	/**
	 * Format a number of seconds for adherence time '+/-00:00:00'.
	 *
	 * @param seconds - the number of seconds to format.
	 * @returns a formatted string representing adherence time.
	 */
	public static formatAdherenceTime = (seconds: number): string => {
		const prefix: string = seconds > 0 ? '-' : '+';
		const time: string = prefix + TimeHelpers.formatTime(seconds);

		return time;
	};

	/**
	 * Format a number of seconds as time '00:00:00',
	 * returning '--' for time values of '0:00' dervied from seconds equal to zero or null.
	 *
	 * @param seconds - the number of seconds to format.
	 * @returns a formatted string representing headway time.
	 */
	public static formatHeadwayTime = (seconds: number): string => {
		const time: string = TimeHelpers.formatTime(seconds);

		return time === '0:00' ? '--' : time;
	};

	/**
	 * Format a number of seconds as time '00:00:00'.
	 *
	 * @param seconds - the number of seconds to format.
	 * @returns a formatted string representing adherence time.
	 */
	public static formatTime = (seconds: number): string => {
		seconds = seconds < 0 ? seconds * -1 : seconds;
		const duration: Duration = moment.duration(seconds * 1000);
		const hours: string = duration.hours() > 0 ? duration.hours() + ':' : '';
		const minutes: string = duration.minutes() > 0 ? duration.minutes() + ':' : '0:';
		const secs: string = duration.seconds() > 9 ? duration.seconds().toString() : '0' + duration.seconds();
		const time: string = hours + minutes + secs;

		return time;
	};

	/**
	 * Formats number of seconds as time a time difference
	 *
	 * @param timeInSeconds - the number of seconds to format.
	 * @returns a formatted string time.
	 */
	public static formatTimeDifference = (timeInSeconds: number): string => {
		let input: number = parseInt(timeInSeconds.toString(), 10);

		if (!input) {
			return timeInSeconds.toString();
		}

		const sign: string = input < 0 ? '-' : '+';

		input = Math.abs(input);
		const seconds: number = input % 60;
		const minutes: number = Math.floor((input % 3600) / 60);
		const hours: number = Math.floor(input / 3600);
		const hoursStr: string = hours ? hours + ':' : '';
		const minutesStr: string = hours > 0 ? StringHelpers.addLeadingZero(minutes) + ':' : minutes + ':';
		const secondsStr: string = StringHelpers.addLeadingZero(seconds);

		return sign + hoursStr + minutesStr + secondsStr;
	};

	/**
	 * Gets the number of seconds past midnight adjusted for the current timezone.
	 *
	 * @param timezone - the timezone to adjust the current date to.
	 * @returns a number of seconds.
	 */
	public static getSecondsPastMidnight = (timezone: string): number => {
		const currentTime: Moment = moment().tz(timezone);
		const midnight: Moment = moment().tz(timezone).startOf('day');

		return currentTime.unix() - midnight.unix();
	};

	/**
	 * Gets the time range for given start/end times for current timezone.
	 *
	 * @param start - start time
	 * @param end - end time
	 * @param timezone - the timezone to adjust the time range to.
	 * @returns formatted time range.
	 */
	public static formatTimeRange = (start: number, end: number, timezone: string): string => {
		const format: string = 'HH:mm';

		const startTime: string = TimeHelpers.getSecondsSinceStartOfDay(start, timezone).format(format);
		const endTime: string = TimeHelpers.getSecondsSinceStartOfDay(end, timezone).format(format);

		return startTime + ' - ' + endTime;
	};

	/**
	 * Gets the time in the appropriate format for a timezone.
	 *
	 * @param timezone - the timezone to adjust the time to.
	 * @param time - the time to format
	 * @param format - the format to use
	 * @returns formatted time.
	 */
	private static getTimes = (timezone: string, time: number, format: string): string => {
		return moment().tz(timezone).startOf('day').add(time, 'seconds').format(format);
	};
}

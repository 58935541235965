<div *ngIf="translationsLoaded" [ngClass]="{ 'progress-cursor': awaitingDisabledPredictionUpdate }" class="nb-view">
	<div class="panel-header-container">
		<panel-header [showBackButton]="false" [heading]="'T_PREDICTION.PREDICTION_CONTROL_HEADING' | translate"> </panel-header>
		<current-agency
			class="nb-panel-header"
			[titleText]="'T_PREDICTION.AGENCY_SELECTION_TITLE' | translate"
			[isDisabled]="false"
			(agencyUpdated)="handleAgencyUpdated()"></current-agency>
	</div>
	<div class="body-container">
		<button (click)="openDisablePredictionCreate()" class="btn btn-action disable-prediction-btn">
			<i class="nb-icons nb-deactivate"></i>
			{{ 'T_PREDICTION.DISABLE_ENABLE_PREDICTIONS' | translate }}
		</button>
		<div class="legend-container">
			<div class="legend prediction-disabled"></div>
			<span class="legend-text">{{ 'T_PREDICTION.DISABLED_LEGEND' | translate }}</span>
			<div class="legend prediction-pending-disabled"></div>
			<span class="legend-text">{{ 'T_PREDICTION.TO_BE_DISABLED_LEGEND' | translate }}</span>
			<div class="legend prediction-active"></div>
			<span class="legend-text">{{ 'T_PREDICTION.ENABLED_LEGEND' | translate }}</span>
		</div>
		<div class="route-filter-container">
			<div class="route-filter" *ngIf="routes?.length > 0">
				<label for="route">{{ 'T_PREDICTION.FILTER_BY_ROUTE' | translate }}&nbsp;&nbsp;</label>
				<select class="form-control" name="route" id="route" (change)="routeChanged($event.target)">
					<option *ngFor="let route of routes" value="{{ route.id }}">
						{{ route.title }}
					</option>
				</select>
			</div>
		</div>
		<br class="table-divide" />
		<div class="data-list-container">
			<data-table
				data-test="prediction-control.list.table"
				[listName]="'prediction-control'"
				[columns]="columns"
				[rowData]="disablePredictionFilteredTableData"
				[totalRows]="disablePredictionFilteredTableData?.length"
				[pagingEnabled]="false"
				[defaultSortings]="defaultSortings"
				[enableFooter]="true"
				[enableCheckRowSelection]="false"
				[loadingIndicator]="loadingIndicator"
				[noDataMessage]="'T_CORE.NO_DATA'"
				(rowClick)="onSelect($event)">
			</data-table>
		</div>
	</div>
</div>

<form [formGroup]="reactiveForm" class="form-group row">
	<div id="title-text" class="form-group">
		<div class="nb-required">
			<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
		</div>
		<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
		<input
			formControlName="title"
			data-test="title.input"
			type="text"
			id="title"
			class="form-control"
			placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
		<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
	</div>

	<div *ngIf="agencies?.length > 1">
		<div id="agency" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
			<select
				name="agencyId"
				id="agencyId"
				class="form-control"
				formControlName="agencyId"
				data-test="agency.selection"
				(change)="agencyChanged()">
				<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
					{{ agency.agency_name }}
				</option>
			</select>
			<input-validation [control]="reactiveForm.controls.agencyId"> </input-validation>
		</div>
	</div>

	<div class="nb-form-control">
		<div class="nb-checkbox">
			<label for="showUserPicture">
				<input
					id="showUserPicture"
					data-test="twitter.show-picture"
					type="checkbox"
					formControlName="showUserPicture"
					(change)="checkboxClicked($event)" />
				<div class="checkbox-label">{{ 'T_DASH.DB_SHOW_POSTING_USER_PICTURE' | translate }}</div>
			</label>
		</div>
	</div>
</form>

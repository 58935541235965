<div class="nb-view">
	<div class="header-container">
		<panel-header class="reports-panel-header" [showBackButton]="false" [heading]="'T_REPORT.REPORTS' | translate"> </panel-header>
	</div>

	<loading-overlay [isLoading]="loading"></loading-overlay>

	<div class="clearfix create-container">
		<div class="col-xs-12 nb-padding-left-md nb-padding-top-sm nb-padding-bottom-sm">
			<button (click)="createReport()" [disabled]="!createButtonEnabled" class="btn btn-primary pull-left" data-test="reports.create.btn">
				<i class="nb-icons nb-add"></i>{{ 'T_REPORT.CREATE_REPORT' | translate }}
			</button>
		</div>
	</div>

	<div>
		<mat-tab-group #tabs [selectedIndex]="activeTab" (selectedTabChange)="tabChanged($event)">
			<mat-tab label="{{ 'T_REPORT.AVAILABLE_REPORTS' | translate | uppercase }}">
				<available-reports-list #availableReports (setLoading)="setLoading($event)"></available-reports-list>
			</mat-tab>
			<mat-tab label="{{ 'T_REPORT.SAVED_REPORT_TEMPLATES' | translate | uppercase }}">
				<saved-report-templates-list #savedReportTemplates (setLoading)="setLoading($event)"></saved-report-templates-list>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

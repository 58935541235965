/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { ReportViewerSortableListBaseComponent } from '../../report-viewer-sortable-list-base.component';

import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { DataService } from '@cubicNx/libs/utils';
import { ReportsConfigService } from '../../../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import {
	InteractiveReportRiderCountByRouteByStopDetails,
	InteractiveReportRidershipCountTotalsDetails,
} from '../../../../../types/api-types';

@Component({
	selector: 'rc200-rider-count-by-route-by-stop-list',
	templateUrl: './rider-count-by-route-by-stop-list.component.html',
	styleUrls: ['./rider-count-by-route-by-stop-list.component.scss'],
})
export class RiderCountByRouteByStopListComponent extends ReportViewerSortableListBaseComponent implements OnInit {
	public listData: InteractiveReportRiderCountByRouteByStopDetails = null;
	public totalsData: InteractiveReportRidershipCountTotalsDetails = null;

	constructor(
		public dataService: DataService,
		protected override reportsEventsService: ReportsEventsService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsEventsService, reportsConfigService, colorUtilityService, translationService);

		this.defaultTemplateId = this.dataService['defaultTemplateId' as keyof DataService];
	}

	/**
	 * performs initialization tasks for the rider count by route by stop details list view (RC200)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (rider count by route by stop data)
	 */
	public updateListView = (listData: InteractiveReportRiderCountByRouteByStopDetails): void => {
		this.listData = listData;
	};

	/**
	 * sets up the list data totals
	 *
	 * @param totalsData - the list totals data (rider count totals data)
	 */
	public updateListTotalsView = (totalsData: InteractiveReportRidershipCountTotalsDetails): void => {
		this.totalsData = totalsData;
	};
}

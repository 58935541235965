import { TemplateRef } from '@angular/core';
export enum ColumnType {
	text,
	icon,
	selectableIcon,
	date,
	dateFromNow,
	subText, // used when we want additional subtext after the main column name
	component,
	cssClass,
	multiClass,
}

export enum SortDirection {
	asc = 'asc',
	desc = 'desc',
}

export enum SelectionByType {
	checkbox = 'checkbox',
	cell = 'cell',
}

export interface Column {
	name: string;
	displayName: string;
	displayNameDynamic?: Function; // this is a function to ensure changes are picked up automatically in the data table
	sortable?: boolean;
	columnType: ColumnType;
	hidden?: Function; // this is a function to ensure changes are picked up automatically in the data table
	format?: string; // applicable when columnType is date
	subText?: string; //applicable when columnType is subText
	width?: number; // initial width for a column
	fixedWidth?: number; // for fixed width columns
	componentTemplate?: TemplateRef<any>; // component ref for component type column
}

export type Columns = Array<Column>;

export interface SelectableIconTypeState {
	selected: any;
	disabled?: any;
	selectedIconClass: string;
	notSelectedIconClass: string;
	selectedIconDisabledClass?: string;
	notSelectedIconDisabledClass?: string;
}

// used when column type is SelectableIconType
export interface SelectableIconType {
	iconName: any;
	selectedState: SelectableIconTypeState;
	hidden?: Function; // this is a function to ensure changes are picked up automatically in the data table
}

// used when ColumnType is icon
export interface IconType {
	iconName: string;
	iconClass: string;
	iconType: string;
}

export interface CssClassType {
	value: string;
	className: string;
	tooltip: string;
	subClassName?: string;
	subClassValue?: string;
	subClassTooltip?: string;
	subClassPosition?: CssSubClassPosition;
}

export enum CssSubClassPosition {
	before,
	within,
	after,
}

export interface CssMultiClassTypeItem {
	value: string;
	tooltip: string;
	attributes?: any; // To store any additional data required by the parent upon clicking the item
}

export interface CssMultiClassType {
	items: Array<CssMultiClassTypeItem>;
	className: string;
}

export interface ListSorting {
	prop: string;
	dir: SortDirection;
}

export type ListSortings = Array<ListSorting>;

export interface PaginatedParams {
	pageNum?: number;
	pageSize?: number;
	sort?: string;
	sortDir?: string;
	search?: string;
}

export interface PageRequestInfo {
	pageNum?: number;
	pageSize?: number;
	sort?: string;
	sortDir?: SortDirection;
}

export interface SortRequestInfo {
	sort: string;
	sortDir: SortDirection;
}

export interface PaginatedResponse {
	nextPageUrl?: string;
	prevPageUrl?: string;
	total: number;
	totalPages: number;
}

// type returned from ngx control when setting page
export interface NgxPageInfo {
	offset: number;
	count?: number;
	limit?: number;
	pageSize?: number;
}

export interface SelectedRowData {
	row: any; // The row data is any because it is generic and row data means something different to each specific datatable use
	columnNameSelected: string;
}

export interface SelectedCssClassData {
	item: any; // The row data is any because it is generic and item data means something different to each specific datatable use
	columnNameSelected: string;
}

<div class="nb-image-view">
	<div class="nb-image-view-content">
		<span *ngIf="data">
			<div *ngIf="data?.file_path">
				<img [src]="data.file_path" [ngStyle]="getImageSize()" alt="Unavailable" />
			</div>
			<div *ngIf="!data?.file_path">
				<span>No file path for image: {{ data.name }}</span>
			</div>
		</span>
		<span *ngIf="!data" class="no-data-sm">
			{{ 'T_CORE.NO_RESULTS_AVAILABLE' | translate }}
		</span>
	</div>
</div>

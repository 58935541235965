import { Injectable } from '@angular/core';

import { ConfigService } from '../config/config.service';
import { LoggerService } from '../logging/logger.service';
import { StorageService } from '../storage/storage.service';

import { StorageType } from '../storage/types/types';

/**
 * Service responsible for managing User tokens.
 */
@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private apiToken: string = '';
	private apiKeyToken: string = '';

	constructor(
		private configService: ConfigService,
		private loggerService: LoggerService,
		private storageService: StorageService
	) {
		this.apiKeyToken = this.configService.getAppId() + '-token';
	}

	/**
	 * Remember the supplied API Token in session storage.
	 *
	 * @param token - The new API token.
	 */
	public setApiToken = (token: string): void => {
		this.loggerService.logDebug('Saving token: ', this.apiToken);
		// Create the token object and add into session storage
		this.apiToken = token;
		this.storageService.set(this.apiKeyToken, this.apiToken, StorageType.session);
	};

	/**
	 * Retrieve the current API Token.
	 *
	 * @returns The current API Token.
	 */
	public getApiToken = (): string => {
		if (!this.apiToken) {
			// Session storage is only being maintained for automated testing.
			// Essentially setting up the token in the same way as we would after a successful login.
			if (this.storageService.exists(this.apiKeyToken, StorageType.session)) {
				this.apiToken = this.storageService.get(this.apiKeyToken, StorageType.session);

				this.loggerService.logDebug('Retrieving token (session storage): ', this.apiToken);
			}
		}

		return this.apiToken;
	};

	/**
	 * Remove the current API Token.
	 */
	public destroyApiToken = (): void => {
		this.storageService.remove(this.apiKeyToken, StorageType.session);
		this.apiToken = '';
	};
}

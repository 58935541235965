/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TranslateBaseComponent } from '../../components/translate-base/translate-base.component';

import { ImageDataService } from './service/image-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '../../types/types';

@Component({
	selector: 'image-view',
	templateUrl: './image-view.component.html',
	styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent extends TranslateBaseComponent implements OnChanges {
	@Input() imageId: string = '';

	public data: any = null;

	constructor(
		private imageDataService: ImageDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handles changes to the input params
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.imageId.previousValue !== changes.imageId.currentValue) {
			if (this.imageId) {
				const response: ResultContent = await this.imageDataService.getImage(this.imageId);

				if (response.success) {
					this.data = response.resultData[0];
				}
			}
		}
	}

	/**
	 * determines the image size (width/height) in pixels
	 * =
	 * @returns the image size style info
	 */
	public getImageSize = (): any => {
		return { width: +this.data.width_px + 'px', height: +this.data.height_px + 'px' };
	};
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	/**
	 * pipe to transform html in to santized safe html for templates
	 *
	 * used to render dynamic html in our pages
	 *
	 * see https://blog.briebug.com/blog/how-do-i-display-html-inside-an-angular-binding
	 *
	 * @param value - the html to sanitize
	 * @returns the safe santized html
	 */
	transform = (value: any): SafeHtml => {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	};
}

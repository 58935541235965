/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { DisplayBlock } from '../types/types';
import { VehicleDetailsActiveTab } from '../../../../map/types/types';

@Component({
	selector: 'block-row',
	templateUrl: './block-row.component.html',
	styleUrls: ['./block-row.component.scss'],
})
export class BlockRowComponent implements OnInit, OnDestroy {
	@Input() displayBlock: DisplayBlock = null;

	@Output() mouseEnterEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() mouseLeaveEvent: EventEmitter<void> = new EventEmitter<void>();

	public translations: any = {};

	private isTooltipShown: boolean = false;

	constructor(
		private mapNavigationService: MapNavigationService,
		private translationService: TranslationService
	) {}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.closeTooltip();
	}

	/**
	 * performs initialization tasks for the current blocks widget row component - loading translations
	 */
	public ngOnInit(): void {
		this.translations['MAP_BLOCKS_FOR'] = this.translationService.getTranslation('T_MAP.MAP_BLOCKS_FOR');
	}

	/**
	 * Navigates to the vehicle view page.
	 *
	 * @param displayBlock - the block data containing the authorityId and vehicle text.
	 */
	public showVehicleView = (displayBlock: DisplayBlock): void => {
		if (displayBlock.text && displayBlock.text !== 'Unassigned') {
			this.mapNavigationService.navigateToVehicleDetails(
				displayBlock.authorityId,
				displayBlock.text,
				VehicleDetailsActiveTab.summary
			);
		}
	};

	/**
	 * Gets the block bar style.
	 *
	 * @param displayBlock - block data
	 * @returns html style object.
	 */
	public getBlockBarStyle = (displayBlock: DisplayBlock): any => {
		return {
			width: displayBlock.barFillPerc,
			'background-color': displayBlock.routeColor,
			cursor: displayBlock.vehicles?.length > 0 ? 'pointer' : 'default',
		};
	};

	/**
	 * Gets the state of the isTooltipShown boolean.
	 *
	 * @returns true if the tooltip is shown.
	 */
	public showTooltip = (): boolean => {
		return this.isTooltipShown;
	};

	/**
	 * Opens a tooltip for the specified block at the specified location.
	 * Firing a mouseEnterEvent emitting the block data and position.
	 *
	 * @param event - the mouseenter event.
	 */
	public openTooltip = (event: any): any => {
		const position: any = this.getPosition(event.target);

		this.mouseEnterEvent.emit({ position: { x: position.x, y: position.y }, displayBlock: this.displayBlock });
	};

	/**
	 * Closes the tooltip, firing a mouseLeaveEvent.
	 */
	public closeTooltip = (): void => {
		this.mouseLeaveEvent.emit();
	};

	/**
	 * Gets the target element's position.
	 *
	 * @param target - the target element.
	 * @returns an object with the x & y position.
	 */
	private getPosition = (target: any): any => {
		let offsetLeft: number = 0;
		let offsetTop: number = 0;

		let el: any = target;

		while (el) {
			offsetLeft += el.offsetLeft;
			offsetTop += el.offsetTop;
			el = el.offsetParent;
		}

		return { y: offsetTop, x: offsetLeft };
	};
}

<div class="headway-detail-sub-list-title grid-title">
	<div>{{ subListData.stop_name }}</div>
	<div class="nb-padding-top-sm" [ngStyle]="{ 'padding-bottom': '8px' }">
		<route-pill class="report-viewer-grid-route-pill" [route]="routeData" [useFullWidth]="true"></route-pill>
		<div class="report-viewer-grid-route-descriptor">
			<span>{{ subListData.route_long_name }} - {{ subListData.trip_headsign }}</span>
		</div>
	</div>
</div>
<table>
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'vehicle_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'vehicle_id')"
				class="ellipsis">
				{{ 'T_CORE.VEHICLE' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'block_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'block_id')"
				class="ellipsis">
				{{ 'T_CORE.BLOCK' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_id')"
				class="mw-col ellipsis">
				{{ 'T_CORE.TRIP' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'arrival_time' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'arrival_time')"
				class="mw-col ellipsis">
				{{ 'T_CORE.ARRIVAL_TIME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'departure_time' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'departure_time')"
				class="mw-col ellipsis">
				{{ 'T_CORE.DEPARTURE_TIME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'arrival_headway' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'arrival_headway')"
				class="mw-col ellipsis">
				{{ 'T_CORE.HEADWAY' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow">
			<td class="ellipsis">{{ reportDataItem.vehicle_id }}</td>
			<td class="ellipsis">{{ reportDataItem.block_id }}</td>
			<td class="ellipsis">{{ reportDataItem.trip_id }}</td>
			<td class="ellipsis">{{ reportDataItem.arrival_time | headwaySecondsToHoursMinutes }}</td>
			<td class="ellipsis">{{ reportDataItem.departure_time | headwaySecondsToHoursMinutes }}</td>
			<td class="ellipsis">
				<headway-adherence-display
					[target]="reportDataItem.target_headway | headwaySecondsToHoursMinutes"
					[headway]="reportDataItem.arrival_headway | headwaySecondsToHoursMinutes"
					[authorityId]="authorityId"
					[agencyId]="agencyId">
				</headway-adherence-display>
			</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

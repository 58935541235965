/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AgenciesEventsService {
	public agenciesSelectionChange: Observable<any>;

	private agenciesSelectionChangeSource: Subject<any> = new Subject<any>();

	constructor() {
		this.agenciesSelectionChange = this.agenciesSelectionChangeSource.asObservable();
	}

	/**
	 * Publish a agencies selection change event allowing subscribers to react to agency change actions.
	 * @param event - an object containing data to pass to subscribers.
	 */
	public publishAgenciesSelectionChange = (): void => {
		// @ts-ignore
		this.agenciesSelectionChangeSource.next();
	};
}

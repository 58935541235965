/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ConfigService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'set-password',
	templateUrl: './set-password.component.html',
	styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent extends TranslateBaseComponent implements OnInit {
	@Input() currentPassword: string = null;
	@Input() showFieldLabels: boolean = false;
	@Input() passwordTitle: string = 'T_CORE.PASSWORD';

	@Output() passwordEntryValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() newPassword: EventEmitter<string> = new EventEmitter<string>();

	public setPasswordForm: FormGroup;
	public initialized: boolean = false;

	private passwordValidators: { validator: string; error: string }[];

	constructor(
		private formBuilder: FormBuilder,
		private configService: ConfigService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the set password component
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.setPasswordForm = this.formBuilder.group(
			{
				password: ['', Validators.required],
				passwordConfirm: ['', Validators.required],
			},
			{}
		);

		this.passwordValidators = this.configService.getPasswordValidators();
	}

	/**
	 * validates tge password
	 */
	public validatePassword = (): void => {
		if (!this.setPasswordForm.controls.password.errors?.required) {
			// the field has passed the required validation - run through our custom validators
			this.validateCustom('password');
		}

		// if there is a current password (i.e if we are here due to a change password rather than creating a password for new user)
		if (this.currentPassword) {
			// make sure the user isnt picking the existing password
			if (this.setPasswordForm.controls.password.value === this.currentPassword) {
				this.setPasswordForm.controls.password.setErrors({ 'passwords-match': true });
			}
		}

		// recheck the confirm password in case we change the first password so it no longer matches
		this.validatePasswordConfirm();

		// update parent
		this.setPassword();
	};

	/**
	 * validates the password confirm
	 */
	public validatePasswordConfirm = (): void => {
		if (!this.setPasswordForm.controls.passwordConfirm.errors?.required) {
			// the field has passed the required validation - check for custom no match error (invalidates the form)
			if (this.setPasswordForm.controls.password.value !== this.setPasswordForm.controls.passwordConfirm.value) {
				this.setPasswordForm.controls.passwordConfirm.setErrors({ 'no-match': true });
			}
		}

		// update parent
		this.setPassword();
	};

	/**
	 * informs parent that the new password is valid and its value
	 */
	public setPassword = (): void => {
		// emit form validity to parent
		this.passwordEntryValid.emit(this.setPasswordForm.valid);

		if (this.setPasswordForm.valid) {
			this.newPassword.emit(this.setPasswordForm.controls.password.value);
		}
	};

	/**
	 * performs initialization tasks for the set password component
	 */
	private loadTranslations = async (): Promise<void> => {
		this.initialized = true;
	};

	/**
	 * performs password validation
	 *
	 * @param field - field
	 */
	private validateCustom = (field: string): void => {
		// validate the password based on our custom password requirements
		if (this.passwordValidators) {
			// use a regular for loop (can't return out of a foreach and we just want the first error to be set)
			for (const v of this.passwordValidators) {
				const regexp: RegExp = RegExp(v.validator);

				if (!regexp.test(this.setPasswordForm.controls[field].value)) {
					this.setPasswordForm.controls[field].setErrors({ [v.error]: true });

					return;
				}
			}
		}
	};
}

<div *ngIf="!vehicleLoaded">
	<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
</div>
<div *ngIf="vehicleLoaded">
	<div class="nb-map-subpage-header-container" id="map-details-vehicle-header">
		<div class="header-backbutton move-up">
			<button (click)="goBack()" class="btn btn-link-dark" data-test="vehicle.details.back.btn">
				<i class="nb-icons nb-backarrow"></i>
			</button>
		</div>
		<div class="header-content">
			<div class="header-title">
				<span class="nb-subhead sub-header-label">
					<span>{{ 'T_MAP.MAP_VEHICLE' | translate }} </span>
					<span data-test="vehicle.details.vehicleId">{{ vehicleId }}</span>
				</span>
				<div class="sub-header-expanded" (click)="toggleExpandedSection()">
					<i
						class="sub-header-expanded-section"
						data-test="vehicle.details.showMore"
						[ngClass]="showExpandedSection ? 'nb-icons nb-filter-up' : 'nb-icons nb-filter-down'">
					</i>
				</div>
			</div>
			<div>
				<div class="header-subtitle">
					<div class="row">
						<div class="column col-xs-6">
							<span class="view-table-label">{{ 'T_MAP.MAP_ROUTE' | translate }}</span>
							<br />
							<route-pill
								*ngIf="vehicleRouteDisplay"
								[route]="vehicleRouteDisplay"
								[useFullWidth]="true"
								(click)="navigateToRouteDetails(vehicle.current_state.route?.route_id)"
								data-test="vehicle.details.pill"
								class="header-body-route-pill">
							</route-pill>
							<span *ngIf="!vehicleRouteDisplay">{{ emDash }}</span>
						</div>
						<div class="column col-xs-6">
							<span class="view-table-label">{{ 'T_MAP.MAP_STATUS' | translate }}</span>
							<br />
							<span class="vehicle-status" data-test="vehicle.details.status">{{ vehicleStatusDisplay }}</span>
						</div>
					</div>
					<br />
					<div class="row">
						<div class="column col-xs-4">
							<span class="view-table-label">{{ 'T_MAP.MAP_DIRECTION' | translate }}</span>
							<br />
							<span class="view-table-field" data-test="vehicle.details.direction">{{ vehicleDirectionDisplay || emDash }}</span>
						</div>
						<div class="column col-xs-4">
							<span class="view-table-label">{{ 'T_MAP.MAP_BLOCK' | translate }}</span>
							<br />
							<span
								class="view-table-field"
								[ngClass]="{ 'header-body-block': vehicleBlockIdDisplay }"
								data-test="vehicle.details.block"
								(click)="viewBlockDetails()"
								>{{ vehicleBlockIdDisplay || emDash }}</span
							>
						</div>
						<div class="column col-xs-4">
							<span class="view-table-label">{{ 'T_MAP.MAP_RIDERSHIP' | translate }}</span>
							<br />
							<span
								*ngIf="ridershipIsValid(vehicle.current_state.passengers.onboard, vehicle.current_state.passengers.ridershipEnum)"
								class="view-table-field"
								data-test="vehicle.details.ridership">
								{{ vehicle.current_state.passengers.onboard }} &nbsp;({{
									getRidershipPercent(vehicle.current_state.passengers.ridershipPercentage)
								}}%)
								<img
									class="header-body-ridership"
									[src]="vehicle.current_state.passengers.ridershipEnum | ridershipImage"
									alt="Unavailable" />
							</span>
							<span
								*ngIf="replayRidershipIsValid(vehicle.current_state.passengers.onboard)"
								class="view-table-field"
								data-test="vehicle.details.ridership">
								{{ vehicle.current_state.passengers.onboard }}
							</span>
							<span
								*ngIf="
									!ridershipIsValid(vehicle.current_state.passengers.onboard, vehicle.current_state.passengers.ridershipEnum) &&
									!replayRidershipIsValid(vehicle.current_state.passengers.onboard)
								"
								class="view-table-field"
								data-test="vehicle.details.ridership"
								>{{ emDash }}
							</span>
						</div>
					</div>
					<br />
				</div>
			</div>
			<div class="header-body" *ngIf="showExpandedSection">
				<div>
					<div class="row">
						<div class="column col-xs-4">
							<span class="view-table-label">{{ 'T_MAP.MAP_LOCATION' | translate }}</span>
							<br />
							<span class="view-table-field" data-test="vehicle.details.location">{{ vehicleLocationDisplay || emDash }}</span>
						</div>
						<div class="column col-xs-4">
							<span class="view-table-label">{{ 'T_MAP.MAP_LAST_AVL_RECEIVED' | translate }}</span>
							<br />
							<span class="view-table-field">{{ lastAVLReceivedAgoDisplay || emDash }}</span>
							<br />
							<span class="view-table-field">{{ lastAVLReceivedDisplay || emDash }}</span>
						</div>
						<div class="column col-xs-4">
							<div>
								<span class="view-table-label">{{ 'T_MAP.MAP_LAST_GPS_REPORT' | translate }}</span>
								<br />
								<span class="view-table-field" data-test="vehicle.details.gps-ago">{{ lastGPSReceivedAgoDisplay || emDash }}</span>
								<br />
								<span class="view-table-field" data-test="vehicle.details.gps-time">{{ lastGPSReceivedDisplay || emDash }}</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="map-vehicle-compass-speedometer">
							<div class="column col-xs-4">
								<div>
									<img src="/assets/img/compass.svg" class="map-details-vehicle-compass" alt="Unavailable" />
									<img
										src="/assets/img/compass-needle.svg"
										class="map-details-vehicle-compass-needle"
										[ngStyle]="{ transform: 'rotate(' + headingDegDisplay + 'deg)' }"
										alt="Unavailable" />
								</div>
							</div>
							<div class="column col-xs-4">
								<div *ngIf="speedoDegDisplay.units === 'KM/H'">
									<img src="/assets/img/kph-speedometer.svg" class="map-details-vehicle-speedo" alt="Unavailable" />
									<img
										src="/assets/img/speedometer-needle_centeraligned.svg"
										class="map-details-vehicle-speedo-needle"
										[ngStyle]="{ transform: 'rotate(' + speedoDegDisplay.deg + 'deg)' }"
										alt="Unavailable" />
									<div class="map-details-vehicle-speedo-digits-container">
										<div class="digits" data-test="vehicle.details.speed">{{ speedoDegDisplay.speed }}</div>
										<div class="units" data-test="vehicle.details.speed-units">{{ speedoDegDisplay.units }}</div>
									</div>
								</div>
								<div *ngIf="speedoDegDisplay.units !== 'KM/H'">
									<img src="/assets/img/mph-speedometer.svg" class="map-details-vehicle-speedo" alt="Unavailable" />
									<img
										src="/assets/img/speedometer-needle_centeraligned.svg"
										class="map-details-vehicle-speedo-needle"
										[ngStyle]="{ transform: 'rotate(' + speedoDegDisplay.deg + 'deg)' }"
										alt="Unavailable" />
									<div class="map-details-vehicle-speedo-digits-container">
										<div class="digits" data-test="vehicle.details.speed">{{ speedoDegDisplay.speed }}</div>
										<div class="units" data-test="vehicle.details.speed-units">{{ speedoDegDisplay.units }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="flex" class="check-boxes-container">
		<div class="check-boxes-sub-container">
			<div class="check-boxes-show-vehicle-container">
				<div class="nb-form-control check-boxes-show-vehicle-form">
					<div class="nb-checkbox">
						<mat-checkbox
							data-test="vehicle.details.showVehicle"
							[disabled]="!showVehicleEnabled()"
							[checked]="vehicleDisplayed()"
							(click)="showVehicleEnabled() ? toggleRenderVehicle() : $event.stopPropagation()">
							{{ 'T_MAP.MAP_SHOW_VEH' | translate }}
						</mat-checkbox>
					</div>
				</div>
			</div>
			<div class="check-boxes-show-route-container">
				<div class="nb-form-control check-boxes-show-route-form">
					<div class="nb-checkbox">
						<mat-checkbox
							data-test="vehicle.details.showRoute"
							[disabled]="!showRouteEnabled()"
							[checked]="routeDisplayed()"
							(click)="showRouteEnabled() ? toggleRenderRoute() : $event.stopPropagation()">
							{{ 'T_MAP.MAP_SHOW_RTE' | translate }}
						</mat-checkbox>
					</div>
				</div>
			</div>

			<div class="check-boxes-tracking-container">
				<div class="nb-form-control check-boxes-tracking-form">
					<div class="nb-checkbox">
						<div [attr.title]="vehicleDisplayed() ? null : getTranslation('T_MAP.MAP_VEHICLE_ACTION_DISABLED')">
							<mat-checkbox
								data-test="vehicle.details.track"
								[disabled]="!vehicleTrackTrailEnabled()"
								[checked]="vehicleId === mapVehicleTrackService.getTrackedVehicleId()"
								(click)="vehicleTrackTrailEnabled() ? changeTrackVehicle() : $event.stopPropagation()">
								{{ 'T_MAP.MAP_TRACK' | translate }}
							</mat-checkbox>
						</div>
					</div>
				</div>
			</div>
			<div class="check-boxes-trails-container">
				<div class="nb-form-control check-boxes-trails-form">
					<div class="nb-checkbox">
						<div [attr.title]="vehicleDisplayed() ? null : getTranslation('T_MAP.MAP_VEHICLE_ACTION_DISABLED')">
							<mat-checkbox
								data-test="vehicle.details.showTrails"
								[disabled]="!vehicleTrackTrailEnabled()"
								[checked]="vehicleId === mapVehicleTrailService.getTrailedVehicleId()"
								(click)="vehicleTrackTrailEnabled() ? changeShowingVehicleTrail() : $event.stopPropagation()">
								{{ 'T_MAP.MAP_TRAIL' | translate }}
							</mat-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="nb-mapheader-toolbar flex" id="map-details-vehicle-toolbar">
		<button
			class="btn btn-action"
			data-test="vehicle.details.zoomTo"
			[attr.title]="vehicleDisplayed() ? getTranslation('T_MAP.MAP_VIEW_ZOOM_TO') : getTranslation('T_MAP.MAP_VEHICLE_ACTION_DISABLED')"
			[disabled]="!zoomEnabled()"
			(click)="zoomTo()">
			<i class="nb-icons nb-zoomtoobject" aria-hidden="true"></i> <span>{{ 'T_MAP.MAP_VIEW_ZOOM_TO' | translate }}</span>
		</button>
		<button
			class="btn btn-action reassign-button"
			data-test="vehicle.details.reassign"
			[disabled]="!hasVehicleReassignPermission || replayModeActive()"
			title="{{ vehicleReassignButtonTooltip }}"
			(click)="showVehicleReAssign()">
			<i class="nb-icons nb-reassign" aria-hidden="true"></i> <span>{{ 'T_MAP.MAP_REASSIGN' | translate }}</span>
		</button>
	</div>
	<div class="tab-container">
		<mat-tab-group animationDuration="0ms" [selectedIndex]="activeTab" (selectedTabChange)="tabChanged($event)">
			<mat-tab label="{{ 'T_MAP.MAP_SUMMARY' | translate | uppercase }}">
				<div class="nb-map-details-vehicle-wrapper nb-map-details-body">
					<div class="content-block-no-border" data-test="vehicles.nextstops">
						<div class="sectiontitle-block">
							<div class="nb-section-title">
								<div>
									<span>{{ 'T_MAP.MAP_NEXT_STOPS' | translate }}</span>
								</div>
							</div>
						</div>
						<div class="data-list-container" *ngIf="nextStopsLoaded && vehicleStatus !== vehicleStatusDisplayType.stale && showNextStops">
							<data-table
								#dataList
								data-test="vehicle.details.stops.table"
								[listName]="stopsListName"
								[columns]="stopListColumns"
								[rowData]="nextStops"
								[pagingEnabled]="false"
								[externalSorting]="true"
								[sortInfo]="stopsListSortings"
								[enableFooter]="false"
								[rowHeight]="28"
								[enableFooter]="false"
								[loadingIndicator]="listLoadingIndicator"
								[noDataMessage]="'T_CORE.NO_NEXT_STOPS_INFO'"
								(sortData)="handleSortStopsList($event)">
							</data-table>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab [disabled]="replayModeActive()" label="{{ getTranslation('T_MAP.MAP_EVENTS') | uppercase }}">
				<div class="nb-map-details-vehicle-wrapper nb-map-details-body">
					<div class="content-block-no-border">
						<!-- template to pass in to the data table for expanded view. Note row will contain the list row at runtime -->
						<ng-template #expandedDetailTemplate let-row="row">
							<br /><span>{{ getTranslation('T_DASH.EVENTS.DESCRIPTIONS.' + row.type) }}</span
							><br /><br />
						</ng-template>

						<div class="data-list-container" *ngIf="vehicleEventsLoaded && showVehicleEvents">
							<data-table
								#dataList
								data-test="vehicle.details.events.table"
								[listName]="eventsListName"
								[columns]="eventsListColumns"
								[rowData]="vehicleEvents"
								[pagingEnabled]="false"
								[externalSorting]="true"
								[sortInfo]="eventsListSortings"
								[enableFooter]="false"
								[enableFooter]="false"
								[loadingIndicator]="listLoadingIndicator"
								[expandedDetailTemplate]="expandedDetailTemplate"
								[noDataMessage]="'T_MAP.MAP_NO_EVENTS'"
								(sortData)="handleSortEventsList($event)">
							</data-table>
						</div>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

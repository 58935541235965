/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Injector, Input, OnInit, StaticProvider, Type } from '@angular/core';

import { ComponentPortal } from '@angular/cdk/portal';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsViewLookupService } from '../../services/reports-view-lookup.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { ReportsDataService } from '../../services/reports-data.service';
import { DataService } from '@cubicNx/libs/utils';

import { ReportSummaryPortalDetails, ReportTemplateSummaryContent } from '../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'report-viewer-summary',
	templateUrl: './report-viewer-summary.component.html',
	styleUrls: ['./report-viewer-summary.component.scss'],
})
export class ReportViewerSummaryComponent extends TranslateBaseComponent implements OnInit {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = null;
	@Input() defaultTemplateId: string = null;
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() createdById: number = null;
	@Input() reportId: string = null;

	// Each summary response can have different types so no type safety here
	public summaryData: any = null;

	public enabled: boolean = false;
	public loading: boolean = true;

	public portal: ComponentPortal<any> = null;

	private portalData: ReportSummaryPortalDetails = {
		params: {
			summaryData: null,
			templateSummaryContent: null,
		},
		view: null,
	};

	constructor(
		private reportViewLookupService: ReportsViewLookupService,
		private reportsDataService: ReportsDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the report viewer summary compoenent
	 *
	 * gets summary view configuration and loads values, and loads dynamic summary component
	 */
	public async ngOnInit(): Promise<void> {
		const summaryView: Type<any> = this.reportViewLookupService.getSummaryView(this.defaultTemplateId);

		if (summaryView) {
			this.enabled = true;

			await this.loadSummaryData();

			// Setup the dynamic report summary component with relevant input parameters
			this.portalData.params.summaryData = this.summaryData;
			this.portalData.params.templateSummaryContent = this.templateSummaryContent;

			this.portalData.view = summaryView;

			const sp: Array<StaticProvider> = [{ provide: DataService, useValue: this.portalData.params }];

			const dynamicComponentInjector: Injector = Injector.create({ providers: sp });

			this.portal = new ComponentPortal(this.portalData.view, null, dynamicComponentInjector);
		}

		this.loading = false;
	}

	/**
	 * retrieves interactive report summary part and loads the summary data
	 */
	private loadSummaryData = async (): Promise<void> => {
		const response: ResultContent = await this.reportsDataService.getInteractiveReportSummary(
			this.authorityId,
			this.agencyId,
			this.reportId,
			this.createdById,
			this.defaultTemplateId
		);

		if (response.success) {
			// Each summary can have different types so no type safety here
			this.summaryData = response.resultData;
		}
	};
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReportViewerBaseComponent } from '../../report-viewer-base.component';

import { ReportsConfigService } from '../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { DefaultPagination, Pagination, PaginationSettings } from '@cubicNx/libs/utils';
import { ReportConfiguration, SortDetails, SortFieldType } from '../../../types/types';
import { RoutePillData } from '@cubicNx/libs/utils';

@Component({
	template: '',
})
export class ReportViewerSortableSubListBaseComponent extends ReportViewerBaseComponent {
	@Output() pageSizeChanged: EventEmitter<void> = new EventEmitter<void>();

	@Input() subListData: any = null;
	@Input() defaultTemplateId: string = null;

	public SortFieldType: typeof SortFieldType = SortFieldType;

	public sortDetails: SortDetails = null;
	public sortClass: string = null;
	public routeData: RoutePillData = null;
	public currentItemsToShow: any = null;
	public showSubPagingToolbar: boolean = true;
	public paginationSettings: PaginationSettings = null;
	public pagination: Pagination = { ...DefaultPagination };

	private defaultSortName: string = null;

	constructor(
		protected reportsConfigService: ReportsConfigService,
		protected colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * manages client side sorting of the supplied sub list column
	 *
	 * @param listData - the sub list data
	 * @param sortField - the sort by field
	 * @param sortFieldType - the sort by field type
	 */
	public toggleSortList = (listData: any, sortField: string, sortFieldType: SortFieldType = SortFieldType.default): void => {
		this.pagination.currentPage = 1;

		const previousSortField: string = this.sortDetails.field;

		this.sortDetails = {
			field: sortField,
			dir: sortField === previousSortField ? (this.sortDetails.dir === 'asc' ? 'desc' : 'asc') : 'asc',
		};

		this.sortClass = this.sortDetails.dir === 'asc' ? 'sort sort-by-asc' : 'sort sort-by-desc';

		this.sortList(listData, sortField, sortFieldType);
	};

	/**
	 * manages the page number change request for the sub list data
	 *
	 * @param listData - the sub list data
	 */
	public pageChanged = (listData: any): void => {
		this.setSubItemsToShow(listData);
	};

	/**
	 * manages the page size change request for the sub list data
	 *
	 * @param listData - the sub list data
	 */
	public setPageSize = (listData: any): void => {
		this.pagination.currentPage = 1;
		this.setSubItemsToShow(listData);

		// emit up to parent to allow scrollbar to be repositioned
		this.pageSizeChanged.emit();
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param item - the item data
	 * @returns route pill
	 */
	public determineRoutePillData = (item: any): RoutePillData => {
		return this.createRoutePillData(
			item.route_short_name,
			item.route_long_name,
			item.route_id,
			item.route_color,
			item.route_text_color
		);
	};

	/**
	 * resets the sub list sort criteria
	 */
	public resetSubListSorting = (): void => {
		this.sortDetails = { field: this.defaultSortName, dir: 'asc' };
		this.sortClass = 'sort sort-by-asc';
	};

	/**
	 * performs sub list initialization tasks
	 */
	protected init(): void {
		// Setup defaults
		this.setupDefaults(this.reportsConfigService.getReportConfiguration(this.defaultTemplateId));
		this.resetSubListData(this.subListData.data);
		this.sortList(this.subListData.data, this.defaultSortName);

		// This is used for route pills that exist once in the title for each sub list
		this.routeData = this.createRoutePillData(
			this.subListData.route_short_name,
			this.subListData.route_long_name,
			this.subListData.route_id,
			this.subListData.route_color,
			this.subListData.route_text_color
		);
	}

	/**
	 * createa a route pill instance from underlying data
	 *
	 * @param listData - the list data
	 * @param routeShortName - the route short name
	 * @param routeLongName - the route long name
	 * @param routeId - the route id
	 * @param routeColor - the route color
	 * @param routeTextColor - the route text color
	 * @returns route pill
	 */
	private createRoutePillData = (
		routeShortName: string,
		routeLongName: string,
		routeId: string,
		routeColor: number,
		routeTextColor: number
	): RoutePillData => ({
		routeShortName,
		routeLongName,
		routeId,
		routeColor: this.colorUtilityService.getColor(routeColor),
		routeTextColor: this.colorUtilityService.getColor(routeTextColor),
	});

	/**
	 * sets up the page and sort defaults from the supplied configuration
	 *
	 * @param reportConfig - the report configuration
	 */
	private setupDefaults = (reportConfig: ReportConfiguration): void => {
		this.paginationSettings = {
			maxPageSize: reportConfig.subViewPaginationSettings.maxPageSize,
			pageSize: reportConfig.subViewPaginationSettings.initialPageSize,
		};

		this.defaultSortName = reportConfig.defaultSortName;
	};

	/**
	 * resets the sub list data
	 *
	 * sorting, paging
	 *
	 * @param listData - the sub list data
	 */
	private resetSubListData = (listData: any): void => {
		this.resetSubListSorting();
		this.resetSubListPaging(listData);
		this.setSubItemsToShow(listData);
	};

	/**
	 * performs client side sorting of the sub list
	 *
	 * @param listData - the sub list data
	 * @param sortField - the sort by field
	 * @param sortFieldType - the sort field type
	 */
	private sortList = (listData: any, sortField: string, sortFieldType: SortFieldType = SortFieldType.default): void => {
		// we may be sorting on a simple field such as stop_name but there may be a further nested property we wish to sort on
		// i.e very_early.percent.  The following approach will ensure we are sorting on the inner property i.e percent when required
		const sortProperty: string[] = sortField.split('.');

		// We use different sorting methods based on sortFieldType
		switch (sortFieldType) {
			case SortFieldType.timeDelta:
				// Used for sorting time deltas (e.g. adherence values)
				listData.sort((a: any, b: any) => {
					let i: number = 0;

					while (i < sortProperty.length) {
						a = a[sortProperty[i]];
						b = b[sortProperty[i]];
						i++;
					}

					// Remove hour/minute/second separators
					a = a.split(':').join('');
					b = b.split(':').join('');

					// Convert to int
					a = parseInt(a, 10);
					b = parseInt(b, 10);

					// Compare
					if (a < b) {
						return this.sortDetails.dir === 'asc' ? -1 : 1;
					} else if (a > b) {
						return this.sortDetails.dir === 'asc' ? 1 : -1;
					} else {
						return 0;
					}
				});
				break;
			case SortFieldType.default:
				// Default sorting method
				listData.sort((a: any, b: any) => {
					let i: number = 0;

					while (i < sortProperty.length) {
						a = a[sortProperty[i]];
						b = b[sortProperty[i]];
						i++;
					}

					if (a < b) {
						return this.sortDetails.dir === 'asc' ? -1 : 1;
					} else if (a > b) {
						return this.sortDetails.dir === 'asc' ? 1 : -1;
					} else {
						return 0;
					}
				});
				break;
		}

		this.setSubItemsToShow(listData);
	};

	/**
	 * manages the rendering of the sub list items, based on paging and sorting criteria
	 *
	 * @param listData - the sub list data
	 */
	private setSubItemsToShow = (listData: any): void => {
		const pageSize: number = this.paginationSettings.pageSize;
		const currentPage: number = this.pagination.currentPage;

		this.currentItemsToShow = listData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
	};

	/**
	 * resets the sub list paging details
	 *
	 * @param listData - the sub list data
	 */
	private resetSubListPaging = (listData: any): void => {
		this.pagination = { ...DefaultPagination };
		this.pagination.totalItems = listData.length;
	};
}

<div class="nb-view-table">
	<div class="nb-view-table-content">
		<div class="row">
			<div class="heading nb-label">{{ 'T_CORE.USERNAME' | translate }}</div>
			<div class="content nb-text truncate">{{ user.username }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_CORE.NAME' | translate }}</div>
			<div class="content nb-text truncate">{{ user.real_name }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_CORE.EMAIL' | translate }}</div>
			<div class="content nb-text truncate">{{ user.email }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_CORE.LAST_LOGIN' | translate }}</div>
			<div class="content nb-text truncate">{{ user.last_login ? (user.last_login | date: 'short') : '' }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_CORE.STATUS' | translate }}</div>
			<div class="content nb-text truncate">{{ getTranslation('T_USER.USER_STATUS.' + (user.status | uppercase)) }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_CORE.CREATED' | translate }}</div>
			<div class="content nb-text truncate">{{ user.created | date: 'short' }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_USER.DEACTIVATED_DATE' | translate }}</div>
			<div class="content nb-text truncate">{{ user.deactivated_date ? (user.deactivated_date | date: 'short') : '' }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_USER.LOCKED_DATE' | translate }}</div>
			<div class="content nb-text truncate">{{ user.lock_date ? (user.lock_date | date: 'short') : '' }}</div>
		</div>
		<div class="row">
			<div class="heading nb-label">{{ 'T_USER.PASSWORD_CHANGED_DATE' | translate }}</div>
			<div class="content nb-text truncate">
				{{ user.password_last_changed_date ? (user.password_last_changed_date | date: 'short') : '' }}
			</div>
		</div>

		<div class="row" *ngFor="let role of userRoles; first as isFirst">
			<div *ngIf="isFirst" class="heading nb-label">{{ 'T_USER.USER_ROLES' | translate }}</div>
			<div *ngIf="!isFirst" class="heading nb-label"></div>

			<div *ngIf="role.agency_info; then useAgencyInfo; else useAssociatedRoleName"></div>

			<ng-template #useAgencyInfo>
				<div *ngIf="role.agency_info" class="content nb-text truncate">
					{{ role.agency_info.agency_name + ' - ' + getTranslation('T_USER.' + role.associated_role.international_name | uppercase) }}
				</div>
			</ng-template>

			<ng-template #useAssociatedRoleName>
				<div *ngIf="!role.agency_info" class="content nb-text truncate">{{ role.associated_role.name }}</div>
			</ng-template>
		</div>
	</div>
</div>

<!--
* COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
*
* Information Contained Herein is Proprietary and Confidential.
* The document is the property of "CUBIC" and may not be disclosed
* distributed, or reproduced  without the express written permission of
* "CUBIC".
-->
<nav class="nb-left-nav navbar-default" role="navigation">
	<div id="left-nav-side" class="sidebar-collapse clearfix">
		<ul class="nav metismenu" id="side-menu">
			<li
				[ngClass]="{ active: getLocation().includes('dashboard') }"
				class="nb-side-nav"
				[attr.title]="hasMiniNavBar() ? getTranslation('T_CORE.DASHBOARD') : null">
				<a (click)="navigate('/dashboard')" class="nb-side-nav-flex-column" aria-label="Dashboard" data-test="nav.dashboard">
					<i class="nb-side-nav-icons nb-icons nb-dashboard nb-side-nav-flex"></i>
					<span class="nav-label translate-cloak">{{ 'T_CORE.DASHBOARD' | translate }}</span>
				</a>
			</li>
			<li
				[ngClass]="{ active: getLocation().includes('map') }"
				class="nb-side-nav"
				[attr.title]="hasMiniNavBar() ? getTranslation('T_CORE.LIVE_OPS') : null">
				<a (click)="navigate('/map')" class="nb-side-nav-flex-column" aria-label="Live Ops" data-test="nav.maps">
					<i class="nb-side-nav-icons nb-icons nb-liveops nb-side-nav-flex"></i>
					<span class="nav-label">{{ 'T_CORE.LIVE_OPS' | translate }}</span>
				</a>
			</li>
			<li
				[ngClass]="{ active: getLocation().includes('rider-messages'), 'cursor-disable': !riderMessageEnabled }"
				class="nb-side-nav"
				[attr.title]="
					!riderMessageEnabled
						? getTranslation('T_MAP.MAP_FEATURE_DISABLED_TOOLTIP')
						: hasMiniNavBar()
							? getTranslation('T_RIDER.RIDER_MESSAGES')
							: null
				">
				<a
					(click)="riderMessageEnabled && navigate('/rider-messages')"
					[ngClass]="{ 'pointer-none': !riderMessageEnabled }"
					class="nb-side-nav-flex-column"
					aria-label="Ridership Messages"
					data-test="nav.rider_messages">
					<i class="nb-side-nav-icons nb-icons nb-rider-messages nb-side-nav-flex"></i>
					<span class="nav-label">{{ 'T_RIDER.RIDER_MESSAGES' | translate }}</span>
				</a>
			</li>

			<li
				[ngClass]="{ active: getLocation().includes('vehicle-messages'), 'cursor-disable': !vehicleMessageEnabled }"
				class="nb-side-nav"
				[attr.title]="
					!vehicleMessageEnabled
						? getTranslation('T_MAP.MAP_FEATURE_DISABLED_TOOLTIP')
						: hasMiniNavBar()
							? getTranslation('T_MAP.MAP_VEHICLE_MESSAGES')
							: null
				">
				<a
					(click)="vehicleMessageEnabled && navigate('/vehicle-messages')"
					[ngClass]="{ 'pointer-none': !vehicleMessageEnabled }"
					class="nb-side-nav-flex-column"
					aria-label="Vehicle Messages"
					data-test="nav.vehicle_messages">
					<i class="nb-side-nav-icons nb-icons nb-vehicle-messages nb-side-nav-flex"></i>
					<span class="nav-label">{{ 'T_MAP.MAP_VEHICLE_MESSAGES' | translate }}</span>
				</a>
			</li>

			<li
				[ngClass]="{ active: getLocation().includes('reports') }"
				class="nb-side-nav"
				[attr.title]="hasMiniNavBar() ? getTranslation('T_CORE.REPORTS') : null">
				<a (click)="navigate('/reports/0')" class="nb-side-nav-flex-column" aria-label="Reports" data-test="nav.reports">
					<i class="nb-side-nav-icons nb-icons nb-reports nb-side-nav-flex"></i>
					<span class="nav-label">{{ 'T_CORE.REPORTS' | translate }}</span>
				</a>
			</li>
			<li
				*ngIf="hasDisablePredictionPermission"
				(mouseover)="setPredictionActive(true)"
				(mouseleave)="setPredictionActive(false)"
				[ngClass]="{ active: getLocation().includes('predictions') }"
				class="nb-side-nav"
				[attr.title]="hasMiniNavBar() ? getTranslation('T_CORE.PREDICTION_CONTROL') : null">
				<a (click)="navigate('/predictions')" class="nb-side-nav-flex-column" aria-label="PREDICTION_CONTROL" data-test="nav.predictions">
					<img
						[hidden]="!showPredictionActive && !getLocation().includes('predictions')"
						class="prediction-control-icon"
						src="/assets/img/NavigationIcons/prediction_control_active.svg"
						alt="Unavailable" />
					<img
						[hidden]="showPredictionActive || getLocation().includes('predictions')"
						class="prediction-control-icon"
						src="/assets/img/NavigationIcons/prediction_control_inactive.svg"
						alt="Unavailable" />
					<span class="nav-label">{{ 'T_CORE.PREDICTION_CONTROL' | translate }}</span>
				</a>
			</li>

			<li
				[ngClass]="{ active: getLocation().includes('admintools') }"
				class="nb-side-nav"
				[attr.title]="hasMiniNavBar() ? getTranslation('T_CORE.ADMIN_TOOLS') : null">
				<a (click)="navigate('/admintools')" class="nb-side-nav-flex-column" aria-label="Admin Tools" data-test="nav.admin_tools">
					<i class="nb-side-nav-icons nav-icons nb-icons nb-admintools nb-side-nav-flex"></i>
					<span class="nav-label">{{ 'T_CORE.ADMIN_TOOLS' | translate }}</span>
				</a>
			</li>
		</ul>
	</div>
</nav>

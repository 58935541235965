/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeUpdated } from './types';

@Component({
	selector: 'time-selector',
	templateUrl: './time-selector.component.html',
	styleUrls: ['./time-selector.component.scss'],
})
export class TimeSelectorComponent implements OnInit {
	@Input() timeLabel: string = null;
	@Input() timeLabelRequired: boolean = false;
	@Input() initialTime: string = '00:00';

	@Output() timeValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() timeUpdated: EventEmitter<TimeUpdated> = new EventEmitter<TimeUpdated>();

	public hours: string;
	public mins: string;
	public amPm: string = 'AM';

	public hoursStyle: string = '';
	public minsStyle: string = '';

	/**
	 * initializes the time selection control, adds any leading zeros to the time
	 * and publishes the control back up to the parent
	 */
	public ngOnInit(): void {
		const initial12HourTime: string = this.get12hrTime(this.initialTime);

		const timeParts: string[] = initial12HourTime.split(' ');

		if (timeParts.length === 2) {
			const timePartsHoursMins: string[] = timeParts[0].split(':');

			if (timeParts.length === 2) {
				const hours: number = +timePartsHoursMins[0];
				const mins: number = +timePartsHoursMins[1];

				if (hours > 0 && hours < 13) {
					this.hours = this.addLeadingZero(hours.toString());
				}

				if (mins >= 0 && mins < 60) {
					this.mins = this.addLeadingZero(mins.toString());
				}

				if (timeParts[1] === 'AM' || timeParts[1] === 'PM') {
					this.amPm = timeParts[1];
				}

				this.publishTime(true);
			}
		}
	}

	/**
	 * handle the hours changed by the user (adds leading zero) and publishes to the parent
	 */
	public hoursChanged = (): void => {
		if (this.hours !== null) {
			this.hoursStyle = '';
			this.hours = this.addLeadingZero(this.hours);
		} else {
			this.hoursStyle = 'invalid';
		}

		this.publishTime();
	};

	/**
	 * handle the minutes changed by the user (adds leading zero) and publishes to the parent
	 */
	public minsChanged = (): void => {
		if (this.mins !== null) {
			this.minsStyle = '';
			this.mins = this.addLeadingZero(this.mins);
		} else {
			this.minsStyle = 'invalid';
		}

		this.publishTime();
	};

	/**
	 * handle the toggle change for AM/PM and publishes to the parent
	 */
	public changeCustomDateAmPm = (): void => {
		this.amPm = this.amPm === 'AM' ? 'PM' : 'AM';

		this.publishTime();
	};

	/**
	 * converts the time string to a 12 hour time format
	 *
	 * @param timeString - the time value to convert
	 * @returns the 12 hour time string
	 */
	private get12hrTime = (timeString: string): string => {
		return new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US', {
			timeZone: 'UTC',
			hour12: true,
			hour: 'numeric',
			minute: 'numeric',
		});
	};

	/**
	 * converts the AM/PM 12 hour time string to 24 hour time format
	 *
	 * @param amPmString - the AM/PM 12 hour time to convert
	 * @returns the 24 hour time string
	 */
	private get24HourTime = (amPmString: string): string => {
		const dateTime: Date = new Date('1/1/2000 ' + amPmString);

		return this.addLeadingZero(dateTime.getHours().toString()) + ':' + this.addLeadingZero(dateTime.getMinutes().toString());
	};

	/**
	 * publishes the time up to the parent in addition to an event stating
	 * if the time is valid or not.
	 *
	 * @param initialPublish - a flag indiciating if this is the first publishJuninho23
	 */
	private publishTime = (initialPublish: boolean = false): void => {
		// check for null (0 mins returns false if checking just this.mins)
		if (this.hours !== null && this.mins !== null) {
			const hoursValue: number = +this.hours;
			const minsValue: number = +this.mins;

			if (hoursValue >= 0 && hoursValue <= 12 && minsValue >= 0 && minsValue <= 59) {
				const time: string = this.hours + ':' + this.mins + ' ' + this.amPm;

				const timeUpdated: TimeUpdated = {
					time: this.get24HourTime(time),
					initialPublish: initialPublish,
				};

				this.timeUpdated.emit(timeUpdated);

				this.timeValid.emit(true);
			} else {
				this.timeValid.emit(false);
			}
		} else {
			this.timeValid.emit(false);
		}
	};

	/**
	 * publishes the time up to the parent in addition to an event stating
	 * if the time is valid or not.
	 *
	 * @param value - the time part (hours or minutes) which may require the leading zero
	 * @returns the string with a leading zero
	 */
	private addLeadingZero = (value: string): string => {
		return (String('0').repeat(2) + value).substr(2 * -1, 2);
	};
}

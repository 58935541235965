/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { UsersAdminDataService } from '../../../services/users-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';

import { maxlengthValidation, minlengthValidation, requiredValidation, ValidationText } from '@cubicNx/libs/utils';

@Component({
	selector: 'reset-password-request',
	templateUrl: './reset-password-request.component.html',
	styleUrls: ['./reset-password-request.component.scss'],
})
export class ResetPasswordRequestComponent extends TranslateBaseComponent implements OnInit {
	@Output() resetInitiated: EventEmitter<void> = new EventEmitter<void>();

	public isLoading: boolean = true;
	public resetForm: FormGroup;

	public usernameRequiredTextOverride: ValidationText = {
		[requiredValidation]: 'T_CORE.USERNAME_REQUIRED',
	};

	public emailRequiredTextOverride: ValidationText = {
		[requiredValidation]: 'T_CORE.EMAIL_ADDRESS_REQUIRED',
	};

	public usernameExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	public emailExtraMessageText: ValidationText = {
		[minlengthValidation]: null,
		[maxlengthValidation]: null,
	};

	private readonly maxUsernameLength: number = 255;
	private readonly maxEmailLength: number = 255;
	private readonly minEmailLength: number = 4;

	constructor(
		private formBuilder: FormBuilder,
		private usersAdminDataService: UsersAdminDataService,
		translationService: TranslationService
	) {
		super(translationService);

		this.usernameExtraMessageText = {
			[maxlengthValidation]: this.maxUsernameLength.toString(),
		};

		this.emailExtraMessageText = {
			[minlengthValidation]: this.minEmailLength.toString(),
			[maxlengthValidation]: this.maxEmailLength.toString(),
		};
	}

	/**
	 * performs initialization tasks for the reset password request component
	 */
	public ngOnInit(): void {
		this.resetForm = this.formBuilder.group(
			{
				username: ['', [Validators.required, Validators.maxLength(this.maxUsernameLength)]],
				email: ['', [Validators.required, Validators.maxLength(this.maxEmailLength), Validators.minLength(this.minEmailLength)]],
			},
			{}
		);

		this.isLoading = false;
	}

	/**
	 * initialises the reset password process
	 */
	public resetPassword = async (): Promise<void> => {
		if (this.resetForm.valid) {
			this.isLoading = true;

			const result: ResultContent = await this.usersAdminDataService.resetPasswordInit(
				this.resetForm.controls.username.value,
				this.resetForm.controls.email.value,
				false
			);

			if (result.success) {
				// submit to parent
				this.resetInitiated.emit();
			}

			this.isLoading = false;
		} else {
			this.resetForm.markAllAsTouched();
		}
	};
}

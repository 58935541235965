/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

// THIS HAS BEEN BROUGHT ACROSS AND HAS BEEN CONVERTED. HOWEVER IT IS CURRENTLY CONFIGURED OUT SINCE IT
// LOOKS LIKE THERE IS A BACK-END ISSUE GENERATING THE REPORT SUMMARY.

declare let d3: any;

import { Component, Input, OnChanges } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { HeadwaySummaryDetail } from '../../../types/api-types';

@Component({
	selector: 'headway-by-route-chart',
	templateUrl: './headway-by-route-chart.component.html',
	styleUrls: ['./headway-by-route-chart.component.scss'],
})
export class HeadwayByRouteChartComponent extends TranslateBaseComponent implements OnChanges {
	@Input() summaryData: HeadwaySummaryDetail = null;

	public hasResults: boolean = false;
	public options: any = null;
	public chartIsInitialized: boolean = false;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * handles changes to the input params
	 *
	 * loads the chart data
	 */
	public ngOnChanges(): void {
		this.chartIsInitialized = false;
		if (!this.summaryData) {
			return;
		}

		this.loadChart();
	}

	/**
	 * loads the headway by route chart with data
	 */
	private loadChart = (): void => {
		this.hasResults = true;
		this.setChartOptions();
		this.chartIsInitialized = true;
	};

	/**
	 * sets up the headway by route chart options
	 */
	private setChartOptions = (): void => {
		this.options = {
			chart: {
				type: 'discreteBarChart',
				height: 450,
				margin: {
					top: 20,
					right: 20,
					bottom: 50,
					left: 55,
				},
				x: (d: any): any => d.stop_id,
				y: (d: any): any => d.max / 60,
				showValues: true,
				valueFormat: (d: any): any => d3.format(',.1f')(d),
				duration: 500,
				xAxis: {
					axisLabel: this.getTranslation('T_REPORT.STOP_ID'),
					rotateLabels: -90,
				},
				yAxis: {
					axisLabel: this.getTranslation('T_REPORT.HEADWAY'),
					axisLabelDistance: -10,
				},
				tooltip: {
					contentGenerator: this.generateTooltipContent,
				},
			},
		};
	};

	/**
	 * generates the template using the supplied object that represents the tooltip over the chart
	 *
	 * @param obj - the object
	 * @returns template using the supplied object data
	 */
	private generateTooltipContent = (obj: any): string => {
		const content: string =
			'<div style="background-color:white"> ' +
			' Stop: ' +
			obj.data.stop_name +
			'<br>' +
			' Route: ' +
			obj.data.route_long_name +
			'<br>' +
			'Max: ' +
			obj.data.max / 60 +
			'<br>' +
			'Min: ' +
			obj.data.min / 60 +
			'</div>';

		return content;
	};
}

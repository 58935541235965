/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnInit } from '@angular/core';

import { ReportViewerSortableListBaseComponent } from '../../report-viewer-sortable-list-base.component';

import { DataService } from '@cubicNx/libs/utils';
import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { ReportsGenerationService } from '../../../../../services/reports-generation.service';
import { ReportsConfigService } from '../../../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { AdherenceColorService } from '../ahderence-color.service';

import { AdherenceByStop } from '../../../../../types/types';

import {
	ReportRoute,
	InteractiveReportAdherenceByRouteDetails,
	InteractiveReportAdherenceByRouteDetail,
} from '../../../../../types/api-types';

@Component({
	selector: 'ad100-adherence-by-route-list',
	templateUrl: './adherence-by-route-list.component.html',
	styleUrls: ['./adherence-by-route-list.component.scss'],
})
export class AdherenceByRouteListComponent extends ReportViewerSortableListBaseComponent implements OnInit {
	public listData: InteractiveReportAdherenceByRouteDetails = null;

	constructor(
		public dataService: DataService,
		public adherenceColorService: AdherenceColorService,
		private reportsGenerationService: ReportsGenerationService,
		protected override reportsEventsService: ReportsEventsService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsEventsService, reportsConfigService, colorUtilityService, translationService);

		this.templateSummaryContent = this.dataService['templateSummaryContent' as keyof DataService];
		this.defaultTemplateId = this.dataService['defaultTemplateId' as keyof DataService];
	}

	/**
	 * performs initialization tasks for the adherence by route list view (AD100)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (adherence)
	 */
	public updateListView = (listData: InteractiveReportAdherenceByRouteDetails): void => {
		this.listData = listData;
	};

	/**
	 * generates the route part of the report data
	 *
	 * @param adherenceData - the adherence data
	 */
	public generateReport = (adherenceData: InteractiveReportAdherenceByRouteDetail): void => {
		const route: ReportRoute = {
			authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
			route_id: adherenceData.route_id,
			route_display_name: adherenceData.route_long_name,
			route_short_name: adherenceData.route_short_name,
			route_long_name: adherenceData.route_long_name,
		};

		this.reportsGenerationService.generateRouteReport(AdherenceByStop, this.templateSummaryContent.template, route);
	};
}

<div class="nb-report-viewer-summary nb-padding-all-md report-viewer-summary-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div class="report-viewer-grid nb-padding-bottom-md">
				<div class="report-viewer-graph-container nb-padding-left-md nb-padding-right-md nb-padding-bottom-md">
					<div class="nb-section-title">{{ 'T_REPORT.SUMMARY' | translate }}</div>
					<div class="schedule-performance-chart-horizontal">
						<div class="schedule-performance-chart-horizontal-item very-early-border">
							<div class="nb-heading">{{ summaryData.totals.missed_percent }}%</div>
							<div class="nb-text">{{ summaryData.totals.missed_count }}</div>
							<div class="nb-text report-viewer-graph-border-label">{{ 'T_REPORT.MISSED' | translate }}</div>
						</div>
						<div class="schedule-performance-chart-horizontal-item early-border">
							<div class="nb-heading">{{ summaryData.totals.late_percent }}%</div>
							<div class="nb-text">{{ summaryData.totals.late_count }}</div>
							<div class="nb-text">{{ 'T_REPORT.LATE' | translate }}</div>
						</div>
						<div class="schedule-performance-chart-horizontal-item on-time-border">
							<div class="nb-heading">{{ summaryData.totals.on_time_percent }}%</div>
							<div class="nb-text">{{ summaryData.totals.on_time_count }}</div>
							<div class="nb-text report-viewer-graph-border-label">{{ 'T_REPORT.EARLY_ON_TIME' | translate }}</div>
						</div>
						<div class="schedule-performance-chart-horizontal-item">
							<div class="nb-heading">{{ summaryData.totals.upcoming_percent }}%</div>
							<div class="nb-text">{{ summaryData.totals.upcoming_count }}</div>
							<div class="nb-text">{{ 'T_REPORT.UPCOMING' | translate }}</div>
						</div>
						<div class="schedule-performance-chart-horizontal-item">
							<div class="nb-heading">&nbsp;</div>
							<div class="nb-text" data-test="blocks.total">{{ summaryData.totals.total_blocks_count }}</div>
							<div class="nb-text">{{ 'T_REPORT.TOTAL_BLOCKS' | translate }}</div>
						</div>
						<div class="schedule-performance-chart-horizontal-item">
							<div class="nb-heading">&nbsp;</div>
							<div class="nb-text">&nbsp;{{ summaryData.totals.average_difference | secondsToHoursMinutes }}</div>
							<div class="nb-text report-viewer-graph-border-label">{{ 'T_REPORT.AVERAGE_DIFFERENCE' | translate }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

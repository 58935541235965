<div class="nb-view-table" [ngClass]="getContainerClass()">
	<div *ngIf="viewtableoptions?.title">
		<div class="nb-view-table-heading">{{ getTranslation(viewtableoptions?.title) }}</div>
	</div>
	<div *ngIf="viewtableoptions?.dynamicTitle">
		<div class="nb-view-table-heading">{{ viewtableoptions?.dynamicTitle(model) }}</div>
	</div>
	<div *ngIf="viewtableoptions?.rowDefs" class="nb-view-table-content" [ngClass]="getContentClass()">
		<div *ngFor="let rowDef of viewtableoptions?.rowDefs" [ngClass]="getDataClass()">
			<div *ngIf="!rowDef.hidden" id="{{ rowDef.name }}-label" [ngClass]="getTitleClass()">
				{{ getTranslation(rowDef.displayName || rowDef.name | uppercase) }}
			</div>
			<div *ngIf="!rowDef.hidden" class="content truncate">
				<span *ngIf="rowDef.type !== 'image'" id="{{ rowDef.name }}-content" class="nb-text truncate">
					{{ model[rowDef.name] | formatField: rowDef : model }}
				</span>
				<div *ngIf="rowDef.type === 'image'" class="nb-text truncate">
					<image-view [imageId]="model[rowDef.name]"></image-view>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="viewtableoptions?.dynamicSubTitle" class="nb-margin-bottom-md nb-margin-top-md nb-view-table-content">
		<div class="nb-subhead">{{ viewtableoptions?.dynamicSubTitle(model) }}</div>
	</div>
	<div *ngIf="viewtableoptions?.dynamicSubTitle" [ngClass]="getContentClass()" class="nb-view-table-content">
		<div *ngFor="let rowSubDef of viewtableoptions?.rowSubDefs" [ngClass]="getDataClass()">
			<div *ngIf="!rowSubDef.hidden" id="{{ rowSubDef.name }}-label" [ngClass]="getTitleClass()">
				{{ getTranslation(rowSubDef.displayName || rowSubDef.name | uppercase) }}
			</div>
			<div *ngIf="!rowSubDef.hidden" class="content truncate">
				<span *ngIf="rowSubDef.type !== 'image'" id="{{ rowSubDef.name }}-content" class="nb-text truncate">
					{{ model[rowSubDef.name] | formatField: rowSubDef : model }}
				</span>
			</div>
		</div>
	</div>
</div>

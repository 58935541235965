<!--
    COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

    Information Contained Herein is Proprietary and Confidential.
    The document is the property of "CUBIC" and may not be disclosed
    distributed, or reproduced  without the express written permission of "CUBIC".
-->
<div class="btn-group-vertical btn-group-xs nb-map-button-map">
	<button
		type="button"
		class="btn nb-map-ladder-button"
		data-test="map.view-map-btn"
		title="{{ 'T_CORE.VIEW_MAP' | translate }}"
		(click)="viewMap()"
		[ngClass]="{ buttonSelectionBgColor: mapOptionsService.getMode() === modeType.map }">
		<img class="nb-map-ladder-container" src="/assets/img/MapIcon.svg" alt="Unavailable" />
	</button>
</div>
<div class="btn-group-vertical btn-group-xs nb-map-button-ladder">
	<button
		type="button"
		class="btn nb-map-ladder-button"
		data-test="map.view-ladder-btn"
		title="{{ ladderViewTooltip }}"
		(click)="viewLadder()"
		[disabled]="!ladderFeatureEnabledForAgency || this.mapOptionsService.getMapMode() === mapModeType.replay"
		[ngClass]="{ buttonSelectionBgColor: mapOptionsService.getMode() === modeType.ladder && ladderFeatureEnabledForAgency }">
		<img class="nb-map-ladder-container" src="/assets/img/LadderIcon.svg" alt="Unavailable" />
	</button>
</div>
<div *ngIf="mapOptionsService.getMode() === modeType.map" class="btn-group-vertical btn-group-xs nb-map-button-zoom">
	<button
		type="button"
		class="btn nb-map-menu-button"
		data-test="zoom.in"
		title="{{ 'T_CORE.ZOOM_IN_TOOLTIP' | translate }}"
		(click)="zoomIn()">
		<i class="nb-icons nb-zoomout icon-style"></i>
	</button>
	<div>
		<button
			type="button"
			class="btn nb-map-menu-button"
			data-test="zoom.out"
			title="{{ 'T_CORE.ZOOM_OUT_TOOLTIP' | translate }}"
			(click)="zoomOut()">
			<i class="nb-icons nb-zoomin icon-style"></i>
		</button>
	</div>
</div>
<div class="btn-group-vertical btn-group-xs nb-map-menu-container">
	<button
		type="button"
		data-test="map.clear-btn"
		class="btn nb-map-menu-button"
		title="{{ 'T_CORE.CLEAR_MAP_TOOLTIP' | translate }}"
		(click)="clearMap()">
		<i class="nb-icons nb-clearmap icon-style"></i>
	</button>
	<div>
		<button
			type="button"
			class="btn nb-map-menu-button"
			title="{{ 'T_CORE.CREATE_SAVED_VIEW_TOOLTIP' | translate }}"
			(click)="createView()"
			[hidden]="mapOptionsService.getMapMode() !== mapModeType.replay">
			<i class="nb-icons nb-favorite icon-style"></i>
		</button>
	</div>
	<button
		[disabled]="mapOptionsService.getMode() === modeType.ladder"
		type="button"
		class="btn nb-map-menu-button"
		data-test="map.replay-btn"
		title="{{ 'T_CORE.TOGGLE_REWIND_MODE_TOOLTIP' | translate }}"
		(click)="toggleReplayMode()">
		<i class="nb-icons nb-replay icon-style"></i>
	</button>
	<div>
		<button
			type="button"
			class="btn nb-map-menu-button"
			data-test="map.toggle-live-updates"
			title="{{ getLiveUpdateTooltip() }}"
			(click)="toggleLiveUpdates()"
			[disabled]="mapOptionsService.getMapMode() === mapModeType.replay"
			[ngClass]="{ 'nb-map-resume-button': mapOptionsService.getLiveUpdatesPaused() }">
			<i *ngIf="!mapOptionsService.getLiveUpdatesPaused()" class="material-icons">pause_circle_filled</i>
			<i *ngIf="mapOptionsService.getLiveUpdatesPaused()" class="material-icons">play_circle_filled</i>
		</button>
	</div>
	<button
		type="button"
		class="btn nb-map-menu-button"
		data-test="map.full-screen"
		title="{{ 'T_CORE.TOGGLE_FULL_SCREEN_MODE_TOOLTIP' | translate }}"
		(click)="toggleFullScreen()">
		<i *ngIf="!mapOptionsService.getFullScreen()" class="nb-icons nb-expandview icon-style"></i>
		<i *ngIf="mapOptionsService.getFullScreen()" class="nb-icons nb-condenseview icon-style"></i>
	</button>
	<div (clickOutside)="closeSettings()">
		<button
			type="button"
			class="btn nb-map-menu-button"
			data-test="map.open-map-settings"
			title="{{ 'T_CORE.OPEN_MAP_SETTINGS_TOOLTIP' | translate }}"
			(click)="toggleSettings()">
			<i class="nb-icons nb-settings icon-style"></i>
		</button>
		<div>
			<div class="nb-map-settings-container" [ngClass]="{ show: showSettings }">
				<map-settings (closeSettings)="closeSettings()"></map-settings>
			</div>
		</div>
	</div>
</div>

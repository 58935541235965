<br />
<div class="pull-right">
	<search-filter [filterValue]="searchText" (search)="search($event)"></search-filter>
</div>
<br /><br />
<hr />

<div *ngIf="depots.length === 0" class="no-data">
	{{ 'T_CORE.NONE_AVAILABLE' | translate }}
</div>

<ng-container *ngFor="let depot of depots">
	<div class="col-xs-6 col-md-4">
		<div class="depots-dataview-tpl nb-padding-bottom-lg">
			<div class="">
				<h3 data-test="depots_table" class="nb-title-sm">{{ depot.nb_id }} - {{ depot.name }}</h3>
				<div class="nb-text nb-margin-bottom-xs">{{ depot.description }}</div>
				<div *ngIf="!depot.is_custom">
					<div class="pull-left nb-text nb-margin-bottom-xs nb-margin-right-md">
						<span class="nb-inline-label">{{ 'T_DEPOT.LATITUDE' | translate }}</span> X{{ depot.latitude }}
					</div>
					<div class="pull-left nb-text nb-margin-bottom-xs nb-margin-right-md">
						<span class="nb-inline-label">{{ 'T_DEPOT.LONGITUDE' | translate }}</span> X{{ depot.longitude }}
					</div>
					<div class="pull-left nb-text nb-margin-bottom-xs">
						<span class="nb-inline-label">{{ 'T_DEPOT.RADIUS' | translate }}</span> X{{ depot.radius }}
					</div>
				</div>
				<div *ngIf="depot.is_custom" class="nb-text nb-margin-bottom-xs">
					{{ 'T_DEPOT.CUSTOM_SHAPE' | translate }}
				</div>
			</div>
		</div>
	</div>
</ng-container>

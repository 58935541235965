/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

import { Route } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class RoutesUtilService {
	constructor(private translationService: TranslationService) {}

	/**
	 * get the display name for a route based on available properties
	 *
	 * @param route - the route to extract a suitable display name
	 * @returns the route display name
	 */
	public getDisplayName = (route: Route): string => {
		let displayName: string = null;

		if (route.route_short_name && route.route_short_name.length > 0) {
			displayName = route.route_short_name;
		} else if (route.route_long_name && route.route_long_name.length > 0) {
			displayName = route.route_long_name;
		} else {
			displayName = this.translationService.getTranslation('T_ROUTE.NO_NAME');
		}

		return displayName;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { AgenciesAdminApiService } from './agencies-admin-api.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { AgenciesPaginatedResponse } from '../types/api-types';
import { AgencyDetail } from '../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class AgenciesAdminDataService {
	constructor(
		private logger: LoggerService,
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private agenciesAdminApiService: AgenciesAdminApiService
	) {}

	/**
	 * retrieves agencies that match the supplied parameters
	 *
	 * @param paginatedParams - agency retrieval parameters - page size, number, sort criteria and search text
	 * @returns agencies matching the supplied parameters
	 */
	public getAgencies = async (paginatedParams: PaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.agenciesAdminApiService.getAgencies(paginatedParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response?.results) {
				// data is a paginated response
				result.resultData = response as AgenciesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: AgenciesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as AgenciesPaginatedResponse;
			}
		} catch (exception) {
			this.logger.logError('Failed to get admin agencies', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves agency details that match the supplied parameters
	 *
	 * @param authorityId - authority id
	 * @param agencyId - agency id
	 * @returns agency details
	 */
	public getAgency = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		if (authorityId !== null) {
			try {
				const agencyDetail: AgencyDetail = await this.agenciesAdminApiService.getAgency(authorityId, agencyId);

				result.success = true;
				result.resultData = agencyDetail as AgencyDetail;
			} catch (exception) {
				this.logger.logError('Failed to get admin agency - ', exception);
			}
		}

		return result;
	};

	/**
	 * saves the agency as per the supplied parameters
	 *
	 * @param updatedAgency - updated agency details
	 * @returns the result of the save operation
	 */
	public saveAgency = async (updatedAgency: AgencyDetail): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.agenciesAdminApiService.saveAgency(updatedAgency);

			result.success = response.success;
			result.resultData = response.result as AgencyDetail;
		} catch (exception: any) {
			let errorMsg: string = null;

			if (exception.status === 500) {
				errorMsg =
					typeof exception.error.error === 'string'
						? exception.error.error
						: 'Please contact administrator to create a tracker configuration profile.';

				this.notificationService.notifyError(errorMsg);
			} else {
				errorMsg = typeof exception.error.error === 'string' ? exception.error.error : 'An unexpected server error occurred.';

				this.notificationService.notifyError(errorMsg);
			}

			this.logger.logError('Failed to save admin agency - ', exception);
		}

		return result;
	};
}

<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
<div *ngIf="success">
	<div *ngIf="hasResults">
		<div class="vehicle-event-details-item-container" [ngStyle]="heightStyle">
			<div *ngFor="let event of vehicleEvents; last as isLast">
				<div
					class="vehicle-events-content-area"
					[ngClass]="!isLast ? 'nb-text vehicle-events-container' : 'vehicle-events-container-noborder'"
					data-test="notifications.rows">
					<vehicle-event-details-item [event]="event" [timezone]="timezone"></vehicle-event-details-item>
				</div>
			</div>
		</div>
		<pagination-toolbar
			[paginationSettings]="paginationSettings"
			[pagination]="pagination"
			(setPageSizeEvent)="setPageSize()"
			(pageChangedEvent)="pageChanged()">
		</pagination-toolbar>
	</div>
	<!-- additional header-padding only applied in header variation -->
	<div *ngIf="!hasResults" class="no-data text-center header-padding" data-test="notifications.no-data">
		{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}
	</div>
</div>
<div *ngIf="loaded && !success" class="no-data text-center header-padding" data-test="server.error">
	{{ 'T_DASH.DB_SERVER_ERROR' | translate }}
</div>

<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div *ngIf="hasResults" class="wrap center">
					<table class="table deadheading-table">
						<tr class="table-row">
							<th class="table-heading">{{ 'T_DASH.DB_YARD_NAME' | translate }}</th>
							<th class="table-heading">{{ 'T_DASH.DB_VEHICLE' | translate }}</th>
							<th class="table-heading">{{ 'T_DASH.DB_ROUTE' | translate }}</th>
							<th class="table-heading">{{ 'T_DASH.DB_BLOCK' | translate }}</th>
							<th class="table-heading">{{ 'T_DASH.DB_TO_DEPARTURE_STOP' | translate }}</th>
							<th class="table-heading">{{ 'T_DASH.DB_DELAYED_DEPARTURE_TIME' | translate }}</th>
							<th class="table-heading">{{ 'T_DASH.DB_SCHEDULE_TIME' | translate }}</th>
						</tr>
						<tr *ngFor="let vehicle of deadHeadingVehicles" class="table-row">
							<td class="table-data" data-test="vehicle.table.deadheading_yard">
								{{ vehicle.deadheadingYard }}
							</td>
							<td
								class="table-data col-pointer"
								data-test="vehicle.table.vehicle_id"
								(click)="navigateToVehicleDetails(data.config.selectedAgency.authority_id, vehicle.vehicleId)">
								{{ vehicle.vehicleId }}
							</td>
							<td class="table-data col-pointer" data-test="vehicle.table.route" (click)="navigateToRouteDetails(vehicle.route.route_id)">
								<route-pill [route]="determineRoutePillData(vehicle.route)" [useFullWidth]="true"></route-pill>
							</td>
							<td
								class="table-data col-pointer"
								data-test="vehicle.table.block_id"
								(click)="navigateToBlockDetails(vehicle.blockDetails.block_id)">
								{{ vehicle.blockDetails.block_id }}
							</td>
							<td class="table-data" data-test="vehicle.table.stop_id">
								{{ vehicle.stop }}
							</td>
							<td class="table-data" data-test="vehicle.table.prediction">
								{{ vehicle.predectionHHmmss }}
							</td>
							<td class="table-data" data-test="vehicle.table.prediction">
								{{ vehicle.schedTime }}
							</td>
						</tr>
					</table>
				</div>

				<div *ngIf="!hasResults" class="no-data text-center">{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}</div>
				<div class="deadheading-footer">
					<div class="update-text">
						<span class="nb-text">
							<span class="bold">{{ 'T_DASH.DB_PERFORMANCE_UPDATED' | translate }}</span>
							<br />
							<span data-test="updated">{{ lastUpdateTime.toString() | timeAgo }}</span>
						</span>
					</div>
				</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

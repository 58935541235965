import { Component, Input, OnChanges } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'schedule-performance-donut-chart',
	templateUrl: './schedule-performance-donut-chart.component.html',
	styleUrls: ['./schedule-performance-donut-chart.component.scss'],
})
export class SchedulePerformanceDonutChartComponent implements OnChanges {
	@Input() summaryData: any;
	@Input() display: any;

	public chartIsInitialized: boolean = false;
	public hasResults: boolean = false;
	public options: any = null;
	public data: any = null;

	public translations: any = {};

	private assignedPerc: number = 0;
	private unassignedPerc: number = 0;
	private veryLatePerc: number = 0;
	private latePerc: number = 0;
	private onTimePerc: number = 0;
	private earlyPerc: number = 0;
	private veryEarlyPerc: number = 0;

	private totalCount: number = 0;

	constructor(private translationService: TranslationService) {}

	/**
	 * for when the underlying chart data changes - loads chart with new data
	 */
	public ngOnChanges(): void {
		this.data = [
			{
				key: this.translations['CHARTS_SCHEDULE_PERFORMANCE'],
				values: [],
			},
		];

		this.chartIsInitialized = false;

		if (!this.summaryData) {
			return;
		}

		this.loadChart();
	}

	/**
	 * sets the chart options
	 *
	 * @param titlePercent - the percentage title
	 */
	private setChartOptions = (titlePercent: number): void => {
		this.options = {
			chart: {
				type: 'pieChart',
				title: titlePercent + '%',
				height: 300,
				width: 300,
				donut: true,
				noData: this.translations['CHARTS_NO_DATA_AVAILABLE'],
				x(d: any): string {
					return d.key;
				},
				y(d: any): string {
					return typeof d.y === 'number' ? Math.round(d.y * 10) / 10 : d.y;
				},
				showLabels: true,
				showLegend: false,
				duration: 500,
				tooltip: {
					enabled: true,
					valueFormatter(d: any): string {
						return d + '%';
					},
				},
			},
		};
	};

	/**
	 * loads the chart with the underlying chart data
	 */
	private loadChart = (): void => {
		if (this.display.displayUnassigned) {
			this.loadAssignedChart();
		} else {
			this.loadAdherenceChart();
		}
	};

	/**
	 * loads the assigned chart data
	 */
	private loadAssignedChart = async (): Promise<void> => {
		if (!this.translations['CHARTS_ASSIGNED']) {
			await this.initTranslations();
		}

		const { assignedCount, unassignedCount } = this.summaryData;

		if (!isNaN(assignedCount) && !isNaN(unassignedCount)) {
			this.totalCount = assignedCount + unassignedCount;
			this.assignedPerc = (100 * assignedCount) / this.totalCount;
			this.assignedPerc = parseFloat(this.assignedPerc.toFixed(1));
			this.unassignedPerc = 100 - this.assignedPerc;
		}

		const assignedUnassignedPerc: any[] = [
			{ label: 'assignedPerc', value: this.assignedPerc },
			{ label: 'unassignedPerc', value: this.unassignedPerc },
		];

		const assignedUnassignedPercMax: number = Math.max.apply(
			null,
			assignedUnassignedPerc.map((v) => v.value)
		);

		const assignedUnassignedPercMaxValue: any = assignedUnassignedPerc.find((n) => n.value === assignedUnassignedPercMax);

		this.setChartOptions(assignedUnassignedPercMaxValue.value);

		this.data = [
			{
				key: this.translations['CHARTS_ASSIGNED'],
				y: this.assignedPerc,
			},
			{
				key: this.translations['CHARTS_UNASSIGNED'],
				y: this.unassignedPerc,
			},
		];
		this.hasResults = true;
		this.chartIsInitialized = true;
	};

	/**
	 * loads the adherence chart data
	 */
	private loadAdherenceChart = async (): Promise<void> => {
		if (!this.translations['CHARTS_ASSIGNED']) {
			await this.initTranslations();
		}

		const { onTimeCount, veryEarlyCount, earlyCount, lateCount, veryLateCount } = this.summaryData;

		if (!isNaN(onTimeCount) && !isNaN(veryEarlyCount) && !isNaN(earlyCount) && !isNaN(lateCount) && !isNaN(veryLateCount)) {
			this.totalCount = onTimeCount + veryEarlyCount + earlyCount + lateCount + veryLateCount;
			if (this.totalCount === 0) {
				this.hasResults = false;
				this.chartIsInitialized = true;

				return;
			} else {
				this.hasResults = true;
				this.veryLatePerc = (100 * veryLateCount) / this.totalCount;
				this.veryLatePerc = parseFloat(this.veryLatePerc.toFixed(1));
				this.latePerc = (100 * lateCount) / this.totalCount;
				this.latePerc = parseFloat(this.latePerc.toFixed(1));
				this.veryEarlyPerc = (100 * veryEarlyCount) / this.totalCount;
				this.veryEarlyPerc = parseFloat(this.veryEarlyPerc.toFixed(1));
				this.earlyPerc = (100 * earlyCount) / this.totalCount;
				this.earlyPerc = parseFloat(this.earlyPerc.toFixed(1));
				this.onTimePerc = 100 - this.veryLatePerc - this.latePerc - this.veryEarlyPerc - this.earlyPerc;
				this.onTimePerc = parseFloat(this.onTimePerc.toFixed(1));
			}
		}

		//Get Max value Percentage and assign that value to setChartOptions
		const assignPercentage: any[] = [
			{ label: 'veryLatePerc', value: this.veryLatePerc },
			{ label: 'latePerc', value: this.latePerc },
			{ label: 'veryEarlyPerc', value: this.veryEarlyPerc },
			{ label: 'earlyPerc', value: this.earlyPerc },
			{ label: 'onTimePerc', value: this.onTimePerc },
		];
		const percMax: number = Math.max.apply(
			null,
			assignPercentage.map((v) => v.value)
		);

		const assignPercMax: any = assignPercentage.find((n) => n.value === percMax);

		this.setChartOptions(assignPercMax.value);

		const performanceColors: any = {
			very_early: '#b00808',
			early: '#eb3b3b',
			on_time: '#3ac15d',
			late: '#ffb852',
			very_late: '#e89015',
		};

		this.data = [
			{
				key: this.translations['CHARTS_EARLY'],
				color: performanceColors.early,
				y: this.earlyPerc,
			},
			{
				key: this.translations['CHARTS_LATE'],
				color: performanceColors.late,
				y: this.latePerc,
			},
			{
				key: this.translations['CHARTS_VERY_EARLY'],
				color: performanceColors.very_early,
				y: this.veryEarlyPerc,
			},
			{
				key: this.translations['CHARTS_VERY_LATE'],
				color: performanceColors.very_late,
				y: this.veryLatePerc,
			},
			{
				key: this.translations['CHARTS_ON_TIME'],
				color: performanceColors.on_time,
				y: this.onTimePerc,
			},
		];
		this.chartIsInitialized = true;
	};

	/**
	 * initializes the translations for the view
	 */
	private initTranslations = async (): Promise<void> => {
		this.translations['DB_NO_DATA_FOUND'] = this.translationService.getTranslation('T_DASH.DB_NO_DATA_FOUND');
		this.translations['CHARTS_NO_DATA_AVAILABLE'] = this.translationService.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE');
		this.translations['CHARTS_ASSIGNED'] = this.translationService.getTranslation('T_DASH.CHARTS_ASSIGNED');
		this.translations['CHARTS_UNASSIGNED'] = this.translationService.getTranslation('T_DASH.CHARTS_UNASSIGNED');
		this.translations['CHARTS_EARLY'] = this.translationService.getTranslation('T_CORE.CHARTS_EARLY');
		this.translations['CHARTS_LATE'] = this.translationService.getTranslation('T_CORE.CHARTS_LATE');
		this.translations['CHARTS_VERY_EARLY'] = this.translationService.getTranslation('T_CORE.CHARTS_VERY_EARLY');
		this.translations['CHARTS_VERY_LATE'] = this.translationService.getTranslation('T_CORE.CHARTS_VERY_LATE');
		this.translations['CHARTS_ON_TIME'] = this.translationService.getTranslation('T_CORE.CHARTS_ON_TIME');
		this.translations['CHARTS_SCHEDULE_PERFORMANCE'] = this.translationService.getTranslation('T_CORE.CHARTS_SCHEDULE_PERFORMANCE');
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'widget-header',
	templateUrl: './widget-header.component.html',
	styleUrls: ['./widget-header.component.scss'],
})
export class WidgetHeaderComponent extends TranslateBaseComponent {
	@Input() title: string;
	@Input() agencyName: string;
	@Input() editMode: boolean;

	@Output() deleteWidgetClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() editWidgetClicked: EventEmitter<void> = new EventEmitter<void>();

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * Fires deleteWidgeClicked emitting the widget to be deleted.
	 */
	public deleteWidget = (): void => {
		this.deleteWidgetClicked.emit();
	};

	/**
	 * Fires editWidgeClicked emitting the widget to be edited.
	 *
	 * @param widget - the widget to edited.
	 */
	public editWidget = (): void => {
		this.editWidgetClicked.emit();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import L, { PointExpression } from 'leaflet';

import { MapNavigationService } from '../map-navigation.service';
import { MapStopsService } from '../map-stops.service';
import { MapLocationService } from '../map-location.service';
import { MapEventsService } from '../map-events.service';

import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';

import { EventExtended, HTMLElementExtended, MapStop, Markers } from '../../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapStopsClusterTooltipMarkerService implements OnDestroy {
	private readonly tooltipCloseDelay: number = 300;

	private mapInstance: L.Map = null;
	private markers: Markers = {};
	private selectedAgency: SelectedAgency = null;
	private removeTooltips$Subscription: Subscription = null;

	constructor(
		private mapLocationService: MapLocationService,
		private mapStopsService: MapStopsService,
		private mapEventsService: MapEventsService,
		private mapNavigationService: MapNavigationService
	) {}

	/**
	 * initialize the stops cluster marker service
	 *
	 * @param mapInstance - the current map instance
	 * @param selectedAgency - the current agency
	 */
	public init = (mapInstance: L.Map, selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapInstance = mapInstance;

		this.markers = {};

		this.setSubscriptions();
	};

	/**
	 * handle any cleanup for the service (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.removeTooltips$Subscription.unsubscribe();
	}

	/**
	 * handle the agency change and clear down any markers
	 *
	 * @param selectedAgency - the current agency
	 */
	public setAgencyChange = (selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.markers = {};
	};

	/**
	 * add the stop tooltip
	 *
	 * @param stopCodes - the stop codes for all the stops in the cluster
	 * @param markerId - the (cluster) marker id to add the tooltip for
	 * @param lat - the latitude to display the tooltip
	 * @param lng - the latitude to display the tooltip
	 */
	public addStopTooltip = async (stopCodes: string[], markerId: string, lat: number, lng: number): Promise<void> => {
		const stops: MapStop[] = [];

		if (stopCodes.length > 0) {
			stopCodes.forEach((stopCode: string) => {
				const stop: MapStop = this.mapStopsService.getStop(stopCode);

				stops.push(stop);
			});

			// if the tooltip is not already showing...
			if (!this.markers[markerId]) {
				// make sure any other tooltips are removed before we add the new one
				this.mapEventsService.publishRemoveTooltips();

				this.markers[markerId] = new L.Marker(new L.LatLng(lat, lng), {
					icon: this.getIcon(stops),
					zIndexOffset: 9002,
				}).addTo(this.mapInstance);

				// add in a withinTooltip flag to manage closing of the tooltip
				this.markers[markerId].withinTooltip = false;

				this.markers[markerId].on('mouseover', () => {
					this.handleMouseOver(markerId);
				});

				this.markers[markerId].on('mouseout', () => {
					this.handleMouseOut(markerId);
				});
			}
		}
	};

	/**
	 * remove the stop tooltip
	 *
	 * @param markerId - the (cluster) marker id of the tooltip to remove
	 */
	public removeStopTooltip = (markerId: string): void => {
		// allow a delay so if we get here from the parent (leaving the stop icon area) but the user enters
		// the tooltip itself, the withinTooltip
		// will be set and we wont actually close the tooltip
		setTimeout(() => {
			if (this.markers[markerId] && !this.markers[markerId].withinTooltip) {
				this.removeClickListeners(markerId);

				this.markers[markerId].remove();

				delete this.markers[markerId];
			}
		}, this.tooltipCloseDelay);
	};

	/**
	 * get the stop tooltip icon for the cluster
	 * @param stops - the stop objects that form the clister
	 * @returns a leaflet div icon to display on the map
	 */
	private getIcon = (stops: MapStop[]): L.DivIcon => {
		let html: string = '<div class="nb-map-stop-label" ';

		html += 'style="padding-left:10px; padding-right:10px; width:300px;">';

		stops.forEach((stop: MapStop) => {
			html += '<a id="stopCode' + stop.stopCode + 'Click">';
			html += '<div class="label-header">';
			html += '<div class="text-block">';
			html += '<div class="stop-code">' + stop.stopCode + '</div>';
			html += '<div class="stop-desc">' + stop.stopName + '</div>';
			html += '</div>';
			html += '<div class="stop-features">';
			html += '</div>';
			html += '</div>';
			html += '</a>';
		});

		html += '</div>';

		return L.divIcon({
			className: 'nb-map-stop-marker',
			html,
			iconAnchor: this.getStopLabelAnchor() as PointExpression,
		});
	};

	/**
	 * get the tooltip icon anchor (location) to display the tooltip
	 * @returns the tooltip icon anchor (location) to display the tooltip
	 */
	private getStopLabelAnchor = (): number[] => {
		switch (this.mapLocationService.getLocationZoom()) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
			case 11:
			case 12:
			case 13:
			case 14:
			case 15:
			case 16:
				return [1, 1];
			default:
				return [-4, -4];
		}
	};

	/**
	 * handle mouse over of the stop cluster tooltip icon
	 *
	 * add the click listeners to handle our click handlers for links in our html
	 *
	 * @param markerId - the marker (icon) id of the tooltip
	 */
	private handleMouseOver = (markerId: string): void => {
		this.markers[markerId].withinTooltip = true;

		this.addStopClickListeners(markerId);
	};

	/**
	 * handle mouse out of the stop cluster tooltip icon
	 *
	 * remove the click handlers for the contents of the tooltip
	 *
	 * @param markerId - the marker (icon) id of the tooltip
	 */
	private handleMouseOut = (markerId: string): void => {
		this.markers[markerId].withinTooltip = false;
		this.removeStopTooltip(markerId);
	};

	/**
	 * add the click listeners to handle our click handlers for links in our html
	 *
	 * @param markerId - the marker (icon) id of the tooltip
	 */
	private addStopClickListeners = (markerId: string): void => {
		const stopCodes: string[] = markerId.split('_');

		stopCodes.forEach((stopCode: string) => {
			this.addStopCodeClickListener(stopCode);
		});
	};

	/**
	 * add the stop code click listener to the tooltip
	 *
	 * @param stopCode - the stop code
	 */
	private addStopCodeClickListener = (stopCode: string): void => {
		const stopCodeElement: HTMLElementExtended = document.getElementById('stopCode' + stopCode + 'Click');

		if (stopCodeElement) {
			stopCodeElement.stopCode = stopCode;
			stopCodeElement.addEventListener('click', this.onStopCodeClick);
		}
	};

	/**
	 * remove the click listeners for the tooltips
	 *
	 * @param markerId - the marker (icon) id of the tooltip
	 */
	private removeClickListeners = (markerId: string): void => {
		const stopCodes: string[] = markerId.split('_');

		stopCodes.forEach((stopCode: string) => {
			this.removeStopCodeClickListener(stopCode);
		});
	};

	/**
	 * remove the stop code click listener on the tooltip
	 *
	 * @param stopCode - the stop code
	 */
	private removeStopCodeClickListener = (stopCode: string): void => {
		const stopCodeElement: HTMLElementExtended = document.getElementById('stopCode' + stopCode + 'Click');

		if (stopCodeElement) {
			stopCodeElement.stopCode = null;
			stopCodeElement.removeEventListener('click', this.onStopCodeClick);
		}
	};

	/**
	 * handle the stop code click on the tooltip
	 *
	 * @param evt - the stop code click event including the stop code
	 */
	private onStopCodeClick = async (evt: EventExtended): Promise<void> => {
		const stopCode: string = evt.currentTarget.stopCode;

		await this.mapNavigationService.navigateToStopDetails(this.selectedAgency.authority_id, stopCode);
	};

	/**
	 * set up subscriptions for the page
	 *
	 * remove tooltips - handle the suscrive to remove the stop cluster tooltip(s)
	 */
	private setSubscriptions = (): void => {
		this.removeTooltips$Subscription = this.mapEventsService.removeTooltips.subscribe(() => {
			for (const markerId in this.markers) {
				this.markers[markerId].remove();
				delete this.markers[markerId];
			}
		});
	};
}

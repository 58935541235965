/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnInit } from '@angular/core';

import { ReportViewerSortableListBaseComponent } from '../../report-viewer-sortable-list-base.component';

import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { DataService } from '@cubicNx/libs/utils';
import { ReportsConfigService } from '../../../../../services/reports-config.service';
import { ReportsGenerationService } from '../../../../../services/reports-generation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { HeadwayByStop } from '../../../../../types/types';

import { ReportRoute, InteractiveReportHeadwayByRouteDetail, InteractiveReportHeadwayByRouteDetails } from '../../../../../types/api-types';

@Component({
	selector: 'hw300-headway-by-route-list',
	templateUrl: './headway-by-route-list.component.html',
	styleUrls: ['./headway-by-route-list.component.scss'],
})
export class HeadwayByRouteListComponent extends ReportViewerSortableListBaseComponent implements OnInit {
	public listData: InteractiveReportHeadwayByRouteDetails = null;

	constructor(
		public dataService: DataService,
		private reportsGenerationService: ReportsGenerationService,
		protected override reportsEventsService: ReportsEventsService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsEventsService, reportsConfigService, colorUtilityService, translationService);

		this.templateSummaryContent = this.dataService['templateSummaryContent' as keyof DataService];
		this.defaultTemplateId = this.dataService['defaultTemplateId' as keyof DataService];
	}

	/**
	 * performs initialization tasks for the headway by route list view (HW300)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (headway by route data)
	 */
	public updateListView = (listData: InteractiveReportHeadwayByRouteDetails): void => {
		this.listData = listData;
	};

	/**
	 * generates the route part of the report data
	 *
	 * @param headwayData - the headway by route data
	 */
	public generateReport = (headwayData: InteractiveReportHeadwayByRouteDetail): void => {
		const route: ReportRoute = {
			authority_id: this.templateSummaryContent.template.report_options.authority.authority_id,
			route_id: headwayData.route_id,
			route_display_name: headwayData.route_long_name,
			route_short_name: headwayData.route_short_name,
			route_long_name: headwayData.route_long_name,
			trip_headsign: headwayData.trip_headsign,
			avg: headwayData.avg,
			min: headwayData.min,
			max: headwayData.max,
			stddev: headwayData.stddev,
			count: headwayData.count,
		};

		this.reportsGenerationService.generateRouteReport(HeadwayByStop, this.templateSummaryContent.template, route);
	};
}

import { Component, Input } from '@angular/core';

import { ReportViewerSortableSubListBaseComponent } from '../report-viewer-sortable-sublist-base.component';

import { ReportsConfigService } from '../../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReportTemplateSummaryContent } from '../../../../types/types';

import { AdherenceThresholdOverride } from '../../../../types/api-types';

@Component({
	template: '',
})
export class ReportViewerAdherenceColumnBasedBaseComponent extends ReportViewerSortableSubListBaseComponent {
	@Input() templateSummaryContent: ReportTemplateSummaryContent = null;

	public authorityId: string = null;
	public agencyId: string = null;
	public adherenceOptions: AdherenceThresholdOverride = null;

	constructor(
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsConfigService, colorUtilityService, translationService);
	}

	/**
	 * common initialization functionality to setup adherence options and initialize
	 */
	protected override init = (): void => {
		this.adherenceOptions = {
			adherence_threshold_override: this.templateSummaryContent.template.report_options.adherence_threshold_override,
			adherence_threshold_settings: this.templateSummaryContent.template.report_options.adherence_threshold_settings,
		};

		super.init();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { VehicleMessagesApiService } from './vehicle-messages-api.service';

import { PredefinedMessages, CreateVehicleMessage, VehicleMessagesPaginatedResponse } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class VehicleMessagesDataService {
	constructor(
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private logger: LoggerService,
		private vehicleMessagesApiService: VehicleMessagesApiService
	) {}

	/**
	 * handles the request for the predefined messages for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the predefined messages for the given authority response
	 */
	public getPredefinedMessages = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.vehicleMessagesApiService.getPredefinedMessages(authorityId);

			result.success = true;
			result.resultData = response as PredefinedMessages;
		} catch (error) {
			this.logger.logError('Failed to get vehicle predefined messages', error);
		}

		return result;
	};

	/**
	 * handles the request for the vehicle messages for the given authority and request parameters from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param paginatedParams - the request parameters
	 * @returns the vehicle messages for the given authority response
	 */
	public getVehicleMessages = async (authorityId: string, paginatedParams: PaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.vehicleMessagesApiService.getVehicleMessages(authorityId, paginatedParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response?.results) {
				// data is a paginated response
				result.resultData = response as VehicleMessagesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: VehicleMessagesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as VehicleMessagesPaginatedResponse;
			}
		} catch (exception) {
			this.logger.logError('Failed to get vehicle messages for authority: ' + authorityId, exception);
		}

		return result;
	};

	/**
	 * handles the request for the creation of a vehicle message for the authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param vehicleMessage - the vehicle message content
	 * @returns the outcome of the create attempt
	 */
	public createVehicleMessage = async (authorityId: string, vehicleMessage: CreateVehicleMessage): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.vehicleMessagesApiService.createVehicleMessage(authorityId, vehicleMessage);

			result.success = response.success;

			if (response?.failures && response?.failures.length > 0) {
				this.logger.logWarn('Message could not be sent to the following vehicles: ', response.failures);
				this.notificationService.notifyWarning(
					this.translationService.getTranslation('T_MESSAGES.VEHICLE_MSG_WARNING') + ' ' + response.failures
				);
			}
		} catch (exception: any) {
			this.logger.logError('Create vehicle message failed.', exception);

			if (exception.status === 503) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_MESSAGES.VEHICLE_MSG_FAILURE'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};
}

<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body">
		<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
		<div *ngIf="loaded">
			<div *ngIf="success">
				<div *ngIf="hasResults">
					<div class="current-blocks-container" *ngIf="data.config.versionToggle === 'Blocks'">
						<table class="blocks-table" data-test="blocks.table">
							<colgroup>
								<col />
								<col *ngFor="let time of times; odd as isOdd" class="block-column" [ngClass]="isOdd ? 'column-light' : 'column-dark'" />
								<col />
							</colgroup>
							<tr>
								<td
									*ngFor="let time of times; first as isFirst"
									class="block-text"
									[ngStyle]="isFirst ? getMinWidth() : getColumnWidthPercentage()">
									{{ time }}
								</td>
							</tr>
							<tr *ngFor="let displayBlock of displayBlocks">
								<td
									(click)="navigateToBlockDetails(displayBlock.block.block_id)"
									[ngStyle]="getColumnWidthPercentage()"
									class="block-text block-id-column"
									data-test="block.block_id">
									{{ displayBlock.blockIdText }}
								</td>
								<td class="block-data" [ngStyle]="getColumnWidthPercentage()">
									<block-row
										[displayBlock]="displayBlock"
										(mouseEnterEvent)="openTooltip($event)"
										(mouseLeaveEvent)="closeTooltip()"></block-row>
								</td>
								<td *ngFor="let item of getRemainingColumns()" [ngStyle]="getColumnWidthPercentage()"></td>
							</tr>
							<tr>
								<td
									*ngFor="let time of times; first as isFirst"
									class="block-text"
									[ngStyle]="isFirst ? getMinWidth() : getColumnWidthPercentage()">
									{{ time }}
								</td>
							</tr>
						</table>
					</div>
					<div class="current-blocks-container" *ngIf="data.config.versionToggle === 'Routes'">
						<table class="blocks-table" data-test="blocks.table">
							<colgroup>
								<col />
								<col *ngFor="let time of times; odd as isOdd" class="block-column" [ngClass]="isOdd ? 'column-light' : 'column-dark'" />
								<col />
							</colgroup>
							<tr>
								<td *ngFor="let time of times" class="block-text" [ngStyle]="getColumnWidthPercentage()">{{ time }}</td>
							</tr>
							<tr *ngFor="let route of routes">
								<td (click)="navigateToRouteDetails(route.blocksInRoute[0])" [ngStyle]="getMinWidth()" class="block-text block-id-column">
									<div class="route-pill-container">
										<route-pill [route]="determineRoutePillData(route)" [useFullWidth]="true"></route-pill>
									</div>
								</td>
								<td class="block-data" [ngStyle]="getColumnWidthPercentage()">
									<div *ngFor="let section of route.sections">
										<div
											(click)="showSectionInformation(section)"
											[ngStyle]="getSectionStyle(section)"
											class="block-route-bar block-text {{ section.class }}"
											data-test="blocks.assigned.count">
											{{ section.text }}
										</div>
									</div>
								</td>
								<td *ngFor="let item of getRemainingColumns()" [ngStyle]="getColumnWidthPercentage()"></td>
							</tr>
							<tr>
								<td
									*ngFor="let time of times; first as isFirst"
									class="block-text"
									[ngStyle]="isFirst ? getMinWidth() : getColumnWidthPercentage()">
									{{ time }}
								</td>
							</tr>
						</table>
					</div>
					<current-section
						*ngIf="isSectionInformationShown"
						(closeSection)="closeSection()"
						(showBlockViewEvent)="navigateToBlockDetails($event)"
						[section]="sectionToShow"
						[timezone]="timezone">
					</current-section>
				</div>
				<div *ngIf="!hasResults" class="no-data text-center">{{ 'T_DASH.DB_NO_DATA_FOUND' | translate }}</div>
			</div>
			<div *ngIf="!success" class="no-data text-center" data-test="server.error">{{ 'T_DASH.DB_SERVER_ERROR' | translate }}</div>
		</div>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { SignsDataService } from '../../../edit/signs/service/signs-data.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { RoutesUtilService } from '../../../../../support-features/routes/services/routes-util.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { RiderMessagesDataService } from '../../../services/rider-messages-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { MessageSelectedSign, MessageSelectedSigns, Signs } from '../../../edit/signs/types/types';
import { MessageRoutesWithStops, MessageRouteWithStops, RiderMessagesListItem } from '../../../types/types';
import { StopServiceAlert, TripHeadsigns } from '../../../types/api-types';
import { Route, Routes } from '../../../../../support-features/routes/types/api-types';

@Component({
	selector: 'rider-messages-list-expanded-detail',
	templateUrl: './rider-messages-list-expanded-detail.component.html',
	styleUrls: ['./rider-messages-list-expanded-detail.component.scss'],
})
export class RiderMessagesListExpandedDetailComponent extends TranslateBaseComponent implements OnInit {
	@Input() messageListRow: RiderMessagesListItem;
	@Input() parentListColumnSizes: Map<string, number>;
	@Input() authorityId: string = null;
	@Input() agencyId: string;

	public isRouteMode: boolean = true;
	public signs: MessageSelectedSigns = [];
	public routes: MessageRoutesWithStops = [];
	public initialized: boolean = false;

	constructor(
		private signsDataService: SignsDataService,
		private riderMessagesDataService: RiderMessagesDataService,
		private routesDataService: RoutesDataService,
		private routesUtilService: RoutesUtilService,
		private logger: LoggerService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initilization for the component
	 *
	 * loads translations, loads routes/signs
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.isRouteMode = this.determineRouteSignMode();

		if (this.isRouteMode) {
			await this.loadRoutes();
		} else {
			await this.loadSigns();
		}

		this.initialized = true;
	}

	/**
	 * Gets the parent width of the description text as this route/signs area in the details will align and occupy same hortizontal space
	 *
	 * @returns the width for the route/signs column
	 */
	public getRouteSignsWidth = (): any => {
		const descriptionTextWidth: number = this.parentListColumnSizes.get('descriptionText');
		const startDateWidth: number = this.parentListColumnSizes.get('startDate');
		const endDateWidth: number = this.parentListColumnSizes.get('endDate');
		const activeTimesWidth: number = this.parentListColumnSizes.get('activeTimes');
		const createdByWidth: number = this.parentListColumnSizes.get('createdBy');

		return { width: descriptionTextWidth + startDateWidth + endDateWidth + activeTimesWidth + createdByWidth + 'px' };
	};

	/**
	 * loads required translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_MESSAGES.ALL_STOPS', 'T_MESSAGES.STOPS', 'T_MESSAGES.STOP']);
	};

	/**
	 * determins if this message has a type of signs or routes
	 *
	 * @returns a flag indiciating true for signs, false for routes
	 */
	private determineRouteSignMode = (): boolean => {
		return this.messageListRow.signIds.length > 0 ? false : true;
	};

	/**
	 * loads sign data
	 */
	private loadSigns = async (): Promise<void> => {
		this.isRouteMode = this.messageListRow.signIds.length > 0 ? false : true;

		const result: ResultContent = await this.signsDataService.getSigns(this.authorityId, this.agencyId);

		if (result.success) {
			const signsResponse: Signs = result.resultData;

			if (signsResponse?.signInfos?.length > 0) {
				// object for our list
				this.signs = signsResponse.signInfos.map((sign) => ({
					id: sign.id,
					name: sign.location,
				}));
			}

			this.signs = this.signs.filter((sign: MessageSelectedSign) => this.messageListRow.signIds.includes(sign.id));
		}
	};

	/**
	 * loads route data
	 */
	private loadRoutes = async (): Promise<void> => {
		const result: ResultContent = await this.routesDataService.getRoutes(this.authorityId, this.agencyId);

		if (result.success) {
			let routes: Routes = result.resultData;

			// filter the routes that are included in the message
			routes = routes.filter((route: Route) => this.messageListRow.routes.includes(route.route_id));

			this.routes = await Promise.all(
				routes.map(async (route: Route) => {
					const messageRoute: MessageRouteWithStops = {
						routeId: route.route_id,
						routeDisplayName: this.routesUtilService.getDisplayName(route),
						stopsLabel: await this.getStopsLabel(route.route_id),
					};

					return messageRoute;
				})
			);
		} else {
			this.logger.logError('Cant load routes - no routes to load.');
		}
	};

	/**
	 * Gets the stop label with the stop count
	 *
	 * @param routeId - the route id
	 * @returns the label for the stops count (or just 'All Stops')
	 */
	private getStopsLabel = async (routeId: string): Promise<string> => {
		let stopLabel: string = '';

		let allStopsSelected: boolean = true;

		const response: ResultContent = await this.riderMessagesDataService.getStopsByRoute(this.authorityId, routeId);

		if (response.success) {
			// first determine how many stops there are in total for this route per destination
			const stopInfo: any = response.resultData;
			const tripHeadsigns: TripHeadsigns = stopInfo[routeId].trip_headsigns;

			tripHeadsigns.forEach((tripHeadsign) => {
				const allDestinationStopsSelected: boolean = tripHeadsign.stops.every((stop) =>
					this.messageListRow.stops.find(
						(stopServiceAlert: StopServiceAlert) =>
							stopServiceAlert.stop_id_text === stop.stopId && stopServiceAlert.route_id === routeId
					)
				);

				allStopsSelected &&= allDestinationStopsSelected;
			});

			if (allStopsSelected) {
				stopLabel = '(' + this.translations['T_MESSAGES.ALL_STOPS'] + ')';
			} else {
				const stopsSelected: StopServiceAlert[] = this.messageListRow.stops.filter(
					(stop: StopServiceAlert) => stop.route_id === routeId
				);

				stopLabel = '(' + stopsSelected.length + ' ';

				if (stopsSelected.length === 1) {
					stopLabel += this.translations['T_MESSAGES.STOP'];
				} else {
					stopLabel += this.translations['T_MESSAGES.STOPS'];
				}

				stopLabel += ')';
			}
		}

		return stopLabel;
	};
}

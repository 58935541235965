/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { ListShowingValues } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapUtilsService {
	/**
	 * get the icon angle for the map from the nextbus API heading value
	 *
	 * @param heading - the heading
	 * @returns - the icon angle
	 */
	public getIconAngleFromHeading = (heading: number): number => {
		if (heading !== null) {
			return heading + 1;
		} else {
			return 0;
		}
	};

	/**
	 * helper method for our map list pages to format showing text
	 *
	 * @param pageNumber - the page number
	 * @param pageSize - the page size
	 * @param totalAmount - the total number of rows
	 * @returns the formatted  display object
	 */
	public getShowingPageValues = (pageNumber: number, pageSize: number, totalAmount: number): ListShowingValues => {
		let first: number = 0;
		let to: number = 0;

		if (totalAmount === 0) {
			first = 0;
			to = 0;
		} else {
			first = pageNumber * pageSize - pageSize + 1;
			to = first + pageSize - 1;

			if (to > totalAmount) {
				to = totalAmount;
			}
		}

		return {
			showingFirst: first,
			showingTo: to,
		};
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { FEED_TYPES_STORE, ROUTES_STORE, ROUTE_CONFIG_STORE } from './predictions-store-factory';

import { LoggerService } from '@cubicNx/libs/utils';
import { PredictionsApiService } from './predictions-api.service';

import { DisabledPredictions, DisablePredictionUpdate, FeedTypes } from '../types/api-types';
import { RouteConfig } from '../types/api-types';
import { Routes } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class PredictionsDataService {
	constructor(
		private predictionsApiService: PredictionsApiService,
		private logger: LoggerService,
		@Inject(ROUTES_STORE) private routesDataStore: any,
		@Inject(ROUTE_CONFIG_STORE) private routeConfigDataStore: any,
		@Inject(FEED_TYPES_STORE) private feedTypesDataStore: any
	) {}

	/**
	 * handles the requests the prediction routes for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the prediction routes response
	 */
	public getRoutes = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			let routes: Routes = this.routesDataStore.get(authorityId);

			if (!routes) {
				this.logger.logDebug(`requesting routes for: ${authorityId}`);

				routes = await this.predictionsApiService.getRoutes(authorityId);

				this.routesDataStore.set(authorityId, routes);
			} else {
				this.logger.logDebug(`returning routes for: ${authorityId} from cached store`);
			}

			result.success = true;
			result.resultData = routes as Routes;
		} catch (exception) {
			this.logger.logError('Failed to get routes for prediction data', exception);
		}

		return result;
	};

	/**
	 * handles the requests for the route configuration for the given authority/route from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param routeId - the route id
	 * @returns the route configuration response
	 */
	public getRouteConfig = async (authorityId: string, routeId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + routeId;

			let routeConfig: RouteConfig = this.routeConfigDataStore.get(storeKey);

			if (!routeConfig) {
				this.logger.logDebug(`requesting route config: ${routeId}`);

				routeConfig = await this.predictionsApiService.getRouteConfig(authorityId, routeId);

				this.routeConfigDataStore.set(storeKey, routeConfig);
			} else {
				this.logger.logDebug(`returning route config: ${routeId} from cached store`);
			}

			result.success = true;
			result.resultData = routeConfig as RouteConfig;
		} catch (exception) {
			this.logger.logError('Failed to get routes config for prediction data', exception);
		}

		return result;
	};

	/**
	 * handles the requests for the feed types for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the feed types response for supplied authority
	 */
	public getFeedTypes = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			let feedTypes: FeedTypes = this.feedTypesDataStore.get(authorityId);

			if (!feedTypes) {
				this.logger.logDebug(`requesting feed types for: ${authorityId}`);

				feedTypes = await this.predictionsApiService.getFeedTypes(authorityId);

				this.feedTypesDataStore.set(authorityId, feedTypes);
			} else {
				this.logger.logDebug(`returning feed types for: ${authorityId} from cached store`);
			}

			result.success = true;
			result.resultData = feedTypes as FeedTypes;
		} catch (exception) {
			this.logger.logError('Failed to get feed types for prediction data', exception);
		}

		return result;
	};

	/**
	 * handles the requests for all of the disabled predictions for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns all of the disabled predictions details for the supplied authority response
	 */
	public getDisabledPredictions = async (authorityId: string): Promise<ResultContent> => {
		this.logger.logDebug('requesting Prediction data for: ' + authorityId);

		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const allPredictions: any = await this.predictionsApiService.getAllDisabledPredictions(authorityId);

			result.success = true;
			result.resultData = allPredictions as DisabledPredictions;
		} catch (exception) {
			this.logger.logError('Failed to get prediction data', exception);
		}

		return result;
	};

	/**
	 * handles the requests for the update of the disabled predictions change for the given authority to the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param disablePredictionUpdate - disable prediction update details
	 * @param isDelete - whether the update is a delete or not
	 * @returns the disable predictions update response
	 */
	public disablePredictionUpdate = async (
		authorityId: string,
		disablePredictionUpdate: DisablePredictionUpdate,
		isDelete: boolean
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// just return after the await (there is no content returned)
			await this.predictionsApiService.disablePredictionUpdate(authorityId, disablePredictionUpdate, isDelete);

			result.success = true;
		} catch (exception) {
			this.logger.logError('Failed to update data prediction data', exception);
		}

		return result;
	};
}

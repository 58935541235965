/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { Agencies, Agency } from '../../../support-features/agencies/types/api-types';
import { maxlengthValidation, ValidationText } from '@cubicNx/libs/utils';
import { DashboardSelectedAgency, IWidgetEditModalComponent } from '../types/types';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	template: '',
})
export class WidgetEditBaseComponent extends TranslateBaseComponent implements IWidgetEditModalComponent {
	@Input() widget: any;
	@Output() formValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	public reactiveForm: FormGroup;
	public agencies: Agencies;

	public titleExtraMessageText: ValidationText = {
		[maxlengthValidation]: null,
	};

	protected maxTitleLength: number = 28;
	protected minTitleLength: number = 1;

	private formChanges$Subscription: Subscription = null;

	constructor(
		protected agenciesService: AgenciesDataService,
		protected formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(translationService);

		this.titleExtraMessageText = {
			[maxlengthValidation]: this.maxTitleLength.toString(),
		};
	}

	/**
	 * Handles the selection of an agency.
	 *
	 * @param agencyFoundCallback - a function to execute if the specific agency has been found.
	 * @param callback - a function to execute regardless of the agency being found.
	 */
	protected handleAgencyChanged = async (agencyFoundCallback: (agency?: any) => any, callback: () => any): Promise<void> => {
		// set the agency loaded form property to be null again - effectively meaning the form will be innvalid
		this.reactiveForm.get('agencyLoaded').setValue(null);

		const agencyId: string = this.getSelectedAgencyId();

		const agency: Agency = this.agencies.find((i) => i.agency_nb_id.toString() === agencyId.toString());

		if (agency) {
			this.widget.config.selectedAgency = {
				id: agency.agency_nb_id,
				agency_id: agency.agency_id,
				agency_name: agency.agency_name,
				agency_nb_id: agency.agency_nb_id,
				authority_id: agency.authority_id,
				nb_id: agency.nb_id,
			} as DashboardSelectedAgency;
			await agencyFoundCallback();
		}

		await callback();

		// new agency loaded - set agency loaded back valid.  This ensures the save is unavilable
		// while necessary agency details are loaded (without this test automation was able to save the form
		// before it was ready)
		this.reactiveForm.get('agencyLoaded').setValue(true);

		// reset the form to back to it's appropraite state after we have performed our call back actions
		this.formValidChanged.emit(this.reactiveForm.valid);
	};

	/**
	 * gets the selected agencyId from the form. This is a separate method
	 * so it can be overriden for widget forms where the agency is located in a different place
	 *
	 * @returns the agency value from the form
	 */
	protected getSelectedAgencyId = (): string => {
		return this.reactiveForm.get('agencyId').value;
	};

	/**
	 * Requests agency list for the widget and defaults settings if only one agency available
	 *
	 * @param agencyChangedCallback - a callback function for widget implementation specific actions.
	 * Required for single agency mode where user doesn't have control to pick an agency. Instead we
	 * fire the agency changed method so any specific actions are ensured the same way as when we choose
	 * an agency
	 */
	protected setupAgencies = async (agencyChangedCallback: () => void): Promise<void> => {
		this.getSelectableAgencies();

		if (this.agencies?.length === 1) {
			// set the single agency and trigger agency change actions for further init only if it hasnt already been set
			if (!this.widget.config?.selectedAgency) {
				this.setSingleAgency();

				// must be called before we call the call back below (hence being in both conditions of the if)
				this.setAgencyFormValues();

				await agencyChangedCallback();
			} else {
				this.setAgencyFormValues();
			}
		}
	};

	/**
	 * Builds a base form and adds extra controls if required.
	 *
	 * @param addExtraControlsCallback - a callback function to add extra controls after the basic title and agency controls are added.
	 * @param options - additional validation options
	 * @param agencyChangedCallback - a callback function for widget implementation specific actions. Required for single agency mode
	 * where user doesn't have control to pick an agency. Instead we
	 *
	 */
	protected buildForm = (addExtraControlsCallback: () => void, options: any): void => {
		this.reactiveForm = this.formBuilder.group(
			{
				title: ['', [Validators.required, Validators.maxLength(this.maxTitleLength), Validators.minLength(this.minTitleLength)]],
				agencyId: ['', [Validators.required]],
				agencyLoaded: ['', [Validators.required]],
			},
			options
		);
		this.reactiveForm.get('title').setValue(this.widget.title);
		this.reactiveForm.get('agencyId').setValue(this.widget.config.selectedAgency?.agency_nb_id);

		if (this.widget.config.selectedAgency?.agency_nb_id) {
			this.reactiveForm.get('agencyLoaded').setValue(true);
		}

		addExtraControlsCallback();
	};

	/**
	 * Sets the widget for the single selected agency.
	 */
	protected setSingleAgency = (): void => {
		this.widget.config.selectedAgency = {
			id: this.agencies[0].agency_nb_id,
			agency_id: this.agencies[0].agency_id,
			agency_name: this.agencies[0].agency_name,
			agency_nb_id: this.agencies[0].agency_nb_id,
			authority_id: this.agencies[0].authority_id,
			nb_id: this.agencies[0].nb_id,
		} as DashboardSelectedAgency;
	};

	/**
	 * Gets the selectable agencies for the agency dropdown input.
	 */
	protected getSelectableAgencies = (): void => {
		this.agencies = this.agenciesService.getAgencies();
	};

	/**
	 * Setup subscriptions to observables.
	 */
	protected setSubscriptions = (): void => {
		this.formChanges$Subscription = this.reactiveForm.valueChanges.subscribe(() => {
			this.handleTitleChange();
			this.formValidChanged.emit(this.reactiveForm.valid);
		});
	};

	/**
	 * Sets the widget object title to the form title.
	 */
	protected handleTitleChange = (): void => {
		this.widget.title = this.reactiveForm.get('title').value;
	};

	/**
	 * Unsubscribe from subscribed observables.
	 */
	protected unsubscribe = (): void => {
		this.formChanges$Subscription?.unsubscribe();
	};

	/**
	 * set/default agency form values
	 */
	private setAgencyFormValues = (): void => {
		this.reactiveForm.get('agencyId').setValue(this.widget.config.selectedAgency.agency_nb_id);
		this.reactiveForm.get('agencyLoaded').setValue(true);
	};
}

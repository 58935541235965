<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container configuration-schedule-for-route-list-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<div *ngFor="let reportItem of listData">
					<div class="configuration-schedule-for-route-list-block-container">
						<div class="configuration-schedule-for-route-list-block">
							<div class="grid-title">
								<div class="configuration-schedule-for-route-list-block-title">
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_CORE.ROUTE' | translate }}
										</div>
										<route-pill [route]="determineRoutePillData(reportItem)"></route-pill>
									</div>
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.FULL_NAME' | translate }}
										</div>
										<div class="view-table-field">
											{{ reportItem.route_long_name }}
										</div>
									</div>
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.SERVICE_CLASS' | translate }}
										</div>
										<div class="view-table-field">
											{{ reportItem.service_class }}
										</div>
									</div>
									<div class="nb-padding-right-lg configuration-schedule-for-route-list-block-title-column">
										<div class="view-table-label">
											{{ 'T_REPORT.DESTINATION' | translate }}
										</div>
										<div class="view-table-field">
											{{ reportItem.direction }}
										</div>
									</div>
								</div>
							</div>
							<table class="table-container">
								<thead>
									<tr *ngIf="reportItem.schedule_sort_selection === 'scheduleSortByBlock'">
										<th class="mw-col ellipsis">{{ 'T_REPORT.BLOCK' | translate }}</th>
										<th class="mw-col ellipsis" *ngFor="let reportItemData of reportItem.data">{{ reportItemData[0] }}</th>
									</tr>
									<tr *ngIf="reportItem.schedule_sort_selection === 'scheduleSortByTime'">
										<th class="mw-col ellipsis" *ngFor="let header of reportItem.headers">{{ header }}</th>
									</tr>
								</thead>
								<tbody class="zebra">
									<tr *ngIf="reportItem.service_class === null">
										<td>
											{{ 'T_REPORT.NO_LOG_DAT' | translate }}
										</td>
									</tr>
									<ng-container *ngIf="reportItem.schedule_sort_selection === 'scheduleSortByBlock'">
										<tr *ngFor="let header of reportItem.headers; let i = index">
											<ng-container *ngIf="i > 0 && reportItem.schedule_sort_selection === 'scheduleSortByBlock'">
												<td class="ellipsis">{{ header }}</td>
												<td class="ellipsis" *ngFor="let times of reportItem.data">
													{{ times[i] }}
												</td>
											</ng-container>
										</tr>
									</ng-container>
									<ng-container *ngIf="reportItem.schedule_sort_selection === 'scheduleSortByTime'">
										<tr *ngFor="let rowTimes of reportItem.data">
											<td class="ellipsis" *ngFor="let timesData of rowTimes">{{ timesData }}</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

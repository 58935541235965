<!--
    COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

    Information Contained Herein is Proprietary and Confidential.
    The document is the property of "CUBIC" and may not be disclosed
    distributed, or reproduced  without the express written permission of "CUBIC".
-->

<div xmlns:padding-top="http://www.w3.org/1999/xhtml">
	<!--MAP SETTINGS HEADER-->
	<div class="nb-map-settings" [hidden]="settingsDisplay !== settingsDisplayType.settings">
		<div class="map-settings-header-container">
			<div>
				<button type="button" class="close btn-link-dark" data-test="map.settings.close" (click)="handleCloseSettings()" aria-hidden="true">
					<i class="nb-icons nb-close"></i>
				</button>
			</div>
			<div class="nb-map-settings-header" [hidden]="mapOptionsService.getMode() === modeType.ladder">
				<span class="nb-subhead" data-test="map.settings.header">{{ 'T_CORE.MAP_SETTINGS' | translate }}</span>
			</div>
			<div class="nb-map-settings-header" [hidden]="mapOptionsService.getMode() !== modeType.ladder">
				<span class="nb-subhead" data-test="map-ladder.settings.header">{{ 'T_MAP.LADDER_SETTINGS' | translate }}</span>
			</div>
		</div>
		<div class="map-settings-vehicles-container">
			<!--MAP SETTINGS / VEHICLES-->
			<div class="content nb-padding-all-md map-settings-vehicles-sub-container">
				<div class="map-settings-vehicles-title-container">
					<div class="nb-section-title">
						<span>{{ 'T_MAP.MAP_VEHICLES' | translate }}</span>
					</div>
				</div>
				<div class="map-settings-vehicles-inner-container">
					<div class="map-settings-vehicles-inner-sub-container">
						<div>
							<span class="nb-section-subtitle">{{ 'T_MAP.MAP_SHOW' | translate }}</span>
						</div>
						<div class="nb-form-control">
							<div class="nb-checkbox">
								<label for="showVehiclesWithAdherenceIssues">
									<input
										id="showVehiclesWithAdherenceIssues"
										type="checkbox"
										data-test="map.settings.early-late"
										(change)="showVehiclesWithAdherenceIssuesChange()"
										[ngModel]="mapVehiclesService.getShowVehiclesWithAdherenceIssues()" />
									<div class="checkbox-label">{{ 'T_MAP.MAP_VEHICLES_WITH_ISSUES_ONLY' | translate }}</div>
								</label>
							</div>
						</div>
						<div class="nb-form-control">
							<div class="nb-checkbox">
								<label for="showVehiclesWithHeadwayIssues">
									<input
										id="showVehiclesWithHeadwayIssues"
										type="checkbox"
										data-test="map.settings.close-distant"
										(change)="showVehiclesWithHeadwayIssuesChange()"
										[ngModel]="mapVehiclesService.getShowVehiclesWithHeadwayIssues()" />
									<div class="checkbox-label">{{ 'T_MAP.MAP_VEHICLES_WITH_HEADWAY_ISSUES_ONLY' | translate }}</div>
								</label>
							</div>
						</div>
					</div>
					<div class="map-settings-vehicles-labels-container">
						<div>
							<span class="nb-section-subtitle">{{ 'T_MAP.MAP_LABELS' | translate }}</span>
						</div>
						<div class="nb-form-control">
							<div class="nb-checkbox">
								<label for="showVehicleLabels">
									<input
										id="showVehicleLabels"
										type="checkbox"
										data-test="map.settings.showVehicleLabels"
										(change)="showVehicleLabelsChange()"
										[ngModel]="mapOptionsService.getShowVehicleLabels()" />
									<div class="checkbox-label">{{ 'T_MAP.MAP_SHOW_LABELS' | translate }}</div>
								</label>
							</div>
						</div>
						<div class="map-settings-vehicles-labels-inner-container">
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelId">
										<input
											id="showVehicleLabelId"
											type="checkbox"
											data-test="map.settings.showVehicleLabelId"
											(change)="showVehicleLabelIdChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelId()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_ID' | translate }}</div>
									</label>
								</div>
							</div>
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelRoute">
										<input
											id="showVehicleLabelRoute"
											type="checkbox"
											data-test="map.settings.showVehicleLabelRoute"
											(change)="showVehicleLabelRouteChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelRoute()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_ROUTE' | translate }}</div>
									</label>
								</div>
							</div>
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelDepartureAdherence">
										<input
											id="showVehicleLabelDepartureAdherence"
											type="checkbox"
											data-test="map.settings.showVehicleLabelDepartureAdherence"
											(change)="showVehicleLabelDepartureAdherenceChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelDepartureAdherence()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_ADHERENCE' | translate }}</div>
									</label>
								</div>
							</div>
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelHeadway">
										<input
											id="showVehicleLabelHeadway"
											type="checkbox"
											data-test="map.settings.showVehicleLabelHeadway"
											(change)="showVehicleLabelHeadwayChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelHeadway()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_HEADWAY' | translate }}</div>
									</label>
								</div>
							</div>
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelStatus">
										<input
											id="showVehicleLabelStatus"
											type="checkbox"
											data-test="map.settings.showVehicleLabelStatus"
											(change)="showVehicleLabelStatusChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelStatus()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_STATUS' | translate }}</div>
									</label>
								</div>
							</div>
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelBlock">
										<input
											id="showVehicleLabelBlock"
											type="checkbox"
											data-test="map.settings.showVehicleLabelBlock"
											(change)="showVehicleLabelBlockChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelBlock()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_BLOCK' | translate }}</div>
									</label>
								</div>
							</div>
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showVehicleLabelPassengers">
										<input
											id="showVehicleLabelPassengers"
											type="checkbox"
											data-test="map.settings.showVehicleLabelPassengers"
											(change)="showVehicleLabelPassengersChange()"
											[ngModel]="mapOptionsService.getShowVehicleLabelPassengers()"
											[disabled]="!mapOptionsService.getShowVehicleLabels()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_OCCUPANCY' | translate }}</div>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content"></div>
		<div class="nb-padding-all-md">
			<span class="nb-section-title">{{ 'T_MAP.MAP_DISPLAY_PRIORITY' | translate }}</span>
			<div>
				<div class="map-settings-display-priority-buttons-container">
					<div class="btn-group">
						<button
							id="route"
							class="map-settings-display-priority-button"
							(click)="displayPriorityChange(displayPriorityType.vehicles)"
							[ngClass]="mapOptionsService.getDisplayPriority() === displayPriorityType.vehicles ? 'btn btn-primary' : 'btn btn-default'">
							<span class="nb-button-text">{{ 'T_MAP.MAP_VEHICLES' | translate }}</span>
						</button>
						<button
							id="adherence"
							class="map-settings-display-priority-button"
							(click)="displayPriorityChange(displayPriorityType.stops)"
							[ngClass]="mapOptionsService.getDisplayPriority() === displayPriorityType.stops ? 'btn btn-primary' : 'btn btn-default'">
							<span class="nb-button-text">{{ 'T_MAP.MAP_STOPS' | translate }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="content"></div>
		<div class="nb-padding-all-md">
			<div>
				<div [hidden]="mapOptionsService.getMode() === modeType.ladder">
					<span class="nb-section-title">{{ 'T_MAP.MAP_VEHICLE_COLOR' | translate }}</span>
				</div>
				<div class="map-settings-color-container" [hidden]="mapOptionsService.getMode() !== modeType.ladder">
					<span class="nb-section-title">{{ 'T_MAP.MAP_VEHICLE_COLOR' | translate }}</span>
				</div>
			</div>
			<div>
				<div class="map-settings-color-buttons-container">
					<div class="btn-group">
						<button
							id="route"
							class="map-settings-color-button"
							(click)="colorVehicleByChange(colorVehicleByType.route)"
							[ngClass]="mapVehiclesService.getColorVehicleBy() === colorVehicleByType.route ? 'btn btn-primary' : 'btn btn-default'">
							<span class="nb-button-text">{{ 'T_MAP.MAP_ROUTE' | translate }}</span>
						</button>
						<button
							id="adherence"
							class="map-settings-color-button"
							(click)="colorVehicleByChange(colorVehicleByType.adherence)"
							[ngClass]="mapVehiclesService.getColorVehicleBy() === colorVehicleByType.adherence ? 'btn btn-primary' : 'btn btn-default'">
							<span class="nb-button-text">{{ 'T_MAP.MAP_SCHEDULE' | translate }}</span>
						</button>
						<button
							id="headway"
							class="map-settings-color-button"
							(click)="colorVehicleByChange(colorVehicleByType.headway)"
							[ngClass]="mapVehiclesService.getColorVehicleBy() === colorVehicleByType.headway ? 'btn btn-primary' : 'btn btn-default'">
							<span class="nb-button-text">{{ 'T_MAP.MAP_HEADWAY' | translate }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="content" [hidden]="mapOptionsService.getMode() === modeType.ladder"></div>
		<div class="nb-padding-all-md" [hidden]="mapOptionsService.getMode() === modeType.ladder">
			<div>
				<div>
					<span class="nb-section-title">{{ 'T_MAP.MAP_VEHICLE_LABEL_CLUSTER_TITLE' | translate }}</span>
				</div>
			</div>
			<div class="map-settings-slider-container">
				<slider
					*ngIf="sliderInitialized"
					[values]="vehicleLabelClusterRadiusValues"
					[sliderCfg]="vehicleLabelClusterRadiusConfig"
					(updatedValues)="setVehicleLabelClusterRadius($event)">
				</slider>
			</div>
		</div>
		<div class="content nb-padding-all-md" [hidden]="mapOptionsService.getMode() !== modeType.ladder">
			<div class="ladder-settings-stops-container">
				<div>
					<span class="nb-section-title">{{ 'T_MAP.LADDER_STOPS' | translate }}</span>
				</div>
				<div>
					<span class="nb-section-subtitle">{{ 'T_MAP.LADDER_LABELS' | translate }}</span>
				</div>
				<div class="nb-form-control">
					<div class="nb-checkbox">
						<label for="showLadderStopLabels">
							<input
								id="showLadderStopLabels"
								type="checkbox"
								data-test="map.settings.stops.showLabels"
								(change)="showLadderStopLabelsChange()"
								[ngModel]="mapOptionsService.getShowLadderStopLabels()" />
							<div class="checkbox-label">{{ 'T_MAP.LADDER_SHOW_LABELS' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="ladder-settings-stops-label-container">
					<div class="nb-form-control">
						<div class="nb-checkbox">
							<label for="showLadderStopLabelId">
								<input
									id="showLadderStopLabelId"
									type="checkbox"
									data-test="map.settings.stops.id"
									(change)="showLadderStopLabelCodeChange()"
									[ngModel]="mapOptionsService.getShowLadderStopLabelCode()"
									[disabled]="!mapOptionsService.getShowLadderStopLabels()" />
								<div class="checkbox-label">{{ 'T_MAP.STOP_CODE' | translate }}</div>
							</label>
						</div>
					</div>
					<div class="nb-form-control">
						<div class="nb-checkbox">
							<label for="showLadderStopLabelName">
								<input
									id="showLadderStopLabelName"
									type="checkbox"
									data-test="map.settings.stops.name"
									(change)="showLadderStopLabelNameChange()"
									[ngModel]="mapOptionsService.getShowLadderStopLabelName()"
									[disabled]="!mapOptionsService.getShowLadderStopLabels()" />
								<div class="checkbox-label">{{ 'T_CORE.STOP_NAME' | translate }}</div>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<current-agency
			(agencyUpdated)="handleAgencyUpdated()"
			[titleText]="'T_CORE.AGENCY_SELECTION' | translate"
			[isDisabled]="mapOptionsService.getMapMode() === mapModeType.replay">
		</current-agency>
		<div class="nb-padding-all-sm content -nb-bottombar-button">
			<div>
				<div class="pull-right nb-clickable">
					<div href (click)="moreSettingsDisplayToggle()">
						<span class="nb-button-text" data-test="map.settings.more-settings" [hidden]="mapOptionsService.getMode() === modeType.ladder">
							{{ 'T_MAP.MAP_MORE_MAP_SETTINGS' | translate }}
						</span>
						<span class="nb-button-text" data-test="map.settings.more-settings" [hidden]="mapOptionsService.getMode() !== modeType.ladder">
							{{ 'T_MAP.LADDER_MORE_LADDER_SETTINGS' | translate }}</span
						>
						<span class="material-icons nb-forward-arrow">arrow_forward</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--MORE MAP SETTINGS-->
	<div class="nb-map-settings map-settings-more-container" [hidden]="settingsDisplay !== settingsDisplayType.moreSettings">
		<button type="button" class="close btn-link-dark" data-test="map.settings.close" aria-hidden="true" (click)="handleCloseSettings()">
			<i class="nb-icons nb-close"></i>
		</button>
		<div class="header nb-map-settings-header">
			<div class="title nb-clickable" (click)="settingsDisplayToggle()">
				<i class="nb-icons nb-backarrow nb-map-details-back-btn" data-test="map.settings-btn.back"></i>
				&nbsp;&nbsp;
				<span class="nb-subhead" [hidden]="mapOptionsService.getMode() === modeType.ladder">{{ 'T_CORE.MAP_SETTINGS' | translate }}</span>
				<span class="nb-subhead" [hidden]="mapOptionsService.getMode() !== modeType.ladder">{{ 'T_MAP.LADDER_SETTINGS' | translate }}</span>
			</div>
		</div>
		<!--MORE MAP SETTINGS / GENERAL-->
		<div class="content nb-padding-all-md">
			<div class="row">
				<div class="col-xs-12 nb-section-title">
					<span>{{ 'T_CORE.GENERAL' | translate }}</span>
				</div>
			</div>
			<div>
				<span class="nb-section-subtitle">{{ 'T_MAP.MAP_DISPLAY_TIME' | translate }}</span>
			</div>
			<div class="more-settings-display-time-button-container">
				<div class="btn-group">
					<button
						id="clock-time"
						class="more-settings-display-time-clock-button"
						(click)="setTimeFormatChange(timeFormatType.clockTime)"
						[ngClass]="mapOptionsService.getTimeFormat() === timeFormatType.clockTime ? 'btn btn-primary' : 'btn btn-default'">
						<span class="nb-button-text" data-test="map.settings.clock">{{ 'T_MAP.MAP_CLOCK_TIME' | translate }}</span>
					</button>
					<button
						id="relative-time"
						class="more-settings-display-time-relative-button"
						(click)="setTimeFormatChange(timeFormatType.relativeTime)"
						[ngClass]="mapOptionsService.getTimeFormat() === timeFormatType.relativeTime ? 'btn btn-primary' : 'btn btn-default'">
						<span class="nb-button-text" data-test="map.settings.relative">{{ 'T_MAP.MAP_RELATIVE_TIME' | translate }}</span>
					</button>
				</div>
			</div>
		</div>
		<!-- DEPOT -->
		<div name="depots" class="content nb-padding-all-md">
			<span class="nb-section-title">{{ 'T_MAP.MAP_DEPOT' | translate }}</span>
			<select
				name="depots"
				id="depots"
				class="form-control"
				data-test="map.settings.more-settings-depot"
				[ngModel]="mapVehiclesService.getSelectedDepotFilter().tag"
				(change)="selectedDepotTagFilterChanged($event.target)">
				<option *ngFor="let depot of depotSelections" value="{{ depot.tag }}">
					{{ depot.name }}
				</option>
			</select>
		</div>
		<!-- MORE MAP SETTINGS / BASE MAP -->
		<div class="content nb-padding-all-md" [hidden]="mapOptionsService.getMode() === modeType.ladder">
			<span class="nb-section-title">{{ 'T_MAP.MAP_VECTOR_MAPS' | translate }}</span>
			<div class="row">
				<div class="col-sm-12 nb-map-settings-subitem">
					<div class="btn-group">
						<button
							id="light"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.light)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.light ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_LIGHT' | translate }}</span>
						</button>
						<button
							id="dark"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.dark)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.dark ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_DARK' | translate }}</span>
						</button>
						<button
							id="streets"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.streets)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.streets ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_STREET' | translate }}</span>
						</button>
						<button
							id="satellite"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.satellite)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.satellite ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_SATELLITE' | translate }}</span>
						</button>
						<button
							id="traffic"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.traffic)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.traffic ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_TRAFFIC' | translate }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="content nb-padding-all-md" [hidden]="!(showRasterMapOption && mapOptionsService.getMode() !== modeType.ladder)">
			<span class="nb-section-title">{{ 'T_MAP.MAP_RASTER_MAPS' | translate }}</span>
			<div class="row">
				<div class="col-sm-12 nb-map-settings-subitem">
					<div class="btn-group">
						<button
							id="raster_light"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.rasterLight)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.rasterLight ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_LIGHT' | translate }}</span>
						</button>
						<button
							id="raster_dark"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.rasterDark)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.rasterDark ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_DARK' | translate }}</span>
						</button>
						<button
							id="raster_streets"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.rasterStreets)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.rasterStreets ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_STREET' | translate }}</span>
						</button>
						<button
							id="raster_satellite"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.rasterSatellite)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.rasterSatellite ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_SATELLITE' | translate }}</span>
						</button>
						<button
							id="raster_traffic"
							class="map-settings-more-maps-buttons"
							(click)="baseMapChange(baseMapType.rasterTraffic)"
							[ngClass]="mapBaseService.getBaseMap() === baseMapType.rasterTraffic ? 'btn btn-primary' : 'btn btn-default'"
							[disabled]="baseMapChanging">
							<span>{{ 'T_MAP.MAP_TRAFFIC' | translate }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="content nb-padding-all-md map-settings-stops-container">
			<div class="map-settings-stops-title-container">
				<div class="nb-section-title">
					<span>{{ 'T_MAP.MAP_STOPS' | translate }}</span>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<div class="row">
						<div class="col-xs-12">
							<div class="nb-form-control">
								<div class="nb-checkbox">
									<label for="showHiddenStops">
										<input
											id="showHiddenStops"
											type="checkbox"
											data-test="map.settings.stops.hidden"
											(change)="showHiddenStopsChange()"
											[ngModel]="mapStopsService.getShowHiddenStops()" />
										<div class="checkbox-label">{{ 'T_MAP.MAP_SHOW_HIDDEN_STOPS' | translate }}</div>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import moment from 'moment';

import { CssClassType, IconType, SelectableIconType } from '@cubicNx/libs/utils';

export enum VehicleAdherenceType {
	unknown,
	veryEarly,
	early,
	onTime,
	late,
	veryLate,
}

export enum VehicleStatusDisplayType {
	none,
	unassigned,
	unassignedInDepot,
	stale,
	onRoute,
	unpredictable,
	deadheading,
	deassignedNoRoute,
	intransit,
	arrived,
	dwelling,
	departing,
	departed,
	driverOnBreak,
	atDepot,
}

export interface VehicleStatusDisplayTypes {
	vehicleStatusDisplayType: VehicleStatusDisplayType;
	VehicleStatusDisplaySubtype: VehicleStatusDisplayType;
}

export interface VehiclesListItem {
	id: string;
	vehicleVisibility: SelectableIconType;
	vehicleId: string;
	vehicleStatus: CssClassType;
}

export type VehiclesList = Array<VehiclesListItem>;

export interface NextStopListItem {
	stopCode: string;
	stopDescription: string;
	rawPredictedArrival: number;
	predictedTimeUntilStop: string;
}

export type NextStopList = Array<NextStopListItem>;

// vehicle event list in vehicle details page
export interface VehicleEventListItem {
	category: IconType;
	categoryRaw: string;
	createdAt: string;
	createdAtRaw: moment.Moment;
	type: string;
}

export type VehicleEventList = Array<VehicleEventListItem>;

export interface VehicleReassignBlockListItem {
	blockId: string;
	blockName: string;
	dateTime: string;
	status: CssClassType;
	reassign: CssClassType;
	available: boolean;
}

export type VehicleReassignBlockList = Array<VehicleReassignBlockListItem>;

export interface VehicleAdherenceDisplay {
	time: string;
	class: string;
}

export interface VehicleAdherencePrefix {
	prefix: string;
	seconds: number;
}

export interface VehicleStatusDisplaySubClass {
	text: string;
	icon: string;
	class: string;
}

export interface VehicleStatusDisplay {
	text: string;
	icon: string;
	class?: string;
	subClass?: VehicleStatusDisplaySubClass;
}

export interface VehicleSpeedDisplay {
	speed: string;
	units: string;
	deg: number;
}

export interface VehicleColor {
	backgroundColor: string;
	foreColor: string;
}

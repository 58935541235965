/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MapViewEventsService {
	public refreshViews: Observable<any>;

	private refreshViewsSource: Subject<any> = new Subject<any>();

	constructor() {
		this.refreshViews = this.refreshViewsSource.asObservable();
	}

	/**
	 * Publish a refreshviews  allowing subscribers to handle events.
	 */
	public publishRefreshViews = (): void => {
		// @ts-ignore
		this.refreshViewsSource.next();
	};
}

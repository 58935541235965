<form [formGroup]="reactiveForm" class="form-group row" [ngClass]="agencies?.length > 1 ? 'form-height' : 'form-height-single-agency'">
	<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
	<div [hidden]="!loaded">
		<div id="title-text" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
			<input
				formControlName="title"
				data-test="title.input"
				type="text"
				id="title"
				class="form-control"
				placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
			<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
		</div>

		<div *ngIf="agencies?.length > 1">
			<div id="agency" class="form-group">
				<div class="nb-required">
					<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
				</div>
				<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
				<select
					name="agencyId"
					id="agencyId"
					class="form-control"
					formControlName="agencyId"
					data-test="agency.selection"
					(change)="agencyChanged()">
					<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
						{{ agency.agency_name }}
					</option>
				</select>
				<input-validation [control]="reactiveForm.controls.agencyId"></input-validation>
			</div>
		</div>

		<div id="route" class="form-group">
			<div>
				<label>{{ 'T_CORE.ROUTES_MAX_SELECT' | translate }}</label>
			</div>
			<div>
				<multi-select
					[settings]="routeSettings"
					data-test="routes.select"
					[items]="routesSummaries"
					[selected]="selectedRoutes"
					(itemClickedEvent)="updateItemClicked()"
					(removeItemClicked)="updateItemClicked()">
				</multi-select>
			</div>
		</div>

		<div id="display" class="form-group">
			<div>
				<label>{{ 'T_DASH.DB_DISPLAY' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button
						class="btn btn-extra-width"
						[ngClass]="displayButtonClass('chartList')"
						(click)="setDisplay('chartList')"
						data-test="display.chart_list">
						{{ 'T_DASH.DB_CHART_PLUS_LIST' | translate }}
					</button>
					<button
						class="btn btn-extra-width"
						[ngClass]="displayButtonClass('chartOnly')"
						(click)="setDisplay('chartOnly')"
						data-test="display.chart_only">
						{{ 'T_DASH.DB_CHART_ONLY' | translate }}
					</button>
					<button
						class="btn btn-extra-width"
						[ngClass]="displayButtonClass('listOnly')"
						(click)="setDisplay('listOnly')"
						data-test="display.list_only">
						{{ 'T_DASH.DB_LIST_ONLY' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div class="form-group">
			<div>
				<label for="listSize">{{ 'T_DASH.DB_LIST_SIZE' | translate }}</label>
			</div>
			<select
				name="listSize"
				id="listSize"
				class="form-control"
				formControlName="listSize"
				(change)="updateListSize($event)"
				data-test="list.size">
				<option *ngFor="let item of listSizeOptions" value="{{ item.value }}">{{ item.option }}</option>
			</select>
		</div>
	</div>
</form>

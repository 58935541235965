import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AgenciesModule } from '../../support-features/agencies/agencies.module';

import { RiderMessagesEditComponent } from './edit/rider-messages-edit.component';
import { RouteSelectionComponent } from './edit/routes/route-selection.component';
import { StopSelectionComponent } from './edit/routes/stops/stop-selection.component';
import { SignSelectionComponent } from './edit/signs/sign-selection.component';
import { RiderMessagesListExpandedDetailComponent } from './main/list/expanded-detail/rider-messages-list-expanded-detail.component';
import { RiderMessagesListComponent } from './main/list/rider-messages-list.component';
import { RiderMessagesComponent } from './main/rider-messages.component';
import { RoutesModule } from '../../support-features/routes/routes.module';

import { signsStoreProvider } from './edit/signs/service/signs-store-factory';
import { riderMessagesStoreProvider } from './services/rider-messages-store-factory';

import { SignsApiService } from './edit/signs/service/signs-api.service';
import { SignsDataService } from './edit/signs/service/signs-data.service';
import { RiderMessagesApiService } from './services/rider-messages-api.service';
import { RiderMessagesDataService } from './services/rider-messages-data.service';
import { RiderMessagesModalService } from './services/rider-messages-modal.service';
import { RiderMessagesUtilService } from './services/rider-messages-util.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [
		RiderMessagesComponent,
		RiderMessagesListComponent,
		RiderMessagesListExpandedDetailComponent,
		RiderMessagesEditComponent,
		StopSelectionComponent,
		RouteSelectionComponent,
		SignSelectionComponent,
	],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		NgxDatatableModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgenciesModule,
		RoutesModule,
	],
	providers: [
		riderMessagesStoreProvider,
		signsStoreProvider,
		RiderMessagesDataService,
		RiderMessagesUtilService,
		RiderMessagesModalService,
		RiderMessagesApiService,
		SignsDataService,
		SignsApiService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class RiderMessagesModule {}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input } from '@angular/core';

import { ReportViewerBaseComponent } from '../../../report-viewer-base.component';

import { DataService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { HeadwaySummaryDetail } from '../../../../types/api-types';

@Component({
	selector: 'hw200-headway-detail-summary',
	templateUrl: './headway-detail-summary.component.html',
	styleUrls: ['./headway-detail-summary.component.scss'],
})
export class HeadwayDetailSummaryComponent extends ReportViewerBaseComponent {
	@Input() summaryData: HeadwaySummaryDetail = null;

	constructor(
		public dataService: DataService,
		translationService: TranslationService
	) {
		super(translationService);

		this.summaryData = this.dataService['summaryData' as keyof DataService];
	}
}

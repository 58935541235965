/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsDataService } from '../services/reports-data.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { ReportsModalService } from '../services/reports-modal.service';
import { ReportsStateService } from '../services/reports-state.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../utils/components/breadcrumbs/types/types';

import { ActionAllowedType, ReportSummaryContent, ReportTemplateSummaryContent } from '../types/types';
import { ReportTemplateDetail } from '../types/api-types';

@Component({
	selector: 'report-template-details',
	templateUrl: './report-template-details.component.html',
	styleUrls: ['./report-template-details.component.scss'],
})
export class ReportTemplateDetailsComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public authorityId: string = null;
	public agencyId: string = null;
	public defaultTemplateId: string = null;
	public loading: boolean = true;
	public editButtonEnabled: boolean = false;
	public editButtonTooltip: string = '';
	public runButtonEnabled: boolean = false;
	public runButtonTooltip: string = '';
	public deleteButtonEnabled: boolean = false;
	public deleteButtonTooltip: string = '';
	public breadcrumbItems: BreadcrumbItems = [];
	public summaryContent: ReportSummaryContent = { loaded: false };
	public templateSummaryContent: ReportTemplateSummaryContent = { loaded: false };

	private readonly reportsHome: string = '/reports/0';

	private routeParams$Subscription: Subscription = null;
	private reportTemplateId: string = null;
	private createdById: number = null;

	constructor(
		private reportsDataService: ReportsDataService,
		private reportsStateService: ReportsStateService,
		private reportsModalService: ReportsModalService,
		private logger: LoggerService,
		private router: Router,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the report template details view
	 *
	 * sets up subscriptions, loads translations, initilizes breadcrumbs, retrieve view data
	 */
	public async ngOnInit(): Promise<void> {
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.reportTemplateId = params.reportTemplateId;
			this.authorityId = params.authorityId;
			this.agencyId = params.agencyId;
			this.createdById = +params.createdById; // Convert to number
			this.defaultTemplateId = params.defaultTemplateId;

			await this.loadTranslations();

			this.initBreadcrumbs();

			await this.getTemplateData();

			this.determineButtonStates();

			// set loading false once the request has been made regardless of its success meaning the loading spinner will turn off.
			// the content component itself will handle failures in the actual data - i.e display appropriate error
			this.loading = false;
		});
	}

	/**
	 * runs the report using the view report template details
	 */
	public run = async (): Promise<void> => {
		await this.reportsDataService.runReport(this.templateSummaryContent.template);
	};

	/**
	 * renders the edit report dialog using the view report template details
	 *
	 * if the user editied the report then the edited report data is loaded again
	 */
	public edit = (): void => {
		this.reportsModalService.editReportTemplate(this.templateSummaryContent.template).subscribe((reportEdited: boolean) => {
			if (reportEdited) {
				this.getTemplateData();
			}
		});
	};

	/**
	 * renders the delete report dialog
	 *
	 * if the user deletes the report, then this navigates them to report home ciew
	 */
	public delete = (): void => {
		this.reportsModalService.deleteReportTemplate(this.templateSummaryContent.template).subscribe((reportDeleted: boolean) => {
			if (reportDeleted) {
				this.router.navigate([this.reportsHome, '1']);
			}
		});
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * load translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_REPORT.REPORTS',
			'T_REPORT.TEMPLATE_DETAILS',
			'T_REPORT.REPORT_FETCH_FAILURE',
			'T_REPORT.FEATURE_DISABLED',
			'T_CORE.NEED_EDIT_PERMISSION',
			'T_CORE.NEED_DEL_PERMISSION',
		]);
	};

	/**
	 * initializes the breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_REPORT.REPORTS'], targetPath: this.reportsHome, activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_REPORT.TEMPLATE_DETAILS'], activePage: true });
	};

	/**
	 * Gets the required summary data required for the child summary content component and the template data
	 * for the report. Requests the data to pass in to the content components to show the data, rather than
	 * the child components requesting the data themselves. This is because we have other usages of the the
	 * child content components that request their data from other sources (i.e when viewing
	 * interactive reports)
	 */
	private getTemplateData = async (): Promise<void> => {
		const response: ResultContent = await this.reportsDataService.getReportTemplate(
			this.authorityId,
			this.reportTemplateId,
			this.createdById
		);

		if (response.success) {
			const reportTemplateData: ReportTemplateDetail = response.resultData;

			// create our report summary detail data object to display content. Unlike an actual report
			// this saved template version will get it's data from the above getReportTemplate call
			this.summaryContent = {
				loaded: true,
				data: {
					defaultTemplateId: reportTemplateData.default_template_id,
					code: reportTemplateData.code,
					name: reportTemplateData.name,
					description: reportTemplateData.description,
					createdByName: reportTemplateData.created_by_name,
					sharing: reportTemplateData.sharing,
					authorityId: reportTemplateData.report_options.agencies.authority_id,
					outputType: reportTemplateData.output_type,
					agencyName: reportTemplateData.report_options.agencies.agency_name,
					updatedAt: reportTemplateData.updated_at,
				},
			};

			this.templateSummaryContent = {
				loaded: true,
				template: reportTemplateData,
			};
		} else {
			this.summaryContent = {
				loaded: false,
				errorMessage: this.translations['T_REPORT.REPORT_FETCH_FAILURE'],
			};

			this.templateSummaryContent = {
				loaded: false,
				errorMessage: this.translations['T_REPORT.REPORT_FETCH_FAILURE'],
			};
		}
	};

	/**
	 * determines the button states on the component - enabled adn tooltips
	 *
	 * dependent upon what has been selected, how many have been selected
	 */
	private determineButtonStates = (): void => {
		const editActionAllowed: ActionAllowedType = this.reportsStateService.canEditReportTemplate(
			this.defaultTemplateId,
			this.createdById,
			this.authorityId,
			this.agencyId
		);

		this.editButtonEnabled = editActionAllowed === ActionAllowedType.allowed;
		this.editButtonTooltip = this.determineEditTooltip(editActionAllowed);

		const deleteActionAllowed: ActionAllowedType = this.reportsStateService.canDeleteReportTemplate(
			this.createdById,
			this.authorityId,
			this.agencyId
		);

		this.deleteButtonEnabled = deleteActionAllowed === ActionAllowedType.allowed;
		this.deleteButtonTooltip = this.determineDeleteTooltip(deleteActionAllowed);

		const runActionAllowed: ActionAllowedType = this.reportsStateService.canRunReportTemplate(this.defaultTemplateId);

		this.runButtonEnabled = runActionAllowed === ActionAllowedType.allowed;
		this.runButtonTooltip = this.determineRunTooltip(runActionAllowed);
	};

	/**
	 * determines if editing report template is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if editing report template is allowed
	 */
	private determineEditTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_EDIT_PERMISSION'];
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if deleting report template is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if deleting report template is allowed
	 */
	private determineDeleteTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_DEL_PERMISSION'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if running a report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if running report is allowed
	 */
	private determineRunTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};
}

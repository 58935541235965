<div class="dashboard-container">
	<dashboard-title
		[editMode]="editMode"
		[dashboardOptions]="options"
		(createDashboardClicked)="openChooseFormatModal($event)"
		(selectDashboardClicked)="selectDashboard($event)"
		(saveDashboardClicked)="saveCurrentDashboard()"
		(editModeChange)="handleEditModeChange($event)"
		(cancelWithoutSave)="cancelWithoutSave()">
	</dashboard-title>

	<dashboard-toolbar
		[editMode]="editMode"
		[dashboardOptions]="options"
		(editDashboardClicked)="openChooseFormatModal($event)"
		(deleteDashboardClicked)="deleteDashboard()">
	</dashboard-toolbar>

	<dashboard-widgets-view
		[editMode]="editMode"
		[dashboard]="currentDashboard"
		(addWidgetClicked)="openAddWidget($event)"
		(deleteWidgetClicked)="deleteWidget($event)"
		(dropWidgetEvent)="dropWidget($event)"
		(widgetConfigUpdated)="handleWidgetConfigUpdated($event)">
	</dashboard-widgets-view>

	<dashboard-format-modal
		[mode]="modalMode"
		[current]="currentDashboard"
		[hidden]="!chooseFormatModalIsOpen"
		(closeClicked)="closeChooseFormatModal()"
		(saveClicked)="saveDashboardFormat($event)">
	</dashboard-format-modal>

	<widget-edit-modal
		*ngIf="editWidgetModalOpen"
		[widget]="editWidget"
		[currentDashboard]="currentDashboard"
		(closeEvent)="closeEditModal()"
		(saveEvent)="saveWidgetData($event)">
	</widget-edit-modal>

	<dashboard-add-widget-modal
		*ngIf="addWidgetLocation"
		(closeClicked)="closeAddWidget()"
		(selectedWidgetClicked)="addSelectedWidget($event)">
	</dashboard-add-widget-modal>
</div>

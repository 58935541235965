/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { BlocksApiService } from './block-api.service';
import { BlockUtilsService } from './block-utils.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { PaginatedParams } from '@cubicNx/libs/utils';

import {
	CurrentBlocksParams,
	DashboardCurrentRouteBlocks,
	DashboardVehicleBlocks,
	VehicleBlocksParams,
	RouteBlockDetails,
} from '../../routes/types/api-types';

import {
	BlocksPaginatedResponse,
	CancelTripsData,
	CancelBlockData,
	ActivateTripsData,
	Block,
	BlockSummaries,
	TripsToCancel,
	TripsToActivate,
} from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class BlocksDataService {
	private readonly UNAUTHORIZED: number = 403;

	constructor(
		private logger: LoggerService,
		private notificationService: NotificationService,
		private translationService: TranslationService,
		private blockUtilsService: BlockUtilsService,
		private blocksApiService: BlocksApiService
	) {}

	/**
	 * handles the request for the stops list from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param pageNumber - the page number to request
	 * @param pageSize - the page size to request
	 * @param sortBy - the sort by field to request
	 * @param sortDir - the sort direction to request
	 * @param search - the search filter text to request
	 * @returns the result object with the blocks list
	 */
	public getBlocksList = async (
		authorityId: string,
		agencyId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: PaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir }),
				...(search && { search }),
			};

			const response: any = await this.blocksApiService.getBlocks(authorityId, agencyId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as BlocksPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: BlocksPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as BlocksPaginatedResponse;
			}
		} catch (exception) {
			this.logger.logError('Failed to get blocks list', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * handles the request of the block suggestions from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param search - the search filter text
	 * @returns the result object with all blocks that pass the filter
	 */
	public getBlockSuggestions = async (authorityId: string, agencyId: string, search: string): Promise<ResultContent> => {
		return await this.getBlocksList(authorityId, agencyId, 1, 50, 'block_id', 'asc', search);
	};

	/**
	 * handle the request to get all blocks from the nextbus API
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param search - the search filter text
	 * @returns the result object with all block data
	 */
	public getBlocksAll = async (authorityId: string, agencyId: string, search: string = null): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.blocksApiService.getBlocksAll(authorityId, agencyId, search);

			result.success = true;
			result.resultData = response as BlockSummaries;
		} catch (exception) {
			this.logger.logError('Failed to get all block IDs for: ' + agencyId, exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * handles the request of a single block from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param blockId - the block id for the request
	 * @returns the result object with block data
	 */
	public getBlock = async (authorityId: string, agencyId: string, blockId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const block: Block[] = await this.blocksApiService.getBlock(authorityId, agencyId, blockId);

			result.success = true;
			result.resultData = block[0];
		} catch (exception) {
			this.logger.logError('Failed to get block details', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_MAP.MAP_BLOCK_DETAIL_ERROR') + blockId);
		}

		return result;
	};

	/**
	 * handles the request to cancels a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param blockId - the block id for the request
	 * @param endTimestamp -  the end time stamp
	 * @param disableCancelationAlert - a flag to disable cancellation alerts
	 * @returns the result object with state result
	 */
	public cancelBlock = async (
		authorityId: string,
		agencyId: string,
		blockId: string,
		endTimestamp: number,
		disableCancelationAlert: boolean
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const body: CancelBlockData = {
			alert: disableCancelationAlert,
			endTime: endTimestamp,
		};

		try {
			await this.blocksApiService.cancelBlock(authorityId, agencyId, blockId, body);

			result.success = true;
		} catch (exception: any) {
			this.logger.logError('Failed to cancel block', exception);

			let errorMessage: string = null;

			if (exception.status === this.UNAUTHORIZED) {
				errorMessage = this.translationService.getTranslation('T_CORE.NO_PERMISSION');
			} else {
				errorMessage = this.translationService.getTranslation('T_MAP.MAP_BLOCK_CANCEL_ERROR');
			}

			this.notificationService.notifyError(errorMessage);
		}

		return result;
	};

	/**
	 * handles the request to activates a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param blockId - the block id for the request
	 * @returns the result object with state result
	 */
	public activateBlock = async (authorityId: string, agencyId: string, blockId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			await this.blocksApiService.activateBlock(authorityId, agencyId, blockId);

			result.success = true;
		} catch (exception: any) {
			this.logger.logError('Failed to activate block', exception);

			let errorMessage: string = null;

			if (exception.status === this.UNAUTHORIZED) {
				errorMessage = this.translationService.getTranslation('T_CORE.NO_PERMISSION');
			} else {
				errorMessage = this.translationService.getTranslation('T_MAP.MAP_BLOCK_ACTIVATE_ERROR');
			}

			this.notificationService.notifyError(errorMessage);
		}

		return result;
	};

	/**
	 * handles the request to cancel trips for a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param blockId - the block id for the request
	 * @param tripsToCancel - the list of trips to cancel
	 * @param disableCancelationAlert - a flag indicating if the associated alert should be cancelled
	 * @returns the result object with state result
	 */
	public cancelTrips = async (
		authorityId: string,
		agencyId: string,
		blockId: string,
		tripsToCancel: TripsToCancel,
		disableCancelationAlert: boolean
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const body: CancelTripsData = { alert: disableCancelationAlert, trips: tripsToCancel };

		try {
			await this.blocksApiService.cancelTrips(authorityId, agencyId, blockId, body);

			result.success = true;
		} catch (exception: any) {
			this.logger.logError('Failed to cancel trips', exception);

			let errorMessage: string = null;

			if (exception.status === this.UNAUTHORIZED) {
				errorMessage = this.translationService.getTranslation('T_CORE.NO_PERMISSION');
			} else {
				errorMessage = this.translationService.getTranslation('T_MAP.MAP_TRIP_CANCEL_ERROR');
			}

			this.notificationService.notifyError(errorMessage);
		}

		return result;
	};

	/**
	 * handles the request to activate trips for a block via the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param blockId - the block id for the request
	 * @param tripsToActivate - the list of trips to activate
	 * @returns the result object with state result
	 */
	public activateTrips = async (
		authorityId: string,
		agencyId: string,
		blockId: string,
		tripsToActivate: TripsToActivate
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const body: ActivateTripsData = { trips: tripsToActivate };

		try {
			await this.blocksApiService.activateTrips(authorityId, agencyId, blockId, body);

			result.success = true;
		} catch (exception: any) {
			this.logger.logError('Failed to activate trips', exception);

			let errorMessage: string = null;

			if (exception.status === this.UNAUTHORIZED) {
				errorMessage = this.translationService.getTranslation('T_CORE.NO_PERMISSION');
			} else {
				errorMessage = this.translationService.getTranslation('T_MAP.MAP_TRIP_ACTIVATE_ERROR');
			}

			this.notificationService.notifyError(errorMessage);
		}

		return result;
	};

	/**
	 * handles the request to gets the current blocks for the paramters provided from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param timezone - the current timezonse
	 * @param display - the display filter
	 * @param routeIds - the route Ids to request blocks for
	 * @param numHours - the number of hours to request for
	 * @param reqType - the request type i.e 'details' - need to check API to see what this for
	 * @param progress - whether to request upcoming only / upcoming and in progress
	 * @returns the result object with the current blocks that pass the parameters set
	 */
	public getCurrentBlocks = async (
		authorityId: string,
		agencyId: string,
		timezone: string,
		display: any,
		routeIds: string[],
		numHours: number,
		reqType: any,
		progress: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			if (authorityId.length > 0 && agencyId.length > 0) {
				const routeId: string = this.blockUtilsService.getRouteIdentificationFromRoutes(routeIds);

				const params: CurrentBlocksParams = {
					current: 'true',
					timezone,
					route_id: routeId,
					include_assignment: display ? display : null,
					hours: numHours,
					progress,
				};

				if (reqType) {
					params.reqtype = reqType;
				}

				const response: any = await this.blocksApiService.getCurrentBlocks(authorityId, agencyId, params);

				result.success = true;
				result.resultData = response as DashboardCurrentRouteBlocks;
			}
		} catch (error) {
			this.logger.logError('Failed to get current blocks.', error);
		}

		return result;
	};

	/**
	 * handles the request for details block details for a single route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param timezone - the current timezonse
	 * @param display - the display filter
	 * @param routeId - the route Id to request blocks for
	 * @param numHours - the number of hours to request for
	 * @returns the result object with detailed block data for a single route
	 */
	public getCurrentBlocksSingleRouteDetails = async (
		authorityId: string,
		agencyId: string,
		timezone: string,
		display: any,
		routeId: string,
		numHours: number
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			if (authorityId.length > 0 && agencyId.length > 0) {
				const params: CurrentBlocksParams = {
					current: 'true',
					timezone,
					route_id: routeId,
					include_assignment: display ? display : null,
					hours: numHours,
					progress: null,
					reqtype: 'details',
				};

				const response: any = await this.blocksApiService.getCurrentBlocks(authorityId, agencyId, params);

				result.success = true;
				result.resultData = response as RouteBlockDetails;
			}
		} catch (error) {
			this.logger.logError('Failed to get current blocks.', error);
		}

		return result;
	};

	/**
	 * handles the request for current blocks for a single route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param timezone - the current timezonse
	 * @param display - the display filter
	 * @param routeId - the route Id to request blocks for
	 * @param numHours - the number of hours to request for
	 * @param reqType - the request type i.e 'details' - need to check API to see what this for
	 * @param progress - whether to request upcoming only / upcoming and in progress
	 * @returns the result object with current blocks for a single route
	 */
	public getCurrentBlocksSingleRoute = async (
		authorityId: string,
		agencyId: string,
		timezone: string,
		display: any,
		routeId: string,
		numHours: number,
		reqType: any,
		progress: string
	): Promise<ResultContent> => {
		return this.getCurrentBlocks(authorityId, agencyId, timezone, display, [routeId], numHours, reqType, progress);
	};

	/**
	 * handles the request for dashbaord vehicle blocks from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param timezone - the current timezonse
	 * @param adhEarly - a flag to include early vehicles
	 * @param adhLate - a flag to include late vehicles
	 * @param adhVeryEarly - a flag to include very early vehicles
	 * @param adhVeryLate - a flag to include very late vehicles
	 * @param stale - a flag to include stale vehicles
	 * @param includeAdherence - a flag to include adherence data
	 * @param includeUnassigned - a flag to unassigned vehicles
	 * @param includeOffRoute - a flag to include off route vehicles
	 * @param listSize - how many rows to return
	 * @returns the result object with dashbaord vehicle blocks for the parameters set
	 */
	public getDashboardVehiclesBlocks = async (
		authorityId: string,
		agencyId: string,
		timezone: string,
		adhEarly: number,
		adhLate: number,
		adhVeryEarly: number,
		adhVeryLate: number,
		stale: number,
		includeAdherence: boolean,
		includeUnassigned: boolean,
		includeOffRoute: boolean,
		listSize: number
	): Promise<ResultContent> => {
		const emptyObj: DashboardVehicleBlocks = {
			summary: {
				veryLateCount: 0,
				lateCount: 0,
				onTimeCount: 0,
				earlyCount: 0,
				veryEarlyCount: 0,
				unassignedCount: 0,
				assignedCount: 0,
			},
			results: [],
		};

		const result: ResultContent = {
			success: false,
			resultData: emptyObj,
		};

		try {
			if (authorityId.length > 0 && agencyId.length > 0) {
				// If they aren't a number, undefine them so they aren't passed in request.
				adhEarly = typeof adhEarly === 'number' ? adhEarly : undefined;
				adhLate = typeof adhLate === 'number' ? adhLate : undefined;
				adhVeryEarly = typeof adhVeryEarly === 'number' ? adhVeryEarly : undefined;
				adhVeryLate = typeof adhVeryLate === 'number' ? adhVeryLate : undefined;
				stale = typeof stale === 'number' ? stale : undefined;

				const params: VehicleBlocksParams = {
					timezone,
					adhEarly,
					adhLate,
					adhVeryEarly,
					adhVeryLate,
					stale,
					includeAdherence: !!includeAdherence,
					includeUnassigned: !!includeUnassigned,
					includeOffRoute: !!includeOffRoute,
					pageSize: listSize || 2500,
					pageNum: 1,
				};

				const response: any = await this.blocksApiService.getDashboardVehiclesBlocks(authorityId, agencyId, params);

				result.success = true;
				result.resultData = response as DashboardVehicleBlocks;
			}
		} catch (error) {
			this.logger.logError('Failed to get blocks performance.', error);
		}

		return result;
	};
}

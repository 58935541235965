/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { AvailableReportsListComponent } from './list/available/available-reports-list.component';
import { SavedReportTemplatesListComponent } from './list/saved/saved-report-templates-list.component';

import { ReportsModalService } from '../services/reports-modal.service';
import { ReportsStateService } from '../services/reports-state.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ActionAllowedType, ReportSaveType } from '../types/types';

@Component({
	selector: 'reports-home',
	templateUrl: './reports-home.component.html',
	styleUrls: ['./reports-home.component.scss'],
})
export class ReportsHomeComponent extends TranslateBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('availableReports') availableReportsListComponent: AvailableReportsListComponent;
	@ViewChild('savedReportTemplates') savedReportTemplatesListComponent: SavedReportTemplatesListComponent;

	public loading: boolean = false;
	public createButtonEnabled: boolean = false;
	public activeTab: string = '0';

	private routeParams$Subscription: Subscription = null;

	constructor(
		private reportsStateService: ReportsStateService,
		private reportsModalService: ReportsModalService,
		private router: Router,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the report home component
	 *
	 * sets the state of the create button dependent upon user permissions
	 */
	public async ngOnInit(): Promise<void> {
		const actionAllowed: ActionAllowedType = this.reportsStateService.canCreateReports();

		this.createButtonEnabled = actionAllowed === ActionAllowedType.allowed;
	}

	/**
	 * manage any additional functionality once the template has rendered
	 *
	 * get params off the url and dependant upon which tab is active will refresh the relevant list
	 */
	public async ngAfterViewInit(): Promise<void> {
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.activeTab = params.activeTabId;

			if (this.activeTab === '0') {
				this.availableReportsListComponent.refresh();
			} else if (this.activeTab === '1') {
				this.availableReportsListComponent.inactive();
				this.savedReportTemplatesListComponent.refresh();
			}
		});
	}

	/**
	 * sets the compoenent loading state
	 *
	 * @param loading - whether the compoenent is loading or not
	 */
	public setLoading = (loading: boolean): void => {
		this.loading = loading;
	};

	/**
	 * navigates to the relevant tab
	 *
	 * @param tab - the index of the tab being selected
	 */
	public tabChanged = (tab: any): void => {
		this.router.navigate(['/reports/', tab.index]);
	};

	/**
	 * renders the create report dialog.
	 *
	 * depending upon the button selected thereafter will either refresh the available reports tab or the report templates tab
	 */
	public createReport = (): void => {
		this.reportsModalService.createReportFromTemplateSelection().subscribe((reportSaveType: ReportSaveType) => {
			// Check report save type and which tab is active to trigger more frequent polling by way of a view refresh
			switch (reportSaveType) {
				case ReportSaveType.run:
					if (this.activeTab === '0') {
						this.availableReportsListComponent.refresh();
					}
					break;
				case ReportSaveType.save:
					if (this.activeTab === '1') {
						this.savedReportTemplatesListComponent.refresh();
					}
					break;
				case ReportSaveType.saveAndRun:
					if (this.activeTab === '0') {
						this.availableReportsListComponent.refresh();
					} else {
						this.savedReportTemplatesListComponent.refresh();
					}
					break;
				default:
					break;
			}
		});
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}
}

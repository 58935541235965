import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TokenService } from '../../token/token.service';

// Intercept the request and inject the authorisation token, prior to passing the
// request onto the back-end server.
@Injectable({
	providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
	constructor(private tokenService: TokenService) {}

	/**
	 * intercept http requests to handle authentication
	 *
	 * @param req - the http request
	 * @param next - the http handler
	 * @returns the http event
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authToken: string = this.tokenService.getApiToken();

		// Clone the request and replace the original headers with
		// cloned headers, updated with the authorization token.
		if (authToken) {
			const authReq: HttpRequest<any> = req.clone({
				headers: req.headers.set('x-nextbus-jwt', authToken),
			});

			return next.handle(authReq);
		} else {
			return next.handle(req);
		}
	}
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input } from '@angular/core';

@Component({
	selector: 'panel-header',
	templateUrl: './panel-header.component.html',
	styleUrls: ['./panel-header.component.scss'],
})
export class PanelHeaderComponent {
	@Input() heading: string = '';
	@Input() showBackButton: boolean = true;
}

<div class="nb-role-edit-container header-container" id="role-edit-search-header">
	<div class="header-search-row float-right">
		<search-filter class="header-searchbar" [searchSuggestions]="agencySearchSuggestions" (search)="search($event)"> </search-filter>
	</div>
</div>

<loading-overlay [isLoading]="loading" [fit]="'content'"></loading-overlay>
<div *ngIf="!loading">
	<div *ngIf="userIsNextBusMaster && showNextBusRole" class="panel panel-default admin-panel">
		<div class="panel-heading nb-role-edit-panel-header pointer" (click)="onTogglePanel('nextbus')">
			<div class="nb-subhead pull-left">
				{{ 'T_CORE.NEXTBUS' | translate }}
			</div>
			<div class="pull-right">
				<button type="button" class="btn btn-link-dark">
					<i [ngClass]="panelContentDisplay['nextbus'] ? 'nb-icons nb-filter-up' : 'nb-icons nb-filter-down'"></i>
				</button>
			</div>
		</div>
		<div class="panel-body" id="role-edit-nextbus-panel" *ngIf="panelContentDisplay['nextbus']">
			<div class="nb-form-control">
				<div class="nb-checkbox">
					<label for="{{ masterRoleName }}">
						<input type="checkbox" id="{{ masterRoleName }}" [checked]="userHasMasterRole()" (click)="toggleHasMasterRole()" title="" />
						<div class="checkbox-label">
							{{ masterRoleName }}
						</div>
					</label>
				</div>
			</div>
			<div>
				<span>{{ 'T_USER.SELECT_DEFAULT_AGENCY' | translate }}</span>
			</div>
		</div>
	</div>

	<div *ngFor="let authority of authorities" [hidden]="authority.hide" class="panel panel-default">
		<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="onTogglePanel(authority.authorityId)">
			<div class="truncate nb-subhead">
				<ng-container *ngIf="authority.authorityName">
					<span>{{ authority.authorityName }}&nbsp;&nbsp;({{ authority.authorityId }})</span>
				</ng-container>
				<ng-container *ngIf="!authority.authorityName">
					<span>{{ authority.authorityId }}</span>
				</ng-container>
			</div>
			<div class="no-shrink">
				<span class="nb-helper-text" *ngIf="authority.roleCount > 0">
					( {{ authority.roleCount }} {{ 'T_CORE.SELECTED' | translate }} ) &nbsp;&nbsp;&nbsp;
				</span>
				<button type="button" class="btn btn-link-dark">
					<i [ngClass]="panelContentDisplay[authority.authorityId] ? 'nb-icons nb-filter-up' : 'nb-icons nb-filter-down'"></i>
				</button>
			</div>
		</div>

		<div class="panel-body" id="role-edit-nextbus-panel" *ngIf="getPanelDisplay(authority.authorityId)">
			<div class="nb-margin-bottom-md" *ngFor="let agency of authority.agency">
				<div class="nb-title-sm">
					<span>{{ 'T_CORE.AGENCY_ROLES' | translate }}: {{ agency.agency_name }}</span>
				</div>
				<div class="nb-form-control">
					<div class="nb-user-default-agency-container">
						<div class="nb-radio">
							<label for="radio{{ agency.agency_name + agency.nb_id }}">
								<input
									id="radio{{ agency.agency_name + agency.nb_id }}"
									type="radio"
									(click)="setPrimaryAgencyName(agency)"
									[ngModel]="primaryAgency"
									[value]="agency.nb_id"
									[disabled]="!agencyOptionIsValid(agency)"
									title="" />
								<div class="checkbox-label">
									{{ 'T_CORE.DEFAULT_AGENCY' | translate }}
								</div>
							</label>
						</div>
					</div>
				</div>
				<div>
					<div class="nb-form-control">
						<div *ngFor="let role of roles; let i = index">
							<div *ngIf="[0, 2, 3, 4].indexOf(i) > -1">
								<div class="nb-checkbox">
									<label for="{{ agency.authority_id + agency.nb_id + role.nb_id }}">
										<input
											type="checkbox"
											id="{{ agency.authority_id + agency.nb_id + role.nb_id }}"
											[value]="role.nb_id"
											[checked]="userHasAgencyRole(agency.nb_id, role.nb_id)"
											(click)="toggleHasAgencyRole(agency.authority_id, agency.nb_id, role.nb_id)"
											title="" />
										<div class="checkbox-label">
											{{ role.localized_name }}
										</div>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { DashboardConfigService } from '../services/dashboard-config.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { modalSlideDownAnimation, modalSlideAnimationTime } from '../types/types';

export enum ModalMode {
	create,
	edit,
}

@Component({
	selector: 'dashboard-format-modal',
	templateUrl: './dashboard-format-modal.component.html',
	styleUrls: ['./dashboard-format-modal.component.scss'],
	animations: modalSlideDownAnimation,
})
export class DashboardFormatModalComponent extends TranslateBaseComponent implements OnInit, OnChanges {
	@Input() mode: ModalMode;
	@Input() selectedStructure: string;
	@Input() current: any;

	@Output() closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() saveClicked: EventEmitter<string> = new EventEmitter<string>();

	public reactiveForm: FormGroup;
	public structures: any;
	public contentVisible: boolean;

	private maxTitleLength: number = 25;
	private minTitleLength: number = 1;

	constructor(
		private formBuilder: FormBuilder,
		private dashboardConfigService: DashboardConfigService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the dashboard format modal component
	 */
	public ngOnInit(): void {
		this.buildForm();
		this.structures = this.dashboardConfigService.structures;
	}

	/**
	 * for when the modal data changes - changes title and modal structure accordingly
	 * @param changes - the changes to the inputs
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.mode) {
			// start animation if mode not set to null (i.e when a a change is triggered for create/edit)
			// node must check explicitly for !== null in case tha value is 0 (false)
			if (changes.mode.currentValue !== null && !changes.mode.firstChange) {
				// set modal content visible (with animation)
				this.contentVisible = true;
			}
		}

		if (changes.mode?.currentValue === ModalMode.create) {
			this.reactiveForm?.get('title').setValue('My Dashboard');
			this.selectedStructure = '6-6';
			this.reactiveForm?.get('structure').setValue(this.selectedStructure);
		} else if (changes.mode?.currentValue === ModalMode.edit) {
			this.selectedStructure = this.current.config.structure;
			this.reactiveForm?.get('title').setValue(this.current.config.title);
			this.reactiveForm?.get('structure').setValue(this.current.config.structure);
		} else if (changes.current?.currentValue?.id !== changes.current?.previousValue?.id) {
			this.selectedStructure = this.current.config.structure;
			this.reactiveForm?.get('title').setValue(this.current.config.title);
			this.reactiveForm?.get('structure').setValue(this.current.config.structure);
		}
	}

	/**
	 * Checks modal mode.
	 *
	 * @returns true if the modal is in create mode.
	 */
	public isCreateMode = (): boolean => this.mode === ModalMode.create;

	/**
	 * Fires a closeClicked event.
	 */
	public close = (): void => {
		// set modal not visible (with animation)
		this.contentVisible = false;

		// allow the animation to finish before closing
		setTimeout(() => {
			this.closeClicked.emit();
		}, modalSlideAnimationTime);
	};

	/**
	 * Fires a saveClicked event emitting the value of the form.
	 */
	public save = (): void => {
		// set modal not visible (with animation)
		this.contentVisible = false;

		// allow the animation to finish before saving
		setTimeout(() => {
			this.saveClicked.emit(this.reactiveForm.value);
		}, modalSlideAnimationTime);
	};

	/**
	 * Updates the form with the selected structure.
	 *
	 * @param name - - the name of the selected structure.
	 */
	public update = (name: string): void => {
		this.selectedStructure = name;
		this.reactiveForm.get('structure').setValue(name);
		this.reactiveForm.markAsDirty();
	};

	/**
	 * Checks the form validity.
	 *
	 * @returns true if the form is valid.
	 */
	public isFormValid = (): boolean => {
		return this.reactiveForm.valid;
	};

	/**
	 * Checks if the form is clean.
	 *
	 * @returns true if the form is clean.
	 */
	public isFormClean = (): boolean => {
		return this.reactiveForm.pristine;
	};

	/**
	 * Builds the form.
	 */
	private buildForm = (): void => {
		this.reactiveForm = this.formBuilder.group(
			{
				title: ['', [Validators.required, Validators.maxLength(this.maxTitleLength), Validators.minLength(this.minTitleLength)]],
				structure: ['', Validators.required],
			},
			{}
		);
	};
}

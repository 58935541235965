import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CurrentUserUtilService } from '../../support-features/login/services/current-user/current-user-utils.service';
import { LoginUtilService } from '../../support-features/login/services/login/login-util.service';
import { InitEventsService } from '../../support-features/login/services/init/init-events.service';
import { InitService } from '../../support-features/login/services/init/init.service';

@Injectable({
	providedIn: 'root',
})
export class RoutingHandlerService implements OnDestroy {
	public initialized: boolean = false;

	private initComplete$Subscription: Subscription = null;
	private urlTarget: string = null;

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private initService: InitService,
		private initEventsService: InitEventsService,
		private loginUtilService: LoginUtilService,
		private router: Router
	) {}

	/**
	 * handle the routing handler initialization
	 */
	public init = (): void => {
		this.setSubScriptions();

		this.handleNavigationChange();
	};

	/**
	 * sets a target to set when the initialization has finished
	 *
	 * @param urlTarget - the url to set once initialization has finished
	 */
	public setInitialTarget = (urlTarget: string): void => {
		this.urlTarget = urlTarget;
	};

	/**
	 * disposes the subscription(s) when the service is destroyed
	 */
	public ngOnDestroy(): void {
		this.initComplete$Subscription?.unsubscribe();
	}

	/**
	 * handle routing navigation changes
	 */
	private handleNavigationChange = async (): Promise<void> => {
		if (!this.initService.isInitComplete()) {
			if (this.loginUtilService.isLoggedIn()) {
				// already logged in - should have our user in the cache!
				this.currentUserUtilService.loadCachedCurrentUser();

				// handle additional processing
				await this.initService.initActions(this.currentUserUtilService.getCurrentUser());
			} else {
				const currentLocation: string = this.router.url.replace('/', '');

				if (
					!currentLocation.includes('login') &&
					!currentLocation.includes('reset_password') &&
					!currentLocation.includes('reset_password_confirm') &&
					!currentLocation.includes('set_password')
				) {
					// navigate to login - eventually loginService will call initService.initActions()
					// so whether they are initially logged in or not - we call the same entry point
					this.router.navigate(['/login']);
				}
			}
		}
	};

	/**
	 * set up subscriptions for the routing handler
	 */
	private setSubScriptions = (): void => {
		this.router.events.subscribe(async (val: any) => {
			if (val instanceof NavigationEnd) {
				await this.handleNavigationChange();
			}
		});

		this.initComplete$Subscription = this.initEventsService.initComplete.subscribe(() => {
			const target: string = this.urlTarget;

			// if a url route was ignored due to initialization not completing, navigate to that page now
			if (target) {
				this.router.navigate(['/' + target]);
			}
		});
	};
}

<form [formGroup]="reactiveForm" class="form-group row" [ngClass]="agencies?.length > 1 ? 'form-height' : 'form-height-single-agency'">
	<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
	<div [hidden]="!loaded">
		<div id="title-text" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
			<input
				formControlName="title"
				data-test="title.input"
				type="text"
				id="title"
				class="form-control"
				placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
			<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
		</div>

		<div *ngIf="agencies?.length > 1">
			<div id="agency" class="form-group">
				<div class="nb-required">
					<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
				</div>
				<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
				<select
					name="agencyId"
					id="agencyId"
					class="form-control"
					formControlName="agencyId"
					data-test="agency.selection"
					(change)="agencyChanged()">
					<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
						{{ agency.agency_name }}
					</option>
				</select>
				<input-validation [control]="reactiveForm.controls.agencyId"></input-validation>
			</div>
		</div>

		<div id="versionToggle" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<div>
				<label>{{ 'T_DASH.DB_VIEW' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button data-test="view.by-route" class="btn" [ngClass]="versionToggleButtonClass('Routes')" (click)="setVersionToggle('Routes')">
						{{ 'T_DASH.DB_SUMMARY_BY_ROUTE' | translate }}
					</button>
					<button data-test="view.by-block" class="btn" [ngClass]="versionToggleButtonClass('Blocks')" (click)="setVersionToggle('Blocks')">
						{{ 'T_DASH.DB_DETAILED_BY_BLOCK' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div id="route" class="form-group">
			<div>
				<label>{{ 'T_CORE.ROUTES_MAX_SELECT' | translate }}</label>
			</div>
			<div>
				<multi-select
					data-test="routes.select"
					[settings]="routeSettings"
					[items]="routesSummaries"
					[selected]="selectedRoutes"
					(itemClickedEvent)="updateItemClicked()"
					(removeItemClicked)="updateItemClicked()">
				</multi-select>
			</div>
		</div>

		<div id="hours" class="form-group">
			<div>
				<label>{{ 'T_DASH.DB_TIMEFRAME' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button data-test="timeframe.2" class="btn btn-hours" [ngClass]="hoursButtonClass(2)" (click)="setHours(2)">
						{{ 'T_DASH.DB_2_HOURS' | translate }}
					</button>
					<button data-test="timeframe.4" class="btn btn-hours" [ngClass]="hoursButtonClass(4)" (click)="setHours(4)">
						{{ 'T_DASH.DB_4_HOURS' | translate }}
					</button>
					<button data-test="timeframe.6" class="btn btn-hours" [ngClass]="hoursButtonClass(6)" (click)="setHours(6)">
						{{ 'T_DASH.DB_6_HOURS' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div id="display" class="form-group">
			<div>
				<label>{{ 'T_DASH.DB_SHOW_STATUS' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button
						data-test="status.assigned"
						class="btn btn-hours"
						[ngClass]="displayButtonClass('assigned')"
						(click)="setDisplay('assigned')">
						{{ 'T_DASH.DB_ASSIGNED' | translate }}
					</button>
					<button
						data-test="status.unassigned"
						class="btn btn-hours"
						[ngClass]="displayButtonClass('unassigned')"
						(click)="setDisplay('unassigned')">
						{{ 'T_DASH.DB_UNASSIGNED' | translate }}
					</button>
					<button data-test="status.both" class="btn btn-hours" [ngClass]="displayButtonClass('both')" (click)="setDisplay('both')">
						{{ 'T_DASH.DB_BOTH' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div id="progress" class="form-group">
			<div>
				<label>{{ 'T_DASH.DB_SHOW_PROGRESS' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button
						data-test="progress.upcoming_only"
						class="btn"
						[ngClass]="progressButtonClass('upcoming')"
						(click)="setProgress('upcoming')">
						{{ 'T_DASH.DB_UPCOMING_ONLY' | translate }}
					</button>
					<button
						data-test="progress.upcoming_inprogress"
						class="btn"
						[ngClass]="progressButtonClass('in-progress')"
						(click)="setProgress('in-progress')">
						{{ 'T_DASH.DB_IN_PROGRESS_AND_UPCOMING' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</form>

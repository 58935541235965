<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>
<panel-header [showBackButton]="true" [heading]="'T_AUTHORITY.AUTHORITY_DETAILS' | translate"> </panel-header>
<mat-tab-group mat-stretch-tabs>
	<mat-tab label="{{ 'T_AUTHORITY.DETAILS' | translate | uppercase }}">
		<div *ngIf="detailsLoaded">
			<br />
			<view-panel [layout]="'column'" [model]="authority" [viewtableoptions]="detailViewTableOptions"> </view-panel>
		</div>
	</mat-tab>
	<mat-tab label="{{ 'T_AUTHORITY.ADMIN_SETTINGS' | translate | uppercase }}" *ngIf="isAdmin">
		<div *ngIf="adminSettingsLoaded">
			<br />
			<view-panel [layout]="'column'" [model]="adminSettings.settings" [viewtableoptions]="settingsViewTableOptions"> </view-panel>
		</div>
	</mat-tab>
</mat-tab-group>

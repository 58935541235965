/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { RoutesUtilService } from '../../../../../support-features/routes/services/routes-util.service';
import { ValidatorService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { Route, Routes, RouteSummaries } from '../../../../../support-features/routes/types/api-types';
import { IWidgetEditModalComponent } from '../../../types/types';

@Component({
	selector: 'performance-edit',
	templateUrl: './performance-edit.component.html',
	styleUrls: ['./performance-edit.component.scss'],
})
export class PerformanceEditComponent extends WidgetEditBaseComponent implements IWidgetEditModalComponent, OnInit, OnDestroy {
	public loaded: boolean = false;
	public enableLowerIntervals: boolean = true;
	public routesSummaries: RouteSummaries = [];
	public selectedRoutes: RouteSummaries[] = [];
	public routeSettings: MultiSelectSettings = {
		id_text: 'route_id',
		value_text: 'route_display_name',
		placeholder: 'Please select a route',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
		optionStyles: {
			// apply the italic style under listed conditions as per original dashboard version
			style: { 'font-style': 'italic' },
			fieldComparisonOrOperator: true,
			fields: [
				{ name: 'is_predict_enabled', value: 'false' },
				{ name: 'is_hidden', value: 'true' },
				{ name: 'is_enabled', value: 'false' },
			],
		},
	};

	constructor(
		private routesDataService: RoutesDataService,
		private routesUtilService: RoutesUtilService,
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * performs initialization tasks for the performance edit component - setting up subscriptions and loading form data
	 */
	public async ngOnInit(): Promise<void> {
		const options: any = {
			validators: [
				ValidatorService.dependantRequiredIfPrimaryHasValue('routes', 'selectedRoutes', 'someRoutes'),
				ValidatorService.atLeastOneRequired([
					'displayEarly',
					'displayLate',
					'displayOnTime',
					'displayVeryEarly',
					'displayVeryLate',
					'displayHeadwayClose',
					'displayHeadwayDistant',
					'displayHeadwayOkay',
					'displayHeadwayVeryClose',
					'displayHeadwayVeryDistant',
				]),
			],
		};

		this.buildForm(this.addFormControls, options);

		await this.setupAgencies(this.agencyChanged);

		this.setup();
		this.setSubscriptions();

		await this.initData();

		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public agencyChanged = (): void => {
		this.handleAgencyChanged(this.getRoutes, this.updateSelectedRoutes);
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param range - the data to check.
	 * @returns a class string.
	 */
	public timeRangeButtonClass = (range: number): string => {
		const current: number = this.reactiveForm.get('timeRangeMinutes').value;

		return current === range ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget time range data.
	 *
	 * @param range - the time range to set.
	 */
	public setTimeRange = (range: number): void => {
		this.widget.config.timeRangeMinutes = range;
		this.reactiveForm.get('timeRangeMinutes').setValue(range);

		this.enableLowerIntervals = true;

		if (range === 360 || range === 720) {
			this.enableLowerIntervals = false;
			this.setTimeInterval(10);
		}
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param interval - the data to check.
	 * @returns a class string.
	 */
	public timeIntervalButtonClass = (interval: number): string => {
		const current: number = this.reactiveForm.get('timeIntervalMinutes').value;

		return current === interval ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget time interval data.
	 *
	 * @param interval - the time interval to set.
	 */
	public setTimeInterval = (interval: number): void => {
		this.widget.config.timeIntervalMinutes = interval;
		this.reactiveForm.get('timeIntervalMinutes').setValue(interval);
	};

	/**
	 * Updates the form and widget data with the selectedRoutes.
	 *
	 * @param item - the item to update.
	 */
	public updateItemClicked = (): void => {
		this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
		this.widget.config.selectedRoutes = this.selectedRoutes;
		this.reactiveForm.updateValueAndValidity();
	};

	/**
	 * updates the all routes selection to all or not
	 *
	 * @param selection - route change selection
	 */
	public routeChange = (selection: string): void => {
		if (selection === 'all') {
			this.selectedRoutes = [];
			this.widget.config.selectedRoutes = this.selectedRoutes;
			this.widget.config.includeAllRoutes = true;
			this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
		} else {
			this.widget.config.includeAllRoutes = false;
		}
	};

	/**
	 * either clears the supplied property from the widget configuration if the checkbox has been unclicked
	 * or deletes the property all together
	 *
	 * @param event - checkbox clicked event
	 * @param property - property
	 */
	public checkboxClicked = (event: any, property: string): void => {
		if (event.target.checked) {
			delete this.widget.config[property];
		} else {
			this.widget.config[property] = false;
		}
	};

	/**
	 * Updates the selected routes for the widget and the form.
	 */
	private updateSelectedRoutes = (): void => {
		this.selectedRoutes = [];
		this.widget.config.selectedRoutes = this.selectedRoutes;
		this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
	};

	/**
	 * sets up the widget configuration  data
	 */
	private setup = (): void => {
		/* Not sure why these properties are deleted other than perhaps for efficiency in saving less
           data against the user (persited to back end). By assuming the absense of the data is the same
           as true, with the idea the values are only overriden to false when applicable.
        */
		if (this.widget.config.displayEarly) {
			delete this.widget.config.displayEarly;
		}

		if (this.widget.config.displayLate) {
			delete this.widget.config.displayLate;
		}

		if (this.widget.config.displayOnTime) {
			delete this.widget.config.displayOnTime;
		}

		if (this.widget.config.displayVeryEarly) {
			delete this.widget.config.displayVeryEarly;
		}

		if (this.widget.config.displayVeryLate) {
			delete this.widget.config.displayVeryLate;
		}

		if (this.widget.config.displayHeadwayClose) {
			delete this.widget.config.displayHeadwayClose;
		}

		if (this.widget.config.displayHeadwayDistant) {
			delete this.widget.config.displayHeadwayDistant;
		}

		if (this.widget.config.displayHeadwayOkay) {
			delete this.widget.config.displayHeadwayOkay;
		}

		if (this.widget.config.displayHeadwayVeryClose) {
			delete this.widget.config.displayHeadwayVeryClose;
		}

		if (this.widget.config.displayHeadwayVeryDistant) {
			delete this.widget.config.displayHeadwayVeryDistant;
		}

		if (this.widget.config.displayPredictability) {
			delete this.widget.config.displayPredictability;
		}

		if (this.widget.config.displayPredictionAccuracy0to5) {
			delete this.widget.config.displayPredictionAccuracy0to5;
		}

		if (this.widget.config.displayPredictionAccuracy5to10) {
			delete this.widget.config.displayPredictionAccuracy5to10;
		}

		if (this.widget.config.displayPredictionAccuracy10to15) {
			delete this.widget.config.displayPredictionAccuracy10to15;
		}

		if (this.widget.config.displayPredictionAccuracyAll) {
			delete this.widget.config.displayPredictionAccuracyAll;
		}

		if (this.widget.config.includeAllRoutes === undefined) {
			this.widget.config.includeAllRoutes = true;
		}
	};

	/**
	 * sets up all of the controls on the edit form
	 */
	private addFormControls = (): void => {
		this.reactiveForm.addControl('routes', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('selectedRoutes', new FormControl('', []));
		this.reactiveForm.addControl('timeRangeMinutes', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('timeIntervalMinutes', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('displayEarly', new FormControl('', []));
		this.reactiveForm.addControl('displayLate', new FormControl('', []));
		this.reactiveForm.addControl('displayOnTime', new FormControl('', []));
		this.reactiveForm.addControl('displayVeryEarly', new FormControl('', []));
		this.reactiveForm.addControl('displayVeryLate', new FormControl('', []));
		this.reactiveForm.addControl('displayHeadwayClose', new FormControl('', []));
		this.reactiveForm.addControl('displayHeadwayDistant', new FormControl('', []));
		this.reactiveForm.addControl('displayHeadwayOkay', new FormControl('', []));
		this.reactiveForm.addControl('displayHeadwayVeryClose', new FormControl('', []));
		this.reactiveForm.addControl('displayHeadwayVeryDistant', new FormControl('', []));
		this.reactiveForm.addControl('displayPredictability', new FormControl('', []));
		this.reactiveForm.addControl('displayPredictionAccuracy0to5', new FormControl('', []));
		this.reactiveForm.addControl('displayPredictionAccuracy5to10', new FormControl('', []));
		this.reactiveForm.addControl('displayPredictionAccuracy10to15', new FormControl('', []));
		this.reactiveForm.addControl('displayPredictionAccuracyAll', new FormControl('', []));
		this.reactiveForm.get('routes').setValue(this.widget.config.includeAllRoutes ? 'all' : 'someRoutes');
		this.reactiveForm.get('selectedRoutes').setValue(this.widget.config.selectedRoutes);
		this.reactiveForm.get('timeRangeMinutes').setValue(this.widget.config.timeRangeMinutes);
		this.reactiveForm.get('timeIntervalMinutes').setValue(this.widget.config.timeIntervalMinutes);
		this.reactiveForm.get('displayEarly').setValue(this.widget.config.displayEarly ?? true);
		this.reactiveForm.get('displayLate').setValue(this.widget.config.displayLate ?? true);
		this.reactiveForm.get('displayOnTime').setValue(this.widget.config.displayOnTime ?? true);
		this.reactiveForm.get('displayVeryEarly').setValue(this.widget.config.displayVeryEarly ?? true);
		this.reactiveForm.get('displayVeryLate').setValue(this.widget.config.displayVeryLate ?? true);
		this.reactiveForm.get('displayHeadwayClose').setValue(this.widget.config.displayHeadwayClose ?? true);
		this.reactiveForm.get('displayHeadwayDistant').setValue(this.widget.config.displayHeadwayDistant ?? true);
		this.reactiveForm.get('displayHeadwayOkay').setValue(this.widget.config.displayHeadwayOkay ?? true);
		this.reactiveForm.get('displayHeadwayVeryClose').setValue(this.widget.config.displayHeadwayVeryClose ?? true);
		this.reactiveForm.get('displayHeadwayVeryDistant').setValue(this.widget.config.displayHeadwayVeryDistant ?? true);
		this.reactiveForm.get('displayPredictability').setValue(this.widget.config.displayPredictability);
		this.reactiveForm.get('displayPredictionAccuracy0to5').setValue(this.widget.config.displayPredictionAccuracy0to5);
		this.reactiveForm.get('displayPredictionAccuracy5to10').setValue(this.widget.config.displayPredictionAccuracy5to10);
		this.reactiveForm.get('displayPredictionAccuracy10to15').setValue(this.widget.config.displayPredictionAccuracy10to15);
		this.reactiveForm.get('displayPredictionAccuracyAll').setValue(this.widget.config.displayPredictionAccuracyAll);

		this.enableLowerIntervals = this.widget.config.timeRangeMinutes < 360;
	};

	/**
	 * initialize any data based on whether we already have an agency, default to loaded true if first time
	 * as we won't have an agency set yet
	 */
	private initData = async (): Promise<void> => {
		if (this.widget.config.selectedRoutes) {
			this.selectedRoutes = this.widget.config.selectedRoutes;
		}

		if (this.widget.config.selectedAgency) {
			await this.getRoutes();
		} else {
			this.loaded = true;
		}
	};

	/**
	 * Gets the routes for the selectedAgency.
	 */
	private getRoutes = async (): Promise<void> => {
		this.loaded = false;

		if (this.widget.config.selectedAgency) {
			const result: ResultContent = await this.routesDataService.getRoutes(
				this.widget.config.selectedAgency.authority_id,
				this.widget.config.selectedAgency.agency_id
			);

			if (result.success) {
				const routes: Routes = result.resultData;

				// create a route summary - we could just use the whole route object but the
				// original dashboard saved the following 'RouteSummary' for each selected route.
				// We need the data in the same format so we can transition from dashboard version1
				// to dashboard version2 so users who have persisted data from the old dashboard
				// settings are not affected
				this.routesSummaries = routes.map((route: Route) => ({
					route_id: route.route_id,
					route_display_name: this.routesUtilService.getDisplayName(route),
					is_enabled: route.is_enabled,
					is_hidden: route.is_hidden,
					is_predict_enabled: route.is_predict_enabled,
				}));
			}
		}

		this.loaded = true;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { TwitterApiService } from './twitter-api.service';

import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class TwitterDataService {
	constructor(
		private loggerService: LoggerService,
		private twitterApiService: TwitterApiService
	) {}

	/**
	 * handles the request for the tweets for the given authority from the twitter API
	 *
	 * @param authorityId - the current authority id
	 * @returns the tweets response
	 */
	public getTweets = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.twitterApiService.getTweets(authorityId);

			if (response) {
				if (!response?.errors) {
					// we could set a return type to be consitent but with it being an external API which can return a large object
					// or a simple erorrs list it's probably easier to leave as an 'any' type
					if (Array.isArray(response)) {
						result.success = true;
						result.resultData = response as any;
					}
				} else {
					// just return the first error
					result.resultData = response.errors[0].message;
				}
			}
		} catch (exception) {
			this.loggerService.logError('Failed to get tweets.', exception);
		}

		return result;
	};
}

<div *ngIf="!summaryContent.loaded" class="nb-padding-all-lg">
	{{ summaryContent.errorMessage }}
</div>
<div *ngIf="summaryContent.loaded && initialized">
	<div class="nb-view-table-4">
		<div class="nb-view-table description-view-table-container">
			<div *ngIf="showHeader" class="nb-view-table-heading" data-test="report-details.code-name">
				{{ summaryContent.data.code }} - {{ summaryContent.data.name }}
				<!-- when showing summary for template - there is no status -->
				<div *ngIf="summaryContent.data.status" class="pull-right nb-report-status-text" data-test="report-details.status">
					{{ getStatusText(summaryContent.data.status) }}
				</div>
			</div>
			<div class="clearfix nb-view-table-row">
				<div class="nb-view-table-cell col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<label data-test="report-details.description.label">{{ 'T_REPORT.DESCRIPTION' | translate }}</label>
					<div data-test="report-details.description">{{ summaryContent.data.description }}</div>
				</div>
				<div *ngIf="summaryContent.data.updatedAt" class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.last-modified.label">{{ 'T_REPORT.DATE_LAST_MODIFIED' | translate }}</label>
					<div data-test="report-details.last-modified">{{ summaryContent.data.updatedAt | date: 'MMM d, yyyy h:mm a' }}</div>
				</div>
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.output-type.label">{{ 'T_REPORT.OUTPUT_TYPE' | translate }}</label>
					<div>
						<div data-test="report-details.output-type">{{ getTranslation('T_REPORT.' + summaryContent.data.outputType | uppercase) }}</div>
					</div>
				</div>
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.created-by.label">{{ 'T_REPORT.CREATED_BY_NAME' | translate }}</label>
					<div data-test="report-details.created-by">{{ summaryContent.data.createdByName }}</div>
				</div>
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.sharing.label">{{ 'T_REPORT.SHARING' | translate }}</label>
					<div data-test="report-details.sharing">{{ getTranslation('T_REPORT.' + summaryContent.data.sharing | uppercase) }}</div>
				</div>
			</div>
		</div>

		<div class="nb-view-table" *ngIf="summaryContent.data.outputType === 'csv'">
			<div class="nb-view-table-heading" data-test="report-details.report-options.heading">{{ 'T_REPORT.REPORT_OPTIONS' | translate }}</div>
			<div class="clearfix nb-view-table-row">
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.authority.label">{{ 'T_CORE.AUTHORITY' | translate }}</label>
					<div data-test="report-details.authority">{{ summaryContent.data.authorityId }}</div>
				</div>
				<div class="nb-view-table-cell col-lg-3 col-md-4 col-sm-6 col-xs-6">
					<label data-test="report-details.agency.label">{{ 'T_CORE.AGENCY' | translate }}</label>
					<div data-test="report-details.agency">{{ summaryContent.data.agencyName }}</div>
				</div>
			</div>
		</div>
	</div>
</div>

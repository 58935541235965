/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { MapOptionsService } from '../../../services/map-options.service';

import { NavigationPage, MapModeType } from '../../../types/types';
import { EntityType, PageType } from '../../../../../utils/components/breadcrumbs/types/types';

@Component({
	selector: 'map-navigation-menu',
	templateUrl: './map-navigation-menu.component.html',
	styleUrls: ['./map-navigation-menu.component.scss'],
})
export class MapNavigationMenuComponent extends TranslateBaseComponent {
	@Output() navigateToPage: EventEmitter<NavigationPage> = new EventEmitter<NavigationPage>();

	// make enums accessible in html
	public navEntityType: typeof EntityType = EntityType;
	public navPageType: typeof PageType = PageType;

	constructor(
		private mapOptionsService: MapOptionsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * navigate to the appropriate menu item
	 *
	 * @param targetEntityType - the entity type to navigate to
	 */
	public navigateMenuItem = (targetEntityType: EntityType): void => {
		const navPage: NavigationPage = {
			entityType: targetEntityType,
			pageType: PageType.list,
			entity: null,
		};

		this.navigateToPage.emit(navPage);
	};

	/**
	 * determine if replay mode is active
	 *
	 * @returns true if replay mode is active
	 */
	public replayModeActive = (): boolean => {
		return this.mapOptionsService.getMapMode() === MapModeType.replay;
	};
}

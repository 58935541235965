<!-- added $event.stopPropagation() so parnt windows with clickOutside directive are not also closed -->
<div class="multi-select" (clickOutside)="closeDropDown()" (click)="$event.stopPropagation()">
	<div
		class="multi-select-selections"
		[ngClass]="dropDownOpen ? 'multi-select-selections-open' : 'multi-select-selections-closed'"
		(click)="toggleDropDownIfSelectedItemUnbadged()">
		<div class="selected-item pull-left" *ngIf="!hasSelections()">{{ settings.placeholder }}</div>

		<div *ngIf="hasSelections()">
			<div *ngFor="let item of selected">
				<div *ngIf="settings.selectedItemBadged" class="btn btn-default selected-badge pull-left">
					{{ item[settings.value_text] }}
					<!-- stopPropagation on click to ensure parent click action doesn't fire and reopen dropdown -->
					<a
						*ngIf="!settings.readonly"
						class="selected-remove"
						(click)="removeSelectedItem(item)"
						data-test="remove-btn"
						(click)="$event.stopPropagation()"
						>x</a
					>
				</div>

				<div *ngIf="!settings.selectedItemBadged && !allItemsSelected" class="multi-select-selected-item pull-left">
					{{ item[settings.value_text] }}
				</div>
			</div>

			<div *ngIf="!settings.selectedItemBadged && allItemsSelected" class="multi-select-selected-item pull-left">All</div>
		</div>

		<div *ngIf="!settings.readonly && settings.showDropdownCaret" class="multi-select-btn pull-right" (click)="toggleDropDown()">
			<span class="multi-select-chevron" [ngClass]="dropDownOpen ? 'multi-select-chevron-up' : 'multi-select-chevron-down'"></span>
		</div>
	</div>

	<div class="multi-select-dropdown" [ngClass]="dropDownOpen ? 'open' : 'close'">
		<ul>
			<li *ngIf="settings.enableSearchFilter" class="search-filter">
				<input #filter id="filter" type="text" placeholder="{{ 'T_CORE.SEARCH' | translate }} ..." [(ngModel)]="filterText" />
			</li>
			<li *ngFor="let item of getItems()" (click)="onItemClick(item)" class="multi-select-item" [ngStyle]="getOptionStyle(item)">
				<input
					[ngClass]="settings.showCheckbox ? 'show-checkbox' : 'hide-checkbox'"
					class="multi-select-checkbox"
					type="checkbox"
					[checked]="isSelected(item)" />
				{{ item[settings.value_text] }}
			</li>
		</ul>
	</div>
</div>

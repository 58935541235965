<div class="widget">
	<widget-header
		[title]="data.title"
		[agencyName]="data.config.selectedAgency?.agency_name"
		[editMode]="false"
		(editWidgetClicked)="openEditWidget()">
	</widget-header>
	<div class="widget-body nb-widget-container">
		<vehicle-event-details
			[listName]="'vehicle-event-list-widget'"
			[settings]="data.config"
			[loaded]="loaded"
			[hasResults]="hasResults"
			[success]="success">
		</vehicle-event-details>
	</div>
</div>

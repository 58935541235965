/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { DEPOTS_STORE } from './depots-store-factory';

import { LoggerService } from '@cubicNx/libs/utils';
import { DepotsApiService } from './depots-api.service';

import { Depots } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class DepotsDataService {
	constructor(
		private logger: LoggerService,
		private depotsApiService: DepotsApiService,
		@Inject(DEPOTS_STORE) private depotsDataStore: any
	) {}

	/**
	 * handles the request for the depots for the given authority/agency (filtered with any search text) from the nextbus API
	 *
	 * @param authorityId - the current authority id
	 * @param agencyId - the current agency id
	 * @param searchText - any uiser filter text
	 * @returns the depots list response
	 */
	public getDepots = async (authorityId: string, agencyId: string, searchText: string = ''): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + agencyId + '-' + searchText;
			let depots: Depots = this.depotsDataStore.get(storeKey);

			if (!depots) {
				this.logger.logDebug(`Requesting depots for: ${storeKey}`);

				depots = await this.depotsApiService.getDepots(authorityId, agencyId, searchText);

				this.depotsDataStore.set(storeKey, depots);
			} else {
				this.logger.logDebug(`Returning depots for: ${storeKey} from cached store`);
			}

			result.success = true;
			result.resultData = depots as Depots;
		} catch (error) {
			this.logger.logError('Failed to get depots.', error);
		}

		return result;
	};
}

<div class="form-group col-xs-12">
	<field-label [name]="'type'" [label]="'T_MESSAGES.ROUTES' | translate" [required]="true"></field-label>
	<br />
	<div class="nb-form-control-vertical">
		<mat-radio-group
			(change)="routeStopSelectionTypeChange()"
			[(ngModel)]="routeStopSelectionType"
			aria-labelledby="route-selection-group-label"
			class="route-selection-group">
			<mat-radio-button class="route-selection-button" [value]="'all'">
				<span class="route-selection-text">{{ 'T_MESSAGES.ALL_STOP' | translate }}</span>
			</mat-radio-button>
			<mat-radio-button class="route-selection-button" [value]="'specific'">
				<span class="route-selection-text">{{ 'T_MESSAGES.SPECIFIC_RTE' | translate }}</span>
			</mat-radio-button>
		</mat-radio-group>

		<div class="col-xs-12" *ngIf="routeStopSelectionType === 'specific'">
			<select class="form-control" [(ngModel)]="selectedRouteId" (change)="routeSelected()" data-test="route.selection">
				<option value="placeholder" disabled selected>{{ 'T_MESSAGES.ADD_ROUTE' | translate }}</option>
				<option *ngFor="let route of allRoutesList" [ngValue]="route.routeId" [disabled]="isRouteSelected(route.routeId)">
					{{ route.routeDisplayName }}
					<span *ngIf="!route.routeIsPredictEnabled || route.routeIsHidden || !route.routeIsEnabled"
						>&nbsp;({{ 'T_MESSAGES.NO_AVAIL_STOPS' | translate }})</span
					>
				</option>
			</select>

			<div *ngFor="let selectedRoute of selectedRoutes; let routeIndex = index">
				<div class="nb-route-list col-xs-11">
					{{ selectedRoute.routeDetails.routeDisplayName }}&nbsp;
					<a *ngIf="selectedRoute.availabeStops" (click)="editStops(selectedRoute.id)">{{ selectedRoute.stopsLabel }}</a>
					<span *ngIf="!selectedRoute.availabeStops">-&nbsp;{{ 'T_MESSAGES.NO_AVAIL_STOPS' | translate }}</span>
				</div>
				<div class="nb-route-list nb-route-list-remove col-xs-1">
					<a (click)="removeRoute(selectedRoute.id, routeIndex)">
						<!-- dont translate 'close' it's used to set the 'x' icon -->
						<i class="material-icons nb-route-list-remove-item">close</i>
					</a>
				</div>
			</div>
			<div *ngIf="routeStopSelectionType === 'specific' && selectedRoutes.length === 0" class="field-error">
				<p>{{ 'T_MESSAGES.ERRORS.ROUTE_REQUIRED' | translate }}</p>
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapRoutesService } from '../map-routes.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { MapRoute, MapRoutesPathItem, Polylines } from '../../types/types';

import L from 'leaflet';

@Injectable({
	providedIn: 'root',
})
export class MapRoutesMarkerService {
	private mapInstance: L.Map = null;
	private paths: Polylines = {};

	constructor(
		private mapRoutesService: MapRoutesService,
		private colorUtilityService: ColorUtilityService
	) {}

	/**
	 * initialize the routes marker service
	 * @param mapInstance - the current map instance
	 */
	public init = (mapInstance: L.Map): void => {
		this.mapInstance = mapInstance;

		this.paths = {};
	};

	/**
	 * handle the agency selection change and clear any routes
	 */
	public setAgencyChange = (): void => {
		this.clearRoutes();

		this.paths = {};
	};

	/**
	 * add a new route
	 *
	 * @param routeId - the id of the route to add
	 */
	public addRoute = (routeId: string): void => {
		const route: MapRoute = this.mapRoutesService.getRoute(routeId);

		const latlngs: L.LatLng[][] = [];

		for (const path in route.paths) {
			const latlngItem: L.LatLng[] = [];

			route.paths[path].forEach((pathItem: MapRoutesPathItem) => {
				latlngItem.push(new L.LatLng(pathItem.lat, pathItem.lng));
			});

			latlngs.push(latlngItem);
		}

		if (!this.paths[route.routeId]) {
			this.paths[route.routeId] = L.polyline(latlngs, {
				color: this.colorUtilityService.getColor(route.routeColor),
				weight: 3,
				smoothFactor: 1,
				noClip: false,
				dashArray: '',
			}).addTo(this.mapInstance);
		}
	};

	/**
	 * remove a route
	 *
	 * @param routeId - the id of the route to remove
	 */
	public removeRoute = (routeId: string): void => {
		if (this.paths[routeId]) {
			this.paths[routeId].remove();
			delete this.paths[routeId];
		}
	};

	/**
	 * clear all routes
	 */
	public clearRoutes = (): void => {
		for (const routeId in this.paths) {
			this.removeRoute(routeId);
		}
	};
}

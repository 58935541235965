<div class="mat-dialog-header">
	<button class="close" mat-dialog-close>
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ getTranslation(modalTitle) }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<div [hidden]="!loading">
		<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
	</div>
	<div [hidden]="loading" *ngIf="user" [ngClass]="adminAgenciesAvailable ? 'tab-container' : 'tab-container-single'">
		<mat-tab-group #tabs (selectedTabChange)="updateTabControl()">
			<mat-tab label="{{ 'T_CORE.USER_DETAILS' | translate | uppercase }}">
				<user-admin-detail-edit
					[user]="user"
					[isCreate]="isCreate"
					(userDetailsValid)="validateUserDetails($event)"></user-admin-detail-edit>
			</mat-tab>
			<mat-tab label="{{ 'T_CORE.ROLES' | translate | uppercase }}" *ngIf="adminAgenciesAvailable">
				<user-admin-role-edit
					[user]="user"
					(rolesUpdated)="rolesUpdated($event)"
					(refreshParentTabControl)="updateTabControl()"></user-admin-role-edit>
			</mat-tab>
		</mat-tab-group>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" (click)="close(false)" cdkFocusInitial>{{ 'T_CORE.CANCEL' | translate }}</button>
	<button class="btn btn-primary" (click)="save()" [disabled]="!enableSave()">{{ 'T_CORE.SAVE' | translate }}</button>
</mat-dialog-actions>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DashboardConfigService } from '../services/dashboard-config.service';

@Component({
	selector: 'structure-select',
	templateUrl: './structure-select.component.html',
	styleUrls: ['./structure-select.component.scss'],
})
export class StructureSelectComponent implements OnInit {
	@Input() selectedStructure: string;

	@Output() update: EventEmitter<string> = new EventEmitter<string>();

	public structures: any;

	constructor(private dashboardConfigService: DashboardConfigService) {}

	/**
	 * performs initialization tasks for the structure select component - loads dashboard structures
	 */
	public ngOnInit(): void {
		this.structures = this.dashboardConfigService.structures;
	}

	/**
	 * Fires the update event emitting the name of the selected structure and setting the selectedStructure.
	 *
	 * @param name - the name of the selected structure.
	 */
	public updateSelected = (name: string): void => {
		this.selectedStructure = name;
		this.update.emit(name);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { StringHelpers } from '@cubicNx/libs/utils';

import { StateService } from '@cubicNx/libs/utils';
import { SchedulesAdminDataService } from '../services/schedules-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../../utils/components/breadcrumbs/types/types';
import { SchedulesList, SchedulesListItem } from '../types/types';
import { SchedulesPaginatedResponse, UploadHistory } from '../types/api-types';

import { Columns, ColumnType, ListSortings, PageRequestInfo, PaginatedParams, SelectedRowData, SortDirection } from '@cubicNx/libs/utils';

@Component({
	selector: 'schedules-admin-list',
	templateUrl: './schedules-admin-list.component.html',
	styleUrls: ['./schedules-admin-list.component.scss'],
})
export class SchedulesAdminListComponent extends TranslateBaseComponent implements OnInit {
	// pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'agencyName', dir: SortDirection.asc }];

	public hasFilters: boolean = false;
	public listName: string = 'schedules-list';
	public columns: Columns = [];
	public schedulesList: SchedulesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public initialized: boolean = false;
	public searchText: string = '';
	public breadcrumbItems: BreadcrumbItems = [];

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];

	constructor(
		private schedulesAdminDataService: SchedulesAdminDataService,
		private stateService: StateService,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the schedules list such as loading translations, initialisng breadcrumbs
	 * and building the list columns
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();
		this.initBreadcrumbs();
		this.loadCache();
		this.buildListColumns();
		this.initialized = true;
	}

	/**
	 * updates the contents of the schedules list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);
		await this.getAgencies();
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);
		await this.getAgencies();
	};

	/**
	 * action taken when an schedule is selected from the list - navigates to the selected schedule details
	 *
	 * @param selectedRow - the selected row information
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const schedule: SchedulesListItem = selectedRow.row;

		this.router.navigate(['admintools/schedules/view', schedule.authorityId]);
	};

	/**
	 * loads the translations presented within the schedules list
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_CORE.ADMIN_TOOLS', 'T_CORE.AGENCY_NAME', 'T_AGENCY.AUTHORITY_ID', 'T_SCHEDULE.SCHEDULES']);
	};

	/**
	 * initialises the schedules list breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_CORE.ADMIN_TOOLS'], targetPath: '/admintools', activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_SCHEDULE.SCHEDULES'], activePage: true });
	};

	/**
	 * builds the schedule list columns
	 */
	private buildListColumns = (): void => {
		// build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: 'agencyName',
				displayName: this.translations['T_CORE.AGENCY_NAME'],
				columnType: ColumnType.text,
			},
			{
				name: 'authorityId',
				displayName: this.translations['T_AGENCY.AUTHORITY_ID'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * retrieves last used search and page information for the list
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current searhed for text
	 *
	 * @param search - the current searched for text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * retrieves list of schedules for the agency that match the current page information and search text and populates the list accordingly
	 */
	private getAgencies = async (): Promise<void> => {
		this.listLoadingIndicator = true;
		this.totalRows = 0;

		// convert client side camel side notation for field name back to underscore version for back end request
		const paginatedParams: PaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			//! tells the compiler that we can trust the value wont be null (as it's an optional param)
			sort: StringHelpers.getUnderscoreValueFromCamelCase(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
		};

		const result: ResultContent = await this.schedulesAdminDataService.getAgencies(paginatedParams);

		if (result.success) {
			const schedulesResponse: SchedulesPaginatedResponse = result.resultData;

			if (schedulesResponse?.results) {
				// object for our list
				this.schedulesList = schedulesResponse.results.map((schedule: UploadHistory) => ({
					agencyName: schedule.agency_name,
					authorityId: schedule.authority_id,
					agencyId: schedule.agency_id,
				}));
				this.totalRows = schedulesResponse.total;
			}
		}

		this.listLoadingIndicator = false;
	};
}

export class StringHelpers {
	/**
	 * converts a text value in case to camel case to underscore
	 *
	 * I.e. converts aPropertyName to a_property_name
	 *
	 * @param name - the text value to convert.
	 * @returns the converted name in underscore case
	 */
	static getUnderscoreValueFromCamelCase = (name: string): string => {
		return name.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase());
	};

	/**
	 * converts a text value in '_' case to camel case
	 *
	 * I.e. converts a_property_name to aPropertyName
	 *
	 * @param name - the text value to convert.
	 * @returns the converted name in camel case
	 */
	static getCamelCaseValueFromUnderscore = (name: string): string => {
		return name.replace(/_([a-z])/g, (m) => m[1].toUpperCase());
	};

	/**
	 * Converts the supplied string to snake case.
	 *
	 * @param str - The string to convert.
	 * @returns The supplied string as snake case.
	 */
	public static toSnakeCase = (str = ''): string => {
		// Split string into array of words
		const strArr: string[] = str.split(' ').join(',').split('-').join(',').split(',');

		// Lower case all of the words.
		const snakeArr: string[] = strArr.reduce((acc, val) => acc.concat(val.toLowerCase()), []);

		// Join the words back together via underscores.
		return snakeArr.join('_');
	};

	/**
	 * adds a leading zero to the supplied number value if necessary
	 *
	 * @param n - The number to add a leading zero (if applicable).
	 * @returns the number with a leading zero if applicable in text format
	 */
	static addLeadingZero = (n: number): string => {
		return (n < 10 ? '0' : '') + n;
	};
}

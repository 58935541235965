/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ReportViewerBaseComponent } from './report-viewer-base.component';

import { LoggerService } from '@cubicNx/libs/utils';
import { ReportsDataService } from '../services/reports-data.service';
import { ReportsUtilService } from '../services/reports-util.service';
import { ReportsModalService } from '../services/reports-modal.service';
import { ReportsStateService } from '../services/reports-state.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ActionAllowedType, ReportSummaryContent, ReportTemplateSummaryContent } from '../types/types';
import { ReportTemplateDetail, ReportSummaryDetailData } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../utils/components/breadcrumbs/types/types';

@Component({
	selector: 'report-viewer',
	templateUrl: './report-viewer.component.html',
	styleUrls: ['./report-viewer.component.scss'],
})
export class ReportViewerComponent extends ReportViewerBaseComponent implements OnInit, OnDestroy {
	// From URL parameters
	public authorityId: string = null;
	public agencyId: string = null;
	public createdById: number = null;
	public defaultTemplateId: string = null;
	public reportId: string = null;

	public headerLoaded: boolean = false;

	public editButtonEnabled: boolean = false;
	public editButtonTooltip: string = '';
	public deleteButtonEnabled: boolean = false;
	public deleteButtonTooltip: string = '';
	public viewDetailsButtonEnabled: boolean = false;
	public viewDetailsButtonTooltip: string = '';
	public breadcrumbItems: BreadcrumbItems = [];

	public templateSummaryContent: ReportTemplateSummaryContent = { loaded: false };
	public summaryContent: ReportSummaryContent = { loaded: false };

	private readonly reportsHome: string = '/reports/0';
	private readonly reportDetails: string = '/reports/available/';
	private readonly interactive: string = 'interactive';
	private readonly finished: string = 'finished';

	private routeParams$Subscription: Subscription = null;

	constructor(
		private reportsDataService: ReportsDataService,
		private reportsUtilService: ReportsUtilService,
		private reportsStateService: ReportsStateService,
		private reportsModalService: ReportsModalService,
		private logger: LoggerService,
		private router: Router,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the available reports viewer compoenent
	 *
	 * sets up subscriptions, loads translations and loads template data, determine button states
	 */
	public async ngOnInit(): Promise<void> {
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.reportId = params.reportId;
			this.authorityId = params.authorityId;
			this.agencyId = params.agencyId;
			this.createdById = +params.createdById; // Convert to number
			this.defaultTemplateId = params.defaultTemplateId;

			await this.loadTranslations();

			await this.loadTemplateData();

			this.determineButtonStates();

			this.initBreadcrumbs();
		});
	}

	/**
	 * handes the rendering of the edit report dialog
	 */
	public edit = (): void => {
		this.reportsModalService.editReport(this.templateSummaryContent.template).subscribe();
	};

	/**
	 * handes the rendering of the delete report dialog
	 *
	 * on user confirmation the useris navigated to the reports home view
	 */
	public delete = (): void => {
		this.reportsModalService.deleteReport(this.authorityId, this.createdById, this.reportId).subscribe((deleted: boolean) => {
			if (deleted) {
				this.router.navigate([this.reportsHome]);
			}
		});
	};

	/**
	 * user clicks on view button and is navigated to the view report template view
	 */
	public viewDetails = (): void => {
		this.router.navigate([
			this.reportDetails,
			this.authorityId,
			this.agencyId,
			this.defaultTemplateId,
			this.createdById,
			this.reportId,
		]);
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads translations for the component
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_REPORT.REPORTS',
			'T_REPORT.VIEWER.DETAILS',
			'T_REPORT.REPORT_FETCH_FAILURE',
			'T_REPORT.FEATURE_DISABLED',
			'T_REPORT.EDIT_INTERACTIVE_ONLY',
			'T_CORE.NEED_EDIT_PERMISSION',
			'T_CORE.NEED_DEL_PERMISSION',
		]);
	};

	/**
	 * initializes breadcrumbs for the component
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_REPORT.REPORTS'], targetPath: this.reportsHome, activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_REPORT.VIEWER.DETAILS'], activePage: true });
	};

	/**
	 * Gets the required summary data required for the child summary content component. Requests the data to pass in to
	 * the content component show the data, rather than the child requesting the data itself. This is because we have
	 * other usages of the summary content component that request the data from other sources (i.e when viewing saved templates)
	 */
	private getReportSummaryDetail = async (): Promise<void> => {
		const response: ResultContent = await this.reportsDataService.getReportSummaryDetail(
			this.authorityId,
			this.createdById,
			this.reportId
		);

		if (response.success) {
			if (response.resultData.success) {
				this.summaryContent = this.reportsUtilService.getSummaryContentFromDetail(
					response.resultData.data as ReportSummaryDetailData
				);
			} else {
				this.summaryContent = {
					loaded: false,
					errorMessage: response.resultData.error,
				};
			}
		}
	};

	/**
	 * retrieves and loads report template details
	 */
	private getReportTemplateDetail = async (): Promise<void> => {
		const response: ResultContent = await this.reportsDataService.getReportTemplateDetail(
			this.authorityId,
			this.agencyId,
			this.reportId,
			this.createdById,
			this.defaultTemplateId
		);

		let template: ReportTemplateDetail = null;
		let success: boolean = false;

		if (response.success) {
			template = response.resultData;

			// solves an issue when the backend is not functioning correctly and returns an empty template
			if (template) {
				success = true;
			}
		}

		if (success) {
			this.templateSummaryContent = {
				loaded: true,
				template,
			};
		} else {
			this.templateSummaryContent = {
				loaded: false,
				errorMessage: this.translations['T_REPORT.REPORT_FETCH_FAILURE'],
			};
		}
	};

	/**
	 * determines if deleting report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if deleting report is allowed
	 */
	private determineDeleteTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_DEL_PERMISSION'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if editing report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if editing report is allowed
	 */
	private determineEditTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_EDIT_PERMISSION'];
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.EDIT_INTERACTIVE_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * loads the summary and detail information needed to render the report template details
	 */
	private loadTemplateData = async (): Promise<void> => {
		await this.getReportSummaryDetail();
		await this.getReportTemplateDetail();

		if (this.summaryContent.loaded && this.templateSummaryContent.loaded) {
			this.headerLoaded = true;
		} else {
			this.router.navigate([this.reportsHome]);
		}
	};

	/**
	 * determines the button states on the component - enabled and tooltips
	 *
	 * dependent upon what has been selected
	 */
	private determineButtonStates = (): void => {
		// Use the constant 'finished' state and 'interactive' type for checks as this component can only
		// be fulfilled for such reports.

		const editActionAllowed: ActionAllowedType = this.reportsStateService.canEditAvailableReport(
			this.defaultTemplateId,
			this.finished,
			this.interactive,
			this.authorityId,
			this.agencyId,
			this.createdById
		);

		this.editButtonEnabled = editActionAllowed === ActionAllowedType.allowed;
		this.editButtonTooltip = this.determineEditTooltip(editActionAllowed);

		const deleteActionAllowed: ActionAllowedType = this.reportsStateService.canDeleteAvailableReport(
			this.authorityId,
			this.agencyId,
			this.createdById,
			this.finished
		);

		this.deleteButtonEnabled = deleteActionAllowed === ActionAllowedType.allowed;
		this.deleteButtonTooltip = this.determineDeleteTooltip(deleteActionAllowed);

		// Can always view the report details from this context so no need to apply the similiar logic as above
		this.viewDetailsButtonEnabled = true;
		this.viewDetailsButtonTooltip = '';
	};
}

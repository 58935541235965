/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { DisablePredictionsEditModalComponent } from '../list/disable-predictions-edit-modal/disable-predictions-edit-modal.component';

import { DisablePredictionCloseType } from '../types/types';
import { DisabledPrediction } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class PredictionsModalService {
	constructor(private openEditModal: MatDialog) {}

	/**
	 * presents the edit prediction disable/enable details dialog and publishes the updated details and save or cancelled thereafter
	 * dependant upon user interaction
	 *
	 * @param authorityId - authority id
	 * @param timeZone - time zone
	 * @param selectedRouteId - selected route id
	 * @param predictionData - prediction data
	 * @param isDisableAllActive - is disable all active
	 * @returns event detailing how the dialog was closed with potential dialog data from the edit
	 */
	public openEdit = (
		authorityId: string,
		timeZone: string,
		selectedRouteId: string,
		predictionData: DisabledPrediction,
		isDisableAllActive: boolean
	): Observable<DisablePredictionCloseType> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<DisablePredictionsEditModalComponent> = this.openEditModal.open(
				DisablePredictionsEditModalComponent,
				{
					position: {
						top: '10px',
					},
					data: {
						authorityId,
						timeZone,
						routeId: selectedRouteId,
						predictionData,
						isDisableAllActive,
					},
				}
			);

			modalRef.afterClosed().subscribe(async (disablePredictionCloseType: DisablePredictionCloseType) => {
				observer.next(disablePredictionCloseType);
			});
		});
	};
}

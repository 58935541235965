import { InjectionToken } from '@angular/core';

import { DataStore } from '@cubicNx/libs/utils';

import { Depots } from '../types/api-types';

export const DEPOTS_STORE: InjectionToken<any> = new InjectionToken('depots_store_token');

export const depotsStore: any = () => new DataStore<Depots>();

export const depotsStoreProvider: any[] = [{ provide: DEPOTS_STORE, useFactory: depotsStore }];

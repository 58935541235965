/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { UploadHistoryDetailsType } from '../../../types/types';
import { SchedulesAdminDataService } from '../../../services/schedules-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { SchedulesUploadHistoryDetails } from '../../../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Component({
	selector: 'schedule-admin-view-upload-history-details',
	templateUrl: './schedule-admin-view-upload-history-details.component.html',
	styleUrls: ['./schedule-admin-view-upload-history-details.component.scss'],
})
export class ScheduleAdminViewUploadHistoryDetailsComponent extends TranslateBaseComponent implements OnInit {
	// Make enum accessible in template html
	public uploadHistoryDetailsTypeEnum: typeof UploadHistoryDetailsType = UploadHistoryDetailsType;

	public uploadType: UploadHistoryDetailsType = UploadHistoryDetailsType.confirmationNumber;
	public historyDetails: SchedulesUploadHistoryDetails = [];
	public loaded: boolean = false;
	private confirmationNumber: string = null;
	private authorityId: string = null;

	constructor(
		private schedulesAdminDataService: SchedulesAdminDataService,
		private modalRef: MatDialogRef<ScheduleAdminViewUploadHistoryDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);
		this.modalRef.disableClose = true;
	}

	/**
	 * closes the schedule admin view upload history dialog
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * performs initialization tasks for the schedule view upload history details component
	 */
	public async ngOnInit(): Promise<void> {
		this.confirmationNumber = this.data['confirmationNumber'];
		this.authorityId = this.data['authorityId'];
		this.uploadType = this.data['uploadHistoryDetailsType'];

		await this.getHistoryDetailsData();
	}

	/**
	 * retrieves the schedule upload history details for authority and confirmation number
	 */
	public getHistoryDetailsData = async (): Promise<void> => {
		const result: ResultContent = await this.schedulesAdminDataService.getSchedulesUploadHistoryDetails(
			this.authorityId,
			this.confirmationNumber
		);

		if (result.success) {
			this.historyDetails = result.resultData;
			this.loaded = true;
		}
	};

	/**
	 * presents the schedule upload history details dialog
	 *
	 * @param details - schedule upload history details
	 */
	public showDetails = (details: string): void => {
		const winUrl: string = URL.createObjectURL(new Blob([details], { type: 'text/html' }));

		window.open(winUrl, 'win', `width=800,height=400,screenX=200,screenY=200`);
	};

	/**
	 * closes the schedule upload history details dialog
	 */
	public close = (): void => {
		this.modalRef.close(false);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, Component, Input, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { MapBaseService } from '../../../../map/services/map-base.service';
import { TrafficService } from '../services/traffic.service';

import { BaseMapLayerConfig, BaseMapType } from '../../../../map/types/types';
import { IWidgetComponent } from '../../../types/types';

import { LayerGroup, TileLayer } from 'leaflet';

import L from 'leaflet';
import { TrafficWidgetSettings } from '../types/types';

@Component({
	selector: 'traffic',
	templateUrl: './traffic.component.html',
	styleUrls: ['./traffic.component.scss'],
})
export class TrafficComponent extends TranslateBaseComponent implements IWidgetComponent, OnInit, OnDestroy, AfterViewInit {
	@Input() data: any;
	@Input() rowData: any;

	public loaded: boolean = false;

	public hasResults: boolean = false;
	public success: boolean = false;
	public lastUpdated: Date = null;
	public mapId: string = null;
	public reloadWidget$Subscription: Subscription = null;

	private readonly creditsLeaflet: string = '<a href="https://leafletjs.com/">Leaflet</a>';
	private readonly creditsMapbox: string = '<a href="https://www.mapbox.com/about/maps/">Maps &copy; Mapbox</a>';
	private readonly LAYER_REMOVE_TIMER_RATE: number = 10000;

	private widgetId: string = null;
	private refreshTimer: any = null;

	private map: any;

	private group: LayerGroup<any>;
	private trafficLayerId: number;

	private defaults: any = {
		scrollWheelZoom: true,
		doubleClickZoom: false,
		zoomControl: true,
		zoomControlPosition: 'topright',
	};

	constructor(
		private mapBaseService: MapBaseService,
		private trafficService: TrafficService,
		private widgetEventsService: WidgetEventsService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the traffic component - setting up subscriptions and widget configuration
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();
		this.setup();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * after the component is loaded, the traffic data is loaded
	 */
	public ngAfterViewInit(): void {
		this.initData();

		// avoids console error
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * sets up traffic widget configuration
	 */
	private setup = (): void => {
		this.lastUpdated = new Date();
		this.mapId = `map-${this.data.wid}`;
		this.widgetId = this.data.wid;

		this.group = L.layerGroup();
	};

	/**
	 * initializes the map within the traffic widget
	 */
	private initData = (): void => {
		if (this.refreshTimer) {
			clearInterval(this.refreshTimer);
		}

		const timerValue: number = 60000 * this.data.config.trafficRefreshTime;

		this.handleMap();

		this.refreshTimer = setInterval(() => {
			this.handleMap();
			this.lastUpdated = new Date();
		}, timerValue);
	};

	/**
	 * loads map and presents within the widget
	 */
	private handleMap = (): void => {
		this.success = this.loadMapSettings();

		if (this.success) {
			if (this.map) {
				this.updateMap();
			} else {
				this.createMap();
			}
		} else {
			// just default to true so loading spinner ends and we can see error message
			this.loaded = true;
		}
	};

	/**
	 * add map attribution
	 */
	private addAttribution = (): void => {
		if (!this.map.hasAttribution) {
			this.map.attributionControl
				.setPrefix(this.creditsLeaflet) // override the default prefix which sets a Ukranian flag
				.addAttribution(this.creditsMapbox);
			this.map.hasAttribution = true;
		}
	};

	/**
	 * sets the map view
	 */
	private setMapView = (): void => {
		if (this.data.config.center && this.data.config.zoom) {
			this.map.setView(this.data.config.center, this.data.config.zoom);
		} else {
			this.map.fitBounds(L.latLngBounds(this.data.config.bounds));
			this.map.invalidateSize();
		}
	};

	/**
	 * loads the map settings
	 *
	 * @returns true if the map settings have been loaded, false otherwise
	 */
	private loadMapSettings = (): boolean => {
		if (this.data.config.bounds) {
			this.data.config.center = null;
			this.data.config.zoom = null;

			const mapConfig: TrafficWidgetSettings = this.trafficService.getTrafficWidgetMapSettings(this.widgetId);

			if (mapConfig) {
				if (this.data.config.selectedAgency.nb_id === mapConfig.nb_id) {
					this.data.config.center = mapConfig.center;
					this.data.config.zoom = mapConfig.zoom;
				} else {
					// If selected agency for the widget has changed,
					// the saved map settings are no longer valid
					this.trafficService.clearTrafficWidgetMapSettings(this.widgetId);
				}
			}

			return true;
		} else {
			return false;
		}
	};

	/**
	 * updates the traffic map
	 */
	private updateMap = (): void => {
		this.setMapView();
		const trafficLayer: TileLayer = this.addLayer();

		setTimeout(() => {
			this.removeLayer(this.trafficLayerId);
			this.trafficLayerId = L.stamp(trafficLayer);
		}, this.LAYER_REMOVE_TIMER_RATE);
	};

	/**
	 * creates and returns a traffic layer tile
	 *
	 * @returns a tile layer for the traffic map
	 */
	private addLayer = (): TileLayer => {
		const trafficLayerConfig: BaseMapLayerConfig = this.mapBaseService.getBaseMapLayer(BaseMapType.rasterTraffic);

		const tileLayerOptions: L.TileLayerOptions = {
			accessToken: trafficLayerConfig.layerOptions.accessToken,
			zoomOffset: trafficLayerConfig.layerOptions.zoomOffset,
			tileSize: trafficLayerConfig.layerOptions.tileSize,
		};

		const trafficLayer: TileLayer = L.tileLayer(trafficLayerConfig.url, tileLayerOptions).addTo(this.map);

		this.group.addLayer(trafficLayer);
		this.map.addLayer(trafficLayer);

		return trafficLayer;
	};

	/**
	 * removes a layer from the map
	 *
	 * @param layerId - the layer id
	 */
	private removeLayer = (layerId: any): void => {
		this.map.removeLayer(this.group.getLayer(layerId));
		this.group.removeLayer(layerId);
	};

	/**
	 * creates the traffic map
	 */
	private createMap = (): void => {
		this.map = L.map(this.mapId, this.defaults);

		this.map.zoomControl.setPosition(this.defaults.zoomControlPosition);
		this.addAttribution();

		// timeout is a workaround to give the map a chance to load. Ideally want to listen to some event here but makes sense
		// to investigate options when upgrading the whole map/leaflet solution to angular11
		setTimeout(() => {
			this.setMapView();

			const layer: TileLayer = this.addLayer();

			this.trafficLayerId = L.stamp(layer);

			this.loaded = true;
		}, 0);

		this.map.on('moveend', () => {
			const mapSettings: any = {
				nb_id: this.data.config.selectedAgency.nb_id,
				center: this.map.getCenter(),
				zoom: this.map.getZoom(),
			};

			this.trafficService.saveTrafficWidgetMapSettings(this.widgetId, mapSettings);
		});
	};

	/**
	 * set subscriptions - is interested in widget reloading
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.initData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.refreshTimer) {
			clearInterval(this.refreshTimer);
		}

		this.reloadWidget$Subscription?.unsubscribe();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class WeatherApiService {
	private readonly agenciesUrl: string = '/agencies';
	private readonly weatherUrl: string = '/weather/data';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the weather conditions for the given authority/agency and boundary from the weather API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param longitude - the boundary longitude
	 * @param latitude - the boundary latitude
	 * @returns the weather conditions response
	 */
	public getWeatherConditions = async (authorityId: string, agencyId: string, longitude: string, latitude: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.agenciesUrl + '/' + agencyId + this.weatherUrl, {
				lon: longitude,
				lat: latitude,
			});
		} catch (exception) {
			throw exception;
		}
	};
}

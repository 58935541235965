/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { ConfigService } from '@cubicNx/libs/utils';
import { HttpService } from '@cubicNx/libs/utils';

import { PaginatedParams } from '@cubicNx/libs/utils';
import { UserRoles, UserProfileDetails } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class UsersAdminApiService {
	private readonly usersUrl: string = '/users';
	private readonly activityNotes: string = '/activitynotes';
	private readonly userProfileUrl: string = '/profile';
	private readonly primaryAgencyUrl: string = '/primaryagency';
	private readonly rolesUrl: string = '/roles';
	private readonly statusUrl: string = '/status';
	private readonly resetPasswordInitiateUrl: string = '/resetpasswordinit';
	private readonly resetPasswordUrl: string = '/resetpassword';
	private readonly changePasswordUrl: string = '/changepassword';
	private readonly newSetPasswordUrl: string = '/newsetpassword';

	constructor(
		private httpService: HttpService,
		private configService: ConfigService
	) {}

	/**
	 * retrieves user details that match the supplied parameters
	 *
	 * @param userId - user id
	 * @returns user details
	 */
	public getUser = async (userId: string): Promise<any> => {
		try {
			return await this.httpService.get(this.usersUrl + '/' + userId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves the activity notes for the supplied user id
	 *
	 * @param userId - user id
	 * @returns activity notes for the user
	 */
	public getUserActivityNotes = async (userId: string): Promise<any> => {
		try {
			return await this.httpService.get(this.usersUrl + '/' + userId + this.activityNotes);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves users that match the supplied parameters
	 *
	 * @param params - user retrieval parameters - page size, number, sort criteria and search text
	 * @returns users matching the supplied parameters
	 */
	public getUsers = async (params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get(this.usersUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * creates a new user
	 *
	 * @param username - users username
	 * @param email - users email address
	 * @param realName - users real name
	 * @param smsNumber - users SMS number
	 * @param userRolesUpdated - if users roles have been updated
	 * @returns result of the creation attempt including new user id
	 */
	public createUser = async (
		username: string,
		email: string,
		realName: string,
		smsNumber: string,
		userRolesUpdated: UserRoles
	): Promise<any> => {
		try {
			const userObj: any = {
				username,
				real_name: realName,
				sms_number: smsNumber,
				email,
				roles: undefined,
				contact: undefined, // originally part of some kind of agency contact that seems to have been removed
			};

			if (userRolesUpdated) {
				userObj.roles = userRolesUpdated.filter((item) => item.selected);
			}

			return await this.httpService.post(this.usersUrl, userObj);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * updates user details
	 *
	 * @param userId - user id
	 * @param email - users email
	 * @param realName - users real name
	 * @param smsNumber - users SMS number
	 * @returns result of the save
	 */
	public saveUser = async (userId: number, email: string, realName: string, smsNumber: string): Promise<any> => {
		const userEditObj: any = {
			real_name: realName,
			sms_number: smsNumber,
			email,
		};

		try {
			return this.httpService.put(this.usersUrl + '/' + userId, userEditObj);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * saves the users primary agency
	 *
	 * @param id - user id
	 * @param nb_primary_agency - users primary agency
	 * @returns result of the save
	 */
	public saveUserPrimaryAgency = async (id: string, nb_primary_agency: string): Promise<any> => {
		try {
			const userProfileEditObj: any = {
				nb_primary_agency,
			};

			return await this.httpService.put(this.usersUrl + '/' + id + this.userProfileUrl + this.primaryAgencyUrl, userProfileEditObj);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * saves the users roles
	 *
	 * @param id - user id
	 * @param userRolesOld - old user roles
	 * @param userRolesUpdated - updated user roles
	 * @param granted_by - granted by user
	 * @returns results of the edit
	 */
	public editUserRoles = async (id: string, userRolesOld: UserRoles, userRolesUpdated: UserRoles, granted_by: string): Promise<any> => {
		try {
			const requests: any[] = [];
			let postObj: any = {};

			userRolesOld.forEach((item, i) => {
				if (item.roleName === this.configService.getAdminMasterRoleName()) {
					if (item.selected && !userRolesUpdated[i].selected) {
						// DEL master role
						requests.push(this.httpService.delete(this.usersUrl + '/' + id + this.rolesUrl + '/' + userRolesUpdated[i].roleId));
					}

					if (!item.selected && userRolesUpdated[i].selected) {
						// POST master role
						postObj = {
							role: userRolesUpdated[i].roleId,
							role_name: userRolesUpdated[i].roleName,
							granted_by,
						};

						requests.push(this.httpService.post(this.usersUrl + '/' + id + this.rolesUrl, postObj));
					}
				} else {
					if (item.selected && !userRolesUpdated[i].selected) {
						// DEL
						requests.push(
							this.httpService.delete(
								'/' +
									userRolesUpdated[i].authorityId +
									'/' +
									userRolesUpdated[i].agencyId +
									this.usersUrl +
									'/' +
									id +
									this.rolesUrl +
									'/' +
									userRolesUpdated[i].roleId
							)
						);
					}

					if (!item.selected && userRolesUpdated[i].selected) {
						// POST
						postObj = {
							authority: userRolesUpdated[i].authorityId,
							role: userRolesUpdated[i].roleId,
							granted_by,
						};

						requests.push(
							this.httpService.post(
								'/' +
									userRolesUpdated[i].authorityId +
									'/' +
									userRolesUpdated[i].agencyId +
									this.usersUrl +
									'/' +
									id +
									this.rolesUrl,
								postObj
							)
						);
					}
				}
			});

			return Promise.all(requests);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves roles that match the supplied parameters
	 *
	 * @param parameters - roles retrieval parameters - page size, number, sort criteria and search text
	 * @returns roles matching the supplied parameters
	 */
	public getRoles = async (parameters: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get(this.rolesUrl, parameters);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * records the fact that a rest password has been initiated
	 *
	 * @param username - users username
	 * @param emailAddress - users email address
	 * @returns result of the reset
	 */
	public resetPasswordInit = async (username: string, emailAddress: string): Promise<any> => {
		try {
			return await this.httpService.get(this.resetPasswordInitiateUrl, { username, email: emailAddress });
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * resets the users password
	 *
	 * @param key - key
	 * @param password - password
	 * @returns result of the reset attempt
	 */
	public resetPassword = async (key: string, password: string): Promise<any> => {
		try {
			return await this.httpService.post(this.resetPasswordUrl, { reset_password_key: key, password });
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * changes a users password
	 *
	 * @param userId - user id
	 * @param currentPW - current password
	 * @param newPW - new password
	 * @returns resturns the result of change password attempt
	 */
	public changePassword = async (userId: string, currentPW: string, newPW: string): Promise<any> => {
		try {
			return await this.httpService.post(this.usersUrl + '/' + userId + this.changePasswordUrl, {
				password: currentPW,
				newPassword: newPW,
			});
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * creates a users password
	 *
	 * @param key - key
	 * @param password - new password
	 * @returns returns the result of the creation
	 */
	public createPassword = async (key: string, password: string): Promise<any> => {
		try {
			return await this.httpService.post(this.newSetPasswordUrl, { reset_password_key: key, password });
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * saves the users profile
	 *
	 * @param userId - user id
	 * @param userProfile - user profile
	 * @returns result of the save
	 */
	public saveUserProfile = async (userId: string, userProfile: UserProfileDetails): Promise<any> => {
		const userProfileEditDetails: any = {
			phone: userProfile.phone,
			address: userProfile.address,
			address_2: userProfile.address_2,
			city: userProfile.city,
			state_province_region: userProfile.state_province_region,
			postal_code: userProfile.postal_code,
			country: userProfile.country,
			org_name: userProfile.org_name,
		};

		try {
			return await this.httpService.put(this.usersUrl + '/' + userId + this.userProfileUrl, userProfileEditDetails);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * updates the users account status
	 *
	 * @param selectedUserId - user id
	 * @param newStatus - new user status
	 * @param note - note
	 * @returns result of the update
	 */
	public updateAccountStatus = async (selectedUserId: string, newStatus: string, note: string): Promise<any> => {
		try {
			const updateAccountDetails: any = {
				status: newStatus,
				note,
			};

			return await this.httpService.put(this.usersUrl + '/' + selectedUserId + this.statusUrl, updateAccountDetails);
		} catch (exception) {
			throw exception;
		}
	};
}

export interface MultiSelectSettings {
	id_text: string;
	value_text: string;
	placeholder: string;
	readonly: boolean;
	selectedItemBadged: boolean;
	showCheckbox: boolean;
	showDropdownCaret: boolean;
	filterWithSelected: boolean;
	enableSearchFilter: boolean;
	maxSelectionCount?: number;
	optionStyles?: MultiSelectOptionStyles;
}

export interface MultiSelectOptionStyles {
	style: any;
	fieldComparisonOrOperator: boolean; // if true: any of the fields can match to apply style. If false: all must match to apply style
	fields: MultiSelectOptionStyleFields;
}

export interface MultiSelectOptionStyleField {
	name: string;
	value: string;
}

export type MultiSelectOptionStyleFields = Array<MultiSelectOptionStyleField>;

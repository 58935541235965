<div>
	<map-navigation></map-navigation>

	<map-banners></map-banners>

	<map [hidden]="mapOptionsService.getMode() !== modeType.map"></map>

	<ladder [hidden]="mapOptionsService.getMode() !== modeType.ladder"></ladder>

	<map-replay *ngIf="mapReplayService.getShowReplayControl()"></map-replay>

	<map-options></map-options>
</div>

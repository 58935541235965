/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ConfirmModalComponent } from '@cubicNx/libs/utils';
import { ConfirmMapViewSaveComponent } from '../save/confirm-map-view-save.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { MapViewDataService } from './map-view-data.service';
import { MapStateService } from '../../../services/map-state.service';

import { ConfirmButtonType, ConfirmResult } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { MapState } from '../../../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapViewModalService {
	constructor(
		private mapViewDataService: MapViewDataService,
		private mapStateService: MapStateService,
		private translationService: TranslationService,
		private renameViewModal: MatDialog,
		private deleteViewModal: MatDialog,
		private createViewModal: MatDialog,
		private applyViewModal: MatDialog
	) {}

	/**
	 * open the modal pop up to create a new view and handle the creation of the view when confirmed
	 *
	 * @param userId - the current user id
	 * @returns true when the view has been successfully created
	 */
	public createView = (userId: number): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.createViewModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					header: this.translationService.getTranslation('T_MAP.MAP_CREATE_SAVED_VIEW'),
					message: this.translationService.getTranslation('T_MAP.MAP_CREATE_SAVED_VIEW_CONFIRM_MSG'),
					confirmButtonType: ConfirmButtonType.saveCancelType,
					component: ConfirmMapViewSaveComponent,
					params: {
						viewName: null,
					},
				},
			});

			return modalRef.afterClosed().subscribe(async (confirmResult: ConfirmResult) => {
				let viewCreated: boolean = false;
				const viewName: string = confirmResult.eventData;

				if (confirmResult.confirmed) {
					const viewState: MapState = this.mapStateService.getCurrentState();

					const response: ResultContent = await this.mapViewDataService.createView(userId, viewName, viewState);

					if (response.success) {
						viewCreated = true;
					}
				}

				observer.next(viewCreated);
			});
		});
	};

	/**
	 * open the modal pop up to rename a new view and handle the rename of the view when confirmed
	 *
	 * @param userId - the current user id
	 * @param viewId - the view id
	 * @param viewTitle - the view title
	 * @param viewState - the saved view map state
	 * @returns true when the view has been successfully renamed
	 */
	public renameView = (userId: number, viewId: number, viewTitle: string, viewState: MapState): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.renameViewModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					header: this.translationService.getTranslation('T_MAP.MAP_RENAME_SAVED_VIEW'),
					message: this.translationService.getTranslation('T_MAP.MAP_RENAME_SAVED_VIEW_CONFIRM_MSG') + "'" + viewTitle + "'?",
					confirmButtonType: ConfirmButtonType.saveCancelType,
					component: ConfirmMapViewSaveComponent,
					params: {
						viewName: viewTitle,
					},
				},
			});

			return modalRef.afterClosed().subscribe(async (confirmResult: ConfirmResult) => {
				let viewRenamed: boolean = false;

				const viewName: string = confirmResult.eventData;

				if (confirmResult.confirmed) {
					const response: ResultContent = await this.mapViewDataService.updateView(userId, viewId, viewName, viewState);

					if (response.success) {
						viewRenamed = true;
					}
				}

				observer.next(viewRenamed);
			});
		});
	};

	/**
	 * open the modal pop up to delete a new view and handle the deletion of the view when confirmed
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view
	 * @param viewTitle - the title of the view
	 * @returns true when the view has been successfully deleted
	 */
	public deleteView = (userId: number, viewId: number, viewTitle: string): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteViewModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					header: this.translationService.getTranslation('T_MAP.MAP_DELETE_SAVED_VIEW'),
					message: this.translationService.getTranslation('T_MAP.MAP_DELETE_SAVED_VIEW_CONFIRM_MSG') + "'" + viewTitle + "'?",
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			return modalRef.afterClosed().subscribe(async (confirmResult: ConfirmResult) => {
				let viewDeleted: boolean = false;

				if (confirmResult.confirmed) {
					const response: any = await this.mapViewDataService.deleteView(userId, viewId);

					if (response.success) {
						viewDeleted = true;
					}
				}

				observer.next(viewDeleted);
			});
		});
	};

	/**
	 * open the modal pop up to apply a new view and handle the apply of the view when confirmed
	 *
	 * @param viewTitle - the title of the view
	 * @returns true when the view has been successfully deleted
	 */
	public applyView = (viewTitle: string): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.applyViewModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					header: this.translationService.getTranslation('T_MAP.MAP_APPLY_SAVED_VIEW'),
					message: this.translationService.getTranslation('T_MAP.MAP_APPLY_SAVED_VIEW_CONFIRM_MSG') + "'" + viewTitle + "'?",
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			return modalRef.afterClosed().subscribe(async (confirmResult: ConfirmResult) => {
				let viewApplyConfirmed: boolean = false;

				if (confirmResult.confirmed) {
					viewApplyConfirmed = true;
				}

				observer.next(viewApplyConfirmed);
			});
		});
	};
}

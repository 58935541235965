/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { VehicleEventSettingsComponent } from '../../../../vehicle-events/settings/vehicle-event-settings.component';
import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { IWidgetEditModalComponent } from '../../../types/types';

/**
 * This particular widget is different from the others in that it has a vehicle-events-settings common component that is also shared
 * with the vehicle events control in the header.  Due to this, this wrapper component handles the widget specific functionality by
 * extending the WidgetEditBaseComponent and updating values where necessary to maintain widget specefic behaviour, meaning the underlying
 * settings control is generic and can be used by the header.
 */

@Component({
	selector: 'vehicle-event-settings-widget',
	templateUrl: './vehicle-event-settings-widget.component.html',
	styleUrls: ['./vehicle-event-settings-widget.component.scss'],
})
export class VehicleEventSettingsWidgetComponent
	extends WidgetEditBaseComponent
	implements IWidgetEditModalComponent, OnInit, AfterViewInit, OnDestroy
{
	@ViewChild(VehicleEventSettingsComponent) vehicleEventsSettings: VehicleEventSettingsComponent;

	public loaded: boolean = true;

	constructor(
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * initializes this widget - builds form
	 */
	public async ngOnInit(): Promise<void> {
		this.buildForm();

		// setup agencies here as widget base class is responsible for storing them
		this.getSelectableAgencies();

		if (this.agencies?.length === 1) {
			// set the single agency if not already set
			if (!this.widget.config?.selectedAgency) {
				this.setSingleAgency();
			}
		}
	}

	/**
	 * initializion events following creation of child (settings) view
	 */
	public ngAfterViewInit(): void {
		this.setSubscriptions();
		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * Callback from settings component to handle agency being changed. Call base method to update widget agency value
	 * and pass in child methods for base class to call
	 */
	public agencyChanged = (): void => {
		this.handleAgencyChanged(this.vehicleEventsSettings.loadAgencyData, this.vehicleEventsSettings.clearAgencyData);
	};

	/**
	 * Callback from settings component to handle loaded value
	 * @param loaded - the loaded state received from the child.
	 */
	public handleLoaded = (loaded: boolean): void => {
		this.loaded = loaded;
	};

	/**
	 * handles any clean up
	 */
	public ngOnDestroy = (): void => {
		this.unsubscribe();
	};

	/**
	 * override base version of method as in this case the agencyId lives within settings sub group
	 *
	 * @returns returns the agencyId, specific to where it resides in this particular form
	 */
	protected override getSelectedAgencyId = (): string => {
		return this.reactiveForm.get('agencyId').value;
	};

	/**
	 * override base version of build form. This is handled this way so that due to the fact the setting component
	 * is shared with the application
	 * header component. As the widget version has title, which only appies to the widget, the following solution
	 * allows us to define the tile field
	 * and the reactiveForm instance is passed to the settings component to add the common form fields.
	 */
	protected override buildForm = (): void => {
		this.reactiveForm = new FormGroup({
			title: new FormControl(this.widget.title, {
				validators: [Validators.required, Validators.maxLength(this.maxTitleLength), Validators.minLength(this.minTitleLength)],
			}),
		});
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UsersAdminDataService } from '../../services/users-admin-data.service';
import { CurrentUserUtilService } from '../../../../../support-features/login/services/current-user/current-user-utils.service';
import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends TranslateBaseComponent implements OnInit {
	@Input() confirmText: string = null;

	public changePasswordForm: FormGroup;
	public newPasswordValid: boolean = false;
	public responseError: string = null;
	public currentPasswordError: boolean = false;
	public loading: boolean = false;

	private newPassword: string = null;

	constructor(
		private formBuilder: FormBuilder,
		private usersAdminDataService: UsersAdminDataService,
		private currentUserUtilService: CurrentUserUtilService,
		private modalRef: MatDialogRef<ChangePasswordComponent>,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * performs initialization tasks for the change password component
	 */
	public ngOnInit(): void {
		this.changePasswordForm = this.formBuilder.group(
			{
				password: ['', Validators.required],
			},
			{}
		);
	}

	/**
	 * reset the error
	 */
	public errorAlertClosed = (): void => {
		this.responseError = null;
	};

	/**
	 * clear the current error (if there is one)
	 */
	public currentPasswordUpdated = (): void => {
		this.currentPasswordError = false;
	};

	/**
	 * remembers the new password
	 *
	 * @param newPassword - new password
	 */
	public setNewPassword = (newPassword: string): void => {
		if (newPassword) {
			this.newPassword = newPassword;
		}
	};

	/**
	 * remembers whether the new password is valid or not
	 *
	 * @param newPasswordValid - true if new password is valid, false otherwise
	 */
	public setNewPasswordValid = (newPasswordValid: boolean): void => {
		this.newPasswordValid = newPasswordValid;
	};

	/**
	 * determines if the save button can be enabled. ie  is new password valid
	 * @returns true if the new password is valid, false otherwise
	 */
	public enableSave = (): boolean => {
		return this.newPasswordValid && !this.changePasswordForm.controls.password.pristine && !this.currentPasswordError;
	};

	/**
	 * saves the new password
	 */
	public save = (): void => {
		const currentUserId: string = this.currentUserUtilService.getCurrentUser().nb_id.toString();
		const currentPassword: string = this.changePasswordForm.controls.password.value;
		const newPassword: string = this.newPassword;

		this.changePassword(currentUserId, currentPassword, newPassword);
	};

	/**
	 * closes the change password dialog
	 */
	public close = (): void => {
		this.modalRef.close();
	};

	/**
	 * changes the password for the supplied user
	 *
	 * @param currentUserId - user id
	 * @param currentPassword - old password
	 * @param newPassword - new password
	 */
	public changePassword = async (currentUserId: string, currentPassword: string, newPassword: string): Promise<void> => {
		this.loading = true;

		const result: ResultContent = await this.usersAdminDataService.changePassword(currentUserId, currentPassword, newPassword);

		if (result.success) {
			this.modalRef.close();
		} else {
			if (result.resultData?.passwordError) {
				this.currentPasswordError = true;
			}

			this.loading = false;
		}
	};
}

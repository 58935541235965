<nav aria-label="breadcrumb">
	<ol class="breadcrumb">
		<ng-container *ngIf="useRouter">
			<ng-container *ngFor="let breadcrumbItem of breadcrumbItems">
				<li *ngIf="!breadcrumbItem.activePage" class="breadcrumb-item nb-breadcrumbs">
					<a (click)="navigateRouter(breadcrumbItem.targetPath)">{{ breadcrumbItem.displayText }}</a>
				</li>
				<li *ngIf="breadcrumbItem.activePage" class="breadcrumb-item nb-breadcrumbs" aria-current="page">
					{{ breadcrumbItem.displayText }}
				</li>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!useRouter">
			<ng-container *ngFor="let breadcrumbItem of breadcrumbItems">
				<li *ngIf="!breadcrumbItem.activePage" class="breadcrumb-item nb-breadcrumbs">
					<a (click)="navigateTarget(breadcrumbItem.entityType, breadcrumbItem.pageType)">{{ breadcrumbItem.displayText }}</a>
				</li>
				<li *ngIf="breadcrumbItem.activePage" class="breadcrumb-item nb-breadcrumbs" aria-current="page">
					{{ breadcrumbItem.displayText }}
				</li>
			</ng-container>
		</ng-container>
	</ol>
</nav>
<hr />

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ReportsDataService } from '../services/reports-data.service';
import { ReportsUtilService } from '../services/reports-util.service';
import { ReportsModalService } from '../services/reports-modal.service';
import { ReportsStateService } from '../services/reports-state.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../utils/components/breadcrumbs/types/types';
import { ActionAllowedType, ReportSummaryContent, ReportTemplateSummaryContent } from '../types/types';
import { ReportTemplateDetail, ReportSummaryDetailData } from '../types/api-types';

@Component({
	selector: 'report-details',
	templateUrl: './report-details.component.html',
	styleUrls: ['./report-details.component.scss'],
})
export class ReportDetailsComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public reportId: string = null;
	public createdById: number = null;
	public authorityId: string = null;
	public agencyId: string = null;
	public defaultTemplateId: string = null;
	public outputType: string = null;
	public status: string = null;
	public loading: boolean = true;
	public showTemplateDetails: boolean = false;
	public viewButtonEnabled: boolean = false;
	public viewButtonTooltip: string = '';
	public downloadButtonEnabled: boolean = false;
	public downloadButtonTooltip: string = '';
	public editButtonEnabled: boolean = false;
	public editButtonTooltip: string = '';
	public deleteButtonEnabled: boolean = false;
	public deleteButtonTooltip: string = '';
	public summaryContent: ReportSummaryContent = { loaded: false };
	public templateSummaryContent: ReportTemplateSummaryContent = { loaded: false };
	public breadcrumbItems: BreadcrumbItems = [];

	private readonly reportsHome: string = '/reports/0';
	private readonly reportInteractiveView: string = '/reports/viewer';

	private routeParams$Subscription: Subscription = null;
	private apiEndpoint: string = null;
	private code: string = null;
	private createdAt: string = null;
	private version: number = null;

	constructor(
		private reportsDataService: ReportsDataService,
		private reportsUtilService: ReportsUtilService,
		private reportsModalService: ReportsModalService,
		private reportsStateService: ReportsStateService,
		private logger: LoggerService,
		private router: Router,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the report details view
	 *
	 * sets up subscriptions, loads translations, initializes breadcrumbs and retrieves report template details
	 */
	public async ngOnInit(): Promise<void> {
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.reportId = params.reportId;
			this.authorityId = params.authorityId;
			this.agencyId = params.agencyId;
			this.createdById = +params.createdById; // Convert to number
			this.defaultTemplateId = params.defaultTemplateId;

			await this.getSummaryData();

			await this.loadTranslations();

			await this.initBreadcrumbs();

			this.determineButtonStates();

			const hasFailed: boolean = this.status.toLowerCase().indexOf('failed') > -1;
			const hasFinished: boolean = this.status === 'Finished';

			// we have template data for finished and failed reports (if they are interactive).  The version property was added
			// to the back end to distinguish between (failed) reports that were saved previously (that wouldn't have saved the
			// extra template details) and new ones that would have the extra template details. This could in theory be removed
			// once we know that all existing reports in the system have been created since the 3.0.18 release.
			if (this.outputType === 'interactive') {
				if (hasFinished || (hasFailed && this.version === 2)) {
					this.showTemplateDetails = true;
					await this.getReportTemplateDetail();
				}
			}

			// set loading false once both requests have been made regardless of their success meaning the loading spinner will turn off.
			// the content components themselves will handle failures in the actual data - i.e display appropriate error
			this.loading = false;
		});
	}

	/**
	 * renders the delete report dialog
	 *
	 * if user chooses to delete, then this navigates back to report home page
	 */
	public remove = async (): Promise<void> => {
		this.reportsModalService.deleteReport(this.authorityId, this.createdById, this.reportId).subscribe((deleted: boolean) => {
			if (deleted) {
				this.router.navigate([this.reportsHome]);
			}
		});
	};

	/**
	 * renders the edit report dialog
	 */
	public edit = async (): Promise<void> => {
		this.reportsModalService.editReport(this.templateSummaryContent.template).subscribe();
	};

	/**
	 * if report type is interactive (shouldnt be allowed to get here otherwise), then the report being rendered is downloaded
	 */
	public download = async (): Promise<void> => {
		if (this.outputType !== 'interactive') {
			await this.reportsDataService.downloadAvailableReport(
				this.authorityId,
				this.agencyId,
				this.apiEndpoint,
				this.createdById.toString(),
				this.reportId,
				this.code,
				this.createdAt
			);
		}
	};

	/**
	 * navigates to the interactive report viewer popuating with the report details from this component
	 */
	public viewInteractiveReport = (): void => {
		this.router.navigate([
			this.reportInteractiveView,
			this.authorityId,
			this.agencyId,
			this.defaultTemplateId,
			this.createdById,
			this.reportId,
		]);
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_REPORT.REPORTS',
			'T_REPORT.AVAILABLE_REPORT_DETAILS',
			'T_REPORT.REPORT_FETCH_FAILURE',
			'T_REPORT.FEATURE_DISABLED',
			'T_REPORT.EDIT_INTERACTIVE_ONLY',
			'T_REPORT.VIEW_INTERACTIVE_ONLY',
			'T_REPORT.DOWNLOAD_CSV_ONLY',
			'T_REPORT.CANT_DELETE_PENDING',
			'T_CORE.NEED_EDIT_PERMISSION',
			'T_CORE.NEED_DEL_PERMISSION',
		]);
	};

	/**
	 * initializes breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_REPORT.REPORTS'], targetPath: this.reportsHome, activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_REPORT.AVAILABLE_REPORT_DETAILS'], activePage: true });
	};

	/**
	 * Gets the required summary data required for the child summary content component. Requests the data to pass in to
	 * the content component show the data, rather than the child requesting the data itself. This is because we have
	 * other usages of the summary content component that request the data from other sources (i.e when viewing saved templates)
	 */
	private getSummaryData = async (): Promise<void> => {
		const response: ResultContent = await this.reportsDataService.getReportSummaryDetail(
			this.authorityId,
			this.createdById,
			this.reportId
		);

		if (response.success) {
			if (response.resultData.success) {
				const reportDetailData: ReportSummaryDetailData = response.resultData.data;

				this.status = reportDetailData.status;
				this.outputType = reportDetailData.output_type;
				this.apiEndpoint = reportDetailData.api_endpoint;
				this.code = reportDetailData.code;
				this.createdAt = reportDetailData.createdAt;
				this.version = reportDetailData.version;

				this.summaryContent = this.reportsUtilService.getSummaryContentFromDetail(reportDetailData);
			} else {
				this.summaryContent = {
					loaded: false,
					errorMessage: response.resultData.error,
				};
			}
		}
	};

	/**
	 * Gets the required interactive data required for the child interactive content component for the report
	 * (which to be here is to be interactive and 'finished'. Requests the data to pass in to the content component
	 * to show the data, rather than the child components requesting the data themselves. This is because we have other
	 * usages of the the child content component that requests the data from other sources (i.e when viewing saved templates)
	 */
	private getReportTemplateDetail = async (): Promise<void> => {
		const response: ResultContent = await this.reportsDataService.getReportTemplateDetail(
			this.authorityId,
			this.agencyId,
			this.reportId,
			this.createdById,
			this.defaultTemplateId
		);

		if (response.success) {
			const template: ReportTemplateDetail = response.resultData;

			this.templateSummaryContent = {
				loaded: true,
				template,
			};
		} else {
			this.templateSummaryContent = {
				loaded: false,
				errorMessage: this.translations['T_REPORT.REPORT_FETCH_FAILURE'],
			};
		}
	};

	/**
	 * determines the button states on the component - enabled and tooltips
	 *
	 * dependent upon what has been selected
	 */
	private determineButtonStates = (): void => {
		const viewActionAllowed: ActionAllowedType = this.reportsStateService.canViewAvailableReport(this.status, this.outputType);

		this.viewButtonEnabled = viewActionAllowed === ActionAllowedType.allowed;
		this.viewButtonTooltip = this.determineViewTooltip(viewActionAllowed);

		const downloadActionAllowed: ActionAllowedType = this.reportsStateService.canDownloadAvailableReport(this.status, this.outputType);

		this.downloadButtonEnabled = downloadActionAllowed === ActionAllowedType.allowed;
		this.downloadButtonTooltip = this.determineDownloadTooltip(downloadActionAllowed);

		const editActionAllowed: ActionAllowedType = this.reportsStateService.canEditAvailableReport(
			this.defaultTemplateId,
			this.status,
			this.outputType,
			this.authorityId,
			this.agencyId,
			this.createdById
		);

		this.editButtonEnabled = editActionAllowed === ActionAllowedType.allowed;
		this.editButtonTooltip = this.determineEditTooltip(editActionAllowed);

		const deleteActionAllowed: ActionAllowedType = this.reportsStateService.canDeleteAvailableReport(
			this.authorityId,
			this.agencyId,
			this.createdById,
			this.status
		);

		this.deleteButtonEnabled = deleteActionAllowed === ActionAllowedType.allowed;
		this.deleteButtonTooltip = this.determineDeleteTooltip(deleteActionAllowed);
	};

	/**
	 * determines if viewing report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if view report is allowed
	 */
	private determineViewTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.VIEW_INTERACTIVE_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if dowloading report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if download report is allowed
	 */
	private determineDownloadTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.DOWNLOAD_CSV_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if editing report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if editing report is allowed
	 */
	private determineEditTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_EDIT_PERMISSION'];
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.EDIT_INTERACTIVE_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if deleting report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if deleting report is allowed
	 */
	private determineDeleteTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_DEL_PERMISSION'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.CANT_DELETE_PENDING'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};
}

<div *ngIf="timeLabel" class="specific-time-label">
	<field-label [label]="timeLabel" [required]="timeLabelRequired"></field-label>
</div>
<div class="specific-time">
	<input
		[(ngModel)]="hours"
		[ngClass]="hoursStyle"
		class="form-control custom-date-element"
		type="number"
		min="01"
		max="12"
		placeholder="HH"
		(keyup)="hoursChanged()"
		(change)="hoursChanged()" />
	<span>&nbsp;:&nbsp;</span>
	<input
		[(ngModel)]="mins"
		[ngClass]="minsStyle"
		class="form-control custom-date-element"
		type="number"
		min="00"
		max="59"
		placeholder="MM"
		(keyup)="minsChanged()"
		(change)="minsChanged()" />
	<span>&nbsp;&nbsp;</span>
	<input [(ngModel)]="amPm" class="form-control custom-date-am-pm-element" readonly (click)="changeCustomDateAmPm()" />
</div>

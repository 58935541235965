/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { DashboardConfigService } from '../services/dashboard-config.service';
import { ComponentInjectionService } from '../services/component-injection.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { modalSlideDownAnimation, modalSlideAnimationTime } from '../types/types';

@Component({
	selector: 'dashboard-add-widget-modal',
	templateUrl: './dashboard-add-widget-modal.component.html',
	styleUrls: ['./dashboard-add-widget-modal.component.scss'],
	animations: modalSlideDownAnimation,
})
export class DashboardAddWidgetModalComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() selectedWidgetClicked: EventEmitter<any> = new EventEmitter<any>();

	public availableWidgets: any[];
	public contentVisible: boolean = false;

	constructor(
		private popoverService: ComponentInjectionService,
		private dashboardConfigService: DashboardConfigService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the dashboard add widget modal component
	 */
	public ngOnInit(): void {
		this.popoverService.appendClassToBody('modal-open');
		this.initTranslations(['T_DASH.DB_ADD_WIDGET', 'T_DASH.DB_CANCEL']);
		this.availableWidgets = this.dashboardConfigService.widgets.sort((a, b) => a.order - b.order);

		// set modal content visible (with animation)
		this.contentVisible = true;
	}

	/**
	 * performs clear up activities when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.popoverService.removeClassFromBody('modal-open');
	}

	/**
	 * Fires a closeClicked event.
	 */
	public close = (): void => {
		// set modal not visible (with animation)
		this.contentVisible = false;

		// allow the animation to finish before closing
		setTimeout(() => {
			this.closeClicked.emit();
		}, modalSlideAnimationTime);
	};

	/**
	 * Fires a selectedWidgetClicked event.
	 *
	 * @param widget - - the selected widget.
	 */
	public selectWidget = (widget: any): void => {
		this.selectedWidgetClicked.emit(widget);
	};
}

import { InjectionToken } from '@angular/core';
import { DataStore } from '@cubicNx/libs/utils';
import { FeedTypes } from '../types/api-types';
import { RouteConfig, Routes } from '../types/api-types';

export const ROUTES_STORE: InjectionToken<any> = new InjectionToken('routes_store_token');
export const ROUTE_CONFIG_STORE: InjectionToken<any> = new InjectionToken('route_config_store_token');
export const FEED_TYPES_STORE: InjectionToken<any> = new InjectionToken('feed_types_store_token');

export const routesStore: any = () => new DataStore<Routes>();
export const routeConfigStore: any = () => new DataStore<RouteConfig>();
export const feedTypesStore: any = () => new DataStore<FeedTypes>();

export const routeConfigStoreProvider: any[] = [
	{ provide: ROUTES_STORE, useFactory: routesStore },
	{ provide: ROUTE_CONFIG_STORE, useFactory: routeConfigStore },
	{ provide: FEED_TYPES_STORE, useFactory: feedTypesStore },
];

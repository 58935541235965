/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { UsersAdminEventsService } from './users-admin-events.service';
import { UsersAdminApiService } from './users-admin-api.service';
import { CurrentUserUtilService } from '../../../../support-features/login/services/current-user/current-user-utils.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { UserDetail, UserRoles, ChangePasswordResp, UsersPaginatedResponse, RolesSummary, ActivityNotes } from '../types/api-types';

import { PaginatedParams } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { UserLogin } from '../../../../support-features/login/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class UsersAdminDataService {
	private readonly incorrectPasswordResponse: string = 'Current password was not entered correctly';

	constructor(
		private logger: LoggerService,
		private currentUserUtilService: CurrentUserUtilService,
		private usersAdminApiService: UsersAdminApiService,
		private usersAdminEventsService: UsersAdminEventsService,
		private notificationService: NotificationService,
		private translationService: TranslationService
	) {}

	/**
	 * retrieves users that match the supplied parameters
	 *
	 * @param paginatedParams - user retrieval parameters - page size, number, sort criteria and search text
	 * @returns users matching the supplied parameters
	 */
	public getUsers = async (paginatedParams: PaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.getUsers(paginatedParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as UsersPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: UsersPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as UsersPaginatedResponse;
			}
		} catch (exception) {
			this.logger.logError('Failed to get users', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves user details that match the supplied parameters
	 *
	 * @param userId - user id
	 * @returns user details
	 */
	public getUser = async (userId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: UserDetail = await this.usersAdminApiService.getUser(userId);

			result.success = true;
			result.resultData = response as UserDetail;
		} catch (exception) {
			this.logger.logError('Failed to get user', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves the activity notes for the supplied user id
	 *
	 * @param userId - user id
	 * @returns activity notes for the user
	 */
	public getUserActivityNotes = async (userId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: ActivityNotes = await this.usersAdminApiService.getUserActivityNotes(userId);

			result.success = true;
			result.resultData = response as ActivityNotes;
		} catch (exception) {
			this.logger.logError('Failed to get user activity notes.', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * creates a new user
	 *
	 * @param user - users details
	 * @param userRolesUpdated - if users roles have been updated
	 * @returns result of the creation attempt including new user id
	 */
	public createUser = async (user: UserDetail, userRolesUpdated: UserRoles): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.createUser(
				user.username,
				user.email,
				user.real_name,
				user.sms_number,
				userRolesUpdated
			);

			if (response && response.id) {
				result.success = true;
				result.resultData = response.id;
			}
		} catch (exception: any) {
			let uniqueConstraintError: boolean = false;

			if (exception.error?.error?.name) {
				if (exception.error.error.name === 'SequelizeUniqueConstraintError') {
					uniqueConstraintError = true;
				}
			}

			if (uniqueConstraintError) {
				this.logger.logError('Failed to create user - sequelize unique constraint error', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.USERNAME_EXISTS'));
			} else {
				this.logger.logError('Failed to create user', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};

	/**
	 * updates user details
	 *
	 * @param user - updated user details
	 * @returns result of the save
	 */
	public saveUser = async (user: UserDetail): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.saveUser(user.nb_id, user.email, user.real_name, user.sms_number);

			result.resultData = response;

			if (response.success) {
				result.success = true;

				const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

				// have we just saved our own profile
				if (currentUser.nb_id === user.nb_id) {
					// update our cached (UserLogin object) with updated user details
					currentUser.profile.real_name = user.real_name;
					currentUser.profile.sms_number = user.sms_number;
					currentUser.profile.email = user.email;

					this.currentUserUtilService.setCurrentUser(currentUser);
				}
			} else {
				this.logger.logError('Failed to save user', response);
				this.notificationService.notifyError(this.translationService.getTranslation('T_USER.USER_SAVE_ERROR'));
			}
		} catch (exception: any) {
			if (exception?.status === 404) {
				this.logger.logError('Failed to save user - user save error', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_USER.USER_SAVE_ERROR'));
			} else {
				this.logger.logError('Server error', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};

	/**
	 * saves the users profile
	 *
	 * @param user - user details
	 * @returns result of the save
	 */
	public saveUserProfile = async (user: UserDetail): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.saveUserProfile(user.nb_id.toString(), user.user_profile);

			result.resultData = response;

			if (response.success) {
				result.success = true;

				const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

				// have we just saved our own profile
				if (currentUser.nb_id === user.nb_id) {
					// update our cached (UserLogin object) with updated profile details
					currentUser.profile.address = user.user_profile.address;
					currentUser.profile.address_2 = user.user_profile.address_2;
					currentUser.profile.city = user.user_profile.city;
					currentUser.profile.country = user.user_profile.country;
					currentUser.profile.phone = user.user_profile.phone;
					currentUser.profile.postal_code = user.user_profile.postal_code;
					currentUser.profile.state_province_region = user.user_profile.state_province_region;

					this.currentUserUtilService.setCurrentUser(currentUser);
				}
			} else {
				this.logger.logError('Failed to save profile', response);
				this.notificationService.notifyError(this.translationService.getTranslation('T_USER.PROFILE_SAVE_ERROR'));
			}
		} catch (exception: any) {
			if (exception?.status === 404) {
				this.logger.logError('Failed to save profile - user not found', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.USER_NOT_FOUND'));
			} else {
				this.logger.logError('Failed to save profile', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_USER.PROFILE_SAVE_ERROR'));
			}
		}

		return result;
	};

	/**
	 * saves the users primary agency
	 *
	 * @param id - user id
	 * @param nb_primary_agency - users primary agency
	 * @returns result of the save
	 */
	public saveUserPrimaryAgency = async (id: string, nb_primary_agency: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.saveUserPrimaryAgency(id, nb_primary_agency);

			if (response && response.success) {
				result.success = true;
				result.resultData = response;
			} else {
				this.logger.logError('Failed to save user primary agency.', response);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		} catch (exception: any) {
			if (exception?.status === 404) {
				this.logger.logError('Failed to save user primary agency - user profile not found');
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.USER_PROFILE_NOT_FOUND'));
			} else {
				this.logger.logError('Failed to save user primary agency - server error', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};

	/**
	 * retrieves list of user roles
	 *
	 * @returns user roles
	 */
	public getRoles = async (): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// For back-end call only
			let params: PaginatedParams;
			let roles: RolesSummary = [];

			const response: any = await this.usersAdminApiService.getRoles(params);

			// The backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// Data is a paginated response
				roles = response.results as RolesSummary;
			} else if (Array.isArray(response)) {
				roles = response as RolesSummary;
			}

			result.success = true;
			result.resultData = roles;
		} catch (error) {
			this.logger.logError(`Failed to get roles`, error);
		}

		return result;
	};

	/**
	 * saves the users roles
	 *
	 * @param id - user id
	 * @param userRolesOld - old user roles
	 * @param userRolesUpdated - updated user roles
	 * @param granted_by - granted by user
	 * @returns results of the edit
	 */
	public editUserRoles = async (
		id: string,
		userRolesOld: UserRoles,
		userRolesUpdated: UserRoles,
		granted_by: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.editUserRoles(id, userRolesOld, userRolesUpdated, granted_by);

			result.resultData = response;

			if (response && Array.isArray(response)) {
				result.success = true;
			} else {
				this.logger.logError('Failed to edit roles', response);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		} catch (exception: any) {
			if (exception?.status === 404) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.USER_ROLES_NOT_FOUND'));
			} else if (exception?.status === 403) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
			} else {
				this.logger.logError('Failed to edit roles', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};

	/**
	 * changes a users password
	 *
	 * @param userId - user id
	 * @param currentPW - current password
	 * @param newPW - new password
	 * @returns resturns the result of change password attempt
	 */
	public changePassword = async (userId: string, currentPW: string, newPW: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.changePassword(userId, currentPW, newPW);

			result.resultData = response;

			if (response.success) {
				result.success = true;

				this.notificationService.notifySuccess(this.translationService.getTranslation('T_USER.PASSWORD_CHANGE_SUCCESS'));

				this.usersAdminEventsService.publishPasswordChange();
			} else {
				this.logger.logError('Failed to change password.', response);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		} catch (exception: any) {
			this.logger.logError('Failed to change password.', exception);

			const changePasswordData: ChangePasswordResp = {
				passwordError: false,
			};

			result.resultData = changePasswordData as ChangePasswordResp;

			// The back end seems to return text (always in English)
			// far from ideal - we need codes for various errors to construct
			// an appropriate message in the form (in the correct language)
			if (exception.error?.error === this.incorrectPasswordResponse) {
				result.resultData.passwordError = true;
			}

			if (result.resultData.passwordError) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_USER.PASSWORD_ERROR'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};
	/**
	 * creates a users password
	 *
	 * @param key - key
	 * @param password - new password
	 * @returns returns the result of the creation
	 */
	public createPassword = async (key: string, password: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.createPassword(key, password);

			result.success = true;
			result.resultData = response;
		} catch (exception: any) {
			let errorMessage: string = null;

			switch (exception?.status) {
				case 401: {
					errorMessage = 'T_USER.PASSWORD_KEY_INVALID';
					break;
				}
				case 404: {
					errorMessage = 'T_USER.PASSWORD_SET_INVALID';
					break;
				}
				default: {
					errorMessage = 'T_CORE.SERVER_ERROR';
					break;
				}
			}

			this.logger.logError('failed to create password', exception);
			this.notificationService.notifyError(this.translationService.getTranslation(errorMessage));
		}

		return result;
	};

	/**
	 * resets the users password
	 *
	 * @param key - key
	 * @param password - password
	 * @returns result of the reset attempt
	 */
	public resetPassword = async (key: string, password: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.resetPassword(key, password);

			result.success = true;
			result.resultData = response;
		} catch (exception: any) {
			let errorMessage: string = null;

			switch (exception?.status) {
				case 401: {
					errorMessage = 'T_USER.PASSWORD_KEY_INVALID';
					break;
				}
				case 404: {
					errorMessage = 'T_USER.PASSWORD_RESET_KEY_INVALID';
					break;
				}
				default: {
					errorMessage = 'T_CORE.SERVER_ERROR';
					break;
				}
			}

			this.logger.logError('Failed to reset password', exception);
			this.notificationService.notifyError(this.translationService.getTranslation(errorMessage));
		}

		return result;
	};

	/**
	 * records the fact that a rest password has been initiated
	 *
	 * @param username - users username
	 * @param emailAddress - users email address
	 * @param showSuccessNotification - true if the show wuccess notification should be shown
	 * @returns result of the reset
	 */
	public resetPasswordInit = async (username: string, emailAddress: string, showSuccessNotification: boolean): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.usersAdminApiService.resetPasswordInit(username, emailAddress);

			result.success = true;
			result.resultData = response;

			if (showSuccessNotification) {
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.PW_RESET_SUCCESS'));
			}
		} catch (exception: any) {
			let errorMessage: string = null;

			switch (exception?.status) {
				case 401: {
					errorMessage = 'T_USER.PASSWORD_KEY_INVALID';
					break;
				}
				case 404: {
					errorMessage = 'T_USER.PASSWORD_RESET_KEY_INVALID';
					break;
				}
				case 500: {
					errorMessage = 'T_CORE.SERVER_ERROR';
					break;
				}
				default: {
					errorMessage = 'T_CORE.PW_RESET_ERROR';
					break;
				}
			}

			this.logger.logError('Failed to initiate password reset', exception);
			this.notificationService.notifyError(this.translationService.getTranslation(errorMessage));
		}

		return result;
	};

	/**
	 * updates the users account status
	 *
	 * @param selectedUserId - user id
	 * @param newStatus - new user status
	 * @param note - note
	 * @param successMsg - message to display if successful
	 * @param failureMsg - message to display if failed
	 * @returns result of the update
	 */
	public updateAccountStatus = async (
		selectedUserId: string,
		newStatus: string,
		note: string,
		successMsg: string,
		failureMsg: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			await this.usersAdminApiService.updateAccountStatus(selectedUserId, newStatus, note);

			result.success = true;

			this.notificationService.notifySuccess(successMsg);
		} catch (exception) {
			this.logger.logError('error updating user status', exception);
			this.notificationService.notifyError(failureMsg);
		}

		return result;
	};
}

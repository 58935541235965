<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container">
			<div *ngIf="listData" class="report-viewer-grid navigable-report">
				<table data-test="report.route-list.all">
					<thead>
						<tr>
							<th
								[ngClass]="sortDetails.field === 'route_id' ? sortClass : null"
								(click)="sortList('route_id')"
								data-test="th.route_id"
								class="ellipsis">
								{{ 'T_CORE.ROUTE_ID' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'route_long_name' ? sortClass : null"
								(click)="sortList('route_long_name')"
								data-test="th.route_long_name"
								class="ellipsis">
								{{ 'T_CORE.ROUTE_NAME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'very_early.percent' ? sortClass : null"
								(click)="sortList('very_early.percent')"
								data-test="th.very_early_percent"
								class="mw-col ellipsis">
								{{ 'T_CORE.VERY_EARLY' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'very_early.count' ? sortClass : null"
								(click)="sortList('very_early.count')"
								data-test="th.very_early_count"
								class="mw-col ellipsis"></th>
							<th
								[ngClass]="sortDetails.field === 'early.percent' ? sortClass : null"
								(click)="sortList('early.percent')"
								data-test="th.early_percent"
								class="mw-col ellipsis">
								{{ 'T_CORE.EARLY' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'early.count' ? sortClass : null"
								(click)="sortList('early.count')"
								data-test="th.early_count"
								class="mw-col ellipsis"></th>
							<th
								[ngClass]="sortDetails.field === 'on_time.percent' ? sortClass : null"
								(click)="sortList('on_time.percent')"
								data-test="th.on_time_percent"
								class="mw-col ellipsis">
								{{ 'T_CORE.ON_TIME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'on_time.count' ? sortClass : null"
								(click)="sortList('on_time.count')"
								data-test="th.on_time_count"
								class="mw-col ellipsis"></th>
							<th
								[ngClass]="sortDetails.field === 'late.percent' ? sortClass : null"
								(click)="sortList('late.percent')"
								data-test="th.late_percent"
								class="mw-col ellipsis">
								{{ 'T_CORE.LATE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'late.count' ? sortClass : null"
								(click)="sortList('late.count')"
								data-test="th.late_count"
								class="mw-col ellipsis"></th>
							<th
								[ngClass]="sortDetails.field === 'very_late.percent' ? sortClass : null"
								(click)="sortList('very_late.percent')"
								data-test="th.very_late_percent"
								class="mw-col ellipsis">
								{{ 'T_CORE.VERY_LATE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'very_late.count' ? sortClass : null"
								(click)="sortList('very_late.count')"
								data-test="th.very_late_count"
								class="mw-col ellipsis"></th>
							<th
								[ngClass]="sortDetails.field === 'total_count' ? sortClass : null"
								(click)="sortList('total_count')"
								data-test="th.total_count"
								class="mw-col ellipsis">
								{{ 'T_REPORT.TOTAL' | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let reportItem of listData" (click)="generateReport(reportItem)">
							<td class="reporttooltip" data-test="td.route_id">
								<div class="truncate">
									<span class="tooltiptext">{{ reportItem.route_long_name }}</span>
									<route-pill [route]="determineRoutePillData(reportItem)" [useFullWidth]="true"></route-pill>
								</div>
							</td>
							<td data-test="td.route_long_name" class="ellipsis reporttooltip">
								<span class="tooltiptext">{{ reportItem.route_long_name }}</span>
								<span>{{ reportItem.route_long_name }}</span>
							</td>
							<td
								data-test="td.very_early_percent"
								class="ellipsis"
								[ngStyle]="adherenceColorService.getEarlyStyle(reportItem.very_early.percent)">
								{{ reportItem.very_early.percent }}%
							</td>
							<td
								data-test="td.very_early_count"
								class="ellipsis"
								[ngStyle]="adherenceColorService.getEarlyStyle(reportItem.very_early.percent)">
								{{ reportItem.very_early.count }}
							</td>

							<td data-test="td.early_percent" class="ellipsis" [ngStyle]="adherenceColorService.getEarlyStyle(reportItem.early.percent)">
								{{ reportItem.early.percent }}%
							</td>
							<td data-test="td.early_count" class="ellipsis" [ngStyle]="adherenceColorService.getEarlyStyle(reportItem.early.percent)">
								{{ reportItem.early.count }}
							</td>

							<td data-test="td.on_time_percent" class="ellipsis">{{ reportItem.on_time.percent }}%</td>
							<td data-test="td.on_time_count" class="ellipsis">{{ reportItem.on_time.count }}</td>

							<td data-test="td.late_percent" class="ellipsis" [ngStyle]="adherenceColorService.getLateStyle(reportItem.late.percent)">
								{{ reportItem.late.percent }}%
							</td>
							<td data-test="td.late_count" class="ellipsis" [ngStyle]="adherenceColorService.getLateStyle(reportItem.late.percent)">
								{{ reportItem.late.count }}
							</td>

							<td
								data-test="td.very_late_percent"
								class="ellipsis"
								[ngStyle]="adherenceColorService.getLateStyle(reportItem.very_late.percent)">
								{{ reportItem.very_late.percent }}%
							</td>
							<td
								data-test="td.very_late_count"
								class="ellipsis"
								[ngStyle]="adherenceColorService.getLateStyle(reportItem.very_late.percent)">
								{{ reportItem.very_late.count }}
							</td>
							<td data-test="td.total_count" class="ellipsis">{{ reportItem.total_count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Directive, Inject, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[widgetHost]',
})
export class WidgetDirective {
	constructor(@Inject(ViewContainerRef) public viewContainerRef: ViewContainerRef) {}
}

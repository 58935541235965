/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnInit } from '@angular/core';

import { ReportViewerSortableListBaseComponent } from '../../report-viewer-sortable-list-base.component';

import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { ReportsConfigService } from '../../../../../services/reports-config.service';
import { DataService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { InteractiveReportAdherenceVehicleArrivalDepartureDetails } from '../../../../../types/api-types';

@Component({
	selector: 'ad600-adherence-vehicle-arrival-and-departure-list',
	templateUrl: './adherence-vehicle-arrival-and-departure-list.component.html',
	styleUrls: ['./adherence-vehicle-arrival-and-departure-list.component.scss'],
})
export class AdherenceVehicleArrivalAndDepartureListComponent extends ReportViewerSortableListBaseComponent implements OnInit {
	public listData: InteractiveReportAdherenceVehicleArrivalDepartureDetails = null;
	public authorityId: string = null;
	public agencyId: string = null;

	constructor(
		public dataService: DataService,
		protected override reportsEventsService: ReportsEventsService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsEventsService, reportsConfigService, colorUtilityService, translationService);

		this.templateSummaryContent = this.dataService['templateSummaryContent' as keyof DataService];
		this.defaultTemplateId = this.dataService['defaultTemplateId' as keyof DataService];
		this.authorityId = this.dataService['authorityId' as keyof DataService];
		this.agencyId = this.dataService['agencyId' as keyof DataService];
	}

	/**
	 * performs initialization tasks for the adherence vehicle departure and arrival list view (AD600)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (adherence)
	 */
	public updateListView = (listData: InteractiveReportAdherenceVehicleArrivalDepartureDetails): void => {
		this.listData = listData;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { ReportSummaryContent, ReportTemplateSummaryContent } from '../../types/types';

@Component({
	selector: 'report-viewer-header',
	templateUrl: './report-viewer-header.component.html',
	styleUrls: ['./report-viewer-header.component.scss'],
})
export class ReportViewerHeaderComponent extends TranslateBaseComponent {
	@Input() summaryContent: ReportSummaryContent = { loaded: false };
	@Input() templateSummaryContent: ReportTemplateSummaryContent = { loaded: false };
	@Input() defaultTemplateId: string = null;
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;

	public detailsDrawer: boolean = false;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * toggles the drawer on the report viewer header component
	 */
	public toggleDrawer = (): void => {
		this.detailsDrawer = !this.detailsDrawer;
	};
}

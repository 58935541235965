import { NgModule } from '@angular/core';

import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule],
	declarations: [BreadcrumbsComponent],
	providers: [],
	exports: [BreadcrumbsComponent],
})
export class AgencyPortalUtilsModule {}

import {
	ApplicationRef,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	Type,
} from '@angular/core';

@Injectable()
export class ComponentInjectionService {
	private _container: ComponentRef<any>;

	constructor(
		private applicationRef: ApplicationRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector
	) {
		this.setRootViewContainer(document.documentElement);
	}

	/**
	 * Gets the root view container to inject the component to.
	 *
	 * @returns root view container
	 * @throws view container not found error
	 */
	getRootViewContainer(): ComponentRef<any> {
		if (this._container) {
			return this._container;
		}

		const rootComponents: any = this.applicationRef['_rootComponents' as keyof ApplicationRef];

		if (rootComponents.length) {
			return rootComponents[0];
		}

		throw new Error('View Container not found! ngUpgrade needs to manually set this via setRootViewContainer.');
	}

	/**
	 * Overrides the default root view container. This is useful for
	 * things like ngUpgrade that doesn't have a ApplicationRef root.
	 *
	 * @param container - container
	 */
	setRootViewContainer(container: any): void {
		this._container = container;
	}

	/**
	 * Gets the html element for a component ref.
	 *
	 * @param componentRef - compoenent reference
	 * @returns HTML element for the compoenent reference
	 */
	getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
		return componentRef.hostView
			? (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]
			: ((componentRef as any).children[1] as HTMLElement);
	}

	/**
	 * Gets the root component container html element.
	 *
	 * @returns root component container html element
	 */
	getRootViewContainerNode(): HTMLElement {
		return this.getComponentRootNode(this.getRootViewContainer());
	}

	/**
	 * Projects the inputs onto the component
	 *
	 * @param component - component
	 * @param options - options
	 * @returns component reference
	 */
	projectComponentInputs(component: ComponentRef<any>, options: any): ComponentRef<any> {
		if (options) {
			const props: string[] = Object.getOwnPropertyNames(options);

			for (const prop of props) {
				component.instance[prop] = options[prop];
			}
		}

		return component;
	}

	/**
	 * Appends a component to a adjacent location
	 *
	 * @param componentClass - compoenent class
	 * @param options - component options
	 * @param location - location
	 * @returns compoenent reference
	 */
	appendComponent<T>(componentClass: Type<T>, options: any = {}, location: Element = this.getRootViewContainerNode()): ComponentRef<any> {
		const componentFactory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(componentClass);
		const componentRef: any = componentFactory.create(this.injector);
		const appRef: any = this.applicationRef;
		const componentRootNode: HTMLElement = this.getComponentRootNode(componentRef);

		// project the options passed to the component instance
		this.projectComponentInputs(componentRef, options);

		appRef.attachView(componentRef.hostView);

		componentRef.onDestroy(() => {
			appRef.detachView(componentRef.hostView);
		});

		location.appendChild(componentRootNode);

		return componentRef;
	}

	/**
	 * Appends the class to the body element.
	 *
	 * @param value - - the class to append.
	 */
	appendClassToBody = (value: string): void => {
		const body: any = (this._container as any).children[1];

		const current: any = body.attributes['class'];

		body.setAttribute('class', `${current.nodeValue} ${value}`);
	};

	/**
	 * Removes the class from the body element.
	 *
	 * @param value - - the class to remove.
	 */
	removeClassFromBody = (value: string): void => {
		const body: any = (this._container as any).children[1];

		const current: any = body.attributes['class'];
		const updated: any = current.nodeValue.replace(value, '').trim();

		body.setAttribute('class', updated);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { CreateVehicleMessage } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class VehicleMessagesApiService {
	private readonly vehiclesUrl: string = '/vehicles';
	private readonly messagesUrl: string = '/messages';
	private readonly predefinedVehiclesUrl: string = '/predefined-messages';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the predefined messages for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the predefined messages for the given authority response
	 */
	public getPredefinedMessages = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.messagesUrl + this.predefinedVehiclesUrl);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the vehicle messages for the given authority and request parameters from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param paginatedParams - the request parameters
	 * @returns the vehicle messages for the given authority response
	 */
	public getVehicleMessages = async (authorityId: string, paginatedParams: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.vehiclesUrl + this.messagesUrl, paginatedParams);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the creation of a vehicle message for the authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param vehicleMessage - the vehicle message content
	 * @returns the outcome of the create attempt
	 */
	public createVehicleMessage = async (authorityId: string, vehicleMessage: CreateVehicleMessage): Promise<any> => {
		try {
			return await this.httpService.post('/' + authorityId + this.vehiclesUrl + this.messagesUrl, vehicleMessage);
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

export enum ResetProcess {
	passwordRequest,
	passwordRequestConfirm,
	passwordEntry,
	passwordConfirmResponse,
}

@Component({
	selector: 'resetPassword',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public resetProcessEnum: typeof ResetProcess = ResetProcess;

	public resetProcess: ResetProcess = ResetProcess.passwordRequest;

	private routeParams$Subscription: Subscription = null;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the reset password component
	 */
	public ngOnInit(): void {
		// get params off the url
		this.routeParams$Subscription = this.route.queryParams.subscribe((params) => {
			const resetPasswordKey: string = params['reset_password_key'];

			if (resetPasswordKey) {
				this.resetProcess = ResetProcess.passwordEntry;
			} else {
				this.resetProcess = ResetProcess.passwordRequest;
			}
		});
	}

	/**
	 * sets the reset password status to initiated
	 */
	public resetInitiated = (): void => {
		this.resetProcess = ResetProcess.passwordRequestConfirm;
	};

	/**
	 * sets the reset password status to complete
	 */
	public passwordResetComplete = (): void => {
		this.resetProcess = ResetProcess.passwordConfirmResponse;
	};

	/**
	 * Navigates the user back to the login view
	 */
	public returnToLogin = (): void => {
		this.router.navigate(['/login']);
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { PerformanceMetricParams } from '../types/api-types';
import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class RoutesApiService {
	private readonly routesUrl: string = '/routes';
	private readonly blocksUrl: string = '/blocks';
	private readonly vehiclesUrl: string = '/vehicles';
	private readonly performanceUrl: string = '/performance';
	private readonly normalisedUrl: string = '/normalizedStops';
	private readonly extendedUrl: string = '/extended';

	constructor(private httpService: HttpService) {}

	/**
	 * requests routes from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param params - additional info for the request
	 * @returns the routes
	 */
	public getRoutes = async (authorityId: string, agencyId: string, params: PaginatedParams = null): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.routesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests routes from the nextbus API with extended detail such as route stops and shape data
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param params - additional info for the request
	 * @returns the routes with extended detail
	 */
	public getRoutesExtended = async (authorityId: string, agencyId: string, params: PaginatedParams = null): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.routesUrl + this.extendedUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request a single route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the id of the route for the request
	 * @returns the single route
	 */
	public getRoute = async (authorityId: string, agencyId: string, routeId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request blocks for route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the id of the route for the request
	 * @param params - additional info for the request
	 * @returns the route blocks
	 */
	public getRouteBlocks = async (authorityId: string, agencyId: string, routeId: string, params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeId + this.blocksUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request performance metrics for a route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param params - additional info for the request
	 * @returns the route performance metrics
	 */
	public getPerformanceMetrics = async (authorityId: string, agencyId: string, params: PerformanceMetricParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.routesUrl + this.performanceUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request headway performance for a route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeIds - the list of route ids for the request
	 * @param params - additional info for the request
	 * @returns the route headway performance data
	 */
	public getHeadwayPerformance = async (authorityId: string, agencyId: string, routeIds: string[], params: any): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeIds + this.vehiclesUrl,
				params
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request headway performance for a route from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id for the request
	 * @param params - additional info for the request
	 * @returns the route occupancy data
	 */
	public getOccupancy = async (authorityId: string, agencyId: string, routeId: string, params: any): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeId + this.vehiclesUrl,
				params
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * request route normalized stops from the nextbus API
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @param routeId - the route id for the request
	 * @returns the route normalized stops
	 */
	public getRouteNormalizedStops = async (authorityId: string, agencyId: string, routeId: string): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + '/' + agencyId + this.routesUrl + '/' + routeId + this.normalisedUrl);
		} catch (exception) {
			throw exception;
		}
	};
}

<div (clickOutside)="closeVehicleEvents()">
	<div id="vehicle-events-window" class="map-vehicle-event-panel" [hidden]="!isVisible">
		<div [hidden]="!isVisible || showSettings" class="nb-maps-vehicle-events-header nb-padding-all-sm events-header">
			<div class="events-header-panel">
				<div class="events-header-col">
					<span class="nb-subhead" data-test="notifications.heading">{{ 'T_DASH.EVENTS.PANEL_TITLE' | translate }}</span>
				</div>
				<div class="events-header-col">
					<div [hidden]="!liveEventsPlay" tabindex="3" class="vehicle-events-icon nb-padding-right-sm">
						<button
							type="button"
							class="btn nb-map-menu-button-events"
							(click)="toggleLiveEvents()"
							data-test="notifications.pause.btn"
							title="{{ 'T_DASH.EVENTS.PAUSE_LIVE_EVENTS' | translate }}"
							aria-label="Pause">
							<i class="material-icons-events">pause_circle_filled</i>
						</button>
					</div>
					<div [hidden]="liveEventsPlay" tabindex="3" class="vehicle-events-icon nb-padding-right-sm">
						<button
							type="button"
							[ngClass]="{ 'nb-map-resume-button': true }"
							class="btn nb-map-menu-button-events"
							data-test="notifications.resume.btn"
							(click)="toggleLiveEvents()"
							title="{{ 'T_DASH.EVENTS.RESUME_LIVE_EVENTS' | translate }}"
							aria-label="Play">
							<i class="material-icons-events">play_circle_filled</i>
						</button>
					</div>
					<!-- Settings button-->
					<div title="{{ 'T_DASH.EVENTS.SETTINGS' | translate }}" tabindex="3" class="vehicle-events-icon nb-padding-right-sm">
						<button
							type="button"
							class="btn-link-dark event-settings-button"
							data-test="notifications.settings.btn"
							aria-label="'T_DASH.EVENTS.SETTINGS' | translate"
							(click)="openSettings()">
							<i class="nb-icons nb-settings"></i>
						</button>
					</div>
					<!-- Close button-->
					<div
						title="{{ 'T_DASH.EVENTS.CLOSE_PANEL' | translate }}"
						tabindex="3"
						class="vehicle-events-icon"
						aria-label="'T_DASH.EVENTS.CLOSE_PANEL' | translate">
						<button type="button" class="btn-link-dark close-button" (click)="closeVehicleEvents()" data-test="notifications.close.btn">
							<i class="nb-icons nb-close"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div [hidden]="!isVisible || showSettings" class="vehicle-event-details-header-container" data-test="notifications.container">
			<vehicle-event-details-header [settings]="settings"></vehicle-event-details-header>
		</div>
		<div [hidden]="!showSettings" class="notification-settings-container" data-test="notification-settings.container">
			<div class="panel-header notification-settings-container-panel-header">
				<div class="vehicle-events-icon">
					<button
						type="button"
						class="btn-link-dark"
						(click)="closeSettings(null)"
						aria-hidden="true"
						data-test="notification-settings.back.btn">
						<i class="nb-icons nb-backarrow"></i>&nbsp;&nbsp;
					</button>
					<span class="nb-subhead" data-test="notification-settings.heading">{{ 'T_DASH.EVENTS.SETTINGS' | translate }}</span>
				</div>
				<!-- Close button -->
				<div title="{{ 'T_DASH.EVENTS.CLOSE_PANEL' | translate }}" tabindex="3" class="vehicle-events-icon" id="vehicle-events-icons6">
					<button type="button" class="btn-link-dark" (click)="closeVehicleEvents()" data-test="notification-settings.close.btn">
						<i class="nb-icons nb-close"></i>
					</button>
				</div>
			</div>
			<div class="container-fluid">
				<vehicle-event-settings-header
					[settings]="editableSettings"
					(closeSettings)="closeSettings($event)"></vehicle-event-settings-header>
			</div>
		</div>
	</div>
	<div class="vehicle-events-icon-container">
		<!--Button to open panel-->
		<div class="vehicle-events-icon">
			<button
				id="map-events-button"
				type="button"
				data-test="notifications.btn"
				class="btn nb-map-menu-button nb-bell-icon"
				(click)="toggleVehicleEvents()">
				<i class="nb-icons nb-alert-solid"></i>
			</button>
			<span class="badge bg-danger nb-event-superscript" data-test="notifications.badge-count" *ngIf="activeAlarmNum !== 0">{{
				activeAlarmNum
			}}</span>
		</div>
	</div>
</div>

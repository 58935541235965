import { Injectable } from '@angular/core';

import { TranslationService } from '@cubicNx/libs/utils';
import { CurrentUserApiService } from './current-user-api.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';

import { UserSettings } from '../../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class CurrentUserDataService {
	constructor(
		private translationService: TranslationService,
		private currentUserApiService: CurrentUserApiService,
		private logger: LoggerService,
		private notificationService: NotificationService
	) {}

	/**
	 * set user settings to the backend API
	 *
	 * @param currentUserNbId - the current user id
	 * @param userSettings - the current user settings
	 * @returns the result of the request
	 */
	public setUserSettings = async (currentUserNbId: number, userSettings: UserSettings): Promise<void> => {
		if (userSettings) {
			try {
				await this.currentUserApiService.saveUserSettings(currentUserNbId, userSettings);
			} catch (exception) {
				this.logger.logError('error saving settings', exception);
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}
	};
}

export interface ConfirmResult {
	confirmed: boolean;
	eventData?: any;
}

export enum ConfirmButtonType {
	yesNoType = 0,
	okType = 1,
	stayContinueType = 2,
	saveCancelType = 3,
}

export interface EventData {
	data: any;
	confirmEnabled: boolean;
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { UsersAdminDataService } from '../../services/users-admin-data.service';
import { LoginDataService } from '../../../../../support-features/login/services/login/login-data.service';
import { UserLoginRequest } from '../../../../../support-features/login/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'create-password',
	templateUrl: './create-password.component.html',
	styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public initialized: boolean = false;
	public loading: boolean = false;
	public passwordValid: boolean = false;
	public error: string = null;

	private routeParams$Subscription: Subscription = null;
	private resetPasswordKey: string = null;
	private username: string = null;

	private password: string = null;

	constructor(
		private usersAdminDataService: UsersAdminDataService,
		private loginDataService: LoginDataService,
		private router: Router,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the create password component
	 */
	public async ngOnInit(): Promise<void> {
		// get params off the url
		this.routeParams$Subscription = this.route.queryParams.subscribe((params) => {
			this.resetPasswordKey = params['reset_password_key'];
			this.username = params['username'];
		});

		await this.loadTranslations();
	}

	/**
	 * remembers the new password
	 *
	 * @param password - new password
	 */
	public newPassword = (password: string): void => {
		if (password) {
			this.password = password;
		}
	};

	/**
	 * remembers if the new password is valid or not
	 *
	 * @param passwordValid - is new password valid or not
	 */
	public enableSetPassword = (passwordValid: boolean): void => {
		this.passwordValid = passwordValid;
	};

	/**
	 * creates a new password
	 */
	public createPassword = async (): Promise<void> => {
		this.error = null;

		this.loading = true;

		const result: ResultContent = await this.usersAdminDataService.createPassword(this.resetPasswordKey, this.password);

		this.loading = false;

		if (result.success) {
			const userLoginRequest: UserLoginRequest = {
				username: this.username,
				password: this.password,
			};

			const loginResult: ResultContent = await this.loginDataService.logIn(userLoginRequest);

			if (loginResult.success) {
				this.router.navigate(['/dashboard']);
			} else {
				this.error = result.resultData.message;

				this.loading = false;
			}
		}
	};

	/**
	 * navigates the user to the login view
	 */
	public returnToLogin = (): void => {
		this.router.navigate(['/login']);
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * loads the translations presented within the create password component
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_USER.PASSWORD_SET_INVALID',
			'T_USER.PASSWORD_KEY_INVALID',
			'T_CORE.SERVER_ERROR',
			'T_CORE.LOGIN_FAILED_TRY_AGAIN',
		]);

		this.initialized = true;
	};
}

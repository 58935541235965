<div class="grid-title">
	<route-pill class="report-viewer-grid-route-pill" [route]="routeData" [useFullWidth]="true"></route-pill>
	<div class="configuration-stops-by-route-sub-list-title">
		<span>{{ subListData.route_long_name }} {{ subListData.direction }}</span>
	</div>
</div>
<table class="configuration-stops-by-route-sub-list-table">
	<thead>
		<tr>
			<th
				[ngClass]="sortDetails.field === 'sequence' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'sequence')"
				data-test="th.sequence"
				class="mw-col ellipsis">
				{{ 'T_CORE.ORDER' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'trip_headsign' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'trip_headsign')"
				data-test="th.trip_headsign"
				class="mw-col ellipsis">
				{{ 'T_REPORT.DESTINATION' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_name' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_name')"
				data-test="th.stop_name"
				class="mw-col ellipsis">
				{{ 'T_CORE.STOP_NAME' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_id')"
				data-test="th.stop_id"
				class="mw-col ellipsis">
				{{ 'T_REPORT.STOP_TAG' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_code' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_code')"
				data-test="th.stop_code"
				class="mw-col ellipsis">
				{{ 'T_CORE.STOP_ID' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'shape_id' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'shape_id')"
				data-test="th.shape_id"
				class="mw-col ellipsis">
				{{ 'T_REPORT.TRIP_PATTERN' | translate }}
			</th>
			<th
				[ngClass]="sortDetails.field === 'stop_lat' ? sortClass : null"
				(click)="toggleSortList(subListData.data, 'stop_lat')"
				data-test="th.stop_lat"
				class="mw-col ellipsis">
				{{ 'T_CORE.LAT_LON' | translate }}
			</th>
		</tr>
	</thead>
	<tbody class="zebra">
		<tr *ngFor="let reportDataItem of currentItemsToShow">
			<td data-test="td.sequence" class="ellipsis">{{ reportDataItem.sequence }}</td>
			<td data-test="td.trip_headsign" class="ellipsis">{{ reportDataItem.trip_headsign }}</td>
			<td data-test="td.stop_name" class="ellipsis reporttooltip">
				<span class="tooltiptext">{{ reportDataItem.stop_name }}</span
				>{{ reportDataItem.stop_name }}
			</td>
			<td data-test="td.stop_id" class="ellipsis">{{ reportDataItem.stop_id }}</td>
			<td data-test="td.stop_code" class="ellipsis">{{ reportDataItem.stop_code }}</td>
			<td data-test="td.shape_id" class="ellipsis">{{ reportDataItem.shape_id }}</td>
			<td data-test="td.stop_lat" class="ellipsis">{{ reportDataItem.stop_lat }} / {{ reportDataItem.stop_lon }}</td>
		</tr>
	</tbody>
</table>
<div class="report-inner-paging-toolbar report-viewer-paging-toolbar-container">
	<pagination-toolbar
		*ngIf="showSubPagingToolbar"
		class="report-viewer-paging-toolbar"
		[paginationSettings]="paginationSettings"
		[pagination]="pagination"
		(setPageSizeEvent)="setPageSize(subListData.data)"
		(pageChangedEvent)="pageChanged(subListData.data)"
		data-test="pagination.inner-section.bar">
	</pagination-toolbar>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnInit } from '@angular/core';

import { ReportViewerSortableListBaseComponent } from '../../report-viewer-sortable-list-base.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { DataService } from '@cubicNx/libs/utils';
import { ReportsConfigService } from '../../../../../services/reports-config.service';
import { AgenciesDataService } from '../../../../../../../support-features/agencies/services/agencies-data.service';
import { ReportsUtilService } from '../../../../../../../features/reports/services/reports-util.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { InteractiveReportBlockAssignmentsDetails } from '../../../../../types/api-types';

import moment from 'moment';

@Component({
	selector: 'bl100-block-assignments-list',
	templateUrl: './block-assignments-list.component.html',
	styleUrls: ['./block-assignments-list.component.scss'],
})
export class BlockAssignmentsListComponent extends ReportViewerSortableListBaseComponent implements OnInit {
	public listData: InteractiveReportBlockAssignmentsDetails = null;
	public authorityId: string = null;
	public agencyId: string = null;
	public agencyTimezoneOffset: string;

	constructor(
		private reportsUtilService: ReportsUtilService,
		private agenciesDataService: AgenciesDataService,
		protected override reportsEventsService: ReportsEventsService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		public dataService: DataService,
		translationService: TranslationService
	) {
		super(reportsEventsService, reportsConfigService, colorUtilityService, translationService);

		this.defaultTemplateId = this.dataService['defaultTemplateId' as keyof DataService];
		this.authorityId = this.dataService['authorityId' as keyof DataService];
		this.agencyId = this.dataService['agencyId' as keyof DataService];
	}

	/**
	 * performs initialization tasks for the block assignments list view (BL100)
	 */
	public ngOnInit(): void {
		const agencyTimezone: string = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);

		this.agencyTimezoneOffset = this.reportsUtilService.formatTimezoneOffset(moment.tz(agencyTimezone).utcOffset());

		this.init();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (block assignments)
	 */
	public updateListView = (listData: InteractiveReportBlockAssignmentsDetails): void => {
		this.listData = listData;
	};
}

<loading-overlay [isLoading]="isLoading" [fit]="'content'"></loading-overlay>

<div [hidden]="isLoading">
	<div class="nb-reset-password-text">
		<p>{{ 'T_USER.PASSWORD_RESET_ENTER_NEW_PASSWORD' | translate }}</p>
	</div>
	<form class="nb-login-form-container m-t" name="newPasswordForm">
		<set-password (passwordEntryValid)="enableSetPassword($event)" (newPassword)="updatePassword($event)"></set-password>

		<div class="divide"></div>
		<div class="form-group">
			<button name="passwordBtn" class="btn btn-primary btn-reset" [disabled]="!passwordValid" (click)="setNewPassword()">
				<p>{{ 'T_USER.PASSWORD_RESET_RESET_PASSWORD' | translate }}</p>
			</button>
		</div>
	</form>
</div>

<!--
COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

Information Contained Herein is Proprietary and Confidential.
The document is the property of "CUBIC" and may not be disclosed
distributed, or reproduced  without the express written permission of
"CUBIC".
-->

<!-- Green Banners (top) - replay / tracked vehicle / filters (depot / adherence / headway) -->
<div class="nb-map-container-display-mode">
	<div
		*ngIf="mapOptionsService.getMapMode() === mapModeType.replay"
		class="nb-map-container-display-mode-replay"
		[ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" data-test="map.banner.replay">{{ 'T_MAP.MAP_REPLAY_MODE' | translate }}</span>
		<button type="button" (click)="setReplayModeOff()" class="close banner-button pull-right" data-test="map.banner.replay.close">
			<i class="nb-icons nb-close" aria-label="Replay Mode"></i>
		</button>
	</div>

	<div
		*ngIf="mapVehiclesService.getShowVehiclesWithAdherenceIssues()"
		class="nb-map-container-display-mode-stale"
		[ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" data-test="map.banner.early">{{ 'T_MAP.MAP_VEHICLES_WITH_ISSUES_ONLY' | translate }}</span>
		<button type="button" (click)="setVehicleEarlyLateOff()" class="close banner-button pull-right" data-test="map.banner.early.close">
			<i class="nb-icons nb-close" aria-label="Vehicle Early Late"></i>
		</button>
	</div>

	<div
		*ngIf="mapVehiclesService.getShowVehiclesWithHeadwayIssues()"
		class="nb-map-container-display-mode-stale"
		[ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" data-test="map.banner.distant">{{
			'T_MAP.MAP_VEHICLES_WITH_HEADWAY_ISSUES_ONLY' | translate
		}}</span>
		<button type="button" (click)="setVehicleCloseDistantOff()" class="close banner-button pull-right" data-test="map.banner.distant.close">
			<i class="nb-icons nb-close" aria-label="Vehicle Close Distant"></i>
		</button>
	</div>

	<div
		*ngIf="mapVehiclesService.getSelectedDepotFilter().tag !== 'All'"
		class="nb-map-container-display-mode-depot"
		[ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" data-test="map.banner.depot">{{
			getTranslation('T_DEPOT.DEPOT') + ': ' + mapVehiclesService.getSelectedDepotFilter().name
		}}</span>
		<button type="button" (click)="resetSelectedDepot()" class="close banner-button pull-right" data-test="map.banner.depot.close">
			<i class="nb-icons nb-close" aria-label="Vehicle Depot"></i>
		</button>
	</div>

	<div
		*ngIf="mapVehicleTrackService.getTrackedVehicleId() !== null && mapOptionsService.getMode() === modeType.map"
		class="nb-map-container-display-mode-tracking"
		[ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" data-test="map.banner.tracking">{{
			getTranslation('T_MAP.MAP_TRACKING_MODE') + ': ' + mapVehicleTrackService.getTrackedVehicleId()
		}}</span>
		<button type="button" (click)="setTrackingModeOff()" class="close banner-button pull-right" data-test="map.banner.tracking.close">
			<i class="nb-icons nb-close" aria-label="Tracking Mode"></i>
		</button>
	</div>

	<div
		*ngIf="mapVehicleTrailService.getTrailedVehicleId() !== null && mapOptionsService.getMode() === modeType.map"
		class="nb-map-container-display-mode-trailing"
		[ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" data-test="map.banner.trailing">{{
			getTranslation('T_MAP.MAP_TRAILING_MODE') + ': ' + mapVehicleTrailService.getTrailedVehicleId()
		}}</span>
		<button type="button" (click)="setTrailModeOff()" class="close banner-button pull-right" data-test="map.banner.trailing.close">
			<i class="nb-icons nb-close" aria-label="Trailing Mode"></i>
		</button>
	</div>
</div>

<!-- Red Banner (bottom) - pausing live updates -->
<div *ngIf="mapOptionsService.getLiveUpdatesPaused()" class="nb-map-container-display-paused">
	<div class="nb-map-container-display-paused-on" [ngStyle]="{ left: getControlPositionOffset() + 'px' }">
		<span class="nb-replayindicator-text" translate="">{{ 'T_CORE.MAP_PAUSED' | translate }}</span>
		<button type="button" (click)="resumeLiveUpdates()" class="close banner-button pull-right">
			<i class="nb-icons nb-close" aria-label="Resume"></i>
		</button>
	</div>
</div>

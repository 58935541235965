<div class="nb-confirm-message confirm-message">
	<div class="nb-form-control">
		<div class="nb-checkbox">
			<label for="send-alert">
				<input id="send-alert" [(ngModel)]="sendAlert" type="checkbox" (change)="onSelect()" />
				<div class="checkbox-label">{{ confirmText }}</div>
			</label>
		</div>
	</div>
</div>

import { Injectable } from '@angular/core';

import { CurrentUserUtilService } from '../../login/services/current-user/current-user-utils.service';
import { TokenService } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private tokenService: TokenService
	) {}

	/**
	 * handles the log out of the user by clearing down the
	 * token and reloading the app
	 */
	public logOut = (): void => {
		this.currentUserUtilService.clearCurrentUser();
		this.tokenService.destroyApiToken();

		document.location.reload();
	};
}

<ngx-datatable
	class="material"
	[rows]="rowData"
	[externalPaging]="pagingEnabled"
	[externalSorting]="externalSorting || pagingEnabled"
	[count]="totalRows"
	[offset]="getOffset()"
	[limit]="getPageSize()"
	[selected]="selectedRows"
	[selectionType]="selectionType"
	[selectAllRowsOnPage]="false"
	[displayCheck]="displayCheck"
	[loadingIndicator]="loadingIndicator"
	[columnMode]="columnMode"
	[footerHeight]="enableFooter ? 50 : 0"
	[rowHeight]="rowHeight"
	[reorderable]="true"
	[messages]="{ emptyMessage: (getTranslation(noDataMessage) | uppercase) }"
	[rowClass]="getRowClass"
	[sorts]="initialSortings"
	[scrollbarV]="true"
	[scrollbarH]="false"
	[virtualization]="false"
	(page)="setPage($event)"
	(sort)="onSort($event)"
	(activate)="onSelect($event)"
	(select)="onSelectAllCheck($event)"
	(resize)="onColumnResize()">
	<ngx-datatable-column
		*ngIf="enableCheckRowSelection"
		[prop]="'checkbox'"
		[name]="''"
		[width]="30"
		[sortable]="false"
		[canAutoResize]="false"
		[draggable]="false"
		[resizeable]="false">
		<ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
			<ng-container *ngIf="enabledCheckSelectAllRowSelection">
				<label class="datatable-checkbox">
					<input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
				</label>
			</ng-container>
		</ng-template>
		<ng-template ngx-datatable-cell-template let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
			<label class="datatable-checkbox">
				<input type="checkbox" [disabled]="row.checkDisabled" [checked]="rowSelected(row)" (change)="onSelectCheck(row)" />
			</label>
		</ng-template>
	</ngx-datatable-column>
	<ng-container *ngFor="let column of columns">
		<ngx-datatable-column
			*ngIf="showColumn(column)"
			prop="{{ column.name }}"
			name="{{ getDisplayName(column) }}"
			[sortable]="isSortable(column.sortable)"
			[width]="getColumnWidth(column.name, column.width, column.fixedWidth)"
			[canAutoResize]="canColumnAutoResize(column.columnType)">
			<ng-template ngx-datatable-cell-template let-value="value" let-row="row">
				<ng-container [ngSwitch]="column.columnType">
					<ng-container *ngSwitchCase="ColumnType.text">
						<span title="{{ value }}">
							{{ value }}
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.icon">
						<span title="{{ value.iconName }}">
							<i [ngClass]="value.iconClass">{{ value.iconType }}</i>
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.selectableIcon">
						<ng-container *ngIf="showValue(value)">
							<span *ngIf="isSelected(value.selectedState.selected)">
								<span title="{{ getIconName(value.iconName) }}">
									<span *ngIf="isDisabled(value.selectedState.disabled)">
										<i [ngClass]="value.selectedState.selectedIconDisabledClass"></i>
									</span>
									<span *ngIf="!isDisabled(value.selectedState.disabled)">
										<i [ngClass]="value.selectedState.selectedIconClass"></i>
									</span>
								</span>
							</span>
							<span *ngIf="!isSelected(value.selectedState.selected)">
								<span title="{{ getIconName(value.iconName) }}">
									<span *ngIf="isDisabled(value.selectedState.disabled)">
										<i [ngClass]="value.selectedState.notSelectedIconDisabledClass"></i>
									</span>
									<span *ngIf="!isDisabled(value.selectedState.disabled)">
										<i [ngClass]="value.selectedState.notSelectedIconClass"></i>
									</span>
								</span>
							</span>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.date">
						<span title="{{ value | date: column.format }}">
							{{ value | date: column.format }}
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.dateFromNow">
						<span title="{{ value | date: column.format }} {{ getTimeAgoValue(value) }}">
							{{ value | date: column.format }} {{ getTimeAgoValue(value) }}
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.subText">
						<span title="{{ row[column.name] }} {{ row[column.subText] }}">
							{{ row[column.name]
							}}<small
								><i>{{ row[column.subText] }}</i></small
							>
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.cssClass">
						<span
							*ngIf="value.subClassName && value.subClassPosition === CssSubClassPosition.before"
							[ngClass]="value.subClassName"
							title="{{ value.subClassTooltip }}"></span>
						<span *ngIf="value.subClassValue && value.subClassPosition === CssSubClassPosition.before" [ngClass]="value.subClassClass">
							{{ value.subClassValue }}
						</span>
						<!-- The column name (with Class concatenated) is added so that parents have a hook to style individual columns if needed -->
						<span class="default-text" [ngClass]="value.className + ' ' + column.name + 'Class'" title="{{ value.tooltip }}">
							<span
								*ngIf="value.subClassName && value.subClassPosition === CssSubClassPosition.within"
								[ngClass]="value.subClassName"></span>
							<a (click)="onCssClassItemSelected(column.name, row)">{{ value.value }}</a>
						</span>
						<span
							*ngIf="value.subClassName && value.subClassPosition === CssSubClassPosition.after"
							[ngClass]="value.subClassName"
							title="{{ value.subClassTooltip }}"></span>
						<span *ngIf="value.subClassValue && value.subClassPosition === CssSubClassPosition.after" [ngClass]="value.subClassClass">
							{{ value.subClassValue }}
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.multiClass">
						<span class="default-text" [ngClass]="value.className + ' ' + column.name + 'Class'">
							<ng-container *ngFor="let item of value.items">
								<a (click)="onCssClassItemSelected(column.name, item)" title="{{ item.tooltip }}">{{ item.value }} </a>
							</ng-container>
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnType.component">
						<ng-template [ngTemplateOutlet]="column.componentTemplate" [ngTemplateOutletContext]="{ row: row }"> </ng-template>
					</ng-container>
				</ng-container>
			</ng-template>
		</ngx-datatable-column>
	</ng-container>

	<ngx-datatable-column
		*ngIf="enableMenuSelection"
		[prop]="'menu'"
		[name]="''"
		[width]="40"
		[headerClass]="'menu-header-cell'"
		[sortable]="false"
		[canAutoResize]="false"
		[draggable]="false"
		[resizeable]="false">
		<ng-template let-sort="sortFn" ngx-datatable-header-template>
			<div role="button" class="burger-icon-container" (click)="onMenuClick($event)">
				<a class="burger-icon">
					<i class="fa fa-bars"></i>
				</a>
			</div>
		</ng-template>
	</ngx-datatable-column>

	<!-- setup extra detail column toggle if we have row template passed in for expanded detail-->
	<ngx-datatable-column
		*ngIf="expandedDetailTemplate"
		[name]="''"
		[prop]="'expand'"
		[width]="30"
		[resizeable]="false"
		[sortable]="false"
		[draggable]="false"
		[canAutoResize]="false">
		<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
			<a
				href="javascript:void(0)"
				[class.datatable-icon-up]="expanded"
				[class.datatable-icon-down]="!expanded"
				title="Expand/Collapse Row"
				(click)="toggleExpandRow(row)"
				data-test="expand.collapse">
			</a>
		</ng-template>
	</ngx-datatable-column>

	<!-- Row Detail Expanded Template -->
	<ngx-datatable-row-detail [template]="expandedDetailTemplate" [rowHeight]="expandedRowHeight"> </ngx-datatable-row-detail>

	<ngx-datatable-footer>
		<ng-template ngx-datatable-footer-template let-isVisible="false">
			<!-- if showing paging - only hide if we are loading unless we have chosen to silently reload -->
			<ng-container *ngIf="pagingEnabled && (!loadingIndicator || silentReload)">
				<datatable-pager
					[pagerLeftArrowIcon]="'datatable-icon-left'"
					[pagerRightArrowIcon]="'datatable-icon-right'"
					[pagerPreviousIcon]="'datatable-icon-prev'"
					[pagerNextIcon]="'datatable-icon-skip'"
					[page]="getCurrentPage()"
					[size]="getPageSize()"
					[count]="totalRows"
					(change)="ngxDatatable.onFooterPage($event)"
					data-test="pagination.page">
				</datatable-pager>
				<div class="paging-toolbar-number-per-page" data-test="pagination.perPage">
					<select *ngIf="pageInfo" class="form-control" [(ngModel)]="pageInfo.pageSize" (change)="setPageSizeChanged()">
						<option value="5">5 per page</option>
						<option *ngIf="paginationMaxSize >= 10" value="10">10 per page</option>
						<option *ngIf="paginationMaxSize >= 25" value="25">25 per page</option>
						<option *ngIf="paginationMaxSize >= 50" value="50">50 per page</option>
						<option *ngIf="paginationMaxSize >= 100" value="100">100 per page</option>
						<option *ngIf="paginationMaxSize >= 200" value="200">200 per page</option>
					</select>
				</div>
			</ng-container>

			<!-- if showing total - only hide if we are loading unless we have chosen to silently reload -->
			<div class="total-rows" *ngIf="enableTotal && (!loadingIndicator || silentReload)">
				{{ 'T_CORE.TOTAL' | translate }}: {{ totalRows }}
			</div>

			<!-- if showing count - only hide if we are loading unless we have chosen to silently reload -->
			<div class="total-rows" *ngIf="enableCount && (!loadingIndicator || silentReload)">
				{{ 'T_CORE.COUNT' | translate }}: {{ selectedRows?.length }}
			</div>

			<ng-container *ngIf="loadingIndicator && !silentReload">
				<loading-overlay
					[isLoading]="true"
					[fit]="'content'"
					[loadingText]="loadingText"
					[showAnimation]="false"
					[fadeOverlay]="false"></loading-overlay>
			</ng-container>
		</ng-template>
	</ngx-datatable-footer>
</ngx-datatable>

<!-- menu popup -->
<div
	class="context-menu"
	[ngStyle]="{ 'left.px': contextMenuTopLeftPosition.x, 'top.px': contextMenuTopLeftPosition.y }"
	[matMenuTriggerFor]="contextMenu"></div>

<mat-menu #contextMenu="matMenu">
	<!-- hard code a single item for now - it may be all we ever need -->
	<button mat-menu-item class="ui-grid-menu-item" (click)="exportToCsv()">Export to CSV</button>
</mat-menu>

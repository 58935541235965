/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { WeatherApiService } from './weather-api.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { WeatherConditions } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class WeatherDataService {
	constructor(
		private weatherApiService: WeatherApiService,
		private loggerService: LoggerService
	) {}

	/**
	 * handles the requests for the weather conditions for the given authority/agency and boundary from the weather API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param longitude - the boundary longitude
	 * @param latitude - the boundary latitude
	 * @returns the weather conditions response
	 */
	public getWeatherConditions = async (
		authorityId: string,
		agencyId: string,
		longitude: string,
		latitude: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.weatherApiService.getWeatherConditions(authorityId, agencyId, longitude, latitude);

			result.success = true;
			result.resultData = response as WeatherConditions;
		} catch (exception) {
			this.loggerService.logError('Failed to get weather conditions.', exception);
		}

		return result;
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapEventsService } from '../../services/map-events.service';
import { MapOptionsService } from '../../services/map-options.service';
import { MapHistoryService } from '../../services/map-history.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { NavigationPage } from '../../types/types';
import { BreadcrumbItems, EntityType, NavigationTargetDetail, PageType } from '../../../../utils/components/breadcrumbs/types/types';

@Component({
	selector: 'map-navigation',
	templateUrl: './map-navigation.component.html',
	styleUrls: ['./map-navigation.component.scss'],
})
export class MapNavigationComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	// make enums accessible in html
	public navEntityType: typeof EntityType = EntityType;
	public navPageType: typeof PageType = PageType;

	public breadcrumbItems: BreadcrumbItems = [];
	public currentNavEntityType: EntityType = null;
	public currentNavPageType: PageType = null;
	public currentNavEntity: any = null;
	public enableRefresh: boolean = true;
	public navAnimation: { transition: string } = null;

	private navigateToPage$Subscription: Subscription = null;

	constructor(
		public mapOptionsService: MapOptionsService,
		private mapHistoryService: MapHistoryService,
		private mapEventsService: MapEventsService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialise the navigation component
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();
	}

	/**
	 * handle the user selecting breadcrub items
	 * @param navigationTargetDetail - the details of the page to navigate to
	 */
	public handleBreadcrumbNavigate = (navigationTargetDetail: NavigationTargetDetail): void => {
		const navPage: NavigationPage = {
			entityType: navigationTargetDetail.entityType,
			pageType: navigationTargetDetail.pageType,
			entity: null,
		};

		this.navigateToPage(navPage);
	};

	/**
	 * open the (collapsable) navigation menu
	 */
	public openNavView = (): void => {
		// trick to ensure child divs (loading spinner) aren't affected (we only turn on animation) when we need to
		// as it seems css doesn't allow you to override child behaviour for animations
		this.navAnimation = { transition: 'all .5s ease-in-out' };

		this.mapOptionsService.setNavViewOpen(true);
	};

	/**
	 * close the (collapsable) navigation menu
	 */
	public closeNavView = (): void => {
		this.mapOptionsService.setNavViewOpen(false);
	};

	/**
	 * handle the click of the refresh button - publish an event for child pages to subscribe to and handle
	 */
	public refreshView = async (): Promise<void> => {
		this.mapEventsService.publishNavigateRefresh();
	};

	/**
	 * set the flag to control whether the refresh button should be disabled
	 * @param enable - whether to enable or disable the button
	 */
	public handleEnableRefresh = (enable: boolean): void => {
		this.enableRefresh = enable;
	};

	/**
	 * navigate to the chosen page and set up the breadcrumbs for easier user navigation
	 *
	 * maintain the history so users can return to previous pages
	 *
	 * @param navigationPage - the details of the page to navigate to
	 */
	public navigateToPage = (navigationPage: NavigationPage): void => {
		this.currentNavEntityType = navigationPage.entityType;
		this.currentNavPageType = navigationPage.pageType;
		this.currentNavEntity = navigationPage.entity;

		this.setBreadcrumbs();

		if (this.currentNavEntityType === EntityType.menu) {
			this.mapHistoryService.clear();
		}

		this.mapHistoryService.addPage(navigationPage);

		this.changeDetectorRef.detectChanges();
	};

	/**
	 * handle any clean up - unsubscribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * navigate to page - handle user selection of an incomping page and navigate accordingly
	 */
	private setSubscriptions = (): void => {
		this.navigateToPage$Subscription = this.mapEventsService.navigateToPage.subscribe((event) => {
			this.navigateToPage(event);
		});
	};

	/**
	 * load translations for the component
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.MAP_LIVE_OPS',
			'T_MAP.MAP_ROUTES',
			'T_MAP.MAP_STOPS',
			'T_MAP.MAP_VEHICLES',
			'T_MAP.MAP_BLOCKS',
			'T_MAP.MAP_ROUTE_DETAILS',
			'T_MAP.MAP_STOP_DETAILS',
			'T_MAP.MAP_BLOCK_DETAILS',
			'T_MAP.MAP_VEHICLE_DETAILS',
			'T_MAP.MAP_VEHICLE_REASSIGN',
		]);
	};

	/**
	 * set up the breadcrumbs
	 */
	private setBreadcrumbs = (): void => {
		this.breadcrumbItems = [];

		if (this.currentNavEntityType !== null) {
			this.breadcrumbItems = [
				{ displayText: this.translations['T_CORE.MAP_LIVE_OPS'], entityType: EntityType.menu, activePage: false },
			];

			if (this.currentNavPageType === PageType.list) {
				switch (this.currentNavEntityType) {
					case EntityType.route:
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_ROUTES'], activePage: true });
						break;
					case EntityType.stop:
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_STOPS'], activePage: true });
						break;
					case EntityType.vehicle:
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_VEHICLES'], activePage: true });
						break;
					case EntityType.block:
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_BLOCKS'], activePage: true });
						break;
				}
			}

			if (this.currentNavPageType === PageType.details) {
				switch (this.currentNavEntityType) {
					case EntityType.route:
						this.breadcrumbItems.push({
							displayText: this.translations['T_MAP.MAP_ROUTES'],
							entityType: EntityType.route,
							pageType: PageType.list,
							activePage: false,
						});
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_ROUTE_DETAILS'], activePage: true });
						break;
					case EntityType.stop:
						this.breadcrumbItems.push({
							displayText: this.translations['T_MAP.MAP_STOPS'],
							entityType: EntityType.stop,
							pageType: PageType.list,
							activePage: false,
						});
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_STOP_DETAILS'], activePage: true });
						break;
					case EntityType.block:
						this.breadcrumbItems.push({
							displayText: this.translations['T_MAP.MAP_BLOCKS'],
							entityType: EntityType.block,
							pageType: PageType.list,
							activePage: false,
						});
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_BLOCK_DETAILS'], activePage: true });
						break;
					case EntityType.vehicle:
						this.breadcrumbItems.push({
							displayText: this.translations['T_MAP.MAP_VEHICLES'],
							entityType: EntityType.vehicle,
							pageType: PageType.list,
							activePage: false,
						});
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_VEHICLE_DETAILS'], activePage: true });
						break;
					case EntityType.vehicleReassign:
					case EntityType.vehicleReassignBlock:
						this.breadcrumbItems.push({
							displayText: this.translations['T_MAP.MAP_VEHICLES'],
							entityType: EntityType.vehicle,
							pageType: PageType.list,
							activePage: false,
						});
						this.breadcrumbItems.push({ displayText: this.translations['T_MAP.MAP_VEHICLE_REASSIGN'], activePage: true });
						break;
				}
			}
		}
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.navigateToPage$Subscription?.unsubscribe();
	};
}

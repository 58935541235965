/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { ReplayTimeFrameSelectComponent } from '../replay/time-frame-select/replay-time-frame-select.component';

@Injectable({
	providedIn: 'root',
})
export class MapModalService {
	constructor(private replayTimeFrameSelectModal: MatDialog) {}

	/**
	 * open  the time fram select modal
	 *
	 * @returns true when a time frame has been selected (false when the modal was cancelled)
	 */
	public replayTimeFrameSelect = (): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<ReplayTimeFrameSelectComponent> = this.replayTimeFrameSelectModal.open(
				ReplayTimeFrameSelectComponent,
				{
					width: '763px',
					data: {
						isCreate: true,
					},
					position: {
						top: '60px',
					},
				}
			);

			return modalRef.afterClosed().subscribe(async (timeFrameSelected: boolean) => {
				observer.next(timeFrameSelected);
			});
		});
	};
}

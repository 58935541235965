/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { TranslateBaseComponent } from '../translate-base/translate-base.component';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'search-filter',
	templateUrl: './search-filter.component.html',
	styleUrls: ['./search-filter.component.scss'],
})
export class searchFilterComponent extends TranslateBaseComponent implements OnInit, OnChanges {
	@Input() filterValue: string = '';
	@Input() searchSuggestions: string[] = [];
	@Input() requestSuggestionsOverHttp: boolean = false;

	@Output() search: EventEmitter<string> = new EventEmitter<string>();
	@Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

	public filter: string = '';

	public typeAheadSearchSuggestions: string[] = [];

	private typeAheadObserver: any = null;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * handles initialization for the search control
	 *
	 * Sets up the handling of the http request if the search is configured to request
	 * suggestions over http
	 */
	public ngOnInit(): void {
		this.filter = this.filterValue;

		if (this.requestSuggestionsOverHttp) {
			this.typeAheadSearchSuggestions = Observable.create((observer: Observer<string[]>) => {
				if (this.filter.length > 0) {
					// fire request to the parent to request new search suggestions
					this.handleSearchTextChanged();

					this.typeAheadObserver = observer;
				}
			});
		}
	}

	/**
	 * handles changes to the input params. Sets up the suggestions based on those changes
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.filterValue) {
			if (changes.filterValue.previousValue !== changes.filterValue.currentValue) {
				this.filter = this.filterValue;
			}
		}

		if (changes.searchSuggestions) {
			if (changes.searchSuggestions.firstChange) {
				if (!this.requestSuggestionsOverHttp) {
					// not using search on text entry mode - initialize the search suggestions
					this.typeAheadSearchSuggestions = changes.searchSuggestions.currentValue;
				}
			} else {
				if (this.requestSuggestionsOverHttp) {
					// updated/changed search suggestions received - inform typeahead observable
					this.typeAheadObserver.next(changes.searchSuggestions.currentValue);
				}
			}
		}
	}

	/**
	 * handles the user changing the search text and emits up to the parent to handle
	 */
	public handleSearchTextChanged = (): void => {
		if (this.requestSuggestionsOverHttp) {
			this.searchTextChanged.emit(this.filter);
		}
	};

	/**
	 * handles the user clearing the search and performs a search with the empty value
	 */
	public clear = (): void => {
		this.filter = '';
		this.performSearch();
	};

	/**
	 * emits the search request up to the parent
	 */
	public performSearch = (): void => {
		this.search.emit(this.filter);
	};
}

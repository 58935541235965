/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { BlocksDataService } from '../../../../../support-features/blocks/services/block-data.service';
import { WidgetEventsService } from '../../../services/widget-events.service';
import { MapNavigationService } from '../../../../map/services/map-navigation.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { DeadHeadingVehicles, DeadHeadingVehicle } from '../types/types';
import { RoutePillData } from '@cubicNx/libs/utils';
import { VehicleSummary, VehicleRoute, VehicleSummaries } from '../../../../../support-features/vehicles/types/api-types';
import { VehicleDetailsActiveTab } from '../../../../map/types/types';

import {
	DashboardCurrentRouteBlocks,
	TripDetail,
	TripDetails,
	DashboardCurrentRouteBlock,
} from '../../../../../support-features/routes/types/api-types';

import moment from 'moment-timezone';

@Component({
	selector: 'deadheading-vehicles-view',
	templateUrl: './deadheading-vehicles-view.component.html',
	styleUrls: ['./deadheading-vehicles-view.component.scss'],
})
export class DeadheadingVehiclesViewComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;
	public loaded: boolean = false;
	public hasResults: boolean = false;
	public success: boolean = false;
	public lastUpdateTime: Date = null;
	public deadHeadingVehicles: DeadHeadingVehicles = [];

	private blocks: DashboardCurrentRouteBlocks = [];
	private vehicles: VehicleSummaries = [];
	private navigationVehicles: VehicleSummaries = [];
	private agencyTimeZone: string = null;
	private listInterval: any = null;
	private agenciesLoaded$Subscription: Subscription = null;

	constructor(
		private mapNavigationService: MapNavigationService,
		private widgetEventsService: WidgetEventsService,
		private agenciesDataService: AgenciesDataService,
		private vehiclesDataService: VehiclesDataService,
		private blocksDataService: BlocksDataService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the deadheading vehicles view - setting up subscriptions and loading the data
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();
		await this.getData();
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * navigate to the vehicle details view
	 *
	 * @param authorityId - authority id
	 * @param vehicleId - vehicle id
	 */
	public navigateToVehicleDetails = async (authorityId: string, vehicleId: string): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(authorityId, vehicleId, VehicleDetailsActiveTab.summary);
	};

	/**
	 * navigate to the route details view
	 *
	 * @param routeId - route id
	 */
	public navigateToRouteDetails = async (routeId: string): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(this.data.config.selectedAgency.authority_id, routeId);
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param route - the route details
	 * @returns route pill
	 */
	public determineRoutePillData = (route: VehicleRoute): RoutePillData => {
		return {
			routeShortName: route.route_name,
			routeLongName: route.route_name,
			routeId: route.route_id,
			routeColor: this.colorUtilityService.getColor(route.route_color),
			routeTextColor: this.colorUtilityService.getColor(route.route_text_color),
		};
	};

	/**
	 * Navigates to the block view page for the specified block.
	 *
	 * @param blockId - the block id
	 */
	public navigateToBlockDetails = async (blockId: string): Promise<void> => {
		await this.mapNavigationService.navigateToBlockDetails(this.data.config?.selectedAgency.authority_id, blockId);
	};

	/**
	 * retrieves the deadheading vehicles data
	 */
	private getData = async (): Promise<void> => {
		this.loaded = false;
		if (this.listInterval) {
			clearInterval(this.listInterval);
		}

		const refreshRate: number = (this.data.config.refreshRateSec || 30) * 1000;

		await this.getDeadheadingVehicles();

		this.listInterval = setInterval(this.getDeadheadingVehicles, refreshRate);
	};

	/**
	 * retrieves the vehicle data and pulls out relevant deadheading data
	 */
	private getDeadheadingVehicles = async (): Promise<void> => {
		this.hasResults = false;
		this.success = false;

		const result: ResultContent = await this.vehiclesDataService.getVehicles(this.data.config?.selectedAgency.authority_id);

		if (result.success) {
			this.vehicles = result.resultData as VehicleSummaries;

			this.navigationVehicles = this.vehicles.filter((x: VehicleSummary): boolean => x.is_navigation_prediction === true);
			if (this.navigationVehicles.length > 0) {
				this.hasResults = true;

				this.agencyTimeZone = this.agenciesDataService.getAgencyTimezone(
					this.data.config?.selectedAgency.authority_id,
					this.navigationVehicles[0].agency_id
				);

				this.navigationVehicles.sort(this.sortOnDepartureTime);
			}

			this.navigationVehicles.forEach((item: VehicleSummary): void => {
				if (!item) {
					return;
				}

				this.getBlockDetails();
			});

			this.lastUpdateTime = new Date();
			this.success = true;
		}

		this.loaded = true;
	};

	/**
	 * populate the deadheading vehicles with route and block details
	 */
	private async getBlockDetails(): Promise<void> {
		const routesIds: string[] = this.navigationVehicles.map((vehicle: VehicleSummary) => vehicle.current_state.route.route_id);

		const blocksResponse: ResultContent = await this.blocksDataService.getCurrentBlocks(
			this.data.config?.selectedAgency.authority_id,
			this.data.config?.selectedAgency.agency_id,
			this.agencyTimeZone,
			this.data.config.display,
			routesIds,
			undefined,
			undefined,
			this.data.config.progress
		);

		if (blocksResponse.success) {
			this.blocks = blocksResponse.resultData as DashboardCurrentRouteBlocks;

			this.deadHeadingVehicles = [];

			this.blocks.filter((block: DashboardCurrentRouteBlock) => {
				this.navigationVehicles.forEach((vehicle: VehicleSummary) => {
					if (vehicle.deadheading_details.block_id === block.block_id) {
						const deadHeadingVehicle: DeadHeadingVehicle = {
							// allows the text to wrap if needed
							deadheadingYard: vehicle.deadheading_details.deadheading_yard.replace(',', ', '),
							vehicleId: vehicle.vehicle_id,
							route: vehicle.current_state.route,
							blockDetails: block,
							stop: vehicle.deadheading_details.stop,
							predectionHHmmss: this.milliSecTohhmmss(+vehicle.deadheading_details.prediction_time, this.agencyTimeZone),
							schedTime: this.getScheduleTime(block.tripDetails),
						};

						this.deadHeadingVehicles.push(deadHeadingVehicle);
					}
				});
			});
		}
	}

	/**
	 * retrieves the trip schedule time for the supplied trip
	 *
	 * @param tripDetails - trip details
	 * @returns the trip schedule time
	 */
	private getScheduleTime = (tripDetails: TripDetails): string => {
		let time: string = null;

		tripDetails.forEach((trip: TripDetail): void => {
			if (trip.active === true) {
				time = this.milliSecTohhmmss(trip.startTimeEpoch, this.agencyTimeZone);
			}
		});

		return time;
	};

	/**
	 * utility to convert supplied seconds to hours, mins and seconds taking supplied timezone into consideration
	 *
	 * @param milliSec - mill seconds
	 * @param timeZone - timezone
	 * @returns formatted time (HH:mm:ss)
	 */
	private milliSecTohhmmss(milliSec: number, timeZone: string): string {
		return moment.tz(milliSec, timeZone).format('HH:mm:ss');
	}

	/**
	 * utility to sort vehciel summaries by their prediction time
	 *
	 * @param a - vehicle summary a
	 * @param b - vehicle summary b
	 * @returns comparison result for sort purposes
	 */
	private sortOnDepartureTime(a: VehicleSummary, b: VehicleSummary): number {
		return Number(a.deadheading_details.prediction_time) - Number(b.deadheading_details.prediction_time);
	}

	/**
	 * sets up component subscriptions - is interested when the widget is reloaded
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.getData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		if (this.listInterval) {
			clearInterval(this.listInterval);
		}

		this.agenciesLoaded$Subscription?.unsubscribe();
		this.reloadWidget$Subscription?.unsubscribe();
	};
}

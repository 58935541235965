import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { RoutePillData } from './types/types';

@Component({
	selector: 'route-pill',
	templateUrl: './route-pill.component.html',
	styleUrls: ['./route-pill.component.scss'],
})
export class RoutePillComponent implements OnInit, OnChanges {
	@Input() route: RoutePillData;
	@Input() useFullWidth: boolean = false;

	public displayName: string;
	public backgroundColor: string;
	public textColor: string;

	private readonly defaultRouteColor: string = '#dddddd'; // light grey
	private readonly blackHexColor: string = '#000000';
	private readonly whiteHexColor: string = '#ffffff';

	/**
	 * handles initialization for the route pill
	 *
	 * Sets up the handling of the http request if the search is configured to request
	 * suggestions over http
	 */
	public ngOnInit(): void {
		this.setValues();
	}

	/**
	 * handles changes to the input params
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.route && !changes.route.firstChange) {
			this.setValues();
		}
	}

	/**
	 * determines the display name for the route based on available values
	 *
	 * @returns the route display name
	 */
	private getDisplayName = (): string => {
		return this.route?.routeShortName || this.route?.routeLongName || this.route?.routeId;
	};

	/**
	 * sets up the display of the route pill
	 */
	private setValues = (): void => {
		this.displayName = this.getDisplayName();

		// Dont allow black background
		this.backgroundColor = this.route?.routeColor === this.blackHexColor ? this.defaultRouteColor : this.route?.routeColor;

		// Dont have white text on grey background
		if (this.backgroundColor === this.defaultRouteColor && this.route?.routeTextColor === this.whiteHexColor) {
			this.textColor = this.blackHexColor;
		} else {
			this.textColor = this.route?.routeTextColor;
		}
	};
}

<div>
	<panel-header [showBackButton]="false" [heading]="'T_CORE.ADMIN_TOOLS' | translate"> </panel-header>
	<div class="col-lg-12">
		<div class="wrapper wrapper-content">
			<h3 class="nb-section-title nb-margin-bottom-md">{{ 'T_ADMIN_TOOLS.TRANSIT_MANAGEMENT' | translate }}</h3>
			<div class="admintools-thumbnail-group">
				<a item="authority" (click)="navigate('/admintools/authorities')">
					<div class="thumbnail-card">
						<img src="/assets/img/AdminToolsThumbnails/NoBG/authorities-thumb.svg" alt="Unavailable" />
						<div class="thumbnail-title">{{ 'T_ADMIN_TOOLS.AUTHORITIES' | translate }}</div>
					</div>
				</a>
				<a item="agency" (click)="navigate('/admintools/agencies')">
					<div class="thumbnail-card">
						<img src="/assets/img/AdminToolsThumbnails/NoBG/agencies-thumb.svg" alt="Unavailable" />
						<div class="thumbnail-title">{{ 'T_ADMIN_TOOLS.AGENCIES' | translate }}</div>
					</div>
				</a>
			</div>
			<h3 class="nb-section-title nb-margin-bottom-md">{{ 'T_ADMIN_TOOLS.SYSTEM_CONFIGURATION' | translate }}</h3>
			<div class="admintools-thumbnail-group">
				<a item="users" (click)="navigate('/admintools/users')">
					<div class="thumbnail-card">
						<img src="/assets/img/AdminToolsThumbnails/NoBG/users-thumb.svg" alt="Unavailable" />
						<div class="thumbnail-title">{{ 'T_ADMIN_TOOLS.USERS' | translate }}</div>
					</div>
				</a>
				<a item="schedule" (click)="navigate('/admintools/schedules')">
					<div class="thumbnail-card">
						<img src="/assets/img/AdminToolsThumbnails/NoBG/schedules-thumb.svg" alt="Unavailable" />
						<div class="thumbnail-title">{{ 'T_ADMIN_TOOLS.SCHEDULES' | translate }}</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>

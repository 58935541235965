/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { ReportViewerSortableListBaseComponent } from '../../report-viewer-sortable-list-base.component';

import { ReportsEventsService } from '../../../../../services/reports-events.service';
import { DataService } from '@cubicNx/libs/utils';
import { ReportsConfigService } from '../../../../../services/reports-config.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { InteractiveReportVehicleLocationLastDetails } from '../../../../../types/api-types';

@Component({
	selector: 'avl300-vehicle-location-last-list',
	templateUrl: './vehicle-location-last-list.component.html',
	styleUrls: ['./vehicle-location-last-list.component.scss'],
})
export class VehicleLocationLastListComponent extends ReportViewerSortableListBaseComponent implements OnInit {
	public listData: InteractiveReportVehicleLocationLastDetails = null;

	constructor(
		public dataService: DataService,
		protected override reportsEventsService: ReportsEventsService,
		protected override reportsConfigService: ReportsConfigService,
		protected override colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(reportsEventsService, reportsConfigService, colorUtilityService, translationService);

		this.defaultTemplateId = this.dataService['defaultTemplateId' as keyof DataService];
	}

	/**
	 * performs initialization tasks for the vehicle location last details list view (AVL300)
	 */
	public ngOnInit(): void {
		this.init();
	}

	/**
	 * sets up the list data
	 *
	 * @param listData - the list data (vehicle location last details data)
	 */
	public updateListView = (listData: InteractiveReportVehicleLocationLastDetails): void => {
		this.listData = listData;
	};
}

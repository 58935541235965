import { DisablePredictionUpdate } from './api-types';

export enum PredictionMode {
	Create = 1,
	Edit,
}

export interface DisablePredictionTableDataRow {
	id: number;
	routeId: string;
	routeText: string;
	routeSubText: string;
	disabledStops: number;
	stopsTotal: number;
	feed: string;
	from: Date;
	to: Date;
	disabledBy: string;
	rowClass: string;
}

export type DisablePredictionTableData = Array<DisablePredictionTableDataRow>;

export interface PredictionRouteDestinationStop {
	id: string;
	name: string;
	selected: boolean;
	style: string;
	code: string;
}

export type PredictionRouteDestinationStops = Array<PredictionRouteDestinationStop>;

export interface PredictionRouteDestination {
	name: string;
	stops: PredictionRouteDestinationStops;
	allStopsSelected: boolean;
}

export type PredictionRouteDestinations = Array<PredictionRouteDestination>;

export interface PredictionSetting {
	checked: boolean;
	key: string;
	text: string;
}

export interface DisablePredictionCloseType {
	saved: boolean;
	disablePredictionUpdate?: DisablePredictionUpdate;
	isDelete?: boolean;
}

export const AllRouteId: string = 'ALL';

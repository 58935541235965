/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { AgenciesAdminModalService } from '../../../features/admin-tools/agencies/services/agencies-admin-modal.service';
import { AgenciesEventsService } from '../services/agencies-events.service';
import { AgenciesDataService } from '../services/agencies-data.service';
import { AgencySelectedUpdate } from '../types/types';
import { SelectedAgency } from '../types/api-types';

@Component({
	selector: 'current-agency',
	templateUrl: './current-agency.component.html',
	styleUrls: ['./current-agency.component.scss'],
})
export class CurrentAgencyComponent implements OnDestroy {
	@Input() titleText: string = 'AGENCY_SELECTION';
	@Input() isDisabled: boolean = true;

	@Output() agencyUpdated: EventEmitter<void> = new EventEmitter<void>();

	public currentAgency: SelectedAgency;

	private agenciesSelectionChange$Subscription: Subscription = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		private agenciesAdminModalService: AgenciesAdminModalService
	) {
		this.setSubscriptions();
		this.init();
	}

	/**
	 * open the agency selector modal
	 */
	public openAgencySelector = async (): Promise<void> => {
		this.agenciesAdminModalService.selectAgency().subscribe(async (agencySelectedUpdate: AgencySelectedUpdate) => {
			if (agencySelectedUpdate.saved) {
				await this.agenciesDataService.setUserSelectedAgency(agencySelectedUpdate.agency.authority_id);

				// get the updated list of selected agencies
				this.currentAgency = this.agenciesDataService.getSelectedAgency();

				this.agencyUpdated.emit();
			}
		});
	};

	/**
	 * get the label name for the view template
	 * @returns the label name
	 */
	public getLabel = (): string => {
		if (this.currentAgency) {
			return this.currentAgency.agency_name;
		} else {
			return 'No Agencies Selected';
		}
	};

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
	}

	/**
	 * handle the initialization for the component
	 */
	private init = (): void => {
		this.currentAgency = this.agenciesDataService.getSelectedAgency();
	};

	/**
	 * set up required subscriptions
	 *
	 * agenciesSelectionChange: so this control itself can refresh when something else changes the agency (i.e loading a map view).
	 * Potentially change to a different publish as this control will also currently publish this event to itself.
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.init();
		});
	};
}

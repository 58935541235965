import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { PredictionsDataService } from '../../../../../../predictions/services/predictions-data.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { StopsDataService } from '../../../../../../../support-features/stops/services/stops-data.service';

import { Route, RouteConfig, Routes, Stop, Stops } from '../../../../../../predictions/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { SliderConfig } from '@cubicNx/libs/utils';
import { StopWithRoutes } from '../../../../../../../support-features/stops/types/api-types';
import { TimeUpdated } from '@cubicNx/libs/utils';

@Component({
	selector: 'terminal-departure-config',
	templateUrl: './terminal-departure-config.component.html',
	styleUrls: ['./terminal-departure-config.component.scss'],
})
export class TerminalDepartureConfigComponent extends TranslateBaseComponent implements OnInit {
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;
	@Input() index: number = null;
	@Input() form: FormGroup;

	@Output() deleteException: EventEmitter<number> = new EventEmitter<number>();
	@Output() updateValidity: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * terminal departure slider configuration
	 */
	public readonly terminalDepartureSliderConfig: SliderConfig = {
		rangeMultiplier: 60,
		padding: 0,
		range: {
			min: 0,
			max: 60,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 10, 20, 30, 40, 50, 60],
			density: 10,
		},
	};

	/**
	 * nav service distance slider configuration
	 */
	public readonly navServiceDistanceSliderCfg: SliderConfig = {
		padding: 0,
		rangeMultiplier: 1,
		step: 100,
		margin: 0,
		range: {
			min: 0,
			max: 5000,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 1000, 2000, 3000, 4000, 5000],
			density: 10,
		},
	};

	/**
	 * arrivals stop radius slider configuration
	 */
	public readonly arrivalStopRadiusSliderCfg: SliderConfig = {
		padding: 0,
		step: 10,
		margin: 0,
		rangeMultiplier: 1,
		range: {
			min: 0,
			max: 300,
		},
		pips: {
			stepped: true,
			mode: 'values',
			values: [0, 50, 100, 150, 200, 250, 300],
			density: 10,
		},
	};

	public sectionCollapsed: boolean = false;
	public arrSettingsEnabled: boolean = false;
	public depSettingsEnabled: boolean = false;
	public loading: boolean = true;
	public stopSequence: Stops = [];

	public routes: Routes = [];
	public stops: Stops = [];

	public maxExtensionValues: Array<number> = [];
	public minLayoverValues: Array<number> = [];
	public minDistanceNavValues: Array<number> = [];
	public defaultDeadheadValues: Array<number> = [];
	public startTimeNavValues: string = null;
	public endTimeNavValues: string = null;
	public callFrequencyNavValues: Array<number> = [];
	public arrivalStopRadiusValues: Array<number> = [];

	public hasCustomTimeError: boolean = false;
	public customTimeErrorText: string = null;
	public customStartTimeErrorText: string = null;
	public customEndTimeErrorText: string = null;

	public overrideArrivalStopRadius: boolean = false;
	public overrideApplySchedLayovers: boolean = false;
	public overrideDetectDepartureAtUpcomingStop: boolean = false;
	public overrideMinLayoverSeconds: boolean = false;
	public overrideMaxDepartureExtension: boolean = false;
	public overrideDetectDepartureStopSequence: boolean = false;
	public overrideDefaultDeadheadSeconds: boolean = false;
	public overrideMinDistNavService: boolean = false;
	public overrideStartEndThrottle: boolean = false;
	public overrideNavServiceCallFrequency: boolean = false;

	private routeId: string = null;
	private stopId: string = null;

	private extensionOverride: boolean = false;

	// Empty route id to signify all routes for selected stop
	private allRoutesOption: Route = {
		id: '',
		rev: null,
		title: this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.ANY_ROUTE'),
		color: null,
		textColor: null,
		hidden: true,
	};

	// Empty stop id to signify all stops for selected route
	private allArrivalStopsOption: Stop = {
		id: 'allArr',
		lat: 0,
		lon: 0,
		name: this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.ALL_STATIONS_ARRIVAL'),
		code: null,
		hidden: true,
		showDestinationSelector: false,
		directions: [],
	};

	private allDepartureStopsOption: Stop = {
		id: 'allDep',
		lat: 0,
		lon: 0,
		name: this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.ALL_STATIONS_DEPARTURE'),
		code: null,
		hidden: true,
		showDestinationSelector: false,
		directions: [],
	};

	constructor(
		private predictionsDataService: PredictionsDataService,
		private stopsDataService: StopsDataService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the terminal departure configuration view
	 */
	public async ngOnInit(): Promise<void> {
		this.depSettingsEnabled = true;
		this.authorityId = this.data['authorityId'];
		this.agencyId = this.data['agencyId'];
		await this.initTranslations(['T_CORE.FORM.ERRORS.TIME_START', 'T_CORE.FORM.ERRORS.TIME_END', 'T_CORE.FORM.ERRORS.TIMERANGE_END']);
		this.initializeSliders();
		this.routeId = this.form.get('route').value;
		this.stopId = this.form.get('stop').value;
		this.startTimeNavValues = this.form.get('start_nav_service_throttle').value;
		this.endTimeNavValues = this.form.get('end_nav_service_throttle').value;
		await this.loadRoutes();
		this.defaultExceptionOverrideCheck();
		this.updateValidity.emit();
		this.updateSettingsBasedOnStopId(this.stopId);
	}

	/**
	 * toggles a section
	 */
	public toggleSection = (): void => {
		this.sectionCollapsed = !this.sectionCollapsed;
	};

	/**
	 * updates the max extension values
	 *
	 * @param values - max entension values
	 */
	public updateMaxExtension = (values: Array<number>): void => {
		this.form.get('max_extension_seconds').setValue(values[0]);
	};

	/**
	 * updates the min layover values
	 *
	 * @param values - min layover values
	 */
	public updateMinLayover = (values: Array<number>): void => {
		this.form.get('min_layover_seconds').setValue(values[0]);
	};

	/**
	 * updates the min distance nav service values
	 *
	 * @param values - min distance nav service values
	 */
	public updateMinDistanceNavService = (values: Array<number>): void => {
		this.form.get('min_dist_nav_service').setValue(values[0]);
	};

	/**
	 * updates the default deadheading values
	 *
	 * @param values - default deadheading values
	 */
	public updateDefaultDeadheading = (values: Array<number>): void => {
		this.form.get('default_deadhead_seconds').setValue(values[0]);
	};

	/**
	 * updates the call frequency nav service values
	 * @param values - call frequency nav service values
	 */
	public updateCallFrequencyNavService = (values: Array<number>): void => {
		this.form.get('nav_service_call_frequency').setValue(values[0]);
	};

	/**
	 * updates the arrival stop radius values
	 *
	 * @param values - arrival stop radius values
	 */
	public updateArrivalStopRadius = (values: Array<number>): void => {
		this.form.get('arrival_stop_radius').setValue(values[0]);
	};

	/**
	 * set the time on the supplied control
	 *
	 * @param timeUpdated - updated time
	 * @param controlName - control name
	 */
	public setUpdatedTime = (timeUpdated: TimeUpdated, controlName: string): void => {
		this.form.controls[controlName].setValue(timeUpdated.time);
	};

	/**
	 * copies default exception overrides from form to members of component
	 */
	public defaultExceptionOverrideCheck = (): void => {
		this.overrideArrivalStopRadius = this.form.value.override_arrival_stop_radius;
		this.overrideApplySchedLayovers = this.form.value.override_apply_sched_layovers;
		this.overrideDetectDepartureAtUpcomingStop = this.form.value.override_detect_departure_at_upcoming_stop;
		this.overrideMinLayoverSeconds = this.form.value.override_min_layover_seconds;
		this.overrideMaxDepartureExtension = this.form.value.override_max_departure_extension;
		this.overrideDetectDepartureStopSequence = this.form.value.override_detect_departure_stop_sequence;
		this.overrideDefaultDeadheadSeconds = this.form.value.override_default_deadhead_seconds;
		this.overrideMinDistNavService = this.form.value.override_min_dist_nav_service;
		this.overrideStartEndThrottle = this.form.value.override_start_end_nav_service_throttle;
		this.overrideNavServiceCallFrequency = this.form.value.override_nav_service_call_frequency;
	};

	/**
	 * retrieves the checked state of the supplied target
	 *
	 * @param target - the target control
	 * @returns true if the target is checked, false otherwise
	 */
	public getCheckedState = (target: EventTarget): boolean => {
		const element: HTMLInputElement = target as HTMLInputElement;

		return element.checked;
	};

	/**
	 * performs an exception override check
	 *
	 * @param value - the value
	 */
	public exceptionOverrideCheck = (value: string): void => {
		switch (value) {
			case 'overrideArrivalStopRadius':
				this.overrideArrivalStopRadius = this.extensionOverride;
				break;
			case 'arrival_stop_radius':
				this.overrideArrivalStopRadius = !this.overrideArrivalStopRadius;
				break;
			case 'overrideApplySchedLayovers':
				this.overrideApplySchedLayovers = this.extensionOverride;
				break;
			case 'apply_sched_layovers':
				this.overrideApplySchedLayovers = !this.overrideApplySchedLayovers;
				break;
			case 'overrideDetectDepartureAtUpcomingStop':
				this.overrideDetectDepartureAtUpcomingStop = this.extensionOverride;
				break;
			case 'detect_departure_at_upcoming_stop':
				this.overrideDetectDepartureAtUpcomingStop = !this.overrideDetectDepartureAtUpcomingStop;
				break;
			case 'overrideMinLayoverSeconds':
				this.overrideMinLayoverSeconds = this.extensionOverride;
				break;
			case 'min_layover_seconds':
				this.overrideMinLayoverSeconds = !this.overrideMinLayoverSeconds;
				break;
			case 'overrideMaxDepartureExtension':
				this.overrideMaxDepartureExtension = this.extensionOverride;
				break;
			case 'max_extension_seconds':
				this.overrideMaxDepartureExtension = !this.overrideMaxDepartureExtension;
				break;
			case 'overrideDetectDepartureStopSequence':
				this.overrideDetectDepartureStopSequence = this.extensionOverride;
				break;
			case 'detect_departure_stop_sequence':
				this.overrideDetectDepartureStopSequence = !this.overrideDetectDepartureStopSequence;
				break;
			case 'overrideDefaultDeadheadSeconds':
				this.overrideDefaultDeadheadSeconds = this.extensionOverride;
				break;
			case 'default_deadhead_seconds':
				this.overrideDefaultDeadheadSeconds = !this.overrideDefaultDeadheadSeconds;
				break;
			case 'overrideMinDistNavService':
				this.overrideMinDistNavService = this.extensionOverride;
				break;
			case 'min_dist_nav_service':
				this.overrideMinDistNavService = !this.overrideMinDistNavService;
				break;
			case 'overrideStartEndThrottle':
				this.overrideStartEndThrottle = this.extensionOverride;
				break;
			case 'override_start_end_throttle':
				this.overrideStartEndThrottle = !this.overrideStartEndThrottle;
				break;
			case 'overrideNavServiceCallFrequency':
				this.overrideNavServiceCallFrequency = this.extensionOverride;
				break;
			case 'nav_service_call_frequency':
				this.overrideNavServiceCallFrequency = !this.overrideNavServiceCallFrequency;
				break;
		}
	};

	/**
	 * sets the time details of the form. Error details if it is invalid
	 *
	 * @param valid - iwhether the time details on the form a valid or not
	 * @param controlName - time control name
	 */
	public setTimeValid = (valid: boolean, controlName: string): void => {
		if (valid) {
			this.form.controls[controlName].setErrors(null);
			this.form.controls[controlName].updateValueAndValidity();
			this.updateValidity.emit();
		} else {
			this.form.controls[controlName].setErrors({ invalid: true });
			this.updateValidity.emit();
		}

		switch (controlName) {
			case 'start_nav_service_throttle':
			case 'end_nav_service_throttle':
				this.checkCustomTimes();
				this.hasCustomTimeError = this.getCustomTimeErrorText();
				break;
		}
	};

	/**
	 * validates cusotm times
	 */
	public checkCustomTimes = (): void => {
		if (
			!this.form.controls.start_nav_service_throttle.hasError('invalid') &&
			!this.form.controls.end_nav_service_throttle.hasError('invalid')
		) {
			const startTime: number = this.form.controls.start_nav_service_throttle.value;
			const endTime: number = this.form.controls.end_nav_service_throttle.value;

			if (startTime.valueOf() >= endTime.valueOf()) {
				this.form.controls.start_nav_service_throttle.setErrors({ 'start-time-greater': true });
				this.updateValidity.emit();
			} else {
				this.form.controls.start_nav_service_throttle.updateValueAndValidity();
				this.updateValidity.emit();
			}
		}
	};

	/**
	 * deletes the terminal exception
	 */
	public deleteThisException = (): void => {
		this.deleteException.emit(this.index);
	};

	/**
	 * updates route options and stops when route is changed
	 *
	 * @param target - the target
	 */
	public routeChanged = async (target: EventTarget): Promise<void> => {
		const element: HTMLInputElement = target as HTMLInputElement;

		this.routeId = element.value;
		if (this.routeId === this.allRoutesOption.id) {
			for (const s of this.stops) {
				if (s.id === this.stopId) {
					this.stops = [s];
					break;
				}
			}
		} else {
			await this.loadStops(true);
			this.validateRouteOptions();
		}

		await this.changeStopSequence(this.stopId, this.routeId);
		this.form.get('detect_departure_stop_sequence').setValue(2);
		this.updateValidity.emit();
	};

	/**
	 * updates the route options when the stop is changed
	 *
	 * @param target - the target
	 */
	public stopChanged = async (target: EventTarget): Promise<void> => {
		const element: HTMLInputElement = target as HTMLInputElement;

		this.stopId = element.value;

		this.updateSettingsBasedOnStopId(this.stopId);

		this.validateRouteOptions();
		this.updateValidity.emit();
		await this.changeStopSequence(this.stopId, this.routeId);
		this.form.get('detect_departure_stop_sequence').setValue(2);
	};

	/**
	 * updates the settings based on the supplied stop id
	 *
	 * @param stopId - stop id
	 */
	public updateSettingsBasedOnStopId = (stopId: string): void => {
		if (stopId.includes('ar') || stopId.includes('allArr')) {
			this.arrSettingsEnabled = true;
			this.depSettingsEnabled = false;

			this.overrideArrivalStopRadius = this.form.value.override_arrival_stop_radius
				? this.form.value.override_arrival_stop_radius
				: false;

			this.overrideApplySchedLayovers = false;
			this.overrideDetectDepartureAtUpcomingStop = false;
			this.overrideMinLayoverSeconds = false;
			this.overrideMaxDepartureExtension = false;
			this.overrideDetectDepartureStopSequence = false;
		} else if (!stopId.includes('ar') || stopId.includes('allDep')) {
			this.arrSettingsEnabled = false;
			this.depSettingsEnabled = true;
			this.overrideArrivalStopRadius = false;

			this.overrideApplySchedLayovers = this.form.value.override_apply_sched_layovers
				? this.form.value.override_apply_sched_layovers
				: false;

			this.overrideDetectDepartureAtUpcomingStop = this.form.value.override_detect_departure_at_upcoming_stop
				? this.form.value.override_detect_departure_at_upcoming_stop
				: false;

			this.overrideMinLayoverSeconds = this.form.value.override_min_layover_seconds
				? this.form.value.override_min_layover_seconds
				: false;

			this.overrideMaxDepartureExtension = this.form.value.override_max_departure_extension
				? this.form.value.override_max_departure_extension
				: false;

			this.overrideDetectDepartureStopSequence = this.form.value.override_detect_departure_stop_sequence
				? this.form.value.override_detect_departure_stop_sequence
				: false;
		}
	};

	/**
	 * Generates a descriptive string for stop based on its id
	 *
	 * @param stop - An object representing the stop
	 * @returns A descriptive string indicating whether the stop is for arrival or departure
	 */
	public getStopDescription(stop: Stop): string {
		if (!stop.id) {
			return stop.name;
		}

		// Determine if the stop is for arrival or departure based on `id`
		if (stop.id.includes('allArr') || stop.id.includes('ar')) {
			return 'Arrival - ' + stop.name;
		} else {
			return 'Departure - ' + stop.name;
		}
	}

	/**
	 * retrieves the route name
	 *
	 * @returns route title
	 */
	public getRouteName = (): string => {
		if (this.routeId === this.allRoutesOption.id) {
			return this.allRoutesOption.title;
		}

		return this.routes?.find((route: Route) => this.routeId === route.id)?.title;
	};

	/**
	 * retrieves the stop name
	 *
	 * @returns stop id
	 */
	public getStopName = (): string => {
		if (this.stopId === this.allArrivalStopsOption.id) {
			return this.allArrivalStopsOption.name;
		} else if (this.stopId === this.allDepartureStopsOption.id) {
			return this.allDepartureStopsOption.name;
		}

		return this.stops?.find((stop: Stop) => this.stopId === stop.id)?.name;
	};

	/**
	 * validates route options
	 */
	private validateRouteOptions = (): void => {
		const allRouteOptionAvailable: boolean = this.routes[this.routes.length - 1].id === this.allRoutesOption.id;

		// Check for all arrival or departure stops option
		const isAllStopsOptionSelected: boolean =
			this.stopId === this.allArrivalStopsOption.id || this.stopId === this.allDepartureStopsOption.id;

		// Add or remove the allRoutesOption based on the selected stop and availability
		if (isAllStopsOptionSelected && allRouteOptionAvailable) {
			this.routes.pop();
		} else if (!isAllStopsOptionSelected && !allRouteOptionAvailable) {
			this.routes.push(this.allRoutesOption);
		}
	};

	/**
	 * changes the stop sequence for the supplied stop id and route id
	 *
	 * @param stopId - stop id
	 * @param routeId - route id
	 */
	private changeStopSequence = async (stopId: string, routeId: string): Promise<void> => {
		this.stopSequence = [];
		if (routeId) {
			const routeConfig: RouteConfig = await this.loadRouteConfig(routeId);

			routeConfig?.stops.forEach((stop: Stop, index: number) => {
				if (stopId === stop.id) {
					for (let i: number = index + 1; i < index + 5; i++) {
						if (routeConfig.stops[i] !== undefined) {
							this.stopSequence.push(routeConfig.stops[i]);
						}
					}
				}
			});
		}
	};

	/**
	 * loads the stops
	 *
	 * @param routeChanged - whether the route has changed or not
	 */
	private loadStops = async (routeChanged: boolean): Promise<void> => {
		if (this.routeId !== this.allRoutesOption.id) {
			this.stops = [];
			this.stopSequence = [];
			const routeConfig: RouteConfig = await this.loadRouteConfig(this.routeId);

			if (routeConfig) {
				// We only want to display the first stop in each direction as an option
				const terminalIds: string[] = [];

				for (const direction of routeConfig.directions) {
					terminalIds.push(direction.stops[0]);
					terminalIds.push(direction.stops[direction.stops.length - 1]);
				}

				for (const stop of routeConfig.stops) {
					if (terminalIds.includes(stop.id)) {
						this.stops.push(stop);
					}
				}

				this.stops.push(this.allArrivalStopsOption);
				this.stops.unshift(this.allDepartureStopsOption);

				// get stop names for stop sequence
				await this.changeStopSequence(this.stopId, this.routeId);
			}

			if (this.stopId === undefined || routeChanged) {
				if (this.stopId !== this.allArrivalStopsOption.id && this.stopId !== this.allDepartureStopsOption.id) {
					this.stopId = this.stops[0].id;
					this.form.get('stop').setValue(this.stopId);
				}
			}
		} else {
			const result: ResultContent = await this.stopsDataService.getStop(this.authorityId, this.agencyId, this.stopId);

			if (result.success) {
				const stop: StopWithRoutes = result.resultData;

				const routeConfig: RouteConfig = await this.loadRouteConfig(stop.route_id);

				if (routeConfig) {
					for (const s of routeConfig.stops) {
						if (s.id === this.stopId) {
							this.stops = [s];
							break;
						}
					}
				}
			}
		}
	};

	/**
	 * loads the routes
	 */
	private loadRoutes = async (): Promise<void> => {
		const result: ResultContent = await this.predictionsDataService.getRoutes(this.authorityId);

		if (result.success) {
			this.routes = result.resultData;

			if (this.routeId === undefined) {
				// The form does not have a route, meaning this is a new configuration
				this.routeId = this.routes[0].id;
				this.form.get('route').setValue(this.routeId);
			}

			this.validateRouteOptions();

			await this.loadStops(false);
		}
	};

	/**
	 * loads the route configuration
	 *
	 * @param routeId - route id
	 * @returns route configuration
	 */
	private loadRouteConfig = async (routeId: string): Promise<RouteConfig> => {
		const response: ResultContent = await this.predictionsDataService.getRouteConfig(this.authorityId, routeId);

		if (response.success) {
			return response.resultData;
		} else {
			return null;
		}
	};

	/**
	 * initialises the sliders
	 */
	private initializeSliders = (): void => {
		this.maxExtensionValues = [this.form.get('max_extension_seconds').value];
		this.minLayoverValues = [this.form.get('min_layover_seconds').value];
		this.minDistanceNavValues = [this.form.get('min_dist_nav_service').value];
		this.defaultDeadheadValues = [this.form.get('default_deadhead_seconds').value];
		this.callFrequencyNavValues = [this.form.get('nav_service_call_frequency').value];
		this.arrivalStopRadiusValues = [this.form.get('arrival_stop_radius').value];
	};

	/**
	 * determines if there is a time error in the form control
	 *
	 * @returns whether ther is a date error in the form or not
	 */
	private getCustomTimeErrorText = (): boolean => {
		let hasDateError: boolean = false;

		this.customTimeErrorText = '';
		this.customStartTimeErrorText = '';
		this.customEndTimeErrorText = '';

		if (!hasDateError) {
			if (this.form.controls.start_nav_service_throttle.hasError('start-time-greater')) {
				hasDateError = true;
				this.customTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIMERANGE_END'];
			}

			if (this.form.controls.start_nav_service_throttle.hasError('invalid')) {
				hasDateError = true;
				this.customStartTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIME_START'];
			}

			if (this.form.controls.end_nav_service_throttle.hasError('invalid')) {
				hasDateError = true;
				this.customEndTimeErrorText = this.translations['T_CORE.FORM.ERRORS.TIME_END'];
			}
		}

		return hasDateError;
	};
}

<div class="clearfix panel-header-container">
	<nav class="nb-panel-header navbar col-lg-12 col-md-12 col-sm-12 col-xs-12 panel-header" role="navigation">
		<div class="navbar-header col-lg-6 col-md-6 col-sm-6 col-xs-6 panel-header-inner">
			<button
				[hidden]="!showBackButton"
				class="btn btn-link-dark navbar-btn navbar-left"
				onclick="window.history.back()"
				aria-label="Back Button"
				data-test="header.back.button">
				<i class="nb-icons nb-backarrow"></i>
			</button>
			<h2 class="navbar-left nb-heading">
				{{ heading }}
			</h2>
		</div>
		<div class="nb-panel-header-actions navbar-right panel-header-actions" id="nb-panel-header-actions-container"></div>
	</nav>
</div>

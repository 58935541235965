import { Component, OnInit, VERSION } from '@angular/core';

import { ConfigService } from '@cubicNx/libs/utils';
import { LoggerService } from '@cubicNx/libs/utils';
import { RoutingHandlerService } from './routing/services/routing-handler.service';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public initialized: boolean = false;

	constructor(
		private configService: ConfigService,
		private loggerService: LoggerService,
		private routingHandlerService: RoutingHandlerService,
		private translationService: TranslationService
	) {}

	/**
	 * performs initialization tasks for the application compoenent
	 *
	 * initializes the various services within the application
	 */
	public async ngOnInit(): Promise<void> {
		// init the translation service
		this.translationService.init();

		await this.configService.init();

		// Logger service is dependant upon config service, so must be initialised after
		// configuration has been read/loaded
		this.loggerService.init();

		this.loggerService.logInfo('Angular Version: ', VERSION.full);

		this.routingHandlerService.init();

		this.initialized = true;
	}
}

/**
 * Class for storing and retrieving local storage data for various parts of the agency portal.
 * this is used to store parts of a class using local storage to restore ui elements to the same state they were in
 * when the user navigated away from it.a
 */

import { Injectable } from '@angular/core';
import { StorageService } from '@cubicNx/libs/utils';

import { StorageType } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class StateService {
	private readonly stateCacheKey: string = 'app-state-persist-across-sessions-';

	private _states: any = {};
	private _userName: string = '';
	private _mapTables: any = {};

	constructor(private storageService: StorageService) {}

	/**
	 * Loads stored localstorage data into the service for both session and non session based items
	 *
	 * @param userName - current user's username
	 */
	public init = (userName: string): void => {
		this._userName = userName;
		if (this._userName !== null && this._userName.length > 0) {
			const mapTablesState: string = this.storageService.get(this.stateCacheKey + this._userName, StorageType.local);

			if (mapTablesState) {
				this._mapTables = mapTablesState;
			}
		}
	};

	/**
	 * loads existing data from the -persist-across-sessions
	 * and then appends any new data passed in through the name, scope, and fields array
	 * those properties are passed in an array of strings which are the key values
	 * and then saves the data with the updated properties
	 *
	 * @param name - the name of the property
	 * @param scope - will be an object with various properties we want to save
	 * @param fields - is an array of strings listing what properties we want to save from the scope object
	 */
	public mapPersistAcrossSessions = (name: string, scope: any, fields: string[]): void => {
		const mapTablesState: string = this.storageService.get(this.stateCacheKey + this._userName, StorageType.local);

		if (mapTablesState) {
			this._mapTables = mapTablesState;
		} else {
			this._mapTables = {};
		}

		fields.forEach((item) => {
			if (typeof this._mapTables[name] === 'undefined') {
				this._mapTables[name] = {};
			}

			this._mapTables[name][item] = scope[item];
		});
		if (this._userName === null || this._userName.length === 0) {
			return;
		}

		this.storageService.set(this.stateCacheKey + this._userName, this._mapTables, StorageType.local);
	};

	/**
	 * loads existing data from the -persist-across-sessions
	 * and then appends it into the scope object
	 * and returns it
	 *
	 * @param name - the name of the property
	 * @param scope - will be an object with various properties we want to save
	 * @param fields - is an array of strings listing what properties we want to save from the scope object
	 * @returns scope
	 */
	public mapLoadAcrossSessions = (name: string, scope: any, fields: string[]): any => {
		const mapTablesState: string = this.storageService.get(this.stateCacheKey + this._userName, StorageType.local);

		if (mapTablesState) {
			this._mapTables = mapTablesState;
		} else {
			this._mapTables = {};
		}

		if (!this._mapTables[name]) {
			return scope;
		}

		fields.forEach((item) => {
			if (typeof this._mapTables[name][item] !== 'undefined') {
				scope[item] = this._mapTables[name][item];
			}
		});

		return scope;
	};

	/**
	 * gets data from the local state object
	 * and specified by the name string
	 * and returns it
	 *
	 *  e.g.  return state[name];
	 *
	 * @param name - the name of the property
	 * @returns value stored in the state under the name property
	 */
	public state = (name: string): string => {
		return this._states[name];
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AuthoritiesAdminDataService } from '../services/authorities-admin-data.service';
import { CurrentUserPermissionService } from '../../../../support-features/login/services/current-user/current-user-permission.service';

import { Authority, AuthoritySettings } from '../types/api-types';
import { BreadcrumbItems } from '../../../../utils/components/breadcrumbs/types/types';
import { ResultContent, ViewPanelSource } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'authorities-admin-view',
	templateUrl: './authorities-admin-view.component.html',
	styleUrls: ['./authorities-admin-view.component.scss'],
})
export class AuthoritiesAdminViewComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public loading: boolean = true;
	public isAdmin: boolean = false;
	public authority: Authority = null;
	public adminSettings: AuthoritySettings = null;
	public detailsLoaded: boolean = false;
	public adminSettingsLoaded: boolean = false;
	public detailViewTableOptions: ViewPanelSource;
	public settingsViewTableOptions: ViewPanelSource;
	public breadcrumbItems: BreadcrumbItems = [];

	routeParams$Subscription: Subscription = null;
	private authorityId: string;

	constructor(
		private currentUserPermissionService: CurrentUserPermissionService,
		private authoritiesAdminDataService: AuthoritiesAdminDataService,
		private route: ActivatedRoute,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the authorities admin view such as loading translations, initialisng breadcrumbs and
	 * other set-up functionality
	 */
	public async ngOnInit(): Promise<void> {
		await this.initTranslations([
			'T_CORE.ADMIN_TOOLS',
			'T_AUTHORITY.AUTHORITIES',
			'T_AUTHORITY.AUTHORITY_DETAILS',
			'T_AUTHORITY.AUTHORITY_STATUS.1',
			'T_AUTHORITY.AUTHORITY_STATUS.2',
			'T_AUTHORITY.AUTHORITY_STATUS.3',
		]);

		this.initBreadcrumbs();

		this.setup();

		// get params off the url
		this.routeParams$Subscription = this.route.params.subscribe(async (params) => {
			this.authorityId = params['authorityId'];
			await this.loadData();

			this.loading = false;
		});
	}

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.routeParams$Subscription?.unsubscribe();
	}

	/**
	 * initialises the authority admin view breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({
			displayText: this.translations['T_CORE.ADMIN_TOOLS'],
			targetPath: '/admintools',
			activePage: false,
		});

		this.breadcrumbItems.push({
			displayText: this.translations['T_AUTHORITY.AUTHORITIES'],
			targetPath: '/admintools/authorities',
			activePage: false,
		});

		this.breadcrumbItems.push({
			displayText: this.translations['T_AUTHORITY.AUTHORITY_DETAILS'],
			activePage: true,
		});
	};

	/**
	 * Setup the component with config.
	 */
	private setup = (): void => {
		this.isAdmin = this.currentUserPermissionService.isNextbusAdmin();

		this.detailViewTableOptions = {
			title: 'T_AUTHORITY.AUTHORITY_DETAILS',
			rowDefs: [
				{
					displayName: 'T_AUTHORITY.AUTHORITY_NAME',
					type: 'name',
					name: 'authority_name',
				},
				{
					displayName: 'T_AUTHORITY.STATUS',
					name: 'authority_status_id',
					convert: (value): string => this.translations['T_AUTHORITY.AUTHORITY_STATUS.' + value],
				},
				{
					displayName: 'T_AUTHORITY.TYPE',
					name: 'authority_type',
				},
				{
					displayName: 'T_AUTHORITY.AUTHORITY_ID',
					name: 'authority_id',
					hidden: false,
				},
				{
					displayName: 'T_AUTHORITY.DATABASE_SHARD',
					name: 'database_shard',
					hidden: false,
				},
				{
					displayName: 'T_AUTHORITY.CITY',
					name: 'city',
					hidden: false,
				},
				{
					displayName: 'T_AUTHORITY.TWO_WAY_MESSAGING',
					name: 'two_way_messaging',
					hidden: false,
				},
				{
					displayName: 'T_AUTHORITY.AUTHORITY_THUMBNAIL_IMAGE',
					type: 'image',
					name: 'thumbnail_image_id',
					hidden: false,
				},
				{
					displayName: 'T_AUTHORITY.AUTHORITY_LOGO_IMAGE',
					type: 'image',
					name: 'image_logo_id',
					hidden: false,
				},
				{
					displayName: 'T_AUTHORITY.AUTHORITY_WEBSITE_URL',
					name: 'website_url',
					hidden: false,
				},
			],
		};

		if (this.isAdmin) {
			this.settingsViewTableOptions = {
				title: 'T_AUTHORITY.ADMIN_SETTINGS',
				rowDefs: [
					{
						displayName: 'T_AUTHORITY.MER_SER',
						name: 'METRICS_SERVICE_INSTANCE',
					},
					{
						displayName: 'T_AUTHORITY.EV_SRV',
						name: 'EVENTS_SERVICE_INSTANCE',
					},
					{
						displayName: 'T_AUTHORITY.ENRICHMENT_MEMORY',
						name: 'ENRICHMENT_MEMORY',
						type: 'number',
					},
					{
						displayName: 'T_AUTHORITY.ENC_SRC',
						name: 'ENRICHMENT_INPUT_SOURCE',
					},
					{
						displayName: 'T_AUTHORITY.AUTH_M',
						name: 'AUTHORITY_CRON_MESSAGES',
					},
					{
						displayName: 'T_AUTHORITY.AUTH_CRON',
						name: 'AUTHORITY_CRON_TASKS',
					},
					{
						displayName: 'T_AUTHORITY.VEH_STATE',
						name: 'VEHICLE_STATE_LIFETIME_DAYS',
						type: 'number',
					},
					{
						displayName: 'T_AUTHORITY.PRD_LIFE',
						name: 'prediction_save_limit_seconds',
						type: 'number',
					},
					{
						displayName: 'T_AUTHORITY.HIST',
						name: 'use_historical_travel_times',
						type: 'boolean',
					},
					{
						displayName: 'T_AUTHORITY.PRED',
						name: 'is_prediction_enabled',
						type: 'boolean',
					},
					{
						displayName: 'T_AUTHORITY.NB_VERSION',
						name: 'NB_VERSION',
					},
				],
			};
		}
	};

	/**
	 * Loads component data; authority, and authority settings.
	 */
	private loadData = async (): Promise<void> => {
		this.loading = true;

		const authorityResponse: ResultContent = await this.authoritiesAdminDataService.getAuthority(this.authorityId);

		if (authorityResponse.success) {
			this.authority = authorityResponse.resultData;

			this.detailsLoaded = true;

			if (this.isAdmin) {
				const authoritySettingsResponse: ResultContent = await this.authoritiesAdminDataService.getAuthoritySettings(
					this.authorityId
				);

				if (authoritySettingsResponse.success) {
					if (authoritySettingsResponse.resultData.settings) {
						this.adminSettings = authoritySettingsResponse.resultData;
					}

					this.adminSettingsLoaded = true;
				}
			}
		}

		this.loading = false;
	};
}

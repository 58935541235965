/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../services/agencies-data.service';

import { Agencies, Agency, SelectedAgency } from '../../types/api-types';
import { AgencySelectedUpdate } from '../../types/types';

@Component({
	selector: 'agency-selector',
	templateUrl: './agency-selector.component.html',
	styleUrls: ['./agency-selector.component.scss'],
})
export class AgencySelectorComponent extends TranslateBaseComponent implements OnInit {
	public availableAgencies: Agencies = [];

	public newAgencySelected: boolean = false;
	public selectedAgencyId: number = null;

	private agencies: Agencies = [];
	private currentAgencyId: number = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private modalRef: MatDialogRef<AgencySelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.cancel();
	}

	/**
	 * handle initialization for the agency selector component
	 *
	 * load available agencies in to the list
	 */
	public async ngOnInit(): Promise<void> {
		this.agencies = this.agenciesDataService.getAgencies();

		this.agencies.forEach((agency) => {
			if (agency.agency_nb_id && agency.agency_name) {
				this.availableAgencies.push(agency);
			}
		});

		// determine the current agency and set selected
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.currentAgencyId = selectedAgency.agency_nb_id;

		// set the current agency in in the control
		this.selectedAgencyId = this.currentAgencyId;
	}

	/**
	 * hamdle the user changing an agency
	 */
	public agencyChanged = (): void => {
		this.newAgencySelected = this.selectedAgencyId !== this.currentAgencyId;
	};

	/**
	 * save the selected agency and close the modal
	 */
	public save = (): void => {
		const agency: Agency = this.agenciesDataService.getAgencyByAgencyNbId(this.selectedAgencyId);

		const agencySelectedUpdate: AgencySelectedUpdate = { saved: true, agency };

		this.modalRef.close(agencySelectedUpdate);
	};

	/**
	 * cancel the agency change and close the modal
	 */
	public cancel = (): void => {
		const agencySelectedUpdate: AgencySelectedUpdate = { saved: false };

		this.modalRef.close(agencySelectedUpdate);
	};
}

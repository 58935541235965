<div *ngIf="initialized">
	<form *ngIf="setPasswordForm" [formGroup]="setPasswordForm">
		<div
			class="form-group"
			[ngClass]="{ 'has-error': setPasswordForm.controls.password.touched && setPasswordForm.controls.password.errors }">
			<field-label *ngIf="showFieldLabels" [name]="'firstNewPW'" [label]="getTranslation(passwordTitle)" [required]="true"></field-label>
			<input
				formControlName="password"
				type="password"
				data-test="password.new"
				class="form-control"
				title="{{ getTranslation(passwordTitle) }}"
				placeholder="{{ getTranslation(passwordTitle) }}"
				autocomplete="off"
				(input)="validatePassword()" />
			<input-validation [control]="setPasswordForm.controls.password" [errorOnTouch]="true"> </input-validation>
		</div>
		<div
			class="form-group"
			[ngClass]="{ 'has-error': setPasswordForm.controls.passwordConfirm.touched && setPasswordForm.controls.passwordConfirm.errors }">
			<field-label
				*ngIf="showFieldLabels"
				[name]="'secondNewPW'"
				[label]="'T_USER.RE_ENTER_NEW_PASSWORD' | translate"
				[required]="true"></field-label>
			<input
				formControlName="passwordConfirm"
				type="password"
				data-test="password.confirm"
				class="form-control"
				title="{{ 'T_USER.PASSWORD_AGAIN' | translate }}"
				placeholder="{{ 'T_USER.PASSWORD_AGAIN' | translate }}"
				autocomplete="off"
				(input)="validatePasswordConfirm()" />
			<input-validation [control]="setPasswordForm.controls.passwordConfirm" [errorOnTouch]="true"> </input-validation>
		</div>
	</form>
</div>

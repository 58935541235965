/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { DataTableComponent } from '@cubicNx/libs/utils';
import { ObjectHelpers } from '@cubicNx/libs/utils';

import { ReportsStateService } from '../../../services/reports-state.service';
import { StateService } from '@cubicNx/libs/utils';
import { ReportsDataService } from '../../../services/reports-data.service';
import { ReportsUtilService } from '../../../services/reports-util.service';
import { ReportsConfigService } from '../../../services/reports-config.service';
import { ReportsModalService } from '../../../services/reports-modal.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';

import { Agencies } from '../../../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

import { ColumnType, Columns, ListSortings, PageRequestInfo, SelectedRowData, SortDirection } from '@cubicNx/libs/utils';

import {
	ActionAllowedType,
	AvailableReportsList,
	AvailableReportsListItem,
	ReportConfiguration,
	ReportFiltersUpdate,
	ReportSaveType,
	SelectedReportsFilter,
} from '../../../types/types';

import {
	AvailableReportPaginatedResponse,
	AvailableReportSummaryDetail,
	ReportFiltersRequest,
	ReportsPaginatedParams,
} from '../../../types/api-types';

import moment from 'moment';

@Component({
	selector: 'available-reports-list',
	templateUrl: './available-reports-list.component.html',
	styleUrls: ['./available-reports-list.component.scss'],
})
export class AvailableReportsListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild(DataTableComponent) dataList: DataTableComponent;

	@Output() setLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

	// Pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'dateCreated', dir: SortDirection.desc }];

	public initialized: boolean = false;
	public listName: string = 'available-reports-list';
	public columns: Columns = [];
	public reportsList: AvailableReportsList = [];
	public listLoadingIndicator: boolean = true;
	public silentReload: boolean = false;
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public reportSelection: AvailableReportsList = [];
	public hasFilters: boolean = false;
	public columnSizes: Map<string, number>;
	public viewDetailsButtonEnabled: boolean = false;
	public viewDetailsButtonTooltip: string = '';
	public viewButtonEnabled: boolean = false;
	public viewButtonTooltip: string = '';
	public downloadButtonEnabled: boolean = false;
	public downloadButtonTooltip: string = '';
	public editButtonEnabled: boolean = false;
	public editButtonTooltip: string = '';
	public deleteButtonEnabled: boolean = false;
	public deleteButtonTooltip: string = '';
	public searchText: string = '';
	public agencies: Agencies = [];

	private readonly reportDetails: string = '/reports/available';
	private readonly reportInteractiveView: string = '/reports/viewer';

	private readonly interactive: string = 'interactive';
	private readonly finished: string = 'finished';
	private readonly failed: string = 'failed';
	private readonly running: string = 'running';

	private readonly normalPollInterval: number = 60000;
	private readonly quickPollInterval: number = 3000;
	private readonly maxNumberOfQuickPollAttempts: number = 6;
	private readonly finishedStateClass: string = 'report-finished';
	private readonly runningStateClass: string = 'report-running';

	private pollTimer: any = null;
	private pollInterval: number = this.normalPollInterval;
	private quickPollAttempt: number = 0;
	private isInitialRequest: boolean = true;
	private selectedReportsFilter: SelectedReportsFilter = { agencies: [] };
	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo', 'filter'];

	constructor(
		private agenciesDataService: AgenciesDataService,
		private stateService: StateService,
		private reportsDataService: ReportsDataService,
		private reportsUtilService: ReportsUtilService,
		private reportsConfigService: ReportsConfigService,
		private reportsModalService: ReportsModalService,
		private reportsStateService: ReportsStateService,
		private logger: LoggerService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the available reports list compoenent
	 *
	 * loads translations, retrieves agencies and builds the list columns
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.agencies = this.agenciesDataService.getAgencies();

		this.loadCache();
		this.buildListColumns();

		this.initialized = true;
	}

	/**
	 * makes the list inactive and stops the list poll timer
	 */
	public inactive = (): void => {
		clearTimeout(this.pollTimer);
	};

	/**
	 * refreshes the availavle reports list and restarts polling
	 */
	public refresh = async (): Promise<void> => {
		clearTimeout(this.pollTimer);

		if (!this.isInitialRequest) {
			await this.getAvailableReports(true);

			this.initPollTimer();
		}
	};

	/**
	 * updates the contents of the available reports list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		this.cachePageInfo(this.pageInfo);

		await this.getAvailableReports(true);

		if (this.isInitialRequest) {
			this.initPollTimer();

			this.isInitialRequest = false;
		}
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;

		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intiating a search
		this.pageInfo.pageNum = 1;
		this.cacheSearch(this.searchText);

		await this.getAvailableReports(true);
	};

	/**
	 * remembers which reports from the list have been selected and derives the action button states and tooltips accordingly
	 *
	 * @param reports - the selected available reports via checkboxes
	 */
	public onCheckSelect = (reports: AvailableReportsList): void => {
		this.reportSelection = reports;

		this.determineButtonStates();
	};

	/**
	 * dependent upon the state of the selected report, either navigates to the interactive report itself
	 * (if report was an interactive report and was successfully created)
	 * or navigates to the report details if it was anything else
	 *
	 * @param selectedRow - the selected report from the list
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const selectedReport: AvailableReportsListItem = selectedRow.row;

		const reportConfigAvailable: ReportConfiguration = this.reportsConfigService.getReportConfiguration(
			selectedReport.defaultTemplateId
		);

		if (selectedReport.rawStatus !== this.running && reportConfigAvailable) {
			if (selectedReport.rawOutputType === this.interactive && selectedReport.rawStatus === this.finished) {
				this.router.navigate([
					this.reportInteractiveView,
					selectedReport.authorityId,
					selectedReport.agencyId,
					selectedReport.defaultTemplateId,
					selectedReport.createdById,
					selectedReport.reportId,
				]);
			} else {
				this.router.navigate([
					this.reportDetails,
					selectedReport.authorityId,
					selectedReport.agencyId,
					selectedReport.defaultTemplateId,
					selectedReport.createdById,
					selectedReport.reportId,
				]);
			}
		}
	};

	/**
	 * navigates to the selected report view
	 */
	public viewReportDetails = (): void => {
		// There can only be one selected for viewing so use the first entry
		const selectedReport: AvailableReportsListItem = this.reportSelection[0];

		this.router.navigate([
			this.reportDetails,
			selectedReport.authorityId,
			selectedReport.agencyId,
			selectedReport.defaultTemplateId,
			selectedReport.createdById,
			selectedReport.reportId,
		]);
	};

	/**
	 * navigates to the selected report view
	 */
	public viewReport = (): void => {
		// There can only be one selected for viewing so use the first entry
		const selectedReport: AvailableReportsListItem = this.reportSelection[0];

		this.router.navigate([
			this.reportInteractiveView,
			selectedReport.authorityId,
			selectedReport.agencyId,
			selectedReport.defaultTemplateId,
			selectedReport.createdById,
			selectedReport.reportId,
		]);
	};

	/**
	 * downloads the selected report from the list
	 */
	public downloadReport = async (): Promise<void> => {
		// There can only be one selected for viewing so use the first entry
		const selectedReport: AvailableReportsListItem = this.reportSelection[0];

		this.listLoadingIndicator = true;

		await this.reportsDataService.downloadAvailableReport(
			selectedReport.authorityId,
			selectedReport.agencyId,
			selectedReport.apiEndpoint,
			selectedReport.createdById.toString(),
			selectedReport.reportId,
			selectedReport.code,
			moment(selectedReport.dateCreated).format('YYYY-MM-DD')
		);

		this.listLoadingIndicator = false;
	};

	/**
	 * retrieves the selected report for editing purposes, renders the edit report dialog
	 */
	public editReport = async (): Promise<void> => {
		// There can only be one selected for viewing so use the first entry
		const selectedReport: AvailableReportsListItem = this.reportSelection[0];

		this.setLoading.emit(true);

		const response: ResultContent = await this.reportsDataService.getReportTemplateDetail(
			selectedReport.authorityId,
			selectedReport.agencyId,
			selectedReport.reportId,
			selectedReport.createdById,
			selectedReport.defaultTemplateId
		);

		this.setLoading.emit(false);

		if (response.success) {
			this.reportsModalService.editReport(response.resultData).subscribe((reportSaveType: ReportSaveType) => {
				switch (reportSaveType) {
					case ReportSaveType.run:
					case ReportSaveType.saveAndRun:
						this.refresh();
						break;
					default:
						// Not interested in save or cancel from this component (tab)
						break;
				}
			});
		}
	};

	/**
	 * renders the delete reports dialog passing in the selected reports
	 */
	public deleteReports = (): void => {
		this.reportsModalService.deleteReports(this.reportSelection).subscribe((deleted: boolean) => {
			if (deleted) {
				this.refresh();
			}
		});
	};

	/**
	 * renders the filter dialog
	 *
	 * when user saves the filter, the filter is applied (list refreshed accordingly) if save was the action
	 */
	public openFilter = (): void => {
		this.reportsModalService
			.openAvailableReportsListFilter(this.selectedReportsFilter)
			.subscribe((reportFiltersUpdate: ReportFiltersUpdate) => {
				if (reportFiltersUpdate.saved) {
					// Has the updated filter changed?
					if (!ObjectHelpers.deepEquals(reportFiltersUpdate.selectedReportsFilter, this.selectedReportsFilter)) {
						this.applyUpdatedFilter(reportFiltersUpdate.selectedReportsFilter);
					}
				}
			});
	};

	/**
	 * clears the filter criterai and applies the cleared filter to refresh the list
	 */
	public clearFilter = (): void => {
		const filter: SelectedReportsFilter = {
			agencies: [],
		};

		this.applyUpdatedFilter(filter);
	};

	/**
	 * general clean up activities when the component is destroyed
	 *
	 * stop polling
	 */
	public ngOnDestroy(): void {
		clearTimeout(this.pollTimer);
	}

	/**
	 * starts list polling
	 *
	 * frequency is dependant upon current list operation.
	 * ie if a report is being generated then a more aggresive polling style is deployed, otherwise normal 9slower) polling is started
	 */
	private initPollTimer = (): void => {
		this.determinePollInterval();

		this.startPollTimer();
	};

	/**
	 * start polling the list
	 */
	private startPollTimer = (): void => {
		this.pollTimer = setTimeout(() => {
			this.pollData();
		}, this.pollInterval);
	};

	/**
	 * poll that retrieves a refreshed view of the list data
	 */
	private pollData = async (): Promise<void> => {
		await this.getAvailableReports(false);

		this.initPollTimer();
	};

	/**
	 * determine the poll interval
	 *
	 * it is more aggresive if a report is currently being generated
	 */
	private determinePollInterval = (): void => {
		this.pollInterval = this.normalPollInterval;

		let listHasPendingReports: boolean = false;

		// Check for reports that are not finished and begin polling to update their status;
		this.reportsList?.forEach((report: AvailableReportsListItem) => {
			if (!report.rawStatus.includes(this.finished) && !report.rawStatus.includes(this.failed)) {
				listHasPendingReports = true;
			}
		});

		if (listHasPendingReports) {
			this.quickPollAttempt++;

			if (this.quickPollAttempt < this.maxNumberOfQuickPollAttempts) {
				this.pollInterval = this.quickPollInterval;
			}
		} else {
			this.quickPollAttempt = 0;
		}
	};

	/**
	 * load list related translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_REPORT.CAT',
			'T_REPORT.CODE',
			'T_REPORT.NAME',
			'T_REPORT.BLOCK_STATUS',
			'T_REPORT.OUTPUT_TYPE',
			'T_REPORT.SHARING',
			'T_CORE.AGENCY',
			'T_REPORT.DT_CREATED',
			'T_REPORT.NO_ROW_SELECTED',
			'T_REPORT.TOO_MANY_ROWS_SELECTED',
			'T_REPORT.VIEW_FINISHED_OR_FAILED_ONLY',
			'T_REPORT.VIEW_INTERACTIVE_ONLY',
			'T_REPORT.DOWNLOAD_CSV_ONLY',
			'T_REPORT.EDIT_INTERACTIVE_ONLY',
			'T_REPORT.FEATURE_DISABLED',
			'T_REPORT.CANT_DELETE_PENDING',
			'T_CORE.NEED_EDIT_PERMISSION',
			'T_CORE.NEED_DEL_PERMISSION',
		]);
	};

	/**
	 * retrieves a list dependant upon the updated filter criteria
	 *
	 * @param updatedFilter - updated search filter
	 */
	private applyUpdatedFilter = async (updatedFilter: SelectedReportsFilter): Promise<void> => {
		// We can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intigating a filter
		this.pageInfo.pageNum = 1;

		// Store updated selected user filters
		this.selectedReportsFilter = updatedFilter;

		this.cacheFilter(this.selectedReportsFilter);

		this.hasFilters = this.hasActiveFilters(this.selectedReportsFilter);

		await this.getAvailableReports(true);
	};

	/**
	 * determines if there ia a current filter applied to the list
	 *
	 * @param selectedReportsFilter - filter criteria
	 * @returns true if there is a filter currently applied to the list, false otherwise
	 */
	private hasActiveFilters = (selectedReportsFilter: SelectedReportsFilter): boolean => {
		let hasFilters: boolean = false;

		if (selectedReportsFilter) {
			if (selectedReportsFilter.agencies.length > 0) {
				hasFilters = true;
			}
		}

		return hasFilters;
	};

	/**
	 * retrieves last used search and page information for the list
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}

		if (cacheContainer.filter) {
			this.selectedReportsFilter = cacheContainer['filter'];
			this.hasFilters = this.hasActiveFilters(this.selectedReportsFilter);
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current searhed for text
	 *
	 * @param search - the current searched for text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current filter criteria
	 *
	 * @param filter - the current search filter criteria
	 */
	private cacheFilter = (filter: SelectedReportsFilter): void => {
		this.listCacheContainer['filter'] = filter;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * creates a report filter request based upon the content of the current filter criteria
	 *
	 * @param selectedReportsFilter - current applied filter criteria
	 * @returns report filters request
	 */
	private mapReportsFilters = (selectedReportsFilter: SelectedReportsFilter): ReportFiltersRequest => {
		// Map our selected user filter to the simplified format for the outgoing data request
		const filters: ReportFiltersRequest = {
			agencies: [],
		};

		if (this.selectedReportsFilter?.agencies) {
			filters.agencies = selectedReportsFilter.agencies.map((agency) => ({
				agency_info_id: agency.nb_id,
				agency_id: agency.agency_id,
				authority_id: agency.authority_id,
			}));
		}

		return filters;
	};

	/**
	 * creates the API equivalent name for the list columns when sorting is required
	 *
	 * @param sortByValue - sort by value
	 * @returns equivalent column name for API purposes
	 */
	private mapColumnName(sortByValue: string): string {
		let columnName: string = null;

		switch (sortByValue) {
			case 'code':
			case 'name':
			case 'status':
			case 'sharing':
				columnName = sortByValue;
				break;
			case 'outputType':
				columnName = 'output_type';
				break;
			case 'agency':
				columnName = 'agency_name';
				break;
			case 'dateCreated':
				columnName = 'created_at';
				break;
			case 'category':
				columnName = 'default_template_id';
				break;
			default:
				columnName = 'unknown';
		}

		return columnName;
	}

	/**
	 * builds the available reports list columns
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: 'category',
				displayName: this.translations['T_REPORT.CAT'],
				columnType: ColumnType.text,
				width: 120,
			},
			{
				name: 'code',
				displayName: this.translations['T_REPORT.CODE'],
				columnType: ColumnType.text,
				width: 80,
			},
			{
				name: 'name',
				displayName: this.translations['T_REPORT.NAME'],
				columnType: ColumnType.text,
			},
			{
				name: 'status',
				displayName: this.translations['T_REPORT.BLOCK_STATUS'],
				columnType: ColumnType.text,
				width: 120,
			},
			{
				name: 'outputType',
				displayName: this.translations['T_REPORT.OUTPUT_TYPE'],
				columnType: ColumnType.text,
				width: 120,
			},
			{
				name: 'sharing',
				displayName: this.translations['T_REPORT.SHARING'],
				columnType: ColumnType.text,
				width: 120,
			},
			{
				name: 'agency',
				displayName: this.translations['T_CORE.AGENCY'],
				columnType: ColumnType.text,
				width: 180,
			},
			{
				name: 'dateCreated',
				displayName: this.translations['T_REPORT.DT_CREATED'],
				columnType: ColumnType.date,
				format: 'MMM d, YYYY h:mm a',
			},
		];
	};

	/**
	 * gets translated sharing text value
	 *
	 * @param sharing - sharing text
	 * @returns translated sharing text
	 */
	private getSharingType = (sharing: string): string => {
		return this.getTranslation('T_REPORT.' + sharing.toUpperCase());
	};

	/**
	 * gets translated output type text value
	 *
	 * @param outputType - output type text
	 * @returns translated output type text
	 */
	private getOutputType = (outputType: string): string => {
		return this.getTranslation('T_REPORT.' + outputType.toUpperCase());
	};

	/**
	 * gets translated report category text value
	 *
	 * @param defaultTemplateId - default template id
	 * @returns translated report category text
	 */
	private getCategory = (defaultTemplateId: string): string => {
		const reportConfig: ReportConfiguration = this.reportsConfigService.getReportConfiguration(defaultTemplateId);

		// report config should always be present but handles edge case where it can be removed but user still has an entry in the list
		// also occurred when a new report was created in a test branch. Report list wouldn't render to delete it once brance was reverted
		if (reportConfig) {
			return this.getTranslation('T_REPORT.' + reportConfig.category.toUpperCase());
		} else {
			return this.getTranslation('T_REPORT.UNKNOWN_REPORT'.toUpperCase());
		}
	};

	/**
	 * determines the presentation class that reflects how the row 9report in the list0 is rendered
	 *
	 * @param status - status of the report
	 * @returns presentation class reflecting the status of the report
	 */
	private setRowClass = (status: string): string => {
		return status === this.finished || status.includes(this.failed) ? this.finishedStateClass : this.runningStateClass;
	};

	/**
	 * retrieves list of reports that match the current page information and search text, filter and populates the list accordingly
	 *
	 * note that if the clear list parameter is set to false, that the list will load silently
	 *
	 * @param clearList - if false forces the list to be loaded silently
	 */
	private getAvailableReports = async (clearList: boolean): Promise<void> => {
		this.listLoadingIndicator = true;

		// silently reload when clear list is false
		if (clearList) {
			this.totalRows = 0;
			this.silentReload = false;
		} else {
			this.silentReload = true;
		}

		const paginatedParams: ReportsPaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			sort: this.mapColumnName(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
			filters: this.mapReportsFilters(this.selectedReportsFilter),
		};

		const response: ResultContent = await this.reportsDataService.getAvailableReports(paginatedParams);

		if (response.success) {
			const resultData: AvailableReportPaginatedResponse = response.resultData;

			if (resultData?.results) {
				// Object for our list
				this.reportsList = response.resultData.results.map((availableReport: AvailableReportSummaryDetail) => ({
					id: availableReport.nb_id,
					category: this.getCategory(availableReport.default_template_id),
					code: availableReport.code,
					name: availableReport.name,
					rawStatus: availableReport.status.toLowerCase(),
					status: this.reportsUtilService.getStatusText(availableReport.status),
					rawOutputType: availableReport.output_type.toLowerCase(),
					outputType: this.getOutputType(availableReport.output_type),
					sharing: this.getSharingType(availableReport.sharing),
					agency: availableReport.agency_name,
					dateCreated: availableReport.created_at,
					authorityId: availableReport.authority_id,
					agencyId: availableReport.agency_id,
					createdById: availableReport.user_id,
					reportId: availableReport.report_id,
					defaultTemplateId: availableReport.default_template_id,
					apiEndpoint: availableReport.api_endpoint,
					rowClass: this.setRowClass(availableReport.status.toLowerCase()),
				}));

				// Update the properties responsible for the buttons being enabled and tooltips
				this.determineButtonStates();

				this.totalRows = resultData.total;
			}
		}

		this.listLoadingIndicator = false;

		this.changeDetectorRef.detectChanges();
	};

	/**
	 * determines the button states on the component - enabled adn tooltips
	 *
	 * dependent upon what has been selected, how many have been selected
	 */
	private determineButtonStates = (): void => {
		const viewActionAllowed: ActionAllowedType = this.reportsStateService.canViewAvailableReports(this.reportSelection);

		this.viewButtonEnabled = viewActionAllowed === ActionAllowedType.allowed;
		this.viewButtonTooltip = this.determineViewTooltip(viewActionAllowed);

		const viewDetailsActionAllowed: ActionAllowedType = this.reportsStateService.canViewAvailableReportsDetails(this.reportSelection);

		this.viewDetailsButtonEnabled = viewDetailsActionAllowed === ActionAllowedType.allowed;
		this.viewDetailsButtonTooltip = this.determineViewDetailsTooltip(viewDetailsActionAllowed);

		const downloadActionAllowed: ActionAllowedType = this.reportsStateService.canDownloadAvailableReports(this.reportSelection);

		this.downloadButtonEnabled = downloadActionAllowed === ActionAllowedType.allowed;
		this.downloadButtonTooltip = this.determineDownloadTooltip(downloadActionAllowed);

		const editActionAllowed: ActionAllowedType = this.reportsStateService.canEditAvailableReports(this.reportSelection);

		this.editButtonEnabled = editActionAllowed === ActionAllowedType.allowed;
		this.editButtonTooltip = this.determineEditTooltip(editActionAllowed);

		const deleteActionAllowed: ActionAllowedType = this.reportsStateService.canDeleteAvailableReports(this.reportSelection);

		this.deleteButtonEnabled = deleteActionAllowed === ActionAllowedType.allowed;
		this.deleteButtonTooltip = this.determineDeleteTooltip(deleteActionAllowed);
	};

	/**
	 * determines if viewing report details is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if view report details is allowed
	 */
	private determineViewDetailsTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.tooManySelected:
				tooltip = this.translations['T_REPORT.TOO_MANY_ROWS_SELECTED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.VIEW_FINISHED_OR_FAILED_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if viewing report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if view report is allowed
	 */
	private determineViewTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.tooManySelected:
				tooltip = this.translations['T_REPORT.TOO_MANY_ROWS_SELECTED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.VIEW_INTERACTIVE_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if dowloading report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if download report is allowed
	 */
	private determineDownloadTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.tooManySelected:
				tooltip = this.translations['T_REPORT.TOO_MANY_ROWS_SELECTED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.DOWNLOAD_CSV_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if editing report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if editing report is allowed
	 */
	private determineEditTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_EDIT_PERMISSION'];
				break;
			case ActionAllowedType.notEnabled:
				tooltip = this.translations['T_REPORT.FEATURE_DISABLED'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.tooManySelected:
				tooltip = this.translations['T_REPORT.TOO_MANY_ROWS_SELECTED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.EDIT_INTERACTIVE_ONLY'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};

	/**
	 * determines if deleting report is allowed and derives an appropriate tooltip
	 *
	 * @param actionAllowed - user action allowed type
	 * @returns the tooltip representing if deleting report is allowed
	 */
	private determineDeleteTooltip = (actionAllowed: ActionAllowedType): string => {
		let tooltip: string = '';

		switch (actionAllowed) {
			case ActionAllowedType.allowed:
				break;
			case ActionAllowedType.noPermission:
				tooltip = this.translations['T_CORE.NEED_DEL_PERMISSION'];
				break;
			case ActionAllowedType.noReportSelected:
				tooltip = this.translations['T_REPORT.NO_ROW_SELECTED'];
				break;
			case ActionAllowedType.invalidStatus:
				tooltip = this.translations['T_REPORT.CANT_DELETE_PENDING'];
				break;
			default:
				this.logger.logError('No tooltip for button as allowed action is incorrect for this context - action: ' + actionAllowed);
				break;
		}

		return tooltip;
	};
}

<div *ngIf="isLoading" class="loading-overlay" [ngClass]="{ 'progress-cursor': isLoading, 'fade-overlay': fadeOverlay }">
	<!--
    the default is for the overlay to appear in a fixed position in the middle of the screen.
    override default positioning to 'content' if we want the overlay to 'fit' the parent content
    instead. Typically used for modals where we want the loading spinner to fit in the modal and
    not the middle of the screen
    -->

	<div class="nb-loading-overlay-container" [ngClass]="{ 'nb-loading-overlay-container-fit-content': fit === 'content' }">
		<div *ngIf="showAnimation" class="nb-spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
		<div class="loading-overlay-text">
			<span class="nb-loading-text">
				{{ loadingText }}
			</span>
		</div>
	</div>
</div>

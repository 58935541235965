/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { RiderMessagesModalService } from '../services/rider-messages-modal.service';
import { RiderMessagesListComponent } from './list/rider-messages-list.component';
import { RiderMessagesUtilService } from '../services/rider-messages-util.service';
import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';
import { SelectedAgency } from '../../../support-features/agencies/types/api-types';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'rider-messages',
	templateUrl: './rider-messages.component.html',
	styleUrls: ['./rider-messages.component.scss'],
})
export class RiderMessagesComponent extends TranslateBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChildren(RiderMessagesListComponent) riderMessages: QueryList<RiderMessagesListComponent>;

	private activeRiderMessageComponent: RiderMessagesListComponent = null;
	private inactiveRiderMessageComponent: RiderMessagesListComponent = null;

	private agenciesSelectionChange$Subscription: Subscription = null;

	private authorityId: string = null;
	private agencyId: string = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		private riderMessagesUtilService: RiderMessagesUtilService,
		private riderMessagesModalService: RiderMessagesModalService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the rider messages component
	 *
	 * sets up subscriptions and agency details
	 */
	public async ngOnInit(): Promise<void> {
		this.getSelectedAgency();
		this.setSubscriptions();
	}

	/**
	 * handles initializes actions once the page has rendered
	 *
	 * sets up inactive and active messages components
	 */
	public async ngAfterViewInit(): Promise<void> {
		const riderMessageComponents: RiderMessagesListComponent[] = this.riderMessages.toArray();

		this.activeRiderMessageComponent = riderMessageComponents[0];
		this.inactiveRiderMessageComponent = riderMessageComponents[1];
	}

	/**
	 * if permission allows, sets up and renders the create rider message dialog
	 */
	public createMessage = (): void => {
		if (this.riderMessagesUtilService.canCreateMessages(this.authorityId, this.agencyId)) {
			this.riderMessagesModalService.createMessage().subscribe((saved: boolean) => {
				if (saved) {
					this.activeRiderMessageComponent.refresh(false);
					this.inactiveRiderMessageComponent.refresh(false);
				}
			});
		}
	};

	/**
	 * the tab has been changed so the opposite tab component is rendered
	 *
	 * @param tab - the tab index
	 */
	public tabChanged = (tab: any): void => {
		if (tab.index === 0) {
			this.activeRiderMessageComponent.refresh(false);
		} else {
			this.inactiveRiderMessageComponent.refresh(false);
		}
	};

	/**
	 * publishes the fact that an agency selection has been changed
	 */
	public handleAgencyUpdated = (): void => {
		this.agenciesEventsService.publishAgenciesSelectionChange();
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * retrieves the selected agewncy details for the current user
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
		}
	};

	/**
	 * setting up subscriptions
	 *
	 * is interested in when an agency selection change has been made
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgencySelectionChange();
		});
	};

	/**
	 * handles an agency selection change, so updates the inactive and active tabs accordingly
	 */
	private handleAgencySelectionChange = (): void => {
		this.getSelectedAgency();
		this.activeRiderMessageComponent.refresh(true);
		this.inactiveRiderMessageComponent.refresh(true);
	};

	/**
	 * unsubscribes from subscriptions
	 */
	private unsubscribe = (): void => {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
	};
}

import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

import { LogLevel } from './types/types';

/**
 * Service responsible for logging messages to the browser console.
 */
@Injectable({
	providedIn: 'root',
})
export class LoggerService {
	private minLogLevel: LogLevel = LogLevel.info;

	/**
	 * Initialise the logging service with the configured minimum logging level,
	 *
	 * @param configService - The Confoguration Service.
	 */
	constructor(private configService: ConfigService) {}

	/**
	 * Log the supplied debug message.
	 *
	 * @param msg - Debug message.
	 * @param debugObj - Optional debug data.
	 */
	public logDebug = (msg: string, debugObj: any = null): void => {
		this.log(msg, debugObj, LogLevel.debug);
	};

	/**
	 * Log the supplied informational message.
	 *
	 * @param msg - Informational message.
	 * @param infoObj - Optional info data.
	 */
	public logInfo = (msg: string, infoObj: any = null): void => {
		this.log(msg, infoObj, LogLevel.info);
	};

	/**
	 * Log the supplied warning message.
	 *
	 * @param msg - Warning message.
	 * @param warnObj - Optional warning data.
	 */
	public logWarn = (msg: string, warnObj: any = null): void => {
		this.log(msg, warnObj, LogLevel.warn);
	};

	/**
	 * Log the supplied error message.
	 *
	 * @param msg - Error message.
	 * @param errorObj - Optional error data.
	 */
	public logError = (msg: string, errorObj: any = null): void => {
		this.log(msg, errorObj, LogLevel.error);
	};

	/**
	 * Log the supplied fatal message.
	 *
	 * @param msg - Fatal message.
	 * @param fatalObj - Optional fatal data.
	 */
	public logFatal = (msg: string, fatalObj: any = null): void => {
		this.log(msg, fatalObj, LogLevel.fatal);
	};

	/**
	 * initializes the logger and sets minimum log level
	 *
	 * set logging level based on config or default if not configured
	 */
	public init = (): void => {
		this.minLogLevel = this.configService.getDefaultMinLogLevel();
	};

	/**
	 * main entry point to log a console message.
	 *
	 * @param msg - the message to output
	 * @param extraData - any additional obeject dumps to output
	 * @param logLevel - the required logLevel
	 */
	private log = (msg: string, extraData: any, logLevel: LogLevel): void => {
		if (typeof console !== 'undefined') {
			if (logLevel >= this.minLogLevel) {
				// If console is available, log to console based on level
				switch (logLevel) {
					case LogLevel.debug:
						if (extraData) {
							console.debug(msg, extraData);
						} else {
							console.debug(msg);
						}
						break;
					case LogLevel.info:
						if (extraData) {
							console.info(msg, extraData);
						} else {
							console.info(msg);
						}
						break;
					case LogLevel.warn:
						if (extraData) {
							console.warn(msg, extraData);
						} else {
							console.warn(msg);
						}
						break;
					case LogLevel.error:
					case LogLevel.fatal:
						if (extraData) {
							console.error(msg, extraData);
						} else {
							console.error(msg);
						}
						break;
					default:
						if (extraData) {
							console.log(msg, extraData);
						} else {
							console.log(msg);
						}
						break;
				}
			}
		}
	};
}

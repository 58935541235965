<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".
-->

<div class="header-container">
	<button
		(click)="viewReportDetails()"
		[disabled]="!viewDetailsButtonEnabled"
		title="{{ viewDetailsButtonTooltip }}"
		class="btn btn-action btn-action-reports">
		<i class="nb-icons nb-information-solid"></i>
		{{ 'T_REPORT.DETAILS' | translate }}
	</button>
	<button (click)="viewReport()" [disabled]="!viewButtonEnabled" title="{{ viewButtonTooltip }}" class="btn btn-action btn-action-reports">
		<i class="nb-icons nb-view-on"></i>
		{{ 'T_REPORT.VIEW' | translate }}
	</button>
	<button
		(click)="downloadReport()"
		[disabled]="!downloadButtonEnabled"
		title="{{ downloadButtonTooltip }}"
		class="btn btn-action btn-action-reports">
		<i class="nb-icons nb-download"></i>
		{{ 'T_REPORT.DNLD' | translate }}
	</button>
	<button (click)="editReport()" [disabled]="!editButtonEnabled" title="{{ editButtonTooltip }}" class="btn btn-action btn-action-reports">
		<i class="nb-icons nb-edit"></i>
		{{ 'T_REPORT.EDIT' | translate }}
	</button>
	<button
		(click)="deleteReports()"
		[disabled]="!deleteButtonEnabled"
		title="{{ deleteButtonTooltip }}"
		class="btn btn-action btn-action-reports">
		<i class="nb-icons nb-delete"></i>
		{{ 'T_REPORT.DELETE' | translate }}
	</button>

	<div class="float-right">
		<search-filter [filterValue]="searchText" (search)="search($event)"></search-filter>
		<button *ngIf="agencies.length > 1" class="btn btn-action filter-btn" (click)="openFilter()">
			<i class="fa nb-margin-right-sm" [ngClass]="{ 'fa-circle-o': !hasFilters, 'fa-circle': hasFilters }"></i>
			{{ 'T_CORE.FILTER' | translate }}
		</button>
		<span class="tooltip-arrow-top" *ngIf="this.agencies.length > 1">
			<button *ngIf="hasFilters" class="btn btn-action nb-unfiltered-ctrl-text ng-binding clear-btn" (click)="clearFilter()">
				<i class="nb-icons nb-clearfield nb-margin-right-sm">
					<span class="tooltiptext">{{ 'T_CORE.CLEAR_ALL_FILTERS' | translate }}</span> </i
				>{{ 'T_CORE.CLEAR' | translate }}
			</button>
		</span>
	</div>
</div>

<div class="data-list-container" *ngIf="initialized">
	<data-table
		#dataList
		data-test="reports.list.table"
		[listName]="listName"
		[columns]="columns"
		[rowData]="reportsList"
		[pagingEnabled]="true"
		[totalRows]="totalRows"
		[defaultSortings]="defaultSortings"
		[pageInfo]="pageInfo"
		[selectedRows]="reportSelection"
		[enableCheckRowSelection]="true"
		[enabledMultiCheckRowSelection]="true"
		[enabledCheckSelectAllRowSelection]="true"
		[enableMenuSelection]="false"
		[enableFooter]="true"
		[loadingIndicator]="listLoadingIndicator"
		[silentReload]="silentReload"
		(rowClick)="onSelect($event)"
		(checkSelect)="onCheckSelect($event)"
		(requestData)="handleDataRequest($event)">
	</data-table>
</div>

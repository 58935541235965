<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

<panel-header [showBackButton]="true" [heading]="'T_AGENCY.AGENCY_DETAILS' | translate"> </panel-header>

<div [hidden]="!(loading || settingsLoading)">
	<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
</div>
<div [hidden]="loading || settingsLoading" *ngIf="agencyDetail">
	<mat-tab-group #tabs>
		<mat-tab label="{{ 'T_AGENCY.DETAILS' | translate | uppercase }}">
			<agency-admin-view-details [agencyDetail]="agencyDetail"> </agency-admin-view-details>
		</mat-tab>
		<mat-tab label="{{ 'T_AGENCY.SETTINGS.SETTINGS' | translate | uppercase }}">
			<agency-admin-view-settings
				[agencyDetail]="agencyDetail"
				[authorityId]="authorityId"
				[agencyId]="agencyId"
				(agencyUpdated)="agencyUpdated()"
				(settingsLoaded)="settingsLoaded()">
			</agency-admin-view-settings>
		</mat-tab>
		<mat-tab label="{{ 'T_AGENCY.DEPOTS' | translate | uppercase }}">
			<depots-view [authorityId]="authorityId" [agencyId]="agencyId"> </depots-view>
		</mat-tab>
	</mat-tab-group>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { ReportSummaryDetail } from '../types/api-types';

import { ReportsPaginatedParams, InteractiveReportListParams, RunReportResponse, ReportTemplateDetail } from '../types/api-types';

@Injectable({
	providedIn: 'root',
})
export class ReportsApiService {
	private readonly reportsUrl: string = '/reports';
	private readonly storedUrl: string = '/stored';
	private readonly templatesUrl: string = '/templates';

	constructor(private httpService: HttpService) {}

	/**
	 * requests the interactive report part for the given authority/agency and report information from the nextbus API
	 *
	 * can be for the summary, template, list or list 2
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportEndpoint - the report endpoint
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @param params - the report parameters
	 * @returns the interactive report part response
	 */
	public getInteractiveReportPart = async (
		authorityId: string,
		agencyId: string,
		reportEndpoint: string,
		createdById: number,
		reportId: string,
		params: InteractiveReportListParams
	): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.reportsUrl + '/' + reportEndpoint + '/' + createdById + '/' + reportId,
				params
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the available reports for the given authority and report search criteria from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param searchParams - the search parameters
	 * @returns the searched for available reports response
	 */
	public getAvailableReports = async (authorityId: string, searchParams: ReportsPaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.reportsUrl + this.storedUrl, searchParams);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the report summary details for the given authority, created by id and report id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @returns the required resport summary detail
	 */
	public getReportSummaryDetail = async (authorityId: string, createdById: number, reportId: string): Promise<ReportSummaryDetail> => {
		try {
			return await this.httpService.get('/' + authorityId + this.reportsUrl + this.storedUrl + '/' + createdById + '/' + reportId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the deletion of an available report for the given authority, created by id and report id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @returns the delete available report response - success or failure
	 */
	public deleteAvailableReport = async (authorityId: string, createdById: number, reportId: string): Promise<any> => {
		try {
			return await this.httpService.delete('/' + authorityId + this.reportsUrl + this.storedUrl + '/' + createdById + '/' + reportId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the report templates for the given authority and search criteria from the nextbus API
	 *
	 * @param authorityId - the authory id
	 * @param searchParams - the search criteria
	 * @returns the searched for report templates
	 */
	public getReportTemplates = async (authorityId: string, searchParams: ReportsPaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get('/' + authorityId + this.reportsUrl + this.templatesUrl, searchParams);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the report template for the given authority, created by and report template from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportTemplateId - the report template id
	 * @returns the report template
	 */
	public getReportTemplate = async (
		authorityId: string,
		createdById: number,
		reportTemplateId: string
	): Promise<ReportTemplateDetail> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + this.reportsUrl + this.templatesUrl + '/' + createdById + '/' + reportTemplateId
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the creation of a report template for the given authority and report request data from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param reportRequestData - the report request data
	 * @returns the outcome response for the creation attempt
	 */
	public createReportTemplate = async (authorityId: string, reportRequestData: ReportTemplateDetail): Promise<ReportTemplateDetail> => {
		try {
			return await this.httpService.post('/' + authorityId + this.reportsUrl + this.templatesUrl, reportRequestData);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the update of a report template for the given authority, report template and update request data from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportTemplateId - the report template id
	 * @param reportRequestData - the update request data
	 * @returns the outcome response for the update attempt
	 */
	public updateReportTemplate = async (
		authorityId: string,
		createdById: number,
		reportTemplateId: string,
		reportRequestData: ReportTemplateDetail
	): Promise<any> => {
		try {
			return await this.httpService.put(
				'/' + authorityId + this.reportsUrl + this.templatesUrl + '/' + createdById + '/' + reportTemplateId,
				reportRequestData
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the deletion of a report template for the given authority, created by and report template from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportTemplateId - the report template id
	 * @returns the outcome response of the delete attempt
	 */
	public deleteReportTemplate = async (authorityId: string, createdById: number, reportTemplateId: string): Promise<any> => {
		try {
			return await this.httpService.delete(
				'/' + authorityId + this.reportsUrl + this.templatesUrl + '/' + createdById + '/' + reportTemplateId
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the creation of a report for the given authority/agency, report endopint and report request data from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportEndpoint - the report endpoint
	 * @param reportRequestData - the report request data
	 * @returns the outcome response of the run report attempt - sucess and status and id of the new report
	 */
	public runReport = async (
		authorityId: string,
		agencyId: string,
		reportEndpoint: string,
		reportRequestData: ReportTemplateDetail
	): Promise<RunReportResponse> => {
		try {
			return this.httpService.post('/' + authorityId + '/' + agencyId + this.reportsUrl + '/' + reportEndpoint, reportRequestData);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the download of a report for the given authority/agency and other report details from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportName - the report name
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @param contentType - the content type
	 * @returns the outcome response of the download report attempt
	 */
	public downloadReport = async (
		authorityId: string,
		agencyId: string,
		reportName: string,
		createdById: number,
		reportId: string,
		contentType: string
	): Promise<any> => {
		try {
			return await this.httpService.get(
				'/' + authorityId + '/' + agencyId + this.reportsUrl + '/' + reportName + '/' + createdById + '/' + reportId,
				null,
				contentType
			);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * requests the stops and timepoints by scheduled blocks for the given authority/agency and other report details from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportEndpoint - the report endpoint
	 * @param reportRequestData - the report request data
	 * @returns response containing stops and timepoints by scheduled blocks
	 */
	public getStopsAndTimePointsByScheduledBlocks = async (
		authorityId: string,
		agencyId: string,
		reportEndpoint: string,
		reportRequestData: ReportTemplateDetail
	): Promise<any> => {
		try {
			return this.httpService.get('/' + authorityId + '/' + agencyId + this.reportsUrl + '/' + reportEndpoint, reportRequestData);
		} catch (exception) {
			throw exception;
		}
	};
}

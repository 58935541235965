/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapLocationService } from '../map-location.service';

@Injectable({
	providedIn: 'root',
})
export class MapMarkerUtilsService {
	constructor(private mapLocationService: MapLocationService) {}

	/**
	 * get the stop marker (icon) size for the map
	 * @returns the stop marker (icon) size for the map
	 */
	public getStopMarkerSize = (): number[] => {
		let returned: number[];

		switch (this.mapLocationService.getLocationZoom()) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
				returned = [2, 2];
				break;
			case 8:
			case 9:
			case 10:
				returned = [3, 3];
				break;
			case 11:
				returned = [4, 4];
				break;
			case 12:
				returned = [5, 5];
				break;
			case 13:
				returned = [6, 6];
				break;
			case 14:
			case 15:
			case 16:
				returned = [8, 8];
				break;
			case 17:
				returned = [14, 14];
				break;
			default:
				returned = [14, 14];
		}

		return returned;
	};

	/**
	 * get the vehicle marker (icon) size for the map
	 * @returns the vehicle marker (icon) size for the map
	 */
	public getVehicleMarkerSize = (): [number, number] => {
		const sizeFactor: number = 0.563;

		let y: number = 50;

		switch (this.mapLocationService.getLocationZoom()) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
			case 11:
			case 12:
			case 13:
				y = 16;
				break;
			case 14:
				y = 24;
				break;
			case 15:
				y = 27;
				break;
			case 16:
				y = 30;
				break;
			case 17:
				y = 33;
				break;
			case 18:
			default:
				y = 50;
		}

		return [y * sizeFactor, y];
	};
}

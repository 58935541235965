/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { RiderMessagesDataService } from '../services/rider-messages-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent, SelectCheckValue, SelectCheckValues, SelectValues } from '@cubicNx/libs/utils';
import { TimeUpdated } from '@cubicNx/libs/utils';

import { MessageSelectedSign, MessageSelectedSigns } from './signs/types/types';
import { RiderMessage, DayTimeInterval, DayTimeIntervals, StopServiceAlerts } from '../types/api-types';
import { SelectedAgency } from '../../../support-features/agencies/types/api-types';

import {
	MessageSelectedRoutes,
	TimeInterval,
	MessageSelectedRoute,
	StopsPerDestination,
	SelectedStop,
	criticalPriorityName,
	highPriorityName,
	normalPriorityName,
	lowPriorityName,
} from '../types/types';

import moment, { Moment } from 'moment';

@Component({
	selector: 'rider-messages-edit',
	templateUrl: './rider-messages-edit.component.html',
	styleUrls: ['./rider-messages-edit.component.scss'],
})
export class RiderMessagesEditComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild('startDatePicker') startDatePicker: any;
	@ViewChild('endDatePicker') endDatePicker: any;
	@ViewChild('selectedMessageControl') selectedMessageControl: ElementRef<HTMLTextAreaElement>;

	public readonly selectedMessageMaxLength: number = 255;
	public readonly signsTypeName: string = 'signs';
	public readonly routesTypeName: string = 'routes';

	public loaded: boolean = false;
	public isCreate: boolean = true;
	public templates: string[] = [];
	public types: SelectValues = [];
	public priorities: SelectValues = [];
	public days: SelectCheckValues = [];
	public selectedTemplate: string = 'placeholder';
	public selectedMessage: string = '';
	public selectedType: string = this.routesTypeName;
	public selectedPriority: string = normalPriorityName;
	public currentMessage: RiderMessage = null;
	public authorityId: string = null;
	public agencyId: string = null;
	public modalTitle: string = null;

	public dateTimeFormGroup: FormGroup = null;
	public hasDateError: boolean = false;
	public minDate: Date = new Date();

	private readonly sundayName: string = 'sun';
	private readonly mondayName: string = 'mon';
	private readonly tuesdayName: string = 'tue';
	private readonly wednesdayName: string = 'wed';
	private readonly thursdayName: string = 'thu';
	private readonly fridayName: string = 'fri';
	private readonly saturdayName: string = 'sat';

	private selectedAgency: SelectedAgency = null;
	private serviceAlertId: string = null;
	private selectedRoutes: MessageSelectedRoutes = [];
	private selectedSigns: MessageSelectedSigns = [];
	private routesValid: boolean = true;
	private isActiveMessage: boolean = true;
	private dateTimeUpdated: boolean = false;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private currentUserUtilService: CurrentUserUtilService,
		private riderMessagesDataService: RiderMessagesDataService,
		private formBuilder: FormBuilder,
		private modalRef: MatDialogRef<RiderMessagesEditComponent>,
		public zone: NgZone,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * closes the edit dialog
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.close();
	}

	/**
	 * performs initialization tasks for the rider messages edit component
	 *
	 * load translations, retrieves rider messages loads the edit rider message dialog
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		// grab the modal data passed in
		this.isCreate = this.data['isCreate'];

		// allow the date control to allow anything from midnight of the current day
		this.minDate = new Date();
		this.minDate.setHours(0, 0, 0, 0);

		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		if (this.selectedAgency) {
			this.authorityId = this.selectedAgency.authority_id;
			this.agencyId = this.selectedAgency.agency_id;
		}

		this.initTemplates();
		this.initTypes();
		this.initPriorities();
		this.initDates();
		this.initDays();

		if (!this.isCreate) {
			this.serviceAlertId = this.data['serviceAlertId'];

			const response: ResultContent = await this.riderMessagesDataService.getRiderMessage(this.authorityId, this.serviceAlertId);

			if (response.success) {
				this.currentMessage = response.resultData as RiderMessage;
				this.isActiveMessage = new Date(this.currentMessage.end_date) > new Date();
				this.initializeEdit(this.currentMessage);
			}
		}

		this.initModalTitle();
		this.loaded = true;
	}

	/**
	 * template has been selected and selection remembered
	 */
	public templateSelected = (): void => {
		this.selectedMessage = this.selectedTemplate;
		this.selectWildcardChars();
	};

	/**
	 * remembers the user routes selection
	 *
	 * @param selectedRoutes - the selected routes
	 */
	public routesSelected = (selectedRoutes: MessageSelectedRoutes): void => {
		this.selectedRoutes = selectedRoutes;
	};

	/**
	 * remembers the route validity state
	 *
	 * @param routesValid - whether the route is valid or not
	 */
	public setRoutesValid = (routesValid: boolean): void => {
		this.routesValid = routesValid;
	};

	/**
	 * remembers the signs selected
	 *
	 * @param selectedSigns - the selected signs
	 */
	public signsSelected = (selectedSigns: MessageSelectedSigns): void => {
		this.selectedSigns = selectedSigns;
	};

	/**
	 * sets the approprate control valid/invalid based on child component updates
	 *
	 * @param valid - the state of the control - true = valid
	 * @param controlName - the name of the control
	 */
	public setTimeValid = (valid: boolean, controlName: string): void => {
		if (valid) {
			this.dateTimeFormGroup.controls[controlName].setErrors(null);
			this.dateTimeFormGroup.controls[controlName].updateValueAndValidity();
		} else {
			this.dateTimeFormGroup.controls[controlName].setErrors({ invalid: true });
		}
	};

	/**
	 * maintains the approriate time from the time control in our form object
	 *
	 * @param timeUpdated - the time to update the control with (HH:MM)
	 * @param controlName - the name of the control
	 */
	public setUpdatedTime = (timeUpdated: TimeUpdated, controlName: string): void => {
		if (!timeUpdated.initialPublish) {
			this.dateTimeUpdated = true;
		}

		this.dateTimeFormGroup.controls[controlName].setValue(timeUpdated.time);
	};

	/**
	 * sets date time update to true due to start date being updated
	 */
	public onChangeStartDate = (): void => {
		this.dateTimeUpdated = true;
	};

	/**
	 * sets date time update to true due to end date being updated
	 */
	public onChangeEndDate = (): void => {
		this.dateTimeUpdated = true;
	};

	/**
	 * determines if there ar eany errors with date time selection
	 *
	 * @returns any errors with date time selection
	 */
	public getDateTimeErrors = (): string => {
		let error: string = '';

		this.hasDateError = false;

		if (this.dateTimeUpdated) {
			// check the date has been updated before checking errors. Then check null value which covers an actual null
			// but also anything entered that isn't a valid date
			if (
				this.dateTimeFormGroup.controls.startDate.value === null ||
				this.dateTimeFormGroup.controls.startTime.value === null ||
				this.dateTimeFormGroup.controls.startTime.errors
			) {
				this.hasDateError = true;
				error += this.translations['T_CORE.FORM.ERRORS.DATE_TIME_START'];
			}

			if (
				this.dateTimeFormGroup.controls.endDate.value === null ||
				this.dateTimeFormGroup.controls.endTime.value === null ||
				this.dateTimeFormGroup.controls.endTime.errors
			) {
				if (this.hasDateError) {
					error += ' / ';
				}

				this.hasDateError = true;
				error += this.translations['T_CORE.FORM.ERRORS.DATE_TIME_END'];
			}

			// we have 2 valid dates - now check for that they are not in the past and the start date is before the end date
			if (!this.hasDateError) {
				// this property only exists if the min date requirment doesnt pass
				if (this.dateTimeFormGroup.controls.startDate.errors?.matDatepickerMin) {
					if (this.hasDateError) {
						error += ' / ';
					}

					this.hasDateError = true;
					error = this.translations['T_CORE.FORM.ERRORS.DATE_START_PAST'];
				}

				if (this.dateTimeFormGroup.controls.endDate.errors?.matDatepickerMin) {
					if (this.hasDateError) {
						error += ' / ';
					}

					this.hasDateError = true;
					error = this.translations['T_CORE.FORM.ERRORS.DATE_END_PAST'];
				}

				if (!this.hasDateError) {
					const startDateMoment: Moment = moment(this.dateTimeFormGroup.controls.startDate.value);
					const endDateMoment: Moment = moment(this.dateTimeFormGroup.controls.endDate.value);

					const startDateTime: Moment = this.setDateTime(startDateMoment, this.dateTimeFormGroup.controls.startTime.value);
					const endDateTime: Moment = this.setDateTime(endDateMoment, this.dateTimeFormGroup.controls.endTime.value);

					if (startDateTime >= endDateTime) {
						this.hasDateError = true;
						error = this.translations['T_CORE.FORM.ERRORS.DATERANGE_END'];
					}
				}
			}
		}

		return error;
	};

	/**
	 * checks for date day errors
	 *
	 * @returns any date errors with days
	 */
	public getDayError = (): string => {
		return this.getDays().length === 0 ? this.translations['T_CORE.SELECT_ATLEAST_ONE'] : '';
	};

	/**
	 * performs checks to see if an edit can be saved
	 *
	 * @returns true if the save button can be enabled, false otherwise
	 */
	public saveEnabled = (): boolean => {
		return (
			this.selectedMessage.length > 0 &&
			this.getRoutesValid() &&
			this.getSignsValid() &&
			!this.hasDateError &&
			this.getDays().length > 0
		);
	};

	/**
	 * either creates or saves the rider message current data from the edit/create dialog
	 */
	public save = async (): Promise<void> => {
		this.loaded = false;

		// dates are stored independently of the times. Set to start date selected from midnight to end date selected to 11:59pm
		// as expected by backend (and consistent with older version of code before it was refactored)
		const startDate: string = moment(this.dateTimeFormGroup.controls.startDate.value).startOf('day').toISOString();
		const endDate: string = moment(this.dateTimeFormGroup.controls.endDate.value).endOf('day').toISOString();

		// time value are handled separately and saved as seconds from midnight within service_alerts_time_intervals
		const startTime: string = this.dateTimeFormGroup.controls.startTime.value;
		const endTime: string = this.dateTimeFormGroup.controls.endTime.value;

		const message: RiderMessage = {
			start_date: startDate,
			end_date: endDate,
			priority: this.selectedPriority,
			description_text: this.selectedMessage,
			created_by: this.currentUserUtilService.getCurrentUser().username,
			stop_service_alerts: this.getStopServiceAlerts(this.selectedRoutes),
			sign_ids: this.getSignIds(this.selectedSigns),
			service_alerts_time_intervals: this.getServiceAlertsTimeIntervals(this.days, startTime, endTime),
			route_service_alerts: [],
			authority_id: this.authorityId,
			agency_id: this.agencyId,
		};

		let response: ResultContent = null;

		if (this.isCreate) {
			response = await this.riderMessagesDataService.createMessage(message);
		} else {
			response = await this.riderMessagesDataService.editMessage(this.serviceAlertId, message);
		}

		this.loaded = true;

		if (response.success) {
			this.modalRef.close(true);
		}
	};

	/**
	 * closes the dialog
	 */
	public close = (): void => {
		this.modalRef.close(false);
	};

	/**
	 * sets up the title of the dialog
	 *
	 * create, edit or view
	 */
	private initModalTitle = (): void => {
		if (this.isActiveMessage) {
			this.modalTitle = this.isCreate ? this.translations['T_MESSAGES.CREATE_MESSAGE'] : this.translations['T_MESSAGES.EDIT_MESSAGE'];
		} else {
			this.modalTitle = this.translations['T_MESSAGES.VIEW_MESSAGE'];
		}
	};

	/**
	 * loads trnsalations for the dialog
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.FORM.ERRORS.DATERANGE_END',
			'T_CORE.FORM.ERRORS.DATE_END_PAST',
			'T_CORE.FORM.ERRORS.DATE_START_PAST',
			'T_CORE.FORM.ERRORS.DATE_TIME_START',
			'T_CORE.FORM.ERRORS.DATE_TIME_END',
			'T_CORE.SELECT_ATLEAST_ONE',
			'T_CORE.SUN',
			'T_CORE.MON',
			'T_CORE.TUE',
			'T_CORE.WED',
			'T_CORE.THU',
			'T_CORE.FRI',
			'T_CORE.SAT',
			'T_MESSAGES.CREATE_MESSAGE',
			'T_MESSAGES.EDIT_MESSAGE',
			'T_MESSAGES.VIEW_MESSAGE',
			'T_MESSAGES.PREDEFINED.EXPECT_DELAYS',
			'T_MESSAGES.PREDEFINED.EXPECT_SIGNIFICANT_DELAYS',
			'T_MESSAGES.PREDEFINED.TRACKS_BLOCKED',
			'T_MESSAGES.PREDEFINED.NORMAL_OPERATION',
			'T_MESSAGES.PREDEFINED.THANKS',
			'T_CORE.ROUTES',
			'T_CORE.SIGNS',
			'T_CORE.CRITICAL',
			'T_CORE.HIGH',
			'T_CORE.NORMAL',
			'T_CORE.LOW',
		]);
	};

	/**
	 * sets up the predefined template texts
	 */
	private initTemplates = (): void => {
		this.templates.push(this.translations['T_MESSAGES.PREDEFINED.EXPECT_DELAYS']);
		this.templates.push(this.translations['T_MESSAGES.PREDEFINED.EXPECT_SIGNIFICANT_DELAYS']);
		this.templates.push(this.translations['T_MESSAGES.PREDEFINED.TRACKS_BLOCKED']);
		this.templates.push(this.translations['T_MESSAGES.PREDEFINED.NORMAL_OPERATION']);
		this.templates.push(this.translations['T_MESSAGES.PREDEFINED.THANKS']);
	};

	/**
	 * sets up the types texts
	 */
	private initTypes = (): void => {
		this.types.push({ name: this.routesTypeName, display: this.translations['T_CORE.ROUTES'] });
		this.types.push({ name: this.signsTypeName, display: this.translations['T_CORE.SIGNS'] });
	};

	/**
	 * sets up the priorities texts
	 */
	private initPriorities = (): void => {
		this.priorities.push({ name: criticalPriorityName, display: this.translations['T_CORE.CRITICAL'] });
		this.priorities.push({ name: highPriorityName, display: this.translations['T_CORE.HIGH'] });
		this.priorities.push({ name: normalPriorityName, display: this.translations['T_CORE.NORMAL'] });
		this.priorities.push({ name: lowPriorityName, display: this.translations['T_CORE.LOW'] });
	};

	/**
	 * sets up the days texts
	 */
	private initDays = (): void => {
		this.days.push({ id: 0, name: this.sundayName, display: this.translations['T_CORE.SUN'], isChecked: false });
		this.days.push({ id: 1, name: this.mondayName, display: this.translations['T_CORE.MON'], isChecked: false });
		this.days.push({ id: 2, name: this.tuesdayName, display: this.translations['T_CORE.TUE'], isChecked: false });
		this.days.push({ id: 3, name: this.wednesdayName, display: this.translations['T_CORE.WED'], isChecked: false });
		this.days.push({ id: 4, name: this.thursdayName, display: this.translations['T_CORE.THU'], isChecked: false });
		this.days.push({ id: 5, name: this.fridayName, display: this.translations['T_CORE.FRI'], isChecked: false });
		this.days.push({ id: 6, name: this.saturdayName, display: this.translations['T_CORE.SAT'], isChecked: false });
	};

	/**
	 * initializes the date part of the dialog
	 */
	private initDates = (): void => {
		const startDate: Date = new Date();

		startDate.setHours(0, 0, 0, 0);

		const endDate: Date = new Date();

		endDate.setHours(0, 0, 0, 0);

		this.dateTimeFormGroup = this.formBuilder.group(
			{
				startDate: [startDate, Validators.required],
				endDate: [endDate, Validators.required],
				startTime: ['00:00', Validators.required],
				endTime: ['23:59', Validators.required],
			},
			{}
		);
	};

	/**
	 * retrieves the days that have been checked
	 *
	 * @returns - the checked days
	 */
	private getDays = (): string[] => {
		return this.days.filter((day) => day.isChecked).map((day) => day.name);
	};

	/**
	 * retrieves the routes valid value
	 *
	 * @returns routes valid value
	 */
	private getRoutesValid = (): boolean => {
		return this.selectedType === this.routesTypeName ? this.routesValid : true;
	};

	/**
	 * retrieves the signs valid value
	 *
	 * @returns signs valid value
	 */
	private getSignsValid = (): boolean => {
		return this.selectedType === this.signsTypeName ? this.selectedSigns.length > 0 : true;
	};

	/**
	 * retrieves the stop service alaerts for the supplied selected routes
	 *
	 * @param selectedRoutes - the selected routes
	 * @returns stops service alerts
	 */
	private getStopServiceAlerts = (selectedRoutes: MessageSelectedRoutes): StopServiceAlerts => {
		const stopServiceAlerts: StopServiceAlerts = [];

		if (this.selectedType === this.routesTypeName) {
			selectedRoutes.forEach((route: MessageSelectedRoute) => {
				route.stopsPerDestinations.forEach((stopsPerDestination: StopsPerDestination) => {
					stopsPerDestination.stops.forEach((stop: SelectedStop) => {
						if (stop.selected) {
							stopServiceAlerts.push({ stopId: stop.id, routeId: route.id });
						}
					});
				});
			});
		}

		return stopServiceAlerts;
	};

	/**
	 * retrieves the sign ids from the currently selected stops
	 *
	 * @param selectedSigns - the selected signs
	 * @returns list of sign ids for the currently selected stops
	 */
	private getSignIds = (selectedSigns: MessageSelectedSigns): string[] => {
		const signIds: string[] = [];

		if (this.selectedType === this.signsTypeName) {
			selectedSigns.forEach((sign: MessageSelectedSign) => {
				signIds.push(sign.id);
			});
		}

		return signIds;
	};

	/**
	 * determines the time interval from the supplied start and end time
	 *
	 * @param startTime - the start time
	 * @param endTime - the end time
	 * @returns time interval detailing start time from start of day and end time from start of day
	 */
	private formatTimeIntervals = (startTime: string, endTime: string): TimeInterval => {
		const splitStartTime: string[] = startTime.split(':');
		const splitEndTime: string[] = endTime.split(':');

		const startTimeHours: number = +splitStartTime[0];
		const startTimeMins: number = +splitStartTime[1];
		const endTimeHours: number = +splitEndTime[0];
		const endTimeMins: number = +splitEndTime[1];

		const startTimeMoment: Moment = moment(new Date().setHours(startTimeHours * 1, startTimeMins * 1, 0));
		const endTimeMoment: Moment = moment(new Date().setHours(endTimeHours * 1, endTimeMins * 1, 0));

		const startTimeFromStartOfDay: number = startTimeMoment.diff(startTimeMoment.clone().startOf('day'), 'seconds');
		const endTimeFromStartOfDay: number = endTimeMoment.diff(endTimeMoment.clone().startOf('day'), 'seconds');

		return { startTime: startTimeFromStartOfDay, endTime: endTimeFromStartOfDay };
	};

	/**
	 * derives the day time interval for the supplied data
	 *
	 * @param days - the days
	 * @param startTime - the start time
	 * @param endTime - the end time
	 * @returns days times intervals
	 */
	private getServiceAlertsTimeIntervals = (days: SelectCheckValues, startTime: string, endTime: string): DayTimeIntervals => {
		/* format appropriately for the back end which expects a list of days grouped in consecutive order

        example1. sun/mon/tue (from midnight to 11:59) only would be
            [
                {
                    "start_day": 0,
                    "end_day": 2,
                    "start_time": 0,
                    "end_time": 86340
                }
            ]

        example2. sun/tues/thu/sat (from midnight to 11:59) would be
            [
                {
                    "start_day": 0,
                    "end_day": 0,
                    "start_time": 0,
                    "end_time": 86340
                },
                {
                    "start_day": 2,
                    "end_day": 2,
                    "start_time": 0,
                    "end_time": 86340
                },
                {
                    "start_day": 4,
                    "end_day": 4,
                    "start_time": 0,
                    "end_time": 86340
                },
                {
                    "start_day": 6,
                    "end_day": 6,
                    "start_time": 0,
                    "end_time": 86340
                }
            ]
        */

		const timeInterval: TimeInterval = this.formatTimeIntervals(startTime, endTime);

		const dayIntervals: DayTimeIntervals = [];

		for (let i: number = 0; i < days.length; i++) {
			if (days[i].isChecked) {
				const interval: DayTimeInterval = {
					start_day: i,
					end_day: i,
					start_time: timeInterval.startTime,
					end_time: timeInterval.endTime,
				};

				dayIntervals.push(interval);

				// find end consecutive end days
				for (i++; i < 7 && days[i].isChecked; i++) {
					interval.end_day++;
				}
			}
		}

		return dayIntervals;
	};

	/**
	 * initializes the edit dialog with data from the supplied message
	 *
	 * @param currentMessage - the current message
	 */
	private initializeEdit = (currentMessage: RiderMessage): void => {
		this.selectedMessage = currentMessage.description_text;
		this.selectedType = currentMessage.sign_ids.length > 0 ? this.signsTypeName : this.routesTypeName;
		this.selectedPriority = currentMessage.priority;

		let startDate: Date = null;
		let endDate: Date = null;

		let startTime: string = null;
		let endTime: string = null;

		const defaultDate: Date = moment().startOf('day').toDate();

		// workaround for issue when Saturday (end of week) is selected and times span midnight
		if (
			currentMessage.time_intervals.length > 1 &&
			currentMessage.time_intervals[0].end_time !== currentMessage.time_intervals[currentMessage.time_intervals.length - 1].endTime
		) {
			currentMessage.time_intervals[currentMessage.time_intervals.length - 1].end_time = currentMessage.time_intervals[0].end_time;
			currentMessage.time_intervals.shift();
		}

		// date/times may not be set if rider messages created from UI1
		if (currentMessage.start_date && currentMessage.time_intervals.length > 0) {
			// our date is stored in start_date but the time within the time_intervals property. Combine for our date/time control
			startDate = moment(currentMessage.start_date).startOf('day').toDate();
			startTime = moment.utc(currentMessage.time_intervals[0].start_time * 1000).format('HH:mm');
		} else {
			// just set to current date
			startDate = defaultDate;
			startTime = '00:00';
		}

		// date/times may not be set if rider messages created from UI1
		if (currentMessage.end_date && currentMessage.time_intervals.length > 0) {
			// our date is stored in start_date but the time within the time_intervals property. Combine for our date/time control
			endDate = moment(currentMessage.end_date).startOf('day').toDate();
			endTime = moment.utc(currentMessage.time_intervals[0].end_time * 1000).format('HH:mm');
		} else {
			// just set to current date
			endDate = defaultDate;
			endTime = '23:59';
		}

		this.dateTimeFormGroup = this.formBuilder.group(
			{
				startDate: [{ value: startDate, disabled: !this.isActiveMessage }, Validators.required],
				endDate: [{ value: endDate, disabled: !this.isActiveMessage }, Validators.required],
				startTime: [startTime, Validators.required],
				endTime: [endTime, Validators.required],
			},
			{}
		);

		this.days.forEach((day: SelectCheckValue) => {
			if (this.isDaySelected(currentMessage, day.id)) {
				day.isChecked = true;
			}
		});
	};

	/**
	 * determines whether the supplied day is selected or not
	 *
	 * @param currentMessage - the current message
	 * @param dayId - the day id
	 * @returns whether the supplied day is selected or not
	 */
	private isDaySelected = (currentMessage: RiderMessage, dayId: number): boolean => {
		let daySelected: boolean = false;

		if (currentMessage.time_intervals.filter((dayTimeInterval: DayTimeInterval) => dayTimeInterval.start_day === dayId).length > 0) {
			daySelected = true;
		}

		return daySelected;
	};

	/**
	 * selects the wildcard part of the predefined message text so that the user can change the value within the form
	 */
	private selectWildcardChars = (): void => {
		const inputElement: HTMLTextAreaElement = this.selectedMessageControl.nativeElement;

		inputElement.value = this.selectedTemplate;

		const wildcardChars: string = 'XX';
		const index: number = inputElement.value.indexOf(wildcardChars);

		if (index >= 0) {
			this.setSelRange(inputElement, index, index + wildcardChars.length);
		}
	};

	/**
	 * sets up the selected range
	 *
	 * @param inputElement - the input element
	 * @param selStart - the selected start position
	 * @param selEnd - the selected end position
	 */
	private setSelRange = (inputElement: any, selStart: number, selEnd: number): void => {
		if (inputElement.setSelectionRange) {
			inputElement.focus();
			inputElement.setSelectionRange(selStart, selEnd);
		} else if (inputElement.createTextRange) {
			const range: any = inputElement.createTextRange();

			range.collapse(true);
			range.moveEnd('character', selEnd);
			range.moveStart('character', selStart);
			range.select();
		}
	};

	/**
	 * creates a moment in tiem representing the supplied data
	 *
	 * @param date - the date
	 * @param time - the end
	 * @returns a moment in time representing the supplied date and time
	 */
	private setDateTime = (date: moment.Moment, time: string): moment.Moment => {
		const timeSplit: string[] = time.split(':');

		date.hour(+timeSplit[0]);
		date.minutes(+timeSplit[1]);

		return date;
	};
}

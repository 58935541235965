/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { TranslationService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { DepotsDataService } from '../../../../../support-features/depots/services/depots-data.service';
import { ValidatorService } from '@cubicNx/libs/utils';

import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { VehicleSummary, VehicleSummaries } from '../../../../../support-features/vehicles/types/api-types';
import { IWidgetEditModalComponent, ListSizeOption, ListSizeOptions, VehicleSelectionAll } from '../../../types/types';
import { Depots, Depot } from '../../../../../support-features/depots/types/api-types';

@Component({
	selector: 'schedule-performance-edit',
	templateUrl: './schedule-performance-edit.component.html',
	styleUrls: ['./schedule-performance-edit.component.scss'],
})
export class SchedulePerformanceEditComponent extends WidgetEditBaseComponent implements IWidgetEditModalComponent, OnInit, OnDestroy {
	public loaded: boolean = false;
	public listSizeOptions: ListSizeOptions = [];

	public vehicles: VehicleSummaries = [];
	public depots: Depots = [];

	public selectedDepots: Depots = [];
	public selectedVehicles: VehicleSummaries = [];

	public vehicleSettings: MultiSelectSettings = {
		id_text: 'vehicle_id',
		value_text: 'vehicle_id',
		placeholder: 'Please select a vehicle',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	public depotSettings: MultiSelectSettings = {
		id_text: 'name',
		value_text: 'name',
		placeholder: 'Please select a depot',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	constructor(
		private depotsDataService: DepotsDataService,
		private vehiclesDataService: VehiclesDataService,
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * performs initialization tasks for the schedule performance edit component - loading translations, setting up subscriptions
	 * and loading widget data
	 */
	public async ngOnInit(): Promise<void> {
		const translationKeys: string[] = ['T_DASH.DB_ALL'];

		this.buildForm(this.addFormControls, {});

		await this.setupAgencies(this.agencyChanged);

		// do this after building the form (the await causes the form to load and give an error due to the form not being setup)
		await this.initTranslations(translationKeys);

		this.setup();
		this.setSubscriptions();

		await this.initData();

		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy = (): void => {
		this.unsubscribe();
	};

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public agencyChanged = (): void => {
		this.handleAgencyChanged(this.loadAgencyData, this.resetAgencyData);
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param display - the data to check.
	 * @returns a class string.
	 */
	public displayButtonClass = (display: string): string => {
		const current: string = this.reactiveForm.get('display').value;

		return current === display ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget display data.
	 *
	 * @param display - the display to set.
	 */
	public setDisplay = (display: string): void => {
		this.widget.config.display = display;
		this.reactiveForm.get('display').setValue(display);
	};

	/**
	 * updates the size of the list
	 *
	 * @param event - event detailing the list size drop down value
	 */
	public updateListSize = (event: any): void => {
		const update: ListSizeOption = this.listSizeOptions.find((item) => item.value === event.target.value);

		this.widget.config.listSize = update;
	};

	/**
	 * Updates the vehicle selection filter value in the widget data and form.
	 *
	 * @param filter - the filter value to update to.
	 */
	public updateVehicleSelectionFilter = (filter: string): void => {
		this.reactiveForm.get('vehicleFilterSelection').setValue(filter);
		this.widget.config.vehicleFilterSelection.value = filter;
	};

	/**
	 * Updates the form and widget data with the selectedVehicles.
	 *
	 * @param item - the item to update.
	 */
	public updateVehicleItemClicked = (): void => {
		// take the selected vehicles (list of vehicle objects) to form a new list of vehicleIds to store
		const vehicleIds: string[] = this.selectedVehicles.map((vehicle) => vehicle.nb_id);

		this.reactiveForm.get('selectedVehicles').setValue(vehicleIds);
		this.widget.config.selectedVehicles = vehicleIds;
		this.reactiveForm.updateValueAndValidity();
	};

	/**
	 * Updates the form and widget data with the selectedDepots.
	 *
	 * @param item - the item to update.
	 */
	public updateDepotItemClicked = (): void => {
		// take the selected vehicles (list of vehicle objects) to form a new list of vehicleIds to store
		const depotsIds: string[] = this.selectedDepots.map((depot) => depot.nb_id);

		this.reactiveForm.get('selectedDepots').setValue(depotsIds);
		this.widget.config.selectedDepots = depotsIds;
		this.reactiveForm.updateValueAndValidity();
	};

	/**
	 * handles the checkbox clicks and updates the widget config. Also
	 * ensure the form is set to invalid if a checkbox in a group is not
	 * selected
	 *
	 * @param event - the event containing the checkbox state.
	 * @param property - the property name corresponding to the widget config.
	 */
	public checkboxClicked = (event: any, property: string): void => {
		this.widget.config[property] = event.target.checked;

		if (property === 'displayAdherence' || property === 'displayUnassigned') {
			// check at least one checkbox is selected
			if (this.widget.config['displayAdherence'] || this.widget.config['displayUnassigned']) {
				this.reactiveForm.controls['adherence'].setErrors(null);
			} else {
				// just set one of the checkboxes as invalid to force the save to be disabled
				this.reactiveForm.controls['adherence'].setErrors({ 'no-display-type': true });
			}
		}

		if (
			property === 'displayVeryEarly' ||
			property === 'displayEarly' ||
			property === 'displayOnTime' ||
			property === 'displayLate' ||
			property === 'displayVeryLate'
		) {
			// check at least one checkbox is selected
			if (
				this.widget.config['displayVeryEarly'] ||
				this.widget.config['displayEarly'] ||
				this.widget.config['displayOnTime'] ||
				this.widget.config['displayLate'] ||
				this.widget.config['displayVeryLate']
			) {
				this.reactiveForm.controls['early'].setErrors(null);
			} else {
				// just set one of the checkboxes as invalid to force the save to be disabled
				this.reactiveForm.controls['early'].setErrors({ 'no-status-selected': true });
			}
		}

		this.reactiveForm.updateValueAndValidity();
	};

	/**
	 * sets up the list size options
	 */
	private setup = (): void => {
		this.listSizeOptions = [
			{ option: '5', value: '5' },
			{ option: '10', value: '10' },
			{ option: '25', value: '25' },
			{ option: '50', value: '50' },
			{ option: '100', value: '100' },
			// keep value as uppercase rather than 'all' due to config already out on site
			{ option: this.translations['T_DASH.DB_ALL'], value: 'All' },
		];
	};

	/**
	 * sets up the controls on the schedule performance edit form
	 */
	private addFormControls = (): void => {
		this.reactiveForm.addControl('display', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('listSize', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('adherence', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('unassigned', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('offRoute', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('early', new FormControl('', []));
		this.reactiveForm.addControl('late', new FormControl('', []));
		this.reactiveForm.addControl('onTime', new FormControl('', []));
		this.reactiveForm.addControl('veryEarly', new FormControl('', []));
		this.reactiveForm.addControl('veryLate', new FormControl('', []));
		this.reactiveForm.addControl('vehicleFilterSelection', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('selectedVehicles', new FormControl(''));
		this.reactiveForm.addControl('selectedDepots', new FormControl(''));

		const formOptions: any = {
			validators: [
				// params as follows: radio coontrol form name / form control / radio control dependent value
				ValidatorService.dependantRequiredIfPrimaryHasValue('vehicleFilterSelection', 'selectedVehicles', 'vehicles'),
				ValidatorService.dependantRequiredIfPrimaryHasValue('vehicleFilterSelection', 'selectedDepots', 'depots'),
			],
		};

		this.reactiveForm.setValidators(formOptions.validators);
	};

	/**
	 * sets up the form data with widget data
	 */
	private setFormData = (): void => {
		this.reactiveForm.get('display').setValue(this.widget.config.display);
		this.reactiveForm.get('listSize').setValue(this.widget.config.listSize.value);
		this.reactiveForm.get('adherence').setValue(this.widget.config.displayAdherence);
		this.reactiveForm.get('unassigned').setValue(this.widget.config.displayUnassigned);
		this.reactiveForm.get('offRoute').setValue(this.widget.config.displayOffRoute);
		this.reactiveForm.get('early').setValue(this.widget.config.displayEarly);
		this.reactiveForm.get('late').setValue(this.widget.config.displayLate);
		this.reactiveForm.get('onTime').setValue(this.widget.config.displayOnTime);
		this.reactiveForm.get('veryEarly').setValue(this.widget.config.displayVeryEarly);
		this.reactiveForm.get('veryLate').setValue(this.widget.config.displayVeryLate);
		this.reactiveForm.get('vehicleFilterSelection').setValue(this.widget.config.vehicleFilterSelection?.value);
		this.reactiveForm.get('selectedVehicles').setValue(this.widget.config.selectedVehicles ?? []);
		this.reactiveForm.get('selectedDepots').setValue(this.widget.config.selectedDepots ?? []);
	};

	/**
	 * initialize any data based on whether we already have an agency, default to loaded true if first time
	 * as we won't have an agency set yet
	 */
	private initData = async (): Promise<void> => {
		if (this.widget.config.selectedAgency) {
			await this.loadAgencyData();
		} else {
			this.loaded = true;
		}

		this.setVehicleData();
		this.setDepotData();

		// update form once we have agency data (vehicles/depots)
		this.setFormData();
	};

	/**
	 * Loads the selected agency data.
	 */
	private loadAgencyData = async (): Promise<void> => {
		this.loaded = false;
		await this.loadVehicles();
		await this.loadDepots();
		this.loaded = true;
	};

	/**
	 * reset agency related form and config data.
	 */
	private resetAgencyData = (): void => {
		this.selectedDepots = [];
		this.selectedVehicles = [];

		this.widget.config.selectedVehicles = [];
		this.widget.config.selectedDepots = [];
		this.widget.config.vehicleFilterSelection = { value: VehicleSelectionAll };

		this.reactiveForm.get('vehicleFilterSelection').setValue(VehicleSelectionAll);
		this.reactiveForm.get('selectedVehicles').setValue([]);
		this.reactiveForm.get('selectedDepots').setValue([]);
	};

	/**
	 * Gets the vehicles for the selectedAgency.
	 */
	private loadVehicles = async (): Promise<void> => {
		const vehiclesResponse: ResultContent = await this.vehiclesDataService.getVehicles(
			this.widget.config.selectedAgency?.authority_id,
			true
		);

		if (vehiclesResponse.success) {
			this.vehicles = vehiclesResponse.resultData as VehicleSummaries;
		}
	};

	/**
	 * Gets the depots for the selectedAgency.
	 */
	private loadDepots = async (): Promise<void> => {
		this.depots = [];

		const result: ResultContent = await this.depotsDataService.getDepots(this.widget.config.selectedAgency?.authority_id, '1');

		if (result.success) {
			this.depots = result.resultData;
		}
	};

	/**
	 * Sets the selectedVehicles data (multiselect control) from the widget data.
	 */
	private setVehicleData = (): void => {
		if (this.widget.config.selectedVehicles?.length > 0) {
			// multi select control expects list of vehicles, we only have list of vehicleIds - map accordingly
			this.selectedVehicles = this.vehicles.filter((vehicle: VehicleSummary) =>
				this.widget.config.selectedVehicles.includes(vehicle.nb_id)
			);
		}
	};

	/**
	 * Sets the selectedDepots data (multiselect control) from the widget data.
	 */
	private setDepotData = (): void => {
		if (this.widget.config.selectedDepots?.length > 0) {
			// multi select control expects list of vehicles, we only have list of vehicleIds - map accordingly
			this.selectedDepots = this.depots.filter((depot: Depot) => this.widget.config.selectedDepots.includes(depot.nb_id));
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { ReportFiltersUpdate, SelectedReportsFilter } from '../../../../types/types';
import { Agencies } from '../../../../../../support-features/agencies/types/api-types';

@Component({
	selector: 'available-reports-list-filter',
	templateUrl: './available-reports-list-filter.component.html',
	styleUrls: ['./available-reports-list-filter.component.scss'],
})
export class AvailableReportsListFilterComponent extends TranslateBaseComponent implements OnInit {
	public agencySelected: boolean = true;

	public agencies: Agencies = [];

	public selectedAgencies: Agencies = [];

	public angencySelectSettings: MultiSelectSettings = {
		id_text: 'agency_nb_id',
		value_text: 'authority_name',
		placeholder: '',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
	};

	constructor(
		private agenciesDataService: AgenciesDataService,
		private modalRef: MatDialogRef<AvailableReportsListFilterComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// Disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * Close on escape key
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.cancel();
	}

	/**
	 * performs initialization tasks for the available reports list filter component
	 *
	 * retrieves agencies and initial filter criteria
	 */
	public async ngOnInit(): Promise<void> {
		this.initAgencies();

		// Set any previously selected filters
		this.getInitialFilters();

		this.agencySelected = this.agencies.length === 1 ? true : this.selectedAgencies.length > 0;
	}

	/**
	 * remembers the selected agency
	 */
	public agencyItemClicked = (): void => {
		this.agencySelected = this.selectedAgencies.length > 0;
	};

	/**
	 * remebers the selected agencies and closes the modal broadcasting the save details
	 */
	public save = (): void => {
		const filters: SelectedReportsFilter = {
			agencies: this.selectedAgencies,
		};

		const reportFiltersUpdate: ReportFiltersUpdate = {
			saved: true,
			selectedReportsFilter: filters,
		};

		this.modalRef.close(reportFiltersUpdate);
	};

	/**
	 * closes the modal broadcasting the cancellation
	 */
	public cancel = (): void => {
		const reportFiltersUpdate: ReportFiltersUpdate = {
			saved: false,
		};

		this.modalRef.close(reportFiltersUpdate);
	};

	/**
	 * retrieves and remembers all of the agencies
	 */
	private initAgencies = (): void => {
		this.agencies = this.agenciesDataService.getAgencies();
	};

	/**
	 * remembers the agencies that have been selected within the modal
	 */
	private getInitialFilters = (): void => {
		// Set the initial selected filters
		if (this.data.selectedAgenciesFilters) {
			this.selectedAgencies = this.data.selectedAgenciesFilters.agencies;
		}
	};
}

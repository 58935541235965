/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { VehicleEventsApiService } from './vehicle-events-api.service';
import { VEHICLE_EVENT_TYPES_STORE } from './vehicle-events-store-factory';

import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { CategoryFilterOptions, VehicleEventTypes } from '../types/types';
import { VehicleEventDetails } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class VehicleEventsDataService {
	constructor(
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private logger: LoggerService,
		private vehicleEventsApiService: VehicleEventsApiService,
		@Inject(VEHICLE_EVENT_TYPES_STORE) private vehicleEventTypesDataStore: any
	) {}

	/**
	 * handles the request for the vehicle events for the given authority, vehicles and parameters from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param eventTypes - the vehicle event types
	 * @param vehicleIds - the vehicle identifiers
	 * @param pageNumber - the page number
	 * @param pageSize  - the page size
	 * @param startTimestamp - the start time stamp
	 * @param endTimestamp - the end time stamp
	 * @param categories - the categories
	 * @param sort - the sort by
	 * @param sortDir - the sort by direction
	 * @param routeIds - the route identifiers
	 * @returns the vehicle events response
	 */
	public getVehicleEvents = async (
		authorityId: string,
		eventTypes: number[],
		vehicleIds: string[],
		pageNumber: number,
		pageSize: number,
		startTimestamp: number,
		endTimestamp: number,
		categories: CategoryFilterOptions,
		sort: string,
		sortDir: string,
		routeIds: string[]
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// Just for this method
			// Include any paramaters as a string - not nice but hacky to aid the back-end implementation
			// For example: to cater for URLS such as the below where they need to have parameters on the URL
			// with the same name - Javascript objects must have unique properties so this can not be done via the
			// normal params object!!!
			//
			// We should really change the API back-end and make this part of a payload rather than on the URL
			//
			// https://api.dev-1.iq.play.umoiq.com/v2.0/cube-opolis/vehicles/events?
			//   category=alarm&category=alert&category=event&eventTypes=1&eventTypes=33&eventTypes=6&
			//   eventTypes=2&eventTypes=34&eventTypes=9&eventTypes=32&eventTypes=30&eventTypes=29&eventTypes=31
			//   &eventTypes=11&eventTypes=10&eventTypes=13&eventTypes=12&pageNum=1&pageSize=50&
			//   sort=category,created_at&sortDir=ASC,DESC
			//

			// Grab all of the inputs into a string for the URL
			const requestParams: string = this.createGetVehicleEventsParameters(
				eventTypes,
				pageSize,
				pageNumber,
				categories,
				routeIds,
				vehicleIds,
				sort,
				sortDir,
				startTimestamp,
				endTimestamp
			);

			const vehicleEvents: VehicleEventDetails = await this.vehicleEventsApiService.getVehicleEvents(
				authorityId,
				vehicleIds,
				requestParams.length > 0 ? '?' + requestParams : ''
			);

			result.success = true;
			result.resultData = vehicleEvents;
		} catch (error) {
			this.logger.logError('Failed to get vehicle events', error);
		}

		return result;
	};

	/**
	 * handes the request for the vehicle event types for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @returns the vehicle event types response
	 */
	public getVehicleEventTypes = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			let eventTypes: VehicleEventTypes = this.vehicleEventTypesDataStore.get(authorityId);

			if (!eventTypes) {
				eventTypes = await this.vehicleEventsApiService.getVehicleEventTypes(authorityId);

				this.vehicleEventTypesDataStore.set(authorityId, eventTypes);
			} else {
				this.logger.logDebug(`returning event types for: ${authorityId} from cached store`);
			}

			result.resultData = eventTypes as VehicleEventTypes;
			result.success = true;
		} catch (error) {
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			this.logger.logError('Failed to get vehicle event types for ' + authorityId, error);
		}

		return result;
	};

	/**
	 * handles the request for the vehicle event audio for the given authority from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param fileName - the audio filename
	 * @returns the vehicle event audio response
	 */
	public getEventAudio = async (authorityId: string, fileName: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const params: any = {
				fileName,

				/*fileName: "bikebell.mp3" // hardcoded to test audio - note also need to bypass type.audio check in
                // caller method if event types are not configured */
			};

			const audio: any = await this.vehicleEventsApiService.getVehicleEventAudio(authorityId, params);

			result.resultData = audio;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get vehicle event audio file: ' + fileName, error);
		}

		return result;
	};

	/**
	 * creates a get vehicle events request parameters
	 *
	 * @param eventTypes - the event types
	 * @param pageSize - the page size
	 * @param pageNumber - the page number
	 * @param categories - the catgories
	 * @param routeIds - the route ids
	 * @param vehicleIds - the vehicle ids
	 * @param sort - the sort by
	 * @param sortDir - the sort by direction
	 * @param startTimestamp - the start timestamp
	 * @param endTimestamp - the end timestamp
	 * @returns the get vehicle events request parameters
	 */
	private createGetVehicleEventsParameters = (
		eventTypes: number[],
		pageSize: number,
		pageNumber: number,
		categories: CategoryFilterOptions,
		routeIds: string[],
		vehicleIds: string[],
		sort: string,
		sortDir: string,
		startTimestamp: number,
		endTimestamp: number
	): string => {
		// Start off with no parameters
		let params: string = '';

		if (eventTypes && eventTypes.length > 0) {
			eventTypes.forEach((eventType: number) => {
				params += 'eventTypes=' + eventType + '&';
			});
		}

		if (pageSize) {
			params += 'pageSize=' + pageSize + '&';
		}

		if (pageNumber) {
			params += 'pageNum=' + pageNumber + '&';
		}

		if (categories) {
			const selectedCategories: string[] = this.getSelectedCategories(categories);

			selectedCategories.forEach((category: string) => {
				params += 'category=' + category + '&';
			});
		}

		if (routeIds && routeIds.length > 0) {
			routeIds.forEach((routeId: string) => {
				params += 'routeId=' + routeId + '&';
			});
		}

		if (sort) {
			params += 'sort=' + sort + '&';
		}

		if (sortDir) {
			params += 'sortDir=' + sortDir + '&';
		}

		if (startTimestamp) {
			params += 'startTimestamp=' + startTimestamp + '&';
		}

		if (endTimestamp) {
			params += 'endTimestamp=' + endTimestamp + '&';
		}

		if (vehicleIds && Array.isArray(vehicleIds) && vehicleIds.length > 0) {
			params += 'vehicleIds=';
			vehicleIds.forEach((vehicleId: string) => {
				params += vehicleId + ','; // add comma even if just one entry - it will be removed
			});
		}

		// Remove last & or comma if there is one
		if (params.length > 0) {
			params = params.slice(0, -1);
		}

		return params;
	};

	/**
	 * the selected category options
	 *
	 * @param categoryOptions - the category options
	 * @returns category options
	 */
	private getSelectedCategories = (categoryOptions: CategoryFilterOptions): string[] => {
		return Object.keys(categoryOptions).filter((key) => categoryOptions[key as keyof CategoryFilterOptions]);
	};
}

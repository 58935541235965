/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { LoggerService } from '@cubicNx/libs/utils';
import { SchedulesAdminApiService } from './schedules-admin-api.service';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { PaginatedParams } from '@cubicNx/libs/utils';

import { SchedulesPaginatedResponse, SchedulesUploadHistoryDetails } from '../types/api-types';
import { AgenciesPaginatedResponse } from '../../agencies/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class SchedulesAdminDataService {
	constructor(
		private logger: LoggerService,
		private translationService: TranslationService,
		private notificationService: NotificationService,
		private schedulesAdminApiService: SchedulesAdminApiService
	) {}

	/**
	 * retrieves agencies that match the supplied parameters
	 *
	 * @param paginatedParams - agency retrieval parameters - page size, number, sort criteria and search text
	 * @returns agencies matching the supplied parameters
	 */
	public getAgencies = async (paginatedParams: PaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.schedulesAdminApiService.getAgencies(paginatedParams);

			result.success = true;

			if (response?.results) {
				result.resultData = response as AgenciesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: AgenciesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as AgenciesPaginatedResponse;
			}
		} catch (exception) {
			this.logger.logError('Failed to get schedules agencies', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves schedules upload history for the supplied authority
	 *
	 * @param authorityId - authority id
	 * @param paginatedParams - schedule upload history parameters - page size, number, sort criteria and search text
	 * @returns schedules upload history for the supplied authority
	 */
	public getSchedulesUploadHistory = async (authorityId: string, paginatedParams: PaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.schedulesAdminApiService.getSchedulesUploadHistory(authorityId, paginatedParams);

			result.success = true;
			if (response?.results) {
				result.resultData = response as SchedulesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: SchedulesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as SchedulesPaginatedResponse;
			}
		} catch (exception: any) {
			this.logger.logError('Failed to get schedules upload history.', exception);
			if (exception.status === 403) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};

	/**
	 * retrieves schedules upload history details for the supplied authority and confirmation number
	 *
	 * @param authorityId - authority id
	 * @param confirmNo - confirmation number
	 * @returns schedules upload history details for the supplied authority and confirmation number
	 */
	public getSchedulesUploadHistoryDetails = async (authorityId: string, confirmNo: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.schedulesAdminApiService.getSchedulesUploadHistoryDetails(authorityId, confirmNo);

			result.success = true;
			result.resultData = response as SchedulesUploadHistoryDetails;
		} catch (exception) {
			this.logger.logError('Failed to get schedules confirmation details.', exception);
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
		}

		return result;
	};

	/**
	 * retrieves schedules revision history for the supplied authority
	 *
	 * @param authorityId - authority id
	 * @returns schedules revision history for the supplied authority
	 */
	public getSchedulesRevisionHistory = async (authorityId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: any = await this.schedulesAdminApiService.getSchedulesRevisionHistory(authorityId);

			result.success = true;
			result.resultData = response as Array<string>;
		} catch (exception: any) {
			this.logger.logError('Failed to get schedules revision history.', exception);
			if (exception.status === 403) {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));
			}
		}

		return result;
	};
}

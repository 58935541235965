import { JobConditions } from '../../../../../support-features/routes/types/api-types';

export enum StopDirection {
	inbound,
	outbound,
}

export interface LadderStop {
	stopId: string;
	stopCode: string;
	direction: string;
	description: string;
	stopTag: string;
	jobCondition: JobConditions;
	position: number;
	lineHeight: number;
	showLabel: boolean;
}

export type LadderStops = Array<LadderStop>;

export interface MatchedStopInTrip {
	stopDistance: number;
	direction: string;
	stopTag: string;
	pathTag: string;
	position: number;
	isStopFoundOnNextPath: boolean;
	countOfDuplicateMatch: number;
}

export interface LadderStopsInRoute {
	stopFound: boolean;
	prvStopInRoute: LadderStopInRoute;
	matchedStopInRoute: LadderStopInRoute;
}

export interface LadderStopInRoute {
	stopId: string;
	lat: number;
	lon: number;
}

export interface LadderVehicle {
	vehicleId: string;
	routeId: string;
	ladderPosition: number;
	icon: string;
	angle: number;
	departure: number;
	vehLabelHtml: string;
	stopDirection: StopDirection;
	hoverFlag: boolean;
}

<div class="mat-dialog-header" data-test="replay.time-select">
	<button class="close" mat-dialog-close data-test="replay.time-select.close">
		<i class="nb-icons nb-close"></i>
	</button>
	<h4 class="nb-subhead">{{ 'T_MAP.MAP_CUSTOM_TIME_FRAME' | translate }}</h4>
</div>
<hr class="mat-dialog-header-divide" />
<mat-dialog-content>
	<form *ngIf="timeFrameSelectForm" [formGroup]="timeFrameSelectForm">
		<div class="time-frame-select-container">
			<div class="form-group row">
				<div class="col-xs-1"></div>
				<div class="col-xs-4">
					<div class="form-group selected-date-container" id="replayDate">
						<label class="font-normal"
							><span>{{ 'T_MAP.MAP_DATE' | translate }}</span></label
						>
						<div class="selected-day" data-test="replay.timeframe.date">
							<mat-form-field>
								<mat-label>{{ 'T_CORE.CHOOSE_DATE' | translate }}</mat-label>
								<input
									matInput
									[matDatepicker]="picker"
									formControlName="selectedDay"
									[max]="maxDate"
									(input)="setUpdatedSelectedDay()"
									(dateChange)="setUpdatedSelectedDay()" />
								<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>
							<span *ngIf="hasSelectedDayError" class="selected-day-error" data-test="form.error">{{ selectedDayErrorText }}</span>
						</div>
					</div>
				</div>
				<div class="col-xs-4">
					<label class="font-normal"
						><span>{{ 'T_MAP.MAP_REPLAY_INCLUDE_PREDICTIONS' | translate }}</span></label
					>
					<div class="predictions-enabled-container">
						<mat-checkbox (click)="togglePredictionsEnabled()" data-test="map-replay-custom.include-predictions">
							<span class="include-predictions-enable">{{ 'T_AGENCY.ENABLED' | translate }}</span>
						</mat-checkbox>
					</div>
				</div>
			</div>
			<div>
				<div class="form-group row">
					<div class="col-xs-1"></div>
					<div class="col-xs-2">
						<label class="font-normal">
							<span class="time-frame-selection-title">{{ 'T_MAP.MAP_REPLAY_RANGE' | translate }}</span>
						</label>
						<br />
					</div>
					<div class="col-xs-9">
						<div class="nb-text selected-time-container">{{ selectedStartTimeDisplay }} - {{ selectedEndTimeDisplay }}</div>
					</div>
				</div>
				<div class="form-group row replay-range-container">
					<div class="col-xs-1"></div>
					<div class="col-xs-10 replay-range-inner-container">
						<div class="form-group" id="replayRange">
							<slider
								*ngIf="sliderTimeRangeValues.length > 0"
								[values]="sliderTimeRangeValues"
								[sliderCfg]="sliderTimeRangeConfig"
								(updatedValues)="handleUpdatedTimeRangeSelection()">
							</slider>
							<div class="slider-container-left slider-container" [ngStyle]="{ width: sliderContainerLeftWidth }"></div>
							<div class="slider-container-text slider-container-text-left" [ngStyle]="{ width: sliderContainerLeftWidth }">
								{{ dayLeftDisplay }}
							</div>
							<div
								class="slider-container-right slider-container"
								[ngStyle]="{ width: sliderContainerRightWidth, left: sliderContainerRightOffset }"></div>
							<div class="slider-container-text" [ngStyle]="{ width: sliderContainerRightWidth, left: sliderContainerRightOffset }">
								{{ dayRightDisplay }}
							</div>
						</div>
					</div>
					<div class="col-xs-1"></div>
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button class="btn btn-secondary" data-test="replay.timeframe.cancel-btn" (click)="cancel()" cdkFocusInitial>
		{{ 'T_CORE.CANCEL' | translate }}
	</button>
	<button class="btn btn-secondary" data-test="replay.timeframe.done-btn" (click)="save()" [disabled]="!saveEnabled()">
		{{ 'T_MAP.MAP_DONE' | translate }}
	</button>
</mat-dialog-actions>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { BarGraphValue, AdherenceBargraphDetail, AdherenceBargraphDetails, AdherenceColors } from '../../../types/api-types';

import Moment from 'moment-timezone';

declare let d3: any;

@Component({
	selector: 'adherence-daily-stacked-bar-chart',
	templateUrl: './adherence-daily-stacked-bar-chart.component.html',
	styleUrls: ['./adherence-daily-stacked-bar-chart.component.scss'],
})
export class AdherenceDailyStackedBarChartComponent extends TranslateBaseComponent implements OnChanges {
	@Input() summaryData: AdherenceBargraphDetails = null;

	public hasResults: boolean = false;
	public data: { values: any[]; key: any }[];
	public chartIsInitialized: boolean;

	public options: {
		title: {
			css: {
				margin: string;
				'text-align': string;
			};
			enable: boolean;
			text: any;
		};
		chart: {
			useInteractiveGuideline: boolean;
			yAxis: {
				tickFormat: (d: any) => string;
			};
			stacked: boolean;
			xAxis: {
				tickFormat: (d: any) => any;
			};
			color: string[];
			yDomain: number[];
			showLegend: boolean;
			noData: any;
			type: string;
			showControls: boolean;
			height: number;
		};
	};

	private readonly adherenceColors: AdherenceColors = {
		very_early: '#b00808',
		early: '#eb3b3b',
		on_time: '#3ac15d',
		late: '#ffb852',
		very_late: '#e89015',
	};

	private readonly translationKeys: any = {
		very_early: 'T_REPORT.VERY_EARLY',
		early: 'T_REPORT.EARLY',
		on_time: 'T_REPORT.ON_TIME',
		late: 'T_REPORT.LATE',
		very_late: 'T_REPORT.VERY_LATE',
	};

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * handles changes to the input params
	 *
	 * loads the chart data
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (Array.isArray(changes.summaryData.currentValue)) {
			this.data = [
				{
					key: this.getTranslation('T_CORE.CHARTS_SCHEDULE_PERFORMANCE'),
					values: [],
				},
			];
			this.chartIsInitialized = false;
			this.loadChart();
		}
	}

	/**
	 * loads the adherence daily stacked chart with data
	 */
	private loadChart = (): void => {
		this.hasResults = true;
		// need to add colors to each value based on its adherence type
		this.summaryData.forEach((item: AdherenceBargraphDetail) => {
			const type: string = item.id;

			if (this.translationKeys[item.id]) {
				item.key = this.getTranslation(this.translationKeys[item.id as keyof any]);
			}

			item.values.forEach((value: BarGraphValue) => {
				value.color = this.adherenceColors[type as keyof AdherenceColors];
			});
		});

		this.setChartOptions();
		this.data = this.summaryData;
		this.chartIsInitialized = true;
	};

	/**
	 * sets up the adherence daily stacked chart options
	 */
	private setChartOptions = (): void => {
		this.options = {
			title: {
				enable: true,
				text: this.getTranslation('T_REPORT.DLY_AD'),
				css: {
					'text-align': 'left',
					margin: '10px 13px 0px 7px',
				},
			},
			chart: {
				type: 'multiBarChart',
				height: 300,
				color: [
					this.adherenceColors.very_early,
					this.adherenceColors.early,
					this.adherenceColors.on_time,
					this.adherenceColors.late,
					this.adherenceColors.very_late,
				],
				noData: this.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE'),
				useInteractiveGuideline: true,
				showControls: false,
				stacked: true,
				xAxis: {
					tickFormat: this.generateXTicks,
				},
				yDomain: [0, 100],
				yAxis: {
					tickFormat: (d): string => d + '%',
				},
				showLegend: true,
			},
		};
	};

	/**
	 * retrieves a formatted value of the supplied x coordinate
	 *
	 * @param d - the x coordinate value
	 * @returns formatted value of the coordinate
	 */
	private generateXTicks = (d: any): string => {
		// Create Date from timezone-less string; will create
		// Date for local tz, but that is ignored; only day
		// month and date are extracted.
		return d3.time.format('%a %m / %d')(Moment(d).toDate());
	};
}

<div
	class="nb-report-viewer-header pointer report-viewer-header-component-container"
	data-toggle="collapse"
	data-target="#report-viewer-header-details"
	(click)="toggleDrawer()">
	<div class="nb-padding-all-md flex space-between report-view-header-container">
		<div class="nb-heading report-viewer-header-component-container" data-test="report-viewer.code">
			{{ templateSummaryContent.template.code }} | {{ templateSummaryContent.template.name }}
			<span *ngIf="templateSummaryContent.template.report_options.vehicles">
				<span *ngIf="templateSummaryContent.template.report_options.vehicles.length === 1"> - {{ 'T_REPORT.VEHICLE' | translate }} </span>
				<span *ngIf="templateSummaryContent.template.report_options.vehicles.length > 1"> - {{ 'T_REPORT.VEHICLES' | translate }} </span>
				{{ templateSummaryContent.template.report_options.vehicles | join: ', ' : 'vehicle_id' }}
			</span>
		</div>
		<button type="button" class="btn btn-link-dark" data-test="report-viewer.chevron">
			<i
				[ngClass]="
					detailsDrawer
						? 'nb-icons nb-filter-up report-viewer-header-details-drawer-btn'
						: 'nb-icons nb-filter-down report-viewer-header-details-drawer-btn'
				"></i>
		</button>
	</div>
	<div
		id="report-viewer-header-details"
		class="nb-collapsable-toolbar collapse report-viewer-header-details-container"
		aria-expanded="true">
		<report-summary-content [summaryContent]="summaryContent" [showHeader]="false"></report-summary-content>

		<report-template-summary-content
			[templateSummaryContent]="templateSummaryContent"
			[defaultTemplateId]="defaultTemplateId"
			[authorityId]="authorityId"
			[agencyId]="agencyId">
		</report-template-summary-content>
	</div>
</div>

export class DownloadHelpers {
	/**
	 * triggers a download for the paramters passed in
	 *
	 * @param fileName - the name of the filename to download
	 * @param content - the content to download
	 * @param fileType - the filetype of the filename to download
	 */
	static download = (fileName: string, content: string, fileType: string): void => {
		const csvFile: Blob = new Blob([content], { type: fileType });
		const downloadLink: HTMLAnchorElement = document.createElement('a');

		downloadLink.download = fileName;
		downloadLink.href = window.URL.createObjectURL(csvFile);
		downloadLink.style.display = 'none';
		document.body.appendChild(downloadLink);
		downloadLink.click();
	};
}

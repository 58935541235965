/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input } from '@angular/core';

import { TranslateBaseComponent } from '../../components/translate-base/translate-base.component';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'field-label',
	templateUrl: './field-label.component.html',
	styleUrls: ['./field-label.component.scss'],
})
export class LabelComponent extends TranslateBaseComponent {
	@Input() label: string = '';
	@Input() name: string;
	@Input() required: boolean = false;
	@Input() helptext: string;

	constructor(translationService: TranslationService) {
		super(translationService);
	}
}

<div class="dashboard-modal-backdrop"></div>
<div class="dashboard-modal-container">
	<div class="modal-dialog modal-lg">
		<div class="modal-content" [@modalSlideDown] *ngIf="contentVisible">
			<div class="modal-header">
				<button type="button" class="close" (click)="close()">
					<i class="ng-icon ng-close"></i>
				</button>
				<h4 class="dashboard-modal-title">{{ 'T_DASH.DB_DASHBOARD_SETTINGS' | translate }}</h4>
			</div>
			<div class="modal-body">
				<form [formGroup]="reactiveForm" class="form-group row">
					<div *ngIf="isCreateMode()">
						<div class="nb-required">
							<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
						</div>
						<label for="title" data-test="title.label">{{ 'T_DASH.DB_NAME' | translate }}</label>
						<input
							formControlName="title"
							data-test="title.input"
							type="text"
							id="title"
							class="form-control"
							placeholder="{{ 'T_DASH.DB_NAME' | translate }}" />
						<input-validation [control]="reactiveForm.controls.title"></input-validation>
					</div>
					<input type="text" hidden formControlName="structure" />
				</form>
				<structure-select [selectedStructure]="selectedStructure" (update)="update($event)"></structure-select>
			</div>
			<div class="dashboard-modal-footer">
				<button type="button" (click)="close()" class="btn btn-secondary">
					{{ 'T_DASH.DB_CANCEL' | translate }}
				</button>
				<button type="button" [disabled]="!isFormValid()" (click)="save()" class="btn btn-primary">
					{{ 'T_DASH.DB_SAVE' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

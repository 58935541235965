/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnChanges } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { HeadwaySummaryDetail } from '../../../types/api-types';

import moment from 'moment';

@Component({
	selector: 'headway-detail-chart',
	templateUrl: './headway-detail-chart.component.html',
	styleUrls: ['./headway-detail-chart.component.scss'],
})
export class HeadwayDetailChartComponent extends TranslateBaseComponent implements OnChanges {
	@Input() summaryData: HeadwaySummaryDetail = null;

	public hasResults: boolean = false;
	public options: any = null;
	public chartIsInitialized: boolean = false;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * handles changes to the input params
	 *
	 * loads the chart data
	 */
	public ngOnChanges(): void {
		this.chartIsInitialized = false;
		if (!this.summaryData) {
			return;
		}

		this.loadChart();
	}

	/**
	 * loads the headway detail chart with data
	 */
	private loadChart = (): void => {
		this.hasResults = true;
		this.setChartOptions();
		this.chartIsInitialized = true;
	};

	/**
	 * sets up the headway detail chart options
	 */
	private setChartOptions = (): void => {
		this.options = {
			chart: {
				type: 'discreteBarChart',
				height: 450,
				margin: {
					top: 20,
					right: 20,
					bottom: 65,
					left: 65,
				},
				noData: this.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE'),
				x: (d: any): any => d.stop_id,
				y: this.formatYValue,
				showValues: false,
				duration: 500,
				xAxis: {
					axisLabel: this.getTranslation('T_REPORT.STOP_ID'),
					rotateLabels: -35,
				},
				yAxis: {
					axisLabel: this.getTranslation('T_REPORT.HEADWAY'),
					axisLabelDistance: 5,
					tickFormat: (d: any): string => this.setTimes(d),
				},
				tooltip: {
					contentGenerator: this.generateTooltipContent,
				},
			},
		};
	};

	/**
	 * generates the template using the supplied object that represnts the tooltip over the chart
	 *
	 * @param obj - the object
	 * @returns template using the supplied object data
	 */
	private generateTooltipContent = (obj: any): string => {
		const content: string =
			'<div style="background-color:white"> ' +
			this.getTranslation('T_REPORT.STOP') +
			': ' +
			obj.data.stop_name +
			'<br>' +
			this.getTranslation('T_REPORT.ROUTE') +
			': ' +
			obj.data.route_long_name +
			'<br>' +
			this.getTranslation('T_REPORT.MAX') +
			': ' +
			this.setTimes(obj.data.max) +
			'<br>' +
			this.getTranslation('T_REPORT.MIN') +
			': ' +
			this.setTimes(obj.data.min) +
			'</div>';

		return content;
	};

	/**
	 * formats the Y coordinate of the chart
	 *
	 * @param y - the coordinate
	 * @returns formatted float
	 */
	private formatYValue = (y: any): number => {
		if (y.max === '-') {
			return 0;
		}

		return parseFloat(y.max);
	};

	/**
	 * formats the supplied time into hh mm ss format
	 *
	 * @param time - the time
	 * @returns frmatted time in hh mm ss
	 */
	private setTimes = (time: number): string => {
		Math.floor(time);
		if (time >= 3600) {
			return moment().startOf('day').add(time, 'seconds').format('hh:mm:ss');
		} else {
			return moment().startOf('day').add(time, 'seconds').format('mm:ss');
		}
	};
}

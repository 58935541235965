/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnChanges } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { AdherenceTotals, AdherenceColors } from '../../../types/api-types';

@Component({
	selector: 'adherence-donut-chart',
	templateUrl: './adherence-donut-chart.component.html',
	styleUrls: ['./adherence-donut-chart.component.scss'],
})
export class AdherenceDonutChartComponent extends TranslateBaseComponent implements OnChanges {
	@Input() summaryTotals: AdherenceTotals = null;

	public hasResults: boolean = false;
	public data: any;
	public chartIsInitialized: boolean = false;

	public options: {
		chart: {
			showLegend: boolean;
			tooltip: {
				valueFormatter: (d: any, i: any) => string;
				enabled: boolean;
			};
			type: string;
			title: string;
			duration: number;
			showLabels: boolean;
			labelThreshold: number;
			donut: boolean;
			width: number;
			x: (d: any) => any;
			y: (d: any) => any;
			noData: any;
			height: number;
		};
	};

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * handles changes to the input params
	 *
	 * loads the chart data
	 *
	 * @param changes - the object containing data about the changed values
	 */
	public ngOnChanges(): void {
		this.data = [
			{
				key: this.getTranslation('T_CORE.CHARTS_SCHEDULE_PERFORMANCE'),
				values: [],
			},
		];

		this.chartIsInitialized = false;

		if (!this.summaryTotals) {
			return;
		}

		this.loadChart(this.summaryTotals);
	}

	/**
	 * loads the adherence donut chart with data
	 *
	 * @param summaryTotals - the summary totals
	 */
	private loadChart = (summaryTotals: AdherenceTotals): void => {
		this.hasResults = true;

		this.setChartOptions(summaryTotals);

		const adherenceColors: AdherenceColors = {
			very_early: '#b00808',
			early: '#eb3b3b',
			on_time: '#3ac15d',
			late: '#ffb852',
			very_late: '#e89015',
		};

		this.data = [
			{
				key: this.getTranslation('T_CORE.CHARTS_VERY_LATE'),
				color: adherenceColors.very_late,
				y: summaryTotals.very_late_percent,
			},
			{
				key: this.getTranslation('T_CORE.CHARTS_LATE'),
				color: adherenceColors.late,
				y: summaryTotals.late_percent,
			},
			{
				key: this.getTranslation('T_CORE.CHARTS_ON_TIME'),
				color: adherenceColors.on_time,
				y: summaryTotals.on_time_percent,
			},
			{
				key: this.getTranslation('T_CORE.CHARTS_EARLY'),
				color: adherenceColors.early,
				y: summaryTotals.early_percent,
			},
			{
				key: this.getTranslation('T_CORE.CHARTS_VERY_EARLY'),
				color: adherenceColors.very_early,
				y: summaryTotals.very_early_percent,
			},
		];

		this.chartIsInitialized = true;
	};

	/**
	 * sets up the adherence donut chart options
	 *
	 * @param summaryTotals - the summary totals
	 */
	private setChartOptions = (summaryTotals: AdherenceTotals): void => {
		this.options = {
			chart: {
				type: 'pieChart',
				title: summaryTotals.on_time_percent + '%',
				height: 360,
				width: 360,
				donut: true,
				noData: this.getTranslation('T_CORE.CHARTS_NO_DATA_AVAILABLE'),
				x(d): string {
					return d.key;
				},
				y(d): string {
					return d.y;
				},
				showLabels: true,
				showLegend: false,
				labelThreshold: 0.045,
				duration: 500,
				tooltip: {
					enabled: true,
					valueFormatter: (d): string => d + '%',
				},
			},
		};
	};
}

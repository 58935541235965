/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { TranslateBaseComponent } from '../translate-base/translate-base.component';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'not-authorized',
	templateUrl: './not-authorized.component.html',
	styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent extends TranslateBaseComponent {
	constructor(
		private location: Location,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handles the user go back click
	 */
	public goBack = (): void => {
		this.location.back();
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Input, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

@Component({
	selector: 'block-status-display',
	templateUrl: './block-status-display.component.html',
	styleUrls: ['./block-status-display.component.scss'],
})
export class BlockStatusDisplayComponent extends TranslateBaseComponent implements OnInit {
	@Input() status: string = null;

	public style: { 'border-radius': string; border: string; color: string; width: string; 'text-align': string };
	public translatedStatus: string;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the blocks status display components
	 *
	 * creates formatted text value and correct styling for template
	 */
	public ngOnInit(): void {
		this.style = this.getStatusStyle(this.status);
		this.translatedStatus = this.determineStatus(this.status);
	}

	/**
	 * determines translated vaersion of the supplied block status
	 *
	 * @param status - the block status
	 * @returns formatted translated block status
	 */
	public determineStatus = (status: string): string => {
		const tranlateKey: string = this.getTranslateKey(status);

		return this.getTranslation(tranlateKey);
	};

	/**
	 * determines the styling for the supplied block status
	 *
	 * @param status - the block status
	 * @returns template styling for the block status
	 */
	private getStatusStyle = (status: string): any => {
		const style: any = {
			'border-radius': '.25em',
			'text-align': 'center',
			border: '1px solid black',
			width: '80%',
			color: '',
		};

		if (status === 'Missed') {
			style['background-color'] = '#BC0000';
			style.color = 'white';
		} else if (status === 'Upcoming') {
			style['background-color'] = '#DBDBDB';
			style.color = 'black';
		} else if (status === 'Unscheduled') {
			style['background-color'] = '#EF8D07';
			style.color = 'white';
		} else {
			style['background-color'] = '#005389';
			style.color = 'white';
		}

		return style;
	};

	/**
	 * translates the supplied block status
	 *
	 * @param status - the block status
	 * @returns the translated block status
	 */
	private getTranslateKey = (status: string): string => {
		if (status === 'Missed') {
			return 'T_REPORT.BLOCK_MISSED';
		} else if (status === 'Upcoming') {
			return 'T_REPORT.BLOCK_UPCOMING';
		} else if (status === 'Unscheduled') {
			return 'T_REPORT.BLOCK_UNSCHEDULED';
		}

		return 'T_REPORT.BLOCK_ASSIGNED';
	};
}

export interface PaginationSettings {
	pageSize: number;
	maxPageSize: number;
}

export interface Pagination {
	currentPage: number;
	firstItemNumber: number;
	lastItemNumber: number;
	numPages: number;
	totalItems: number;
}

export const DefaultPagination: Pagination = {
	currentPage: 1,
	firstItemNumber: 0,
	lastItemNumber: 0,
	numPages: 0,
	totalItems: 0,
};

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { TranslationService } from '@cubicNx/libs/utils';

import { PasswordExpiryWarningComponent } from '../../login-form/password-expiry-warning/password-expiry-warning.component';
import { ConfirmModalComponent } from '@cubicNx/libs/utils';
import { ConfirmButtonType } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class LoginModalService {
	constructor(
		private passwordExpiryWarningModal: MatDialog,
		private translationService: TranslationService
	) {}

	/**
	 * displays a warning modal informing the user that their password is expiring
	 *
	 * @param days - the number of days before the password expires
	 */
	public passwordExpiryWarning = async (days: number): Promise<void> => {
		this.passwordExpiryWarningModal.open(ConfirmModalComponent, {
			width: '600px',
			position: {
				top: '60px',
			},
			data: {
				header: this.translationService.getTranslation('T_DASH.DB_PASSWORD_EXPIRE_WARING_HEADING'),
				confirmButtonType: ConfirmButtonType.okType,
				component: PasswordExpiryWarningComponent,
				params: {
					warningMessage: this.translationService.getTranslation('T_DASH.DB_PASSWORD_EXPIRE_WARNING_MESSAGE'),
					daysText: this.translationService.getTranslation('T_DASH.DB_DAYS'),
					days,
				},
			},
		});
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AsyncTimeout } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { PredictionsDataService } from '../services/predictions-data.service';
import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { TimeHelpers } from '@cubicNx/libs/utils';
import { PredictionsModalService } from '../services/predictions-modal.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { SelectedAgency } from '../../../support-features/agencies/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { Columns, ColumnType, ListSortings, SelectedRowData, SortDirection } from '@cubicNx/libs/utils';
import { DisablePredictionTableData, DisablePredictionTableDataRow, AllRouteId, DisablePredictionCloseType } from '../types/types';
import { DisabledPrediction, DisabledPredictions, FeedTypes } from '../types/api-types';
import { Route, RouteConfig, Routes } from '../types/api-types';

import moment, { Moment } from 'moment';

@Component({
	selector: 'disable-predictions',
	templateUrl: './predictions-list.component.html',
	styleUrls: ['./predictions-list.component.scss'],
})
export class PredictionsListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	// pass through the defaukt sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'from', dir: SortDirection.desc }];

	public columns: Columns = [];
	public disabledPredictionsPrevious: DisabledPredictions = [];
	public disabledPredictions: DisabledPredictions = [];
	public disablePredictionFullTableData: DisablePredictionTableData = [];
	public disablePredictionFilteredTableData: DisablePredictionTableData = [];
	public routes: Routes = [];
	public loadingIndicator: boolean = true;
	public translationsLoaded: boolean = false;
	public awaitingDisabledPredictionUpdate: boolean = false;

	private readonly allRoutesFilterId: string = 'all_routes';
	private readonly predictionActiveClass: string = 'prediction-active';
	private readonly predictionDisabledClass: string = 'prediction-disabled';
	private readonly predictionPendingDisabledClass: string = 'prediction-pending-disabled';
	private readonly pollIntervalDefaultMs: number = 60000;

	private agenciesSelectionChange$Subscription: Subscription = null;
	private allRoutesText: string = null;
	private feedTypes: FeedTypes = [];
	private selectedRouteFilterId: string = null;

	private agencyId: string = null;
	private authorityId: string = null;
	private timeZone: string = null;
	private pollTimer: any = null;

	constructor(
		private predictionsDataService: PredictionsDataService,
		private agenciesEventsService: AgenciesEventsService,
		private agenciesDataService: AgenciesDataService,
		private logger: LoggerService,
		private predictionsModalService: PredictionsModalService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * Initialises the page (getting what data is required from back end)
	 */
	public ngOnInit(): void {
		this.setSubscriptions();

		this.initTranslations([
			'T_CORE.ROUTE',
			'T_PREDICTION.FEED',
			'T_PREDICTION.FROM',
			'T_PREDICTION.TO',
			'T_PREDICTION.DISABLED_BY',
			'T_PREDICTION.ALL_ROUTES',
			'T_CORE.STOPS',
		]).then((value) => {
			this.translationsLoaded = value;

			this.getSelectedAgency();

			this.allRoutesText = '*' + this.getTranslation('T_PREDICTION.ALL_ROUTES') + '*';

			this.buildTableColumns();

			this.getData();

			this.startPollTimer();
		});
	}

	/**
	 * Stores the selected route Id and updates the view with correct route filter
	 *
	 * @param target - the target
	 */
	public routeChanged(target: EventTarget): void {
		const element: HTMLInputElement = target as HTMLInputElement;
		const routeId: string = element.value;

		this.selectedRouteFilterId = routeId;
		this.filterDataView(this.selectedRouteFilterId);
	}

	/**
	 * Opens the disable prediction modal in create mode
	 */
	public openDisablePredictionCreate = async (): Promise<void> => {
		const allRowPredictionData: DisabledPrediction = this.disabledPredictions.filter((item) => item.routeId === AllRouteId)[0];

		const selectedRouteId: string = null;

		let predictionData: DisabledPrediction = null;
		let isDisableAllActive: boolean = false;

		if (allRowPredictionData) {
			predictionData = allRowPredictionData;
			isDisableAllActive = true;
		}

		await this.predictionsModalService
			.openEdit(this.authorityId, this.timeZone, selectedRouteId, predictionData, isDisableAllActive)
			.subscribe((disablePredictionCloseType: DisablePredictionCloseType) => {
				this.handlEditClose(disablePredictionCloseType);
			});
	};

	/**
	 * Opens the disable prediction modal in edit mode
	 *
	 * @param selectedRow - the selected row
	 */
	public onSelect = async (selectedRow: SelectedRowData): Promise<void> => {
		const row: DisablePredictionTableDataRow = selectedRow.row;

		const selectedRouteId: string = row.routeId;

		// check our rendered data for the individual row to cover an edge case where we can see stored disabled prediction
		// records but where the route no longer exists ultimatately this is a backend data issue but the UI will just render
		// these rows anyway with a zero stop count.
		// If this edge case has occured with the data just don't open the modal as this means we have no route config for it
		const filteredPredictionData: DisablePredictionTableDataRow = this.disablePredictionFullTableData.filter(
			(predictionData) => predictionData.routeId === selectedRouteId
		)[0];

		// Cover the above scenario but we also need to allow for 'All' routes having no stops
		if (filteredPredictionData.stopsTotal > 0 || selectedRouteId === AllRouteId) {
			// grab the raw individual prediction data record for the chosen row ready for our modal popup
			const filteredData: DisabledPrediction = this.disabledPredictions.filter((item) => item.id === selectedRow.row.id)[0];

			await this.predictionsModalService
				.openEdit(this.authorityId, this.timeZone, selectedRouteId, filteredData, false)
				.subscribe((disablePredictionCloseType: DisablePredictionCloseType) => {
					this.handlEditClose(disablePredictionCloseType);
				});
		}
	};

	/**
	 * fires when the agency is updated in the control
	 */
	public handleAgencyUpdated = (): void => {
		this.agenciesEventsService.publishAgenciesSelectionChange();
	};

	/**
	 * fires when the component is destroyed/closed
	 */
	public ngOnDestroy(): void {
		this.clearPolling();
		this.unsubscribe();
	}

	/**
	 * builds the list columns
	 */
	private buildTableColumns = (): void => {
		// build the column list for the underlying datatable.
		// note: the each name must correspond to a value to our row data passed in (disablePredictionFilteredTableData in this case)
		this.columns = [
			{
				name: 'routeText',
				displayName: this.translations['T_CORE.ROUTE'],
				columnType: ColumnType.subText,
				subText: 'routeSubText',
			},
			{
				name: 'feed',
				displayName: this.translations['T_PREDICTION.FEED'],
				columnType: ColumnType.text,
			},
			{
				name: 'from',
				displayName: this.translations['T_PREDICTION.FROM'],
				columnType: ColumnType.date,
				format: 'MM/dd/YYYY hh:mm a',
			},
			{
				name: 'to',
				displayName: this.translations['T_PREDICTION.TO'],
				columnType: ColumnType.date,
				format: 'MM/dd/YYYY hh:mm a',
			},
			{
				name: 'disabledBy',
				displayName: this.translations['T_PREDICTION.DISABLED_BY'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * gets (and sets the local var) the selected agency and sets
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;

			this.timeZone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
		}
	};

	/**
	 * starts the polling timer to poll the data
	 *
	 * @param interval - the timeout for the next 'poll'
	 */
	private startPollTimer = (interval: number = this.pollIntervalDefaultMs): void => {
		this.pollTimer = new AsyncTimeout();

		this.pollTimer.start(interval).then(async () => {
			await this.pollData();
		});
	};

	/**
	 * polls the data (more frequently if an update is anticipated)
	 */
	private pollData = async (): Promise<void> => {
		const nextInterval: number = this.pollIntervalDefaultMs;

		// store the previous request
		this.disabledPredictionsPrevious = [...this.disabledPredictions];

		// request the data (this will request and update the data table that is rendered by the view)
		await this.requestAndBuildPredictionData();

		// if we are expecting data - turn off loading spinner if so
		if (this.awaitingDisabledPredictionUpdate) {
			this.awaitingDisabledPredictionUpdate = false;
			this.loadingIndicator = false;

			// sanity check - don't technically need to do this but helps with clarity and provides debugging info
			if (this.disablePredictionDataUpdateReceived()) {
				this.logger.logDebug('Disabled Predictions update received');
			} else {
				this.logger.logDebug('Disabled Predictions update not received');
			}
		}

		// restart the timer
		this.startPollTimer(nextInterval);
	};

	/**
	 * determines if the latest prediction data has changed from the previous request
	 *
	 * @returns a flag indicating if the data has changed
	 */
	private disablePredictionDataUpdateReceived = (): boolean => {
		// check for a change in the data
		return JSON.stringify(this.disabledPredictionsPrevious) !== JSON.stringify(this.disabledPredictions);
	};

	/**
	 * gets all the required data (lookup routes/feeds) and prediction data
	 */
	private getData = async (): Promise<void> => {
		this.loadingIndicator = true;

		// default to all routes selected as that will be chosen due to being first in the select control
		this.selectedRouteFilterId = this.allRoutesFilterId;

		this.routes = await this.getRoutes();
		this.feedTypes = await this.getFeedTypes();

		await this.requestAndBuildPredictionData();

		this.loadingIndicator = false;
	};

	/**
	 * gets the prediction data from the backend and builds the data in the required format for the page
	 */
	private requestAndBuildPredictionData = async (): Promise<void> => {
		this.disabledPredictions = await this.getDisabledPredictions();

		if (this.disabledPredictions.length > 0) {
			await this.buildDisablePredictionTableData();

			// filter the data - also forces a refresh in the view
			this.filterDataView(this.selectedRouteFilterId);
		}
	};

	/**
	 * build the route list (adds an 'all routes' option to the start of the list)
	 *
	 * @param routes - the raw route data
	 * @returns the routes list
	 */
	private buildRouteSelectionData = (routes: Routes): Routes => {
		const allRouteOption: Route = {
			id: this.allRoutesFilterId,
			title: this.allRoutesText,
			hidden: false,
		};

		// add to the start of the list
		routes.unshift(allRouteOption);

		return routes;
	};

	/**
	 * builds the prediction data in the required format for the page (ngx data list)
	 */
	private buildDisablePredictionTableData = async (): Promise<void> => {
		// reset the table data
		this.disablePredictionFullTableData = [];

		// use regular for loop rather than foreach as foreach isnt compatible with inner await
		for (const disabledPrediction of this.disabledPredictions) {
			const route: Route = this.routes.filter((r) => r.id === disabledPrediction.routeId)[0];

			// Convert incoming unix time (UTC) to agency local time for to and from
			const fromMomentTZ: Moment = moment.utc(disabledPrediction.timeStart).tz(this.timeZone);
			const toMomentTZ: Moment = moment.utc(disabledPrediction.timeEnd).tz(this.timeZone);

			// Create Date objects from these moments for list display purposes only
			const from: Date = TimeHelpers.createNewDateFromMoment(fromMomentTZ);
			const to: Date = TimeHelpers.createNewDateFromMoment(toMomentTZ);

			let disabledStops: number = 0;
			let stopsTotal: number = 0;
			let routeSubText: string = '';

			// No need to check route config and stops if this is an 'ALL' entry
			const isAllRoutes: boolean = disabledPrediction.routeId === AllRouteId;

			if (!isAllRoutes) {
				disabledStops = disabledPrediction.disabledStopList.length;
				stopsTotal = await this.getStopCount(disabledPrediction.routeId);
				routeSubText = '(' + disabledStops + '/' + stopsTotal + ') ' + this.translations['T_CORE.STOPS'];
			}

			const row: DisablePredictionTableDataRow = {
				id: disabledPrediction.id,
				routeId: disabledPrediction.routeId,
				routeSubText,
				routeText: route ? route.title : disabledPrediction.routeId,
				disabledStops,
				stopsTotal,
				feed: this.feedTypes.filter((feedType) => feedType.value === disabledPrediction.feedTypeValue)[0].description,
				from,
				to,
				disabledBy: disabledPrediction.userId,
				rowClass: this.setRowClass(fromMomentTZ, disabledPrediction.enabled),
			};

			this.disablePredictionFullTableData.push(row);
		}
	};

	/**
	 * gets the total stop count for all of the directions combined
	 *
	 * @param routeId - the route id
	 * @returns the stop count for the route
	 */
	private getStopCount = async (routeId: string): Promise<number> => {
		let stopCount: number = 0;

		const routeConfig: RouteConfig = await this.getRouteConfig(routeId);

		if (routeConfig) {
			// count the number of stops in each direction configured to 'useForUi'
			routeConfig.directions
				.filter((direction) => direction.useForUi === true)
				.forEach((direction) => {
					stopCount += direction.stops.length;
				});
		}

		return stopCount;
	};

	/**
	 * sets the style class for the individual row
	 *
	 * @param from - the predictor from field for the current row being processed
	 * @param enabled - the enabled state for the current row being processed
	 * @returns style class name containing the correct color for the row
	 */
	private setRowClass = (from: Moment, enabled: boolean): any => {
		let rowClassName: string = this.predictionActiveClass;

		// Get moment in time for now but in the agency timezone in readiness for the calculation
		const now: Moment = moment.utc(new Date()).tz(this.timeZone);

		// return the appropriate style class - note enabled flag seems to be set wrong way round
		if (enabled) {
			if (from > now) {
				rowClassName = this.predictionPendingDisabledClass;
			} else {
				rowClassName = this.predictionDisabledClass;
			}
		}

		return rowClassName;
	};

	/**
	 * sets the list object used in the view based on the appropraite route filter
	 *
	 * @param routeId - the route id
	 */
	private filterDataView = (routeId: string): void => {
		if (routeId === this.allRoutesFilterId) {
			// just grab the full data set
			this.disablePredictionFilteredTableData = this.disablePredictionFullTableData;
		} else {
			// filter based on the route id
			this.disablePredictionFilteredTableData = this.disablePredictionFullTableData.filter(
				(predictionData) => predictionData.routeId === routeId
			);
		}

		// force a refresh - angular doesn't fire a change detection with arrarys as changing the data doesn't change the reference
		// this is a workaround for that issue.
		this.disablePredictionFilteredTableData = [...this.disablePredictionFilteredTableData];
	};

	/**
	 * gets the route data from the backend/cache
	 *
	 * @returns the route data
	 */
	private getRoutes = async (): Promise<Routes> => {
		const response: ResultContent = await this.predictionsDataService.getRoutes(this.authorityId);

		if (response.success) {
			// take a copy of the routes - not the 'referenced' cached data as we will be adding to this collection
			const routes: any[] = [...response.resultData];

			return this.buildRouteSelectionData(routes);
		}

		return null;
	};

	/**
	 * gets the feed type data from the backend/cache
	 *
	 * @returns the feed type data
	 */
	private getFeedTypes = async (): Promise<FeedTypes> => {
		const response: ResultContent = await this.predictionsDataService.getFeedTypes(this.authorityId);

		if (response.success) {
			return response.resultData;
		}

		return null;
	};

	/**
	 * gets the prediction data from the backend/cache
	 *
	 * @returns the feed type data
	 */
	private getDisabledPredictions = async (): Promise<DisabledPredictions> => {
		const response: ResultContent = await this.predictionsDataService.getDisabledPredictions(this.authorityId);

		if (response.success) {
			return response.resultData;
		}

		return [];
	};

	/**
	 * gets the individual route config from the backend/cache
	 *
	 * @param routeId - the route id
	 * @returns the feed type data
	 */
	private getRouteConfig = async (routeId: string): Promise<RouteConfig> => {
		// prediction service will handle returning of cached data if available
		const response: ResultContent = await this.predictionsDataService.getRouteConfig(this.authorityId, routeId);

		if (response.success) {
			return response.resultData;
		}

		return null;
	};

	/**
	 * handles the close of the disable prediction modal (and resets the polling if the close is due to an update)
	 *
	 * @param disablePredictionCloseType - details of the close type including details of the update if saved
	 */
	private handlEditClose = async (disablePredictionCloseType: DisablePredictionCloseType): Promise<void> => {
		if (disablePredictionCloseType.saved) {
			this.loadingIndicator = true;
			this.awaitingDisabledPredictionUpdate = true;

			await this.predictionsDataService.disablePredictionUpdate(
				this.authorityId,
				disablePredictionCloseType.disablePredictionUpdate,
				disablePredictionCloseType.isDelete
			);

			this.logger.logDebug('Awaiting disable predictions update');

			// user has made an update - cancel the current polling timer and get the data now
			this.clearPolling();

			// restart the polling tbut request immediately
			this.startPollTimer(0);
		}
	};

	/**
	 * sets any subscriptions required for the component
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgencySelectionChange();
		});
	};

	/**
	 * handles the agency change and requests the data again
	 */
	private handleAgencySelectionChange = (): void => {
		// clear the current list
		this.disablePredictionFilteredTableData = [];

		this.getSelectedAgency();
		this.getData();
	};

	/**
	 * Unsubscribes from and observables.
	 */
	private unsubscribe = (): void => {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
	};

	/**
	 * clears the polling timer
	 */
	private clearPolling = (): void => {
		if (this.pollTimer) {
			this.pollTimer.cancel();
			this.pollTimer = null;
		}
	};
}

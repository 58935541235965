<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".-->

<div class="panel-header-container">
	<panel-header class="messages-panel-header" [showBackButton]="false" [heading]="'T_MAP.MAP_VEHICLE_MESSAGES' | translate"> </panel-header>
	<current-agency
		class="nb-panel-header"
		[titleText]="'T_MAP.MAP_AGENCY' | translate"
		[isDisabled]="false"
		(agencyUpdated)="handleAgencyUpdated()"></current-agency>
</div>
<div class="clearfix create-container">
	<div class="col-xs-12 nb-padding-left-md nb-padding-top-sm nb-padding-bottom-sm">
		<button (click)="createMessage()" class="btn btn-primary pull-left">
			<i class="nb-icons nb-add"></i>{{ 'T_MESSAGES.CREATE' | translate }}
		</button>
	</div>
</div>
<hr />
<div class="header-container">
	<div class="search-filter-container">
		<search-filter [filterValue]="searchText" (search)="search($event)"></search-filter>
	</div>
</div>

<!-- template to pass in to the data table for our route pill column. Note row will contain the list row at runtime (see underlying data table) -->
<ng-template #routePillColumnTemplate let-row="row">
	<route-pill *ngIf="row.route" [route]="determineRoutePillData(row.route)"></route-pill>
</ng-template>

<div class="data-list-container" *ngIf="initailized">
	<data-table
		data-test="vehicle-messages.list.table"
		[listName]="listName"
		[columns]="columns"
		[rowData]="vehicleMessagesList"
		[pagingEnabled]="true"
		[totalRows]="totalRows"
		[defaultSortings]="defaultSortings"
		[pageInfo]="pageInfo"
		[enableCheckRowSelection]="false"
		[enabledMultiCheckRowSelection]="false"
		[enableMenuSelection]="false"
		[enableFooter]="true"
		[loadingIndicator]="listLoadingIndicator"
		(requestData)="handleDataRequest($event)">
	</data-table>
</div>

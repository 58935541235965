/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { StopsPerDestinations } from '../types/types';
import { Observable } from 'rxjs';
import { RiderMessagesEditComponent } from '../edit/rider-messages-edit.component';
import { StopSelectionComponent } from '../edit/routes/stops/stop-selection.component';
import { ConfirmModalComponent } from '@cubicNx/libs/utils';

import { RiderMessagesDataService } from './rider-messages-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { ConfirmResult } from '@cubicNx/libs/utils';
import { ConfirmButtonType } from '@cubicNx/libs/utils';

@Injectable()
export class RiderMessagesModalService {
	constructor(
		private riderMessagesDataService: RiderMessagesDataService,
		private translationService: TranslationService,
		private createMessageModal: MatDialog,
		private editMessageModal: MatDialog,
		private editStopsModal: MatDialog,
		private deleteMessageModal: MatDialog
	) {}

	/**
	 * renders the create rider message dialog
	 *
	 * @returns true if the user creation attempt was successful
	 */
	public createMessage = (): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<RiderMessagesEditComponent> = this.createMessageModal.open(RiderMessagesEditComponent, {
				width: '938px',
				data: {
					isCreate: true,
				},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe(async (messageCreated: boolean) => {
				observer.next(messageCreated);
			});
		});
	};

	/**
	 * renders the edit rider message dialog
	 *
	 * @param serviceAlertId - the rider message id/
	 * @returns true if the user edit attempt was successful
	 */
	public editMessage = (serviceAlertId: string): Observable<boolean> => {
		return new Observable((observer) => {
			const modalRef: MatDialogRef<RiderMessagesEditComponent> = this.editMessageModal.open(RiderMessagesEditComponent, {
				width: '938px',
				minHeight: '785px', // min height so loading spinner takes up full space
				data: {
					isCreate: false,
					serviceAlertId,
				},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe(async (messageEdited: boolean) => {
				observer.next(messageEdited);
			});
		});
	};

	/**
	 * renders the delete rider message(s) dialog
	 *
	 * if the user confirms the delete, then the delete is attempted
	 *
	 * @param serviceAlertIds - the message ids being deleted
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @returns true if the delete was confirmed
	 */
	public deleteMessages = (serviceAlertIds: string[], authorityId: string, agencyId: string): Observable<boolean> => {
		const message: string =
			serviceAlertIds.length > 1
				? this.translationService.getTranslation('T_MESSAGES.MESSAGE_MULTI_DELETE_CONFIRM')
				: this.translationService.getTranslation('T_MESSAGES.MESSAGE_DELETE_CONFIRM');

		const header: string = this.translationService.getTranslation('T_MESSAGES.MESSAGE_DELETE_CONFIRM_HEADING');

		return new Observable((observer) => {
			const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteMessageModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message,
					header,
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				if (result?.confirmed) {
					const deleteResult: ResultContent = await this.riderMessagesDataService.deleteMessages(
						serviceAlertIds,
						authorityId,
						agencyId
					);

					observer.next(deleteResult.success);
				}
			});
		});
	};

	/**
	 * renders the edit stops dialog
	 *
	 * @param stopsPerDestinations - the stops per destinations
	 * @returns the stops per destination once the dialog has been saved
	 */
	public editStops = (stopsPerDestinations: StopsPerDestinations): Observable<StopsPerDestinations> => {
		return new Observable((observer) => {
			const destinations: number = stopsPerDestinations.length;

			// increase width for edge cases with more destinations
			let width: string = '940px';

			if (destinations > 4) {
				width = '1540px';
			} else if (destinations > 3) {
				width = '1340px';
			}

			const modalRef: MatDialogRef<StopSelectionComponent> = this.editStopsModal.open(StopSelectionComponent, {
				width,
				data: {
					stopsPerDestinations,
				},
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe(async (stopsPerDests: StopsPerDestinations) => {
				observer.next(stopsPerDests);
			});
		});
	};
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join',
})
export class JoinPipe implements PipeTransform {
	/**
	 * This pipe will join the elements of the supplied input array using the supplied seperator (default ', ' if not supplied).
	 * If a joinField is supplied, then the joinField will be the property of each input element to use as the thing to join together.
	 *
	 * @param input - the array of values to join
	 * @param seperator - the separator charctor between input text (typically a comma)
	 * @param joinField - property within the object to join
	 * @returns the formatted 'joined' text
	 */
	transform(input: Array<any>, seperator: string = ', ', joinField: string = null): string {
		if (joinField) {
			const values: any[] = input.map((a) => a[joinField]);

			return values.join(seperator);
		} else {
			return input.join(seperator);
		}
	}
}

@Pipe({
	name: 'joinByIndividualFields',
})
export class JoinByIndividualFields implements PipeTransform {
	/**
	 * This pipe will join the elements of the supplied input array by a specific field for each input element.
	 * The specific field to be used for the join is supplied within the joinFields array.
	 * ie if there are 3 elements in the input, then the first element's field that matches the first element
	 * of the joinFields array will be used and so on.
	 * A 1-2-1 mapping.
	 *
	 * @param input - the array of values to join
	 * @param seperator - the separator charctor between input text (typically a comma)
	 * @param joinFields - array of property values to join
	 * @returns the formatted 'joined' text
	 */
	transform(input: Array<any>, seperator: string = ', ', joinFields: Array<string> = null): string {
		if (joinFields) {
			const values: any[] = input.map((a, index) => a[joinFields[index]]);

			return values.join(seperator);
		} else {
			return input.join(seperator);
		}
	}
}

import { IconType, CssClassType } from '@cubicNx/libs/utils';

export enum UploadHistoryDetailsType {
	confirmationNumber,
	status,
}

export interface SchedulesListItem {
	agencyName: string;
	authorityId: string;
	agencyId: string;
}

export type SchedulesList = Array<SchedulesListItem>;

export interface UploadHistoryDataListItem {
	seq: number;
	startDate: string;
	productType: string;
	confirmationNumber: CssClassType;
	status: IconType;
	updateComments: string;
}
export type UploadHistoryDataList = Array<UploadHistoryDataListItem>;

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { WidgetEventsService } from '../../../services/widget-events.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { AgencyDetail } from '../../../../../support-features/agencies/types/api-types';

@Component({
	selector: 'external-links-view',
	templateUrl: './external-links-view.component.html',
	styleUrls: ['./external-links-view.component.scss'],
})
export class ExternalLinksViewComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() data: any;
	@Input() rowData: any;

	public reloadWidget$Subscription: Subscription = null;
	public loaded: boolean = false;
	public success: boolean = false;
	public hasData: boolean = false;
	public markdownContent: string = null;

	constructor(
		private widgetEventsService: WidgetEventsService,
		private agenciesDataService: AgenciesDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the external links view component - setting up subscriptions and loading widget data
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		this.getData();
	}

	/**
	 * Publishes an open widget edit modal event.
	 */
	public openEditWidget = (): void => {
		this.widgetEventsService.publishOpenWidgetEditModal({ widget: this.data });
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * retrieves widget data
	 */
	private getData = async (): Promise<void> => {
		this.loaded = false;
		this.hasData = false;
		this.success = false;

		const result: ResultContent = await this.agenciesDataService.getAgency(
			this.data.config?.selectedAgency.authority_id,
			this.data.config?.selectedAgency.agency_id
		);

		if (result.success) {
			const agency: AgencyDetail = result.resultData;

			if (agency.important_links) {
				this.markdownContent = agency.important_links;
				this.hasData = true;
			}

			this.success = true;
		}

		this.loaded = true;
	};

	/**
	 * sets up subscriptions - is interested when the widget is reloaded
	 */
	private setSubscriptions = (): void => {
		this.reloadWidget$Subscription = this.widgetEventsService.reloadWidget.subscribe((event) => {
			if (event.widgetId === this.data.wid) {
				this.getData();
			}
		});
	};

	/**
	 * Unsubscribes from any observables.
	 */
	private unsubscribe = (): void => {
		this.reloadWidget$Subscription?.unsubscribe();
	};
}

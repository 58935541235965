/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { UserStatusChangeComponent } from '../shared/user-status-change/user-status-change.component';
import { UserAdminProfileEditComponent } from '../user-profile/edit/user-admin-profile-edit.component';
import { UserAdminListFilterComponent } from '../list/filter/user-admin-list-filter.component';
import { ConfirmModalComponent } from '@cubicNx/libs/utils';
import { ChangePasswordComponent } from '../password/change-password/change-password.component';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { CurrentUserPermissionService } from '../../../../support-features/login/services/current-user/current-user-permission.service';
import { UsersAdminDataService } from './users-admin-data.service';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { SelectedUserFilters, UserFiltersUpdate } from '../types/types';
import { UserDetail } from '../types/api-types';
import { ConfirmResult, ConfirmButtonType } from '@cubicNx/libs/utils';
import { PermissionRequestActionType } from './../../../../support-features/login/types/types';
import { PermissionRequest } from '../../../../support-features/login/types/types';
import { ResultContent } from '@cubicNx/libs/utils';

@Injectable({
	providedIn: 'root',
})
export class UsersAdminModalService {
	constructor(
		private usersAdminDataService: UsersAdminDataService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private notificationService: NotificationService,
		private translationService: TranslationService,
		private changePasswordModal: MatDialog,
		private userStatusChangeModal: MatDialog,
		private userProfileEditModal: MatDialog,
		private openFilterModal: MatDialog,
		private resetPasswordModal: MatDialog
	) {}

	/**
	 * presents a dialog for changing a users password - permission is checked first
	 *
	 * @param selectedUser - user details
	 */
	public changePassword = (selectedUser: UserDetail): void => {
		const permissionRequest: PermissionRequest = {
			user: selectedUser,
		};

		if (this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.editUser, permissionRequest)) {
			this.changePasswordModal.open(ChangePasswordComponent, {
				width: '600px',
				data: {},
				position: {
					top: '60px',
				},
			});
		} else {
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
		}
	};

	/**
	 * presents a dialog for resetting a users password - permission is checked first
	 * if the user confirms the rese, the whole reset password process is initiated
	 *
	 * @param selectedUser - user details
	 */
	public resetPassword = (selectedUser: UserDetail): void => {
		const permissionRequest: PermissionRequest = {
			user: selectedUser,
		};

		if (this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.editUser, permissionRequest)) {
			const username: string = selectedUser.username;
			const email: string = selectedUser.email;

			const modalRef: MatDialogRef<ConfirmModalComponent> = this.resetPasswordModal.open(ConfirmModalComponent, {
				width: '600px',
				position: {
					top: '60px',
				},
				data: {
					message: this.translationService.getTranslation('T_CORE.PW_RESET_CONFIRM') + ' ' + username + ' - ' + email + '?',
					header: this.translationService.getTranslation('T_CORE.PW_RESET_CONFIRM_TITLE'),
					confirmButtonType: ConfirmButtonType.yesNoType,
				},
			});

			modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
				if (result.confirmed) {
					await this.usersAdminDataService.resetPasswordInit(username, email, true);
				}
			});
		}
	};

	/**
	 * presents the user create/edit dialog - permission is checked first
	 *
	 * @param selectedUser - user details
	 * @param isCreate - true if this is a create, false if it is an edit
	 * @returns whether the update happened or not
	 */
	public openUserEditor = (selectedUser: UserDetail, isCreate: boolean): Observable<boolean> => {
		return new Observable((observer) => {
			const permissionRequest: PermissionRequest = {
				user: selectedUser,
			};

			if (this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.editUser, permissionRequest)) {
				const modalRef: MatDialogRef<UserAdminProfileEditComponent> = this.userProfileEditModal.open(
					UserAdminProfileEditComponent,
					{
						width: '1000px',
						height: 'calc(80vh + 113px)', // fixed height (rather than maxHeight) so loading spinner takes up full space
						data: {
							isCreate,
							selectedUserId: selectedUser?.nb_id,
						},
						position: {
							top: '60px',
						},
					}
				);

				modalRef.afterClosed().subscribe(async (saved: boolean) => {
					if (saved) {
						observer.next(saved);
					}
				});
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
				observer.next(null);
			}
		});
	};

	/**
	 * presents the confirm user status change dialog - permission is checked first
	 * if the user confirms the update, the account status is then updated
	 *
	 * @param selectedUser - user details
	 * @param newStatus - new user status
	 * @param note - note
	 * @param message - message for the confirmation on the dialog
	 * @param header - header for the confirmation on the dialog
	 * @param successMsg - message to be displayed to the user if successful
	 * @param failureMsg - message to display to the user if failed
	 * @returns result of the update
	 */
	public confirmStatusChange = (
		selectedUser: UserDetail,
		newStatus: string,
		note: string,
		message: string,
		header: string,
		successMsg: string,
		failureMsg: string
	): Observable<boolean> => {
		return new Observable((observer) => {
			const permissionRequest: PermissionRequest = {
				user: selectedUser,
			};

			if (this.currentUserPermissionService.hasPermissionTo(PermissionRequestActionType.updateUserStatus, permissionRequest)) {
				const modalRef: MatDialogRef<ConfirmModalComponent> = this.userStatusChangeModal.open(ConfirmModalComponent, {
					width: '600px',
					position: {
						top: '60px',
					},
					data: {
						message,
						header,
						component: UserStatusChangeComponent,
						confirmButtonType: ConfirmButtonType.yesNoType,
						params: {
							user: selectedUser,
						},
					},
				});

				modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
					if (result?.confirmed) {
						const response: ResultContent = await this.usersAdminDataService.updateAccountStatus(
							selectedUser.nb_id.toString(),
							newStatus,
							note,
							successMsg,
							failureMsg
						);

						// pass the result of the update back to the caller so it can refresh
						observer.next(response.success);
					}
				});
			} else {
				this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.NO_PERMISSION'));
				observer.next(null);
			}
		});
	};

	/**
	 * presents the user filter dialog
	 *
	 * @param selectedUserFilters - user filter content
	 * @returns contents of the dialog is published
	 */
	public openFilter = (selectedUserFilters: SelectedUserFilters): Observable<UserFiltersUpdate> => {
		// set the initial selected filters.  Ensure we take a copy as otherwise it will affect the original
		// from the parent and we don't want edit that object within the modal as we may cancel changes
		const selectedUserFiltersCopy: SelectedUserFilters = ObjectHelpers.deepCopy(selectedUserFilters);

		return new Observable((observer) => {
			const modalRef: MatDialogRef<UserAdminListFilterComponent> = this.openFilterModal.open(UserAdminListFilterComponent, {
				width: '600px',
				data: { selectedUserFilters: selectedUserFiltersCopy },
				position: {
					top: '60px',
				},
			});

			return modalRef.afterClosed().subscribe(async (result: UserFiltersUpdate) => {
				observer.next(result);
			});
		});
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WidgetEventsService {
	public openWidgetEditModal: Observable<any>;
	public reloadWidget: Observable<any>;
	public widgetConfigChanged: Observable<any>;

	private openWidgetEditModalSource: Subject<any> = new Subject<any>();
	private reloadWidgetSource: Subject<any> = new Subject<any>();
	private widgetConfigChangedSource: Subject<any> = new Subject<any>();

	constructor() {
		this.openWidgetEditModal = this.openWidgetEditModalSource.asObservable();
		this.reloadWidget = this.reloadWidgetSource.asObservable();
		this.widgetConfigChanged = this.widgetConfigChangedSource.asObservable();
	}

	/**
	 * publishes an event to indiciate the widget edit modal has been opened
	 *
	 * @param widget - widget being edited
	 */
	public publishOpenWidgetEditModal = (widget: any): void => {
		this.openWidgetEditModalSource.next(widget);
	};

	/**
	 * publishes an event to indiciate the widget has been reloaded
	 *
	 * @param widgetId - id of the widget being reloaded
	 */
	public publishReloadWidget = (widgetId: string): void => {
		this.reloadWidgetSource.next({ widgetId });
	};

	/**
	 * publishes an event to indiciate the widget configiration has changed
	 *
	 * @param widgetData - widget data
	 */
	public publishWidgetConfigChanged = (widgetData: any): void => {
		this.widgetConfigChangedSource.next(widgetData);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { MapState } from '../../../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapViewApiService {
	private readonly usersUrl: string = '/users';
	private readonly viewsUrl: string = '/views';

	constructor(private httpService: HttpService) {}

	/**
	 * get the user map views from the nextbus API
	 *
	 * @param userId - the id of the user
	 * @returns the views for the user
	 */
	public getViews = async (userId: number): Promise<any> => {
		try {
			const params: any = { pageNum: 1, pageSize: 100, sort: 'name', sortDir: 'asc' };

			return await this.httpService.get(this.usersUrl + '/' + userId + this.viewsUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * create a view via the nextbus API
	 *
	 * @param userId - the id of the user
	 * @param viewTitle - the new view title
	 * @param viewState - a copy (snapshot) of the map state for this view
	 * @returns the response object containing the success result
	 */
	public createView = async (userId: number, viewTitle: string, viewState: MapState): Promise<any> => {
		try {
			const params: any = {
				name: viewTitle,
				view: JSON.stringify(viewState),
			};

			return await this.httpService.post(this.usersUrl + '/' + userId + this.viewsUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * update a view via the nextbus API
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view to update
	 * @param viewTitle - the new view title
	 * @param viewState - a copy (snapshot) of the map state for this view
	 * @returns the response object containing the success result
	 */
	public updateView = async (userId: number, viewId: number, viewTitle: string, viewState: MapState): Promise<any> => {
		try {
			const params: any = {
				name: viewTitle,
				nb_id: viewId,
				nb_user: userId,
				view: JSON.stringify(viewState),
			};

			return await this.httpService.put(this.usersUrl + '/' + userId + this.viewsUrl + '/' + viewId, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * delete a view via the nextbus API
	 *
	 * @param userId - the id of the user
	 * @param viewId - the id of the view
	 * @returns the response object
	 */
	public deleteView = async (userId: number, viewId: number): Promise<any> => {
		try {
			return await this.httpService.delete(this.usersUrl + '/' + userId + this.viewsUrl + '/' + viewId);
		} catch (exception) {
			throw exception;
		}
	};
}

import { InjectionToken } from '@angular/core';
import { DataStore } from '@cubicNx/libs/utils';
import { ReportTemplateDetail, ReportSummaryDetail } from '../types/api-types';

export const SUMMARY_DETAIL_STORE: InjectionToken<any> = new InjectionToken('summary_detail_store_token');
export const INTERACTIVE_TEMPLATE_DETAIL_STORE: InjectionToken<any> = new InjectionToken('interactive_template_detail_store_token');
export const INTERACTIVE_SUMMARY_DETAIL_STORE: InjectionToken<any> = new InjectionToken('interactive_summary_detail_store_token');

export const summaryDetailStore: any = () => new DataStore<ReportSummaryDetail>();
export const interactiveTemplateDetailStore: any = () => new DataStore<ReportTemplateDetail>();
export const interactiveSummaryDetailStore: any = () => new DataStore<any>();

export const reportsStoreProvider: any[] = [
	{ provide: SUMMARY_DETAIL_STORE, useFactory: summaryDetailStore },
	{ provide: INTERACTIVE_TEMPLATE_DETAIL_STORE, useFactory: interactiveTemplateDetailStore },
	{ provide: INTERACTIVE_SUMMARY_DETAIL_STORE, useFactory: interactiveSummaryDetailStore },
];

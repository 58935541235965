import { NgModule } from '@angular/core';

import { UtilsModule } from '@cubicNx/libs/utils';

import { CanActivateGuard } from './guard/activate-guard';
import { CanDeactivateGuard } from './guard/deactivate-guard';
import { RoutingHandlerService } from './services/routing-handler.service';

@NgModule({
	declarations: [],
	exports: [],
	imports: [UtilsModule],
	providers: [CanActivateGuard, CanDeactivateGuard, RoutingHandlerService],
})
export class RoutingModule {}

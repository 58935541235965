/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { WidgetEditBaseComponent } from '../../../widget-edit-modal/widget-edit-modal-interface';

import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { RoutesUtilService } from '../../../../../support-features/routes/services/routes-util.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { Route, Routes, RouteSummaries } from '../../../../../support-features/routes/types/api-types';
import { MultiSelectSettings } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { IWidgetEditModalComponent } from '../../../types/types';

@Component({
	selector: 'current-blocks-edit',
	templateUrl: './current-blocks-edit.component.html',
	styleUrls: ['./current-blocks-edit.component.scss'],
})
export class CurrentBlocksEditComponent extends WidgetEditBaseComponent implements IWidgetEditModalComponent, OnInit, OnDestroy {
	public loaded: boolean = false;
	public routesSummaries: RouteSummaries = [];
	public selectedRoutes: RouteSummaries[] = [];
	public routeSettings: MultiSelectSettings = {
		id_text: 'route_id',
		value_text: 'route_display_name',
		placeholder: 'Please select a route',
		readonly: false,
		selectedItemBadged: true,
		showCheckbox: false,
		showDropdownCaret: false,
		filterWithSelected: true,
		enableSearchFilter: true,
		maxSelectionCount: 10,
		optionStyles: {
			// apply the italic style under listed conditions as per original dashboard version
			style: { 'font-style': 'italic' },
			fieldComparisonOrOperator: true,
			fields: [
				{ name: 'is_predict_enabled', value: 'false' },
				{ name: 'is_hidden', value: 'true' },
				{ name: 'is_enabled', value: 'false' },
			],
		},
	};

	constructor(
		private routesDataService: RoutesDataService,
		private routesUtilService: RoutesUtilService,
		protected override agenciesService: AgenciesDataService,
		protected override formBuilder: FormBuilder,
		translationService: TranslationService
	) {
		super(agenciesService, formBuilder, translationService);
	}

	/**
	 * performs initialization tasks for the current blocks widget edit component - building form, subscriptions
	 */
	public async ngOnInit(): Promise<void> {
		this.buildForm(this.addFormControls, {});

		await this.setupAgencies(this.agencyChanged);

		this.setSubscriptions();

		await this.initData();

		this.formValidChanged.emit(this.reactiveForm.valid);
	}

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy = (): void => {
		this.unsubscribe();
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param version - the data to check.
	 * @returns a class string.
	 */
	public versionToggleButtonClass = (version: string): string => {
		const current: string = this.reactiveForm.get('versionToggle').value;

		return current === version ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget versionToggle data.
	 *
	 * @param version - the versionToggle to set.
	 */
	public setVersionToggle = (version: string): void => {
		this.widget.config.versionToggle = version;
		this.reactiveForm.get('versionToggle').setValue(version);
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param hours - the data to check.
	 * @returns a class string.
	 */
	public hoursButtonClass = (hours: number): string => {
		const current: number = this.reactiveForm.get('hours').value;

		return current === hours ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget hours data.
	 *
	 * @param hours - the hours to set.
	 */
	public setHours = (hours: number): void => {
		this.widget.config.hours = { id: hours, name: hours };
		this.reactiveForm.get('hours').setValue(hours);
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param display - the data to check.
	 * @returns a class string.
	 */
	public displayButtonClass = (display: string): string => {
		const current: string = this.reactiveForm.get('display').value;

		return current === display ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget display data.
	 *
	 * @param display - the display to set.
	 */
	public setDisplay = (display: string): void => {
		this.widget.config.display = display;
		this.reactiveForm.get('display').setValue(display);
	};

	/**
	 * Determines the required class based on the data.
	 *
	 * @param progress - the data to check.
	 * @returns a class string.
	 */
	public progressButtonClass = (progress: string): string => {
		const current: string = this.reactiveForm.get('progress').value;

		return current === progress ? 'btn-primary' : 'btn-default';
	};

	/**
	 * Set the form and widget progress data.
	 *
	 * @param progress - the progress to set.
	 */
	public setProgress = (progress: string): void => {
		this.widget.config.progress = progress;
		this.reactiveForm.get('progress').setValue(progress);
	};

	/**
	 * Updates the form and widget data with the selectedRoutes.
	 *
	 * @param item - the item to update.
	 */
	public updateItemClicked = (): void => {
		this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
		this.widget.config.selectedRoutes = this.selectedRoutes;
	};

	/**
	 * Handles the agency dropdown selected value change event.
	 */
	public agencyChanged = (): void => {
		this.handleAgencyChanged(this.getRoutes, this.updateSelectedRoutes);
	};

	/**
	 * Updates the selected routes for the widget and the form.
	 */
	private updateSelectedRoutes = (): void => {
		this.selectedRoutes = [];
		this.widget.config.selectedRoutes = this.selectedRoutes;
		this.reactiveForm.get('selectedRoutes').setValue(this.selectedRoutes);
	};

	/**
	 * Adds extra form controls.
	 */
	private addFormControls = (): void => {
		this.reactiveForm.addControl('versionToggle', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('selectedRoutes', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('hours', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('display', new FormControl('', [Validators.required]));
		this.reactiveForm.addControl('progress', new FormControl('', [Validators.required]));
		this.reactiveForm.get('versionToggle').setValue(this.widget.config.versionToggle);
		this.reactiveForm.get('selectedRoutes').setValue(this.widget.config.selectedRoutes);
		this.reactiveForm.get('hours').setValue(this.widget.config.hours?.id);
		this.reactiveForm.get('display').setValue(this.widget.config.display);
		this.reactiveForm.get('progress').setValue(this.widget.config.progress);
	};

	/**
	 * initialize any data based on whether we already have an agency, default to loaded true if first time
	 * as we won't have an agency set yet
	 */
	private initData = async (): Promise<void> => {
		if (this.widget.config.selectedRoutes) {
			this.selectedRoutes = this.widget.config.selectedRoutes;
		}

		if (this.widget.config.selectedAgency) {
			await this.getRoutes();
		} else {
			this.loaded = true;
		}
	};

	/**
	 * Gets the routes for the selectedAgency.
	 */
	private getRoutes = async (): Promise<void> => {
		this.loaded = false;

		if (this.widget.config.selectedAgency) {
			const result: ResultContent = await this.routesDataService.getRoutes(
				this.widget.config.selectedAgency.authority_id,
				this.widget.config.selectedAgency.agency_id
			);

			if (result.success) {
				const routes: Routes = result.resultData;

				// create a route summary rather than saving the whole route object. More effecient and in line with the original dashboard
				this.routesSummaries = routes.map((route: Route) => ({
					route_id: route.route_id,
					route_display_name: this.routesUtilService.getDisplayName(route),
					is_enabled: route.is_enabled,
					is_hidden: route.is_hidden,
					is_predict_enabled: route.is_predict_enabled,
				}));
			}
		}

		this.loaded = true;
	};
}

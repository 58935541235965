/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { StateService } from '@cubicNx/libs/utils';
import { VehicleMessagesDataService } from '../services/vehicle-messages-data.service';
import { SelectedAgency } from '../../../support-features/agencies/types/api-types';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';
import { VehicleMessagesModalService } from '../services/vehicle-messages-modal.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { RoutePillData } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { VehicleMessagesPaginatedResponse, VehicleRoute } from '../types/api-types';
import { VehicleMessagesList } from '../types/types';

import { Columns, ColumnType, IconType, ListSortings, PageRequestInfo, PaginatedParams, SortDirection } from '@cubicNx/libs/utils';

@Component({
	selector: 'vehicle-messages',
	templateUrl: './vehicle-messages-list.component.html',
	styleUrls: ['./vehicle-messages-list.component.scss'],
})
export class VehicleMessagesListComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild('routePillColumnTemplate', { static: true, read: TemplateRef }) routePillColumnTemplate: TemplateRef<any>;

	// pass through the defaukt sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'sentDate', dir: SortDirection.desc }];

	public hasFilters: boolean = false;

	public listName: string = 'vehicle-messages';
	public columns: Columns = [];
	public vehicleMessagesList: VehicleMessagesList = [];
	public listLoadingIndicator: boolean = true;
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public initailized: boolean = false;
	public searchText: string = '';

	private readonly pollInterval: number = 60000;
	private readonly statusSent: number = 0;
	private readonly statusDelivered: number = 1;
	private readonly statusError: number = 2;
	private readonly statusAckPending: number = 3;
	private readonly statusAcknowledged: number = 4;

	private agenciesSelectionChange$Subscription: Subscription = null;
	private authorityId: string = null;
	private agencyId: string = null;
	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo'];
	private pollTimer: any = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		private vehicleMessagesDataService: VehicleMessagesDataService,
		private vehicleMessagesModalService: VehicleMessagesModalService,
		private stateService: StateService,
		private colorUtilityService: ColorUtilityService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the vehicle messages list compoenent
	 *
	 * loads translations, sets up subscriptions and default values, and loads list data
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.setSubscriptions();
		this.getSelectedAgency();
		this.loadCache();
		this.buildListColumns();
		this.startPollTimer();

		this.initailized = true;
	}

	/**
	 * updates the contents of the list dependent upon the page information
	 *
	 * @param pageInfo - the paging information
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;

		this.cachePageInfo(this.pageInfo);

		this.getVehicleMessages();
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = (searchText: string): void => {
		this.searchText = searchText;

		// we can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intigating a search
		this.pageInfo.pageNum = 1;

		this.cacheSearch(this.searchText);

		this.getVehicleMessages();
	};

	/**
	 * renders the create vehicle message dialog
	 *
	 * if the user saves the creation, then the list is refreshed
	 */
	public createMessage = async (): Promise<void> => {
		this.vehicleMessagesModalService.createMessage(this.authorityId, this.agencyId).subscribe((saved: boolean) => {
			if (saved) {
				// Unfortunatately if we request the data instantly, it returns before the backend has finished processing
				// the date comes back in wrong format and there is no id. Ideally the back end wouldn't send the response
				// for the createVehicleMessage until after it has finsihed the full process. This needs fixing then the list
				// can update instantly - instead just wait and refresh
				setTimeout(() => {
					// refresh the data with the current page settings
					this.getVehicleMessages();
				}, 10000);
			}
		});
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @param route - the route data
	 * @returns route pill
	 */
	public determineRoutePillData = (route: VehicleRoute): RoutePillData => {
		return {
			routeShortName: route.route_short_name,
			routeLongName: route.route_long_name,
			routeId: route.id,
			routeColor: this.colorUtilityService.getColor(route.route_color),
			routeTextColor: this.colorUtilityService.getColor(route.route_text_color),
		};
	};

	/**
	 * agency has been changed so publishes the event
	 */
	public handleAgencyUpdated = (): void => {
		this.agenciesEventsService.publishAgenciesSelectionChange();
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		clearTimeout(this.pollTimer);
		this.unsubscribe();
	}

	/**
	 * loads translations
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.VEHICLE',
			'T_CORE.MESSAGE',
			'T_CORE.STATUS',
			'T_MESSAGES.DATETIME',
			'T_MESSAGES.SENT_BY',
			'T_MESSAGES.TO_FROM',
			'T_MESSAGES.RECEIPT',
			'T_MESSAGES.ROUTE',
			'T_MESSAGES.BLOCK',
			'T_MESSAGES.TRIP',
			'T_MESSAGES.SENT',
			'T_MESSAGES.DELIVERED',
			'T_MESSAGES.SEND_ERROR',
			'T_MESSAGES.PENDING_ACKNOWLEDGEMENT',
			'T_MESSAGES.ACKNOWLEDGED',
		]);
	};

	/**
	 * builds the vehciel messages list columns
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: 'sentDate',
				displayName: this.translations['T_MESSAGES.DATETIME'],
				columnType: ColumnType.text,
			},
			{
				name: 'vehicleTag',
				displayName: this.translations['T_CORE.VEHICLE'],
				columnType: ColumnType.text,
			},
			{
				name: 'sentBy',
				displayName: this.translations['T_MESSAGES.SENT_BY'],
				columnType: ColumnType.text,
			},
			{
				name: 'toVehicleText',
				displayName: this.translations['T_MESSAGES.TO_FROM'],
				columnType: ColumnType.text,
			},
			{
				name: 'messageText',
				displayName: this.translations['T_CORE.MESSAGE'],
				columnType: ColumnType.text,
				width: 600,
			},
			{
				name: 'status',
				displayName: this.translations['T_CORE.STATUS'],
				columnType: ColumnType.icon,
				width: 100,
			},
			{
				name: 'receipt',
				displayName: this.translations['T_MESSAGES.RECEIPT'],
				columnType: ColumnType.icon,
				width: 100,
			},
			{
				name: 'route',
				displayName: this.translations['T_MESSAGES.ROUTE'],
				componentTemplate: this.routePillColumnTemplate,
				columnType: ColumnType.component,
				width: 250,
			},
			{
				name: 'blockTag',
				displayName: this.translations['T_MESSAGES.BLOCK'],
				columnType: ColumnType.text,
			},
			{
				name: 'tripTag',
				displayName: this.translations['T_MESSAGES.TRIP'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * retrieves last used search and page information for the list
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current searhed for text
	 *
	 * @param search - the current searched for text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * retrieves list of vehicle messages that match the current page information and search text and populates the list accordingly
	 */
	private getVehicleMessages = async (): Promise<void> => {
		this.listLoadingIndicator = true;

		this.totalRows = 0;

		// convert client side camel side notation for field name back to underscore version for back end request
		const paginatedParams: PaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			sort: this.pageInfo.sort,
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
		};

		const result: ResultContent = await this.vehicleMessagesDataService.getVehicleMessages(this.authorityId, paginatedParams);

		if (result.success) {
			const vehicleMessagesResponse: VehicleMessagesPaginatedResponse = result.resultData;

			if (vehicleMessagesResponse?.results) {
				// object for our list
				this.vehicleMessagesList = vehicleMessagesResponse.results.map((vehicleMessages) => ({
					id: vehicleMessages.id,
					sentDate: vehicleMessages.sentDate,
					vehicleTag: vehicleMessages.vehicleTag,
					sentBy: vehicleMessages.sentBy,
					toVehicleText: vehicleMessages.toVehicleText,
					messageText: vehicleMessages.messageText,
					status: this.getIconStyle(vehicleMessages.status),
					receipt: this.getIconStyle(vehicleMessages.receipt),
					route: vehicleMessages.route,
					blockTag: vehicleMessages.blockTag,
					tripTag: vehicleMessages.tripTag,
				}));

				this.totalRows = vehicleMessagesResponse.total;
			}
		}

		this.listLoadingIndicator = false;
	};

	/**
	 * determines the vehicle message status icon value
	 *
	 * @param status - the message status
	 * @returns the icon etails representing the supplied status
	 */
	private getIconStyle = (status: number): IconType => {
		let iconClass: string = 'status-icon nb-icons ';
		let iconName: string = '';

		switch (status) {
			case this.statusSent:
				iconClass += 'nb-success icon-green';
				iconName = 'T_MESSAGES.SENT';
				break;
			case this.statusDelivered:
				iconClass += 'nb-success-solid icon-green';
				iconName = 'T_MESSAGES.DELIVERED';
				break;
			case this.statusError:
				iconClass += 'nb-solid-circle-x icon-red';
				iconName = 'T_MESSAGES.SEND_ERROR';
				break;
			case this.statusAckPending:
				iconClass += 'nb-icons nb-success icon-green';
				iconName = 'T_MESSAGES.PENDING_ACKNOWLEDGEMENT';
				break;
			case this.statusAcknowledged:
				iconClass += 'nb-success-solid icon-green';
				iconName = 'T_MESSAGES.ACKNOWLEDGED';
				break;
		}

		return {
			iconName: this.translations[iconName],
			iconClass,
			iconType: null,
		};
	};

	/**
	 * gets the selected agency details
	 */
	private getSelectedAgency = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		if (selectedAgency) {
			this.authorityId = selectedAgency.authority_id;
			this.agencyId = selectedAgency.agency_id;
		}
	};

	/**
	 * starts polling the list
	 */
	private startPollTimer = (): void => {
		this.pollTimer = setTimeout(() => {
			this.pollData();
		}, this.pollInterval);
	};

	/**
	 * retrieve vehicle messages and load list, start polling again
	 */
	private pollData = async (): Promise<void> => {
		this.getVehicleMessages();

		// restart the timer
		this.startPollTimer();
	};

	/**
	 * sets up subscriptions
	 *
	 * is interested in agency selection changes
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgencySelectionChange();
		});
	};

	/**
	 * handles an agency selection change
	 *
	 * retrieives new message for the list for that agency
	 */
	private handleAgencySelectionChange = (): void => {
		this.getSelectedAgency();
		this.getVehicleMessages();
	};

	/**
	 * unsubscribes from subsciptions
	 */
	private unsubscribe = (): void => {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
		clearTimeout(this.pollTimer);
	};
}

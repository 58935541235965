<form [formGroup]="reactiveForm" class="form-group row" [ngClass]="agencies?.length > 1 ? 'form-height' : 'form-height-single-agency'">
	<loading-overlay [isLoading]="!loaded" [fit]="'content'"></loading-overlay>
	<div [hidden]="!loaded">
		<div id="title-text" class="form-group">
			<div class="nb-required">
				<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
			</div>
			<label for="title" data-test="title.label">{{ 'T_DASH.DB_TITLE' | translate }}</label>
			<input
				formControlName="title"
				data-test="title.input"
				type="text"
				id="title"
				class="form-control"
				placeholder="{{ 'T_DASH.DB_TITLE' | translate }}" />
			<input-validation [control]="reactiveForm.controls.title" [extraMessageText]="titleExtraMessageText"> </input-validation>
		</div>

		<div *ngIf="agencies?.length > 1">
			<div id="agency" class="form-group">
				<div class="nb-required">
					<span>{{ 'T_DASH.DB_REQUIRED' | translate }}</span>
				</div>
				<label for="agencyId" data-test="agency.label">{{ 'T_DASH.DB_AGENCY' | translate }}</label>
				<select
					name="agencyId"
					id="agencyId"
					class="form-control"
					formControlName="agencyId"
					data-test="agency.selection"
					(change)="agencyChanged()">
					<option *ngFor="let agency of agencies" [ngValue]="agency.agency_nb_id">
						{{ agency.agency_name }}
					</option>
				</select>
				<input-validation [control]="reactiveForm.controls.agencyId"></input-validation>
			</div>
		</div>

		<div class="form-group no-padding vehicle-filter-container">
			<div class="nb-form-control-vertical col-md-12 no-padding">
				<div>
					<field-label
						name="selectVehiclesDepots"
						label="{{ 'T_DASH.DB_VEHICLES' | translate }}&nbsp;/&nbsp;{{ 'T_DASH.DB_DEPOTS' | translate }}"
						[required]="true"></field-label>
				</div>
				<div class="nb-radio">
					<label>
						<input
							id="specific_vehicles"
							type="radio"
							data-test="vehicles.input"
							name="vehicleFilterSelection"
							formControlName="vehicleFilterSelection"
							(click)="updateVehicleSelectionFilter('vehicles')"
							value="vehicles" />
						<div class="radio-label">{{ 'T_DASH.EVENTS.SELECT_VEHICLES' | translate }}</div>
					</label>
					<br />

					<div *ngIf="widget.config.vehicleFilterSelection?.value === 'vehicles'" class="form-group">
						<div>
							<multi-select
								[settings]="vehicleSettings"
								data-test="vehicles.select"
								[items]="vehicles"
								[selected]="selectedVehicles"
								(itemClickedEvent)="updateVehicleItemClicked()"
								(removeItemClicked)="updateVehicleItemClicked()">
							</multi-select>
						</div>
						<input-validation [control]="reactiveForm.controls.selectedVehicles"></input-validation>
					</div>

					<label>
						<input
							id="specific_depots"
							data-test="depots.input"
							type="radio"
							name="vehicleFilterSelection"
							formControlName="vehicleFilterSelection"
							(click)="updateVehicleSelectionFilter('depots')"
							value="depots" />
						<div class="radio-label">{{ 'T_DASH.EVENTS.SELECT_DEPOTS_C' | translate }}</div>
					</label>

					<div *ngIf="widget.config.vehicleFilterSelection?.value === 'depots'" class="form-group">
						<div>
							<multi-select
								[settings]="depotSettings"
								[items]="depots"
								data-test="depots.select"
								[selected]="selectedDepots"
								(itemClickedEvent)="updateDepotItemClicked()"
								(removeItemClicked)="updateDepotItemClicked()">
							</multi-select>
							<input-validation [control]="reactiveForm.controls.selectedDepots"></input-validation>
						</div>
					</div>
					<div class="nb-radio">
						<label>
							<input
								id="all_vehicles"
								data-test="all.input"
								type="radio"
								name="vehicleFilterSelection"
								formControlName="vehicleFilterSelection"
								(click)="updateVehicleSelectionFilter('all')"
								value="all" />
							<div class="radio-label">{{ 'T_DASH.EVENTS.SELECT_ALL' | translate }}</div>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div id="display" class="form-group">
			<div class="display-label">
				<label>{{ 'T_DASH.DB_DISPLAY' | translate }}</label>
			</div>
			<div>
				<div class="btn-group">
					<button
						class="btn btn-extra-width"
						[ngClass]="displayButtonClass('chartList')"
						(click)="setDisplay('chartList')"
						data-test="display.chart_list">
						{{ 'T_DASH.DB_CHART_PLUS_LIST' | translate }}
					</button>
					<button
						class="btn btn-extra-width"
						[ngClass]="displayButtonClass('chartOnly')"
						(click)="setDisplay('chartOnly')"
						data-test="display.chart_only">
						{{ 'T_DASH.DB_CHART_ONLY' | translate }}
					</button>
					<button
						class="btn btn-extra-width"
						[ngClass]="displayButtonClass('listOnly')"
						(click)="setDisplay('listOnly')"
						data-test="display.list_only">
						{{ 'T_DASH.DB_LIST_ONLY' | translate }}
					</button>
				</div>
			</div>
		</div>

		<div class="form-group">
			<div>
				<label for="listSize">{{ 'T_DASH.DB_LIST_SIZE' | translate }}</label>
			</div>
			<select
				name="listSize"
				id="listSize"
				class="form-control"
				formControlName="listSize"
				(change)="updateListSize($event)"
				data-test="list.size">
				<option *ngFor="let item of listSizeOptions" value="{{ item.value }}">{{ item.option }}</option>
			</select>
		</div>

		<div class="form-group">
			<div>
				<field-label name="view" label="{{ 'T_DASH.DB_VIEW' | translate }}" [required]="true"></field-label>
			</div>
			<div class="nb-form-control">
				<div class="nb-checkbox">
					<label for="adherence">
						<input
							id="adherence"
							type="checkbox"
							formControlName="adherence"
							data-test="input.show_adherence"
							(change)="checkboxClicked($event, 'displayAdherence')" />
						<div class="checkbox-label">{{ 'T_DASH.DB_SHOW_ADHERENCE' | translate }}</div>
					</label>
				</div>
			</div>
			<div class="nb-form-control">
				<div class="nb-checkbox">
					<label for="unassigned">
						<input
							id="unassigned"
							type="checkbox"
							formControlName="unassigned"
							data-test="input.show_unassigned"
							(change)="checkboxClicked($event, 'displayUnassigned')" />
						<div class="checkbox-label">{{ 'T_DASH.DB_SHOW_UNASSIGNED' | translate }}</div>
					</label>
				</div>
			</div>
		</div>
		<div>
			<field-label name="status" label="{{ 'T_DASH.DB_STATUS' | translate }}" [required]="true"></field-label>
		</div>
		<div class="performance-viewsettings">
			<div class="form-group performance-adherencecol">
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="veryEarly">
							<input
								id="veryEarly"
								type="checkbox"
								data-test="status.very-early"
								formControlName="veryEarly"
								(change)="checkboxClicked($event, 'displayVeryEarly')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_VERY_EARLY' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="early">
							<input
								id="early"
								type="checkbox"
								data-test="status.early"
								formControlName="early"
								(change)="checkboxClicked($event, 'displayEarly')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_EARLY' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="onTime">
							<input
								id="onTime"
								type="checkbox"
								data-test="status.on-time"
								formControlName="onTime"
								(change)="checkboxClicked($event, 'displayOnTime')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_ON_TIME' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="late">
							<input
								id="late"
								type="checkbox"
								data-test="status.late"
								formControlName="late"
								(change)="checkboxClicked($event, 'displayLate')" />
							<div class="checkbox-label">{{ 'T_DASH.DB_LATE' | translate }}</div>
						</label>
					</div>
				</div>
				<div class="nb-form-control no-wrap">
					<div class="nb-checkbox">
						<label for="veryLate">
							<label for="veryLate">
								<input
									id="veryLate"
									data-test="status.very-late"
									type="checkbox"
									formControlName="veryLate"
									(change)="checkboxClicked($event, 'displayVeryLate')" />
								<div class="checkbox-label">{{ 'T_DASH.DB_VERY_LATE' | translate }}</div>
							</label>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

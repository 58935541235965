/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { DataService } from '@cubicNx/libs/utils';
import { ModalEventsService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { EventData } from '@cubicNx/libs/utils';

@Component({
	selector: 'confirm-rider-cancellation-alert',
	templateUrl: './confirm-rider-cancellation-alert.component.html',
	styleUrls: ['./confirm-rider-cancellation-alert.component.scss'],
})
export class ConfirmRiderCancellationAlertComponent extends TranslateBaseComponent implements OnInit {
	public sendAlert: boolean;
	public confirmText: string;

	constructor(
		private modalEventsService: ModalEventsService,
		public data: DataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handles the intitialization of the confirm rider cancellation alert - sets up the display text
	 */
	public ngOnInit(): void {
		this.confirmText = this.data['confirmText' as keyof DataService];
	}

	/**
	 * handles the update of the cancellation checkbox and published the change event to the parent
	 **/
	public onSelect = (): void => {
		const eventData: EventData = {
			data: this.sendAlert,
			confirmEnabled: true,
		};

		this.modalEventsService.publishDataChangedEvent(eventData);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { HttpService } from '@cubicNx/libs/utils';

import { PaginatedParams } from '@cubicNx/libs/utils';

@Injectable()
export class AuthoritiesAdminApiService {
	private readonly authoritiesUrl: string = '/authorities';
	private readonly settingsUrl: string = '/settings';

	constructor(private httpService: HttpService) {}

	/**
	 * retrieves authority details that match the supplied parameters
	 *
	 * @param authorityId - authority id
	 * @returns authority details
	 */
	public getAuthority = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get(this.authoritiesUrl + '/' + authorityId);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves authorities that match the supplied parameters
	 *
	 * @param params - authority retrieval parameters - page size, number, sort criteria and search text
	 * @returns authorities matching the supplied parameters
	 */
	public getAuthorities = async (params: PaginatedParams): Promise<any> => {
		try {
			return await this.httpService.get(this.authoritiesUrl, params);
		} catch (exception) {
			throw exception;
		}
	};

	/**
	 * retrieves authority settings for supplied authority identifier
	 *
	 * @param authorityId - authority id
	 * @returns authority settings
	 */
	public getAuthoritySettings = async (authorityId: string): Promise<any> => {
		try {
			return await this.httpService.get(this.authoritiesUrl + '/' + authorityId + this.settingsUrl);
		} catch (exception) {
			throw exception;
		}
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TranslationService } from '@cubicNx/libs/utils';

import { modalSlideUpAnimation } from '../types/types';

@Component({
	selector: 'dashboard-widgets-view',
	templateUrl: './dashboard-widgets-view.component.html',
	styleUrls: ['./dashboard-widgets-view.component.scss'],
	animations: modalSlideUpAnimation,
})
export class DashboardWidgetsViewComponent extends TranslateBaseComponent implements OnInit {
	@Input() editMode: boolean;
	@Input() dashboard: any;
	@Output() addWidgetClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteWidgetClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() dropWidgetEvent: EventEmitter<any> = new EventEmitter<any>();
	@Output() widgetConfigUpdated: EventEmitter<any> = new EventEmitter<any>();

	public override translations: { [key: string]: string } = {};

	public animationState: string = null;

	constructor(translationService: TranslationService) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the dashboard widgets view - set modal content visible (with animation)
	 */
	public ngOnInit(): void {
		this.animationState = 'open';
	}

	/**
	 * Checks to determine if the dashboard has widgets.
	 *
	 * @returns true if the dashboard has widgets.
	 */
	public hasWidgets = (): boolean => {
		let hasWidgets: boolean = false;

		const columns: any[] = this.dashboard.config.rows.flatMap((i: any) => i.columns);

		columns.every((column: any) => {
			if (column.widgets?.length > 0) {
				hasWidgets = true;

				return false;
			}

			return true;
		});

		return hasWidgets;
	};

	/**
	 * Fires addWidgetClicked event emitting the row and column index to add the widget to.
	 *
	 * @param rowIndex - - index of the row to add the widget to.
	 * @param columnIndex - - index of the column to add the widget to.
	 */
	public addWidget = (rowIndex: number, columnIndex: number): void => {
		this.addWidgetClicked.emit({ rowIndex, columnIndex });
	};

	/**
	 * Fires deleteWidgetClicked emitting the widget to be deleted.
	 *
	 * @param widget - - the widget to delete.
	 */
	public deleteWidget = (widget: any): void => {
		this.deleteWidgetClicked.emit(widget);
	};

	/**
	 * Fires dropWidgetEvent emitting the dropped widget.
	 *
	 * @param event - - the widget being dropped.
	 */
	public dropWidget = (event: any): void => {
		this.dropWidgetEvent.emit(event);
	};

	/**
	 * Fires widgetConfigUpdated emitting the updated widget.
	 *
	 * Handles edge case where new config/controls/options have been added to the widget but a
	 * previous saved version of the widget exists that was created before the new property was added
	 * If there is a new property, update the old widget saved config so it has a value
	 *
	 * @param widget - - the widget to delete.
	 */
	public handleWidgetConfigUpdated = (widget: any): void => {
		this.widgetConfigUpdated.emit(widget);
	};
}

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';

import { EntityType } from '../../../utils/components/breadcrumbs/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapActiveEntityService {
	constructor(private mapStateService: MapStateService) {}

	/**
	 * set the active entity
	 *
	 * @param activeEntityId - the active entity id i.e vehicle/stop id
	 * @param entityType - the entity type i.e vehicle/stop
	 */
	public setActiveEntity = (activeEntityId: string, entityType: EntityType): void => {
		const activeEntityKey: string = this.getActiveEntityKey(activeEntityId, entityType);

		this.mapStateService.setActiveEntity(activeEntityKey);
	};

	/**
	 * determine if the entity is currently active on the map. Active being the main highlighted item
	 *
	 * @param activeEntityId - the id of the entity
	 * @param entityType - the entity type i.e vehicle
	 * @returns true if the entity is active/highlighted on the map
	 */
	public isActiveEntity = (activeEntityId: string, entityType: EntityType): boolean => {
		const activeEntityKey: string = this.getActiveEntityKey(activeEntityId, entityType);

		return activeEntityKey === this.mapStateService.getActiveEntity();
	};

	/**
	 * determine if there is currently an active (highlighted) entity on the map
	 *
	 * @returns true if there is currently an active (highlighted) entity on the map
	 */
	public hasActiveEntity = (): boolean => {
		return this.mapStateService.getActiveEntity() !== null;
	};

	/**
	 * get an activity entity key
	 *
	 * @param activeEntityId - the id of the entity
	 * @param entityType - the entity type i.e vehicle
	 * @returns the activity entity key
	 */
	private getActiveEntityKey = (activeEntityId: string, entityType: EntityType): string => {
		let activeEntityKey: string = '';

		switch (entityType) {
			case EntityType.stop:
				activeEntityKey += 'stop-';
				break;
			case EntityType.vehicle:
				activeEntityKey += 'vehicle-';
				break;
		}

		activeEntityKey += activeEntityId;

		return activeEntityKey;
	};
}

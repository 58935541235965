/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';

import { ReportViewerBaseComponent } from '../../../report-viewer-base.component';

import { TranslationService } from '@cubicNx/libs/utils';
import { DataService } from '@cubicNx/libs/utils';

import { BlockAssignmentsReportSummaryDetail } from '../../../../types/api-types';

@Component({
	selector: 'bl100-block-assignments-summary',
	templateUrl: './block-assignments-summary.component.html',
	styleUrls: ['./block-assignments-summary.component.scss'],
})
export class BlockAssignmentsSummaryComponent extends ReportViewerBaseComponent {
	public summaryData: BlockAssignmentsReportSummaryDetail = null;

	constructor(
		public dataService: DataService,
		translationService: TranslationService
	) {
		super(translationService);

		this.summaryData = this.dataService['summaryData' as keyof DataService];
	}
}

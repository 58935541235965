<div class="widget-header">
	<div>
		<div class="flex space-between">
			<div class="truncate">
				<div class="widget-title" data-test="dashboard.widget.title">
					{{ title }}
				</div>
			</div>
			<a *ngIf="editMode" (click)="deleteWidget()">
				<i class="ng-icon ng-delete"></i>
			</a>
			<a *ngIf="!editMode" (click)="editWidget()" data-test="dashboard.widget.edit">
				<i title="{{ 'T_DASH.DB_EDIT_WIDGET' | translate }}" class="ng-icon ng-edit"></i>
			</a>
		</div>
		<div class="truncate">
			<div class="widget-agency-text" data-test="dashboard.widget.agency">
				{{ agencyName }}
			</div>
		</div>
	</div>
</div>

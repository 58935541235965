<div class="detail-container">
	<div *ngIf="!initialized" class="loading-container">
		<loading-overlay [isLoading]="true" [fit]="'content'" [showAnimation]="false" [fadeOverlay]="false"></loading-overlay>
	</div>
	<table *ngIf="initialized">
		<tbody>
			<tr>
				<td class="buffer"></td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode">
					<span class="detail-label">{{ 'T_MESSAGES.ROUTES' | translate }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode">
					<span class="detail-label">{{ 'T_MESSAGES.SIGNS' | translate }}</span>
				</td>
			</tr>
			<tr>
				<td class="buffer"></td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode && routes.length > 0">
					<span class="detail-value">{{ routes[0].routeDisplayName }}&nbsp;{{ routes[0].stopsLabel }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode && routes.length === 0">
					<span class="detail-value">{{ 'T_RIDER.ALL_ROUTES_AND_STOPS' | translate }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode && signs.length > 0">
					<span class="detail-value">{{ signs[0].name }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode && signs.length === 0">
					<span class="detail-value"></span>
					<!-- shouldnt be possible but creation from UI1 allows it -->
				</td>
			</tr>
			<tr>
				<td class="buffer"></td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode && routes.length > 1">
					<span class="detail-value">{{ routes[1].routeDisplayName }}&nbsp;{{ routes[1].stopsLabel }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode && routes.length <= 1">
					<span class="detail-value"></span
					><!--empty place holder-->
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode && signs.length > 1">
					<span class="detail-value">{{ signs[1].name }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode && signs.length <= 1">
					<span class="detail-value"></span
					><!--empty place holder-->
				</td>
			</tr>
			<tr>
				<td class="buffer"></td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode && routes.length > 2">
					<span class="detail-value">{{ routes[2].routeDisplayName }}&nbsp;{{ routes[2].stopsLabel }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="isRouteMode && routes.length <= 2">
					<span class="detail-value"></span
					><!--empty place holder-->
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode && signs.length > 2">
					<span class="detail-value">{{ signs[2].name }}</span>
				</td>
				<td [ngStyle]="getRouteSignsWidth()" *ngIf="!isRouteMode && signs.length <= 2">
					<span class="detail-value"></span
					><!--empty place holder-->
				</td>
			</tr>
			<!-- get the remaining routes (first few rows dealt with above) -->
			<ng-container *ngIf="isRouteMode && routes.length >= 3">
				<tr *ngFor="let route of routes | slice: 3">
					<td class="buffer"></td>
					<td [ngStyle]="getRouteSignsWidth()">
						<span class="detail-value">{{ route.routeDisplayName }}&nbsp;{{ route.stopsLabel }}</span>
					</td>
				</tr>
			</ng-container>
			<!-- get the remaining signs (first few rows dealt with above) -->
			<ng-container *ngIf="!isRouteMode && signs.length >= 3">
				<tr *ngFor="let sign of signs | slice: 3">
					<td class="buffer"></td>
					<td [ngStyle]="getRouteSignsWidth()">
						<span class="detail-value">{{ sign.name }}</span>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>

<div class="nb-view">
	<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

	<panel-header [showBackButton]="true" [heading]="'T_REPORT.AVAILABLE_REPORT_DETAILS' | translate"> </panel-header>
	<div>
		<div class="clearfix">
			<div class="col-lg-12 col-md-12 col-sm1-12 col-xs-12 toolbar nb-margin-bottom-md report-details-container">
				<button
					*ngIf="outputType !== 'interactive'"
					[disabled]="!summaryContent.loaded || !downloadButtonEnabled"
					(click)="download()"
					title="{{ downloadButtonTooltip }}"
					data-test="report-details.download.btn"
					class="btn btn-action pull-left report-details-btn">
					<i class="nb-icons nb-download report-details-btn-icon"></i>
					{{ 'T_CORE.DOWNLOAD' | translate }}
				</button>
				<button
					*ngIf="outputType === 'interactive'"
					[disabled]="!summaryContent.loaded || !viewButtonEnabled"
					(click)="viewInteractiveReport()"
					title="{{ viewButtonTooltip }}"
					class="btn btn-action pull-left report-details-btn"
					data-test="report-details.view.btn">
					<i class="nb-icons nb-view-on report-details-btn-icon"></i>
					{{ 'T_REPORT.VIEW' | translate }}
				</button>
				<button
					*ngIf="outputType === 'interactive'"
					[disabled]="!summaryContent.loaded || !editButtonEnabled"
					(click)="edit()"
					title="{{ editButtonTooltip }}"
					data-test="report-details.edit.btn"
					class="btn btn-action pull-left report-details-btn">
					<i class="nb-icons nb-edit"></i>
					{{ 'T_CORE.EDIT' | translate }}
				</button>
				<button
					[disabled]="!summaryContent.loaded || !deleteButtonEnabled"
					(click)="remove()"
					title="{{ deleteButtonTooltip }}"
					data-test="report-details.delete.btn"
					class="btn btn-action pull-left report-details-btn">
					<i class="nb-icons nb-delete"></i>
					{{ 'T_CORE.DELETE' | translate }}
				</button>
			</div>
		</div>

		<loading-overlay [isLoading]="loading"></loading-overlay>

		<div *ngIf="!loading">
			<report-summary-content [summaryContent]="summaryContent" [showHeader]="true"></report-summary-content>

			<report-template-summary-content
				*ngIf="showTemplateDetails"
				[templateSummaryContent]="templateSummaryContent"
				[defaultTemplateId]="defaultTemplateId"
				[authorityId]="authorityId"
				[agencyId]="agencyId">
			</report-template-summary-content>
		</div>
	</div>
</div>

<div class="nb-view">
	<breadcrumbs [breadcrumbItems]="breadcrumbItems"></breadcrumbs>

	<panel-header [showBackButton]="true" [heading]="'T_REPORT.REPORT_TEMPLATE' | translate"> </panel-header>
	<div>
		<div class="clearfix">
			<div class="col-lg-12 col-md-12 col-sm1-12 col-xs-12 toolbar nb-margin-bottom-md report-template-details-btns-container">
				<button
					(click)="edit()"
					[disabled]="!summaryContent.loaded || !editButtonEnabled"
					title="{{ editButtonTooltip }}"
					class="btn btn-action pull-left report-template-details-btn">
					<i class="nb-icons nb-edit"></i>
					{{ 'T_CORE.EDIT' | translate }}
				</button>
				<button
					(click)="run()"
					[disabled]="!summaryContent.loaded || !runButtonEnabled"
					title="{{ runButtonTooltip }}"
					class="btn btn-action pull-left report-template-run-btn">
					<i class="nb-icons nb-success-solid"></i>
					{{ 'T_REPORT.RUN' | translate }}
				</button>
				<button
					(click)="delete()"
					[disabled]="!summaryContent.loaded || !deleteButtonEnabled"
					title="{{ deleteButtonTooltip }}"
					class="btn btn-action pull-left report-template-delete-btn">
					<i class="nb-icons nb-success-solid"></i>
					{{ 'T_CORE.DELETE' | translate }}
				</button>
			</div>
		</div>

		<loading-overlay [isLoading]="loading"></loading-overlay>

		<div *ngIf="!loading">
			<report-summary-content [summaryContent]="summaryContent" [showHeader]="true"></report-summary-content>

			<report-template-summary-content
				[templateSummaryContent]="templateSummaryContent"
				[defaultTemplateId]="defaultTemplateId"
				[authorityId]="authorityId"
				[agencyId]="agencyId">
			</report-template-summary-content>
		</div>
	</div>
</div>

<form [formGroup]="form">
	<div class="panel panel-default">
		<div class="panel-heading nb-role-edit-panel-header flex space-between pointer" (click)="toggleSection()">
			<div class="nb-subhead">
				{{ 'T_AGENCY.TERMINAL_DEPARTURE.EXCEPTION_TITLE' | translate }} - {{ getRouteName() }} | {{ getStopName() }}
			</div>
			<button type="button" class="btn btn-link-dark">
				<i class="sectionTitle" [ngClass]="sectionCollapsed ? 'nb-icons nb-filter-down' : 'nb-icons nb-filter-up'"></i>
			</button>
		</div>
		<div [hidden]="sectionCollapsed" class="panel-body">
			<div class="row">
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
					<field-label [name]="'route'" [label]="'T_CORE.ROUTE' | translate" [required]="true"></field-label>
					<select class="form-control" (change)="routeChanged($event.target)" formControlName="route">
						<option *ngFor="let route of routes" value="{{ route.id }}">
							{{ route.title }}
						</option>
					</select>
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
					<field-label [name]="'stop'" [label]="'T_AGENCY.TERMINAL_DEPARTURE.TERMINAL_STATION' | translate" [required]="true"></field-label>
					<select class="form-control" (change)="stopChanged($event.target)" formControlName="stop">
						<option *ngFor="let stop of stops" value="{{ stop.id }}">
							{{ getStopDescription(stop) }}
						</option>
					</select>
				</div>
			</div>
			<div class="row override">
				<div class="nb-form-control check-override" [ngClass]="arrSettingsEnabled ? 'override-enabled' : 'override-disabled'">
					<field-label [name]="'override_arrival_stop_radius'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_arrival_stop_radius_' + index }}">
							<input
								id="{{ 'override_arrival_stop_radius_' + index }}"
								formControlName="override_arrival_stop_radius"
								type="checkbox"
								(change)="exceptionOverrideCheck(getCheckedState($event.target) ? 'arrival_stop_radius' : 'overrideArrivalStopRadius')" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_ARRIVAL_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideArrivalStopRadius" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'arrival_stop_radius'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.ARRIVAL_STOP_RADIUS' | translate"
						[required]="false"
						matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.ARRIVAL_STOP_RADIUS_TOOLTIP' | translate }}"
						[matTooltipPosition]="'right'"></field-label>
					<input
						class="form-control"
						disabled
						value="{{ arrivalStopRadiusValues[0] === null ? 0 : arrivalStopRadiusValues[0] }} {{ 'T_DEPOT.METERS' | translate }} " />
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<slider
						*ngIf="arrivalStopRadiusValues.length > 0"
						[values]="arrivalStopRadiusValues"
						[sliderCfg]="arrivalStopRadiusSliderCfg"
						(updatedValues)="updateArrivalStopRadius($event)">
					</slider>
				</div>
			</div>
			<div class="row override-col">
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-col-lr">
					<div class="nb-form-control check-override-col" [ngClass]="depSettingsEnabled ? 'override-enabled' : 'override-disabled'">
						<field-label [name]="'override_apply_sched_layovers'" [label]="" [required]="false"></field-label>
						<div class="nb-checkbox">
							<label for="{{ 'override_apply_sched_layovers_' + index }}">
								<input
									id="{{ 'override_apply_sched_layovers_' + index }}"
									formControlName="override_apply_sched_layovers"
									type="checkbox"
									(change)="
										exceptionOverrideCheck(getCheckedState($event.target) ? 'apply_sched_layovers' : 'overrideApplySchedLayovers')
									" />
								<div
									class="checkbox-label"
									matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
									[matTooltipPosition]="'right'">
									Override
								</div>
							</label>
						</div>
					</div>
					<div *ngIf="!overrideApplySchedLayovers" class="box-override-col"></div>
					<div class="box-override-col-p">
						<field-label
							[name]="'apply_sched_layovers'"
							[label]="'T_AGENCY.TERMINAL_DEPARTURE.APPLY_SCHED_LAYOVERS' | translate"
							[required]="false"></field-label>
						<div class="nb-form-control">
							<div class="nb-checkbox">
								<label for="{{ 'apply_sched_layovers_' + index }}">
									<input id="{{ 'apply_sched_layovers_' + index }}" formControlName="apply_sched_layovers" type="checkbox" />
									<div class="checkbox-label">{{ 'T_AGENCY.ENABLED' | translate }}</div>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-col-lr-nl">
					<div class="nb-form-control check-override-col" [ngClass]="depSettingsEnabled ? 'override-enabled' : 'override-disabled'">
						<field-label [name]="'override_detect_departure_at_upcoming_stop'" [label]="" [required]="false"></field-label>
						<div class="nb-checkbox">
							<label for="{{ 'override_detect_departure_at_upcoming_stop_' + index }}">
								<input
									id="{{ 'override_detect_departure_at_upcoming_stop_' + index }}"
									formControlName="override_detect_departure_at_upcoming_stop"
									type="checkbox"
									(change)="
										exceptionOverrideCheck(
											getCheckedState($event.target) ? 'detect_departure_at_upcoming_stop' : 'overrideDetectDepartureAtUpcomingStop'
										)
									" />
								<div
									class="checkbox-label"
									matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
									[matTooltipPosition]="'right'">
									Override
								</div>
							</label>
						</div>
					</div>
					<div *ngIf="!overrideDetectDepartureAtUpcomingStop" class="box-override-col"></div>
					<div class="box-override-col-p">
						<field-label
							[name]="'detect_departure_at_upcoming_stop'"
							[label]="'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_AT_UPCOMING_STOP' | translate"
							[required]="false"></field-label>
						<div class="nb-form-control">
							<div class="nb-checkbox">
								<label for="{{ 'detect_departure_at_upcoming_stop_' + index }}">
									<input
										id="{{ 'detect_departure_at_upcoming_stop_' + index }}"
										formControlName="detect_departure_at_upcoming_stop"
										type="checkbox" />
									<div class="checkbox-label">{{ 'T_AGENCY.ENABLED' | translate }}</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row override">
				<div class="nb-form-control check-override" [ngClass]="depSettingsEnabled ? 'override-enabled' : 'override-disabled'">
					<field-label [name]="'override_min_layover_seconds'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_min_layover_seconds_' + index }}">
							<input
								id="{{ 'override_min_layover_seconds_' + index }}"
								formControlName="override_min_layover_seconds"
								type="checkbox"
								(change)="exceptionOverrideCheck(getCheckedState($event.target) ? 'min_layover_seconds' : 'overrideMinLayoverSeconds')" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideMinLayoverSeconds" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'min_layover_seconds'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.MIN_LAYOVER' | translate"
						[required]="false"></field-label>
					<input class="form-control" disabled value="{{ minLayoverValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<slider
						*ngIf="minLayoverValues.length > 0"
						[values]="minLayoverValues"
						[sliderCfg]="terminalDepartureSliderConfig"
						(updatedValues)="updateMinLayover($event)">
					</slider>
				</div>
			</div>
			<div class="row override" *ngIf="maxExtensionValues.length > 0">
				<div class="nb-form-control check-override" [ngClass]="depSettingsEnabled ? 'override-enabled' : 'override-disabled'">
					<field-label [name]="'override_max_departure_extension'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_max_departure_extension_' + index }}">
							<input
								id="{{ 'override_max_departure_extension_' + index }}"
								formControlName="override_max_departure_extension"
								type="checkbox"
								(change)="
									exceptionOverrideCheck(getCheckedState($event.target) ? 'max_extension_seconds' : 'overrideMaxDepartureExtension')
								" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideMaxDepartureExtension" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'max_departure_extension'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.MAX_DEPARTURE' | translate"
						[required]="false"></field-label>
					<input class="form-control" disabled value="{{ maxExtensionValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<slider
						*ngIf="maxExtensionValues.length > 0"
						[values]="maxExtensionValues"
						[sliderCfg]="terminalDepartureSliderConfig"
						(updatedValues)="updateMaxExtension($event)">
					</slider>
				</div>
			</div>
			<div class="row override-col">
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-col-lr">
					<div class="nb-form-control check-override-col" [ngClass]="depSettingsEnabled ? 'override-enabled' : 'override-disabled'">
						<field-label [name]="'override_detect_departure_stop_sequence'" [label]="" [required]="false"></field-label>
						<div class="nb-checkbox">
							<label for="{{ 'override_detect_departure_stop_sequence_' + index }}">
								<input
									id="{{ 'override_detect_departure_stop_sequence_' + index }}"
									formControlName="override_detect_departure_stop_sequence"
									type="checkbox"
									(change)="
										exceptionOverrideCheck(
											getCheckedState($event.target) ? 'detect_departure_stop_sequence' : 'overrideDetectDepartureStopSequence'
										)
									" />
								<div
									class="checkbox-label"
									matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
									[matTooltipPosition]="'right'">
									Override
								</div>
							</label>
						</div>
					</div>
					<div *ngIf="!overrideDetectDepartureStopSequence" class="box-override-col"></div>
					<div class="box-override-col-p">
						<field-label
							[name]="'detect_departure_stop_sequence'"
							[label]="'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_STOP_SEQUENCE' | translate"
							[required]="false"></field-label>
						<select *ngIf="stopSequence.length > 0" class="form-control" formControlName="detect_departure_stop_sequence">
							<option *ngFor="let stop of stopSequence; let i = index" value="{{ i + 2 }}">{{ i + 2 }}. {{ stop.name }}</option>
						</select>
						<select *ngIf="stopSequence.length === 0" formControlName="detect_departure_stop_sequence" class="form-control">
							<option value="2" selected>2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
						</select>
					</div>
				</div>
			</div>
			<div class="nb-subhead sub-title">{{ 'T_AGENCY.TERMINAL_DEPARTURE.NAVIGATION_SERVICE' | translate }}</div>
			<br />
			<div class="row override">
				<div class="nb-form-control check-override">
					<field-label [name]="'override_default_deadhead_seconds'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_default_deadhead_seconds_' + index }}">
							<input
								id="{{ 'override_default_deadhead_seconds_' + index }}"
								formControlName="override_default_deadhead_seconds"
								type="checkbox"
								(change)="
									exceptionOverrideCheck(getCheckedState($event.target) ? 'default_deadhead_seconds' : 'overrideDefaultDeadheadSeconds')
								" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideDefaultDeadheadSeconds" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'default_deadhead_seconds'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.DEFAULT_DEADHEADING' | translate"
						[required]="false"></field-label>
					<input class="form-control" disabled value="{{ defaultDeadheadValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<slider
						*ngIf="defaultDeadheadValues.length > 0"
						[values]="defaultDeadheadValues"
						[sliderCfg]="terminalDepartureSliderConfig"
						(updatedValues)="updateDefaultDeadheading($event)">
					</slider>
				</div>
			</div>
			<div class="row override">
				<div class="nb-form-control check-override">
					<field-label [name]="'override_min_dist_nav_service'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_min_dist_nav_service_' + index }}">
							<input
								id="{{ 'override_min_dist_nav_service_' + index }}"
								formControlName="override_min_dist_nav_service"
								type="checkbox"
								(change)="exceptionOverrideCheck(getCheckedState($event.target) ? 'min_dist_nav_service' : 'overrideMinDistNavService')" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideMinDistNavService" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'min_dist_nav_service'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.MIN_DIST_NAV_SERVICE' | translate"
						[required]="false"></field-label>
					<input
						class="form-control"
						disabled
						value="{{ minDistanceNavValues[0] === null ? 0 : minDistanceNavValues[0] }} {{ 'T_DEPOT.METERS' | translate }} " />
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<slider
						*ngIf="minDistanceNavValues.length > 0"
						[values]="minDistanceNavValues"
						[sliderCfg]="navServiceDistanceSliderCfg"
						(updatedValues)="updateMinDistanceNavService($event)">
					</slider>
				</div>
			</div>
			<div class="row override">
				<div class="nb-form-control check-override">
					<field-label [name]="'override_start_end_nav_service_throttle'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_start_end_nav_service_throttle_' + index }}">
							<input
								id="{{ 'override_start_end_nav_service_throttle_' + index }}"
								formControlName="override_start_end_nav_service_throttle"
								type="checkbox"
								(change)="
									exceptionOverrideCheck(getCheckedState($event.target) ? 'override_start_end_throttle' : 'overrideStartEndThrottle')
								" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideStartEndThrottle" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'start_nav_service_throttle'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.START_TIME_NAV_SERVICE' | translate"
						[required]="false"></field-label>
					<time-selector
						[initialTime]="form.controls.start_nav_service_throttle.value"
						(timeValid)="setTimeValid($event, 'start_nav_service_throttle')"
						(timeUpdated)="setUpdatedTime($event, 'start_nav_service_throttle')">
					</time-selector>
					<span *ngIf="hasCustomTimeError" class="text-danger">{{ customStartTimeErrorText }}</span>
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'end_nav_service_throttle'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.END_TIME_NAV_SERVICE' | translate"
						[required]="false"></field-label>
					<time-selector
						[initialTime]="form.controls.end_nav_service_throttle.value"
						(timeValid)="setTimeValid($event, 'end_nav_service_throttle')"
						(timeUpdated)="setUpdatedTime($event, 'end_nav_service_throttle')">
					</time-selector>
					<span *ngIf="hasCustomTimeError" class="text-danger">{{ customEndTimeErrorText }}</span>
					<span *ngIf="hasCustomTimeError" class="text-danger">{{ customTimeErrorText }}</span>
				</div>
			</div>
			<div class="row override">
				<div class="nb-form-control check-override">
					<field-label [name]="'override_nav_service_call_frequency'" [label]="" [required]="false"></field-label>
					<div class="nb-checkbox">
						<label for="{{ 'override_nav_service_call_frequency_' + index }}">
							<input
								id="{{ 'override_nav_service_call_frequency_' + index }}"
								formControlName="override_nav_service_call_frequency"
								type="checkbox"
								(change)="
									exceptionOverrideCheck(getCheckedState($event.target) ? 'nav_service_call_frequency' : 'overrideNavServiceCallFrequency')
								" />
							<div
								class="checkbox-label"
								matTooltip="{{ 'T_AGENCY.TERMINAL_DEPARTURE.APPLIES_TO_DEPARTURE_TERMINALS_TOOLTIP' | translate }}"
								[matTooltipPosition]="'right'">
								Override
							</div>
						</label>
					</div>
				</div>
				<div *ngIf="!overrideNavServiceCallFrequency" class="box-override"></div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<field-label
						[name]="'nav_service_call_frequency'"
						[label]="'T_AGENCY.TERMINAL_DEPARTURE.CALL_FREQUENCY_NAV_SERVICE' | translate"
						[required]="false"></field-label>
					<input
						class="form-control"
						disabled
						value="{{ callFrequencyNavValues[0] / 60 }} {{ 'T_AGENCY.SETTINGS.MINUTES' | translate }} " />
				</div>
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6 override-box-pt">
					<slider
						*ngIf="callFrequencyNavValues.length > 0"
						[values]="callFrequencyNavValues"
						[sliderCfg]="terminalDepartureSliderConfig"
						(updatedValues)="updateCallFrequencyNavService($event)">
					</slider>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-xs-6 col-sm-6 col-md-6 col-lg-6">
					<button (click)="deleteThisException()" class="btn btn-action">
						<i class="nb-icons nb-create"></i>{{ 'T_AGENCY.TERMINAL_DEPARTURE.DELETE_EXCEPTION' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</form>

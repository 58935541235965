/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component } from '@angular/core';

import { DataService } from '@cubicNx/libs/utils';

@Component({
	selector: 'password-expiry-warning',
	templateUrl: './password-expiry-warning.component.html',
	styleUrls: ['./password-expiry-warning.component.scss'],
})
export class PasswordExpiryWarningComponent {
	public warningMessage: string;
	public daysText: string;
	public days: string;

	constructor(public data: DataService) {
		this.warningMessage = this.data['warningMessage' as keyof DataService];
		this.daysText = this.data['daysText' as keyof DataService];
		this.days = this.data['days' as keyof DataService];
	}
}

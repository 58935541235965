<br />

<button (click)="editSettings()" class="btn btn-action edit-button">
	<i class="nb-icons nb-edit"></i>{{ 'T_AGENCY.EDIT' | translate }}
</button>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="settingsViewTableOptions"> </view-panel>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="adherenceViewTableOptions"> </view-panel>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="headwayViewTableOptions"> </view-panel>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="ridershipViewTableOptions"> </view-panel>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="twitterViewTableOptions"> </view-panel>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="legacyNextbusOptions"> </view-panel>

<view-panel [layout]="'row'" [model]="agencyDetail" [viewtableoptions]="terminalDepartureOptions"> </view-panel>

<view-panel
	*ngFor="let exception of agencyDetail.terminal_departure_exceptions"
	[layout]="'row'"
	[model]="exception"
	[viewtableoptions]="exception.exceptionOptions">
</view-panel>

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class ValidatorService {
	/**
	 * Validates the dependant control has values if the primary control has a specified value.
	 *
	 * @param primary - the name of the primary control.
	 * @param dependant - the name of the dependant control.
	 * @param value - the value the primary must have to make the dependant required.
	 * @returns a validation function.
	 */
	static dependantRequiredIfPrimaryHasValue = (primary: string, dependant: string, value: string): any => {
		return (group: FormGroup) => {
			const primaryControl: any = group.controls[primary];
			const dependantControl: any = group.controls[dependant];
			const hasValues: boolean = dependantControl?.value?.length > 0;

			if (primaryControl?.value === value && !hasValues) {
				dependantControl.setErrors({ required: dependant });
			} else {
				dependantControl?.setErrors(null);
			}
		};
	};

	/**
	 * Validates the dependant control to ensure at least one entry is selected.
	 *
	 * @param controlNames - the control names to validate.
	 * @returns the validation result
	 */
	static atLeastOneRequired = (controlNames: string[]): any => {
		return (group: FormGroup) => {
			let checked: number = 0;

			controlNames.forEach((name) => {
				const control: any = group.controls[name];

				if (control?.value === true) {
					checked++;
				}
			});

			if (checked < 1) {
				return {
					atLeastOneTrue: true,
				};
			}

			return null;
		};
	};
}

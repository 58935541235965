<!--
 COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.

 Information Contained Herein is Proprietary and Confidential.
 The document is the property of "CUBIC" and may not be disclosed
 distributed, or reproduced  without the express written permission of
 "CUBIC".
-->
<div *ngIf="vehicleUnassigning">
	<loading-overlay [isLoading]="vehicleUnassigning" [fit]="'content'"></loading-overlay>
</div>

<ng-template #routePillColumnTemplate let-row="row">
	<route-pill [route]="determineRoutePillData(row.id)" [useFullWidth]="true"></route-pill>
</ng-template>

<div *ngIf="!vehicleUnassigning">
	<div class="nb-map-details-content">
		<div class="nb-map-header-container" id="map-details-routes-nav-header">
			<div class="header-title-row">
				<div class="header-backbutton">
					<button class="btn btn-link-dark" data-test="route.list.back.btn" (click)="goBack()">
						<i class="nb-icons nb-backarrow"></i>
					</button>
				</div>
				<div class="header-title" data-test="route.list.title">
					<span class="nb-subhead">{{ 'T_CORE.VEHICLE' | translate }} </span>
					<span class="nb-subhead" data-test="vehicle.reassign.header.vehicleId">{{ vehicleId }} </span>
					<span class="nb-subhead">{{ 'T_MAP.MAP_REASSIGN' | translate }}</span>
				</div>
			</div>

			<div class="header-search-row float-right">
				<search-filter
					class="header-searchbar"
					[searchSuggestions]="routeSearchSuggestions"
					[filterValue]="searchText"
					(search)="search($event)">
				</search-filter>
			</div>

			<div *ngIf="listLoadingIndicator === false" class="header-filter-row no-print">
				<div class="header-searchresults">
					<span class="nb-helper-text">
						<span>{{ 'T_CORE.SHOWING' | translate }} </span>
						<span>{{ showingFirst }} </span>
						<span>{{ 'T_CORE.TO' | translate }} </span>
						<span>{{ showingLast }} </span>
						<span>{{ 'T_CORE.OF' | translate }} </span>
						<span>{{ totalRows }} </span>
						<span>{{ 'T_CORE.RESULTS' | translate }} </span>
					</span>
					<span class="nb-helper-text pull-right helper-text-offset" data-test="route.list.update-time">
						<span>{{ 'T_MAP.MAP_AS_OF' | translate }} </span>{{ updatedAt }}
					</span>
				</div>
			</div>
		</div>

		<div class="nb-mapheader-toolbar unassign-button-container" id="map-details-vehicle-reassign-toolbar">
			<div class="unassign-button-sub-container">
				<button
					type="button"
					class="btn btn-action"
					data-test="vehicle.reassign.unassign.btn"
					title="{{ unassignStatus }}"
					[disabled]="!vehicleBlockId"
					(click)="unassignVehicle()">
					{{ 'T_MAP.MAP_UNASSIGN' | translate }}
				</button>
			</div>
		</div>

		<div class="data-list-container" *ngIf="initialized">
			<data-table
				#dataList
				data-test="vehicle.reassign.routes.table"
				[listName]="listName"
				[columns]="columns"
				[rowData]="routesList"
				[pagingEnabled]="true"
				[totalRows]="totalRows"
				[enableTotal]="false"
				[rowHeight]="28"
				[defaultSortings]="defaultSortings"
				[pageInfo]="pageInfo"
				[enableFooter]="true"
				[loadingIndicator]="listLoadingIndicator"
				(rowClick)="onSelect($event)"
				(requestData)="handleDataRequest($event)">
			</data-table>
		</div>
	</div>
</div>

/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { DownloadHelpers } from '@cubicNx/libs/utils';
import { ObjectHelpers } from '@cubicNx/libs/utils';

import { INTERACTIVE_TEMPLATE_DETAIL_STORE, INTERACTIVE_SUMMARY_DETAIL_STORE, SUMMARY_DETAIL_STORE } from './reports-store-factory';

import { LoggerService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { ReportsConfigService } from './reports-config.service';
import { ReportsApiService } from './reports-api.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { AvailableReportsList, MultiGenerateReportType, ReportConfiguration } from '../types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { Agency } from '../../../support-features/agencies/types/api-types';
import { UserLogin } from '../../../support-features/login/types/api-types';

import {
	ReportSummaryDetail,
	AvailableReportPaginatedResponse,
	InteractiveReportPartPaginatedResponse,
	ReportTemplateDetail,
	ReportsPaginatedParams,
	ReportTemplatesPaginatedResponse,
	ReportVehicle,
	ReportVehicles,
	ReportRoutes,
	ReportRoute,
	ReportBlocks,
	ReportBlock,
	ReportTemplateDetails,
	RunReportResponse,
} from '../types/api-types';

import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class ReportsDataService {
	constructor(
		private logger: LoggerService,
		private reportsConfigService: ReportsConfigService,
		private reportsApiService: ReportsApiService,
		private currentUserUtilService: CurrentUserUtilService,
		private agenciesDataService: AgenciesDataService,
		private translationService: TranslationService,
		private notificationService: NotificationService,
		@Inject(INTERACTIVE_TEMPLATE_DETAIL_STORE) private interactiveTemplateDetailDataStore: any,
		@Inject(INTERACTIVE_SUMMARY_DETAIL_STORE) private interactiveSummaryDetailDataStore: any,
		@Inject(SUMMARY_DETAIL_STORE) private summaryDetailDataStore: any
	) {}

	/**
	 * handles the request for the available reports for the given authority and report search criteria from the nextbus API
	 *
	 * @param reportParameters - the report parameters
	 * @returns the searched for available reports response
	 */
	public getAvailableReports = async (reportParameters: ReportsPaginatedParams): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

			const authorityId: string = currentUser.primary_agency_authority_id;

			const response: any = await this.reportsApiService.getAvailableReports(authorityId, reportParameters);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as AvailableReportPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: AvailableReportPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as AvailableReportPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get available reports', error);
		}

		return result;
	};

	/**
	 * handles the request for the report summary details for the given authority, created by id and report id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @returns the required resport summary detail
	 */
	public getReportSummaryDetail = async (authorityId: string, createdById: number, reportId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + createdById + '-' + reportId;

			let summaryDetail: ReportSummaryDetail = this.summaryDetailDataStore.get(storeKey);

			if (!summaryDetail) {
				const response: ReportSummaryDetail = await this.reportsApiService.getReportSummaryDetail(
					authorityId,
					createdById,
					reportId
				);

				summaryDetail = response;

				// cache data as with reports - the data will never change (editing just creates a new report)
				this.summaryDetailDataStore.set(storeKey, summaryDetail);
			}

			result.resultData = summaryDetail;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get available report.', error);
		}

		return result;
	};

	/**
	 * handles the request for report template detail for the authority/agency and report details from either cache or nextbus API
	 *
	 * this makes use of the getInteractiveReportPart API method stipulating 'template' as the part
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportId - the report id
	 * @param createdById - the created by id
	 * @param defaultTemplateId - the default template id
	 * @returns the requested report template detail
	 */
	public getReportTemplateDetail = async (
		authorityId: string,
		agencyId: string,
		reportId: string,
		createdById: number,
		defaultTemplateId: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + agencyId + '-' + reportId + '-' + createdById + '-' + defaultTemplateId;
			let interactiveTemplateDetail: ReportTemplateDetail = this.interactiveTemplateDetailDataStore.get(storeKey);

			if (!interactiveTemplateDetail) {
				// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
				const requestParams: ReportsPaginatedParams = {
					...(reportId && { reportId }),
					requestedReportPart: 'template',
				};

				const reportEndpoint: string = this.reportsConfigService.getReportConfiguration(defaultTemplateId).endpoint;

				const response: any = await this.reportsApiService.getInteractiveReportPart(
					authorityId,
					agencyId,
					reportEndpoint,
					createdById,
					reportId,
					requestParams
				);

				// we either get the just the ReportTemplateDetail if the response is successful but if we get a fail the data
				// comes back with success: false, error:<error msg>
				if (response.success === false) {
					this.logger.logWarn('Failed to get interactive report template. Invalid Response', response);
					this.notificationService.notifyError(this.translationService.getTranslation('T_REPORT.REPORT_FETCH_FAILURE'));
				} else {
					interactiveTemplateDetail = response as ReportTemplateDetail;

					// Check the response for any vehicle content and ensure the type is always an array.
					// Currently the back-end may include vehicles either as an array or a single object.
					// This should be a back-end change but will handle it here for now
					// Edit: Turns out if when we create the vehicles, if we submit with an array of 1, rather
					// than a single vehicle object then everything works as expected and the data comes back in array
					// making the following method redundant. Unfortunately we keep this method call until all
					// reports have been created using the modern apporach (post release of reports Angular upgrade)
					this.adjustVehiclesData(interactiveTemplateDetail);

					// And similarly for received responses with route data
					this.adjustRoutesData(interactiveTemplateDetail);

					// And similarly for received responses with block data
					this.adjustBlocksData(interactiveTemplateDetail);

					// cache data as with reports - the data will never change (editing just creates a new report)
					this.interactiveTemplateDetailDataStore.set(storeKey, interactiveTemplateDetail);

					result.resultData = interactiveTemplateDetail;
					result.success = true;
				}
			} else {
				this.logger.logDebug(`Returning template details for: ${storeKey} from cached store`);

				result.resultData = interactiveTemplateDetail;
				result.success = true;
			}
		} catch (error) {
			this.logger.logError('Failed to get interactive report template.', error);
			this.notificationService.notifyError(this.translationService.getTranslation('T_REPORT.REPORT_FETCH_FAILURE'));
		}

		return result;
	};

	/**
	 * handles the request for interactive report summary detail for the authority/agency and report details from
	 * either cache or nextbus API
	 *
	 * this makes use of the getInteractiveReportPart API method stipulating 'summary' as the part
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportId - the report id
	 * @param createdById - the created by id
	 * @param defaultTemplateId - the default template id
	 * @returns the requested report summary detail
	 */
	public getInteractiveReportSummary = async (
		authorityId: string,
		agencyId: string,
		reportId: string,
		createdById: number,
		defaultTemplateId: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const storeKey: string = authorityId + '-' + agencyId + '-' + reportId + '-' + createdById + '-' + defaultTemplateId;
			let interactiveSummaryDetail: ReportSummaryDetail = this.interactiveSummaryDetailDataStore.get(storeKey);

			if (!interactiveSummaryDetail) {
				const requestParams: ReportsPaginatedParams = {
					reportId,
					requestedReportPart: 'summary',
				};

				const reportEndpoint: string = this.reportsConfigService.getReportConfiguration(defaultTemplateId).endpoint;

				const response: any = await this.reportsApiService.getInteractiveReportPart(
					authorityId,
					agencyId,
					reportEndpoint,
					createdById,
					reportId,
					requestParams
				);

				// cant have type safety - the type is dynamic based on report
				interactiveSummaryDetail = response as any;

				// cache data as with reports - the data will never change (editing just creates a new report)
				this.interactiveSummaryDetailDataStore.set(storeKey, interactiveSummaryDetail);
			}

			result.resultData = interactiveSummaryDetail;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get interactive report summary.', error);
		}

		return result;
	};

	/**
	 * handles the request for interactive report list for the authority/agency and report details from the nextbus API
	 *
	 * this makes use of the getInteractiveReportPart API method stipulating 'list' or 'list2' as the part
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param reportId - the report id
	 * @param createdById - the created by id
	 * @param defaultTemplateId - the default template id
	 * @param pageNumber - the page number
	 * @param pageSize - the page size
	 * @param sortBy - the sort by field
	 * @param sortDir - the sort by direction
	 * @param requestedReportPart - the requested report by
	 * @returns the requested interactive report list detail
	 */
	public getInteractiveReportList = async (
		authorityId: string,
		agencyId: string,
		reportId: string,
		createdById: number,
		defaultTemplateId: string,
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		requestedReportPart: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: ReportsPaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir }),
				...(reportId && { reportId }),
				...(requestedReportPart && { requestedReportPart }),
			};

			const reportEndpoint: string = this.reportsConfigService.getReportConfiguration(defaultTemplateId).endpoint;

			const response: any = await this.reportsApiService.getInteractiveReportPart(
				authorityId,
				agencyId,
				reportEndpoint,
				createdById,
				reportId,
				requestParams
			);

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as InteractiveReportPartPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: InteractiveReportPartPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as InteractiveReportPartPaginatedResponse;
			}

			// covers the scenario where [{}] is returned (ideally needs fixing in backend) so no data is handled appropriately
			if (result.resultData?.results && result.resultData.results.length === 1) {
				if (Object.keys(result.resultData.results[0]).length === 0) {
					result.resultData.results = null;
				}
			}

			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get interactive reports list.', error);
			this.notificationService.notifyError(this.translationService.getTranslation('T_REPORT.REPORT_FETCH_FAILURE'));
		}

		return result;
	};

	/**
	 * handles the request for the report templates for the given authority and paging, sort, search criteria from the nextbus API
	 *
	 * @param pageNumber - the page number
	 * @param pageSize - the page size
	 * @param sortBy - the sort by value
	 * @param sortDir - the sort by direction
	 * @param search - the searched for criteria
	 * @returns the searched for report templates
	 */
	public getReportTemplates = async (
		pageNumber: number,
		pageSize: number,
		sortBy: string,
		sortDir: string,
		search: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const defaultAgency: Agency = this.agenciesDataService.getDefaultAgency() || this.agenciesDataService.getAgencies()[0];
			const authorityId: any = defaultAgency.authority_id;

			// ... ensures that properties are only included if value is not null/undefined (as the backend expects)
			const requestParams: ReportsPaginatedParams = {
				...(pageNumber && { pageNum: pageNumber }),
				...(pageSize && { pageSize }),
				...(sortBy && { sort: sortBy }),
				...(sortDir && { sortDir }),
				...(search && { search }),
			};

			const response: any = await this.reportsApiService.getReportTemplates(authorityId, requestParams);

			result.success = true;

			// the backend typically returns a paged object including results and totals but can just return an array with
			// the dataset if the total is less than the page size. Ensure we return the response in the same format either way
			if (response.results) {
				// data is a paginated response
				result.resultData = response as ReportTemplatesPaginatedResponse;
			} else if (Array.isArray(response)) {
				const paginatedResponse: ReportTemplatesPaginatedResponse = {
					results: response,
					total: response.length,
					totalPages: 1,
				};

				result.resultData = paginatedResponse as ReportTemplatesPaginatedResponse;
			}
		} catch (error) {
			this.logger.logError('Failed to get report templates', error);
		}

		return result;
	};

	/**
	 * handles the request for the report template for the given authority, created by and report template from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportTemplateId - the report template id
	 * @returns the report template
	 */
	public getReportTemplate = async (authorityId: string, reportTemplateId: string, createdById: number): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: ReportTemplateDetail = await this.reportsApiService.getReportTemplate(
				authorityId,
				createdById,
				reportTemplateId
			);

			result.resultData = response;

			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to get report template', error);
		}

		return result;
	};

	/**
	 * handles the request for the deletion of an available reports for the given authority, created by id and
	 * report id from the nextbus API
	 *
	 * @param reports - the reports
	 * @returns the delete available report response - success or failure
	 */
	public deleteAvailableReports = async (reports: AvailableReportsList): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const successes: any[] = [];
			const errors: any[] = [];

			const requests: Promise<any>[] = reports.map(async (report) => {
				try {
					await this.reportsApiService.deleteAvailableReport(report.authorityId, report.createdById, report.reportId);
					successes.push(report);
				} catch (error) {
					errors.push({
						report,
						error,
					});
				}
			});

			await Promise.all(requests);

			if (errors.length) {
				errors.forEach((errorCfg) => {
					this.logger.logError('Failed to delete available report.', errorCfg.error);
				});
			}

			if (successes.length > 0) {
				result.success = true;
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'), '', {
					timeOut: 2000,
				});
			}
		} catch (error) {
			this.logger.logError('Failed to delete available reports', error);
		}

		return result;
	};

	/**
	 * handles the request for the deletion of an available report for the given authority, created by id and
	 * report id from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param reportId - the report id
	 * @returns the delete available report response - success or failure
	 */
	public deleteAvailableReport = async (authorityId: string, createdById: number, reportId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			await this.reportsApiService.deleteAvailableReport(authorityId, createdById, reportId);
			result.success = true;
			this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'), '', { timeOut: 2000 });
		} catch (error) {
			this.logger.logError('Failed to delete available report.', error);
			this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'), '', { timeOut: 2000 });
		}

		return result;
	};

	/**
	 * handles the request for the deletion of a report templates for the given authority, created by and
	 * report template from the nextbus API
	 *
	 * @param reporTemplateDetails - the report template details
	 * @returns the outcome response of the delete attempt
	 */
	public deleteReportTemplates = async (reporTemplateDetails: ReportTemplateDetails): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const successes: any[] = [];
			const errors: any[] = [];

			const requests: Promise<any>[] = reporTemplateDetails.map(async (template: ReportTemplateDetail) => {
				try {
					await this.reportsApiService.deleteReportTemplate(
						template.report_options.authority.authority_id,
						template.created_by_id,
						template.nb_id.toString()
					);
					successes.push(template);
				} catch (error) {
					errors.push({
						template,
						error,
					});
				}
			});

			await Promise.all(requests);

			if (errors.length) {
				errors.forEach((errorCfg) => {
					this.logger.logError('Failed to delete report template.', errorCfg.error);
				});
			}

			if (successes.length > 0) {
				result.success = true;
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'), '', {
					timeOut: 2000,
				});
			}
		} catch (error) {
			this.logger.logError('Failed to delete report templates', error);
		}

		return result;
	};

	/**
	 * handles the request for the deletion of a report template for the given authority, created by and
	 * report template from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param createdById - the created by id
	 * @param nbId - the nextbus report template id
	 * @returns the outcome response of the delete attempt
	 */
	public deleteReportTemplate = async (authorityId: string, createdById: number, nbId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			await this.reportsApiService.deleteReportTemplate(authorityId, createdById, nbId);

			result.success = true;

			this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'), '', { timeOut: 2000 });
		} catch (error) {
			this.logger.logError('Failed to delete report template.', error);

			this.notificationService.notifySuccess(this.translationService.getTranslation('T_CORE.DELETE_SUCCESS'), '', { timeOut: 2000 });
		}

		return result;
	};

	/**
	 * requests the download of an available report for the given authority/agency and other report details from the nextbus API
	 *
	 * @param authorityId - the authority id
	 * @param agencyId - the agency id
	 * @param apiEndpoint - the API endpoint
	 * @param userId - the user id
	 * @param reportId - the report id
	 * @param code - the code
	 * @param createdAt - the created at
	 * @returns the outcome response of the download report attempt
	 */
	public downloadAvailableReport = async (
		authorityId: string,
		agencyId: string,
		apiEndpoint: string,
		userId: string,
		reportId: string,
		code: string,
		createdAt: string
	): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const data: any = await this.reportsApiService.downloadReport(authorityId, agencyId, apiEndpoint, +userId, reportId, 'blob');

			DownloadHelpers.download(code + '_' + moment(createdAt).format('YYYY-MM-DD') + '.csv', data, 'text/csv');

			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to download available report.', error);
		}

		return result;
	};

	/**
	 * handles the request for the creation of a report template for the given authority and report request data from the nextbus API
	 *
	 * @param reportTemplate - the report template details
	 * @returns the outcome response for the creation attempt
	 */
	public createReportTemplate = async (reportTemplate: ReportTemplateDetail): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		try {
			const response: ReportTemplateDetail = await this.reportsApiService.createReportTemplate(
				reportTemplate.report_options.authority.authority_id,
				reportTemplate
			);

			if (response) {
				result.success = true;
				result.resultData = response;
			}
		} catch (error) {
			this.logger.logError('Failed to create report template.', error);
		}

		return result;
	};

	/**
	 * handles the request for the update of a report template for the given authority, report template and
	 * update request data from the nextbus API
	 *
	 * @param reportTemplateDetail - the report template details
	 * @returns the outcome response for the update attempt
	 */
	public updateReportTemplate = async (reportTemplateDetail: ReportTemplateDetail): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const authorityId: string = reportTemplateDetail.report_options.authority.authority_id;
		const createdById: number = reportTemplateDetail.created_by_id;
		const templateId: number = reportTemplateDetail.nb_id;

		try {
			const response: ReportTemplateDetail = await this.reportsApiService.updateReportTemplate(
				authorityId,
				createdById,
				templateId.toString(),
				reportTemplateDetail
			);

			result.resultData = response;
			result.success = true;
		} catch (error) {
			this.logger.logError('Failed to save report template', error);
		}

		return result;
	};

	/**
	 * handles the requests for the creation of one or more reports for the given authority/agency, report endopint
	 * and report request data from the nextbus API
	 *
	 * @param reportRequests - the report run requests
	 * @returns the outcome response of the run report attempt - sucess and status and id of the new report
	 */
	public runReports = async (reportRequests: ReportTemplateDetails): Promise<void> => {
		this.notificationService.notifySuccess(this.translationService.getTranslation('T_REPORT.REPORT_QUEUED'));

		// use normal for loop as foreach insists it uses async variation and it doesn't wait for the report to finish
		// and looping on to next
		for (const reportRequest of reportRequests) {
			await this.runReport(reportRequest, false);
		}
	};

	/**
	 * requests the creation of a report for the given authority/agency, report endpint and report request data
	 *
	 * the request is orchestrated here and makes use of the runSingleReport method
	 *
	 * @param reportRequest - the run report request
	 * @param showNotification - whether to show a notification or not
	 * @returns the outcome response of the run report attempt - sucess and status and id of the new report
	 */
	public runReport = async (reportRequest: ReportTemplateDetail, showNotification: boolean = true): Promise<void> => {
		const reportConfig: ReportConfiguration = this.reportsConfigService.getReportConfiguration(reportRequest.default_template_id);

		if (reportConfig.reportGenerationOverride) {
			const reportGenerateTemplateId: string = reportConfig.reportGenerationOverride.defaultTemplateId;

			if (reportRequest.code === reportConfig.code) {
				// code hasn't been overwritten by user - switch to override code
				reportRequest.code = this.reportsConfigService.getReportConfiguration(reportGenerateTemplateId).code;
			}

			const defaultName: string = this.translationService.getTranslation(
				('T_REPORT.TEMPLATES.' + reportRequest.default_template_id + '.name').toUpperCase()
			);

			if (reportRequest.name === defaultName) {
				// name hasn't been overwritten by user - switch to override name
				reportRequest.name = this.translationService.getTranslation(
					('T_REPORT.TEMPLATES.' + reportGenerateTemplateId + '.name').toUpperCase()
				);
			}

			const defaultDescription: string = this.translationService.getTranslation(
				('T_REPORT.TEMPLATES.' + reportRequest.default_template_id + '.description').toUpperCase()
			);

			if (reportRequest.description === defaultDescription) {
				// descrisption hasn't been overwritten by user - switch to override name
				reportRequest.description = this.translationService.getTranslation(
					('T_REPORT.TEMPLATES.' + reportGenerateTemplateId + '.description').toUpperCase()
				);
			}

			// override the default template id
			reportRequest.default_template_id = reportGenerateTemplateId;

			switch (reportConfig.reportGenerationOverride.multiGenerateReportType) {
				case MultiGenerateReportType.perRoute:
					// not implemented
					break;
				case MultiGenerateReportType.perBlock:
					if (showNotification) {
						this.notificationService.notifySuccess(this.translationService.getTranslation('T_REPORT.REPORT_QUEUED'));
					}

					// use normal for loop as foreach insists it uses async variation and it doesn't wait for the report to finish
					// and looping on to next
					for (const selectedBlock of reportRequest.report_options.blocks) {
						// create new template with just the single block in the request
						const reportTemplate: ReportTemplateDetail = ObjectHelpers.deepCopy(reportRequest);

						reportTemplate.report_options.blocks = [selectedBlock];

						await this.runSingleReport(reportTemplate);
					}
					break;
			}
		} else {
			if (showNotification) {
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_REPORT.RUNNING_REPORT'), '', {
					timeOut: 2000,
				});
			}

			// run the single report in the usual way
			await this.runSingleReport(reportRequest);
		}
	};

	/**
	 * requests the creation of a report for the given report request data from the nextbus API
	 *
	 * @param reportRequest - the report request details
	 * @returns the outcome response of the run report attempt - sucess and status and id of the new report
	 */
	public runSingleReport = async (reportRequest: ReportTemplateDetail): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		const authorityId: string = reportRequest.report_options.authority.authority_id;
		const agencyId: string = reportRequest.report_options.agencies.agency_id;

		const reportEndpoint: string = this.reportsConfigService.getReportConfiguration(reportRequest.default_template_id).endpoint;

		try {
			const response: RunReportResponse = await this.reportsApiService.runReport(
				authorityId,
				agencyId,
				reportEndpoint,
				reportRequest
			);

			reportRequest.report_id = response.data.report_id;

			result.resultData = reportRequest;
			result.success = true;
		} catch (error) {
			this.notificationService.notifyError(this.translationService.getTranslation('T_CORE.SERVER_ERROR'));

			this.logger.logError('Failed to run report', error);
		}

		return result;
	};

	/**
	 * utility that adjusts the vehicle data within the supplied interactive template detail
	 *
	 * @param interactiveTemplateDetail - interactive report template detail
	 */
	private adjustVehiclesData = (interactiveTemplateDetail: ReportTemplateDetail): void => {
		if (interactiveTemplateDetail.report_options.vehicles) {
			if (this.isVehicle(interactiveTemplateDetail.report_options.vehicles)) {
				const vehicles: ReportVehicles = [];

				vehicles.push(interactiveTemplateDetail.report_options.vehicles);
				interactiveTemplateDetail.report_options.vehicles = vehicles;
			}
		}
	};

	/**
	 * utility that adjusts the route data within the supplied interactive template detail
	 *
	 * @param interactiveTemplateDetail - interactive report template detail
	 */
	private adjustRoutesData = (interactiveTemplateDetail: ReportTemplateDetail): void => {
		if (interactiveTemplateDetail.report_options.routes) {
			if (this.isRoute(interactiveTemplateDetail.report_options.routes)) {
				const routes: ReportRoutes = [];

				routes.push(interactiveTemplateDetail.report_options.routes);
				interactiveTemplateDetail.report_options.routes = routes;
			}
		}
	};

	/**
	 * utility that adjusts the blocks data within the supplied interactive template detail
	 *
	 * @param interactiveTemplateDetail - interactive report template detail
	 */
	private adjustBlocksData = (interactiveTemplateDetail: ReportTemplateDetail): void => {
		if (interactiveTemplateDetail.report_options.blocks) {
			if (this.isBlock(interactiveTemplateDetail.report_options.blocks)) {
				const blocks: ReportBlocks = [];

				blocks.push(interactiveTemplateDetail.report_options.blocks);
				interactiveTemplateDetail.report_options.blocks = blocks;
			}
		}
	};

	/**
	 * determines if the supplied routes parameter is a route instance
	 *
	 * @param routes - the routes information
	 * @returns true if the supplied routes data is a route, false otherwise
	 */
	private isRoute = (routes: ReportRoutes | ReportRoute): routes is ReportRoute => {
		return (routes as ReportRoute).route_id !== undefined;
	};

	/**
	 * determines if the supplied vehicles parameter is a vehicle instance
	 *
	 * @param vehicles - the vehicles information
	 * @returns true if the supplied vehicles data is a vehicle, false otherwise
	 */
	private isVehicle = (vehicles: ReportVehicles | ReportVehicle): vehicles is ReportVehicle => {
		return (vehicles as ReportVehicle).vehicle_id !== undefined;
	};

	/**
	 * determines if the supplied blocks parameter is a block instance
	 *
	 * @param blocks - the blocks information
	 * @returns true if the supplied blocks data is a block, false otherwise
	 */
	private isBlock = (blocks: ReportBlocks | ReportBlock): blocks is ReportBlock => {
		return (blocks as ReportBlock | ReportBlock).block_id !== undefined;
	};
}

<div class="nb-report-viewer-list nb-padding-all-md report-viewer-list-container">
	<div class="nb-report-viewer-summary-inner">
		<div class="report-viewer-grid-container block-assignments-list-container">
			<div *ngIf="listData" class="report-viewer-grid">
				<table>
					<thead>
						<tr>
							<th
								[ngClass]="sortDetails.field === 'block_id' ? sortClass : null"
								(click)="sortList('block_id')"
								data-test="th.block_id"
								class="ellipsis">
								{{ 'T_CORE.BLOCK' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'vehicle_id' ? sortClass : null"
								(click)="sortList('vehicle_id')"
								data-test="th.vehicle_id"
								class="ellipsis">
								{{ 'T_CORE.VEHICLE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'scheduled_start_time' ? sortClass : null"
								(click)="sortList('scheduled_start_time')"
								data-test="th.scheduled_start_time"
								class="mw-col ellipsis">
								{{ 'T_CORE.SCHEDULED_START' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'time_of_assignment' ? sortClass : null"
								(click)="sortList('time_of_assignment')"
								data-test="th.time_of_assignment"
								class="mw-col ellipsis">
								{{ 'T_CORE.ASSIGNMENT_TIME' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'assignment_difference' ? sortClass : null"
								(click)="sortList('assignment_difference')"
								data-test="th.assignment_difference"
								class="mw-col ellipsis">
								{{ 'T_CORE.DIFFERENCE' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'scheduled_end_time' ? sortClass : null"
								(click)="sortList('scheduled_end_time')"
								data-test="th.scheduled_end_time"
								class="mw-col ellipsis">
								{{ 'T_CORE.SCHEDULED_END' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'status' ? sortClass : null"
								(click)="sortList('status')"
								data-test="th.status"
								class="mw-col ellipsis">
								{{ 'T_REPORT.BLOCK_STATUS' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'gps_reports' ? sortClass : null"
								(click)="sortList('gps_reports')"
								data-test="th.gps_reports"
								class="mw-col ellipsis">
								{{ 'T_REPORT.GPS_REPORTS' | translate }}
							</th>
							<th
								[ngClass]="sortDetails.field === 'source' ? sortClass : null"
								(click)="sortList('source')"
								data-test="th.source"
								class="mw-col ellipsis">
								{{ 'T_REPORT.SOURCE' | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let reportItem of listData">
							<td class="ellipsis">{{ reportItem.block_id }}</td>
							<td class="ellipsis">{{ reportItem.vehicle_id }}</td>
							<td class="ellipsis">
								{{
									reportItem.scheduled_start_time !== '—'
										? (reportItem.scheduled_start_time | date: 'y-MM-dd &nbsp; HH:mm' : agencyTimezoneOffset)
										: '—'
								}}
							</td>
							<td class="ellipsis">
								{{
									reportItem.time_of_assignment !== '—'
										? (reportItem.time_of_assignment | date: 'y-MM-dd &nbsp; HH:mm' : agencyTimezoneOffset)
										: '—'
								}}
							</td>
							<td>
								<block-difference-display [blockDifference]="reportItem.assignment_difference"></block-difference-display>
							</td>
							<td class="ellipsis">
								{{ reportItem.scheduled_end_time !== '—' ? (reportItem.scheduled_end_time | date: 'HH:mm' : agencyTimezoneOffset) : '—' }}
							</td>
							<td>
								<block-status-display [status]="reportItem.status"></block-status-display>
							</td>
							<td class="ellipsis">{{ reportItem.gps_reports }}</td>
							<td class="ellipsis">{{ reportItem.source }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="!listData">
				{{ 'T_REPORT.NO_AVAILABLE_DATA' | translate }}
			</div>
		</div>
	</div>
</div>

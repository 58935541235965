/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { ConfigService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../../support-features/agencies/services/agencies-data.service';
import { UsersAdminDataService } from '../../../services/users-admin-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { CurrentUserPermissionService } from '../../../../../../support-features/login/services/current-user/current-user-permission.service';

import { ResultContent } from '@cubicNx/libs/utils';
import { Agencies, Agency } from '../../../../../../support-features/agencies/types/api-types';
import { PermissionRequest } from '../../../../../../support-features/login/types/types';
import { UserAdminRoleAuthorities, UserAdminRoleAuthority } from '../../../types/types';
import { Role, RolesSummary, RoleSummary, UserDetail, UpdatedRoles, UserRoles } from '../../../types/api-types';

@Component({
	selector: 'user-admin-role-edit',
	templateUrl: './user-admin-role-edit.component.html',
	styleUrls: ['./user-admin-role-edit.component.scss'],
})
export class UserAdminRoleEditComponent extends TranslateBaseComponent implements OnInit {
	@Input() user: UserDetail;

	@Output() rolesUpdated: EventEmitter<UpdatedRoles> = new EventEmitter<UpdatedRoles>();
	@Output() refreshParentTabControl: EventEmitter<void> = new EventEmitter<void>();

	public panelContentDisplay: any = null;
	public loading: boolean = true;
	public showNextBusRole: boolean = false;
	public userIsNextBusMaster: boolean = false;
	public agencySearchSuggestions: string[] = [];
	public masterRoleName: string = null;
	public authorities: UserAdminRoleAuthority[] = [];
	public primaryAgency: number = null;
	public roles: RolesSummary = [];

	private readonly nextBusRoleLabel: string = 'Umo IQ';

	private newAgencyRoles: UserRoles = null;
	private oldAgencyRoles: UserRoles = null;
	private adminRoleName: string = null;
	private basicUserRoleName: string = null;
	private guestUserRoleName: string = null;
	private allAvailableAgencies: Agencies = [];
	private agencies: Agencies = [];

	constructor(
		private currentUserPermissionService: CurrentUserPermissionService,
		private agenciesDataService: AgenciesDataService,
		private userAdminDataService: UsersAdminDataService,
		private configService: ConfigService,
		translationService: TranslationService
	) {
		super(translationService);

		this.newAgencyRoles = [];
		this.oldAgencyRoles = [];
		this.authorities = [];
		this.showNextBusRole = true;

		this.panelContentDisplay = {
			nextbus: false,
		};
	}

	/**
	 * performs initialization tasks for the user role edit view
	 */
	public async ngOnInit(): Promise<void> {
		this.masterRoleName = this.configService.getAdminMasterRoleName();
		this.adminRoleName = this.configService.getAdminAgencyRoleName();
		this.basicUserRoleName = this.configService.getBasicUserRoleName();
		this.guestUserRoleName = this.configService.getGuestUserRoleName();
		this.userIsNextBusMaster = this.currentUserPermissionService.isNextbusAdmin();
		this.allAvailableAgencies = this.agenciesDataService.getAgencies();

		this.agencies = [];

		this.allAvailableAgencies.forEach((availableAgency: Agency) => {
			const permissionRequest: PermissionRequest = {
				authorityId: availableAgency.authority_id,
				agencyId: availableAgency.agency_id,
			};

			if (
				this.currentUserPermissionService.isAdmin(permissionRequest) ||
				this.currentUserPermissionService.isAuthorityAdmin(permissionRequest)
			) {
				this.agencies.push(availableAgency);
			}
		});

		const rolesResponse: ResultContent = await this.userAdminDataService.getRoles();

		if (rolesResponse.success) {
			this.roles = rolesResponse.resultData as RolesSummary;
			this.roles.sort((a, b) => a.localized_name.localeCompare(b.localized_name));

			this.assignRolesToCols();

			let hasMasterRole: boolean = false;

			if (this.userHasMasterRole()) {
				hasMasterRole = true;
			}

			const masterRoleId: number = this.getMasterRoleId();

			this.newAgencyRoles.push({
				authorityId: null,
				agencyId: null,
				agencyNbId: null,
				roleId: masterRoleId,
				roleName: this.masterRoleName,
				selected: hasMasterRole,
			});

			this.oldAgencyRoles.push({
				authorityId: null,
				agencyId: null,
				agencyNbId: null,
				roleId: masterRoleId,
				roleName: this.masterRoleName,
				selected: hasMasterRole,
			});

			this.organizeAgencies();

			this.assocRolesAuthorities();

			this.assocRolesAgencies();

			this.initPanelDisplay();

			if (this.user.user_profile) {
				this.primaryAgency = this.user.user_profile.nb_primary_agency;
			} else {
				if (this.authorities.length === 1) {
					this.primaryAgency = this.authorities[0].agency[0].nb_id;
				}
			}

			this.buildSearchSuggestions();
		}

		this.loading = false;
	}

	/**
	 * When role panel is toggled the parent is informed
	 * workaround for selected tab ink bar not aligning properly when a
	 * toggle a panel can add/remove a scroll bar
	 *
	 * @param panelId - panel id
	 */
	public onTogglePanel = (panelId: string): void => {
		this.panelContentDisplay[panelId] = !this.panelContentDisplay[panelId];

		this.refreshParentTabControl.emit();
	};

	/**
	 * retrieves the panel display for the supplied authority
	 *
	 * @param authorityId - authority id
	 * @returns the panel display for the supplied authority
	 */
	public getPanelDisplay = (authorityId: string): any => {
		return this.panelContentDisplay[authorityId];
	};

	/**
	 * when a agency role is toggled, the role panel information is derived
	 *
	 * @param authorityId - authority id
	 * @param agencyId - agency id
	 * @param roleId - role id
	 */
	public toggleHasAgencyRole = (authorityId: string, agencyId: number, roleId: number): void => {
		let firstAuthority: number = null;
		let primaryActive: boolean = false;

		this.newAgencyRoles.forEach((agencyRole) => {
			if (agencyRole.roleId === roleId && agencyRole.authorityId === authorityId && agencyRole.agencyNbId === agencyId) {
				agencyRole.selected = !agencyRole.selected;

				this.authorities.forEach((authority) => {
					if (authority.authorityId === authorityId) {
						if (agencyRole.selected) {
							authority.roleCount++;
						} else {
							authority.roleCount--;
						}
					}
				});

				if (!this.isNumber(this.primaryAgency)) {
					this.primaryAgency = agencyId;
					primaryActive = true;
				}
			}

			if (agencyRole.selected) {
				if (!firstAuthority) {
					firstAuthority = agencyRole.agencyNbId;
				}

				if (agencyRole.agencyNbId === this.primaryAgency) {
					primaryActive = true;
				}
			}
		});

		if (!primaryActive) {
			this.primaryAgency = firstAuthority;
		}

		this.assignRoles();
	};

	/**
	 * determines if there is an agency role for the supplied parameters
	 *
	 * @param agencyId - agency id
	 * @param roleId - role id
	 * @returns true if there is an agency role for the supplied parameters, false otherwise
	 */
	public userHasAgencyRole = (agencyId: number, roleId: number): boolean => {
		// note: can't just return out of foreach so set flag
		let hasAgencyRole: boolean = false;

		this.user.user_roles.forEach((role) => {
			if (role.associated_role && role.agency_info) {
				if (role.associated_role.nb_id === roleId && role.agency_info.nb_id === agencyId) {
					hasAgencyRole = true;
				}
			}
		});

		return hasAgencyRole;
	};

	/**
	 * toggles the has master role
	 */
	public toggleHasMasterRole = (): void => {
		this.newAgencyRoles.forEach((newAgencyRole) => {
			if (newAgencyRole.roleName === this.masterRoleName) {
				newAgencyRole.selected = !newAgencyRole.selected;
			}
		});
		this.assignRoles();
	};

	/**
	 * determines whether the user has a master role
	 *
	 * @returns true if they have a master role, false otherwise
	 */
	public userHasMasterRole = (): boolean => {
		let hasMasterRole: boolean = false;

		if (this.user.user_roles) {
			this.user.user_roles.forEach((role) => {
				if (role.associated_role) {
					if (role.associated_role.name === this.masterRoleName) {
						hasMasterRole = true;
					}
				}
			});
		}

		return hasMasterRole;
	};

	/**
	 * determines if the agency option is valid
	 *
	 * @param agency - agency id
	 * @returns true if the agency option is valid
	 */
	public agencyOptionIsValid = (agency: Agency): boolean => {
		let found: boolean = false;

		if (typeof this.user.user_roles !== 'undefined') {
			this.user.user_roles.forEach((item) => {
				if (
					typeof item.associated_role !== 'undefined' &&
					typeof item.associated_role.name !== 'undefined' &&
					item.associated_role.name === 'UMO_ADMIN'
				) {
					found = true;
				} else if (item.agency_info === null && typeof item.authority !== 'undefined' && item.authority === agency.authority_id) {
					found = true;
				} else if (
					typeof item.agency_info !== 'undefined' &&
					item.agency_info !== null &&
					typeof item.agency_info.agency_name !== 'undefined' &&
					typeof item.agency_info.authority_id !== 'undefined' &&
					item.agency_info.agency_name === agency.agency_name &&
					item.agency_info.authority_id === agency.authority_id
				) {
					found = true;
				}
			});
		}

		return found;
	};

	/**
	 * remembers the primary agency name and assigns roles
	 *
	 * @param agency - agency details
	 */
	public setPrimaryAgencyName = (agency: Agency): void => {
		this.primaryAgency = agency.nb_id;
		this.assignRoles();
	};

	/**
	 * populates the relevant roles based upon the supplied search criteria
	 *
	 * @param searchFilter - search criteria
	 */
	public search = (searchFilter: string): void => {
		if (searchFilter.length === 0) {
			this.showNextBusRole = true;

			this.authorities.forEach((authority) => {
				authority.hide = false;
				this.panelContentDisplay[authority.authorityId] = false;
			});

			// revert to default?
			this.initPanelDisplay();

			return;
		}

		this.showNextBusRole = this.nextBusRoleLabel.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;

		this.authorities.forEach((authority) => {
			let matched: boolean = false;

			if (
				authority.authorityId.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1 ||
				authority.authorityName.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1
			) {
				matched = true;
			}

			authority.agency.forEach((agency: Agency) => {
				if (agency.agency_name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
					matched = true;
				}
			});

			if (matched) {
				authority.hide = false;
				this.panelContentDisplay[authority.authorityId] = true;
			} else {
				authority.hide = true;
				this.panelContentDisplay[authority.authorityId] = false;
			}
		});
	};

	/**
	 * builds suggestions list for searching purposes
	 */
	private buildSearchSuggestions = (): void => {
		this.addSearchSuggestion(this.nextBusRoleLabel);

		// build a list of authorities/agencies
		this.authorities.forEach((authority) => {
			this.addSearchSuggestion(authority.authorityId);
			this.addSearchSuggestion(authority.authorityName);

			authority.agency.forEach((agency: Agency) => {
				this.addSearchSuggestion(agency.agency_name);
			});
		});
	};

	/**
	 * adds the supplied suggestion to suggestions list
	 *
	 * @param searchSuggestion - search suggestion
	 */
	private addSearchSuggestion = (searchSuggestion: string): void => {
		if (this.agencySearchSuggestions.indexOf(searchSuggestion) === -1) {
			this.agencySearchSuggestions.push(searchSuggestion);
		}
	};

	/**
	 * associate roles and authorities
	 */
	private assocRolesAuthorities = (): void => {
		this.authorities.forEach((authority) => {
			const isSelected: boolean = this.userHasAuthorityAdminRole(authority.authorityId);

			const adminRoleId: number = this.getAdministratorRoleId();

			this.newAgencyRoles.push({
				authorityId: authority.authorityId,
				authorityName: authority.authorityName,
				agencyName: null,
				agencyId: null,
				agencyNbId: null,
				associated_role_nb_id: adminRoleId,
				roleId: adminRoleId,
				roleName: 'Administrator',
				selected: isSelected,
			});

			this.oldAgencyRoles.push({
				authorityId: authority.authorityId,
				authorityName: authority.authorityName,
				agencyId: null,
				agencyNbId: null,
				roleId: adminRoleId,
				roleName: 'Administrator',
				selected: isSelected,
			});
		});
	};

	/**
	 * associate roles and agencies
	 */
	private assocRolesAgencies = (): void => {
		this.agencies.forEach((agency) => {
			this.roles.forEach((role: RoleSummary) => {
				if (role.name !== this.masterRoleName) {
					let isSelected: boolean = false;

					this.user.user_roles.forEach((userRole: Role) => {
						if (userRole.associated_role && userRole.agency_info) {
							if (userRole.associated_role.nb_id === role.nb_id && userRole.agency_info.nb_id === agency.nb_id) {
								isSelected = true;
							}
						}
					});

					this.newAgencyRoles.push({
						authorityId: agency.authority_id,
						authorityName: agency.authority_name,
						agencyName: agency.agency_name,
						associated_role_nb_id: role.nb_id,
						agencyId: agency.agency_id,
						agencyNbId: agency.nb_id,
						roleId: role.nb_id,
						roleName: role.name,
						selected: isSelected,
					});

					this.oldAgencyRoles.push({
						authorityId: agency.authority_id,
						authorityName: agency.authority_name,
						agencyId: agency.agency_id,
						agencyName: agency.agency_name,
						agencyNbId: agency.nb_id,
						roleId: role.nb_id,
						roleName: role.name,
						selected: isSelected,
					});
				}
			});
		});
	};

	/**
	 * organise agencies
	 */
	private organizeAgencies = (): void => {
		const authorities: UserAdminRoleAuthorities = {};

		this.agencies.forEach((agency: Agency) => {
			if (!authorities[agency.authority_id]) {
				authorities[agency.authority_id] = {
					authorityId: agency.authority_id,
					authorityName: agency.authority_name,
					hide: false,
					agency: [],
					roleCount: 0,
				};
			}

			authorities[agency.authority_id].agency.push(agency);
		});

		const authoritiesList: UserAdminRoleAuthority[] = [];

		for (const key in authorities) {
			authoritiesList.push(authorities[key]);
		}

		const collator: Intl.Collator = new Intl.Collator(undefined, {
			numeric: true,
			sensitivity: 'base',
			ignorePunctuation: true,
		});

		this.authorities = authoritiesList.sort((a, b) =>
			collator.compare(a.authorityName || a.authorityId, b.authorityName || b.authorityId)
		);
	};

	/**
	 * assigns roles to columns
	 */
	private assignRolesToCols = (): void => {
		const cols: number = 4;
		let roleCount: number = 0;

		this.roles.forEach((role) => {
			if (role.name !== this.masterRoleName && role.name !== this.basicUserRoleName && role.name !== this.guestUserRoleName) {
				roleCount++;
			}
		});

		const rolesPerCol: number = Math.ceil(roleCount / cols);

		let rolesInCol: number = 0;

		this.roles.forEach((role: RoleSummary) => {
			if (role.name !== this.masterRoleName && role.name !== this.basicUserRoleName && role.name !== this.guestUserRoleName) {
				rolesInCol++;

				if (rolesInCol === rolesPerCol) {
					rolesInCol = 0;
				}
			}
		});
	};

	/**
	 * emits the updated roles to the parent component
	 */
	private assignRoles = (): void => {
		const updatedRoles: UpdatedRoles = {
			primaryAgency: this.primaryAgency,
			userRolesUpdated: this.newAgencyRoles,
			userRolesOld: this.oldAgencyRoles,
		};

		this.rolesUpdated.emit(updatedRoles);
	};

	/**
	 * retrieves the master role id
	 *
	 * @returns master role id
	 */
	private getMasterRoleId = (): number => {
		const masterRole: RoleSummary = this.roles.filter((role) => role.name === this.masterRoleName)[0];

		return masterRole?.nb_id;
	};

	/**
	 * retrieves the administrator role id
	 *
	 * @returns administrator role id
	 */
	private getAdministratorRoleId = (): number => {
		const administratorRole: RoleSummary = this.roles.filter((role) => role.name.includes(this.adminRoleName))[0];

		return administratorRole?.nb_id;
	};

	/**
	 * utility to determine if the supplied object is a number
	 *
	 * @param o - object
	 * @returns true if the supplied object is a number, false otherwise
	 */
	private isNumber = (o: any): boolean => {
		return !isNaN(o - 0) && o !== null && o !== '' && o !== false;
	};

	/**
	 * initialises the panel
	 */
	private initPanelDisplay = (): void => {
		if (this.userHasMasterRole()) {
			this.panelContentDisplay.nextbus = true;
		}

		if (this.authorities.length === 1) {
			this.panelContentDisplay[this.authorities[0].authorityId] = true;
		}

		this.authorities.forEach((authority) => {
			if (this.userHasAuthorityAdminRole(authority.authorityId)) {
				authority.roleCount++;
			}

			authority.agency.forEach((agency: Agency) => {
				this.roles.forEach((role) => {
					if (this.userHasAgencyRole(agency.nb_id, role.nb_id)) {
						authority.roleCount++;
					}
				});
			});

			if (authority.roleCount > 0) {
				this.panelContentDisplay[authority.authorityId] = true;
			}
		});
	};

	/**
	 * determines if the user has an authority admin role
	 *
	 * @param authorityId - authority id
	 * @returns true if the user has an authority admin role, false otherwise
	 */
	private userHasAuthorityAdminRole = (authorityId: string): boolean => {
		// note: can't just return out of foreach so set flag
		let hasAuthority: boolean = false;

		this.user.user_roles.forEach((role) => {
			if (role.agency_info === null && role.authority !== null && role.authority === authorityId) {
				hasAuthority = true;
			}
		});

		return hasAuthority;
	};
}

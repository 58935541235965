<div *ngIf="!blockLoaded">
	<loading-overlay [isLoading]="true" [fit]="'content'"></loading-overlay>
</div>
<div *ngIf="blockLoaded">
	<!--Header container-->
	<div class="nb-map-subpage-header-container nb-map-details-body block-details-container" id="map-details-block-header">
		<div class="header-backbutton move-up">
			<button (click)="goBack()" class="btn btn-link-dark" data-test="block.details.back.btn">
				<i class="nb-icons nb-backarrow"></i>
			</button>
		</div>
		<div class="header-content">
			<div class="header-title">
				<span class="nb-subhead block-details-title">
					<span>{{ 'T_MAP.MAP_BLOCK' | translate }} </span>
					<span data-test="block.details.block_id">{{ blockId }}</span>
				</span>
			</div>
			<div class="header-body">
				<div class="map-block-details-header">
					<div class="field-label">
						<span>{{ 'T_MAP.MAP_TIME' | translate }}</span>
					</div>
					<div class="field-value">
						<span data-test="block.details.block_time">{{ blockTimeRangeDisplay }}</span>
					</div>
					<div class="field-label">
						<span>{{ 'T_MAP.MAP_STATUS' | translate }}</span>
					</div>
					<div class="field-value" data-test="block.details.block_status">
						<span *ngIf="block.status === blockStatus.assigned" class="label nb-block-assigned">{{
							'T_MAP.BLOCK_ASSIGNED' | translate
						}}</span>
						<span *ngIf="block.status === blockStatus.unassigned" class="label nb-block-unassigned">{{
							'T_MAP.BLOCK_UNASSIGNED' | translate
						}}</span>
						<span *ngIf="block.status === blockStatus.canceled" class="label nb-block-canceled">{{
							'T_MAP.BLOCK_CANCELED' | translate
						}}</span>
					</div>
					<div class="field-label">
						<span>{{ 'T_MAP.MAP_VEHICLES' | translate }}</span>
					</div>
					<div class="field-value" data-test="block.details.block_vehicles">
						<div class="block-details-vehicles-container">
							<div
								*ngFor="let vehicle of block.vehicles; let last = last"
								(click)="navigateToVehicleDetails(vehicle.vehicle_id)"
								class="block-details-vehicles">
								{{ vehicle.vehicle_id }}
								<span *ngIf="!last">,&nbsp;</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="nb-mapheader-toolbar" id="map-details-route-toolbar">
		<!--Buttons here, like view-on/off, zoom to object, other actions-->
		<span [hidden]="!blockIsRendered()">
			<button
				class="btn btn-toggle-on"
				(click)="toggleRenderBlock()"
				title="{{ 'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_OFF_TOOLTIP' | translate }}"
				[disabled]="toggleBlockDisabled"
				data-test="block.details.toggleVisibility.off">
				<i class="nb-icons nb-view-on visibility-button-icon"></i>
			</button>
		</span>
		<span [hidden]="blockIsRendered()">
			<button
				class="btn btn-toggle"
				(click)="toggleRenderBlock()"
				title="{{ 'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_ON_TOOLTIP' | translate }}"
				[disabled]="toggleBlockDisabled"
				data-test="block.details.toggleVisibility.on">
				<i class="nb-icons nb-view-off visibility-button-icon"></i>
			</button>
		</span>
		<button class="btn btn-action" (click)="zoomTo()" title="{{ 'T_MAP.MAP_VIEW_ZOOM_TO' | translate }}" data-test="block.details.zoomTo">
			<i class="nb-icons nb-zoomtoobject zoom-button-icon" aria-hidden="true"></i>
		</button>
		<div class="nb-blocks-trip-header-action-buttons">
			<div class="cancel-block-container" title="{{ cancelBlockTooltip }}">
				<button class="btn btn-action" (click)="cancelBlock()" [disabled]="!enableCancelBlock" data-test="block.details.cancel_block">
					<i class="fa fa-times-circle-o cancel-block-icon" aria-hidden="true"></i>
					<span>{{ 'T_MAP.MAP_BLOCKS_CANCEL_BLOCK' | translate }}</span>
				</button>
			</div>
			<div class="activate-block-container" title="{{ activateBlockTooltip }}">
				<button class="btn btn-action" (click)="activateBlock()" [disabled]="!enableActivateBlock" data-test="block.details.cancel_block">
					<i class="fa fa-plus-circle activate-block-icon" aria-hidden="true"></i>
					<span>{{ 'T_MAP.MAP_BLOCKS_ACTIVATE_BLOCK' | translate }}</span>
				</button>
			</div>
		</div>
	</div>
	<div class="nb-map-details-block-wrapper nb-map-details-body" id="blockdetailstop">
		<div class="content-block-no-border">
			<div class="sectiontitle-block">
				<div class="nb-section-title">
					<div>
						<span>{{ 'T_CORE.TRIPS' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="nb-blocks-trip-header-buttons" id="map-details-block-toolbar">
				<div class="cancel-trip-container" title="{{ cancelTripsTooltip }}">
					<button class="btn btn-action" (click)="cancelTrips()" [disabled]="!enableCancelTrips" data-test="block.details.cancel_trip">
						<i class="fa fa-minus-circle cancel-trip-icon" aria-hidden="true"></i>
						<span>{{ 'T_MAP.MAP_BLOCKS_CANCEL_TRIPS' | translate }}</span>
					</button>
				</div>
				<div class="activate-trip-container" title="{{ activateTripsTooltip }}">
					<button class="btn btn-action" (click)="activateTrips()" [disabled]="!enableActivateTrips">
						<i class="fa fa-plus-circle activate-trip-icon" aria-hidden="true"></i>
						<span>{{ 'T_MAP.MAP_BLOCKS_ACTIVATE_TRIPS' | translate }}</span>
					</button>
				</div>
			</div>
			<div class="liveops-table-body" *ngIf="!listLoadingIndicator">
				<div id="trip-list-table" class="trip-list-container">
					<div class="liveops-container-fluid" id="tripTable">
						<div class="data-list-container">
							<data-table
								#dataList
								data-test="block.details.table"
								[listName]="listName"
								[columns]="columns"
								[rowData]="tripList"
								[pagingEnabled]="false"
								[externalSorting]="true"
								[sortInfo]="sortInfo"
								[enableFooter]="false"
								[enableCheckRowSelection]="true"
								[enabledMultiCheckRowSelection]="true"
								[enabledCheckSelectAllRowSelection]="true"
								[loadingIndicator]="listLoadingIndicator"
								[noDataMessage]="'T_MAP.NO_BLOCK_TRIPS_INFO'"
								(checkSelect)="onCheckSelect($event)"
								(sortData)="handleSortRequest($event)">
							</data-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #routePillColumnTemplate let-row="row">
	<route-pill *ngIf="row.routeId" [route]="determineRoutePillData(row.routeId)" [useFullWidth]="true"></route-pill>
</ng-template>

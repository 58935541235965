/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { MapSettingsComponent } from './settings/map-settings.component';

import { MapEventsService } from '../services/map-events.service';
import { MapViewService } from '../left-menu/views/services/map-view.service';
import { MapPollingService } from '../services/map-polling.service';
import { MapUpdateService } from '../services/map-update.service';
import { MapRoutesService } from '../services/map-routes.service';
import { MapVehiclesService } from '../services/map-vehicles.service';
import { MapStopsService } from '../services/map-stops.service';
import { MapOptionsService } from '../services/map-options.service';
import { MapReplayService } from '../services/map-replay.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { MapModeType, ModeType, SettingsDisplayType } from '../types/types';
import { UserLogin } from '../../../support-features/login/types/api-types';

@Component({
	selector: 'map-options',
	templateUrl: './map-options.component.html',
	styleUrls: ['./map-options.component.scss'],
})
export class MapOptionsComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@ViewChild(MapSettingsComponent) mapSettingsCmp: MapSettingsComponent;

	// Make Enums accessible within the html
	public mapModeType: typeof MapModeType = MapModeType;
	public modeType: typeof ModeType = ModeType;

	public ladderViewTooltip: string = null;
	public ladderFeatureEnabledForAgency: boolean = false;
	public showSettings: boolean = false;

	private readonly agencyLadderFeatureId: number = 1;

	private agenciesSelectionChange$Subscription: Subscription = null;

	private settingFullScreen: boolean = false;

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private agenciesDataService: AgenciesDataService,
		private mapViewService: MapViewService,
		private mapReplayService: MapReplayService,
		private agenciesEventsService: AgenciesEventsService,
		private mapEventsService: MapEventsService,
		private mapPollingService: MapPollingService,
		private mapUpdateService: MapUpdateService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesService: MapVehiclesService,
		private mapStopsService: MapStopsService,
		public mapOptionsService: MapOptionsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * Add listeners for all browser types that will listen to screen size changing
	 * this is so we can capture if the esc key has been pressed to minimise from the full screen
	 * and therefore update the map state value to reflect this full screen change
	 * handle the screen change event
	 */
	@HostListener('document:fullscreenchange', ['$event'])
	@HostListener('document:webkitfullscreenchange', ['$event'])
	@HostListener('document:mozfullscreenchange', ['$event'])
	@HostListener('document:MSFullscreenChange', ['$event'])
	screenChange(): void {
		this.handleFullScreenChange();
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.setSubscriptions();

		this.determineLadderFeatureEnabled(this.currentUserUtilService.getCurrentUserPrimaryAgencyId());
	}

	/* Supports HTML */

	/**
	 * handle the user selection to view the map
	 */
	public viewMap = (): void => {
		this.mapOptionsService.setMode(ModeType.map);
	};

	/**
	 * handle the user selection to view the ladder
	 */
	public viewLadder = (): void => {
		this.mapOptionsService.setMode(ModeType.ladder);

		// cancel replay mode if we enter the ladder
		this.mapReplayService.setShowReplayControl(false);
	};

	/**
	 * handle the user selection to zoom in
	 */
	public zoomIn = (): void => {
		this.mapEventsService.publishZoomIn();
	};

	/**
	 * handle the user selection to zoom out
	 */
	public zoomOut = (): void => {
		this.mapEventsService.publishZoomOut();
	};
	/**
	 * handle the user selection to clear the mao
	 */
	public clearMap = (): void => {
		this.mapVehiclesService.clearVehicles();
		this.mapRoutesService.clearRoutes();
		this.mapStopsService.clearStops();
	};

	/**
	 * handle the user selection to create a view
	 */
	public createView = async (): Promise<void> => {
		const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		this.mapViewService.createView(currentUser.nb_id);
	};

	/**
	 * handle the user selection to toggle replay mode
	 */
	public toggleReplayMode = (): void => {
		if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
			// if we are currently in replay mode - unload the replay controls and revert to live
			this.mapReplayService.setShowReplayControl(false);

			this.mapOptionsService.setMapMode(MapModeType.live);

			this.mapPollingService.initPolling();
		} else {
			// when setting to replay mode - just show the toggle the replay controls -
			// we won't go in to replay mode until user has initiated it via the control
			this.mapReplayService.setShowReplayControl(!this.mapReplayService.getShowReplayControl());
		}
	};

	/**
	 * handle the user selection to toggle live updates (i.e pause/unpause)
	 */
	public toggleLiveUpdates = (): void => {
		this.mapUpdateService.toggleLiveUpdates();
	};

	/**
	 * handle the user selection to toggle full screen
	 */
	public toggleFullScreen = (): void => {
		this.settingFullScreen = true;

		this.mapOptionsService.setFullScreen(!this.mapOptionsService.getFullScreen());
	};

	/**
	 * handle the user selection to toggle the settings menu opening/closing
	 */
	public toggleSettings = (): void => {
		this.showSettings = !this.showSettings;
	};

	/**
	 * handle the user selection to cose the settings menu
	 */
	public closeSettings = (): void => {
		if (this.showSettings) {
			this.showSettings = false;

			this.mapSettingsCmp.setSettingDisplayType(SettingsDisplayType.settings);
		}
	};

	/**
	 * get the live updates tooltip translation
	 *
	 * @returns the live updates tooltip
	 */
	public getLiveUpdateTooltip = (): string => {
		let tooltip: string = this.translations['T_CORE.UNAVAILABLE'];

		if (this.mapOptionsService.getMapMode() !== this.mapModeType.replay) {
			tooltip = this.mapOptionsService.getLiveUpdatesPaused()
				? this.translations['T_CORE.MAP_RESUME_TOOLTIP']
				: this.translations['T_CORE.MAP_PAUSE_TOOLTIP'];
		}

		return tooltip;
	};

	/**
	 * handle any cleanup for the component (unsubscribe from our subscriptions)
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * set up subscriptions for the page
	 *
	 * agency selection change - handle the change of agency. Disable the ladder feature if the ladder is not enabled for
	 * this agency
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.determineLadderFeatureEnabled(this.currentUserUtilService.getCurrentUserPrimaryAgencyId());
		});
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.VIEW_LADDER_ENABLE_TOOLTIP',
			'T_CORE.VIEW_LADDER_DISABLE_TOOLTIP',
			'T_CORE.UNAVAILABLE',
			'T_CORE.MAP_RESUME_TOOLTIP',
			'T_CORE.MAP_PAUSE_TOOLTIP',
		]);
	};

	/**
	 * determine if the ladder should be enabled for the given agency
	 *
	 * @param agencyId - the agency id to check
	 */
	private determineLadderFeatureEnabled = (agencyId: number): void => {
		this.ladderFeatureEnabledForAgency = this.agenciesDataService.isFeatureEnabled(agencyId, this.agencyLadderFeatureId);

		if (this.ladderFeatureEnabledForAgency) {
			this.ladderViewTooltip = this.translations['T_CORE.VIEW_LADDER_ENABLE_TOOLTIP'];
		} else {
			this.ladderViewTooltip = this.translations['T_CORE.VIEW_LADDER_DISABLE_TOOLTIP'];

			this.mapOptionsService.setMode(ModeType.map);
		}
	};

	/**
	 * handle the full screen size changing
	 */
	private handleFullScreenChange = (): void => {
		// ignore if we are getting here due to us actually toggling the full screen via our toggle method (clicking the button).
		if (!this.settingFullScreen) {
			// handle the user cancelling the fullscreen (i.e pressing the escape key)
			if (this.mapOptionsService.getFullScreen()) {
				this.settingFullScreen = true;

				this.mapOptionsService.setFullScreen(false);
			}
		}

		this.settingFullScreen = false;
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
	};
}
